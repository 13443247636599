import React, { useEffect, useState, useRef } from 'react';
import Video from './components/video';
import Videos from './components/videos';
import Draggable, { DraggableCore } from 'react-draggable';
import { Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Phone, PhoneDisabled, Videocam, VideocamOff, Mic, MicOff, Fullscreen, FullscreenExit } from '@material-ui/icons';
import DialogCNO from '../../library/DialogCNO';
import { toggleCallModal, toggleCallModalOpenOnce } from '../../actions/chatActions';
import { useDispatch, useSelector } from 'react-redux';
import { MessageType } from '../../utils';
import ToggleIcon from "material-ui-toggle-icon";
import { fetchActiveGroupMembers } from '../../actions/chatActions';
import { Howl } from 'howler';
import ringtone from '../../Sounds/ringtone.mp3';
import outgoingCall from '../../Sounds/iphone.mp3';
import incomingCall from '../../Sounds/mariamba.mp3';
import rejectedCall from '../../Sounds/skype_busy_line.mp3';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Icon from '@mdi/react';
import { mdiArrowExpand, mdiExpandAll, mdiWindowMaximize } from '@mdi/js';
import { useHistory } from "react-router-dom";
import { apiMessageCreate, apiMessageGroupMembers } from '../../api';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  localVideoContainer: {
    zIndex: 101,
    position: 'absolute',
    right: 20,
    top: 150,
    cursor: 'move',
  },
  expandScreenButton: {
    display: 'flex',
    margin: theme.spacing(1),
    backgroundColor: 'grey',
    color: '#FFF',
    justifyContent: 'flex-end',
  },
  endCallButton: {
    display: 'flex',
    margin: theme.spacing(1),
    backgroundColor: 'red',
    color: '#FFF',
    justifyContent: 'flex-end',
  },
  answerCallButton: {
    display: 'flex',
    margin: theme.spacing(1),
    backgroundColor: 'green',
    color: '#FFF',
    justifyContent: 'flex-start',
  },
  notificationContainer: {
    display: 'flex',
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.3)',
    justifyContent: 'space-between',
    zIndex: 50,
    borderRadius: 5,
    color: '#FFFFFF',
    marginBottom: 10,
  },
  timer: {
    justifyContent: 'center'
  },
}));

const outgoingCallSound = new Howl({
  src: [outgoingCall],
  loop: true,
  preload: true
});

const incomingCallSound = new Howl({
  src: [incomingCall],
  loop: true,
  preload: true
});

const rejectedCallSound = new Howl({
  src: [rejectedCall],
  loop: false,
  preload: true
});

function ChatCallScreen() {

  const history = useHistory();

  const classes = useStyles();

  const dispatch = useDispatch();
  const {
    isCallModalOpen,
    isCallModalOpenOnce,
    activeChatUser,
    messageReceived,
    activeGroupMembers
  } = useSelector(state => state.chats);
  const [localStream, setLocalStream] = useState(null); // used to hold local stream object to avoid recreating the stream everytime a new offer comes
  const [remoteStream, setRemoteStream] = useState(null); // used to hold remote stream object that is displayed in the main screen
  const [remoteStreams, setRemoteStreams] = useState([]); // holds all Video Streams (all remote streams)
  const [peerConnections, setPeerConnections] = useState({}); // holds all Peer Connections
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isVideoFullScreen, setIsVideoFullScreen] = useState(false);
  const [isVideoCallTriggered, setIsVideoCallTriggered] = useState(false);

  const [incomingCallerId, setIncomingCallerId] = useState(null);
  const [isGroupCall, setIsGroupCall] = useState(false);
  const [showAnswerButton, setShowAnswerButton] = useState(false);
  const [mediaConstraints, setMediaConstraints] = useState({
    audio: true,
    video: messageReceived.isVideoCall ?
      {
        width: 640,
        height: 480
      } : false,
    options: {
      mirror: false,
    }
  });

  const [currentCallCandidates, setCurrentCallCandidates] = useState([]);
  const [currentCallData, setCurrentCallData] = useState(null);

  const [pc_config, setPc_config] = useState({
    "iceServers": [
      { url: 'stun:stun.l.google.com:19302' },
    ]
  });

  const [sdpConstraints, setSdpConstraints] = useState({
    'mandatory': {
      'OfferToReceiveAudio': true,
      'OfferToReceiveVideo': true
    }
  });

  const [statusText, setStatusText] = useState('Please wait...');
  const [callHeaderText, setCallHeaderText] = useState('Call');

  const authUser = JSON.parse(localStorage.getItem("authUser")) || {};
  const [key, setKey] = useState(0);

  //-------For Drag Drop START-----
  let [activeDrags, setActiveDrags] = useState(0);
  const [deltaPosition, setDeltaPosition] = useState({
    x: 0, y: 0
  });
  const [controlledPosition, setControlledPosition] = useState({
    x: -400, y: 200
  });

  const handleDrag = (e, ui) => {
    const { x, y } = deltaPosition;
    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };


  const handleStart = () => {
    setActiveDrags(++activeDrags);
  };

  const handleStop = () => {
    setActiveDrags(--activeDrags);
  };

  const handleDrop = (e) => {
    setActiveDrags(--activeDrags);
    if (e.target.classList.contains("drop-target")) {
      alert("Dropped!");
      e.target.classList.remove('hovered');
    }
  };

  const onDropAreaMouseEnter = (e) => {
    if (activeDrags) {
      e.target.classList.add('hovered');
    }
  };

  const onDropAreaMouseLeave = (e) => {
    e.target.classList.remove('hovered');
  };

  // For controlled component
  const adjustXPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { x, y } = controlledPosition;
    setControlledPosition({ x: x - 10, y });
  };

  const adjustYPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { x, y } = controlledPosition;
    setControlledPosition({ x, y: y - 10 });
  };

  const onControlledDrag = (e, position) => {
    const { x, y } = position;
    setControlledPosition({ x, y });
  };

  const onControlledDragStop = (e, position) => {
    onControlledDrag(e, position);
    handleStop();
  };
  //-------For Drag Drop END-------


  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return (<div className="timer">X</div>);
    }
    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
      </div>
    );
  };

  const missedCallTimer = () => {
    return (
      <div style={{ alignSelf: 'center' }}>
        <CountdownCircleTimer
          key={key}
          size={40}
          strokeWidth={5}
          isPlaying
          duration={30}
          colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
          onComplete={() => {
            triggerCallMissed();
          }}
        >
          {renderTime}
        </CountdownCircleTimer>
      </div>
    )
  };

  const triggerCallMissed = async () => {
    console.log("-------TriggerCallMissed----CALL MISSED----------");
    if (showAnswerButton) {
      console.log("------------------MISSED CALL-------");

      sendToPeer(MessageType.VideoCallMissed, null, messageReceived.createdBy);

      incomingCallSound.stop();
      rejectedCallSound.play();

      setCurrentCallCandidates([]);
      setCurrentCallData(null);

      setIncomingCallerId(null);

      await stopTracks(localStream);

      dispatch(toggleCallModal(false));

      setTimeout(() => {
        history.push("/home");
        window.location.reload();
      }, 2000);
    }
  };

  useEffect(() => {
    if (activeChatUser && activeChatUser.contactType === "group") {
      console.log("-------------------IS GROUP CALL FETCH GROUP MEMBERS----------------");
      const groupMembersPayload = {
        pageSize: 10,
        pageIndex: 0,
        orderBy: "",
        sortDecending: true,
        groupId: activeChatUser.id
      };
      setIsGroupCall(true);
      dispatch(fetchActiveGroupMembers(groupMembersPayload));
    }
    else {
      setIsGroupCall(false);
    }
  }, [activeChatUser]);

  useEffect(() => {
    console.log("------------ MessageReceived----", messageReceived);
    console.log("---------MessageReceived activeGroupMembers----------------", activeGroupMembers);

    if (messageReceived) {
      if (messageReceived.isVideoCall &&
        (messageReceived.callContext === 'IncomingVideoCall' || messageReceived.callContext === 'TriggerVideoCall')) {
        setIsVideoCallTriggered(true);
      }
      if (messageReceived.callContext) {
        console.log("------------ MessageReceived--callContext--", messageReceived.callContext);
        switch (messageReceived.callContext.toString()) {

          case 'TriggerVideoCall':
            console.log("----MESSAGE TYPE-----LOCAL STREAM----", localStream);
            getLocalStream();
            setCallHeaderText(`Calling ${activeChatUser.contactName}`);
            setCurrentCallData(messageReceived);
            outgoingCallSound.play();
            setStatusText(`Calling ${activeChatUser.contactName}`);
            console.log("----MESSAGE TYPE-----TriggerVideoCall----", messageReceived.callContext.toString());
            break;

          case 'TriggerAudioCall':
            getLocalStream();
            setCallHeaderText(`Calling ${activeChatUser.contactName}`);
            setCurrentCallData(messageReceived);
            outgoingCallSound.play();
            setStatusText(`Calling ${activeChatUser.contactName}`);
            console.log("----MESSAGE TYPE-----TriggerVideoCall----", messageReceived.callContext.toString());
            break;

          default:

            if (messageReceived.message) {

              console.log("------------ MessageReceived--messageReceived.message.replyToText--", JSON.parse(messageReceived.message.replyToText));

              switch (messageReceived.message.messageType) {

                case MessageType.AudioCall:
                case MessageType.VideoCall:
                case MessageType.AutoOffer:
                  setShowAnswerButton(true);
                  getLocalStream();

                  console.log("----OFFER - LASH----", messageReceived);
                  console.log("----OFFER - LASH--messageReceived.message.replyToText.isGroupCall--", JSON.parse(messageReceived.message.replyToText).isGroupCall);

                  setIsGroupCall(JSON.parse(messageReceived.message.replyToText).isGroupCall ? true : false);

                  JSON.parse(messageReceived.message.replyToText).isGroupCall ?
                    setCallHeaderText(`Incoming Group Call`) :
                    setCallHeaderText(`Incoming Call from ${JSON.parse(messageReceived.message.replyToText).members.firstName + ' ' + JSON.parse(messageReceived.message.replyToText).members.lastName}`);
                  incomingCallSound.play();

                  console.log("----MESSAGE TYPE-----Call INITIATED----", messageReceived.message.messageType);
                  console.log("-----create offer----useeffect--- inside OutgoingAudio/VideoCall");

                  if (messageReceived.message.messageType !== MessageType.AutoOffer) {
                    setIncomingCallerId(messageReceived.createdBy);
                    setCurrentCallData(messageReceived);
                  }
                  else {
                    console.log('-------OFFER: setIncomingCallerId--------', incomingCallerId);
                    if (localStream) {
                      createPeerConnection(messageReceived.createdBy, pc => {
                        console.log('-------OFFER: PC--------', pc);
                        console.log('-------OFFER: ALL PC--------', peerConnections);
                        setLocalStream(prevState => {
                          console.log('--------------AFTER PC CREATE - IN CALL BACK-------prevState------------', prevState);

                          console.log('--------------AFTER PC CREATE - IN CALL BACK-------localStream------------', localStream);

                          pc.addStream(prevState);

                          return prevState;
                        });

                        pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(messageReceived.message.text))).then(() => {
                          if (messageReceived.message.messageType === MessageType.AutoOffer) {
                            createSingleAnswer(messageReceived.createdBy, MessageType.AutoAnswer, pc);
                          }
                        });

                        setStatusText('Incoming call...');
                      });
                    }
                  }
                  break;

                case MessageType.AudioCallAccepted:
                case MessageType.VideoCallAccepted:
                case MessageType.AutoAnswer:
                  outgoingCallSound.stop();
                  incomingCallSound.stop();

                  setIsGroupCall(JSON.parse(messageReceived.message.replyToText).isGroupCall ? true : false);

                  JSON.parse(messageReceived.message.replyToText).isGroupCall ?
                    setCallHeaderText(`Ongoing Group Call`) :
                    setCallHeaderText(`In call with ${JSON.parse(messageReceived.message.replyToText).members.firstName + ' ' + JSON.parse(messageReceived.message.replyToText).members.lastName}`);

                  console.log("----MESSAGE TYPE-----Call ACCEPTED----", messageReceived.message.messageType);
                  console.log('-------ACCEPT: Setting local params--------');

                  // setIncomingCallerId(messageReceived.message.createdBy);

                  // console.log('-------ACCEPT: setIncomingCallerId--------', incomingCallerId);

                  setPeerConnections(currentConnections => {
                    const pc = currentConnections[messageReceived.createdBy];
                    //console.log('------------------PEER CONNECTIONS--------peerConnectionsFromStorage--------', peerConnectionsFromStorage);
                    console.log('------------------PEER CONNECTIONS----------------', peerConnections);
                    console.log("-----pc-----", pc);

                    pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(messageReceived.message.text))).then(() => { });
                    return currentConnections;
                  });
                  setStatusText('Call connected...');
                  break;

                case MessageType.Candidate:
                  console.log("----MESSAGE TYPE-----Call CANDIDATE----", messageReceived.message.messageType);
                  console.log("-----CANDIDATE:---------------------");

                  // setIncomingCallerId(messageReceived.message.createdBy);

                  // console.log('-------CANDIDATE: setIncomingCallerId--------', incomingCallerId);

                  setLocalStream(prevState => {
                    if (prevState) {

                      setPeerConnections(currentConnections => {
                        const pc = currentConnections[messageReceived.createdBy];

                        if (pc)
                          pc.addIceCandidate(new RTCIceCandidate(JSON.parse(messageReceived.message.text)));
                        return currentConnections;
                      });
                    }
                    else {
                      setCurrentCallCandidates(ccc => { return [...ccc, JSON.parse(messageReceived.message.text)]; });
                    }

                    return prevState;
                  });
                  break;

                case MessageType.AudioCallMissed:
                case MessageType.VideoCallMissed:
                  console.log("----MESSAGE TYPE-----Call MISSED----", messageReceived.message.messageType);
                  outgoingCallSound.stop();
                  incomingCallSound.stop();
                  rejectedCallSound.play();

                  setIsGroupCall(JSON.parse(messageReceived.message.replyToText).isGroupCall ? true : false);

                  JSON.parse(messageReceived.message.replyToText).isGroupCall ?
                    setCallHeaderText(`Ongoing Group Call`) :
                    setCallHeaderText(`In call with ${JSON.parse(messageReceived.message.replyToText).members.firstName + ' ' + JSON.parse(messageReceived.message.replyToText).members.lastName}`);

                  JSON.parse(messageReceived.message.replyToText).isGroupCall ?
                    setStatusText(`Group Call`) :
                    setStatusText(`Call not picked by ${JSON.parse(messageReceived.message.replyToText).members.firstName + ' ' + JSON.parse(messageReceived.message.replyToText).members.lastName}`);
                  break;

                case MessageType.AudioCallRejected:
                case MessageType.VideoCallRejected:
                  console.log("----MESSAGE TYPE-----Call REJECTED----", messageReceived.message.messageType);
                  outgoingCallSound.stop();
                  incomingCallSound.stop();
                  rejectedCallSound.play();

                  setIsGroupCall(JSON.parse(messageReceived.message.replyToText).isGroupCall ? true : false);

                  JSON.parse(messageReceived.message.replyToText).isGroupCall ?
                    setStatusText(`Group Call`) :
                    setStatusText(`Call rejected by ${JSON.parse(messageReceived.message.replyToText).members.firstName + ' ' + JSON.parse(messageReceived.message.replyToText).members.lastName}`);

                  break;

                case MessageType.AudioCallEnded:
                case MessageType.VideoCallEnded:
                  console.log("----MESSAGE TYPE-----Call ENDED----", messageReceived.message.messageType);
                  outgoingCallSound.stop();
                  incomingCallSound.stop();
                  rejectedCallSound.play();

                  setIsGroupCall(JSON.parse(messageReceived.message.replyToText).isGroupCall ? true : false);

                  JSON.parse(messageReceived.message.replyToText).isGroupCall ?
                    setStatusText(`Group Call`) :
                    setStatusText(`Call disconnected by ${JSON.parse(messageReceived.message.replyToText).members.firstName + ' ' + JSON.parse(messageReceived.message.replyToText).members.lastName}`);

                  peerDisconnected(messageReceived.createdBy);
                  if (remoteStreams.length === 0) {
                    closeCallModal();
                  }
                  break;

                case MessageType.JoinCall:
                  console.log("----MESSAGE TYPE-----JOIN CALL----", messageReceived.message.messageType);
                  outgoingCallSound.stop();
                  incomingCallSound.stop();
                  rejectedCallSound.play();
                  setStatusText("Call connected...");
                  break;

                default:
                  console.log("-----create offer----useeffect--- inside default");
                  break;
              }
            }
            break;
        }
      }
    }
  }, [messageReceived]);


  useEffect(() => {
    if (localStream) {
      console.log("------ANAND123 AYAN------ localStream----", localStream);
      console.log("------AYAN------ currentCallData----", currentCallData);
      console.log("----AYAN-----MessageReceived activeGroupMembers----------------", activeGroupMembers);
      if (currentCallData) {

        switch (currentCallData.callContext.toString()) {
          case 'TriggerVideoCall':
            console.log("------ANAND123 AYAN------ messageReceived.callContext----", messageReceived.callContext);
            createOffer(MessageType.VideoCall, activeChatUser.id, isGroupCall, authUser.contactName);
            console.log("----ON LOCAL STREAM----MESSAGE TYPE-----TriggerVideoCall----", currentCallData.callContext.toString());
            break;

          case 'TriggerAudioCall':
            createOffer(MessageType.AudioCall, activeChatUser.id, isGroupCall, authUser.contactName);
            console.log("----ON LOCAL STREAM----MESSAGE TYPE-----TriggerVideoCall----", currentCallData.callContext.toString());
            break;

          default:

            if (currentCallData.message) {

              console.log("------ANAND123 AYAN------ currentCallData.message----", currentCallData.message);
              switch (currentCallData.message.messageType) {

                case MessageType.AudioCall:
                case MessageType.VideoCall:
                  console.log("------ANAND123 AYAN------ HERE----");
                  setShowAnswerButton(true);

                  console.log('-------ON LOCAL STREAM----OFFER: setIncomingCallerId--------', incomingCallerId);

                  createPeerConnection(currentCallData.createdBy, pc => {
                    console.log('-------ON LOCAL STREAM---OFFER: PC--------', pc);
                    console.log('-------ON LOCAL STREAM---OFFER: ALL PC--------', peerConnections);
                    setLocalStream(prevState => {
                      console.log('--------------ON LOCAL STREAM---AFTER PC CREATE - IN CALL BACK-------prevState------------', prevState);

                      console.log('--------------ON LOCAL STREAM---AFTER PC CREATE - IN CALL BACK-------localStream------------', localStream);

                      pc.addStream(prevState);

                      return prevState;
                    });

                    pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(currentCallData.message.text))).then(() => {

                    });

                    // apply pending candidates

                    if (currentCallCandidates && currentCallCandidates.length > 0) {
                      currentCallCandidates.forEach(currentCandidate => {
                        setPeerConnections(currentConnections => {
                          const pc = currentConnections[currentCallData.createdBy];

                          if (pc)
                            pc.addIceCandidate(new RTCIceCandidate(currentCandidate));
                          return currentConnections;
                        });
                      });
                    }

                    setIsGroupCall(JSON.parse(messageReceived.message.replyToText).isGroupCall ? true : false);

                    JSON.parse(messageReceived.message.replyToText).isGroupCall ?
                      setStatusText(`Incoming Group Call`) :
                      setStatusText(`Incoming Call from ${JSON.parse(currentCallData.message.replyToText).members.firstName + ' ' + JSON.parse(currentCallData.message.replyToText).members.lastName}`);
                  });
                  break;

                default:
                  console.log("-----create offer----useeffect--- inside default");
                  break;
              }
            }
            break;
        }
      }
    }
  }, [localStream]);


  const getLocalStream = () => {
    // called when getUserMedia() successfully returns - see below
    // getUserMedia() returns a MediaStream object (https://developer.mozilla.org/en-US/docs/Web/API/MediaStream)
    const success = (stream) => {
      window.localStream = stream;

      setLocalStream(prevState => { return stream; });

      console.log("---------setLocalStream-----------", stream);

      // whoisOnline();
    };

    // called when getUserMedia() fails - see below
    const failure = (e) => {
      console.log('getUserMedia Error: ', e);
    };

    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
    // see the above link for more constraint options
    console.log("------getLocalStream--------222-------------mediaConstraints--------------", mediaConstraints);
    const constraints = mediaConstraints;

    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
    navigator.mediaDevices.getUserMedia(constraints)
      .then(success)
      .catch(failure);
  };

  const whoisOnline = () => {
    // let all peers know I am joining
    console.log("----------whoisOnline--------------");
    sendToPeer(MessageType.JoinCall, null, authUser.id);
  };

  const sendToPeer = async (messageType, sdp, toMemberId) => {
    const stringData = JSON.stringify(sdp);

    console.log("-----isGroupCall-------currentGroupMembers-------------AARRRAA---------", activeGroupMembers);

    let replyToTextObj = {};

    if (isGroupCall) {
      replyToTextObj = {
        isGroupCall: isGroupCall,
        members: activeGroupMembers
      };
    } else {
      replyToTextObj = {
        isGroupCall: isGroupCall,
        members: authUser
      };
    }

    const messagePayload = JSON.stringify({
      text: stringData,
      toMemberId: toMemberId,
      messageType: messageType,
      replyToText: JSON.stringify(replyToTextObj)
      //replyToId: isGroupCall ? JSON.stringify(activeGroupMembers) : null
    });

    await apiMessageCreate.post(messagePayload)
      .then((response) => {
        console.log("Message Create Response: ", response);
        if (response.status === 200) {
          console.log("Message Sent Successfully: ", response);
        }
        else {
          console.log("Message Create Error: ", response);
          toast.error("Error in message send");
        }
      })
      .catch((error) => {
        console.error("Message Create Error: ", error);
        toast.error("Error in message send");
      });
  };

  const peerDisconnected = (userId) => {

    console.log("----------------PEER DISCONNECTED---------------", peerConnections);

    console.log("----------------PEER DISCONNECTED---------peerConnections[userId]------", peerConnections[userId]);

    console.log("----------------PEER DISCONNECTED-------remoteStreams--------", remoteStreams);

    // close peer-connection with this peer
    peerConnections && peerConnections[userId] && peerConnections[userId].close();

    // get and stop remote audio and video tracks of the disconnected peer
    const rVideo = remoteStreams.filter(stream => stream.id === userId);
    console.log("----------------PEER DISCONNECTED-------rVideo--------", rVideo);
    rVideo && rVideo.length > 0 && stopTracks(rVideo[0].stream);

    // filter out the disconnected peer stream
    const remoteStreamsLocal = remoteStreams.filter(stream => stream.id !== userId);

    // setRemoteStreams(prevState => {
    //   // check if disconnected peer is the selected video and if there still connected peers, then select the first
    //   const selectedVideo = prevState.selectedVideo.id === userId && remoteStreams.length ? { selectedVideo: remoteStreams[0] } : null;

    //   return {
    //     // remoteStream: remoteStreams.length > 0 && remoteStreams[0].stream || null,
    //     remoteStreamsLocal,
    //     ...selectedVideo
    //   };
    // });
  };

  const createPeerConnection = (toId, callback) => {

    try {
      let pc = new RTCPeerConnection(pc_config);

      console.log("=========createPeerConnection===============", pc);
      // add pc to peerConnections object
      //const _peerConnections = { ...peerConnections, [toId]: pc };

      setPeerConnections(p => { return { ...p, [toId]: pc }; });


      //setPeerConnections(_peerConnections);

      //localStorage.setItem("peerConnectionsStorage", JSON.stringify(_peerConnections));

      console.log("====peerConnections=====createPeerConnection=======peerConnections========", peerConnections);

      //console.log("====peerConnections=====peerConnectionsStorage=======peerConnections========", JSON.parse(localStorage.getItem("peerConnectionsStorage")));

      //console.log("=========createPeerConnection=======_peerConnections========", _peerConnections);
      pc.onicecandidate = (e) => {
        if (e.candidate) {
          sendToPeer(MessageType.Candidate, e.candidate, toId);
        }
      };

      pc.oniceconnectionstatechange = (e) => {
        console.log("----DISCONNECT-------oniceconnectionstatechange----------", pc.iceConnectionState);
        if (pc.iceConnectionState === 'disconnected') {
          console.log("----DISCONNECT-------toId----------", toId);

          console.log("----DISCONNECT-------remoteStreams.length----------", remoteStreams.length);

          if (remoteStreams.length === 0) {
            closeCallModal();
          }
          // sendToPeer(MessageType.VideoCallEnded, e.candidate, toId);
          // const remoteStreamsLocal = remoteStreams.filter(stream => stream.id !== toId);
          // setRemoteStream(remoteStreamsLocal.length > 0 ? remoteStreamsLocal[0].stream : null);
        }
      };

      pc.ontrack = (e) => {

        let _remoteStream = null;
        let _remoteStreams = remoteStreams;
        let _remoteVideo = {};

        console.log('-----------------ALL STREAMS---------------------', remoteStreams);

        // 1. check if stream already exists in remoteStreams
        let rVideos = [];

        setRemoteStreams(p => {
          rVideos = p.filter(stream => stream.id === toId);

          return p;
        });

        // 2. if it does exist then add track
        if (rVideos.length) {
          console.log('-----------------rVideos---------------------', rVideos);

          _remoteStream = rVideos[0].stream;
          _remoteStream.addTrack(e.track, _remoteStream);

          _remoteVideo = {
            ...rVideos[0],
            stream: _remoteStream,
          };

          setRemoteStreams(p => {
            _remoteStreams = p.map(remoteV => {
              return remoteV.id === _remoteVideo.id && _remoteVideo || remoteV;
            });

            return _remoteStreams;
          });

        } else {
          // 3. if not, then create new stream and add track
          _remoteStream = new MediaStream();
          _remoteStream.addTrack(e.track, _remoteStream);

          _remoteVideo = {
            id: toId,
            name: toId,
            stream: _remoteStream,
          };

          setRemoteStreams(p => {
            _remoteStreams = [...p, _remoteVideo];
            return _remoteStreams;
          });

          console.log('-----------------_remoteStreams---------------------', _remoteStreams);
        }

        setRemoteStream(prevState => {
          console.log('-----------------setRemoteStream------------prevState---------', prevState);
          const remoteStreamLocal = prevState && prevState.length > 0 ? {} : _remoteStream;
          console.log('-----------------setRemoteStream------------remoteStreamLocal---------', remoteStreamLocal);
          return remoteStreamLocal;
        });

        //setRemoteStreams(_remoteStreams);

        setSelectedVideo(prevState => {
          console.log('-----------------setSelectedVideo------------prevState---------', prevState);
          let selectedVideo = _remoteStreams && prevState ? _remoteStreams.filter(stream => stream.id === prevState.id) : null;
          // if the video is still in the list, then do nothing, otherwise set to new video stream
          selectedVideo = selectedVideo && selectedVideo.length ? {} : _remoteVideo;

          console.log('-----------------setSelectedVideo------------selectedVideo---------', selectedVideo);
          return selectedVideo;
        });
      };

      pc.close = () => {
        // alert('GONE')
        console.log("pc closed");
      };

      if (localStream)
        // pc.addStream(this.state.localStream)

        localStream.getTracks().forEach(track => {
          pc.addTrack(track, localStream);
        });

      //setStatusText(`${Object.keys(peerConnections).length} peers are connected in call`);

      // return pc
      callback(pc);

    } catch (e) {
      console.log('Something went wrong! pc not created!!', e);
      // return;
      callback(null);
    }
  };



  const switchVideo = (_video) => {
    // console.log(_video)
    setSelectedVideo(_video);
  };


  const createOffer = async (messageType, objId, isGroup, callingUser) => {

    console.log("------------IS GROUP CALL-------", isGroup);

    if (isGroup) {
      // TODO: based on objId, fetch members here and replace groupMembers variable
      const filteredMembers = activeGroupMembers.filter(g => g.memberId !== authUser.id);

      console.log("------------IS GROUP CALL:: GROUP MEMBERS-------", isGroup);

      filteredMembers.map(member => {
        createSingleOffer(member.memberId, messageType);
      });
    }
    else {
      createSingleOffer(objId, messageType);
    }
  };

  const createSingleOffer = (userId, messageType) => {
    // create and send offer to the peer (data.socketID)
    // 1. Create new pc
    createPeerConnection(userId, pc => {
      console.log("=========createPeerConnection======inside callback=========", pc);

      if (pc) {
        pc.createOffer(sdpConstraints)
          .then(sdp => {
            console.log("=========createOffer======then=========", sdp);

            pc.setLocalDescription(sdp);

            sendToPeer(messageType, sdp, userId);
          })
          .catch(e => console.error('------createOffer----error--', e));
      }
    });
  };

  const createAnswer = async (messageType, userIdToAnswer) => {
    console.log("-----createAnswer-------userIdToAnswer-----------------", userIdToAnswer);
    console.log("-----createAnswer-------isGroupCall-----------------", messageReceived.message.replyToId);

    const pcToAnswer = peerConnections[userIdToAnswer];
    console.log("-----createAnswer-------pcToAnswer-----------------", pcToAnswer);

    createSingleAnswer(userIdToAnswer, messageType, pcToAnswer);

    if (messageReceived && messageReceived.message && messageReceived.message.replyToText !== null && JSON.parse(messageReceived.message.replyToText).isGroupCall) {
      setIsGroupCall(true);

      let groupMembers = JSON.parse(messageReceived.message.replyToText).members;

      const filteredMembers = groupMembers.filter(g => g.memberId !== authUser.id && g.memberId !== userIdToAnswer);
      console.log("-----groupMembers-------isGroupCall-----------------", groupMembers);

      console.log("-----filteredMembers-------isGroupCall-----------------", filteredMembers);

      Promise.all(filteredMembers.map(async member => {
        createSingleOffer(member.memberId, MessageType.AutoOffer);
      }));
    }
  };

  const createSingleAnswer = (userId, messageType, pc) => {

    console.log('-------ANSWER CONNECTION--ABHI-----', pc);

    if (pc) {
      pc.createAnswer(sdpConstraints)
        .then(sdp => {
          pc.setLocalDescription(sdp);
          sendToPeer(messageType, sdp, userId);
          setShowAnswerButton(false);
        });
    }
    incomingCallSound.stop();

    JSON.parse(messageReceived.message.replyToText).isGroupCall ?
      setCallHeaderText(`Ongoing Group Call`) :
      setCallHeaderText(`In call with ${JSON.parse(messageReceived.message.replyToText).members.firstName + ' ' + JSON.parse(messageReceived.message.replyToText).members.lastName}`);

    setStatusText('Call in progress...');
  };

  const rejectCall = async () => {
    console.log("------------------REJECT CALL-------");

    setIncomingCallerId(null);

    await stopTracks(localStream);

    setCurrentCallCandidates([]);
    setCurrentCallData(null);

    //ringtoneSound.stop();
    incomingCallSound.stop();
    outgoingCallSound.stop();
    rejectedCallSound.play();

    sendToPeer(MessageType.VideoCallRejected, null, messageReceived.createdBy);

    dispatch(toggleCallModal(false));

    history.push("/home");
    window.location.reload();
  };

  const closeCallModal = async () => {
    console.log("------------------CLOSE-------");
    setIncomingCallerId(null);

    await stopTracks(localStream);

    setCurrentCallCandidates([]);
    setCurrentCallData(null);

    incomingCallSound.stop();
    outgoingCallSound.stop();
    rejectedCallSound.play();

    // stop all remote audio & video tracks
    remoteStreams.forEach(rVideo => stopTracks(rVideo.stream));

    // stop all remote peerconnections
    peerConnections && Object.values(peerConnections).forEach(pc => pc.close());

    sendToPeer(MessageType.VideoCallEnded, null, messageReceived.createdBy);

    dispatch(toggleCallModal(false));
    history.push("/home");
    window.location.reload();
  };

  const stopTracks = async (stream) => {
    console.log("--------stopTracks-------streamstream----------", stream);
    if (stream) {
      await stream.getTracks().forEach(track => track.stop());
    }
  };


  const expandScreen = (e) => {
    const elem = e.target;
    setIsVideoFullScreen(false);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  return (
    <DialogCNO
      open={isCallModalOpen}
      onClose={() => { closeCallModal(); }}
      width={'65%'}
      //height={'90%'}
      dialogTitle={callHeaderText}
    >
      <div className={classes.notificationContainer}>
        {
          <div style={{
            flex: 1,
            justifyContent: 'center',
            margin: 10,
            backgroundColor: '#cdc4ff4f',
            padding: 10,
            borderRadius: 5,
            color: '#0E0E0E',
            //display: showAnswerButton? 'block' : 'none'
          }}>{statusText}</div>
        }
        {!showAnswerButton && <Button
          variant="contained"
          color="secondary"
          onClick={() => { closeCallModal() }}
          size="small"
          className={classes.endCallButton}
          startIcon={<PhoneDisabled fontSize="large" />}
        >
          End Call
        </Button>}

        {showAnswerButton && missedCallTimer()}
        {showAnswerButton &&
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              createAnswer(
                messageReceived.message.MessageType === MessageType.VideoCall ?
                  MessageType.VideoCallAccepted : MessageType.AudioCallAccepted,
                incomingCallerId,
                isGroupCall
              )
            }}
            size="small"
            className={classes.answerCallButton}
            startIcon={<Phone fontSize="large" />}
          >
            Answer Call
          </Button>
        }

        {showAnswerButton && <Button
          variant="contained"
          color="secondary"
          onClick={() => { rejectCall() }}
          size="small"
          className={classes.endCallButton}
          startIcon={<PhoneDisabled fontSize="large" />}
        >
          Reject Call
        </Button>}

        {!showAnswerButton &&
          <Icon
            path={mdiArrowExpand}
            size='30px'
            color='goldenrod'
            onClick={expandScreen}
            style={{ alignSelf: 'center', marginRight: '10px' }}
          />
        }

      </div>
      <div style={{
        display: (!showAnswerButton && messageReceived.isVideoCall ? 'block' : 'none')
      }}>
        {messageReceived.isVideoCall && <Draggable
          axis="both"
          handle="#localVideoDraggableContainer"
          defaultPosition={{ x: 0, y: 0 }}
          position={null}
          scale={1}
          onStart={handleStart}
          onDrag={handleDrag}
          onStop={handleStop}
          bounds="parent"
        >
          {messageReceived.isVideoCall && <div className={classes.localVideoContainer} id="localVideoDraggableContainer">
            <Video
              videoType='localVideo'
              videoStyles={{
                width: 180,
                visibility: (messageReceived && messageReceived.isVideoCall) ? 'visible' : 'hidden',
              }}
              frameStyle={{
                width: 180,
                borderRadius: 10,
                backgroundColor: 'black',
              }}
              showMuteControls={true}
              videoStream={localStream}
              autoPlay muted>
            </Video>
          </div>}
        </Draggable>}

        {!messageReceived.isVideoCall && <div className={classes.localVideoContainer} id="localVideoDraggableContainer">
          <Video
            videoType='localVideo'
            videoStyles={{
              width: 180,
              visibility: (messageReceived && messageReceived.isVideoCall) ? 'visible' : 'hidden',
            }}
            frameStyle={{
              width: 180,
              borderRadius: 10,
              backgroundColor: 'black',
            }}
            showMuteControls={true}
            videoStream={localStream}
            autoPlay muted>
          </Video>
        </div>}
        <div>
          {messageReceived.isVideoCall && <Videos
            switchVideo={switchVideo}
            remoteStreams={remoteStreams}
            visibility={(messageReceived && messageReceived.isVideoCall) ? 'visible' : 'hidden'}
          //videoStream={selectedVideo && selectedVideo.stream}
          />}
        </div>
      </div>
    </DialogCNO>
  )
}

export default ChatCallScreen;
