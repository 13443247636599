import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useState } from 'react';
import DialogCNO from '../../library/DialogCNO';
import FormActionButtons from '../FormActionButtons';
import { Autocomplete } from '@material-ui/lab';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { useDispatch, useSelector } from 'react-redux';
import UserSuggestionOption from '../UserSuggestionOption/UserSuggestionOption';
import { Chip, TextField } from '@material-ui/core';
import { fetchMemberSuggest, resetSuggestions } from '../../actions/suggestionsActions';
import { assignQuotationItem } from '../../actions/quotationItemActions';

function EditQuotationItemAssignUsers({
  open,
  onClose,
  quotationItem,
}) {

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const suggestions = useSelector(state => state.suggestions);
  const { editLoader } = useSelector(state => state.quotationItems);

  const [suggestionsOpen, setSuggestionsOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      assignTo: quotationItem.offerItemAssignments,
    },
    validationSchema: yup.object({
      assignTo: yup.array().required('Assigned user(s) is required')
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const unSelectedSuggestions = () => suggestions.filter(item => {
    return formik.values.assignTo
      .map(item => item.staffId || item.id)
      .indexOf(item.id) === -1;
  });

  const handleUpdate = (values) => {
    console.log("Edit Quotation Item: Assign: ", values);

    const offerItemAssignments = values.assignTo.map(item => ({
      staffId: item.id || item.staffId,
      staffText: item.title || item.staffText,
      startDate: quotationItem.startDate,
      endDate: quotationItem.endDate,
      startTime: quotationItem.startTime,
      endTime: quotationItem.endTime,
      paymentToEmployeeStatus: 1,
      paidAmount: 0,
      rate: 0,
      rateType: 0,
      quotationItemId: quotationItem.id
    }));

    const payload = {
      ...quotationItem,
      offerItemAssignments: offerItemAssignments
    };

    console.log("Edit Quotation Item: Assign: payload", payload);

    dispatch(assignQuotationItem(payload));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Quotation Item Assign User"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <Autocomplete
          className={GlobalStyles.textField}
          classes={{
            paper: GlobalStyles.suggestionsPaper,
            noOptions: GlobalStyles.autocompleteNoOptions,
            popper: GlobalStyles.popper
          }}
          value={formik.values.assignTo}
          open={suggestionsOpen}
          multiple
          dense
          closeIcon={() => null}
          forcePopupIcon={false}
          options={unSelectedSuggestions()}
          getOptionLabel={option => option.title}
          renderOption={option => <UserSuggestionOption user={option} />}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.title || option.staffText}
                {...getTagProps({ index })}
                className={GlobalStyles.autocompleteChip}
              />
            ))
          }
          }
          renderInput={(params) => (
            <TextField autoFocus {...params} variant="outlined" placeholder="Select Team Member" />
          )}
          onChange={(e, newValue) => {
            formik.setFieldValue("assignTo", newValue);
          }}
          onInputChange={(e) => {
            if (e && e.target.value) {
              dispatch(fetchMemberSuggest(e.target.value));
              setSuggestionsOpen(true);
            }
            else {
              setSuggestionsOpen(false);
            }
          }}
          onBlur={() => {
            setSuggestionsOpen(false)
            dispatch(resetSuggestions());
          }}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditQuotationItemAssignUsers
