import moment from "moment";
import { JobTargetType, TrackProximityRadius, TrackType, WorkHourType } from "./mappings";
import { v4 } from "uuid";
import { calculateDistance, isWithinTrackingProximity, timeDifference, timeDifferenceFromMidnight, timeDifferenceTillMidnight } from "./Tracking";

export const calculateDayHourTotal = async (jobHourDetails, date, dayIndex, jobHourDetailsKeysLength, task) => {

  const jobCoords = {
    lat: task ? task.lat : null,
    lng: task ? task.lng : null
  };

  const taskProximity = task ? (
    task.proximity ? task.proximity : TrackProximityRadius
  ) : null;

  const isJobDistanceType = task ? task.targetType === JobTargetType.Km : false;
  const isJobTimeType = task ? task.targetType === JobTargetType.Hour : true;
  const isJobVolumeType = task ? task.targetType === JobTargetType.Quantity : false;

  // console.log("date: klm: ", date);

  const dayHourDetails = jobHourDetails[date];

  let isPreviousEntryFromWithinProximity = false;

  let isPreviousEntryFromSoft = false;

  let previousEntryFromHard = null;
  let previousEntryFromSoft = null;

  let hardHoursWithinProximity = 0;
  let hardHoursOutsideProximity = 0;

  let softHoursWithinProximity = 0;
  let softHoursOutsideProximity = 0;

  let hardDistanceWithinProximity = 0;
  let hardDistanceOutsideProximity = 0;

  let softDistanceWithinProximity = 0;
  let softDistanceOutsideProximity = 0;

  let suspiciousHoursLocal = [];

  const nextDateString = moment(date, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
  const nextDayEntries = jobHourDetails[nextDateString];
  const nextDayFirstEntry = nextDayEntries ? nextDayEntries[0] : null;

  // process entries of a day to get the total hours for that day
  const totalHours = dayHourDetails.reduce((total, entry, dayHoursindex) => {

    const markerCoords = {
      lat: entry.lat,
      lng: entry.lng
    };

    // nextEntryTrackType === TrackType.Location:
    // this check is due to wrong order of Checkin and Location tracktype 
    // to fix the order patch has been made when doing checkin : PATCH_CHECKIN_ORDER_TRACK_TYPE
    const nextEntry = dayHoursindex === (dayHourDetails.length - 1) ? null : dayHourDetails[dayHourDetails + 1];
    const nextEntryTrackType = nextEntry ? nextEntry.trackType : null;

    const isWithinProximity = task ? isWithinTrackingProximity(jobCoords, markerCoords, taskProximity) : false;
    const isCheckIn = entry.trackType === TrackType.CheckIn || entry.trackType === TrackType.Location || entry.trackType === TrackType.AppRestart;
    const isCheckOut = entry.trackType === TrackType.CheckOut || entry.trackType === TrackType.Done;

    // process soft track data
    if (entry.taskId === null) {

      console.log("processing soft entries...................");

      // process switching from hard to soft
      if (!isPreviousEntryFromSoft && previousEntryFromHard != null) {

        const timeDiff = timeDifference(previousEntryFromHard.modificationDate, entry.modificationDate);

        softHoursOutsideProximity += timeDiff;

        if (entry.trackType === TrackType.AppRestart) {
          suspiciousHoursLocal = [
            ...suspiciousHoursLocal,
            {
              datetime: entry.modificationDate,
              timeDiff: timeDiff,
              type: WorkHourType.SoftHourOutsideProximity,
            }
          ];
        }

        if (isJobDistanceType) {
          const distance = calculateDistance(
            { lat: previousEntryFromHard.lat, lng: previousEntryFromHard.lng },
            { lat: entry.lat, lng: entry.lng },
          ).distanceInMeter;
          softDistanceOutsideProximity += parseFloat(distance);
        }

        previousEntryFromSoft = entry;
      }

      if (isWithinProximity && isCheckIn) {
        if (previousEntryFromSoft != null) {

          const timeDiff = timeDifference(previousEntryFromSoft.modificationDate, entry.modificationDate);

          if (isPreviousEntryFromWithinProximity) {
            softHoursWithinProximity += timeDiff;

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.SoftHourWithinProximity,
                }
              ];
            }

            if (isJobDistanceType) {
              const distance = calculateDistance(
                { lat: previousEntryFromHard.lat, lng: previousEntryFromHard.lng },
                { lat: entry.lat, lng: entry.lng },
              ).distanceInMeter;
              softDistanceWithinProximity += parseFloat(distance);
            }

          }
          else {
            softHoursOutsideProximity += timeDiff;

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.SoftHourOutsideProximity,
                }
              ];
            }

            if (isJobDistanceType) {
              const distance = calculateDistance(
                { lat: previousEntryFromHard.lat, lng: previousEntryFromHard.lng },
                { lat: entry.lat, lng: entry.lng },
              ).distanceInMeter;
              softDistanceOutsideProximity += parseFloat(distance);
            }

          }
        }

        // last entry of day is checkin within proximity and day is not last
        if (dayHoursindex === dayHourDetails.length - 1 && dayIndex !== jobHourDetailsKeysLength - 1) {

          const timeDiff = timeDifferenceTillMidnight(entry.modificationDate);

          softHoursWithinProximity += timeDiff;

          if (entry.trackType === TrackType.AppRestart) {
            suspiciousHoursLocal = [
              ...suspiciousHoursLocal,
              {
                datetime: entry.modificationDate,
                timeDiff: timeDiff,
                type: WorkHourType.SoftHourWithinProximity,
              }
            ];
          }

          if (isJobDistanceType && nextDayFirstEntry) {
            const distance = calculateDistance(
              { lat: entry.lat, lng: entry.lng },
              { lat: nextDayFirstEntry.lat, lng: nextDayFirstEntry.lng },
            ).distanceInMeter;
            softDistanceWithinProximity += parseFloat(distance);
          }

        }

        // nextEntryTrackType === TrackType.Location:
        // this check is due to wrong order of Checkin and Location tracktype 
        // to fix the order patch has been made when doing checkin : PATCH_CHECKIN_ORDER_TRACK_TYPE


        // first entry of day is location within proximity and day is not first
        if (dayIndex !== 0 && dayHoursindex === 0 && (entry.trackType === TrackType.Location && nextEntryTrackType === TrackType.Location)) {

          const timeDiff = timeDifferenceFromMidnight(entry.modificationDate);

          softHoursWithinProximity += timeDifferenceFromMidnight(entry.modificationDate);

          if (entry.trackType === TrackType.AppRestart) {
            suspiciousHoursLocal = [
              ...suspiciousHoursLocal,
              {
                datetime: entry.modificationDate,
                timeDiff: timeDiff,
                type: WorkHourType.SoftHourWithinProximity,
              }
            ];
          }

        }

        previousEntryFromSoft = entry;
        isPreviousEntryFromWithinProximity = true;
      }
      else {
        if (previousEntryFromSoft != null) {

          const timeDiff = timeDifference(previousEntryFromSoft.modificationDate, entry.modificationDate);

          if (isPreviousEntryFromWithinProximity) {

            if (!(previousEntryFromSoft.trackType === TrackType.CheckOut || previousEntryFromSoft.trackType === TrackType.Done)) {
              softHoursWithinProximity += timeDiff;
            }

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.SoftHourWithinProximity,
                }
              ];
            }

            if (isJobDistanceType) {
              const distance = calculateDistance(
                { lat: previousEntryFromHard.lat, lng: previousEntryFromHard.lng },
                { lat: entry.lat, lng: entry.lng },
              ).distanceInMeter;
              softDistanceWithinProximity += parseFloat(distance);
            }

          }
          else {

            if (!(previousEntryFromSoft.trackType === TrackType.CheckOut || previousEntryFromSoft.trackType === TrackType.Done)) {
              softHoursOutsideProximity += timeDiff;
            }

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.SoftHourOutsideProximity,
                }
              ];
            }

            if (isJobDistanceType) {
              const distance = calculateDistance(
                { lat: previousEntryFromHard.lat, lng: previousEntryFromHard.lng },
                { lat: entry.lat, lng: entry.lng },
              ).distanceInMeter;
              softDistanceOutsideProximity += parseFloat(distance);
            }

          }
        }

        // first entry of day is checkout but day should not be first
        if (dayHoursindex === 0 && dayIndex !== 0 && isCheckOut) {

          const timeDiff = timeDifferenceFromMidnight(entry.modificationDate);

          //  user is within proximity but due to checkout, code is here
          if (isWithinProximity) {
            softHoursWithinProximity += timeDiff;

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.SoftHourWithinProximity,
                }
              ];
            }

            if (isJobDistanceType) {
              const distance = calculateDistance(
                { lat: previousEntryFromHard.lat, lng: previousEntryFromHard.lng },
                { lat: entry.lat, lng: entry.lng },
              ).distanceInMeter;
              softDistanceWithinProximity += parseFloat(distance);
            }

          }
          else {
            softHoursOutsideProximity += timeDiff;

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.SoftHourOutsideProximity,
                }
              ];
            }

            if (isJobDistanceType) {
              const distance = calculateDistance(
                { lat: previousEntryFromHard.lat, lng: previousEntryFromHard.lng },
                { lat: entry.lat, lng: entry.lng },
              ).distanceInMeter;
              softDistanceOutsideProximity += parseFloat(distance);
            }

          }
        }

        // last entry of day is checkin outside proximity and day is not last
        if (dayHoursindex === dayHourDetails.length - 1 && dayIndex !== jobHourDetailsKeysLength - 1 && isCheckIn) {

          const timeDiff = timeDifferenceTillMidnight(entry.modificationDate);

          softHoursOutsideProximity += timeDiff;

          if (entry.trackType === TrackType.AppRestart) {
            suspiciousHoursLocal = [
              ...suspiciousHoursLocal,
              {
                datetime: entry.modificationDate,
                timeDiff: timeDiff,
                type: WorkHourType.SoftHourOutsideProximity,
              }
            ];
          }

          if (isJobDistanceType && nextDayFirstEntry) {
            const distance = calculateDistance(
              { lat: entry.lat, lng: entry.lng },
              { lat: nextDayFirstEntry.lat, lng: nextDayFirstEntry.lng },
            ).distanceInMeter;
            softDistanceOutsideProximity += parseFloat(distance);
          }

        }

        previousEntryFromSoft = entry;
        isPreviousEntryFromWithinProximity = false;
      }

      previousEntryFromHard = null;
      isPreviousEntryFromSoft = true;
    }
    // process hard track data
    else {
      // process swicthing from soft to hard
      if (isPreviousEntryFromSoft) {

        const timeDiff = timeDifference(previousEntryFromSoft.modificationDate, entry.modificationDate);

        if (isWithinProximity) {
          softHoursWithinProximity += timeDiff;

          if (entry.trackType === TrackType.AppRestart) {
            suspiciousHoursLocal = [
              ...suspiciousHoursLocal,
              {
                datetime: entry.modificationDate,
                timeDiff: timeDiff,
                type: WorkHourType.SoftHourWithinProximity,
              }
            ];
          }

          if (isJobDistanceType) {
            const distance = calculateDistance(
              { lat: previousEntryFromHard.lat, lng: previousEntryFromHard.lng },
              { lat: entry.lat, lng: entry.lng },
            ).distanceInMeter;
            softDistanceWithinProximity += parseFloat(distance);
          }

        }
        else {
          softHoursOutsideProximity += timeDiff;

          if (entry.trackType === TrackType.AppRestart) {
            suspiciousHoursLocal = [
              ...suspiciousHoursLocal,
              {
                datetime: entry.modificationDate,
                timeDiff: timeDiff,
                type: WorkHourType.SoftHourOutsideProximity,
              }
            ];
          }

          if (isJobDistanceType) {
            const distance = calculateDistance(
              { lat: previousEntryFromHard.lat, lng: previousEntryFromHard.lng },
              { lat: entry.lat, lng: entry.lng },
            ).distanceInMeter;
            softDistanceOutsideProximity += parseFloat(distance);
          }

        }
        previousEntryFromHard = entry;
      }
      // normal processing of hard track data
      else {
        if (isWithinProximity && isCheckIn) {
          if (previousEntryFromHard != null) {

            const timeDiff = timeDifference(previousEntryFromHard.modificationDate, entry.modificationDate);

            if (isPreviousEntryFromWithinProximity) {
              hardHoursWithinProximity += timeDiff;

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.HardHourWithinProximity,
                  }
                ];
              }

              if (isJobDistanceType) {
                const distance = calculateDistance(
                  { lat: previousEntryFromHard.lat, lng: previousEntryFromHard.lng },
                  { lat: entry.lat, lng: entry.lng },
                ).distanceInMeter;
                hardDistanceWithinProximity += parseFloat(distance);
              }

            }
            else {
              hardHoursOutsideProximity += timeDiff;

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.HardHourOutsideProximity,
                  }
                ];
              }

              if (isJobDistanceType) {
                const distance = calculateDistance(
                  { lat: previousEntryFromHard.lat, lng: previousEntryFromHard.lng },
                  { lat: entry.lat, lng: entry.lng },
                ).distanceInMeter;
                hardDistanceWithinProximity += parseFloat(distance);
              }

            }
          }

          // last entry of day is checkin within proximity and day is not last
          if (dayHoursindex === dayHourDetails.length - 1 && dayIndex !== jobHourDetailsKeysLength - 1) {
            const timeDiff = timeDifferenceTillMidnight(entry.modificationDate);
            hardHoursWithinProximity += timeDiff;

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.HardHourWithinProximity,
                }
              ];
            }

            if (isJobDistanceType && nextDayFirstEntry) {
              const distance = calculateDistance(
                { lat: entry.lat, lng: entry.lng },
                { lat: nextDayFirstEntry.lat, lng: nextDayFirstEntry.lng },
              ).distanceInMeter;
              hardDistanceWithinProximity += parseFloat(distance);
            }

          }

          // nextEntryTrackType === TrackType.Location:
          // this check is due to wrong order of Checkin and Location tracktype 
          // to fix the order patch has been made when doing checkin : PATCH_CHECKIN_ORDER_TRACK_TYPE

          // first entry of day is location within proximity and day is not first
          if (dayIndex !== 0 && dayHoursindex === 0 && (entry.trackType === TrackType.Location && nextEntryTrackType === TrackType.Location)) {

            const timeDiff = timeDifferenceFromMidnight(entry.modificationDate);

            hardHoursWithinProximity += timeDiff;

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.HardHourWithinProximity,
                }
              ];
            }

          }

          previousEntryFromHard = entry;
          isPreviousEntryFromWithinProximity = true;
        }
        else {
          if (previousEntryFromHard != null) {

            const timeDiff = timeDifference(previousEntryFromHard.modificationDate, entry.modificationDate);

            if (isPreviousEntryFromWithinProximity) {

              if (!(previousEntryFromHard.trackType === TrackType.CheckOut || previousEntryFromHard.trackType === TrackType.Done)) {
                hardHoursWithinProximity += timeDiff;
              }

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.HardHourWithinProximity,
                  }
                ];
              }

              if (isJobDistanceType) {
                const distance = calculateDistance(
                  { lat: previousEntryFromHard.lat, lng: previousEntryFromHard.lng },
                  { lat: entry.lat, lng: entry.lng },
                ).distanceInMeter;
                hardDistanceWithinProximity += parseFloat(distance);
              }

            }
            else {

              if (!(previousEntryFromHard.trackType === TrackType.CheckOut || previousEntryFromHard.trackType === TrackType.Done)) {
                hardHoursOutsideProximity += timeDiff;
              }

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.HardHourOutsideProximity,
                  }
                ];
              }

              if (isJobDistanceType) {
                const distance = calculateDistance(
                  { lat: previousEntryFromHard.lat, lng: previousEntryFromHard.lng },
                  { lat: entry.lat, lng: entry.lng },
                ).distanceInMeter;
                hardDistanceOutsideProximity += parseFloat(distance);
              }

            }
          }

          // first entry of day is checkout but day should not be first
          if (dayHoursindex === 0 && dayIndex !== 0 && isCheckOut) {

            const timeDiff = timeDifferenceFromMidnight(entry.modificationDate);

            //  user is within proximity but due to checkout, code is here
            if (isWithinProximity) {
              hardHoursWithinProximity += timeDiff;

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.HardHourWithinProximity,
                  }
                ];
              }

            }
            else {
              hardHoursOutsideProximity += timeDiff;

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.HardHourOutsideProximity,
                  }
                ];
              }

            }
          }

          // last entry of day is checkin outside proximity and day is not last
          if (dayHoursindex === dayHourDetails.length - 1 && dayIndex !== jobHourDetailsKeysLength - 1 && isCheckIn) {
            const timeDiff = timeDifferenceTillMidnight(entry.modificationDate);

            hardHoursOutsideProximity += timeDiff;

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.HardHourOutsideProximity,
                }
              ];
            }

            if (isJobDistanceType && nextDayFirstEntry) {
              const distance = calculateDistance(
                { lat: entry.lat, lng: entry.lng },
                { lat: nextDayFirstEntry.lat, lng: nextDayFirstEntry.lng },
              ).distanceInMeter;
              hardDistanceOutsideProximity += parseFloat(distance);
            }

          }

          previousEntryFromHard = entry;
          isPreviousEntryFromWithinProximity = false;
        }
      }

      previousEntryFromSoft = null;
      isPreviousEntryFromSoft = false;
    }

    total = {
      hardHoursWithinProximity,
      hardHoursOutsideProximity,
      softHoursWithinProximity,
      softHoursOutsideProximity,

      hardDistanceWithinProximity,
      hardDistanceOutsideProximity,
      softDistanceWithinProximity,
      softDistanceOutsideProximity,
    };
    return total;

  }, {});

  // sum up the total hours
  // totalHoursWithinProximityTemp += totalHours.hardHoursWithinProximity + totalHours.softHoursWithinProximity;
  // totalHoursOutsideProximityTemp += totalHours.hardHoursOutsideProximity + totalHours.softHoursOutsideProximity;


  // totalHardHoursWithinProximityTemp += totalHours.hardHoursWithinProximity;


  return {
    id: v4(),
    date,
    hourDetail: dayHourDetails,
    hardHoursWithinProximity: totalHours.hardHoursWithinProximity.toFixed(2),
    hardHoursOutsideProximity: totalHours.hardHoursOutsideProximity.toFixed(2),
    softHoursWithinProximity: totalHours.softHoursWithinProximity.toFixed(2),
    softHoursOutsideProximity: totalHours.softHoursOutsideProximity.toFixed(2),

    hardDistanceWithinProximity: totalHours.hardDistanceWithinProximity.toFixed(2),
    hardDistanceOutsideProximity: totalHours.hardDistanceOutsideProximity.toFixed(2),
    softDistanceWithinProximity: totalHours.softDistanceWithinProximity.toFixed(2),
    softDistanceOutsideProximity: totalHours.softHoursOutsideProximity.toFixed(2),

    suspiciousHours: suspiciousHoursLocal,

  };

};