import { makeStyles, Tooltip } from '@material-ui/core';
import React, { Fragment } from 'react';

function TooltipCNO({
  children,
  title,
  placement = "right",
  arrow = true,
  ...rest
}) {

  const styles = useStyles();

  return (
    <Fragment>
      <Tooltip
        title={title}
        arrow={arrow}
        placement={placement}
        classes={{
          tooltip: styles.tooltip,
          arrow: styles.arrow
        }}
        {...rest}
      >
        {children}
      </Tooltip>
    </Fragment>
  )
}

export default TooltipCNO

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: '1.1rem',
    background: '#9b4b0e'
  },
  arrow: {
    color: "#9b4b0e"
  }
}))
