import React from "react";
import ReactGiphySearchbox from "react-giphy-searchbox";
import { makeStyles } from "@material-ui/core";

const GifGenerator = ({ onGifSelect, setOpenGif }) => {
  const API_KEY = "Ikpj2VHGNfbDSRo9MHD531FJF7OgSMCr";
  const styles = useStyles();

  return (
    <div className={styles.searchboxWrapper}>
      <ReactGiphySearchbox
        apiKey={API_KEY}
        onSelect={(item) => {
          console.log("gif", item.images.original.url);
          onGifSelect(item.images.original.url);
          setOpenGif(false);
        }}
        masonryConfig={[
          { columns: 2, imageWidth: 200, gutter: 5 },
          { mq: "1000px", columns: 1, imageWidth: 720, gutter: 5 },
        ]}
        // masonryConfig={[{ columns: 4, imageWidth: "800px", gutter: 5 }]}
      />
    </div>
  );
};

export default GifGenerator;

const useStyles = makeStyles(() => ({
  searchboxWrapper: {
    width: "100%",
    maxWidth: "100vw",
    height: "100%",
    margin: "0 auto",
    padding: "20px",
    boxSizing: "border-box",
    overflow: "hidden",

    // flex: 1,
    // display: "flex",
    // flexDirection: "column",
  },
  wrapper: {
    width: "100%",
    overflowX: "hidden",
  },
}));
