import Icon from '@mdi/react'
import React from 'react'
import { getMaterialIconString } from '../../utils/icons'
import * as icons from '@mdi/js';
import { useTheme } from '@material-ui/core';

function IconMain({ name, size = '1.5rem', color, themeColorKey = "ICON_COLOR", title, className, style, onClick, ...rest }) {

  const pathString = getMaterialIconString(name);

  const theme = useTheme();

  return (
    <Icon
      path={icons[pathString]}
      size={size}
      color={color || theme.colors[themeColorKey]}
      title={title}
      className={className}
      style={style}
      onClick={onClick}
      {...rest}
    />
  )
}

export default IconMain