import { Chip, fade, makeStyles, TextField, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { resetSuggestions } from '../../actions/suggestionsActions';
import FieldHelperTextCNO from '../FieldHelperTextCNO';

function AutocompleteCNO({
  formik,
  className,
  name,
  label,
  data = [],
  onSuggestionClick,
  onChange,
  optionLabel,
  renderOption,
  emptyValue = "",
  multiple = false,
  renderTags,
  isRequired = false,
  disabled = false,
  triggerSearchOnEnter = false,
  ...rest
}) {

  const theme = useTheme();
  const styles = useStyles({ disabled });

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setValue(formik?.values[name]);
    return () => {
      setValue("");
    };
  }, [formik?.values[name]]);

  console.log("onSuggestionClick: formik?.values[name]: ", formik?.values[name]);
  console.log("dataOnPeak",data)
  const unSelectedSuggestions = () => data.filter(item => !value.some(assign => assign.id === item.id));

  if (!name) return null;

  return (
    <div className={className} style={{ width: '100%' }} >
      <Typography component='label' htmlFor={name} className={styles.label}>
        {label}
        <span style={{ color: theme.colors.ERROR_COLOR }}>{isRequired ? ' *' : null}</span>
      </Typography>

      <Autocomplete
        typeof="search"
        multiple={multiple}
        forcePopupIcon={false}
        open={open}
        className={styles.textField}
        classes={{
          paper: styles.paper,
          noOptions: styles.noOptions,
          popper: styles.popper,
          option: styles.option
        }}
        name={name}
        value={value}
        onChange={(e, newValue) => {
          formik?.setFieldValue(name, newValue || "");
          setValue(newValue)
          onSuggestionClick && onSuggestionClick(multiple ? newValue : { ...newValue, enterKey: false });
        }}
        onInputChange={(e) => {
          (onChange && e) && onChange(e.target.value);
          if (e && e.target.value) {
            setOpen(true);
          }
          else {
            setOpen(false);
          }
        }}
        onKeyDown={(e) => {

          if (multiple) return;

          if ((e.code === 'Enter' || e.code === 'NumpadEnter') && e.target.value) {

            e.preventDefault();

            if (triggerSearchOnEnter) {

              const value = { title: e.target.value };

              formik?.setFieldValue(name, value || "");
              setValue(value)
              onSuggestionClick && onSuggestionClick({ ...value, enterKey: true });

              setOpen(false);
              dispatch(resetSuggestions());

            }

          }
        }}
        fullWidth
        onBlur={() => {
          setOpen(false);
          dispatch(resetSuggestions());
        }}
        options={multiple ? unSelectedSuggestions() : data}
        margin="dense"
        getOptionLabel={optionLabel ? optionLabel : x => (x.title || x.label || "")}
        renderOption={renderOption}
        noOptionsText="No data found.."
        {...multiple ? {
          renderTags: renderTags ? renderTags : (value, getTagProps) => {
            return value.map((option, index) => {
              return (
                <Chip
                  key={option.id}
                  variant="outlined"
                  label={option.title}
                  {...getTagProps({ index })}
                  style={{ background: theme.palette.background.light }}
                />
              )
            })
          }
        } : {}}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" {...rest} />
        )}
        disabled={disabled}
      />

      <FieldHelperTextCNO
        showHelperText={formik && Boolean(formik.errors[name])}
        helperText={formik.errors[name]}
        containerStyle={{ marginTop: 5 }}
      />
    </div>
  )
}

export default AutocompleteCNO

const useStyles = makeStyles(theme => ({
  option: {
    padding: 8,
    borderBottom: '1px solid #eee',
    '&:hover': {
      background: '#eeeeee'
    },
  },
  label: {
    display: 'block',
    marginBottom: theme.spacing(1),
    color: theme.colors.FORM_LABEL_COLOR
  },
  textField: {
    borderRadius: 5,
    '& input': {
      background: ({ disabled }) => disabled ? fade(theme.colors.INPUT_BACKGROUND_COLOR, 0.8) : theme.colors.INPUT_BACKGROUND_COLOR,
      color: '#444',
      Radius: 5,
      padding: 8,
      opacity: ({ disabled }) => disabled ? 0.8 : 1,
      color: ({ disabled }) => disabled ? fade(theme.colors.INPUT_TEXT_COLOR, 0.8) : (theme.colors.INPUT_TEXT_COLOR),
    },
    '& .MuiOutlinedInput-multiline': {
      color: '#444',
      opacity: ({ disabled }) => disabled ? 0.8 : 1,
      color: ({ disabled }) => disabled ? fade(theme.colors.INPUT_TEXT_COLOR, 0.8) : (theme.colors.INPUT_TEXT_COLOR),
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": "#444",
      caretColor: "#444",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 14
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0
    },
    '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      padding: 0
    }
  },
  paper: {
    background: 'white !important',
    '& ::-webkit-scrollbar': {
      display: 'none'
    }
  },
  noOptions: {
    color: theme.colors.ERROR_COLOR
  },
  popper: {
    zIndex: 1301,
    padding: 0
  },
  error: {
    color: theme.colors.ERROR_COLOR,
    fontSize: "0.8rem",
    marginLeft: 5,
    marginTop: 5
  }
}))