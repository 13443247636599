import { SET_DYNAMIC_FORMS_DATA, SET_FORMS, SET_FORMS_IN_MENUS, SET_FORMS_LOADER, SET_FORM_BY_ID, SET_FORM_SEARCH_PARAMS, TOGGLE_ADD_DYNAMIC_DATA_FORMS_DIALOG } from "../actions/formBuilderActions";
import { AddDynamicFormsDataMode, FormStatus } from "../features/formBuilder/utils/enums";

const initialState = {

  forms: [],
  formById: null,
  formsInMenu: [],

  dynamicFormsData: [],

  formsLoader: false,
  formByIdLoader: false,
  addFormLoader: false,
  formsInMenuLoader: false,

  dynamicFormsDataLoader: false,
  addDynamicFormsDataLoader: false,
  addDynamicFormsDataInitialValues: {},

  isAddDynamicFormsDataDialog: false,
  addDynamicFormsDataMode: AddDynamicFormsDataMode.ids.View,

  formSearchParams: {
    status: FormStatus.id.Draft
  }

}

export const formBuilderReducer = (state = initialState, { type, payload }) => {

  switch (type) {

    case SET_FORMS: {
      return {
        ...state,
        forms: payload
      }
    }

    case SET_FORM_BY_ID: {
      return {
        ...state,
        formById: payload
      }
    }

    case SET_FORMS_LOADER: {

      const { loaderName, loaderState } = payload;

      return {
        ...state,
        [loaderName]: loaderState
      }
    }

    case SET_FORM_SEARCH_PARAMS: {
      return {
        ...state,
        formSearchParams: payload
      }
    }

    case SET_FORMS_IN_MENUS: {
      return {
        ...state,
        formsInMenu: payload
      }
    }

    case SET_DYNAMIC_FORMS_DATA: {
      return {
        ...state,
        dynamicFormsData: payload
      }
    }

    case TOGGLE_ADD_DYNAMIC_DATA_FORMS_DIALOG: {
      return {
        ...state,
        isAddDynamicFormsDataDialog: payload.state,
        addDynamicFormsDataMode: payload.mode || AddDynamicFormsDataMode.ids.View,
        addDynamicFormsDataInitialValues: payload.formikValues || {},
      }
    }

    default:
      return state;

  }

}
