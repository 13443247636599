import TrackingScreenLight from '../../../assets/image/LandingPage/checkndout/TrackingScreenLight.png';
import TrackWorkHoursLight from '../../../assets/image/LandingPage/checkndout/TrackWorkHoursLight.png';
import TrackPayModalLight from '../../../assets/image/LandingPage/checkndout/TrackPayModalLight.png';
import JobSearchLight from '../../../assets/image/LandingPage/checkndout/JobSearchLight.png';
import JobListingWithActionsLight from '../../../assets/image/LandingPage/checkndout/JobListingWithActionsLight.png';
import ChatMessagesLight from '../../../assets/image/LandingPage/checkndout/ChatMessagesLight.png';
import DrawerLight from '../../../assets/image/LandingPage/checkndout/DrawerLight.png';
import InvoiceDetailsLight from '../../../assets/image/LandingPage/checkndout/InvoiceDetailsLight.png';
import VideoCallLight from '../../../assets/image/LandingPage/checkndout/VideoCallLight.png';
import CalendarAppointmentsLight from '../../../assets/image/LandingPage/checkndout/CalendarAppointmentsLight.png';
import AppointmentListingWithActionsLight from '../../../assets/image/LandingPage/checkndout/AppointmentListingWithActionsLight.png';
import AddJobLight from '../../../assets/image/LandingPage/checkndout/AddJobLight.png';
import ChatListingLight from '../../../assets/image/LandingPage/checkndout/ChatListingLight.png';
import InvoiceListingWithActionsLight from '../../../assets/image/LandingPage/checkndout/InvoiceListingWithActionsLight.png';
import QuotationListingWithActionsLight from '../../../assets/image/LandingPage/checkndout/QuotationListingWithActionsLight.png';
import QuotationDetailsLight from '../../../assets/image/LandingPage/checkndout/QuotationDetailsLight.png';
import QuotationAmountSummaryLight from '../../../assets/image/LandingPage/checkndout/QuotationAmountSummaryLight.png';

import AudiCallDark from '../../../assets/image/LandingPage/checkndout/AudiCallDark.png';
import InvoicePreview from '../../../assets/image/LandingPage/checkndout/InvoicePreview.png';
import QuotationPreview from '../../../assets/image/LandingPage/checkndout/QuotationPreview.png';

import TrackingScreenDark from '../../../assets/image/LandingPage/checkndout/TrackingScreenDark.png';
import TrackWorkHoursDark from '../../../assets/image/LandingPage/checkndout/TrackWorkHoursDark.png';
import TrackPayModalDark from '../../../assets/image/LandingPage/checkndout/TrackPayModalDark.png';
import JobSearchModalDark from '../../../assets/image/LandingPage/checkndout/JobSearchModalDark.png';
import JobListingWithActionsDark from '../../../assets/image/LandingPage/checkndout/JobListingWithActionsDark.png';
import ChatMessagesDark from '../../../assets/image/LandingPage/checkndout/ChatMessagesDark.png';
import DrawerDark from '../../../assets/image/LandingPage/checkndout/DrawerDark.png';
import InvoiceDetailsDark from '../../../assets/image/LandingPage/checkndout/InvoiceDetailsDark.png';
import VideoCallDark from '../../../assets/image/LandingPage/checkndout/VideoCallDark.png';
import CalendarAppointmentsDark from '../../../assets/image/LandingPage/checkndout/CalendarAppointmentsDark.png';
import AppointmentListingWithActionsDark from '../../../assets/image/LandingPage/checkndout/AppointmentListingWithActionsDark.png';
import AddJobDark from '../../../assets/image/LandingPage/checkndout/AddJobDark.png';
import ChatListingDark from '../../../assets/image/LandingPage/checkndout/ChatListingDark.png';
import InvoiceListingWithActionsDark from '../../../assets/image/LandingPage/checkndout/InvoiceListingWithActionsDark.png';
import QuotationListingWithActionsDark from '../../../assets/image/LandingPage/checkndout/QuotationListingWithActionsDark.png';
import QuotationAmountSummaryDark from '../../../assets/image/LandingPage/checkndout/QuotationAmountSummaryDark.png';
import QuotationDetailsDark from '../../../assets/image/LandingPage/checkndout/QuotationDetailsDark.png';

import { mdiCalendarCheck, mdiCashMultiple, mdiChartBar, mdiCheckboxMarkedCircleOutline, mdiClipboardAccountOutline, mdiHistory, mdiMap, mdiMapMarkerPath, mdiMapMarkerRadiusOutline, mdiNotebookOutline, mdiTimelineClockOutline } from '@mdi/js';

const data = {
  features: [
    {
      id: 1,
      darkThemeImage: TrackingScreenDark,
      lightThemeImage: TrackingScreenLight,
      title: 'Live Tracking',
      iconName: mdiMapMarkerRadiusOutline,
      description: 'Keep real-time track of your workforce attendance, location, distance traveled, total productive hours at your palm from anywhere, anytime.',
      bgColor: "#e8dfd4",
      iconColor: "#fe940b",
      isPartial: false,
    },
    {
      id: 2,
      darkThemeImage: CalendarAppointmentsDark,
      lightThemeImage: CalendarAppointmentsLight,
      title: 'Meeting Notes',
      iconName: mdiNotebookOutline,
      description: 'Customizable forms will give credible information about your team’s activities in real-time while they check-in or check out from a customer’s location.',
      bgColor: "#d4e4dd",
      iconColor: "#57d39f",
      isPartial: false,
    },
    {
      id: 3,
      darkThemeImage: AddJobDark,
      lightThemeImage: AddJobLight,
      isPartial: true,
    },
    {
      id: 4,
      darkThemeImage: JobListingWithActionsDark,
      lightThemeImage: JobListingWithActionsLight,
      title: 'Timeline',
      iconName: mdiTimelineClockOutline,
      description: 'Glimpse each member’s entire day activity in chronological order with the timestamp.',
      bgColor: "#d6dde6",
      iconColor: "#458be8",
      isPartial: false,
    },

    {
      id: 5,
      darkThemeImage: JobSearchModalDark,
      lightThemeImage: JobSearchLight,
      title: 'Task Management',
      iconName: mdiClipboardAccountOutline,
      description: "Assign location-based tasks so that you can measure your on-field team's punctuality and productivity while visiting a customer.",
      bgColor: "#d9e3d8",
      iconColor: "#5eca59",
      isPartial: false,
    },

    {
      id: 6,
      darkThemeImage: ChatListingDark,
      lightThemeImage: ChatListingLight,
      isPartial: true,
    },

    {
      id: 7,
      darkThemeImage: ChatMessagesDark,
      lightThemeImage: ChatMessagesLight,
      title: 'Daily Status',
      iconName: mdiChartBar,
      description: 'Get the detailed status of your field employee’s visits on daily basis with live location & attachments. Download reports as per your requirement for follow-up actions.',
      bgColor: "#e8dddd",
      iconColor: "#fe7373",
      isPartial: false,
    },
    {
      id: 8,
      darkThemeImage: AudiCallDark,
      lightThemeImage: AudiCallDark,
      isPartial: true,
    },
    {
      id: 9,
      darkThemeImage: VideoCallDark,
      lightThemeImage: VideoCallLight,
      title: 'Route Plan',
      iconName: mdiMapMarkerPath,
      description: 'Check the daily Route Plan of your field employees with their attendance & get a quick view of where they will go throughout the day.',
      bgColor: "#e8dbdf",
      iconColor: "#ff588b",
      isPartial: false,
    },
    {
      id: 10,
      darkThemeImage: DrawerDark,
      lightThemeImage: DrawerLight,
      title: 'Task & Targets',
      originalAlt: 'Task & Targets',
      thumbnailAlt: 'Task & Targets',
      iconName: mdiCheckboxMarkedCircleOutline,
      description: 'The manager can map a list of assigned targets completed by his field staff & can also assign, monitor and verify the daily task performance of his agents.',
      bgColor: "#e8dfd4",
      iconColor: "#fe940b",
      isPartial: false,
    },
    {
      id: 11,
      darkThemeImage: InvoiceListingWithActionsDark,
      lightThemeImage: InvoiceListingWithActionsLight,
      isPartial: true,
    },
    {
      id: 12,
      darkThemeImage: InvoiceDetailsDark,
      lightThemeImage: InvoiceDetailsLight,
      title: 'Live Track',
      iconName: mdiMap,
      description: 'The manager can track his sales employees with their live location on the map while they are on the field & the employee will highlight with start and endpoint.',
      bgColor: "#d4e4dd",
      iconColor: "#57d39f",
      isPartial: false,
    },
    {
      id: 13,
      darkThemeImage: InvoicePreview,
      lightThemeImage: InvoicePreview,
      isPartial: true,
    },
    {
      id: 14,
      darkThemeImage: TrackWorkHoursDark,
      lightThemeImage: TrackWorkHoursLight,
      title: "History",
      iconName: mdiHistory,
      description: 'Admin can count all visited & non-visited appointments of his sales employees With check-in & check-out time and can export reports on a daily or monthly basis.',
      bgColor: "#e8dddd",
      iconColor: "#fe7373",
      isPartial: false,
    },
    {
      id: 15,
      darkThemeImage: TrackPayModalDark,
      lightThemeImage: TrackPayModalLight,
      title: 'Allowances',
      iconName: mdiCashMultiple,
      description: 'Receive sales agent’s travel expenses, compensation claim and approve or reject in real-time. Also, download the reports to review their all visits.',
      bgColor: "#d6dde6",
      iconColor: "#458be8",
      isPartial: false,
    },
    {
      id: 16,
      darkThemeImage: AppointmentListingWithActionsDark,
      lightThemeImage: AppointmentListingWithActionsLight,
      title: "Today's Appointments",
      iconName: mdiCalendarCheck,
      description: 'Admin & Sub-admin both can check all follow-ups raised by their field staff on a particular date and on daily basis.',
      bgColor: "#d9e3d8",
      iconColor: "#5eca59",
      isPartial: false,
    },
    {
      id: 17,
      darkThemeImage: QuotationListingWithActionsDark,
      lightThemeImage: QuotationListingWithActionsLight,
      isPartial: true,
    },
    {
      id: 18,
      darkThemeImage: QuotationDetailsDark,
      lightThemeImage: QuotationDetailsLight,
      isPartial: true,
    },
    {
      id: 19,
      darkThemeImage: QuotationAmountSummaryDark,
      lightThemeImage: QuotationAmountSummaryLight,
      isPartial: true,
    },
    {
      id: 20,
      darkThemeImage: QuotationPreview,
      lightThemeImage: QuotationPreview,
      isPartial: true,
    },

  ],
};
export default data;
