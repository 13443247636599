import { CircularProgress, Typography, useTheme } from "@material-ui/core";
import debounce from "lodash.debounce";
import React, { Fragment, useEffect, useMemo, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import JobCardSkeleton from "../components/JobCardSkeleton/JobCardSkeleton";
import { useElementDimensions } from "../hooks";
import { useGlobalStyles } from "../utils/Styles/GlobalStyles";
import ListCTAButtons from "../components/ListCTAButtons";
import Icon from "@mdi/react";
import StickyWrapper from "./StickyWrapper";
import { EventsFilterBar } from "../pages/EventManagement/components";
import TestGridViewListing from "../common/components/GridListToggle/TestGridViewListing";
import { useSelector } from "react-redux";

function TestInfiniteScrollCNO({
  items = [],
  next,
  hasMore,
  loading,
  moreLoader,
  containerStyle = {},
  infiniteScrollStyle = {},
  renderListItem,
  containerRef,
  renderLoader,
  notFoundTextMessage = "Item not found.",
  notFoundIcon,
  renderNotFound,
  showListCTAButtons = false,
  listCTAButtonsProps = { addTitle: "Add", exploreId: 1, onClickAdd: null },
  isGridView,
  setIsGridView,
  isJobs,
  isPosts,
  ...rest
}) {
  const { hiddenPostsList, deletedPostsList, showHiddenPosts } = useSelector(
    (state) => state.posts
  );

  const filteredItems = isPosts
    ? items.filter((item) => {
        // Always filter out deleted posts
        const isDeleted = deletedPostsList.includes(item.id);
        // Filter out hidden posts only if showHiddenPosts is false
        const isHidden = !showHiddenPosts && hiddenPostsList.includes(item.id);

        return !isDeleted && !isHidden;
      })
    : items;
  console.log("filteredItems", filteredItems);
  const _renderLoader = () => {
    if (!renderLoader) {
      return (
        <Fragment>
          {Array.from({ length: 10 }).map((_, index) => (
            <JobCardSkeleton key={index} />
          ))}
        </Fragment>
      );
    }

    return renderLoader;
  };

  const theme = useTheme();
  const _renderNotFound = () => {
    if (!renderNotFound) {
      return (
        <Fragment>
          <div
            className={GlobalStyles.listingNotFound}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {notFoundIcon && (
              <Icon
                path={notFoundIcon}
                size="4rem"
                color={theme.colors.LIST_CARD_USERNAMES_COLOR}
              />
            )}
            <Typography style={{ fontSize: "1.2rem" }}>
              {notFoundTextMessage}
            </Typography>
          </div>
          {showListCTAButtons && (
            <ListCTAButtons
              addTitle={listCTAButtonsProps.addTitle}
              onClickAdd={listCTAButtonsProps.onClickAdd}
              exploreId={listCTAButtonsProps.exploreId}
            />
          )}
        </Fragment>
      );
    }

    return renderNotFound;
  };

  const GlobalStyles = useGlobalStyles();

  const { height: elHeight = 0 } = useElementDimensions();

  const parentRef = useRef(null);
  const scrollableRef = useRef(null);

  useEffect(() => {
    parentRef.current = containerRef
      ? containerRef.current
      : { offsetHeight: elHeight };

    return () => {
      parentRef.current = null;
    };
  }, [elHeight, containerRef]);

  const handleResize = () => {
    if (!parentRef.current || !scrollableRef.current) return;

    if (
      scrollableRef.current.offsetHeight < parentRef.current.offsetHeight &&
      typeof next === "function"
    ) {
      next();
    }
  };

  const debouncedHandleResize = useMemo(() => debounce(handleResize, 3000), []);

  useEffect(() => {
    if (!parentRef.current || !scrollableRef.current) return;

    debouncedHandleResize();

    window.addEventListener("resize", debouncedHandleResize);
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, [parentRef, scrollableRef, debouncedHandleResize]);
  console.log("SpacialPosts", items);
  return (
    <div ref={scrollableRef} style={containerStyle}>
      {loading ? _renderLoader() : null}

      {!filteredItems.length ? _renderNotFound() : null}
      {console.log("filteredItems", filteredItems)}

      {filteredItems.length && !loading ? (
        isGridView ? (
          <TestGridViewListing posts={filteredItems} isJobs />
        ) : (
          <InfiniteScroll
            dataLength={filteredItems.length}
            next={next}
            hasMore={hasMore}
            loader={
              moreLoader || (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress />
                </div>
              )
            }
            style={{
              overflow: "none",
              display: "flex",
              alignItems: "center",
              gap: 4,
              flexWrap: "wrap",
              ...infiniteScrollStyle,
            }}
            {...rest}
          >
            {filteredItems.map(renderListItem)}
          </InfiniteScroll>
        )
      ) : null}
    </div>
  );
}

export default TestInfiniteScrollCNO;
