import { Box, useTheme } from '@material-ui/core';
import React from 'react'
import { DocumentUploadCNO } from '../../../library';
import { mdiFilePlus } from '@mdi/js';

const PostMediaPicker = ({ onMediaSelect ,setLoaders,mediaDisabled}) => {

  const handleMediaUpload = async (files) => {

    let mediasToUpload = [];

    if (files && files.length) {
      mediasToUpload = files;
    }

    typeof onMediaSelect === 'function' && onMediaSelect(mediasToUpload);

  };

  const theme = useTheme();

  return (
    <Box>

      <DocumentUploadCNO
        pickerIcon={{
          icon: mdiFilePlus,
          size: '1.5rem',
          color: theme.colors.IMAGE_UPLOAD_ICON_COLOR
        }}
        multiple={true}
        onUpload={async (files) => {

          console.log("====onUpload====files====", files);

          handleMediaUpload(files);

        }}
        pickerOptions={{
          isImage: true,
          isVideo: true,
          isDocument: false,
        }}
        mediaDisabled={mediaDisabled}
      />

    </Box>
  )
}

export default PostMediaPicker