import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import {
  mdiAccountDetails,
  mdiDelete
} from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import LinkCNO from '../../library/LinkCNO';
import RightsManager from '../../utils/RightsManager';
import DialogCNO from '../../library/DialogCNO';
import FormActionButtons from '../FormActionButtons';
import { deleteCompany } from '../../actions/customerCompanyActions';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';

function CompanyListCardPopover({ profile, closeMoreActions }) {

  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const dispatch = useDispatch();

  const [isDeactivateProfile, setIsDeactivateProfile] = useState(false);

  const removeCompany = () => {

    const removeCompanyPayload = {
      id: profile.id,
    };

    dispatch(deleteCompany(removeCompanyPayload));
    setIsDeactivateProfile(false);
  };

  return (
    <div className={styles.container_MorePopover}>
      <List className={styles.list}>

        <LinkCNO
          to={`company/${profile.id}`}
        >
          <ListItem className={GlobalStyles.listItem}>
            <ListItemText primary='Details' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiAccountDetails} size='1.5rem' color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        </LinkCNO>

        {
          isUserAdmin && profile.isActive &&
          <ListItem className={GlobalStyles.listItem}>
            <ListItemText primary='Deactivate' onClick={() => setIsDeactivateProfile(true)} />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiDelete} size='1.5rem' color={theme.colors.ERROR_COLOR} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

      </List>

      {
        isDeactivateProfile &&
        <DialogCNO
          open={isDeactivateProfile}
          onClose={() => setIsDeactivateProfile(false)}
          dialogTitle="Delete Employee"
        >
          <Typography>Are you sure you want to delete company?</Typography>
          <FormActionButtons
            rightText="Deactivate"
            rightClick={removeCompany}
            leftClick={() => setIsDeactivateProfile(false)}
            closeMoreActions={closeMoreActions}
          />
        </DialogCNO>
      }

    </div>
  )
}

export default CompanyListCardPopover

const useStyles = makeStyles(theme => ({
  container_MorePopover: {
    padding: 5,
    overflow: 'hidden',
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR
  },
  list: {
    background: theme.colors.MORE_ACTIONS_OPTIONS_WRAPPER_BACKGROUND_COLOR,
    // background: theme.palette.background.dark,
    padding: 0
  },
  listItem: {
    background: theme.colors.MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 2,
    width: 200,
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.01)'
    }
  },
  listItemText: {
    textDecoration: 'none',
  }
}))