import React from 'react'
import { FormFieldsTypes } from '../../utils/enums'
import { InputCNO } from '../../../../library'
import { Box } from '@material-ui/core'
import FormFieldActions from '../FormFieldActions'

const InputFormRender = ({ field, formik, onEdit, onDelete }) => {
  return (
    <>
      {
        field.type === FormFieldsTypes.ids.Text &&
        <Box display='flex' alignItems='flex-start'>
          <InputCNO
            name={field.name}
            label={field.label}
            helperText={field.helperText}
            placeholder={`Enter ${field.label}`}
            formik={formik}
            containerStyles={{ width: '100%' }}
            onChange={null}
          />

          <FormFieldActions fieldInitialValues={field} onEdit={onEdit} onDelete={onDelete} />

        </Box>
      }
    </>
  )
}

export default InputFormRender