import { SET_NOTIFICATION_COUNT, READ_NOTIFICATION, SET_HAS_MORE, SET_NEW_NOTIFICATIONS, SET_NOTIFICATIONS } from "../actions/notificationActions";

export const initialNotifications = {
    allNotifications: [],
    notifications: [],
    notificationsCount: 0,
    isNotification: false,
    hasMore: true,
    notificationLoading: false,
};

export function notificationReducer(state = initialNotifications, { type, payload }) {

    switch (type) {

        case SET_NEW_NOTIFICATIONS:
            state = {
                ...state,
                notifications: [payload, ...state.notifications]
            };
            return state;

        case SET_NOTIFICATIONS:

            state = {
                ...state,
                notifications: payload
            };

            console.log("nnn- noty in actions: ", payload, state.notifications)
            return state;

        case READ_NOTIFICATION:
            state = {
                ...state,
                notifications: payload
            };
            return state;

        case SET_NOTIFICATION_COUNT:
            state = {
                ...state,
                notificationsCount: payload
            };
            console.log("nnn- noty in count: ", state.notifications);
            return state;

        case SET_HAS_MORE:
            state = {
                ...state,
                hasMore: payload
            };
            return state;


        default:
            return state;
    }
}