import { Box } from '@material-ui/core'
import React, { useState } from 'react'
import { AddFieldDialog } from '../dialogs'
import { IconMain } from '../../../library'

const FormFieldActions = ({ fieldInitialValues, onEdit, onDelete }) => {

  const [isEdit, setIsEdit] = useState(false);

  return (
    <>
      <Box display='flex' alignItems='center' ml={2} style={{ gap: 16 }} >
        <IconMain name='pencil' color='orange' onClick={() => setIsEdit(true)} />
        <IconMain name='trash-can' color='red' onClick={onDelete} />
      </Box>

      <AddFieldDialog
        open={isEdit}
        onClose={() => setIsEdit(false)}
        fieldInitialValues={fieldInitialValues}
        onAddField={onEdit}
      />
    </>
  )
}

export default FormFieldActions