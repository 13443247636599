import React, { useEffect, useState } from "react";
import {
  makeStyles,
  useMediaQuery,
  TextField,
  Slider,
} from "@material-ui/core";
import HeadingBar from "../HeadingBar/HeadingBar";
import ChipCNO from "../../library/ChipCNO";
import { mdiRestore, mdiClose } from "@mdi/js";
import { TOGGLE_SEARCH } from "../../utils/actions";
import { useGlobalStore } from "../../providers/GlobalProvider";
import { InputLabelCNO } from "../../library";

function TestSearchSidebar({ setSearchParams ,className }) {
  const tillTablet = useMediaQuery("(max-width: 767px)");
  const [categories, setCategories] = useState([]);
  const [localSearchParams, setLocalSearchParams] = useState({
    title: "",
    description: "",
    duration_minutes: [0, 100],
    categoryIds: [],
  });

  const { dispatchGlobal } = useGlobalStore();

  useEffect(() => {
    const storedCategories = JSON.parse(
      localStorage.getItem("testCategory")
    ) || { categories: [] };
    setCategories(storedCategories.categories);
  }, []);

  useEffect(() => {
    setSearchParams(localSearchParams);
  }, [localSearchParams, setSearchParams]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalSearchParams((prev) => ({ ...prev, [name]: value }));
  };

  const handleSliderChange = (e, newValue) => {
    setLocalSearchParams((prev) => ({ ...prev, duration_minutes: newValue }));
  };

  const handleCategoryClick = (categoryId) => {
    setLocalSearchParams((prev) => {
      const newCategoryIds = prev.categoryIds.includes(categoryId)
        ? prev.categoryIds.filter((id) => id !== categoryId)
        : [...prev.categoryIds, categoryId];
      return { ...prev, categoryIds: newCategoryIds };
    });
  };

  const handleReset = () => {
    setLocalSearchParams({
      title: "",
      description: "",
      duration_minutes: [0, 100],
      categoryIds: [],
    });
  };

  const handleClose = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false,
    });
  };

  const styles = useStyles({ tillTablet });

  return (
    <div className={styles.container}>
      <HeadingBar
        title={
          localSearchParams.title ||
          localSearchParams.description ||
          localSearchParams.duration_minutes[0] !== 0
            ? "Reset"
            : "Search"
        }
        icon={
          localSearchParams.title ||
          localSearchParams.description ||
          localSearchParams.duration_minutes[0] !== 0
            ? mdiRestore
            : null
        }
        onHeaderClick={
          localSearchParams.title ||
          localSearchParams.description ||
          localSearchParams.duration_minutes[0] !== 0
            ? handleReset
            : null
        }
        closeIcon={mdiClose}
        onClose={handleClose}
      />
      <div className={styles.content}>
        <div className={styles.categoryList}>
          {categories.map((category) => (
            <ChipCNO
              key={category.categoryId}
              name={category.category}
              icon="bullseye"
              active={localSearchParams.categoryIds.includes(
                category.categoryId
              )}
              isCategoryChip={true}
              onClick={() => handleCategoryClick(category.categoryId)}
            />
          ))}
        </div>
        <br />
        <InputLabelCNO label={"Search by Title"} isRequired={true} />
        <TextField
          name="title"
          className={`${styles.textField} ${className}`}
          value={localSearchParams.title}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          margin="dense"
          placeholder="Enter the title"
        />
         <br /> <br />
         <InputLabelCNO label={"Search by Description"} isRequired={true} />
        <TextField
          className={`${styles.textField} ${className}`}
          name="description"
          value={localSearchParams.description}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          margin="dense"
          placeholder="Enter the description"
        />
        <div className={styles.sliderContainer}>
          <Slider
            value={localSearchParams.duration_minutes}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            min={0}
            max={100}
            step={5}
            style={{
              color: "whitesmoke",
              marginTop: "4%",
              width: "97%",
            }}
          />
          <div className={styles.sliderLabels}>
            <span>{localSearchParams.duration_minutes[0]} min</span>
            <span>{localSearchParams.duration_minutes[1]} min</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestSearchSidebar;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    "&>*:first-child": {
      height: ({ tillTablet }) => (tillTablet ? "auto" : 68),
    },
  },
  content: {
    "&>div": {
      marginTop: theme.spacing(2),
      "&:first-child": {
        marginTop: 0,
      },
    },
  },
  categoryList: {
    display: "flex",
    flexWrap: "wrap",
    "&>div": {
      margin: theme.spacing(0.5),
    },
  },
  activeCategory: {
    backgroundColor: theme.palette.primary.main,
  },
  sliderContainer: {
    marginTop: theme.spacing(3),
    color: "white",
  },
  sliderLabels: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    opacity: ({ disabled }) => (disabled ? 0.8 : 1),
    width: "100%",
    borderRadius: 5,
    "& input": {
      background: "white",
      color: ({ color, disabled }) =>
        disabled
          ? fade(color || theme.colors.INPUT_TEXT_COLOR, 0.8)
          : color || theme.colors.INPUT_TEXT_COLOR,
      borderRadius: 5,
      padding: 8,
    },
    "& .MuiOutlinedInput-multiline": {
      color: ({ color }) => (color ? color : theme.colors.INPUT_TEXT_COLOR),
      background: "white",
    },
    "& .MuiOutlinedInput-adornedStart": {
      background: "white",
      borderRadius: 5,
    },
    "& .MuiOutlinedInput": {
      background: "white",
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
      "-webkit-box-shadow": `0 0 0 100px ${theme.colors.INPUT_BACKGROUND_COLOR} inset`,
      "-webkit-text-fill-color": theme.colors.INPUT_TEXT_COLOR,
      caretColor: ({ color }) =>
        color ? color : theme.colors.INPUT_TEXT_COLOR,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: ({ isBorderHighlight }) =>
        isBorderHighlight && "2px solid orange",
    },
  },
}));
