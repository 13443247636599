import React, { useEffect, useContext, useRef } from "react";
import { makeStyles, useTheme, Modal, Button,Dialog, useMediaQuery } from "@material-ui/core";
import { mdiFormatText } from "@mdi/js";
import { SnapList, SnapItem, useDragToScroll } from "react-snaplist-carousel";
import { TestContext } from "../../common/contexts/TestContext";
import ChipCNO from "../../library/ChipCNO";
import InputCNO from "../../library/InputCNO";
import InputLabelCNO from "../../library/InputLabelCNO";
import HeadingBar from "../HeadingBar";
import FormBox from "../FormBox";
import FormActionButtons from "../FormActionButtons";

const AddTest = () => {

  const tillTablet = useMediaQuery("(max-width: 767px)");
  const styles = useStyles({ tillTablet });
  
  //const parentClasses = useParentStyles();
  const theme = useTheme();
  const {
    allTests,
    saveAllTests,
    isModalOpen,
    setIsModalOpen,
    setNewTest,
    currentTestId,
    setCurrentTestId,
    newTest,
  } = useContext(TestContext);

  const handleClose = () => {
    setNewTest({});
    setIsModalOpen(false);
    setCurrentTestId(null);
  };

  let localCategories = localStorage.getItem("testCategory");
  localCategories = localCategories
    ? JSON.parse(localCategories).categories
    : [];

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  useEffect(() => {
    if (currentTestId) {
      const testToEdit = allTests.find((test) => test.testId === currentTestId);
      setNewTest(testToEdit || {});
    }
  }, [currentTestId, allTests, setNewTest]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newTest.categoryId) {
      alert("Please select a category.");
      return;
    }

    if (currentTestId) {
      const updatedAllTests = allTests.map((test) =>
        test.testId === currentTestId ? { ...test, ...newTest } : test
      );
      saveAllTests(updatedAllTests);
    } else {
      const newTestId =
        allTests.length > 0
          ? Math.max(...allTests.map((test) => test.testId)) + 1
          : 1;
      const newTestEntry = {
        testId: newTestId,
        ...newTest,
        questions: [],
      };
      saveAllTests([...allTests, newTestEntry]);
    }

    setNewTest({});
    setIsModalOpen(false);
    setCurrentTestId(null);
  };

  return (
    <Dialog
      open={isModalOpen}
      classes={{ paper: styles.paper }}
      className={styles.container}
    >
      
      <form onSubmit={handleSubmit} className={styles.form}>
      <HeadingBar
          title={currentTestId ? "Edit Test" : "Add New Test"}
          onClose={handleClose}
        />
        <FormBox  title={currentTestId ? "Edit Test" : "Add New Test"}>
        <InputLabelCNO label={"Category"} isRequired={true} />
              <SnapList ref={snapList} className={styles.jobChipSlider}>
                {localCategories.map((category) => (
                  <SnapItem key={category.categoryId}>
                    <ChipCNO
                      name={category.category}
                      isCategoryChip={true}
                      icon="bullseye"
                      active={newTest.categoryId === category.categoryId}
                      onClick={() =>
                        setNewTest({
                          ...newTest,
                          categoryId: category.categoryId,
                        })
                      }
                    />
                  </SnapItem>
                ))}
              </SnapList>
              
              <InputCNO
              name="title"
              label="Title"
              value={newTest.title || ""}
              onChange={(e) =>
                setNewTest({ ...newTest, title: e.target.value })
              }
              placeholder="Enter Test Title"
              icon={mdiFormatText}
              fullWidth
              isRequired
            /> 
            <InputCNO
              name="description"
              label="Description"
              value={newTest.description || ""}
              onChange={(e) =>
                setNewTest({ ...newTest, description: e.target.value })
              }
              placeholder="Enter Test Description"
              fullWidth
              
              isRequired
            />
          
            <InputCNO
              name="duration_minutes"
              label="Duration (minutes)"
              type="number"
              value={newTest.duration_minutes || ""}
              onChange={(e) =>
                setNewTest({ ...newTest, duration_minutes: e.target.value })
              }
              placeholder="Enter Test Duration in Minutes"
              fullWidth
              isRequired
            />

        </FormBox>
         <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
          
        />
      </form>
    </Dialog>
  );
};

export default AddTest;

const useStyles = makeStyles((theme) => ({
  container: {},
  paper: {
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    borderRadius: 10,
    width: "70vw",
    maxWidth: "90vw",
    margin: ({ tillTablet }) => (tillTablet ? 8 : 32),
  },
  form: {
    padding: "20px",
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    overflowX: "hidden",
    "&>*": {
      marginBottom: theme.spacing(3),
    },
  },
  jobChipSlider: {
    display: "flex",
    padding: "5px 0",
    "&>*:not(:first-child)": {
      marginLeft: 15,
    },
  },
}));
