import { Box, Collapse } from '@material-ui/core'
import React from 'react'
import { InputCNO, InputLabelCNO, SwitchCNO } from '../../../../library'

const RequiredValueAttribute = ({ formik }) => {
  return (
    <>
      <Box display='flex' alignItems='center'>
        <InputLabelCNO label='Required' />
        <SwitchCNO
          checked={formik.values.validations.required.value}
          onChange={() => formik.setFieldValue('validations.required.value', !formik.values.validations.required.value)}
        />
      </Box>

      {/* <Collapse in={formik.values.validations.required.value} >
        <InputCNO
          name='validations.required.message'
          label='Required Error Message'
          placeholder='Enter the message to show if field is missing value...'
          formik={formik}
          fullWidth
        />
      </Collapse> */}

    </>
  )
}

export default RequiredValueAttribute