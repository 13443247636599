import React, { useEffect, useState, useRef } from 'react';
import Video from './video';
//import { useDispatch, useSelector } from 'react-redux';

function Videos({ remoteStreams, rVideo }) {

  const [rVideosLocal, setrVideosLocal] = useState([]);
  const [remoteStreamsLocal, setRemoteStreamsLocal] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoVisible, setVideoVisible] = useState(true);

  // const dispatch = useDispatch();
  // const {
  //   isCallModalOpen,
  //   isCallModalOpenOnce,
  //   activeChatUser,
  //   messageReceived,
  //   activeGroupMembers
  // } = useSelector(state => state.chats);
  
  // useEffect(() => {
  //   console.log("===VIDEO=======messageReceived====================", messageReceived && messageReceived.isVideoCall);
  //   if (messageReceived && messageReceived.isVideoCall) {
  //     setVideoVisible(true);
  //   } else {
  //     setVideoVisible(false);
  //   }
  // }, [messageReceived]);
  
  useEffect(() => {
    setRemoteStreamsLocal(remoteStreams);
    setrVideosLocal(rVideo);
  }, []);

  useEffect(() => {

    if (remoteStreamsLocal !== remoteStreams) {

      const NoOfRemoteStreams = remoteStreams.length;

      let selectedVideoPrivate = { };

      if (NoOfRemoteStreams === 1)
        selectedVideoPrivate = remoteStreams[0];
      else {
        selectedVideoPrivate = selectedVideoPrivate? remoteStreams.filter(stream => stream.id === selectedVideoPrivate.id) : [];

        selectedVideoPrivate = selectedVideoPrivate.length ? { } : { selectedVideoPrivate: remoteStreams[NoOfRemoteStreams - 1] };
      }

      let _rVideos = remoteStreams.map((rVideo, index) => {

        const _videoTrack = rVideo.stream.getTracks().filter(track => track.kind === 'video');
        // if (_videoTrack.length)
        // _videoTrack[0].onmute = () => {
        // alert('muted')
        // }

        let video = _videoTrack && (
          <Video
            videoMuted={videoMuted}
            videoType='remoteVideo'
            videoStream={rVideo.stream}
            frameStyle={{
              backgroundColor: '#0E0E0E',
              maxWidth: 120, 
              maxHeight: 120,
              borderRadius: 5,
              float: 'left', 
              margin: '0 3px',
              visibility: rVideosLocal.length > 1? 'visible' : 'hidden',
            }}
            videoStyles={{
              objectFit: 'contain',
              borderRadius: 5,
              width: 120, height: 120,
              maxWidth: 120, maxHeight: 120,
              visibility: rVideosLocal.length > 1? 'visible' : 'hidden',
            }}
          />
        ) || <div></div>

        return (
          <div
            id={rVideo.name}
            onClick={() => switchVideo(rVideo)}
            style={{
              cursor: 'pointer', display: 'inline-block',
              visibility: rVideosLocal.length > 1? 'visible' : 'hidden',
            }}
            key={index}
          >
            {video}
          </div>
        )
      })

      setRemoteStreamsLocal(remoteStreams);
      setrVideosLocal(_rVideos);
      setSelectedVideo(selectedVideoPrivate);
    }

  }, [remoteStreams]);

  const videoMuted = (rVideo) => {
    const muteTrack = rVideo.getVideoTracks()[0]
    const isSelectedVideo = rVideo.id === selectedVideo.stream.id
    if (isSelectedVideo) {
      setVideoVisible(!muteTrack.muted);
    }
  };

  const switchVideo = (_video) => {
    const muteTrack = _video.stream.getVideoTracks()[0]
    setSelectedVideo(_video);
    setVideoVisible(!muteTrack.muted);
  };

  //render() {
  return (
    <div>
      <Video
        videoType='previewVideo'
        frameStyle={{
          zIndex: 1,
          position: 'relative',
          bottom: 0,
          //width: '100%', 
          //height: '80%',
          backgroundColor: 'transparent',
          objectFit:'contain'
        }}
        videoStyles={{
          //width: '100%', 
          //height: '50%',
          visibility: videoVisible? 'visible' : 'hidden',
          overflow: 'hidden',
          whiteSpace: 'no-wrap',
          objectFit:'contain',
        }}
        videoStream={selectedVideo && selectedVideo.stream}
      />
      <div
        style={{
          zIndex: 3,
          position: 'fixed',
          padding: '6px 3px',
          backgroundColor: 'rgba(0,0,0,0.3)',
          //maxHeight: 120,
          top: 'auto',
          right: 10,
          left: 10,
          bottom: 10,
          overflowX: 'hidden',
          whiteSpace: 'nowrap',
          objectFit:'contain',
          visibility: rVideosLocal.length > 1? 'visible' : 'hidden',  
          //display: rVideosLocal.length > 1? 'block' : 'none'
        }}
      >
        {rVideosLocal}
      </div>
    </div>
  )
  //}

}

export default Videos