import {
  apiMemberCreate,
  apiMemberEdit,
  apiMemberRemove,
  apiMemberSearch,
} from "../api";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { RoleId } from "../utils/mappings";
import providerProfile from "../actions/DummyData/providerProfile.json";
import axios from "axios";

export const SET_SERVICE_PROVIDERS = "SET_SERVICE_PROVIDERS";
export const SET_SERVICE_PROVIDERS_LOADER = "SET_SERVICE_PROVIDERS_LOADER";
export const SET_HAS_MORE_SERVICE_PROVIDERS = "SET_HAS_MORE_SERVICE_PROVIDERS";
export const SET_SERVICE_PROVIDER = "SET_SERVICE_PROVIDER";
export const SET_SERVICE_PROVIDER_BY_ID = "SET_SERVICE_PROVIDER_BY_ID";
export const TOGGLE_ADD_SERVICE_PROVIDER_DIALOG =
  "TOGGLE_ADD_SERVICE_PROVIDER_DIALOG";
export const SET_SERVICE_PROVIDER_SEARCH_PARAMS =
  "SET_SERVICE_PROVIDER_SEARCH_PARAMS";
export const SET_ALREADY_EXIST_SERVICE_PROVIDERS =
  "SET_ALREADY_EXIST_SERVICE_PROVIDERS";
export const SET_SERVICE_PROVIDER_SEARCH_MANAGER =
  "SET_SERVICE_PROVIDER_SEARCH_MANAGER";

export function setAlreadyExistServiceProviders(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_ALREADY_EXIST_SERVICE_PROVIDERS,
      payload: payload,
    });
  };
}

export function setServiceProviderSearchParams(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_SERVICE_PROVIDER_SEARCH_PARAMS,
      payload: payload,
    });

    dispatch(searchServiceProviders(payload));
  };
}

export function setHasMoreServiceProviders(status) {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_MORE_SERVICE_PROVIDERS,
      payload: status,
    });
  };
}

export function setServiceProvidersLoader(loaderType, loaderState) {
  return (dispatch) => {
    const loaderPayload = {
      loaderType,
      loaderState,
    };

    dispatch({
      type: SET_SERVICE_PROVIDERS_LOADER,
      payload: loaderPayload,
    });
  };
}

export function setServiceProviders(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_SERVICE_PROVIDERS,
      payload: payload,
    });
  };
}

// export function setServiceProvider(payload) {
//   return (dispatch, getState) => {

//     const prevServiceProviders = getState().serviceProviders.serviceProviders;

//     dispatch({
//       type: SET_SERVICE_PROVIDER,
//       payload: payload
//     });
//   };
// }

export function toggleAddServiceProviderDialog(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_SERVICE_PROVIDER_DIALOG,
      payload: status,
    });
  };
}

export function setServiceProviderById(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_SERVICE_PROVIDER_BY_ID,
      payload: payload,
    });
  };
}
export function filterServiceProviderById(userId) {
  const { serviceProviders } = getState().serviceProviders;
  const searchedProvider = serviceProviders.find(
    (provider) => provider.id === userId
  );
  return searchedProvider;
}
export function getServiceProviderById(
  ids,
  resolvePic = false,
  offlineData,
  onFetchById
) {
  return async (dispatch) => {
    dispatch(setServiceProvidersLoader("serviceProviderDetailLoader", true));

    const payload = {
      ids: ids,
      orderBy: "newest",
      ResolvePic: resolvePic,
    };

    // await axios.get('https://jsonplaceholder.typicode.com/users', payload)
    await apiMemberSearch
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data.data;

          const data = filterServiceProviderById(payload.id);

          console.log("responseData: ", responseData);
          console.log("filteredServiceProviderById", [data]);

          // if (onFetchById) {
          //   onFetchById(responseData[0]);
          // }

          if (onFetchById) {
            onFetchById(data);
          }

          // dispatch({
          //   type: SET_SERVICE_PROVIDER_BY_ID,
          //   payload: responseData[0]
          // });

          dispatch({
            type: SET_SERVICE_PROVIDER_BY_ID,
            payload: data,
          });
        } else {
          if (offlineData) {
            dispatch({
              type: SET_SERVICE_PROVIDER_BY_ID,
              payload: offlineData,
            });
          } else {
            console.error("Fetch serviceProvider by Id ");
            toast.error(
              "There is some issue in fetching details of serviceProvider."
            );
          }
        }
      })
      .catch((error) => {
        if (offlineData) {
          dispatch({
            type: SET_SERVICE_PROVIDER_BY_ID,
            payload: offlineData,
          });
        } else {
          console.error("Fetch serviceProvider by Id Error: ", error);
          toast.error(
            "There is some issue in fetching details of serviceProvider."
          );
        }
      })
      .finally(() => {
        dispatch(
          setServiceProvidersLoader("serviceProviderDetailLoader", false)
        );
      });
  };
}

export function searchServiceProviders(payload) {
  return (dispatch, getState) => {
    console.log("===> searchServiceProviders payload", payload);

    const searchParams = {
      serviceProvider: payload.serviceProvider ? payload.serviceProvider : null,
    };

    const searchPayload = {
      ids: [],
      orderBy: "newest",
      pageIndex: 0,
      ...(payload.serviceProvider
        ? {
            ...(payload.serviceProvider.enterKey
              ? { searchText: payload.serviceProvider.title }
              : {}),
            ...(!payload.serviceProvider.enterKey
              ? { ids: [payload.serviceProvider.id] }
              : {}),
          }
        : {}),
    };

    dispatch(fetchServiceProviders(searchPayload));
  };
}

export function fetchServiceProviders(payload) {
  return async (dispatch, getState) => {
    dispatch(setServiceProvidersLoader("serviceProvidersLoader", true));
    const { serviceProviderSearchParams } = getState().serviceProviders;
    console.log("payload,searh", { payload, serviceProviderSearchParams });
    payload = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      SortDecending: true,
      ids: [],
      ResolvePic: true,
      searchText: serviceProviderSearchParams.serviceProvider
        ? serviceProviderSearchParams.serviceProvider.title
        : null,
      ...payload,
    };
    await axios
      .get("https://jsonplaceholder.typicode.com/users", payload)
      // await apiMemberSearch.post(payload)
      .then((response) => {
        if (response.status === 200) {
          let serviceProvidersResponse = response.data.data;
          console.log("serviceProvidersResponse: ", serviceProvidersResponse);
          // dispatch(setServiceProviders(serviceProvidersResponse));
          if (payload.searchText) {
            const profiles = providerProfile.filter((provider) => {
              const title = `${provider.firstName} ${provider.lastName}`;
              return title.includes(payload.searchText);
            });
            dispatch(setServiceProviders(profiles));
          } else {
            dispatch(setServiceProviders(providerProfile));
          }

          if (serviceProvidersResponse.length < 10) {
            dispatch(setHasMoreServiceProviders(false));
          }
        } else {
          console.error("Error: Fetch serviceProviders: ");
        }
      })
      .catch((error) => {
        console.error("Error: Fetch serviceProviders: ", error);
      })
      .finally(() => {
        dispatch(setServiceProvidersLoader("serviceProvidersLoader", false));
      });
  };
}

export function fetchMoreServiceProviders(payload) {
  return async (dispatch, getState) => {
    const prevServiceProviders = getState().serviceProviders.serviceProviders;
    const { serviceProviderSearchParams } = getState().serviceProviders;

    dispatch(setServiceProvidersLoader("moreServiceProvidersLoader", true));

    payload = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      SortDecending: true,
      ids: [],
      ResolvePic: true,
      searchText: serviceProviderSearchParams.serviceProvider
        ? serviceProviderSearchParams.serviceProvider.title
        : null,
      ...payload,
    };

    await apiMemberSearch
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          let moreServiceProvidersResponse = response.data.data;
          console.log(
            "moreServiceProvidersResponse: ",
            moreServiceProvidersResponse
          );

          const serviceProviderspayload = [
            ...prevServiceProviders,
            ...moreServiceProvidersResponse,
          ];

          dispatch(setServiceProviders(serviceProviderspayload));

          if (moreServiceProvidersResponse.length < 10) {
            dispatch(setHasMoreServiceProviders(false));
          }
        } else {
          console.error("Error: Fetch ServiceProviders: ");
        }
      })
      .catch((error) => {
        console.error("Error: Fetch ServiceProviders: ", error);
      })
      .finally(() => {
        dispatch(
          setServiceProvidersLoader("moreServiceProvidersLoader", false)
        );
      });
  };
}

export function updateServiceProviderDetails(payload) {
  return async (dispatch, getState) => {
    dispatch(setServiceProvidersLoader("editLoader", true));

    const dataToSend = {
      ...payload,
      hourlyRate: payload.hourlyRate ? payload.hourlyRate : "",
      code: payload.code ? payload.code : "",
      id: payload.id ? payload.id : "",
      isActive: payload.isActive ? payload.isActive : "",
      password: payload.password ? payload.password : null,
      userId: payload.userId ? payload.userId : "",
      salutation: payload.salutation ? payload.salutation : "",
      firstName: payload.firstName ? payload.firstName : "",
      lastName: payload.lastName ? payload.lastName : "",
      roleId: payload.roleId ? payload.roleId : "",
      profilePic: payload.profilePic ? payload.profilePic : "",
      birthday: payload.birthday ? payload.birthday : "",
      gender: payload.gender ? payload.gender : "",
      email: payload.email ? payload.email : "",
      mobile: payload.mobile ? payload.mobile : "",
      skype: payload.skype ? payload.skype : "",
      facebook: payload.facebook ? payload.facebook : "",
      twitter: payload.twitter ? payload.twitter : "",
      linkedIn: payload.linkedIn ? payload.linkedIn : "",
      abstract: payload.abstract ? payload.abstract : "",
      experience: payload.experience ? payload.experience : "",
      dailyRate: payload.dailyRate ? payload.dailyRate : "",
      availability: payload.availability ? payload.availability : "",
    };

    console.log("ServiceProvider edit payload: ", dataToSend);

    await axios
      .get("https://jsonplaceholder.typicode.com/users", dataToSend)
      // await apiMemberEdit.post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          const payloadTemp = {
            ...payload,
            profilePic: payload.localProfilePicUrl,
          };
          const { serviceProviders } = getState().serviceProviders;
          const serviceProvidersTemp = serviceProviders.map((serviceProvider) =>
            serviceProvider.id === payload.id ? payloadTemp : serviceProvider
          );

          dispatch(setServiceProviders(serviceProvidersTemp));

          dispatch({
            type: SET_SERVICE_PROVIDER_BY_ID,
            payload: payload,
          });

          toast.success("ServiceProvider edited successfully!!");
        } else {
          toast.error("Error in editing ServiceProvider!!");
        }
      })
      .catch((error) => {
        console.error("ServiceProvider Edit Error: ", error);
        toast.error("Error in editing ServiceProvider!!");
      })
      .finally(() => {
        dispatch(setServiceProvidersLoader("editLoader", false));
      });
  };
}

export function addServiceProvider(payload, onAddSuccess, onAddError) {
  return async (dispatch, getState) => {
    dispatch(setServiceProvidersLoader("addLoader", true));

    const { serviceProviders } = getState().serviceProviders;

    console.log("Add ServiceProvider payloadaction: ", payload);

    payload = {
      ...payload,
      roleId: RoleId.Member,
      hourlyRate: payload.hourlyRate ? payload.hourlyRate : 0,
    };

    await apiMemberCreate
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          console.log("Add ServiceProvider Response: ", response);

          const id = response.data;
          const newServiceProvider = {
            ...payload,
            userId: id,
            id: id,
            groupId: "",
            isActive: true,
          };

          dispatch(
            setServiceProviders([newServiceProvider, ...serviceProviders])
          );

          if (onAddSuccess) onAddSuccess();

          toast.success("ServiceProvider added successfully!!");
        } else {
          console.log("Add ServiceProvider Error: ");
          toast.error("Error in adding ServiceProvider!!");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 400 &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {
            console.error(
              "Add ServiceProvider Error: response ",
              error.response.data
            );
            toast.error(error.response.data);

            if (onAddError) onAddError(error.response.data);

            return;
          }
        }

        console.error("Add ServiceProvider Error: ", error);
        toast.error("Error in adding ServiceProvider!!");
      })
      .finally(() => {
        dispatch(setServiceProvidersLoader("addLoader", false));
      });
  };
}

export function deleteServiceProvider(payload) {
  return async (dispatch, getState) => {
    dispatch(setServiceProvidersLoader("deleteLoader", true));

    const { serviceProviders } = getState().serviceProviders;

    console.log("Delete ServiceProvider payloadaction: ", payload);

    await apiMemberRemove
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          console.log("Remove ServiceProvider Response: ", response);

          const serviceProvidersTemp = serviceProviders.map(
            (serviceProvider) => {
              if (serviceProvider.id === payload.id) {
                const updatedServiceProvider = {
                  ...serviceProvider,
                  isActive: false,
                };

                dispatch(setServiceProviderById(updatedServiceProvider));
                return updatedServiceProvider;
              } else {
                return serviceProvider;
              }
            }
          );

          dispatch(setServiceProviders(serviceProvidersTemp));
          toast.success("ServiceProvider removed successfully!!");
        } else {
          console.log("Delete ServiceProvider Error: ");
          toast.error("Error in removing ServiceProvider!!");
        }
      })
      .catch((error) => {
        console.error("Delete ServiceProvider Error: ", error);
        toast.error("Error in removing ServiceProvider!!");
      })
      .finally(() => {
        dispatch(setServiceProvidersLoader("deleteLoader", false));
      });
  };
}

export function searchAlreadyServiceProviders(payload) {
  return async (dispatch) => {
    dispatch(setServiceProvidersLoader("ServiceProvidersLoader", true));

    payload = {
      PageIndex: 0,
      OrderBy: "newest",
      SortDecending: true,
      resolvePic: true,
      SearchText: "",
      ...payload,
    };

    await apiMemberSearch
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          let serviceProvidersResponse = response.data.data;
          console.log("ServiceProvidersResponse: ", serviceProvidersResponse);
          dispatch(setAlreadyExistServiceProviders(serviceProvidersResponse));

          if (serviceProvidersResponse < 10) {
            dispatch(setHasMoreServiceProviders(false));
          }
        } else {
          console.error("Error: Fetch ServiceProviders: ");
        }
      })
      .catch((error) => {
        console.error("Error: Fetch ServiceProviders: ", error);
      })
      .finally(() => {
        dispatch(setServiceProvidersLoader("ServiceProvidersLoader", false));
      });
  };
}

export function setServiceProviderSearchManager({
  sortAscending,
  pageSize,
  ...payload
}) {
  return async function setServiceProviderSearchManagerThunk(
    dispatch,
    getState
  ) {
    const searchParams = {
      customer: payload.customer || null,
      startDate: payload.startDate || null,
      endDate: payload.endDate || null,
      assignedUsers: payload.assignedUsers,
      categories: payload.categories.length ? payload.categories : [],
      serviceProvider: payload.serviceProvider ? payload.serviceProvider : null,
      statuses: payload.statuses ? payload.statuses : [],
    };

    console.log(
      "fetchTodos: setServiceProviderSearchManager searchParams",
      searchParams
    );

    let searchServiceProvider = {};
    if (payload.serviceProvider) {
      searchServiceProvider = {
        ...(payload.serviceProvider.enterKey
          ? { searchText: payload.serviceProvider.title }
          : {}),
        ...(!payload.serviceProvider.enterKey
          ? { ids: [payload.serviceProvider.id] }
          : {}),
      };
    }

    const searchParamsPayload = {
      ...searchParams,
      customerIds: payload.customer ? [payload.customer.id] : null,
      assignedIds: payload.assignedUsers ? [payload.assignedUsers.id] : null,
      ...(sortAscending !== undefined || sortAscending !== null
        ? { sortAscending }
        : {}),
      ...(pageSize !== undefined || pageSize !== null ? { pageSize } : {}),
      ...searchServiceProvider,
    };

    console.log(
      "fetchTodos: setServiceProviderSearchManager searchParamsPayload",
      searchParamsPayload
    );

    await dispatch({
      type: SET_SERVICE_PROVIDER_SEARCH_MANAGER,
      payload: searchParams,
    });

    await dispatch(handleServiceProviderFilter(searchParamsPayload));
  };
}
