import { makeStyles, useTheme } from '@material-ui/core';
import { mdiAttachment, mdiEmoticonOutline } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import React from 'react';
import { useState } from 'react';
import AttachmentPicker from './AttachmentPicker';
import EmojiPicker from './EmojiPicker';

function ChatActions({ props, setText }) {

  const theme = useTheme();

  const [isEmojiPicker, setIsEmojiPicker] = useState(false);
  const [isAttachmentPicker, setIsAttachmentPicker] = useState(false);

  const toggleEmojiPicker = (status) => {
    setIsEmojiPicker(status);
  };

  const toggleAttachmentPicker = (status) => {
    setIsAttachmentPicker(status);
  };

  const styles = useStyles();
  return (
    <div className={styles.container_ChatActions}>
      <Icon
        className={clsx(styles.chatActionIcon, styles.emojiIcon)}
        path={mdiEmoticonOutline}
        size="2rem"
        color={theme.colors.CHAT_EMOJI_PICKER_ICON_COLOR}
        onClick={() => {
          toggleEmojiPicker(!isEmojiPicker);
          toggleAttachmentPicker(false);
        }}
      />
      <Icon
        className={clsx(styles.chatActionIcon, styles.attachmentIcon)}
        path={mdiAttachment}
        size="2rem"
        color={theme.colors.CHAT_ATTACHMENT_PICKER_ICON_COLOR}
        onClick={() => {
          toggleAttachmentPicker(!isAttachmentPicker);
          toggleEmojiPicker(false);
        }}
      />

      {
        isEmojiPicker &&
        <EmojiPicker
          props={props}
          isEmojiPicker={isEmojiPicker}
          toggleEmojiPicker={toggleEmojiPicker}
          setText={setText}
        />
      }

      {
        isAttachmentPicker &&
        <AttachmentPicker
          props={props}
          toggleAttachmentPicker={toggleAttachmentPicker}
        />
      }

    </div>
  )
}

export default ChatActions;

const useStyles = makeStyles((theme) => ({
  container_ChatActions: {
    display: 'flex',
    padding: 5
  },
  chatActionIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    padding: 3,
    // boxShadow: '2px 2px 5px 0px #5e5e5e',
    cursor: 'pointer',
  },
  emojiIcon: {
    // background: theme.colors.,
    marginRight: 10
  },
  attachmentIcon: {
    // background: "#fc7c05",
  }
}));