import {
  FETCH_TASK_SEARCH,
  RESET_MAP_DATA,
  SET_HAS_MORE_PAYMENT_HISTORY,
  SET_TASK_LOADERS,
  SET_TASK_PAYMENT_HISTORY,
  TOGGLE_MAP_SEARCH_SIDEBAR
} from "../actions/taskSearchActions";

const initialState = {
  data: [],
  isMapSearch: true,
  isMapData: false,
  taskPaymentHistory: [],
  hasMorePaymentHistory: true,
  taskPaymentHistoryLoader: false
};

export default function taskSearchReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_TASK_SEARCH:

      state = {
        ...state,
        data: payload,
        isMapSearch: false,
        isMapData: true
      };
      return state;

    case RESET_MAP_DATA:
      state = {
        ...state,
        data: [],
        isMapSearch: true,
        isMapData: false
      };
      return state;

    case TOGGLE_MAP_SEARCH_SIDEBAR:
      state = {
        ...state,
        isMapSearch: payload
      };
      return state;

    case SET_TASK_PAYMENT_HISTORY:
      state = {
        ...state,
        taskPaymentHistory: payload
      };
      return state;

    case SET_HAS_MORE_PAYMENT_HISTORY:
      state = {
        ...state,
        hasMorePaymentHistory: payload
      };
      return state;

    case SET_TASK_LOADERS:
      const { loaderName, loaderState } = payload;
      state = {
        ...state,
        [loaderName]: loaderState
      };
      return state;

    default:
      return state;
  }
}