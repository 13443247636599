import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_ChatsListSection: {
    borderRight: `1px solid ${theme.colors.BORDER_COLOR}`,
    width: ({ tillTablet }) => tillTablet ? '100%' : "40%",
    maxWidth: ({ tillTablet }) => tillTablet ? '100%' : 400,
    maxHeight: 'calc(100vh - 60px)',
    minHeight: 'calc(100vh - 60px)',
    overflow: 'auto',
    background: theme.colors.CHAT_PROFILE_LIST_CONTAINER_BACKGROUND_COLOR,
    zIndex: 0,
    position: 'relative',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  searchToolbar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    position: 'sticky',
    top: 0,
    padding: '0px 0px 5px 10px',
  },
  search: {
    flex: 1,
    borderRadius: 5,
    background: 'red',
    '& input': {
      background: 'white',
      color: ({ color }) => color ? color : theme.colors.CHAT_SEARCH_INPUT_COLOR,
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: ({ color }) => color ? color : theme.colors.CHAT_SEARCH_INPUT_COLOR,
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      // "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": theme.colors.CHAT_SEARCH_INPUT_COLOR,
      caretColor: ({ color }) => color ? color : theme.colors.CHAT_SEARCH_INPUT_COLOR
    }
  },
  container_MorePopover: {
    padding: 5,
    overflow: 'hidden',
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR
  },
  list: {
    background: theme.colors.MORE_ACTIONS_OPTIONS_WRAPPER_BACKGROUND_COLOR,
    padding: 0
  },
  listItem: {
    background: theme.colors.MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 2,
    width: 200,
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.01)'
    }
  },
  listItemText: {
    textDecoration: 'none',
  },
  fabContainer: {
    width: '100%',
    // border: '1px solid blue',
    position: 'sticky',
    bottom: 0,
    // right: 20,
    padding: 10,
    textAlign: 'right',
  },
  fab: {
    background: theme.palette.secondary.main,
    color: 'white',
  }
}));