import React, { useEffect, useState } from "react";
import GroupMedias from "../../../components/GroupMedias/GroupMedias";
import { useDispatch, useSelector } from "react-redux";
import { MediaSizeType } from "../../../utils/mappings";
import { getResolvedPostMediasByIds } from "../../../actions/postActions";

const PostGroupedMedia = ({ post, details }) => {
  console.log("checkPost", post);
  console.log("details", details);
  const dispatch = useDispatch();
  const { posts, postMediaFetchLoader } = useSelector((state) => state.posts);

  const [mediaLinks, setMediaLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resolvedMediasLocal, setResolvedMediasLocal] = useState([]);

  const fetchMediaLinks = async () => {
    if (!post) return;

    const hasMediaLinks =
      Array.isArray(post.mediaLinks) && post.mediaLinks.length > 0;
    const hasGifs = Array.isArray(post.gifs) && post.gifs.length > 0;

    if (!hasMediaLinks && !hasGifs) return;

    const ids = hasMediaLinks
      ? post.mediaLinks.map((image) => image.id)
      : post.gifs.map((image) => image.id);

    const payload = {
      ids: ids,
      size: MediaSizeType.Thumbnail,
    };

    try {
      if (post) {
        setMediaLinks(hasMediaLinks ? post.mediaLinks : post.gifs);
      } else {
        setLoading(true);
        const response = posts;
        console.log("PostGroupedMedia: response: ", response);
        const fetchedMedias = response.find((p) => p.id === post.id);
        console.log("PostGroupedMedia: fetchedMedias: ", fetchedMedias);

        if (fetchedMedias) {
          setMediaLinks(
            hasMediaLinks ? fetchedMedias.mediaLinks : fetchedMedias.gifs
          );
        } else {
          setMediaLinks([]);
        }
      }
    } catch (error) {
      setMediaLinks([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMediaLinks();
  }, [post]);

  const onResolveMediasByIds = async (
    resolvedMedia,
    _,
    openMedia,
    setLightboxController
  ) => {
    if (openMedia) {
      return;
    }

    if (resolvedMedia) {
      const resolvedMediaIdsLocal = resolvedMediasLocal.map(
        (image) => image.id
      );

      const _resolvedJobMedias = mediaLinks.map((media, index) => {
        if (media.id === resolvedMedia.id) {
          return {
            ...resolvedMedia,
            uri: resolvedMedia.isImage ? resolvedMedia.link : media.link,
            mediaOpenLink: resolvedMedia.link,
          };
        } else if (resolvedMediaIdsLocal.includes(media.id)) {
          return resolvedMediasLocal[index];
        } else {
          return {
            ...media,
            uri: media.link,
            thumbnailUri: media.link,
            isResolved: false,
            isImage: false,
          };
        }
      });

      setResolvedMediasLocal(_resolvedJobMedias);
      setLightboxController((prev) => ({
        ...prev,
        toggler: !prev.toggler,
      }));
    }
  };

  const onThumbnailClick = (index, setLightboxController) => {
    const document = mediaLinks[index];

    setResolvedMediasLocal(mediaLinks);
    setLightboxController((prev) => ({
      ...prev,
      toggler: !prev.toggler,
    }));

    const payload = {
      clickedMedia: document,
      allMedias: mediaLinks,
    };

    dispatch(
      getResolvedPostMediasByIds(payload, (...args) =>
        onResolveMediasByIds(...args, setLightboxController)
      )
    );
  };
  // const onThumbnailClick = (index, setIsImageViewerVisible) => {
  //   const document = mediaLinks[index];

  //   setResolvedMediasLocal(mediaLinks);
  //   setIsImageViewerVisible(true);

  //   const payload = {
  //     clickedMedia: document,
  //     allMedias: mediaLinks,
  //   };

  //   dispatch(
  //     getResolvedPostMediasByIds(payload, (...args) =>
  //       onResolveMediasByIds(...args, setIsImageViewerVisible)
  //     )
  //   );
  // };

  const onChangeImageIndex = (index, setIsImageViewerVisible) => {
    const nextImage = resolvedMediasLocal[index];
    if (nextImage?.isResolved) return;

    const payload = {
      clickedMedia: nextImage,
      allMedias: mediaLinks,
    };

    dispatch(
      getResolvedPostMediasByIds(
        payload,
        (...args) => onResolveMediasByIds(...args, setIsImageViewerVisible),
        false
      )
    );
  };
  console.log("details", details);
  console.log("medi..", mediaLinks);
  console.log("resol", resolvedMediasLocal);

  return (
    <GroupMedias
      mediaLinks={mediaLinks}
      resolvedMediaLinks={resolvedMediasLocal}
      onClick={onThumbnailClick}
      onChangeImageIndex={onChangeImageIndex}
      loading={loading}
      imageViewerLoader={postMediaFetchLoader}
      details={details}
    />
  );
};

export default PostGroupedMedia;
