import { toast } from "react-toastify";
import {
  apiQuotationAssign,
  apiQuotationChangeStatus,
  apiQuotationCreate,
  apiQuotationEdit,
  apiQuotationGetMediaLink,
  apiQuotationInitUpload,
  apiQuotationRemove,
  apiQuotationSearch,
  apiQuotationUploadMedia,
} from "../api/quotation";
import { createJob } from "./jobActions";
import {
  addMultipleQuotationItems,
  addQuotationItem,
  fetchQuotationItems,
} from "./quotationItemActions";
import { setIsNewSubTaskAdded } from "./subTaskActions";
import moment from "moment";
import {
  QuotationStatus,
  JobVisibilityForCustomer,
  MediaSizeType,
  FileType,
  MediaType,
} from "../utils/mappings";
import { batch } from "react-redux";
import LocalStorageConstants from "../utils/LocalStorageConstants";
import { getCompanyById, getCompanyProfilePic } from "./customerCompanyActions";
import { getCustomerById } from "./customerActions";
import { generateQuotationTemplate } from "../utils/PDFHelpers";
import { generatePdf } from "../utils/PDFHelpers/PDFHelpers";
import { AppConstants } from "../utils/AppConstants";
import {
  getContentType,
  getFileTypeFromMimeType,
  getMediaData,
  getTodayQuotations,
  parseNumber,
} from "../utils/Helpers";
import { getTotalAmountValues } from "../utils/InvoiceHelpers";
import { v4 } from "uuid";
import { combineDateAndTime } from "../utils/Tracking";

export const SET_QUOTATIONS = "SET_QUOTATIONS";
export const TOGGLE_ADD_QUOTATION_DIALOG = "TOGGLE_ADD_QUOTATION_DIALOG";
export const SET_QUOTATION_BY_ID = "SET_QUOTATION_BY_ID";
export const SET_QUOTATIONS_LOADER = "SET_QUOTATIONS_LOADER";
export const SET_HAS_MORE_QUOTATIONS = "SET_HAS_MORE_QUOTATIONS";
export const SET_CONVERT_TO_JOB_DATA = "SET_CONVERT_TO_JOB_DATA";
export const TOGGLE_QUOTATION_FILTER_DIALOG = "TOGGLE_QUOTATION_FILTER_DIALOG";
export const SET_QUOTATION_SEARCH_PARAMS = "SET_QUOTATION_SEARCH_PARAMS";
export const TOOGLE_PDF_DIALOG = "TOOGLE_PDF_DIALOG";
export const SET_RESOLVED_QUOTATION_MEDIAS = "SET_RESOLVED_QUOTATION_MEDIAS";

export const setResolvedQuotationMedias = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_RESOLVED_QUOTATION_MEDIAS,
      payload: payload,
    });
  };
};

export function setQuotationLoader(loaderType, loaderState) {
  return (dispatch) => {
    const loaderPayload = {
      loaderType,
      loaderState,
    };

    dispatch({
      type: SET_QUOTATIONS_LOADER,
      payload: loaderPayload,
    });
  };
}

export const togglePDFDialog = (status) => {
  return (dispatch) => {
    dispatch({
      type: TOOGLE_PDF_DIALOG,
      payload: status,
    });
  };
};

export function setHasMoreQuotations(status) {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_MORE_QUOTATIONS,
      payload: status,
    });
  };
}

export function setQuotationSearchParams(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_QUOTATION_SEARCH_PARAMS,
      payload: payload,
    });
  };
}

export function toggleAddQuotationDialog(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_QUOTATION_DIALOG,
      payload: status,
    });
  };
}

export function toggleQuotationFilterDialog(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_QUOTATION_FILTER_DIALOG,
      payload: status,
    });
  };
}

export function setQuotations(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_QUOTATIONS,
      payload: payload,
    });
  };
}

export function fetchQuotations(payload) {
  return async (dispatch, getState) => {
    dispatch(setQuotationLoader("quotationsLoader", true));
    dispatch(setHasMoreQuotations(true));

    const { quotationSearchParams } = getState().quotations;

    payload = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      endDate: null,
      assignedIds: [],
      ids: null,
      customerIds: quotationSearchParams.customer
        ? [quotationSearchParams.customer.id]
        : null,
      searchText: quotationSearchParams.quotation
        ? quotationSearchParams.quotation.title
        : "",
      categories: quotationSearchParams.categories,
      offerNumbers: quotationSearchParams.offerNumber
        ? [quotationSearchParams.offerNumber]
        : null,
      startDate: quotationSearchParams.sentOn
        ? moment(quotationSearchParams.sentOn).startOf("day")
        : null,
      dueInDays: quotationSearchParams.validForDays
        ? quotationSearchParams.validForDays
        : null,
      statuses: quotationSearchParams.statuses
        ? quotationSearchParams.statuses
        : null,
      ...payload,
    };

    await apiQuotationSearch
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          const { isToday } = quotationSearchParams;

          let quotationsResponse = response.data.data;

          console.log("quotationsResponse: ", quotationsResponse);

          quotationsResponse = getTodayQuotations(quotationsResponse, isToday);

          quotationsResponse = quotationsResponse.map((quotation) => {
            const {
              totalAmount,
              amount,
              amountAfterDiscount,
              discount,
              discountRate,
              taxableAmount,
              tax,
              taxRate,
            } = getTotalAmountValues(
              quotation.rate,
              quotation.discount,
              quotation.taxRate,
              quotation.includeTax
            );

            return {
              ...quotation,
              totalAmount,
              amount,
              amountAfterDiscount,
              discount,
              discountRate,
              taxableAmount,
              tax,
              taxRate,
              notes: quotation.notes
                ? quotation.notes
                : AppConstants.QUOTATION_NOTES,
            };
          });

          dispatch(setQuotations(quotationsResponse));

          if (quotationsResponse.length < 10) {
            dispatch(setHasMoreQuotations(false));
          }
        } else {
          console.error("Error: Fetch Employees: ");
        }
      })
      .catch((error) => {
        console.error("Error: Fetch Employees: ", error);
      })
      .finally(() => {
        dispatch(setQuotationLoader("quotationsLoader", false));
      });
  };
}

export function fetchMoreQuotations(payload) {
  return async (dispatch, getState) => {
    const prevQuotations = getState().quotations.quotations;
    const { quotationSearchParams } = getState().quotations;

    dispatch(setQuotationLoader("moreQuotationsLoader", true));
    dispatch(setHasMoreQuotations(true));

    payload = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      endDate: null,
      assignedIds: [],
      ids: null,
      customerIds: quotationSearchParams.customer
        ? [quotationSearchParams.customer.id]
        : null,
      searchText: quotationSearchParams.quotation
        ? quotationSearchParams.quotation.title
        : "",
      categories: quotationSearchParams.categories,
      offerNumbers: quotationSearchParams.offerNumber
        ? [quotationSearchParams.offerNumber]
        : null,
      startDate: quotationSearchParams.sentOn
        ? moment(quotationSearchParams.sentOn).startOf("day")
        : null,
      dueInDays: quotationSearchParams.validForDays
        ? quotationSearchParams.validForDays
        : null,
      ...payload,
    };

    await apiQuotationSearch
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          const { isToday } = quotationSearchParams;

          let moreQuotationsResponse = response.data.data;
          if (moreQuotationsResponse.length < 10) {
            dispatch(setHasMoreQuotations(false));
          }

          console.log("moreEmployeesResponse: ", moreQuotationsResponse);
          moreQuotationsResponse = getTodayQuotations(
            moreQuotationsResponse,
            isToday
          );

          moreQuotationsResponse = moreQuotationsResponse.map((quotation) => {
            const {
              totalAmount,
              amount,
              amountAfterDiscount,
              discount,
              discountRate,
              taxableAmount,
              tax,
              taxRate,
            } = getTotalAmountValues(
              quotation.rate,
              quotation.discount,
              quotation.taxRate,
              quotation.includeTax
            );

            return {
              ...quotation,
              totalAmount,
              amount,
              amountAfterDiscount,
              discount,
              discountRate,
              taxableAmount,
              tax,
              taxRate,
              notes: quotation.notes
                ? quotation.notes
                : AppConstants.QUOTATION_NOTES,
            };
          });

          const quotationsPayload = [
            ...prevQuotations,
            ...moreQuotationsResponse,
          ];

          dispatch(setQuotations(quotationsPayload));
        } else {
          console.error("Error: Fetch more Quotations: ");
        }
      })
      .catch((error) => {
        console.error("Error: Fetch more Quotations: ", error);
      })
      .finally(() => {
        dispatch(setQuotationLoader("moreQuotationsLoader", false));
      });
  };
}

export function setQuotationById(payload) {
  return (dispatch, getState) => {
    if (payload) {
      batch(() => {
        dispatch({
          type: SET_QUOTATION_BY_ID,
          payload: payload,
        });

        const { quotations } = getState().quotations;
        let updatedQuotations = [];
        const targetQuotation = quotations.find(
          (quotation) => quotation.id === payload.id
        );
        if (targetQuotation) {
          updatedQuotations = quotations.map((quotation) =>
            quotation.id === payload.id ? payload : quotation
          );
        } else {
          updatedQuotations = [payload, ...quotations];
        }
        dispatch(setQuotations(updatedQuotations));
      });
    } else {
      dispatch({
        type: SET_QUOTATION_BY_ID,
        payload: payload,
      });
    }
  };
}

export function getQuotationById(quotationId, offlineData) {
  return async (dispatch, getState) => {
    dispatch(setQuotationLoader("quotationDetailLoader", true));

    const payload = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      startDate: null,
      endDate: null,
      assignedIds: [],
      categories: [],
      searchText: "",
      ids: [quotationId],
    };

    console.log("====================================");
    console.log("getQuotationById: ", quotationId);
    console.log("====================================");

    await apiQuotationSearch
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          let quotationResponse = response.data.data;

          console.log("getQuotationById: quotation: ", quotationResponse);

          quotationResponse = quotationResponse.map((quotation) => {
            const {
              totalAmount,
              amount,
              amountAfterDiscount,
              discount,
              discountRate,
              taxableAmount,
              tax,
              taxRate,
            } = getTotalAmountValues(
              quotation.rate,
              quotation.discount,
              quotation.taxRate,
              quotation.includeTax
            );

            return {
              ...quotation,
              totalAmount,
              amount,
              amountAfterDiscount,
              discount,
              discountRate,
              taxableAmount,
              tax,
              taxRate,
              notes: quotation.notes
                ? quotation.notes
                : AppConstants.QUOTATION_NOTES,
            };
          });

          dispatch(setQuotationById(quotationResponse[0]));
        } else {
          if (offlineData) {
            dispatch(setQuotationById(offlineData));
          } else {
            console.log("Fetch quotation by Id Error: ");
            toast.error("There is issue in fetching details of Quotation");
          }
        }
      })
      .catch((error) => {
        if (offlineData) {
          dispatch(setQuotationById(offlineData));
        } else {
          toast.error("There is issue in fetching details of Quotation");
          console.error("Fetch quotation by Id Error: ", error);
        }
      })
      .finally(() => {
        dispatch(setQuotationLoader("quotationDetailLoader", false));
      });
  };
}

export function addQuotation(payload, onAddSuccess, quotationItems = []) {
  return async (dispatch, getState) => {
    dispatch(setQuotationLoader("addLoader", true));

    payload = {
      ...payload,
      startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startTime: combineDateAndTime(payload.startDate, payload.startTime),
      endDate: combineDateAndTime(payload.endDate, payload.endTime),
      endTime: combineDateAndTime(payload.endDate, payload.endTime),
    };

    console.log("Add quotations payloadaction: ", payload);

    const dataToSend = {
      title: "",
      description: "",
      mediaLinks: [],
      categories: [],
      status: 2,
      customerId: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      offerAssignments: [],
      paidAmount: 0,
      rate: 0,
      rateType: 1,
      target: 0,
      targetType: 0,
      paymentByCustomerStatus: 1,
      mapAddress: "",
      lat: "",
      lng: "",
      proximity: 0,
      visibility: 0,
      ...payload,
    };

    let newQuotation = null;

    await apiQuotationCreate
      .post(dataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          console.log("Add Quotation Response: ", response);

          const quotationId = response.data;

          const {
            totalAmount,
            amount,
            amountAfterDiscount,
            discount,
            discountRate,
            taxableAmount,
            tax,
            taxRate,
          } = getTotalAmountValues(
            payload.rate,
            payload.discount,
            payload.taxRate,
            payload.includeTax
          );

          newQuotation = {
            ...payload,
            id: quotationId,
            totalAmount,
            amount,
            amountAfterDiscount,
            discount,
            discountRate,
            taxableAmount,
            tax,
            taxRate,
          };

          dispatch(setQuotationById(newQuotation));

          dispatch(setIsNewSubTaskAdded(true));

          // quotation item processing
          if (quotationItems.length) {
            await dispatch(
              addMultipleQuotationItems(quotationItems, newQuotation)
            );
          }

          if (onAddSuccess) {
            onAddSuccess(newQuotation);
          }

          toast.success("Quotation added successfully!!");
        } else {
          console.log("Add Quotation Error: ");
          toast.error("Error in adding Quotation!!");
        }
      })
      .catch((error) => {
        console.error("Add Quotation Error: ", error);
        toast.error("Error in adding Quotation!!");
      })
      .finally(() => {
        dispatch(setQuotationLoader("addLoader", false));
      });

    return newQuotation;
  };
}

export function updateQuotation(payload, showToast = false, onAddSuccess) {
  return async (dispatch, getState) => {
    dispatch(setQuotationLoader("editLoader", true));

    payload = {
      ...payload,
      startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startTime: combineDateAndTime(payload.startDate, payload.startTime),
      endDate: combineDateAndTime(payload.endDate, payload.endTime),
      endTime: combineDateAndTime(payload.endDate, payload.endTime),
      rate: parseNumber(payload.amount, "float"),
    };

    const dataToSend = {
      id: "",
      title: "",
      description: "",
      categories: [],
      customerId: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      rate: 0,
      rateType: 0,
      target: 0,
      targetType: 0,
      mapAddress: "",
      lat: "",
      lng: "",
      proximity: 0,
      visibility: 1,
      ...payload,
    };

    await apiQuotationEdit
      .post(dataToSend)
      .then((response) => {
        if (response && response.status === 200) {
          const {
            totalAmount,
            amount,
            amountAfterDiscount,
            discount,
            discountRate,
            taxableAmount,
            tax,
            taxRate,
          } = getTotalAmountValues(
            payload.rate,
            payload.discount,
            payload.taxRate,
            payload.includeTax
          );

          const newQuotation = {
            ...payload,
            totalAmount,
            amount,
            amountAfterDiscount,
            discount,
            discountRate,
            taxableAmount,
            tax,
            taxRate,
          };

          dispatch(setQuotationById(newQuotation));

          if (onAddSuccess) onAddSuccess();

          if (showToast) {
            toast.success("Quoation edited successfully!!");
          }
        } else {
          console.log("Edit Quoation Error: ");
          if (showToast) {
            toast.success("There is issue in updating quotation!!");
          }
        }
      })
      .then(() => {
        if (payload.status === QuotationStatus.SentRejected) {
          const updateStatusPayload = {
            ...payload,
            status:
              payload.status === QuotationStatus.SentRejected
                ? QuotationStatus.Draft
                : payload.status,
            meta: "",
          };

          dispatch(updateQuotationStatus(updateStatusPayload));
        }
      })
      .catch((error) => {
        console.error("Edit Quoation Error: ", error);
        toast.error("There is issue in updating quotation");
      })
      .finally(() => {
        dispatch(setQuotationLoader("editLoader", false));
      });
  };
}

export function deleteQuotation(payload) {
  return async (dispatch, getState) => {
    dispatch(setQuotationLoader("deleteLoader", true));

    const { quotations } = getState().quotations;

    console.log("Delete Quotation payloadaction: ", payload);

    const quotationId = payload.id;
    const dataToSend = {
      id: quotationId,
      undo: true,
    };

    await apiQuotationRemove
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          console.log("Remove Employee Response: ", response);

          const tempQuotations = quotations.filter(
            (quotation) => quotation.id !== quotationId
          );

          dispatch(setQuotations(tempQuotations));
          toast.success("Quotation removed successfully!!");
        } else {
          console.log("Delete Quotation Error: ");
          toast.error("Error in removing Quotation!!");
        }
      })
      .catch((error) => {
        console.error("Delete Quotation Error: ", error);
        toast.error("Error in removing Quotation!!");
      })
      .finally(() => {
        dispatch(setQuotationLoader("deleteLoader", false));
      });
  };
}

export function convertToJob(payload, onCreateJob) {
  return (dispatch, getState) => {
    //create job and redirect to job details

    const { quotationItems } = getState().quotationItems;

    const subTasks = quotationItems.map((item) => ({
      title: item.title,
      description: item.description,
      categories: item.categories,
      status: item.status,
      startDate: item.startDate,
      endDate: item.endDate,
      startTime: item.startTime,
      endTime: item.endTime,
      subTaskAssignments: item.offerItemAssignments,
      rate: item.rate ? item.rate : 0,
      rateType: item.rateType,
      target: item.target ? item.target : 0,
      targetType: item.targetType,
    }));

    dispatch(createJob(payload, subTasks, onCreateJob, "quotation"));
  };
}

export const resetQuotationSearch = (onlyClientSide = false) => {
  return (dispatch) => {
    const quotationSearchParams = {
      customer: null,
      categories: [],
      quotation: null,
      offerNumber: "",
      sentOn: null,
      validForDays: null,
      statuses: [],
      isToday: false,
    };

    if (onlyClientSide) {
      dispatch(setQuotationSearchParams(quotationSearchParams));
    } else {
      dispatch(handleQuotationSearch(quotationSearchParams));
    }
  };
};

export function handleQuotationSearch({ pageSize, ...searchParams }) {
  return async (dispatch) => {
    console.log("Quotation Filter Values: ", searchParams);

    await dispatch(setQuotationSearchParams(searchParams));

    const searchPayload = {
      customerIds: searchParams.customer ? [searchParams.customer.id] : null,
      categories: searchParams.categories,
      offerNumbers: searchParams.offerNumber
        ? [searchParams.offerNumber]
        : null,
      startDate: searchParams.sentOn
        ? moment(searchParams.sentOn).startOf("day")
        : null,
      dueInDays: searchParams.validForDays ? searchParams.validForDays : null,
      ...(searchParams.quotation
        ? {
            ...(searchParams.quotation.enterKey
              ? { searchText: searchParams.quotation.title }
              : {}),
            ...(!searchParams.quotation.enterKey
              ? { ids: [searchParams.quotation.id] }
              : {}),
          }
        : {}),
      ...(pageSize !== undefined || pageSize !== null ? { pageSize } : {}),
    };

    await dispatch(fetchQuotations(searchPayload));
  };
}

export function assignQuotation(payload) {
  return async (dispatch, getState) => {
    dispatch(setQuotationLoader("editLoader", true));

    const dataToSend = payload.offerAssignments;

    await apiQuotationAssign
      .post(dataToSend)
      .then((response) => {
        if (response && response.status === 200) {
          const { quotations } = getState().quotations;

          const tempQuotations = quotations.map((quotation) =>
            quotation.id === payload.id ? payload : quotation
          );

          dispatch(setQuotations(tempQuotations));
          dispatch(setQuotationById(payload));

          toast.success("Quoation assigned successfully!!");
        } else {
          console.log("Assign Quoation Error: ");
          toast.success("There is issue in assigning quotation!!");
        }
      })
      .catch((error) => {
        console.error("Assign Quoation Error: ", error);
        toast.error("There is issue in assigning quotation");
      })
      .finally(() => {
        dispatch(setQuotationLoader("editLoader", false));
      });
  };
}

export const updateQuotationStatus = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setQuotationLoader("editLoader", true));

    const dataToSend = {
      offerId: payload.id,
      status: payload.status,
      meta: payload.meta,
    };

    await apiQuotationChangeStatus
      .post(dataToSend)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(setQuotationById(payload));

          toast.success("Quotation status updated successfully!!");
        } else {
          console.log("Error: Quotation status: Response", response);
          toast.error("There is issue in updating the status of quotation.");
        }
      })
      .then(() => {
        if (
          payload.status === QuotationStatus.Sent &&
          payload.visibility === JobVisibilityForCustomer.NotVisible
        ) {
          payload = {
            ...payload,
            visibility: JobVisibilityForCustomer.Visible,
          };
          dispatch(updateQuotation(payload, false));
        }
      })
      .catch((error) => {
        console.log("Error: Quotation status: ", error);
        toast.error("There is issue in updating the status of quotation.");
      })
      .finally(() => dispatch(setQuotationLoader("editLoader", false)));
  };
};

export const generateQuotationPdf = (
  quotation,
  onGeneratePdfComplete,
  download
) => {
  return async (dispatch, getState) => {
    dispatch(setQuotationLoader("isPdfGenerating", true));

    try {
      let authUserCompany = LocalStorageConstants.getItem(
        "authUserCompany",
        {}
      );

      await dispatch(getCompanyById([authUserCompany.id]));

      const { companyById } = getState().companies;

      await dispatch(getCompanyProfilePic(companyById.profilePic));

      const customerPayload = [quotation.customerId];
      await dispatch(getCustomerById(customerPayload));

      let { quotationItems } = getState().quotationItems;

      if (!quotationItems.length) {
        const quotationItemsPayload = {
          offerId: quotation.id,
        };
        await dispatch(fetchQuotationItems(quotationItemsPayload));

        quotationItems = getState().quotationItems.quotationItems;
      }

      const { companyProfilePic } = getState().companies;
      const { customerById } = getState().customers;

      const htmlString = await generateQuotationTemplate(
        quotation,
        quotationItems,
        customerById,
        companyById,
        companyProfilePic
      );

      const watermark = {
        text: "Quotation",
        image: companyProfilePic || AppConstants.DEFAULT_PDF_LOGO,
      };

      await generatePdf(
        `${quotation.title}.pdf`,
        htmlString,
        watermark,
        onGeneratePdfComplete,
        download
      );

      if (download) {
        toast.success(`${quotation.title} PDF downloaded successfully.`);
      }
    } catch (error) {
      console.error("Error: PDF Generate: ", error);
      toast.error("Error in generating PDF, please try again.");
    } finally {
      dispatch(setQuotationLoader("isPdfGenerating", false));
    }
  };
};

export const processAndUploadQuotationMedia = (payload, isConvert = false) => {
  return async (dispatch, getState) => {
    const { mediasToUpload, mediaLinks } = payload;

    dispatch(setQuotationLoader("uploadQuotationMediaLoader", true));

    console.log("processAndUploadMedia STARTED");
    const uIds = mediasToUpload.map((item) => v4());

    console.time("time_initRes");
    // INIT UPLOAD
    const initRes = await apiQuotationInitUpload
      .post({ ids: uIds })
      .catch((error) =>
        console.log("processAndUploadMedia: initupload error: ", error)
      );
    console.timeEnd("time_initRes");

    console.log("processAndUploadMedia: initRes: ", initRes);

    console.time("time_uploadMediasPromise");

    // UPLOAD
    const uploadMediasPromise = mediasToUpload.map(async (media, index) => {
      console.log("processAndUploadMedia: media: ", media);

      console.time("time_getMediaData" + index);

      const { mediaBlob, thumbnailBlob } = await getMediaData(media, isConvert);
      console.timeEnd("time_getMediaData" + index);

      console.log("====== media.type media.type media.type=====", media.type);

      try {
        console.time("time_fetch");

        fetch(initRes.data.links[index].m, {
          method: "PUT",
          body: mediaBlob,
          headers: { "Content-Type": media.type },
        });
        fetch(initRes.data.links[index].t, {
          method: "PUT",
          body: thumbnailBlob,
          headers: { "Content-Type": "image/png" },
        });
        fetch(initRes.data.links[index].b, {
          method: "PUT",
          body: mediaBlob,
          headers: { "Content-Type": media.type },
        });
        console.timeEnd("time_fetch");
      } catch (error) {
        console.log(
          "===processAndUploadMedia====apiInitUpload error: ==",
          error
        );
        media = null;
      }

      return media;
    });

    console.timeEnd("time_uploadMediasPromise");

    let uploadSuccessMedias = [];
    console.time("time_uploadMediasPromisePromise.all");
    uploadSuccessMedias = await Promise.all(uploadMediasPromise);
    console.timeEnd("time_uploadMediasPromisePromise.all");

    uploadSuccessMedias = uploadSuccessMedias.filter((media) => Boolean(media));

    if (!uploadSuccessMedias.length) {
      toast.error(
        `0 of ${mediasToUpload.length} medias uploaded successfully.`
      );
      dispatch(setQuotationLoader("uploadQuotationMediaLoader", false));
      return;
    }

    const newMedias = uploadSuccessMedias.map((media, index) => ({
      id: uIds[index],
      title: media.name,
      fileType: media.type,
      abstract: "",
      link: uIds[index],
      mediaType: payload.mediaType,
      offerId: payload.offerId,
    }));

    const medias = [...newMedias, ...mediaLinks].map((media) => {
      return {
        link: media.id,
        mediaType: media.mediaType,
        offerId: media.offerId,
        title: media.title,
        fileType: media.fileType,
      };
    });

    const dataToSend = {
      offerId: payload.offerId,
      medias: medias,
    };

    console.log("processAndUploadMedia: dataToSend: ", dataToSend);

    console.time("time_apiQuotationUploadMedia");

    await apiQuotationUploadMedia
      .post(dataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          const { quotationById } = getState().quotations;

          let newMedias = uploadSuccessMedias.map(async (media, index) => {
            const { thumbnailBase64, fileType } = await getMediaData(
              media,
              isConvert
            );

            console.log(
              "======processAppointmentMediaUpload===thumbnailBase64===",
              thumbnailBase64
            );

            return {
              id: uIds[index],
              title: media.name,
              fileType: fileType,
              link: thumbnailBase64,
              mediaType: payload.mediaType,
              offerId: payload.id,
            };
          });

          newMedias = await Promise.all(newMedias);

          if (quotationById) {
            dispatch(
              setQuotationById({
                ...quotationById,
                mediaLinks: [...newMedias, ...quotationById.mediaLinks],
              })
            );
          }

          toast.success(
            `${uploadSuccessMedias.length} of ${mediasToUpload.length} medias uploaded successfully.`
          );
        } else {
          console.log("Upload Media Error: response: ", response);
          toast.error(
            "There is some issues in uploading job media, please try again!"
          );
        }
      })
      .catch((error) => {
        console.log("Upload Media Error: ", error);
        toast.error(
          "There is some issues in uploading job media, please try again!"
        );
      })
      .finally(() => {
        dispatch(setQuotationLoader("uploadQuotationMediaLoader", false));
      });

    console.timeEnd("time_apiQuotationUploadMedia");
  };
};

export const getQuotationMediaLinks = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setQuotationLoader("getQuotationMediaLoader", true));

    let dataToSend = {
      ids: payload.mediaLinks.map((image) => image.id),
      size: MediaSizeType.Thumbnail,
    };

    console.log("getMediaLinks: dataToSend: ", dataToSend);

    await apiQuotationGetMediaLink
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          let newMedias = [];
          console.log("media fetch response: ", response.data.links);
          if (response.data.links.length) {
            newMedias = response.data.links.map((link) => {
              let mediaLink = payload.mediaLinks.find(
                (mediaLink) => link.id === mediaLink.id
              );

              if (mediaLink) {
                return {
                  ...mediaLink,
                  link: link.link,
                };
              } else {
                return mediaLink;
              }
            });

            const { quotationById } = getState().quotations;

            console.log(
              "====getMediaLinks====quotationById=====",
              quotationById
            );

            const updatedQuotation = {
              ...quotationById,
              mediaLinks: newMedias,
            };
            dispatch(setQuotationById(updatedQuotation));

            console.log(
              "====getMediaLinks====updatedQuotation=====",
              updatedQuotation
            );

            console.log("====getMediaLinks====newMedias=====", newMedias);
          }
        } else {
          console.log("Media Load Error: response: ", response);
        }
      })
      .catch((error) => {
        console.log("Media Load Error: ", error);
      })
      .finally(() => {
        dispatch(setQuotationLoader("getQuotationMediaLoader", false));
      });
  };
};

export const deleteQuotationMedia = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setQuotationLoader("deleteQuotationMediaLoader", true));

    const docToDeleteId = payload.id;
    const newList = payload.mediaLinks.filter(
      (image) => image.id !== docToDeleteId
    );

    const medias = newList.map((doc) => {
      return {
        link: doc.id,
        offerId: payload.offerId,
        mediaType: doc.mediaType,
      };
    });

    const dataToSend = {
      offerId: payload.offerId,
      medias: medias,
    };

    console.log("DELETE MEDIA", dataToSend);
    console.log(docToDeleteId);

    await apiQuotationUploadMedia
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          console.log("delete media response: ", response);

          const { quotationById } = getState().quotations;

          const updatedQuotation = {
            ...quotationById,
            mediaLinks: newList,
          };

          dispatch(setQuotationById(updatedQuotation));

          toast.success("Media deleted successfully!!!");
        } else {
          console.log("Delete media Error: ", response);
          toast.error("There was an issue with deleting media!!!");
        }
      })
      .catch((error) => {
        console.log("Delete media Error: ", error);
        toast.error("There was an issue with deleting media!!!");
      })
      .finally(() => {
        dispatch(setQuotationLoader("deleteQuotationMediaLoader", false));
      });
  };
};

export const getResolvedQuotationByIds = (
  payload,
  onResolveMediasByIds,
  openMedia = true,
  returnAll = false
) => {
  return async (dispatch, getState) => {
    const { clickedMedia = {}, allMedias = [] } = payload;

    const ids = returnAll
      ? allMedias.map((media) => media.id)
      : clickedMedia
      ? [clickedMedia.id]
      : [];

    const dataToSend = {
      ids: ids,
      size: MediaSizeType.BigScreen,
    };

    dispatch(setQuotationLoader("getQuotationMediaLoader", true));

    console.log("getMediaLinks: dataToSend: ", dataToSend);

    await apiQuotationGetMediaLink
      .post(dataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          console.log("media fetch response: ", response.data.links);

          const mediaLinks = response.data.links;

          if (mediaLinks.length) {
            let allResolvedMedias = [];
            if (returnAll) {
              allResolvedMedias = mediaLinks.map(async (media, index) => {
                let fileType = null;
                let contentType = null;
                try {
                  const resp = await getContentType(media.link);
                  fileType = resp.fileType;
                  contentType = resp.contentType;
                } catch (error) {
                  toast.error(
                    "Media not found. Please delete and upload the media again."
                  );
                  return;
                }

                const isImage = fileType === FileType.Image;

                return {
                  ...media,
                  uri: media.link,
                  thumbnailUri: allMedias[index].link,
                  fileType: fileType,
                  type: contentType,
                  isResolved: true,
                  isImage,
                };
              });

              allResolvedMedias = await Promise.all(allResolvedMedias);
              allResolvedMedias = allResolvedMedias.filter(Boolean);

              onResolveMediasByIds(null, allResolvedMedias, false);
              dispatch(setResolvedQuotationMedias(allResolvedMedias));
              return;
            }

            const media = mediaLinks.find(
              (_media) => _media.id === clickedMedia.id
            );

            let fileType = null;
            let contentType = null;
            try {
              const resp = await getContentType(media.link);
              fileType = resp.fileType;
              contentType = resp.contentType;
            } catch (error) {
              toast.error(
                "Media not found. Please delete and upload the media again."
              );
              return;
            }

            const isImage = fileType === FileType.Image;
            let resolvedMedia = {
              ...media,
              type: contentType,
              uri: media.link,
              thumbnailUri: clickedMedia.link,
              fileType,
              isResolved: true,
              isImage,
            };

            if (!isImage && openMedia) {
              window.open(resolvedMedia.uri, "_blank");
            }

            allResolvedMedias = mediaLinks.map((media, index) => {
              if (media.id === resolvedMedia.id) {
                return resolvedMedia;
              } else {
                return {
                  ...media,
                  uri: media.link,
                  thumbnailUri: allMedias[index].link,
                  fileType: FileType.Image,
                  isResolved: false,
                  isImage: true,
                };
              }
            });

            allResolvedMedias = await Promise.all(allResolvedMedias);
            allResolvedMedias = allResolvedMedias.filter(Boolean);

            if (onResolveMediasByIds) {
              const isOpeningMedia = openMedia && !isImage;

              onResolveMediasByIds(
                resolvedMedia,
                allResolvedMedias,
                isOpeningMedia
              );
              dispatch(setResolvedQuotationMedias(allResolvedMedias));
            }
          }
        } else {
          console.log("Media Load Error: response: ", response);
        }
      })
      .catch((error) => {
        console.error("Media Load Error: ", error);
        toast.error("There is some issue in opening media.");
      })
      .finally(() => {
        dispatch(setQuotationLoader("getQuotationMediaLoader", false));
      });
  };
};
