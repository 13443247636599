import {
  SET_ALREADY_EXIST_SERVICE_PROVIDERS,
  SET_SERVICE_PROVIDER,
  SET_SERVICE_PROVIDERS,
  SET_SERVICE_PROVIDERS_LOADER,
  SET_SERVICE_PROVIDER_BY_ID,
  SET_SERVICE_PROVIDER_SEARCH_PARAMS,
  SET_HAS_MORE_SERVICE_PROVIDERS,
  TOGGLE_ADD_SERVICE_PROVIDER_DIALOG,
  SET_SERVICE_PROVIDER_SEARCH_MANAGER
} from "../actions/serviceProviderActions";

const initialState = {
  serviceProviders: [],
  serviceProviderById: null,
  alreadyExistServiceProviders: [],
  isAddServiceProviderDialog: false,
  allServiceProvidersLoader: {
    serviceProvidersLoader: false,
    moreServiceProvidersLoader: false,
    editLoader: false,
    addLoader: false,
    deleteLoader: false,
    serviceProviderDetailLoader: false
  },
  hasMoreServiceProviders: true,
  serviceProviderSearchParams: {
    serviceProvider: null,
    email: "",
    phone: "",
    address: ""
  },
};

export const serviceProviderReducer = (state = initialState, { type, payload }) => {

  switch (type) {
    case SET_SERVICE_PROVIDERS:
      state = {
        ...state,
        serviceProviders: payload
      };
      return state;

    // case SET_SERVICE_PROVIDER:
    //   state = {
    //     ...state,
    //     serviceProviders: payload
    //   };
    //   return state;

    case SET_SERVICE_PROVIDERS_LOADER:
      state = {
        ...state,
        allServiceProvidersLoader: {
          ...state.allServiceProvidersLoader,
          [payload.loaderType]: payload.loaderState
        }
      };
      return state;

    case SET_HAS_MORE_SERVICE_PROVIDERS:
      state = {
        ...state,
        hasMoreServiceProviders: payload
      };
      return state;

    case SET_SERVICE_PROVIDER_BY_ID:
      state = {
        ...state,
        serviceProviderById: payload
      };
      return state;

    case TOGGLE_ADD_SERVICE_PROVIDER_DIALOG:
      state = {
        ...state,
        isAddServiceProviderDialog: payload
      };
      return state;

    case SET_SERVICE_PROVIDER_SEARCH_PARAMS:
      state = {
        ...state,
        serviceProviderSearchParams: payload
      };
      return state;

    case SET_ALREADY_EXIST_SERVICE_PROVIDERS:
      state = {
        ...state,
        alreadyExistServiceProviders: payload
      };
      return state;


    default:
      return state;
  }

};