import { makeStyles, Typography, useTheme } from "@material-ui/core";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import IconCNO from "../IconCNO/IconCNO";
import { IconMain } from "../IconCNO";

function ChipCNO({
  icon,
  name,
  active,
  isCategoryChip = false,
  onRemoveChip,
  disabled = false,
  onClick,
  ...rest
}) {
  const theme = useTheme();

  const styles = useStyles({ active });

  const handleClick = () => {
    if (disabled) return;
    typeof onClick === 'function' && onClick();
  }

  return (
    <div className={styles.container} onClick={handleClick} {...rest}>
      {isCategoryChip ? (
        <IconMain
          name={icon}
          size="1.2rem"
          color={
            active
              ? theme.colors.CHIP_BUTTON_SELECTED_TEXT_COLOR
              : theme.colors.CHIP_BUTTON_ICON_COLOR
          }
          styles={{
            marginRight: theme.spacing(1),
          }}
        />
      ) : (
        icon[0] !== 'M' ? <IconMain name={icon} size="1.2rem" className={styles.icon} /> : <Icon path={icon} size="1.2rem" className={styles.icon} />
      )}
      <Typography component="pre" className={styles.name}>
        {name}
      </Typography>
      {onRemoveChip && (
        <Icon
          path={mdiClose}
          size="1.1rem"
          className={styles.deleteIcon}
          onClick={(e) => {
            if (disabled) return;
            e.stopPropagation();
            onRemoveChip();
          }}
        />
      )}
    </div>
  );
}

export default ChipCNO;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // boxShadow: `2px 2px 4px -1px ${theme.colors.CHIP_BUTTON_SHADOW_COLOR}`,
    border: `1px solid ${theme.colors.CHIP_BUTTON_SHADOW_COLOR}`,
    width: "fit-content",
    borderRadius: 10,
    background: ({ active }) =>
      active
        ? theme.colors.CHIP_BUTTON_SELECTED_BACKGROUND_COLOR
        : theme.colors.CHIP_BUTTON_BACKGROUND_COLOR,
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    color: ({ active }) =>
      active
        ? theme.colors.CHIP_BUTTON_SELECTED_TEXT_COLOR
        : theme.colors.CHIP_BUTTON_ICON_COLOR,
  },
  deleteIcon: {
    marginLeft: theme.spacing(1),
    color: theme.colors.CHIP_BUTTON_DELETE_ICON_COLOR,
    background: theme.colors.CHIP_BUTTON_DELETE_ICON_BACKGROUND_COLOR,
    borderRadius: "50%",
    padding: 3,
  },
  name: {
    fontSize: "0.8rem",
    userSelect: "none",
    color: ({ active }) =>
      active
        ? theme.colors.CHIP_BUTTON_SELECTED_TEXT_COLOR
        : theme.colors.CHIP_BUTTON_TEXT_COLOR,
  },
}));
