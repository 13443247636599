import { Box } from '@material-ui/core';
import { Formik } from 'formik'
import React from 'react'
import * as Yup from "yup";
import { FormFieldsTypes } from '../utils/enums';
import { AddressAttributes, AutoCompleteAttributes, ChipFieldAttributes, ChipOptionsArray, CommonFieldAttributes, DateTimeAttributes, FieldTypeSelect, FormInput, InputFieldAttributes, SelectOptionsArray } from './component';
import FormActionButtons from '../../../components/FormActionButtons';
import { Spacer } from '../../../library';

Yup.addMethod(Yup.MixedSchema, "oneOfSchemas", function (schemas) {
  return this.test(
    "one-of-schemas",
    "Not all items in ${path} match one of the allowed schemas",
    (item) =>
      schemas.some((schema) => schema.isValidSync(item, { strict: true }))
  );
});



const AddFieldForm = ({ fieldInitialValues, onAddField, onClose }) => {

  const initialValues = {
    type: FormFieldsTypes.ids.Text,
    name: '',
    label: '',
    helperText: '',

    validations: {
      type: 'string',
      required: {
        value: false,
      },
      maxLength: {
        value: 255
      }
    },
    order: 1,

    // field type based attributes
    // input
    attributes: {
      defaultValue: "",
      multiline: false,
      numberOfLines: 10,
    },

    ...fieldInitialValues
  }

  const validationSchema = Yup.object().shape({
    type: Yup.string().required('Field type is required'),
    name: Yup
      .mixed()
      .oneOfSchemas([
        Yup.string().required("Field name is required."),
        Yup.object().required("Field name is required."),
      ]),
    label: Yup
      .mixed()
      .oneOfSchemas([
        Yup.string().required("Field label is required."),
        Yup.object().required("Field label is required."),
      ]),
    helperText: Yup.string(),
    validations: Yup.object(),
    order: Yup.number()
      .typeError('Field order should be a number')
      .required('Field order is required')
  })

  const handleAddField = (values) => {

    typeof onAddField === 'function' && onAddField(values);

    typeof onClose === 'function' && onClose();

  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleAddField}
    >
      {
        function AddFieldFormik(formik) {

          console.log("AddFieldFormik: values: ", formik.values);
          console.log("AddFieldFormik: errors: ", formik.errors);

          return (
            <Spacer>

              <FieldTypeSelect formik={formik} />

              <InputFieldAttributes formik={formik} />

              <ChipFieldAttributes formik={formik} />

              <SelectOptionsArray formik={formik} />

              <AutoCompleteAttributes formik={formik} />

              <DateTimeAttributes formik={formik} />

              <AddressAttributes formik={formik} />

              <CommonFieldAttributes formik={formik} />

              {/* <SwitchAttributes formik={formik} /> */}

              {/* <CheckBoxAttributes formik={formik} /> */}

              <FormActionButtons
                rightText={fieldInitialValues ? 'Update Field' : 'Add Field'}
                rightClick={formik.handleSubmit}
                formik={formik}
                leftClick={onClose}
              />

            </Spacer>
          )
        }
      }
    </Formik>
  )
}

export default AddFieldForm