import {
  FETCH_SOFT_TRACK_DATA,
  FETCH_TRACK_SEARCH,
  RESET_TRACK_SEARCH_MARKERS,
  SET_IS_PLOT_JOB_MARKERS,
  SET_MAP_SEARCH_PARAMS,
  SET_TRACK_SEARCH_LOADER,
  TOGGLE_PAY_USER_DIALOG,
  TOGGLE_SOFT_TRACKING,
  TOGGLE_WORK_HOURS_DIALOG,
  IS_WORKING_TODAY_MODAL,
  SET_TRACK_LOADERS,
  SET_CHECKINS_IDS,
  SET_TOTAL_EMPLOYEES_NUMBER
} from "../actions/trackSearchActions";
import { sortTrackDataByDate } from "../utils/Tracking";

import moment from "moment";

const initialState = {
  data: [],
  isWorkHoursDialog: false,
  isPayUserDialog: false,
  isTrackSearchLoading: false,
  isSoftTracking: false,
  softTrackData: [],
  isPlotJobMarkers: false,

  mapSearchParams: {
    teamMember: null,
    trackStartDate: new Date(),
    trackEndDate: null,
    trackStartTime: moment().startOf('day').toDate(),
    trackEndTime: moment().endOf('day').toDate(),
    job: null,
    jobStartDate: null,
    jobEndDate: null,
  },

  isWorkingTodayModal: false,

  checkInsCountLoader: false,

  checkInsIds: [],
  totalEmployeesNumber: 0

};

export default function trackSearchReducer(state = initialState, { type, payload }) {

  switch (type) {
    case FETCH_TRACK_SEARCH:
      payload = sortTrackDataByDate(payload.response);
      state = {
        ...state,
        data: payload,
      };
      return state;

    case RESET_TRACK_SEARCH_MARKERS:
      state = {
        ...state,
        data: [],
        isSoftTracking: false,
        softTrackData: [],
      };
      return state;

    case TOGGLE_WORK_HOURS_DIALOG:
      state = {
        ...state,
        isWorkHoursDialog: payload
      };
      return state;

    case TOGGLE_PAY_USER_DIALOG:
      state = {
        ...state,
        isPayUserDialog: payload
      };
      return state;

    case SET_TRACK_SEARCH_LOADER:
      state = {
        ...state,
        isTrackSearchLoading: payload
      };
      return state;

    case TOGGLE_SOFT_TRACKING:
      state = {
        ...state,
        isSoftTracking: payload
      };
      return state;

    case FETCH_SOFT_TRACK_DATA:
      state = {
        ...state,
        softTrackData: sortTrackDataByDate(payload)
      };
      return state;

    case SET_IS_PLOT_JOB_MARKERS:
      state = {
        ...state,
        isPlotJobMarkers: payload
      };
      return state;

    case SET_MAP_SEARCH_PARAMS:
      state = {
        ...state,
        mapSearchParams: payload
      };
      return state;

    case IS_WORKING_TODAY_MODAL:
      state = {
        ...state,
        isWorkingTodayModal: payload
      };
      return state;

    case SET_TRACK_LOADERS:
      const { loaderName, loaderState } = payload;
      state = {
        ...state,
        [loaderName]: loaderState
      };
      return state;

    case SET_CHECKINS_IDS:
      state = {
        ...state,
        checkInsIds: payload
      };
      return state;

    case SET_TOTAL_EMPLOYEES_NUMBER:
      state = {
        ...state,
        totalEmployeesNumber: payload
      };
      return state;

    default:
      return state;
  }
}