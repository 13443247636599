import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function IconCNO({
  name,
  size,
  color,
  styles,
  ...rest
}) {

  return (
    <Fragment>
      <FontAwesomeIcon
        icon={['fas', name]}
        style={{
          fontSize: size,
          color: color,
          ...styles
        }}
        {...rest}
      />
    </Fragment>
  )
}

export default IconCNO
