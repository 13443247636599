import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { mdiClockEnd, mdiClockStart, mdiFormatText } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuotation } from '../../actions/quotationActions';
import DialogCNO from '../../library/DialogCNO';
import { calendarStrings, DATE_FORMAT, TIME_FORMAT } from '../../utils/formatDate';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import FormActionButtons from '../FormActionButtons';
import * as yup from 'yup';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { QuotationStatus } from '../../utils/mappings';
import InputCNO from '../../library/InputCNO';
import RightsManager from '../../utils/RightsManager';
import { formatTimeWithTZ } from '../../utils/Helpers';

const EditTimelineDialog = ({
  open,
  onClose,
  quotation,
}) => {

  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const dispatch = useDispatch();
  const { allQuotationLoader: { editLoader } } = useSelector(state => state.quotations);

  console.log("===== EditTimelineDialog ", quotation.sentDate, quotation.validDate);

  const formik = useFormik({
    initialValues: {
      sentDate: quotation.offerDate,
      validDate: quotation.dueInDays,
    },
    validationSchema: yup.object({
      sentDate: yup.date('Invalid Date').required("Sent On date is required."),
      validDate: yup.number('Invalid Date').required("Valid for days is required."),
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {

    console.log("Quotation edit values: ", values);

    const updateQuotationPayload = {
      ...quotation,
      offerDate: values.sentDate,
      dueInDays: values.validDate,
    };

    dispatch(updateQuotation(updateQuotationPayload));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Quotation"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <div className={GlobalStyles.timeStampWrapper}>
          <div>
            <Typography component='label'>Send On Date</Typography>
            <KeyboardDatePicker
              className={clsx(GlobalStyles.picker, styles.start)}
              margin="dense"
              value={formik.values.sentDate}
              format="MMM dd, yyyy"
              inputVariant='outlined'
              placeholder='Send On Date'
              name='sentDate'
              onChange={date => {
                formik.setFieldTouched('sentDate', true);
                formik.setFieldValue('sentDate', date);
              }}
              error={formik.touched.sentDate && Boolean(formik.errors.sentDate)}
              helperText={formik.errors.sentDate}
              fullWidth
            />
          </div>
        </div>
        <InputCNO
          name='validDate'
          label='Valid for days'
          formik={formik}
          placeholder='Enter valid for days'
          icon={mdiFormatText}
          fullWidth
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )

}

function QuotationTimelineDetailsCard({ quotation }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isCompanyAdmin = RightsManager.isGroupLeader();

  const [isTimelineEditable, setIsTimelineEditable] = useState(false);

  return (
    <div className={GlobalStyles.detailsCardContainer}>
      <Typography className={GlobalStyles.detailsCardSectionTitle}>
        Quotation Timeline
      </Typography>
      <div className={GlobalStyles.detailsCardSection} onClick={() => setIsTimelineEditable(true)}>
        <div className={GlobalStyles.detailsRow}>
          <Typography style={{ color: theme.colors.JOB_START_DATE_COLOR }}>
            <Moment format={DATE_FORMAT} >
              {formatTimeWithTZ(quotation.offerDate)}
            </Moment>
          </Typography>
          <Icon
            path={mdiClockStart}
            size='2rem'
            color={theme.colors.JOB_START_DATE_COLOR}
          />
        </div>
        {
          // isUserAdmin &&
          <div className={GlobalStyles.detailsRow}>
            <Typography style={{ color: theme.colors.JOB_END_DATE_COLOR }}>
              Valid for {quotation.dueInDays} days
            </Typography>
            <Icon
              path={mdiClockEnd}
              size='2rem'
              color={theme.colors.JOB_END_DATE_COLOR}
            />
          </div>
        }
      </div>

      {
        isCompanyAdmin &&
        isTimelineEditable &&
        <EditTimelineDialog
          open={isTimelineEditable}
          onClose={() => setIsTimelineEditable(false)}
          quotation={quotation}
        />
      }

    </div>
  )
}

export default QuotationTimelineDetailsCard;

const useStyles = makeStyles((theme) => ({
  start: {
    '& .MuiSvgIcon-root': {
      color: theme.colors.JOB_START_DATE_COLOR
    }
  },
  end: {
    '& .MuiSvgIcon-root': {
      color: theme.colors.JOB_END_DATE_COLOR
    }
  },
}));
