import { Box, Button } from '@material-ui/core'
import { FieldArray } from 'formik'
import React from 'react'
import { InputCNO } from '../../../../library'
import { IconMain } from '../../../../library/IconCNO'
import { v4 } from 'uuid'
import { FormFieldsTypes } from '../../utils/enums'

function SelectOptionsArray({ formik }) {

  return (
    <>
      {
        formik.values.type === FormFieldsTypes.ids.Select ?
          <FieldArray name='attributes.options'>
            {
              function SelectOptions(fieldArray) {

                const handleAddSelectOption = () => {
                  fieldArray.push(
                    {
                      id: v4(),
                      label: '',
                      value: ''
                    }
                  )
                }

                return (
                  <Box py={1} px={2}>
                    {
                      formik.values.attributes.options.map((option, index) => {

                        const optionAccessorKey = `attributes.options.${index}`;

                        return (
                          <Box display='flex' alignItems='center' >

                            <InputCNO
                              name={`${optionAccessorKey}.label`}
                              placeholder='Enter option title...'
                              formik={formik}
                              fullWidth
                              containerStyles={{ width: '100%' }}
                              onChange={(e) => {

                                const labelValue = e.target.value;
                                const fieldValue = labelValue.toLowerCase().split(' ').join('_');

                                formik.setFieldValue(`${optionAccessorKey}.label`, labelValue);
                                formik.setFieldValue(`${optionAccessorKey}.value`, fieldValue);
                              }}
                            />

                            <IconMain name='trash-can' onClick={() => fieldArray.remove(index)} />
                          </Box>
                        )
                      })
                    }
                    <Button variant='contained' onClick={handleAddSelectOption}>Add Select Option</Button>
                  </Box>
                )

              }
            }
          </FieldArray>
          : null
      }
    </>

  )
}

export default SelectOptionsArray