import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_CalendarCNO: {
    padding: '20px',
    margin: '10px',
    marginTop: '20px',
    minHeight: 'calc(80vh - 80px)',
    minHeight: '-webkit-max-content',
    minHeight: '-moz-max-content',
    minHeight: 'max-content',
    height: 'calc(100vh - 100px)',
    borderRadius: '10px',
    background: theme.colors.CALENDAR_BACKGROUND_COLOR,
    borderColor: theme.colors.CALENDAR_BORDER_COLOR,
    color: theme.colors.CALENDAR_DATE_TEXT_COLOR,
    '&:hover': {
      cursor: 'pointer'
    },
  },
  toolTipCalendar: {
    backgroundColor: '#1b66c3',
    borderColor: theme.colors.CALENDAR_BORDER_COLOR,
    color: '#f5e4e4',
    padding: '10px',
    margin: '10px',
    borderRadius: '10px',
    zIndex: 200,
    flex: 1,
    minWidth: 200,
  },

  loadMoreFromServerLink: {
    color: theme.colors.LIST_CARD_TITLE_COLOR,
    fontSize: '0.8rem',
    border: `1px solid ${theme.colors.LIST_CARD_TITLE_COLOR}`,
    borderRadius: 4,
    padding: '4px 8px'
  },
  warning:{
    color:'red'
  },
  success:{
    color:'#42C00C'
  }
}));