import { Box, Button, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { AddFieldDialog } from '../dialogs';
import { IconMain } from '../../../library';

const AddFieldButton = ({ onAddField }) => {

  const theme = useTheme();

  const [isAddFieldDialog, setIsAddFieldDialog] = useState(false);

  return (
    <Box>
      <Button
        style={{
          backgroundColor: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
          color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR
        }}
        variant='contained'
        onClick={() => setIsAddFieldDialog(true)}
        startIcon={<IconMain name='plus' size="1.1rem" color={'#ddd'} />}
      >
        Add Field
      </Button>

      <AddFieldDialog
        open={isAddFieldDialog}
        onClose={() => setIsAddFieldDialog(false)}
        onAddField={onAddField}
      />

    </Box>
  )
}

export default AddFieldButton