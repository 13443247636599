import { Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from './CompanyCategoryDetailsCardStyles';
import ChipCNO from '../../library/ChipCNO';
import { TrackingMode } from '../../utils';
// import { mdiCar, mdiMapMarkerPath, mdiPlus, mdiWalk } from '@mdi/js';
import * as icons from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmployeeDetails } from '../../actions/employeeActions';
import { getMaterialIconString } from '../../utils/icons';
import Icon from '@mdi/react';
import DialogCNO from '../../library/DialogCNO';
import { useFormik } from 'formik';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import InputCNO from '../../library/InputCNO';
import * as yup from 'yup';
import { IconPicker } from '../../library/IconPickerCNO';
import { v4 } from 'uuid';
import { updateCompanyCategories } from '../../actions/customerCompanyActions';
import * as MaterialIcon from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconCNO from '../../library/IconCNO/IconCNO';
import ConfirmationDialogCNO from '../../library/ConfirmationDialogCNO/ConfirmationDialogCNO';
import { apiCompanyCategoriesUpdate, apiTaskSearch } from '../../api';
import { toast } from 'react-toastify';
import FlexibleSpaceCNO from '../../library/FlexibleSpaceCNO/FlexibleSpaceCNO';



const AddNewIconModal = ({
  open,
  onClose,
  profile,
  categories,
  setCategories
}) => {

  const styles = useStyles();

  const dispatch = useDispatch();
  const {
    allCompaniesLoader: { editLoader }
  } = useSelector(state => state.companies);

  const validationSchema = yup.object({
    iconLink: yup.string().required("Required"),
    iconTitle: yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      iconLink: '',
      iconTitle: ''
    },
    validationSchema,
    onSubmit: (values) => addNewCategory(values)
  });

  const addNewCategory = (values) => {

    const newCategory = {
      id: v4(),
      title: values.iconTitle,
      groupId: profile.id,
      iconLink: values.iconLink
    };

    const payload = [newCategory, ...categories];

    console.log('====================================');
    console.log("addNewCategory: values: ", payload);
    console.log('====================================');

    localStorage.setItem('categories', JSON.stringify(payload));
    setCategories(payload);
    dispatch(updateCompanyCategories(payload, profile));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Add New Category"
      loading={editLoader}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>

        <Typography className={styles.label}>Category Icon</Typography>
        <IconPicker
          value={formik.values.iconLink}
          onChange={(value) => formik.setFieldValue('iconLink', value)}
          buttonStyles={{
            background: 'white',
            marginRight: 10,
            width: '100%',
            marginBottom: 10
          }}
        />

        <InputCNO
          name={'iconTitle'}
          label="Category Title"
          formik={formik}
          placeholder='Category Title'
          icon={icons.mdiFormatText}
          fullWidth
        />

        <FormActionButtons
          type="submit"
          rightText="Save"
          leftClick={onClose}
          formik={formik}
          wrapperStyles={{ marginTop: 120 }}
        />
      </form>
    </DialogCNO>
  )
};

function CompanyCategoryDetailsCard({ profile, isActive }) {

  const theme = useTheme();

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const styles = useStyles({ tillTablet });

  const dispatch = useDispatch();

  const [categories, setCategories] = useState(null);

  const [isAddNewIcon, setIsAddNewIcon] = useState(false);
  const [confirmDeleteCategory, setConfirmDeleteCategory] = useState(false);
  const [isDeleteCategoryMsgDialog, setIsDeleteCategoryMsgDialog] = useState(false);

  const [isCategoryDetailsEditable, setIsCategoryDetailsEditable] = useState(false);

  const [currentCategory, setCurrentCategory] = useState(null);


  useEffect(() => {

    let localCategories = localStorage.getItem('categories');
    localCategories = localCategories ? JSON.parse(localCategories) : [];

    if (profile) {
      setCategories(localCategories);
    }

    console.log("localCategories: :", localCategories);

  }, [profile]);


  const handleDeleteCategory = async () => {

    console.log("current category: ", currentCategory);

    const taskSearchPayload = {
      "ids": null,
      "assignedIds": [],
      "categories": [currentCategory.id],
      "orderBy": "newest",
      "pageIndex": 0,
      "pageSize": 10,
      "searchText": "",
      "sortAscending": false,
      "startDate": null,
      "endDate": null
    }

    await apiTaskSearch.post(taskSearchPayload)
      .then((response) => {
        if (response.status === 200) {

          if (response.data.data.length === 0) {

            console.log("Deleting category.....");

            const categoriesTemp = categories.filter((category) => category.id !== currentCategory.id);

            console.log("categoriesTemp: ", categoriesTemp);

            localStorage.setItem('categories', JSON.stringify(categoriesTemp));
            setCategories(categoriesTemp);
            dispatch(updateCompanyCategories(categoriesTemp, profile));

          }
          else {
            console.log('====================================');
            console.log("Job category cannot be deleted because jobs exists in the system for this category. ");
            console.log('====================================');
            setIsDeleteCategoryMsgDialog(true);
          }

        } else {
          console.log("Error in deleting category");
        }
      })
      .catch((error) => {
        console.error("Error in deleting category");

        toast.error("Error in deleting category!")

      });

    setConfirmDeleteCategory(false);

  }

  return (
    <div className={styles.container_CompanyCategoryDetailsCard}>
      <div className={styles.titleWrapper}>
        <Typography className={styles.title}>Categories</Typography>
        <Button
          variant='contained'
          className={styles.addBtn}
          startIcon={<Icon path={icons.mdiPlus} size="1.5rem" color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR} />}
          onClick={() => setIsAddNewIcon(true)}
          disabled={!isActive}
        >
          <pre>Add New</pre>
        </Button>
      </div>

      <div className={styles.chipsWrapper}>
        {
          categories?.map((category) => (
            <ChipCNO
              key={category.id}
              icon={category.iconLink}
              name={category.title}
              onClick={() => {
                setIsCategoryDetailsEditable(true);
                setCurrentCategory(category);
              }}
              isCategoryChip
              onRemoveChip={() => {
                setConfirmDeleteCategory(true);
                setCurrentCategory(category);
              }}
            />
          ))
        }
      </div>

      {
        isAddNewIcon && isActive &&
        <AddNewIconModal
          open={isAddNewIcon}
          onClose={() => setIsAddNewIcon(false)}
          profile={profile}
          categories={categories}
          setCategories={setCategories}
        />
      }

      {
        confirmDeleteCategory &&
        <ConfirmationDialogCNO
          open={confirmDeleteCategory}
          onClose={() => setConfirmDeleteCategory(false)}
          dialogTitle={"Delete Category"}
          messages={["Are you sure you want to delete category?"]}
          yesClick={handleDeleteCategory}
        />
      }

      {
        isDeleteCategoryMsgDialog &&
        <ConfirmationDialogCNO
          open={isDeleteCategoryMsgDialog}
          onClose={() => setIsDeleteCategoryMsgDialog(false)}
          dialogTitle={"Delete Category"}
          messages={["Job category cannot be deleted because jobs exists in the system for this category."]}
          rightText="Ok"
          yesClick={() => setIsDeleteCategoryMsgDialog(false)}
          isLeftButton={false}
          disabled={false}
        />
      }

      {
        isCategoryDetailsEditable &&
        <EditCategoryDetails
          open={isCategoryDetailsEditable}
          onClose={() => setIsCategoryDetailsEditable(false)}
          profile={profile}
          categories={categories}
          setCategories={setCategories}
          selectedCategory={currentCategory}
        />
      }

    </div>
  )
}

export default CompanyCategoryDetailsCard;


const EditCategoryDetails = ({
  open,
  onClose,
  profile,
  categories,
  setCategories,
  selectedCategory
}) => {

  const styles = useStyles();

  const validationSchema = yup.object({
    iconTitle: yup.string().required("Required"),
    iconLink: yup.string().required("Required"),
  })

  const dispatch = useDispatch();
  const {
    allCompaniesLoader: { editLoader }
  } = useSelector(state => state.companies);

  const formik = useFormik({
    initialValues: {
      iconTitle: selectedCategory.title,
      iconLink: selectedCategory.iconLink,
    },
    validationSchema,
    onSubmit: (values) => updateCategory(values)
  });

  const updateCategory = (values) => {

    const newCategory = {
      id: selectedCategory.id,
      title: values.iconTitle,
      groupId: profile.id,
      iconLink: values.iconLink
    };

    // const payload = [newCategory, ...categories];

    const payload = categories.map((category) => {
      if (category.id === selectedCategory.id) {
        return newCategory;
      } else {
        return category;
      }
    });

    console.log('====================================');
    console.log("updateCategoryIcon: values: ", payload);
    console.log('====================================');

    localStorage.setItem('categories', JSON.stringify(payload));
    setCategories(payload);

    dispatch(updateCompanyCategories(payload, profile));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Update Category"
      loading={editLoader}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>

        <Typography className={styles.label}>Category Icon</Typography>
        <IconPicker
          value={formik.values.iconLink}
          onChange={(value) => formik.setFieldValue('iconLink', value)}
          buttonStyles={{
            background: 'white',
            marginRight: 10,
            width: '100%',
            marginBottom: 10
          }}
        />

        <InputCNO
          name={'iconTitle'}
          label="Category Title"
          formik={formik}
          placeholder='Category Title'
          icon={icons.mdiFormatText}
          fullWidth
        />

        <FormActionButtons
          type="submit"
          rightText="Save"
          leftClick={onClose}
          formik={formik}
          wrapperStyles={{ marginTop: 110 }}
        />
      </form>
    </DialogCNO>
  )

};
