import { IconButton, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@material-ui/core';
import { mdiAccountDetails, mdiCancel, mdiCheck, mdiContentCopy, mdiDownload, mdiEye, mdiSend, mdiStamper } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import LinkCNO from '../../library/LinkCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import ConfirmationDialogCNO from '../../library/ConfirmationDialogCNO';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInvoice, generateInvoicePdf, updateInvoiceStatus } from '../../actions/invoiceActions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { InvoiceStatus } from '../../utils/mappings';
import InvoiceRejectDialog from './InvoiceRejectDialog';
import InvoiceSendDialog from './InvoiceSendDialog';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import FileViewerModal from '../../library/FileViewerModal';
import { getInvoiceAcceptHtmlString } from '../../pdfTemplates/templates';
import RightsManager from '../../utils/RightsManager';
import { fetchInvoiceItems, setInvoiceItems } from '../../actions/invoiceItemActions';
import { getCustomerById } from '../../actions/customerActions';
import DuplicateInvoiceDialog from './DuplicateInvoiceDialog';

function InvoiceListCardPopover({ invoice, closeMoreActions }) {

  const isUserAdmin = RightsManager.isAdmin();

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isDraft = invoice.status === InvoiceStatus.Draft;
  const isReady = invoice.status === InvoiceStatus.Ready;
  const isSent = invoice.status === InvoiceStatus.Sent;
  const isSentAccepted = invoice.status === InvoiceStatus.SentAccepted;
  const isSentRejected = invoice.status === InvoiceStatus.SentRejected;

  const isInvoiceDetails = useLocation().pathname.includes("/invoice/");

  const dispatch = useDispatch();

  const { invoiceItems, invoiceItemsLoader } = useSelector(state => state.invoiceItems);
  const { allInvoiceLoader } = useSelector(state => state.invoices);
  const { isPdfGenerating } = allInvoiceLoader;

  const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isReadyToShare, setIsReadyToShare] = useState(false);
  const [pdfFileSource, setPdfFileSource] = useState(null);
  const [isDuplicateDialog, setIsDuplicateDialog] = useState(false);

  const handleCreatePDF = async (download = false) => {

    const onGeneratePdfComplete = (fileSource) => {
      setPdfFileSource(fileSource);
      if (!isInvoiceDetails) {
        dispatch(setInvoiceItems([]));
      }
    };

    await dispatch(generateInvoicePdf(invoice, onGeneratePdfComplete, download));

    if (download) {
      closeMoreActions();
    }

  };

  const handleInvoiceSendDialog = async () => {

    if (!invoiceItems.length) {
      const invoiceItemsPayload = {
        invoiceId: invoice.id
      };
      await dispatch(fetchInvoiceItems(invoiceItemsPayload));


    }
    await dispatch(getCustomerById([invoice.customerId]));
    setIsSend(true);

  };

  return (
    <div className={GlobalStyles.container_MorePopover}>

      {
        (isPdfGenerating || invoiceItemsLoader)
        &&
        <FullScreenLoaderCNO />
      }

      <List className={GlobalStyles.list}>

        {
          !isInvoiceDetails &&
          <LinkCNO
            to={`invoice/${invoice.id}`}
          >
            <ListItem className={GlobalStyles.listItem}>
              <ListItemText primary='Details' />
              <ListItemIcon>
                <IconButton edge='end'>
                  <Icon path={mdiAccountDetails} size='1.5rem'
                    color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </LinkCNO>
        }

        {
          isUserAdmin &&
          (isDraft) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => setIsReadyToShare(true)}
          >
            <ListItemText primary='Mark as Ready' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiCheck} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          (isReady) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={handleInvoiceSendDialog}
          >
            <ListItemText primary='Send Invoice' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiSend} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>

        }

        {
          isUserAdmin &&
          (isSent || isSentAccepted) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={handleInvoiceSendDialog}
          >
            <ListItemText primary='Resend Invoice' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiSend} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          (isSent) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setIsAccept(true);
            }}
          >
            <ListItemText primary='Mark as Paid' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiStamper} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          (isSent) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setIsReject(true);
            }}
          >
            <ListItemText primary='Mark as Rejected' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiCancel} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          <FileViewerModal
            mimeType={'pdf'}
            filePath={pdfFileSource}
            dialogTitle={`Invoice Preview - ${invoice.title}`}
            onClick={handleCreatePDF}
            closeMoreActions={closeMoreActions}
          >
            <ListItem className={GlobalStyles.listItem}>
              <ListItemText primary='Preview Invoice' />
              <ListItemIcon>
                <IconButton edge='end'>
                  <Icon path={mdiEye} size='1.5rem'
                    color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </FileViewerModal>
        }

        {
          !isDraft &&
          <ListItem className={GlobalStyles.listItem} onClick={() => handleCreatePDF(true)}>
            <ListItemText primary='Download Invoice' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiDownload} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          <ListItem className={GlobalStyles.listItem}
            onClick={() => {
              setIsDuplicateDialog(true);
              const payload = { invoiceId: invoice.id };
              dispatch(fetchInvoiceItems(payload));
            }}
          >
            <ListItemText primary='Duplicate' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiContentCopy} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

      </List>

      {
        isDuplicateDialog &&
        <DuplicateInvoiceDialog
          closeMoreActions={closeMoreActions}
          invoice={invoice}
          onClose={() => setIsDuplicateDialog(false)}
          open={isDuplicateDialog}
        />
      }

      {
        isConfirmDeleteModal &&
        <ConfirmationDialogCNO
          open={isConfirmDeleteModal}
          onClose={() => setIsConfirmDeleteModal(false)}
          dialogTitle="Comfirm Delete Invoice"
          messages={["Are you sure you want to delete?"]}
          yesClick={() => dispatch(deleteInvoice(invoice))}
          rightText="Delete"
          closeMoreActions={closeMoreActions}
        />
      }

      {
        (isAccept || isReadyToShare) &&
        <ConfirmationDialogCNO
          open={isAccept || isReadyToShare}
          onClose={() => {
            setIsAccept(false);
            setIsReadyToShare(false);
          }}
          dialogTitle="Update Invoice Status"
          messages={[
            isAccept ? "Are you sure you want to mark it as paid?" : null,
            isReadyToShare ? "Are you sure draft is ready to share?" : null,
          ]}
          yesClick={() => {

            const meta = getInvoiceAcceptHtmlString(invoice);

            const invoicePayload = {
              ...invoice,
              status: isAccept ? InvoiceStatus.SentAccepted : InvoiceStatus.Ready,
              meta: isAccept ? meta : ""
            }
            dispatch(updateInvoiceStatus(invoicePayload))
          }}
          rightText={isAccept ? "Mark Paid" : "Yes"}
          closeMoreActions={closeMoreActions}
        />
      }

      {
        isReject &&
        <InvoiceRejectDialog
          open={isReject}
          onClose={() => setIsReject(false)}
          invoice={invoice}
          closeMoreActions={closeMoreActions}
        />
      }

      {
        isSend &&
        <InvoiceSendDialog
          open={isSend}
          onClose={() => setIsSend(false)}
          invoice={invoice}
          closeMoreActions={closeMoreActions}
        />
      }

    </div >
  )
}

export default InvoiceListCardPopover
