import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import { Typography } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuotationItem } from '../../actions/quotationItemActions';
import { validateStartDateEndDate } from '../../utils/Helpers';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';

function EditQuotationItemTimeline({
  open,
  onClose,
  quotationItem,
}) {

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { editLoader } = useSelector(state => state.quotationItems);

  const formik = useFormik({
    initialValues: {
      startDate: quotationItem.startDate,
      endDate: quotationItem.endDate,
    },
    validationSchema: yup.object({
      startDate: yup.date().required('Start date is required'),
      endDate: yup.date()
        .required('End date is required')
        .test(
          'endDateTimeValidation',
          "End Date can't be less than Start Date.",
          function () {
            const startDate = this.parent.startDate;
            const endDate = this.parent.endDate;

            const isValid = validateStartDateEndDate({ startDate, endDate });

            return isValid;
          }
        ),
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("Edit Quotation Item: timeline: ", values);

    const payload = {
      ...quotationItem,
      startDate: values.startDate,
      endDate: values.endDate,
    };

    dispatch(updateQuotationItem(payload));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Quotation Item Timeline"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>


        <DateTimePickerCNO
          dateName="startDate"
          datePlaceholder="Start Date"
          datePickerLabel='Start by Date'
          onDateChange={date => {
            formik.setFieldValue('startDate', date);
          }}

          formik={formik}
          disablePast={true}
          iconColor="red"
        />

        <DateTimePickerCNO
          dateName="endDate"
          datePlaceholder="Finish Date"
          datePickerLabel='Finish by Date'
          onDateChange={date => {
            formik.setFieldValue('endDate', date);
          }}

          formik={formik}
          minDate={formik.values.startDate}
          iconColor="green"
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditQuotationItemTimeline
