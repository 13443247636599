import { Typography } from '@material-ui/core';
import { mdiCurrencyGbp } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react'
import { useStyles } from './UserWorkingDetailsStyles';
import DialogCNO from '../../library/DialogCNO';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmployeeDetails } from '../../actions/employeeActions';
import RightsManager from '../../utils/RightsManager';
import * as icons from '@mdi/js';
import { Currency, CurrencyIcon } from '../../utils/mappings';
import { getMaterialIconString } from '../../utils/icons';

function EditWorkingDetails({
  profile,
  open,
  onClose,
}) {

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const styles = useStyles();

  const dispatch = useDispatch();
  const { allEmployeesLoader: { editLoader: employeeEditLoader } } = useSelector(state => state.employees);

  const validationSchema = yup.object({
    hourlyRate: yup.string().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      hourlyRate: profile.hourlyRate ? profile.hourlyRate.toString() : '',
    },
    validationSchema,
    onSubmit: values => handleUpdate(values)
  });

  const handleUpdate = (values) => {

    const dataToSend = {
      ...profile,
      hourlyRate: formik.values.hourlyRate ? formik.values.hourlyRate : 0
    };

    dispatch(updateEmployeeDetails(dataToSend));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Working Details"
      loading={employeeEditLoader}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <InputCNO
          name='hourlyRate'
          label='Job Price'
          formik={formik}
          placeholder='Enter Job Rate'
          icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
          fullWidth
        />
        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )
}

function UserWorkingDetails({ profile, isActiveMember, localProfilePicUrl }) {

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const styles = useStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const [isWorkingEditable, setIsWorkingEditable] = useState(false);

  return (
    <div className={styles.container_UserWorkingDetails}>
      <Typography className={styles.title}>Working Details</Typography>
      <div
        className={styles.row}
        onClick={() => {
          setIsWorkingEditable(true);
        }}
      >
        <Icon path={icons[getMaterialIconString(CurrencyIcon[currencyType])]} size="1.5rem" className={styles.icon} />
        <Typography>{profile.hourlyRate ? `${profile.hourlyRate} / hour` : 'Click to add Work Rate'}</Typography>
      </div>

      {
        isWorkingEditable &&
        (isUserAdmin) && isActiveMember &&
        <EditWorkingDetails
          open={isWorkingEditable}
          onClose={() => setIsWorkingEditable(false)}
          profile={profile}
          localProfilePicUrl={localProfilePicUrl}
        />
      }

    </div>
  )
}

export default UserWorkingDetails
