import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import ChatsListItem from '../ChatsListItem/ChatsListItem';
import { useStyles } from './ChatListSectionStyles';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import Icon from '@mdi/react';
import { mdiAccountDetails, mdiDotsVertical, mdiMagnify, mdiClose } from '@mdi/js';
import { IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Popover, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import CreateChatGroupModal from '../CreateChatGroupModal/CreateChatGroupModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMemberSuggest } from '../../actions/suggestionsActions';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import SearchInputCNO from '../../library/SearchInputCNO/SearchInputCNO';
import { getCurrentUser } from '../../utils/Helpers';
import RightsManager from '../../utils/RightsManager';

function ChatsListSection() {

  const isUserCustomer = RightsManager.isCustomer();

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  let { chatSnippetList } = useSelector(state => state.chats);
  chatSnippetList = chatSnippetList.filter(user => !(user.contactType === 'user' && isUserCustomer));

  const dispatch = useDispatch();
  const suggestions = useSelector(state => state.suggestions);

  console.log("suggestions: ", suggestions);

  const [isCreateChatGroup, setIsCreateChatGroup] = useState(false);

  const [chatSearchKey, setChatSearchKey] = useState('');
  const [searchedChatProfileList, setSearchedChatProfileList] = useState([]);

  console.log("chatSnippetList: ", chatSnippetList);

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const openCreateChatGroup = () => {
    setIsCreateChatGroup(true);
  };

  const closeCreateChatGroup = () => {
    setIsCreateChatGroup(false);
  };

  const [refMore, setRefMore] = useState(null);

  const openMore = (e) => {
    console.log("clickedddd");
    setRefMore(e.currentTarget);
  };

  const closeMore = () => {
    setRefMore(null);
  };
  const open = Boolean(refMore);

  const NativePopover = () => {
    return (
      <Popover
        open={open}
        anchorEl={refMore}
        onClose={closeMore}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={styles.container_MorePopover}>
          <List className={styles.list}>
            <ListItem className={GlobalStyles.listItem} onClick={() => { openCreateChatGroup(); closeMore(); }}>
              <ListItemText primary='New Group' />
              <ListItemIcon>
                <IconButton edge='end'>
                  <Icon path={mdiAccountDetails} size='1.5rem' />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </List>
        </div>
      </Popover>
    )
  }

  useEffect(() => {

    const authUser = getCurrentUser();

    const profileList = suggestions
      .filter((user) => user.id !== authUser.id)
      .map((user) => (
        {
          id: user.id,
          contactName: user.title,
          contactAvatar: user.profilePic,
          contactType: 'user'
        }
      ));

    let localGroups = localStorage.getItem("localChatGroupList");
    localGroups = localGroups ? JSON.parse(localGroups) : [];

    let pattern = new RegExp(chatSearchKey, "gi");
    let filteredGroups = localGroups.filter(group => pattern.test(group.contactName));

    let mergedList = [...profileList, ...filteredGroups];
    mergedList = mergedList.filter(user => !(user.contactType === 'user' && isUserCustomer));

    setSearchedChatProfileList(mergedList);
  }, [suggestions, chatSearchKey, isUserCustomer]);

  const handleChatProfileSearch = (searchKey) => {

    setChatSearchKey(searchKey);

    if (searchKey) {
      dispatch(fetchMemberSuggest(searchKey));
    }

  }

  const styles = useStyles({ tillTablet });
  return (
    <div className={styles.container_ChatsListSection}>
      <div className={styles.searchToolbar}>
        <NativePopover />

        <SearchInputCNO
          placeholder="Search User/Group"
          onSearch={(value) => handleChatProfileSearch(value)}
        />

        <Icon
          path={mdiDotsVertical}
          size="2rem"
          color={theme.colors.MORE_ACTIONS_ICON_COLOR}
          onClick={openMore}
        />
      </div>
      {
        !chatSearchKey &&
        chatSnippetList.map((chat) => {
          return (
            <ChatsListItem
              key={v4()}
              chat={chat}
              clearSearch={() => setChatSearchKey("")}
            />)
        })
      }
      {
        chatSearchKey &&
        searchedChatProfileList.map((chat) => {
          return (
            <ChatsListItem
              key={v4()}
              chat={chat}
              clearSearch={() => setChatSearchKey("")}
            />)
        })
      }
      {
        isCreateChatGroup &&
        <CreateChatGroupModal
          isCreateChatGroup={isCreateChatGroup}
          closeCreateChatGroup={closeCreateChatGroup}
          closeMoreActions={closeMore}
        />
      }
    </div>
  )
}

export default ChatsListSection
