import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons';
import * as icons from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { updateInvoiceItem } from '../../actions/invoiceItemActions';

function EditInvoiceItemHour({
  open,
  onClose,
  invoiceItem,
}) {

  const dispatch = useDispatch();
  const { editLoader } = useSelector(state => state.invoiceItems);

  const formik = useFormik({
    initialValues: {
      hours: invoiceItem.hours,
    },
    validationSchema: yup.object({
      hours: yup.string().required('Hour is required'),
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("Edit Invoice Item: hours: ", values);

    const payload = {
      ...invoiceItem,
      hours: values.hours,
    };

    dispatch(updateInvoiceItem(payload));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Invoice Item Hour"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name='hours'
          label='Invoice Item Hour'
          formik={formik}
          placeholder='Enter Invoice Item Hour'
          icon={icons.mdiFormatText}
          fullWidth
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditInvoiceItemHour
