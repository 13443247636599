import { makeStyles } from '@material-ui/core';
import { mdiMapMarkerRadiusOutline, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerSuggest } from '../../actions/suggestionsActions';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import AddCustomer from '../AddCustomer/AddCustomer';
import GooglePlaceInputCNO from '../../library/GooglePlaceInputCNO';
import InputCNO from '../../library/InputCNO';
import { toggleAddCustomerDialog } from '../../actions/customerActions';
import { getTitleFromCustomer } from '../../utils/Helpers';

function CustomerDetailsForm({ formik }) {

  const { isAddCustomerDialog } = useSelector(state => state.customers);

  const handleAddCustomerDialog = () => {
    dispatch(toggleAddCustomerDialog(true));
  };

  const dispatch = useDispatch();

  const suggestions = useSelector(state => state.suggestions);

  const styles = useStyles();
  return (<>
    {
      isAddCustomerDialog &&
      <AddCustomer
        onAdd={(customer) => {
          console.log("===== customer : add: ", customer);
          const _customer = {
            firstName: customer.firstName,
            id: customer.id,
            isActive: true,
            lastName: customer.lastName,
            title: `${customer.firstName} ${customer.lastName}`,
            profilePic: null
          };
          formik.setFieldValue('customer', _customer);
          formik.setFieldValue('location', customer.mapAddress);
          formik.setFieldValue('lat', customer.lat);
          formik.setFieldValue('lng', customer.lng);
        }}
        jobLocation={formik.values.location}
      />
    }
    <div className={styles.container}>
      <div className={styles.customerNameWrapper}>
        <AutocompleteCNO
          name='customer'
          label='Contact Person'
          formik={formik}
          placeholder='Search Customer'
          // fullWidth
          data={suggestions}
          optionLabel={x => x.title || ""}
          renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
          onSuggestionClick={(value) => {

            if (value) {
              formik.setValues({
                ...formik.values,
                customer: value,
                location: value.mapAddress,
                lat: value.lat,
                lng: value.lng,
                jobTitle: getTitleFromCustomer(value, formik.values.jobTitle)
              });
            }

          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchCustomerSuggest(value));
            }
          }}
          isRequired
        />
        <Icon
          path={mdiPlus}
          size='43px'
          color='goldenrod'
          onClick={handleAddCustomerDialog}
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <GooglePlaceInputCNO
          address={formik.values.location}
          onSelectAddress={(address) => {
            formik.setFieldValue("location", address.address);
          }}
          label={"Job Location"}
          isRequired
        />
      </div>

      <InputCNO
        name='proximity'
        label='Proximity in meter'
        formik={formik}
        placeholder='Enter proximity in meter'
        icon={mdiMapMarkerRadiusOutline}
        fullWidth
      />

    </div>
  </>)
}

export default CustomerDetailsForm

const useStyles = makeStyles(theme => ({
  container: {
    '&>*': {
      marginTop: theme.spacing(2)
    }
  },
  customerNameWrapper: {
    display: 'flex',
    // alignItems: 'flex-end',
    '&>*:first-child': {
      flex: 1
    },
    '&>*:last-child': {
      alignSelf: 'flex-end',
    }
  },
  jobLocationLabel: {
    marginBottom: theme.spacing(1)
  }
}))