import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChatUsers, fetchMessageCount, fetchMessageProfileCount, setActiveChatUser } from '../../actions/chatActions';
import ChatMessages from '../../components/ChatMessages/ChatMessages';
import ChatsListSection from '../../components/ChatsListSection/ChatsListSection';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { useStyles } from './ChatPageStyles';
import { useMediaQuery } from '@material-ui/core';
import ScreenView from '../../components/ScreenView';

function ChatPage() {

  const dispatch = useDispatch();
  const { universalLoader, loaders } = useSelector(state => state.chats);
  const createGroupLoader = loaders.createGroupLoader;

  const tillTablet = useMediaQuery('(max-width: 768px)');

  console.log("chattillTablet: ", tillTablet);

  useEffect(() => {

    let lastActiveChatUser = localStorage.getItem("lastActiveChatUser");
    if (!lastActiveChatUser) {
      lastActiveChatUser = null;
    }
    else {
      lastActiveChatUser = JSON.parse(lastActiveChatUser);
    }

    localStorage.setItem("currentChatProfile", JSON.stringify(lastActiveChatUser));

    dispatch(setActiveChatUser(lastActiveChatUser));

    return () => localStorage.removeItem("currentChatProfile");
  }, []);

  const styles = useStyles({ tillTablet });
  return (
    <div className={styles.container_ChatPage}>

      <ScreenView
        content={<>
          {
            tillTablet &&
            <ChatsListSection />
          }

          {
            !tillTablet &&
            <div style={{ display: 'flex' }}>
              <ChatsListSection />
              <ChatMessages />
            </div>
          }
        </>}
        headerTitle="iConnectChats"
      />

      {
        universalLoader &&
        <FullScreenLoaderCNO />
      }

      {
        createGroupLoader &&
        <FullScreenLoaderCNO />
      }

    </div>
  )
}

export default ChatPage;
