import { IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, useTheme } from '@material-ui/core';
import { mdiAccountArrowRight, mdiAccountCancel, mdiAccountDetails, mdiAccountSupervisorCircleOutline, mdiCommentAccountOutline, mdiContentCopy, mdiDelete, mdiFileChartOutline, mdiFileTable, mdiShare } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { generateInvoiceFromJob, toggleAddInvoiceDialog } from '../../actions/invoiceActions';
import LinkCNO from '../../library/LinkCNO';
import RightsManager from '../../utils/RightsManager';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import ShareJobModal from '../ShareJobModal/ShareJobModal';
import ShareTaskWithCustomer from '../ShareTaskWithCustomer/ShareTaskWithCustomer';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import JobSummaryModal from '../JobSummaryModal/JobSummaryModal';
import { fetchTrackSearch } from '../../actions/trackSearchActions';
import moment from 'moment';
import AddInvoice from '../Invoice/AddInvoice';
import { useIsFeatureAllowedInSubscription } from '../../hooks';
import SubscriptionNotAllowedDialog from '../SubscriptionNotAllowedDialog';
import ConfirmationDialogCNO from '../../library/ConfirmationDialogCNO';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import { fetchSubTasks } from '../../actions/subTaskActions';
import { getTaskById } from '../../actions/jobActions';
import DuplicateJobDialog from './DuplicateJobDialog';
import { AddInvoiceDialogMode } from '../../utils';

function MorePopover({
  item,
  closeMore,
  onShareTaskWithCustomer,
  handleEnableCustomerChat,
  handleEnableTeamChat
}) {

  let authUser = localStorage.getItem('authUser');
  authUser = authUser ? JSON.parse(authUser) : authUser;

  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();

  const { taskById } = useSelector(state => state.jobs);
  const { subTasks } = useSelector(state => state.subTasks);
  const { isAddInvoiceDialog, mode } = useSelector(state => state.invoices);

  const [open, setOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isShareWithCustomer, setIsShareWithCustomer] = useState(false);
  const [isJobSummary, setIsJobSummary] = useState(false);
  const [isEnableCustomerChatConfirmDialog, setIsEnableCustomerChatConfirmDialog] = useState(false);
  const [isEnableTeamChatConfirmDialog, setIsEnableTeamChatConfirmDialog] = useState(false);
  const [isDuplicateDialog, setIsDuplicateDialog] = useState(false);

  const isUserAdmin = RightsManager.isAdmin();
  const isMember = RightsManager.isMember();

  const isJobDetails = useLocation().pathname.includes("/job/");

  const handleShowJobSummary = () => {

    const payload = {
      search: {
        taskIds: [item.id],
        profileId: authUser.id,
        startDate: moment(new Date()).startOf('day').toDate(),
        endDate: moment(new Date()).endOf('day').toDate()
      }
    };

    dispatch(fetchTrackSearch(payload, true));

    setIsJobSummary(true);
  };

  let { isShareAllowed } = useIsFeatureAllowedInSubscription();

  return (<>
    {
      open &&
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        message={item?.title}
        item={item}
        closeMore={closeMore}
      />
    }

    {
      isShareOpen &&
      <ShareJobModal
        isShareOpen={isShareOpen}
        setIsShareOpen={setIsShareOpen}
        item={item}
        closeMore={closeMore}
      />
    }

    {
      isShareWithCustomer && isShareAllowed &&
      <ShareTaskWithCustomer
        open={isShareWithCustomer}
        onClose={() => setIsShareWithCustomer(false)}
        todo={item}
        onShareTaskWithCustomer={onShareTaskWithCustomer}
        closeMoreActions={closeMore}
      />
    }

    {
      isShareWithCustomer && !isShareAllowed &&
      <SubscriptionNotAllowedDialog
        isAllowed={isShareAllowed}
        featureName='Share Job with Customer'
        shouldRedirect={false}
        onAction={() => setIsShareWithCustomer(false)}
        closeMoreActions={closeMore}
      />
    }

    {
      isJobSummary &&
      <JobSummaryModal
        open={isJobSummary}
        onClose={() => setIsJobSummary(false)}
        closeMoreActions={closeMore}
      />
    }

    <div className={styles.container_MorePopover}>
      <List className={styles.list}>
        {
          !isJobDetails &&
          <LinkCNO to={`/job/${item.id}`}>
            <ListItem className={GlobalStyles.listItem} onClick={closeMore} >
              <ListItemText primary='Details' />
              <ListItemIcon>
                <IconButton edge='end'>
                  <Icon path={mdiAccountDetails} size='1.5rem' color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR} />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </LinkCNO>
        }
        {
          isUserAdmin &&
          <ListItem className={GlobalStyles.listItem} onClick={() => { setOpen(true) }}>
            <ListItemText primary='Delete' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiDelete} size='1.5rem' color={theme.colors.ERROR_COLOR} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          <ListItem className={GlobalStyles.listItem} onClick={() => { setIsShareOpen(true) }}>
            <ListItemText className={GlobalStyles.listItemText} primary='Share Job' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiShare} size='1.5rem' color={theme.colors.MORE_ACTIONS_SHARE_JOB_OPTION_ICON_COLOR} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          <ListItem className={GlobalStyles.listItem} onClick={() => setIsShareWithCustomer(true)}>
            <ListItemText
              className={styles.listItemText}
              primary={item.visibility ? 'Remove from Customer' : 'Share With Customer'}
            />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon
                  path={item.visibility ? mdiAccountCancel : mdiAccountArrowRight}
                  size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_SHARE_JOB_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          <ListItem className={GlobalStyles.listItem} onClick={() => {

            dispatch(toggleAddInvoiceDialog({ status: true, mode: AddInvoiceDialogMode.GenerateFromJob }));

            dispatch(generateInvoiceFromJob(item.id));

            if (closeMore) closeMore();

          }}>
            <ListItemText className={styles.listItemText} primary='Generate Invoice' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiFileTable} size='1.5rem' color={theme.colors.MORE_ACTIONS_GENERATE_INVOICE_OPTION_ICON_COLOR} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          (isUserAdmin || isMember) &&
          <ListItem className={GlobalStyles.listItem} onClick={handleShowJobSummary}>
            <ListItemText className={GlobalStyles.listItemText} primary="Today's Work Summary" />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiFileChartOutline} size='1.5rem' color={theme.colors.MORE_ACTIONS_WORK_SUMMARY_OPTION_ICON_COLOR} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          (isUserAdmin) &&
          isJobDetails &&
          !item.customerChatGroupId &&
          <ListItem className={GlobalStyles.listItem} onClick={() => setIsEnableCustomerChatConfirmDialog(true)}>
            <ListItemText className={GlobalStyles.listItemText} primary="Enable customer chat" />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiCommentAccountOutline} size='1.5rem' color={theme.colors.MORE_ACTIONS_ENABLE_CUSTOMER_CHAT_ICON_COLOR} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          (isUserAdmin) &&
          isJobDetails &&
          !item.teamChatGroupId &&
          <ListItem className={GlobalStyles.listItem} onClick={() => setIsEnableTeamChatConfirmDialog(true)}>
            <ListItemText className={GlobalStyles.listItemText} primary="Enable team chat" />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiAccountSupervisorCircleOutline} size='1.5rem' color={theme.colors.MORE_ACTIONS_ENABLE_TEAM_CHAT_COLOR} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          <ListItem className={GlobalStyles.listItem} onClick={() => {
            setIsDuplicateDialog(true);
            if (!subTasks.length) {
              const itemsPayload = {
                taskId: item.id
              };
              dispatch(fetchSubTasks(itemsPayload))
            }
          }}>
            <ListItemText className={GlobalStyles.listItemText} primary="Duplicate" />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiContentCopy} size='1.5rem' color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

      </List>

      {
        isDuplicateDialog &&
        <DuplicateJobDialog
          open={isDuplicateDialog}
          onClose={() => setIsDuplicateDialog(false)}
          closeMoreActions={closeMore}
          job={item}
        />
      }

      {
        isEnableCustomerChatConfirmDialog &&
        <ConfirmationDialogCNO
          open={isEnableCustomerChatConfirmDialog}
          onClose={() => setIsEnableCustomerChatConfirmDialog(false)}
          messages={["Are you sure you want to enable customer chat?"]}
          yesClick={handleEnableCustomerChat}
          dialogTitle={"Enable Customer Chat"}
          closeMoreActions={closeMore}
        />
      }

      {
        isEnableTeamChatConfirmDialog &&
        <ConfirmationDialogCNO
          open={isEnableTeamChatConfirmDialog}
          onClose={() => setIsEnableTeamChatConfirmDialog(false)}
          messages={["Are you sure you want to enable team chat?"]}
          yesClick={handleEnableTeamChat}
          dialogTitle={"Enable Team Chat"}
          closeMoreActions={closeMore}
        />
      }

    </div>
  </>)
}

export default MorePopover

const useStyles = makeStyles(theme => ({
  container_MorePopover: {
    padding: 5,
    overflow: 'hidden',
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR
  },
  list: {
    background: theme.colors.MORE_ACTIONS_OPTIONS_WRAPPER_BACKGROUND_COLOR,
    // background: theme.palette.background.dark,
    padding: 0
  },
  listItem: {
    background: theme.colors.MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 2,
    // width: 200,
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.01)'
    }
  },
  listItemText: {
    textDecoration: 'none',
  }
}))