import { Box, Button, Collapse, Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppsLoader } from "../actions/appActions";
import { useMediasPendingUploadingForCurrrentItem } from "../hooks/useMediasPendingUploadingForCurrrentItem";
import LocalStorageConstants from "../utils/LocalStorageConstants";
import {
  isAnyMediasUploadingLoadersTrue,
  isMediasPendingUploadExistInLS,
  removeMediasPendingFromLS,
  triggerUploadMediasLsBg,
} from "../utils/MediaUpload";

const defaultMessage =
  "Uploading medias in background, please don't close the browser.";

const AppMessageRibbon = () => {
  const dispatch = useDispatch();
  const { mediaPendingLoader } = useSelector((state) => state.app);

  const [message, setMessage] = useState(defaultMessage);

  const [isMediaPendingUploadExist, setIsMediaPendingUploadExist] =
    useState(false);

  const isAnyUploadingLoader = useMediasPendingUploadingForCurrrentItem();

  console.log("input -- isAnyUploadingLoader --- ", isAnyUploadingLoader);

  useEffect(() => {
    const onStorageChange = () => {
      const mediaPendingLoaderLS =
        LocalStorageConstants.getItem("mediaPendingLoader");

      const isAnyLoaders = isAnyMediasUploadingLoadersTrue();
      setIsMediaPendingUploadExist((prev) => (isAnyLoaders ? false : prev));
      console.log("messageribbon - isAnyLoaders: ", isAnyLoaders);

      setMessage((prevMsg) => (isAnyLoaders ? defaultMessage : prevMsg));

      dispatch(setAppsLoader("mediaPendingLoader", mediaPendingLoaderLS));
    };

    window.addEventListener("media-pending-loader-storage", onStorageChange);

    onStorageChange();

    return () => {
      window.removeEventListener(
        "media-pending-loader-storage",
        onStorageChange
      );
    };
  }, []);

  useEffect(() => {
    const isMediasPendingUploadExist = isMediasPendingUploadExistInLS();
    setIsMediaPendingUploadExist(isMediasPendingUploadExist);

    setMessage(
      isMediasPendingUploadExist
        ? "You have pending medias item to upload, please retry or clear them."
        : defaultMessage
    );

    LocalStorageConstants.setItem(
      "mediaPendingLoader",
      isMediasPendingUploadExist
    );
    window.dispatchEvent(new Event("media-pending-loader-storage"));
  }, []);

  const theme = useTheme();

  const handleRetryUpload = () => {
    setIsMediaPendingUploadExist(false);
    setMessage(defaultMessage);
    triggerUploadMediasLsBg();
  };

  const handleClear = () => {
    removeMediasPendingFromLS(null, false, true);

    LocalStorageConstants.setItem("mediaPendingLoader", false);
    window.dispatchEvent(new Event("media-pending-loader-storage"));
  };

  return (
    <Collapse
      in={mediaPendingLoader}
      style={{
        position: "fixed",
        top: 60,
        zIndex: 2000,
        width: "100%",
        backgroundColor: "#436a93",
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography
          variant="body1"
          style={{ color: theme.colors.HEADER_TITLE_COLOR }}
          align="center"
        >
          {isAnyUploadingLoader ? defaultMessage : message}
        </Typography>

        {isMediaPendingUploadExist && (
          <Box display="flex" alignItems="center" style={{ gap: 4 }}>
            <Button
              size="small"
              color="primary"
              onClick={handleRetryUpload}
              style={{ fontWeight: "bold" }}
            >
              Retry
            </Button>
            <Button
              size="small"
              color="secondary"
              onClick={handleClear}
              style={{ fontWeight: "bold" }}
            >
              Clear
            </Button>
          </Box>
        )}
      </Box>
    </Collapse>
  );
};

export default AppMessageRibbon;
