import { makeStyles, Typography, useTheme } from "@material-ui/core";
import React from "react";
import ResponsiveLinesCNO from "../ResponsiveLinesCNO";

const MetaText = ({ children, maxLine = null, fontSize = "0.9rem", color, themeColorKey, style = {}, ...rest }) => {

  const theme = useTheme();

  if (!children) return null;

  //BUG: className styling not working
  return (
    <ResponsiveLinesCNO
      text={Array.isArray(children) ? children.join("") : children}
      maxLine={maxLine}
      style={{
        fontSize,
        color: theme.colors[themeColorKey] || color || theme.colors.META_TEXT_COLOR,
        ...style
      }}
      {...rest}
    />
  );
};

export default MetaText;
