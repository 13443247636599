import React, { useEffect, useState } from 'react'
import { useStyles } from '../../../library/DialogCNO/DialogCNOStyles';
import { Dialog, useTheme } from '@material-ui/core';
import HeadingBar from '../../../components/HeadingBar';
import Feelings from '../../../actions/AddToPostData/feelings.json'
import Activities from '../../../actions/AddToPostData/activity.json'
import {makeStyles} from '@material-ui/core';
import { mdiArrowLeft } from '@mdi/js'
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import ActivityDetails from './ActivityDetails';

const FeelingActivity = ({open,onClose,formik,setFeeling,setSelectedActivity}) => {

  const width="40vw";
  const maxHeight=450;
  const minHeight=200;

  const theme = useTheme();
  const styles = useStyles({ width,maxHeight, minHeight });
  const [isActivityBox,setIsActivityBox]=useState(false);
  const [isFeelingBox,setIsFeelingBox]=useState(true);
  const [activity,setActivity]=useState(null);
  const [search,setSearch]=useState('');
  console.log(search)
  const onEmojiClick=(emoji,title)=>{
    const feeling={
      feelingEmoji:emoji,
      feelingTitle:title,
    }

    setFeeling(feeling);
    formik.setFieldValue('feeling', feeling);

    setSelectedActivity(null);
    formik.setFieldValue('activity',null);
    
    onClose();
  }
  const onActivityClick=(displayName,icon,title)=>{
    const activity={
      display_name:displayName,
      icon:icon,
      title:title,
    }
   
    setSelectedActivity(activity);
    formik.setFieldValue('activity',activity);
    
    setFeeling(null);
    formik.setFieldValue('feeling', null);

    onClose();
  }
  const setBoxData=(source)=>{
    if(source === 'feeling'){
      setIsFeelingBox(true)
      setIsActivityBox(false)
    }else{
      setIsFeelingBox(false)
      setIsActivityBox(true)
    }
  }

  const handleActivity=(activity)=>{
    setActivity(activity);
    setIsActivityBox(false);
  }

  return (
    

    <Dialog
      open={open}
      onClose={onClose}
      className={styles.container_DialogCNO}
      classes={{ paper: styles.paper }}

    >
      <HeadingBar
        title={'How are you feeling?'}
        onClose={onClose}
        icon={mdiArrowLeft}
        backTop={true}
      />
      
        <div className={styles.scrollableContent}>
          <div className={styles.feelingActivityBox}>
            <div className={styles.feelingElements} onClick={()=>setBoxData('feeling')}>Feelings</div>
            <div className={styles.feelingElements} onClick={()=>setBoxData('activity')}>Activities</div>
          </div>
          
          <input type='search' placeholder='Search' className={styles.searchBox} onChange={(e)=>setSearch(e.target.value)}/>
          
          {
            isFeelingBox && (
              <div className={styles.feelingIconContainer}>
                {
                  Feelings.filter((item)=>{
                    return search.toLocaleLowerCase() === '' ? item : item.feeling.toLocaleLowerCase().includes(search)
                  }).map((feeling)=>(
                      <div key={feeling.id} className={styles.feelingElementsEmoji} onClick={()=>onEmojiClick(feeling.emoji,feeling.feeling)}>
                        <div className={styles.backgroundDisplay}>
                          <p className={styles.emoji}>{feeling.emoji}</p>
                        </div>
                        <p className={styles.emojiName}>{feeling.feeling}</p>
                      </div>

                  ))
                }
              </div>
            )
          }

          {
            isActivityBox &&(
              <div className={styles.activityIconContainer}>
                {
                  Activities.filter((item)=>{
                    return search.toLocaleLowerCase() === '' ? item : item.display_name.toLocaleLowerCase().includes(search)
                  }).map((activity)=>(
                    <div className={styles.activityBox} onClick={()=>handleActivity(activity)}>
                      <div key={activity.id} className={styles.activityElementsEmoji} >
                        <div className={styles.backgroundDisplay}>
                          <img src={activity.iconsrc} className={styles.emoji}/>
                        </div>
                        <p className={styles.emojiName}>{activity.display_name}</p>
                      </div>

                      <div>
                        <Icon path={mdiChevronRight} size={2} />
                      </div>
                    </div>
                    
                  ))
                }
              </div>
            )
          }
          {
            activity && <ActivityDetails activity={activity} styles={styles} onActivityClick={onActivityClick} search={search}/>
          }
        </div>
    </Dialog>
  )
}

export default FeelingActivity;

