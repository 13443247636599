import { toast } from 'react-toastify';
import {
  apiSubTaskAssign,
  apiSubTaskChangeStatus,
  apiSubTaskCreate,
  apiSubTaskEdit,
  apiSubTaskRemove,
  apiSubTaskSearch
} from '../api/subtask';
import {
  editJob,
  setTaskById
} from './jobActions';

import { getHoursFromTimeline, parseNumber } from '../utils/Helpers';
import { RateType } from '../utils';
import { getJobTotalAmount } from '../utils/InvoiceHelpers';
import { AppConstants } from '../utils/AppConstants';

export const SET_SUBTASKS = "SET_SUBTASKS";
export const SET_SUBTASK_BY_ID = "SET_SUBTASK_BY_ID";
export const SET_SUBTASK_LOADERS = "SET_SUBTASK_LOADERS";
export const TOGGLE_ADD_SUBTASK_DIALOG = "TOGGLE_ADD_SUBTASK_DIALOG";
export const SET_IS_NEW_SUBTASK_ADDED = "SET_IS_NEW_SUBTASK_ADDED";

export function setSubTasks(payload) {
  return (dispatch) => {

    const sortedItems = payload.sort((first, second) => first.itemOrder < second.itemOrder ? -1 : 1);

    dispatch({
      type: SET_SUBTASKS,
      payload: sortedItems
    });

  };
}

export function setIsNewSubTaskAdded(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_NEW_SUBTASK_ADDED,
      payload: payload
    });
  };
}

export function setSubTaskById(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_SUBTASK_BY_ID,
      payload: payload
    });
  };
}

export function setSubTaskLoader(loaderName, loaderValue) {
  return (dispatch) => {

    dispatch({
      type: SET_SUBTASK_LOADERS,
      payload: { loaderName, loaderValue }
    });
  };
}

export function toggleAddSubTaskDialog(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_SUBTASK_DIALOG,
      payload: status
    });
  };
}

export function fetchSubTasks(payload) {
  return async (dispatch) => {

    console.log("SubTasks: dispatched");

    dispatch(setSubTaskLoader('subTasksLoader', true));

    const dataToSend = {
      "pageSize": 100,
      "pageIndex": 0,
      "orderBy": "newest",
      "sortAscending": false,
      "startDate": null,
      "endDate": null,
      "assignedIds": [],
      "categories": [],
      "searchText": "",
      "ids": [],
      "taskId": "",
      ...payload
    };

    await apiSubTaskSearch.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          let subTasksResponse = response.data.data;

          subTasksResponse = subTasksResponse.map((subTask, index) => {

            const hours = getHoursFromTimeline(subTask.startDate, subTask.endDate);
            const amount = subTask.rateType === RateType.Hourly ? hours * subTask.rate
              : subTask.rate;

            return ({
              ...subTask,
              hours,
              amount,
              itemOrder: subTask.itemOrder ? subTask.itemOrder : parseFloat(AppConstants.SORTING_MULTIPLIER * (index + 1)),
              startDate: subTask.startDate + "Z",
              startTime: subTask.startTime + "Z",
              endDate: subTask.endDate + "Z",
              endTime: subTask.endTime + "Z",
            });

          });

          dispatch(setSubTasks(subTasksResponse));

        } else {
          console.error("Error: Fetch Subtasks:");
        }

      })
      .catch((error) => {
        console.error("Error: Fetch Subtasks: ", error);
      })
      .finally(() => {
        dispatch(setSubTaskLoader('subTasksLoader', false));
      });

  };
}

export function getSubTaskById(payload) {
  return async (dispatch) => {

    dispatch(setSubTaskLoader('subTaskDetailLoader', true));

    const dataToSend = {
      "pageSize": 100,
      "pageIndex": 0,
      "orderBy": "newest",
      "sortAscending": false,
      "startDate": null,
      "endDate": null,
      "assignedIds": [],
      "categories": [],
      "searchText": "",
      "ids": [],
      "taskId": "",
      ...payload
    };

    await apiSubTaskSearch.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          let subTaskResponse = response.data.data;

          subTaskResponse = subTaskResponse.map((subTask, index) => {

            const hours = getHoursFromTimeline(subTask.startDate, subTask.endDate);
            const amount = subTask.rateType === RateType.Hourly ? hours * subTask.rate
              : subTask.rate;

            return ({
              ...subTask,
              hours,
              amount,
              itemOrder: subTask.itemOrder ? subTask.itemOrder : parseFloat(AppConstants.SORTING_MULTIPLIER * (index + 1))
            });

          });

          dispatch(setSubTaskById(subTaskResponse));

        } else {
          console.error("Error: Fetch Subtask Details:");
        }

      })
      .catch((error) => {
        console.error("Error: Fetch Subtask Details: ", error);
      })
      .finally(() => {
        dispatch(setSubTaskLoader('subTaskDetailLoader', false));
      });


  };
}

export const addMultipleSubTasks = (itemsToAdd, todo) => {
  return async (dispatch, getState) => {

    dispatch(setSubTaskLoader('addLoader', true));

    let { subTasks } = getState().subTasks;

    const maxOrder = subTasks.length ? Math.max(...(subTasks.map(item => item.itemOrder))) : AppConstants.SORTING_MULTIPLIER;

    const itemsToAddPromises = itemsToAdd.map(async (item, index) => {

      const payload = {
        title: item.title,
        description: item.description,
        categories: item.categories,
        status: item.status,
        startDate: item.startDate,
        endDate: item.endDate,
        startTime: item.startTime,
        endTime: item.endTime,
        subTaskAssignments: item.subTaskAssignments || [],
        rate: item.rate,
        rateType: item.rateType,
        target: item.target,
        targetType: item.targetType,
        taskId: todo.id,
        itemOrder: maxOrder + (index + 1) * AppConstants.SORTING_MULTIPLIER,
      };

      const itemPromiseResponse = await apiSubTaskCreate.post(payload)
        .then(async (response) => {

          if (response && response.status === 200) {

            const subTaskId = response.data;

            const hours = getHoursFromTimeline(payload.startDate, payload.endDate);
            const amount = payload.rateType === RateType.Hourly ? hours * payload.rate
              : payload.rate;

            const newSubTask = {
              ...payload,
              id: subTaskId,
              hours,
              amount: parseNumber(amount, 'float')
            };

            return newSubTask;

          } else {
            return null;
          }

        });

      return itemPromiseResponse;

    });

    let itemsAdded = await Promise.all(itemsToAddPromises);
    itemsAdded = itemsAdded.filter(item => item);

    dispatch(setSubTasks(itemsAdded));

    const { taskById } = getState().jobs;

    // sync oneInvoiceItem*** with invoices
    if (taskById.id === todo.id) {
      const firstItem = itemsAdded[0];
      const updatedTodo = {
        ...todo,
        oneSubTaskId: firstItem.id,
        oneSubTaskTitle: firstItem.title,
        oneSubTaskCategories: firstItem.categories[0],
      };
      dispatch(setTaskById(updatedTodo));
    }

    dispatch(setSubTaskLoader('addLoader', false));

  };
};

export function addSubTask(payload, isFromServer = false, source, onAddSuccess, itemOrder = AppConstants.SORTING_MULTIPLIER) {
  return async (dispatch, getState) => {

    dispatch(setSubTaskLoader('addLoader', true));

    let { subTasks } = getState().subTasks;

    itemOrder = subTasks.length ? Math.max(...(subTasks.map(item => item.itemOrder))) + AppConstants.SORTING_MULTIPLIER : itemOrder;

    payload = { ...payload, itemOrder: itemOrder };

    const dataToSend = {
      "title": "",
      "description": "",
      "categories": [],
      "status": 1,
      "startDate": null,
      "endDate": null,
      "startTime": null,
      "endTime": null,
      "subTaskAssignments": [],
      "rate": 1,
      "rateType": 1,
      "target": 0,
      "targetType": 2,
      "taskId": "",
      itemOrder: AppConstants.SORTING_MULTIPLIER,
      ...payload
    };

    await apiSubTaskCreate.post(dataToSend)
      .then(async (response) => {

        if (response && response.status === 200) {

          subTasks = getState().subTasks.subTasks;

          const subTaskId = response.data;

          const hours = getHoursFromTimeline(payload.startDate, payload.endDate);
          const amount = payload.rateType === RateType.Hourly ? hours * payload.rate
            : payload.rate;

          const newSubTask = {
            ...payload,
            id: subTaskId,
            hours,
            amount
          };

          console.log(" ----- addSubTask----- newSubTask action: ", newSubTask);

          // sync oneSubTask*** with todos
          const { taskById } = getState().jobs;

          if (taskById.id === payload.taskId && !subTasks.length) {

            const updatedTask = {
              ...taskById,
              oneSubTaskId: subTaskId,
              oneSubTaskTitle: payload.title,
              oneSubTaskCategories: payload.categories,
            };

            await dispatch(setTaskById(updatedTask));

          }

          const subTasksTemp = [newSubTask, ...subTasks];

          dispatch(setSubTasks(subTasksTemp));

          dispatch(setIsNewSubTaskAdded(true));

          !isFromServer && toast.success("Sub Task created successfully.");

          if (onAddSuccess) {
            onAddSuccess();
          }

          return subTasksTemp;
        } else {
          console.error("Error: Add Subtask: ", response);
          !isFromServer && toast.error("Error in creating Sub Task.");
        }

      }).then((subTasks = []) => {

        if (["quotation", "jobs"].includes(source)) {
          return;
        }

        updateTaskOnEditOfSubtask(subTasks, getState, dispatch);

      })
      .catch((error) => {
        console.error("Error: Add Subtask: ", error);
        !isFromServer && toast.error("Error in creating Sub Task.");
      })
      .finally(() => {
        dispatch(setSubTaskLoader('addLoader', false));
      });

  };
}

export function updateSubTask(payload, shouldUpdateOneSubtaskInTask = false, onAddSuccess) {
  return async (dispatch, getState) => {

    dispatch(setSubTaskLoader('editLoader', true));

    const dataToSend = {
      "id": "",
      "title": "",
      "description": "",
      "categories": [],
      "status": 2,
      "customerId": "",
      "startDate": null,
      "endDate": null,
      "startTime": null,
      "endTime": null,
      "paidAmount": 0,
      "rate": 0,
      "rateType": 1,
      "paymentByCustomerStatus": 1,
      "proximity": 0,
      "visibility": 1,
      itemOrder: AppConstants.SORTING_MULTIPLIER,
      ...payload
    };

    await apiSubTaskEdit.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          const { subTasks } = getState().subTasks;

          const hours = getHoursFromTimeline(payload.startDate, payload.endDate);
          const amount = payload.rateType === RateType.Hourly ? hours * payload.rate
            : payload.rate;

          const newSubTask = {
            ...payload,
            hours,
            amount
          };

          const subTasksTemp = subTasks.map((item) => item.id === payload.id ? newSubTask : item);

          dispatch(setSubTasks(subTasksTemp));

          if (shouldUpdateOneSubtaskInTask) {

            const { taskById } = getState().jobs;

            if (taskById.oneSubTaskId === payload.id) {
              const targetTodo = {
                ...taskById,
                oneSubTaskId: payload.id,
                oneSubTaskTitle: payload.title,
                oneSubTaskCategories: payload.categories
              };

              dispatch(setTaskById(targetTodo));

            }
          }

          if (onAddSuccess) {
            onAddSuccess();
          }

          toast.success("Sub Task edited successfully.");
          return subTasksTemp;
        } else {
          console.error("Error: Edit Subtask:");
          toast.error("Error in editing sub task.");
        }

      })
      .then((subTasks = []) => {

        updateTaskOnEditOfSubtask(subTasks, getState, dispatch);

      })
      .catch((error) => {
        console.error("Error: Edit Subtask: ", error);
        toast.error("Error in editing sub task.");
      })
      .finally(() => {
        dispatch(setSubTaskLoader('editLoader', false));
      });


  };
}

export function deleteSubTask(payload) {
  return async (dispatch, getState) => {

    dispatch(setSubTaskLoader('deleteLoader', true));

    const subTaskId = payload.id;

    const dataToSend = {
      "id": "",
      "undo": true,
      ...payload,
    };

    await apiSubTaskRemove.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          const { subTasks } = getState().subTasks;

          const subTasksTemp = subTasks.filter((subTask) => subTask.id !== subTaskId);

          dispatch(setSubTasks(subTasksTemp));

          const { taskById } = getState().jobs;

          if (taskById.oneSubTaskId === subTaskId) {

            let targetTodo = taskById;

            if (subTasks.length === 1) {

              const {
                amount,
                totalAmount
              } = getJobTotalAmount(0);

              targetTodo = {
                ...targetTodo,
                amount, totalAmount,
                oneSubTaskId: null,
                oneSubTaskTitle: null,
                oneSubTaskCategories: []
              };

              dispatch(editJob({ ...targetTodo, amount, totalAmount }));

            } else {
              const nextOldestSubTask = subTasks[subTasks.length - 2];
              targetTodo = {
                ...targetTodo,
                oneSubTaskId: nextOldestSubTask.id,
                oneSubTaskTitle: nextOldestSubTask.title,
                oneSubTaskCategories: nextOldestSubTask.categories
              };
            }

            dispatch(setTaskById(targetTodo));

          }

          toast.success("Sub Task deleted successfully.");

          return subTasksTemp;
        } else {
          console.error("Error: Delete Subtask:");
          toast.error("Error in deleting sub task assignment.");
        }

      })
      .then((subTasks = []) => {
        updateTaskOnEditOfSubtask(subTasks, getState, dispatch);
      })
      .catch((error) => {
        console.error("Error: Delete Subtask: ", error);
        toast.error("Error in deleting sub task assignment.");
      })
      .finally(() => {
        dispatch(setSubTaskLoader('deleteLoader', false));
      });

  };
}

function updateTaskOnEditOfSubtask(subTasks, getState, dispatch) {
  const subTasksAmount = subTasks.reduce((result, subItem) => {
    result += parseFloat(subItem.amount);

    return result;
  }, 0);

  const { taskById } = getState().jobs;

  const isAnySubtaskFixedType = subTasks.map(subTask => subTask.rateType).includes(RateType.Fixed);
  const rateType = (isAnySubtaskFixedType || subTasks.length) ? RateType.Fixed : taskById.rateType;

  const jobAmount = subTasks.length ? subTasksAmount : taskById.amount;

  const { amount, totalAmount } = getJobTotalAmount(jobAmount);

  const payload = {
    ...taskById,
    rateType: rateType,
    amount,
    totalAmount
  };

  if ((jobAmount !== taskById.amount) || (rateType !== taskById.rateType)) {
    dispatch(editJob(payload));
  }

}

export function assignSubTask(payload = []) {
  return async (dispatch, getState) => {

    dispatch(setSubTaskLoader('editLoader', true));

    const subTaskAssignments = payload.subTaskAssignments;

    await apiSubTaskAssign.post(subTaskAssignments)
      .then((response) => {

        if (response && response.status === 200) {

          const { subTasks } = getState().subTasks;

          const subTasksTemp = subTasks.map((item) => item.id === payload.id ? payload : item);

          dispatch(setSubTasks(subTasksTemp));

          // update todo assignment
          const { taskById } = getState().jobs;

          const taskAssignments = taskById.taskAssignments;
          const newAssignments = subTaskAssignments.filter(subTask => !taskAssignments.some(task => task.staffId === subTask.staffId));

          const updatedTask = {
            ...taskById,
            taskAssignments: [...taskAssignments, ...newAssignments]
          };
          dispatch(setTaskById(updatedTask));

          toast.success("Sub Task assigned successfully.");

        } else {
          console.error("Error: Assign Subtask:");
          toast.error("Error in sub task assignment.");
        }

      })
      .catch((error) => {
        console.error("Error: Assign Subtask: ", error);
        toast.error("Error in sub task assignment.");
      })
      .finally(() => {
        dispatch(setSubTaskLoader('editLoader', false));
      });


  };
}


export const updateSubtaskStatus = (payload) => {
  return async (dispatch, getState) => {

    dispatch(setSubTaskLoader('editLoader', true));

    const dataToSend = {
      subTaskId: "",
      status: 0,
      ...payload
    };

    await apiSubTaskChangeStatus.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          const { subTasks } = getState().subTasks;

          let targetSubTask = subTasks.find((subTask) => subTask.id === payload.subTaskId);
          targetSubTask = {
            ...targetSubTask,
            id: payload.subTaskId,
            status: payload.status
          };
          dispatch(setSubTaskById(targetSubTask));

          const tempSubtasks = subTasks.map((subTask) => subTask.id === payload.subTaskId ? targetSubTask : subTask);
          dispatch(setSubTasks(tempSubtasks));

          toast.success('Sub task status updated successfully!!');

        } else {

          console.log("Error: Sub task status: Response", response);
          toast.error('There is issue in updating the status of quotation.');

        }

      })
      .catch((error) => {

        console.log("Error: Sub task status: ", error);
        toast.error('There is issue in updating the status of quotation.');

      })
      .finally(() => dispatch(setSubTaskLoader('editLoader', false)));

  };
};
