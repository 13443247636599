import AppointmentsDummyData from "./DummyData/AppointmentsDummyData.json";

import { batch } from "react-redux";
import { toast } from "react-toastify";
import {
  apiAppointmentAssign,
  apiAppointmentChangeStatus,
  apiAppointmentCreate,
  apiAppointmentEdit,
  apiAppointmentGetMediaLink,
  apiAppointmentInitUpload,
  apiAppointmentRemove,
  apiAppointmentSearch,
  apiAppointmentUploadMedia,
} from "../api/appointment";
import moment from "moment";
import { v4 } from "uuid";
import { addQuotation } from "./quotationActions";
import { generateId } from "../utils/IdGeneratorHelpers";
import { TaskStatus, RateType } from "../utils";
import {
  AppointmentStatus,
  QuotationStatus,
  JobVisibilityForCustomer,
  MediaSizeType,
  FileType,
  LeaveStatus,
  AppointmentType,
  LeaveCategory,
  HolidayCategory,
} from "../utils/mappings";
import {
  b64toBlob,
  getContentType,
  getFileDataAsync,
  getFileTypeFromMimeType,
  getMediaData,
  getThumbnailBlob,
} from "../utils/Helpers";
import { addJob, createJob } from "./jobActions";
import { AppConstants } from "../utils/AppConstants";
import { combineDateAndTime } from "../utils/Tracking";
import axios from "axios";

export const SET_APPOINTMENTS = "SET_APPOINTMENTS";
export const SET_APPOINTMENT_BY_ID = "SET_APPOINTMENT_BY_ID";
export const SET_APPOINTMENT_LOADERS = "SET_APPOINTMENT_LOADERS";
export const SET_HAS_MORE_APPOINTMENTS = "SET_HAS_MORE_APPOINTMENTS";
export const SET_APPOINTMENT_SEARCH_PARAMS = "SET_APPOINTMENT_SEARCH_PARAMS";
export const SET_LEAVE_SEARCH_PARAMS = "SET_LEAVE_SEARCH_PARAMS";
export const SET_HOLIDAY_SEARCH_PARAMS = "SET_HOLIDAY_SEARCH_PARAMS";
export const TOGGLE_ADD_MEETING_DIALOG = "TOGGLE_ADD_MEETING_DIALOG";
export const TOGGLE_ADD_LEAVE_DIALOG = "TOGGLE_ADD_LEAVE_DIALOG";
export const TOGGLE_ADD_HOLIDAY_DIALOG = "TOGGLE_ADD_HOLIDAY_DIALOG";
export const TOGGLE_ADD_APPOINTMENT_DIALOG = "TOGGLE_ADD_APPOINTMENT_DIALOG";
export const SET_APPOINTMENT_TIMES_FILTER = "SET_APPOINTMENT_TIMES_FILTER";
export const SET_RESOLVED_APPOINTMENT_MEDIAS ="SET_RESOLVED_APPOINTMENT_MEDIAS";
export const SET_APPOINTMENT_TYPE="SET_APPOINTMENT_TYPE";

export function setResolvedAppointmentMedias(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_RESOLVED_APPOINTMENT_MEDIAS,
      payload: payload,
    });
  };
}

export function setAppointmentTimesFilter(value) {
  return (dispatch) => {
    dispatch({
      type: SET_APPOINTMENT_TIMES_FILTER,
      payload: value,
    });
  };
}

export const toggleAddAppointmentDialog = (payload) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_APPOINTMENT_DIALOG,
      payload: payload,
    });
  };
};

export const setAppointmentType=(payload)=>{
  return (dispatch)=>{
    dispatch({
      type:SET_APPOINTMENT_TYPE,
      payload:payload
    })
  }
}
export const toggleAddMeetingDialog = (payload) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_MEETING_DIALOG,
      payload: payload,
    });
  };
};

export const toggleAddLeaveDialog = (payload) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_LEAVE_DIALOG,
      payload: payload,
    });
  };
};

export const toggleAddHolidayDialog = (payload) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_HOLIDAY_DIALOG,
      payload: payload,
    });
  };
};
export const setAppointmentSearchParams = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_APPOINTMENT_SEARCH_PARAMS,
      payload: payload,
    });
  };
};

// TODO : SEE ON WHICH PLACES setAppointmentSearchParams IS USED , IMPLEMENT FOR LEAVE AND HOLIDAY ALSO

export const setLeaveSearchParams = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_LEAVE_SEARCH_PARAMS,
      payload: payload,
    });
  };
};

export const setHolidaySearchParams = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_HOLIDAY_SEARCH_PARAMS,
      payload: payload,
    });
  };
};

export const setAppointmentLoaders = (loaderName, loaderState) => {
  return (dispatch) => {
    dispatch({
      type: SET_APPOINTMENT_LOADERS,
      payload: { loaderName, loaderState },
    });
  };
};

export const setAppointments = (payload) => {
  console.log("z14", payload);
  return (dispatch) => {
    dispatch({
      type: SET_APPOINTMENTS,
      payload: payload,
    });
  };
};

export const setHasMoreAppointments = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_MORE_APPOINTMENTS,
      payload: payload,
    });
  };
};

export const setAppointmentById = (payload) => {
  return (dispatch, getState) => {
    if (payload) {
      batch(() => {
        dispatch({
          type: SET_APPOINTMENT_BY_ID,
          payload: payload,
        });

        const { appointments } = getState().appointments;
        const updatedAppointments = appointments.map((appointment) =>
          appointment.id === payload.id ? payload : appointment
        );
        dispatch(setAppointments(updatedAppointments));
      });
    } else {
      dispatch({
        type: SET_APPOINTMENT_BY_ID,
        payload: payload,
      });
    }
  };
};

export const fetchAppointments = (payload) => {
  console.log("f1", payload);
  return async (dispatch, getState) => {
    dispatch(setAppointmentLoaders("appointmentsLoader", true));
    dispatch(setHasMoreAppointments(true));

    const { appointmentSearchParams } = getState().appointments;

    const dataToSend = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      customerIds: appointmentSearchParams.customer
        ? [appointmentSearchParams.customer.id]
        : null,
      searchText: appointmentSearchParams.appointment
        ? appointmentSearchParams.appointment.title
        : "",
      categories: appointmentSearchParams.categories,
      startDate: appointmentSearchParams.startDate
        ? moment(appointmentSearchParams.startDate).startOf("day")
        : null,
      // endDate: appointmentSearchParams.endDate ? moment(appointmentSearchParams.endDate).endOf('day') : null,
      assignedIds: appointmentSearchParams.assignments
        ? [appointmentSearchParams.assignments.id]
        : null,
      ...payload,

      endDate: payload.endDate
        ? payload.endDate
        : appointmentSearchParams.endDate
        ? moment(appointmentSearchParams.endDate).toISOString()
        : moment().add(5, "year").endOf("day").toDate(),
    };
    console.log("f2", dataToSend);

    await apiAppointmentSearch
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          console.log("f4", response);
          const appointments = response.data.data;
          console.log("f3", appointments);

          if (appointments.length < 10) {
            dispatch(setHasMoreAppointments(false));
          }

          dispatch(setAppointments(appointments));
        } else {
          console.error("Error: Fetch Appointments : Response", response);
        }
      })
      .catch((error) => {
        console.error("Error: Fetch Appointments: ", error);
      })
      .finally(() =>
        dispatch(setAppointmentLoaders("appointmentsLoader", false))
      );
  };
};

export const fetchLeaves = (payload) => {
  console.log("fetchleavePayload", payload);
  return async (dispatch, getState) => {
    dispatch(setAppointmentLoaders("appointmentsLoader", true));
    dispatch(setHasMoreAppointments(true));

    const { leaveSearchParams, appointments } = getState().appointments;

    const dataToSend = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      searchValue: leaveSearchParams.leave
        ? leaveSearchParams.leave.leaveType
        : 0,
      startDate: leaveSearchParams.startDate
        ? moment(leaveSearchParams.startDate).startOf("day")
        : null,
      ...payload,

      endDate: payload.endDate
        ? payload.endDate
        : leaveSearchParams.endDate
        ? moment(leaveSearchParams.endDate).toISOString()
        : moment().add(5, "year").endOf("day").toDate(),
    };
    console.log("f2", dataToSend);

    await apiAppointmentSearch
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          console.log("f3", appointments);

          if (appointments.length < 10) {
            dispatch(setHasMoreAppointments(false));
          }

          const leaves = appointments
            .filter(
              (leave) =>
                leave.appointmentType &&
                leave.appointmentType == AppointmentType.LEAVE
            );
            console.log("leavefetched",leaves)
            const filteres=leaves.filter(
              (leave) =>
                leave.leaveType === payload.searchValue ||
                leave.startDate === payload.startDate ||
                leave.endDate === payload.endDate
            );
          console.log("leavefetchedData", filteres);
          dispatch(setAppointments(filteres));
        } else {
          console.error("Error: Fetch leaves : Response", response);
        }
      })
      .catch((error) => {
        console.error("Error: Fetch Leaves: ", error);
      })
      .finally(() =>
        dispatch(setAppointmentLoaders("appointmentsLoader", false))
      );
  };
};


export const fetchHolidays = (payload) => {
  console.log("fetchHolidaysPayload", payload);
  return async (dispatch, getState) => {
    dispatch(setAppointmentLoaders("appointmentsLoader", true));
    dispatch(setHasMoreAppointments(true));

    const { holidaySearchParams, appointments } = getState().appointments;

    const dataToSend = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      searchValue: holidaySearchParams.holiday
        ? holidaySearchParams.holiday.holidayType
        : 0,
      startDate: holidaySearchParams.startDate
        ? moment(holidaySearchParams.startDate).startOf("day")
        : null,
      ...payload,

      endDate: payload.endDate
        ? payload.endDate
        : holidaySearchParams.endDate
        ? moment(holidaySearchParams.endDate).toISOString()
        : moment().add(5, "year").endOf("day").toDate(),
    };
    console.log("f2", dataToSend);

    await apiAppointmentSearch
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          console.log("f3", appointments);

          if (appointments.length < 10) {
            dispatch(setHasMoreAppointments(false));
          }

          const holidays = appointments
            .filter(
              (holiday) =>
                holiday.appointmentType &&
              holiday.appointmentType == AppointmentType.HOLIDAY
            );
            console.log("holidayfetched",holidays)
            const filteres=holidays.filter(
              (holiday) =>
                holiday.holidayType === payload.searchValue ||
                holiday.startDate === payload.startDate ||
                holiday.endDate === payload.endDate
            );
          console.log("holidayfetchedData", filteres);
          dispatch(setAppointments(filteres));
        } else {
          console.error("Error: Fetch holidays : Response", response);
        }
      })
      .catch((error) => {
        console.error("Error: Fetch holidays: ", error);
      })
      .finally(() =>
        dispatch(setAppointmentLoaders("appointmentsLoader", false))
      );
  };
};
export const fetchMoreAppointments = (payload) => {
  return async (dispatch, getState) => {
    const prevAppointments = getState().appointments.appointments;
    const { appointmentSearchParams } = getState().appointments;

    dispatch(setAppointmentLoaders("moreAppointmentsLoader", true));

    dispatch(setHasMoreAppointments(true));

    const dataToSend = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      // endDate: null,
      ids: null,
      customerIds: appointmentSearchParams.customer
        ? [appointmentSearchParams.customer.id]
        : null,
      searchText: appointmentSearchParams.appointment
        ? appointmentSearchParams.appointment.title
        : "",
      categories: appointmentSearchParams.categories,
      startDate: appointmentSearchParams.startDate
        ? moment(appointmentSearchParams.startDate).startOf("day")
        : null,
      // endDate: appointmentSearchParams.endDate ? moment(appointmentSearchParams.endDate).endOf('day') : null,
      assignedIds: appointmentSearchParams.assignments
        ? [appointmentSearchParams.assignments.id]
        : null,
      ...payload,
      endDate: appointmentSearchParams.endDate
        ? appointmentSearchParams.endDate
        : moment().add(5, "year").endOf("day").toDate(),
    };

    await apiAppointmentSearch
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          let appointments = response.data.data;

          if (appointments.length < 10) {
            dispatch(setHasMoreAppointments(false));
          }

          appointments = [...prevAppointments, ...appointments];

          dispatch(setAppointments(appointments));
        } else {
          console.error("Error: Fetch more Appointments : Response", response);
        }
      })
      .catch((error) => {
        console.error("Error: Fetch more Appointments: ", error);
      })
      .finally(() =>
        dispatch(setAppointmentLoaders("moreAppointmentsLoader", false))
      );
  };
};

export const fetchAppointmentById = (appointmentId, offlineData) => {
  return async (dispatch, getState) => {
    dispatch(setAppointmentLoaders("appointmentDetailLoader", true));

    const dataToSend = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      startDate: null,
      endDate: null,
      assignedIds: [],
      categories: [],
      searchText: "",
      ids: [appointmentId],
    };

    await apiAppointmentSearch
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          // const appointments = getState().appointments;

          let appointment = response.data.data[0];
          // appointment = appointment ? appointment : null;
          // console.log("appointment details: appointment== ", appointment);
          // console.log("appointment details: AppointmentsDummyData== ", AppointmentsDummyData);

          if (
            appointment.appointmentType &&
            appointment.appointmentType === AppointmentType.LEAVE
          ) {
            appointment = offlineData;
            //  THIS BLOCK IS TEMPORARILY USED TO CHECK FUCNTIONALITY OF LEAVES
          }
          dispatch(setAppointmentById(appointment));
        } else {
          if (offlineData) {
            dispatch(setAppointmentById(offlineData));
          } else {
            console.error("Error: Fetch Appointment : Response", response);
          }
        }
      })
      .catch((error) => {
        if (offlineData) {
          console.log("offlineData", offlineData);
          dispatch(setAppointmentById(offlineData));
        } else {
          console.error("Error: Fetch Appointment: ", error);
        }
      })
      .finally(() =>
        dispatch(setAppointmentLoaders("appointmentDetailLoader", false))
      );
  };
};

// Assuming you have the JSON server running locally on port 3030
// const API_BASE_URL = "http://localhost:3030";

// export const addAppointment = (payload, onAddSuccess) => {
//   return async (dispatch, getState) => {
//     dispatch(setAppointmentLoaders('addAppointmentLoader', true));

//     // Combine date and time fields if necessary
//     payload = {
//       ...payload,
//       startDate: combineDateAndTime(payload.startDate, payload.startTime),
//       startTime: combineDateAndTime(payload.startDate, payload.startTime),
//       endDate: combineDateAndTime(payload.endDate, payload.endTime),
//       endTime: combineDateAndTime(payload.endDate, payload.endTime),
//     };

//     const dataToSend = {
//       title: "",
//       description: "",
//       mediaLinks: [],
//       categories: [],
//       status: AppointmentStatus.Draft,
//       customerId: "",
//       startDate: new Date().toISOString(),
//       endDate: new Date().toISOString(),
//       startTime: new Date().toISOString(),
//       endTime: new Date().toISOString(),
//       markedCompleteDate:"",
//       markedCompleteTime:"",
//       mapAddress: "",
//       lat: "",
//       lng: "",
//       visibility: JobVisibilityForCustomer.NotVisible,
//       ...payload
//     };

//     try {
//       // Make POST request to JSON server endpoint
//       const response = await fetch(`${API_BASE_URL}/posts`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json"
//         },
//         body: JSON.stringify(dataToSend)
//       });

//       if (response.ok) {
//         const appointmentId = "1111";

//           const newAppointment = {
//             ...payload,
//             id: appointmentId
//           };

//           const { appointments } = getState().appointments;

//           const updatedAppointments = [newAppointment, ...appointments];

//         // const newAppointment = await response.json();

//         // // Update appointments state with new appointment
//         // const { appointments } = getState().appointments;
//         // const updatedAppointments = [newAppointment, ...appointments];
//         dispatch(setAppointments(updatedAppointments));

//         toast.success('Appointment added successfully!!');

//         if (onAddSuccess) {
//           onAddSuccess(newAppointment);
//         }
//       } else {
//         console.error("Error: Add Appointments : Response", response);
//         toast.error('Error in adding appointment.');
//       }
//     } catch (error) {
//       console.error("Error: Add Appointments: ", error);
//       toast.error('Error in adding appointment.');
//     } finally {
//       dispatch(setAppointmentLoaders('addAppointmentLoader', false));
//     }
//   };
// };

export const addAppointment = (payload, onAddSuccess) => {
  console.log("z3", payload);

  return async (dispatch, getState) => {
    console.log("z4", payload);

    dispatch(setAppointmentLoaders("addAppointmentLoader", true));
    console.log("z5", payload);

    payload = {
      ...payload,
      startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startTime: combineDateAndTime(payload.startDate, payload.startTime),
      endDate: combineDateAndTime(payload.endDate, payload.endTime),
      endTime: combineDateAndTime(payload.endDate, payload.endTime),
    };
    console.log("z6", payload);

    const dataToSend = {
      title: "",
      description: "",
      mediaLinks: [],
      categories: [],
      status: AppointmentStatus.Draft,
      customerId: "",
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      startTime: new Date().toISOString(),
      endTime: new Date().toISOString(),
      markedCompleteDate: "",
      markedCompleteTime: "",
      mapAddress: "",
      lat: "",
      lng: "",
      visibility: JobVisibilityForCustomer.NotVisible,
      ...payload,
    };
    console.log("z7", dataToSend);

    await apiAppointmentCreate
      .post(dataToSend)
      .then((response) => {
        console.log("dataResponse", response);
        if (response.status === 200) {
          const appointmentId = response.data;

          const newAppointment = {
            ...payload,
            id: appointmentId,
          };

          const { appointments } = getState().appointments;

          const updatedAppointments = [newAppointment, ...appointments];
          console.log("z8", updatedAppointments);

          dispatch(setAppointments(updatedAppointments));
          console.log("z9", updatedAppointments);
          toast.success("Appointment added successfully!!");

          if (onAddSuccess) {
            console.log("z10", newAppointment);
            onAddSuccess(newAppointment);
          }
        } else {
          console.error("Error: Add Appointments : Response", response);
          toast.error("Error in adding appointment.");
        }
      })
      .catch((error) => {
        console.error("Error: Add Appointments: ", error);
        toast.error("Error in adding appointment2.");
      })
      .finally(() =>
        dispatch(setAppointmentLoaders("addAppointmentLoader", false))
      );
  };
};

export const addLeave = (payload, onAddSuccess) => {
  return async (dispatch, getState) => {
    dispatch(setAppointmentLoaders("addAppointmentLoader", true));

    let authUser = localStorage.getItem("authUser");
    authUser = authUser ? JSON.parse(authUser) : [];

    payload = {
      ...payload,
      startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startTime: combineDateAndTime(payload.startDate, payload.startTime),
      endDate: combineDateAndTime(payload.endDate, payload.endTime),
      endTime: combineDateAndTime(payload.endDate, payload.endTime),
      
    };

    const dataToSend = {
      leaveType: "",
      description: "",
      duration: 1,
      leaveBalance: 15,
      status: LeaveStatus.PENDING,
      mediaLinks: [],
      categories: [LeaveCategory.categoryId],
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      startTime: new Date().toISOString(),
      endTime: new Date().toISOString(),
      appointmentType: AppointmentType.LEAVE,
      employee: authUser,
      ...payload,
    };

    await axios
      .get("https://jsonplaceholder.typicode.com/users")
      .then((response) => {
        if (response.status === 200) {
          const leaveId = v4();

          const newAppointment = {
            ...dataToSend,
            id: leaveId,
          };

          const { appointments } = getState().appointments;

          const updatedAppointments = [newAppointment, ...appointments];

          dispatch(setAppointments(updatedAppointments));

          toast.success("Leave added successfully!!");

          if (onAddSuccess) {
            onAddSuccess(newAppointment);
          }
        } else {
          console.error("Error: Add Leaves : Response", response);
          toast.error("Error in adding Leave.");
        }
      })
      .catch((error) => {
        console.error("Error: Add Leave: ", error);
        toast.error("Error in adding Leave.");
      })
      .finally(() =>
        dispatch(setAppointmentLoaders("addAppointmentLoader", false))
      );
  };
};
export const addMeeting = (payload, onAddSuccess) => {
  console.log("addLeave is called!");
};
export const addHoliday = (payload, onAddSuccess) => {
  return async (dispatch, getState) => {
    dispatch(setAppointmentLoaders("addAppointmentLoader", true));

    payload = {
      ...payload,
      // startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startDate: payload.startDate,

      // startTime: combineDateAndTime(payload.startDate, payload.startTime),
      // endDate: combineDateAndTime(payload.endDate, payload.endTime),
      // endTime: combineDateAndTime(payload.endDate, payload.endTime),
    };

    const dataToSend = {
      holidayType: "",
      holidayName: "",
      description: "",
      categories: [HolidayCategory.categoryId],
      duration: 1,
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      startTime: new Date().toISOString(),
      endTime: new Date().toISOString(),
      appointmentType: AppointmentType.HOLIDAY,
      ...payload,
    };

    await axios
      .get("https://jsonplaceholder.typicode.com/users")
      .then((response) => {
        if (response.status === 200) {
          const holidayId = v4();

          const newAppointment = {
            ...dataToSend,
            id: holidayId,
          };

          const { appointments } = getState().appointments;

          const updatedAppointments = [newAppointment, ...appointments];

          dispatch(setAppointments(updatedAppointments));

          toast.success("Holiday added successfully!!");

          if (onAddSuccess) {
            onAddSuccess(newAppointment);
          }
        } else {
          console.error("Error: Add Holidays : Response", response);
          toast.error("Error in adding Holiday.");
        }
      })
      .catch((error) => {
        console.error("Error: Add Holiday: ", error);
        toast.error("Error in adding Holiday.");
      })
      .finally(() =>
        dispatch(setAppointmentLoaders("addAppointmentLoader", false))
      );
  };
};

export const updateAppointment = (payload, onAddSuccess) => {
  console.log("Updated->", payload);
  return async (dispatch, getState) => {
    dispatch(setAppointmentLoaders("editAppointmentLoader", true));

    payload = {
      ...payload,
      startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startTime: combineDateAndTime(payload.startDate, payload.startTime),
      endDate:
        payload.endDate &&
        payload.endTime &&
        combineDateAndTime(payload.endDate, payload.endTime),
      endTime:
        payload.endDate &&
        payload.endTime &&
        combineDateAndTime(payload.endDate, payload.endTime),
      // completeDate:payload.completeDate && payload.completeTime && combineDateAndTime(payload.completeDate, payload.completeTime),
      // completeTime:payload.completeDate && payload.completeTime && combineDateAndTime(payload.completeDate, payload.completeTime),
    };
    console.log("Updated->12", payload);
    const dataToSend = {
      id: "",
      title: "",
      description: "",
      categories: [],
      customerId: "",
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      completeDate: null,
      completeTime: null,
      mapAddress: "",
      lat: "",
      lng: "",
      visibility: 0,
      ...payload,
    };

    await apiAppointmentEdit
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          const updatedAppointment = {
            ...payload,
          };

          dispatch(setAppointmentById(updatedAppointment));

          if (onAddSuccess) {
            onAddSuccess(updatedAppointment);
          }

          toast.success("Appointment edit successfully!!");
        } else {
          console.error("Error: Edit Appointments : Response", response);
          toast.error("Error in editing appointment12.");
        }
      })
      .then(() => {
        if (payload.status === AppointmentStatus.SentRejected) {
          const updateStatusPayload = {
            ...payload,
            status:
              payload.status === AppointmentStatus.SentRejected
                ? AppointmentStatus.Draft
                : payload.status,
            meta: "",
          };

          dispatch(updateAppointmentStatus(updateStatusPayload));
        }
      })
      .catch((error) => {
        console.error("Error: Edit Appointments: ", error);
        toast.error("Error in editing appointment23.");
      })
      .finally(() =>
        dispatch(setAppointmentLoaders("editAppointmentLoader", false))
      );
  };
};

export const deleteAppointment = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setAppointmentLoaders("deleteAppointmentLoader", true));

    const appointmentId = payload.id;
    const dataToSend = {
      id: appointmentId,
      undo: false,
    };

    await apiAppointmentRemove
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          const { appointments } = getState().appointments;

          const updatedAppointments = appointments.filter(
            (appointment) => appointment.id !== appointmentId
          );

          dispatch(setAppointments(updatedAppointments));

          toast.success("Appointment deleted successfully!!");
        } else {
          console.error("Error: Delete Appointments : Response", response);
          toast.error("Error in deleting appointment.");
        }
      })
      .catch((error) => {
        console.error("Error: Delete Appointments: ", error);
        toast.error("Error in deleting appointment.");
      })
      .finally(() =>
        dispatch(setAppointmentLoaders("deleteAppointmentLoader", false))
      );
  };
};

export const assignAppointment = (payload) => {
  return async (dispatch) => {
    dispatch(setAppointmentLoaders("editAppointmentLoader", true));

    const dataToSend = payload.appointmentAssignments;

    await apiAppointmentAssign
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          const newAppointment = {
            ...payload,
          };

          dispatch(setAppointmentById(newAppointment));

          toast.success("Appointment assigned successfully!!");
        } else {
          console.error("Error: Assign Appointments : Response", response);
          toast.error("Error in assigning appointment.");
        }
      })
      .catch((error) => {
        console.error("Error: Assign Appointments: ", error);
        toast.error("Error in assigning appointment.");
      })
      .finally(() =>
        dispatch(setAppointmentLoaders("editAppointmentLoader", false))
      );
  };
};

export const updateAppointmentStatus = (payload) => {
  return async (dispatch,getState) => {
    dispatch(setAppointmentLoaders("editAppointmentLoader", true));

    const dataToSend = {
      appointmentId: payload.id,
      status: payload.status,
      meta: payload.meta,
    };

    await apiAppointmentChangeStatus
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          let newAppointment = {
            ...payload,
            rejectionMessage:payload.rejectionMessage
          };

          dispatch(setAppointmentById(newAppointment));

          toast.success("Appointment status updated successfully!!");
        } else {
          console.error(
            "Error: Update status Appointments : Response",
            response
          );
          toast.error("Error in updating status appointment.");
        }
      })
      .then(() => {
        if (
          payload.status === AppointmentStatus.Sent &&
          payload.visibility === JobVisibilityForCustomer.NotVisible
        ) {
          payload = {
            ...payload,
            visibility: JobVisibilityForCustomer.Visible,
          };
          dispatch(updateAppointment(payload, false));
        }
      })
      .catch((error) => {
        console.error("Error: Update status Appointments: ", error);
        toast.error("Error in updating status appointment.");
      })
      .finally(() =>
        dispatch(setAppointmentLoaders("editAppointmentLoader", false))
      );
  };
};

export const updateLeaveStatus = (payload) => {
  return async (dispatch) => {
    dispatch(setAppointmentLoaders("editAppointmentLoader", true));

    const dataToSend = {
      appointmentId: payload.id,
      status: payload.status,
      meta: payload.meta,
    };

    await apiAppointmentChangeStatus
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          const newAppointment = {
            ...payload,
          };

          dispatch(setAppointmentById(newAppointment));

          toast.success("Leave status updated successfully!!");
        } else {
          console.error("Error: Update status Leaves : Response", response);
          toast.error("Error in updating status leave.");
        }
      })
      .then()
      .catch((error) => {
        console.error("Error: Update status Leaves: ", error);
        toast.error("Error in updating status leave.");
      })
      .finally(() =>
        dispatch(setAppointmentLoaders("editAppointmentLoader", false))
      );
  };
};

export function handleAppointmentSearch(
  { sortAscending, pageSize, ...searchParams },
  payload = {}
) {
  return async (dispatch) => {
    console.log(
      "Appointment Filter Values: setAppointmentSearchParams",
      searchParams
    );

    await dispatch(setAppointmentSearchParams(searchParams));

    const searchPayload = {
      customerIds: searchParams.customer ? [searchParams.customer.id] : null,
      searchText: searchParams.appointment
        ? searchParams.appointment.title
        : "",
      categories: searchParams.categories,
      startDate: searchParams.startDate
        ? moment(searchParams.startDate).startOf("day")
        : null,
      endDate: searchParams.endDate
        ? moment(searchParams.endDate).endOf("day")
        : null,
      assignedIds: searchParams.assignments
        ? [searchParams.assignments.id]
        : null,
      ...(sortAscending !== undefined || !isNaN(sortAscending)
        ? { sortAscending }
        : {}),
      ...(searchParams.appointment
        ? {
            ...(searchParams.appointment.enterKey
              ? { searchText: searchParams.appointment.title }
              : {}),
            ...(!searchParams.appointment.enterKey
              ? { ids: [searchParams.appointment.id] }
              : {}),
          }
        : {}),
      ...(pageSize !== undefined || pageSize !== null ? { pageSize } : {}),
      ...payload,
    };

    await dispatch(fetchAppointments(searchPayload));
  };
}

export function handleLeaveSearch(
  { sortAscending, pageSize, ...searchParams },
  payload = {}
) {
  console.log("handleLeaveSearch is called!");
  
  return async (dispatch) => {
    console.log("Leave Filter Values: setLeaveSearchParams", searchParams);

    await dispatch(setLeaveSearchParams(searchParams));

    const searchPayload = {
      searchValue: searchParams.leaveType ? searchParams.leaveType : 0,
      startDate: searchParams.startDate
        ? moment(searchParams.startDate).startOf("day")
        : null,
      endDate: searchParams.endDate
        ? moment(searchParams.endDate).endOf("day")
        : null,
      ...(sortAscending !== undefined || !isNaN(sortAscending)
        ? { sortAscending }
        : {}),
      ...(pageSize !== undefined || pageSize !== null ? { pageSize } : {}),
      ...payload,
    };

    await dispatch(fetchLeaves(searchPayload));
  };
}

export function handleHolidaySearch(
  { sortAscending, pageSize, ...searchParams },
  payload = {}
) {
  console.log("handleHolidaySearch is called!");
  
  return async (dispatch) => {
    console.log("Holiday Filter Values: setHolidaySearchParams", searchParams);

    await dispatch(setHolidaySearchParams(searchParams));

    const searchPayload = {
      searchValue: searchParams.holidayType ? searchParams.holidayType : 0,
      startDate: searchParams.startDate
        ? moment(searchParams.startDate).startOf("day")
        : null,
      endDate: searchParams.endDate
        ? moment(searchParams.endDate).endOf("day")
        : null,
      ...(sortAscending !== undefined || !isNaN(sortAscending)
        ? { sortAscending }
        : {}),
      ...(pageSize !== undefined || pageSize !== null ? { pageSize } : {}),
      ...payload,
    };

    await dispatch(fetchHolidays(searchPayload)); 
  }
}
export const convertAppointmentToJob = (payload, onConvertToAppointment) => {
  return async (dispatch, getState) => {
    dispatch(setAppointmentLoaders("editAppointmentLoader", true));

    const jobPayload = {
      ...payload,
      mediaLinks: [],
      rateType: RateType.Fixed,
      startDate: AppConstants.DEFAULT_START_DATE,
      startTime: AppConstants.DEFAULT_START_TIME,
      endDate: AppConstants.DEFAULT_END_TIME,
      endTime: AppConstants.DEFAULT_END_TIME,
      taskAssignments: payload.appointmentAssignments,
      status: TaskStatus.Open,
      taxRate: AppConstants.DEFAULT_TAX_RATE,
    };

    console.log("convertAppointmentToJob=======jobPayload", jobPayload);

    delete jobPayload.id;

    await dispatch(
      createJob(jobPayload, [], onConvertToAppointment, "appointment", {
        success: "Appointment converted to job successfully.",
        error: "There is issue is converting to job",
      })
    );

    dispatch(setAppointmentLoaders("editAppointmentLoader", false));
  };
};

export const convertAppointmentToQuotation = (
  payload,
  onConvertToQuotation
) => {
  return async (dispatch, getState) => {
    dispatch(setAppointmentLoaders("editAppointmentLoader", true));

    const now = new Date();

    const quotationPayload = {
      ...payload,
      mediaLinks: [],
      startDate: AppConstants.DEFAULT_START_DATE,
      startTime: AppConstants.DEFAULT_START_TIME,
      endDate: AppConstants.DEFAULT_END_TIME,
      endTime: AppConstants.DEFAULT_END_TIME,
      offerDate: now,
      sentDate: now,
      dueInDays: 1,
      offerNumber: generateId(
        "quotation",
        payload.customerText ? payload.customerText : "No Customer"
      ),
      offerAssignments: payload.appointmentAssignments,
      status: QuotationStatus.Draft,
      taxRate: AppConstants.DEFAULT_TAX_RATE,
    };

    delete quotationPayload.id;

    await dispatch(addQuotation(quotationPayload, onConvertToQuotation));

    dispatch(setAppointmentLoaders("editAppointmentLoader", false));
  };
};

export const processAndUploadAppointmentMedia = (
  payload,
  isConvert = false
) => {
  return async (dispatch, getState) => {
    const { mediasToUpload, mediaLinks } = payload;
    console.log("mediaPayload", payload);
    dispatch(setAppointmentLoaders("uploadAppointmentMediaLoader", true));

    console.log("processAndUploadMedia STARTED");
    const uIds = mediasToUpload.map((item) => v4());

    console.log("processAndUploadMedia: uIds: ", uIds);

    // INIT UPLOAD
    const initRes = await apiAppointmentInitUpload
      .post({ ids: uIds })
      .catch((error) =>
        console.log("processAndUploadMedia: initupload error: ", error)
      );

    console.log("processAndUploadMedia: initRes: ", initRes);

    console.log(
      "===kkk===processAppointmentMediaUpload===mediasToUpload===",
      mediasToUpload
    );

    const uploadMediasPromise = mediasToUpload.map(async (media, index) => {
      console.log("processAndUploadMedia: media: ", media);

      const { mediaBlob, thumbnailBlob } = await getMediaData(media, isConvert);

      console.log("====== media.type media.type media.type=====", media.type);

      // UPLOAD
      try {
        fetch(initRes.data.links[index].m, {
          method: "PUT",
          body: mediaBlob,
          headers: { "Content-Type": media.type },
        });
        fetch(initRes.data.links[index].t, {
          method: "PUT",
          body: thumbnailBlob,
          headers: { "Content-Type": "image/png" },
        });
        fetch(initRes.data.links[index].b, {
          method: "PUT",
          body: mediaBlob,
          headers: { "Content-Type": media.type },
        });
      } catch (error) {
        console.log(
          "===processAndUploadMedia====apiInitUpload error: ==",
          error
        );
        media = null;
      }

      return media;
    });

    let uploadSuccessMedias = [];
    uploadSuccessMedias = await Promise.all(uploadMediasPromise);
    uploadSuccessMedias = uploadSuccessMedias.filter((media) => Boolean(media));

    if (!uploadSuccessMedias.length) {
      toast.error(
        `0 of ${mediasToUpload.length} medias uploaded successfully.`
      );
      dispatch(setAppointmentLoaders("uploadAppointmentMediaLoader", false));
      return;
    }

    const newMedias = uploadSuccessMedias.map((media, index) => ({
      id: uIds[index],
      title: media.name,
      fileType: media.type,
      abstract: "",
      link: uIds[index],
      mediaType: payload.mediaType,
      appointmentId: payload.appointmentId,
    }));

    const medias = [...newMedias, ...mediaLinks].map((media) => {
      return {
        link: media.id,
        mediaType: media.mediaType,
        appointmentId: payload.appointmentId,
        title: media.title,
        fileType: media.fileType,
      };
    });

    const dataToSend = {
      appointmentId: payload.appointmentId,
      medias: medias,
    };

    console.log("processAndUploadMedia: dataToSend: ", dataToSend);

    await apiAppointmentUploadMedia
      .post(dataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          const { appointmentById } = getState().appointments;

          let newMedias = uploadSuccessMedias.map(async (media, index) => {
            const { thumbnailBase64, fileType } = await getMediaData(
              media,
              isConvert
            );

            console.log(
              "======processAppointmentMediaUpload===thumbnailBase64===",
              thumbnailBase64
            );

            return {
              id: uIds[index],
              title: media.name,
              fileType: fileType,
              link: thumbnailBase64,
              mediaType: payload.mediaType,
              appointmentId: payload.appointmentId,
            };
          });

          newMedias = await Promise.all(newMedias);

          console.log(
            "===kkk===processAppointmentMediaUpload===newMedias===",
            newMedias
          );

          if (appointmentById) {
            dispatch(
              setAppointmentById({
                ...appointmentById,
                mediaLinks: [...newMedias, ...appointmentById.mediaLinks],
              })
            );
          }

          toast.success(
            `${uploadSuccessMedias.length} of ${mediasToUpload.length} medias uploaded successfully.`
          );
        } else {
          console.log("Upload Media Error: response: ", response);
          toast.error(
            "There is some issues in uploading job media, please try again!"
          );
        }
      })
      .catch((error) => {
        console.log("Upload Media Error: ", error);
        toast.error(
          "There is some issues in uploading job media, please try again!"
        );
      })
      .finally(() => {
        dispatch(setAppointmentLoaders("uploadAppointmentMediaLoader", false));
      });
  };
};

export const getAppointmentMediaLinks = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setAppointmentLoaders("getAppointmentMediaLoader", true));

    let dataToSend = {
      ids: payload.mediaLinks.map((image) => image.id),
      size: MediaSizeType.Thumbnail,
    };

    console.log("getMediaLinks: dataToSend: ", dataToSend);

    await apiAppointmentGetMediaLink
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          let newMedias = [];
          console.log("media fetch response: ", response.data.links);
          if (response.data.links.length) {
            newMedias = response.data.links.map((link) => {
              let mediaLink = payload.mediaLinks.find(
                (mediaLink) => link.id === mediaLink.id
              );

              if (mediaLink) {
                return {
                  ...mediaLink,
                  link: link.link,
                };
              } else {
                return mediaLink;
              }
            });

            const { appointmentById } = getState().appointments;

            console.log(
              "====getMediaLinks====appointmentById=====",
              appointmentById
            );

            const updatedAppointment = {
              ...appointmentById,
              mediaLinks: newMedias,
            };
            dispatch(setAppointmentById(updatedAppointment));

            console.log(
              "====getMediaLinks====updatedAppointment=====",
              updatedAppointment
            );

            console.log("====getMediaLinks====newMedias=====", newMedias);
          }
        } else {
          console.log("Media Load Error: response: ", response);
        }
      })
      .catch((error) => {
        console.log("Media Load Error: ", error);
      })
      .finally(() => {
        dispatch(setAppointmentLoaders("getAppointmentMediaLoader", false));
      });
  };
};

export const deleteAppointmentMedia = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setAppointmentLoaders("deleteAppointmentMediaLoader", true));

    const docToDeleteId = payload.id;
    const newList = payload.mediaLinks.filter(
      (image) => image.id !== docToDeleteId
    );

    const medias = newList.map((doc) => {
      return {
        link: doc.id,
        appointmentId: payload.appointmentId,
        mediaType: doc.mediaType,
      };
    });

    const dataToSend = {
      appointmentId: payload.appointmentId,
      medias: medias,
    };

    console.log("DELETE MEDIA", dataToSend);
    console.log(docToDeleteId);

    await apiAppointmentUploadMedia
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          console.log("delete media response: ", response);

          const { appointmentById } = getState().appointments;

          const updatedAppointment = {
            ...appointmentById,
            mediaLinks: newList,
          };

          dispatch(setAppointmentById(updatedAppointment));

          toast.success("Media deleted successfully!!!");
        } else {
          console.log("Delete media Error: ", response);
          toast.error("There was an issue with deleting media!!!");
        }
      })
      .catch((error) => {
        console.log("Delete media Error: ", error);
        toast.error("There was an issue with deleting media!!!");
      })
      .finally(() => {
        dispatch(setAppointmentLoaders("deleteAppointmentMediaLoader", false));
      });
  };
};

export const getResolvedAppointmentMediasByIds = (
  payload,
  onResolveMediasByIds,
  openMedia = true,
  returnAll = false
) => {
  return async (dispatch, getState) => {
    const { clickedMedia = {}, allMedias = [] } = payload;

    const ids = returnAll
      ? allMedias.map((media) => media.id)
      : clickedMedia
      ? [clickedMedia.id]
      : [];

    const dataToSend = {
      ids: ids,
      size: MediaSizeType.BigScreen,
    };

    dispatch(setAppointmentLoaders("getAppointmentMediaLoader", true));

    await apiAppointmentGetMediaLink
      .post(dataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          const mediaLinks = response.data.links;

          if (mediaLinks.length) {
            let allResolvedMedias = [];
            if (returnAll) {
              allResolvedMedias = mediaLinks.map(async (media, index) => {
                let fileType = null;
                let contentType = null;
                try {
                  const resp = await getContentType(media.link);
                  fileType = resp.fileType;
                  contentType = resp.contentType;
                } catch (error) {
                  toast.error(
                    "Media not found. Please delete and upload the media again."
                  );
                  return;
                }
                const isImage = fileType === FileType.Image;

                return {
                  ...media,
                  uri: media.link,
                  thumbnailUri: allMedias[index].link,
                  fileType: fileType,
                  type: contentType,
                  isResolved: true,
                  isImage,
                };
              });

              allResolvedMedias = await Promise.all(allResolvedMedias);
              allResolvedMedias = allResolvedMedias.filter(Boolean);

              onResolveMediasByIds(null, allResolvedMedias, false);
              dispatch(setResolvedAppointmentMedias(allResolvedMedias));
              return;
            }

            const media = mediaLinks.find(
              (_media) => _media.id === clickedMedia.id
            );

            let fileType = null;
            let contentType = null;
            try {
              const resp = await getContentType(media.link);
              fileType = resp.fileType;
              contentType = resp.contentType;
            } catch (error) {
              toast.error(
                "Media not found. Please delete and upload the media again."
              );
              return;
            }

            const isImage = fileType === FileType.Image;
            let resolvedMedia = {
              ...media,
              type: contentType,
              uri: media.link,
              thumbnailUri: clickedMedia.link,
              fileType,
              isResolved: true,
              isImage,
            };

            if (!isImage && openMedia) {
              window.open(resolvedMedia.uri, "_blank");
            }

            allResolvedMedias = mediaLinks.map((media, index) => {
              if (media.id === resolvedMedia.id) {
                return resolvedMedia;
              } else {
                return {
                  ...media,
                  uri: media.link,
                  thumbnailUri: allMedias[index].link,
                  fileType: FileType.Image,
                  isResolved: false,
                  isImage: true,
                };
              }
            });

            allResolvedMedias = await Promise.all(allResolvedMedias);
            allResolvedMedias = allResolvedMedias.filter(Boolean);

            if (onResolveMediasByIds) {
              const isOpeningMedia = openMedia && !isImage;

              onResolveMediasByIds(
                resolvedMedia,
                allResolvedMedias,
                isOpeningMedia
              );
              dispatch(setResolvedAppointmentMedias(allResolvedMedias));
            }
          }
        }
      })
      .catch((error) => {
        console.error("Media Load Error: ", error);
        toast.error("There is some issue in opening media.");
      })
      .finally(() => {
        dispatch(setAppointmentLoaders("getAppointmentMediaLoader", false));
      });
  };
};
