import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import ScreenView from "../../components/ScreenView/ScreenView";
import TestDetails from "../../components/TestDetails";
import Switcher from "../../common/components/GridListToggle/Switcher";
import TestQuestionSearchSidebar from "../../components/TestQuestionSearchSidebar";

function TestDetailsPage() {
  const styles = useStyles();

  const tillTablet = useMediaQuery("(max-width: 768px)");
  const [isGridView, setIsGridView] = useState(false);
  const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useState({
    questionTypes: [],
    questionSearch: "",
  });

  const renderContent = () => (
    <div className={styles.content_JobPage}>
      <div style={{ marginTop: 10 }}>
        <TestDetails
          page={page}
          setPage={setPage}
          isGridView={isGridView}
          setIsGridView={setIsGridView}
          searchParams={searchParams}
        />
      </div>
    </div>
  );

  return (
    <div className={styles.container_JobPage}>
      <ScreenView
        content={renderContent()}
        secondarySidebar={
          <TestQuestionSearchSidebar
            setPage={setPage}
            setSearchParams={setSearchParams}
          />
        }
      />
    </div>
  );
}

export default TestDetailsPage;

const useStyles = makeStyles((theme) => ({
  container_JobPage: {
    width: "100%",
    minHeight: "100vh",
  },
  content_JobPage: {},
  toolbar: {
    background: theme.colors.HOME_SEARCH_SCROLL_BACKGROUND_COLOR,
    display: "flex",
    alignItems: "center",
    padding: "8px",
  },
  searchIcon: {
    paddingRight: 5,
    borderRight: `2px solid ${theme.palette.text.disabled}`,
  },
  jobChipSlider: {
    display: "flex",
    padding: "5px 0",
    overflowX: "auto",
    marginLeft: 5,
    alignItems: "center",
    "&>*": {
      marginLeft: 8,
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  container: {
    background: theme.palette.background.dark,
    width: "100%",
    minHeight: "100vh",
  },
}));
