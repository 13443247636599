import { Chip, useTheme } from '@material-ui/core';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchMemberSuggest } from '../../../../actions/suggestionsActions';
import FormBox from '../../../../components/FormBox'
import UserSuggestionOption from '../../../../components/UserSuggestionOption/UserSuggestionOption';
import AutocompleteCNO from '../../../../library/AutocompleteCNO';
import { EventAttendeeRole, EventResponseActionStatus } from '../../../../utils/mappings';
import { useGlobalStyles } from '../../../../utils/Styles/GlobalStyles';

const AddEventAttendees = ({ formik, bgColor, title = "Attendees" }) => {

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const suggestions = useSelector(state => state.suggestions);

  const attendees = [
    ...formik.values.hosts,
    ...formik.values.coHosts,
    ...formik.values.audience,
  ];
  const filteredSuggestions = suggestions.filter(item => !attendees.some(attendee => attendee.id === item.id));

  return (
    <FormBox

      {...(bgColor ? { bgColor } : {})}
      {...(title ? { title } : {})}
    >
      <div className={GlobalStyles.sectionChildrenContainer}>

        <AutocompleteCNO
          name='hosts'
          label='Hosts'
          formik={formik}
          placeholder='Search Hosts'
          data={filteredSuggestions}
          multiple={true}
          forcePopupIcon={false}
          optionLabel={option => option.title || ""}
          renderOption={option => <UserSuggestionOption user={option} />}
          onSuggestionClick={(values) => {

            values = values.map(value => ({
              ...value,
              role: EventAttendeeRole.ids.Host,
              responseStatus: EventResponseActionStatus.ids.Going
            }));

            formik.setFieldValue("hosts", values);
          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchMemberSuggest(value));
            }
          }}
        />

        <AutocompleteCNO
          name='coHosts'
          label='Co-Host'
          formik={formik}
          placeholder='Search Co-host'
          data={filteredSuggestions}
          multiple={true}
          forcePopupIcon={false}
          optionLabel={option => option.title || ""}
          renderOption={option => <UserSuggestionOption user={option} />}
          onSuggestionClick={(values) => {

            values = values.map(value => ({
              ...value,
              role: EventAttendeeRole.ids.CoHost,
              responseStatus: EventResponseActionStatus.ids.Going
            }));

            formik.setFieldValue("coHosts", values);
          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchMemberSuggest(value));
            }
          }}
        />

        <AutocompleteCNO
          name='audience'
          label='Audience'
          formik={formik}
          placeholder='Search Audience'
          data={filteredSuggestions}
          multiple={true}
          forcePopupIcon={false}
          optionLabel={option => option.title || ""}
          renderOption={option => <UserSuggestionOption user={option} />}
          onSuggestionClick={(values) => {

            values = values.map(value => ({
              ...value,
              role: EventAttendeeRole.ids.Audience,
              responseStatus: EventResponseActionStatus.ids.Interested
            }));

            formik.setFieldValue("audience", values);
          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchMemberSuggest(value));
            }
          }}
        />

      </div>
    </FormBox>
  )
}

export default AddEventAttendees