import { makeStyles } from "@material-ui/core";

const statusIcon = {
  borderRadius: '50%',
  borderColor: 'grey',
  borderStyle: "ridge",
  padding: 2,
  marginRight: 5
};

const statusText = {
  display: 'flex',
  padding: '4px 15px',
  alignItems: 'center',
  borderRadius: '50px',
  background: 'linear-gradient(180deg, rgb(106 107 106) 9%, rgb(86 87 86) 53%)',
  '&:hover': {
    cursor: 'pointer'
  }
};

const doneButton = {
  borderRadius: '50%',
  padding: 2,
  position: "relative",
  top: -10,
  marginLeft: 5,
  width: '1.5rem',
  height: "1.5rem",
  '&:hover': {
    cursor: 'pointer'
  }
};

const doneIcon = {
  position: "relative",
  top: -3,
  marginLeft: -2
};

export const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 10,
    flex: 1,
    background: theme.colors.JOB_ACTION_CONTAINER_BACKGROUND_COLOR,
    // boxShadow: `1px 1px 6px -1px ${theme.palette.text.secondary}`,
    '&>*:nth-child(2n+1)': {
      fontSize: ({ tillTablet }) => tillTablet ? '0.8rem' : 'initial'
    },
    '&>*': {
      color: theme.palette.text.secondary,
    }
  },
  align: {
    display: 'flex',
    alignItems: 'center',
  },
  checkInActive: {
    color: theme.colors.JOB_ACTION_CONTAINER_BACKGROUND_COLOR,
    // color: "#FEDAD7",
    textShadow: "1px 1px 10px #BE0A02",
  },
  inProgressActive: {
    color: theme.colors.JOB_ACTION_CONTAINER_BACKGROUND_COLOR,
    // color: "#FEEBCE",
    textShadow: "1px 1px 10px #FFC300"
  },
  statusTextInactive: {
    ...statusText,
    background: theme.colors.JOB_ACTION_BUTTON_BACKGROUND_COLOR,
    borderColor: theme.colors.JOB_ACTION_BUTTON_BORDER_COLOR,
    // background: 'linear-gradient(180deg, rgb(106 107 106) 9%, rgb(86 87 86) 53%)',
    // borderColor: 'rgb(116 119 115)',
    color: theme.colors.JOB_ACTION_BUTTON_TEXT_COLOR,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  statusIconInactive: {
    ...statusIcon,
    background: theme.colors.JOB_ACTION_BUTTON_ICON_WRAPPER_BORDER_COLOR,
    borderColor: theme.colors.JOB_ACTION_BUTTON_ICON_WRAPPER_BORDER_COLOR,
    color: theme.colors.JOB_ACTION_BUTTON_TEXT_COLOR,
    // borderColor: "#7c7c7c7a"
  },

  // start
  statusIconStartActive: {
    ...statusIcon,
    borderColor: "#d86c67",
  },
  statusTextStartActive: {
    ...statusText,
    borderColor: "rgba(190,10,2,1)",
    textShadow: "0px 0px 10px #be0a02d4",
    color: theme.colors.JOB_ACTION_BUTTON_TEXT_COLOR,
  },

  // progress
  statusIconProgressActive: {
    ...statusIcon,
    borderColor: theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_ICON_COLOR
    // borderColor: "#ffd280"
  },
  statusTextProgressActive: {
    ...statusText,
    borderColor: theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR,
    background: theme.colors.JOB_ACTION_BUTTON_ACTIVE_BACKGROUND_COLOR,
    textShadow: "0px 0px 10px orange",
    color: theme.colors.JOB_ACTION_BUTTON_TEXT_COLOR,
  },

  // stop
  statusIconStopActive: {
    ...statusIcon,
    borderColor: theme.colors.JOB_ACTION_BUTTON_STOP_ACTIVE_ICON_COLOR
    // borderColor: "#c7ff99"
  },
  statusTextStopActive: {
    ...statusText,
    borderColor: theme.colors.JOB_ACTION_BUTTON_STOP_ACTIVE_COLOR,
    background: theme.colors.JOB_ACTION_BUTTON_ACTIVE_BACKGROUND_COLOR,
    textShadow: "0px 0px 10px #72ff00",
    color: theme.colors.JOB_ACTION_BUTTON_TEXT_COLOR,
  },

  // done button
  doneButtonInactive: {
    ...doneButton,
    background: theme.colors.JOB_ACTION_BUTTON_INACTIVE_DONE_WRAPPER_ICON_BACKGROUND_COLOR,
    borderColor: theme.colors.JOB_ACTION_BUTTON_INACTIVE_DONE_WRAPPER_ICON_BORDER_COLOR,
    color: theme.colors.JOB_ACTION_BUTTON_INACTIVE_DONE_TEXT_COLOR,
  },
  doneButtonActive: {
    ...doneButton,
    background: "#c7ff99",
    borderColor: "#72ff00",
    color: "#3F8A02",
  },

  // done icon
  doneIconActive: {
    ...doneIcon
  },
  doneIconInactive: {
    ...doneIcon,
    color: theme.colors.JOB_ACTION_BUTTON_INACTIVE_DONE_TEXT_COLOR,
  },

}));