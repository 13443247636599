import React from 'react';
import SelectCNO from '../../../../library/SelectCNO';
import { AutoCompleteSuggestionTypes, FormFieldsTypes } from '../../utils/enums';

function AutoCompleteAttributes({ formik }) {

  const handleSuggestionTypeChange = (value) => {
    const suggestionType = AutoCompleteSuggestionTypes.getItem(value, 'value');
    formik.setFieldValue('attributes.suggestionType', suggestionType.id);
    formik.setFieldValue('label', suggestionType.label);
  }

  return (
    <>
      {
        formik.values.type === FormFieldsTypes.ids.AutoComplete ?
          <SelectCNO
            name={'attributes.suggestionType'}
            formik={formik}
            label='AutoComplete Type'
            fullWidth
            options={AutoCompleteSuggestionTypes.entities}
            value={AutoCompleteSuggestionTypes.getItem(formik.values.attributes.suggestionType)?.value || ''}
            onChange={handleSuggestionTypeChange}
          />
          : null
      }
    </>
  )
}

export default AutoCompleteAttributes;