import React, { useState } from 'react';
import MapCNO from '../../components/MapCNO/MapCNO';
import MapSidebar from '../../components/MapSidebar/MapSidebar';
import ScreenView from '../../components/ScreenView';

function MapPage() {

  return (
    <>
      <ScreenView
        content={<MapCNO />}
        secondarySidebar={<MapSidebar />}
        headerTitle="Track User"
      />
    </>
  )
}

export default MapPage
