import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { mdiDelete, mdiImagePlus } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useRef, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { v4 } from 'uuid';
import { apiMedia } from '../../api';
import RightsManager from '../../utils/RightsManager';
import { apiInitUpload } from '../../api/apiInitUpload';
import { apiUploadMedia } from '../../api/apiUploadMedia';
import { toast } from 'react-toastify';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { CircularProgress } from '@material-ui/core';
import ConfirmYesNoDialog from '../ConfirmYesNoDialog/ConfirmYesNoDialog';
import { SnapItem, SnapList, useDragToScroll } from 'react-snaplist-carousel';
import { MediaType } from '../../utils';
import ImgsViewer from 'react-images-viewer';

function JobImagesDetailsCard({ job, setJob, header, mediaType, userType }) {

  let { id, mediaLinks } = job;

  const theme = useTheme();
  const isUserAdmin = RightsManager.isAdmin();

  const [isEditable, setIsEditable] = useState(false);

  const [media, setMedia] = useState([]);

  const [isMediaViewer, setIsMediaViewer] = useState(false);

  const [image] = useState([]);

  const [imageIndex, setImageIndex] = useState(null);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);

  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  console.log("Media Links: ", mediaLinks, job.mediaLinks);
  console.log("=========> media ==media : ", media);

  // UPLOAD IMAGE START

  /**
   * Convert a base64 string in a Blob according to the data and contentType.
   * 
   * @param b64Data {String} Pure base64 string without contentType
   * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
   * @param sliceSize {Int} SliceSize to process the byteCharacters
   * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
   * @return Blob
   */
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


  const processAndUploadImage = async (uri) => {

    setLoading(true);

    console.log("UPLOAD STARTED");
    const uId = v4();

    console.log("uri: ", uri);

    const imageURL = uri[0].link;

    // Split the base64 string in data and contentType
    const block = imageURL.split(";");
    // Get the content type of the image
    const contentType = block[0].split(":")[1];
    // get the real base64 content of the file
    const realData = block[1].split(",")[1];

    // console.log("Block: ", block)
    // console.log("contentType: ", contentType);
    // console.log("realData: ", realData);

    const blob = b64toBlob(realData, contentType);

    console.log("job image blob: ", blob);

    // INIT UPLOAD
    const initRes = await apiInitUpload.post({ "ids": [uId] })
      .catch((error) => {
        console.log("Image Upload Error: initRes: ", error);
      });

    // UPLOAD
    try {
      const mRes = await fetch(initRes.data.links[0].m, { method: 'PUT', body: blob });
      const tRes = await fetch(initRes.data.links[0].t, { method: 'PUT', body: blob });
      const bRes = await fetch(initRes.data.links[0].b, { method: 'PUT', body: blob });

    } catch (error) {
      console.log("Image Upload Error: initRes.data: ", error);
    }

    // SAVE TO SERVER
    const medias = [{
      id: uId,
      title: uId,
      abstract: "",
      link: uId,
      mediaType: mediaType,
      taskId: job.id
    }, ...mediaLinks].map((img) => {
      return {
        link: img.id,
        mediaType: img.mediaType,
        taskId: job.id
      };
    });

    const dataToSend = {
      taskId: job.id,
      medias: medias
    };

    console.log("upload image dataToSend: ", dataToSend);

    await apiUploadMedia.post(dataToSend)
      .then(response => {
        setLoading(false);
        if (response.status === 200) {
          const locationImgList = [{
            id: uId,
            title: uId,
            link: imageURL,
            mediaType: mediaType,
            taskId: job.id
          }, ...media];

          setMedia(locationImgList);

          setJob({
            ...job,
            mediaLinks: [{
              id: uId,
              title: uId,
              abstract: "",
              link: uId,
              mediaType: mediaType,
              taskId: job.id
            }, ...mediaLinks]
          });

          // job.mediaLinks = locationImgList;

          toast.success("Job image uploaded successfully!!!");

        }
        else {
          setLoading(false);
          toast.error("There is some issues in uploading job media, please try again!");
        }
      })
      .catch(error => {
        setLoading(false);
        console.log("Upload Image Error: ", error);
        toast.error("There is some issues in uploading job media, please try again!");
      });
  };

  // UPLOAD IMAGE END

  // DELETE IMAGE
  const processDelete = () => {

    setdeleteLoading(true);
    const newList = mediaLinks.filter(image => image.id !== imageIndex);

    const medias = newList.map(img => {
      return {
        // id: img.id,
        // title: img.id,
        // abstract: "",
        link: img.id,
        taskId: job.id,
        mediaType: img.mediaType,
      };
    });

    const dataToSend = {
      taskId: job.id,
      medias: medias
    };

    console.log('DELETE MEDIA', dataToSend);
    console.log(imageIndex);

    async function deleteAndUpload() {
      await apiUploadMedia.post(dataToSend)
        .then(response => {
          setdeleteLoading(false);
          if (response.status === 200) {
            console.log("delete response: ", response);
            job.mediaLinks = newList;
            setJob({
              ...job,
              mediaLinks: newList
            });
            toast.success("Image deleted successfully!!!");
          }
          else {
            toast.error("There was an issue with deleting image!!!");
          }
        })
        .catch(error => {
          setdeleteLoading(false);
          console.log("Delete Image Error: ", error);
          toast.error("There was an issue with deleting image!!!");
        });
    }
    deleteAndUpload();
  };
  // DELETE IMAGE END

  const styles = useStyles();

  useEffect(() => {
    console.log("mediaLinks fetch: ", mediaLinks);
    if (mediaLinks?.length > 0) {
      async function fetchMedia() {

        let dataToSend = [];
        if (userType === "employee") {
          dataToSend = {
            ids: mediaLinks.filter(image => image.mediaType === MediaType.Empty || image.mediaType === MediaType.Input)
              .map(image => image.link),
            size: 1
          };
        }
        else {
          dataToSend = {
            ids: mediaLinks.filter(image => image.mediaType === MediaType.Output)
              .map(image => image.link),
            size: 1
          };
        }

        // dataToSend = {
        // 	ids: mediaLinks.map(image => image.link), size: 1
        // };

        console.log("dataToSend fetch: ", dataToSend);

        apiMedia.post(dataToSend)
          .then(response => {
            if (response.status === 200) {
              let newImages = [];
              console.log("image fetch response: ", response.data.links);
              if (response.data.links.length) {
                newImages = response.data.links.map((link) => {
                  let mediaLink = mediaLinks
                    .filter((mediaLink) => link.id === mediaLink.id);

                  if (mediaLink.length) {
                    mediaLink = mediaLink[0];
                    return {
                      ...mediaLink,
                      link: link.link
                    };
                  }
                  else {
                    return mediaLink;
                  }

                });
              }
              setMedia(newImages);

            } else {
              toast.error("Error in loading image!!!");
            }
          })
          .catch(error => {
            console.log("Image Load Error: ", error);
            toast.error("Error in loading image!!!");
          });
      }
      fetchMedia();
    }
  }, [id, mediaLinks]);

  if (!media.length && userType === 'employee' && !isUserAdmin) {
    return null;
  }

  return (<>
    {
      deleteLoading &&
      <FullScreenLoaderCNO />
    }
    <div className={styles.container} onClick={() => setIsEditable(!isEditable)}>
      <Typography className={styles.cardTitle}>
        {header}
      </Typography>
      {
        !media.length
          ? <div className={styles.editImageIcon}>
            {
              loading &&
              <div className={styles.imageLoader}>
                <CircularProgress />
              </div>
            }
            {
              !loading &&
              <ImageUploading
                value={image}
                onChange={processAndUploadImage}
                dataURLKey="link"
              >
                {
                  ({
                    imageList,
                    onImageUpload,
                    onImageRemove,
                    onImageUpdate
                  }) => (
                    <div className={styles.upload_wrapper}>
                      <Icon
                        path={mdiImagePlus}
                        size="3rem"
                        onClick={(e) => {

                          onImageUpdate(0);

                        }}
                      />
                    </div>
                  )
                }
              </ImageUploading>
            }
          </div>
          : <div className={styles.cardContent} onClick={(e) => e.stopPropagation()}>
            {
              loading
                ? <div className={styles.imageLoader}>
                  <CircularProgress />
                </div>
                : ""
            }
            <SnapList ref={snapList}>
              {media.map((img, index) => (
                <SnapItem key={img.id} margin={{ right: 5, left: 5 }}>
                  <div className={styles.imageCard} onClick={() => setIsMediaViewer(true)}>
                    <LazyLoadImage
                      src={img.link}
                      effect="blur"
                      className={styles.image}
                      visibleByDefault={true}
                      placeholder={<CircularProgress />}
                      alt=""
                      onClick={() => setCurrentMediaIndex(index)}
                    />

                    {
                      // isEditable &&
                      <div className={styles.imagesActionRow} onClick={(e) => e.stopPropagation()}>
                        {
                          ((userType === 'employee' && isUserAdmin) || userType === 'assignee') &&
                          <div className={styles.imageActions} onClick={() => {
                            setDeleteAlertOpen(true);
                            setImageIndex(img.id);
                          }}>
                            <Icon
                              path={mdiDelete}
                              size='1.5rem'
                              color={theme.colors.ERROR_COLOR}
                            />
                          </div>
                        }
                      </div>
                    }
                  </div>
                </SnapItem>
              ))}
            </SnapList>

          </div>
      }
      {
        (media.length && ((userType === 'employee' && isUserAdmin) || userType === 'assignee')) ?
          <div className={styles.addImageActions} onClick={(e) => e.stopPropagation()}>
            <ImageUploading
              value={image}
              onChange={processAndUploadImage}
              dataURLKey="link"
            >
              {
                ({
                  imageList,
                  onImageUpload,
                  onImageRemove,
                  onImageUpdate
                }) => (
                  <Icon
                    path={mdiImagePlus}
                    size='2rem'
                    color={theme.colors.IMAGE_UPLOAD_ICON_COLOR}
                    className={styles.upload_wrapper}
                    onClick={() => {
                      onImageUpload(0);
                    }}
                  />
                )
              }
            </ImageUploading>
          </div> : ""
      }

    </div>

    <ImgsViewer
      imgs={media.map((image) => ({ src: image.link }))}
      currImg={currentMediaIndex}
      isOpen={isMediaViewer}
      onClose={() => setIsMediaViewer(false)}
      onClickPrev={() => setCurrentMediaIndex(index => index - 1)}
      onClickNext={() => setCurrentMediaIndex(index => index + 1)}
      onClickThumbnail={(index) => setCurrentMediaIndex(index)}
      showThumbnails
    />

    {
      deleteAlertOpen &&
      <ConfirmYesNoDialog
        open={deleteAlertOpen}
        setOpen={setDeleteAlertOpen}
        message="Are you sure, you want to delete image?"
        onConfirm={processDelete}
      />
    }

  </>)
}

export default JobImagesDetailsCard

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`
  },
  cardTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR
  },
  cardContent: {
    padding: theme.spacing(1),
    display: 'flex',
    // flexWrap: 'wrap',
    width: "100%",
    overflowX: 'auto',
    // width: 'fit-content',
    '&::-webkit-scrollbar': {
      display: 'none'
    },

  },
  snapList: {
    '&>*': {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    }
  },
  image: {
    width: 150,
    height: 150,
    borderRadius: 10,
    border: `2px solid ${theme.colors.LIGHT_COLOR_SHADE_1}`,
    '& [alt]': {
      marginLeft: -16,
      textIndent: 16
    }
  },

  imageLoader: {
    width: 150,
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imageCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  imagesActionRow: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  imageActions: {
    marginRight: theme.spacing(1)
  },
  editImageIcon: {
    color: theme.colors.IMAGE_UPLOAD_ICON_COLOR,
    textAlign: 'center'
  },
  addImageActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 8
  }
}))