import { mdiFormatText } from '@mdi/js';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { InvoiceStatus } from '../../utils/mappings';
import { updateInvoiceStatus } from '../../actions/invoiceActions';
import { getInvoiceRejectHtmlString } from '../../pdfTemplates/templates';

function InvoiceRejectDialog({ open, onClose, invoice, closeMoreActions }) {

  const dispatch = useDispatch();
  const { allInvoiceLoader: { editLoader } } = useSelector(state => state.invoices);

  const formik = useFormik({
    initialValues: {
      rejectReason: ""
    },
    validationSchema: yup.object({
      rejectReason: yup.string().required('Confirm Text is required')
    }),
    onSubmit: (values) => handleReject(values)
  });

  const handleReject = (values) => {

    const updatePayload = {
      ...invoice,
      status: InvoiceStatus.SentRejected,
      meta: getInvoiceRejectHtmlString(invoice, values.rejectReason)
    };
    dispatch(updateInvoiceStatus(updatePayload));

    onClose();

  };

  const handleClose = () => {

    onClose();

    if (closeMoreActions) {
      closeMoreActions();
    }

  };

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Reject Invoice"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name='rejectReason'
          label={`Are you sure, you want to reject?`}
          formik={formik}
          placeholder={"Please provide reasons for rejection."}
          icon={mdiFormatText}
          fullWidth
          autoFocus
        />

        <FormActionButtons
          rightText="Reject"
          formik={formik}
          leftClick={onClose}
          closeMoreActions={closeMoreActions}
        />

      </form>
    </DialogCNO>
  )
}

export default InvoiceRejectDialog
