import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../actions/authUserActions';
import DialogCNO from '../library/DialogCNO';
import LocalStorageConstants from '../utils/LocalStorageConstants';
import RightsManager from '../utils/RightsManager';
import FormActionButtons from './FormActionButtons';

function SubscriptionNotPresentMemeber() {

  const isUserMember = RightsManager.isMember();

  const history = useHistory();

  const dispatch = useDispatch();
  const {
    hasSubscriptionExpired,
    paymentDue,
    fetchPaymentHistoryLoader
  } = useSelector(state => state.subscription);

  console.log("=========SubscriptionNotPresentMemeber=====hasSubscriptionExpired====", { paymentDue, hasSubscriptionExpired });

  const [open, setOpen] = useState(hasSubscriptionExpired);

  useEffect(() => {
    setOpen(hasSubscriptionExpired || !paymentDue);
  }, [hasSubscriptionExpired, history.location.pathname, paymentDue]);

  if (!isUserMember) return null;

  if (fetchPaymentHistoryLoader) return null;

  return (
    <DialogCNO
      open={open}
      onClose={null}
      dialogTitle="Subscription Expired"
    >

      <Typography style={{ fontSize: '1.1rem' }}>
        Your subscription has expired, please renew your subscription to continue using the application. Please contact your Company Administrator to renew subscription.
      </Typography>

      <FormActionButtons
        rightText={'Logout'}
        rightClick={() => {
          dispatch(logout());
        }}
        isLeftButton={false}
      />

    </DialogCNO>
  )
}

export default SubscriptionNotPresentMemeber