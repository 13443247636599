import React, { Fragment, useEffect, useRef, useState } from 'react';
import { SnapItem, SnapList } from 'react-snaplist-carousel';
import { v4 } from 'uuid';
import ChipCNO from '../../library/ChipCNO';
import { getMaterialIconString } from '../../utils/icons';
import * as icons from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { handleServiceSearch, setServiceTimesFilter } from '../../actions/serviceActions';
import moment from 'moment';
import Icon from '@mdi/react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { TOGGLE_SEARCH } from '../../utils/actions';
import { useGlobalStore } from '../../providers/GlobalProvider';
import { TimesFilter, TimesFilterIcon, TimesFilterReverse } from '../../utils';
import { getPrefillAssignedUser, isFormikEmpty } from '../../utils/Helpers';
import RightsManager from '../../utils/RightsManager';
import ResetListing from '../../components/ResetListing';
import Switcher from '../../common/components/GridListToggle/Switcher';
import {Box} from '@material-ui/core';
function ServiceFilterBar({isGridView, setIsGridView}) {

  const isUserMember = RightsManager.isMember();

  const theme = useTheme();

  const snapList = useRef(null);

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const dispatch = useDispatch();
  const { serviceSearchParams, serviceTimesFilter } = useSelector(state => state.services);

  const [activeFilter, setActiveFilter] = useState(serviceTimesFilter);

  const { globalState, dispatchGlobal } = useGlobalStore();
  const { isSearch } = globalState;

  const initialValues = {
    customer: null,
    categories: [],
    service: null,
    startDate: null,
    endDate: null,
    assignments: isUserMember ? getPrefillAssignedUser() : null
  };

  useEffect(() => {
    setActiveFilter(serviceTimesFilter);
  }, [serviceTimesFilter]);

  const handleServiceSearchSidebar = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: !isSearch
    });
  };

  const handleServiceTimeFilter = (timeFilter) => {

    console.log("timefilter: ", timeFilter);

    if (timeFilter === activeFilter) {
      timeFilter = TimesFilter.None;
    }

    setActiveFilter(timeFilter);
    dispatch(setServiceTimesFilter(timeFilter));

    let startDate = null;
    let endDate = null;
    let sortAscending = true;

    switch (timeFilter) {
      case TimesFilter.None:
        startDate = null;
        endDate = null;
        sortAscending = false;
        break;

      case TimesFilter.Today:
        startDate = moment(new Date()).startOf('day').toDate();
        endDate = moment(new Date()).endOf('day').toDate();
        break;

      case TimesFilter.Tomorrow:
        startDate = moment(new Date()).add(1, 'days').startOf('day').toDate();
        endDate = moment(new Date()).add(1, 'days').endOf('day').toDate();
        break;

      case TimesFilter.ThisWeek:
        startDate = moment(new Date()).startOf('week').startOf('day').toDate();
        endDate = moment(new Date()).endOf('week').endOf('day').toDate();
        break;

      case TimesFilter.NextWeek:
        startDate = moment(new Date()).endOf('week').add(1, 'day').startOf('day').toDate();
        endDate = moment(new Date()).endOf('week').add(1, 'week').endOf('day').toDate();
        break;

      case TimesFilter.ThisMonth:
        startDate = moment(new Date()).startOf('month').startOf('day').toDate();
        endDate = moment(new Date()).endOf('month').endOf('day').toDate();
        break;

      case TimesFilter.Later:
        startDate = moment(new Date()).add(1, 'month').startOf('day').toDate();
        endDate = null;
        break;

      default:
        startDate = null;
        endDate = null;
        sortAscending = false;
        break;
    }

    console.log("==== Service time filter date values ==== ", { startDate, endDate });

    const params = {
      ...serviceSearchParams,
      startDate: startDate,
      endDate: endDate,
      sortAscending
    };

    console.log("==== Service time filter ServiceSearchParams ==== ", params);

    dispatch(handleServiceSearch(params));
  };

  const hasSearched = !isFormikEmpty(initialValues, serviceSearchParams);

  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        {
          tillTablet &&
          <Icon
            path={icons.mdiMagnify}
            size='2.4rem'
            onClick={handleServiceSearchSidebar}
            style={{
              paddingRight: 10,
              borderRight: `1px solid ${theme.colors.HOME_FILTER_SEARCH_DIVIDER_COLOR}`
            }}
          />
        }

        <SnapList ref={snapList}>
          {
            Object.values(TimesFilter)
              .filter(timeFilter => timeFilter !== TimesFilter.None)
              .map((timeFilter) => (
                <SnapItem key={v4()} margin={{ left: '5px', right: '5px' }}>
                  <ChipCNO
                    icon={icons[getMaterialIconString(TimesFilterIcon[timeFilter])]}
                    name={TimesFilterReverse[timeFilter]}
                    active={activeFilter === timeFilter}
                    onClick={() => handleServiceTimeFilter(timeFilter)}
                  />
                </SnapItem>
              ))
          }
        </SnapList>
        <Box>
          <Switcher isGridView={isGridView} setIsGridView={setIsGridView}/>
        </Box>
      </div>

      <ResetListing
        isReset={hasSearched}
        onReset={() => {
          dispatch(handleServiceSearch(initialValues));
          dispatch(setServiceTimesFilter(TimesFilter.None));
        }}
      />

    </Fragment>
  )
}

export default ServiceFilterBar;


