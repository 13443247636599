import {
  CREATE_JOB,
  DELETE_JOB_BY_ID,
  SET_JOB_DELETE_LOADER,
  SET_JOB_LOADER,
  SET_JOB_SEARCH_MANAGER,
  SET_SHARE_JOB_LOADER,
  SHARE_JOB,
  TOGGLE_IS_DELETE_UNDO,
  TOGGLE_SHARE_JOB_MODAL,
  UNDO_DELETE,
  SET_TASK_BY_ID,
  SET_JOB_TIMES_FILTER,
  SET_IS_JOB_FINISHED_FILTER,
  SET_JOB_MEDIA_LOADER,
  SET_JOB_MEDIA_UPLOAD_LOADER,
  SET_TODOS,
  SET_HAS_MORE_TODOS,
  SET_TASK_LOADERS,
  SET_RESOLVED_JOB_MEDIAS,
  TOGGLE_ADD_JOB_DIALOG
} from "../actions/jobActions";
import { JobTimesFilter } from "../utils/mappings";

const initialState = {
  jobs: [],
  taskById: null,

  isAddJobDialog: false,
  hasMoreTodos: true,

  resolvedJobMedias: [],

  jobSearchParams: {
    customer: null,
    startDate: null,
    endDate: null,
    assignedUsers: null,
    categories: [],
    job: null,
    statuses: []
  },
  jobTimesFilter: JobTimesFilter.None,

  todosLoader: false,
  todoDetailsLoader: false,
  addTodoLoader: false,
  editTodoLoader: false,
  deleteTodoLoader: false,
  moreTodosLoader: false,
  todoShareLoader: false,
  taskPaymentHistoryLoader: false,
  mediaUploadLoader: false,
  mediaDeleteLoader: false,
  mediaFetchLoader: false,
  getJobMediaLoader: false,

  isShareJobModal: false,
  isJobDeleteUndo: false,
  isJobDeleteLoader: false,
  isShareJobLoader: false,
  isJobLoader: false,
  isJobUnFinishedFilter: false,
  mediaLoader: false,
};

export default function jobsReducer(state = initialState, { type, payload }) {
  let newState = {};
  switch (type) {

    case SET_TODOS: {
      return {
        ...state,
        jobs: payload
      };
    }

    case SET_HAS_MORE_TODOS: {
      return {
        ...state,
        hasMoreTodos: payload
      };
    }

    case SET_TASK_LOADERS:
      const { loaderName, loaderState } = payload;
      state = {
        ...state,
        [loaderName]: loaderState
      };
      return state;

    case DELETE_JOB_BY_ID:
      const tempJobs = state.jobs.filter(job => job.id !== payload);
      newState = {
        ...state,
        jobs: tempJobs
      };
      return newState;

    case UNDO_DELETE:
      newState = {
        ...state,
        jobs: [payload, ...state.jobs]
      };
      return newState;

    case SET_JOB_DELETE_LOADER:
      newState = {
        ...state,
        isJobDeleteLoader: payload
      };
      return newState;

    case CREATE_JOB:
      newState = {
        ...state,
        jobs: [payload, ...state.jobs],
        taskById: payload
      };
      return newState;

    case SET_JOB_SEARCH_MANAGER:
      newState = {
        ...state,
        jobSearchParams: {
          ...state.jobSearchParams,
          ...payload,
        }
      };
      return newState;

    case TOGGLE_SHARE_JOB_MODAL:
      newState = {
        ...state,
        isShareJobModal: payload
      };
      return newState;

    case SET_SHARE_JOB_LOADER:
      newState = {
        ...state,
        isShareJobLoader: payload
      };
      return newState;

    case SHARE_JOB:
      const tempShareJobs = state.jobs.map((job) => {
        if (job.id === payload.jobId) {
          return {
            ...job,
            taskAssignments: payload.tasks
          };
        }
        else {
          return job;
        }
      });
      newState = {
        ...state,
        jobs: tempShareJobs
      };
      return newState;

    case TOGGLE_IS_DELETE_UNDO:
      newState = {
        ...state,
        isJobDeleteUndo: payload
      };
      return newState;

    case SET_JOB_LOADER:
      newState = {
        ...state,
        isJobLoader: payload
      };
      return newState;

    case SET_TASK_BY_ID:
      newState = {
        ...state,
        taskById: payload
      };
      return newState;

    case SET_JOB_TIMES_FILTER:
      newState = {
        ...state,
        jobTimesFilter: payload
      };
      return newState;

    case SET_IS_JOB_FINISHED_FILTER:
      newState = {
        ...state,
        isJobUnFinishedFilter: payload
      };
      return newState;

    case SET_JOB_MEDIA_LOADER:
      newState = {
        ...state,
        mediaLoader: payload
      };
      return newState;

    case SET_JOB_MEDIA_UPLOAD_LOADER:
      newState = {
        ...state,
        mediaDeleteLoader: payload
      };
      return newState;

    case SET_RESOLVED_JOB_MEDIAS:
      newState = {
        ...state,
        resolvedJobMedias: payload
      };
      return newState;

    case TOGGLE_ADD_JOB_DIALOG:
      console.log("dispatched22")

      newState = {
        ...state,
        isAddJobDialog: payload
      };
      return newState;

    default:
      return state;
  }
}