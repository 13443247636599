import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

function JobTypeIconSkeleton() {

    const useStyles = makeStyles(theme => ({
        container_JobTypeIconSkeleton: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        icon: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 60,
            height: 60,
            padding: theme.spacing(1.1),
            paddingTop: 2,
            background: theme.palette.background.light
        },
        jobCategory: {
            display: 'inline-block',
            padding: '1px 20px',
            borderRadius: 8,
            position: 'relative',
            top: -10,
            width: 100,
            height: 43
        }
    }));
    const styles = useStyles();

    return (
        <div className={styles.container_JobTypeIconSkeleton}>
            <Skeleton className={styles.icon} variant="circle" animation='wave' />
            <Skeleton variant="text" className={styles.jobCategory} animation='wave' />
        </div>
    )
}

export default JobTypeIconSkeleton
