import { Dialog, TextField, useMediaQuery } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import { useStyles } from './ShareJobModalSyles';
import HeadingBar from '../HeadingBar/HeadingBar';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import UserSuggestionOption from '../UserSuggestionOption/UserSuggestionOption';
import { Chip } from '@material-ui/core';
import { fetchMemberSuggest, resetSuggestions } from '../../actions/suggestionsActions';
import { Autocomplete } from '@material-ui/lab';
import { shareJob, toggleShareJobModal } from '../../actions/jobActions';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';

function ShareJobModal({ setIsShareOpen, isShareOpen, item, closeMore }) {

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const dispatch = useDispatch();
  const { isShareJobLoader } = useSelector(state => state.jobs);

  const suggestions = useSelector(state => state.suggestions);

  const handleCloseShareJobModal = () => {
    setIsShareOpen(false);
    dispatch(toggleShareJobModal(false));
    closeMore();
  };

  const validationSchema = yup.object({
    assignTo: yup.array().required("Required!!")
  });

  const formik = useFormik({
    initialValues: {
      assignTo: item.taskAssignments
    },
    validationSchema,
    onSubmit: values => handleShareJob(values)
  });

  const handleShareJob = (values) => {
    dispatch(shareJob(values, item));
    formik.resetForm();
    handleCloseShareJobModal();
  };

  const unSelectedSuggestions = () => suggestions.filter(item => {
    return formik.values.assignTo
      .map(item => item.staffId || item.id)
      .indexOf(item.id) === -1;
  });

  console.log("formik items: ", formik.values.assignTo, unSelectedSuggestions());

  const styles = useStyles({ tillTablet });
  return (
    <Dialog
      open={isShareOpen}
      className={styles.container_ShareJobModal}
      classes={{ paper: styles.paper }}
    >

      {
        isShareJobLoader &&
        <FullScreenLoaderCNO />
      }

      <HeadingBar title="Share Job" onClose={handleCloseShareJobModal} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <Autocomplete
          className={styles.textField}
          classes={{
            paper: styles.autocompletePaper,
            noOptions: styles.noOptions,
            popper: styles.popper
          }}
          multiple
          dense
          value={formik.values.assignTo}
          forcePopupIcon={false}
          options={unSelectedSuggestions()}
          getOptionLabel={option => option.title}
          renderOption={option => <UserSuggestionOption user={option} />}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.title || option.staffText}
                {...getTagProps({ index })}
                className={styles.chip}
              />
            ))
          }
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" placeholder="Select Team Member" />
          )}
          onChange={(e, newValue) => {
            formik.setFieldValue("assignTo", newValue);
          }}
          onInputChange={(e) => {
            if (e && e.target.value) {
              dispatch(fetchMemberSuggest(e.target.value));
            }
          }}
          onBlur={() => {
            dispatch(resetSuggestions());
          }}
        />
        <FormActionButtons
          rightText="Share"
          leftClick={handleCloseShareJobModal}
          formik={formik}
        />
      </form>
    </Dialog>
  )
}

export default ShareJobModal
