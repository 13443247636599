import React from 'react';
import { useStyles } from './NotificationTypeIconStyles';
import { NotificationTypeColor, NotificationTypeIcon } from '../../utils';
import Icon from '@mdi/react';
import { getMaterialIconString } from '../../utils/icons';
import * as icons from '@mdi/js';

function NotificationTypeIconRender({ type }) {

  const color = NotificationTypeColor[type];
  const styles = useStyles({ color });

  return (
    <div className={styles.container_NotificationTypeIcon}>
      <Icon
        className={styles.progressIcon}
        path={icons[getMaterialIconString(NotificationTypeIcon[type])]}
        size="2rem"
        color={color}
      />
    </div>
  )
}

export default NotificationTypeIconRender
