import moment from "moment";
import LocalStorageConstants from "../../utils/LocalStorageConstants";
import { EventAttendeeRole, EventResponseActionStatus, EventCategories } from "../../utils/mappings";
import { PostType } from "../PostsManagement/enums";

export const getAttendeeResponseStatus = (attendees, attendeeeId) => {

  if (!attendeeeId) {
    // const authUser = LocalStorageConstants.getItem("authUser");
    // attendeeeId = authUser.id;

    const host = attendees.find(attendee => attendee.role === EventAttendeeRole.ids.Host);
    attendeeeId = host?.id;
  }

  const attendee = attendees.find(attendee => attendee.id === attendeeeId);

  if (!attendee) return [];

  const result = EventResponseActionStatus.entities.reduce((_result, action) => {
    _result = [
      ..._result,
      {
        ...action,
        isTrue: action.id === attendee.responseStatus,
      }
    ];

    return _result;
  }, []);

  return result;

}

export const getAttendeesGroupedBasedOnStatus = (attendees = []) => {

  const groupedAttendees = attendees.reduce((result, attendee) => {

    if (!result[attendee.responseStatus]) {
      result = {
        ...result,
        [attendee.responseStatus]: []
      }
    }

    result = {
      ...result,
      [attendee.responseStatus]: [
        ...result[attendee.responseStatus],
        attendee
      ]
    };

    return result;

  }, []);

  return groupedAttendees;

}

export const getAttendeeRole = (attendee) => {

  return {
    isHost: attendee.role === EventAttendeeRole.ids.Host,
    isCoHost: attendee.role === EventAttendeeRole.ids.CoHost,
    isAudience: attendee.role === EventAttendeeRole.ids.Audience,
  }

}

export const getAttendeesGroupedBasedOnRole = (attendees = []) => {

  const groupedAttendees = attendees.reduce((result, attendee) => {

    if (!result[attendee.role]) {
      result = {
        ...result,
        [attendee.role]: []
      }
    }

    result = {
      ...result,
      [attendee.role]: [
        ...result[attendee.role],
        attendee
      ]
    };

    return result;

  }, []);

  return groupedAttendees;

}

export const getEventDuration = (startTime, endTime) => {

  const duration = moment(endTime).diff(startTime, 'hours') - moment(endTime).diff(startTime, 'days') * 24;

  return duration;

}

export const getEventCategory = (categories = []) => categories.map(categoryId => EventCategories.entities.find(eCategory => eCategory.id === categoryId)).filter(Boolean);

export const getEventDiscussionsByType = (posts) => {
  return posts.filter(post => [PostType.ids.EventDiscussion, PostType.ids.PostEventDiscussion].includes(post.postType));
}