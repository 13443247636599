import { mdiRestore } from '@mdi/js';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleHolidaySearch, setAppointmentTimesFilter } from '../../actions/appointmentActions';
import FormActionButtons from '../../components/FormActionButtons';
import JobChipsFilter from '../../components/JobChipsFilter';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import * as yup from 'yup';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import HeadingBar from '../../components/HeadingBar';
import { useGlobalStore } from '../../providers/GlobalProvider';
import { TOGGLE_SEARCH } from '../../utils/actions';
import InputLabelCNO from '../../library/InputLabelCNO';
import { TimesFilter } from '../../utils';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';
import SelectCNO from '../../library/SelectCNO';
import { HolidayType } from '../../utils/mappings';

function HolidayFilterModal() {

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const styles = useStyles({ tillTablet });
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { holidaySearchParams } = useSelector(state => state.appointments);

  const { dispatchGlobal } = useGlobalStore();
  const closeHolidayFilterDialog = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false
    });
  };


  const formik = useFormik({
    initialValues: {
      holidayType: null,
      holiday: null,
      startDate: null,
      endDate: null
    },
    onSubmit: (values) => {
      dispatch(handleHolidaySearch(values));
      closeHolidayFilterDialog();
    }
  });

  useEffect(() => {

    console.log("==========useEffectHolidaySearchParams", holidaySearchParams);

    formik.setValues(holidaySearchParams);
  }, [holidaySearchParams]);


  const handleResetForm = () => {
    formik.resetForm();
    dispatch(handleHolidaySearch(formik.initialValues));

    dispatch(setAppointmentTimesFilter(TimesFilter.None));

  };

  const triggerHolidaySearch = (values) => {
    if (!tillTablet) {
      dispatch(handleHolidaySearch(values));
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.container_HolidayFilterModal}>

      <HeadingBar
        title={formik.dirty ? "Reset" : "Search"}
        icon={formik.dirty ? mdiRestore : null}
        onHeaderClick={handleResetForm}
        onClose={formik.dirty ? undefined : closeHolidayFilterDialog}
      />

      <div style={{ marginTop: 12 }}></div>

      <SelectCNO
        name="holidayType"
        label="Select a Holiday Type"
        options={HolidayType.entities}
        fullWidth
        containerStyles={{ width: "98%" }}
        value={formik.values.holidayType}
        formik={formik}
        isRequired={false}
        onChange={(value) => {
          triggerHolidaySearch({ ...formik.values, holidayType: value });
        }}
      />

      <div style={{ marginTop: 12 }} />

      <DateTimePickerCNO
        dateName="startDate"
        datePlaceholder="Start Date"
        datePickerLabel='Start by Date'
        onDateChange={date => {
          formik.setFieldValue('startDate', date);
        }}

        formik={formik}
        iconColor="red"
        isRequired={false}
      />

      <DateTimePickerCNO
        dateName="endDate"
        datePlaceholder="End Date"
        datePickerLabel='End Date'
        onDateChange={date => {
          formik.setFieldValue('endDate', date);
        }}

        formik={formik}
        minDate={formik.values.startDate}
        iconColor="green"
        isRequired={false}
      />

      {
        tillTablet &&
        <FormActionButtons
          rightText="Search"
          rightClick={formik.handleSubmit}
          leftClick={closeHolidayFilterDialog}
          formik={formik}
        />
      }

    </form>
  )
}

export default HolidayFilterModal

const useStyles = makeStyles((theme) => ({
  container_HolidayFilterModal: {
    padding: 8,
    '&>*:first-child': {
      height: ({ tillTablet }) => tillTablet ? 'auto' : 68
    },
  },

  chip: {
    background: '#444'
  }
}));