import { ClickAwayListener } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { HexAlphaColorPicker } from "react-colorful";

export const PopoverPicker = ({ color, onChange, ...rest }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => toggle(false)}>
      <div style={{ position: "relative" }}>
        <div
          style={{ ...swatchStyles, backgroundColor: color }}
          onClick={() => toggle((prev) => !prev)}
        />

        {isOpen && (
          <div style={popoverStyles} ref={popover}>
            <HexAlphaColorPicker color={color} onChange={onChange} {...rest} />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

const swatchStyles = {
  width: "28px",
  height: "28px",
  borderRadius: "8px",
  border: "2px solid #fff",
  boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
};

const popoverStyles = {
  position: "absolute",
  top: "calc(100% + 2px)",
  left: 0,
  borderRadius: "9px",
  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  zIndex: 3000,
};
