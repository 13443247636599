import { Button, fade, IconButton, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCalculator, mdiDirections, mdiMapMarkerRadius, mdiTableClock } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import JobTypeIcon from '../JobTypeIcon';
import LinkCNO from '../../library/LinkCNO';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrackSearch, toggleWorkHourDialog, togglePayUserDialog } from '../../actions/trackSearchActions';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import LinesEllipsis from 'react-lines-ellipsis';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { combineDateAndTime } from '../../utils/Tracking';

function UserCard({ job, ...rest }) {

  const ResponsiveLines = responsiveHOC()(LinesEllipsis);

  const theme = useTheme();

  const tillTablet = useMediaQuery("(max-width: 768px)");

  const cardBP = useMediaQuery("(max-width: 500px)");

  const { title, categories } = job;

  const dispatch = useDispatch();

  const { isTrackSearchLoading, mapSearchParams, data } = useSelector(state => state.trackSearch);
  const trackMarkers = data;

  const teamMember = mapSearchParams.teamMember;
  const profileId = teamMember?.id;

  const trackStartDateTime = combineDateAndTime(mapSearchParams.trackStartDate, mapSearchParams.trackStartTime);
  const trackEndDateTime = combineDateAndTime(mapSearchParams.trackEndDate, mapSearchParams.trackEndTime);

  const openWorkHoursDialog = () => {
    if (!trackMarkers || !trackMarkers.length) {
      renderTaskMarkers(true);
    }
    dispatch(toggleWorkHourDialog(true));
  };

  const renderTaskMarkers = (isWorkHourCall = false) => {
    const trackSearchPayload = {
      taskIds: [job.id],
      profileId: profileId,
      startDate: trackStartDateTime,
      endDate: trackEndDateTime
    };
    const payload = {
      search: trackSearchPayload
    };
    dispatch(fetchTrackSearch(payload, isWorkHourCall));
  };

  const styles = useStyles({ tillTablet, cardBP });
  return (
    <div className={styles.container_UserCard} {...rest}>
      {
        isTrackSearchLoading &&
        <FullScreenLoaderCNO />
      }
      <div className={styles.iconActions}>
        <JobTypeIcon
          name={null}
          iconLink={null}
          categoryId={categories[0]}
        />

        <Button
          type='submit'
          variant='contained'
          className={styles.button}
          startIcon={<Icon path={mdiMapMarkerRadius} size="1.5rem" color={theme.colors.TRACK_JOB_CARD_BUTTON_ICON_COLOR} />}
          onClick={() => renderTaskMarkers(false)}
          autoCapitalize={false}
        >
          Track
        </Button>

        <Button
          type='submit'
          variant='contained'
          className={styles.button}
          startIcon={<Icon path={mdiTableClock} size="1.5rem" color={theme.colors.TRACK_JOB_CARD_BUTTON_ICON_COLOR} />}
          autoCapitalize={false}
          onClick={openWorkHoursDialog}
        >
          Pay
        </Button>

      </div>
      <Typography component="div" className={styles.jobTitle}>
        <ResponsiveLines
          text={title}
          maxLine='3'
          ellipsis='...'
          trimRight
          basedOn='words'
        />
      </Typography>
    </div>
  )
}

export default UserCard

const useStyles = makeStyles(theme => ({
  container_UserCard: {
    border: `2px solid ${theme.colors.TRACK_JOB_CARD_BORDER_COLOR}`,
    background: fade(theme.colors.TRACK_JOB_CARD_BACKGROUND_COLOR, 0.4),
    borderRadius: 10,
    padding: theme.spacing(1),
    // width: ({ tillTablet }) => tillTablet ? "90%" : '100%',
    width: "100%",
    margin: "0 auto",
    // height: 190,
    display: 'flex',
    flexDirection: 'column',
    // transform: ({ cardBP }) => cardBP ? 'scale(0.8)' : 'scale(1)'
    // justifyContent: 'center'
    // height: 'max-content'
  },
  iconActions: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '&>*': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '&>*:first-child': {
      marginRight: '4rem',
    },
    '&>*:nth-child(2)': {
      marginRight: 4,
    }
  },
  iconButtonWrap: {
    textAlign: 'center',
    '&>*:last-child': {
      marginTop: theme.spacing(-1.1),
      background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
      padding: '0 20px',
      border: `1px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
      borderRadius: 8,
      position: 'relative',
      zIndex: 1,
      fontSize: '0.7rem'
    },
    '&:hover': {
      cursor: 'pointer',
    }
  },
  button: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    border: theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR,
    '&:hover': {
      background: fade(theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR, 0.8),
    }
  },
  jobTitle: {
    color: theme.colors.TRACK_JOB_CARD_TITLE_COLOR
  }
}))
