import { makeStyles } from '@material-ui/core';
import { alignItems, backgroundColor, border, borderRadius, color, display, fontSize, gridTemplateColumns, height, justifyContent, marginRight, marginTop } from 'styled-system';

export const useTestStyles = makeStyles((theme) => ({
  mainbox :{
    height : "100vh",
    position : "fixed",
    width : " 100%",
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
  },
  headContainer :{
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
    padding: '14px 8px',
    marginTop: theme.spacing(10),
    marginLeft :"1rem",
    marginRight :"1rem",
    color: theme.colors.LIST_CARD_TITLE_COLOR,
    display : "flex",
    justifyContent : "space-between",
    fontSize : "16px",
    alignItems : "center",
    fontWeight : "bold"
  },
  time : {
    marginRight : "0.6rem",
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
    padding: "0.5rem 1rem",
    borderRadius : "5px"
  },

  headAnsStatus : {
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
    marginTop : theme.spacing(1),
    marginLeft :"1rem",
    marginRight :"1rem",
    padding: '14px 8px',
  },
  ansHead :{
    color: theme.palette.getContrastText(theme.palette.background.main),
    fontWeight: '500'
  },
  gridpattern : {
    marginTop : theme.spacing(1),
    background: theme.colors.LIST_CARD_PARA_BACKGROUND_COLOR,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderRadius: 5,
    color: theme.colors.LIST_CARD_PARA_COLOR,
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
  },
  btn : {
    borderRadius: "10%",
    fontWeight: "500",
    width: "2rem",
    height: "2rem",
    marginTop: "0.2rem",
    marginLeft: "1rem"
  },
  mainQuestionArea :{
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
    marginTop : theme.spacing(1),
    marginLeft :"1rem",
    marginRight :"1rem",
    padding: '14px 8px',
    color: theme.palette.getContrastText(theme.palette.background.main),
    //height : "42vh",
    
  },
  questionContainer :{
   // color: theme.colors.LIST_CARD_TITLE_COLOR,
    color: theme.palette.getContrastText(theme.palette.background.main),
  },
  quesHeading :{
    marginLeft: theme.spacing(18),
    color: theme.palette.getContrastText(theme.palette.background.main),
  },
  optionAnswer :{
    marginLeft: theme.spacing(18),
    marginTop: theme.spacing(2),
    color: theme.palette.getContrastText(theme.palette.background.main),
  },
  images : {
    width: "13%",
    marginLeft: theme.spacing(18),
    marginTop: "1rem",
  },
  ansHeading : {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(18),
    padding: "0.6rem 1.5rem",
    border: "1.3px solid black",
    borderRadius: "0.5rem",
  },
  imgAns :{
    marginTop: theme.spacing(1),
    padding: "0.4rem 0.8rem",
    border: "1.3px solid black",
    borderRadius: "0.5rem",
  },
  vedio : {
    width: "20%",
    marginLeft: theme.spacing(24),
    marginTop: "1rem",
  },
  videoAns :{
    marginTop: theme.spacing(1),
    padding: "0.4rem 0.8rem",
    border: "1.3px solid black",
    borderRadius: "0.5rem",
    marginLeft: theme.spacing(18),
  },
  headButtons :{
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
    marginTop : theme.spacing(1),
    marginLeft :"1rem",
    marginRight :"1rem",
    padding: '14px 8px',
    display :"flex",
    justifyContent :"space-between"
  },
  prevNext : {
    display : "flex",
    gap : "0.5rem",
    marginLeft :theme.spacing(1),
  },
  btn1 : {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
    padding: "0.3rem 1rem",
  },
  btn4 : {
    padding: "0.3rem 1rem",
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    marginRight :theme.spacing(1),
  },
  container: {},
  paper: {
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    borderRadius: 10,
    width: "40vw",
    maxWidth: "90vw",
    margin: ({ tillTablet }) => (tillTablet ? 8 : 32),
  },
  form: {
    padding: "20px",
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    overflowX: "hidden",
    "&>*": {
      marginBottom: theme.spacing(3),
    },
  },

}));