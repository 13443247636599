import { InputAdornment, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import Icon from '@mdi/react';
import React from 'react';
import InputLabelCNO from '../InputLabelCNO';

function SelectCNO({
  formik,
  value,
  name,
  label,
  labelPosition,
  icon,
  iconColor,
  options = [],
  onChange,
  containerStyles,
  disabled,
  showNoneOption = false,
  noneValue = "none",
  noneOption = {},
  isRequired=false,
  ...rest
}) {

  const styles = useStyles({ name, labelPosition });

  if (showNoneOption) {
    options = [
      { id: 0, value: 'none', label: "None", ...noneOption },
      ...options,
    ]
  }

  value = value
    ? value
    : formik
      ? formik.values[name]
      : '';

  if (showNoneOption && JSON.stringify(value) === JSON.stringify(noneValue)) {
    value = 'none'
  }

  const handleOnChange = (e) => {

    if (disabled) return;

    let value = e.target.value;

    value = showNoneOption && value === 'none' ? noneValue : value;

    formik && formik.setFieldValue(name, value);
    typeof onChange === 'function' && onChange(value);
  }

  if (!name) return null;

  return (
    <div className={styles.container_InputCNO} style={containerStyles}>
      {
        label &&
        // <Typography className={styles.label} component='label' htmlFor={name} aria-required>{label}</Typography>
        <InputLabelCNO
          label={label}
          isRequired={isRequired}
        />
      }
      <TextField
        id={name}
        select
        className={styles.textField}
        variant='outlined'
        // margin='dense'
        {...formik ? formik.getFieldProps(name) : {}}
        value={value}
        onChange={handleOnChange}
        {...formik ? { error: formik.touched[name] && Boolean(formik.errors[name]) } : {}}
        {...formik ? { helperText: formik.errors[name] } : {}}
        InputProps={icon && {
          startAdornment: <InputAdornment>
            <Icon color={iconColor ? iconColor : '#444'} path={icon} size='1.5rem' />
          </InputAdornment>
        }}
        style={{ minWidth: 127, }}
        disabled={disabled}
        {...rest}
      >
        {
          options.map(option => (
            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
          ))
        }
      </TextField>
    </div>
  )
}

export default SelectCNO


const useStyles = makeStyles((theme ) => ({
  container_SelectCNO: {
    display: 'flex',
    flexDirection: ({ labelPosition }) => labelPosition === 'left' ? 'row' : 'column',
    alignItems: ({ labelPosition }) => labelPosition === 'left' ? 'center' : 'flex-start',
  },
  label: {
    flex: 1,
    color: theme.colors.FORM_LABEL_COLOR,

  },
  textField: {
    borderRadius: 5,
    '& input': {
      background: 'white',
      color: '#444',
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: '#444'
    },
    '& .MuiOutlinedInput-adornedStart': {
      // background: 'white',
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      paddingTop: 8,
      paddingBottom: 8,
      background: 'white',
      color: '#444',
      borderRadius: 5,
    },
    '& .MuiSelect-icon': {
      color: '#444',
    }
  }
}))