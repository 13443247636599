import axios from 'axios';
import moment from 'moment';
import { ChartType, Widgets } from '../utils';
import { formatCurrency, getHiddenWidgetsLocal, getPrefillAssignedUser, getTodayInvoices, getTodayQuotations, getTotalInvoicesAmount, getWidgetDefaultLayout, parseNumber } from '../utils/Helpers';
import { getTotalAmountValues } from '../utils/InvoiceHelpers';
import LocalStorageConstants from '../utils/LocalStorageConstants';
import { CurrencyCode, CurrencyHTMLEntities, InvoiceStatus, PaymentByCustomerStatus, QuotationStatus, TaskStatus } from '../utils/mappings';
import RightsManager from '../utils/RightsManager';
import { handleAppointmentSearch, setAppointments, setAppointmentSearchParams } from './appointmentActions';
import { handleInvoiceSearch, resetInvoiceSearch, setInvoices, setInvoiceSearchParams } from './invoiceActions';
import { resetJobSearch, setJobSearchManager, setJobSearchParams, setTodos } from './jobActions';
import { handleQuotationSearch, resetQuotationSearch, setQuotations, setQuotationSearchParams } from './quotationActions';
import { getTrackCheckinsCount } from './trackSearchActions';

export const SET_WIDGETS = "SET_WIDGETS";
export const SET_LAYOUTS = "SET_LAYOUTS";
export const SET_DASHBOARD_LOADERS = "SET_DASHBOARD_LOADERS";
export const SET_WIDGET_BY_ID = "SET_WIDGET_BY_ID";

export const setWidgetById = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_WIDGET_BY_ID,
      payload: payload
    });
  };
};

export const setLayouts = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_LAYOUTS,
      payload: payload
    });
  };
};

export const setWidgets = (payload) => {
  return (dispatch) => {

    const hiddenWidgetsLocal = getHiddenWidgetsLocal();

    const widgets = payload.map(widget => {
      return {
        ...widget,
        hidden: hiddenWidgetsLocal.includes(widget.id),
      }
    });

    dispatch({
      type: SET_WIDGETS,
      payload: widgets
    });

  };
};

export const setDashboardLoaders = (loaderName, loaderState) => {
  return (dispatch) => {
    dispatch({
      type: SET_DASHBOARD_LOADERS,
      payload: { loaderName, loaderState }
    });
  };
};

export const getDashboardData = (payload) => {
  return async (dispatch) => {

    dispatch(setDashboardLoaders('dashboardDataLoader', true));

    await axios.get('/data/dashboard.json')
      .then(async (response) => {

        console.log("===== getDashboardData ==== ", response);

        const widgets = response.data.data.widgets
          .map((widget, idx) => ({ ...widget, defaultLayout: getWidgetDefaultLayout(idx, widget) }));
        const layouts = response.data.data.layouts;
        console.log("===== getDashboardData ====widgets ", widgets);

        dispatch(setWidgets(widgets));
        dispatch(setLayouts(layouts));

      })
      .catch((error) => {
        console.error("Widgets: Fetch: Error: ", error);
      })
      .finally(() => {
        dispatch(setDashboardLoaders('dashboardDataLoader', false));
      });

  };
};

export const fetchWidgets = (payload) => {
  return async (dispatch) => {

    dispatch(setDashboardLoaders('widgetsLoader', true));

    await axios.get('/data/widgets.json')
      .then(async (response) => {

        const { widgets } = response.data.data;

        dispatch(setWidgets(widgets));

      })
      .catch((error) => {
        console.error("Widgets by Id: Fetch: Error: ", error);
      })
      .finally(() => {
        dispatch(setDashboardLoaders('widgetsLoader', false));
      });

  };
};

export const fetchWidgetById = (payload) => {
  return async (dispatch, getState) => {

    const isUserMember = RightsManager.isMember();

    dispatch(setDashboardLoaders(`widget_${payload.id}_loader`, true));

    const { widgets } = getState().dashboard;

    const widgetIndex = widgets.findIndex(widget => widget.id === payload.id);
    let widgetById = widgets.find(widget => widget.id === payload.id);

    try {
      switch (payload.id) {
        case Widgets.Employees: {

          await dispatch(getTrackCheckinsCount());
          const { checkInsIds, totalEmployeesNumber } = getState().trackSearch;

          const workingTodayCount = checkInsIds && Array.isArray(checkInsIds) ? checkInsIds.length : 0;


          widgetById = {
            ...widgetById,
            statValues: {
              actual: workingTodayCount,
              total: totalEmployeesNumber
            },
            defaultLayout: getWidgetDefaultLayout(widgetIndex, widgetById),
            chart: {
              type: ChartType.DoughnutChart,
              chartData: {
                labels: ['Working Today', 'Total Employee'],
                datasets: {
                  label: "Working Today",
                  data: [workingTodayCount, totalEmployeesNumber],
                  backgroundColor: [
                    "rgba(0, 128, 0, 0.7)",
                    "rgba(173, 57, 57, 0.8)",
                  ]
                },
                centerText: `${workingTodayCount}/${totalEmployeesNumber}`
              },
              bgColor: "#DEDEDE"
            },
            data: null
          };

          break;
        }

        case Widgets.OngoingJobs: {
          const ongoingJobSearchValues = {
            customer: null,
            startDate: moment().startOf('day').toISOString(),
            endDate: moment().endOf('day').toISOString(),
            assignedUsers: null,
            categories: [],
            searchText: "",
            statuses: [TaskStatus.InProgress],

            pageSize: 100
          };

          await dispatch(setJobSearchManager(ongoingJobSearchValues));

          let { jobs } = getState().jobs;

          widgetById = {
            ...widgetById,
            statValues: {
              actual: jobs && Array.isArray(jobs) ? jobs.length : 0,
            },
            defaultLayout: getWidgetDefaultLayout(widgetIndex, widgetById),
            chart: null,
            data: jobs
          };

          dispatch(setTodos([]));
          dispatch(resetJobSearch(true));

          break;
        }

        case Widgets.AppointmentsToday: {
          const appointmentSearchValues = {
            customer: null,
            categories: [],
            appointment: null,
            startDate: moment().startOf('day').toISOString(),
            endDate: moment().endOf('day').toISOString(),
            assignments: null,

            pageSize: 100
          };
          await dispatch(handleAppointmentSearch(appointmentSearchValues));

          const { appointments } = getState().appointments;

          widgetById = {
            ...widgetById,
            statValues: {
              actual: appointments && Array.isArray(appointments) ? appointments.length : 0,
            },
            defaultLayout: getWidgetDefaultLayout(widgetIndex, widgetById),
            chart: null,
            data: appointments
          };

          dispatch(setAppointments([]));
          dispatch(setAppointmentSearchParams({
            customer: null,
            categories: [],
            appointment: null,
            startDate: null,
            endDate: null,
            assignments: isUserMember ? getPrefillAssignedUser() : null
          }));

          break;
        }

        case Widgets.QuotationDue: {
          const quotationSearchValues = {
            customer: null,
            categories: [],
            quotation: null,
            offerNumber: "",
            sentOn: null,
            validForDays: null,
            statuses: [QuotationStatus.Sent],
            isToday: true,

            pageSize: 100
          };
          await dispatch(handleQuotationSearch(quotationSearchValues));

          let { quotations } = getState().quotations;

          quotations = getTodayQuotations(quotations, quotationSearchValues.isToday);

          widgetById = {
            ...widgetById,
            statValues: {
              actual: quotations && Array.isArray(quotations) ? quotations.length : 0,
            },
            defaultLayout: getWidgetDefaultLayout(widgetIndex, widgetById),
            chart: null,
            data: quotations
          };

          dispatch(setQuotations([]));
          dispatch(resetQuotationSearch(true));

          break;
        }

        case Widgets.InvoiceDue: {
          const invoiceSearchValues = {
            customer: null,
            categories: [],
            invoice: null,
            invoiceNumber: "",
            billDate: null,
            dueInDays: null,
            statuses: [InvoiceStatus.Sent],
            paymentByCustomerStatuses: [PaymentByCustomerStatus.Empty, PaymentByCustomerStatus.Open, PaymentByCustomerStatus.Partial, PaymentByCustomerStatus.Cancelled],
            isToday: true,

            pageSize: 100
          };
          await dispatch(handleInvoiceSearch(invoiceSearchValues));

          let { invoices } = getState().invoices;

          invoices = getTodayInvoices(invoices, invoiceSearchValues.isToday)

          const noOfInvoices = invoices && Array.isArray(invoices) ? invoices.length : 0;

          let amountDue = 0;

          if (noOfInvoices) {
            amountDue = getTotalInvoicesAmount(invoices);
          }

          const chartValues = invoices.reduce((data, invoice) => {

            const customer = invoice.customerText;

            console.log("ChartLabelsCustom: reduce: customer:", customer);

            if (!Object.keys(data).includes(customer)) {
              data = { ...data, [customer]: 0 }
            }

            console.log("ChartLabelsCustom: reduce: data:", data);
            console.log("ChartLabelsCustom: reduce: invoice.rate:", invoice.rate);

            const { totalAmount } = getTotalAmountValues(invoice.rate, invoice.discount, invoice.taxRate, invoice.includeTax);

            data = {
              ...data,
              [customer]: data[customer] + totalAmount
            }

            console.log("ChartLabelsCustom: reduce: data: after: ", data);

            return data;

          }, {});

          console.log("ChartLabelsCustom: chartValues: ", chartValues);

          widgetById = {
            ...widgetById,
            statValues: {
              actual: noOfInvoices ? `${formatCurrency(amountDue)} (${noOfInvoices})` : '0 (0)',
            },
            defaultLayout: getWidgetDefaultLayout(widgetIndex, widgetById),
            "chart": {
              "type": ChartType.BarChart,
              "chartData": {
                "labels": Object.keys(chartValues),
                "datasets": {
                  "label": "Invoice Due",
                  "data": Object.values(chartValues),
                  "backgroundColor": [
                    "rgba(153, 102, 255, 1)"
                  ]
                }
              },
              "bgColor": "#DEDEDE"
            },
            data: invoices
          };

          dispatch(setInvoices([]));
          dispatch(resetInvoiceSearch(true));

          break;
        }

        case Widgets.UpcomingJobs: {

          const upcomingJobsSearchValues = {
            customer: null,
            startDate: moment().add(1, 'day').startOf('day').toISOString(),
            endDate: null,
            assignedUsers: null,
            categories: [],
            searchText: "",
            statuses: [],

            pageSize: 100
          };
          await dispatch(setJobSearchManager(upcomingJobsSearchValues));

          let { jobs } = getState().jobs;

          jobs = jobs.slice(0, 5);

          const listingData = {
            itemKeys: ['title', 'startDate', 'startTime'],
            itemURL: `/job/{{id}}>`
          }

          widgetById = {
            ...widgetById,
            // statValues: {
            //   actual: jobs && Array.isArray(jobs) ? jobs.length : 0,
            // },
            listingData: listingData,
            defaultLayout: getWidgetDefaultLayout(widgetIndex, widgetById),
            chart: null,
            data: jobs,
          };

          dispatch(setTodos([]));
          dispatch(resetJobSearch(true));

          break;
        }

        default:
          break;
      }

      dispatch(setWidgetById(widgetById));

    } catch (error) {

      console.error("Widgets by Id: Fetch: Error: ", payload.id, error);

    } finally {
      dispatch(setDashboardLoaders(`widget_${payload.id}_loader`, false));
    }


    // setTimeout(async () => {


    //   await axios.get('/data/widgets.json')
    //     .then(async (response) => {

    //       const { widgets } = response.data.data;

    //       const widgetById = widgets.find(widget => widget.id === payload.id);

    //       console.log("==== fetchWidgetById ===== ", { widgets, widgetById });

    //       dispatch(setWidgetById(widgetById));

    //     })
    //     .catch((error) => {
    //       console.error("Widgets by Id: Fetch: Error: ", error);
    //     })
    //     .finally(() => {
    //       dispatch(setDashboardLoaders(`widget_${payload.id}_loader`, false));
    //     });

    // }, 3000);

  };
};

export const updateWidgetsLayout = (layouts) => {
  return async (dispatch) => {

    dispatch(setDashboardLoaders('updateWidgetsLoader', true));

    await axios.get('/data/dashboard.json')
      .then(async (response) => {

        dispatch(setLayouts(layouts));

      })
      .catch((error) => {
        console.error("Widgets: Update: Error: ", error);
      })
      .finally(() => {
        dispatch(setDashboardLoaders('updateWidgetsLoader', false));
      });

  };
};

export const updateWidget = (payload) => {
  return async (dispatch, getState) => {

    dispatch(setDashboardLoaders('updateWidgetsLoader', true));

    const dashboard = getState().dashboard;
    const widgets = dashboard.widgets;
    const widget = dashboard[`widget_${payload.id}`];

    await axios.get('/data/widgets.json')
      .then(async (response) => {

        const _widgets = widgets.map(widget => {

          if (widget.id === payload.id) {
            return { ...widget, ...payload };
          } else {
            return widget;
          }

        });

        dispatch(setWidgets(_widgets));

        dispatch(setWidgetById({ ...widget, ...payload }));

      })
      .catch((error) => {
        console.error("Widgets: Update: Error: ", error);
      })
      .finally(() => {
        dispatch(setDashboardLoaders('updateWidgetsLoader', false));
      });

  };
};