import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container_ShareJobModal: {
    '& .MuiBackdrop-root': {
      background: 'transparent'
    }
  },
  paper: {
    padding: theme.spacing(2),
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    width: '70vw',
    maxWidth: '90vh',
    margin: ({ tillTablet }) => tillTablet ? 8 : 32
  },
  textField: {
    borderRadius: 5,
    '& input': {
      background: 'white',
      color: theme.colors.INPUT_TEXT_COLOR,
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: theme.colors.INPUT_TEXT_COLOR
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": theme.colors.INPUT_TEXT_COLOR,
      caretColor: theme.colors.INPUT_TEXT_COLOR,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 14
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0
    },
    '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      padding: 0
    }
  },
  autocompletePaper: {
    background: 'white !important',
    '& ::-webkit-scrollbar': {
      display: 'none'
    }
  },
  noOptions: {
    color: theme.colors.ERROR_COLOR
  },
  popper: {
    zIndex: 1301
  },
  assignToWrapper: {
    display: 'flex',
    // alignItems: 'flex-end',
    flexDirection: 'column',
    '&>*:first-child': {
      flex: 1,
      marginBottom: 8
    }
  },
  chip: {
    background: theme.colors.CHIP_BUTTON_BACKGROUND_COLOR
  },
}));