import React, { Fragment, useState } from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import LinkCNO from '../../library/LinkCNO';
import { Typography } from '@material-ui/core';
import Moment from 'react-moment';
import MoreActionCNO from '../../library/MoreActionsCNO';
import AppointmentStatusButtonBar from './AppointmentStatusButtonBar';
import AppointmentListCardPopover from './AppointmentListCardPopover';
import JobTypeIcon from '../JobTypeIcon/JobTypeIcon';
import { formatTimeWithTZ, getCategory } from '../../utils/Helpers';
import { calendarStrings, TIME_FORMAT } from '../../utils/formatDate';
import { mdiDirections } from '@mdi/js';
import Icon from '@mdi/react';
import ConfirmationDialogCNO from '../../library/ConfirmationDialogCNO';
import RightsManager from '../../utils/RightsManager';
import ResponsiveLinesCNO from '../../library/ResponsiveLinesCNO/ResponsiveLinesCNO';
import AppointmentGroupedMedia from './AppointmentGroupedMedia';
import clsx from 'clsx';

function AppointmentListCard({ appointment }) {

  const isUserAdmin = RightsManager.isAdmin();
  const isUserCustomer = RightsManager.isCustomer();

  const category = getCategory(appointment.categories[0]);

  const GlobalStyles = useGlobalStyles();

  const [isMapConfirmModal, setIsMapConfirmModal] = useState(false);

  return (
    <div className={clsx(GlobalStyles.listCardContainer, GlobalStyles.listCardFW)}>

      <div className={GlobalStyles.cardInfoWrapper}>
        <JobTypeIcon
          iconLink={category.iconLink}
          name={category.title}
          categoryId={category?.id}
        />
        <div className={GlobalStyles.cardInfo}>
          <LinkCNO to={`/appointment/${appointment.id}`}>
            <Typography className={GlobalStyles.cardTitle}>{appointment.title}</Typography>
            <div className={GlobalStyles.datesWrapper}>
              <Typography className={GlobalStyles.cardDate}>

                <Fragment>
                  <span>From&nbsp;</span>
                  <Moment interval={0} calendar={calendarStrings} >
                    {formatTimeWithTZ(appointment.startDate)}
                  </Moment>
                  &nbsp;
                  <Moment interval={0} format={TIME_FORMAT}>
                    {formatTimeWithTZ(appointment.startTime)}
                  </Moment>
                  &nbsp;
                </Fragment>

                <Fragment>
                  <span>To&nbsp;</span>
                  <Moment interval={0} calendar={calendarStrings} >
                    {formatTimeWithTZ(appointment.endDate)}
                  </Moment>
                  &nbsp;
                  <Moment interval={0} format={TIME_FORMAT}>
                    {formatTimeWithTZ(appointment.endTime)}
                  </Moment>
                </Fragment>
              </Typography>
            </div>
            {
              appointment.mapAddress &&
              <Typography style={{ marginTop: 4 }} className={GlobalStyles.cardInfoRowText}>
                Location: {appointment.mapAddress}
              </Typography>
            }

          </LinkCNO>
        </div>

        <div style={{ display: 'flex', alignItems: "center", height: '1.8rem' }}>

          {
            appointment.mapAddress &&
            <Icon
              className={GlobalStyles.moreIcon}
              path={mdiDirections}
              size='1.5rem'
              color={'grey'}
              onClick={() => setIsMapConfirmModal(true)}
            />
          }

          {
            (isUserAdmin || isUserCustomer) &&
            <MoreActionCNO>
              <AppointmentListCardPopover appointment={appointment} />
            </MoreActionCNO>
          }

        </div>

      </div>

      {/* <LinkCNO to={`/appointment/${appointment.id}`}>
        <pre className={GlobalStyles.cardDescription}>
          <ResponsiveLinesCNO
            text={appointment.description || "Appointment details not added"}
            maxLine='10'
            ellipsis='...'
            trimRight
            basedOn='words'
          />
        </pre>
      </LinkCNO> */}

      <AppointmentGroupedMedia appointment={appointment} />

      <AppointmentStatusButtonBar appointment={appointment} />

      {
        isMapConfirmModal &&
        <ConfirmationDialogCNO
          open={isMapConfirmModal}
          onClose={() => setIsMapConfirmModal(false)}
          dialogTitle="Navigate to Appointment"
          messages={["Do you want to navigate to appointment location?"]}
          yesClick={() => {
            window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${appointment.mapAddress}`, '_blank');
          }}
        />
      }

    </div>
  )
}

export default AppointmentListCard
