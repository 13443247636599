import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import Heading from '../../../common/components/Heading';
import 'react-image-gallery/styles/css/image-gallery.css';
import Container from '../../../common/components/UI/Container';
import FeatureSliderWrapper from './featureSlider.style';

import { useTheme } from '@material-ui/core';
import { ThemeType } from '../../../utils/mappings';
import darkColors from '../../../common/theme/LandingPage/darkThemeColors';
import colors from '../../../common/theme/LandingPage/colors';
import KeyFeatureFlipCard from '../KeyFeatureFlipCard';
import data from '../../../common/data/LandingPage/FeatureSliderTwo';

// import DomainSection from '../container/Hosting/Domain';

const FeatureSlider = ({ sectionSubTitle, sectionTitle, sectionHeader }) => {

  const isDarkTheme = useTheme().palette.type === ThemeType.Dark;

  const images = data.features.map(item => ({
    ...item,
    original: isDarkTheme ? item.darkThemeImage : item.lightThemeImage,
    thumbnailLabel: item.title,
    originalAlt: item.title,
    thumbnailAlt: item.title,
    thumbnail: item.iconName
  }));

  return (
    <FeatureSliderWrapper style={{ marginBottom: 370 }}>
      <div className="FeatureSliderInner">
        <span> </span>
        <span> </span>
        <span> </span>
      </div>
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionSubTitle} />
          <Heading {...sectionTitle} color={isDarkTheme ? darkColors.headingColor : colors.headingColor} />
        </Box>
        <Box className="FeatureSlider featureSliderSection">
          <ImageGallery
            items={images.map(image => ({ ...image, description: '', desc: image.description }))}
            className="Slider-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            showBullets={true}
            autoPlay={true}
            renderThumbInner={(item) => <KeyFeatureFlipCard item={item} />}
          />
        </Box>
      </Container>
    </FeatureSliderWrapper>
  );
};

// FeatureSlider style props
FeatureSlider.propTypes = {
  sectionSubTitle: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionHeader: PropTypes.object,
};

// FeatureSlider default style
FeatureSlider.defaultProps = {
  sectionHeader: {
    mb: '80px'
  },
  sectionSubTitle: {
    content: 'WHY CHOOSE US',
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.13em',
    fontWeight: '700',
    color: '#ff8000',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    content: 'Key features of our app',
    textAlign: 'center',
    fontSize: ['20px', '24px', '24px', '24px', '30px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
};

export default FeatureSlider;
