import { Divider, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Avatar, ListItemAvatar, ListItemIcon, ListItemText, useTheme } from '@material-ui/core';
import { List, ListItem } from '@material-ui/core';
import { mdiAccount, mdiAccountGroup, mdiAccountPlus, mdiClose, mdiMagnify, mdiTrashCan } from '@mdi/js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DialogCNO from '../../library/DialogCNO/DialogCNO';
import { useStyles } from './ChatProfileDetailsStyles';
import Icon from '@mdi/react';
import Moment from 'react-moment';
import AvatarCNO from '../../library/AvatarCNO/AvatarCNO';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import ChatProfileDetailsAvatar from '../ChatProfileDetailsAvatar/ChatProfileDetailsAvatar';
import SearchInputCNO from '../../library/SearchInputCNO/SearchInputCNO';

function ChatProfileDetails({ open, onClose, alreadySelectedUsers, handleRemoveUserConfirmation, handleAddUserToGroup }) {

  const authUser = JSON.parse(localStorage.getItem('authUser'));

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const { activeChatUser, universalLoader } = useSelector(state => state.chats);

  console.log("Group Members in details : ", alreadySelectedUsers);

  const [groupMembers, setGroupMembers] = useState(alreadySelectedUsers);
  const [memberSearchKey, setMemberSearchKey] = useState("");

  useEffect(() => {
    setGroupMembers(alreadySelectedUsers);
  }, [alreadySelectedUsers]);

  useEffect(() => {
    handleGroupMemberSearch();
  }, [memberSearchKey]);

  const handleGroupMemberSearch = () => {

    let filteredMembers = [];

    if (memberSearchKey) {
      filteredMembers = alreadySelectedUsers.filter((member) => `${member.firstName} ${member.lastName}`.toLowerCase().includes(memberSearchKey.toLowerCase()));
    } else {
      filteredMembers = alreadySelectedUsers;
    }

    setGroupMembers(filteredMembers);

  };


  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle={`${activeChatUser.contactName} Details`}
      bgColor={theme.colors.CHAT_PROFILE_DIALOG_BACKGROUND_COLOR}
    >
      {
        universalLoader &&
        <FullScreenLoaderCNO />
      }
      <div className={styles.chatProfileWrapper}>
        <div className={styles.avatarWrapper}>
          <ChatProfileDetailsAvatar activeChatUser={activeChatUser} />

        </div>
        <div className={styles.nameWrapper}>
          <Typography className={styles.name}>{activeChatUser.contactName}</Typography>
          {
            activeChatUser.contactType === 'group' &&
            <>
              <Typography variant="span" className={styles.date}> Created on </Typography>
              <Moment className={styles.date} format='MMM DD, YYYY \at hh:mm a'>{new Date()}</Moment>
            </>
          }
        </div>
      </div>
      {
        activeChatUser.contactType === "group" &&
        <div className={styles.memberListWrapper}>
          <List className={styles.list}>
            <ListItem className={GlobalStyles.listItem}>
              <ListItemText primary={`${groupMembers.length} Participants`} />

              <ListItemIcon>
                <SearchInputCNO
                  placeholder="Search member"
                  onSearch={(value) => setMemberSearchKey(value)}
                />
              </ListItemIcon>
            </ListItem>

            <Divider />
            {
              (authUser?.id === activeChatUser.createdBy) &&
              <ListItem className={GlobalStyles.listItem} onClick={() => {
                handleAddUserToGroup();
                // onClose();
              }}>
                <ListItemAvatar>
                  <Avatar style={{ background: theme.colors.GREEN_COLOR }}>
                    <Icon path={mdiAccountPlus} size="1.5rem" color={theme.colors.PROFILE_USER_PIC_ICON_COLOR} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Add Member" />
              </ListItem>
            }

            <Divider component="li" variant="inset" />
            {
              groupMembers.map((user) => (
                <>
                  <ListItem className={GlobalStyles.listItem} onClick={() => null}>
                    <ListItemAvatar>
                      <AvatarCNO
                        src={user.contactAvatar}
                        fallbackIcon={mdiAccount}
                        avatarStyle={{ border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}` }}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                    <ListItemIcon>
                      <IconButton edge='end'>
                        {
                          user.isAdmin &&
                          <Typography className={styles.groupAdmin}>Group Admin</Typography>
                        }
                        {
                          (authUser?.id === activeChatUser.createdBy) && !user.isAdmin &&
                          <Icon
                            path={mdiTrashCan}
                            size='1.5rem'
                            color={theme.colors.ERROR_COLOR}
                            onClick={() => {
                              handleRemoveUserConfirmation(user);
                              // onClose();
                            }}
                          />
                        }
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                  <Divider component="li" variant="inset" />
                </>
              ))
            }
          </List>
        </div>
      }
    </DialogCNO>
  )
}

export default ChatProfileDetails
