import React, { useCallback, useEffect, useState } from 'react';
import { useStyles } from './ChatMessagesStyles';
import { GiftedChat } from 'react-native-gifted-chat';
import { Text, View } from 'react-native';
import { renderActions, renderComposer, renderInputToolbar, renderSend, renderChatEmpty, renderUrlPreview } from '../../utils/ChatUtils/renders';
import {
  renderBubble,
  renderMessageText,
  // renderCustomView,
  renderMessageVideo,
  renderMessageAudio,
  renderMessage,
  renderAvatar,
  RenderCustomView,
  renderSystemMessage
} from '../../utils/ChatUtils/MessageContainer';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiArrowDownThick, mdiWechat } from '@mdi/js';
import {
  fetchActiveGroupMembers,
  fetchChatMessages,
  fetchMoreChatMessages,
  setChatMessages,
  setChatSnippetList,
  setHasMoreMessages
} from '../../actions/chatActions';
import ChatMessagesHeader from '../ChatMessagesHeader/ChatMessagesHeader';
import { apiMessageCreate } from '../../api';
import { toast } from 'react-toastify';
import { MessageType } from '../../utils';
import { getChatMessageSnippetListFromLocalStorage, sortChatsList } from '../../utils/ChatUtils/Helpers';
import { useMediaQuery, useTheme } from '@material-ui/core';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import { useLocation } from 'react-router-dom';
import messageReceivedSoundFile from '../../Sounds/messageReceived.mp3';
import { Howl } from 'howler';

function ChatMessages() {

  const theme = useTheme();

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const authUser = JSON.parse(localStorage.getItem('authUser'));

  const isJobDetailsPath = useLocation().pathname.includes("/job/");

  const dispatch = useDispatch();

  const [text, setText] = useState('');
  const [messages, setMessages] = useState([]);

  const messageReceivedSound = new Howl({
    src: [messageReceivedSoundFile],
    loop: false,
    preload: true
  });

  const chatsStore = useSelector(state => state.chats);
  const {
    activeChatUser,
    loaders,
    hasMoreMessages,
    activeGroupMembers
  } = chatsStore;

  const messageFromStore = chatsStore.messages;
  const { loadMoreChatLoader } = loaders;

  const [isCurrentUserGroupMember, setIsCurrentUserGroupMember] = useState(true);
  const [isSystemMessage, setIsSystemMessage] = useState(false);
  const [systemMessages, setSystemMessages] = useState([]);

  const noGroupMemberSystemMessage = {
    _id: new Date().toString(),
    text: "You can't send or receive messages in this group because you are no longer a member.",
    createdAt: new Date(),
    system: true,
  };

  // get members if it is a group
  useEffect(() => {

    // console.log("activeChatUser: ", activeChatUser);

    if (activeChatUser && activeChatUser.contactType === "group") {
      const groupMembersPayload = {
        pageSize: 10,
        pageIndex: 0,
        orderBy: "",
        sortDecending: true,
        groupId: activeChatUser.id
      };

      // console.log("checcckk: messages fetch");

      dispatch(fetchActiveGroupMembers(groupMembersPayload));
    }

  }, [activeChatUser]);

  console.log("=====custoemrCheck=====activeGroupMembers", activeGroupMembers);

  useEffect(() => {
    if (activeChatUser && activeChatUser.contactType === "group") {

      const member = activeGroupMembers.find((user) => (user.memberId === authUser.id) || (user.customerId === authUser.id));

      console.log("custoemrCheck=====isCurrentUserGroupMember: member: ", member);

      if (member) {
        setIsCurrentUserGroupMember(true);
        setIsSystemMessage(false);
      }
      else {
        setIsCurrentUserGroupMember(false);
        setIsSystemMessage(true);
      }
    }
    else {
      setIsCurrentUserGroupMember(true);
      setIsSystemMessage(false);
    }
  }, [activeGroupMembers]);

  useEffect(() => {
    if (isSystemMessage) {
      setSystemMessages([noGroupMemberSystemMessage]);
    }
    else {
      setSystemMessages([]);
    }
  }, [isSystemMessage]);

  // console.log("isCurrentUserGroupMember: ", isCurrentUserGroupMember);

  // console.log("isCurrentUserGroupMember: messages: ", messages);

  // fetch chats (either from server or local) on load of chats
  useEffect(() => {

    console.log("offlinechats: activeChatUser: ", activeChatUser);
    if (activeChatUser) {
      let localChatMessages = localStorage.getItem(LocalStorageConstants.localChatMessages(activeChatUser.id));

      if (!localChatMessages) {
        let oldMessagePayload = {};
        oldMessagePayload = {
          profileId: activeChatUser.contactType === 'user' ? activeChatUser.id : null,
          groupId: activeChatUser.contactType === 'group' ? activeChatUser.id : null,
          fromDateTime: null,
          toDateTime: null,
          zeroStart: true
        };
        console.log("offlinechats: making call to server");
        dispatch(fetchChatMessages(oldMessagePayload, systemMessages));
      }
      else {
        console.log("offlinechats: no call to server: got from localstorage");
        localChatMessages = JSON.parse(localChatMessages);
        console.log("offlinechats: userid chats ", activeChatUser.id, localChatMessages);
        console.log("offlinechats: length", localChatMessages.length);

        if (!activeChatUser.count) {

          console.log("offlinechats: on load: no total message count: showing offline chats");

          let isLastOfflineMessage = localStorage.getItem(LocalStorageConstants.isLastOfflineMessage(activeChatUser.id));

          // to hide and show loadearlier message button in chat
          if (!isLastOfflineMessage) {
            dispatch(setHasMoreMessages(true));
          }
          else {
            isLastOfflineMessage = JSON.parse(isLastOfflineMessage);
            dispatch(setHasMoreMessages(!isLastOfflineMessage));
          }

          if (isSystemMessage) {
            // localChatMessages = [...systemMessages, ...localChatMessages];
            console.log("localChatMessages: ", localChatMessages);
            setMessages(localChatMessages);
            dispatch(setChatMessages(localChatMessages));
          }
          else {
            setMessages(localChatMessages);
            dispatch(setChatMessages(localChatMessages));
          }

        }
        else {

          console.log("offlinechats: making call to server for latest message as there is profile message count");

          let oldMessagePayload = {};
          oldMessagePayload = {
            profileId: activeChatUser.contactType === 'user' ? activeChatUser.id : null,
            groupId: activeChatUser.contactType === 'group' ? activeChatUser.id : null,
            fromDateTime: null,
            toDateTime: null,
            zeroStart: true
          };
          dispatch(fetchChatMessages(oldMessagePayload, systemMessages));
        }
      }
    }
    //messageReceivedSound.play();
  }, [activeChatUser, activeGroupMembers, isSystemMessage]);

  useEffect(() => {
    return () => {
      setMessages([]);
      dispatch(setChatMessages([]));
    };
  }, []);

  useEffect(() => {
    setMessages(messageFromStore);
  }, [messageFromStore]);

  const messageSend = async (message, activeChatUser) => {

    let text = null;

    if (message[0].text && !message[0].text.includes("##Shared_Location##")) {
      text = message[0].text;
    }

    let messagePayload = {
      // ...message[0],
      text: message[0].text || null,
      toMessageGroupId: activeChatUser.contactType === 'group' ? activeChatUser.id : null,
      toMemberId: activeChatUser.contactType === 'user' ? activeChatUser.id : null,
      replyToId: null,
      url: message[0].url || null,
      preview: null,
      messageType: message[0].messageType || MessageType.Text
    };

    await apiMessageCreate.post(messagePayload)
      .then((response) => {
        console.log("Message Create Response: ", response);
        if (response.status === 200) {
          // console.log("inside messageSEnd autUser id location : ", authUser.id);

          const newMessage = {
            ...message[0],
            _id: response.data,
            text: text,
            createdAt: (new Date()).toString(),
            location: message[0].location || null,
            user: {
              _id: authUser.id,
              name: authUser.firstName + " " + authUser.lastName,
              avatar: authUser.profilePic
            }
          };

          setMessages(previousMessages => {

            let tempMessages;
            (async function setMessagesIIFE() {
              tempMessages = previousMessages.map(item => {
                if (item.loading && item._id === message[0].url) {
                  return newMessage;
                }
                else {
                  return item;
                }
              });

              if (!(newMessage.messageType === MessageType.Image || newMessage.messageType === MessageType.Video)) {
                tempMessages = [newMessage, ...tempMessages];
              }

              dispatch(setChatMessages(tempMessages));

              localStorage.setItem(LocalStorageConstants.localChatMessages(activeChatUser.id), JSON.stringify(tempMessages));

              if (tempMessages.length < 10) {
                // console.log("onsend: doing isLastOfflineMessage to true as messages are less than 10");
                localStorage.setItem(LocalStorageConstants.isLastOfflineMessage(activeChatUser.id), JSON.stringify(true));
              }
            })();

            return tempMessages;
          });

          let currentChatProfile = localStorage.getItem("currentChatProfile");
          if (!currentChatProfile) {
            currentChatProfile = {};
          }
          else {
            currentChatProfile = JSON.parse(currentChatProfile);
          }

          // save latest message 
          const latestMessage = {
            ...currentChatProfile,
            messageId: response.data,
            id: currentChatProfile.id,
            contactName: currentChatProfile.contactName,
            contactAvatar: currentChatProfile.contactAvatar,
            contactType: currentChatProfile.contactType,
            time: new Date(),
            snippet: newMessage.text || (message[0].location ? "##Shared_Location##" : ''),
            messageType: newMessage.messageType || MessageType.Text,
            count: 0
          };
          // console.log("saving message to local on send: ", latestMessage);

          let localChatSnippetList = getChatMessageSnippetListFromLocalStorage();

          let filteredChatSnippetaList = localChatSnippetList.filter(profile => profile.id !== latestMessage.id);

          let sortedChatSnippetList = sortChatsList([latestMessage, ...filteredChatSnippetaList]);

          // setLocalProfileList(newLocalProfileList);
          dispatch(setChatSnippetList(sortedChatSnippetList));
        }
        else {
          console.log("Message Create Error: ", response);
          toast.error("Error in message send");
        }
      })
      .catch((error) => {
        console.error("Message Create Error: ", error);
        toast.error("Error in message send");
      });
  };

  const onSend = useCallback((messages = [], activeChatUser) => {
    messageSend(messages, activeChatUser);
  }, []);

  const onLoadEarlier = () => {
    console.log("load earlier started");

    let isLastOfflineMessage = localStorage.getItem(LocalStorageConstants.isLastOfflineMessage(activeChatUser.id));

    if (!isLastOfflineMessage) {
      // make a server call once for session
      // if exist merge and save to local
      // else show "No further messages"

      // console.log("onscroll: no isLastOfflineMessage found calling server for older messages");
      // await getMessagesOnScroll();

      const payload = {
        groupId: activeChatUser.contactType === 'group' ? activeChatUser.id : null,
        profileId: activeChatUser.contactType === 'user' ? activeChatUser.id : null,
        zeroStart: false,
        fromDateTime: null,
        fromStartTime: null,
      };
      dispatch(fetchMoreChatMessages(payload, messages));
    }
    else {
      isLastOfflineMessage = JSON.parse(isLastOfflineMessage);
      console.log("onscroll: isLastOfflineMessage: ", isLastOfflineMessage);

      if (isLastOfflineMessage) {
        console.log("onscroll: it is the last message in offline");
        // add some display to show No furhter messages
        console.log('onscroll: No further messages');
      }
      else {
        console.log("onscroll: it is not the last message in offline making call to server to get next set of messages");
        const payload = {
          groupId: activeChatUser.contactType === 'group' ? activeChatUser.id : null,
          profileId: activeChatUser.contactType === 'user' ? activeChatUser.id : null,
          zeroStart: false,
          fromDateTime: null,
          fromStartTime: null,
        };
        dispatch(fetchMoreChatMessages(payload, messages));
      }
    }


    const payload = {
      groupId: activeChatUser.contactType === 'group' ? activeChatUser.id : null,
      profileId: activeChatUser.contactType === 'user' ? activeChatUser.id : null,
      zeroStart: false,
      fromDateTime: null,
      fromStartTime: null,
    };
    dispatch(fetchMoreChatMessages(payload, messages));

  };

  const styles = useStyles();

  if (!activeChatUser) {
    return (
      <View style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Icon path={mdiWechat} size="5rem" color="#e5b9b7" />
        <Text style={{
          fontSize: 20,
          color: "#5e5e5e"
        }}>
          Welcome to iConnect
        </Text>
      </View>
    )
  };

  const isCloseToTop = ({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToTop = 80;
    console.log("contentOffset: ", contentOffset);
    return contentSize.height - layoutMeasurement.height - paddingToTop <= contentOffset.y;
  }

  const paddingTop = tillTablet ? 40 : 60;

  return (
    <View style={{ flex: 1, height: `calc(100vh - ${paddingTop}px)`, backgroundColor: theme.colors.CHAT_MESSAGE_BACKGROUND_COLOR }}>
      {
        activeChatUser &&
        !isJobDetailsPath &&
        <ChatMessagesHeader
          activeChatUser={activeChatUser}
        />
      }
      <GiftedChat
        messages={messages}
        text={text}
        onInputTextChanged={setText}
        // extraData={activeChatUser}
        onSend={message => onSend(message, activeChatUser)}
        user={{
          _id: authUser.id,
          name: `${authUser.firstName} ${authUser.lastName}`,
          avatar: authUser.profilePic
        }}
        loadEarlier={hasMoreMessages}
        onLoadEarlier={onLoadEarlier}
        isLoadingEarlier={loadMoreChatLoader}
        listViewProps={{
          scrollEventThrottle: 400,
          onScroll: ({ nativeEvent }) => {
            if (isCloseToTop(nativeEvent)) {
              if (!loadMoreChatLoader && hasMoreMessages) {
                onLoadEarlier();
              }
            }
          }
        }}
        alwaysShowSend
        bottomOffset={5}
        scrollToBottom
        scrollToBottomComponent={() => (
          <Icon path={mdiArrowDownThick} size="2rem" color={theme.colors.SCROLL_TO_BOTTOM_ICON_COLOR} />
        )}
        // scrollToBottomOffset={100}
        renderUsernameOnMessage
        renderInputToolbar={(props) => isCurrentUserGroupMember ? renderInputToolbar(props, theme) : null}
        renderActions={(props) => renderActions(props, setText, theme)}
        renderComposer={(props) => renderComposer(props, theme)}
        renderSend={(props) => renderSend(props, theme)}
        messagesContainerStyle={{ backgroundColor: theme.colors.CHAT_MESSAGE_BACKGROUND_COLOR }}
        renderBubble={(props) => renderBubble(props, theme)}
        renderAvatarOnTop
        renderAvatar={(props) => renderAvatar(props, theme)}
        renderMessage={(props) => renderMessage(props, theme)}
        renderMessageText={(props) => renderMessageText(props, theme)}
        renderSystemMessage={(props) => renderSystemMessage(props, theme)}
        renderMessageVideo={(props) => renderMessageVideo(props, theme)}
        renderMessageAudio={(props) => renderMessageAudio(props, theme)}
        renderCustomView={(props) => RenderCustomView(props, theme)}
        renderChatEmpty={(props) => renderChatEmpty(props, theme)}
        isTyping={true}
        isCustomViewBottom
        parsePatterns={(linkStyle) => [
          {
            type: 'url',
            // style: linkStyle,
            onPress: (url) => window.open(url, '_blank'),
            renderText: (url) => renderUrlPreview(url),
          },
          {
            type: 'phone',
            style: linkStyle,
            onPress: (phone) => window.location.href = 'tel://' + phone,
          },
          {
            type: 'email',
            style: linkStyle,
            onPress: (email) => window.open('mailto:' + email),
          },
          {
            pattern: /#(\w+)/,
            style: linkStyle,
            onPress: (tag) => console.log(`Pressed on hashtag: ${tag}`),
          },
        ]}
      />

    </View>
  )
};

export default ChatMessages;
