import { Box, Collapse } from '@material-ui/core'
import React, { useState } from 'react'
import { InputCNO, InputLabelCNO, SwitchCNO } from '../../../../library'
import { FormFieldsTypes } from '../../utils/enums'
import GooglePlaceInputCNO from '../../../../library/GooglePlaceInputCNO'

const DefaulValueAttribute = ({ children, initialState }) => {

  const [hasDefaultValue, setHasDefaultValue] = useState(initialState)

  return (
    <>
      <Box display='flex' alignItems='center'>
        <InputLabelCNO label='Default Value' />
        <SwitchCNO
          checked={hasDefaultValue}
          onChange={() => setHasDefaultValue(prev => !prev)}
        />
      </Box>

      <Collapse in={hasDefaultValue}>
        {children}
      </Collapse>
    </>
  )
}

export default DefaulValueAttribute