import React from 'react'
import { Redirect, Route } from 'react-router-dom/cjs/react-router-dom.min'

function PrivateRoute({ children, ...rest }) {

  const authUser = JSON.parse(localStorage.getItem("authUser")) || {};
  const { token = "" } = authUser;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
