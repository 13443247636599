import React, { Fragment, useMemo } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Box, useTheme } from '@material-ui/core';
import { doughnutChartConfigs } from './doughnutChartConfigs';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

function DoughnutChart({
  chart,
  bgColor = "#1A73E8"
}) {

  const theme = useTheme();

  const { data, options } = doughnutChartConfigs(chart.labels || [], chart.datasets || {}, chart.centerText || "");

  console.log("DoughnutChart: ", { data, options });

  const chartPluginsSet = [
    {
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          // Get ctx from string
          const { ctx } = chart;

          // Get options from the center object in options
          const centerConfig = chart.config.options.elements.center;
          const fontStyle = centerConfig.fontStyle || 'Arial';
          const txt = centerConfig.text;
          const color = theme.colors.DASHBOARD_TEXT_COLOR || centerConfig.color || '#000';
          const maxFontSize = centerConfig.maxFontSize || 75;
          const sidePadding = centerConfig.sidePadding || 20;
          const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
          // Start with a base font of 30px
          ctx.font = "30px " + fontStyle;

          // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          const stringWidth = ctx.measureText(txt).width;
          const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          const widthRatio = elementWidth / stringWidth;
          const newFontSize = Math.floor(30 * widthRatio);
          const elementHeight = (chart.innerRadius * 2);

          // Pick a new font size so it will not be larger than the height of label.
          let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
          let minFontSize = centerConfig.minFontSize;
          const lineHeight = centerConfig.lineHeight || 25;
          let wrapText = false;

          if (minFontSize === undefined) {
            minFontSize = 20;
          }

          if (minFontSize && fontSizeToUse < minFontSize) {
            fontSizeToUse = minFontSize;
            wrapText = true;
          }

          // Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
          ctx.font = fontSizeToUse + "px " + fontStyle;
          ctx.fillStyle = color;

          if (!wrapText) {
            ctx.fillText(txt, centerX, centerY);
            return;
          }

          const words = txt.split(' ');
          let line = '';
          const lines = [];

          // Break words up into multiple lines if necessary
          for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = ctx.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > elementWidth && n > 0) {
              lines.push(line);
              line = words[n] + ' ';
            } else {
              line = testLine;
            }
          }

          // Move the center up depending on line height and number of lines
          centerY -= (lines.length / 2) * lineHeight;

          for (let n = 0; n < lines.length; n++) {
            ctx.fillText(lines[n], centerX, centerY);
            centerY += lineHeight;
          }
          //Draw text in center
          ctx.fillText(line, centerX, centerY);
        }
      }
    }
  ];

  return (
    <Fragment>
      <Box>
        {useMemo(
          () => (
            <Box
              style={{
                backgroundColor: 'transparent',
                padding: "2px 0.5px",
                // marginTop: 30,
                height: "12.5rem",
                borderRadius: 5
              }}
            >
              <Doughnut data={data} options={options} plugins={chartPluginsSet} />
            </Box>
          ),
          [chart, bgColor]
        )}
      </Box>
    </Fragment>
  )
}

export default DoughnutChart;