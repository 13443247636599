import React, { useEffect } from "react";
import ScreenView from "../../../components/ScreenView/ScreenView";
import { Box, makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormActionButtons from "../../../components/FormActionButtons";
import { FormSectionArray } from "../forms";
import { useDebouncedFormik } from "../../../hooks";
import { useParams, useHistory } from "react-router-dom";
import { isArrayEmpty } from "../../../utils/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { MetaText, Spacer } from "../../../library";
import {
  addForms,
  fetchFormById,
  handleFormSearch,
  setFormById,
} from "../../../actions/formBuilderActions";
import { FormStatus } from "../utils/enums";

Yup.addMethod(Yup.MixedSchema, "oneOfSchemas", function (schemas) {
  return this.test(
    "one-of-schemas",
    "Not all items in ${path} match one of the allowed schemas",
    (item) =>
      schemas.some((schema) => schema.isValidSync(item, { strict: true }))
  );
});

function FormDesignerPage() {
  const { id } = useParams();

  const styles = useStyles();

  const dispatch = useDispatch();
  const { formById, formByIdLoader, formSearchParams } = useSelector(
    (state) => state.formBuilder
  );

  useEffect(() => {
    const payload = {
      id,
    };

    dispatch(fetchFormById(payload));

    return () => {
      dispatch(setFormById(null));
    };
  }, []);

  const initialValues = formById || {};

  const validationSchema = Yup.object().shape({
    sections: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required("Section type is required"),
        title: Yup.string(),
        icon: Yup.string().nullable(),
        order: Yup.number()
          .typeError("Order should be a number")
          .required("Section order is required"),
        fields: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required("Field type is required"),
            name: Yup.mixed().oneOfSchemas([
              Yup.string().required("Field name is required."),
              Yup.object().required("Field name is required."),
            ]),
            label: Yup.mixed().oneOfSchemas([
              Yup.string().required("Field label is required."),
              Yup.object().required("Field label is required."),
            ]),
            helperText: Yup.string(),
            validations: Yup.object(), // Assuming validations is an array of strings
            order: Yup.number()
              .typeError("Field order should be a number")
              .required("Field order is required"),
          })
        ),
      })
    ),
  });

  const history = useHistory();

  const redirectToFormBuilderPage = () => {
    history.push("/forms/builder");

    localStorage.removeItem(`form_draft_${id}`);
  };

  const handleSaveForm = (values) => {
    console.log("FormDesignerPage: handleSaveForm: values: ", values);

    const payload = {
      ...values,
      id,
    };

    const onSuccess = () => {
      dispatch(handleFormSearch({ status: formSearchParams.status }));
      redirectToFormBuilderPage();
    };

    dispatch(addForms(payload, { onSuccess }));
  };

  return (
    <ScreenView headerTitle="Form Builder">
      {!formById ? (
        formByIdLoader ? (
          "Loading..."
        ) : (
          "No form found!"
        )
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSaveForm}
        >
          {function FormDesignerForm(formik) {
            useDebouncedFormik(formik, `form_draft_${id}`);

            console.log("FormDesignerForm: errors : ", formik.errors);

            useEffect(() => {
              formik.setValues({
                ...initialValues,
                ...formById,
              });
            }, [formById]);

            console.log("FormDesignerPage: values: ", formik.values);

            return (
              <Form className={styles.container}>
                <Spacer>
                  <MetaText>{formik.values.title}</MetaText>

                  <FormSectionArray formik={formik} />

                  {!isArrayEmpty(formik.values.sections) && (
                    <FormActionButtons
                      rightText="Save Form"
                      rightClick={() =>
                        formik.setFieldValue("status", FormStatus.id.Published)
                      }
                      secondaryText="Save as Draft"
                      secondaryClick={() =>
                        formik.setFieldValue("status", FormStatus.id.Draft)
                      }
                      formik={formik}
                      leftClick={redirectToFormBuilderPage}
                    />
                  )}
                </Spacer>
              </Form>
            );
          }}
        </Formik>
      )}
    </ScreenView>
  );
}

export default FormDesignerPage;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1rem",
  },
}));
