import { getItemFromArray } from "./Helpers";
import { getItemByTitleArray } from "./Helpers";
export const RateType = {
  Empty: 0,
  Fixed: 1,
  Hourly: 2,
};

export const RateTypeIcon = {
  Empty: 0,
  Fixed: "lock-clock",
  Hourly: "hours-24",
};

export const RateTypeReverse = {
  0: "Empty",
  1: "Fixed",
  2: "Hourly",
};

export const Gender = {
  None: 0,
  Female: 1,
  Male: 2,
  Others: 3,
};

export const GenderIcon = {
  None: 0,
  Female: "gender-female",
  Male: "gender-male",
  Others: "circle-outline",
};

export const Salutation = {
  None: 0,
  Mr: 1,
  Ms: 2,
  Mrs: 3,
  Dr: 4,
  Sir: 5,
};

export const SalutationReverse = {
  0: "",
  1: "Mr",
  2: "Ms",
  3: "Mrs",
  4: "Dr",
  5: "Sir",
};

export const SalutationIcon = {
  None: 0,
  Mr: "human-male",
  Ms: "human-female",
  Mrs: "human-female",
  Dr: "doctor",
  Sir: "account-tie",
};

export const MediaType = {
  Empty: 0,
  Input: 1,
  Output: 2,
};

export const FileType = {
  Image: 1,
  Pdf: 2,
  Doc: 3,
  Video: 4,
  Xls: 5,
  Xlsx: 6,
  Ppt: 7,
  Pptx: 8,
  Docx: 9,
  Text: 10,
  Gif: 11,
};

export const MimeType = {
  [FileType.Image]: "image/*",
  [FileType.Pdf]: "application/pdf",
  [FileType.Doc]: "application/msword",
  [FileType.Video]: "video/*",
  [FileType.Xls]: "application/vnd.ms-excel",
  [FileType.Xlsx]:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  [FileType.Ppt]: "application/vnd.ms-powerpoint",
  [FileType.Pptx]:
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  [FileType.Docx]:
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  [FileType.Text]: "text/plain",
};

export const MimeTypeReverse = {
  "image/*": FileType.Image,
  "application/pdf": FileType.Pdf,
  "application/msword": FileType.Doc,
  "video/*": FileType.Video,
  "application/vnd.ms-excel": FileType.Xls,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    FileType.Xlsx,
  "application/vnd.ms-powerpoint": FileType.Ppt,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    FileType.Pptx,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    FileType.Docx,
  "text/plain": FileType.Text,
};

export const TaskStatus = {
  Empty: 0,
  Open: 1,
  Started: 2,
  Done: 3,
  Cancelled: 4,
  InProgress: 5,
};

export const TaskStatusReverse = {
  [TaskStatus.Empty]: "Empty",
  [TaskStatus.Open]: "Open",
  [TaskStatus.Started]: "Stop",
  [TaskStatus.Done]: "Stop",
  [TaskStatus.Cancelled]: "Cancelled",
  [TaskStatus.InProgress]: "In Progress",
};

export const TaskStatusIcon = {
  [TaskStatus.Empty]: "bullseye",
  [TaskStatus.Open]: "Open",
  [TaskStatus.Started]: "Stop",
  [TaskStatus.Done]: "Stop",
  [TaskStatus.Cancelled]: "Cancelled",
  [TaskStatus.InProgress]: "In Progress",
};

export const TrackType = {
  Empty: 0,
  CheckIn: 1,
  CheckOut: 2,
  Location: 3,
  Done: 4,
  AppRestart: 5,
};

export const MessageType = {
  Empty: 0,
  Text: 1,
  Video: 2,
  Image: 3,
  PDF: 4,
  Doc: 5,
  Others: 6,
  Audio: 7,
  Typing: 8,
  VideoCall: 9,
  AudioCall: 10,
  VideoCallAccepted: 11,
  AudioCallAccepted: 12,
  VideoCallRejected: 13,
  AudioCallRejected: 14,
  VideoCallEnded: 15,
  AudioCallEnded: 16,
  VideoCallMissed: 17,
  AudioCallMissed: 18,
  Candidate: 19,
  AutoOffer: 20,
  AutoAnswer: 21,
  JoinCall: 22,
  AutoJoinCallAnswer: 23,
};

export const NotificationFeatureGroup = {
  Company: 1,
};

export const NotificationType = {
  Allocation: 1,
  TaskChanged: 2,
  TaskDeleted: 3,
  TaskStartReminder: 4,
  PaymentProcessed: 5,
  TaskStateChanged: 6,
  TaskStartDelayed: 7,
  TaskEndDelayed: 8,
  TaskCreated: 9,
  TaskMediaUpdated: 10,
  SubTaskAllocation: 11,
  SubTaskChanged: 12,
  SubTaskDeleted: 13,
  SubTaskStartReminder: 14,
  SubPaymentProcessed: 15,
  SubTaskStateChanged: 16,
  SubTaskStartDelayed: 17,
  SubTaskEndDelayed: 18,
  SubTaskCreated: 19,
  SubTaskMediaUpdated: 20,
  ProfileCreated: 21,
  InvoiceSent: 22,
  InvoiceRejected: 23,
  InvoiceAccepted: 24,
  OfferSent: 25,
  OfferRejected: 26,
  OfferAccepted: 27,
  ProfileEdited: 28,
  GroupEdited: 29,
  GroupRemoved: 30,
  GroupCategoriesChanged: 31,
  GroupPaymentMethodChanged: 32,
  AppointmentSent: 33,
  AppointmentRejected: 34,
  AppointmentAccepted: 35,
};

export const NotificationTypeColor = {
  [NotificationType.Allocation]: "#039668",
  [NotificationType.TaskChanged]: "#A89561",
  [NotificationType.TaskDeleted]: "#cf543c",
  [NotificationType.TaskStartReminder]: "#436a93",
  [NotificationType.PaymentProcessed]: "#5ba668",
  [NotificationType.TaskStateChanged]: "#A89561",
  [NotificationType.TaskStartDelayed]: "#cf543c",
  [NotificationType.TaskEndDelayed]: "#5ba668",
  [NotificationType.TaskCreated]: "#B6B303",
  [NotificationType.TaskMediaUpdated]: "#A89561",
  [NotificationType.SubTaskAllocation]: "#039668",
  [NotificationType.SubTaskChanged]: "#A89561",
  [NotificationType.SubTaskDeleted]: "#cf543c",
  [NotificationType.SubTaskStartReminder]: "#436a93",
  [NotificationType.SubPaymentProcessed]: "#5ba668",
  [NotificationType.SubTaskStateChanged]: "#A89561",
  [NotificationType.SubTaskStartDelayed]: "#cf543c",
  [NotificationType.SubTaskEndDelayed]: "#5ba668",
  [NotificationType.SubTaskCreated]: "#B6B303",
  [NotificationType.SubTaskMediaUpdated]: "#A89561",
  [NotificationType.ProfileCreated]: "#B6B303",
  [NotificationType.InvoiceSent]: "#B6B303",
  [NotificationType.InvoiceRejected]: "#cf543c",
  [NotificationType.InvoiceAccepted]: "#5ba668",
  [NotificationType.OfferSent]: "#B6B303",
  [NotificationType.OfferRejected]: "#cf543c",
  [NotificationType.OfferAccepted]: "#5ba668",
  [NotificationType.ProfileEdited]: "#A89561",
  [NotificationType.GroupEdited]: "#A89561",
  [NotificationType.GroupRemoved]: "#cf543c",
  [NotificationType.GroupCategoriesChanged]: "#A89561",
  [NotificationType.GroupPaymentMethodChanged]: "#A89561",
  [NotificationType.AppointmentSent]: "#B6B303",
  [NotificationType.AppointmentRejected]: "#cf543c",
  [NotificationType.AppointmentAccepted]: "#5ba668",
};

export const NotificationTypeIcon = {
  [NotificationType.Allocation]: "briefcase-check-outline",
  [NotificationType.TaskChanged]: "briefcase-edit-outline",
  [NotificationType.TaskDeleted]: "briefcase-remove-outline",
  [NotificationType.TaskStartReminder]: "briefcase-clock-outline",
  [NotificationType.PaymentProcessed]: "credit-card-outline",
  [NotificationType.TaskStateChanged]: "briefcase-edit-outline",
  [NotificationType.TaskStartDelayed]: "briefcase-download-outline",
  [NotificationType.TaskEndDelayed]: "briefcase-download-outline",
  [NotificationType.TaskCreated]: "briefcase-plus-outline",
  [NotificationType.TaskMediaUpdated]: "briefcase-upload-outline",
  [NotificationType.SubTaskAllocation]: "clipboard-check-outline",
  [NotificationType.SubTaskChanged]: "clipboard-edit-outline",
  [NotificationType.SubTaskDeleted]: "clipboard-remove-outline",
  [NotificationType.SubTaskStartReminder]: "clipboard-text-clock-outline",
  [NotificationType.SubPaymentProcessed]: "credit-card-outline",
  [NotificationType.SubTaskStateChanged]: "clipboard-arrow-right-outline",
  [NotificationType.SubTaskStartDelayed]: "clipboard-arrow-down-outline",
  [NotificationType.SubTaskEndDelayed]: "clipboard-arrow-down-outline",
  [NotificationType.SubTaskCreated]: "clipboard-plus-outline",
  [NotificationType.SubTaskMediaUpdated]: "clipboard-file-outline",
  [NotificationType.ProfileCreated]: "file-document-edit",
  [NotificationType.InvoiceSent]: "table-arrow-up",
  [NotificationType.InvoiceRejected]: "table-remove",
  [NotificationType.InvoiceAccepted]: "table-check",
  [NotificationType.OfferSent]: "file-send-outline",
  [NotificationType.OfferRejected]: "file-remove-outline",
  [NotificationType.OfferAccepted]: "file-check-outline",
  [NotificationType.ProfileEdited]: "account-edit",
  [NotificationType.GroupEdited]: "home-city-outline",
  [NotificationType.GroupRemoved]: "home-city-outline",
  [NotificationType.GroupCategoriesChanged]: "home-city-outline",
  [NotificationType.GroupPaymentMethodChanged]: "home-city-outline",
  [NotificationType.AppointmentSent]: "clock-check-outline",
  [NotificationType.AppointmentRejected]: "clock-check-outline",
  [NotificationType.AppointmentAccepted]: "clock-check-outline",
};

export const ThemeType = {
  Dark: "dark",
  Light: "light",
  Custom: "custom",
};

export const TrackingMode = {
  // Empty: 0,
  Automotive: 1,
  Fitness: 2,
  Others: 3,
};

export const ProfileMode = {
  Employee: "employee",
  Customer: "customer",
  ServiceProvider:"ServiceProvider"
};

export const RoleId = {
  Member: "64ebaf14-392c-11eb-9b5c-00ffba546a08",
  GroupLeader: "b2922c7e-392d-11eb-9b5c-00ffba546a08",
  CompanyAdmin: "c1e3de57-392d-11eb-9b5c-00ffba546a08",
  Customer: "94ebaf14-392c-11eb-9b5c-00ffba546a07",
};

export const JobVisibilityForCustomer = {
  NotVisible: 0,
  Visible: 1,
};

export const CompanySubscription = {
  Silver: 1,
  Gold: 2,
  Diamond: 3,
  Platinum: 4,
};

export const CompanySubscriptionReverse = {
  1: "Silver",
  2: "Gold",
  3: "Diamond",
  4: "Platinum",
};

export const SubscriptionMode = {
  Monthly: 2,
  Yearly: 4,
};

export const PaymentType = {
  BankAccount: 1,
  CreditCard: 2,
  DebitCard: 3,
  Cash: 4,
  Others: 5,
};

export const PaymentTypeReverse = {
  1: "BankAccount",
  2: "CreditCard",
  3: "DebitCard",
  4: "Cash",
  5: "Others",
};

export const Currency = {
  GBP: 1,
  Dollar: 2,
  Euro: 3,
  Rupees: 4,
};

export const CurrencyReverse = {
  1: "GBP",
  2: "Dollar",
  3: "Euro",
  4: "Rupees",
};

export const CurrencyIcon = {
  1: "currency-gbp",
  2: "currency-usd",
  3: "currency-eur",
  4: "currency-inr",
};

export const CurrencyHTMLEntities = {
  1: "&#163;",
  2: "&#36;",
  3: "&#8364;",
  4: "&#8377;",
};

export const CurrencyCode = {
  1: "GBP",
  2: "USD",
  3: "EUR",
  4: "INR",
};

export const PaymentByCustomerStatus = {
  Empty: 0,
  Paid: 1,
  Partial: 2,
  Open: 3,
  Cancelled: 4,
};

export const JobMeasureType = {
  Empty: 0,
  Distance: 1,
  Volume: 2,
  Hours: 3,
};

export const JobMeasureUnit = {
  0: "",
  1: "km",
  2: "hrs",
  3: "units",
};

export const ImageSizeType = {
  None: 0,
  Mobile: 1,
  BigScreen: 2,
  Thumbnail: 3,
};

export const MediaSizeType = {
  None: 0,
  Mobile: 1,
  BigScreen: 2,
  Thumbnail: 3,
};

export const JobTargetType = {
  None: 0,
  Km: 2,
  Hour: 1,
  Quantity: 3,
};

export const JobTargetTypeReverse = {
  0: "None",
  2: "Km",
  1: "Hour",
  3: "Quantity",
};

export const JobTargetTypeIcon = {
  None: "circle-off-outline",
  Km: "map-marker-distance",
  Hour: "clock-outline",
  Quantity: "counter",
};

export const JobTargetTypeUnit = {
  0: "",
  2: "km",
  1: "hrs",
  3: "unit",
};

export const WorkHourType = {
  HardHourWithinProximity: 1,
  HardHourOutsideProximity: 2,
  SoftHourWithinProximity: 3,
  SoftHourOutsideProximity: 4,
};

export const WorkHourTypeReverse = {
  1: "WP",
  2: "OP",
  3: "WP<sup>soft</sup>",
  4: "OP<sup>soft</sup>",
};

export const WorkHourTypeBackgroundColor = {
  1: "WORK_HOUR_TABLE_GREEN_COLUMN_BACKGROUND_COLOUR",
  2: "WORK_HOUR_TABLE_RED_COLUMN_BACKGROUND_COLOUR",
  3: "WORK_HOUR_TABLE_GREY_COLUMN_BACKGROUND_COLOUR",
  4: "WORK_HOUR_TABLE_GREY_COLUMN_BACKGROUND_COLOUR",
};

export const WorkHourTypeColor = {
  1: "WORK_HOUR_TABLE_COLUMN_TEXT_COLOUR",
  2: "WORK_HOUR_TABLE_COLUMN_TEXT_COLOUR",
  3: "WORK_HOUR_TABLE_GREEN_COLUMN_BACKGROUND_COLOUR",
  4: "WORK_HOUR_TABLE_RED_COLUMN_BACKGROUND_COLOUR",
};

export const SubTaskType = {
  Task: 0,
  Quotation: 1,
  Invoice: 2,
};

export const QuotationStatus = {
  Draft: 1,
  Ready: 2,
  SentAccepted: 3,
  SentRejected: 4,
  Sent: 5,
};

export const InvoiceStatus = {
  Draft: 1,
  Ready: 2,
  SentAccepted: 3,
  SentRejected: 4,
  Sent: 5,
};

export const InvoiceStatusReverse = {
  1: "Draft",
  2: "Ready",
  3: "SentAccepted",
  4: "SentRejected",
  5: "Sent",
};

export const InvoiceDueDateAfter = {
  OnReceipt: 0,
  In15days: 1,
  DueEndOfMonth: 2,
  ChooseADate: 3,
};

export const InvoiceDueDateAfterReverse = {
  0: "On Receipt",
  1: "In 15 days",
  2: "Due end of month",
  3: "Choose a date",
};

export const InvoiceTaxPercentage = {
  0: 0,
  5: 1,
  10: 2,
  15: 3,
};

export const InvoiceTaxPercentageReverse = {
  0: 0,
  1: 5,
  2: 10,
  3: 15,
};

export const AppointmentStatus = {
  Draft: 1,
  Ready: 2,
  SentAccepted: 3,
  SentRejected: 4,
  Sent: 5,
};

export const ServiceStatus = {
  Draft: 1,
  Ready: 2,
  SentAccepted: 3,
  SentRejected: 4,
  Sent: 5,
};

export const ServiceProviderStatus = {
  Draft: 1,
  Ready: 2,
  SentAccepted: 3,
  SentRejected: 4,
  Sent: 5,
};
export const CalendarFilter = {
  None: 0,
  Appointments: 1,
  Jobs: 2,
  Leaves:3,
  Holidays:4
};

export const CalendarFilterReverse = {
  0: "None",
  1: "Appointments",
  2: "Jobs",
  3: "Leaves",
  4: "Holidays"
};

export const CalendarFilterIcon = {
  0: "none",
  1: "calendar-clock",
  2: "calendar-check",
  3: "calendar-blank",
  4: "calendar-weekend",
};

export const AppointmentFilterIcon = {
  0: "none",
  1: "calendar-clock",
  2: "calendar-blank",
  3: "calendar-weekend",
  // 4: "calendar-check",
};

export const AppointmentFilter = {
  None: 0,
  Appointments: 1,
  Leaves:2,
  Holidays:3
};
export const AppointmentFilterReverse = {
  0: "None",
  1: "Appointments",
  2: "Leaves",
  3: "Holidays",
  4: "All"
};
export const LeaveCategory={
  categoryId:'057c0b54-f580-454c-bfd7-bc133a60184d ',
  category:'Leave',
  iconLink:'bullseye'
}

export const HolidayCategory={
  categoryId:'057c0b54-f210-454c-bfd7-bc133a60184d ',
  category:'Holiday',
  iconLink:'bullseye'
}
export const JobTimesFilter = {
  None: 0,
  Today: 1,
  Tomorrow: 2,
  ThisWeek: 3,
  NextWeek: 4,
  ThisMonth: 5,
  Later: 6,
};

export const serviceTimesFilter = {
  None: 0,
  Today: 1,
  Tomorrow: 2,
  ThisWeek: 3,
  NextWeek: 4,
  ThisMonth: 5,
  Later: 6,
};

export const JobTimesFilterReverse = {
  0: "None",
  1: "Today",
  2: "Tomorrow",
  3: "This Week",
  4: "Next Week",
  5: "This Month",
  6: "Later",
};

export const JobTimesFilterIcon = {
  0: "none",
  1: "calendar-today",
  2: "calendar-arrow-right",
  3: "calendar-week-begin",
  4: "calendar-week",
  5: "calendar-month",
  6: "calendar-export",
};

export const ProfilePicType = {
  None: 0,
  Mobile: 1,
  BigScreen: 2,
  Thumbnail: 3,
};

export const DeepLinkDomainPrefixes = {
  App: "com.emeistech.checkndout://",
  Https: "https://checkndout.com",
};

export const TrackProximityRadius = 50; //in meter

export const TimesFilter = {
  None: 0,
  Today: 1,
  Tomorrow: 2,
  ThisWeek: 3,
  NextWeek: 4,
  ThisMonth: 5,
  Later: 6,
};

export const TimesFilterReverse = {
  0: "None",
  1: "Today",
  2: "Tomorrow",
  3: "This Week",
  4: "Next Week",
  5: "This Month",
  6: "Later",
};

export const TimesFilterIcon = {
  0: "none",
  1: "calendar-today",
  2: "calendar-arrow-right",
  3: "calendar-week-begin",
  4: "calendar-week",
  5: "calendar-month",
  6: "calendar-export",
};

export const TaxType = {
  Inclusive: 1,
  Exclusive: 2,
};

export const TaxTypeReverse = {
  [TaxType.Inclusive]: "Inclusive",
  [TaxType.Exclusive]: "Exclusive",
};

export const TaxTypeIcon = {
  [TaxType.Inclusive]: "check",
  [TaxType.Exclusive]: "close",
};

export const AddInvoiceDialogMode = {
  Add: 1,
  GenerateFromJob: 2,
  GenerateFromQuotation: 3,
};

export const EmailPreTextType = {
  Quotation: 1,
  Invoice: 2,
  Appointment: 3,
};

export const EmailPreTextTypeReverse = {
  [EmailPreTextType.Quotation]: "Quotation",
  [EmailPreTextType.Invoice]: "Invoice",
  [EmailPreTextType.Appointment]: "Appointment",
};

export const Widgets = {
  Employees: "1",
  OngoingJobs: "2",
  AppointmentsToday: "3",
  QuotationDue: "4",
  InvoiceDue: "5",
  UpcomingJobs: "6",
};

export const ChartType = {
  BarChart: 1,
  LineChart: 2,
  PieChart: 3,
  DoughnutChart: 4,
};

export const ChartLabels = {
  WeekDays: 1,
  Months: 2,
  DayTime: 3,
  Year: 4,
};

export const ChartLabelsReverse = {
  [ChartLabels.WeekDays]: ["M", "T", "W", "T", "F", "S", "S"],
  [ChartLabels.Months]: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  [ChartLabels.DayTime]: [
    "8:00 AM",
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 AM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
  ],
  [ChartLabels.Year]: ["2018", "2019", "2020", "2021", "2022"],
};

// Events
export const EventType = {
  ids: {
    Online: 1,
    InPerson: 2,
  },
  entities: [
    {
      id: 1,
      title: "Online",
      icon: "webcam",
    },
    {
      id: 2,
      title: "In Person",
      icon: "account",
    },
  ],
  getItem: getItemFromArray,
};

export const EventStatus = {
  ids: {
    Draft: 1,
    Ready: 2,
    Live: 3,
    Done: 4,
    Cancelled: 5,
  },
  entities: [
    {
      id: 1,
      title: "Draft",
    },
    {
      id: 2,
      title: "Upcoming",
    },
    {
      id: 3,
      title: "Live",
    },
    {
      id: 4,
      title: "Done",
    },
    {
      id: 5,
      title: "Cancelled",
    },
  ],
  getItem: getItemFromArray,
};

export const EventAttendeeRole = {
  ids: {
    Host: 1,
    CoHost: 2,
    Audience: 3,
  },
  entities: [
    {
      id: 1,
      title: "Host",
    },
    {
      id: 2,
      title: "Co-Host",
    },
    {
      id: 3,
      title: "Audience",
    },
  ],
  getItem: getItemFromArray,
};

export const EventResponseActionStatus = {
  ids: {
    None: 0,
    Interested: 1,
    Going: 2,
  },
  entities: [
    {
      id: 1,
      title: "Interested",
      icon: "star-outline",
    },
    {
      id: 2,
      title: "Going",
      icon: "check-circle-outline",
    },
  ],
  getItem: getItemFromArray,
};

export const EventVisibility = {
  ids: {
    Host: 0, //only host
    Private: 1, // host + co-host
    Shared: 2, // host + co-host + attendee added
    Public: 3, // everyone
  },
  entities: [
    {
      id: 1,
      title: "Host",
    },
    {
      id: 2,
      title: "Private",
    },
    {
      id: 3,
      title: "Shared",
    },
    {
      id: 4,
      title: "Public",
    },
  ],
  getItem: getItemFromArray,
};

export const DateTimeFormat = {
  ids: {
    calendarString: 1,
    date: 2,
    time: 3,
    dateTime: 4,
    calendarStringWithTime: 5,
  },
  entities: [
    {
      id: 1,
      title: {
        lastDay: "[Yesterday]",
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        lastWeek: "[Last] dddd",
        nextWeek: "[Coming] dddd",
        sameElse: "ll",
      },
    },
    {
      id: 2,
      title: "MMM DD, YYYY",
    },
    {
      id: 3,
      title: "hh:mm a",
    },
    {
      id: 4,
      title: "MMM DD, YYYY [at] hh:mm a",
    },
    {
      id: 5,
      title: {
        lastDay: "[Yesterday at ] hh:mm a",
        sameDay: "[Today at ] hh:mm a",
        nextDay: "[Tomorrow at ] hh:mm a",
        lastWeek: "[Last] dddd [at ] hh:mm a",
        nextWeek: "[Coming] dddd [at ] hh:mm a",
        sameElse: "ll [at ] hh:mm a",
      },
    },
  ],
  getItem: getItemFromArray,
};

export const Privacy = {
  ids: {
    Public: 1,
    Private: 2,
    Friends: 3,
    FriendsExcept: 4,
    SpecificFriends: 5,
  },
  entities: [
    {
      id: 1,
      title: "Public",
      description: "anyone on or off meFolia",
      icon: "mdiEarth",
    },
    {
      id: 2,
      title: "Private",
      description: "only you can see",
      icon: "mdiLock",
    },
    {
      id: 3,
      title: "Friends",
      description: "your friends on meFolia",
      icon: "mdiAccountMultiple",
    },
    {
      id: 4,
      title: "Friends Except",
      description: "don't show to some friends",
      icon: "mdiAccountMultipleOutline",
    },
    {
      id: 5,
      title: "Specific Friends",
      description: "only show to some friends",
      icon: "mdiAccount",
    },
  ],
  getItem: getItemFromArray,
  getItemByTitle: getItemByTitleArray,
};

export const PostListTypes = {
  entities: [
    {
      id: 1,
      title: "Add Post",
      postType: 1,
    },
    {
      id: 2,
      title: "Add Job",
      postType: 2,
    },
    {
      id: 3,
      title: "Add Event",
      postType: 3,
    },
  ],
};

export const AppointmentType ={
  MEETING:1,
  LEAVE:2,
  HOLIDAY:3
}

export const LeaveType={
  entities:[
    { id: 1, label: 'Sick Leave', value: 1 },
    { id: 2, label: 'Casual Leave', value: 2 },
    { id: 3, label: 'Maternity Leave', value: 3 },
    { id: 4, label: 'Paternity Leave', value: 4 },
    { id: 5, label: 'Bereavement Leave', value: 5 },
    { id: 6, label: 'Compensatory Leave', value: 6 },
    { id: 7, label: 'Annual Leave', value: 7 },
    { id: 8, label: 'Emergency Leave', value: 8 },
    { id: 9, label: 'Study Leave', value: 9 },
    { id: 10, label: 'Sabbatical Leave', value: 10 },
    { id: 11, label: 'Others', value : 11 },
  ],
  getItem:getItemFromArray
}

export const HolidayType={
  entities:[
    { id: 1, label: 'Public Holiday', value: 1 },
    { id: 2, label: 'Religious Holiday', value: 2 },
    { id: 3, label: 'Company-Specific Holiday', value: 3 },
    { id: 4, label: 'Seasonal Holiday', value: 4 },
    { id: 5, label: 'Cultural Holiday', value: 5 },
    { id: 6, label: 'Bank Holiday', value: 6 },
    { id: 7, label: 'Floating Holiday', value: 7 },
    { id: 8, label: 'Special Observance Day', value: 8 },
    { id: 9, label: 'National Holiday', value: 9 },
    { id: 10, label: 'Memorial Holiday', value: 10 },
    { id: 11, label: 'Others', value : 11 },
  ],
  getItem:getItemFromArray
}

export const LeaveStatus={
  PENDING:1,
  ACCEPTED:2,
  REJECTED:3
}
export const EventCategories = {
  ids: {
    Sports: "fa1aa806-b8b5-4a6e-a0b7-8c887febbcaf",
    Education: "c92977f8-44c5-4ad4-9219-7f9fc30688ba",
    Dance: "11a0f99d-8b15-47db-80ef-50301fe417db",
    Discussion: "b87b09dc-d5c2-4da7-95a5-5d6fdb068bb3",
    Party: "0d3b5c2c-9dca-4c4a-b6de-7176be88687e",
    Birthday: "256c830f-464b-4441-8b8a-35a722be9808",
    Marriage: "7c9b458c-ade9-4cf6-8ed2-187a6ad48055",
    Health: "5ad94ceb-30d6-4d4a-9ec1-909258182f3c",
    Awareness: "c9fb1dfa-9511-4dd7-a203-6e0a4c215fa8",
    Marketing: "d803a539-a504-4c6c-a51f-08a949a2da85",
  },
  entities: [
    {
      id: "fa1aa806-b8b5-4a6e-a0b7-8c887febbcaf",
      title: "Sports",
      icon: "soccer",
    },
    {
      id: "c92977f8-44c5-4ad4-9219-7f9fc30688ba",
      title: "Education",
      icon: "school-outline",
    },
    {
      id: "11a0f99d-8b15-47db-80ef-50301fe417db",
      title: "Dance",
      icon: "dance-ballroom",
    },
    {
      id: "b87b09dc-d5c2-4da7-95a5-5d6fdb068bb3",
      title: "Discussion",
      icon: "forum-outline",
    },
    {
      id: "0d3b5c2c-9dca-4c4a-b6de-7176be88687e",
      title: "Party",
      icon: "party-popper",
    },
    {
      id: "256c830f-464b-4441-8b8a-35a722be9808",
      title: "Birthday",
      icon: "cake",
    },
    {
      id: "7c9b458c-ade9-4cf6-8ed2-187a6ad48055",
      title: "Marriage",
      icon: "ring",
    },
    {
      id: "5ad94ceb-30d6-4d4a-9ec1-909258182f3c",
      title: "Health",
      icon: "hospital-box-outline",
    },
    {
      id: "c9fb1dfa-9511-4dd7-a203-6e0a4c215fa8",
      title: "Awareness",
      icon: "access-point",
    },
    {
      id: "d803a539-a504-4c6c-a51f-08a949a2da85",
      title: "Marketing",
      icon: "bullhorn-outline",
    },
  ],
  getItem: getItemFromArray,
};

export const ServiceCategories = {
  ids: {
    Plumber: "1b9b93bb-5f0b-4df8-b879-690b414efb42",
    Painter: "6e02567b-0b1f-4779-8654-0fb1e4087635",
    Carpenter: "c1524e92-6fd2-409e-91ac-01d5035d6f4c",
    Mechanic: "67188f14-316e-431f-9927-8fbf4ccb8de9",
    Electrician: "9496926e-2278-42ff-ae0e-45aa4c986884",
    Welder: "31f8c3ad-87a5-4466-a113-d201ac82bb4b",
    Gardener: "1d53c095-0c44-45e6-bb13-9e41add6c34c",
    Technician: "1138ad12-3359-4e3a-95f5-194540e4b684",
    Barbar: "7f859171-99e4-4100-8150-8491d17a22d0",
    Cleaner: "e0900f10-4ebe-487d-b540-a0180e4824ba",
    Contractor: "aef2b3d4-4bf1-40c9-a2d7-cfb1af4ee08c",
  },
  entities: [
    {
      id: "1b9b93bb-5f0b-4df8-b879-690b414efb42",
      title: "Plumber",
      iconLink: "faucet",
      groupId: "acecaf7d-98fb-49ec-bd50-c9d7e1dd17b7",
    },
    {
      id: "6e02567b-0b1f-4779-8654-0fb1e4087635",
      title: "Painter",
      iconLink: "brush",
      groupId: "acecaf7d-98fb-49ec-bd50-c9d7e1dd17b7",
    },
    {
      id: "c1524e92-6fd2-409e-91ac-01d5035d6f4c",
      title: "Carpenter",
      iconLink: "tree",
      groupId: "acecaf7d-98fb-49ec-bd50-c9d7e1dd17b7",
    },
    {
      id: "67188f14-316e-431f-9927-8fbf4ccb8de9",
      title: "Mechanic",
      iconLink: "wrench",
      groupId: "acecaf7d-98fb-49ec-bd50-c9d7e1dd17b7",
    },
    {
      id: "9496926e-2278-42ff-ae0e-45aa4c986884",
      title: "Electrician",
      iconLink: "bolt",
      groupId: "acecaf7d-98fb-49ec-bd50-c9d7e1dd17b7",
    },
    {
      id: "31f8c3ad-87a5-4466-a113-d201ac82bb4b",
      title: "Welder",
      iconLink: "screwdriver",
      groupId: "acecaf7d-98fb-49ec-bd50-c9d7e1dd17b7",
    },
    {
      id: "1d53c095-0c44-45e6-bb13-9e41add6c34c",
      title: "Gardener",
      iconLink: "leaf",
      groupId: "acecaf7d-98fb-49ec-bd50-c9d7e1dd17b7",
    },
    {
      id: "1138ad12-3359-4e3a-95f5-194540e4b684",
      title: "Technician",
      iconLink: "check",
      groupId: "acecaf7d-98fb-49ec-bd50-c9d7e1dd17b7",
    },
    {
      id: "7f859171-99e4-4100-8150-8491d17a22d0",
      title: "Barbar",
      iconLink: "square",
      groupId: "acecaf7d-98fb-49ec-bd50-c9d7e1dd17b7",
    },
    {
      id: "e0900f10-4ebe-487d-b540-a0180e4824ba",
      title: "Cleaner",
      iconLink: "broom",
      groupId: "acecaf7d-98fb-49ec-bd50-c9d7e1dd17b7",
    },
    {
      id: "aef2b3d4-4bf1-40c9-a2d7-cfb1af4ee08c",
      title: "Contractor",
      iconLink: "file",
      groupId: "acecaf7d-98fb-49ec-bd50-c9d7e1dd17b7",
    },
    
  ],
  getItem: getItemFromArray,
};
