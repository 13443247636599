import { toast } from "react-toastify";
import { v4 } from "uuid";
import { apiTaskPaymentHistory, apiTaskSearch } from "../api";

import taskPaymentHistoryDummy from './Task Payment History/taskPaymentHistoryDummy.json';

export const FETCH_TASK_SEARCH = "FETCH_TASK_SEARCH";
export const RESET_MAP_DATA = "RESET_MAP_DATA";
export const TOGGLE_MAP_SEARCH_SIDEBAR = "TOGGLE_MAP_SEARCH_SIDEBAR";
export const SET_TASK_PAYMENT_HISTORY = "SET_TASK_PAYMENT_HISTORY";
export const SET_HAS_MORE_PAYMENT_HISTORY = "SET_HAS_MORE_PAYMENT_HISTORY";
export const SET_TASK_LOADERS = "SET_TASK_LOADERS";

export function setTaskLoaders({ loaderName, loaderState }) {
  return (dispatch) => {
    dispatch({
      type: SET_TASK_LOADERS,
      payload: { loaderName, loaderState }
    });
  };
}

export function fetchTaskSearch(payload) {
  return async function fetchTaskSearchThunk(dispatch, getState) {

    const search = {
      ids: null,
      assignedIds: [],
      categories: [],
      orderBy: "newest",
      pageIndex: 0,
      pageSize: 10,
      searchText: "",
      sortAscending: false,
      startDate: null,
      endDate: null,
      ...payload
    };

    await apiTaskSearch.post(search)
      .then(response => {

        if (response.status === 200) {
          dispatch({
            type: FETCH_TASK_SEARCH,
            payload: response.data.data
          });
        } else {
          toast.error("Error while searching!!!");
          console.log("Task search API Fetch response: ", response);
        }

      })
      .catch(error => {
        toast.error("Error while searching!!!");
        console.log("Task search API Fetch error: ", error);
      });
  };
}

export function resetMapData() {
  return async function resetMapDataThunk(dispatch, getState) {
    dispatch({
      type: RESET_MAP_DATA,
    });
  };
}

export function toggleMapSearchSidebar(payload) {
  return async function toggleMapSearchSidebarThunk(dispatch, getState) {
    dispatch({
      type: TOGGLE_MAP_SEARCH_SIDEBAR,
      payload: payload
    });
  };
}

export function setTaskPaymentHistory(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_TASK_PAYMENT_HISTORY,
      payload: payload
    });
  };
}

export function setHasMorePaymentHistory(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_MORE_PAYMENT_HISTORY,
      payload: payload
    });
  };
}

export function fetchTaskPaymentHistory(payload) {
  return async (dispatch) => {

    dispatch(setTaskLoaders('taskPaymentHistoryLoader', true));

    const dataToSend = {
      pageSize: 999,
      pageIndex: 0,
      orderBy: "newest",
      sortDecending: true,
      memberId: "",
      taskId: "",
      zeroStart: true,
      ...payload
    };

    await apiTaskPaymentHistory.post(dataToSend)
      .then(response => {

        if (response.status === 200) {

          let responseData = response.data.data;
          responseData = responseData.map((item) => ({ id: v4(), ...item }));

          if (responseData.length === 0 || responseData.length < 10) {
            dispatch(setHasMorePaymentHistory(false));
          }

          dispatch(setTaskPaymentHistory(responseData));

        } else {
          console.log("Task payment history error: ");
        }

      })
      .catch(error => {
        toast.error("Error while fetching Task Payment History!!!");
        console.log("Task payment history error: ", error);
      })
      .finally(() => {
        dispatch(setTaskLoaders('taskPaymentHistoryLoader', false));
      });

  };
}

export function fetchMoreTaskPaymentHistory(payload) {
  return async (dispatch, getState) => {

    const { taskPaymentHistory } = getState().taskSearch;

    const dataToSend = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortDecending: true,
      memberId: "",
      taskId: "",
      zeroStart: true,
      ...payload
    };

    await apiTaskPaymentHistory.post(dataToSend)
      .then(response => {

        if (response.status === 200) {

          let responseData = response.data.data;
          responseData = responseData.map((item) => ({ id: v4(), ...item }));

          if (responseData.length === 0 || responseData.length < 10) {
            dispatch(setHasMorePaymentHistory(false));
          } else {
            dispatch(setHasMorePaymentHistory(true));
          }

          dispatch(setTaskPaymentHistory([...responseData, ...taskPaymentHistory]));

        } else {
          console.log("More Task payment history error: ");
        }

      })
      .catch(error => {
        toast.error("Error while fetching more Task Payment History!!!");
        console.log("Task payment history more error: ", error);
      })
      .finally(() => {

      });

  };
}

