import {
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  mdiAccount,
  mdiAccountGroup,
  mdiDotsVertical,
  mdiArrowLeft,
  mdiPhone,
  mdiVideo,
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActiveGroupMembers,
  fetchChatUsers,
  removeUserFromChatGroup,
  setMessageReceived,
  toggleCallModal,
  toggleCallModalOpenOnce,
} from "../../actions/chatActions";
import ConfirmYesNoDialog from "../ConfirmYesNoDialog/ConfirmYesNoDialog";
import { useStyles } from "./ChatMessagesHeaderStyles";
import CreateChatGroupModal from "../CreateChatGroupModal/CreateChatGroupModal";
import ChatProfileDetails from "../ChatProfileDetails/ChatProfileDetails";
import ChatCallScreen from "../ChatCallScreen/ChatCallScreen";
import AvatarCNO from "../../library/AvatarCNO/AvatarCNO";
import { createBrowserHistory } from "history";
import LinkCNO from "../../library/LinkCNO";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import { MessageType } from "../../utils";

function ChatMessagesHeader({ activeChatUser }) {
  const tillTablet = useMediaQuery("(max-width: 768px)");

  const theme = useTheme();
  const styles = useStyles({ tillTablet });
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { activeGroupMembers, chatUsers } = useSelector((state) => state.chats);

  const [markedForRemove, setMarkedForRemove] = useState([]);
  const [isConfirmUserRemove, setIsConfirmUserRemove] = useState(false);

  const [isChatProfileDetails, setIsChatProfileDetails] = useState(false);

  const [isCallScreenVisible, setIsCallScreenVisible] = useState(false);

  const [isCreateChatGroup, setIsCreateChatGroup] = useState(false);
  const [groupAdmin, setGroupAdmin] = useState({});

  useEffect(() => {
    const payload = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "",
      sortDecending: true,
      groupId: activeChatUser.id,
    };
    dispatch(fetchActiveGroupMembers(payload));
  }, [activeChatUser]);

  // useEffect(() => {
  //   if (activeChatUser.contactType === 'group') {
  //     if (!chatUsers.length) {
  //       const payload = {
  //         pageSize: 10,
  //         pageIndex: 0,
  //         orderBy: "newest",
  //         sortAscending: false,
  //         searchText: ""
  //       };
  //       dispatch(fetchChatUsers(payload));
  //     }
  //     else {
  //       let admin = activeGroupMembers.find(user => user.id === activeChatUser.createdBy);
  //       admin = {
  //         firstName: admin.firstName,
  //         id: activeGroupMembers[0]?.id,
  //         isAdmin: true,
  //         lastName: admin.lastName,
  //         memberId: admin.id,
  //         profilePic: admin.profilePic,
  //       };

  //       setGroupAdmin(admin);
  //     }
  //   }
  // }, [chatUsers, activeChatUser]);

  const [refMore, setRefMore] = useState(null);

  const openMore = (e) => {
    setRefMore(e.currentTarget);
  };

  const closeMore = () => {
    setRefMore(null);
  };
  const open = Boolean(refMore);

  const NativePopover = () => {
    return (
      <Popover
        open={open}
        anchorEl={refMore}
        onClose={closeMore}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={styles.container_MorePopover}>
          <List className={styles.list}>
            <ListItem className={GlobalStyles.listItem} onClick={() => null}>
              <ListItemText
                primary={
                  activeChatUser.contactType === "user"
                    ? "User Info"
                    : "Group Info"
                }
                onClick={() => {
                  setIsChatProfileDetails(true);
                  closeMore();
                }}
              />
            </ListItem>
          </List>
        </div>
      </Popover>
    );
  };

  const handleRemoveUserConfirmation = (user) => {
    closeMore();
    setIsConfirmUserRemove(true);
    const currentUser = {
      Id: user.id,
      MemberId: user.memberId,
      MessageGroupId: activeChatUser.id,
    };
    setMarkedForRemove([...markedForRemove, currentUser]);
  };

  const handleRemoveUserFromGroup = () => {
    dispatch(removeUserFromChatGroup(markedForRemove));
    setMarkedForRemove([]);
  };

  const handleAddUserToGroup = () => {
    setIsCreateChatGroup(true);
    closeMore();
  };

  const handleBackButton = () => {
    const history = createBrowserHistory();
    // const path = history.createHref({ pathname: '/chat' });
    // history.push(path);
    // history.go();
    history.goBack();
  };

  const openCallModal = () => {
    //dispatch(toggleCallModalOpenOnce(true));
    dispatch(toggleCallModal(true));
  };

  console.log("Group Members in header : ", activeGroupMembers);

  const handleMakeCall = (callType) => {
    dispatch(
      setMessageReceived({
        callContext: callType,
        isVideoCall: callType.toString() === "TriggerVideoCall" ? true : false,
      })
    );
    openCallModal();
  };

  if (!activeChatUser) {
    return null;
  }

  return (
    <div className={styles.container_ChatMessagesHeader}>
      {tillTablet && (
        <Icon
          path={mdiArrowLeft}
          size="1.5rem"
          onClick={handleBackButton}
          className={styles.backButton}
        />
      )}
      <div className={styles.activeUserWrapper}>
        {activeChatUser.contactType === "user" && (
          <AvatarCNO
            src={activeChatUser.contactAvatar}
            fallbackIcon={mdiAccount}
            avatarStyle={{
              border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}`,
            }}
            iconSize="2rem"
          />
        )}
        {activeChatUser.contactType === "group" && (
          <AvatarCNO
            src={activeChatUser.contactAvatar}
            fallbackIcon={mdiAccountGroup}
            iconSize="2rem"
            avatarStyle={{
              border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}`,
            }}
          />
        )}
        <Typography className={styles.activeUsername}>
          {activeChatUser.contactName}
        </Typography>
      </div>
      {activeChatUser.contactType === "user" && (
        <Icon
          path={mdiPhone}
          size="1.8rem"
          color={theme.colors.HEADER_CALL_ICON_COLOR}
          onClick={() => handleMakeCall("TriggerAudioCall")}
        />
      )}

      {activeChatUser.contactType === "user" && (
        <Icon
          path={mdiVideo}
          size="1.8rem"
          color={theme.colors.HEADER_CALL_ICON_COLOR}
          style={{ marginLeft: 10 }}
          onClick={() => handleMakeCall("TriggerVideoCall")}
        />
      )}

      <Icon
        path={mdiDotsVertical}
        size="1.5rem"
        color={theme.colors.MORE_ACTIONS_ICON_COLOR}
        onClick={openMore}
      />
      {open && <NativePopover />}
      {isConfirmUserRemove && (
        <ConfirmYesNoDialog
          open={isConfirmUserRemove}
          setOpen={setIsConfirmUserRemove}
          message="Are you sure, you want to delete member(s) from Group?"
          onConfirm={handleRemoveUserFromGroup}
          onCancel={() => setMarkedForRemove([])}
        />
      )}
      {isCreateChatGroup && (
        <CreateChatGroupModal
          isCreateChatGroup={isCreateChatGroup}
          closeCreateChatGroup={() => setIsCreateChatGroup(false)}
          // alreadySelectedUsers={[groupAdmin, ...activeGroupMembers]}
          alreadySelectedUsers={activeGroupMembers}
          mode="edit" //mode: ['new', 'edit']
        />
      )}
      {isChatProfileDetails && (
        <ChatProfileDetails
          open={isChatProfileDetails}
          onClose={() => setIsChatProfileDetails(false)}
          handleRemoveUserConfirmation={handleRemoveUserConfirmation}
          handleAddUserToGroup={handleAddUserToGroup}
          alreadySelectedUsers={activeGroupMembers}
          // alreadySelectedUsers={[groupAdmin, ...activeGroupMembers]}
        />
      )}
      {isCallScreenVisible && (
        <ChatCallScreen
          open={isCallScreenVisible}
          onClose={() => setIsCallScreenVisible(false)}
        />
      )}
    </div>
  );
}

export default ChatMessagesHeader;
