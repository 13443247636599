import { toast } from "react-toastify";
import { v4 } from "uuid";
import {
  apiAppointmentGetMediaLink,
  apiAppointmentSearch,
  apiAppointmentUploadMedia,
  apiAppointmentInitUpload
} from "../../api/appointment";
import { getContentType, isArrayEmpty } from "../Helpers";
import { FileType, MediaSizeType, MediaType } from "../mappings";

export const getAppointmentMedias = async (appointmentId) => {

  let medias = [];

  console.log("Get Appointment Medias: appointmentId: ", appointmentId);

  try {
    const response = await apiAppointmentSearch.post({ ids: [appointmentId] });

    console.log("Get Appointment Medias: response: ", response);
    console.log("Get Appointment Medias: !isArrayEmpty(response.data): ", !isArrayEmpty(response.data));

    if (response && !isArrayEmpty(response.data.data)) {
      const appointment = response.data.data[0];

      console.log("Get Appointment Medias: appointment: ", appointment);

      medias = appointment.mediaLinks;
    }
  } catch (error) {
    console.log("Error: Get Appointment Medias: ", error);
  }

  console.log("Get Appointment Medias: medias: ", medias);

  return medias;

}

export const getResolvedAppointmentMedias = async (medias, appointmentId) => {

  const mediaIds = medias.map(media => media.id);

  const dataToSend = {
    ids: mediaIds,
    size: MediaSizeType.BigScreen
  };

  let resolvedMedias = [];

  try {
    const response = await apiAppointmentGetMediaLink.post(dataToSend);

    resolvedMedias = response.data.links;

    const resolvedMediasPromise = resolvedMedias.map(async (media, index) => {

      let fileType = null, contentType = null;

      try {
        // if ([1].includes(index)) {
        //   throw new Error("forcefully error upload");
        // } else {
        const resp = await getContentType(media.link);
        fileType = resp.fileType;
        contentType = resp.contentType;
        // }
      } catch (error) {
        toast.error("Media not found. Please delete and upload the media again.");
        console.log("Error: Resolve Appointment Medias: Get Content Type: ", error);
        return null;
      }

      const isImage = fileType === FileType.Image;
      return {
        ...media,
        uri: media.link,
        // thumbnailUri: allMedias[index].link,
        fileType: fileType,
        type: contentType,
        isResolved: true,
        isImage,
        appointmentId,
        newId: v4()
      };

    });

    resolvedMedias = await Promise.all(resolvedMediasPromise);

  } catch (error) {
    resolvedMedias = [];
    console.log("Error: Resolve Appointment Medias: ", error);
  }

  return resolvedMedias.filter(Boolean);

}

export const getAWSAppointmentUploadSignedUrls = async (ids = []) => {

  if (isArrayEmpty(ids)) return null;

  let awsUploadSignedUrls = [];

  try {
    const awsUploadSignedUrlsResp = await apiAppointmentInitUpload.post({ ids });
    awsUploadSignedUrls = awsUploadSignedUrlsResp.data.links;
  } catch (error) {
    awsUploadSignedUrls = [];
    console.log("Error: Get AWS Appointment Media Signed URLs: ", error);
  }

  return awsUploadSignedUrls;

}

// TODO: group medias based on mediaType and then make server call accordingly
export const saveUploadedAppointmentMediasToServer = async (medias, ids, appointmentId, mediaType = MediaType.Input) => {

  const prevMedias = await getAppointmentMedias(appointmentId);

  const newMedias = medias.map((media, index) => ({
    id: ids[index],
    title: media.name,
    fileType: media.type,
    abstract: "",
    link: ids[index],
    mediaType: media.mediaType || mediaType,
    appointmentId: appointmentId
  }));

  const mediasToSave = [...newMedias, ...prevMedias];

  const dataToSend = {
    appointmentId: appointmentId,
    medias: mediasToSave
  };

  return await apiAppointmentUploadMedia.post(dataToSend);

}

