import * as React from 'react';
import * as FontIcon from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@material-ui/core';
import { IconMain } from '../IconCNO';

const IconPickerItem = ({
  icon,
  size,
  color,
  onClick,
  containerStyles,
  library
}) => {
  // const iconDiv = !!icon ? React.createElement(FontIcon[icon]) : <div style={{ fontSize: '0.7rem' }}>Pick</div>

  const theme = useTheme();

  const renderIcon = () => {
    switch (library) {
      case 'F':
        return <FontAwesomeIcon icon={['fas', icon]} color="#4e4e4e" />

      case 'M':
        return <IconMain name={icon} color="#4e4e4e" />

      default:
        return <IconMain name={icon} color="#4e4e4e" />

    }
  }

  return (
    <div
      onClick={() => !!onClick && onClick(icon)}
      style={{ fontSize: size, color: color, ...containerStyles }}
    >
      {
        !!icon
          ? renderIcon()
          : <div style={{ fontSize: '0.7rem' }}>Select Category Icon</div>
      }

    </div>
  )
}

IconPickerItem.defaultProps = {
  color: '#000',
  size: 24,
  onClick: (_) => { },
}

export { IconPickerItem }