import { fade, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_CompanyCategoryDetailsCard: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    background: theme.colors.PROFILE_CARD_BACKGROUND_COLOR,
    padding: ({ tillTablet }) => tillTablet ? 12 : theme.spacing(4),
    borderRadius: 20
  },
  form: {
    height: 300,
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
  },
  label: {
    flex: 1,
    marginBottom: 5,
    color: theme.colors.FORM_LABEL_COLOR
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.colors.PROFILE_CARD_TITLE_COLOR,
  },
  chipsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    '&>*': {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  row: {
    display: 'flex',
    // marginLeft: theme.spacing(1),
    marginLeft: -32,
    marginRight: -32,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: 32,
    alignItems: 'center',
    color: theme.colors.PROFILE_TEXT_INFO_COLOR,
    borderBottom: `1px solid ${theme.colors.LIST_DIVIDER_COLOR}`,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  icon: {
    color: theme.colors.CHIP_BUTTON_SELECTED_BACKGROUND_COLOR,
    marginRight: theme.spacing(2),
    padding: 6
  },
  addBtn: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    '&:hover': {
      background: fade(theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR, 0.7),
    }
  }
}));