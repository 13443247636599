import { Typography, useTheme } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { mdiMap, mdiMapMarkerRadius } from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployees, setEmployees } from '../../actions/employeeActions';
import { handleMapTrackSearch, resetSoftTrackData, toggleWorkingTodayModal } from '../../actions/trackSearchActions';
import DialogCNO from '../../library/DialogCNO';
import { useGlobalStore } from '../../providers/GlobalProvider';
import { TOGGLE_SEARCH } from '../../utils/actions';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';

function WhoIsWorkingTodayModal() {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { isWorkingTodayModal, checkInsIds } = useSelector(state => state.trackSearch);
  const { employees, allEmployeesLoader } = useSelector(state => state.employees);
  const { employeesLoader } = allEmployeesLoader;

  const { dispatchGlobal } = useGlobalStore();

  useEffect(() => {

    if (!(Array.isArray(checkInsIds) && checkInsIds.length)) return;

    const employeeWorkPayload = {
      ids: checkInsIds
    };

    dispatch(fetchEmployees(employeeWorkPayload));

    return () => {
      dispatch(setEmployees([]));
    };

  }, []);

  const handleTrack = (row) => {

    console.log("====handleTrack====row====", row);

    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false
    });

    dispatch(resetSoftTrackData());

    const mNow = moment();

    const trackSearchPayload = {
      teamMember: row.name,
      trackStartDate: mNow.startOf('day').toDate(),
      trackEndDate: mNow.endOf('day').toDate(),
      trackStartTime: mNow.startOf('day').toDate(),
      trackEndTime: mNow.endOf('day').toDate(),
      job: row.job || null,
      jobStartDate: null,
      jobEndDate: null,
    };

    dispatch(handleMapTrackSearch(trackSearchPayload));

    dispatch(toggleWorkingTodayModal(false));

  };

  const columns = [
    {
      field: 'name', headerName: 'Name',
      minWidth: 200,
      flex: 1,
      renderHeader: () => {
        return (
          <div style={{ color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR, fontSize: '0.8rem' }}>
            Name
          </div>
        )
      },
      renderCell: (params) => {
        return (
          <Typography>{params.value.title}</Typography>
        )
      }
    },
    // {
    //   field: 'job', headerName: 'Job',
    //   minWidth: 300,
    //   flex: 1,
    //   renderHeader: () => {
    //     return (
    //       <div style={{ color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR, fontSize: '0.8rem' }}>
    //         Job
    //       </div>
    //     )
    //   },
    //   renderCell: (params) => {
    //     return (
    //       <Typography>{params.value.title}</Typography>
    //     )
    //   }
    // },
    // {
    //   field: 'trackCounts', headerName: 'Track Counts',
    //   renderHeader: () => {
    //     return (
    //       <div style={{ color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR, fontSize: '0.8rem' }}>
    //         Track Counts
    //       </div>
    //     )
    //   },
    //   renderCell: (params) => {
    //     return (
    //       <Typography>{params.value}</Typography>
    //     )
    //   }
    // },
    {
      field: 'actions', headerName: 'Actions',
      renderHeader: () => {
        return (
          <div style={{ color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR, fontSize: '0.8rem' }}>
            Actions
          </div>
        )
      },
      renderCell: (params) => {
        return (
          <div>
            <Icon
              path={mdiMapMarkerRadius}
              color={theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR}
              size="1.3rem"
              title="Track"
              onClick={() => handleTrack(params.row)}
            />
          </div>
        )
      }
    },
  ];

  const rows = employees.map((emp, index) => (
    {
      id: index + 1,
      name: { id: emp.id, title: `${emp.firstName} ${emp.lastName}` }
    }
  ));

  // const rows = [
  //   {
  //     id: 1,
  //     name: { id: "3a7dffe6-580a-4552-a7cb-cc8e8a461279", title: "User One" },
  //     job: { id: "3a7dffe6-580a-4552-a7cb-cc8e8a461270", title: "Working on fix bathroom tiles job." },
  //     trackCounts: 40
  //   },
  //   {
  //     id: 2,
  //     name: { id: "3a7dffe6-580a-4552-a7cb-cc8e8a461271", title: "User Two" },
  //     job: { id: "3a7dffe6-580a-4552-a7cb-cc8e8a461272", title: "Add garden in front empty space" },
  //     trackCounts: 40
  //   },
  //   {
  //     id: 3,
  //     name: { id: "3a7dffe6-580a-4552-a7cb-cc8e8a461273", title: "User Three" },
  //     job: { id: "3a7dffe6-580a-4552-a7cb-cc8e8a461274", title: "Layout the pipeline in whole house" },
  //     trackCounts: 40
  //   },
  //   {
  //     id: 4,
  //     name: { id: "3a7dffe6-580a-4552-a7cb-cc8e8a461275", title: "User Four" },
  //     job: { id: "3a7dffe6-580a-4552-a7cb-cc8e8a461276", title: "Just the random job." },
  //     trackCounts: 40
  //   }
  // ];

  return (
    <DialogCNO
      open={isWorkingTodayModal}
      onClose={() => dispatch(toggleWorkingTodayModal(false))}
      dialogTitle="Working Today"
    >
      <div
        style={{
          width: "100%",
          marginTop: 16
        }}
      >
        {
          employeesLoader
            ? (
              <Typography align='center'>Loading...</Typography>
            )
            : (
              rows.length
                ? <DataGrid
                  rows={rows}
                  columns={columns}
                  rowHeight={35}
                  hideFooterSelectedRowCount
                  pageSize={5}
                  autoHeight
                  disableColumnMenu
                  disableSelectionOnClick
                />
                : <Typography align='center' className={GlobalStyles.listingNotFound}>No employee working today.</Typography>
            )
        }
      </div>
    </DialogCNO>
  )
}

export default WhoIsWorkingTodayModal;