import { AppConstants } from "../../../utils/AppConstants";
import LocalStorageConstants from "../../../utils/LocalStorageConstants";
import { Currency, CurrencyHTMLEntities, DeepLinkDomainPrefixes } from "../../../utils/mappings";

// export const defaultMessageTop = (invoice) => `Hello ${invoice.customerText ? invoice.customerText : ""},\n\nThank you giving us the opportunity to serve you for ${invoice.title} job.\n\nPlease find below summary of the Invoice for your kind reference.`;

export const defaultMessageTop = (invoice, invoiceEmailTemplate) => {

  invoiceEmailTemplate = invoiceEmailTemplate ? invoiceEmailTemplate : AppConstants.DefaultInvoiceEmailTemplate;

  return invoiceEmailTemplate
    .replace(/<<Name>>/g, invoice.customerText ? invoice.customerText : "")
    .replace(/<<Title>>/g, invoice.title);

};

const messageBottom = (emailSignatureTemplate) => {

  const authUserCompany = LocalStorageConstants.getItem('authUserCompany');

  emailSignatureTemplate = emailSignatureTemplate ? emailSignatureTemplate : AppConstants.DefaultEmailSignatureTemplate;

  return `<div style="color: #4e4e4e; margin-right: 10px; margin-top: 20px; white-space: pre-line;">
      ${emailSignatureTemplate.replace(/<<FromName>>/, authUserCompany.title)}
    </div>`;

};


export const getInvoiceSendSummaryHtmlString = (invoice, invoiceItems, emailData) => {

  const currency = CurrencyHTMLEntities[invoice.currency ? invoice.currency : Currency.GBP];

  const authUserCompany = LocalStorageConstants.getItem('authUserCompany');

  emailData = emailData ? defaultMessageTop(invoice, emailData) : defaultMessageTop(invoice);

  const messageTop = `<div style="color: #4e4e4e; white-space: pre-line;">${emailData}</div>`;

  const invoiceItemsString = invoiceItems.length ? invoiceItems.map((item, index) => (`
                <div style="display: flex; padding: 16px; font-size: 14px;">
                    <div style="flex: 1;">
                        <div style="color: #4e4e4e;">${item.title}</div>
                        <pre style=" white-space: pre-line; margin: 0; margin-top: 10px; padding-left: 16px; font-family: Arial, Helvetica, sans-serif; font-size: 12px; color: #8f8f8f; ">
                            ${item.description}
                        </pre>
                    </div>
                    <div style="flex: 0.2; text-align: right; color: #4e4e4e; ">${item.amount}</div>
                </div>
                    ${invoiceItems.length - 1 === index ? '' : '<div style="height: 2px; background-color: #d4d4d4; margin-left: 2%; margin-right: 2%; "></div>'}
                    `)).join("\n")
    : '<div style="display: flex; padding: 16px; font-size: 14px;">No item found.</div>';

  const invoiceSummaryTable = `
      <div>
                <div style="display: flex; background: #eaeaea; color: #5e5e5e; margin-top: 16px; border: 2px solid #d9d9d9; border-radius: 6px; padding: 8px 12px">
                    <div style="flex: 1; font-weight: 700; font-size: 14px;">Item</div>
                    <div style="flex: 0.2; text-align: right; font-weight: 700; font-size: 14px;">Amount</div>
                </div>

                ${invoiceItemsString}

                <div style="height: 2px; background-color: #9e9e9e; "></div>
            </div>
    `;

  const totalSection = `
                      <div style="color: #4e4e4e;">
                    <div style="text-align: right; display: flex; margin-right: 16px; margin-bottom: 16px; ">
                        <span style="flex: 1; font-size: 15px; ">Amount</span>
                        <span style="flex: 0.2; text-align: right; font-size: 15px; ">
                        ${currency}
                        </span>
                        <span style="flex: 0.2; font-size: 15px; ">${invoice.amount || 0}</span>
                    </div>
                    <div style="text-align: right; display: flex; margin-right: 18px; margin-bottom: 18px; ">
                        <span style="flex: 1; font-size: 15px; ">Discount @ ${invoice.discountRate || 0}%</span>
                        <span style="flex: 0.2; text-align: right; font-size: 15px; ">
                        ${currency}
                        </span>
                        <span style="flex: 0.2; font-size: 15px; ">${invoice.discount || 0}</span>
                    </div>
                    <div style="text-align: right; display: flex; margin-right: 18px; margin-bottom: 18px; ">
                        <span style="flex: 1; font-size: 15px; ">Amount after Discount</span>
                        <span style="flex: 0.2; text-align: right; font-size: 15px; ">
                        ${currency}
                        </span>
                        <span style="flex: 0.2; font-size: 15px; ">${invoice.amountAfterDiscount || 0}</span>
                    </div>
                    <div style="text-align: right; display: flex; margin-right: 18px; margin-bottom: 18px; ">
                        <span style="flex: 1; font-size: 15px; ">
                        ${invoice.includeTax ? 'Inclusive' : 'Exclusive'} VAT @ ${invoice.taxRate || 0}% (${currency} ${invoice.taxableAmount || 0})
                        </span>
                        <span style="flex: 0.2; text-align: right; font-size: 15px; ">
                        ${currency}
                        </span>
                        <span style="flex: 0.2; font-size: 15px; ">${invoice.tax || 0}</span>
                    </div>
                    <div style="box-sizing: border-box; display: flex; justify-content: space-between; font-size: 16px; padding: 5px; align-items: center; background: #dedede70; color: #3e3e3e; border-radius: 8px; border: 2px solid #bebebe8f; margin-top: 8px; flex: 1 ">
                        <div style="flex: 0.5; font-weight: 700; font-size: 16px;">Total Amout</div>
                        <div style="display: flex; background: #fefefe; padding: 6px 8px; flex: 0.5; border-radius: 6px; text-align: center; ">
                            <span style="flex: 0.7; text-align: right; font-size: 16px; font-weight: 700;">
                            ${currency}
                            </span>
                            <span style="flex: 0.3; font-size: 16px; font-weight: 700; text-align: right; ">${invoice.totalAmount || 0}</span>
                        </div>
                    </div>
                </div>
    `;

  const viewInvoiceButton = `<div style="display: flex; flex: 1; justify-content: center; align-items: center">
    <a href="${DeepLinkDomainPrefixes.Https}/invoice/${invoice.id}" style="background: #008dc9; text-decoration: none; padding: 10px 20px; border-radius: 5px; color: #fefefe; font-weight: bold; cursor: pointer;">
      View Invoice
    </a>
  </div>`;


  const appBanner = `
    <div style="display: flex; flex: 1; justify-content: center; align-items: center; background: #e8e8e8; border-radius: 5px;">
    <img src="https://checkndout.com/logoAntEmail.png" alt="app-logo" style="width: 40px; object-fit: contain;" />
    <img src="https://checkndout.com/logoTextEmail.png" alt="name-logo" style="height: 40px; object-fit: contain;" />
    </div>
  `;

  const messageSummary = `
      <div style="font-family: Arial, Helvetica, sans-serif;">
        ${messageTop}
        <div style="color: #4e4e4e; font-weight: bold;  margin-top: 10px;">${invoice.title}</div>
        <div>${invoiceSummaryTable}</div>
        <div style="margin-top: 10px;">${totalSection}</div>
        <div style="margin-top: 40px; margin-bottom: 40px;">${viewInvoiceButton}</div>
        ${messageBottom(authUserCompany.emailSignatureTemplate)}
        <div style="margin-top: 40px;">${appBanner}</div>
      </div>
    `;

  return messageSummary;

};
