import React from 'react'
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import clsx from 'clsx';
import ListCardItemWrapper from './ListCardItemWrapper';
import { Box, makeStyles } from '@material-ui/core';
import AvatarCNO from '../AvatarCNO';
import { MetaText } from '../Typography';
import { IconMain } from '../IconCNO';

const ListCardItem = ({
  cardTitle,
  profilePic,
  title,
  subTitles = [],
  description,
  Footer,
  CardActionComponent,
  icon,
  iconProps = {}
}) => {

  const styles = useStyles();

  return (
    <ListCardItemWrapper title={cardTitle} CardActionComponent={CardActionComponent} >

      <Box className={styles.mainContentWrapper}>
        {profilePic
          ? <AvatarCNO src={profilePic} title={title} />
          : <Box bgcolor='#3e3e3e' borderRadius='100%' alignSelf='flex-start' p={1} width='3rem' height='3rem' >
            <IconMain name={icon} size='2rem' {...iconProps} />
          </Box>
        }
        <Box mr={1}>
          <MetaText className={styles.title} fontSize='1.2rem'>{title}</MetaText>
          {
            subTitles.map(subTitle => <MetaText className={styles.subTitle}>{subTitle}</MetaText>)
          }
        </Box>
      </Box>

      <MetaText style={{ marginTop: 12 }} className={styles.description}>{description}</MetaText>

      {Footer}
    </ListCardItemWrapper>
  )
}

export default ListCardItem;

const useStyles = makeStyles((theme) => ({
  mainContentWrapper: {
    position: 'relative',
    display: 'flex',
    gap: '12px'
  },
  title: {

  },
  subTitle: {

  },
  description: {

  }
}))