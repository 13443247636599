import { Box, makeStyles } from '@material-ui/core';
import React from 'react'
import { IconMain, MetaText } from '../../../library';

const PostShareExternal = () => {

  const styles = useStyles();

  return (
    <Box>

      <Box className={styles.shareWrapper}>
        <IconMain name='share-outline' size='1.1rem' />
        <MetaText>Share</MetaText>
      </Box>

    </Box>
  )
}

export default PostShareExternal;

const useStyles = makeStyles((theme) => ({
  shareWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    paddingLeft: 20,
    paddingRight: 20,
    cursor: 'pointer'
  }
}))