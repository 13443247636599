import { makeStyles } from "@material-ui/core";

export const usePublicPagesStyles = makeStyles((theme) => ({

  pageWrapper: {
    minHeight: '90vh',
    padding: '10px 2%',
    borderRadius: 10,
    background: theme.colors.PUBLIC_PAGE_BACKGROUND_COLOR,
    maxWidth: 1040,
    margin: '0 auto',
    [theme.breakpoints.down(1040)]: {
      margin: '5%',
    },
    marginTop: '70px',
  },

  pageTitle: {
    marginTop: '1rem',
    marginBottom: '2rem',
    color: 'orange',
  },

  para: {
    color: theme.colors.PUBLIC_PAGE_PARA_COLOR,
    marginBottom: '1rem',
    fontSize: '1rem'
  },

  paraTitle: {
    color: theme.colors.LIST_CARD_TITLE_COLOR,
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: '2rem'
  },

  list: {
    marginLeft: '2rem',
    marginBottom: '2rem',
    '& > li': {
      listStyleType: 'unset',
      marginBottom: '0.7rem',
      lineHeight: '1.5rem',
      color: theme.colors.PUBLIC_PAGE_PARA_COLOR,
      fontFamily: "Roboto, Helvetica, Arial, 'sans-serif'"
    }
  },

  link: {
    fontFamily: "Roboto, Helvetica, Arial, 'sans-serif'",
    textDecoration: 'underline !important',
    color: theme.colors.PUBLIC_PAGE_PARA_COLOR,
  },

  linkAction: {
    color: theme.colors.PUBLIC_PAGE_PARA_COLOR,
    fontFamily: "Roboto, Helvetica, Arial, 'sans-serif'"
  },

  contactItemWrapper: {
    maxWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
    marginTop: '3rem',
    '& > *': {
      marginTop: '1rem'
    }
  },

  contactItem: {
    color: theme.colors.PUBLIC_PAGE_PARA_COLOR,
    fontFamily: "Roboto, Helvetica, Arial, 'sans-serif'",
    display: 'flex',
    alignItems: 'center',
    fontSize: '2rem',
  },

  videoContainer: {
    cursor: 'pointer',
    borderRadius: 5,
    maxWidth: 320,
  },

  thumbnailContainer: {
    position: 'relative',
    '&:hover': {
      opacity: 0.7
    }
  }

}));