import { fade, makeStyles, Typography } from '@material-ui/core';
import { mdiAccount, } from '@mdi/js';
import Icon from '@mdi/react';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTrackCheckinsCount, toggleWorkingTodayModal } from '../../actions/trackSearchActions';
import WhoIsWorkingTodayModal from './WhoIsWorkingTodayModal';

function WhoIsWorkingToday() {
  const styles = useStyles();

  const dispatch = useDispatch();

  const { isWorkingTodayModal } = useSelector(state => state.trackSearch);

  const openWorkingTodayModal = async () => {
    dispatch(toggleWorkingTodayModal(true));
    await dispatch(getTrackCheckinsCount());
  };

  return (
    <Fragment>
      <div className={styles.container_WhoIsWorkingToday} onClick={openWorkingTodayModal}>
        <Icon path={mdiAccount} size='1.5rem' />
        <Typography>Who is working today?</Typography>
      </div>
      {
        isWorkingTodayModal &&
        <WhoIsWorkingTodayModal />
      }
    </Fragment>
  )
}

export default WhoIsWorkingToday

const useStyles = makeStyles(theme => ({
  container_WhoIsWorkingToday: {
    background: fade(theme.colors.TRACK_USER_START_BUTTON_BACKGROUND_COLOR, 0.8),
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: 80,
    // left: '45%',
    padding: '0.8rem 2.5rem',
    borderRadius: 10,
    border: `1px solid ${theme.colors.TRACK_USER_START_BUTTON_BORDER_COLOR}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.2rem',
    minWidth: 280,
    '&>*:first-child': {
      marginRight: theme.spacing(1)
    },
    '&>*:last-child': {
      color: theme.colors.TRACK_USER_START_BUTTON_TEXT_COLOR
    },
    '&:hover': {
      cursor: 'pointer',
    }
  }
}));