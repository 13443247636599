import React, { useEffect, useState } from "react";
import ScreenView from "../../../components/ScreenView";
import { InfiniteScrollCNO } from "../../../library";
import { useDispatch, useSelector } from "react-redux";
import {
  AddPostListDialogue,
  PostListCard,
  AddPostDialog,
} from "../components";
import { mdiScriptTextOutline } from "@mdi/js";
import {
  handlePostSearch,
  toggleAddPostDialog,
} from "../../../actions/postActions";
import { Box } from "@material-ui/core";
import { MainPostType } from "../enums";
import JobCard from "../../../components/JobCard/JobCard";
import { EventListItemFW } from "../../EventManagement/components";
import { transformListData } from "../../../utils/Helpers";
import { AddEventFBDialog } from "../../EventManagement";
import { toggleAddPostListDialog } from "../../../actions/postListActions";
import AddJob from "../../../components/AddJob/AddJob";
import { fetchTodos, toggleAddJobDialog } from "../../../actions/jobActions";
import Switcher from "../../../common/components/GridListToggle/Switcher";
import { Privacy } from "../../../utils/mappings";
import { markHiddenPosts } from "../../../actions/postActions";
import AddService from "../../../components/Service/AddService";
import { fetchServices } from "../../../actions/serviceActions";
import RightsManager from "../../../utils/RightsManager";
import { getPrefillAssignedUser } from "../../../utils/Helpers";
import ServiceListCard from "../../../components/ServiceListCard/ServiceListCard";

const PostListingPage = () => {
  const dispatch = useDispatch();
  const [isGridView, setIsGridView] = useState(false);
  const [filters, setFilters] = useState({
    goto: "2023",
    postedBy: "Anyone",
    privacy: "All Posts",
    taggedPosts: "All Posts",
  });
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [postsCombination, setPostsCombination] = useState([]);
  

  const {
    posts,
    loadMore,
    hasMorePosts,
    postsLoader,
    isAddPostDialog,
    hiddenPostsList,
    deletedPostsList,
    showHiddenPosts,
  } = useSelector((state) => state.posts);
  const { events } = useSelector((state) => state.events);
  const { jobs } = useSelector((state) => state.jobs);
  const {services} = useSelector((state) => state.services)

  useEffect(() => {
    const combinedPosts = [...posts, ...jobs, ...events, ...services];
    setPostsCombination(combinedPosts);
    console.log("postsCombination", combinedPosts);
  }, [posts, jobs, events,services]);

  const { isAddJobDialog } = useSelector((state) => state.jobs);
  const { isAddEventDialog } = useSelector((state) => state.events);
  const {isAddServiceDialog}=useSelector((state) =>state.services)
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const isUserMember = RightsManager.isMember();


  const applyFilters = (posts, filters, authUser) => {
    return posts.filter((post) => {
      const postDate = post.createdAt
        ? new Date(post.createdAt)
        : post.startDate
        ? new Date(post.startDate)
        : null;
      console.log("applyFilters : postDate", postDate);

      const matchCreatedAt = filters.createdAt
        ? postDate && postDate.getFullYear() === filters.createdAt
        : true;

      const matchPostedBy = filters.postedBy
        ? filters.postedBy === "You"
          ? post.createdBy.id === authUser.id
          : filters.postedBy === "Others"
          ? post.createdBy.id !== authUser.id
          : true
        : true;

      const matchPrivacy = filters.privacy
        ? filters.privacy === "Public"
          ? post.visibility === Privacy.ids.Public
          : filters.privacy === "Private"
          ? post.visibility === Privacy.ids.Private
          : filters.privacy === "Friends"
          ? post.visibility === Privacy.ids.Friends
          : true
        : true;

      const matchTaggedPosts = filters.taggedPosts
        ? filters.taggedPosts === "Only Show Posts I'm tagged In"
          ? post?.taggedUsers?.includes(authUser.id)
          : true
        : true;

      console.log("taggedUsers", post.taggedUsers);
      console.log("authUser.id", authUser.id);
      console.log("matchTaggedPosts", matchTaggedPosts);
      return (
        matchCreatedAt && matchPostedBy && matchPrivacy && matchTaggedPosts
      );
    });
  };

  useEffect(() => {
    dispatch(handlePostSearch());
  }, [dispatch]);

  useEffect(() => {
    const payload = {
      assignedIds:
        isUserMember && getPrefillAssignedUser()
          ? [getPrefillAssignedUser().id]
          : null,
      customerIds: null,
    };

    dispatch(fetchServices(payload));
  }, [services]);
  
  useEffect(() => {
    if (postsCombination && filters) {
      const hiddenPosts =
        filters?.showHiddenPosts === "True"
          ? postsCombination.filter((post) => hiddenPostsList.includes(post.id))
          : [];

      
      hiddenPosts.length > 0 ? dispatch(markHiddenPosts(true)) : dispatch(markHiddenPosts(false))

      console.log("postListing : hiddenPosts", hiddenPosts);

      const filtered = applyFilters(
        hiddenPosts.length > 0 ? hiddenPosts : postsCombination,
        filters,
        authUser
      );
      console.log("fillltered",filtered)
      setFilteredPosts(filtered);
    }
  }, [filters, postsCombination,hiddenPostsList]);

  const renderListItem = (item) => {
    item = transformListData(item);
    
    switch (item.postType) {
      case MainPostType.ids.Post:
        return <PostListCard key={item.id} post={item}/>;
      case MainPostType.ids.Job:
        return <JobCard key={item.id} item={item} />;
      case MainPostType.ids.Event:
        return <EventListItemFW key={item.id} event={item} />;
      case MainPostType.ids.Service:
        return <ServiceListCard key={item.id} service={item} />;
      default:
        return null;
    }
  };

  // const backToCreatePostDialog = () => {
  //   if (isAddPostDialog) {
  //     dispatch(toggleAddPostDialog(false));
  //     dispatch(toggleAddPostListDialog(true));
  //   } else if (isAddJobDialog) {
  //     dispatch(toggleAddJobDialog(false));
  //     dispatch(toggleAddPostListDialog(true));
  //   }
  // };

  console.log("applyFilters : filters", filters);
  console.log("applyFilters : posts", posts);
  console.log("applyFilters : filteredPost", filteredPosts);

  return (
    <ScreenView headerTitle="Posts" isSearchOpenByDefault>
      <Switcher
        isGridView={isGridView}
        setIsGridView={setIsGridView}
        isPost={true}
        setFilters={setFilters}
      />
      <Box padding={2} margin="0 auto">
        <InfiniteScrollCNO
          items={filteredPosts} // Use filtered posts
          next={loadMore}
          hasMore={hasMorePosts}
          loading={postsLoader}
          renderListItem={renderListItem}
          notFoundTextMessage={"No Post Present"}
          notFoundIcon={mdiScriptTextOutline}
          showListCTAButtons={true}
          listCTAButtonsProps={{ addTitle: "Add Post" }}
          isGridView={isGridView}
          setIsGridView={setIsGridView}
          isPosts={true}
          mode={'posts'}
        />
        {isAddEventDialog && <AddEventFBDialog />}
        {isAddPostDialog && <AddPostDialog />}
        {isAddJobDialog && <AddJob />}
        {isAddServiceDialog && <AddService/>}
      </Box>
    </ScreenView>
  );
};

export default PostListingPage;
