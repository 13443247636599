import { fade, makeStyles, Typography } from '@material-ui/core';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { getMaterialIconString } from '../../utils/icons';
import { CurrencyHTMLEntities, CurrencyIcon } from '../../utils/mappings';
import * as icons from '@mdi/js';
import { parseDisplayNumber, parseNumber } from '../../utils/Helpers';
import AddInvoiceDiscount from './AddInvoiceDiscount';
import AddInvoiceTax from './AddInvoiceTax';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import { getTotalAmountValues } from '../../utils/InvoiceHelpers';
import FieldHelperTextCNO from '../../library/FieldHelperTextCNO';

function AddInvoicePaymentSummary({
  selectedSubTasks,
  formik,
  paymentSummaryValues,
  setPaymentSummaryValues
}) {

  const styles = useStyles();

  const [isDiscountEditable, setIsDiscountEditable] = useState(false);
  const [isTaxEditable, setIsTaxEditable] = useState(false);

  useEffect(() => {

    let amount = 0;
    if (selectedSubTasks.length) {
      amount = selectedSubTasks.reduce((result, invoiceItemId) => {

        console.log("============= handleCalculationChange ==reduce==key= ", formik.values);

        result = result + parseNumber(formik.values[`amount-${invoiceItemId}`], 'float');

        console.log("============= handleCalculationChange ==result= ", result);

        return result;
      }, 0);
      amount = Math.round(amount);
    }

    const paymentValues = getTotalAmountValues(amount, paymentSummaryValues.discount, paymentSummaryValues.taxRate, paymentSummaryValues.includeTax);

    setPaymentSummaryValues({ ...paymentValues, includeTax: paymentSummaryValues.includeTax });

  }, [formik.values, selectedSubTasks]);

  return (
    <div>

      <FieldHelperTextCNO
        showHelperText={!paymentSummaryValues.amount}
        helperText={"Amount can't be 0."}
      />

      <div className={styles.amountBreakdownWrapper}>

        <div className={styles.row}>
          <Typography className={styles.label}>Amount</Typography>
          <div className={styles.valueWrapper}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[formik.values.currency])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography className={styles.value}>{parseDisplayNumber(paymentSummaryValues.amount)}</Typography>
          </div>
        </div>

        <div className={styles.row} onClick={() => setIsDiscountEditable(true)}>
          <Typography className={styles.label}>
            {`Discount @ ${paymentSummaryValues.discountRate}%`}
          </Typography>
          <div className={styles.valueWrapper}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[formik.values.currency])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography className={styles.value}>{parseDisplayNumber(paymentSummaryValues.discount)}</Typography>
          </div>
        </div>

        <div className={styles.row}>
          <Typography className={styles.label}>Amount after Discount</Typography>
          <div className={styles.valueWrapper}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[formik.values.currency])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography className={styles.value}>{parseDisplayNumber(paymentSummaryValues.amountAfterDiscount)}</Typography>
          </div>
        </div>

        <div className={styles.row} onClick={() => setIsTaxEditable(true)}>

          <div
            className={styles.label}
            dangerouslySetInnerHTML={{
              __html: `<div>
                ${paymentSummaryValues.includeTax ? 'Inclusive' : 'Exclusive'} VAT @ ${parseDisplayNumber(paymentSummaryValues.taxRate)}% (<span>${CurrencyHTMLEntities[formik.values.currency]}</span> ${parseDisplayNumber(paymentSummaryValues.taxableAmount, 'float')})
              </div>`
            }}
          />

          <div className={styles.valueWrapper}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[formik.values.currency])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography className={styles.value}>{parseDisplayNumber(paymentSummaryValues.tax)}</Typography>
          </div>

        </div>

      </div>

      <div className={styles.totalAmountWraper}>
        <Typography className={styles.label} style={{ fontSize: '1rem' }}>Total Amount Due</Typography>
        <div className={styles.amountWrapper}>
          <div className={styles.amount}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[formik.values.currency])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography
              className={styles.value}
              style={{
                fontSize: '1rem',
                fontWeight: 'bold',
              }}>
              {parseDisplayNumber(paymentSummaryValues.totalAmount)}
            </Typography>
          </div>
        </div>
      </div>

      {
        isDiscountEditable &&
        <AddInvoiceDiscount
          open={isDiscountEditable}
          onClose={() => setIsDiscountEditable(false)}
          invoiceFormik={formik}
          paymentSummaryValues={paymentSummaryValues}
          setPaymentSummaryValues={setPaymentSummaryValues}
        />
      }

      {
        isTaxEditable &&
        <AddInvoiceTax
          open={isTaxEditable}
          onClose={() => setIsTaxEditable(false)}
          invoiceFormik={formik}
          paymentSummaryValues={paymentSummaryValues}
          setPaymentSummaryValues={setPaymentSummaryValues}
        />
      }

    </div >
  )
}

export default AddInvoicePaymentSummary

const useStyles = makeStyles((theme) => ({
  container_AddInvoicePaymentSummary: {

  },
  amountBreakdownWrapper: {
    padding: 8
  },

  row: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 8
  },

  label: {
    marginRight: 16
  },

  valueWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 120,
  },

  value: {
    minWidth: 60,
    textAlign: 'right'
  },

  totalAmountWraper: {
    display: 'flex',
    alignItems: 'center',
    background: theme.colors.WORK_HOURS_TOTAL_ROW_BACKGROUND_COLOR,
    padding: 8,
    justifyContent: 'flex-end',
    borderRadius: 4
  },

  amountWrapper: {
    background: theme.colors.INPUT_DISABLED_BACKGROUND_COLOR,
    width: 120,
    borderRadius: 4,
    padding: '4px 8px',
    display: 'flex',
    justifyContent: 'flex-end',
    color: fade(theme.colors.INPUT_TEXT_COLOR, 0.8),
  },

  amount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }

}));