import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchEventById, setEventById } from '../../../actions/eventActions';
import ScreenView from '../../../components/ScreenView';
import FullScreenLoaderCNO from '../../../library/FullScreenLoaderCNO';
import { EventDetailsFB, EventDetailsNative } from '../components';

function EventDetailsPage() {

  const dispatch = useDispatch();
  const { eventById, eventDetailsLoader, events } = useSelector(state => state.events);

  const { id } = useParams();

  useEffect(() => {
    const eventId = id;

    const event = events.find(event => event.id === id);

    dispatch(fetchEventById(eventId, event));

  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(setEventById(null));
    };
  }, []);

  console.log("eventById: ", eventById);

  return (
    <div>
      <ScreenView
        content={
          eventById
            ? <EventDetailsFB event={eventById} />
            // ? <EventDetailsNative event={eventById} />
            : (eventDetailsLoader ? <FullScreenLoaderCNO /> : null)
        }
        headerTitle="Events"
      />
    </div>
  )
}

export default EventDetailsPage;
