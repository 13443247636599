import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react'

const Stack = ({ children, direction = "column", spacing = 1, className = '' }) => {

  const styles = useStyles({ direction, spacing });

  return (
    <div className={clsx(styles.container, className)}>{children}</div>
  )
}

export default Stack;

const useStyles = makeStyles(() => ({

  container: {
    display: 'flex',
    flexDirection: ({ direction }) => direction,
    '& > *': {
      marginBottom: ({ spacing }) => spacing * 4
    },
    '& > *:last-child': {
      marginBottom: 0
    }
  }

}))