import { makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';

const InputLabelCNO = ({
  label,
  renderLabel,
  isRequired,
  onClick
}) => {

  const theme = useTheme();

  const useStyles = makeStyles((theme) => ({
    label: {
      // flex: 1,
      marginRight: 5,
      color: theme.colors.FORM_LABEL_COLOR,
      // marginTop: theme.spacing(2),
      marginBottom:10
    },
  }));

  const styles = useStyles();

  if (renderLabel) {
    return renderLabel();
  }

  if (!label) {
    return null;
  }

  return (
    <Typography
      className={styles.label}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {label}
      <span style={{ color: theme.colors.ERROR_COLOR }}>{isRequired ? ' *' : null}</span>
    </Typography>
  );
}

export default InputLabelCNO;
