import { makeStyles } from '@material-ui/core';

export const useDashBoardStyles = makeStyles((theme) => ({

  dashboardContainer: {
    padding: 8
  },

  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.colors.DASHBOARD_HEADER_BACKGROUND_COLOR,
    borderRadius: 4,
    padding: '8px 4px'
  },

  headerTileWrapper: {
    display: 'flex',
    gap: 6,
    alignItems: 'center'
  },

  headerTitle: {
    color: theme.colors.DASHBOARD_HEADER_TITLE_TEXT_COLOR,
    fontSize: '1rem'
  },

  dashboardStatsContainer: {
    minHeight: 200,
    padding: "20px 10px",
  },

  statsCardContainer: {
    boxShadow: `${theme.colors.STATS_CARD_SHADOW_COLOR} 0px 7px 29px 0px`,
    border: `1px solid ${theme.colors.STATS_CARD_BORDER_COLOR}`,
    borderRadius: 10,
    background: theme.colors.STATS_CARD_BACKGROUND_COLOR
  },

  ribbonContainer: {
    background: theme.colors.DASHBOARD_RIBBON_BACKGROUND_COLOR,
    padding: "10px 5px",
    borderRadius: 5
  }

}));