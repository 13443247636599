import { Box, TextField, makeStyles } from '@material-ui/core'
import React from 'react'
import AvatarCNO from '../../../../library/AvatarCNO';
import LocalStorageConstants from '../../../../utils/LocalStorageConstants';
import { MetaText } from '../../../../library';

const AddEventDiscussionTrigger = ({ onClick }) => {

  const styles = useStyles();

  const authUser = LocalStorageConstants.getItem('authUser');
  const userName = `${authUser.firstName} ${authUser.lastName}`;

  return (
    <Box className={styles.container} onClick={onClick}>

      <Box className={styles.userWrapper}>
        <AvatarCNO
          src={authUser.profilePic}
          title={userName}
        />
        <MetaText fontWeight='bold' fontSize={16} >{userName}</MetaText>
      </Box>

      <TextField
        name='post'
        placeholder="What's on your mind?"
        multiline
        minRows={4}
        fullWidth
        className={styles.input}
        disabled
      />

    </Box>
  )
}

export default AddEventDiscussionTrigger;

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.colors.BACKGROUND_COLOR_LIGHT,
    padding: '0.6rem 1rem',
    marginBottom: theme.spacing(1.5),
    borderRadius: 8
  },
  userWrapper: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    marginBottom: 12
  },
  input: {
    '& .MuiInput-underline:before': {
      borderBottom: 0
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0
    }
  }
}))