import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./CompanyPersonalDetailsCardStyles";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { updateDefaultPaymentMethod } from "../../actions/customerCompanyActions";
import { PaymentType } from "../../utils/mappings";
import DialogCNO from "../../library/DialogCNO";
import { SnapItem, SnapList } from "react-snaplist-carousel";
import ChipCNO from "../../library/ChipCNO";
import { mdiBank, mdiCreditCard } from "@mdi/js";
import CompanyBankDetails from "./CompanyBankDetails";
import CompanyCreditCardDetails from "./CompanyCreditCardDetails";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import { Typography } from '@material-ui/core';
import FieldHelperTextCNO from '../../library/FieldHelperTextCNO';

export function EditDefaultPaymentTypeDetails({
  open,
  onClose,
  profile,
}) {
  const styles = useStyles();

  const dispatch = useDispatch();
  const {
    allCompaniesLoader: { editLoader }
  } = useSelector(state => state.companies);

  const validationSchema = yup.object({
    type: yup
      .number()
      .oneOf([PaymentType.BankAccount, PaymentType.CreditCard], "Please select a default payment method type.")
      .required("Please select a default payment method type."),

    //credit card details
    number: yup
      .string()
      .nullable()
      .when('type', {
        is: PaymentType.CreditCard,
        then: yup
          .string()
          .required("Credit card number is required.")
          .nullable()
      }),
    expiryDate: yup
      .string()
      .nullable()
      .when('type', {
        is: PaymentType.CreditCard,
        then: yup
          .string()
          .required("Expiry date is required.")
          .nullable()
      }),
    cardHolderName: yup
      .string()
      .nullable()
      .when('type', {
        is: PaymentType.CreditCard,
        then: yup
          .string()
          .required("Card holder name is required.")
          .nullable()
      }),
    cvv: yup
      .string()
      .nullable()
      .when('type', {
        is: PaymentType.CreditCard,
        then: yup
          .string()
          .required("CVV is required.")
          .nullable()
      }),

    // bank details
    branch: yup
      .string()
      .when('type', {
        is: PaymentType.BankAccount,
        then: yup
          .string()
          .required("Bank branch is required.")
          .nullable()
      }),
    code: yup
      .string()
      .when('type', {
        is: PaymentType.BankAccount,
        then: yup
          .string()
          .required("Bank code is required.")
          .nullable()
      }),
    accNumber: yup
      .string()
      .when('type', {
        is: PaymentType.BankAccount,
        then: yup
          .string()
          .required("Account number is required.")
          .nullable()
      }),
    accHolderName: yup
      .string()
      .when('type', {
        is: PaymentType.BankAccount,
        then: yup
          .string()
          .required("Account holder name is required.")
          .nullable()
      }),

  });


  const formik = useFormik({
    initialValues: {
      type: profile.defaultPaymentMethod ? profile.defaultPaymentMethod.type : PaymentType.BankAccount,

      //credit card details
      number: profile.defaultPaymentMethod ? profile.defaultPaymentMethod.number : '',
      expiryDate: profile.defaultPaymentMethod ? profile.defaultPaymentMethod.expiryDate : '',
      cardHolderName: profile.defaultPaymentMethod ? profile.defaultPaymentMethod.cardHolderName : '',
      cvv: profile.defaultPaymentMethod ? profile.defaultPaymentMethod.cvv : '',

      // bank details
      branch: profile.defaultPaymentMethod ? profile.defaultPaymentMethod.branch : '',
      code: profile.defaultPaymentMethod ? profile.defaultPaymentMethod.code : '',
      accNumber: profile.defaultPaymentMethod ? profile.defaultPaymentMethod.accNumber : '',
      accHolderName: profile.defaultPaymentMethod ? profile.defaultPaymentMethod.accHolderName : '',

      companyId: profile.id,
    },
    validationSchema,
    onSubmit: values => handleUpdate(values),
  });

  const handleUpdate = (values) => {

    const bankDetails = {
      branch: values.branch,
      code: values.code,
      accNumber: values.accNumber,
      accHolderName: values.accHolderName
    };

    const creditCardDetails = {
      number: values.number,
      expiryDate: values.expiryDate,
      cardHolderName: values.cardHolderName,
      cvv: values.cvv
    };

    const defaultPaymentMethod = {
      ...profile.defaultPaymentMethod,
      type: values.type,
      ...(values.type === PaymentType.BankAccount ? bankDetails : {}),
      ...(values.type === PaymentType.CreditCard ? creditCardDetails : {}),
      companyId: values.companyId
    };

    const updatedProfile = {
      ...profile,
      defaultPaymentMethod
    };

    console.log("EditDefaultPaymentTypeDetails: handleUpdate: ", { defaultPaymentMethod, updatedProfile });

    dispatch(updateDefaultPaymentMethod(defaultPaymentMethod, updatedProfile));

    onClose();

  };

  const isDefaultPaymentBankType = formik.values.type === PaymentType.BankAccount;
  const isDefaultPaymentCreditCardType = formik.values.type === PaymentType.CreditCard;

  console.log("EditDefaultPaymentTypeDetails: values: ", formik.values);
  console.log("EditDefaultPaymentTypeDetails: errors: ", formik.errors);
  console.log("EditDefaultPaymentTypeDetails: touched: ", formik.touched);

  const handlePaymentMethodSelect = (value) => {
    formik.setFieldValue('type', value);
    formik.setFieldTouched('type', true);
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Company Details"
      loading={editLoader}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>

        <div className={styles.selectChips}>
          <Typography className={styles.label}>Payment Type</Typography>
          <SnapList className={styles.chipsWrapper}>
            <SnapItem>
              <ChipCNO icon={mdiBank} name='Bank Account'
                active={isDefaultPaymentBankType}
                onClick={() => handlePaymentMethodSelect(PaymentType.BankAccount)} />
            </SnapItem>
            {/* <SnapItem>
              <ChipCNO icon={mdiCreditCard} name='Credit Card'
                active={isDefaultPaymentCreditCardType}
                onClick={() => handlePaymentMethodSelect(PaymentType.CreditCard)} />
            </SnapItem> */}
          </SnapList>
        </div>

        <FieldHelperTextCNO
          helperText={formik.errors.type || "Please select a default payment method."}
          showHelperText={formik.values.type === 0}
          errorColor={Boolean(formik.touched.type)}
        />

        {
          isDefaultPaymentBankType ? (
            <CompanyBankDetails formik={formik} />
          ) : (null)
        }

        {/* {
          isDefaultPaymentCreditCardType ? (
            <CompanyCreditCardDetails formik={formik} />
          ) : (null)
        } */}

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )
};