import {
  FETCH_CHAT_USERS,
  MESSAGE_COUNT,
  MESSAGE_PROFILE_COUNT,
  SET_ACTIVE_CHAT_USER,
  SET_ACTIVE_GROUP_MEMBERS,
  SET_CHATS, SET_CHAT_GROUPS,
  SET_CHAT_MESSAGES,
  SET_CHAT_SNIPPETS_LIST,
  SET_CURRENT_CHAT_USER,
  SET_HAS_MORE_MESSAGES,
  SET_LOADERS,
  SET_MESSAGE_TEXT,
  SET_NEW_CHAT,
  TOGGLE_UNIVERSAL_LOADER,
  TOGGLE_CALL_MODAL,
  TOGGLE_CALL_MODAL_OPEN_ONCE,
  SET_MESSAGE_RECEIVED
} from '../actions/chatActions';
import { MessageType } from '../utils';

const initialState = {
  chats: [],
  messages: [],
  hasMoreMessages: true,
  messageText: "",
  activeChatUser: null,
  currentChatUser: null,
  chatUsers: [],
  activeGroupMembers: [],
  messageCount: 0,
  messageProfileCount: [],
  loaders: {
    createGroupLoader: false,
    loadMoreChatLoader: false
  },
  universalLoader: false,
  chatSnippetList: [],
  chatGroups: [],

  isCallModalOpen: false,
  messageType: MessageType.Empty,
  messageReceived: null,
  isCallModalOpenOnce: false
};

export default function chatReducer(state = initialState, { type, payload }) {

  let newState = null;

  switch (type) {

    case SET_MESSAGE_RECEIVED:
      newState = {
        ...state,
        messageReceived: payload
      };
      return newState;

    case TOGGLE_CALL_MODAL:
      newState = {
        ...state,
        isCallModalOpen: payload
      };
      return newState;

    case TOGGLE_CALL_MODAL_OPEN_ONCE:
      newState = {
        ...state,
        isCallModalOpenOnce: payload
      };
      return newState;

    case SET_NEW_CHAT:
      newState = {
        ...state,
        messages: [payload, ...state.messages]
      };
      return newState;

    case SET_CHATS:
      newState = {
        ...state,
        chats: payload
      };
      return newState;

    case SET_MESSAGE_TEXT:
      newState = {
        ...state,
        messageText: payload
      };
      return newState;

    case SET_CHAT_MESSAGES:
      newState = {
        ...state,
        messages: payload
      };
      return newState;

    case SET_ACTIVE_CHAT_USER:
      newState = {
        ...state,
        activeChatUser: payload
      };
      return newState;

    case SET_CURRENT_CHAT_USER:
      newState = {
        ...state,
        currentChatUser: payload
      };
      return newState;

    case FETCH_CHAT_USERS:
      newState = {
        ...state,
        chatUsers: payload
      };
      return newState;

    case SET_ACTIVE_GROUP_MEMBERS:
      newState = {
        ...state,
        activeGroupMembers: payload
      };
      return newState;

    case SET_LOADERS:
      newState = {
        ...state,
        loaders: {
          ...state.loaders,
          ...payload
        }
      };
      console.log("group state in reducer: ", newState);
      return newState;

    case TOGGLE_UNIVERSAL_LOADER:
      newState = {
        ...state,
        universalLoader: payload
      };
      return newState;

    case SET_HAS_MORE_MESSAGES:
      newState = {
        ...state,
        hasMoreMessages: payload
      };
      return newState;

    case MESSAGE_COUNT:
      newState = {
        ...state,
        messageCount: payload
      };
      return newState;

    case MESSAGE_PROFILE_COUNT:
      newState = {
        ...state,
        messageProfileCount: payload
      };
      return newState;

    case SET_CHAT_SNIPPETS_LIST:
      newState = {
        ...state,
        chatSnippetList: payload
      };
      return newState;

    case SET_CHAT_GROUPS:
      newState = {
        ...state,
        chatGroups: payload
      };
      return newState;

    default:
      return state;
  }
}