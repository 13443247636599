import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_AddToGroupChatModal: {

  },
  form: {
    textAlign: 'center'
  },
  avatarBadge: {
    marginBottom: theme.spacing(2),
    borderRadius: '50%',
    '& .MuiBadge-anchorOriginBottomRightCircle': {
      right: 'calc(14% + 30px)',
      bottom: '14%',
      transform: 'scale(1) translate(50%, 50%)',
      transformOrigin: '100% 100%',
      width: 100,
    }
  },
  loader: {
    // border: '2px solid #c2abf7',
    padding: '20px',
    borderRadius: '50%',
    width: '5rem',
    height: '5rem'
  },
  avatar: {
    // background: theme.palette.background.light,
    width: 80,
    height: 80,
    // border: '1px solid orange',
  },
  pickers: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  }
}));