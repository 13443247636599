import { mdiArrowRight, mdiCheck, mdiClose, mdiFileDocument, mdiSend, mdiSquareEditOutline } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateQuotation } from '../../actions/quotationActions';
import CardStatusCNO from '../../library/CardStatusCNO/CardStatusCNO';
import { QuotationStatus } from '../../utils/mappings';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';

function QuotationStatusButtonBar({ quotation }) {

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();

  const handleStatusChange = (status) => {
    const payload = {
      ...quotation,
      status: status
    };

    dispatch(updateQuotation(payload));

  };

  return (
    <div className={GlobalStyles.cardStatusBarContainer}>

      <CardStatusCNO
        title="Draft"
        icon={mdiSquareEditOutline}
        isActive={quotation.status === QuotationStatus.Draft}
        activeBorderColor={'#ffb366'}
      />

      <Icon path={mdiArrowRight} size='1.2rem' />

      <CardStatusCNO
        title="Ready"
        icon={mdiFileDocument}
        isActive={quotation.status === QuotationStatus.Ready}
        activeBorderColor={'#ff8000'}
      />

      <Icon path={mdiArrowRight} size='1.2rem' />

      <CardStatusCNO
        title="Sent"
        icon={mdiSend}
        isActive={(quotation.status === QuotationStatus.Sent) || (quotation.status === QuotationStatus.SentAccepted) || (quotation.status === QuotationStatus.SentRejected)}
        activeBorderColor={'#00cc00'}
        hasTopRightIcon={true}
        topRightIcon={
          quotation.status === QuotationStatus.SentRejected ? mdiClose : mdiCheck
        }
        isTopRightActive={(quotation.status === QuotationStatus.SentAccepted) || (quotation.status === QuotationStatus.SentRejected)}
        topRightActiveBgColor={quotation.status === QuotationStatus.SentAccepted ? "#2bb673"
          : quotation.status === QuotationStatus.SentRejected && "#ef5647"}
        topRightActiveBorderColor={quotation.status === QuotationStatus.SentAccepted ? "#2bb673"
          : quotation.status === QuotationStatus.SentRejected && "#ef5647"}
        topRightActiveColor={"#cecece"}
      />

    </div>
  )
}

export default QuotationStatusButtonBar
