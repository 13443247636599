import { Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiFormSelect } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react'
import RightsManager from '../../../utils/RightsManager';
import { useLocation } from 'react-router-dom';
import { AddFormDataDialog } from '../dialogs';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAddDynamicFormsDataDialog } from '../../../actions/formBuilderActions';
import { AddDynamicFormsDataMode } from '../utils/enums';

const AddFormDataHeaderButton = () => {

  const isUserAdmin = RightsManager.isAdmin();

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const userId = authUser?.id;

  const theme = useTheme();
  const styles = useStyles();

  const pattern = /^\/forms\/details\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  const location = useLocation();
  const isFormDataDetailsPath = pattern.test(location.pathname);
  const isCallPath = location.pathname.includes("/call");

  const headerIconsBP = useMediaQuery("(min-width: 1100px)");

  const isButtonVisible = isUserAdmin && isFormDataDetailsPath && userId && !isCallPath;

  const dispatch = useDispatch();

  const openAddFormDataDialog = () => dispatch(toggleAddDynamicFormsDataDialog({
    state: true,
    mode: AddDynamicFormsDataMode.ids.Add
  }));

  return (
    <>
      {isButtonVisible && !headerIconsBP && (
        <pre onClick={openAddFormDataDialog} className={styles.navItem}>
          <Icon
            path={mdiFormSelect}
            size="1.5rem"
            color={theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR}
          />
        </pre>
      )}

      {isButtonVisible && (
        <pre
          onClick={openAddFormDataDialog}
          className={styles.navItem}
        >
          <Icon
            path={mdiFormSelect}
            size="1.5rem"
            color={
              theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR
            }
          />
          <Typography>New Entry</Typography>
        </pre>
      )}

    </>
  )
}

export default AddFormDataHeaderButton;

const useStyles = makeStyles((theme) => ({
  navItem: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    color: theme.colors.HEADER_TITLE_COLOR,
    textDecoration: "none",
    "&>*:nth-child(2)": {
      marginLeft: theme.spacing(1),
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
}))