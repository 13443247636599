import { KeyboardDatePicker } from '@material-ui/pickers';
import { mdiRestore } from '@mdi/js';
import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleAppointmentSearch, setAppointmentTimesFilter } from '../../actions/appointmentActions';
import { fetchCustomerSuggest, fetchMemberSuggest } from '../../actions/suggestionsActions';
import FormActionButtons from '../../components/FormActionButtons';
import JobChipsFilter from '../../components/JobChipsFilter';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import * as yup from 'yup';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import HeadingBar from '../../components/HeadingBar';
import { useGlobalStore } from '../../providers/GlobalProvider';
import { TOGGLE_SEARCH } from '../../utils/actions';
import InputLabelCNO from '../../library/InputLabelCNO';
import UserSuggestionOption from '../../components/UserSuggestionOption/UserSuggestionOption';
import RightsManager from '../../utils/RightsManager';
import { useRouteMatch } from 'react-router-dom';
import { getPrefillAssignedUser } from '../../utils/Helpers';
import { TimesFilter } from '../../utils';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';

function AppointmentFilterModal() {

  const isUserAdmin = RightsManager.isAdmin();
  const isUserCustomer = RightsManager.isCustomer();
  const isUserMember = RightsManager.isMember();

  const isCalendarPath = useRouteMatch().path.includes('/calendar');

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const styles = useStyles({ tillTablet });
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { appointmentSearchParams } = useSelector(state => state.appointments);
  const suggestions = useSelector(state => state.suggestions);

  const { dispatchGlobal } = useGlobalStore();
  const closeAppointmentFilterDialog = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false
    });
  };

  const validationSchema = yup.object({
    categories: yup.array()
  });

  const formik = useFormik({
    initialValues: {
      customer: null,
      categories: [],
      appointment: null,
      startDate: null,
      endDate: null,
      assignments: isUserMember ? getPrefillAssignedUser() : null
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(handleAppointmentSearch(values));
      closeAppointmentFilterDialog();
    }
  });

  useEffect(() => {

    console.log("==========useEffectappointmentSearchParams", appointmentSearchParams);

    formik.setValues(appointmentSearchParams);
  }, [appointmentSearchParams]);


  const handleResetForm = () => {
    formik.resetForm();
    dispatch(handleAppointmentSearch(formik.initialValues));

    dispatch(setAppointmentTimesFilter(TimesFilter.None));

  };

  const triggerAppointmentSearch = (values) => {
    if (!tillTablet) {
      dispatch(handleAppointmentSearch(values));
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.container_AppointmentFilterModal}>

      <HeadingBar
        title={formik.dirty ? "Reset" : "Search"}
        icon={formik.dirty ? mdiRestore : null}
        onHeaderClick={handleResetForm}
        onClose={formik.dirty ? undefined : closeAppointmentFilterDialog}
      />

      {
        isUserAdmin &&
        <AutocompleteCNO
          name='customer'
          label='Contact Person'
          formik={formik}
          placeholder='Search Customer'
          // fullWidth
          data={suggestions}
          optionLabel={x => x.title || ""}
          renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
          onChange={(value) => {
            if (value) {
              dispatch(fetchCustomerSuggest(value));
            }
          }}
          onSuggestionClick={(value) => {
            formik.setFieldValue('customer', value);
            triggerAppointmentSearch({ ...formik.values, customer: value });
          }}
        />
      }

      <div style={{ marginTop: 12 }}></div>

      {/* {
        tillTablet &&
        <AutocompleteCNO
          label='Select Appointment'
          name='appointment'
          placeholder='Search Appointment...'
          formik={formik}
          data={suggestions}
          optionLabel={x => x.title || ""}
          renderOption={option => <div style={{ color: 'orangered' }}>{option.title}</div>}
          // fullWidth
          onSuggestionClick={(value) => {
            formik.setFieldValue('appointment', value);
            triggerAppointmentSearch({ ...formik.values, appointment: value });
          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchAppointmentSuggest(value));
            }
          }}
        />
      } */}

      <InputLabelCNO label={'Categories'} />
      <div className={GlobalStyles.verticalScrollChipsWrapper} style={{ marginTop: 12, marginBottom: 12 }}>
        <JobChipsFilter
          mode="appointment"
          onChipToggle={(categories) => {
            formik.setFieldValue('categories', categories);
            triggerAppointmentSearch({ ...formik.values, categories: categories });
          }}
        />
      </div>

      {
        isUserAdmin &&
        <AutocompleteCNO
          name='assignments'
          formik={formik}
          label={"Assign To"}
          className={styles.profileSearch}
          placeholder='Search user...'
          data={suggestions}
          optionLabel={x => x.title || ""}
          renderOption={option => <UserSuggestionOption user={option} />}
          onSuggestionClick={(value) => {
            const searchParams = {
              ...appointmentSearchParams,
              assignments: value
            };
            dispatch(handleAppointmentSearch(searchParams));
          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchMemberSuggest(value));
            }
          }}
        />
      }

      <div style={{ marginTop: 12 }} />

      <DateTimePickerCNO
        dateName="startDate"
        datePlaceholder="Start Date"
        datePickerLabel='Start by Date'
        onDateChange={date => {
          formik.setFieldValue('startDate', date);
        }}

        formik={formik}
        iconColor="red"
        isRequired={false}
      />

      <DateTimePickerCNO
        dateName="endDate"
        datePlaceholder="End Date"
        datePickerLabel='End Date'
        onDateChange={date => {
          formik.setFieldValue('endDate', date);
        }}

        formik={formik}
        minDate={formik.values.startDate}
        iconColor="green"
        isRequired={false}
      />

      {
        tillTablet &&
        <FormActionButtons
          rightText="Search"
          rightClick={formik.handleSubmit}
          leftClick={closeAppointmentFilterDialog}
          formik={formik}
        />
      }

    </form>
  )
}

export default AppointmentFilterModal

const useStyles = makeStyles((theme) => ({
  container_AppointmentFilterModal: {
    padding: 8,
    '&>*:first-child': {
      height: ({ tillTablet }) => tillTablet ? 'auto' : 68
    },
    

  },

  chip: {
    background: '#444'
  },
  textField: {
    borderRadius: 5,
    '& input': {
      background: 'white',
      color: '#444',
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: '#444'
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": "#444",
      caretColor: "#444",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 14
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0
    },
    '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      padding: 0
    }
  },
  paper: {
    background: 'white !important',
    '& ::-webkit-scrollbar': {
      display: 'none'
    }
  },
  noOptions: {
    color: '#BE0A02'
  },
  popper: {
    zIndex: 1301
  },
  assignToWrapper: {
    display: 'flex',
    marginTop: 8,
    flexDirection: 'column',
    '&>*:first-child': {
      flex: 1,
      marginBottom: 8
    }
  },
}));