import React, { useState } from 'react'
import SelectCNO from '../../../../library/SelectCNO'
import { FormFieldsTypes } from '../../utils/enums'
import { isArrayEmpty } from '../../../../utils/Helpers'
import { Box } from '@material-ui/core'
import FormFieldActions from '../FormFieldActions'

const SelectFormRender = ({ field, formik, onEdit, onDelete }) => {

  const [value, setValue] = useState(formik.values[field.name] || '')

  return (
    <>
      {
        field.type === FormFieldsTypes.ids.Select && !isArrayEmpty(field.attributes.options) &&
        <Box display='flex' alignItems='flex-start' justifyContent='space-between' >
          <SelectCNO
            name={field.name}
            label={field.label}
            options={field.attributes.options}
            fullWidth
            containerStyles={{ width: '100%' }}
            value={value}
            onChange={(v) => setValue(v)}
          />
          <FormFieldActions fieldInitialValues={field} onEdit={onEdit} onDelete={onDelete} />
        </Box>
      }
    </>
  )
}

export default SelectFormRender