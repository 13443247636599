import { Typography } from '@material-ui/core';
import { mdiFormatText } from '@mdi/js';
import React from 'react';
import ChipCNO from '../../library/ChipCNO';
import InputCNO from '../../library/InputCNO';
import { getMaterialIconString } from '../../utils/icons';
import { JobTargetType, JobTargetTypeIcon } from '../../utils/mappings';
import { useStyles } from './JobTargetDetailsStyles';
import * as icons from '@mdi/js';

function JobTargetDetailsForm({ formik }) {

  const styles = useStyles();

  return (
    <div className={styles.container_JobTargetDetails}>
      <div className={styles.chipsWrapper}>
        <Typography>Job Target Types</Typography>
        <div className={styles.chips}>

          {
            Object.keys(JobTargetType).filter((t) => t !== 'None')
              .map((target) => (
                <ChipCNO
                  icon={icons[getMaterialIconString(JobTargetTypeIcon[target])]}
                  name={target}
                  size="1.5rem"
                  active={formik.values.targetType === JobTargetType[target]}
                  onClick={() => formik.setFieldValue('targetType', JobTargetType[target])}
                />
              ))
          }

        </div>

        <InputCNO
          name='target'
          label='Job Target'
          formik={formik}
          placeholder='Enter Job Target'
          icon={mdiFormatText}
          fullWidth
        />
      </div>
    </div>
  )
}

export default JobTargetDetailsForm
