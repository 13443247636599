import { makeStyles } from '@material-ui/core';
import { mdiFormatText } from '@mdi/js';
import React, { useRef } from 'react'
import { SnapItem, SnapList, useDragToScroll } from 'react-snaplist-carousel';
import FormBox from '../../../../components/FormBox'
import { InputLabelCNO, ChipCNO, InputCNO } from '../../../../library'
import LocalStorageConstants from '../../../../utils/LocalStorageConstants';
import { useGlobalStyles } from '../../../../utils/Styles/GlobalStyles';
import EventCategories from '../../EventCategories.json';

const AddEventDetails = ({ formik, bgColor, title = "Event Details" }) => {

  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  // let localCategories = LocalStorageConstants.getItem("categories", []);

  return (
    <FormBox
      {...(bgColor ? { bgColor } : {})}
      {...(title ? { title } : {})}
    >
      <div className={GlobalStyles.sectionChildrenContainer}>

        <div>
          <InputLabelCNO label={"Category"} isRequired={true} />
          <SnapList ref={snapList} className={styles.chipsWrapper}>
            {EventCategories.map((category) => (
              <SnapItem>
                <ChipCNO
                  icon={category.iconLink}
                  name={category.title}
                  isCategoryChip={true}
                  active={formik.values.categories.includes(category.id)}
                  onClick={() => {

                    let newCategories = formik.values.categories;

                    if (formik.values.categories.includes(category.id)) {
                      newCategories = newCategories.filter(c => c.id !== category.id);
                    } else {
                      newCategories = [category.id, ...newCategories];
                    }

                    formik.setFieldValue("categories", newCategories);

                  }}
                />
              </SnapItem>
            ))}
          </SnapList>
        </div>
        <InputCNO
          name="title"
          label="Event Title"
          formik={formik}
          placeholder="Enter Event Title"
          icon={mdiFormatText}
          fullWidth
          isRequired
        />
        <InputCNO
          name="description"
          label="Event Description"
          formik={formik}
          placeholder="Enter Event Details"
          fullWidth
          multiline
        />
      </div>


    </FormBox>
  )
}

export default AddEventDetails;

const useStyles = makeStyles(() => ({
  chipsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 132,
    overflowY: 'auto',
    '&>*': {
      margin: 4
    },
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#5e5e5e',
      borderRadius: 10,
    },
  },
}))