const colors = {
  transparent: 'transparent',
  labelColor: '#767676',
  inactiveField: '#f2f2f2',
  inactiveButton: '#b7dbdd',
  inactiveIcon: '#EBEBEB',
  primaryHover: '#F29E02',
  secondary: '#ff5b60',
  secondaryHover: '#FF282F',
  yellow: '#fdb32a',
  yellowHover: '#F29E02',
  borderColor: '#dadada',
  black: '#000000',
  white: '#ffffff',
  primary: '#ff8000',
  headingColor: '#0f2137',
  quoteText: '#343d48',
  textColor: 'rgba(52, 61, 72, 0.8)',
  linkColor: '#2b9eff',
  backgroundColor: '#ffffff',
  featureRippleColor: 'rgba(0, 0, 0, 0.5)',
  boxShadowColor: 'rgba(22, 30, 54, 0.25)',
  footerBackgroundColor: 'rgb(246, 249, 252)',
  footerHeadingColor: '#343d48',
  footerTextColor: 'rgba(52, 61, 72, 0.8)',
  navbarShadow: 'rgba(0, 0, 0, 0.1)',
  blueColor: '#436a93',
  heroSquareShapeColor: '#c68a4d',
  heroCircleShapeColor: '#6d9468',
  pricingTableBorder: '#EDF0F4',
  pricingTableShadow: 'rgba(22, 30, 54, 0.25)',
  exploreLabelShadow: "rgba(0, 0, 0, 0.5)"
};

export default colors;
