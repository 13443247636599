import {
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Typography,
  useTheme
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useRef } from 'react';
import ChipCNO from '../../library/ChipCNO';
import {
  mdiAccount,
  mdiCamera,
  mdiEmail,
  mdiFormatText,
  mdiImagePlus,
  mdiWeb,
  mdiPlus,
} from '@mdi/js';
import InputCNO from '../../library/InputCNO';
import Icon from '@mdi/react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import AddCompany from '../AddCompany/AddCompany';
import HeadingBar from '../HeadingBar/HeadingBar';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import {
  SnapList,
  SnapItem,
  useDragToScroll,
} from 'react-snaplist-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { useStyles } from './RegisterCompanyStyles';
import { addCompany, toggleAddCompanyDialog } from '../../actions/customerCompanyActions';
import GooglePlaceInputCNO from '../../library/GooglePlaceInputCNO/GooglePlaceInputCNO';
import { toast } from 'react-toastify';
import Upload from 'rc-upload';
import AvatarCNO from '../../library/AvatarCNO/AvatarCNO';
import { ApiCore } from '../../api/utils/core';
import { v4 } from 'uuid';
import { IconPicker } from '../../library/IconPickerCNO';
import DialogCNO from '../../library/DialogCNO';
import { getUserCountry } from '../../utils/Helpers';
import FormBox from '../FormBox';
import PhoneInputCNO from '../../library/PhoneInputCNO';
import { RegexPatterns } from '../../utils/regexHelpers';

function RegisterCompany({
  isRegister,
  onRegister
}) {

  const theme = useTheme();

  const country = getUserCountry();

  const dispatch = useDispatch();

  const { isAddCompanyDialog, addLoader } = useSelector(state => state.companies);

  const suggestions = useSelector(state => state.suggestions);

  const [isAddNewIcon, setIsAddNewIcon] = useState(false);
  const [companyProfilePic, setCompanyProfilePic] = useState(null);
  const [notRobot, setNotRobot] = useState(false);

  const handleClose = () => {
    dispatch(toggleAddCompanyDialog(false));
  };

  const validationSchema = yup.object({
    title: yup.string().required("Company name is required"),
    contactPersonFirstName: yup.string(),
    contactPersonLastName: yup.string(),
    abstract: yup.string(),
    profilePic: yup.string(),
    groupType: yup.number(),
    categories: yup.array().nullable(),
    email: yup.string().email('Please enter a valid email').required("Company email is required"),
    phone: yup.string(),
    address: yup.string(),
    website: yup.string()
      .matches(RegexPatterns.url, 'Website url is not valid.'),
    currency: yup.number(),
    isActive: yup.boolean(),
    subscriptionType: yup.number(),
    paymentType: yup.number(),
    paymentDate: yup.string(),
    taxNumber: yup.string(),
    companyRegistration: yup.string(),
    defaultPaymentMethodId: yup.string().nullable(),
    defaultPaymentMethod: yup.object(),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      contactPersonFirstName: "",
      contactPersonLastName: "",
      abstract: "",
      profilePic: "",
      groupType: 0,
      categories: [
        {
          id: v4(),
          title: "General",
          // groupId: v4(),
          iconLink: "bullseye"
        }
      ],
      email: "",
      phone: "",
      address: "",
      website: "",
      currency: 0,
      isActive: true,
      subscriptionType: 0,
      paymentType: 0,
      paymentDate: new Date(),
      taxNumber: "",
      companyRegistration: "",
      defaultPaymentMethodId: null,
      defaultPaymentMethod: {},
    },
    validationSchema,
    onSubmit: values => handleAddCompany(values)
  });

  console.log("Register company formik: ", formik.values);

  const handleAddCompany = async (values) => {

    let dataToSend = null;

    if (isRegister) {
      dataToSend = {
        title: values.title,
        email: values.email,
        phone: values.phone,
        // contactPersonFirstName: values.contactPersonFirstName,
        // contactPersonLastName: values.contactPersonLastName,
        categories: values.categories
      };
    }
    else {
      dataToSend = values;
    }

    console.log('====================================');
    console.log("Register company dataToSend: ", dataToSend);
    console.log("Register company values: ", values);
    console.log('====================================');

    dispatch(addCompany(dataToSend, isRegister));


    if (onRegister) {
      onRegister(formik.values.email);
    }

    handleClose();

    formik.resetForm();

  };

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  const [avatarLoading, setAvatarLoading] = useState(false);

  const [isMoreDetailsExpanded, setIsMoreDetailsExpanded] = React.useState(false);

  const handleChange = () => {
    setIsMoreDetailsExpanded(!isMoreDetailsExpanded);
  };

  // converts base64 image to blob
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const processAndUploadImage = async (base64file) => {
    setAvatarLoading(true);
    const imageId = v4();
    const imageURL = base64file;

    // Split the base64 string in data and contentType
    const block = imageURL.split(";");
    // Get the content type of the image
    const contentType = block[0].split(":")[1];
    // get the real base64 content of the file
    const realData = block[1].split(",")[1];

    const blob = b64toBlob(realData, contentType);

    const url = "member/initupload";
    const apiProfileInitUpload = new ApiCore({
      post: true,
      url: url
    });

    const initRes = await apiProfileInitUpload.post({ "ids": [imageId] });

    // UPLOAD
    const mRes = await fetch(initRes.data.links[0].m, { method: 'PUT', body: blob });
    const tRes = await fetch(initRes.data.links[0].t, { method: 'PUT', body: blob });
    const bRes = await fetch(initRes.data.links[0].b, { method: 'PUT', body: blob });

    formik.setFieldValue('profilePic', imageId);
    setCompanyProfilePic(imageURL);

    setAvatarLoading(false);

  };

  const processMediaUpload = (file) => {

    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      // console.log("File Reader image Result: ", reader.result);
      processAndUploadImage(reader.result);
    };

    reader.onerror = function (error) {
      toast.error("Error in uploading profile");
      console.log('Error: ', error);
    };
  };

  const uploadProps = {
    beforeUpload: (file) => {
      if (file.type.includes('image')) {
        return true;
      }
      toast.error("Unsupported file");
      return false;
    },
    action: (file) => {
      console.log("Upload Action file: ", file);
      processMediaUpload(file);
    },
    multiple: false,
  };

  const styles = useStyles();

  return (
    <Dialog
      open={isAddCompanyDialog}
      classes={{ paper: styles.paper }}
      className={styles.container_RegisterCompany}
    >

      {
        addLoader &&
        <FullScreenLoaderCNO />
      }

      <form
        onSubmit={formik.handleSubmit}
        className={styles.form}
      >
        <HeadingBar title="Register Company" onClose={handleClose} />

        {
          !isRegister &&
          <div className={styles.avatarWrapper}>

            <Badge
              className={styles.avatarBadge}
              overlap="circle"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <div className={styles.pickers}>
                  <Icon
                    path={mdiCamera}
                    size="1.5rem"
                    color={theme.colors.IMAGE_CAMERA_ICON_COLOR}
                    style={{ visibility: 'hidden' }}
                  />
                  <Upload {...uploadProps} accept="image/*">
                    <Icon
                      path={mdiImagePlus}
                      size="1.5rem"
                      color={theme.colors.IMAGE_UPLOAD_ICON_COLOR}
                    />
                  </Upload>
                </div>
              }
            >
              {
                avatarLoading
                  ? <CircularProgress
                    style={{
                      background: '#4e4e4e',
                      padding: '20px',
                      borderRadius: '50%',
                      width: '8rem',
                      height: '8rem'
                    }}
                  />
                  :
                  <AvatarCNO
                    src={companyProfilePic}
                    fallbackIcon={mdiAccount}
                    avatarStyle={{ border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}` }}
                    iconSize="8rem"
                  />
              }

            </Badge>
          </div>
        }

        <InputCNO
          name='title'
          label='Company Name'
          formik={formik}
          placeholder='Company Name'
          icon={mdiFormatText}
          fullWidth
        />

        <InputCNO
          name='email'
          label='Email'
          type='email'
          formik={formik}
          placeholder='Email'
          icon={mdiEmail}
          fullWidth
        />

        {/* <div>
          <Typography className={styles.label}>Contact Person</Typography>
          <div className={styles.row}>
            <InputCNO
              name='contactPersonFirstName'
              formik={formik}
              placeholder='First Name'
              icon={mdiFormatText}
              fullWidth
            />

            <InputCNO
              name='contactPersonLastName'
              formik={formik}
              placeholder='Last Name'
              icon={mdiFormatText}
              fullWidth
            />
          </div>
        </div> */}

        {
          isRegister &&
          <div className={styles.link} onClick={handleChange}>
            {
              (isMoreDetailsExpanded)
                ? 'Less Details'
                : 'More Details'
            }
          </div>
        }

        {
          (isMoreDetailsExpanded || !isRegister) &&
          <div
            className={styles.accordionDetails}
          >

            <FormBox title={"Categories"}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant='contained'
                  className={styles.addBtn}
                  startIcon={<Icon path={mdiPlus} size="1.5rem" color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR} />}
                  onClick={() => setIsAddNewIcon(true)}
                >
                  <pre>Add New</pre>
                </Button>
              </div>
              <div className={styles.selectChips}>

                <SnapList className={styles.chipsWrapper}>
                  {
                    formik.values.categories.map((category) => (
                      <SnapItem>
                        <ChipCNO
                          icon={category.iconLink}
                          isCategoryChip={true}
                          name={category.title}
                          active={true}
                        // onClick={() => formik.setFieldValue('subscriptionType', CompanySubscription.Silver)}
                        />
                      </SnapItem>
                    ))
                  }


                </SnapList>

              </div>
            </FormBox>

            <FormBox title="Contact Details">
              <GooglePlaceInputCNO
                address={formik.values.address}
                onSelectAddress={(addressObj) => {
                  formik.setFieldValue('address', addressObj.address)
                  formik.setFieldValue('lat', addressObj.lat)
                  formik.setFieldValue('lng', addressObj.lng)
                }}
                label="Location"
              />

              <InputCNO
                name='website'
                label='Website'
                formik={formik}
                placeholder='Website'
                icon={mdiWeb}
                fullWidth
              />

              <PhoneInputCNO
                label={'Mobile'}
                placeholder='Enter phone number'
                value={formik.values.phone}
                onChange={(value) => formik.setFieldValue('phone', value)}
                showHelperText={formik.values.phone}
                helperText={formik.values.phone}
                onValidatePhone={(error) => {
                  if (!!error) formik.setFieldError('phone', error)
                }}
              />

              <div style={{ marginTop: 16 }}>
                <InputCNO
                  name='abstract'
                  label='About Company'
                  formik={formik}
                  placeholder='About Company'
                  icon={mdiFormatText}
                  fullWidth
                  multiline
                />
              </div>
            </FormBox>

            {/* <div className={styles.selectChips}>
              <Typography component='label'>Subscription</Typography>
              <SnapList className={styles.chipsWrapper}>
                <SnapItem>
                  <ChipCNO
                    icon={mdiStar} name='Silver'
                    active={formik.values.subscriptionType === CompanySubscription.Silver}
                    onClick={() => formik.setFieldValue('subscriptionType', CompanySubscription.Silver)} />
                </SnapItem>
                <SnapItem>
                  <ChipCNO icon={mdiGold} name='Gold'
                    active={formik.values.subscriptionType === CompanySubscription.Gold}
                    onClick={() => formik.setFieldValue('subscriptionType', CompanySubscription.Gold)} />
                </SnapItem>
                <SnapItem>
                  <ChipCNO icon={mdiRocket} name='Platinum'
                    active={formik.values.subscriptionType === CompanySubscription.Platinum}
                    onClick={() => formik.setFieldValue('subscriptionType', CompanySubscription.Platinum)} />
                </SnapItem>
                <SnapItem>
                  <ChipCNO icon={mdiSecurity} name='Private'
                    active={formik.values.subscriptionType === CompanySubscription.Private}
                    onClick={() => formik.setFieldValue('subscriptionType', CompanySubscription.Private)} />
                </SnapItem>
              </SnapList>
            </div>

            <InputCNO
              name='taxNumber'
              label='Tax number'
              formik={formik}
              placeholder='Tax Number'
              icon={mdiNumeric}
              fullWidth
            />

            <InputCNO
              name='companyRegistration'
              label='Company Registration Number'
              formik={formik}
              placeholder='Company Registration Number'
              icon={mdiText}
              fullWidth
            />

            <div className={styles.selectChips}>
              <Typography component='label'>Default Payment Mode</Typography>
              <SnapList className={styles.chipsWrapper}>
                <SnapItem>
                  <ChipCNO
                    icon={mdiCash} name='Bank Account'
                    active={formik.values.defaultPaymentMethod.type === PaymentType.BankAccount}
                    onClick={() => formik.setFieldValue('defaultPaymentMethod', {
                      type: PaymentType.BankAccount,
                      number: null,
                      companyId: v4()
                    })} />
                </SnapItem>
                <SnapItem>
                  <ChipCNO icon={mdiCreditCard} name='Credit Card'
                    active={formik.values.defaultPaymentMethod.type === PaymentType.CreditCard}
                    onClick={() => formik.setFieldValue('defaultPaymentMethod', {
                      type: PaymentType.CreditCard,
                      number: null,
                      companyId: v4()
                    })} />
                </SnapItem>
                <SnapItem>
                  <ChipCNO icon={mdiCreditCardOutline} name='Debit Card'
                    active={formik.values.defaultPaymentMethod.type === PaymentType.DebitCard}
                    onClick={() => formik.setFieldValue('defaultPaymentMethod', {
                      type: PaymentType.DebitCard,
                      number: null,
                      companyId: v4()
                    })} />
                </SnapItem>
                <SnapItem>
                  <ChipCNO icon={mdiCreditCardOutline} name='Cash'
                    active={formik.values.defaultPaymentMethod.type === PaymentType.Cash}
                    onClick={() => formik.setFieldValue('defaultPaymentMethod', {
                      type: PaymentType.Cash,
                      number: null,
                      companyId: v4()
                    })} />
                </SnapItem>
                <SnapItem>
                  <ChipCNO icon={mdiCreditCardOutline} name='Others'
                    active={formik.values.defaultPaymentMethod.type === PaymentType.Others}
                    onClick={() => formik.setFieldValue('defaultPaymentMethod', {
                      type: PaymentType.Others,
                      number: null,
                      companyId: v4()
                    })} />
                </SnapItem>
              </SnapList>
            </div> */}

          </div>
        }

        <FormControlLabel
          control={
            <Checkbox
              checked={notRobot}
              onChange={() => {
                setNotRobot(!notRobot);
              }}
              name="notRobot"
              color="primary"
            />
          }
          label="I am not a Robot"
        />

        <FormActionButtons
          rightText="Add Company"
          leftClick={handleClose}
          formik={formik}
          wrapperStyles={{ marginTop: 24 }}
          disabled={!notRobot}
        />
      </form>

      {
        isAddNewIcon &&
        <AddNewIconModal
          open={isAddNewIcon}
          onClose={() => setIsAddNewIcon(false)}
          formikRegister={formik}
        />
      }

    </Dialog>
  )
}

export default RegisterCompany;


const AddNewIconModal = ({
  open,
  onClose,
  formikRegister
}) => {

  const styles = useStyles();

  const validationSchema = yup.object({
    iconLink: yup.string().required("Required"),
    iconTitle: yup.string().required("Required"),
  })

  const formik = useFormik({
    initialValues: {
      iconLink: '',
      iconTitle: ''
    },
    validationSchema,
    onSubmit: (values) => addNewCategory(values)
  });

  const addNewCategory = (values) => {

    const newCategory = {
      id: v4(),
      title: values.iconTitle,
      // groupId: null,
      iconLink: values.iconLink
    };

    const payload = [newCategory, ...formikRegister.values.categories];

    formikRegister.setFieldValue("categories", payload);

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Add New Category"
    >
      <form className={styles.addNewIconForm} onSubmit={formik.handleSubmit}>

        <Typography className={styles.addNewIconLabel}>Category Icon</Typography>
        <IconPicker
          value={formik.values.iconLink}
          onChange={(value) => formik.setFieldValue('iconLink', value)}
          buttonStyles={{
            background: 'white',
            marginRight: 10,
            width: '100%',
            marginBottom: 10
          }}
        />

        <InputCNO
          name={'iconTitle'}
          label="Category Title"
          formik={formik}
          placeholder='Category Title'
          icon={mdiFormatText}
          fullWidth
        />

        <FormActionButtons
          type="submit"
          rightText="Save"
          leftClick={onClose}
          formik={formik}
          wrapperStyles={{ marginTop: 120 }}
        />
      </form>
    </DialogCNO>
  )
};

