import { makeStyles, useMediaQuery } from '@material-ui/core';
import { mdiRestore } from '@mdi/js';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerSearchParams } from '../../actions/customerActions';
import { fetchCustomerSuggest } from '../../actions/suggestionsActions';
import FormActionButtons from '../../components/FormActionButtons';
import HeadingBar from '../../components/HeadingBar';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import { useGlobalStore } from '../../providers/GlobalProvider';
import { TOGGLE_SEARCH } from '../../utils/actions';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import * as yup from 'yup';

function CustomerFilterModal({ setPage }) {

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const GlobalStyles = useGlobalStyles();
  const styles = useStyles({ tillTablet });

  const dispatch = useDispatch();
  const { customerSearchParams } = useSelector(state => state.customers);
  const suggestions = useSelector(state => state.suggestions);

  const { dispatchGlobal } = useGlobalStore();
  const closeCustomerFilterDialog = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false
    });
  };

  const validationSchema = yup.object({
    categories: yup.array()
  });

  const formik = useFormik({
    initialValues: {
      customer: null,
      email: "",
      phone: "",
      address: ""
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(setCustomerSearchParams(values));
      closeCustomerFilterDialog();
    }
  });

  useEffect(() => {
    formik.setValues(customerSearchParams);
    setPage(0);
  }, [customerSearchParams]);

  const handleResetForm = () => {
    formik.resetForm();
    dispatch(setCustomerSearchParams(formik.initialValues));
  };

  const triggerCustomerSearch = (values) => {
    if (!tillTablet) {
      dispatch(setCustomerSearchParams(values));
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.container_CustomerFilterModal}>

      <HeadingBar
        title={formik.dirty ? "Reset" : "Search"}
        icon={formik.dirty ? mdiRestore : null}
        onHeaderClick={handleResetForm}
        onClose={formik.dirty ? undefined : closeCustomerFilterDialog}
      />

      <AutocompleteCNO
        name='customer'
        label='Name'
        formik={formik}
        placeholder='Search customer'
        fullWidth
        triggerSearchOnEnter={true}
        data={suggestions}
        optionLabel={x => x.title || ""}
        renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
        onSuggestionClick={(value) => {
          formik.setFieldValue("customer", value);
          triggerCustomerSearch({ ...formik.values, customer: value });
          console.log("onSuggestionClickValue",value)
        }}
        onChange={(value) => {
          if (value) {
            dispatch(fetchCustomerSuggest(value));
          }
        }}
      />

      {/* <div style={{ marginTop: 10 }}>
        <InputCNO
          name='email'
          label='Email'
          formik={formik}
          placeholder='Enter customer email'
          icon={mdiEmail}
          fullWidth
        />
      </div> */}

      {/* <PhoneInputCNO
        value={formik.values.phone}
        label="Mobile"
        onChange={(value) => formik.setFieldValue('phone', value)}
        containerStyle={{ marginTop: 10, marginBottom: 10 }}
      /> */}

      {/* <GooglePlaceInputCNO
        address={formik.values.address}
        onSelectAddress={(addressObj) => {
          formik.setFieldValue('address', addressObj.address)
        }}
      /> */}

      {
        tillTablet &&
        <FormActionButtons
          rightText="Search"
          rightClick={formik.handleSubmit}
          leftClick={closeCustomerFilterDialog}
          formik={formik}
        />
      }

    </form>
  )
}

export default CustomerFilterModal;

const useStyles = makeStyles((theme) => ({
  container_CustomerFilterModal: {
    padding: 8,
    width: 350,
    '&>*:first-child': {
      height: ({ tillTablet }) => tillTablet ? 'auto' : 68
    }
  }
}));
