import { Box, Collapse } from '@material-ui/core';
import React from 'react';
import { InputCNO, MetaText, SwitchCNO, SwitchMain } from '../../../../library';
import { FormFieldsTypes } from '../../utils/enums';
import DefaulValueAttribute from './DefaulValueAttribute';
import ConditionalAttribute from './ConditionalAttribute';

const InputFieldAttributes = ({ formik }) => {
  return (
    <>
      {
        formik.values.type === FormFieldsTypes.ids.Text ?
          <Box>

            <DefaulValueAttribute formik={formik} >
              <InputCNO
                name='attributes.defaultValue'
                label='Default Value'
                placeholder='Enter the default value...'
                formik={formik}
                fullWidth
              />
            </DefaulValueAttribute>

            <InputCNO
              name='validations.maxLength.value'
              label='Maximum number of characters'
              placeholder="Maximum number of characters..."
              type="number"
              formik={formik}
              value={formik.values.validations.maxLength.value}
            />

            <Box>
              <SwitchMain
                name='attributes.multiline'
                label='Multiline'
                formik={formik}
                inlineLabel
              />
              <Collapse in={formik.values.multiline} >
                <InputCNO
                  name='attributes.numberOfLines'
                  label='Number of lines'
                  placeholder="Enter number of lines..."
                  type="number"
                  value={formik.values.attributes.numberOfLines}
                />
              </Collapse>
            </Box>
          </Box> : null
      }
    </>
  )
}

export default InputFieldAttributes;