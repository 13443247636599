import { toast } from "react-toastify";
import { apiTrackCheckinsCount, apiTrackSearch } from "../api";

import { fetchTaskSearch } from "./taskSearchActions";
import { combineDateAndTime } from "../utils/Tracking";

export const FETCH_TRACK_SEARCH = "FETCH_TRACK_SEARCH";
export const RESET_TRACK_SEARCH_MARKERS = "RESET_TRACK_SEARCH_MARKERS";
export const TOGGLE_WORK_HOURS_DIALOG = "TOGGLE_WORK_HOURS_DIALOG";
export const TOGGLE_PAY_USER_DIALOG = "TOGGLE_PAY_USER_DIALOG";
export const SET_TRACK_SEARCH_LOADER = "SET_TRACK_SEARCH_LOADER";
export const TOGGLE_SOFT_TRACKING = "TOGGLE_SOFT_TRACKING";
export const FETCH_SOFT_TRACK_DATA = 'FETCH_SOFT_TRACK_DATA';
export const SET_IS_PLOT_JOB_MARKERS = 'SET_IS_PLOT_JOB_MARKERS';
export const SET_MAP_SEARCH_PARAMS = "SET_MAP_SEARCH_PARAMS";
export const IS_WORKING_TODAY_MODAL = "IS_WORKING_TODAY_MODAL";
export const SET_TRACK_LOADERS = "SET_TRACK_LOADERS";
export const SET_CHECKINS_IDS = "SET_CHECKINS_IDS";
export const SET_TOTAL_EMPLOYEES_NUMBER = "SET_TOTAL_EMPLOYEES_NUMBER";

export const setTrackLoaders = (loaderName, loaderState) => {
  return (dispatch) => {
    dispatch({
      type: SET_TRACK_LOADERS,
      payload: { loaderName, loaderState }
    });
  };
};

export const setCheckInsIds = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_CHECKINS_IDS,
      payload: payload
    });
  };
};

export const setTotalEmployeesNumber = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_TOTAL_EMPLOYEES_NUMBER,
      payload: payload
    });
  };
};

export function toggleWorkingTodayModal(payload) {
  return (dispatch) => {
    dispatch({
      type: IS_WORKING_TODAY_MODAL,
      payload: payload
    });
  };
}

export function setMapSearchParams(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_MAP_SEARCH_PARAMS,
      payload: payload
    });
  };
}

export function setTrackSearchData(payload) {
  return (dispatch) => {
    dispatch({
      type: FETCH_TRACK_SEARCH,
      payload: {
        response: payload
      }
    });
  };
}

function setLoader(status) {
  return (dispatch) => {
    dispatch({
      type: SET_TRACK_SEARCH_LOADER,
      payload: status
    });
  };
}

export function fetchTrackSearch(payload, isWorkHourCall) {
  return async function fetchTrackSearchThunk(dispatch, getState) {

    const { softTrackData } = getState().trackSearch;

    console.time("speed: track fetch");

    dispatch(setLoader(true));

    const search = {
      "pageSize": 500,
      "pageIndex": 0,
      "orderBy": "",
      "sortDecending": true,
      "taskIds": [],
      "profileId": null,
      "startDate": null,
      "endDate": null,
      ...payload.search,
    };

    await apiTrackSearch.post(search)
      .then(response => {
        if (!response.data.data.length) {
          toast.info("Tracking data is not logged yet for this task!");
        }

        console.log("Track Search Response: ", response);
        console.timeEnd("speed: track fetch");

        // merge soft track data if exists
        // after fetching hard track data
        let mergedTrackData = [
          ...response.data.data,
          ...softTrackData
        ];

        // mergedTrackData = simpleWithinProximityData.data;

        dispatch({
          type: FETCH_TRACK_SEARCH,
          payload: {
            response: mergedTrackData,
          }
        });

        dispatch({
          type: SET_IS_PLOT_JOB_MARKERS,
          payload: true
        });

        console.log("isWorkHourCall: ", isWorkHourCall);

        if (response.data.data.length && !isWorkHourCall) {
          toast.info("The tracks plotted in map are based on the location provided by the user's mobile. The accuracy of the location may differ by some meters due to circumstances of the phone and its location. ");
        }

        dispatch(setLoader(false));
      })
      .catch(error => {
        dispatch(setLoader(false));
        toast.error("Error while searching!!!");
        console.log("Track search API Fetch error: ", error);
      });
  };
}


export function resetTrackSearchMarkers() {
  return function resetTrackSearchMarkersThunk(dispatch, getState) {
    dispatch({
      type: RESET_TRACK_SEARCH_MARKERS
    });
  };
}

export function resetSoftTrackData() {
  return (dispatch) => {
    dispatch({
      type: FETCH_SOFT_TRACK_DATA,
      payload: []
    });
  };
}


export function toggleWorkHourDialog(payload) {
  return function toggleWorkHourDialogThunk(dispatch, getState) {
    dispatch({
      type: TOGGLE_WORK_HOURS_DIALOG,
      payload: payload
    });
  };
}


export function togglePayUserDialog(payload) {
  return function togglePayUserDialogThunk(dispatch, getState) {
    dispatch({
      type: TOGGLE_PAY_USER_DIALOG,
      payload: payload
    });
  };
}

export function toggleSoftTracking(isSoftTracking) {
  return (dispatch, getState) => {

    const trackSearchData = getState().trackSearch.data;

    dispatch({
      type: TOGGLE_SOFT_TRACKING,
      payload: isSoftTracking
    });

    // if softTracking enabled then fetch soft track and merge with hard track data
    // otherwise filter out soft track data (taskId=null) from merged data
    if (isSoftTracking) {
      dispatch(fetchSoftTrackData());
    }
    else {
      dispatch({
        type: FETCH_SOFT_TRACK_DATA,
        payload: []
      });

      const newTrackData = trackSearchData.filter((track) => track.taskId !== null);
      dispatch({
        type: FETCH_TRACK_SEARCH,
        payload: {
          response: newTrackData
        }
      });
    }
  };
}

export function fetchSoftTrackData(searchParams = {}) {
  return async (dispatch, getState) => {
    dispatch(setLoader(true));

    const trackSearch = getState().trackSearch;
    const trackSearchData = trackSearch.data;
    const mapSearchParams = trackSearch.mapSearchParams;

    console.log("======fetchSoftTrackData========mapSearchParams=========", mapSearchParams);

    const trackStartDateTime = combineDateAndTime(mapSearchParams.trackStartDate, mapSearchParams.trackStartTime);
    const trackEndDateTime = mapSearchParams.trackEndDate ? combineDateAndTime(mapSearchParams.trackEndDate, mapSearchParams.trackEndTime) : null;

    const search = {
      pageSize: 500,
      pageIndex: 0,
      orderBy: "",
      sortDecending: true,
      taskIds: null,
      profileId: mapSearchParams.teamMember.id,
      startDate: trackStartDateTime,
      endDate: trackEndDateTime,
      ...searchParams
    };

    await apiTrackSearch.post(search)
      .then((response) => {
        console.log("softTrackData: resp ", response);
        if (response && response.status === 200) {

          console.log("responseSoftrack: ", response);

          if (response.data.data.length > 0) {
            // set soft Track Data
            dispatch({
              type: FETCH_SOFT_TRACK_DATA,
              payload: response.data.data
            });

            // merge both soft track data and hard trackData
            dispatch({
              type: FETCH_TRACK_SEARCH,
              payload: {
                response: [...trackSearchData, ...response.data.data]
              }
            });

            toast.info("The tracks plotted in map are based on the location provided by the user's mobile. The accuracy of the location may differ by some meters due to circumstances of the phone and its location. ");

          }
          else {
            toast.info("No Soft Track Data Found!!!");
          }
        }
      })
      .catch((error) => {
        console.log("Soft Track Search Error: ", error);
        toast.error("Error in fetching Soft Track Data!!!");
      })
      .finally(() => dispatch(setLoader(false)));
  };
}

export const handleMapTrackSearch = (searchParams) => {
  return (dispatch) => {

    console.log("====handleMapTrackSearch===searchParams========");

    dispatch(setMapSearchParams(searchParams));

    const trackTaskSearchPayload = {
      ids: searchParams.job ? [searchParams.job.id] : null,
      assignedIds: searchParams.teamMember ? [searchParams.teamMember.id] : [],
      startDate: searchParams.jobStartDate ? searchParams.jobStartDate : null,
      endDate: searchParams.jobEndDate ? searchParams.jobEndDate : null,
      teamMember: searchParams.teamMember
    };

    dispatch(fetchTaskSearch(trackTaskSearchPayload));

  };
};

const fetchTrackCheckinsCount = () => {
  return apiTrackCheckinsCount.post({});
}

export const getTrackCheckinsCount = () => {
  return async (dispatch) => {

    dispatch(setTrackLoaders("checkInsCountLoader", true));

    await fetchTrackCheckinsCount()
      .then((response) => {

        if (response && response.data) {

          const { checkInsIds, totalEmployees } = response.data;

          dispatch(setCheckInsIds(checkInsIds));

          dispatch(setTotalEmployeesNumber(totalEmployees));

        }

      })
      .catch(error => {
        console.error("Error: TrackCheckinsCount: ", error);
      })
      .finally(() => {
        dispatch(setTrackLoaders("checkInsCountLoader", false));
      });

  };
};