
export const SET_VISITOR_DETAILS="SET_VISITOR_DETAILS"
export const SET_TO_MEEET="SET_TO_MEEET"

export const setVisitorDetails=(payload)=>{
    return (dispatch) => {
        dispatch({
          type: SET_VISITOR_DETAILS,
          payload: payload
        });
    };
}

export const setToMeet=(payload)=>{
    return (dispatch) => {
        dispatch({
          type: SET_TO_MEEET,
          payload: payload
        });
    };
}

