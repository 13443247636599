import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { mdiHours24, mdiLockClock } from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import { Typography } from '@material-ui/core';
import ChipCNO from '../../library/ChipCNO';
import { RateType } from '../../utils';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { updateInvoiceItem } from '../../actions/invoiceItemActions';
import { Currency, CurrencyIcon } from '../../utils/mappings';
import * as icons from '@mdi/js';
import { getMaterialIconString } from '../../utils/icons';
import { AppConstants } from '../../utils/AppConstants';

function EditInvoiceItemCost({
  open,
  onClose,
  invoiceItem,
  invoice
}) {

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { editLoader } = useSelector(state => state.invoiceItems);

  const formik = useFormik({
    initialValues: {
      rate: invoiceItem.rate,
      rateType: invoiceItem.rateType,
    },
    validationSchema: yup.object({
      rate: yup.string().required('Rate is required'),
      rateType: yup.number().required('Invoice Item Type is required'),
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("Edit Invoice Item: cost: ", values);

    const payload = {
      ...invoiceItem,
      rate: values.rate,
      rateType: values.rateType,
      ...(
        values.rateType === RateType.Hourly ? {
          startDate: AppConstants.DEFAULT_START_DATE,
          endDate: AppConstants.DEFAULT_END_DATE,
          startTime: AppConstants.DEFAULT_START_TIME,
          endTime: AppConstants.DEFAULT_END_TIME,
        } : {}
      )
    };

    dispatch(updateInvoiceItem(payload));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Invoice Item Cost"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <div style={{ marginBottom: 8 }}>
          <Typography>Invoice Item Types</Typography>
          <div className={GlobalStyles.chipsWrapper}>
            <ChipCNO
              icon={mdiLockClock}
              name="Fixed"
              size="1.5rem"
              active={formik.values.rateType === RateType.Fixed}
              onClick={() => formik.setFieldValue('rateType', RateType.Fixed)}
            />
            <ChipCNO
              icon={mdiHours24}
              name="Hourly"
              size="1.5rem"
              active={formik.values.rateType === RateType.Hourly}
              onClick={() => formik.setFieldValue('rateType', RateType.Hourly)}
            />
          </div>
        </div>
        <InputCNO
          name='rate'
          label='Invoice Item Rate'
          formik={formik}
          placeholder='Enter Invoice Item Rate'
          icon={icons[getMaterialIconString(CurrencyIcon[invoice.currency])]}
          fullWidth
          onChange={(e) => {

            const value = e.target.value;

            const rateValue = !isNaN(value) ? value : formik.values.rate;

            formik.setFieldValue('rate', value === "" ? "" : rateValue);

          }}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditInvoiceItemCost
