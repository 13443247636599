import { Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { FileType } from '../../utils/mappings';
import ImageDocumentDisplayCard from './ImageDocumentDisplayCard';
import PdfDocumentDisplayCard from './PdfDocumentDisplayCard';
import VideoDocumentDisplayCard from './VideoDocumentDisplayCard';
import WordDocumentDisplayCard from './WordDocumentDisplayCard';

function DocumentSelectDisplayCard({ document, contentType }) {

  // console.log("========DocumentSelectDisplayCard=========document.fileType=====", document.fileType, contentType);
  // console.log("========DocumentSelectDisplayCard=========document=====", document);

  const _renderDocumentDisplayCard = () => {

    if (document.fileType === FileType.Doc) {
      return (
        <Fragment>
          <WordDocumentDisplayCard document={document} />
        </Fragment>
      )
    } else if (document.fileType === FileType.Image) {
      return (
        <Fragment>
          <ImageDocumentDisplayCard document={document} />
        </Fragment>
      )
    } else if (document.fileType === FileType.Pdf) {
      return (
        <Fragment>
          <PdfDocumentDisplayCard document={document} />
        </Fragment>
      )
    } else if (document.fileType === FileType.Video) {
      return (
        <Fragment>
          <VideoDocumentDisplayCard document={document} />
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <ImageDocumentDisplayCard document={document} />
          {/* <Typography align='center'>File type not supported</Typography> */}
        </Fragment>
      );
    }

  };

  return (
    <Fragment>
      {/* {_renderDocumentDisplayCard()} */}

      <ImageDocumentDisplayCard document={document} />

    </Fragment>
  )
}

export default DocumentSelectDisplayCard
