import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    backdrop_WebCamera: {
        position: 'fixed',
        top: 60,
        left: 0,
        zIndex: 1,
        display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        background: '#fefefe',
        // background: 'wheat',
        width: '100vw',
        minHeight: '100vh'
    },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        // marginLeft: "-2rem",
        background: '#5e5e5e90'
    },
    container_WebCamera: {
        // display: 'flex'
        // width: '80vw',
        // height: '60vh',
        // background: theme.palette.background.main
    },
    webCamWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    webCamActions: {
        // border: '2px solid red',
        paddingLeft: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '&>*': {
            marginBottom: 10
        }
    },
    button: {
        width: 150
    },
    previewWrapper: {
        padding: "10px",
        overflow: 'auto',
        display: 'flex',
        width: '100vw',
        '&::-webkit-scrollbar': {
            height: 5
        },
        '&::-webkit-scrollbar-track': {
            background: '#fff'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#5e5e5e90',
            borderRadius: 10
        },
    },
    previewImage: {
        width: 200,
        objectFit: 'contain',
        padding: "10px 10px 0 0"
    },
    imageActions: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 5
    },
    previewImageBtn: {
        borderRadius: '50%',
        boxShadow: '0px 0px 3px 0 #5e5e5e',
        padding: 5,
        margin: '0 10px',
        '&:hover': {
            cursor: 'pointer'
        }
    }
}));