import React, { useState } from 'react'
import TooltipCNO from '../../../library/TooltipCNO'
import RightsManager from '../../../utils/RightsManager';
import { Collapse, ListItem, ListItemIcon, ListItemText, lighten, makeStyles, useTheme } from '@material-ui/core';
import { isArrayEmpty } from '../../../utils/Helpers';
import LinkCNO from '../../../library/LinkCNO';
import ResponsiveLinesCNO from '../../../library/ResponsiveLinesCNO';
import Icon from '@mdi/react';
import { mdiCog, mdiFormSelect, mdiTools } from '@mdi/js';
import { FormBuilderMenu } from '../../../features/formBuilder';

const SettingsDrawerMenu = () => {

  const isUserAdmin = RightsManager.isAdmin();

  const theme = useTheme();
  const styles = useStyles();

  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      {
        <TooltipCNO title="Settings" arrow placement="right">
          <TooltipCNO title="Settings" arrow placement="right">
            <ListItem button className={styles.listItem} onClick={() => setIsVisible(prev => !prev)}>
              <ListItemIcon>
                <Icon path={mdiCog} size='1.5rem' color={theme.colors.DRAWER_ITEM_ICON_COLOR} />
              </ListItemIcon>
              <ListItemText primary='Settings' />
            </ListItem>
          </TooltipCNO>
        </TooltipCNO>
      }

      {/* nested list */}
      <Collapse in={isVisible} className={styles.nestedListContainer}>

        <LinkCNO to={'/settings'}>
          <TooltipCNO title='General' arrow placement="right">
            <ListItem button className={styles.listItem1} onClick={() => setIsVisible(true)} >
              <ListItemIcon>
                <Icon path={mdiTools} size='0.8rem' style={{ marginLeft: '0.4rem' }} color={theme.colors.DRAWER_ITEM_ICON_COLOR} />
              </ListItemIcon>
              <ResponsiveLinesCNO text='General' />
            </ListItem>
          </TooltipCNO>
        </LinkCNO>

        <LinkCNO to={'/forms/builder'}>
          <TooltipCNO title='Form Builder' arrow placement="right">
            <ListItem button className={styles.listItem1} onClick={() => setIsVisible(true)} >
              <ListItemIcon>
                <Icon path={mdiFormSelect} size='0.8rem' style={{ marginLeft: '0.4rem' }} color={theme.colors.DRAWER_ITEM_ICON_COLOR} />
              </ListItemIcon>
              <ResponsiveLinesCNO text='Form Builder' />
            </ListItem>
          </TooltipCNO>
        </LinkCNO>

      </Collapse>
    </>
  )
}

export default SettingsDrawerMenu

const useStyles = makeStyles(theme => ({
  listItem: {
    borderRadius: 5,
    color: theme.colors.DRAWER_ITEM_TEXT_COLOR,
    height: 50
  },
  nestedListContainer: {
    background: lighten(theme.colors.SIDEBAR_BACKGROUND_COLOR, 0.1)
  },
  listItem1: {
    borderRadius: 5,
    color: theme.colors.DRAWER_ITEM_TEXT_COLOR,
    height: 36
  }
}))