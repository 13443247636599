import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { generateThumbnailImage } from "../../../utils/Helpers";
import { getTestCategory } from "../../../utils/Helpers";
import JobTypeIcon from "../../../components/JobTypeIcon";
import { Privacy } from "../../../utils/mappings";
import * as mdi from "@mdi/js";
import Icon from "@mdi/react";
import LinkCNO from "../../../library/LinkCNO";
import { mdiPlayCircleOutline } from "@mdi/js";
import { FileType } from "../../../utils/mappings";
import LocalStorageConstants from "../../../utils/LocalStorageConstants";
import { duration } from "moment";
import TestTypeIcon from "../../../components/TestTypeIcon/TestTypeIcon";
import { marginBottom, marginLeft } from "styled-system";
import TestFormDialog from "../../../components/TestFormDialog";

const TestGridItemCard = ({ post, isJobs }) => {
  const [mediaLinks, setMediaLinks] = useState([]);
  const [postDescription, setPostDescription] = useState("");
  const [moreMediaCount, setMoreMediaCount] = useState(0);
  const [createdBy, setCreatedBy] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [visibility, setVisibility] = useState("");
  const [title, setTitle] = useState("");
  const [gif, setGif] = useState("");
  const [lifeEventIcon, setLifeEventIcon] = useState("");
  const [lifeEventTitle, setLifeEventTitle] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState(null);
  const isTestPath = location.pathname.includes("/test");

  const maxImages = 4;

  const getVisibility = (value) => {
    const privacy = Privacy.entities;
    const matchedItem = privacy.find((item) => item.id === value);
    return matchedItem ? matchedItem.icon : null;
  };

  const handleSelectTest = (testId) => {
    setSelectedTestId(testId);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleSaveForm = (testId, formData) => {
    const storedData = JSON.parse(localStorage.getItem('allTests') || '{}');
    if (!storedData[testId]) {
      storedData[testId] = { users: [], nextId: 1 };
    }
    const testData = storedData[testId];
    formData.id = testData.nextId;
    testData.users.push(formData);
    testData.nextId += 1;

    localStorage.setItem('allTests', JSON.stringify(storedData));
  };


  useEffect(() => {
    const fetchThumbnails = async () => {
      if (post && Object.keys(post).length > 0) {
        const mediaWithThumbnails = await Promise.all(
          post.questions.map(async (item) => {
            if (item.fileType === FileType.Video) {
              const thumbnail = await generateThumbnailImage(item.link);
              return { ...item, thumbnail: thumbnail.base64 };
            }
            return item;
          })
        );

        setMediaLinks(mediaWithThumbnails);
        setPostDescription(post?.description || "ram");
        console.log("postDes", post.description);
        setTitle(post?.title || "");
        if (mediaWithThumbnails.length > maxImages) {
          setMoreMediaCount(mediaWithThumbnails.length - maxImages);
        } else {
          setMoreMediaCount(0);
        }
      }
    };

    fetchThumbnails();
    if (post && post.postType === 1) {
      setCreatedBy(post.createdBy.profilePic);
      setCreatedDate(new Date(post.createdAt));
    } else if (post.categories && post.categories.length > 0) {
     // setCategoryId(getTestCategory(post.categories[0]));
      setCreatedDate(new Date(post.startDate));
    }

    if (post && post.visibility) {
      setVisibility(getVisibility(post.visibility));
    }

    if (post?.gifs) {
      setGif(post?.gifs[0]?.gif);
    }

    if (post?.lifeEventTitle) {
      setLifeEventIcon(post.lifeEventIcon);
      setLifeEventTitle(post.lifeEventTitle);
    }
  }, [post]);

  const styles = useStyles();
  const displayImages = mediaLinks.slice(0, maxImages);

  return (

   //<LinkCNO to={`${`/testq/${post.testId}`}`}>
   <>
      <div className={styles.gridCardContainer}>
        <div
          className={`${styles.collageContainer} ${
            styles[`collage${displayImages.length}`]
          }`}
        >
          { displayImages.length > 0 ? (
            displayImages.map((item, index) => (
              <div
                key={index}
                className={styles.collageItem}
                style={{
                  backgroundImage:
                    item.fileType && item.fileType === FileType.Video
                      ? `url(${item.thumbnail})`
                      : item.fileType === FileType.Gif
                      ? `url(${item.gif})`
                      : `url(${item.link})`,
                }}
              >
                {item.fileType && item.fileType === 4 && (
                  <div className={styles.overlay}>
                    <Icon path={mdiPlayCircleOutline} size={3} />
                  </div>
                )}
                {/* {index === maxImages - 1 && moreMediaCount > 0 && (
                  <div className={styles.overlay}>+{moreMediaCount}</div>
                )} */}
              </div>
            ))
          ) : gif ? (
            <div className={styles.gifItem}>
              <img src={gif} alt="gif" />
            </div>
          ) : lifeEventTitle ? (
            <div className={styles.LifeEventCategoryIcon}>
              <Icon path={mdi[lifeEventIcon]} size={1} />
              <p>{`${userName} : ${lifeEventTitle}`}</p>
            </div>
          ) : (
            <div className={styles.descriptionContainer}>
              <Typography className={styles.descriptionText}>
                {"No Media Available"}
              </Typography>
            </div>
          )}
        </div>
        { !isTestPath &&
        <LinkCNO to={`${`/testq/${post.testId}`}`}>
        <div className={styles.cardDescription}>
          {/* {createdBy ? (
            <img
              src={createdBy}
              style={{ height: "60px", width: "60px", borderRadius: "50%" }}
              alt="profile-pic"
            />
          ) : categoryId ? ( */}
          
      <TestTypeIcon  iconLink="bullseye" name="Test" categoryId={post?.categoryId} />
   
          {/* ) : null} */}
          <div>
            <Typography variant="body2" color="text.secondary">
              {title
                ? title
                : postDescription
                ? `${postDescription.slice(0, 50)}...`
                : ""}
            </Typography>

            
            {/* <Typography>
              {postDescription ? postDescription : "description"}
            </Typography> */}
            <Typography>Duration: {post?.duration_minutes} Min</Typography>
            {/* <Typography variant="caption" color="text.secondary">
              {createdDate &&
                `${createdDate.getFullYear()}-${(createdDate.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")}-${createdDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")} at 
              ${createdDate
                .getHours()
                .toString()
                .padStart(2, "0")}:${createdDate
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}:${createdDate
                  .getSeconds()
                  .toString()
                  .padStart(2, "0")}
                `}
              {visibility && <Icon path={mdi[visibility]} size={0.4} />}
            </Typography> */}
          </div>
        </div>
        </LinkCNO> }

        { isTestPath &&
       
        <div className={styles.cardDescription}>
          
      <TestTypeIcon  iconLink="bullseye" name="Test" categoryId={post?.categoryId} />
          <div>
            <Typography variant="body2" color="text.secondary">
              {title
                ? title
                : postDescription
                ? `${postDescription.slice(0, 50)}...`
                : ""}
            </Typography>

            <Typography>Duration: {post?.duration_minutes} Min</Typography>
           
          </div>
        </div>
       }


        { isTestPath && (
           <div>
           <button onClick={() => handleSelectTest(post?.testId)} className={styles.btn}>Start Now</button>   
         </div>  
        )}
      </div>
      <TestFormDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onSave={handleSaveForm}
        testId={selectedTestId}
        />
  </>
  );
};

export default TestGridItemCard;

const useStyles = makeStyles((theme) => ({
  gridCardContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.5)",
    width: "300px",
    height: "370px",
  },
  collageContainer: {
    position: "relative",
    display: "grid",
    width: "100%",
    height: "272px",
    background: `${theme.colors.COLLAGE_CONTAINER_BACKGROUND}`,
    overflow: "hidden",
    borderRadius: "10px",
  },
  collage1: {
    gridTemplateColumns: "1fr",
  },
  collage2: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  collage3: {
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "1fr 1fr",
  },
  collage4: {
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
  },
  collageItem: {
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  gifItem: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "auto",
    height: "auto",
    objectFit: "contain",
  },
  overlay: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: theme.spacing(1),
    fontSize: "1.2rem",
    fontWeight: "bold",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  descriptionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    textAlign: "center",
    height: "100%",
    width: "100%",
  },
  descriptionText: {
    fontSize: "1rem",
  },
  cardDescription: {
    display: "flex",
    gap: "15px",
    padding: "15px",
  },
  profilePicContainer: {
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    overflow: "hidden",
  },
  LifeEventCategoryIcon: {
    height: "70px",
    width: "70px",
    borderRadius: "50%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  btn: {
   
    marginBottom : theme.spacing(2),
    marginLeft : theme.spacing(2),
    padding : "0.5rem 1rem",
    textTransform : "uppercase",
    fontWeight : "500",
    color : "white",
    backgroundColor : "#3F8A02",
    borderRadius : "4px",
    border: "2px solid #fcb103",
    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
    cursor : "pointer",
    '&:hover':{
        backgroundColor : "#E0E0E0",
    },
    
  },
}));
