import React, { useState } from "react";
import { DialogCNO, IconMain } from "../../../library";
import { useDispatch } from "react-redux";
import { apiTaskEdit } from "../../../api";
import { toast } from "react-toastify";
import {
  SET_FORM_BY_ID,
  setFormById,
  updateFormStatus,
} from "../../../actions/formBuilderActions";
import { Button } from "@material-ui/core";
import { Text } from "react-native";

function FormStatusToDraft({ open, onClose, formData }) {
  if (!open) return null;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleUpdate = async () => {
    setLoading(true);

    try {
      const updatedFormData = { ...formData, status: 1 };
      console.log(updatedFormData, "updatedFormData");

      // Dispatching the action with the updated form data as payload
      dispatch(updateFormStatus(updatedFormData));

      setLoading(false);
      onClose();
    } catch (error) {
      console.log("Revert To Draft", error);
      toast.error(
        "There is an issue in Reverting to Draft , please try again!"
      );
    }
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Revert To Draft"
      width="60vw"
      minHeight={1}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button variant="contained" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleUpdate}>
          Revert To Draft
        </Button>
      </div>
    </DialogCNO>
  );
}

export default FormStatusToDraft;
