import { Accordion, AccordionDetails, AccordionSummary, Divider, makeStyles, Typography, useTheme } from '@material-ui/core';
import {
  mdiBullseyeArrow,
  mdiClockEnd,
  mdiClockStart,
  mdiHours24,
  mdiLockClock,
} from '@mdi/js';
import Icon from '@mdi/react';
import React, { Fragment, useState } from 'react';
import ResponsiveLinesCNO from '../../library/ResponsiveLinesCNO/ResponsiveLinesCNO';
import MoreActionCNO from '../../library/MoreActionsCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import Moment from 'react-moment';
import { DATE_FORMAT } from '../../utils/formatDate';
import { RateType } from '../../utils';
import ChipCNO from '../../library/ChipCNO';
import {
  Currency,
  CurrencyIcon,
  JobTargetTypeIcon,
  JobTargetTypeReverse,
  JobTargetTypeUnit,
  QuotationStatus,
  RateTypeReverse
} from '../../utils/mappings';
import { getMaterialIconString } from '../../utils/icons';
import * as icons from '@mdi/js';
import { formatTimeWithTZ, getCategory, parseDisplayNumber } from '../../utils/Helpers';
import JobTypeIcon from '../JobTypeIcon/JobTypeIcon';
import RightsManager from '../../utils/RightsManager';
import {
  EditQuotationItemAssignUsers,
  EditQuotationItemCategory,
  EditQuotationItemCost,
  EditQuotationItemDescription,
  EditQuotationItemTarget,
  EditQuotationItemTimeline,
  EditQuotationItemTitle,
  QuotationItemMoreActions
} from '.';

function QuotationItemDetailsListItem({ quotation, quotationItem }) {

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const isSent = quotation.status === QuotationStatus.Sent;
  const isSentAccepted = quotation.status === QuotationStatus.SentAccepted;

  // quotationItem.categories = ['81724aba-78bf-4cf6-bdd7-0200a520da4d'];
  const jobCategory = getCategory(quotationItem.categories[0]);

  const [expanded, setExpanded] = useState(false);

  const [isCategoryEditable, setIsCategoryEditable] = useState(false);
  const [isTitleEditable, setIsTitleEditable] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  const [isAssignEditable, setIsAssignEditable] = useState(false);
  const [isTimlineEditable, setIsTimlineEditable] = useState(false);
  const [isCostEditable, setIsCostEditable] = useState(false);
  const [isTargetEditable, setIsTargetEditable] = useState(false);

  return (
    <Fragment>
      <Accordion expanded={expanded} className={GlobalStyles.accordion} style={{ background: theme.colors.SUBTASK_CARD_BACKGROUND_COLOR, marginTop: 10 }}>
        <AccordionSummary
          className={GlobalStyles.accordionSummary}
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ width: '100%' }}
          >
            <div style={{ flex: 1, display: 'flex' }}>
              <JobTypeIcon
                iconLink={jobCategory?.iconLink}
                name={jobCategory?.title}
                categoryId={jobCategory.id}
                onClick={() => setIsCategoryEditable(true)}
              />
              <div style={{ flex: 1, marginLeft: 8 }}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>

                  <Typography
                    style={{ color: theme.colors.JOB_DETAIL_TITLE_COLOR, fontSize: '1rem' }}
                    onClick={() => setIsTitleEditable(true)}
                  >
                    {quotationItem.title}
                  </Typography>

                  {
                    isUserAdmin &&
                    <MoreActionCNO>
                      <QuotationItemMoreActions quotationItem={quotationItem} />
                    </MoreActionCNO>
                  }

                </div>

                <ResponsiveLinesCNO
                  text={quotationItem.description || 'Quotation Item details not added'}
                  maxLine={!expanded ? 2 : 1000}
                  style={{ flex: 1, color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR }}
                  onClick={() => setIsDescription(true)}
                  component={'pre'}
                />

              </div>

            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
              {
                !expanded
                  ?
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Fragment>
                      <Moment onClick={() => setIsTimlineEditable(true)} format={"MMM DD, YYYY"} style={{ color: theme.colors.LIST_CARD_USERNAMES_COLOR }}>
                        {formatTimeWithTZ(quotationItem.startDate)}
                      </Moment>
                    </Fragment>
                    <Typography style={{ marginLeft: 3, marginRight: 3 }}>|</Typography>
                    <Typography onClick={() => setIsCostEditable(true)} style={{ display: 'flex', alignItems: 'center', color: theme.colors.LIST_CARD_USERNAMES_COLOR }}>
                      <Icon
                        path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
                        size="1rem"
                        color={theme.colors.JOB_DETAIL_DESCRIPTION_COLOR}
                      />
                      {parseDisplayNumber(quotationItem.amount)}
                    </Typography>

                  </div>
                  : null
              }
              <pre
                style={{ color: theme.colors.LINK_COLOR, textDecoration: 'underline', width: expanded ? '100%' : 'auto', textAlign: 'right' }}
                onClick={() => expanded ? setExpanded(null) : setExpanded('1')}
              >
                {expanded ? 'Less Details' : 'More Details'}
              </pre>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={GlobalStyles.quotationItemMoreWrapper} style={{ width: '100%' }}>

            {
              quotationItem.rateType === RateType.Hourly &&
              <Fragment>
                <div
                  className={styles.row}
                  onClick={() => setIsTimlineEditable(true)}
                  style={{ paddingTop: 8, paddingBottom: 8 }}
                >
                  <Typography style={{ color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR, minWidth: 100, alignSelf: 'flex-start' }}>
                    Timeline
                  </Typography>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <div className={GlobalStyles.detailsRow}>
                      <Typography style={{ color: theme.colors.JOB_START_DATE_COLOR }}>
                        <Moment format={DATE_FORMAT}  >
                          {formatTimeWithTZ(quotationItem.startDate)}
                        </Moment>
                      </Typography>
                      <Icon
                        path={mdiClockStart}
                        size='1.2rem'
                        color={theme.colors.JOB_START_DATE_COLOR}
                      />
                    </div>
                    {
                      isUserAdmin &&
                      <Fragment>
                        <span>-</span>
                        <div className={GlobalStyles.detailsRow}>
                          <Typography style={{ color: theme.colors.JOB_END_DATE_COLOR }}>
                            <Moment format={DATE_FORMAT} >
                              {formatTimeWithTZ(quotationItem.endDate)}
                            </Moment>
                          </Typography>
                          <Icon
                            path={mdiClockEnd}
                            size='1.2rem'
                            color={theme.colors.JOB_END_DATE_COLOR}
                          />
                        </div>
                      </Fragment>
                    }
                  </div>
                </div>

                <Divider />

              </Fragment>
            }

            <div
              className={styles.row}
              onClick={() => setIsCostEditable(true)}
            >
              <Typography style={{ color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR, minWidth: 100 }}>
                Cost
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                <Typography style={{ display: 'flex', alignItems: 'center', color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR }}>
                  <Icon
                    path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
                    size="1rem"
                    color={theme.colors.JOB_DETAIL_DESCRIPTION_COLOR}
                  />
                  {parseDisplayNumber(quotationItem.rate)}
                </Typography>
                {
                  quotationItem.rateType ?
                    <ChipCNO
                      icon={quotationItem.rateType === RateType.Hourly ? mdiHours24 : mdiLockClock}
                      name={RateTypeReverse[quotationItem.rateType]}
                      active={true}
                    /> : null
                }
              </div>
            </div>

            <Divider />

            <div className={styles.row}>
              <Typography style={{ color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR, minWidth: 100 }}>
                Amount
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                <Typography style={{ display: 'flex', alignItems: 'center', color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR }}>
                  <Icon
                    path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
                    size="1rem"
                    color={theme.colors.JOB_DETAIL_DESCRIPTION_COLOR}
                  />
                  {parseDisplayNumber(quotationItem.amount)}
                </Typography>
              </div>
            </div>

            <Divider />

            <div
              className={styles.row}
              onClick={() => setIsTargetEditable(true)}
            >
              <Typography style={{ color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR, minWidth: 100 }}>
                Target
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon
                    path={mdiBullseyeArrow}
                    size="1.2rem"
                    color={theme.colors.JOB_DETAIL_RATE_COLOR}
                    style={{ marginRight: theme.spacing(1) }}
                  />
                  <Typography style={{ color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR }}>
                    {`${quotationItem.target} ${JobTargetTypeUnit[quotationItem.targetType]}`}
                  </Typography>
                </div>
                {
                  quotationItem.targetType ?
                    <ChipCNO
                      icon={icons[getMaterialIconString(JobTargetTypeIcon[JobTargetTypeReverse[quotationItem.targetType]])]}
                      name={JobTargetTypeReverse[quotationItem.targetType]}
                      active={true}
                    /> : null
                }
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      {
        isCategoryEditable &&
        isUserAdmin &&
        <EditQuotationItemCategory
          open={isCategoryEditable}
          onClose={() => setIsCategoryEditable(false)}
          quotationItem={quotationItem}
        />
      }

      {
        isTitleEditable &&
        isUserAdmin &&
        <EditQuotationItemTitle
          open={isTitleEditable}
          onClose={() => setIsTitleEditable(false)}
          quotationItem={quotationItem}
        />
      }

      {
        isDescription &&
        isUserAdmin &&
        <EditQuotationItemDescription
          open={isDescription}
          onClose={() => setIsDescription(false)}
          quotationItem={quotationItem}
        />
      }

      {
        isAssignEditable &&
        isUserAdmin &&
        <EditQuotationItemAssignUsers
          open={isAssignEditable}
          onClose={() => setIsAssignEditable(false)}
          quotationItem={quotationItem}
        />
      }

      {
        isTimlineEditable &&
        isUserAdmin &&
        <EditQuotationItemTimeline
          open={isTimlineEditable}
          onClose={() => setIsTimlineEditable(false)}
          quotationItem={quotationItem}
        />
      }

      {
        isCostEditable &&
        isUserAdmin &&
        <EditQuotationItemCost
          open={isCostEditable}
          onClose={() => setIsCostEditable(false)}
          quotationItem={quotationItem}
        />
      }

      {
        isTargetEditable &&
        isUserAdmin &&
        <EditQuotationItemTarget
          open={isTargetEditable}
          onClose={() => setIsTargetEditable(false)}
          quotationItem={quotationItem}
        />
      }
    </Fragment>

  )
}

export default QuotationItemDetailsListItem;

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 4,
    paddingBottom: 4
  }
}))
