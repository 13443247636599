import {
  FETCH_APPOINTMENT_SUGGEST,
  FETCH_CUSTOMER_COMPANY_SUGGEST,
  FETCH_CUSTOMER_SUGGEST,
  FETCH_INVOICE_SUGGEST,
  FETCH_MEMBER_SUGGEST,
  FETCH_QUOTATION_SUGGEST,
  FETCH_TASK_SUGGEST,
  RESET_SUGGESTIONS
} from "../actions/suggestionsActions";

const initialState = [];

export default function suggestionsReducer(state = initialState, { type, payload }) {
  
  switch (type) {
    case RESET_SUGGESTIONS:
      state = [];
      return state;

    case FETCH_TASK_SUGGEST:
      state = [
        ...payload.data.tasks
      ];
      return state;

    case FETCH_MEMBER_SUGGEST:
      state = payload;
      return state;

    case FETCH_CUSTOMER_SUGGEST:
      state = payload;
      return state;

    case FETCH_CUSTOMER_COMPANY_SUGGEST:
      state = [
        ...payload.data.data
      ];
      return state;

    case FETCH_INVOICE_SUGGEST:
      state = payload;
      return state;

    case FETCH_QUOTATION_SUGGEST:
      state = payload;
      return state;

    case FETCH_APPOINTMENT_SUGGEST:
      state = [
        ...payload.data.appointments
      ];
      return state;

    default:
      return state;
  }
}