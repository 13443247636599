import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import NameLogo from "../../assets/nameLogo.png";
import NameLogoBlack from "../../assets/nameLogoBlack.png";

function Brand() {
  const theme = useTheme();

  const fromTablet = useMediaQuery("(min-width: 800px)");
  const mobileSmall = useMediaQuery("(max-width: 425px)");

  const styles = useStyles({ fromTablet, mobileSmall });
  return (
    <div className={styles.container}>
      {/* <img src={AppLogo} alt="" className={styles.appLogo}/> */}
      {theme.palette.type !== "dark" ? (
        <img src={NameLogoBlack} alt="" className={styles.nameLogo} />
      ) : (
        <img src={NameLogo} alt="" className={styles.nameLogo} />
      )}
    </div>
  );
}

export default Brand;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    // justifyContent: ({fromTablet})=>!fromTablet ? 'center' : 'flex-start',
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: 14,
    marginRight: 14,
    // flex: 1
  },
  appLogo: {
    width: "50px",
    objectFit: "contain",
  },
  nameLogo: {
    width: ({ fromTablet, mobileSmall }) =>
      fromTablet ? "120px" : mobileSmall ? "100%" : "120px",
  },
}));
