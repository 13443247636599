import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { mdiHours24, mdiLockClock } from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import { Typography } from '@material-ui/core';
import ChipCNO from '../../library/ChipCNO';
import { RateType } from '../../utils';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuotationItem } from '../../actions/quotationItemActions';
import { Currency, CurrencyIcon } from '../../utils/mappings';
import * as icons from '@mdi/js';
import { getMaterialIconString } from '../../utils/icons';
import { AppConstants } from '../../utils/AppConstants';

function EditQuotationItemCost({
  open,
  onClose,
  quotationItem,
}) {

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { editLoader } = useSelector(state => state.quotationItems);

  const formik = useFormik({
    initialValues: {
      rate: quotationItem.rate,
      rateType: quotationItem.rateType,
    },
    validationSchema: yup.object({
      rate: yup.string().required('Rate is required').test('NaN', 'Please enter a valid rate.', value => !isNaN(value)),
      rateType: yup.number().required('Quotation Item Type is required'),
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("Edit Quotation Item: cost: ", values);

    const payload = {
      ...quotationItem,
      rate: values.rate ? values.rate : 0,
      rateType: values.rateType,
      ...(
        values.rateType === RateType.Hourly ? {
          startDate: AppConstants.DEFAULT_START_DATE,
          endDate: AppConstants.DEFAULT_END_DATE,
          startTime: AppConstants.DEFAULT_START_TIME,
          endTime: AppConstants.DEFAULT_END_TIME,
        } : {}
      )
    };

    dispatch(updateQuotationItem(payload));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Quotation Item Cost"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <div style={{ marginBottom: 8 }}>
          <Typography>Quotation Item Types</Typography>
          <div className={GlobalStyles.chipsWrapper}>
            <ChipCNO
              icon={mdiLockClock}
              name="Fixed"
              size="1.5rem"
              active={formik.values.rateType === RateType.Fixed}
              onClick={() => formik.setFieldValue('rateType', RateType.Fixed)}
            />
            <ChipCNO
              icon={mdiHours24}
              name="Hourly"
              size="1.5rem"
              active={formik.values.rateType === RateType.Hourly}
              onClick={() => formik.setFieldValue('rateType', RateType.Hourly)}
            />
          </div>
        </div>
        <InputCNO
          name='rate'
          label='Quotation Item Rate'
          formik={formik}
          placeholder='Enter Quotation Item Rate'
          icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
          fullWidth
          onChange={(e) => {

            const value = e.target.value;

            const rateValue = !isNaN(value) ? value : formik.values.rate;

            formik.setFieldValue('rate', value === "" ? "" : rateValue);

          }}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditQuotationItemCost
