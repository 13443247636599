import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_ChatMessagesHeader: {
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 4px',

    position: ({ tillTablet }) => tillTablet ? "fixed" : 'relative',
    // margin: ({ tillTablet }) => tillTablet ? "0 auto",
    top: 0,
    left: 0,
    right: 0,
    height: 50,
    background: theme.colors.HEADER_BACKGROUND_COLOR,
    color: theme.colors.HEADER_TITLE_COLOR,
    zIndex: 1300,
    borderBottom: `1px solid ${theme.colors.BORDER_COLOR}`,
  },
  activeUserWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    marginLeft: theme.spacing(2)
  },
  activeUsername: {
    marginLeft: theme.spacing(2),
    color: theme.colors.HEADER_TITLE_COLOR,
  },
  addUserBtn: {
    background: "#3F8A02",
    color: 'white'
  },
  container_MorePopover: {
    padding: 5,
    overflow: 'hidden',
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR
  },
  list: {
    background: theme.colors.MORE_ACTIONS_OPTIONS_WRAPPER_BACKGROUND_COLOR,
    padding: 0,
  },
  listItem: {
    background: theme.colors.MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR,
    paddingTop: 10,
    paddingBottom: 10,
    width: 300,
    marginTop: 4,
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.01)'
    },
  },
  listItemText: {
    textDecoration: 'none',
  },
}));