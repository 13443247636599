import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ScreenView from '../../components/ScreenView';
import UserProfile from '../../components/UserProfile/UserProfile';
import { getEmployeeById, setEmployeeById } from '../../actions/employeeActions';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { ProfileMode } from '../../utils';

function EmployeeDetailsPage() {

  const styles = useStyles();

  const dispatch = useDispatch();
  const { employeeById, allEmployeesLoader, employees } = useSelector(state => state.employees);
  const { employeeDetailLoader, editLoader } = allEmployeesLoader;

  const { id } = useParams();

  useEffect(() => {

    const employeeId = [id];

    const employee = employees.find(employee => employee.id === id);

    dispatch(getEmployeeById(employeeId, false, employee));

  }, [id]);

  console.log("employeeDetailLoader: ", employeeDetailLoader);

  return (
    <div className={styles.container_EmployeeDetailsPage}>
      {
        (
          employeeDetailLoader ||
          editLoader
        ) &&
        <FullScreenLoaderCNO />
      }
      <ScreenView
        content={
          employeeById &&
          <UserProfile
            mode={ProfileMode.Employee}
            profile={employeeById}
            isActiveMember={employeeById.isActive}
          />
        }
        headerTitle="Employee Detail"
      />
    </div>
  )
}

export default EmployeeDetailsPage;

const useStyles = makeStyles((theme) => ({
  container_EmployeeDetailsPage: {
    background: theme.colors.APP_BACKGROUND_COLOR,
  }
}))