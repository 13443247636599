import { Button, useTheme } from "@material-ui/core";
import React, { Fragment, useState, useEffect } from "react";
import ImgsViewer from "react-images-viewer";
import FullScreenLoaderCNO from "../FullScreenLoaderCNO";
import Lightbox from "../MediaViewerCNO/LightBox";
import ScreenView from "../../components/ScreenView";
import FsViewer from "./FsViewer";
function ImagesViewerSliderCNO({
  isOpen,
  onClose,
  images,
  currentImageIndex,
  onChangeImageIndex,
  isMediaLoading = false,
  // setLightboxController,
  // lightboxController,
}) {
  const theme = useTheme();

  const [activeImageIndex, setActiveImageIndex] = useState(currentImageIndex);

  useEffect(() => {
    setActiveImageIndex(currentImageIndex);
  }, [currentImageIndex]);

  const _header = () => {
    const media = images[activeImageIndex];

    console.log("======_header====media====", media);

    if ((media && media.isImage) || isMediaLoading) return <div></div>;

    return (
      <div
        style={{
          color: "white",
          alignSelf: "center",
          flex: 1,
          display: "flex",
        }}
      >
        <Button
          style={{
            background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
            color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
            borderColor: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
            fontSize: "0.7rem",
            fontWeight: 700,
          }}
          disabled={isMediaLoading}
          onClick={async () => {
            if (isMediaLoading) return;

            window.open(media.mediaOpenLink, "_blank");
          }}
        >
          {isMediaLoading ? "Loading..." : "Open"}
        </Button>
      </div>
    );
  };
  console.log("mediDocumentLinks", images);
  // console.log("lightboxController",lightboxController);
  console.log("isMediaLoading",isMediaLoading)
  return (
    <Fragment>
      {isMediaLoading && <FullScreenLoaderCNO zIndex={2010} />}

      {/* <FsViewer
        images={images.map((image) => image.src)}
        lightboxController={lightboxController}
        setLightboxController={setLightboxController}
      /> */}

      <Lightbox
        images={images}
        currImg={activeImageIndex}
        isOpen={isOpen}
        onClose={onClose}
        onClickPrev={() => setActiveImageIndex(index => {

          const nextIndex = index - 1;

          onChangeImageIndex(nextIndex);

          return nextIndex;
        })}
        onClickNext={() => setActiveImageIndex(index => {

          const nextIndex = index + 1;

          onChangeImageIndex(nextIndex);

          return nextIndex;

        })}
        // onClickThumbnail={(index) => {

        //   setActiveImageIndex(index);
        //   onChangeImageIndex(index);

        // }}
        // showThumbnails
        // customControls={[_header()]}
        // theme={{
        //   figure: {
        //     minWidth: 300,
        //     minHeight: 300,
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center'
        //   }
        // }}
      >
         
      </Lightbox>
    </Fragment>
  );
}

export default ImagesViewerSliderCNO;
