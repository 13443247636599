import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container_AddEmployee: {
    margin: '0 auto',
  },
  paper: {
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    borderRadius: 10,
    width: '70vw',
    maxWidth: '90vh',
    margin: ({ tillTablet }) => tillTablet ? 8 : 32
  },
  form: {
    padding: '20px',
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    '&>*': {
      marginBottom: theme.spacing(2)
    },
  },
  label: {
    color: theme.colors.FORM_LABEL_COLOR
  },
  chipsWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    '&>*': {
      marginRight: 10
    }
  },
  selectChips: {
    '&>*:first-child': {
      color: theme.colors.FORM_LABEL_COLOR
    }
  },
  selectWithAdd: {
    display: 'flex',
    alignItems: 'flex-end',
    '&>*:first-child': {
      flex: 1,
      color: theme.colors.FORM_LABEL_COLOR
    }
  },
  mobile: {
    '& input': {
      padding: '10px 5px',
      border: 'none',
      borderRadius: '5px'
    }
  },
  picker: {
    '& .MuiInputBase-root': {
      background: 'white',
      color: '#444'
    },
    '& .MuiIconButton-label': {
      color: '#444'
    }
  }
}));