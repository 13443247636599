import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { scroller } from 'react-scroll';
import TrialPeriodSubscriptionModal from '../components/TrialPeriodSubscriptionModal';
import SubscriptionExpiredDialog from '../components/SubscriptionExpiredDialog';
import SubscriptionNotPresentMemeber from '../components/SubscriptionNotPresentMemeber';

const RouteChangeTracker = ({ history }) => {

  const isLandingPagePath = history.location.pathname === "/";
  const isPrivacyPagePath = history.location.pathname === "/privacy";
  const isTermsPagePath = history.location.pathname === "/terms";
  const isEulaPagePath = history.location.pathname === "/eula";
  const isDisclaimerPagePath = history.location.pathname === "/disclaimer";
  const isCookiePagePath = history.location.pathname === "/cookie";
  const isAboutUsPath = history.location.pathname === "/aboutus";
  const isContactUsPath = history.location.pathname === "/contactus";
  const isHelpPath = history.location.pathname === "/help";

  const isPublicPagePath = !(isLandingPagePath || isPrivacyPagePath || isTermsPagePath || isEulaPagePath || isDisclaimerPagePath || isCookiePagePath || isAboutUsPath || isContactUsPath || isHelpPath);

  console.log("====kkkkkkkkk=====mmmmmmmmmmmmmmmmmm");

  history.listen((location, action) => {

    const hash = history.location.hash.slice(1);
    console.log("===========usescrollto===history====", hash);
    scroller.scrollTo(hash);

    console.log("====kkkkkkkkkk=====nnnnnnnnnnnnnnn");

    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  return (
    <div>
      {
        isPublicPagePath &&
        <Fragment>
          {/* <TrialPeriodSubscriptionModal /> */}
          <SubscriptionExpiredDialog />
          <SubscriptionNotPresentMemeber />
        </Fragment>
      }

    </div>
  );
};

export default withRouter(RouteChangeTracker);