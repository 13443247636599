import { Typography, useTheme } from "@material-ui/core";
import React, { Children, Fragment, useEffect, useRef, useState } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useStyles } from "./CalendarCNOStyles";
import "react-big-calendar/lib/css/react-big-calendar.css";
import reactDom from "react-dom";
import { Overlay, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CloseIcon from "../CloseIcon";
import ResponsiveLinesCNO from "../ResponsiveLinesCNO";
import CalendarAddModal from "../../components/Calendar/CalendarAddModal";
import {
  fetchEventsList,
  setAddModalsCalendarDates,
  setApiCalendarDatesFilter,
} from "../../actions/calendarActions";
import FullScreenLoaderCNO from "../FullScreenLoaderCNO";
import {
  CalendarFilter,
  HolidayType,
  LeaveStatus,
  LeaveType,
} from "../../utils/mappings";
import {
  AddAppointment,
  AddHoliday,
  AddLeave,
} from "../../components/Appointment";
import {
  handleAppointmentSearch,
  handleHolidaySearch,
  handleLeaveSearch,
} from "../../actions/appointmentActions";
import { resetJobSearch, setJobSearchManager } from "../../actions/jobActions";
import CustomToolbar from "./CustomToolbar";
import JobTypeIcon from "../../components/JobTypeIcon/JobTypeIcon";
import {
  formatTimeWithTZ,
  getCategory,
  getPrefillAssignedUser,
  getPrefillCustomer,
} from "../../utils/Helpers";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import LocalStorageConstants from "../../utils/LocalStorageConstants";
import RightsManager from "../../utils/RightsManager";
import Moment from "react-moment";
import { calendarStrings, TIME_FORMAT } from "../../utils/formatDate";
import { mdiDirections } from "@mdi/js";
import Icon from "@mdi/react";
import ConfirmationDialogCNO from "../ConfirmationDialogCNO";
import ShowMorePopup from "./ShowMorePopup";
import clsx from "clsx";
import LeaveTypeIcon from "../../components/LeaveTypeIcon";
import HolidayTypeIcon from "../../components/HolidayTypeIcon";

function CalendarCNO({ isCalendarAddDialog, setIsCalendarAddDialog }) {
  const isUserAdmin = RightsManager.isAdmin();

  const history = useHistory();

  const dispatch = useDispatch();

  const { eventsList, calendarFilter, eventsLoader, apiCalendarDatesFilter } =
    useSelector((state) => state.calendar);

  const {
    appointments,
    appointmentsLoader,
    isAddAppointmentDialog,
    appointmentSearchParams,
    hasMoreAppointments,
    isAddLeaveDialog,
    isAddHolidayDialog,
    leaveSearchParams,
    holidaySearchParams,
  } = useSelector((state) => state.appointments);

  const { jobs, todosLoader, jobSearchParams } = useSelector(
    (state) => state.jobs
  );

  const [calendarView, setCalendarView] = useState(Views.DAY);
  const [dateRange, setDateRange] = useState({});

  const isAppointment = calendarFilter === CalendarFilter.Appointments;
  const isLeave = calendarFilter === CalendarFilter.Leaves;
  const isHoliday = calendarFilter === CalendarFilter.Holidays;

  moment.locale("en-gb", {
    week: {
      dow: 1,
      doy: 1,
    },
  });

  // to reset job search sidebar when leaving calendar
  useEffect(() => {
    return () => {
      dispatch(
        handleAppointmentSearch({
          customer: null,
          categories: [],
          appointment: null,
          startDate: null,
          endDate: null,
          assignments: null,
        })
      );

      dispatch(resetJobSearch());
    };
  }, []);

  useEffect(() => {
    if (
      !appointments.length &&
      calendarFilter === CalendarFilter.Appointments
    ) {
      const payload = {
        ...appointmentSearchParams,
        ...apiCalendarDatesFilter,
        pageSize: 500,
        customer: appointmentSearchParams.customer
          ? appointmentSearchParams.customer
          : getPrefillCustomer(),
        assignments: appointmentSearchParams.assignments
          ? appointmentSearchParams.assignments
          : getPrefillAssignedUser(),
      };

      console.log(
        "======calendarFilter======useEffect first===payload",
        payload
      );
      dispatch(handleAppointmentSearch(payload));
    }
  }, []);

  useEffect(() => {
    switch (calendarFilter) {
      case CalendarFilter.Appointments:
        dispatch(fetchEventsList(appointments));
        break;

      case CalendarFilter.Jobs:
        dispatch(fetchEventsList(jobs));
        break;

      case CalendarFilter.Leaves:
        dispatch(fetchEventsList(appointments));
        break;

      case CalendarFilter.Holidays:
        dispatch(fetchEventsList(appointments));
        break;

      default:
        break;
    }
  }, [appointments, jobs, calendarFilter]);

  useEffect(() => {
    let apiDateRangesPayload = apiCalendarDatesFilter;

    switch (calendarView) {
      case Views.MONTH:
        apiDateRangesPayload = {
          startDate: dateRange.start,
          startTime: dateRange.start,
          endDate: dateRange.end,
          endTime: dateRange.end,
        };
        break;

      case Views.WEEK:
      case Views.WORK_WEEK:
        apiDateRangesPayload = {
          startDate: dateRange[0],
          startTime: dateRange[0],
          endDate: moment(dateRange[dateRange.length - 1]).endOf("D"),
          endTime: moment(dateRange[dateRange.length - 1]).endOf("D"),
        };
        break;

      case Views.DAY:
        apiDateRangesPayload = {
          startDate: moment(dateRange[dateRange.length - 1]).startOf("D"),
          startTime: moment(dateRange[dateRange.length - 1]).startOf("D"),
          endDate: moment(dateRange[dateRange.length - 1]).endOf("D"),
          endTime: moment(dateRange[dateRange.length - 1]).endOf("D"),
        };
        break;

      case Views.AGENDA:
        apiDateRangesPayload = {
          startDate: dateRange.start,
          startTime: dateRange.start,
          endDate: dateRange.end,
          endTime: dateRange.end,
        };
        break;

      default:
        break;
    }

    dispatch(setApiCalendarDatesFilter(apiDateRangesPayload));
  }, [calendarView, dateRange]);

  useEffect(() => {
    let payload = {};

    console.log(
      "====calendarFilter====useEffect=====apiCalendarDatesFilter",
      apiCalendarDatesFilter
    );
    console.log(
      "====calendarFilter====useEffect=====apiCalendarDatesFilter====calendarFilter",
      calendarFilter
    );

    switch (calendarFilter) {
      case CalendarFilter.Appointments:
        payload = {
          ...appointmentSearchParams,
          ...apiCalendarDatesFilter,
          pageSize: 500,
          customer: appointmentSearchParams.customer
            ? appointmentSearchParams.customer
            : getPrefillCustomer(),
          assignments: appointmentSearchParams.assignments
            ? appointmentSearchParams.assignments
            : getPrefillAssignedUser(),
        };
        console.log(
          "====calendarFilter====useEffect=====apiCalendarDatesFilter====Appointmentspayload",
          payload
        );
        dispatch(handleAppointmentSearch(payload));
        break;

      case CalendarFilter.Jobs:
        payload = {
          ...jobSearchParams,
          ...apiCalendarDatesFilter,
          pageSize: 500,
          assignedUsers: jobSearchParams.assignedUsers
            ? jobSearchParams.assignedUsers
            : getPrefillAssignedUser(),
        };
        console.log(
          "====calendarFilter====useEffect=====apiCalendarDatesFilter====Appointmentspayload",
          payload
        );
        dispatch(setJobSearchManager(payload));

        break;

      case CalendarFilter.Leaves:
        payload = {
          ...leaveSearchParams,
          ...apiCalendarDatesFilter,
          pageSize: 500,
        };
        console.log(
          "====calendarFilter====useEffect=====apiCalendarDatesFilter====LeavesPayload",
          payload
        );
        dispatch(handleLeaveSearch(payload));

        break;

      case CalendarFilter.Holidays:
        payload = {
          ...holidaySearchParams,
          ...apiCalendarDatesFilter,
          pageSize: 500,
        };
        console.log(
          "====calendarFilter====useEffect=====apiCalendarDatesFilter====HolidaysPayload",
          payload
        );
        dispatch(handleHolidaySearch(payload));

        break;

      default:
        break;
    }
  }, [apiCalendarDatesFilter]);

  const theme = useTheme();
  const styles = useStyles({});

  const localizer = momentLocalizer(moment);

  let allViews = Object.keys(Views).map((k) => Views[k]);

  const ColoredDateCellWrapper = ({ children, value }) =>
    React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor:
          value < moment()
            ? theme.colors.CALENDAR_EVENT_ACTIVE_BACKGROUND_COLOR
            : theme.colors.CALENDAR_EVENT_INACTIVE_BACKGROUND_COLOR,
      },
    });

  const ColoredTimeCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
      style: {
        backgroundColor:
          theme.colors.CALENDAR_TIME_CELL_WRAPPER_BACKGROUND_COLOR,
      },
    });

  const TooltipContent = ({ onClose, event }) => {
    const [isMapConfirmModal, setIsMapConfirmModal] = useState(false);

    const item = event.event;

    const GlobalStyles = useGlobalStyles();
    // console.log("isLeaaveemploye",item.employee)
    const category = getCategory(item.categories[0]);
    return (
      <div
        className={clsx(
          GlobalStyles.listCardContainer,
          GlobalStyles.listCardFW
        )}
        style={{
          border: `1px solid ${theme.colors.LIST_CARD_TITLE_COLOR}`,
          maxWidth: 400,
          zIndex: 1300,
        }}
      >
        <div className={GlobalStyles.cardInfoWrapper}>
          {isLeave ? (
            <LeaveTypeIcon />
          ) : isHoliday ? (
            <HolidayTypeIcon />
          ) : (
            <JobTypeIcon
              iconLink={category?.iconLink}
              name={category?.title}
              categoryId={item.categories[0]}
            />
          )}
          <div className={GlobalStyles.cardInfo}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ResponsiveLinesCNO
                text={
                  isLeave
                    ? LeaveType.getItem(item.leaveType).label
                    : isHoliday
                    ? item.holidayName
                    : item.title
                }
                maxLine={1}
                className={GlobalStyles.cardTitle}
              />

              <div style={{ display: "flex", alignItems: "center" }}>
                {item.mapAddress && (
                  <Icon
                    className={GlobalStyles.moreIcon}
                    path={mdiDirections}
                    size="1.5rem"
                    color={"grey"}
                    onClick={() => setIsMapConfirmModal(true)}
                  />
                )}
                <CloseIcon
                  size="1.1rem"
                  onClick={onClose}
                  style={{ marginLeft: 16 }}
                />
              </div>
            </div>
            {isHoliday && (
              <ResponsiveLinesCNO
                text={HolidayType.getItem(item.holidayType).label}
                maxLine={3}
                style={{ fontSize: 12 }}
              />
            )}
            {!isAppointment && (
              <ResponsiveLinesCNO
                text={item.description}
                maxLine={3}
                style={{ fontSize: 12 }}
              />
            )}
            
            {isLeave && (
              <Typography
                style={{ marginTop: 4 }}
                className={GlobalStyles.cardInfoRowText}
              >
                Leave Status :
                {item.status == LeaveStatus.PENDING ? (
                  <span className={styles.warning}>{" Applied"}</span>
                ) : (
                  <span className={styles.success}>{" Approved"}</span>
                )}
              </Typography>
            )}

            {isLeave && (
              <Typography
                style={{ marginTop: 4 }}
                className={GlobalStyles.cardInfoRowText}
              >
                Employee Name :
                {` ${item.employee.firstName} ${item.employee.lastName}`}
              </Typography>
            )}

            {!isAppointment && !isLeave && (
              <Typography className={styles.date}>
                <Moment interval={0} calendar={calendarStrings}>
                  {formatTimeWithTZ(item.startDate)}
                </Moment>
                <Moment interval={0} format={TIME_FORMAT}>
                  {formatTimeWithTZ(item.startTime)}
                </Moment>
              </Typography>
            )}

            {(isAppointment || isLeave) && (
              <Fragment>
                <div className={GlobalStyles.datesWrapper}>
                  <Typography className={GlobalStyles.cardDate}>
                    <Fragment>
                      <span>From&nbsp;</span>
                      <Moment interval={0} calendar={calendarStrings}>
                        {formatTimeWithTZ(item.startDate)}
                      </Moment>
                      &nbsp;
                      <Moment interval={0} format={TIME_FORMAT}>
                        {formatTimeWithTZ(item.startTime)}
                      </Moment>
                      &nbsp;
                    </Fragment>
                  </Typography>
                </div>

                <div className={GlobalStyles.datesWrapper}>
                  <Typography className={GlobalStyles.cardDate}>
                    <Fragment>
                      <span>To&nbsp;</span>
                      <Moment interval={0} calendar={calendarStrings}>
                        {formatTimeWithTZ(item.endDate)}
                      </Moment>
                      &nbsp;
                      <Moment interval={0} format={TIME_FORMAT}>
                        {formatTimeWithTZ(item.endTime)}
                      </Moment>
                    </Fragment>
                  </Typography>
                </div>
              </Fragment>
            )}

            {item.mapAddress && (
              <Typography
                style={{ marginTop: 4 }}
                className={GlobalStyles.cardInfoRowText}
              >
                Location: {item.mapAddress}
              </Typography>
            )}

            {!isHoliday && (
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  onClick={() => {
                    switch (calendarFilter) {
                      case CalendarFilter.Appointments:
                        history.push(`appointment/${item.id}`);
                        break;
                      case CalendarFilter.Jobs:
                        history.push(`job/${item.id}`);
                        break;
                      case CalendarFilter.Leaves:
                        history.push(`appointment/${item.id}`);
                        break;
                      // case CalendarFilter.Holidays:
                      //   history.push(`hoiday/${item.id}`);
                      //   break;
                      default:
                        break;
                    }
                  }}
                  style={{
                    marginRight: 12,
                    padding: "4px 8px",
                    borderRadius: 4,
                    cursor: "pointer",
                  }}
                  className={styles.loadMoreFromServerLink}
                >
                  Details
                </div>

                {isMapConfirmModal && (
                  <ConfirmationDialogCNO
                    open={isMapConfirmModal}
                    onClose={() => setIsMapConfirmModal(false)}
                    dialogTitle="Navigate"
                    messages={["Do you want to navigate to location?"]}
                    yesClick={() => {
                      window.open(
                        `https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${item.mapAddress}`,
                        "_blank"
                      );
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  function EventPopover(event) {
    console.log("EventValue", event);
    const [showTooltip, setShowTooltip] = useState(false);

    const closeTooltip = () => {
      setShowTooltip(false);
    };

    const openTooltip = () => {
      setShowTooltip(true);
    };
    const ref = useRef(null);

    const getTarget = () => {
      return reactDom.findDOMNode(ref.current);
    };

    const location =
      event.event && event.event.mapAddress ? event.event.mapAddress : null;

    let title = `${event.title}  ${location ? ` | ${location}` : null}`;
    // isLeave ? LeaveType.getItem(event.event.leaveType).label : isHoliday ? HolidayType.getItem(event.event.holidayType).label :
    if (event.event?.leaveType) {
      title = LeaveType.getItem(event.event.leaveType).label;
    } else if (event.event?.holidayName) {
      title = event.event.holidayName;
    }
    return (
      <div ref={ref}>
        <span onClick={openTooltip} title={title}>
          {title}
        </span>
        <Overlay
          rootClose
          target={getTarget}
          show={showTooltip}
          placement="top"
          onHide={closeTooltip}
        >
          <Tooltip id={event.id} style={{ zIndex: 1300 }}>
            <TooltipContent event={event} onClose={closeTooltip} />
          </Tooltip>
        </Overlay>
      </div>
    );
  }

  const eventStyleSetter = (event, start, end, isSelected) => {
    var backgroundColor = event.hexColor ? event.hexColor : "yellow";

    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: theme.palette.getContrastText(backgroundColor),
      border: "0px",
      minHeight: 25,
      display: "flex",
      flexDirection: "row",
    };
    return {
      style: style,
    };
  };

  const slotStyleSetter = () => {
    const style = {
      backgroundColor: "orange",
    };
    return {
      style: style,
    };
  };

  const daySlotStyleSetter = (date) => {
    const style = {
      backgroundColor:
        date.getDate() === moment().toDate().getDate()
          ? theme.colors.CALENDAR_DAY_HEADER_ACTIVE_COLOR
          : theme.colors.CALENDAR_DAY_HEADER_COLOR,
      color:
        date.getDate() === moment().toDate().getDate()
          ? "black"
          : theme.colors.CALENDAR_DATE_TEXT_COLOR,
    };
    return {
      style: style,
    };
  };

  const timeSlotStyleSetter = (date) => {
    const style = {
      backgroundColor:
        date.getTime() === moment().toDate().getTime()
          ? theme.colors.CALENDAR_DAY_HEADER_ACTIVE_COLOR
          : theme.colors.CALENDAR_DAY_HEADER_COLOR,
      color:
        date.getTime() === moment().toDate().getTime()
          ? "black"
          : theme.colors.CALENDAR_DATE_TEXT_COLOR,
    };
    return {
      style: style,
    };
  };

  const handleSlotSelect = async ({ start, end }) => {
    if (isUserAdmin) {
      console.log("handleSlotSelect==== ", { start, end });

      await dispatch(
        setAddModalsCalendarDates({
          startDate: start,
          startTime: start,
          endDate: end,
          endTime: end,
        })
      );

      setIsCalendarAddDialog(true);
    }
  };

  const [showMoreDialog, setShowMoreDialog] = useState(false);
  const [showMoreData, setShowMoreData] = useState({
    events: [],
    date: new Date().toISOString(),
  });
  const onShowMore = (events, date, rest) => {
    setShowMoreDialog(true);
    setShowMoreData({ events, date });
  };

  return (
    <div className={styles.container_CalendarCNO}>
      {(eventsLoader || todosLoader || appointmentsLoader) && (
        <FullScreenLoaderCNO />
      )}

      <ShowMorePopup
        open={showMoreDialog}
        onClose={() => setShowMoreDialog(false)}
        showMoreData={showMoreData}
        setShowMoreData={setShowMoreData}
        calendarFilter={calendarFilter}
      />

      <Calendar
        events={eventsList}
        views={allViews}
        defaultView={Views.DAY}
        step={30}
        onDrillDown={() => null}
        showMultiDayTimes
        min={moment().startOf("day").toDate()}
        max={moment().endOf("day").toDate()}
        defaultDate={moment().toDate()}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={eventStyleSetter}
        slotPropGetter={slotStyleSetter}
        dayPropGetter={daySlotStyleSetter}
        style={{
          color: theme.colors.CALENDAR_CURRENT_MONTH_TEXT_COLOR,
        }}
        timeSlotWrapper={timeSlotStyleSetter}
        tooltipAccessor={null}
        onSelectSlot={(e) => handleSlotSelect(e)}
        selectable
        popup={false}
        components={{
          event: EventPopover,
          timeSlotWrapper: ColoredTimeCellWrapper,
          dateCellWrapper: ColoredDateCellWrapper,
          toolbar: CustomToolbar,
        }}
        onView={(view) => setCalendarView(view)}
        onRangeChange={(dateRange) => setDateRange(dateRange)}
        onNavigate={(newDate, view) => setCalendarView(view)}
        onShowMore={onShowMore}
      />

      {isCalendarAddDialog && (
        <CalendarAddModal
          open={isCalendarAddDialog}
          onClose={() => setIsCalendarAddDialog(false)}
        />
      )}

      {isAddAppointmentDialog && <AddAppointment />}

      {isAddLeaveDialog && <AddLeave />}

      {isAddHolidayDialog && <AddHoliday />}
    </div>
  );
}

export default CalendarCNO;
