import { makeStyles, useMediaQuery } from '@material-ui/core';
import { mdiRestore } from '@mdi/js';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEmployeeSearchParams } from '../../actions/employeeActions';
import { fetchMemberSuggest } from '../../actions/suggestionsActions';
import FormActionButtons from '../../components/FormActionButtons';
import HeadingBar from '../../components/HeadingBar';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import { useGlobalStore } from '../../providers/GlobalProvider';
import { TOGGLE_SEARCH } from '../../utils/actions';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import * as yup from 'yup';

function EmployeeFilterModal({ setPage }) {

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const GlobalStyles = useGlobalStyles();
  const styles = useStyles({ tillTablet });

  const dispatch = useDispatch();
  const { employeeSearchParams } = useSelector(state => state.employees);
  const suggestions = useSelector(state => state.suggestions);

  const { dispatchGlobal } = useGlobalStore();
  const closeEmployeeFilterDialog = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false
    });
  };

  const validationSchema = yup.object({
    categories: yup.array()
  });

  const formik = useFormik({
    initialValues: {
      employee: null,
      email: "",
      phone: "",
      address: ""
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(setEmployeeSearchParams(values));
      closeEmployeeFilterDialog();
    }
  });

  useEffect(() => {
    formik.setValues(employeeSearchParams);
    setPage(0);
  }, [employeeSearchParams]);

  const handleResetForm = () => {
    formik.resetForm();
    dispatch(setEmployeeSearchParams(formik.initialValues));
  };

  const triggerEmployeeSearch = (values) => {
    if (!tillTablet) {
      dispatch(setEmployeeSearchParams(values));
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.container_EmployeeFilterModal}>

      <HeadingBar
        title={formik.dirty ? "Reset" : "Search"}
        icon={formik.dirty ? mdiRestore : null}
        onHeaderClick={handleResetForm}
        onClose={formik.dirty ? undefined : closeEmployeeFilterDialog}
      />

      <AutocompleteCNO
        name='employee'
        label='Name'
        formik={formik}
        placeholder='Search employee...'
        fullWidth
        triggerSearchOnEnter={true}
        data={suggestions}
        optionLabel={x => x.title || ""}
        renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
        onSuggestionClick={(value) => {
          formik.setFieldValue("employee", value);
          triggerEmployeeSearch({ ...formik.values, employee: value });
        }}
        onChange={(value) => {
          if (value) {
            dispatch(fetchMemberSuggest(value));
          }
        }}
      />

      {/* <div style={{ marginTop: 10 }}>
        <InputCNO
          name='email'
          label='Email'
          formik={formik}
          placeholder='Enter employee email'
          icon={mdiEmail}
          fullWidth
        />
      </div> */}

      {/* <PhoneInputCNO
        value={formik.values.phone}
        label="Mobile"
        onChange={(value) => formik.setFieldValue('phone', value)}
        containerStyle={{ marginTop: 10, marginBottom: 10 }}
      /> */}

      {/* <GooglePlaceInputCNO
        address={formik.values.address}
        onSelectAddress={(addressObj) => {
          formik.setFieldValue('address', addressObj.address)
        }}
      /> */}

      {
        tillTablet &&
        <FormActionButtons
          rightText="Search"
          rightClick={formik.handleSubmit}
          leftClick={closeEmployeeFilterDialog}
          formik={formik}
        />
      }

    </form>
  )
}

export default EmployeeFilterModal;

const useStyles = makeStyles((theme) => ({
  container_EmployeeFilterModal: {
    padding: 8,
    width: 350,
    '&>*:first-child': {
      height: ({ tillTablet }) => tillTablet ? 'auto' : 68
    }
  }
}));
