import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { generateThumbnailImage } from "../../../utils/Helpers";
import { getCategory } from "../../../utils/Helpers";
import JobTypeIcon from "../../../components/JobTypeIcon";
import { Privacy } from "../../../utils/mappings";
import * as mdi from "@mdi/js";
import Icon from "@mdi/react";
import LinkCNO from "../../../library/LinkCNO";
import { mdiPlayCircleOutline } from "@mdi/js";
import { FileType } from "../../../utils/mappings";
import LocalStorageConstants from "../../../utils/LocalStorageConstants";
import { MainPostType } from "../../../pages/PostsManagement/enums";

const GridItemCard = ({ post,mode }) => {
  console.log("postItem", post);
  const [mediaLinks, setMediaLinks] = useState([]);
  const [postDescription, setPostDescription] = useState("");
  const [moreMediaCount, setMoreMediaCount] = useState(0);
  const [createdBy, setCreatedBy] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [visibility, setVisibility] = useState("");
  const [title, setTitle] = useState("");
  const [gif, setGif] = useState("");
  const [lifeEventIcon, setLifeEventIcon] = useState("");
  const [lifeEventTitle, setLifeEventTitle] = useState("");
  const [iconDark,setIconDark]=useState('');
  const [serviceCategory,setServiceCategory]=useState('');

  const maxImages = 4;

  const getVisibility = (value) => {
    const privacy = Privacy.entities;
    const matchedItem = privacy.find((item) => item.id === value);
    return matchedItem ? matchedItem.icon : null;
  };


  useEffect(() => {
    const fetchThumbnails = async () => {
      if (post && Object.keys(post).length > 0) {
        const mediaWithThumbnails = await Promise.all(
          post.mediaLinks.map(async (item) => {
            if (item.fileType === FileType.Video) {
              const thumbnail = await generateThumbnailImage(item.link);
              console.log("ttthumbnail", thumbnail);
              return { ...item, thumbnail: thumbnail.base64 };
            }
            return item;
          })
        );

        setMediaLinks(mediaWithThumbnails);
        setPostDescription(post?.description || "");
        setTitle(post?.title);
        if (mediaWithThumbnails.length > maxImages) {
          setMoreMediaCount(mediaWithThumbnails.length - maxImages);
        } else {
          setMoreMediaCount(0);
        }
      }
    };

    fetchThumbnails();
    if (post && post.postType === MainPostType.ids.Post) {
      setCreatedBy(post.createdBy.profilePic);
      setCreatedDate(new Date(post.createdAt));
    } else {
      setCategoryId(getCategory(post.categories[0]));
      setCreatedDate(new Date(post.startDate));
    }
    // if (post && (post.postType === 2 || post.postType === 3))
    if (post && post.visibility) {
      setVisibility(getVisibility(post.visibility));
    }

    if (post?.gifs) {
      // setMediaLinks((prev) => [...prev, ...post.gifs]);
      setGif(post?.gifs[0]?.gif);
      console.log("gifs", post.gifs);
    }

    if (post?.lifeEventTitle) {
      setLifeEventIcon(post.lifeEventIcon);
      setLifeEventTitle(post.lifeEventTitle);
    }

    if(post?.iconDark){
      setIconDark(post.iconDark);
      setServiceCategory(post.service);
    }
  }, [post]);

  const styles = useStyles();
  const displayImages = mediaLinks.slice(0, maxImages);

  const authUser = LocalStorageConstants.getItem("authUser");
  const userName = `${authUser.firstName} ${authUser.lastName}`;

  console.log("createdBy", createdBy);
  console.log("categoryid", categoryId);
  console.log("createdDate", createdDate);
  console.log("visibility", visibility);
  console.log("Jobtitle", title);
  console.log("postType",post?.postType);

  return (
    <LinkCNO
      to={`${
        post && post.postType === MainPostType.ids.Post || mode === 'posts'
          ? `/post/${post.id}`
          : post.postType === MainPostType.ids.Service || mode === 'services'
          ? `/service/${post.id}`
          : post.postType === MainPostType.ids.Job || mode === 'jobs'
          ? `/job/${post.id}`
          : post.postType === MainPostType.ids.Event || mode === 'events'
          ? `/event/${post.id}`
          : post.postType === MainPostType.ids.ServiceProvider || mode === 'serviceProviders'
          ? `/service-provider/${post.id}`
          :  mode === 'invoices'
          ? `/invoice/${post.id}`
          : mode === 'quotations'
          ? `/quotation/${post.id}`
          : mode === 'appointments'
          ? `/appointment/${post.id}`
          : ``
      }`}
    >
      <div className={styles.gridCardContainer}>
        <div
          className={`${styles.collageContainer} ${
            styles[`collage${displayImages.length}`]
          }`}
        >
          {displayImages.length > 0 ? (
            displayImages.map((item, index) => (
              <div
                key={index}
                className={styles.collageItem}
                style={{
                  backgroundImage:
                    item.fileType && item.fileType === FileType.Video
                      ? `url(${item.thumbnail})`
                      : item.fileType === FileType.Gif
                      ? `url(${item.gif})`
                      : `url(${item.link})`,
                  // border: "1px solid black",
                }}
              >
                {item.fileType && item.fileType === 4 && (
                  <div className={styles.overlay}>
                    <Icon path={mdiPlayCircleOutline} size={3} />
                  </div>
                )}
                {index === maxImages - 1 && moreMediaCount > 0 && (
                  <div className={styles.overlay}>+{moreMediaCount}</div>
                )}
              </div>
            ))
          ) : gif ? (
            <div className={styles.gifItem}>
              <img src={gif} />
            </div>
          ) : lifeEventTitle ? (
            <div style={
              {
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'column'
              }
            }>
              <div className={styles.LifeEventCategoryIcon}>
                <Icon path={mdi[lifeEventIcon]} size={1} />
              </div>
              <div>
                <p className={styles.text}>{`${userName} : ${lifeEventTitle}`}</p>
              </div>
            </div>
          ) : iconDark ? (
            <div style={
              {
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'column'
              }
            }>
              <div className={styles.LifeEventCategoryIcon}>
                <img src={iconDark} style={{height:'60px',width:'60px'}}/>
              </div>
              <div>
                <p className={styles.text}>{serviceCategory}</p>
              </div>
            </div>
          ):(
            <div className={styles.descriptionContainer}>
              <Typography className={styles.descriptionText}>
                {"No Media Avaialable"}
              </Typography>
            </div>
          )}
        </div>

        <div className={styles.cardDescription}>
          {createdBy ? (
            // <div className={styles.profilePicContainer}>
            <img
              src={createdBy}
              style={{ height: "60px", width: "60px", borderRadius: "50%" }}
            />
          ) : (
            // </div>
            categoryId && (
              <JobTypeIcon
                iconLink={categoryId.iconLink}
                name={categoryId?.title}
                categoryId={post.categories[0]}
              />
            )
          )}
          <div>
            <Typography variant="body2" color="text.secondary">
              {title
                ? title
                : postDescription
                ? `${postDescription.slice(0, 50)}...`
                : ""}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {createdDate &&
                `${createdDate.getFullYear()}-${(createdDate.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")}-${createdDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")} at 
              ${createdDate
                .getHours()
                .toString()
                .padStart(2, "0")}:${createdDate
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}:${createdDate
                  .getSeconds()
                  .toString() //
                  .padStart(2, "0")}
                `}
              {visibility && <Icon path={mdi[visibility]} size={0.4} />}
            </Typography>
          </div>
        </div>
      </div>
    </LinkCNO>
  );
};

export default GridItemCard;

const useStyles = makeStyles((theme) => ({
  gridCardContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.5)",
    width: "300px",
    height: "370px",
    
  },
  collageContainer: {
    position: "relative",

    display: "grid",
    width: "100%",
    height: "272px",
    background: `${theme.colors.COLLAGE_CONTAINER_BACKGROUND}`,
    overflow:'hidden',
    borderRadius:'10px',

  },
  collage1: {
    gridTemplateColumns: "1fr",
  },
  collage2: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  collage3: {
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "1fr 1fr",
  },
  collage4: {
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
  },
  collageItem: {
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  gifItem: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: 'auto',
    objectFit: 'contain',
  },
  overlay: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: theme.spacing(1),
    fontSize: "1.2rem",
    fontWeight: "bold",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  descriptionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    textAlign: "center",
    height: "100%",
    width: "100%",
  },
  descriptionText: {
    fontSize: "1rem",
  },
  cardDescription: {
    display: "flex",
    gap: "15px",
    padding: "15px",
  },
  profilePicContainer: {
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    overflow: "hidden",
  },
  LifeEventCategoryIcon: {

    height: "80px",
    width: "80px",
    borderRadius: "50%",
    // backgroundColor: "#5f5858",
    // background:'#0866ff',
    background:'rgb(155,190,0)',

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  text:{
    fontWeight:'bold',
    color:'#91b200',
    marginTop:'3px'
  }
}));
