import { makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { mdiFormatText, mdiRestore } from '@mdi/js';
import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleQuotationSearch, resetQuotationSearch } from '../../actions/quotationActions';
import { fetchCustomerSuggest, fetchQuotationSuggest } from '../../actions/suggestionsActions';
import FormActionButtons from '../../components/FormActionButtons';
import HeadingBar from '../../components/HeadingBar';
import JobChipsFilter from '../../components/JobChipsFilter';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import InputCNO from '../../library/InputCNO';
import { useGlobalStore } from '../../providers/GlobalProvider';
import { TOGGLE_SEARCH } from '../../utils/actions';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import * as yup from 'yup';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';

function QuotationFilterModal({ setPage }) {

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const GlobalStyles = useGlobalStyles();
  const styles = useStyles({ tillTablet });

  const dispatch = useDispatch();
  const { quotationSearchParams } = useSelector(state => state.quotations);
  const suggestions = useSelector(state => state.suggestions);

  const { dispatchGlobal } = useGlobalStore();
  const closeQuotationFilterDialog = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false
    });
  };

  const validationSchema = yup.object({
    categories: yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      customer: null,
      categories: [],
      quotation: null,
      offerNumber: "",
      sentOn: null,
      validForDays: null,
      statuses: [],
      isToday: false,
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(handleQuotationSearch(values));
      closeQuotationFilterDialog();
    }
  });

  useEffect(() => {
    formik.setValues(quotationSearchParams);
    setPage(0);
  }, [quotationSearchParams]);

  const handleResetForm = () => {
    formik.resetForm();
    dispatch(resetQuotationSearch());
  };

  const triggerQuotationSearch = (values) => {
    if (!tillTablet) {
      dispatch(handleQuotationSearch(values));
    }
  };

  console.log("==== quotation formik values : ", formik.values);

  return (
    <form onSubmit={formik.handleSubmit} className={styles.container_QuotationFilterModal}>

      <HeadingBar
        title={formik.dirty ? "Reset" : "Search"}
        icon={formik.dirty ? mdiRestore : null}
        onHeaderClick={handleResetForm}
        onClose={formik.dirty ? undefined : closeQuotationFilterDialog}
      />

      <AutocompleteCNO
        name='customer'
        label='Contact Person'
        formik={formik}
        placeholder='Search Customer'
        // fullWidth
        data={suggestions}
        optionLabel={x => x.title || ""}
        renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
        onSuggestionClick={(value) => {
          formik.setFieldValue('customer', value);
          triggerQuotationSearch({ ...formik.values, customer: value });
        }}
        onChange={(value) => {
          if (value) {
            dispatch(fetchCustomerSuggest(value));
          }
        }}
      />

      <div style={{ marginTop: 12 }}></div>

      {
        tillTablet &&
        <AutocompleteCNO
          label='Select Quotation'
          name='quotation'
          placeholder='Search Quotation...'
          formik={formik}
          data={suggestions}
          optionLabel={x => x.title || ""}
          renderOption={option => <div style={{ color: 'orangered' }}>{option.title}</div>}
          onSuggestionClick={(value) => {
            formik.setFieldValue('quotation', value);
            triggerQuotationSearch({ ...formik.values, quotation: value });
          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchQuotationSuggest(value));
            }
          }}
        />
      }

      <div className={GlobalStyles.verticalScrollChipsWrapper} style={{ marginTop: 12, marginBottom: 12 }}>
        <JobChipsFilter
          mode="quotation"
          onChipToggle={(categories) => {
            formik.setFieldValue('categories', categories);
            triggerQuotationSearch({ ...formik.values, categories: categories });
          }}
        />
      </div>

      <InputCNO
        name='offerNumber'
        label='Quotation Number'
        formik={formik}
        placeholder='Enter Quotation number'
        icon={mdiFormatText}
        fullWidth
        onChange={(e) => {
          console.log("===> offerNumber ", e.target.value);
          formik.setFieldValue('offerNumber', e.target.value);
          triggerQuotationSearch({ ...formik.values, offerNumber: e.target.value });
        }}
      />

      <div style={{ marginTop: 12 }} />

      <DateTimePickerCNO
        dateName="sentOn"
        datePlaceholder="Sent On"
        datePickerLabel='Sent On Date'
        onDateChange={date => {
          formik.setFieldValue('sentOn', date);
          triggerQuotationSearch({ ...formik.values, sentOn: date });
        }}

        formik={formik}
        iconColor="red"
        isRequired={false}
      />

      <div style={{ marginTop: 12 }}>
        <InputCNO
          name='validForDays'
          label='Valid for days'
          formik={formik}
          placeholder='Search by valid for days'
          icon={mdiFormatText}
          fullWidth
          onChange={(e) => {
            formik.setFieldValue('validForDays', e.target.value);
            triggerQuotationSearch({ ...formik.values, validForDays: e.target.value });
          }}
        />
      </div>

      {
        tillTablet &&
        <FormActionButtons
          rightText="Search"
          rightClick={formik.handleSubmit}
          leftClick={closeQuotationFilterDialog}
          formik={formik}
        />
      }

    </form>
  )
}

export default QuotationFilterModal;

const useStyles = makeStyles((theme) => ({
  container_QuotationFilterModal: {
    padding: 8,
    '&>*:first-child': {
      height: ({ tillTablet }) => tillTablet ? 'auto' : 68
    }
  }
}));
