import React, { useState } from 'react'
import { FormFieldsTypes } from '../../utils/enums'
import { Box, Checkbox } from '@material-ui/core'
import { InputLabelCNO } from '../../../../library'
import FormFieldActions from '../FormFieldActions';

function CheckBoxRender({ field, formik, onEdit, onDelete, onChange, showFormFieldActions = false, disabled }) {

  const value = formik.values[field.name];

  const [checked, setChecked] = useState(value);

  const handleOnChange = () => {

    setChecked(prev => {
      const nextValue = !prev;

      typeof onChange === 'function' && onChange(nextValue);

      return nextValue;

    });

  }

  return (
    <>
      {
        field.type === FormFieldsTypes.ids.CheckBox ?
          <Box display='flex' alignItems='flex-start'>
            <Box display='flex' alignItems='flex-start' width='100%' >
              <Checkbox
                checked={value}
                onChange={handleOnChange}
                name={field.name}
                color="primary"
                style={{ paddingTop: 2, marginRight: 8 }}
                disabled={disabled}
              />
              <InputLabelCNO label={field.label} />
            </Box>
            {
              showFormFieldActions &&
              <FormFieldActions fieldInitialValues={field} onEdit={onEdit} onDelete={onDelete} />
            }

          </Box>
          : null
      }
    </>
  )
}

export default CheckBoxRender