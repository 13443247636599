import { getItemFromArray } from "../../utils/Helpers"

export const PostStatus = {
  id: {
    Draft: 1,
    Published: 2,
  },
  entities: [
    { id: 1, title: 'Draft', icon: 'file-edit-outline' },
    { id: 2, title: 'Published', icon: 'file-lock-outline' },
  ],
  getItem: getItemFromArray
}

export const PostReactions = {
  ids: {
    Like: 1,
    Love: 2,
    Haha: 3,
    Wow: 4,
    Sad: 5,
    Angry: 6,
    Progress: 7,
    Party: 8,
  },
  entities: [
    {
      id: 1,
      title: "Like",
      icon: 'thumbs-up',
      // iconType: IconType.FontAwesome5
    },
    {
      id: 2,
      title: "Love",
      icon: 'heart',
      // iconType: IconType.FontAwesome5
    },
    {
      id: 3,
      title: "Haha",
      icon: 'laugh-squint',
      // iconType: IconType.FontAwesome5
    },
    {
      id: 4,
      title: "Wow",
      icon: 'surprise',
      // iconType: IconType.FontAwesome5
    },
    {
      id: 5,
      title: "Sad",
      icon: 'sad-tear',
      // iconType: IconType.FontAwesome5
    },
    {
      id: 6,
      title: "Angry",
      icon: 'angry',
      // iconType: IconType.FontAwesome5
    },
    {
      id: 7,
      title: "Progress",
      icon: 'rocket',
      // iconType: IconType.FontAwesome5
    },
    {
      id: 8,
      title: "Party",
      icon: 'party-popper',
      // iconType: IconType.MaterialCommunityIcons
    },
  ],
  getItem: getItemFromArray,
}

export const PostType = {
  ids: {
    Post: 1,
    EventDiscussion: 2,
    PostEventDiscussion: 3,
    Service:4,
    ServiceProvider:5
  },
  entities: [
    {
      id: 1,
      title: 'Post',
      icon: 'script-text-outline',
    },
    {
      id: 2,
      title: 'Event Discussion',
      icon: 'calendar-star',
    },
    {
      id: 3,
      title: 'Post & Event Discussion',
      icon: 'script-text-outline',
    },
    {
      id:4,
      title:'Service',
      icon:'mdiAccountWrench'
    },
    {
      id:5,
      title:'ServiceProvider',
      icon:'mdiHandCoin'
    }
  ],
  getItem: getItemFromArray,
}

export const MainPostType = {
  ids: {
    Post: 1,
    Job: 2,
    Event: 3,
    Service:4,
    ServiceProvider:5
  },
  entities: [
    {
      id: 1,
      title: 'Post',
      icon: 'script-text-outline',
    },
    {
      id: 2,
      title: 'Job',
      icon: 'breifcase',
    },
    {
      id: 3,
      title: 'Event',
      icon: 'calendar-star',
    },
    {
      id:4,
      title:'Service',
      icon:'mdiAccountWrench'
    },
    {
      id:5,
      title:'ServiceProvider',
      icon:'mdiHandCoin'
    }
  ],
  getItem: getItemFromArray,
}