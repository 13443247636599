import { mdiFormatText } from '@mdi/js';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { useDebouncedFormik } from '../../hooks';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons';

export default function EditAddInvoiceItemDescription({
  open,
  onClose,
  onSave,
  value,
}) {

  const formik = useFormik({
    initialValues: {
      description: value,
    },
    validationSchema: yup.object({
      description: yup.string()
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleClose = () => {

    formik.resetForm();

    localStorage.removeItem('editInvoiceDescription');

    onClose();

  };

  const handleUpdate = (values) => {
    console.log("Edit Add Invoice Item: description: ", values);

    if (onSave) {
      onSave(values.description);
    }

    handleClose();

  };

  useDebouncedFormik(formik, 'editInvoiceDescription');


  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Edit Invoice Item Description"
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name="description"
          label="Invoice Item Description"
          placeholder="Enter Invoice Item Description"
          formik={formik}
          multiline={true}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}
