import { Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DialogCNO from '../../library/DialogCNO';
import FormActionButtons from '../FormActionButtons';
import { getCustomerById } from '../../actions/customerActions';
import InputCNO from '../../library/InputCNO';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { JobVisibilityForCustomer } from '../../utils/mappings';
import { apiTaskEdit } from '../../api';
import { setShareJobLoader } from '../../actions/jobActions';
import { updateCustomerDetails } from '../../actions/customerActions';
import { mdiEmail } from '@mdi/js';

function ShareConfirmModal({
  open,
  onClose,
  isVisible,
  shareWithCustomer,
  closeMoreActions
}) {

  const handeClose = () => {

    onClose();

    closeMoreActions();

  };

  return (
    <DialogCNO
      open={open}
      onClose={handeClose}
      dialogTitle="Share task with customer"
    >
      <Typography>
        {
          isVisible
            ? 'Are you sure you want to remove task from customer?'
            : 'Are you sure you want to share task with customer?'
        }
      </Typography>
      <FormActionButtons
        rightText={isVisible ? "Remove" : "Share"}
        rightClick={shareWithCustomer}
        leftClick={onClose}
        closeMoreActions={closeMoreActions}
      />
    </DialogCNO>
  )
}

function AddCustomerEmailModal({
  todo,
  open,
  shareWithCustomer,
  onClose,
  closeMoreActions
}) {

  const dispatch = useDispatch();
  const { customerById, allCustomersLoader: { editLoader } } = useSelector(state => state.customers);

  useEffect(() => {

    const payload = todo.customerId;
    dispatch(getCustomerById([payload]));

  }, [todo]);

  const addEmailToCustomer = (values) => {

    const customerPayload = {
      ...customerById,
      email: values.email
    };

    dispatch(updateCustomerDetails(customerPayload));

    shareWithCustomer();

  };

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: yup.object({
      email: yup.string().email('Please enter a valid email').required("Required!!!")
    }),
    onSubmit: (values) => {
      console.log("add email and share");
      addEmailToCustomer(values)
    }
  });

  const handleClose = () => {

    onClose();

    closeMoreActions();

  };

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Share task with customer"
      loading={editLoader}
    >

      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name='email'
          label="Email"
          formik={formik}
          placeholder='Enter Customer Email'
          icon={mdiEmail}
          fullWidth
        />

      </form>

      <FormActionButtons
        rightText="Share"
        rightClick={() => addEmailToCustomer(formik.values)}
        leftClick={onClose}
        formik={formik}
      />
    </DialogCNO>
  )
}

function ShareTaskWithCustomer({
  open,
  onClose,
  todo,
  onShareTaskWithCustomer,
  closeMoreActions
}) {

  const dispatch = useDispatch();
  const { customerById } = useSelector(state => state.customers);

  const [isEmailAvailable, setIsEmailAvailable] = useState(false);

  // get customer details
  useEffect(() => {

    const payload = todo.customerId;
    dispatch(getCustomerById([payload]));

  }, [todo]);

  // set if customer email is available or not
  // to open email popup if not available
  useEffect(() => {

    if (customerById && customerById.email) {
      setIsEmailAvailable(true);
    } else {
      setIsEmailAvailable(false);
    }

  }, [customerById]);


  const shareWithCustomer = async () => {

    dispatch(setShareJobLoader(true));

    const dataToSend = {
      ...todo,
      visibility: todo.visibility
        ? JobVisibilityForCustomer.NotVisible
        : JobVisibilityForCustomer.Visible
    };

    await apiTaskEdit.post(dataToSend)
      .then((response) => {

        if (response.status === 200) {

          console.log("Share with customer Response: ", response);

          if (todo.visibility) {
            toast.error("Task removed successfully from customer!!");
          } else {
            toast.success("Task shared successfully with customer!!");
          }

          onShareTaskWithCustomer(dataToSend);

        } else {
          console.log("Share Task Error: ");
          toast.error("Error in sharing task with customer");
        }

      })
      .catch((error) => {
        toast.error("Error in sharing task with customer!!!");
        console.error("Share Task Error: ", error);
      })
      .finally(() => dispatch(setShareJobLoader(false)));

    onClose();

  };


  return (
    <Fragment>

      <ShareConfirmModal
        open={open}
        onClose={onClose}
        isVisible={Boolean(todo.visibility)}
        shareWithCustomer={shareWithCustomer}
        closeMoreActions={closeMoreActions}
      />

      {
        open &&
        <AddCustomerEmailModal
          todo={todo}
          open={!isEmailAvailable}
          shareWithCustomer={shareWithCustomer}
          onClose={() => {
            setIsEmailAvailable(true);
            onClose();
          }}
          closeMoreActions={closeMoreActions}
        />
      }

    </Fragment>
  )
}

export default ShareTaskWithCustomer
