import { Dialog, InputAdornment, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { Fragment, useEffect, useRef } from 'react';
import ChipCNO from '../../library/ChipCNO';
import { mdiAccountTie, mdiCalendar, mdiCar, mdiCircleOutline, mdiDoctor, mdiEmail, mdiFacebook, mdiFormatText, mdiGenderFemale, mdiGenderMale, mdiHumanFemale, mdiHumanMale, mdiLinkedin, mdiMapMarkerPath, mdiSkype, mdiTwitter, mdiWalk } from '@mdi/js';
import InputCNO from '../../library/InputCNO';
import Icon from '@mdi/react';
import 'react-phone-number-input/style.css';
import HeadingBar from '../HeadingBar/HeadingBar';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import {
  SnapList,
  SnapItem,
  useDragToScroll,
} from 'react-snaplist-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { Currency, CurrencyIcon, Gender, Salutation, TrackingMode } from '../../utils/mappings';
import { useState } from 'react';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { useStyles } from './AddEmployeeStyles';
import { addEmployee, searchAlreadyEmployees, setAlreadyExistEmployees, toggleAddEmployeeDialog } from '../../actions/employeeActions';
import { DatePicker } from '@material-ui/pickers';
import GooglePlaceInputCNO from '../../library/GooglePlaceInputCNO/GooglePlaceInputCNO';
import * as icons from '@mdi/js';
import { getMaterialIconString } from '../../utils/icons';
import FormBox from '../FormBox';
import { getGenderBasedOnSalutation, getUserCountry } from '../../utils/Helpers';
import { useDebouncedFormik, useFormikErrors } from '../../hooks';
import AlreadyExistCustomerList from '../AlreadyExistCustomerList/AlreadyExistCustomerList';
import PhoneInputCNO from '../../library/PhoneInputCNO/PhoneInputCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import AddEmployeeErrorDialog from './AddEmployeeErrorDialog';

function AddEmployee({ onAdd }) {

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const country = getUserCountry();

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const theme = useTheme();

  const dispatch = useDispatch();

  const {
    isAddEmployeeDialog,
    alreadyExistEmployees,
    allEmployeesLoader: { addLoader }
  } = useSelector(state => state.employees);

  const [isAlreadyExistEmployee, setIsAlreadyExistEmployee] = useState(false);
  const [isUserSelectedFromList, setIsUserSelectedFromList] = useState(false);
  const [isMoreDetailsExpanded, setIsMoreDetailsExpanded] = useState(false);
  const [addError, setAddError] = useState({ open: false, error: null });

  useEffect(() => {
    return () => {
      setIsAlreadyExistEmployee(false);
      formik.resetForm();
      dispatch(setAlreadyExistEmployees([]));
    };
  }, []);

  useEffect(() => {
    setIsAlreadyExistEmployee(alreadyExistEmployees.length ? true : false);
  }, [alreadyExistEmployees]);

  const handleClose = () => {

    localStorage.removeItem('addEmployee');

    dispatch(toggleAddEmployeeDialog(false));
  };

  const validationSchema = yup.object({
    salutation: yup.string().required("Salutation is required."),
    firstName: yup.string().required("First Name is required."),
    lastName: yup.string().required("Last Name is required."),
    gender: yup.string(),
    birthday: yup.object().nullable(),
    abstract: yup.string(),
    email: yup.string().email('Please enter a valid email').required("Email is required."),
    mobile: yup.string().required("Mobile is required."),
    mapAddress: yup.string().required("Address is required."),
    lat: yup.string(),
    lng: yup.string(),
    skype: yup.string(),
    facebook: yup.string(),
    twitter: yup.string(),
    linkedIn: yup.string(),
    trackingMode: yup.number(),
    hourlyRate: yup.number()
  });

  const formik = useFormik({
    initialValues: {
      salutation: '',
      firstName: '',
      lastName: '',
      gender: Gender.Others,
      birthday: new Date(),
      abstract: '',
      email: '',
      mobile: '',
      mapAddress: '',
      lat: '',
      lng: '',
      skype: '',
      facebook: '',
      twitter: '',
      linkedIn: '',
      trackingMode: TrackingMode.Fitness,
      hourlyRate: 0
    },
    validationSchema,
    onSubmit: values => handleAddEmployee(values)
  });

  const onAddSuccess = () => {

    if (onAdd) onAdd();

    handleClose();

    formik.resetForm();

  };

  const onAddError = (error) => {

    console.log("Add Employee: onAddError: ", error);

    if (error) {
      setAddError({ open: true, error });
    }
  };

  console.log("Add Employee: addError state: ", addError);

  const handleAddEmployee = async (values) => {

    if (isUserSelectedFromList) {
      onAddSuccess();
    } else {
      await dispatch(addEmployee(values, onAddSuccess, onAddError));
    }

  };

  console.log("formik addEmp: errors", formik.errors);
  console.log("formik addEmp: values", formik.values);

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  const styles = useStyles({ tillTablet });
  const GlobalStyles = useGlobalStyles();

  useFormikErrors(formik);
  useDebouncedFormik(formik, 'addEmployee');

  const checkIfEmployeeAlreadyExist = () => {

    const { firstName, lastName } = formik.values;
    const searchText = `${firstName} ${lastName}`.trim();

    const dataToSend = {
      searchText: searchText
    };

    if (!searchText) {
      dispatch(setAlreadyExistEmployees([]));
      return;
    }

    dispatch(searchAlreadyEmployees(dataToSend));

  };

  return (
    <Dialog
      open={isAddEmployeeDialog}
      classes={{ paper: styles.paper }}
      className={styles.container_AddEmployee}
    >
      <form
        onSubmit={formik.handleSubmit}
        className={styles.form}
      >
        {
          addLoader &&
          <FullScreenLoaderCNO />
        }

        <HeadingBar title="Add Employee" onClose={handleClose} />

        <FormBox title="Employee details">
          <div className={styles.selectChips}>
            <Typography component='label'>
              Salutation
              <span style={{ color: theme.colors.ERROR_COLOR }}>{' *'}</span>
            </Typography>
            <SnapList className={styles.chipsWrapper}>
              <SnapItem>
                <ChipCNO icon={mdiAccountTie} name='Sir'
                  active={formik.values.salutation === Salutation.Sir}
                  onClick={() => {
                    formik.setFieldValue('salutation', Salutation.Sir);
                    formik.setFieldValue('gender', getGenderBasedOnSalutation(Salutation.Sir));
                  }} />
              </SnapItem>
              <SnapItem>
                <ChipCNO icon={mdiHumanMale} name='Mr'
                  active={formik.values.salutation === Salutation.Mr}
                  onClick={() => {
                    formik.setFieldValue('salutation', Salutation.Mr);
                    formik.setFieldValue('gender', getGenderBasedOnSalutation(Salutation.Mr));
                  }} />
              </SnapItem>
              <SnapItem>
                <ChipCNO icon={mdiHumanFemale} name='Ms'
                  active={formik.values.salutation === Salutation.Ms}
                  onClick={() => {
                    formik.setFieldValue('salutation', Salutation.Ms);
                    formik.setFieldValue('gender', getGenderBasedOnSalutation(Salutation.Ms));
                  }} />
              </SnapItem>
              <SnapItem>
                <ChipCNO icon={mdiHumanFemale} name='Mrs'
                  active={formik.values.salutation === Salutation.Mrs}
                  onClick={() => {
                    formik.setFieldValue('salutation', Salutation.Mrs);
                    formik.setFieldValue('gender', getGenderBasedOnSalutation(Salutation.Mrs));
                  }} />
              </SnapItem>
              <SnapItem>
                <ChipCNO icon={mdiDoctor} name='Dr'
                  active={formik.values.salutation === Salutation.Dr}
                  onClick={() => {
                    formik.setFieldValue('salutation', Salutation.Dr);
                    formik.setFieldValue('gender', getGenderBasedOnSalutation(Salutation.None));
                  }} />
              </SnapItem>
            </SnapList>
          </div>
          <InputCNO
            name='firstName'
            label='First Name'
            formik={formik}
            placeholder='First Name'
            icon={mdiFormatText}
            fullWidth
            isRequired
            onBlur={() => checkIfEmployeeAlreadyExist()}
            isBorderHighlight={isAlreadyExistEmployee}
          />
          <InputCNO
            name='lastName'
            label='Last Name'
            formik={formik}
            placeholder='Last Name'
            icon={mdiFormatText}
            fullWidth
            isRequired
            onBlur={() => checkIfEmployeeAlreadyExist()}
            isBorderHighlight={isAlreadyExistEmployee}
          />
        </FormBox>

        <FormBox title="Contact details">
          <InputCNO
            name='email'
            label='Email'
            type='email'
            formik={formik}
            placeholder='Email'
            icon={mdiEmail}
            fullWidth
            isRequired
          />

          <PhoneInputCNO
            label={'Mobile'}
            value={formik.values.mobile}
            onChange={(value) => formik.setFieldValue('mobile', value)}
            showHelperText={formik.errors.mobile}
            helperText={formik.errors.mobile}
            required={true}
            onValidatePhone={(error) => {
              if (!!error) formik.setFieldError('mobile', error)
            }}
          />

          <GooglePlaceInputCNO
            address={formik.values.mapAddress}
            onSelectAddress={(addressObj) => {
              formik.setFieldValue('mapAddress', addressObj.address)
              formik.setFieldValue('lat', addressObj.lat)
              formik.setFieldValue('lng', addressObj.lng)
            }}
            isRequired
          />
        </FormBox>

        <div className={GlobalStyles.link} onClick={() => setIsMoreDetailsExpanded(!isMoreDetailsExpanded)}>
          {
            (isMoreDetailsExpanded)
              ? 'Less Details'
              : 'More Details'
          }
        </div>

        {
          isMoreDetailsExpanded &&
          <div className={GlobalStyles.moreDetailsContainer}>
            <FormBox title="Personal details">

              <div className={styles.selectChips}>
                <Typography component='label'>
                  Gender
                </Typography>
                <div className={styles.chipsWrapper}>
                  <ChipCNO icon={mdiGenderFemale} name='Female'
                    active={formik.values.gender === Gender.Female}
                    onClick={() => formik.setFieldValue('gender', Gender.Female)} />
                  <ChipCNO icon={mdiGenderMale} name='Male'
                    active={formik.values.gender === Gender.Male}
                    onClick={() => formik.setFieldValue('gender', Gender.Male)} />
                  <ChipCNO icon={mdiCircleOutline} name='Others'
                    active={formik.values.gender === Gender.Others}
                    onClick={() => formik.setFieldValue('gender', Gender.Others)} />
                </div>
              </div>

              <Typography component='label' className={styles.label}>Birthday</Typography>
              <DatePicker
                className={styles.picker}
                margin="dense"
                value={formik.values.birthday}
                format="MMM dd, yyyy"
                inputVariant='outlined'
                placeholder='Enter birthday'
                onChange={date => formik.setFieldValue('birthday', date)}
                error={formik.touched.birthday && Boolean(formik.errors.birthday)}
                helperText={formik.errors.birthday}
                InputProps={{
                  endAdornment: <InputAdornment>
                    <Icon color={theme.colors.INPUT_ICON_COLOR} path={mdiCalendar} size='1.5rem' />
                  </InputAdornment>
                }}
                fullWidth
              />

              <InputCNO
                name='abstract'
                label='Abstract'
                formik={formik}
                placeholder='Abstract'
                icon={mdiFormatText}
                fullWidth
                multiline
              />
            </FormBox>

            <FormBox title="Social details">
              <InputCNO
                name='skype'
                label='Skype'
                formik={formik}
                placeholder='Skype'
                icon={mdiSkype}
                fullWidth
              />

              <InputCNO
                name='facebook'
                label='Facebook'
                formik={formik}
                placeholder='Facebook'
                icon={mdiFacebook}
                fullWidth
              />

              <InputCNO
                name='twitter'
                label='Twitter'
                formik={formik}
                placeholder='Twitter'
                icon={mdiTwitter}
                fullWidth
              />

              <InputCNO
                name='linkedIn'
                label='LinkedIn'
                formik={formik}
                placeholder='LinkedIn'
                icon={mdiLinkedin}
                fullWidth
              />
            </FormBox>

            <FormBox title="Work details">
              <div className={styles.selectChips}>
                <Typography component='label'>Tracking Activity Type</Typography>
                <SnapList className={styles.chipsWrapper}>
                  <SnapItem>
                    <ChipCNO
                      icon={mdiMapMarkerPath}
                      name="Others"
                      active={formik.values.trackingMode === TrackingMode.Others}
                      onClick={() => formik.setFieldValue('trackingMode', 3)}
                    />
                  </SnapItem>
                  <SnapItem>
                    <ChipCNO
                      icon={mdiWalk}
                      name="Fitness"
                      active={formik.values.trackingMode === TrackingMode.Fitness}
                      onClick={() => formik.setFieldValue('trackingMode', 2)}
                    />
                  </SnapItem>
                  <SnapItem>
                    <ChipCNO
                      icon={mdiCar}
                      name="Automotive"
                      active={formik.values.trackingMode === TrackingMode.Automotive}
                      onClick={() => formik.setFieldValue('trackingMode', 1)}
                    />
                  </SnapItem>
                </SnapList>
              </div>

              <InputCNO
                name='hourlyRate'
                label='Hourly Rate'
                formik={formik}
                placeholder='Hourly Rate'
                icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
                fullWidth
              />
            </FormBox>
          </div>
        }

        {
          isAlreadyExistEmployee &&
          <AlreadyExistCustomerList
            customers={alreadyExistEmployees}
            onSelectItem={(selectedUser) => {
              if (Boolean(selectedUser)) {
                formik.setValues({ ...selectedUser });
              } else {
                formik.resetForm();
              }
              setIsUserSelectedFromList(Boolean(selectedUser));
            }}
          />
        }

        <AddEmployeeErrorDialog
          open={addError.open}
          error={addError.error}
          onClose={() => {
            setAddError({ open: false, error: null });
          }}
        />

        <FormActionButtons
          rightText={isUserSelectedFromList ? "OK" : "Add Employee"}
          leftClick={handleClose}
          formik={formik}
        />
      </form>
    </Dialog>
  )
}

export default AddEmployee

