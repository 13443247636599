import { Typography } from '@material-ui/core';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import FormActionButtons from '../FormActionButtons/FormActionButtons';

function AddEmployeeErrorDialog({ open, error, onClose }) {

  console.log("Add Employee AddEmployeeErrorDialog: ", { open, error });

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle='Add Employee Error'
      minHeight={100}
      width={'50vw'}
    >
      <Typography style={{ fontSize: '1rem' }}>
        {error ? error : "There is some issue in adding Employee. Please check your network connection."}
      </Typography>

      <FormActionButtons
        rightText={'Okay'}
        rightClick={onClose}
        isLeftButton={false}
      />

    </DialogCNO>
  )
}

export default AddEmployeeErrorDialog