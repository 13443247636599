import React, { Fragment, useEffect, useState } from 'react';
import { FilePreviewerThumbnail } from 'react-file-previewer';
import { getBase64Data, getBase64FromURL } from '../utils/Helpers';

function FileThumbnail({
  filePath,
  mimeType,
  fileName = "File",
  onClick = () => null,
  hideControls = true,
  style = {}
}) {

  // console.log("=====FileThumbnail=====fileData====", filePath);

  const [file, setFile] = useState({});

  useEffect(() => {

    (
      async function getFileIIFE() {

        if (filePath.includes('http')) {
          // file = { url: filePath };

          const fileData = await getBase64FromURL(filePath);

          setFile({
            data: getBase64Data(fileData),
            mimeType: mimeType,
            name: fileName
          });

        } else if (filePath.includes('base64')) {

          setFile({
            data: getBase64Data(filePath),
            mimeType: mimeType,
            name: fileName
          });

          // file = {
          //   data: getBase64Data(filePath),
          //   mimeType: mimeType,
          //   name: fileName
          // };
        }

      }
    )();

  }, [filePath]);

  // console.log("=====FileThumbnail=====file====", file);

  return (
    <Fragment>
      <FilePreviewerThumbnail
        file={file}
        onClick={onClick}
        hideControls={hideControls}
        style={style}
      />
    </Fragment>
  )
}

export default FileThumbnail
