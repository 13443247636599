import * as React from "react";
import { useState } from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core";

export default function TagChipDisplay({
  taggedPeople,
  addFriend,
  setTaggedFriends,
}) {
  console.log("taggedP", taggedPeople);
  const [items, setItems] = useState(taggedPeople);

  React.useEffect(() => {
    setItems(taggedPeople);
  }, [taggedPeople]);

  const styles = useStyles();

  const handleDelete = (itemToDelete) => {
    const updatedItems = items.filter(
      (item) => item.name !== itemToDelete.name
    );
    setItems(updatedItems);
    setTaggedFriends(updatedItems);
    const addFriendToTagsPeopleList = itemToDelete;
    addFriend(addFriendToTagsPeopleList);
  };

  return (
    <>
      {items?.length > 0 && (
        <div className={styles.stack}>
          {items.map((item) => (
            <Chip
              key={item}
              label={item.name}
              onDelete={() => handleDelete(item)}
            />
          ))}
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  stack: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    flexWrap: "wrap",
    overflowX: "hidden",
    border: `1px solid ${theme.colors.BORDER_COLOR}`,
    padding: "10px",
    borderRadius: "7px",
  },
}));
