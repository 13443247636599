import { makeStyles } from '@material-ui/core';
import React from 'react';
import JobDetails from '../../components/JobDetails';
import ScreenView from '../../components/ScreenView';
import MobileHeader from '../../components/MobileHeader';

function JobDetailsPage() {

  const styles = useStyles();
  return (
    <div className={styles.container}>
      <ScreenView
        content={<JobDetails />}
        headerTitle="Job Details"
      />
    </div>
  )
}

export default JobDetailsPage

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.background.dark,
    width: '100%',
    minHeight: '100vh',
  },
}))