import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_CompanyListings: {
    padding: theme.spacing(0.8),
    minHeight: 'calc(100vh - 80px)',
    '&>*': {
      marginBottom: theme.spacing(1),
    },
    '& .infinite-scroll-component > *': {
      marginBottom: theme.spacing(1),
    }
  },
  infiniteLoader: {
    textAlign: 'center'
  },
  searchToolbar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    position: 'sticky',
    top: 40,
    zIndex: 1,
    // padding: '0px 0px 5px 10px',
  },
}));