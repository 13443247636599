import { toast } from "react-toastify";
import { v4 } from "uuid";
import {
  apiCompanyEdit,
  apiCompanySearch,
  apiMemberSearch,
  apiCompanyCreate,
  apiCompanyRemove,
  apiCompanyUpdateDefaultPaymentMethod,
  apiCompanyCategoriesUpdate,
  apiCompanyGetMediaLinks,
} from "../api";
import LocalStorageConstants from "../utils/LocalStorageConstants";
import { ProfilePicType } from "../utils/mappings";
import fakeCompanies from "./CompaniesDummyJSON/fakeCompanies.json";
import axios from "axios";

export const CREATE_COMPANY = "CREATE_COMPANY";
export const SEARCH_COMPANY = "SEARCH_COMPANY";
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_COMPANIES_LOADER = "SET_COMPANIES_LOADER";
export const SET_HAS_MORE_COMPANIES = "SET_HAS_MORE_COMPANIES";
export const SET_COMPANY_BY_ID = "SET_COMPANY_BY_ID";
export const TOGGLE_ADD_COMPANY_DIALOG = "TOGGLE_ADD_COMPANY_DIALOG";
export const CREATE_COMPANY_COMPANY = "CREATE_COMPANY_COMPANY";
export const SET_COMPANY_PROFILE_PIC = "SET_COMPANY_PROFILE_PIC";

export function setCompanyProfilePic(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_COMPANY_PROFILE_PIC,
      payload: payload,
    });
  };
}

export function setHasMoreCompanies(status) {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_MORE_COMPANIES,
      payload: status,
    });
  };
}

export function setCompaniesLoader(loaderType, loaderState) {
  return (dispatch) => {
    const loaderPayload = {
      loaderType,
      loaderState,
    };

    dispatch({
      type: SET_COMPANIES_LOADER,
      payload: loaderPayload,
    });
  };
}

export function setCompanies(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_COMPANIES,
      payload: payload,
    });
  };
}

export function toggleAddCompanyDialog(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_COMPANY_DIALOG,
      payload: status,
    });
  };
}

export function fetchCompanies(payload) {
  return async (dispatch) => {
    const dataToSend = {
      pageSize: 0,
      pageIndex: 0,
      orderBy: "",
      sortDecending: true,
      searchText: "",
      lat: "",
      lng: "",
      ip: "",
      ids: [],
      resolvePic: true,
      ...payload,
    };

    dispatch(setCompaniesLoader("companiesLoader", true));

    await apiCompanySearch
      .post(dataToSend)
      // await apiMemberSearch.post(payload)
      .then((response) => {
        console.log("companiesResponse: response::", response);

        if (response.status === 200) {
          // response = fakeCompanies;
          // let companiesResponse = fakeCompanies.data;
          let companiesResponse = response.data.data;

          // console.log('====================================');
          // console.log("companiesResponse::", fakeCompanies);
          // console.log('====================================');

          console.log("companiesResponse: ", companiesResponse);
          dispatch(setCompanies(companiesResponse));

          if (companiesResponse < 10) {
            dispatch(setHasMoreCompanies(false));
          }
        } else {
          console.log("companiesResponse: Error::");
          console.error("Error: Fetch Companies: ");
        }
      })
      .catch((error) => {
        console.error("Error: Fetch Companies: ", error);
      })
      .finally(() => {
        dispatch(setCompaniesLoader("companiesLoader", false));
      });
  };
}

export function fetchMoreCompanies(payload) {
  return async (dispatch, getState) => {
    const prevCompanies = getState().companies.companies;

    dispatch(setCompaniesLoader("moreCompaniesLoader", true));

    // await apiCompanySearch.post(payload)
    await apiMemberSearch
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          let moreCompaniesResponse = response.data.data;
          console.log("moreCompaniesResponse: ", moreCompaniesResponse);

          const companiespayload = [...prevCompanies, ...moreCompaniesResponse];

          dispatch(setCompanies(companiespayload));

          if (moreCompaniesResponse < 10) {
            dispatch(setHasMoreCompanies(false));
          }
        } else {
          console.error("Error: Fetch Companies: ");
        }
      })
      .catch((error) => {
        console.error("Error: Fetch Companies: ", error);
      })
      .finally(() => {
        dispatch(setCompaniesLoader("moreCompaniesLoader", false));
      });
  };
}

export function updateCompanyDetails(payload, { onSuccess = null } = {}) {
  return async (dispatch, getState) => {
    console.log("Company edit payload: ", payload);

    dispatch(setCompaniesLoader("editLoader", true));

    await apiCompanyEdit
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          console.log("Company Edit Error: 1 ");

          const stripeSecretKey = response.data;

          payload = {
            ...payload,
            stripeSecretKey,
          };

          const { companies } = getState().companies;
          const companyTemp = companies.map((company) =>
            company.id === payload.id ? payload : company
          );
          dispatch(setCompanies(companyTemp));

          dispatch({
            type: SET_COMPANY_BY_ID,
            payload: payload,
          });

          localStorage.setItem("authUserCompany", JSON.stringify(payload));

          if (onSuccess) onSuccess(payload);

          toast.success("Company edited successfully!!");
        } else {
          console.log("Company Edit Error: 2 ");
          toast.error("Error in editing Company!!");
        }
      })
      .catch((error) => {
        console.error("Company Edit Error: ", error);
        toast.error("Error in editing Company!!");
      })
      .finally(() => {
        dispatch(setCompaniesLoader("editLoader", false));
      });
  };
}

export function addCompany(payload, isRegister) {
  return async (dispatch, getState) => {
    dispatch(setCompaniesLoader("addLoader", true));

    const { companies } = getState().companies;

    payload = {
      ...payload,
      isActive: true,
    };

    console.log("Add Company payloadaction: ", payload);

    let header = {};
    // if (isRegister) {
    //   header = {
    //     "Authorization": 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIiLCJqdGkiOiI2YWI4NDFlZi0xZTYyLTQxOWQtYTczZi1mMGJhZWJjOTYwNTAiLCJpYXQiOiIwNy8yNy8yMDIxIDAzOjIwOjA2IiwidW5pcXVlX25hbWUiOiIzNTAzNDU0NS0zYTBlLTExZWItOWI1Yy0wMGZmYmE1NDZhMDgiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJiMjkyMmM3ZS0zOTJkLTExZWItOWI1Yy0wMGZmYmE1NDZhMDgiLCJHcm91cCI6IjIzY2QzZTA0LTNhMDktMTFlYi05YjVjLTAwZmZiYTU0NmEwOCIsIm5iZiI6MTYyNzM1NjAwNiwiZXhwIjoxOTQyNzE2MDA2LCJpc3MiOiJUZXN0SXNzZXIiLCJhdWQiOiJSZXN0QXVkaWVuY2UifQ.0gRJ-RapR3OpHThWeyJtSJ67zH7i_bP_6j2bNrKG2XA'
    //   };
    // }

    await apiCompanyCreate
      .post(payload, header)
      // await apiCustomerCompanyCreate.post(payload)
      .then((response) => {
        console.log("Add Company Response: ", response);

        if (response.status === 200) {
          // const id = response.data;
          const newCompany = {
            ...payload,
            // userId: id,
            id: v4(),
          };

          dispatch(setCompanies([newCompany, ...companies]));

          toast.success("Company added successfully!!");
        } else {
          console.log("Add company Error: ");
          toast.success("Error in adding company!!");
        }
      })
      .catch((error) => {
        console.error("Add company Error: ", error);
        toast.success("Error in adding company!!");
      })
      .finally(() => {
        dispatch(setCompaniesLoader("addLoader", false));
      });
  };
}

export function setCompanyById(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_COMPANY_BY_ID,
      payload: payload,
    });
  };
}

export function getCompanyById(ids, resolvePic = false, onFetchById) {
  return async (dispatch, getState) => {
    const { companies } = getState().companies;

    dispatch(setCompaniesLoader("companyDetailLoader", true));

    const dataToSend = {
      pageSize: 0,
      pageIndex: 0,
      orderBy: "",
      sortDecending: true,
      searchText: "",
      lat: "",
      lng: "",
      ip: "",
      ids: ids,
      resolvePic: resolvePic,
    };

    await apiCompanySearch
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data.data;

          let company = null;
          if (response.data.data.length > 0) {
            company = responseData[0];
          } else {
            company = companies.find((company) => company.id === ids[0]);
          }

          if (onFetchById) {
            onFetchById(company);
          }

          console.log("responseData: ", company);

          dispatch({
            type: SET_COMPANY_BY_ID,
            payload: company,
          });
        } else {
          console.log("Fetch Company by Id Error: ");
        }
      })
      .catch((error) => {
        console.error("Fetch Company by Id Error: ", error);
      })
      .finally(() => {
        dispatch(setCompaniesLoader("companyDetailLoader", false));
      });
  };
}

export function deleteCompany(payload) {
  return async (dispatch, getState) => {
    // PAYLOAD:
    // {
    //   "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    // }

    dispatch(setCompaniesLoader("deleteLoader", true));

    const { companies } = getState().companies;

    console.log("Delete Employee payloadaction: ", payload);

    const dataToSend = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      SortDecending: true,
      searchText: "",
      ids: [],
      ResolvePic: true,
    };

    await apiCompanyRemove
      .post(payload)
      // await apiMemberSearch.post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          console.log("Remove Company Response: ", response);

          const companiesTemp = companies.map((company) => {
            if (company.id === payload.id) {
              return {
                ...company,
                isActive: false,
              };
            } else {
              return company;
            }
          });

          dispatch(setCompanies(companiesTemp));
          toast.success("Company deactivated successfully!!");
        } else {
          console.log("Deactivate Company Error: ");
          toast.error("Error in removing Company!!");
        }
      })
      .catch((error) => {
        console.error("Deactivate Company Error: ", error);
        toast.error("Error in removing Company!!");
      })
      .finally(() => {
        dispatch(setCompaniesLoader("deleteLoader", false));
      });
  };
}

export function updateDefaultPaymentMethod(payload, profile) {
  return async (dispatch, getState) => {
    dispatch(setCompaniesLoader("editLoader", true));

    const { companies } = getState().companies;

    console.log("Update Payment Mode payload: ", payload);

    await apiCompanyUpdateDefaultPaymentMethod
      .post(payload)
      .then((response) => {
        console.log("Update Payment Mode respnse: ", response);
        if (response.status === 200) {
          dispatch({
            type: SET_COMPANY_BY_ID,
            payload: profile,
          });

          const companiesTemp = companies.map((company) => {
            if (company.id === profile.id) {
              return profile;
            } else {
              return company;
            }
          });

          dispatch(setCompanies(companiesTemp));

          const authUserCompany =
            LocalStorageConstants.getItem("authUserCompany");
          const updatedAuthUserCompany = {
            ...authUserCompany,
            defaultPaymentMethod: payload,
          };
          console.log(
            "Update Payment Mode updatedAuthUserCompany: ",
            updatedAuthUserCompany
          );
          LocalStorageConstants.setItem(
            "authUserCompany",
            updatedAuthUserCompany
          );

          toast.success("Deault Payment Method updated successfully!!");
        } else {
          console.log("Update Payment Mode Error");
          toast.success("Update Payment Mode Error: ");
        }
      })
      .catch((error) => {
        console.log("Update Payment Mode Error: ", error);
        toast.error("Update Payment Mode Error: ", error);
      })
      .finally(() => {
        dispatch(setCompaniesLoader("editLoader", false));
      });
  };
}

export function updateCompanyCategories(payload, profile) {
  return async (dispatch, getState) => {
    const { companies } = getState().companies;

    // const dataToSend = [
    //   {
    //     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //     "title": "string",
    //     "groupId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //     "iconLink": "string"
    //   }
    // ];
    dispatch(setCompaniesLoader("editLoader", true));

    console.log("====================================");
    console.log("CompanyCategoriesUpdate payload: ", payload);
    console.log("====================================");

    await apiCompanyCategoriesUpdate
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: SET_COMPANY_BY_ID,
            payload: {
              ...profile,
              categories: payload,
            },
          });

          const companiesTemp = companies.map((company) => {
            if (companies.id === payload.id) {
              return {
                ...company,
                categories: payload,
              };
            } else {
              return company;
            }
          });

          dispatch(setCompanies(companiesTemp));
          toast.success("Categories updated successfully!!!");
        } else {
          console.log("Update Categories Mode Error");
          toast.error("Update Categories Error");
        }
      })
      .catch((error) => {
        console.log("Update Categories Mode Error: ", error);
        toast.error("Update Categories Error");
      })
      .finally(() => {
        dispatch(setCompaniesLoader("editLoader", false));
      });
  };
}

export const getCompanyProfilePic = (profilePicId) => {
  return async (dispatch) => {
    dispatch(setCompaniesLoader("companyPicLoader", true));

    if (profilePicId) {
      const profilePayload = {
        ids: [profilePicId],
        size: ProfilePicType.Mobile,
      };

      await apiCompanyGetMediaLinks
        .post(profilePayload)
        .then((response) => {
          if (response && response.status === 200) {
            console.log("MediaLinkResponse: Company ", response.data);

            const mediaLinks = response.data.links;

            dispatch(setCompanyProfilePic(mediaLinks[0].link));
          } else {
            console.log("Media Fetch Error l: ", response);
          }
        })
        .catch((error) => {
          console.error("Media Fetch Error: ", error);
        })
        .finally(() => {
          dispatch(setCompaniesLoader("companyPicLoader", false));
        });
    }
  };
};
