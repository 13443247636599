import { CircularProgress, Typography, useTheme } from "@material-ui/core";
import { mdiDelete, mdiFilePlus } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnapItem, SnapList, useDragToScroll } from "react-snaplist-carousel";
import {
  deleteServiceMedia,
  getServiceMediaLinks,
  getResolvedServiceMediasByIds,
  processAndUploadServiceMedia,
} from "../../actions/serviceActions";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import DocumentUploadCNO from "../../library/DocumentUploadCNO";
import { MediaType } from "../../utils";
import ConfirmYesNoDialog from "../ConfirmYesNoDialog/ConfirmYesNoDialog";
import FormBox from "../FormBox";
import { DocumentSelectDisplayCard } from "../DocumentTypeDisplayCard";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import RightsManager from "../../utils/RightsManager";
import ImagesViewerSliderCNO from "../../library/ImagesViewerSliderCNO/ImagesViewerSliderCNO";

function ServiceMediaDetailsCard({ service }) {
  if(!service) return  <div></div>;

  const isUserAdmin = RightsManager.isAdmin();
  const isUserMember = RightsManager.isMember();
  const isUserCustomer = RightsManager.isCustomer();

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  console.log("==========ServiceMediaDetailsCard=======Services=====", service);

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  const dispatch = useDispatch();
  const {
    uploadServiceMediaLoader,
    deleteServiceMediaLoader,
    getServiceMediaLoader,
  } = useSelector((state) => state.services);

  const ServiceMedias = service.mediaLinks;

  console.log(
    "==========ServiceMediaDetailsCard=======ServiceMedias=====",
    ServiceMedias
  );

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isImageViewerVisible, setIsImageViewerVisible] = useState(false);
  const [mediasUploadCount, setMediasUploadCount] = useState(0);
  const [resolvedMediasLocal, setResolvedMediasLocal] = useState([]);

  useEffect(() => {
    const payload = {
      mediaLinks: ServiceMedias,
    };

    dispatch(getServiceMediaLinks(payload));
  }, []);

  const handleMediaUpload = async (files) => {
    if (files && files.length) {
      setMediasUploadCount(files.length);

      const payload = {
        mediasToUpload: files,
        mediaLinks: service.mediaLinks,
        ServiceId: service.id,
        mediaType: MediaType.Input,
      };

      await dispatch(processAndUploadServiceMedia(payload));
    }
  };

  const handleDelete = () => {
    const payload = {
      id: selectedCard.id,
      ServiceId: service.id,
      mediaLinks: service.mediaLinks,
    };

    dispatch(deleteServiceMedia(payload));
  };

  const onResolveMediasByIds = async (resolvedMedia, _, openMedia) => {
    if (openMedia) {
      return;
    }

    if (resolvedMedia) {
      const resolvedMediaIdsLocal = resolvedMediasLocal.map(
        (image) => image.id
      );

      const _resolvedJobMedias = ServiceMedias.map((media, index) => {
        if (media.id === resolvedMedia.id) {
          return {
            ...resolvedMedia,
            uri: resolvedMedia.isImage ? resolvedMedia.link : media.link,
            mediaOpenLink: resolvedMedia.link,
          };
        } else if (resolvedMediaIdsLocal.includes(media.id)) {
          return resolvedMediasLocal[index];
        } else {
          return {
            ...media,
            uri: media.link,
            thumbnailUri: media.link,
            isResolved: false,
            isImage: false,
          };
        }
      });

      setResolvedMediasLocal(_resolvedJobMedias);
      setIsImageViewerVisible(true);
    }
  };

  const onChangeImageIndex = (index) => {
    const nextImage = resolvedMediasLocal[index];
    setCurrentImageIndex(index);

    if (nextImage.isResolved) return;

    const payload = {
      clickedMedia: nextImage,
      allMedias: ServiceMedias,
    };

    dispatch(
      getResolvedServiceMediasByIds(payload, onResolveMediasByIds, false)
    );
  };

  const renderMediaThumbnail = (file, index) => {
    return (
      <SnapItem key={file.id} margin={{ right: 5, left: 5 }}>
        <div className={GlobalStyles.documentCard}>
          <div
            className={GlobalStyles.documentThumbnail}
            onClick={() => {
              const payload = {
                clickedMedia: file,
                allMedias: ServiceMedias,
              };

              dispatch(
                getResolvedServiceMediasByIds(payload, onResolveMediasByIds)
              );

              setCurrentImageIndex(index);
            }}
          >
            <DocumentSelectDisplayCard document={file} />
          </div>

          {isUserAdmin && (
            <div
              className={GlobalStyles.documentActionRow}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className={GlobalStyles.documentActions}
                onClick={() => {
                  setIsDeleteConfirm(true);
                  setSelectedCard(file);
                }}
              >
                <Icon
                  path={mdiDelete}
                  size="1.5rem"
                  color={theme.colors.ERROR_COLOR}
                />
              </div>
            </div>
          )}
        </div>
      </SnapItem>
    );
  };

  console.log("====loader check====", uploadServiceMediaLoader);
  console.log(
    "====ServiceMedias====resolvedMediasLocal=====",
    resolvedMediasLocal
  );
  
  return (
    <FormBox
      title="Medias"
      bgColor={theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR}
      titleColor={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR}
    >
      {(deleteServiceMediaLoader || getServiceMediaLoader) && (
        <FullScreenLoaderCNO />
      )}

      {!ServiceMedias.length &&
      !uploadServiceMediaLoader &&
      (isUserAdmin || isUserMember) ? (
        <div className={GlobalStyles.documentUploadWrapper}>
          <DocumentUploadCNO
            pickerIcon={{
              icon: mdiFilePlus,
              size: "3rem",
              color: theme.colors.IMAGE_UPLOAD_ICON_COLOR,
            }}
            multiple={true}
            onUpload={async (files) => {
              console.log("====onUpload====files====", files);

              handleMediaUpload(files);
            }}
          />
        </div>
      ) : (
        <Typography align="center">
          {ServiceMedias.length || uploadServiceMediaLoader
            ? ""
            : "No document found."}
        </Typography>
      )}

      <div className={GlobalStyles.documentThumbnailWrapper}>
        <SnapList ref={snapList}>
          {uploadServiceMediaLoader
            ? [...Array(mediasUploadCount).keys()].map((_, id) => (
                <SnapItem key={id} margin={{ right: 5, left: 5 }}>
                  <div className={GlobalStyles.documentLoader}>
                    <CircularProgress />
                  </div>
                </SnapItem>
              ))
            : ""}
          {ServiceMedias.length
            ? ServiceMedias.map((service, index) =>
                renderMediaThumbnail(service, index)
              )
            : null}
        </SnapList>
      </div>

      {ServiceMedias.length &&
      !uploadServiceMediaLoader &&
      (isUserAdmin || isUserMember) ? (
        <div className={GlobalStyles.documentAddMediaAction}>
          <DocumentUploadCNO
            pickerIcon={{
              icon: mdiFilePlus,
              size: "1.5rem",
              color: theme.colors.IMAGE_UPLOAD_ICON_COLOR,
            }}
            multiple={true}
            onUpload={async (files) => {
              console.log("====onUpload====files====", files);

              handleMediaUpload(files);
            }}
          />
        </div>
      ) : null}

      <ImagesViewerSliderCNO
        images={resolvedMediasLocal.map((image) => ({
          ...image,
          src: image.uri,
        }))}
        currentImageIndex={currentImageIndex}
        isOpen={isImageViewerVisible}
        onClose={() => {
          setIsImageViewerVisible(false);
          setResolvedMediasLocal([]);
        }}
        onChangeImageIndex={onChangeImageIndex}
        isMediaLoading={getServiceMediaLoader}
      />

      {isDeleteConfirm && (
        <ConfirmYesNoDialog
          open={isDeleteConfirm}
          setOpen={setIsDeleteConfirm}
          message={`Are you sure, you want to delete?`}
          onConfirm={handleDelete}
        />
      )}
    </FormBox>
  );
}

export default ServiceMediaDetailsCard;
