// import { applyMiddleware, createStore } from 'redux';
// import thunk from 'redux-thunk';
// import rootReducer from '../reducers/rootReducer';

// export const store = createStore(rootReducer, applyMiddleware(thunk));

import { applyMiddleware, createStore, compose } from 'redux'; // Import compose
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';

// Check if Redux DevTools Extension is installed and use it, otherwise use compose
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
