import { Typography } from "@material-ui/core";
import { mdiCurrencyGbp } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { useStyles } from "./ServiceProviderWorkingDetailsStyles";
import DialogCNO from "../../library/DialogCNO";
import { useFormik } from "formik";
import * as yup from "yup";
import InputCNO from "../../library/InputCNO";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import { useDispatch, useSelector } from "react-redux";
// import { updateEmployeeDetails } from '../../actions/employeeActions';
import { updateServiceProviderDetails } from "../../actions/serviceProviderActions";
import RightsManager from "../../utils/RightsManager";
import * as icons from "@mdi/js";
import { Currency, CurrencyIcon } from "../../utils/mappings";
import { getMaterialIconString } from "../../utils/icons";
import { PostGroupedMedia } from "../../pages/PostsManagement";
import { ServiceCategories } from "../../utils/mappings";
import { ChipCNO } from "../../library";
import { SnapList } from "react-snaplist-carousel";
import { SnapItem } from "react-snaplist-carousel";

function EditWorkingDetails({ profile, open, onClose }) {
  let currencyType = localStorage.getItem("currencyType");
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const styles = useStyles();

  const dispatch = useDispatch();

  const {
    allServiceProvidersLoader: { editLoader: serviceProviderEditLoader },
  } = useSelector((state) => state.serviceProviders);

  const validationSchema = yup.object({
    hourlyRate: yup.string().required("Required!!!"),
    dailyRate: yup.string().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      hourlyRate: profile.hourlyRate ? profile.hourlyRate.toString() : "",
      dailyRate: profile.dailyRate ? profile.dailyRate.toString() : "",
      experience: profile.experience ? profile.experience.toString() : "",
    },
    validationSchema,
    onSubmit: (values) => handleUpdate(values),
  });

  const handleUpdate = (values) => {
    const dataToSend = {
      ...profile,
      hourlyRate: formik.values.hourlyRate ? formik.values.hourlyRate : 0,
      dailyRate: formik.values.dailyRate ? formik.values.dailyRate : 0,
      experience: formik.values.experience ? formik.values.experience : 0,
    };

    dispatch(updateServiceProviderDetails(dataToSend));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Working Details"
      loading={serviceProviderEditLoader}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <InputCNO
          name="hourlyRate"
          label="Job Price"
          formik={formik}
          placeholder="Enter Job Rate"
          icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
          fullWidth
        />
        <InputCNO
          name="dailyRate"
          label="Job Price"
          formik={formik}
          placeholder="Enter Job Rate"
          icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
          fullWidth
        />
        <InputCNO
          name="experience"
          label="experience"
          formik={formik}
          placeholder="Enter experience"
          icon={icons.mdiAlphaACircleOutline}
          fullWidth
        />

        <InputCNO
          name="availability"
          label="Availability"
          formik={formik}
          placeholder="Enter Availability"
          icon={icons.mdiClockTimeEightOutline}
          fullWidth
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  );
}

function ServiceProviderWorkingDetails({
  profile,
  isActiveMember,
  localProfilePicUrl,
}) {
  let currencyType = localStorage.getItem("currencyType");
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const styles = useStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const [isWorkingEditable, setIsWorkingEditable] = useState(false);

  return (
    <div className={styles.container_UserWorkingDetails}>
      <div className={styles.selectChips}>
          <Typography component="label">Categories</Typography>
          <SnapList className={styles.chipsWrapper}>
            {ServiceCategories.entities.map((category) => {
              if (profile.categories.includes(category.id)) {
                return (
                  <SnapItem>
                    <ChipCNO
                      icon={category.iconLink}
                      name={category.title}
                    />
                  </SnapItem>
                );
              }
            })}
          </SnapList>
        </div>
        
      <Typography className={styles.title}>Working Details</Typography>

      <div className={styles.listing}>
        
        <div
          className={styles.row}
          onClick={() => {
            setIsWorkingEditable(true);
          }}
        >
          <Icon
            path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            size="1.5rem"
            className={styles.icon}
          />
          <Typography>
            {profile.hourlyRate
              ? `${profile.hourlyRate} / hour`
              : "Click to add Work Rate"}
          </Typography>
        </div>

        <div
          className={styles.row}
          onClick={() => {
            setIsWorkingEditable(true);
          }}
        >
          <Icon
            path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            size="1.5rem"
            className={styles.icon}
          />
          <Typography>
            {profile.dailyRate
              ? `${profile.dailyRate} / Day`
              : "Click to add Work Rate"}
          </Typography>
        </div>

        <div
          className={styles.row}
          onClick={() => {
            setIsWorkingEditable(true);
          }}
        >
          <Icon
            path={icons.mdiAlphaECircleOutline}
            size="1.5rem"
            className={styles.icon}
          />
          <Typography>
            {profile.experience
              ? `${profile.experience} years`
              : "Click to add experience"}
          </Typography>
        </div>

        <div
          className={styles.row}
          onClick={() => {
            setIsWorkingEditable(true);
          }}
        >
          <Icon
            path={icons.mdiClockTimeEightOutline}
            size="1.5rem"
            className={styles.icon}
          />
          <Typography>
            {profile.availability
              ? `${profile.availability} `
              : "Click to add availability"}
          </Typography>
        </div>
      </div>

      {isWorkingEditable && isUserAdmin && isActiveMember && (
        <EditWorkingDetails
          open={isWorkingEditable}
          onClose={() => setIsWorkingEditable(false)}
          profile={profile}
          localProfilePicUrl={localProfilePicUrl}
        />
      )}
    </div>
  );
}

export default ServiceProviderWorkingDetails;
