import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { IconMain } from '../../../library';
import EmojiPicker from '../../../utils/ChatUtils/EmojiPicker';

const PostEmojiPicker = ({ onEmojiSelect }) => {

  const [isVisible, setIsVisible] = useState(false);

  
  const toggleEmojiPicker = () => {
    setIsVisible(prev => !prev); 
  };

  return (
    <Box>
      <IconMain
        name='emoticon'
        themeColorKey='EMOJI_ICON_COLOR'
        onClick={toggleEmojiPicker} 
      />

      {isVisible && (
        <EmojiPicker
          isEmojiPicker={isVisible}
          toggleEmojiPicker={toggleEmojiPicker} 
          onChange={onEmojiSelect}
        />
      )}
    </Box>
  );
};

export default PostEmojiPicker;
