import { Button, Typography, useTheme } from '@material-ui/core';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { batch, useDispatch, useSelector } from 'react-redux';
import { fetchQuotationItems, setIsNewQuotationItemAdd, setQuotationItems, toggleAddQuotationItemDialog, updateQuotationItem } from '../../actions/quotationItemActions';
import AddQuotationItemDialog from './AddQuotationItemDialog';
import QuotationItemDetailsListItem from './QuotationItemDetailsListItem';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import RightsManager from '../../utils/RightsManager';
import FakeSubTaskCard from '../../components/FakeSubTaskCard';
import DraggableLists from '../../library/DraggableLists';

function QuotationItemDetailsCard({ quotation, quotationItemType }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const dispatch = useDispatch();
  const {
    isAddQuotationItemDialog,
    quotationItems,
    quotationItemsLoader,
    addLoader,
    editLoader,
    deleteLoader,
    isNewQuotationItemAdded
  } = useSelector(state => state.quotationItems);

  const [isServerQuotationItemsVisible, setIsServerQuotationItemsVisible] = useState(false);

  const toggleIsServerQuotationItemsVisible = () => setIsServerQuotationItemsVisible(!isServerQuotationItemsVisible);

  useEffect(() => {
    return () => {
      setIsServerQuotationItemsVisible(false);
      dispatch(setQuotationItems([]));
    };
  }, []);

  const loadQuotationItemsFromServer = () => {
    setIsServerQuotationItemsVisible(true);
    if (quotation) {

      let refreshData = true;

      if (quotationItems.length) {
        const isItemsOfSameId = quotationItems.every(item => item.offerId === quotation.id);
        if (isItemsOfSameId) {
          refreshData = true;
        }
      } else {
        refreshData = true;
      }

      if (refreshData) {
        const payload = {
          offerId: quotation.id,
        };

        dispatch(fetchQuotationItems(payload));
      }

    }
  };

  useEffect(() => {
    if (isNewQuotationItemAdded) {
      loadQuotationItemsFromServer();
    }
    return () => {
      dispatch(setIsNewQuotationItemAdd(false));
    };
  }, [isNewQuotationItemAdded]);

  useEffect(() => {
    if (!quotationItems.length) {
      setIsServerQuotationItemsVisible(false);
    }
  }, [quotationItems]);

  return (
    <div className={GlobalStyles.detailsCardContainer} style={{ padding: '16px 8px' }}>
      <div className={GlobalStyles.detailsCardSectionTitle} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography>Quotation Items</Typography>
        {
          isUserAdmin &&
          <Button
            style={{
              background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
              color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
              borderColor: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
              fontSize: '0.7rem',
              fontWeight: 700
            }}
            startIcon={<Icon path={mdiPlus} size="1rem" />}
            onClick={async () => {

              batch(() => {

                dispatch(toggleAddQuotationItemDialog(true));

                dispatch(fetchQuotationItems({ offerId: quotation.id }));

              })

            }}
          >
            Add Quotation Item
          </Button>
        }

      </div>

      {
        !isServerQuotationItemsVisible &&
        <FakeSubTaskCard
          subItem={{
            id: quotation.oneOfferItemId,
            title: quotation.oneOfferItemTitle,
            categories: quotation.oneOfferItemCategories,
          }}
          onLoadFromServer={() => loadQuotationItemsFromServer()}
          emptyMessage={"No Quotation Item found."}
          isSubTask={false}
        />
      }

      {
        isServerQuotationItemsVisible && quotation.oneOfferItemId &&
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}>
          <p
            style={{
              color: theme.colors.LINK_COLOR_GREY,
              fontSize: '0.8rem',
              border: `1px solid ${theme.colors.LINK_COLOR_GREY}`,
              borderRadius: 4,
              padding: '4px 8px',
              cursor: 'pointer'
            }}
            onClick={toggleIsServerQuotationItemsVisible}
          >
            Load Less
          </p>
        </div>
      }

      {
        isServerQuotationItemsVisible &&
        <div className={GlobalStyles.subItemsListContainer} >

          <DraggableLists
            items={quotationItems}
            listItemPropName="quotationItem"
            renderNotFound={'No Quotation Item found'}
            loading={quotationItemsLoader}
            onDrop={(dragItems, reOrderItems, dropEvent) => {
              dispatch(setQuotationItems(reOrderItems));
              dragItems.map(item => dispatch(updateQuotationItem(item)));
            }}
          >
            <QuotationItemDetailsListItem quotation={quotation} />
          </DraggableLists>

        </div>
      }
      {
        (addLoader || editLoader || deleteLoader)
        &&
        <FullScreenLoaderCNO />
      }

      {
        isAddQuotationItemDialog &&
        <AddQuotationItemDialog
          quotation={quotation}
        />
      }

    </div>
  )
}

export default QuotationItemDetailsCard
