import { useFormik } from 'formik';
import React from 'react';
import { DateTimePickerCNO, DialogCNO, InputCNO } from '../../../library';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../../../components/FormActionButtons';
import { editEvent } from '../../../actions/eventActions';
import { useGlobalStyles } from '../../../utils/Styles/GlobalStyles';
import { validateStartDateEndDate } from '../../../utils/Helpers';

const EditEventTimeline = ({ open, onClose, event }) => {

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    startDate: yup
      .string()
      .required('Event start date is required!'),
    endDate: yup
      .string()
      .required('Event end date is required!')
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      ),
    startTime: yup
      .string()
      .required('Event start time is required!'),
    endTime: yup
      .string()
      .required('Event end time is required!')
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      ),
  });

  const handleEditEventTimeline = (values) => {

    const payload = {
      ...event,
      ...values,
    }

    dispatch(editEvent(payload, { onSuccess: onClose }));

  }

  const formik = useFormik({
    initialValues: {
      startDate: event.startDate,
      startTime: event.startTime,
      endDate: event.endDate,
      endTime: event.endTime,
    },
    validationSchema,
    onSubmit: handleEditEventTimeline
  });

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Event Timeline"
    >
      <form onSubmit={formik.handleSubmit}>
        <div className={GlobalStyles.sectionChildrenContainer}>

          <DateTimePickerCNO
            dateName="startDate"
            datePlaceholder="Start Date"
            datePickerLabel="Start by Date"
            onDateChange={(date) => {
              formik.setFieldValue("startDate", date);
            }}
            timeName="startTime"
            timePlaceholder="Start Time"
            timePickerLabel="Start by Time"
            onTimeChange={(date) => {
              formik.setFieldValue("startTime", date);
            }}
            formik={formik}
            disablePast={true}
            iconColor="red"
          />

          <DateTimePickerCNO
            dateName="endDate"
            datePlaceholder="Finish Date"
            datePickerLabel="Finish by Date"
            onDateChange={(date) => {
              formik.setFieldValue("endDate", date);
            }}
            timeName="endTime"
            timePlaceholder="Finish Time"
            timePickerLabel="Finish by Time"
            onTimeChange={(date) => {
              formik.setFieldValue("endTime", date);
            }}
            formik={formik}
            minDate={formik.values.startDate}
            iconColor="green"
          />
        </div>

        <FormActionButtons formik={formik} rightText="Save" leftClick={onClose} />

      </form>
    </DialogCNO>
  )
}

export default EditEventTimeline;