import {
  fade,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import Icon from "@mdi/react";
import React from "react";
import InputLabelCNO from "../InputLabelCNO";

const InputCNO = React.forwardRef(
  (
    {
      formik,
      inputValue,
      name,
      label,
      renderLabel,
      labelPosition,
      icon,
      rightIcon,
      color,
      className,
      fullWidth = true,
      isRequired = false,
      iconColor,
      rightIconColor,
      disabled = false,
      isBorderHighlight,
      showError = true,
      rows = "10",
      containerStyles,
      onLabelClick,
      onIconClick,
      onRightIconClick,
      isCopyAllowed = true,
      isPasteAllowed = true,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();

    const styles = useStyles({
      name,
      labelPosition,
      color,
      disabled,
      isBorderHighlight,
    });

    if (!name) return null;

    return (
      <div className={styles.container_InputCNO} style={containerStyles}>
        <InputLabelCNO
          label={label}
          isRequired={isRequired}
          onClick={onLabelClick}
          renderLabel={renderLabel}
        />
        {formik && (
          <TextField
            inputRef={ref}
            id={name}
            className={`${styles.textField} ${className}`}
            variant="outlined"
            margin="dense"
            rows={rows}
            rowsMax={"20"}
            disabled={disabled}
            fullWidth={fullWidth}
            {...formik.getFieldProps(name)}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={
              showError && formik.touched[name] && formik.errors[name]
            }
            InputProps={{
              ...(icon
                ? {
                    startAdornment: (
                      <InputAdornment>
                        <Icon
                          color={
                            iconColor
                              ? iconColor
                              : theme.colors.INPUT_ICON_COLOR
                          }
                          path={icon}
                          size="1.5rem"
                          onClick={onIconClick}
                          style={{
                            cursor: onIconClick ? "pointer" : "default",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }
                : {}),
              ...(rightIcon
                ? {
                    endAdornment: (
                      <InputAdornment>
                        <Icon
                          color={
                            rightIconColor
                              ? rightIconColor
                              : theme.colors.INPUT_ICON_COLOR
                          }
                          path={rightIcon}
                          size="1.5rem"
                          onClick={onRightIconClick}
                          style={{
                            cursor: onRightIconClick ? "pointer" : "default",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }
                : {}),
            }}
            {...(!isPasteAllowed
              ? {
                  onPaste: (e) => {
                    e.preventDefault();
                    return false;
                  },
                }
              : {})}
            {...(!isCopyAllowed
              ? {
                  onCopy: (e) => {
                    e.preventDefault();
                    return false;
                  },
                }
              : {})}
            {...rest}
          />
        )}
        {!formik && (
          <TextField
            value={inputValue}
            className={`${styles.textField} ${className}`}
            variant="outlined"
            margin="dense"
            rows="4"
            disabled={disabled}
            InputProps={
              icon && {
                startAdornment: (
                  <InputAdornment>
                    <Icon
                      color={
                        iconColor ? iconColor : theme.colors.INPUT_ICON_COLOR
                      }
                      path={icon}
                      size="1.5rem"
                    />
                  </InputAdornment>
                ),
              }
            }
            {...rest}
          />
        )}
      </div>
    );
  }
);

export default InputCNO;

const useStyles = makeStyles((theme) => ({
  container_InputCNO: {
    display: "flex",
    // flex: 1,
    marginRight: 10,
    flexDirection: ({ labelPosition }) =>
      labelPosition === "left" ? "row" : "column",
    alignItems: ({ labelPosition }) =>
      labelPosition === "left" ? "center" : "flex-start",
    justifyContent: ({ labelPosition }) =>
      labelPosition === "left" ? "space-between" : "flex-start",
    width: ({ labelPosition }) => (labelPosition === "left" ? "100%" : "auto"),
  },
  label: {
    // flex: 1,
    marginRight: 5,
    color: theme.colors.FORM_LABEL_COLOR,
    // marginTop: theme.spacing(2),
  },
  textField: {
    opacity: ({ disabled }) => (disabled ? 0.8 : 1),
    width: "100%",
    borderRadius: 5,
    "& input": {
      background: "white",
      color: ({ color, disabled }) =>
        disabled
          ? fade(color || theme.colors.INPUT_TEXT_COLOR, 0.8)
          : color || theme.colors.INPUT_TEXT_COLOR,
      borderRadius: 5,
      padding: 8,
    },
    "& .MuiOutlinedInput-multiline": {
      color: ({ color }) => (color ? color : theme.colors.INPUT_TEXT_COLOR),
      background: "white",
    },
    "& .MuiOutlinedInput-adornedStart": {
      background: "white",
      borderRadius: 5,
    },
    "& .MuiOutlinedInput": {
      background: "white",
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
      "-webkit-box-shadow": `0 0 0 100px ${theme.colors.INPUT_BACKGROUND_COLOR} inset`,
      "-webkit-text-fill-color": theme.colors.INPUT_TEXT_COLOR,
      caretColor: ({ color }) =>
        color ? color : theme.colors.INPUT_TEXT_COLOR,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: ({ isBorderHighlight }) =>
        isBorderHighlight && "2px solid orange",
    },
  },
}));
