import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import JobTypeIcon from "../JobTypeIcon/JobTypeIcon";
import EditJobTitleDialog from "../EditJobTitleDialog/EditJobTitleDialog";
import EditJobTypeDialog from "../EditJobTypeDialog/EditJobTypeDialog";
import EditJobDescriptionDialog from "../EditJobDescriptionDialog/EditJobDescriptionDialog";
import RightsManager from "../../utils/RightsManager";
import { getCategory } from "../../utils/Helpers";

function JobDetailsCard({ job, setJob }) {
  const isUserAdmin = RightsManager.isAdmin();

  const [isJobTitleEditable, setIsJobTitleEditable] = useState(false);
  const [isJobTypeEditable, setIsJobTypeEditable] = useState(false);
  const [isJobDescriptionEditable, setIsJobDescriptionEditable] =
    useState(false);

  const tillTablet = useMediaQuery("(max-width: 767px)");
  const styles = useStyles({ tillTablet });

  const jobCategory = getCategory(job.categories[0]);

  return (
    <div className={styles.container}>
      <JobTypeIcon
        iconLink={jobCategory?.iconLink}
        name={jobCategory?.title}
        onClick={() => setIsJobTypeEditable(true)}
        categoryId={jobCategory?.id}
      />
      <div className={styles.jobDetails}>
        <Typography
          variant="h6"
          className={styles.jobTitle}
          onClick={() => setIsJobTitleEditable(true)}
        >
          {job.title}
        </Typography>
        <pre
          className={styles.jobDescription}
          onClick={() => setIsJobDescriptionEditable(true)}
        >
          {job.description || "Job details not added"}
        </pre>
      </div>
      {isJobTitleEditable && isUserAdmin && (
        <EditJobTitleDialog
          job={job}
          setJob={setJob}
          isJobTitleEditable={isJobTitleEditable}
          setIsJobTitleEditable={setIsJobTitleEditable}
        />
      )}

      {isJobTypeEditable && isUserAdmin && (
        <EditJobTypeDialog
          job={job}
          setJob={setJob}
          isJobTypeEditable={isJobTypeEditable}
          setIsJobTypeEditable={setIsJobTypeEditable}
        />
      )}
      {isJobDescriptionEditable && isUserAdmin && (
        <EditJobDescriptionDialog
          job={job}
          setJob={setJob}
          isJobDescriptionEditable={isJobDescriptionEditable}
          setIsJobDescriptionEditable={setIsJobDescriptionEditable}
        />
      )}
    </div>
  );
}

export default JobDetailsCard;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`,
  },
  jobDetails: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  jobTitle: {
    color: theme.colors.JOB_DETAIL_TITLE_COLOR,
    fontWeight: "700",
    flex: 1,
  },
  jobDescription: {
    fontSize: "14px",
    whiteSpace: "pre-wrap",
    minHeight: 15,
    color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR,
  },
  moreIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  wrapper: {
    display: "flex",
  },
}));
