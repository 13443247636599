import { Button, makeStyles, useTheme } from '@material-ui/core';
import { mdiAttachment, mdiFormatText, mdiPencil } from '@mdi/js';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateInvoiceStatus } from '../../actions/invoiceActions';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import { fetchCustomerSuggest } from '../../actions/suggestionsActions';
import { EmailPreTextType, EmailPreTextTypeReverse, InvoiceStatus } from '../../utils/mappings';
import InputLabelCNO from '../../library/InputLabelCNO';
import { getAppointmentSendSummaryHtmlString, getInvoiceSendSummaryHtmlString, getQuotationSendSummaryHtmlString } from '../../pdfTemplates/templates';
import { setInvoiceItems } from '../../actions/invoiceItemActions';
import RightsManager from '../../utils/RightsManager';
import { defaultMessageTop } from '../../pdfTemplates/templates/Invoice/InvoiceSendSummaryTemplateString';
import Icon from '@mdi/react';
import InvoiceEmailMessageEdit from './InvoiceEmailMessageEdit';
import { setCustomerById } from '../../actions/customerActions';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import SelectCNO from '../../library/SelectCNO';

function InvoiceSendDialog({ open, onClose, invoice, closeMoreActions }) {

  const isUserAdmin = RightsManager.isAdmin();

  const theme = useTheme();

  const styles = useStyles();

  const dispatch = useDispatch();
  const { allInvoiceLoader: { editLoader } } = useSelector(state => state.invoices);

  const suggestions = useSelector(state => state.suggestions);
  const { customerById } = useSelector(state => state.customers);

  const { invoiceItems } = useSelector(state => state.invoiceItems);

  const [isEmailMessageEdit, setIsEmailMessageEdit] = useState(false);

  const authUserCompany = LocalStorageConstants.getItem("authUserCompany");

  const validationSchema = yup.object({
    to: yup.object().required('Sender is required').nullable(),
    subject: yup.string().required('Subject is required'),
    message: yup.string(),
    attachment: yup.object()
  });

  const formik = useFormik({
    initialValues: {
      to: {},
      subject: invoice.title,
      emailData: "",
      message: "",
      attachment: {},
      emailPreTextType: EmailPreTextType.Invoice
    },
    validationSchema,
    onSubmit: (values) => handleSend(values)
  });

  useEffect(() => {

    const emailData = authUserCompany.invoiceEmailTemplate;

    const messageSummary = getInvoiceSendSummaryHtmlString(invoice, invoiceItems, emailData);

    formik.setValues({
      ...formik.values,
      to: {
        id: invoice.customerId,
        title: invoice.customerText,
        email: customerById ? customerById.email : null
      },
      subject: invoice.title,
      emailData: emailData,
      message: messageSummary,
      attachment: {
        title: `${invoice.title}.pdf`,
      }
    });

  }, [invoice, customerById]);

  useEffect(() => {

    let emailData = authUserCompany.invoiceEmailTemplate;
    let messageSummary = getInvoiceSendSummaryHtmlString(invoice, invoiceItems, emailData);

    switch (formik.values.emailPreTextType) {
      case EmailPreTextType.Quotation:
        emailData = authUserCompany.offerEmailTemplate;
        messageSummary = getQuotationSendSummaryHtmlString(invoice, invoiceItems, emailData);
        break;

      case EmailPreTextType.Invoice:
        emailData = authUserCompany.invoiceEmailTemplate;
        messageSummary = getInvoiceSendSummaryHtmlString(invoice, invoiceItems, emailData);
        break;

      case EmailPreTextType.Appointment:
        emailData = authUserCompany.appointmentEmailTemplate;
        messageSummary = getAppointmentSendSummaryHtmlString(invoice, emailData);
        break;

      default:
        break;
    }

    formik.setFieldValue('message', messageSummary);
    formik.setFieldValue('emailData', emailData);

  }, [formik.values.emailPreTextType]);


  const handleSend = (values) => {

    const updateInvoicePayload = {
      ...invoice,
      status: InvoiceStatus.Sent,
      meta: values.message,

    };

    dispatch(updateInvoiceStatus(updateInvoicePayload));

    handleClose();

  };

  const handleClose = () => {
    onClose();
    // dispatch(setInvoiceItems([]));
    formik.resetForm();
    dispatch(setCustomerById(null));

    if (closeMoreActions) {
      closeMoreActions();
    }

  };

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Send Invoice"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit} className={styles.form}>

        <AutocompleteCNO
          name='to'
          label='Contact Person'
          formik={formik}
          placeholder='Search Customer'
          // fullWidth
          data={suggestions}
          optionLabel={x => x.title || ""}
          renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
          onSuggestionClick={(value) => {
            formik.setFieldValue('customer', value);
          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchCustomerSuggest(value));
            }
          }}
          disabled={true}
        />

        <InputCNO
          name='subject'
          label='Subject'
          formik={formik}
          placeholder={"Enter subject"}
          icon={mdiFormatText}
          fullWidth
        />

        {/* <InputCNO
          name='subject'
          label='Attachment'
          formik={formik}
          placeholder={"Enter subject"}
          icon={mdiAttachment}
          fullWidth
          disabled
          value={formik.values.attachment.title}
        /> */}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <InputLabelCNO label={'Message'} />

          <Fragment>
            {
              isUserAdmin &&
              <SelectCNO
                formik={formik}
                name="emailPreTextType"
                options={Object.values(EmailPreTextType)
                  .filter(item => item !== EmailPreTextType.Appointment)
                  .map(item => ({ value: item, label: EmailPreTextTypeReverse[item] }))
                }
              />
            }

            {
              isUserAdmin &&
              <Button
                style={{
                  background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
                  color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
                  borderColor: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
                  fontSize: '0.7rem',
                  fontWeight: 700,
                  marginLeft: 8
                }}
                startIcon={<Icon path={mdiPencil} size="1rem" />}
                onClick={() => setIsEmailMessageEdit(true)}
              >
                Edit Message
              </Button>
            }
          </Fragment>

        </div>
        <div
          dangerouslySetInnerHTML={{ __html: formik.values.message }}
          style={{ background: 'white', padding: "20px 10px", borderRadius: 5 }}
        />

        <FormActionButtons
          rightText="Send"
          formik={formik}
          leftClick={handleClose}
          closeMoreActions={closeMoreActions}
        />

      </form>

      {
        isEmailMessageEdit &&
        <InvoiceEmailMessageEdit
          open={isEmailMessageEdit}
          onClose={() => setIsEmailMessageEdit(false)}
          emailData={formik.values.emailData}
          onSave={({ message }) => {
            const messageSummary = getInvoiceSendSummaryHtmlString(invoice, invoiceItems, message);
            formik.setFieldValue('emailData', message);
            formik.setFieldValue('message', messageSummary);
          }}
        />
      }

    </DialogCNO>
  )
}

export default InvoiceSendDialog


const useStyles = makeStyles((theme) => ({

  form: {
    '&>*': {
      marginBottom: theme.spacing(2)
    }
  }

}));