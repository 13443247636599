import { GiftedChat } from "react-native-gifted-chat";
import { toast } from "react-toastify";
import { apiMemberSearch, apiMessageCount, apiMessageCreate, apiMessageGroupCreate, apiMessageGroupMemberAssign, apiMessageGroupMemberRemove, apiMessageGroupMembers, apiMessageGroupSearch, apiMessageProfileCount, apiMessageSearch } from '../api';
import { MessageType } from "../utils";
import { getCreatedByUser, sortChatsList } from "../utils/ChatUtils/Helpers";
import LocalStorageConstants from '../utils/LocalStorageConstants';
import { getChatsWithoutDuplicate } from '../utils/ChatUtils/FilterDuplicateChats';
import { createBrowserHistory } from 'history';
import RightsManager from "../utils/RightsManager";
import { isArrayEmpty } from "../utils/Helpers";

export const SET_CHATS = "SET_CHATS";
export const SET_ACTIVE_CHAT_USER = "SET_ACTIVE_CHAT_USER";
export const FETCH_CHAT_USERS = "FETCH_CHAT_USERS";
export const CREATE_CHAT_GROUP = "CREATE_CHAT_GROUP";
export const SET_LOADERS = "SET_LOADERS";
export const SET_MESSAGE_TEXT = "SET_MESSAGE_TEXT";
export const SET_ACTIVE_GROUP_MEMBERS = "SET_ACTIVE_GROUP_MEMBERS";
export const TOGGLE_UNIVERSAL_LOADER = "TOGGLE_UNIVERSAL_LOADER";
export const SET_CHAT_MESSAGES = "SET_CHAT_MESSAGES";
export const SET_CURRENT_CHAT_USER = "SET_CURRENT_CHAT_USER";
export const SET_NEW_CHAT = "SET_NEW_CHAT";
export const REMOVE_GROUP_MEMBER = "REMOVE_GROUP_MEMBER";
export const SET_HAS_MORE_MESSAGES = "SET_HAS_MORE_MESSAGES";
export const MESSAGE_COUNT = "MESSAGE_COUNT";
export const MESSAGE_PROFILE_COUNT = "MESSAGE_PROFILE_COUNT";
export const SET_CHAT_SNIPPETS_LIST = "SET_CHAT_SNIPPETS_LIST";
export const SET_CHAT_GROUPS = "SET_CHAT_GROUPS";

export const TOGGLE_CALL_MODAL = "TOGGLE_CALL_MODAL";
export const SET_MESSAGE_RECEIVED = "SET_MESSAGE_RECEIVED";
export const TOGGLE_CALL_MODAL_OPEN_ONCE = "TOGGLE_CALL_MODAL_OPEN_ONCE";


export function setMessageReceived(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_MESSAGE_RECEIVED,
      payload: payload
    });
  };
}

export function toggleCallModal(openModal) {
  console.log("------------openModal-------", openModal);
  return (dispatch, getState) => {
    dispatch({
      type: TOGGLE_CALL_MODAL,
      payload: openModal
    });
  };
}

export function toggleCallModalOpenOnce(openModal) {
  return (dispatch, getState) => {
    dispatch({
      type: TOGGLE_CALL_MODAL_OPEN_ONCE,
      payload: openModal
    });
  };
}

export function toggleUniversalLoader(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_UNIVERSAL_LOADER,
      payload: status
    });
  };
}

export function setChatMessages(payload, systemMessages = []) {
  return async (dispatch, getState) => {
    const { activeChatUser } = getState().chats;

    console.log("message received: allMessages: ", payload);
    dispatch({
      type: SET_CHAT_MESSAGES,
      payload: payload
    });
    // console.log("offlinechats: onfetch: saving user chats to local");
    // localStorage.setItem(LocalStorageConstants.localChatMessages(activeChatUser.id), JSON.stringify(payload));
  };
}

export function setNewChat(chat) {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_CHAT,
      payload: chat
    });
  };
}

export function setMessageCount(messageCountPayload) {
  return async (dispatch) => {
    dispatch({
      type: MESSAGE_COUNT,
      payload: messageCountPayload
    });
  };
}

export function fetchMessageCount() {
  return async (dispatch) => {
    await apiMessageCount.post({})
      .then((response) => {
        console.log("msgCnt: Message Count Response: ", response);

        if (response.status === 200) {
          // dispatch(setMessageCount(5));
          dispatch(setMessageCount(response.data.count));
        }
        else {
          console.error("Error in Message Count: else");
        }
      })
      .catch((error) => {
        console.error("Error in Message Count: ", error);
      });
  };
}

export function setMessageProfileCount(chats, messageProfileCountPayload) {
  return async (dispatch, getState) => {

    dispatch({
      type: MESSAGE_PROFILE_COUNT,
      payload: messageProfileCountPayload
    });

    if (chats.length === 0) {
      chats = getState().chats.chats;
    }

    // for lookup of count based on chat user id
    const messageProfileCountMap = messageProfileCountPayload.reduce((result, item) => {
      result = {
        ...result,
        [item.objId]: item
      };
      return result;
    }, {});

    const profileIdsOfCountKeys = Object.keys(messageProfileCountMap || {});

    const chatsPayload = chats.map((chat) => {
      let updatedChat = {
        id: chat.id,
        contactName: `${chat.firstName} ${chat.lastName}`,
        contactAvatar: chat.profilePic,
        snippet: "",
        time: new Date(),
        contactType: "user",
        chatProfileMessageCount: 0,
        ...chat,
      };

      // check if user has messageProfileCount
      if (profileIdsOfCountKeys.includes(updatedChat.id)) {
        return {
          ...updatedChat,
          chatProfileMessageCount: messageProfileCountMap[updatedChat.id].count
        };
      }

      return updatedChat;
    });

    dispatch({
      type: SET_CHATS,
      payload: chatsPayload
    });

  };
}

export function fetchMessageProfileCount() {
  return async (dispatch, getState) => {

    await apiMessageProfileCount.post({})
      .then((response) => {
        console.log("msgCnt: Message Profile Count Response: ", response);

        // const resp = {
        //     "profileCounts": [
        //         {
        //             "objId": "14fd03ad-75eb-11eb-9448-0a7666dc064a",
        //             "count": 3
        //         },
        //         {
        //             "objId": "2f9f766d-bedb-11eb-a373-00ffba546a08",
        //             "count": 2
        //         },
        //         {
        //             "objId": "35034545-3a0e-11eb-9b5c-00ffba546a08",
        //             "count": 4
        //         },
        //     ]
        // }

        if (response.status === 200) {
          // dispatch(setMessageProfileCount(chats, response.data.profileCounts));

          let profileMessageCountResp = response.data.profileCounts;
          profileMessageCountResp = profileMessageCountResp.map((profileCountItem) => {
            return {
              id: profileCountItem.objId,
              count: profileCountItem.count,
              time: profileCountItem.creationDate,
              messageType: profileCountItem.messageType,
              snippet: profileCountItem.text,
              contactName: profileCountItem.name,
              contactAvatar: profileCountItem.profilePic,
              contactType: profileCountItem.isGroup ? "group" : "user"
            };
          });

          dispatch({
            type: MESSAGE_PROFILE_COUNT,
            payload: profileMessageCountResp
          });

        }
        else {
          console.error("Error in Message Profile Count: else");
        }
      })
      .catch((error) => {
        console.error("Error in Message Profile Count: ", error);
      });
  };
}

export function setChatGroups(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_CHAT_GROUPS,
      payload: payload
    });
    localStorage.setItem("localChatGroupList", JSON.stringify(payload));

  };
}

export function fetchChatGroups(payload) {
  return async (dispatch) => {

    dispatch(toggleUniversalLoader(true));

    await apiMessageGroupSearch.post(payload)
      .then((response) => {
        if (response && response.status === 200) {
          let chatGroupsResponse = response.data.data;

          chatGroupsResponse = chatGroupsResponse.map((group) => ({
            ...group,
            id: group.id,
            contactName: group.title,
            contactAvatar: group.profilePic,
            snippet: '',
            time: null,
            contactType: 'group',
            count: 0
          }));

          dispatch(setChatGroups(chatGroupsResponse));
        }
      })
      .catch((error) => {
        console.error("Chat Group Fetch Error: ", error);
        toast.success("Error in fetching chat groups");
      })
      .finally(() => dispatch(toggleUniversalLoader(false)));

  };
}

export function fetchChats(payload) {
  return async (dispatch) => {

    dispatch(toggleUniversalLoader(true));

    await apiMessageGroupSearch.post(payload)
      .then((response) => {
        let chats = response.data.data;

        chats = chats.map((chat) => ({
          ...chat,
          id: chat.id,
          contactName: chat.title,
          contactAvatar: chat.profilePic,
          snippet: "",
          time: new Date(),
          contactType: "group",
          chatProfileMessageCount: 0
        }));

        // dispatch({
        //     type: SET_CHATS,
        //     payload: chats
        // });

        dispatch(fetchUsersChats(chats));

        dispatch(toggleUniversalLoader(false));
      })
      .catch((error) => {
        dispatch(toggleUniversalLoader(false));
        console.log("Group Fetch Error: ", error);
        toast.error("There is some issue!!!");
      });
  };
}

// chat users in ChatListSection
export function fetchUsersChats(chatsPrev) {
  return async (dispatch, getState) => {

    const authUser = JSON.parse(localStorage.getItem('authUser'));

    const { messageProfileCount } = getState().chats;

    console.log("merged check messageProfileCount: ", messageProfileCount);

    dispatch(toggleUniversalLoader(true));

    const payload = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      searchText: ""
    };

    await apiMemberSearch.post(payload)
      .then((response) => {
        dispatch(toggleUniversalLoader(false));

        let chats = response.data.data;

        chats = chats.filter((chat) => chat.id !== authUser.id);

        // combined chats of uses and group
        chats = [...chats, ...chatsPrev];

        chats = chats.map((chat) => {
          let updatedChat = {
            id: chat.id,
            contactName: `${chat.firstName} ${chat.lastName}`,
            contactAvatar: chat.profilePic,
            snippet: '',
            time: new Date(),
            contactType: "user",
            chatProfileMessageCount: 0,
            ...chat,
          };

          return updatedChat;
        });

        // dispatch({
        //     type: SET_CHATS,
        //     payload: chats
        // });

        dispatch(setMessageProfileCount(chats, messageProfileCount));

      })
      .catch((error) => {
        dispatch(toggleUniversalLoader(false));
        console.log("Fetch Chats Error: ", error);
        toast.success("There is some issue in fetching Chats!");
      });
  };
}

export function setLoaders(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADERS,
      payload: payload
    });
  };
}

export function setMessageText(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_MESSAGE_TEXT,
      payload: payload
    });
  };
}

export function setActiveChatUser(userChat) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_CHAT_USER,
      payload: userChat
    });
  };
}

export function fetchChatUsers(memberSearchPayload) {
  return async (dispatch) => {
    dispatch(toggleUniversalLoader(true));

    await apiMemberSearch.post(memberSearchPayload)
      .then((response) => {
        const users = response.data.data.map(user => ({
          ...user,
          isSelected: false
        }));

        dispatch({
          type: FETCH_CHAT_USERS,
          payload: users
        });
        dispatch(toggleUniversalLoader(false));
      })
      .catch((error) => {
        dispatch(toggleUniversalLoader(false));
        toast.error("There is some issue in fetching Members");
        console.log("Member Search Error: ", error);
      });
  };
}

export function createChatGroup(payload, contactAvatar, onChatGroupCreate) {
  return async (dispatch, getState) => {

    dispatch(setLoaders({ createGroupLoader: true }));

    const { chatGroups, chatSnippetList } = getState().chats;

    const authUser = JSON.parse(localStorage.getItem('authUser'));

    await apiMessageGroupCreate.post(payload)
      .then((response) => {
        console.log("Create group: ", response);
        dispatch(setLoaders({ createGroupLoader: false }));
        if (response.status === 200) {

          const chatGroupId = response.data;

          const group = {
            id: chatGroupId,
            contactName: payload.title,
            contactAvatar: contactAvatar || null,
            profilePic: contactAvatar || null,
            snippet: '',
            time: new Date(),
            contactType: 'group',
            createdBy: authUser.id
          };

          dispatch({
            type: SET_CHAT_GROUPS,
            payload: [group, ...chatGroups]
          });

          const newGroup = {
            id: chatGroupId,
            contactName: payload.title,
            contactAvatar: contactAvatar || null,
            snippet: '',
            time: null,
            contactType: 'group',
            createdBy: authUser.id,
            creationDate: new Date(),
          };
          localStorage.setItem("MyLocalGroupList", JSON.stringify([newGroup, ...chatGroups]));

          dispatch(setChatSnippetList([newGroup, ...chatSnippetList]));

          const currentProfile = {
            id: chatGroupId,
            contactName: payload.title,
            contactAvatar,
            snippet: '',
            time: null,
            contactType: 'group',
            count: 0
          };
          dispatch(setActiveChatUser(currentProfile));
          localStorage.setItem("lastActiveChatUser", JSON.stringify(currentProfile));

          if (onChatGroupCreate) {
            onChatGroupCreate(chatGroupId);
          } else {
            const history = createBrowserHistory();
            history.push(`/chat/messages/${chatGroupId}`);
          }

          toast.success("Chat Group Created Successfully!");
        }
        else {
          toast.success("There is some issue in creating Chat Group!");
        }
      })
      .catch((error) => {
        dispatch(setLoaders({ createGroupLoader: false }));
        toast.success("There is some issue in creating Chat Group!");
        console.log("Create Group Error: ", error);
      });
  };
}

export function fetchActiveGroupMembers(payload) {
  return async (dispatch) => {
    dispatch(toggleUniversalLoader(true));
    await apiMessageGroupMembers.post(payload)
      .then((response) => {
        // console.log("Group members fetch: ", response.data.data);
        dispatch({
          type: SET_ACTIVE_GROUP_MEMBERS,
          payload: response.data.data
        });
        dispatch(toggleUniversalLoader(false));
      })
      .catch((error) => {
        dispatch(toggleUniversalLoader(false));
        console.log("Group members fetch error: ", error);
      });
  };
}

export function removeUserFromChatGroup(payload) {
  return async (dispatch, getState) => {
    dispatch(toggleUniversalLoader(true));

    const { activeGroupMembers } = getState().chats;

    await apiMessageGroupMemberRemove.post(payload)
      .then((response) => {
        dispatch(toggleUniversalLoader(false));
        console.log("Member Removed from group", response);

        const members = activeGroupMembers.filter((member) => member.memberId !== payload[0].MemberId);

        console.log("group members in remove action: ", payload, members);

        dispatch({
          type: SET_ACTIVE_GROUP_MEMBERS,
          payload: members
        });
        toast.success("User removed from Group Successfully!");
      })
      .catch((error) => {
        dispatch(toggleUniversalLoader(false));
        toast.error("There is some issue in removing user!");
        console.log("Member Remove form Group Error: ", error);
      });
  };
}

export function assignUserToChatGroup(payload, userPayload) {
  return async (dispatch, getState) => {
    dispatch(toggleUniversalLoader(true));

    const { activeGroupMembers } = getState().chats;

    await apiMessageGroupMemberAssign.post(payload)
      .then((response) => {
        dispatch(toggleUniversalLoader(false));
        console.log("Assing user to chat: ", response);

        dispatch({
          type: SET_ACTIVE_GROUP_MEMBERS,
          payload: [...userPayload, ...activeGroupMembers]
        });
        toast.success("User added to Group Successfully!");
      })
      .catch((error) => {
        dispatch(toggleUniversalLoader(false));
        console.log("Assing user to Chat Group Error: ", error);
        toast.error("There is some is issue in adding user to Group!");
      });
  };
}

export const handleAssignRemoveChatGroupUsers = (groupId, groupMembers) => {
  return async (dispatch, getState) => {

    // get current group members
    const groupMemerPayload = {
      pageSiz: 10,
      pageIndex: 0,
      orderBy: "",
      sortDecending: true,
      groupId: groupId
    };
    await dispatch(fetchActiveGroupMembers(groupMemerPayload));
    const { activeGroupMembers } = getState().chats;

    // remove current group members
    const removeUsersFromGroupPayload = activeGroupMembers
      .filter(member => !member.isAdmin) //filter out admins
      .map((user) => ({
        Id: user.id,
        MemberId: user.memberId,
        MessageGroupId: groupId
      }));
    if (!isArrayEmpty(removeUsersFromGroupPayload)) {
      await dispatch(removeUserFromChatGroup(removeUsersFromGroupPayload));
    }

    const groupAdmins = activeGroupMembers.filter(member => member.isAdmin);

    // add new group members
    const addUserToGroupPayload = groupMembers
      .filter(member => !groupAdmins.some(admin => admin.memberId === member.staffId)) //filter out admins
      .map((user) => ({
        MemberId: user.staffId,
        MessageGroupId: groupId
      }));
    if (!isArrayEmpty(addUserToGroupPayload)) {
      await dispatch(assignUserToChatGroup(addUserToGroupPayload, []));
    }

  }
}

export function setCurrentChatUser(createdById) {
  return async (dispatch, getState) => {
    const { chatUsers } = getState().chats.chats;

    if (!chatUsers.length) {
      const payload = {
        pageSize: 10,
        pageIndex: 0,
        orderBy: "newest",
        sortAscending: false,
        searchText: ""
      };
      dispatch(fetchChatUsers(payload));
    }
    else {
      let admin = chatUsers.filter(user => user.id === createdById)[0];
      admin = {
        firstName: admin.firstName,
        id: null,
        isAdmin: true,
        lastName: admin.lastName,
        memberId: admin.id,
        profilePic: admin.profilePic,
      };
      dispatch({
        type: SET_CURRENT_CHAT_USER,
        payload: admin
      });
    }

  };
}

export function fetchChatMessages(payload, systemMessages = []) {
  return async (dispatch, getState) => {

    const { activeGroupMembers, chatSnippetList, activeChatUser } = getState().chats;

    await apiMessageSearch.post(payload)
      .then((response) => {
        console.log("Chat Messages Reponse: ", response.data.data);
        if (response.status === 200) {

          let messagePayload = response.data.data.map(message => ({
            ...message,
            _id: message.id,
            user: getCreatedByUser(message.createdBy, activeGroupMembers),
            createdAt: message.creationDate,
            image: message.messageType === MessageType.Image ? message.url : null,
            text: message.text ? (
              message.text.includes("##Shared_Location##")
                ? null
                : message.text
            ) : (null),
            location: message.text ? (
              message.text.includes("##Shared_Location##")
                ? JSON.parse(message.text)["##Shared_Location##"]
                : null
            ) : (null),
            video: message.messageType === MessageType.Video ? message.url : null,
          }));

          // messagePayload = [...systemMessages, ...messagePayload];

          if (messagePayload.length < 10 || messagePayload.length === 0) {
            dispatch(setHasMoreMessages(false));
            localStorage.setItem(LocalStorageConstants.isLastOfflineMessage(activeChatUser.id), JSON.stringify(true));
          }

          dispatch({
            type: SET_CHAT_MESSAGES,
            payload: [...systemMessages, ...messagePayload]
          });

          // save chats of user to local
          console.log("offlinechats: onfetch: saving user chats to local");
          localStorage.setItem(LocalStorageConstants.localChatMessages(activeChatUser.id), JSON.stringify(messagePayload));

          // save message to local chat profile list
          if (messagePayload.length) {
            const latestMessage = {
              ...activeChatUser,
              messageId: messagePayload[0].id,
              id: activeChatUser.id,
              contactName: activeChatUser.contactName,
              contactAvatar: activeChatUser.contactAvatar,
              contactType: activeChatUser.contactType,
              time: messagePayload[0].creationDate,
              snippet: messagePayload[0].text || "##Shared_Location##" || '',
              messageType: messagePayload[0].messageType,
              count: 0
            };
            console.log("saving message to local whileFetch: ", latestMessage);
            let newCleanList = chatSnippetList.filter(profile => profile.id !== latestMessage.id);

            console.log("whileFetch: fetchchatmesage chatSnippetList", chatSnippetList);
            console.log("whileFetch: fetchchatmesage newCleanList", newCleanList);

            let newLocalProfileList = sortChatsList([latestMessage, ...newCleanList]);
            dispatch(setChatSnippetList(newLocalProfileList));
            console.log("whileFetch: fetchchatmesage  newLocalProfileList :", newLocalProfileList);

          }

        }
        else {
          console.log("Chat Message Fetch Error else");
          toast.error("Error in fetching messages");
        }
      })
      .catch((error) => {
        console.error("Chat Message Fetch Error: ", error);
        toast.error("Error in fetching messages");
      });
  };
}

export function setHasMoreMessages(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_MORE_MESSAGES,
      payload: payload
    });
  };
}

export function fetchMoreChatMessages(payload, oldMessages) {
  return async (dispatch, getState) => {

    dispatch(setLoaders({ loadMoreChatLoader: true }));

    const { activeGroupMembers, activeChatUser } = getState().chats;

    let localChatMessages = localStorage.getItem(LocalStorageConstants.localChatMessages(activeChatUser.id));

    let lastMessageInLocal = null;
    let lastMessageDate = null;

    if (!localChatMessages) {
      localChatMessages = [];
    }
    else {
      localChatMessages = JSON.parse(localChatMessages);
      lastMessageInLocal = localChatMessages[localChatMessages.length - 1];
      lastMessageDate = lastMessageInLocal.creationDate;
    }

    console.log("on scroll: last message: ", lastMessageInLocal);
    console.log("on scroll: last message: date: ", lastMessageDate);

    const morePayload = {
      ...payload,
      toDateTime: lastMessageDate,
      zeroStart: true
    };


    await apiMessageSearch.post(morePayload)
      .then((response) => {
        console.log("MOre Chat Messages Reponse: ", response.data.data);
        if (response.status === 200) {

          if (response.data.data.length > 0) {
            const messagePayload = response.data.data.map(message => ({
              ...message,
              _id: message.id,
              user: getCreatedByUser(message.createdBy, activeGroupMembers),
              createdAt: message.creationDate,
              image: message.messageType === MessageType.Image ? message.url : null,
              text: message.text ? (
                message.text.includes("##Shared_Location##")
                  ? null
                  : message.text
              ) : (null),
              location: message.text ? (
                message.text.includes("##Shared_Location##")
                  ? JSON.parse(message.text)["##Shared_Location##"]
                  : null
              ) : (null),
              video: message.messageType === MessageType.Video ? message.url : null,
            }));

            console.log("moremore: ", messagePayload);

            if (messagePayload.length < 10) {
              console.log("moremore nomore");
              dispatch(setHasMoreMessages(false));
              console.log("onscroll: doing isLastOfflineMessage to false as no more messages on server");
              localStorage.setItem(LocalStorageConstants.isLastOfflineMessage(activeChatUser.id), JSON.stringify(true));
            }

            // console.log("all more messages: ", [...oldMessages, ...messagePayload,]);

            const filteredMessages = getChatsWithoutDuplicate(oldMessages, messagePayload);

            // setMessages(filteredMessages);
            dispatch(setChatMessages(filteredMessages));

            // console.log("onscroll: saving messages to local storage");
            localStorage.setItem(LocalStorageConstants.localChatMessages(activeChatUser.id), JSON.stringify(filteredMessages));

          }
          else {
            dispatch(setHasMoreMessages(false));
            console.log("onscroll: doing isLastOfflineMessage to false as no more messages on server");
            localStorage.setItem(LocalStorageConstants.isLastOfflineMessage(activeChatUser.id), JSON.stringify(true));
          }

          dispatch(setLoaders({ loadMoreChatLoader: false }));

        }
        else {
          console.log("Chat Message Fetch Error else");
          toast.error("Error in fetching messages");
        }
      })
      .catch((error) => {
        console.error("Chat Message Fetch Error: ", error);
        toast.error("Error in fetching messages");
      });
  };
}

export function createMessage(payload, setMessages) {
  return async (dispatch, getState) => {

    const { messages } = getState().chats;

    const authUser = JSON.parse(localStorage.getItem('authUser'));

    await apiMessageCreate.post(payload)
      .then((response) => {
        console.log("Message Create Response: ", response);
        if (response.status === 200) {
          const messagePayload = [
            ...messages,
            {
              ...messages[0],
              _id: response.data,
              text: messages[0].text || null,
              image: messages[0].url || null,
              video: messages[0].url || null,
              createdAt: (new Date()).toString(),
              user: {
                _id: authUser.id,
                name: authUser.firstName + " " + authUser.lastName,
                avatar: authUser.profilePic
              }
            }
          ];

          setMessages(previousMessages => GiftedChat.append(previousMessages,
            {
              ...messages[0],
              _id: response.data,
              text: messages[0].text || null,
              image: messages[0].url || null,
              video: messages[0].url || null,
              createdAt: (new Date()).toString(),
              user: {
                _id: authUser.id,
                name: authUser.firstName + " " + authUser.lastName,
                avatar: authUser.profilePic
              }
            }));

          dispatch({
            type: SET_CHAT_MESSAGES,
            payload: messagePayload
          });

        }
        else {
          console.log("Message Create Error: ", response);
          toast.error("Error in message send");
        }
      })
      .catch((error) => {
        console.error("Message Create Error: ", error);
        toast.error("Error in message send");
      });
  };
}

export function setChatSnippetList(chatSnippetList) {
  return (dispatch) => {

    const isUserCustomer = RightsManager.isCustomer();

    const chatsList = chatSnippetList.filter(chatSnippet => !(chatSnippet.contactType === 'user' && isUserCustomer));
    localStorage.setItem('ChatMessageSnippetList', JSON.stringify(chatsList));

    dispatch({
      type: SET_CHAT_SNIPPETS_LIST,
      payload: chatsList
    });
  };
}