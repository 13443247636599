import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  phoneInput: {
    '& input': {
      padding: '10px 5px',
      border: `1px solid ${theme.colors.INPUT_BORDER_COLOR}`,
      borderRadius: '5px'
    }
  }
}));