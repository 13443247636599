import { Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './UserTrackingDetailsCardStyles';
import ChipCNO from '../../library/ChipCNO';
import { TrackingMode } from '../../utils';
import { mdiCar, mdiMapMarkerPath, mdiWalk } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmployeeDetails } from '../../actions/employeeActions';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';

function UserTrackingDetailsCard({ profile, isActiveMember, localProfilePicUrl, isUserAdmin }) {

  const styles = useStyles();

  const dispatch = useDispatch();
  const { allEmployeesLoader: { editLoader: employeeEditLoader } } = useSelector(state => state.employees);

  const updateTrackingMode = (trackingMode) => {

    if (isActiveMember) {
      const employeeEditPayload = {
        ...profile,
        trackingMode: trackingMode,
        localProfilePicUrl: localProfilePicUrl
      };

      dispatch(updateEmployeeDetails(employeeEditPayload));
    }

  };

  return (
    <div className={styles.container_UserTrackingDetailsCard}>
      <Typography className={styles.title}>Tracking Activity Type</Typography>

      {
        employeeEditLoader &&
        <FullScreenLoaderCNO />
      }

      <div className={styles.chipsWrapper}>

        <ChipCNO
          icon={mdiMapMarkerPath}
          name="Others"
          active={profile.trackingMode === TrackingMode.Others}
          onClick={() => {
            if (isUserAdmin) {
              updateTrackingMode(TrackingMode.Others)
            }
          }
          }
        />

        <ChipCNO
          icon={mdiWalk}
          name="Fitness"
          active={profile.trackingMode === TrackingMode.Fitness}
          onClick={() => {
            if (isUserAdmin) {
              updateTrackingMode(TrackingMode.Fitness)
            }
          }
          }
        />

        <ChipCNO
          icon={mdiCar}
          name="Automotive"
          active={profile.trackingMode === TrackingMode.Automotive}
          onClick={() => {
            if (isUserAdmin) {
              updateTrackingMode(TrackingMode.Automotive)
            }
          }
          }
        />
      </div>
    </div>
  )
}

export default UserTrackingDetailsCard;
