import { Dialog, useTheme } from '@material-ui/core';
import React from 'react';
import HeadingBar from '../../components/HeadingBar/HeadingBar';
import { useStyles } from './DialogCNOStyles';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';

function DialogCNO({
  open,
  children,
  dialogTitle,
  onClose,
  width = '70vw',
  height,
  minHeight = 340,
  maxHeight = "90vh",
  bgColor,
  zIndex,
  headerIcon,
  onHeaderIconClick,
  isClickAwayClose = false,
  loading = false
}) {

  const theme = useTheme();
  const styles = useStyles({ width, bgColor, height, maxHeight, minHeight });

  return (
    <Dialog
      open={open}
      onClose={isClickAwayClose ? onClose : null}
      className={styles.container_DialogCNO}
      classes={{ paper: styles.paper }}
      style={{ zIndex: zIndex ? zIndex : theme.zIndex.modal }}
    >

      {
        loading &&
        <FullScreenLoaderCNO message='Loading...' />
      }

      {
        dialogTitle &&
        <HeadingBar
          title={dialogTitle}
          onClose={onHeaderIconClick ? onHeaderIconClick : onClose}
          icon={headerIcon}
        />
      }
      <div style={{ minHeight: minHeight - 40 }}>
        {children}
      </div>
    </Dialog>
  )
}

export default DialogCNO
