import { IconButton, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@material-ui/core';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import ConfirmationDialogCNO from '../../library/ConfirmationDialogCNO';
import { useDispatch } from 'react-redux';
import { deleteQuotationItem } from '../../actions/quotationItemActions';

function QuotationItemMoreActions({ quotationItem }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();

  const [isQuotationItemDeleteConfirmDialog, setIsQuotationItemDeleteConfirmDialog] = useState(false);

  const handleDeleteQuotationItem = () => {

    dispatch(deleteQuotationItem(quotationItem));

  };

  return (
    <div className={GlobalStyles.container_MorePopover}>
      <List className={GlobalStyles.list}>

        <ListItem className={GlobalStyles.listItem} onClick={() => setIsQuotationItemDeleteConfirmDialog(true)} >
          <ListItemText primary='Delete' />
          <ListItemIcon>
            <IconButton edge='end'>
              <Icon path={mdiDelete} size='1.5rem'
                color={theme.colors.ERROR_COLOR}
              />
            </IconButton>
          </ListItemIcon>
        </ListItem>

      </List>

      {
        isQuotationItemDeleteConfirmDialog &&
        <ConfirmationDialogCNO
          open={isQuotationItemDeleteConfirmDialog}
          onClose={() => setIsQuotationItemDeleteConfirmDialog(false)}
          dialogTitle="Delete Quotation Item"
          messages={["Are you sure you want to to delete?"]}
          rightText="Delete"
          yesClick={handleDeleteQuotationItem}
        />
      }

    </div>
  )
}

export default QuotationItemMoreActions
