import React from "react";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import JobTypeIcon from "../JobTypeIcon/JobTypeIcon";
import {
  formatTimeWithTZ,
  getCategory,
  parseDisplayNumber,
} from "../../utils/Helpers";
import LinkCNO from "../../library/LinkCNO";
import { Typography, useTheme } from "@material-ui/core";
import Moment from "react-moment";
import Icon from "@mdi/react";
import ResponsiveLinesCNO from "../../library/ResponsiveLinesCNO";
import QuotationStatusButtonBar from "./QuotationStatusButtonBar";
import MoreActionCNO from "../../library/MoreActionsCNO";
import QuotationListCardPopover from "./QuotationListCardPopover";
import { Currency, CurrencyIcon } from "../../utils/mappings";
import { getMaterialIconString } from "../../utils/icons";
import * as icons from "@mdi/js";
import LocalStorageConstants from "../../utils/LocalStorageConstants";
import QuotationGroupedMedia from "./QuotationGroupedMedia";
import clsx from "clsx";

function QuotationListCard({ quotation }) {
  const currencyType = LocalStorageConstants.getItem(
    "currencyType",
    Currency.GBP
  );

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const category = getCategory(quotation.categories[0]);

  return (
    <div className={clsx(GlobalStyles.listCardContainer, GlobalStyles.listCardFW)}>
      {/* {
        (isJobDeleteLoader || isShareJobLoader) &&
        <FullScreenLoaderCNO />
      } */}
      <div className={GlobalStyles.cardInfoWrapper}>
        <JobTypeIcon
          iconLink={category?.iconLink}
          name={category?.title}
          categoryId={quotation.categories[0]}
        />
        <div className={GlobalStyles.cardInfo}>
          <LinkCNO to={`/quotation/${quotation.id}`}>
            <Typography className={GlobalStyles.cardTitle}>
              {quotation.title}
            </Typography>
            <Typography className={GlobalStyles.cardTitleHelper}>
              Quotation# {quotation.offerNumber}
            </Typography>
            <div className={GlobalStyles.datesWrapper}>
              <div className={GlobalStyles.cardDate}>
                <p>Sent on:&nbsp;</p>
                <Moment element="p" format="MMM DD, YYYY">
                  {formatTimeWithTZ(quotation.offerDate)}
                </Moment>
              </div>
              <span>&nbsp; &nbsp;</span>
              <div className={GlobalStyles.cardDate}>
                <p>Valid for days:&nbsp;{quotation.dueInDays}</p>
                {/* <Moment element="p" format="MMM DD, YYYY" >
                  {quotation.validDate}
                </Moment> */}
              </div>
            </div>

            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                color: theme.colors.LIST_CARD_USERNAMES_COLOR,
              }}
            >
              Estimated Amount:
              <Icon
                path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
                size="1rem"
                style={{ marginLeft: 4 }}
              />
              {parseDisplayNumber(quotation.totalAmount)}
            </Typography>
          </LinkCNO>
        </div>

        <MoreActionCNO>
          <QuotationListCardPopover quotation={quotation} />
        </MoreActionCNO>
      </div>

      {/* <LinkCNO to={`/quotation/${quotation.id}`}>
        <pre className={GlobalStyles.cardDescription}>
          <ResponsiveLinesCNO
            text={quotation.description || "Quotation details not added"}
            maxLine='10'
            ellipsis='...'
            trimRight
            basedOn='words'
          />
        </pre>
      </LinkCNO> */}

      <QuotationGroupedMedia quotation={quotation} />

      <QuotationStatusButtonBar quotation={quotation} />
    </div>
  );
}

export default QuotationListCard;
