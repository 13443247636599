import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import FormActionButtons from '../FormActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubTask } from '../../actions/subTaskActions';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';
import { validateStartDateEndDate } from '../../utils/Helpers';

function EditSubTaskTimeline({
  open,
  onClose,
  subTask,
}) {

  const dispatch = useDispatch();
  const { editLoader } = useSelector(state => state.subTasks);

  const formik = useFormik({
    initialValues: {
      startDate: subTask.startDate,
      startTime: subTask.startTime,
      endDate: subTask.endDate,
      endTime: subTask.endTime,
    },
    validationSchema: yup.object({
      startDate: yup.date().required('Start date is required'),
      startTime: yup.date().required('Start time is required'),
      endDate: yup.date()
        .required('End date is required')
        .test(
          'endDateTimeValidation',
          "End Date can't be less than Start Date.",
          function () {
            const startDate = this.parent.startDate;
            const startTime = this.parent.startTime;
            const endDate = this.parent.endDate;
            const endTime = this.parent.endTime;

            const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

            return isValid;
          }
        ),
      endTime: yup.date()
        .required('End time is required')
        .test(
          'endDateTimeValidation',
          "End Date can't be less than Start Date.",
          function () {
            const startDate = this.parent.startDate;
            const startTime = this.parent.startTime;
            const endDate = this.parent.endDate;
            const endTime = this.parent.endTime;

            const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

            return isValid;
          }
        ),
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("Edit Subtask: timeline: ", values);

    const payload = {
      ...subTask,
      startDate: values.startDate,
      startTime: values.startTime,
      endDate: values.endDate,
      endTime: values.endTime,
    };

    dispatch(updateSubTask(payload));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Subtask Timeline"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <div>

          <DateTimePickerCNO
            formik={formik}

            datePickerLabel='Start by Date'
            dateName={'startDate'}
            datePlaceholder={'Start Date'}
            onDateChange={date => formik.setFieldValue('startDate', date)}
            disablePast={true}

            timePickerLabel="Start by Time"
            timeName={'startTime'}
            onTimeChange={date => formik.setFieldValue('startTime', date)}
            timePlaceholder={'Start Time'}

            iconColor={'red'}
          />

          <DateTimePickerCNO
            formik={formik}

            datePickerLabel='Finish by Date'
            dateName={'endDate'}
            datePlaceholder={'Finish Date'}
            onDateChange={date => formik.setFieldValue('endDate', date)}
            disablePast={true}
            minDate={formik.values.startDate}

            timePickerLabel="Finish by Time"
            timeName={'endTime'}
            onTimeChange={date => formik.setFieldValue('endTime', date)}
            timePlaceholder={'Finish Time'}

            iconColor={'green'}
          />

        </div>

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditSubTaskTimeline
