import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubTask } from '../../actions/subTaskActions';

function EditSubTaskTitle({
  open,
  onClose,
  subTask,
}) {

  const dispatch = useDispatch();
  const { editLoader } = useSelector(state => state.subTasks);

  const formik = useFormik({
    initialValues: {
      title: subTask.title,
    },
    validationSchema: yup.object({
      title: yup.string().required('Title is required')
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("Edit Subtask: title: ", values);

    const payload = {
      ...subTask,
      title: values.title
    };

    dispatch(updateSubTask(payload, true));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Subtask Title"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name="title"
          label="Subtask Title"
          placeholder="Enter Subtask Title"
          icon={mdiFormatText}
          formik={formik}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditSubTaskTitle
