import React from 'react';
import Fade from 'react-reveal/Fade';
import Text from '../../../common/components/Text';
import Heading from '../../../common/components/Heading';
import Container from '../../../common/components/UI/Container';
import SectionWrapper, {
  ThumbWrapper,
  TextWrapper,
} from './availableStore.style';
import AndroidDownload from '../../../assets/google_play_download.png';
import IosDownload from '../../../assets/ios_app_store_download.png';
import { makeStyles, useTheme } from '@material-ui/core';

import { availableStore } from '../../../common/data/LandingPage';
import Image from '../../../common/components/Image';
import TrackingScreenLight from '../../../common/assets/image/LandingPage/checkndout/TrackingScreenLight.png';
import TrackingScreenDark from '../../../common/assets/image/LandingPage/checkndout/TrackingScreenDark.png';

import { ThemeType } from '../../../utils/mappings';
import FeatureSliderWrapper from '../FeatureSlider/featureSlider.style';
import ReactImageGallery from 'react-image-gallery';
import Box from '../../../common/components/Box';
import { AppConstants } from '../../../utils/AppConstants';


const AvailableStore = () => {
  const { title, description, thumb } = availableStore;

  const styles = useStyles();

  const isDarkTheme = useTheme().palette.type === ThemeType.Dark;

  return (
    <SectionWrapper id='download'>
      <Container>
        <TextWrapper>
          <Heading content={title} />
          <Text content={description} />
          {/* <Subscribe>
            <SubscribeField>
              <Input
                inputType="text"
                placeholder="Email"
                iconPosition="left"
                aria-label="text"
              />
            </SubscribeField>
            <Button title="Send" type="submit" />
          </Subscribe> */}

          {/* <BarCodeArea>
            <Button
              className="bar__code"
              variant="textButton"
              icon={<Image src={BarCode} alt="Scan" />}
              iconPosition="left"
              title="Scan to download"
            />
          </BarCodeArea> */}

          <div className={styles.downlaodWrapper}>
            <a href={AppConstants.PLAY_STORE_LINK} target="_blank" rel="noreferrer">
              <img src={AndroidDownload} alt="" />
            </a>
            <a href={AppConstants.APP_STORE_LINK} target="_blank" rel="noreferrer">
              <img src={IosDownload} alt="" />
            </a>
          </div>

        </TextWrapper>

        <ThumbWrapper>
          <Fade right>
            {/* <Image src={thumb} alt="App Image" /> */}

            <FeatureSliderWrapper style={{ zIndex: 3 }}>
              <Box className="FeatureSlider heroFeatureSlider" style={{ zIndex: 3 }}>
                <ReactImageGallery
                  items={[
                    {
                      original: `${isDarkTheme ? TrackingScreenDark : TrackingScreenLight}`,
                      originalAlt: 'slide one',
                      originalHeight: '710px',
                      originalWidth: '300px',
                      originalClass: "app-store-image-class"
                    },
                  ]}
                  className="Slider-img"
                  showPlayButton={false}
                  showFullscreenButton={false}
                  showNav={false}
                  showBullets={false}
                  autoPlay={false}
                />
              </Box>
            </FeatureSliderWrapper>
          </Fade>
        </ThumbWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default AvailableStore;


const useStyles = makeStyles((theme) => ({
  downlaodWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '1rem',
    '& a': {
      width: "30%"
    },
    '& img': {
      width: "100%"
    },
    '&>*:first-child': {
      marginRight: '1rem'
    }
  }
}))