import React from 'react';

function FlexibleSpaceCNO({ height }) {
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        ...(height ? { height } : {})
      }}
    >
    </div>
  )
}

export default FlexibleSpaceCNO
