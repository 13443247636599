import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  fade,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { mdiCalculator, mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import {
  togglePayUserDialog,
  toggleWorkHourDialog,
} from "../../actions/trackSearchActions";
import {
  groupHoursDetailByDate,
  isWithinTrackingProximity,
  timeDifference,
  timeDifferenceFromMidnight,
  timeDifferenceTillMidnight,
  convertDecimalTimeToTime,
  getDecimalHoursFromSeconds,
  calculateDistance,
} from "../../utils/Tracking";
import {
  JobTargetType,
  TrackProximityRadius,
  TrackType,
  WorkHourType,
} from "../../utils/mappings";
import SuspiciousHoursTable from "./SuspiciousHoursTable";
import moment from "moment";
import JobCalculationValues from "../JobCalculationValues/JobCalculationValues";
import HeadingBar from "../HeadingBar";
import { formatTimeWithTZ } from "../../utils/Helpers";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";

function WorkHourDetails({
  job,
  totalHoursWithinProximity,
  totalHoursOutsideProximity,
  setTotalHoursWithinProximity,
  setTotalHoursOutsideProximity,
  totalHardHoursWithinProximity,
  setTotalHardHoursWithinProximity,
}) {
  const theme = useTheme();

  const dispatch = useDispatch();

  const trackSearch = useSelector((state) => state.trackSearch);
  const hourDetails = trackSearch.data;
  const { isWorkHoursDialog } = trackSearch;

  const { teamMember } = trackSearch.mapSearchParams;
  const teamMemberName = teamMember.title;

  const [isTargetAcheived, setIsTargetAcheived] = useState(false);

  const [suspiciousHours, setSuspiciousHours] = useState([]);

  const [distanceTotals, setDistanceTotals] = useState({});
  const [hourTotals, setHourTotals] = useState({});

  const jobTitle = job.title;
  const { startDate, endDate } = job;
  const jobTarget = job.target ? job.target : 0;

  const jobCoords = {
    lat: job.lat,
    lng: job.lng,
  };

  const isJobDistanceType = job.targetType === JobTargetType.Km;
  const isJobTimeType = job.targetType === JobTargetType.Hour;
  const isJobVolumeType = job.targetType === JobTargetType.Quantity;

  useEffect(() => {
    if (isJobDistanceType) {
      setIsTargetAcheived(
        distanceTotals.hardDistanceWithinProximity >= jobTarget
      );
    }
    if (isJobTimeType) {
      setIsTargetAcheived(totalHardHoursWithinProximity >= jobTarget);
    }
  }, [totalHardHoursWithinProximity, distanceTotals, job.target]);

  const [workHourDetails, setWorkHourDetails] = useState([]);

  const tillTablet = useMediaQuery("(max-width: 768px)");

  let totalHardHoursWithinProximityTemp = 0;

  let totalHoursWithinProximityTemp = 0;
  let totalHoursOutsideProximityTemp = 0;

  const calculateDayHourTotal = async (
    jobHourDetails,
    date,
    dayIndex,
    jobHourDetailsKeysLength
  ) => {
    // console.log("date: klm: ", date);

    const dayHourDetails = jobHourDetails[date];

    let isPreviousEntryFromWithinProximity = false;

    let isPreviousEntryFromSoft = false;

    let previousEntryFromHard = null;
    let previousEntryFromSoft = null;

    let hardHoursWithinProximity = 0;
    let hardHoursOutsideProximity = 0;

    let softHoursWithinProximity = 0;
    let softHoursOutsideProximity = 0;

    let hardDistanceWithinProximity = 0;
    let hardDistanceOutsideProximity = 0;

    let softDistanceWithinProximity = 0;
    let softDistanceOutsideProximity = 0;

    let suspiciousHoursLocal = [];

    const nextDateString = moment(date, "YYYY-MM-DD")
      .add(1, "days")
      .format("YYYY-MM-DD");
    const nextDayEntries = jobHourDetails[nextDateString];
    const nextDayFirstEntry = nextDayEntries ? nextDayEntries[0] : null;

    // console.log("dayHourDetails: ", dayHourDetails);

    // process entries of a day to get the total hours for that day
    const totalHours = dayHourDetails.reduce((total, entry, dayHoursindex) => {
      const markerCoords = {
        lat: entry.lat,
        lng: entry.lng,
      };

      // nextEntryTrackType === TrackType.Location:
      // this check is due to wrong order of Checkin and Location tracktype
      // to fix the order patch has been made when doing checkin : PATCH_CHECKIN_ORDER_TRACK_TYPE
      const nextEntry =
        dayHoursindex === dayHourDetails.length - 1
          ? null
          : dayHourDetails[dayHourDetails + 1];
      const nextEntryTrackType = nextEntry ? nextEntry.trackType : null;

      const isWithinProximity = isWithinTrackingProximity(
        jobCoords,
        markerCoords,
        job.proximity ? job.proximity : TrackProximityRadius
      );
      const isCheckIn =
        entry.trackType === TrackType.CheckIn ||
        entry.trackType === TrackType.Location ||
        entry.trackType === TrackType.AppRestart;
      const isCheckOut =
        entry.trackType === TrackType.CheckOut ||
        entry.trackType === TrackType.Done;

      // process soft track data
      if (entry.taskId === null) {
        // process switching from hard to soft
        if (!isPreviousEntryFromSoft && previousEntryFromHard != null) {
          const timeDiff = timeDifference(
            previousEntryFromHard.modificationDate,
            entry.modificationDate
          );

          softHoursOutsideProximity += timeDiff;

          console.log("=======softTrackCheck======125", {
            date: entry.modificationDate,
            softHoursOutsideProximity,
            timeDiff,
          });

          if (entry.trackType === TrackType.AppRestart) {
            suspiciousHoursLocal = [
              ...suspiciousHoursLocal,
              {
                datetime: entry.modificationDate,
                timeDiff: timeDiff,
                type: WorkHourType.SoftHourOutsideProximity,
              },
            ];
          }

          if (isJobDistanceType) {
            const distance = calculateDistance(
              {
                lat: previousEntryFromHard.lat,
                lng: previousEntryFromHard.lng,
              },
              { lat: entry.lat, lng: entry.lng }
            ).distanceInMeter;
            softDistanceOutsideProximity += parseFloat(distance);
          }

          previousEntryFromSoft = entry;
        }

        if (isWithinProximity && isCheckIn) {
          if (previousEntryFromSoft != null) {
            const timeDiff = timeDifference(
              previousEntryFromSoft.modificationDate,
              entry.modificationDate
            );

            if (isPreviousEntryFromWithinProximity) {
              if (
                !(
                  previousEntryFromSoft.trackType === TrackType.CheckOut ||
                  previousEntryFromSoft.trackType === TrackType.Done
                )
              ) {
                softHoursWithinProximity += timeDiff;
              }

              console.log("=======softTrackCheck======160", {
                date: entry.modificationDate,
                softHoursWithinProximity,
                timeDiff,
              });

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.SoftHourWithinProximity,
                  },
                ];
              }

              if (isJobDistanceType) {
                const distance = calculateDistance(
                  {
                    lat: previousEntryFromHard.lat,
                    lng: previousEntryFromHard.lng,
                  },
                  { lat: entry.lat, lng: entry.lng }
                ).distanceInMeter;
                softDistanceWithinProximity += parseFloat(distance);
              }
            } else {
              softHoursOutsideProximity += timeDiff;

              console.log("=======softTrackCheck======184", {
                date: entry.modificationDate,
                softHoursOutsideProximity,
                timeDiff,
              });

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.SoftHourOutsideProximity,
                  },
                ];
              }

              if (isJobDistanceType) {
                const distance = calculateDistance(
                  {
                    lat: previousEntryFromHard.lat,
                    lng: previousEntryFromHard.lng,
                  },
                  { lat: entry.lat, lng: entry.lng }
                ).distanceInMeter;
                softDistanceOutsideProximity += parseFloat(distance);
              }
            }
          }

          // last entry of day is checkin within proximity and day is not last
          if (
            dayHoursindex === dayHourDetails.length - 1 &&
            dayIndex !== jobHourDetailsKeysLength - 1
          ) {
            const timeDiff = timeDifferenceTillMidnight(entry.modificationDate);

            softHoursWithinProximity += timeDiff;

            console.log("=======softTrackCheck======215", {
              date: entry.modificationDate,
              softHoursWithinProximity,
              timeDiff,
            });

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.SoftHourWithinProximity,
                },
              ];
            }

            if (isJobDistanceType && nextDayFirstEntry) {
              const distance = calculateDistance(
                { lat: entry.lat, lng: entry.lng },
                { lat: nextDayFirstEntry.lat, lng: nextDayFirstEntry.lng }
              ).distanceInMeter;
              softDistanceWithinProximity += parseFloat(distance);
            }
          }

          // nextEntryTrackType === TrackType.Location:
          // this check is due to wrong order of Checkin and Location tracktype
          // to fix the order patch has been made when doing checkin : PATCH_CHECKIN_ORDER_TRACK_TYPE

          // first entry of day is location within proximity and day is not first
          if (
            dayIndex !== 0 &&
            dayHoursindex === 0 &&
            entry.trackType === TrackType.Location &&
            nextEntryTrackType === TrackType.Location
          ) {
            // console.log("entry: location first entry: ", entry);

            const timeDiff = timeDifferenceFromMidnight(entry.modificationDate);

            softHoursWithinProximity += timeDiff;

            console.log("=======softTrackCheck======249", {
              date: entry.modificationDate,
              softHoursWithinProximity,
              timeDiff,
            });

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.SoftHourWithinProximity,
                },
              ];
            }
          }

          previousEntryFromSoft = entry;
          isPreviousEntryFromWithinProximity = isWithinProximity ? true : false;
        } else {
          if (previousEntryFromSoft != null) {
            const timeDiff = timeDifference(
              previousEntryFromSoft.modificationDate,
              entry.modificationDate
            );

            if (isPreviousEntryFromWithinProximity) {
              if (
                !(
                  previousEntryFromSoft.trackType === TrackType.CheckOut ||
                  previousEntryFromSoft.trackType === TrackType.Done
                )
              ) {
                softHoursWithinProximity += timeDiff;
              }

              console.log("=======softTrackCheck======275", {
                date: entry.modificationDate,
                softHoursWithinProximity,
                timeDiff,
              });

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.SoftHourWithinProximity,
                  },
                ];
              }

              if (isJobDistanceType) {
                const distance = calculateDistance(
                  {
                    lat: previousEntryFromHard.lat,
                    lng: previousEntryFromHard.lng,
                  },
                  { lat: entry.lat, lng: entry.lng }
                ).distanceInMeter;
                softDistanceWithinProximity += parseFloat(distance);
              }
            } else {
              if (
                !(
                  previousEntryFromSoft.trackType === TrackType.CheckOut ||
                  previousEntryFromSoft.trackType === TrackType.Done
                )
              ) {
                softHoursOutsideProximity += timeDiff;
              }

              console.log("=======softTrackCheck======300", {
                date: entry.modificationDate,
                softHoursOutsideProximity,
                timeDiff,
              });

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.SoftHourOutsideProximity,
                  },
                ];
              }

              if (isJobDistanceType) {
                const distance = calculateDistance(
                  {
                    lat: previousEntryFromHard.lat,
                    lng: previousEntryFromHard.lng,
                  },
                  { lat: entry.lat, lng: entry.lng }
                ).distanceInMeter;
                softDistanceOutsideProximity += parseFloat(distance);
              }
            }
          }

          // first entry of day is checkout but day should not be first
          if (dayHoursindex === 0 && dayIndex !== 0 && isCheckOut) {
            const timeDiff = timeDifferenceFromMidnight(entry.modificationDate);

            //  user is within proximity but due to checkout, code is here
            if (isWithinProximity) {
              softHoursWithinProximity += timeDiff;

              console.log("=======softTrackCheck======333", {
                date: entry.modificationDate,
                softHoursWithinProximity,
                timeDiff,
              });

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.SoftHourWithinProximity,
                  },
                ];
              }

              if (isJobDistanceType) {
                const distance = calculateDistance(
                  {
                    lat: previousEntryFromHard.lat,
                    lng: previousEntryFromHard.lng,
                  },
                  { lat: entry.lat, lng: entry.lng }
                ).distanceInMeter;
                softDistanceWithinProximity += parseFloat(distance);
              }
            } else {
              softHoursOutsideProximity += timeDiff;

              console.log("=======softTrackCheck======358", {
                date: entry.modificationDate,
                softHoursOutsideProximity,
                timeDiff,
              });

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.SoftHourOutsideProximity,
                  },
                ];
              }

              if (isJobDistanceType) {
                const distance = calculateDistance(
                  {
                    lat: previousEntryFromHard.lat,
                    lng: previousEntryFromHard.lng,
                  },
                  { lat: entry.lat, lng: entry.lng }
                ).distanceInMeter;
                softDistanceOutsideProximity += parseFloat(distance);
              }
            }
          }

          // last entry of day is checkin outside proximity and day is not last
          if (
            dayHoursindex === dayHourDetails.length - 1 &&
            dayIndex !== jobHourDetailsKeysLength - 1 &&
            isCheckIn
          ) {
            const timeDiff = timeDifferenceTillMidnight(entry.modificationDate);

            softHoursOutsideProximity += timeDiff;

            console.log("=======softTrackCheck======389", {
              date: entry.modificationDate,
              softHoursOutsideProximity,
              timeDiff,
            });

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.SoftHourOutsideProximity,
                },
              ];
            }

            if (isJobDistanceType && nextDayFirstEntry) {
              const distance = calculateDistance(
                { lat: entry.lat, lng: entry.lng },
                { lat: nextDayFirstEntry.lat, lng: nextDayFirstEntry.lng }
              ).distanceInMeter;
              softDistanceOutsideProximity += parseFloat(distance);
            }
          }

          previousEntryFromSoft = entry;
          isPreviousEntryFromWithinProximity = isWithinProximity ? true : false;
        }

        previousEntryFromHard = null;
        isPreviousEntryFromSoft = true;
      }
      // process hard track data
      else {
        // process swicthing from soft to hard
        if (isPreviousEntryFromSoft) {
          const timeDiff = timeDifference(
            previousEntryFromSoft.modificationDate,
            entry.modificationDate
          );

          if (isWithinProximity) {
            softHoursWithinProximity += timeDiff;

            console.log("=======softTrackCheck======429", {
              date: entry.modificationDate,
              softHoursWithinProximity,
              timeDiff,
            });

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.SoftHourWithinProximity,
                },
              ];
            }

            if (isJobDistanceType) {
              const distance = calculateDistance(
                {
                  lat: previousEntryFromHard.lat,
                  lng: previousEntryFromHard.lng,
                },
                { lat: entry.lat, lng: entry.lng }
              ).distanceInMeter;
              softDistanceWithinProximity += parseFloat(distance);
            }
          } else {
            softHoursOutsideProximity += timeDiff;

            console.log("=======softTrackCheck======454", {
              date: entry.modificationDate,
              softHoursOutsideProximity,
              timeDiff,
            });

            if (entry.trackType === TrackType.AppRestart) {
              suspiciousHoursLocal = [
                ...suspiciousHoursLocal,
                {
                  datetime: entry.modificationDate,
                  timeDiff: timeDiff,
                  type: WorkHourType.SoftHourOutsideProximity,
                },
              ];
            }

            if (isJobDistanceType) {
              const distance = calculateDistance(
                {
                  lat: previousEntryFromHard.lat,
                  lng: previousEntryFromHard.lng,
                },
                { lat: entry.lat, lng: entry.lng }
              ).distanceInMeter;
              softDistanceOutsideProximity += parseFloat(distance);
            }
          }
          previousEntryFromHard = entry;
        }
        // normal processing of hard track data
        else {
          if (isWithinProximity && isCheckIn) {
            if (previousEntryFromHard != null) {
              const timeDiff = timeDifference(
                previousEntryFromHard.modificationDate,
                entry.modificationDate
              );

              if (isPreviousEntryFromWithinProximity) {
                if (
                  !(
                    previousEntryFromHard.trackType === TrackType.CheckOut ||
                    previousEntryFromHard.trackType === TrackType.Done
                  )
                ) {
                  hardHoursWithinProximity += timeDiff;
                }

                console.log("=======softTrackCheck==hardTrackCheck====494", {
                  date: entry.modificationDate,
                  hardHoursWithinProximity,
                  timeDiff,
                });

                if (entry.trackType === TrackType.AppRestart) {
                  suspiciousHoursLocal = [
                    ...suspiciousHoursLocal,
                    {
                      datetime: entry.modificationDate,
                      timeDiff: timeDiff,
                      type: WorkHourType.HardHourWithinProximity,
                    },
                  ];
                }

                if (isJobDistanceType) {
                  const distance = calculateDistance(
                    {
                      lat: previousEntryFromHard.lat,
                      lng: previousEntryFromHard.lng,
                    },
                    { lat: entry.lat, lng: entry.lng }
                  ).distanceInMeter;
                  hardDistanceWithinProximity += parseFloat(distance);
                }
              } else {
                hardHoursOutsideProximity += timeDiff;

                console.log("=======softTrackCheck==hardTrackCheck====519", {
                  date: entry.modificationDate,
                  hardHoursOutsideProximity,
                  timeDiff,
                });

                if (entry.trackType === TrackType.AppRestart) {
                  suspiciousHoursLocal = [
                    ...suspiciousHoursLocal,
                    {
                      datetime: entry.modificationDate,
                      timeDiff: timeDiff,
                      type: WorkHourType.HardHourOutsideProximity,
                    },
                  ];
                }

                if (isJobDistanceType) {
                  const distance = calculateDistance(
                    {
                      lat: previousEntryFromHard.lat,
                      lng: previousEntryFromHard.lng,
                    },
                    { lat: entry.lat, lng: entry.lng }
                  ).distanceInMeter;
                  hardDistanceOutsideProximity += parseFloat(distance);
                }
              }
            }

            // last entry of day is checkin within proximity and day is not last
            if (
              dayHoursindex === dayHourDetails.length - 1 &&
              dayIndex !== jobHourDetailsKeysLength - 1
            ) {
              const timeDiff = timeDifferenceTillMidnight(
                entry.modificationDate
              );

              hardHoursWithinProximity += timeDiff;

              console.log("=======softTrackCheck==hardTrackCheck====549", {
                date: entry.modificationDate,
                hardHoursWithinProximity,
                timeDiff,
              });

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.HardHourWithinProximity,
                  },
                ];
              }

              if (isJobDistanceType && nextDayFirstEntry) {
                const distance = calculateDistance(
                  { lat: entry.lat, lng: entry.lng },
                  { lat: nextDayFirstEntry.lat, lng: nextDayFirstEntry.lng }
                ).distanceInMeter;
                hardDistanceWithinProximity += parseFloat(distance);
              }
            }

            // nextEntryTrackType === TrackType.Location:
            // this check is due to wrong order of Checkin and Location tracktype
            // to fix the order patch has been made when doing checkin : PATCH_CHECKIN_ORDER_TRACK_TYPE

            // first entry of day is location within proximity and day is not first
            if (
              dayIndex !== 0 &&
              dayHoursindex === 0 &&
              entry.trackType === TrackType.Location &&
              nextEntryTrackType === TrackType.Location
            ) {
              const timeDiff = timeDifferenceFromMidnight(
                entry.modificationDate
              );

              hardHoursWithinProximity += timeDiff;

              console.log("=======softTrackCheck==hardTrackCheck====581", {
                date: entry.modificationDate,
                hardHoursWithinProximity,
                timeDiff,
              });

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.HardHourWithinProximity,
                  },
                ];
              }
            }

            previousEntryFromHard = entry;
            isPreviousEntryFromWithinProximity = true;
          } else {
            if (previousEntryFromHard != null) {
              const timeDiff = timeDifference(
                previousEntryFromHard.modificationDate,
                entry.modificationDate
              );

              if (isPreviousEntryFromWithinProximity) {
                if (
                  !(
                    previousEntryFromHard.trackType === TrackType.CheckOut ||
                    previousEntryFromHard.trackType === TrackType.Done
                  )
                ) {
                  hardHoursWithinProximity += timeDiff;
                }

                console.log("=======softTrackCheck==hardTrackCheck====609", {
                  date: entry.modificationDate,
                  hardHoursWithinProximity,
                  timeDiff,
                });

                if (entry.trackType === TrackType.AppRestart) {
                  suspiciousHoursLocal = [
                    ...suspiciousHoursLocal,
                    {
                      datetime: entry.modificationDate,
                      timeDiff: timeDiff,
                      type: WorkHourType.HardHourWithinProximity,
                    },
                  ];
                }

                if (isJobDistanceType) {
                  const distance = calculateDistance(
                    {
                      lat: previousEntryFromHard.lat,
                      lng: previousEntryFromHard.lng,
                    },
                    { lat: entry.lat, lng: entry.lng }
                  ).distanceInMeter;
                  hardDistanceWithinProximity += parseFloat(distance);
                }
              } else {
                if (
                  !(
                    previousEntryFromHard.trackType === TrackType.CheckOut ||
                    previousEntryFromHard.trackType === TrackType.Done
                  )
                ) {
                  hardHoursOutsideProximity += timeDiff;
                }

                console.log("=======softTrackCheck==hardTrackCheck====634", {
                  date: entry.modificationDate,
                  hardHoursOutsideProximity,
                  timeDiff,
                });

                if (entry.trackType === TrackType.AppRestart) {
                  suspiciousHoursLocal = [
                    ...suspiciousHoursLocal,
                    {
                      datetime: entry.modificationDate,
                      timeDiff: timeDiff,
                      type: WorkHourType.HardHourOutsideProximity,
                    },
                  ];
                }

                if (isJobDistanceType) {
                  const distance = calculateDistance(
                    {
                      lat: previousEntryFromHard.lat,
                      lng: previousEntryFromHard.lng,
                    },
                    { lat: entry.lat, lng: entry.lng }
                  ).distanceInMeter;
                  hardDistanceOutsideProximity += parseFloat(distance);
                }
              }
            }

            // first entry of day is checkout but day should not be first
            if (dayHoursindex === 0 && dayIndex !== 0 && isCheckOut) {
              const timeDiff = timeDifferenceFromMidnight(
                entry.modificationDate
              );

              //  user is within proximity but due to checkout, code is here
              if (isWithinProximity) {
                hardHoursWithinProximity += timeDiff;

                console.log("=======softTrackCheck==hardTrackCheck====667", {
                  date: entry.modificationDate,
                  hardHoursWithinProximity,
                  timeDiff,
                });

                if (entry.trackType === TrackType.AppRestart) {
                  suspiciousHoursLocal = [
                    ...suspiciousHoursLocal,
                    {
                      datetime: entry.modificationDate,
                      timeDiff: timeDiff,
                      type: WorkHourType.HardHourWithinProximity,
                    },
                  ];
                }
              } else {
                hardHoursOutsideProximity += timeDiff;
                if (entry.trackType === TrackType.AppRestart) {
                  suspiciousHoursLocal = [
                    ...suspiciousHoursLocal,
                    {
                      datetime: entry.modificationDate,
                      timeDiff: timeDiff,
                      type: WorkHourType.HardHourOutsideProximity,
                    },
                  ];
                }
              }
            }

            // last entry of day is checkin outside proximity and day is not last
            if (
              dayHoursindex === dayHourDetails.length - 1 &&
              dayIndex !== jobHourDetailsKeysLength - 1 &&
              isCheckIn
            ) {
              const timeDiff = timeDifferenceTillMidnight(
                entry.modificationDate
              );

              hardHoursOutsideProximity += timeDiff;

              console.log("=======softTrackCheck==hardTrackCheck====702", {
                date: entry.modificationDate,
                hardHoursOutsideProximity,
                timeDiff,
              });

              if (entry.trackType === TrackType.AppRestart) {
                suspiciousHoursLocal = [
                  ...suspiciousHoursLocal,
                  {
                    datetime: entry.modificationDate,
                    timeDiff: timeDiff,
                    type: WorkHourType.HardHourOutsideProximity,
                  },
                ];
              }

              if (isJobDistanceType && nextDayFirstEntry) {
                const distance = calculateDistance(
                  { lat: entry.lat, lng: entry.lng },
                  { lat: nextDayFirstEntry.lat, lng: nextDayFirstEntry.lng }
                ).distanceInMeter;
                hardDistanceOutsideProximity += parseFloat(distance);
              }
            }

            previousEntryFromHard = entry;
            isPreviousEntryFromWithinProximity = isWithinProximity
              ? true
              : false;
          }
        }

        previousEntryFromSoft = null;
        isPreviousEntryFromSoft = false;
      }

      total = {
        hardHoursWithinProximity,
        hardHoursOutsideProximity,
        softHoursWithinProximity,
        softHoursOutsideProximity,

        hardDistanceWithinProximity,
        hardDistanceOutsideProximity,
        softDistanceWithinProximity,
        softDistanceOutsideProximity,
      };
      return total;
    }, {});

    // sum up the total hours
    totalHoursWithinProximityTemp +=
      totalHours.hardHoursWithinProximity + totalHours.softHoursWithinProximity;
    totalHoursOutsideProximityTemp +=
      totalHours.hardHoursOutsideProximity +
      totalHours.softHoursOutsideProximity;

    totalHardHoursWithinProximityTemp += totalHours.hardHoursWithinProximity;

    return {
      id: v4(),
      date,
      hourDetail: dayHourDetails,
      hardHoursWithinProximity: totalHours.hardHoursWithinProximity.toFixed(2),
      hardHoursOutsideProximity:
        totalHours.hardHoursOutsideProximity.toFixed(2),
      softHoursWithinProximity: totalHours.softHoursWithinProximity.toFixed(2),
      softHoursOutsideProximity:
        totalHours.softHoursOutsideProximity.toFixed(2),

      hardDistanceWithinProximity:
        totalHours.hardDistanceWithinProximity.toFixed(2),
      hardDistanceOutsideProximity:
        totalHours.hardDistanceOutsideProximity.toFixed(2),
      softDistanceWithinProximity:
        totalHours.softDistanceWithinProximity.toFixed(2),
      softDistanceOutsideProximity:
        totalHours.softHoursOutsideProximity.toFixed(2),

      suspiciousHours: suspiciousHoursLocal,
    };
  };

  const calculateJobHourDetails = async () => {
    const jobHourDetails = groupHoursDetailByDate(hourDetails);

    console.log("======jobHourDetailsjobHourDetails==========", jobHourDetails);

    const jobHourDetailsKeys = Object.keys(jobHourDetails);
    const jobHourDetailsKeysLength = jobHourDetailsKeys.length;

    let allDaysCalculation = [];

    // iterate through each day
    await Promise.all(
      jobHourDetailsKeys.map(async (date, dayIndex) => {
        // process all entries for current day to get total hours
        const result = calculateDayHourTotal(
          jobHourDetails,
          date,
          dayIndex,
          jobHourDetailsKeysLength
        );
        allDaysCalculation.push(result);

        const jobHourDetailsArray = await Promise.all(allDaysCalculation);

        // jobHourDetailsArray.

        console.log("=============================================");
        console.log("jobHourDetailsArray: ", jobHourDetailsArray);
        console.log("=============================================");

        setWorkHourDetails(jobHourDetailsArray);

        const suspHours = jobHourDetailsArray.reduce((suspHours, item) => {
          return [...suspHours, ...item.suspiciousHours];
        }, []);
        setSuspiciousHours(suspHours);

        const timeTotals = jobHourDetailsArray.reduce(
          (totals, item) => {
            totals = {
              hardHoursWithinProximity:
                parseFloat(totals.hardHoursWithinProximity) +
                parseFloat(item.hardHoursWithinProximity),
              hardHoursOutsideProximity:
                parseFloat(totals.hardHoursOutsideProximity) +
                parseFloat(item.hardHoursOutsideProximity),
              softHoursWithinProximity:
                parseFloat(totals.softHoursWithinProximity) +
                parseFloat(item.softHoursWithinProximity),
              softHoursOutsideProximity:
                parseFloat(totals.softHoursOutsideProximity) +
                parseFloat(item.softHoursOutsideProximity),
            };

            return totals;
          },
          {
            hardHoursWithinProximity: 0,
            hardHoursOutsideProximity: 0,
            softHoursWithinProximity: 0,
            softHoursOutsideProximity: 0,
          }
        );

        setHourTotals(timeTotals);

        const distanceTotals = jobHourDetailsArray.reduce(
          (totals, item) => {
            totals = {
              hardDistanceWithinProximity:
                parseFloat(totals.hardDistanceWithinProximity) +
                parseFloat(item.hardDistanceWithinProximity),
              hardDistanceOutsideProximity:
                parseFloat(totals.hardDistanceOutsideProximity) +
                parseFloat(item.hardDistanceOutsideProximity),
              softDistanceWithinProximity:
                parseFloat(totals.softDistanceWithinProximity) +
                parseFloat(item.softDistanceWithinProximity),
              softDistanceOutsideProximity:
                parseFloat(totals.softDistanceOutsideProximity) +
                parseFloat(item.softDistanceOutsideProximity),
            };

            return totals;
          },
          {
            hardDistanceWithinProximity: 0,
            hardDistanceOutsideProximity: 0,
            softDistanceWithinProximity: 0,
            softDistanceOutsideProximity: 0,
          }
        );

        setDistanceTotals(distanceTotals);
      })
    );

    // setTotalHoursWithinProximity(30);
    setTotalHoursWithinProximity(totalHoursWithinProximityTemp.toFixed(2));
    setTotalHoursOutsideProximity(totalHoursOutsideProximityTemp.toFixed(2));
    setTotalHardHoursWithinProximity(
      parseFloat(totalHardHoursWithinProximityTemp).toFixed(2)
    );
  };

  useEffect(() => {
    (async function calculateHours() {
      await calculateJobHourDetails();
    })();
  }, [
    hourDetails,
    setTotalHoursWithinProximity,
    setTotalHoursOutsideProximity,
  ]);

  console.log("====================================");
  console.log("SuspHours: ", suspiciousHours);
  console.log("ditanceTotals: ", distanceTotals);
  console.log("====================================");

  const handleClose = () => dispatch(toggleWorkHourDialog(false));

  const openPayUserDialog = () => {
    // TODO: remove true from if, after bonus and payment history
    if (getDecimalHoursFromSeconds(totalHardHoursWithinProximity) > 0 || true) {
      dispatch(togglePayUserDialog(true));
    } else {
      toast.info("No payment to pay!!!");
    }
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      renderHeader: () => {
        return (
          <div
            style={{
              color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR,
              fontSize: "0.8rem",
            }}
          >
            Date
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <Moment format="DD MMM, YYYY">
            {formatTimeWithTZ(params.value)}
          </Moment>
        );
      },
    },
    {
      field: "hardHoursWithinProximity",
      headerName: "Hard - Within Proximity",
      flex: 1,
      renderHeader: () => {
        return (
          <div
            style={{
              color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR,
              fontSize: "0.8rem",
            }}
          >
            WP
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <Typography
            style={{
              background:
                theme.colors.WORK_HOUR_TABLE_GREEN_COLUMN_BACKGROUND_COLOUR,
              width: "100%",
              borderRadius: 5,
              textAlign: "center",
              color: theme.colors.WORK_HOUR_TABLE_COLUMN_TEXT_COLOUR,
              fontWeight: "bold",
            }}
          >
            {convertDecimalTimeToTime(params.value)}
          </Typography>
        );
      },
    },
    {
      field: "hardHoursOutsideProximity",
      headerName: "Hard - Outside Proximity",
      flex: 1,
      renderHeader: () => {
        return (
          <div
            style={{
              color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR,
              fontSize: "0.8rem",
            }}
          >
            OP
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <Typography
            style={{
              background:
                theme.colors.WORK_HOUR_TABLE_RED_COLUMN_BACKGROUND_COLOUR,
              width: "100%",
              borderRadius: 5,
              textAlign: "center",
              color: theme.colors.WORK_HOUR_TABLE_COLUMN_TEXT_COLOUR,
              fontWeight: "bold",
            }}
          >
            {convertDecimalTimeToTime(params.value)}
          </Typography>
        );
      },
    },
    {
      field: "softHoursWithinProximity",
      headerName: "Soft HWP",
      flex: 1,
      renderHeader: () => {
        return (
          <div
            style={{
              color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR,
              fontSize: "0.8rem",
            }}
          >
            WP<sup style={{ fontSize: "0.6rem" }}>soft</sup>
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <Typography
            style={{
              background:
                theme.colors.WORK_HOUR_TABLE_GREY_COLUMN_BACKGROUND_COLOUR,
              width: "100%",
              borderRadius: 5,
              textAlign: "center",
              color:
                theme.colors.WORK_HOUR_TABLE_GREEN_COLUMN_BACKGROUND_COLOUR,
              fontWeight: "bold",
            }}
          >
            {convertDecimalTimeToTime(params.value)}
          </Typography>
        );
      },
    },
    {
      field: "softHoursOutsideProximity",
      headerName: "Soft HOP",
      flex: 1,
      renderHeader: () => {
        return (
          <div
            style={{
              color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR,
              fontSize: "0.8rem",
            }}
          >
            OP<sup style={{ fontSize: "0.6rem" }}>soft</sup>
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <Typography
            style={{
              background:
                theme.colors.WORK_HOUR_TABLE_GREY_COLUMN_BACKGROUND_COLOUR,
              width: "100%",
              borderRadius: 5,
              textAlign: "center",
              color: theme.colors.WORK_HOUR_TABLE_RED_COLUMN_BACKGROUND_COLOUR,
              fontWeight: "bold",
            }}
          >
            {convertDecimalTimeToTime(params.value)}
          </Typography>
        );
      },
    },
  ];
  const rows = workHourDetails;

  const styles = useStyles({ tillTablet });
  const GlobalStyles = useGlobalStyles();

  // return null;

  return (
    <Dialog
      open={isWorkHoursDialog}
      className={styles.container_WorkHourDetails}
      classes={{
        paper: styles.paper,
        columnHeader: styles.tableCell,
      }}
    >
      <HeadingBar
        title={`Work Hour Details of ${teamMemberName}`}
        onClose={handleClose}
      />
      <div className={styles.metaWrapper}>
        <div className={styles.jobTitleWrapper}>
          <Typography className={styles.jobTitle}>{jobTitle}</Typography>
        </div>

        <div className={styles.wrapper}>
          <div className={styles.timeAndPayWrapper}>
            <div className={styles.timeWrapper}>
              <Typography className={styles.timeLabel}>Start</Typography>
              <div className={styles.startTime}>
                {/* <Icon path={mdiClockStart} size='0.8rem' /> */}
                <Moment style={{ fontSize: "1rem" }} format="DD MMM, YYYY">
                  {formatTimeWithTZ(startDate)}
                </Moment>
              </div>
            </div>
            <div className={styles.timeWrapper}>
              <Typography className={styles.timeLabel}>End &nbsp;</Typography>
              <div className={styles.endTime}>
                {/* <Icon path={mdiClockEnd} size='1.2rem' /> */}
                <Moment style={{ fontSize: "1rem" }} format="DD MMM, YYYY">
                  {formatTimeWithTZ(endDate)}
                </Moment>
              </div>
            </div>
          </div>
          <div style={{ flexGrow: 1 }}></div>

          <Button
            type="submit"
            variant="contained"
            className={styles.pay}
            startIcon={<Icon path={mdiCalculator} size="2rem" />}
            autoCapitalize={false}
            onClick={openPayUserDialog}
          >
            Calculate <br /> Payment
          </Button>
        </div>
      </div>

      {isJobVolumeType && (
        <JobCalculationValues
          job={job}
          title={"Quantity"}
          showTarget={false}
          mode="quantity"
        />
      )}

      {isJobDistanceType && (
        <JobCalculationValues
          job={job}
          distanceTotals={distanceTotals}
          title={"Total kms"}
          showTarget={isJobDistanceType}
          isTargetAcheived={isTargetAcheived}
          mode="distance"
        />
      )}

      <JobCalculationValues
        job={job}
        hourTotals={hourTotals}
        title={"Total Hours"}
        showTarget={isJobTimeType}
        isTargetAcheived={isTargetAcheived}
        mode="time"
      />

      <div className={styles.grid}>
        {workHourDetails.length ? (
          <DataGrid
            rows={rows}
            columns={columns}
            rowHeight={35}
            hideFooterSelectedRowCount
            pageSize={5}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
          />
        ) : (
          "Loading"
        )}
        <div className={styles.legendWrapper}>
          <Typography>
            <span>*WP - Within Proximity, </span>
            <span>*OP - Outside Proximity, </span>
          </Typography>
        </div>
      </div>

      <div style={{ marginTop: theme.spacing(2) }}>
        <Accordion className={GlobalStyles.accordion}>
          <AccordionSummary
            expandIcon={
              <Icon
                path={mdiChevronDown}
                size="1.2rem"
                color={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR}
              />
            }
            className={GlobalStyles.accordionSummary}
          >
            Suspicious Hours
          </AccordionSummary>
          <AccordionDetails>
            <SuspiciousHoursTable suspiciousHours={suspiciousHours} />
          </AccordionDetails>
        </Accordion>
      </div>
    </Dialog>
  );
}

export default WorkHourDetails;

const useStyles = makeStyles((theme) => ({
  container_WorkHourDetails: {
    width: ({ tillTablet }) => (tillTablet ? "auto" : 740),
    maxWidth: 740,
    margin: "0 auto",
    "& .MuiDataGrid-root > *": {
      // border: '2px solid #BE0A02'
      outline: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-colCellSortable": {
      background: theme.colors.WORK_HOUR_TABLE_HEADER_BACKGROUND_COLOUR,
      // lineHeight: 3,
    },
    "& .MuiDataGrid-root .MuiDataGrid-dataContainer": {
      background: theme.colors.WORK_HOUR_TABLE_ROW_BACKGROUND_COLOUR,
    },
    "& .MuiDataGrid-root .MuiDataGrid-footer": {
      background: theme.colors.WORK_HOUR_TABLE_HEADER_BACKGROUND_COLOUR,
      minHeight: 35,
      height: 35,
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-colCell, .MuiDataGrid-root .MuiDataGrid-cell":
      {
        paddingRight: 0,
        // height: 80
      },
    "& .MuiDataGrid-root .MuiDataGrid-columnsContainer": {
      background: theme.colors.DIALOG_BACKGROUND_COLOR,
    },
  },
  paper: {
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    padding: theme.spacing(2),
    width: ({ tillTablet }) => (tillTablet ? "90vw" : "70vw"),
  },
  tableCell: {
    width: 100,
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "2px solid white",
    paddingBottom: 5,
    "&>*:first-child": {
      marginRight: theme.spacing(2),
      fontSize: "0.9rem",
    },
    "&>*:last-child": {
      background: theme.colors.MODAL_CLOSE_ICON_BACKGROUND_COLOR,
      borderRadius: 20,
      padding: 2,
    },
  },
  metaWrapper: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  wrapper: {
    display: "flex",
    alignItems: "flex-start",
    "&>*:not(:first-child)": {
      marginTop: theme.spacing(1),
    },
  },
  jobTitleWrapper: {
    background: theme.colors.WORK_HOUR_JOB_TITLE_BACKGROUND_COLOR,
    display: "flex",
    alignItems: "center",
    padding: "4px 8px",
  },
  jobTitle: {
    borderRadius: 5,
    color: theme.colors.WORK_HOUR_JOB_TITLE_TEXT_COLOR,
    flex: 1,
  },
  targetWrapper: {
    marginRight: theme.spacing(4),
    // padding: '4px 8px',
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
  },
  target: {
    display: "flex",
    alignItems: "center",
    color: theme.colors.WORK_HOUR_JOB_TITLE_TEXT_COLOR,
  },
  timeAndPayWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: theme.spacing(2),
    "&>*": {
      display: "flex",
      alignItems: "center",
      "&:first-child": {
        marginRight: theme.spacing(4),
      },
      "&>*:first-child": {
        marginRight: theme.spacing(1),
      },
    },
  },
  timeWrapper: {},
  timeLabel: {
    color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR,
    fontSize: "0.8rem",
  },
  startTime: {
    color: theme.colors.JOB_START_DATE_COLOR,
  },
  endTime: {
    color: theme.colors.JOB_END_DATE_COLOR,
  },
  pay: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    textAlign: "left",
    border: `1px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    boxSizing: "border-box",
    "&:hover": {
      background: fade(theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR, 0.8),
    },
  },
  totalHoursWrapper: {
    display: "flex",
    alignItems: "center",
    background: theme.colors.WORK_HOURS_TOTAL_ROW_BACKGROUND_COLOR,
    borderRadius: 5,
    padding: "8px 12px",
    "&>*": {
      "&:first-child": {
        fontWeight: "bold",
        flex: 0.3,
      },
    },
  },
  grid: {
    // height: 320,
    width: "100%",
    marginTop: theme.spacing(2),
  },
  legendWrapper: {
    marginTop: 8,
    color: theme.colors.WORK_HOUR_LEGEND_TEXT_COLOR,
    "&>*": {
      fontSize: "0.7rem",
    },
  },
  accordion: {
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
  },
  accordionSummary: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
    fontSize: "0.8rem",
    minHeight: 35,
    paddingLeft: "8px",
    "& .MuiAccordionSummary-expandIcon": {
      padding: 0,
    },
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 35,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
  },
}));
