import { KeyboardDatePicker } from "@material-ui/pickers";
import { mdiRestore } from "@mdi/js";
import clsx from "clsx";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleServiceSearch,
  setServiceTimesFilter,
} from "../../actions/serviceActions";
import {
  fetchCustomerSuggest,
  fetchMemberSuggest,
} from "../../actions/suggestionsActions";
import FormActionButtons from "../../components/FormActionButtons";
import JobChipsFilter from "../../components/JobChipsFilter";
import AutocompleteCNO from "../../library/AutocompleteCNO";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import * as yup from "yup";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import HeadingBar from "../../components/HeadingBar";
import { useGlobalStore } from "../../providers/GlobalProvider";
import { TOGGLE_SEARCH } from "../../utils/actions";
import InputLabelCNO from "../../library/InputLabelCNO";
import UserSuggestionOption from "../../components/UserSuggestionOption/UserSuggestionOption";
import RightsManager from "../../utils/RightsManager";
import { useRouteMatch } from "react-router-dom";
import { getPrefillAssignedUser } from "../../utils/Helpers";
import { TimesFilter } from "../../utils";
import DateTimePickerCNO from "../../library/DateTimePickerCNO";
import GooglePlaceInputCNO from "../../library/GooglePlaceInputCNO";

function ServiceFilterModal() {
  const isUserAdmin = RightsManager.isAdmin();

  const isUserMember = RightsManager.isMember();

  const tillTablet = useMediaQuery("(max-width: 768px)");

  const styles = useStyles({ tillTablet });
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { serviceSearchParams } = useSelector((state) => state.services);

  const { dispatchGlobal } = useGlobalStore();
  const closeServiceFilterDialog = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false,
    });
  };

  const validationSchema = yup.object({
    categories: yup.array(),
    startDate: yup.date().nullable(),
    endDate: yup.date().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      service: null,
      categories: [],
      startDate: null,
      endDate: null,
      mapAddress: null,
      lat: null,
      lng: null,
      assignments: isUserMember ? getPrefillAssignedUser() : null,
      customer:null,
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(handleServiceSearch(values));
      closeServiceFilterDialog();
    },
  });

  useEffect(() => {
    console.log("==========useEffectServiceSearchParams", serviceSearchParams);

    formik.setValues(serviceSearchParams);
  }, [serviceSearchParams]);

  const handleResetForm = () => {
    formik.resetForm();
    formik.setValues(formik.initialValues);
    dispatch(handleServiceSearch(formik.initialValues));

    dispatch(setServiceTimesFilter(TimesFilter.None));
  };

  const triggerServiceSearch = (values) => {
    if (!tillTablet) {
      dispatch(handleServiceSearch(values));
    }
  };

  console.log("======isdirty=====", formik.dirty);
  console.log("======isdirty==values===", formik.values);
  console.log("======isdirty==initial===", formik.initialValues);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.container_ServiceFilterModal}
    >
      <HeadingBar
        title={formik.dirty ? "Reset" : "Search"}
        icon={formik.dirty ? mdiRestore : null}
        onHeaderClick={handleResetForm}
        onClose={formik.dirty ? undefined : closeServiceFilterDialog}
      />

      {/* {isUserAdmin && (
        <AutocompleteCNO
          name="service"
          label="Service Title"
          formik={formik}
          placeholder="Search Service"
          data={suggestions}
          optionLabel={(x) => x.title || ""}
          renderOption={(option) => (
            <div style={{ color: "#014663", fontWeight: "500" }}>
              {option.title}
            </div>
          )}
          onChange={(value) => {
            if (value) {
              dispatch(fetchCustomerSuggest(value));
            }
          }}
          onSuggestionClick={(value) => {
            formik.setFieldValue("service", value);
            triggerServiceSearch({ ...formik.values, customer: value });
          }}
        />
      )} */}

      <div style={{ marginTop: 12 }}></div>

      <InputLabelCNO label={"Categories"} />
      <div
        className={GlobalStyles.verticalScrollChipsWrapper}
        style={{ marginTop: 12, marginBottom: 12 }}
      >
        <JobChipsFilter
          mode="service"
          onChipToggle={(categories) => {
            formik.setFieldValue("categories", categories);
            triggerServiceSearch({ ...formik.values, categories: categories });
          }}
        />
      </div>

      {isUserAdmin && (
        <div style={{ marginTop: 10 }}>
          <GooglePlaceInputCNO
            address={formik.values.mapAddress}
            onSelectAddress={(address) => {
              formik.setFieldValue("mapAddress", address.address);
              formik.setFieldValue("lat", address.lat);
              formik.setFieldValue("lng", address.lng);
              triggerServiceSearch({ ...formik.values, lat:address.lat,lng:address.lng});

            }}
            label={"Service Location"}
          />
        </div>
      )}

      <div style={{ marginTop: 12 }} />

      <DateTimePickerCNO
        dateName="startDate"
        datePlaceholder="Start Date"
        datePickerLabel="Start by Date"
        onDateChange={(date) => {
          formik.setFieldValue("startDate", date);
          triggerServiceSearch({ ...formik.values, startDate:date});

        }}
        formik={formik}
        iconColor="red"
        isRequired={false}
      />

      <DateTimePickerCNO
        dateName="endDate"
        datePlaceholder="End Date"
        datePickerLabel="End by Date"
        onDateChange={(date) => {
          formik.setFieldValue("endDate", date);
          triggerServiceSearch({ ...formik.values, endDate:date});

        }}
        formik={formik}
        // minDate={formik.values.startDate}
        iconColor="green"
        isRequired={false}
      />

      {tillTablet && (
        <FormActionButtons
          rightText="Search"
          rightClick={formik.handleSubmit}
          leftClick={closeServiceFilterDialog}
          formik={formik}
        />
      )}
    </form>
  );
}

export default ServiceFilterModal;

const useStyles = makeStyles((theme) => ({
  container_ServiceFilterModal: {
    padding: 8,
    "&>*:first-child": {
      height: ({ tillTablet }) => (tillTablet ? "auto" : 68),
    },
  },

  chip: {
    background: "#444",
  },
  textField: {
    borderRadius: 5,
    "& input": {
      background: "white",
      color: "#444",
      borderRadius: 5,
      padding: 8,
    },
    "& .MuiOutlinedInput-multiline": {
      color: "#444",
    },
    "& .MuiOutlinedInput-adornedStart": {
      background: "white",
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": "#444",
      caretColor: "#444",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
      {
        paddingLeft: 14,
      },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    "& .MuiOutlinedInput-adornedEnd": {
      padding: 0,
    },
    "& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
      padding: 0,
    },
  },
  paper: {
    background: "white !important",
    "& ::-webkit-scrollbar": {
      display: "none",
    },
  },
  noOptions: {
    color: "#BE0A02",
  },
  popper: {
    zIndex: 1301,
  },
  assignToWrapper: {
    display: "flex",
    marginTop: 8,
    flexDirection: "column",
    "&>*:first-child": {
      flex: 1,
      marginBottom: 8,
    },
  },
}));
