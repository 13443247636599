import { toast } from "react-toastify";
import { v4 } from "uuid";
import {
  apiQuotationGetMediaLink,
  apiQuotationSearch,
  apiQuotationUploadMedia,
  apiQuotationInitUpload
} from "../../api/quotation";
import { getContentType, isArrayEmpty } from "../Helpers";
import { FileType, MediaSizeType, MediaType } from "../mappings";

export const getQuotationMedias = async (quotationId) => {

  let medias = [];

  console.log("Get Quotation Medias: quotationId: ", quotationId);

  try {
    const response = await apiQuotationSearch.post({ ids: [quotationId] });

    console.log("Get Quotation Medias: response: ", response);
    console.log("Get Quotation Medias: !isArrayEmpty(response.data): ", !isArrayEmpty(response.data));

    if (response && !isArrayEmpty(response.data.data)) {
      const quotation = response.data.data[0];

      console.log("Get Quotation Medias: quotation: ", quotation);

      medias = quotation.mediaLinks;
    }
  } catch (error) {
    console.log("Error: Get Quotation Medias: ", error);
  }

  console.log("Get Quotation Medias: medias: ", medias);

  return medias;

}

export const getResolvedQuotationMedias = async (medias, quotationId) => {

  const mediaIds = medias.map(media => media.id);

  const dataToSend = {
    ids: mediaIds,
    size: MediaSizeType.BigScreen
  };

  let resolvedMedias = [];

  try {
    const response = await apiQuotationGetMediaLink.post(dataToSend);

    resolvedMedias = response.data.links;

    const resolvedMediasPromise = resolvedMedias.map(async (media, index) => {

      let fileType = null, contentType = null;

      try {
        // if ([1].includes(index)) {
        //   throw new Error("forcefully error upload");
        // } else {
        const resp = await getContentType(media.link);
        fileType = resp.fileType;
        contentType = resp.contentType;
        // }
      } catch (error) {
        toast.error("Media not found. Please delete and upload the media again.");
        console.log("Error: Resolve Quotation Medias: Get Content Type: ", error);
        return null;
      }

      const isImage = fileType === FileType.Image;
      return {
        ...media,
        uri: media.link,
        // thumbnailUri: allMedias[index].link,
        fileType: fileType,
        type: contentType,
        isResolved: true,
        isImage,
        offerId: quotationId,
        newId: v4()
      };

    });

    resolvedMedias = await Promise.all(resolvedMediasPromise);

  } catch (error) {
    resolvedMedias = [];
    console.log("Error: Resolve Quotation Medias: ", error);
  }

  return resolvedMedias.filter(Boolean);

}

export const getAWSQuotationUploadSignedUrls = async (ids = []) => {

  if (isArrayEmpty(ids)) return null;

  let awsUploadSignedUrls = [];

  try {
    const awsUploadSignedUrlsResp = await apiQuotationInitUpload.post({ ids });
    awsUploadSignedUrls = awsUploadSignedUrlsResp.data.links;
  } catch (error) {
    awsUploadSignedUrls = [];
    console.log("Error: Get AWS Quotation Media Signed URLs: ", error);
  }

  return awsUploadSignedUrls;

}

// TODO: group medias based on mediaType and then make server call accordingly
export const saveUploadedQuotationMediasToServer = async (medias, ids, quotationId, mediaType = MediaType.Input) => {

  const prevMedias = await getQuotationMedias(quotationId);

  const newMedias = medias.map((media, index) => ({
    id: ids[index],
    title: media.name,
    fileType: media.type,
    abstract: "",
    link: ids[index],
    mediaType: media.mediaType || mediaType,
    offerId: quotationId
  }));

  const mediasToSave = [...newMedias, ...prevMedias];

  const dataToSend = {
    offerId: quotationId,
    medias: mediasToSave
  };

  return await apiQuotationUploadMedia.post(dataToSend);

}

