import { apiProvider } from './provider';

export class ApiCore {

    constructor(options) {
        
        if (options.getAll) {
            this.getAll = () => apiProvider.getAll(options.url);
        }

        if (options.getSingle) {
            this.getSingle = (id) => apiProvider.getSingle(options.url, id);
        }

        if (options.post) {
            this.post = (payload, header) => apiProvider.post(options.url, payload, header);
        }
        
        if (options.put) {
            this.put = (payload) => apiProvider.put(options.url, payload);
        }

        if (options.patch) {
            this.patch = (payload) => apiProvider.patch(options.url, payload);
        }

        if (options.remove) {
            this.remove = (id) => apiProvider.remove(options.url, id);
        }
    }
}