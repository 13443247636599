import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuotationItem } from '../../actions/quotationItemActions';

function EditQuotationItemTitle({
  open,
  onClose,
  quotationItem,
}) {

  const dispatch = useDispatch();
  const { editLoader } = useSelector(state => state.quotationItems);

  const formik = useFormik({
    initialValues: {
      title: quotationItem.title,
    },
    validationSchema: yup.object({
      title: yup.string().required('Title is required')
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("Edit Quotation Item: title: ", values);

    const payload = {
      ...quotationItem,
      title: values.title
    };

    dispatch(updateQuotationItem(payload, true));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Quotation Item Title"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name="title"
          label="Quotation Item Title"
          placeholder="Enter Quotation Item Title"
          icon={mdiFormatText}
          formik={formik}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditQuotationItemTitle
