import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container_JobTargetDetailsCard: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
  },
  cardContent: {
    padding: theme.spacing(1),
    '&>*': {
      marginTop: theme.spacing(1)
    }
  },
  cardTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR
  },
  row: {
    color: theme.colors.JOB_DETAIL_RATE_COLOR,
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'center'
  },
  formTitle: {
    marginLeft: 5
  },
  form: {
    '&>*': {
      marginTop: theme.spacing(2)
    }
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    borderRadius: 10,
    width: '70vw',
    maxWidth: '90vh'
  },
  rateTypeWrapper: {
    '&>*:first-child': {
      marginBottom: theme.spacing(1)
    }
  },
  rateTypeChips: {
    display: 'flex',
    '&>*': {
      marginRight: theme.spacing(1)
    }
  },
}));