import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getResolvedJobMediasByIds } from "../../actions/jobActions";
import { apiMedia } from "../../api";
import { MediaSizeType } from "../../utils/mappings";
import GroupMedias from "../GroupMedias/GroupMedias";

const JobGroupedMedia = ({ job }) => {
  const dispatch = useDispatch();
  const { getJobMediaLoader } = useSelector((state) => state.jobs);

  const [mediaLinks, setMediaLinks] = useState([]);
  const [loading, setLoading] = useState(false);

  const [resolvedMediasLocal, setResolvedMediasLocal] = useState(mediaLinks);

  const fetchMediaLinks = async () => {
    if (!job) return;

    if (Array.isArray(job.mediaLinks) && job.mediaLinks.length === 0) return;

    let payload = {
      ids: job.mediaLinks.map((image) => image.id),
      size: MediaSizeType.Thumbnail,
    };

    try {
      setLoading(true);
      const response = await apiMedia.post(payload);
      const fetchedMedias = await response.data.links;
      setMediaLinks(fetchedMedias);
    } catch (error) {
      setMediaLinks([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => fetchMediaLinks(), []);

  const onResolveMediasByIds = async (
    resolvedMedia,
    _,
    openMedia,
    // setLightboxController
    setIsImageViewerVisible
  ) => {
    if (openMedia) {
      return;
    }

    if (resolvedMedia) {
      const resolvedMediaIdsLocal = resolvedMediasLocal.map(
        (image) => image.id
      );

      const _resolvedJobMedias = mediaLinks.map((media, index) => {
        if (media.id === resolvedMedia.id) {
          return {
            ...resolvedMedia,
            uri: resolvedMedia.isImage ? resolvedMedia.link : media.link,
            mediaOpenLink: resolvedMedia.link,
          };
        } else if (resolvedMediaIdsLocal.includes(media.id)) {
          return resolvedMediasLocal[index];
        } else {
          return {
            ...media,
            uri: media.link,
            thumbnailUri: media.link,
            isResolved: false,
            isImage: false,
          };
        }
      });

      setResolvedMediasLocal(_resolvedJobMedias);
      setIsImageViewerVisible(true);
      // setLightboxController((prev) => ({
      //   ...prev,
      //   toggler: true,
      // }));
    }
  };

  const onChangeImageIndex = (index, setIsImageViewerVisible) => {
    const nextImage = resolvedMediasLocal[index];
    if (nextImage?.isResolved) return;

    const payload = {
      clickedMedia: nextImage,
      allMedias: mediaLinks,
    };

    dispatch(
      getResolvedJobMediasByIds(
        payload,
        (...args) => onResolveMediasByIds(...args, setIsImageViewerVisible),
        false
      )
    );
  };

  // const onThumbnailClick = (index, setLightboxController) => {
  //   const document = mediaLinks[index];

  //   setResolvedMediasLocal(mediaLinks);
  //   setLightboxController((prev) => ({
  //     ...prev,
  //     toggler: true,
  //   }));

  //   const payload = {
  //     clickedMedia: document,
  //     allMedias: mediaLinks,
  //   };

  //   dispatch(
  //     getResolvedJobMediasByIds(payload, (...args) =>
  //       onResolveMediasByIds(...args, setLightboxController)
  //     )
  //   );
  // };
  const onThumbnailClick = (index, setIsImageViewerVisible) => {
    const document = mediaLinks[index];

    const payload = {
      clickedMedia: document,
      allMedias: mediaLinks,
    };

    dispatch(
      getResolvedJobMediasByIds(payload, (...args) =>
        onResolveMediasByIds(...args, setIsImageViewerVisible)
      )
    );
  };

  return (
    <GroupMedias
      mediaLinks={mediaLinks}
      resolvedMediaLinks={resolvedMediasLocal}
      onClick={onThumbnailClick}
      onChangeImageIndex={onChangeImageIndex}
      loading={loading}
      imageViewerLoader={getJobMediaLoader}
    />
  );
};

export default JobGroupedMedia;
