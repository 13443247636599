import { AppConstants } from "./AppConstants";
import { parseNumber } from "./Helpers";

export const getBonusValues = (target, hourlyRate, hoursWorked, alreadyPaidBonus, isTaskCompleted) => {

  const jobTotalAmount = parseNumber(target) * parseNumber(hourlyRate);
  const jobNetAmount = parseNumber(hoursWorked) * parseNumber(hourlyRate);
  const jobSavings = parseNumber(jobTotalAmount) - parseNumber(jobNetAmount);

  let netBonusValue = 0;
  let calcBonus = 0;
  let isBonusNegative = false;
  if (isTaskCompleted && jobSavings > 0) {
    calcBonus = (AppConstants.JOB_BONUS_PERCENTAGE / 100) * parseNumber(jobSavings);
    netBonusValue = parseNumber(calcBonus) - parseNumber(alreadyPaidBonus);
    isBonusNegative = netBonusValue < 0;
    netBonusValue = isBonusNegative ? 0 : netBonusValue;
  }

  return {
    jobTotalAmount,
    jobNetAmount,
    jobSavings,
    calcBonus,
    isBonusNegative,
    netBonusValue
  };

};