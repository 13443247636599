import { Button, Typography, useTheme } from '@material-ui/core';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { batch, useDispatch, useSelector } from 'react-redux';
import { fetchSubTasks, setIsNewSubTaskAdded, setSubTasks, toggleAddSubTaskDialog, updateSubTask } from '../../actions/subTaskActions';
import AddSubTaskDialog from '../AddSubTaskDialog';
import SubTaskDetailsListItem from './SubTaskDetailsListItem';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import RightsManager from '../../utils/RightsManager';
import { SubTaskType } from '../../utils';
import { QuotationStatus } from '../../utils/mappings';
import FakeSubTaskCard from '../FakeSubTaskCard';
import DraggableLists from '../../library/DraggableLists';

function SubTaskDetailsCard({ job, setJob, subTaskType }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const isSubTaskOfTask = subTaskType === SubTaskType.Task;
  const isSubTaskOfQuotation = subTaskType === SubTaskType.Quotation;
  const isSubTaskOfInvoice = subTaskType === SubTaskType.Invoice;

  const isSent = (isSubTaskOfQuotation || isSubTaskOfInvoice) && job.status === QuotationStatus.Sent;
  const isSentAccepted = (isSubTaskOfQuotation || isSubTaskOfInvoice) && job.status === QuotationStatus.SentAccepted;

  const dispatch = useDispatch();
  const {
    isAddSubTaskDialog,
    subTasks,
    subTasksLoader,
    addLoader,
    editLoader,
    deleteLoader,
    isNewSubTaskAdded
  } = useSelector(state => state.subTasks);

  console.log("-------- subTasksnj -------", subTasks);
  console.log("-------- isNewSubTaskAdded -------", isNewSubTaskAdded);

  const [isServerSubTasksVisible, setIsServerSubTasksVisible] = useState(false);

  const toggleIsServerSubTasksVisible = () => setIsServerSubTasksVisible(!isServerSubTasksVisible);

  useEffect(() => {
    return () => {
      setIsServerSubTasksVisible(false);
      dispatch(setSubTasks([]));
    };
  }, []);

  const loadSubTasksFromServer = () => {

    if (!job) return;

    let refreshData = false;

    setIsServerSubTasksVisible(true);

    if (subTasks.length && subTasks.length > 0) {

      const isItemsOfSameId = subTasks.every(item => item.invoiceId === (job.taskId || job.id));

      if (!isItemsOfSameId) {
        refreshData = true;
      };

    }

    if (!subTasks.length) {
      refreshData = true;
    }

    if (refreshData) {
      const payload = {
        taskId: job.taskId || job.id,
      };
      dispatch(fetchSubTasks(payload));

    }

  };

  useEffect(() => {
    if (isNewSubTaskAdded) {
      console.log("---isNewSubTaskAdded triggering loadSubTasksFromServer--- ");
      loadSubTasksFromServer();
    }
    return () => {
      dispatch(setIsNewSubTaskAdded(false));
    };
  }, [isNewSubTaskAdded]);

  return (
    <div className={GlobalStyles.detailsCardContainer} style={{ padding: '16px 8px' }}>
      <div className={GlobalStyles.detailsCardSectionTitle} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography>{isSubTaskOfInvoice ? 'Invoice Items' : 'Sub Tasks'}</Typography>
        {
          isUserAdmin &&
          !(isSent || isSentAccepted) &&
          <Button
            style={{
              background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
              color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
              borderColor: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
              fontSize: '0.7rem',
              fontWeight: 700
            }}
            startIcon={<Icon path={mdiPlus} size="1rem" />}
            onClick={async () => {

              dispatch(toggleAddSubTaskDialog(true));

              if (!subTasks.length) {
                dispatch(fetchSubTasks({ taskId: job.id }));
              }

            }}
          >
            {isSubTaskOfInvoice ? 'Add Invoice Item' : 'Add SubTask'}
          </Button>
        }

      </div>

      {
        !isServerSubTasksVisible &&
        <FakeSubTaskCard
          subItem={{
            id: job.oneSubTaskId,
            title: job.oneSubTaskTitle,
            categories: job.oneSubTaskCategories,
          }}
          onLoadFromServer={() => loadSubTasksFromServer()}
          emptyMessage={"No sub task added."}
          isSubTask={true}
        />
      }

      {
        isServerSubTasksVisible && job.oneSubTaskId &&
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}>
          <p
            style={{
              color: theme.colors.LINK_COLOR_GREY,
              fontSize: '0.8rem',
              border: `1px solid ${theme.colors.LINK_COLOR_GREY}`,
              borderRadius: 4,
              padding: '4px 8px',
              cursor: 'pointer'
            }}
            onClick={toggleIsServerSubTasksVisible}
          >
            Load Less
          </p>
        </div>
      }

      {
        isServerSubTasksVisible &&
        <div className={GlobalStyles.subItemsListContainer}>

          <DraggableLists
            items={subTasks}
            listItemPropName="subTask"
            notFoundText={'No sub task added'}
            loading={subTasksLoader}
            onDrop={(dragItems, reOrderItems, dropEvent) => {
              dispatch(setSubTasks(reOrderItems));
              dragItems.map(item => dispatch(updateSubTask(item)));
            }}
          >
            <SubTaskDetailsListItem />
          </DraggableLists>

        </div>
      }
      {
        (addLoader || editLoader || deleteLoader)
        &&
        <FullScreenLoaderCNO />
      }

      {
        isAddSubTaskDialog &&
        <AddSubTaskDialog
          job={job}
          setJob={setJob}
          subTaskType={subTaskType}
        />
      }

    </div>
  )
}

export default SubTaskDetailsCard
