import React, { useEffect, useState } from 'react';
import ScreenView from '../../../components/ScreenView/ScreenView';
import { makeStyles, Box } from '@material-ui/core';
import { mdiFormSelect } from '@mdi/js';
import { CreateFormDialog } from '../dialogs';
import { InfiniteScrollCNO, MetaText, StickyWrapper } from '../../../library';
import { useGlobalStyles } from '../../../utils/Styles/GlobalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@material-ui/data-grid';
import ListCTAButtons from '../../../components/ListCTAButtons';
import { FormBuilderStatusBar, FormListItem } from '../components';
import { fetchForms, handleFormSearch } from '../../../actions/formBuilderActions';

function FormBuilderPage() {

  const styles = useStyles();

  const [isCreateForm, setIsCreateForm] = useState(false);

  const openCreateFormDialog = () => {
    setIsCreateForm(true);
  }

  const GlobalStyles = useGlobalStyles();

  const { forms, formSearchParams } = useSelector(state => state.formBuilder)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleFormSearch(formSearchParams));
  }, []);

  return (
    <ScreenView
      headerTitle="Form Builder"
      bgColor={styles.screenBgColor}
    >
      <Box m={1} className={GlobalStyles.listingContainer}>

        <FormBuilderStatusBar />

        <InfiniteScrollCNO
          items={forms}
          next={null}
          hasMore={false}
          loading={false}
          renderListItem={(form) => (
            <FormListItem form={form} />
          )}
          notFoundTextMessage={'No Forms Present'}
          notFoundIcon={mdiFormSelect}
          showListCTAButtons={true}
          listCTAButtonsProps={{ addTitle: 'Create Form', onClickAdd: openCreateFormDialog }}
        />
      </Box>

      <CreateFormDialog
        open={isCreateForm}
        onClose={() => setIsCreateForm(false)}
      />

    </ScreenView>
  )
}

export default FormBuilderPage;

const useStyles = makeStyles(theme => ({
  screenBgColor: {
    background: theme.colors.MODAL_BACKGROUND_COLOR,
  },
  container: {

  }
}))