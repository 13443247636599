import { IconButton, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@material-ui/core';
import {
  mdiAccountDetails,
  mdiCancel,
  mdiDelete,
  mdiDownload,
  mdiEye,
  mdiCheck,
  mdiRecycle,
  mdiSend,
  mdiStamper,
  mdiContentCopy,
  mdiFileTable
} from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import LinkCNO from '../../library/LinkCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import ConfirmationDialogCNO from '../../library/ConfirmationDialogCNO';
import { useDispatch, useSelector } from 'react-redux';
import { convertToJob, generateQuotationPdf, getResolvedQuotationByIds, updateQuotationStatus } from '../../actions/quotationActions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { QuotationStatus, RateType, AddInvoiceDialogMode, TaskStatus } from '../../utils/mappings';
import QuotationConfirmDeleteDialog from './QuotationConfirmDeleteDialog';
import QuotationRejectDialog from './QuotationRejectDialog';
import QuotationSendDialog from './QuotationSendDialog';
import { getLatLngFromAddress, parseNumber } from '../../utils/Helpers';
import { fetchQuotationItems, setQuotationItems } from '../../actions/quotationItemActions';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { useHistory } from 'react-router-dom';
import RightsManager from '../../utils/RightsManager';
import FileViewerModal from '../../library/FileViewerModal';
import { getQuotationAcceptHtmlString } from '../../pdfTemplates/templates';
import { getCustomerById } from '../../actions/customerActions';
import { AppConstants } from '../../utils/AppConstants';
import DuplicateQuotationDialog from './DuplicateQuotationDialog';
import { generateInvoiceFromQuotation, toggleAddInvoiceDialog } from '../../actions/invoiceActions';
import { ItemType, setMediasPendingToLS, triggerUploadMediasLsBg } from '../../utils/MediaUpload';
import LocalStorageConstants from '../../utils/LocalStorageConstants';

function QuotationListCardPopover({ quotation, closeMoreActions }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : null;

  const isUserAdmin = RightsManager.isAdmin();

  const isDraft = quotation.status === QuotationStatus.Draft;
  const isReady = quotation.status === QuotationStatus.Ready;
  const isSent = quotation.status === QuotationStatus.Sent;
  const isSentAccepted = quotation.status === QuotationStatus.SentAccepted;
  const isSentRejected = quotation.status === QuotationStatus.SentRejected;

  const isQuotationDetails = useLocation().pathname.includes("/quotation/");

  const dispatch = useDispatch();

  const { quotationItems, quotationItemsLoader } = useSelector(state => state.quotationItems);
  const { allQuotationLoader } = useSelector(state => state.quotations);
  const { isPdfGenerating } = allQuotationLoader;

  const [isConverToJob, setIsConverToJob] = useState(false);
  const [targetStatus, setTargetStatus] = useState(quotation.status);

  const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false);
  const [isConfirmQuotationStatus, setIsConfirmQuotationStatus] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isConfirmConvertToJob, setIsConfirmConvertToJob] = useState(false);
  const [isReadyToShare, setIsReadyToShare] = useState(false);
  const [pdfFileSource, setPdfFileSource] = useState(null);
  const [isDuplicateDialog, setIsDuplicateDialog] = useState(false);

  useEffect(() => {

    if (isConverToJob) {
      handleConvertToJob();
    }
  }, [quotationItems]);

  useEffect(() => {
    return () => setIsConverToJob(false);
  }, []);

  const handleCreatePDF = async (download = false) => {

    const onGeneratePdfComplete = (fileSource) => {
      setPdfFileSource(fileSource);
      if (!isQuotationDetails) {
        dispatch(setQuotationItems([]));
      }
    };

    await dispatch(generateQuotationPdf(quotation, onGeneratePdfComplete, download));

    if (download) {
      closeMoreActions();
    }

  };

  const history = useHistory();

  const onConvertToJob = (taskId) => {

    const mediasPending = {
      from: ItemType.Quotation,
      to: ItemType.Job,
      medias: quotation.mediaLinks,
      itemId: taskId,
    }

    setMediasPendingToLS([mediasPending]);

    history.push(`/job/${taskId}`);

    LocalStorageConstants.setItem("mediaPendingLoader", true);
    window.dispatchEvent(new Event("media-pending-loader-storage"));

    triggerUploadMediasLsBg();

  };

  const handleConvertToJob = async () => {

    const values = quotation;

    let coordinates = { latitude: 0, longitude: 0 };
    coordinates = values.mapAddress ? await getLatLngFromAddress(values.mapAddress) : coordinates;

    const adminAlreadyAssigned = values.offerAssignments.find(item => (item.id === authUser.id || item.staffId === authUser.id));

    const assignedUsers = adminAlreadyAssigned ? values.offerAssignments : [authUser, ...values.offerAssignments];

    console.log("=========handleConvertToJob=====values mediaLinks: ", values.mediaLinks);

    const jobPayload = {
      oneSubTaskId: null,
      oneSubTaskTitle: null,
      oneSubTaskCategories: [],
      categories: values.categories,
      customerId: values.customerId,
      customerText: values.customerText,
      description: values.description,
      endDate: AppConstants.DEFAULT_END_DATE,
      endTime: AppConstants.DEFAULT_END_TIME,
      lat: coordinates.latitude.toString(),
      lng: coordinates.longitude.toString(),
      mapAddress: values.mapAddress,
      mediaLinks: [],
      rate: quotationItems.length ? parseNumber(values.amount, 'round') : parseNumber(values.rate, 'round'),
      rateType: quotationItems.length ? RateType.Fixed : values.rateType,
      startDate: AppConstants.DEFAULT_START_DATE,
      startTime: AppConstants.DEFAULT_START_TIME,
      taskAssignments: assignedUsers.map(item => (
        {
          staffId: item.id || item.staffId,
          staffText: item.staffText || `${item.firstName} ${item.lastName}`,
          startDate: AppConstants.DEFAULT_START_DATE,
          startTime: AppConstants.DEFAULT_START_TIME,
          endDate: AppConstants.DEFAULT_END_DATE,
          endTime: AppConstants.DEFAULT_END_TIME,
        }
      )),
      title: values.title,
      targetType: values.targetType,
      target: values.target ? values.target : 0,
      status: TaskStatus.Open
    };

    console.log("=======handleConvertToJob=====jobPayload======", jobPayload);

    dispatch(convertToJob(jobPayload, onConvertToJob));

    setIsConverToJob(false);

  };

  const handleQuotationSendDialog = async () => {

    if (!quotationItems.length) {

      const quotationItemsPayload = {
        offerId: quotation.id
      };
      await dispatch(fetchQuotationItems(quotationItemsPayload));


    }
    await dispatch(getCustomerById([quotation.customerId]));
    setIsSend(true);

  };

  return (
    <div className={GlobalStyles.container_MorePopover}>

      {
        (isPdfGenerating || quotationItemsLoader)
        &&
        <FullScreenLoaderCNO />
      }

      <List className={GlobalStyles.list}>

        {
          !isQuotationDetails &&
          <LinkCNO
            to={`quotation/${quotation.id}`}
          >
            <ListItem className={GlobalStyles.listItem}>
              <ListItemText primary='Details' />
              <ListItemIcon>
                <IconButton edge='end'>
                  <Icon path={mdiAccountDetails} size='1.5rem'
                    color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </LinkCNO>
        }
        {
          isUserAdmin &&
          (isDraft) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setIsConfirmQuotationStatus(true);
              setTargetStatus(QuotationStatus.Ready);
            }}
          >
            <ListItemText primary='Mark as Ready' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiCheck} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          false &&
          <ListItem className={GlobalStyles.listItem}>
            <ListItemText primary='View Quotation' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiEye} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          (isReady) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={handleQuotationSendDialog}
          >
            <ListItemText primary='Send Quotation' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiSend} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>

        }

        {
          isUserAdmin &&
          (isSent || isSentAccepted) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={handleQuotationSendDialog}
          >
            <ListItemText primary='Resend Quotation' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiSend} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          (!isDraft) &&
          isUserAdmin &&
          <ListItem className={GlobalStyles.listItem} onClick={() => setIsConfirmConvertToJob(true)}>
            <ListItemText primary='Convert To Job' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiRecycle} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          (isReady || isSent) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setIsConfirmQuotationStatus(true);
              setTargetStatus(QuotationStatus.SentAccepted)
            }}
          >
            <ListItemText primary='Approve Quotation' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiStamper} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          (isReady || isSent) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setIsReject(true);
            }}
          >
            <ListItemText primary='Reject Quotation' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiCancel} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          <FileViewerModal
            mimeType={'pdf'}
            filePath={pdfFileSource}
            dialogTitle={`Quotation Preview - ${quotation.title}`}
            onClick={handleCreatePDF}
            closeMoreActions={closeMoreActions}
          >
            <ListItem className={GlobalStyles.listItem}>
              <ListItemText primary='Preview Quotation' />
              <ListItemIcon>
                <IconButton edge='end'>
                  <Icon path={mdiEye} size='1.5rem'
                    color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </FileViewerModal>
        }

        {
          !isDraft &&
          <ListItem className={GlobalStyles.listItem} onClick={() => handleCreatePDF(true)}>
            <ListItemText primary='Download Quotation' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiDownload} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          (isReady) &&
          <ListItem className={GlobalStyles.listItem} onClick={() => setIsConfirmDeleteModal(true)}>
            <ListItemText primary='Delete Quotation' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiDelete} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          <ListItem className={GlobalStyles.listItem} onClick={() => {

            dispatch(toggleAddInvoiceDialog({ status: true, mode: AddInvoiceDialogMode.GenerateFromQuotation }));

            dispatch(generateInvoiceFromQuotation(quotation.id));

            if (closeMoreActions) closeMoreActions();

          }}>
            <ListItemText className={GlobalStyles.listItemText} primary='Generate Invoice' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiFileTable} size='1.5rem' color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          <ListItem className={GlobalStyles.listItem}
            onClick={() => {

              setIsDuplicateDialog(true);

              if (!quotationItems.length) {
                const payload = {
                  offerId: quotation.id
                };
                dispatch(fetchQuotationItems(payload));
              }

            }}
          >
            <ListItemText primary='Duplicate' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiContentCopy} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

      </List>

      {
        isDuplicateDialog &&
        <DuplicateQuotationDialog
          closeMoreActions={closeMoreActions}
          onClose={() => setIsDuplicateDialog(false)}
          open={isDuplicateDialog}
          quotation={quotation}
        />
      }

      {isConfirmDeleteModal &&
        <QuotationConfirmDeleteDialog
          open={isConfirmDeleteModal}
          onClose={() => setIsConfirmDeleteModal(false)}
          quotation={quotation}
          closeMoreActions={closeMoreActions}
        />
      }

      {
        isReject &&
        <QuotationRejectDialog
          open={isReject}
          onClose={() => setIsReject(false)}
          quotation={quotation}
          closeMoreActions={closeMoreActions}
        />
      }

      {
        isConfirmQuotationStatus &&
        <ConfirmationDialogCNO
          open={isConfirmQuotationStatus}
          onClose={() => setIsConfirmQuotationStatus(false)}
          dialogTitle="Update Quotation Status"
          messages={[
            targetStatus === QuotationStatus.SentAccepted ? "Are you sure you want to approve?" : null,
            targetStatus === QuotationStatus.Ready ? "Are you sure draft is ready to share?" : null,
          ]}
          yesClick={() => {

            const meta = getQuotationAcceptHtmlString(quotation);

            const quotationPayload = {
              ...quotation,
              status: targetStatus,
              meta: targetStatus === QuotationStatus.SentAccepted ? meta : ""
            };
            dispatch(updateQuotationStatus(quotationPayload))
          }}
          rightText={
            (targetStatus === QuotationStatus.SentAccepted && "Approve") ||
            (targetStatus === QuotationStatus.Ready && "Yes") ||
            "Save"
          }
          closeMoreActions={closeMoreActions}
        />
      }

      {
        isSend &&
        <QuotationSendDialog
          open={isSend}
          onClose={() => setIsSend(false)}
          quotation={quotation}
          closeMoreActions={closeMoreActions}
        />
      }

      {
        isConfirmConvertToJob &&
        <ConfirmationDialogCNO
          open={isConfirmConvertToJob}
          onClose={() => {
            setIsConfirmConvertToJob(false);
            setIsConverToJob(true);
          }}
          dialogTitle="Comfirm Convert To Job"
          messages={["Are you sure you want to convert to job?"]}
          yesClick={async () => {
            setIsConverToJob(true);
            // fetch quotation items to add as subtask
            const payload = {
              offerId: quotation.id,
            };
            await dispatch(fetchQuotationItems(payload));
            closeMoreActions();
          }}
          rightText="Convert"
        // closeMoreActions={closeMoreActions}
        />
      }

    </div >
  )
}

export default QuotationListCardPopover
