import React, { useState, useEffect } from "react";
import "./App.css";
import SearchLocationInput from "./components/GooglePlcasesApi";
import MapComponent from "./components/Map";
import GooglePlaceInputCNO from "../../library/GooglePlaceInputCNO";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  btnAdd: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    width: '100%',
  },
}));

function NewApp({ formik, setOpenMap, setOpenMore }) {
  const styles = useStyles();
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 26.449923,
    lng: 80.331871,
  });
  const [location, setLocation] = useState("");

  const handleSubmit = () => {
    formik.setFieldValue("location", location);
    setOpenMap(false);
    setOpenMore(false);
  };
  // console.log("llocation",location)
  return (
    <div style={{ height: "auto", width: "100%" }}>
      <div>
        <GooglePlaceInputCNO
          address=""
          onSelectAddress={(address) => {
            console.log("address", address);
            setLocation(address.address);
            const lat = parseFloat(address.lat);
            const lng = parseFloat(address.lng);

            if (!isNaN(lat) && !isNaN(lng)) {
              setSelectedLocation({
                lat: lat,
                lng: lng,
              });
            } else {
              console.error("Invalid latitude or longitude:", lat, lng);
            }
          }}
        />
        <Button
          variant="contained"
          className={styles.btnAdd}
          onClick={handleSubmit}
          disabled={!location} 
        >
          {`Done`}
        </Button>
      </div>

      <div
        style={{
          maxHeight: "400px",
          overflow: "auto",
        }}
      >
        <MapComponent selectedLocation={selectedLocation} />
      </div>
    </div>
  );
}

export default NewApp;
