import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container_UserMainDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.colors.PROFILE_HEADER_BACKGROUND_COLOR,
    padding: theme.spacing(4),
    borderRadius: 20,
    position: 'relative',
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    border: '2px solid white',
  },
  username: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.colors.PROFILE_USER_NAME_COLOR
  },
  abstract: {
    color: theme.colors.PROFILE_TEXT_INFO_COLOR,
    whiteSpace: 'pre-wrap'
  },
  code: {
    color: theme.colors.PROFILE_TEXT_INFO_COLOR,
    marginBottom: theme.spacing(1)
  },
  avatarBadge: {
    marginBottom: theme.spacing(2),
    borderRadius: '50%',
    '& .MuiBadge-anchorOriginBottomRightCircle': {
      right: 'calc(50%)',
      bottom: '10%',
      transform: 'scale(1) translate(50%, 50%)',
      transformOrigin: '100% 100%',
      width: 130,
    }
  },
  pickers: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  avatarWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatarStatusText: {
    fontSize: '0.7rem',
    position: 'relative',
    top: -15,
    background: theme.colors.INACTIVE_USER_CHIP_BACKGROUND_COLOR,
    borderRadius: 4,
    padding: '0 20px',
    color: theme.colors.INACTIVE_USER_CHIP_TEXT_COLOR
  }

}));