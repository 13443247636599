import moment from "moment";
import LocalStorageConstants from "./LocalStorageConstants";

const authUserCompany = LocalStorageConstants.getItem('authUserCompany');

export const AppConstants = {
  JOB_BONUS_PERCENTAGE: 20,
  DEFAULT_PDF_LOGO: "https://mefolia.com/logo-icon.png",
  MAX_FILE_SIZE: 100 * 1024 * 1024, //15 MB
  MAX_NUMBER_OF_FILES: 10,
  QUOTATION_NOTES: `*Taxes as applicable\n - Any query write us at ${(authUserCompany && authUserCompany.email) || ""}\n - This is a digital quotation and does not requires signature`,
  INVOICE_NOTES: `*After due date interest @10% will be charged\n- Any query write us at ${(authUserCompany && authUserCompany.email) || ""
    }\n- This is a digital invoice and does not requires signature`,
  DEFAULT_TAX_RATE: 20,
  SORTING_MULTIPLIER: 10,

  PLAY_STORE_LINK: "https://play.google.com/store/apps/details?id=com.emeistech.checkndout",
  APP_STORE_LINK: "https://apps.apple.com/us/app/checkndout/id1568117667",

  TELL_ME_MORE_VIDEO: 'https://www.youtube.com/watch?v=Q0D4jWEeFz8',
  NEWS_VIDEO: 'https://www.youtube.com/watch?v=x4KpXXGaLUs',

  SOCIAL_LINKS: {
    linkedin: 'https://www.linkedin.com/company/checkndout/',
    youtube: 'https://www.youtube.com/channel/UCYqfPNbXpYWWn33M0Zuj-QQ',
    facebook: 'https://www.facebook.com/CheckNdOut-100667722566854',
    instagram: "https://www.instagram.com/checkndout/",
    twitter: "https://twitter.com/CheckNdout"
  },
  SOCIAL_LINKS_COLOR: {
    linkedin: '#0e76a8',
    youtube: '#b2071d',
    facebook: '#39569c',
    instagram: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
    twitter: '#1DA1F2',
  },

  AppSlogan: "With meFolia, managing your remote workforce is a piece of cake. The platform lets you focus on your business and takes away the field staff tracking overhead.",

  DEFAULT_START_DATE: moment().toISOString(),
  DEFAULT_END_DATE: moment().toISOString(),
  DEFAULT_START_TIME: moment().startOf('day').add(8, 'h').toISOString(),
  DEFAULT_END_TIME: moment().startOf('day').add(17, 'h').toISOString(),

  DefaultQuotationEmailTemplate: `Hello <<Name>>\n\nThank you for the opportunity on the <<Title>> job.\n\nPlease find below summary of the Quotation for your kind reference.`,
  DefaultInvoiceEmailTemplate: `Hello <<Name>>,\n\nThank you giving us the opportunity to serve you for <<Title>> job.\n\nPlease find below summary of the Invoice for your kind reference.`,
  DefaultAppointmentEmailTemplate: `Hello <<Name>>,\n\nPlease find below details of appointment scheduled for <<Title>>.\n\nStarts on: <<StartDate>> at <<StartTime>>\n\nEnds on: <<EndDate>> at <<EndTime>>`,
  DefaultEmailSignatureTemplate: `Thank you !\n\nBest Regards\n<<FromName>>`,

  SUBSCRIPTION_TRIAL_PERIOD: 14, //days
};