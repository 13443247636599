import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import DialogCNO from '../../library/DialogCNO';
import FormActionButtons from '../FormActionButtons';
import * as yup from 'yup';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerSuggest } from '../../actions/suggestionsActions';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { toggleAddCustomerDialog } from '../../actions/customerActions';
import AddCustomer from '../AddCustomer/AddCustomer';
import { addQuotation, getResolvedQuotationByIds } from '../../actions/quotationActions';
import { useHistory } from 'react-router-dom';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { setQuotationItems } from '../../actions/quotationItemActions';
import { QuotationStatus } from '../../utils/mappings';
import { getTitleFromCustomer, validateStartDateEndDate } from '../../utils/Helpers';
import { AppConstants } from '../../utils/AppConstants';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';
import { ItemType, setMediasPendingToLS, triggerUploadMediasLsBg } from '../../utils/MediaUpload';
import LocalStorageConstants from '../../utils/LocalStorageConstants';

function DuplicateQuotationDialog({
  open,
  onClose,
  quotation,
  closeMoreActions
}) {

  const styles = useStyles();

  const dispatch = useDispatch();
  const suggestions = useSelector(state => state.suggestions);
  const { quotationItems } = useSelector(state => state.quotationItems);
  const { allQuotationLoader: { addLoader, getQuotationMediaLoader } } = useSelector(state => state.quotations);

  const [mediaResolveLoader, setMediaResolveLoader] = useState(false);

  const validationSchema = yup.object({
    customer: yup.object().required("Contact person is required.").nullable(),
    startDate: yup
      .string()
      .required('Quotation start date is required!'),
    endDate: yup
      .string()
      .required('Quotation end date is required!')
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      ),
    startTime: yup
      .string()
      .required('Quotation start time is required!'),
    endTime: yup
      .string()
      .required('Quotation end time is required!')
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      ),
  });

  const history = useHistory();

  const onDuplicateSuccess = ({ id }) => {

    const mediasPending = {
      from: ItemType.Quotation,
      to: ItemType.Quotation,
      medias: quotation.mediaLinks,
      itemId: id,
    }

    setMediasPendingToLS([mediasPending]);

    handleClose();
    history.push(`/quotation/${id}`);

    LocalStorageConstants.setItem("mediaPendingLoader", true);
    window.dispatchEvent(new Event("media-pending-loader-storage"));

    triggerUploadMediasLsBg();
  };

  const handleCreateDuplicate = async (values) => {

    console.log("handleCreateDuplicate====>  values", values);

    dispatch(setQuotationItems([]));

    const payload = {
      ...quotation,
      status: QuotationStatus.Draft,
      mediaLinks: [],
      customerId: values.customer.id,
      customerText: values.customer.title,
      mapAddress: values.location,
      lat: values.lat,
      lng: values.lng,
      startDate: values.startDate,
      startTime: values.startTime,
      endDate: values.endDate,
      endTime: values.endTime,
      title: getTitleFromCustomer(values.customer, quotation.title)
    };

    console.log("handleCreateDuplicate====>  quotationItems", quotationItems);
    console.log("handleCreateDuplicate====>  payload", payload);

    dispatch(addQuotation(payload, onDuplicateSuccess, quotationItems));

  };

  const formik = useFormik({
    initialValues: {
      customer: {
        id: quotation.customerId,
        title: quotation.customerText
      },
      location: quotation.mapAddress,
      lat: quotation.lat,
      lng: quotation.lng,
      startDate: AppConstants.DEFAULT_START_DATE,
      startTime: AppConstants.DEFAULT_START_TIME,
      endDate: AppConstants.DEFAULT_END_DATE,
      endTime: AppConstants.DEFAULT_END_TIME,
    },
    validationSchema,
    onSubmit: (values) => handleCreateDuplicate(values)
  });

  const handleAddCustomerDialog = () => {
    dispatch(toggleAddCustomerDialog(true));
  };

  const handleClose = () => {
    formik.resetForm();
    closeMoreActions();
    onClose();
  };

  console.log("DuplicateQuotationDialog====> ", { open, quotation, values: formik.values });

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle={`Duplicate Quotation - ${quotation.title}`}
      minHeight="fit-content"
      width='50vw'
    >

      {
        (addLoader || getQuotationMediaLoader || mediaResolveLoader) &&
        <FullScreenLoaderCNO />
      }

      <form onSubmit={formik.handleSubmit} >
        <div className={styles.customerWrapper} style={{ marginBottom: '1rem' }}>
          <AutocompleteCNO
            name='customer'
            label='Contact Person'
            formik={formik}
            placeholder='Search Customer'
            data={suggestions}
            optionLabel={x => x.title || ""}
            renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
            onSuggestionClick={(value) => {

              if (value) {
                formik.setValues({
                  ...formik.values,
                  customer: value,
                  location: value.mapAddress,
                  lat: value.lat,
                  lng: value.lng
                });
              }

            }}
            onChange={(value) => {
              if (value) {
                dispatch(fetchCustomerSuggest(value));
              }
            }}
            isRequired
          />
          <Icon
            path={mdiPlus}
            size='43px'
            color='goldenrod'
            onClick={handleAddCustomerDialog}
          />
        </div>

        <DateTimePickerCNO
          dateName="startDate"
          datePlaceholder="Start Date"
          datePickerLabel='Start by Date'
          onDateChange={date => {
            formik.setFieldValue('startDate', date);
          }}

          timeName="startTime"
          timePlaceholder="Start Time"
          timePickerLabel='Start by Time'
          onTimeChange={date => {
            formik.setFieldValue('startTime', date);
          }}

          formik={formik}
          disablePast={true}
          iconColor="red"
        />

        <DateTimePickerCNO
          dateName="endDate"
          datePlaceholder="Finish Date"
          datePickerLabel='Finish by Date'
          onDateChange={date => {
            formik.setFieldValue('endDate', date);
          }}

          timeName="endTime"
          timePlaceholder="Finish Time"
          timePickerLabel='Finish by Time'
          onTimeChange={date => {
            formik.setFieldValue('endTime', date);
          }}

          formik={formik}
          minDate={formik.values.startDate}
          iconColor="green"
        />

        <Typography style={{ marginTop: 20 }}>
          Are you sure you want to create duplicate quotation?
        </Typography>

        <FormActionButtons
          rightText='Yes'
          leftText='No'
          leftClick={handleClose}
          disabled={!formik.isValid}
        />
      </form>

      <AddCustomer
        onAdd={(customer) => {
          console.log("===== customer : add: ", customer);
          const _customer = {
            firstName: customer.firstName,
            id: customer.id,
            isActive: true,
            lastName: customer.lastName,
            title: `${customer.firstName} ${customer.lastName}`,
            profilePic: null
          };
          formik.setFieldValue('customer', _customer);
          formik.setFieldValue('location', customer.mapAddress);
          formik.setFieldValue('lat', customer.lat);
          formik.setFieldValue('lng', customer.lng);
        }}
        quotationLocation={formik.values.location}
      />

    </DialogCNO>
  )
}

const useStyles = makeStyles((theme) => ({
  customerWrapper: {
    display: 'flex',
    '&>*:first-child': {
      flex: 1
    },
    '&>*:last-child': {
      alignSelf: 'flex-end',
    }
  }
}))

export default DuplicateQuotationDialog;