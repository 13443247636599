import { Box, ListItem, ListItemText, Popover, makeStyles, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { IconMain, MetaText } from '../../../library';
import { PostListTypes } from '../../../utils/mappings';

const PostListTypesDopDown = ({ value, onChange }) => {

  const theme = useTheme();
  const styles = useStyles();

  console.log("PostListTypesDopDown")

  const [dropdownRef, setDropdownRef] = useState(null);
  const [selected,setSelected]=useState(false);
  const [option,setOption]=useState('postTypes')

  const openDropdown = (e) => {
    setDropdownRef(e.currentTarget);
  };

  const closeDropdown = () => {
    setDropdownRef(null);
  };

  const open = Boolean(dropdownRef);

  const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  };

  const transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  };

  const handleChange = (item) => () => {

    setOption(item);
    console.log("item->",item.postType)
    setSelected(true);
    typeof onChange === 'function' && onChange(item);

    closeDropdown();

  }

  return (
    <div className={styles.container}>

      <Box component="section" sx={{ p: 2, typography: 'h3',whiteSpace: 'nowrap' }}>
        Select type of post
      </Box>
      
      <Box className={styles.content}>
        
        {
          !selected ?
          (
            <Box onClick={openDropdown} className={styles.content} >
              <MetaText fontSize='1.1rem'>{option}</MetaText>
              <IconMain name={'menu-down'} size='1.5rem' />
            </Box>
          ):
          (
            <Box onClick={openDropdown} className={styles.content} >
              <MetaText fontSize='1.1rem'>{option.title}</MetaText>
            </Box>
          )
        }
        

        <Popover
          open={open}
          anchorEl={dropdownRef}
          onClose={closeDropdown}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        
        >
          {
            PostListTypes.entities.map(postType => (
              <ListItem
                key={postType.id}
                className={styles.listItem}
                onClick={handleChange(postType)}
                sx={{p:5,}}
              >
                <ListItemText primary={postType.title} />
              </ListItem>
            ))
          }
        </Popover>
      </Box>
    </div>
    
  )
}

export default PostListTypesDopDown;

const useStyles = makeStyles((theme) => ({

  content: {
    background: theme.colors.BACKGROUND_COLOR_LIGHTER,
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    width: 250,
    padding: '4px 0px',
    borderRadius: 4,
    fontSize:"4rem",
    justifyContent:"center",
  },

  container:{
    display:"flex",
    justifyContent:"center",
    height:'250px',
    alignItems:"center",
   
  },

  listItem:{
    background: theme.colors.MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR,
    paddingTop: 4,
    paddingBottom: 4,
    marginTop: 2,
    width: 250,
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.01)'
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0,
    },
    '& .MuiIconButton-root': {
      padding: 6
    },
  }



}))