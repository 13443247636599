import { fade, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_ProfileListCard: {
    borderRadius: 10,
    padding: '8px',
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    color: theme.palette.getContrastText(theme.palette.background.main),
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    width: '100%'
  },
  deactivate: {
    opacity: '0.5',
  },
  jobMeta: {
    display: 'flex',
  },
  meta: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  title: {
    color: theme.colors.LIST_CARD_TITLE_COLOR,
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center'
  },
  cardInfoRow: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.LIST_CARD_INFO_TEXT_COLOR,
    '&>*:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  cardInfoRowText: {
    fontSize: '0.8rem'
  },
  moreIcon: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
  avatarWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatarStatusText: {
    fontSize: '0.5rem',
    position: 'relative',
    top: -12,
    background: theme.colors.INACTIVE_USER_CHIP_BACKGROUND_COLOR,
    borderRadius: 4,
    padding: '0 5px',
    color: theme.colors.INACTIVE_USER_CHIP_TEXT_COLOR
  }
}));