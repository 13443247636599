import { Box, CircularProgress, Typography, useTheme } from '@material-ui/core';
import { mdiDelete, mdiFilePlus } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SnapItem, SnapList, useDragToScroll } from 'react-snaplist-carousel';
import { deleteInvoiceMedia, getInvoiceMediaLinks, getResolvedInvoiceMediasByIds, processAndUploadInvoiceMedia } from '../../actions/invoiceActions';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import DocumentUploadCNO from '../../library/DocumentUploadCNO';
import { MediaType } from '../../utils';
import ConfirmYesNoDialog from '../ConfirmYesNoDialog/ConfirmYesNoDialog';
import FormBox from '../FormBox';
import DocumentSelectDisplayCard from '../DocumentTypeDisplayCard/DocumentSelectDisplayCard';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import RightsManager from '../../utils/RightsManager';
import { ImagesViewerSliderCNO } from '../../library/ImagesViewerSliderCNO';
import MediaUploadRetryClearButtons from '../../library/MediaUploadRetryClearButtons';
import { useMediasPendingUploadingForCurrrentItem } from '../../hooks/useMediasPendingUploadingForCurrrentItem';

function InvoiceMediaDetailsCard({
  invoice,
}) {

  const isUserAdmin = RightsManager.isAdmin();
  const isMediaUploadingLs = useMediasPendingUploadingForCurrrentItem();

  const isUserAllowed = isUserAdmin && !isMediaUploadingLs;

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  console.log("==========InvoiceMediaDetailsCard=======invoices=====", invoice);

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  const dispatch = useDispatch();
  const {
    allInvoiceLoader: { uploadInvoiceMediaLoader, deleteInvoiceMediaLoader, getInvoiceMediaLoader },
  } = useSelector(state => state.invoices);

  const invoiceMedias = invoice.mediaLinks;

  console.log("==========InvoiceMediaDetailsCard=======invoiceMedias=====", invoiceMedias);

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isImageViewerVisible, setIsImageViewerVisible] = useState(false);
  const [mediasUploadCount, setMediasUploadCount] = useState(0);
  const [resolvedMediasLocal, setResolvedMediasLocal] = useState([]);

  useEffect(() => {

    if (invoiceMedias && invoiceMedias.length && invoiceMedias.length > 0) {
      if (invoiceMedias[0].link.startsWith('http')) {
      } else {
        const payload = {
          mediaLinks: invoiceMedias
        };

        dispatch(getInvoiceMediaLinks(payload));
      }
    }

  }, [invoiceMedias]);

  const handleMediaUpload = async (files) => {

    if (files && files.length) {
      setMediasUploadCount(files.length);

      const payload = {
        mediasToUpload: files,
        mediaLinks: invoice.mediaLinks,
        invoiceId: invoice.id,
        mediaType: MediaType.Input,
      };

      await dispatch(processAndUploadInvoiceMedia(payload));

    }

  };

  const handleDelete = () => {

    const payload = {
      id: selectedCard.id,
      invoiceId: invoice.id,
      mediaLinks: invoice.mediaLinks,
    };

    dispatch(deleteInvoiceMedia(payload));

  };

  const onResolveMediasByIds = async (resolvedMedia, _, openMedia) => {

    if (openMedia) {
      return;
    }

    if (resolvedMedia) {

      const resolvedMediaIdsLocal = resolvedMediasLocal.map(image => image.id);

      const _resolvedJobMedias = invoiceMedias.map((media, index) => {

        if (media.id === resolvedMedia.id) {
          return {
            ...resolvedMedia,
            uri: resolvedMedia.isImage ? resolvedMedia.link : media.link,
            mediaOpenLink: resolvedMedia.link
          };
        } else if (resolvedMediaIdsLocal.includes(media.id)) {

          return resolvedMediasLocal[index];

        } else {
          return {
            ...media,
            uri: media.link,
            thumbnailUri: media.link,
            isResolved: false,
            isImage: false
          };
        }

      });

      console.log("====_resolvedJobMedias=====", _resolvedJobMedias);

      setResolvedMediasLocal(_resolvedJobMedias);
      setIsImageViewerVisible(true);

    }

  };

  console.log("====resolvedMediasLocal=====", resolvedMediasLocal);


  const onChangeImageIndex = (index) => {

    const nextImage = resolvedMediasLocal[index];
    setCurrentImageIndex(index);

    if (nextImage.isResolved) return;

    const payload = {
      clickedMedia: nextImage,
      allMedias: invoiceMedias
    };

    dispatch(getResolvedInvoiceMediasByIds(payload, onResolveMediasByIds, false));

  };

  const renderMediaThumbnail = (file, index) => {
    return (
      <SnapItem key={file.id} margin={{ right: 5, left: 5 }}>
        <div className={GlobalStyles.documentCard}>
          <div
            className={GlobalStyles.documentThumbnail}
            onClick={() => {

              const payload = {
                clickedMedia: file,
                allMedias: invoiceMedias
              };

              dispatch(getResolvedInvoiceMediasByIds(payload, onResolveMediasByIds));

              setCurrentImageIndex(index);

            }}
          >
            <DocumentSelectDisplayCard document={file} />
          </div>

          {
            isUserAllowed &&
            <div className={GlobalStyles.documentActionRow} onClick={(e) => e.stopPropagation()}>
              {
                <div className={GlobalStyles.documentActions} onClick={() => {
                  setIsDeleteConfirm(true);
                  setSelectedCard(file);
                }}>
                  <Icon
                    path={mdiDelete}
                    size='1.5rem'
                    color={theme.colors.ERROR_COLOR}
                  />
                </div>
              }
            </div>
          }

        </div>
      </SnapItem>
    )
  };

  return (
    <FormBox title="Medias"
      bgColor={theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR}
      titleColor={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR}
    >

      {
        (deleteInvoiceMediaLoader || getInvoiceMediaLoader) &&
        <FullScreenLoaderCNO />
      }

      {
        (!invoiceMedias.length && !uploadInvoiceMediaLoader && isUserAllowed) ? (
          <div className={GlobalStyles.documentUploadWrapper}>
            <DocumentUploadCNO
              pickerIcon={{
                icon: mdiFilePlus,
                size: '3rem',
                color: theme.colors.IMAGE_UPLOAD_ICON_COLOR
              }}
              multiple={true}
              onUpload={async (files) => {

                handleMediaUpload(files);

              }}
            />
          </div>
        ) : (
          <Typography align='center'>
            {(invoiceMedias.length || uploadInvoiceMediaLoader) ? '' : 'No document found.'}
          </Typography>
        )
      }

      <div className={GlobalStyles.documentThumbnailWrapper}>
        <SnapList ref={snapList}>
          {
            uploadInvoiceMediaLoader
              ? (
                [...Array(mediasUploadCount).keys()].map((_, id) => (
                  <SnapItem key={id} margin={{ right: 5, left: 5 }}>
                    <div className={GlobalStyles.documentLoader}>
                      <CircularProgress />
                    </div>
                  </SnapItem>
                ))
              )
              : ""
          }
          {
            invoiceMedias.length ? (
              invoiceMedias.map((invoice, index) => renderMediaThumbnail(invoice, index))
            ) : null
          }
        </SnapList>
      </div>

      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <MediaUploadRetryClearButtons />
        {
          (invoiceMedias.length && !uploadInvoiceMediaLoader && isUserAllowed) ?
            <div className={GlobalStyles.documentAddMediaAction}>
              <DocumentUploadCNO
                pickerIcon={{
                  icon: mdiFilePlus,
                  size: '1.5rem',
                  color: theme.colors.IMAGE_UPLOAD_ICON_COLOR
                }}
                multiple={true}
                onUpload={async (files) => {

                  handleMediaUpload(files);

                }}
              />
            </div> : null
        }
      </Box>

      <ImagesViewerSliderCNO
        images={resolvedMediasLocal.map((image) => ({ ...image, src: image.uri }))}
        currentImageIndex={currentImageIndex}
        isOpen={isImageViewerVisible}
        onClose={() => {
          setIsImageViewerVisible(false);
          setResolvedMediasLocal([]);
        }}
        onChangeImageIndex={onChangeImageIndex}
        isMediaLoading={getInvoiceMediaLoader}
      />

      {
        isDeleteConfirm &&
        <ConfirmYesNoDialog
          open={isDeleteConfirm}
          setOpen={setIsDeleteConfirm}
          message={`Are you sure, you want to delete?`}
          onConfirm={handleDelete}
        />
      }

    </FormBox>
  )
}

export default InvoiceMediaDetailsCard;