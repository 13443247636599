import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useState } from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import HeadingBar from '../HeadingBar/HeadingBar';
import InputCNO from '../../library/InputCNO';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import { apiTaskEdit } from '../../api';
import { toast } from 'react-toastify';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { getTaskById } from '../../actions/jobActions';
import { useDispatch } from 'react-redux';

function EditJobTitleDialog({ job, setJob, isJobTitleEditable, setIsJobTitleEditable }) {

  const styles = useStyles();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    setIsJobTitleEditable(false);
  };

  const handleUpdate = async (values) => {
    setLoading(true);

    job.title = values.jobTitle;
    setJob({
      ...job,
      title: values.jobTitle
    });

    await apiTaskEdit.post(job)
      .then(response => {
        setLoading(false);
        if (response.status === 200) {
          toast.success("Job title edited successfully!!");
        }
        else {
          toast.error("There is some issues in editing job detail, please try again!");
        }
      })
      .catch(error => {
        console.log("Edit Job title Error: ", error);
        toast.error("There is some issues in editing job detail, please try again!");
      });
    dispatch(getTaskById([job.id]));
    handleClose();
  };

  const validationSchema = yup.object({
    jobTitle: yup.string().required("Required!!!")
  });

  const formik = useFormik({
    initialValues: {
      jobTitle: job.title
    },
    validationSchema,
    onSubmit: values => handleUpdate(values)
  });

  return (
    <Dialog
      open={isJobTitleEditable}
      className={styles.container}
      classes={{
        paper: styles.paper
      }}
    >

      {
        loading &&
        <FullScreenLoaderCNO />
      }

      <HeadingBar title="Edit Job Title" onClose={handleClose} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <InputCNO
          name='jobTitle'
          label='Job Title'
          formik={formik}
          icon={mdiFormatText}
          fullWidth
        />
        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
          formik={formik}
        />
      </form>
    </Dialog>
  )
}

export default EditJobTitleDialog

const useStyles = makeStyles(theme => ({
  container: {
    '&>*': {
      marginTop: theme.spacing(2)
    }
  },
  paper: {
    padding: theme.spacing(2),
    // color: theme.colors.DIALOG_BACKGROUND_COLOR,
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    borderRadius: 10,
    width: '70vw',
    maxWidth: '90vh'
  },
  formTitle: {
    marginLeft: 5,
    color: theme.colors.DIALOG_TITLE_COLOR,
  },
  form: {
    '&>*': {
      marginTop: theme.spacing(2)
    }
  },
}));