import { Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import DialogCNO from '../library/DialogCNO';
import RightsManager from '../utils/RightsManager';
import FormActionButtons from './FormActionButtons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FullScreenLoaderCNO from '../library/FullScreenLoaderCNO';
import { useOnlineStatus } from '../hooks';
import { toast } from 'react-toastify';

function SubscriptionNotAllowedDialog({
  isAllowed,
  featureName = "This feature",
  shouldRedirect = true,
  onAction = () => null,
  closeMoreActions
}) {

  const isOnline = useOnlineStatus();

  const history = useHistory();

  const isUserAdmin = RightsManager.isAdmin();

  const message = isUserAdmin ? (`${featureName} is not enabled in current subscription. Please upgrade the subscription to enable this feature.
  `) : (`${featureName} is not enabled in current subscription. Please upgrade the subscription to enable this feature.\n\nPlease contact your Company Administrator to upgrade subscription.
  `);

  const rightText = isUserAdmin ? 'Upgrade' : 'OK';
  const leftText = 'Cancel';

  const { subscriptionDetailsLoader } = useSelector(state => state.subscription);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(!isAllowed);
  }, [isAllowed]);

  const onClose = () => {
    setIsModalOpen(false);
    onAction();
  };

  const onCancel = () => {
    if (shouldRedirect) {
      history.push("/home");
    }

    onClose();

    if (closeMoreActions) {
      closeMoreActions();
    }

  };

  const onUpgrade = () => {

    const authUserCompany = JSON.parse(localStorage.getItem("authUserCompany"));

    history.push(`/mycompany/${authUserCompany.id}#subscriptions`);

    onClose();
  };

  console.log("=========isModalOpen===", isModalOpen);

  if (subscriptionDetailsLoader) {
    return (
      <Fragment>
        {subscriptionDetailsLoader ? <FullScreenLoaderCNO /> : null}
      </Fragment>
    );
  }

  if (!isOnline) {
    toast.error("You are not connected to internet. Please check your connectivity.")
    return null;
  }

  return (
    <DialogCNO
      open={isModalOpen}
      onClose={onCancel}
      dialogTitle={`${featureName} not enabled`}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1
      }}>

        <Typography style={{ marginTop: 24, paddingRight: 12 }}>
          {message}
        </Typography>

        <FormActionButtons
          leftText={leftText}
          rightText={rightText}
          isLeftButton={isUserAdmin}

          rightClick={isUserAdmin ? onUpgrade : onCancel}
          leftClick={onCancel}

          closeMoreActions={closeMoreActions}
        />

      </div>
    </DialogCNO>
  )
}

export default SubscriptionNotAllowedDialog;
