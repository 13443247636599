import { fade, makeStyles, Typography, useTheme } from "@material-ui/core";
import { mdiAccount, mdiCircleEditOutline, mdiEmail, mdiPhone } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import CloseIcon from "../../library/CloseIcon/CloseIcon";
import AppLogo from "../../assets/appLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { resetMapData } from "../../actions/taskSearchActions";
import {
  resetTrackSearchMarkers,
  setMapSearchParams,
  toggleSoftTracking,
} from "../../actions/trackSearchActions";
import { TOGGLE_SEARCH } from "../../utils/actions";
import { useGlobalStore } from "../../providers/GlobalProvider";
import AvatarCNO from "../../library/AvatarCNO/AvatarCNO";
import Moment from "react-moment";
import moment from "moment";
import { getDateWithoutTime, combineDateAndTime } from "../../utils/Tracking";
import { formatTimeWithTZ } from "../../utils/Helpers";

function ContactUserCard({ setData, setPage }) {
  const theme = useTheme();
  const styles = useStyles();

  const dispatch = useDispatch();

  const { mapSearchParams } = useSelector((state) => state.trackSearch);
  const teamMember = mapSearchParams.teamMember;
  const { title, mobile, email, profilePic } = teamMember;

  const trackStartDateTime = combineDateAndTime(
    mapSearchParams.trackStartDate,
    mapSearchParams.trackStartTime
  );
  const trackEndDateTime = combineDateAndTime(
    mapSearchParams.trackEndDate,
    mapSearchParams.trackEndTime
  );

  const { dispatchGlobal } = useGlobalStore();

  const openSidebar = () => {
    // dispatch(toggleMapSearchSidebar(false));
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: true,
    });
  };

  const handleUserCardClose = () => {
    openSidebar();

    dispatch(resetMapData());
    dispatch(resetTrackSearchMarkers());
    setData([]);
    setPage(0);

    dispatch(toggleSoftTracking(false));

    dispatch(
      setMapSearchParams({
        teamMember: null,
        trackStartDate: new Date(),
        trackEndDate: null,
        trackStartTime: moment().startOf("day").toDate(),
        trackEndTime: moment().endOf("day").toDate(),
        job: null,
        jobStartDate: null,
        jobEndDate: null,
      })
    );
  };

  const isTrackTimeDefault = () => {
    const trackStartTime = trackStartDateTime
      ? moment(trackStartDateTime).format("hh:mm a")
      : moment(getDateWithoutTime(new Date(), false)).format("hh:mm a");
    const trackEndTime = trackEndDateTime
      ? moment(trackEndDateTime).format("hh:mm a")
      : moment(getDateWithoutTime(new Date(), true)).format("hh:mm a");

    if (
      trackStartTime ===
        moment(getDateWithoutTime(new Date(), false)).format("hh:mm a") &&
      trackEndTime ===
        moment(getDateWithoutTime(new Date(), true)).format("hh:mm a")
    ) {
      // console.log("defaultCheck: isTrackTimeDefault ", true);
      return true;
    } else {
      // console.log("defaultCheck: isTrackTimeDefault ", false);
      return false;
    }
  };

  const handleMapSearchSidebar = () => {
    openSidebar();

    dispatch(resetMapData());
    dispatch(resetTrackSearchMarkers());
    setData([]);
    setPage(0);

    dispatch(toggleSoftTracking(false));
  };

  return (
    <div className={styles.container_ContactUserCard}>
      <div className={styles.topRowWrap}>
        <div className={styles.userInfoWrap}>
          <AvatarCNO
            src={profilePic}
            fallbackIcon={mdiAccount}
            avatarStyle={{
              border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}`,
            }}
            iconSize="2.5rem"
          />
          <Typography
            style={{
              marginLeft: "1rem",
              color: theme.colors.TRACK_SELECTED_USER_CARD_USERNAME_COLOR,
            }}
          >
            {title || "Anonymous"}
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon
            path={mdiCircleEditOutline}
            size={"1.3rem"}
            color={theme.colors.EDIT_MAP_ICON_COLOR}
            style={{ marginRight: 8 }}
            onClick={handleMapSearchSidebar}
          />
          <CloseIcon onClick={handleUserCardClose} />
        </div>
      </div>

      <div className={styles.bottomeRowWrap}>
        <a href={`tel:${mobile}`}>
          <Icon path={mdiPhone} size="1.5rem" color="orange" />
        </a>

        {(trackStartDateTime || trackEndDateTime) && !isTrackTimeDefault() && (
          <div className={styles.timeWrap}>
            {trackStartDateTime ? (
              <Moment format="DD MMM, YYYY hh:mm a">
                {formatTimeWithTZ(trackStartDateTime)}
              </Moment>
            ) : (
              String.fromCodePoint(0x267e)
            )}
            &nbsp;{String.fromCodePoint(0x279c)}&nbsp;
            {trackEndDateTime ? (
              <Moment format="DD MMM, YYYY hh:mm a">
                {formatTimeWithTZ(trackEndDateTime)}
              </Moment>
            ) : (
              String.fromCodePoint(0x267e)
            )}
          </div>
        )}

        {(trackStartDateTime || trackEndDateTime) && isTrackTimeDefault() && (
          <div className={styles.timeWrap}>
            {trackStartDateTime ? (
              <Moment format="DD MMM, YYYY">
                {formatTimeWithTZ(trackStartDateTime)}
              </Moment>
            ) : (
              String.fromCodePoint(0x267e)
            )}
            &nbsp;{String.fromCodePoint(0x279c)}&nbsp;
            {trackEndDateTime ? (
              <Moment format="DD MMM, YYYY">
                {formatTimeWithTZ(trackEndDateTime)}
              </Moment>
            ) : (
              String.fromCodePoint(0x267e)
            )}
          </div>
        )}

        <a href={`mailto:${email}`}>
          <Icon
            path={mdiEmail}
            size="1.5rem"
            color={theme.colors.GREEN_COLOR}
          />
        </a>
      </div>
    </div>
  );
}

export default ContactUserCard;

const useStyles = makeStyles((theme) => ({
  container_ContactUserCard: {
    minWidth: "300px",
    position: "absolute",
    top: 20,
    border: `2px solid ${theme.colors.TRACK_SELECTED_USER_CARD_BORDER_COLOR}`,
    borderRadius: 5,
    background: fade(
      theme.colors.TRACK_SELECTED_USER_CARD_BACKGROUND_COLOR,
      0.8
    ),
    padding: "2px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  topRowWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px",
  },
  userInfoWrap: {
    display: "flex",
    alignItems: "center",
  },
  bottomeRowWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    background: fade(
      theme.colors.TRACK_SELECTED_USER_CARD_DATE_SECTION_BACKGROUND_COLOR,
      0.6
    ),
    padding: "4px",
    borderRadius: "5px",
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: "50%",
    // border: `2px solid #fefefe`
  },
  timeWrap: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    "&>*": {
      fontSize: "0.7rem",
    },
  },
}));
