import { Badge, Button, makeStyles, useTheme } from "@material-ui/core";
import {
  mdiCamera,
  mdiImage,
  mdiImagePlus,
  mdiPanoramaVariantOutline,
} from "@mdi/js";
import React, { useState } from "react";
import FormBox from "../../../../components/FormBox";
import { MetaText } from "../../../../library";
import AvatarCNO from "../../../../library/AvatarCNO";
import RightsManager from "../../../../utils/RightsManager";
import { useGlobalStyles } from "../../../../utils/Styles/GlobalStyles";
import Upload from "rc-upload";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import Icon from "@mdi/react";
import { AppConstants } from "../../../../utils/AppConstants";
import { getMediaData } from "../../../../utils/Helpers";
import clsx from "clsx";

const AddEventCover = ({ formik, bgColor, title }) => {
  const isUserAdmin = RightsManager.isAdmin();

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const processMediaUpload = async (acceptedFiles) => {
    console.log("EventCover: acceptedFiles: ", acceptedFiles);

    const media = acceptedFiles[0];
    const { mediaData, thumbnailBlob } = await getMediaData(media);
    formik.setFieldValue("mediaLinks", [{ link: mediaData }]);
  };

  let dropzoneProps = {
    maxFiles: 1,
    maxSize: AppConstants.MAX_FILE_SIZE,
    disabled: false,
  };

  return (
    <FormBox {...(bgColor ? { bgColor } : {})} {...(title ? { title } : {})}>
      <div
        className={clsx(
          GlobalStyles.sectionChildrenContainer,
          styles.container
        )}
      >
        <div className={styles.imageWrapper}>
          {formik.values.mediaLinks && formik.values.mediaLinks.length ? (
            <img
              src={formik.values.mediaLinks[0].link}
              className={styles.image}
            />
          ) : (
            <Icon path={mdiPanoramaVariantOutline} size="9rem" color="grey" />
          )}
        </div>

        <Dropzone
          {...dropzoneProps}
          accept={"image/*"}
          onDropAccepted={processMediaUpload}
          onDropRejected={(rejectedFiles) => {
            console.log(
              "=======acceptedFiles==rejectedFiles===",
              rejectedFiles
            );
            // if (onError) onError();
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                  variant="contained"
                  className={clsx(
                    GlobalStyles.orangeButton,
                    styles.imagePicker
                  )}
                >
                  {formik.values.mediaLinks && formik.values.mediaLinks.length
                    ? "Edit Cover Image"
                    : "Upload Media Image"}
                </Button>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    </FormBox>
  );
};

export default AddEventCover;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    maxHeight: "20rem",
    objectFit: "contain",
  },
  imagePicker: {
    marginLeft: 0,
  },
}));
