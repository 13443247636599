import { IconButton, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import Moment from 'react-moment';
import { getCategory } from '../../utils/Helpers';
import RightsManager from '../../utils/RightsManager';
import JobTypeIcon from '../JobTypeIcon/JobTypeIcon';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import SubTaskStatusBar from '../SubTaskStatusBar/SubTaskStatusBar';
import { SubTaskType, TaskStatus } from '../../utils';
import Icon from '@mdi/react';
import { mdiDotsVertical } from '@mdi/js';
import { useStyles } from './FakeSubTaskCardStyles';

function FakeSubTaskCard({
  subItem = {},
  onLoadFromServer,
  emptyMessage = 'No Sub Task found',
  isSubTask = false
}) {

  let authUser = localStorage.getItem('authUser');
  authUser = authUser ? JSON.parse(authUser) : null;

  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const isUserAdmin = RightsManager.isAdmin();

  console.log("=======category======jobCategory========", subItem);

  const jobCategory = getCategory(subItem.categories ? subItem.categories[0] : null);

  if (!subItem.title) {
    return (
      <Typography style={{ marginTop: 8 }}>
        {emptyMessage}
      </Typography>
    )
  }

  return (

    <div className={styles.container_FakeSubTaskCard}>
      <div
        className={styles.blurEffectContainer}
        onClick={onLoadFromServer}
      >
        <p className={styles.loadMoreFromServerLink}>Load More</p>
      </div>
      <div style={{ flex: 1, display: 'flex' }}>
        <JobTypeIcon
          iconLink={jobCategory?.iconLink}
          name={jobCategory?.title}
          categoryId={jobCategory.id}
        />
        <div style={{ flex: 1, marginLeft: 8 }}>
          <div className={styles.subTaskCardTitleWrapper}>
            <Typography
              style={{ color: theme.colors.JOB_DETAIL_TITLE_COLOR, fontSize: '1rem' }}
            >
              {subItem.title}
            </Typography>

            {
              isUserAdmin &&
              <IconButton
                size="small"
              >
                <Icon
                  path={mdiDotsVertical}
                  size='1.8rem'
                  color={theme.colors.MORE_ACTIONS_ICON_COLOR}
                />
              </IconButton>
            }

          </div>
        </div>
      </div>

      <div className={styles.expandableDetailsWrapper}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Moment format={"MMM DD, YYYY"} style={{ color: theme.colors.LIST_CARD_USERNAMES_COLOR }}>
            {new Date()}
          </Moment>
          <Typography style={{ marginLeft: 3, marginRight: 3 }}>|</Typography>
          <pre style={{ color: theme.colors.LIST_CARD_USERNAMES_COLOR }}>
            {`${authUser.firstName} ${authUser.lastName}`}
          </pre>
        </div>
        <pre className={styles.moreSubTaskDetailsLink}>
          More Details
        </pre>
      </div>

      {
        isSubTask &&
        <div style={{ marginTop: theme.spacing(2) }}>
          <SubTaskStatusBar
            subTask={{ status: TaskStatus.Open }}
            styles={{ background: theme.colors.SUBTASK_STATUS_BACKGROUND_COLOR }}
          />
        </div>
      }

    </div>
  )
}

export default FakeSubTaskCard
