import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container: {
    '&>*': {
      marginTop: theme.spacing(2)
    }
  },
  textField: {
    borderRadius: 5,
    '& input': {
      background: 'white',
      color: '#444',
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: '#444'
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": "#444",
      caretColor: "#444",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 14
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0
    },
    '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      padding: 0
    }
  },
  paper: {
    background: 'white !important',
    '& ::-webkit-scrollbar': {
      display: 'none'
    }
  },
  noOptions: {
    color: '#BE0A02'
  },
  popper: {
    zIndex: 1301
  },
  assignToWrapper: {
    display: 'flex',
    // alignItems: 'flex-end',
    flexDirection: 'column',
    '&>*:first-child': {
      flex: 1,
      marginBottom: 8
    }
  },
  chip: {
    background: theme.palette.background.light
  },
  rateTypeWrapper: {
    '&>*:first-child': {
      marginBottom: theme.spacing(1)
    }
  },
  rateTypeChips: {
    display: 'flex',
    '&>*': {
      marginRight: theme.spacing(1)
    }
  },

}));