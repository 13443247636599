import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
  Tooltip,
  Box
} from "@material-ui/core";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import { useServiceProviderEngagemenStyles } from "./ServiceproviderEngagementDetailsCard";
import clsx from "clsx";
import { batch, useDispatch, useSelector } from "react-redux";
import FormBox from "../FormBox";
import { PostGroupedMedia } from "../../pages/PostsManagement";
import IconCNO from "../../library/IconCNO";
import { mdiChevronDown, mdiFolderMultipleImage } from "@mdi/js";

import {toast} from 'react-hot-toast';
function ServiceProviderMediasDetailCard({ profile }) {
  const theme = useTheme();
  const styles = useServiceProviderEngagemenStyles();
  const GlobalStyles = useGlobalStyles();

  const [isSectionExpanded, setIsSectionExpanded] = useState(false);

  useEffect(() => {
    return () => {
      setIsSectionExpanded(false);
    };
  }, []);



  return (
    <div style={{ padding: 0 }}>
      <Accordion
        expanded={isSectionExpanded}
        className={clsx(GlobalStyles.accordion, styles.accordion)}
      >
        <AccordionSummary
          expandIcon={
            <Icon
              path={mdiChevronDown}
              size="1.2rem"
              color={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR}
            />
          }
          className={GlobalStyles.accordionSummary}
          onClick={async (e) => {
            e.stopPropagation();

            const isExpanded = !isSectionExpanded;

            setIsSectionExpanded(isExpanded);
          }}
        >
          <Icon
            path={mdiFolderMultipleImage}
            size="1.2rem"
            className={styles.accordionTitleIcon}
          />
          <Typography>Medias</Typography>
        </AccordionSummary>

        {isSectionExpanded ? (
          <AccordionDetails className={styles.accordionDetails}>
            <div className={styles.grid}>
              
              {profile.mediaLinks.length > 0 ? (
                <div className={styles.listingWrapper}>
                  <div className={styles.listing}>
                    <PostGroupedMedia post={profile} />
                  </div>
                </div>
              ) : (
                <Typography className={styles.loader}>
                  {"No Medias found"}
                </Typography>
              )}
            </div>
          </AccordionDetails>
        ) : null}
      </Accordion>
    </div>
  );
}

export default ServiceProviderMediasDetailCard;
