import { makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import Moment from 'react-moment'
import { calendarStrings, DATE_FORMAT, TIME_FORMAT } from '../../utils/formatDate'
import { formatTimeWithTZ } from '../../utils/Helpers'
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles'

export const DateTimeFormat = {
  CalendarString: 1,
  TimeFormat: 2,
  DateFormat: 3
}

export const DateTimeColor = {
  Normal: 1,
  Start: 2,
  End: 3
}

/**
 * 
 * @param {date} dateTime date
 * @param {int} format format = calendarString, TIME_FORMAT, DATE_FORMAT
 * @returns 
 */
export const DateTimeText = ({
  children,
  format = DateTimeFormat.DateFormat,
  color = DateTimeColor.Normal,
  className = ''
}) => {

  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  return (
    <Moment
      interval={0}
      {...(format === DateTimeFormat.CalendarString ? { calendar: calendarStrings } : {})}
      {...(format === DateTimeFormat.TimeFormat ? { format: TIME_FORMAT } : {})}
      {...(format === DateTimeFormat.DateFormat ? { format: DATE_FORMAT } : {})}
      element={Typography}
      className={
        clsx(
          GlobalStyles.cardDate,
          styles.normal,
          {
            [styles.start]: color === DateTimeColor.Start,
            [styles.end]: color === DateTimeColor.End,
          },
          className
        )
      }
    >
      {formatTimeWithTZ(children)}
    </Moment>
  )
}

const useStyles = makeStyles((theme) => ({
  normal: {
    color: theme.colors.META_TEXT_COLOR,
    fontSize: '0.9rem'
  },
  start: {
    color: theme.colors.JOB_START_DATE_COLOR,
  },
  end: {
    color: theme.colors.JOB_END_DATE_COLOR,
  }
}));