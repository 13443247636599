import { Box, Button, makeStyles } from "@material-ui/core";
import React from "react";
import { ListCardItem } from "../../../library";
import { formatDateTimeFromNow } from "../../../utils/formatDate";
import { IconMain } from "../../../library";
import FormActionsPopover from "./FormActionsPopover";
import FormActionButtons from "../../../components/FormActionButtons";
import LinkCNO from "../../../library/LinkCNO";
import { FormFeatures } from "../utils/enums";

function FormListItem({ form }) {
  const styles = useStyles();

  const subTitles = [
    `Created By: ${form.createdBy.title}`,
    `Created: ${formatDateTimeFromNow(
      form.createdAt
    )}\t\tUpdated: ${formatDateTimeFromNow(form.updatedAt)}`,
  ];

  const feature = FormFeatures.getItem(form.feature);

  return (
    <ListCardItem
      cardTitle={feature.label}
      title={form.title}
      icon={feature.icon}
      subTitles={subTitles}
      description={form.description}
      CardActionComponent={<FormActionsPopover form={form} />}
      Footer={
        <Box className={styles.footer}>
          <LinkCNO to={`/forms/builder/${form.id}`}>
            <Button
              variant="contained"
              color="primary"
              startIcon={
                <IconMain
                  name={"brush-outline"}
                  size="1rem"
                  themeColorKey="FORM_CONFIRM_BUTTON_ICON_COLOR"
                />
              }
              // onClick={handeRightClick}
            >
              Design Form
            </Button>
          </LinkCNO>
        </Box>
      }
    />
  );
}

export default FormListItem;

const useStyles = makeStyles((theme) => ({
  container: {},
  footer: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    justifyContent: "flex-end",
  },
}));
