import React from 'react';
import { useDashBoardStyles } from './Dashboard.styles';
import Icon from '@mdi/react';
import { mdiViewDashboardOutline } from '@mdi/js';
import { useTheme, Typography } from '@material-ui/core';
import DashboardHeaderActionsPopover from './DashboardHeaderActionsPopover';

function DashboardHeader({ onRearrange }) {

  const theme = useTheme();
  const styles = useDashBoardStyles();

  return (
    <div className={styles.headerContainer}>

      <div className={styles.headerTileWrapper}>
        <Icon
          path={mdiViewDashboardOutline}
          size="1.2rem"
          color={theme.colors.DASHBOARD_HEADER_TITLE_ICON_COLOR}
        />
        <Typography variant="h4" className={styles.headerTitle}>Dashboard</Typography>
      </div>

      <DashboardHeaderActionsPopover
        onRearrange={onRearrange}
      />

    </div>
  )
}

export default DashboardHeader