import React from 'react'
import { DialogCNO } from '../../../library'
import { CreateForm } from '../forms'

function CreateFormDialog({ open, onClose }) {
  if (!open) return null;

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Create Form"
      width='60vw'
    >
      <CreateForm onClose={onClose} />
    </DialogCNO>
  )
}

export default CreateFormDialog