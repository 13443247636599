import { parseDisplayNumber } from "../../../utils/Helpers";
import LocalStorageConstants from "../../../utils/LocalStorageConstants";
import { Currency, CurrencyHTMLEntities } from "../../../utils/mappings";

export const generateQuotationFixedTemplate = (company, customer, quotation, quotationItems) => {

  const currencyType = LocalStorageConstants.getItem('currencyType', Currency.GBP);
  const authUserCompany = LocalStorageConstants.getItem('authUserCompany');

  const quotationItemsString = quotationItems.length ? quotationItems.map((item, index) => (`
                <div style="display: flex; padding: 16px; font-size: 14px;">
                      <div style="flex: 1;">
                        <div style="color: #4e4e4e;">${item.title}</div>
                        <pre style=" white-space: pre-line; margin: 0; margin-top: 10px; padding-left: 16px; font-family: Arial, Helvetica, sans-serif; font-size: 12px; color: #8f8f8f; ">
                          ${item.description}
                        </pre>
                      </div>
                      <div style="flex: 0.2; text-align: right ">${parseDisplayNumber(item.amount)}</div>
                    </div>
                    ${quotationItems.length - 1 === index ? '' : '<div style="height: 2px; background-color: #d4d4d4; margin-left: 2%; margin-right: 2%; "></div>'}
                    `)).join("\n")
    : '<div style="display: flex; padding: 16px; font-size: 14px;">No item found.</div>';

  const htmlString = `
  <div style="width: 800px;box-sizing: border-box; padding: 0px 16px; margin: 0; font-family: Arial, Helvetica, sans-serif; -webkit-print-color-adjust: exact;">

  <!-- <div style="
  position: absolute;
  top: 13rem;
  left: 18rem;
  align-self: center;
  z-index: -5;
  opacity: 0.1;">
    <img src=${company.profilePic} alt=${company.title} style="display: block; opacity: 0.1; border-radius: 50%; width: 200px; height: 200px;">
  </div> -->

  <!-- <div style="opacity: 0.2;
  color: rgb(204, 203, 203);
  font-size: 15rem;
  transform: rotate(310deg);
  -webkit-transform: rotate(310deg);
  position: absolute;
  background-color:red;
  top: 12rem;
  z-index: -10;">Quotation</p>
</div> -->

    <!-- header -->
    <header style="display: flex; flex-direction: column; align-items: center;">
        <div style="display: flex; width: 100%; align-items: center; padding-left: 8px; padding-bottom: 16px;  border-bottom: 3px solid #9e9e9e;">
            <img src=${company.profilePic} alt=alt=${company.title} style="display: block; border-radius: 50%; width: 80px; height: 80px;">
            <h1 style="color: #5e5e5e; font-weight: 500; font-size: 34px; margin-left: 16px;">
            ${company.title}
            </h1>
        </div>

        <div style="display: inline-block; background: #dedede70; color: #7e7e7e; padding: 5px 20px; margin: 16px auto; border-radius: 5px; font-weight: bold; font-size: 16px;">Quotation</div>
    </header>


    <div>

        <div>
            <!-- left detail -->
            <div style="width: 300px; float: left; margin-top: 8px;">
                <div style="background: #dedede70; color: #5e5e5e; font-size: 18px; padding: 10px 8px; border: 2px solid #bebebe8f; border-top-right-radius: 8px; border-top-left-radius: 8px;">
                    ${customer.title}
                </div>
                <div style="color: #606060; padding: 8px; border: 2px solid #e6e6e6; border-top: 0px; border-bottom-right-radius: 8px; border-bottom-left-radius: 8px;">
                    <div style="margin-bottom: 12px;">
                        <div style="overflow-wrap: anywhere">${customer.email}</div>
                        <div>${customer.mobile}</div>
                    </div>
                    <div>
                        <div style="color: #505050; font-size: 16px;">${customer.customerCompanyName}</div>
                        <div>${customer.customerCompanyAddress}</div>
                    </div>
                </div>
            </div>

            <!-- right details -->
            <div style="float: right; width: 300px;">

                <div style="box-sizing: border-box; float: left; width: 100%; font-size: 14px; vertical-align: middle; padding: 5px; background: #dedede70; border-radius: 8px; border: 2px solid #bebebe8f; margin-top: 8px;">
                    <div style="color: #4e4e4e; float: left; padding: 5px;">Quotation No.</div>
                    <div style="width: 140px; float: right; background: #fefefe; color: #3e3e3e; padding: 5px;  border-radius: 6px; text-align: center;">
                    ${quotation.offerNumber}
                    </div>
                </div>

                <div style="box-sizing: border-box; float: left; width: 100%; font-size: 14px; padding: 5px; background: #dedede70; border-radius: 8px; border: 2px solid #bebebe8f; margin-top: 8px;">
                    <div style="color: #4e4e4e; float: left; padding: 5px;">Quotation Date</div>
                    <div style="width: 140px; float: right; background: #fefefe; color: #3e3e3e; padding: 5px;  border-radius: 6px; text-align: center;">
                    ${quotation.offerDate}
                    </div>
                </div>

                <div style="box-sizing: border-box; float: left; width: 100%; font-size: 14px; padding: 5px; background: #dedede70; border-radius: 8px; border: 2px solid #bebebe8f; margin-top: 8px;">
                    <div style="color: #4e4e4e; float: left; padding: 5px;">Quotation Valid Till</div>
                    <div style="width: 140px; float: right; background: #fefefe; color: #3e3e3e; padding: 5px;  border-radius: 6px; text-align: center;">
                    ${quotation.validTillDate}
                    </div>
                </div>

                <div style="box-sizing: border-box; float: left; width: 100%; font-size: 14px; padding: 5px; background: #dedede70; border-radius: 8px; border: 2px solid #bebebe8f; margin-top: 8px;">
                    <div style="color: #4e4e4e; float: left; padding: 5px;">Billing Mode</div>
                    <div style="width: 140px; float: right; background: #fefefe; color: #3e3e3e; padding: 5px;  border-radius: 6px; text-align: center;">
                      Fixed
                    </div>
                </div>

            </div>

        </div>

        <!-- start date and end date -->
        <div style="clear: both; margin-top: 8px;">

            <div style="box-sizing: border-box; float: left; width: 300px; font-size: 14px; padding: 5px; background: #dedede70; border-radius: 8px; border: 2px solid #bebebe8f; margin-top: 8px;">
                <div style="color: #4e4e4e; float: left; padding: 5px;">Start Date</div>
                <div style="width: 140px; float: right; background: #fefefe; color: #3e3e3e; padding: 5px;  border-radius: 6px; text-align: center;">${quotation.startDate}</div>
            </div>

            <div style="box-sizing: border-box; float: right; width: 300px; font-size: 14px; padding: 5px; background: #dedede70; border-radius: 8px; border: 2px solid #bebebe8f; margin-top: 8px;">
                <div style="color: #4e4e4e; float: left; padding: 5px;">End Date</div>
                <div style="width: 140px; float: right; background: #fefefe; color: #3e3e3e; padding: 5px;  border-radius: 6px; text-align: center;">${quotation.endDate}</div>
            </div>

        </div>

        <div style="clear: both; color: #4e4e4e; font-size: 22px; margin-left: 4px; align-self: stretch; margin-top: 120px;">
            <div style="font-size: 16px; font-weight: 700;">${quotation.title}</div>
            <div style="font-size: 12px; margin: 4px 0; width: 100%; color: #8f8f8f;">
            ${quotation.description}
            </div>
        </div>

        <!-- amount details -->
        <div style="clear: both;">

            <div>
                <div style="display: flex; background: #eaeaea; color: #5e5e5e; margin-top: 16px; border: 2px solid #d9d9d9; border-radius: 6px; padding: 8px 12px">
                    <div style="flex: 1; font-weight: 700; font-size: 14px;">Item</div>
                    <div style="flex: 0.2; text-align: right; font-weight: 700; font-size: 14px; margin-right: 12px;">Amount</div>
                </div>

                ${quotationItemsString}

                <div style="height: 2px; background-color: #9e9e9e; "></div>
            </div>

            <div style="margin-top: 16px; display: flex; ">
                <div style="color: #707070; font-size: 12px; margin-left: 16px; flex: 1; line-height: 2; ">
                    ${quotation.notes ? quotation.notes : '*Taxes as applicable <br/> - Any query write us at contact@companyemail.com <br/> - This is a digital quotation and does not requires signature'}
                </div>


                <div style="color: #4e4e4e; flex: 1.5;">
                    <div style="text-align: right; display: flex; margin-right: 16px; margin-bottom: 16px; ">
                        <span style="flex: 1; font-size: 15px; ">Amount</span>
                        <span style="flex: 0.2; text-align: right; font-size: 15px; ">
                        ${CurrencyHTMLEntities[currencyType]}
                        </span>
                        <span style="flex: 0.3; font-size: 15px; ">${parseDisplayNumber(quotation.amount)}</span>
                    </div>
                    <div style="text-align: right; display: flex; margin-right: 18px; margin-bottom: 18px; ">
                        <span style="flex: 1; font-size: 15px; ">Discount @ ${parseDisplayNumber(quotation.discountRate)}%</span>
                        <span style="flex: 0.2; text-align: right; font-size: 15px; ">
                        ${CurrencyHTMLEntities[currencyType]}
                        </span>
                        <span style="flex: 0.3; font-size: 15px; ">${parseDisplayNumber(quotation.discount)}</span>
                    </div>
                    <div style="text-align: right; display: flex; margin-right: 18px; margin-bottom: 18px; ">
                        <span style="flex: 1; font-size: 15px; ">Amount after Discount</span>
                        <span style="flex: 0.2; text-align: right; font-size: 15px; ">
                        ${CurrencyHTMLEntities[currencyType]}
                        </span>
                        <span style="flex: 0.3; font-size: 15px; ">${parseDisplayNumber(quotation.amountAfterDiscount)}</span>
                    </div>
                    <div style="text-align: right; display: flex; margin-right: 18px; margin-bottom: 18px; margin-top:0px;">
                        <span style="flex: 1;font-size: 15px;">
                            ${quotation.includeTax ? 'Inclusive' : 'Exclusive'}&nbsp;VAT&nbsp;@&nbsp; ${parseDisplayNumber(quotation.taxRate)}%&nbsp;(${CurrencyHTMLEntities[currencyType]} ${parseDisplayNumber(quotation.taxableAmount)})
                        </span>
                        <span style="flex: 0.2; text-align: right; font-size: 15px; ">
                        ${CurrencyHTMLEntities[currencyType]}
                        </span>
                        <span style="flex: 0.3; font-size: 15px; ">${parseDisplayNumber(quotation.tax)}</span>
                    </div>
                    <div style="box-sizing: border-box; display: flex; justify-content: space-between; minWidth: 320px; width: '100%'; font-size: 16px; padding: 5px; align-items: center; background: #dedede70; color: #3e3e3e; border-radius: 8px; border: 2px solid #bebebe8f; margin-top: 8px; ">
                        <div style="flex: 0.5; font-weight: 700; font-size: 16px;">Total Costing*</div>
                        <div style="display: flex; background: #fefefe; padding: 6px 8px; flex: 0.5; border-radius: 6px; text-align: center; ">
                            <span style="flex: 0.6; text-align: right; font-size: 16px; font-weight: 700; ">${CurrencyHTMLEntities[currencyType]}</span>
                            <span style="flex: 0.4; font-size: 16px; font-weight: 700; text-align: right; ">${parseDisplayNumber(quotation.totalAmount)}</span>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>

    <!-- footer -->
    <footer style="border-top: 3px solid #9e9e9e; margin-top: 5px; padding: 8px; color: #707070; font-size: 12px; line-height: 1.4; margin-bottom:10px;">
        <!-- left -->
        <div style="flex: 1; margin-top:-5px">
            <span>${company.address}</span>
            <br/><span><b>E:</b>&nbsp;${company.email}&nbsp;&nbsp;</span>
            <span><b>M:</b>&nbsp;${company.mobile}</span>
            <br/><span><b>Company Reg No#</b>&nbsp;${company.cinNumber}</span>
            <br/><span><b>VAT&nbsp;&nbsp;Reg&nbsp;&nbsp;No#</b>&nbsp;${company.taxNumber}</span>
        </div>
        <!-- right -->
        <div style="text-align: right; margin-top:-70px">
            <span><b>Bank Name:</b>&nbsp;${company.bankName}</span>
            <br/><span><b>Account Name:</b>&nbsp;${company.bankAccountName}</span>
            <br/><span><b>Account Number#</b>&nbsp;${company.bankAccountNumber}</span>
            <br/><span><b>Sort Code:</b>&nbsp;${company.sortCode}</span>
        </div>
    </footer>

    ${authUserCompany.emailTermsAndConditionTemplate ? (
      `<div style="padding:5px; color: #4e4e4e; margin-right: 10px; margin-top: 80px; white-space: pre-line; font-size: 12px;">
        <p style="color: #4e4e4e; text-align: center; font-weight: bold; font-size: 14px;">Terms & Conditions</p>
        <p style="color: #4e4e4e; font-size: 12px;">${authUserCompany.emailTermsAndConditionTemplate}</p>
    </div>`
    ) : ""
    }
    

</div>
  `;

  return htmlString;
};