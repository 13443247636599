import { useFormik } from 'formik';
import React, { useState } from 'react';
import DialogCNO from '../library/DialogCNO';
import InputCNO from '../library/InputCNO';
import * as yup from 'yup';
import { updateAuthPassword } from '../actions/authUserActions';
import { useDispatch, useSelector } from 'react-redux';
import { mdiEye, mdiEyeOff, mdiLock } from '@mdi/js';
import FormActionButtons from './FormActionButtons';
import FullScreenLoaderCNO from '../library/FullScreenLoaderCNO';
import { RegexPatterns } from '../utils/regexHelpers';

function ProfileResetPassword({
  open,
  onClose,
  profile,
  mode,
}) {

  const dispatch = useDispatch();

  const { updateLoader } = useSelector(state => state.authUser);


  const [isPasswordSecure, setIsPasswordSecure] = useState({
    oldPassword: true,
    newPassword: true,
    confirmPassword: true
  });

  const validationSchema = yup.object({
    newPassword: yup.string()
      .required("New password is required.")
      .matches(
        RegexPatterns.passwordStrength,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmPassword: yup.string()
      .oneOf([yup.ref('newPassword'), null], 'Confirm Password must be same as New Password.')
      .required("Confirm password is required."),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: (values) => handleChangePassword(values)
  });

  const handleChangePassword = async (values) => {

    const payload = {
      username: profile.email,
      password: values.newPassword,
    };

    console.log("=====handleChangePassword: ", payload);

    await dispatch(updateAuthPassword(payload));

    formik.resetForm();

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Reset Passsword"
      width='60vw'
    >

      {
        updateLoader &&
        <FullScreenLoaderCNO />
      }

      <InputCNO
        name='newPassword'
        label='New Password'
        formik={formik}
        placeholder={'New Password'}
        type={isPasswordSecure.newPassword ? "password" : 'text'}
        icon={mdiLock}
        fullWidth
        isRequired={true}
        containerStyles={{ marginTop: 10 }}
        rightIcon={isPasswordSecure.newPassword ? mdiEye : mdiEyeOff}
        onRightIconClick={() => setIsPasswordSecure(prev => ({ ...prev, newPassword: !prev.newPassword }))}
        isCopyAllowed={false}
        isPasteAllowed={false}
      />

      <InputCNO
        name='confirmPassword'
        label='Confirm Password'
        formik={formik}
        placeholder={'Confirm Password'}
        type={isPasswordSecure.confirmPassword ? "password" : 'text'}
        icon={mdiLock}
        isRequired={true}
        containerStyles={{ marginTop: 10 }}
        rightIcon={isPasswordSecure.confirmPassword ? mdiEye : mdiEyeOff}
        onRightIconClick={() => setIsPasswordSecure(prev => ({ ...prev, confirmPassword: !prev.confirmPassword }))}
        isCopyAllowed={false}
        isPasteAllowed={false}
      />

      <FormActionButtons
        rightText={'Change Password'}
        rightClick={formik.handleSubmit}
        formik={formik}
        isLeftButton={false}
        wrapperStyles={{ marginTop: 30, marginBottom: 20 }}
      />
    </DialogCNO>
  )
}

export default ProfileResetPassword;
