import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DialogCNO from '../library/DialogCNO';
import { Typography } from '@material-ui/core';
import FormActionButtons from './FormActionButtons';
import LocalStorageConstants from '../utils/LocalStorageConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchPaymentDue } from '../actions/subscriptionActions';
import RightsManager from '../utils/RightsManager';

function SubscriptionReminderDialog() {

  const isUserAdmin = RightsManager.isAdmin();

  const authUserCompany = LocalStorageConstants.getItem('authUserCompany');

  const history = useHistory();

  const dispatch = useDispatch();
  const { paymentDue } = useSelector(state => state.subscription);

  const [isReminder, setIsReminder] = useState(false);

  useEffect(() => {

    if (!paymentDue) {
      dispatch(fetchPaymentDue());
    }

  }, []);

  useEffect(() => {

    console.log("========paymentDuepaymentDue========", paymentDue);

    if (!authUserCompany || !paymentDue) return;

    const reminderShownDate = LocalStorageConstants.getItem(`reminderShownDate-${authUserCompany.id}`);

    console.log("========paymentDuepaymentDue========", reminderShownDate);

    const hasReminderShown = reminderShownDate ? moment().format('LL') === reminderShownDate : false;

    const reminderDiff = moment(paymentDue.dueDate).diff(moment(), 'days', true).toFixed(2);

    if (reminderDiff >= 0 && reminderDiff <= 7 && !hasReminderShown) {
      setIsReminder(true);

      LocalStorageConstants.setItem(`reminderShownDate-${authUserCompany.id}`, moment().format('LL'));

    }

  }, [paymentDue, authUserCompany]);

  const handleClose = () => setIsReminder(false);

  if (!isUserAdmin) return null;

  return (
    <DialogCNO
      open={isReminder}
      onClose={() => setIsReminder(false)}
      dialogTitle="Subscription Reminder"
    >

      {
        (paymentDue && paymentDue.dueDate) ? (
          <Typography style={{ fontSize: '1.1rem' }}>
            You have {Math.round(moment(paymentDue.dueDate).diff(moment(), 'day', true))} days remaining before your subscription expires. Please renew your subscription for using uninterrupted services.
          </Typography>
        ) : (
          null
        )
      }

      <FormActionButtons
        rightText={'Renew Subscription'}
        rightClick={() => {

          if (authUserCompany) {
            history.push(`/mycompany/${authUserCompany.id}#subscriptions`);
            handleClose();
          }

        }}
        leftClick={handleClose}
      />

    </DialogCNO>
  )
}

export default SubscriptionReminderDialog