import React, { Fragment } from 'react';
import InputCNO from './InputCNO';
import InputLabelCNO from './InputLabelCNO';

function PartialInputCNO({
  formik,

  name,
  placeholder,
  label,
  isRequired = false,
  multiline = true,
  disabled = false,

}) {

  const { editable, notEditable } = formik.values[name];

  console.log("PartialInputCNO: formik.values[name]", formik.values[name]);
  console.log("PartialInputCNO: { editable, notEditable }", { editable, notEditable });

  return (
    <Fragment>

      <InputLabelCNO
        label={label}
        isRequired={isRequired}
      />

      {
        notEditable &&
        <div
          style={{
            background: "white",
            borderRadius: 4,
            padding: 12,
            whiteSpace: 'pre',
            color: "#444",
            paddingBottom: "3rem",
            fontSize: '14.22px',
            fontFamily: '"Roboto", "Helvetica", "Arial", "sans- serif"'
          }}
        >
          {notEditable}
        </div>
      }

      <div style={{ marginTop: notEditable ? "-2rem" : 0 }}>
        <InputCNO
          formik={formik}
          value={editable}
          onChange={(e) => {
            const value = { editable: e.target.value, notEditable };
            formik.setFieldValue(name, value)
          }}
          name={name}
          placeholder={placeholder}
          rows={10}
          isRequired={isRequired}
          multiline={multiline}
          disabled={disabled}
          containerStyles={{ width: "100%" }}
        />
      </div>
    </Fragment>
  )
}

export default PartialInputCNO