import React from 'react';
import SelectCNO from '../../../../library/SelectCNO';
import { AutoCompleteSuggestionTypes, DateTimePickerType, FormFieldsTypes } from '../../utils/enums';
import moment from 'moment';

const FieldTypeSelect = ({ formik }) => {

  const getFieldAttributes = (fieldType) => {

    switch (fieldType.id) {
      case FormFieldsTypes.ids.Text:
        return {
          attributes: {
            defaultValue: '',
            multiline: false,
            numberOfLines: 10
          },
          validations: {
            type: "string",
            required: {
              active: false,
              value: false,
            },
            maxLength: {
              active: false,
              value: 255,
            },
          },
        }

      case FormFieldsTypes.ids.Chips:
        return {
          attributes: {
            defaultValue: '',
            options: fieldType.options,
            multiple: false,
          },
          validations: {
            type: "string",
            required: {
              active: false,
              value: false,
            },
            options: {
              value: fieldType.options.map(option => option.id),
            }
          },
        }

      case FormFieldsTypes.ids.Select:
        return {
          attributes: {
            defaultValue: '',
            options: fieldType.options
          },
          validations: {
            type: "string",
            required: {
              active: false,
              value: false,
            },
            options: {
              value: fieldType.options.map(option => option.id),
            }
          },
        }

      case FormFieldsTypes.ids.AutoComplete:
        return {
          name: 'employee',
          label: AutoCompleteSuggestionTypes.getItem(AutoCompleteSuggestionTypes.ids.Employee).label,
          attributes: {
            defaultValue: '',
            suggestionType: AutoCompleteSuggestionTypes.ids.Employee
          },
          validations: {
            type: "object",
            required: {
              active: false,
              value: false,
            }
          },
        }

      case FormFieldsTypes.ids.DateTime:
        return {
          name: {
            date: 'date',
            time: 'time',
          },
          label: {
            date: 'Date',
            time: 'Time',
          },
          attributes: {
            defaultValue: moment().toISOString(),
            dateTimePickerType: DateTimePickerType.ids.DateTimeBoth
          },
          validations: {
            type: "date",
            required: {
              active: false,
              value: false,
            }
          },
        }

      case FormFieldsTypes.ids.Address:
        return {
          name: {
            address: 'address',
            lat: 0.0,
            lng: 0.0,
          },
          label: {
            address: 'Address',
            lat: 0.0,
            lng: 0.0,
          },
          attributes: {
            defaultValue: '',
          },
          validations: {
            type: "address",
            required: {
              active: false,
              value: false,
            }
          },
        }

      case FormFieldsTypes.ids.Switch:
        return {
          attributes: {
            defaultValue: false,
          },
          validations: {
            type: "boolean",
            required: {
              active: false,
              value: false,
            }
          },
        }

      case FormFieldsTypes.ids.CheckBox:
        return {
          attributes: {
            defaultValue: false,
          },
          validations: {
            type: "boolean",
            required: {
              active: false,
              value: false,
            }
          },
        }

      default:
        return {};
    }

  }

  const handleFieldTypeSelect = (value) => {

    const fieldType = FormFieldsTypes.getItem(value, 'value');
    const attributes = getFieldAttributes(fieldType);

    formik.setFieldValue('type', fieldType.id);

    formik.setFieldValue('name', attributes.name || '');
    formik.setFieldValue('label', attributes.label || '');

    formik.setFieldValue('attributes', attributes.attributes);
    formik.setFieldValue('validations', attributes.validations);

    console.log()

    // if (fieldType.options) formik.setFieldValue('attributes.options', fieldType.options);

    // if (fieldType.suggestionType) formik.setFieldValue('suggestionType', fieldType.suggestionType);

    // if (fieldType.dateTimePickerType) formik.setFieldValue('dateTimePickerType', fieldType.dateTimePickerType);

  }

  return (
    <SelectCNO
      name='type'
      formik={formik}
      label='Field Type'
      fullWidth
      options={FormFieldsTypes.entities}
      value={FormFieldsTypes.getItem(formik.values.type).value}
      onChange={handleFieldTypeSelect}
    />
  )
}

export default FieldTypeSelect;