import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useState } from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import HeadingBar from '../HeadingBar/HeadingBar';
import InputCNO from '../../library/InputCNO';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import { apiTaskEdit } from '../../api';
import { toast } from 'react-toastify';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { useDebouncedFormik } from '../../hooks';

function EditJobDescriptionDialog({ job, setJob, isJobDescriptionEditable, setIsJobDescriptionEditable }) {

  const styles = useStyles();

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    localStorage.removeItem(`editJobDescription_${job.id}`);
    setIsJobDescriptionEditable(false);
  };

  const handleUpdate = async (values) => {
    setLoading(true);

    job.description = values.jobDescription;

    setJob({
      ...job,
      description: values.jobDescription
    });

    await apiTaskEdit.post(job)
      .then(response => {
        setLoading(false);
        if (response.status === 200) {
          toast.success("Job description edited successfully!!");

          localStorage.removeItem(`editJobDescription_${job.id}`);

        }
        else {
          toast.error("There is some issues in editing job detail, please try again!");
        }
      })
      .catch(error => {
        console.log("Edit Job title Error: ", error);
        toast.error("There is some issues in editing job detail, please try again!");
      });
    handleClose();
  };

  const validationSchema = yup.object({
    jobDescription: yup.string()
  });

  const formik = useFormik({
    initialValues: {
      jobDescription: job.description
    },
    validationSchema,
    onSubmit: values => handleUpdate(values)
  });

  useDebouncedFormik(formik, `editJobDescription_${job.id}`);

  if (loading) {
    return (
      <FullScreenLoaderCNO />
    );
  }

  return (
    <Dialog
      open={isJobDescriptionEditable}
      className={styles.container}
      classes={{
        paper: styles.paper
      }}
    >
      <HeadingBar title="Edit Job Description" onClose={handleClose} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <InputCNO
          name='jobDescription'
          label='Job Description'
          formik={formik}
          icon={mdiFormatText}
          fullWidth
          multiline
        />
        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
        />
      </form>
    </Dialog>
  );
};

export default EditJobDescriptionDialog

const useStyles = makeStyles(theme => ({
  container: {
    '&>*': {
      marginTop: theme.spacing(2)
    }
  },
  paper: {
    padding: theme.spacing(2),
    // color: theme.palette.text.secondary,
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    borderRadius: 10,
    width: '70vw',
    maxWidth: '90vh'
  },
  formTitle: {
    color: theme.colors.DIALOG_TITLE_COLOR,
    marginLeft: 5
  },
  form: {
    '&>*': {
      marginTop: theme.spacing(2)
    }
  },
}));