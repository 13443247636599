import React, { useState } from 'react'
import { DialogCNO, IconMain, MetaText, TabPanel } from '../../../library'
import { Tab, Tabs } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { groupReactionsByType } from '../helpers';
import { PostReactions } from '../enums';
import PostReactionList from './PostReactionList';

const PostReactionsDetailDialog = ({ open, onClose, }) => {

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, value) => {
    setTabValue(value);
  }

  const { postReactions, postReactionsLoader } = useSelector(state => state.posts);

  const groupedReactions = groupReactionsByType(postReactions);

  const groupedReactionsKeys = Object.keys(groupedReactions);

  if (!open) return null;

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Post Reactions"
      loading={postReactionsLoader}
    >
      <Tabs value={tabValue} onChange={handleTabChange}>
        {
          groupedReactionsKeys.map(reactionId => {

            const reaction = PostReactions.getItem(Number(reactionId));

            return (
              <Tab
                icon={<IconMain name={reaction.icon} themeColorKey={'EMOJI_ICON_COLOR'} />}
                style={{ minWidth: 30 }}
              />
            )

          })
        }

      </Tabs>

      {
        groupedReactionsKeys.map((reactionId, index) => (
          <TabPanel value={tabValue} index={index}>
            <PostReactionList reactions={groupedReactions[reactionId]} />
          </TabPanel>

        ))
      }

    </DialogCNO>
  )
}

export default PostReactionsDetailDialog