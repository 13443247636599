import React from 'react';
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from 'yup';
import { updateService, updateServiceStatus } from "../../actions/serviceActions";
import DateTimePickerCNO from "../../library/DateTimePickerCNO";
import DialogCNO from "../../library/DialogCNO";
import FormActionButtons from "../FormActionButtons";
import { validateStartDateEndDate } from '../../utils/Helpers';
import { ServiceStatus } from '../../utils/mappings';

const EditServiceTimelineDialog = ({
  open,
  onClose,
  service,
  closeMoreActions,
  isReschedule = false,
  isMarkedComplete = false,
  
}) => {

  const dispatch = useDispatch();

  const { editServiceLoader } = useSelector(state => state.services);

  const formik = useFormik({
    initialValues: {
      // startDate: service.startDate,
      // endDate: service.endDate,
      // startTime: service.startTime,
      // endTime: service.endTime,
      completeDate:"",
      completeTime:"",
    },
    validationSchema: yup.object({
      // startDate: yup.date('Invalid Date').required("Start date is required."),
      // endDate: yup.date('Invalid Date')
      //   .required("End date is required.")
      //   .test(
      //     'endDateTimeValidation',
      //     "End Date can't be less than Start Date.",
      //     function () {
      //       const startDate = this.parent.startDate;
      //       const startTime = this.parent.startTime;
      //       const endDate = this.parent.endDate;
      //       const endTime = this.parent.endTime;

      //       const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

      //       return isValid;
      //     }
      //   ),
      
        completeDate: yup.date('Invalid Date')
        .required("complete date is required."),
        // .test(
        //   'completeDateTimeValidation',
        //   "complete Date can't be less than Start Date.",
        //   function () {
        //     const startDate = this.parent.startDate;
        //     const startTime = this.parent.startTime;
        //     const completeDate = this.parent.completeDate;
        //     const completeTime = this.parent.completeTime;

        //     const isValid = validateStartDateEndDate({ startDate, startTime,completeDate,completeTime});

        //     return isValid;
        //   }
        // ),
      // startTime: yup.date('Invalid Time').required("Start time is required."),
      // endTime: yup.date('Invalid Time')
      //   .required("End time is required.")
      //   .test(
      //     'endDateTimeValidation',
      //     "End Date can't be less than Start Date.",
      //     function () {
      //       const startDate = this.parent.startDate;
      //       const startTime = this.parent.startTime;
      //       const endDate = this.parent.endDate;
      //       const endTime = this.parent.endTime;

      //       const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

      //       return isValid;
      //     }
      //   ),
        completeTime: yup.date('Invalid Time')
        .required("complete time is required.")
        // .test(
        //   'completeDateTimeValidation',
        //   "complete Date can't be less than Start Date.",
        //   function () {
        //     const startDate = this.parent.startDate;
        //     const startTime = this.parent.startTime;
        //     const completeDate = this.parent.completeDate;
        //     const completeTime = this.parent.completeTime;

        //     const isValid = validateStartDateEndDate({ startDate, startTime, completeDate, completeTime });

        //     return isValid;
        //   }
        // ), 
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const onAddSuccess = (service) => {

    if (isReschedule && service.status !== ServiceStatus.Ready) {
      const updateStatusPayload = {
        ...service,
        status: ServiceStatus.Ready,
        meta: ""
      };

      dispatch(updateServiceStatus(updateStatusPayload));
    }

    if (isMarkedComplete && service.status !== ServiceStatus.Ready) {
      const updateStatusPayload = {
        ...service,
        status: ServiceStatus.Ready,
        meta: ""
      };

      dispatch(updateServiceStatus(updateStatusPayload));
    }

    if (isMarkedComplete && service.status == ServiceStatus.Ready) {
      const updateStatusPayload = {
        ...service,
        status: ServiceStatus.Sent,
        meta: ""
      };

      dispatch(updateServiceStatus(updateStatusPayload));
    }

  };

  const handleUpdate = (values) => {

    console.log("Service edit values2: ", values);

    const updateServicePayload = {
      ...service,
      // startDate: values.startDate,
      // endDate:isReschedule && values.endDate,
      // startTime:values.startTime,
      // endTime: isReschedule &&values.endTime,
      completeDate: isMarkedComplete && values.completeDate,
      completeTime:isMarkedComplete && values.completeTime,
    };

    dispatch(updateService(updateServicePayload, onAddSuccess));

    onClose();

  };

  const handleClose = () => {

    onClose();

    if (closeMoreActions) {
      closeMoreActions();
    }

  };

  console.log("Service edit errors: ", formik.errors);

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle={isReschedule ? "Edit Service" : "Mark Service Complete"}
      loading={editServiceLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        {/* {
          !isMarkedComplete && 
          <DateTimePickerCNO
            datePickerLabel='Start Date'
            dateName={'startDate'}
            datePlaceholder={"Start Date"}
            disablePast={true}
            onDateChange={date => formik.setFieldValue('startDate', date)}

            timePlaceholder={"Start Time"}
            timeName={"startTime"}
            onTimeChange={date => formik.setFieldValue('startTime', date)}

            formik={formik}
            iconColor={'red'}
            dateDisabled={isMarkedComplete ? true :false}
            timeDisabled={isMarkedComplete ? true : false}
          />
        } */}

        <DateTimePickerCNO
          datePickerLabel={isMarkedComplete ? 'Complete Date':'End Date'}
          dateName={isMarkedComplete ? 'completeDate':'endDate'}
          datePlaceholder={isMarkedComplete ? 'Complete Date':"End Date"}
          minDate={formik.values.startDate}
          onDateChange={date => formik.setFieldValue(isMarkedComplete ? 'completeDate':'endDate', date)}

          timePlaceholder={isMarkedComplete ? 'Complete Time':"End Time"}
          timeName={isMarkedComplete ? 'completeTime':"endTime"}
          onTimeChange={date => formik.setFieldValue(isMarkedComplete ? 'completeTime':'endTime', date)}

          formik={formik}
          iconColor={'green'}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
          closeMoreActions={closeMoreActions}
        />

      </form>
    </DialogCNO>
  )

}

export default EditServiceTimelineDialog;