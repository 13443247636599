import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import Heading from '../../../common/components/Heading';
import Logo from '../../../common/components/UIElements/Logo';
import Container from '../../../common/components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';
import LogoImage from '../../../assets/appLogo.png';

import data from '../../../common/data/LandingPage/Footer';
import { useTheme } from '@material-ui/core';
import { ThemeType } from '../../../utils/mappings';
import darkColors from '../../../common/theme/LandingPage/darkThemeColors';
import colors from '../../../common/theme/LandingPage/colors';
import moment from 'moment';
import ComingSoonModal from '../../ComingSoonModal';
import Icon from '@mdi/react';
import LinkCNO from '../../../library/LinkCNO';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
  copyrightMenu,
  copyright,
}) => {

  const isDarkTheme = useTheme().palette.type === ThemeType.Dark;

  const [isComingSoon, setIsComingSoon] = useState(false);

  return (
    <FooterWrapper>
      <Container>
        <Box className="row" {...row}>
          {/* End of footer logo column */}
          <Box {...colOne}>
            {data.menuWidget.map((widget) => {

              if (widget.id === 3) {
                return (
                  <Box className="col" {...col} key={widget.id}>
                    <Heading content={widget.title} {...titleStyle} color={isDarkTheme ? darkColors.textColor : colors.textColor} />
                    <List style={{ display: 'flex' }}>
                      {widget.menuItems.map((item) => (
                        <ListItem key={`list__item-${item.id}`}>
                          <LinkCNO to={item.url} type="absolute" target="_blank" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                            <div style={{
                              background: item.iconColor,
                              marginRight: 8,
                              borderRadius: '50%',
                              width: '1.5rem',
                              height: '1.5rem',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                              <Icon path={item.icon} color={'#fff'} size='1rem' />
                            </div>
                          </LinkCNO>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )
              }

              return (
                <Box className="col" {...col} key={widget.id}>
                  <Heading content={widget.title} {...titleStyle} color={isDarkTheme ? darkColors.textColor : colors.textColor} />
                  <List>
                    {widget.menuItems.map((item) => (
                      <ListItem key={`list__item-${item.id}`}>
                        <a className="ListItem" href={item.url} style={{ cursor: 'pointer' }}>{item.text}</a>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )
            })}
          </Box>
          <Box {...colTwo} className="copyrightClass">
            <Logo
              href="/"
              logoSrc={LogoImage}
              title="App"
              logoStyle={logoStyle}
            />

            <Box>
              <Box {...copyrightMenu} className="copyrightMenu">

                <Text content={<a className="ListItem" href={'/help'} style={{ cursor: 'pointer', color: isDarkTheme ? darkColors.textColor : colors.textColor }}>Help</a>} {...textStyle} />

                <Text content={<a className="ListItem" href='/disclaimer' style={{ cursor: 'pointer', color: isDarkTheme ? darkColors.textColor : colors.textColor }}>Disclaimer</a>} {...textStyle} />

              </Box>

              <Box {...copyright} className="copyrightText" style={{ marginLeft: 40 }}>
                <Text content="Copyright 2022 meFolia" {...textStyle} color={isDarkTheme ? darkColors.textColor : colors.textColor} />
              </Box>
            </Box>

            {/* <Box {...copyright} className="copyrightText">
              <a href="https://emeistechnologies.com" target="_blank" rel="noreferrer">
                <Text content="Powered by Emeis Technologies" {...textStyle} color={isDarkTheme ? darkColors.textColor : colors.textColor} />
              </a>
          </Box>*/}
          </Box>
          {/* End of footer List column */}
        </Box>

        {
          isComingSoon &&
          <ComingSoonModal
            open={isComingSoon}
            onClose={() => setIsComingSoon(false)}
          />
        }
      </Container>
    </FooterWrapper >
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colTwo: {
    mt: [0, '13px'],
    mb: ['0px', 0],
    pl: ['15px', 0],
    pt: ['35px', '55px'],
    pr: ['15px', '15px', 0],
    borderTop: '1px solid',
    borderColor: 'rgba(0,0,0,0.102)',
    flexBox: true,
    flexWrap: 'wrap',
    width: ['100%'],
  },
  // Footer col two style
  colOne: {
    width: ['100%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%', '25%'],
    pl: ['15px', '0px'],
    pr: ['15px', '0px'],
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
  },
  // Default logo size
  logoStyle: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100px',
    mb: ['15px', 0],
  },
  // widget text default style
  textStyle: {
    color: '#20201d',
    fontSize: '14px',
    mb: '10px',
    mr: '30px',
  },
  copyrightMenu: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: [0, '40px'],
    mt: '3px',
    fontWeight: '500',
    justifyContent: 'flex-start',
    alignItems: 'center',
    mb: ['15px', 0],
  },
  copyright: {
    ml: [0, 0, 0, 'auto', 'auto'],
    color: '#20201d',
    fontSize: '14px',
    mb: '10px',
    mt: '3px',
    fontWeight: '500',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default Footer;
