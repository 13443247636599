export const SET_EVENT_DISCUSSIONS = "SET_EVENT_DISCUSSIONS";
export const SET_HAS_MORE_EVENT_DISCUSSIONS = "SET_HAS_MORE_EVENT_DISCUSSIONS";
export const SET_EVENT_DISCUSSIONS_LOADERS = "SET_EVENT_DISCUSSIONS_LOADERS";

import postDummyData from './DummyData/posts.json';
import moment from 'moment';
import { v4 } from "uuid";
import axios from "axios";
import { getEventDiscussionsByType } from '../pages/EventManagement/helpers';
import LocalStorageConstants from '../utils/LocalStorageConstants';
import { PostType } from '../pages/PostsManagement/enums';
import { Privacy } from '../utils/mappings';
import { toast } from 'react-toastify';

// const postDummyData = postData.data;
const eventDiscussionsDummy = getEventDiscussionsByType(postDummyData);

export const setEventDiscussions = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_EVENT_DISCUSSIONS,
      payload: payload
    });
  };
};

export const setHasMoreEventDiscussions = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_EVENT_DISCUSSIONS,
      payload: payload
    });
  };
};

export const setEventDiscussionLoaders = (loaderName, loaderState) => {
  return (dispatch) => {
    dispatch({
      type: SET_EVENT_DISCUSSIONS_LOADERS,
      payload: { loaderName, loaderState }
    });
  };
};


export const fetchEventsDiscussion = (payload) => {
  return async (dispatch) => {

    await axios.get('https://jsonplaceholder.typicode.com/users')
      .then((response) => {

        if (response.status === 200) {

          let eventDiscussions = eventDiscussionsDummy;
          // const eventDiscussions = response.data.data;

          eventDiscussions = eventDiscussions.filter(ed => ed.eventId === payload.eventId);

          if (eventDiscussions.length < 10) {
            dispatch(setHasMoreEventDiscussions(false));
          }

          dispatch(setEventDiscussions(eventDiscussions));

        } else {
          console.error("Error: Fetch EventDiscussions : Response", response);
        }

      })
      .catch((error) => {
        console.error("Error: Fetch EventDiscussions: ", error);

      })
      .finally(() => dispatch(setEventDiscussionLoaders('eventDiscussionsLoader', false)));

  }
}

export const addEventDiscussion = (payload, { onSuccess = null }) => {
  return async (dispatch, getState) => {

    dispatch(setEventDiscussionLoaders('EventDiscussionLoader', true));

    payload = {
      ...payload,
    };

    const authUser = LocalStorageConstants.getItem('authUser');

    const dataToSend = {
      postType: PostType.ids.EventDiscussion,
      description: "",
      mediaLinks: [],
      categories: [],
      createdAt: moment().toISOString(),
      createdBy: {
        id: authUser.id,
        title: authUser.firstName + " " + authUser.lastName,
        profilePic: authUser.profilePic
      },
      mapAddress: "",
      lat: "",
      lng: "",
      status: 1,
      visibility: Privacy.ids.Private,
      reactionCounts: 0,
      commentsCounts: 0,
      myReactionId: 0,
      ...payload
    };

    console.log(" addEventDiscussion: dataToSend: ", dataToSend);

    // await axios.post("v1/post/create", dataToSend)
    await axios.get('https://jsonplaceholder.typicode.com/users')
      .then((response) => {

        if (response.status === 200) {

          // const postId = response.data;

          // console.log(" addEventDiscussion: postId: ", postId);

          const newEventDiscussion = {
            id: v4(),
            ...dataToSend,
            // id: postId
          };

          console.log(" addEventDiscussion: newEventDiscussion: ", newEventDiscussion);

          const { eventDiscussions } = getState().eventDiscussions;

          const updatedEventDiscussions = [newEventDiscussion, ...eventDiscussions];

          dispatch(setEventDiscussions(updatedEventDiscussions));

          toast.success("EventDiscussion added successfully!!");

          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess(newEventDiscussion);
          }

          console.log(" addEventDiscussion: end: ");

        } else {

          console.error("Error: Add EventDiscussions : Response", response);

          toast.error("Error in adding discussion.");
        }

      })
      .catch((error) => {

        console.error("Error: Add EventDiscussions: ", error);
        toast.error("Error in adding discussion.");

      })
      .finally(() => dispatch(setEventDiscussionLoaders('addEventDiscussionLoader', false)));

  };
};