import {
    mdiArrowRight,
    mdiCheck,
    mdiClose,
    mdiFileDocument,
    mdiSend,
    mdiSquareEditOutline
  } from '@mdi/js';
  import Icon from '@mdi/react';
  import React from 'react';
  import CardStatusCNO from '../../library/CardStatusCNO/CardStatusCNO';
  import { ServiceStatus } from '../../utils/mappings';
  import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
  
  function ServiceStatusButtonBar({ service }) {
  
    const GlobalStyles = useGlobalStyles();

    if(!service || !service.status) return <div></div>;

    console.log("statsy",service)
    console.log("service.status",service.status);
    console.log("ServiceStatus.Draft",ServiceStatus.Draft);


    return (
      <div className={GlobalStyles.cardStatusBarContainer}>
        
        <CardStatusCNO
          title="Started"
          icon={mdiSquareEditOutline}
          isActive={service?.status === ServiceStatus.Draft}
          activeBorderColor={'#ffb366'}
        />
  
        <Icon path={mdiArrowRight} size='1.2rem' />
  
        <CardStatusCNO
          title="In Progress"
          icon={mdiFileDocument}
          isActive={service?.status === ServiceStatus.Ready}
          activeBorderColor={'#ff8000'}
        />
  
        <Icon path={mdiArrowRight} size='1.2rem' />
  
        <CardStatusCNO
          title="Completed"
          icon={mdiSend}
          isActive={(service?.status === ServiceStatus.Sent) || (service?.status === ServiceStatus.SentAccepted) || (service.status === ServiceStatus.SentRejected)}
          activeBorderColor={'#00cc00'}
          hasTopRightIcon={true}
          topRightIcon={
            service.status === ServiceStatus.SentRejected ? mdiClose : mdiCheck
          }
          isTopRightActive={(service.status === ServiceStatus.SentAccepted) || (service.status === ServiceStatus.SentRejected)}
          topRightActiveBgColor={service.status === ServiceStatus.SentAccepted ? "#2bb673"
            : service.status === ServiceStatus.SentRejected && "#ef5647"}
          topRightActiveBorderColor={service.status === ServiceStatus.SentAccepted ? "#2bb673"
            : service.status === ServiceStatus.SentRejected && "#ef5647"}
          topRightActiveColor={"#cecece"}
        />
  
      </div>
    )
  }
  
  export default ServiceStatusButtonBar
  