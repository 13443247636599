import { Typography, useTheme } from '@material-ui/core';
import { mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';
import React, { } from 'react';
import { useDispatch } from 'react-redux';
import { getMaterialIconString } from '../../utils/icons';
import { Currency, CurrencyIcon } from '../../utils/mappings';
import { useStyles } from './PricingCardStyles';
import * as icons from '@mdi/js';
import RightsManager from '../../utils/RightsManager';

function PricingCard({
  profile,
  type,
  isCardActive,
  isActive,
  pricing,
  isYearly,
  onSelectCard,
}) {

  const isUserAdmin = RightsManager.isAdmin();

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const theme = useTheme();
  const styles = useStyles({ isCardActive });

  const dispatch = useDispatch();

  return (
    <div
      className={styles.container_PricingCard}
      onClick={() => {
        if (isActive) {
          onSelectCard({
            subscriptionType: type,
            paymentGatewayMeta: "Subscribe"
          })
        }

      }}
    >

      <Typography className={styles.pricingType} variant="h5">
        {pricing.title || pricing.package_name}
      </Typography>

      <div className={styles.pricingDetailsWrapper} style={{ marginLeft: -3 }}>
        <Typography style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            size="1.5rem"
          />
          {pricing.price}/{isYearly ? 'year' : 'month'}
        </Typography>
        <Typography style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem', textDecoration: 'line-through' }}>
          <Icon
            path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            size="1.1rem"
          />
          {pricing.strikedPrice}/{isYearly ? 'year' : 'month'}
        </Typography>

        <Typography style={{ marginLeft: 4, marginTop: 10 }}>For {pricing.noOfUsers} users</Typography>
        <Typography style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}>
          <Icon
            path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            size="1rem"
          />
          {pricing.perUserPrice} per user
        </Typography>

      </div>

      <div className={styles.featuresWrapper}>
        {
          pricing.features
            .filter(feature => feature.isAvailable)
            .map((feature) => (
              <div className={styles.featureWrapper} key={feature.id}>
                <Icon
                  path={mdiCheck}
                  size="1rem"
                  color={isCardActive
                    ? theme.colors.PRICING_CARD_ACTIVE_FEATURE_COLOR
                    : theme.colors.PRICING_CARD_FEATURE_COLOR
                  }
                />
                <Typography>{feature.name}</Typography>
              </div>
            ))
        }
      </div>

    </div>
  )
}

export default PricingCard
