import { SEARCH_CUSTOMER, SET_CUSTOMERS, SET_CUSTOMERS_LOADER, SET_CUSTOMER_BY_ID, SET_CUSTOMER_SEARCH_PARAMS, SET_HAS_MORE_CUSTOMERS, SET_SEARCHED_CUSTOMERS, TOGGLE_ADD_CUSTOMER_DIALOG } from "../actions/customerActions";

const inititalState = {
  customer: null,
  customers: [],
  searchedCustomers: [],
  customerById: null,
  isAddCustomerDialog: false,
  allCustomersLoader: {
    customersLoader: false,
    moreCustomersLoader: false,
    editLoader: false,
    addLoader: false,
    customerDetailLoader: false
  },
  hasMoreCustomers: true,
  customerSearchParams: {
    customer: null,
    email: "",
    phone: "",
    address: ""
  },
};

export const customerReducer = (state = inititalState, { type, payload }) => {

  switch (type) {
    case SEARCH_CUSTOMER:
      state = {
        ...state,
        customer: payload.data.data
      };
      return state;
    case SET_CUSTOMERS:
      state = {
        ...state,
        customers: payload
      };
      return state;

    case SET_SEARCHED_CUSTOMERS:
      state = {
        ...state,
        searchedCustomers: payload
      };
      return state;

    case SET_CUSTOMERS_LOADER:
      state = {
        ...state,
        allCustomersLoader: {
          ...state.allCustomersLoader,
          [payload.loaderType]: payload.loaderState
        }
      };
      return state;

    case SET_HAS_MORE_CUSTOMERS:
      state = {
        ...state,
        hasMoreCustomers: payload
      };
      return state;

    case SET_CUSTOMER_BY_ID:
      state = {
        ...state,
        customerById: payload
      };
      return state;

    case TOGGLE_ADD_CUSTOMER_DIALOG:
      state = {
        ...state,
        isAddCustomerDialog: payload
      };
      return state;

    case SET_CUSTOMER_SEARCH_PARAMS:
      state = {
        ...state,
        customerSearchParams: payload
      };
      return state;

    default:
      return state;
  }
};