import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ScreenView from '../../components/ScreenView';
import UserProfile from '../../components/UserProfile/UserProfile';
import { getServiceProviderById, setServiceProviderById } from '../../actions/serviceProviderActions';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { ProfileMode } from '../../utils';


function ServiceProviderDetailsPage() {

  const styles = useStyles();
  

  const dispatch = useDispatch();
  const { serviceProviderById, allServiceProvidersLoader, serviceProviders } = useSelector(state => state.serviceProviders);
  const { serviceProviderDetailLoader, editLoader } = allServiceProvidersLoader;

  const { id } = useParams();
  console.log("serviceProviders",serviceProviders);
  useEffect(() => {

    const serviceProviderId = [id];

    const serviceProvider = serviceProviders.find(serviceProvider => serviceProvider.id === id);
    console.log("serviceProvider",serviceProvider)

    dispatch(getServiceProviderById(serviceProviderId, false, serviceProvider));

  }, [id]);

  console.log("ServiceProviderDetailLoader: ", serviceProviderDetailLoader);
  console.log("serviceProviderById",serviceProviderById)
 

  return (
    <div className={styles.container_ServiceProviderDetailsPage}>
      {
        (
          serviceProviderDetailLoader ||
          editLoader
        ) &&
        <FullScreenLoaderCNO />
      }
      <ScreenView
        content={
          serviceProviderById &&
          <UserProfile
            mode={ProfileMode.ServiceProvider}
            profile={serviceProviderById}
            isActiveMember={serviceProviderById.isActive}
          />
        }
        headerTitle="ServiceProvider Detail"
        
      />
    </div>
  )
}

export default ServiceProviderDetailsPage;

const useStyles = makeStyles((theme) => ({
  container_ServiceProviderDetailsPage: {
    background: theme.colors.APP_BACKGROUND_COLOR,
  }
}))