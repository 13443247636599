import React, { Fragment, useMemo } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Box } from '@material-ui/core';
import { pieChartConfigs } from './pieChartConfigs';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

function PieChart({
  chart,
  bgColor = "#1A73E8"
}) {

  const { data, options } = pieChartConfigs(chart.labels || [], chart.datasets || {});

  console.log("PieChart: ", { data, options });

  return (
    <Fragment>
      <Box>
        {useMemo(
          () => (
            <Box
              style={{
                backgroundColor: bgColor,
                padding: "2px 0.5px",
                // marginTop: 30,
                height: "12.5rem",
                borderRadius: 5
              }}
            >
              <Pie data={data} options={options} />
            </Box>
          ),
          [chart, bgColor]
        )}
      </Box>
    </Fragment>
  )
}

export default PieChart;