import { fade, makeStyles, Typography } from '@material-ui/core';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { getMaterialIconString } from '../../utils/icons';
import { CurrencyHTMLEntities, CurrencyIcon, InvoiceStatus } from '../../utils/mappings';
import * as icons from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { updateInvoice } from '../../actions/invoiceActions';
import RightsManager from '../../utils/RightsManager';
import { getTotalAmountValues } from '../../utils/InvoiceHelpers';
import { parseDisplayNumber } from '../../utils/Helpers';
import EditInvoiceDiscountDialog from './EditInvoiceDiscountDialog';
import EditInvoiceTaxDialog from './EditInvoiceTaxDialog';

function InvoicePaymentSummaryDetailsCard({ invoice }) {

  const styles = useStyles();

  const isCompanyAdmin = RightsManager.isGroupLeader();

  const dispatch = useDispatch();
  const { invoiceItems } = useSelector(state => state.invoiceItems);

  const invoiceItemsAmount = useSelector(state => state.invoiceItems.invoiceItems
    .reduce((result, invoiceItem) => {
      result = result + parseFloat(invoiceItem.amount);
      return result;
    }, 0));

  const [isDiscountEditable, setIsDiscountEditable] = useState(false);
  const [isTaxEditable, setIsTaxEditable] = useState(false);

  console.log("====>>>>invoiceItemsAmountinvoiceItemsAmount", invoiceItemsAmount);

  useEffect(() => {

    // prevent amount to be zeros if invoice items not fetched
    const invoiceAmount = invoiceItems.length ? invoiceItemsAmount : invoice.amount;

    const { discountRate, amountAfterDiscount, tax, taxableAmount, totalAmount } = getTotalAmountValues(invoiceAmount, invoice.discount, invoice.taxRate, invoice.includeTax);

    const payload = {
      ...invoice,
      amount: invoiceAmount,
      discountRate,
      amountAfterDiscount,
      taxableAmount,
      tax,
      totalAmount
    };

    // update invoice only when invoice amount comes different
    if (invoiceAmount !== invoice.amount) {
      dispatch(updateInvoice(payload));
    }

  }, [invoiceItemsAmount, invoice]);

  return (
    <div>

      <div className={styles.amountBreakdownWrapper}>

        <div className={styles.row}>
          <Typography className={styles.label}>Amount</Typography>
          <div className={styles.valueWrapper}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[invoice.currency])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography className={styles.value}>{parseDisplayNumber(invoice.amount)}</Typography>
          </div>
        </div>

        <div className={styles.row} onClick={() => setIsDiscountEditable(true)}>
          <Typography className={styles.label}>
            {`Discount @ ${invoice.discountRate}%`}
          </Typography>
          <div className={styles.valueWrapper}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[invoice.currency])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography className={styles.value}>{parseDisplayNumber(invoice.discount)}</Typography>
          </div>

        </div>

        <div className={styles.row}>
          <Typography className={styles.label}>
            {`Amount after Discount`}
          </Typography>
          <div className={styles.valueWrapper}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[invoice.currency])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography className={styles.value}>{parseDisplayNumber(invoice.amountAfterDiscount)}</Typography>
          </div>

        </div>

        <div className={styles.row} onClick={() => setIsTaxEditable(true)}>

          <div
            className={styles.label}
            dangerouslySetInnerHTML={{
              __html: `<div>
                ${invoice.includeTax ? 'Inclusive' : 'Exclusive'} VAT @ ${invoice.taxRate}% (<span>${CurrencyHTMLEntities[invoice.currency]}</span> ${parseDisplayNumber(invoice.taxableAmount, 'float')})
              </div>`
            }}
          />

          <div className={styles.valueWrapper}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[invoice.currency])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography className={styles.value}>{parseDisplayNumber(invoice.tax)}</Typography>
          </div>

        </div>

      </div>

      <div className={styles.totalAmountWraper}>
        <Typography className={styles.label} style={{ fontSize: '1rem' }}>Total Amount Due</Typography>
        <div className={styles.amountWrapper}>
          <div className={styles.amount}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[invoice.currency])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography
              className={styles.value}
              style={{
                fontSize: '1rem',
                fontWeight: 'bold',
              }}>
              {parseDisplayNumber(invoice.totalAmount)}
            </Typography>
          </div>
        </div>
      </div>


      {
        isDiscountEditable &&
        isCompanyAdmin &&
        <EditInvoiceDiscountDialog
          open={isDiscountEditable}
          onClose={() => setIsDiscountEditable(false)}
          invoice={invoice}
        />
      }

      {
        isTaxEditable &&
        isCompanyAdmin &&
        <EditInvoiceTaxDialog
          open={isTaxEditable}
          onClose={() => setIsTaxEditable(false)}
          invoice={invoice}
        />
      }

    </div>
  )
}

export default InvoicePaymentSummaryDetailsCard

const useStyles = makeStyles((theme) => ({
  container_AddInvoicePaymentSummary: {

  },
  amountBreakdownWrapper: {
    padding: 8
  },

  row: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 8,
  },

  label: {
    marginRight: 16
  },

  valueWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 120,
  },

  value: {
    minWidth: 60,
    textAlign: 'right'
  },

  totalAmountWraper: {
    display: 'flex',
    alignItems: 'center',
    background: theme.colors.WORK_HOURS_TOTAL_ROW_BACKGROUND_COLOR,
    padding: 8,
    justifyContent: 'flex-end',
    borderRadius: 4
  },
  amountWrapper: {
    background: theme.colors.INPUT_DISABLED_BACKGROUND_COLOR,
    width: 120,
    borderRadius: 4,
    padding: '4px 8px',
    display: 'flex',
    justifyContent: 'flex-end',
    color: fade(theme.colors.INPUT_TEXT_COLOR, 0.8),
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }

}));