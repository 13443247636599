import { createContext, useContext, useReducer } from "react";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";
import { TOGGLE_THEME } from "../utils/actions";
import { ThemeType } from "../utils/mappings";
import { ThemeColorConstants } from "./ThemeColorConstants";
import { getCustomThemeColors } from "./CustomThemeColorConstants";

const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
const systemTheme = isDarkTheme ? ThemeType.Dark : ThemeType.Light;

let theme = localStorage.getItem("theme");
theme = theme ? theme : systemTheme;

export const store = createContext({ theme });
const { Provider } = store;

const reducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      let theme = action.payload;
      localStorage.setItem("theme", theme);
      return { ...state, theme };

    default:
      return state;
  }
};

const MyThemeProvider = ({ children }) => {
  const [themeState, dispatchTheme] = useReducer(reducer, { theme });

  let darkTheme = createMuiTheme({
    colors: {
      ...ThemeColorConstants.DarkThemeColors,
    },
    palette: {
      type: ThemeType.Dark,
      primary: {
        main: "#9BBE00",
      },
      secondary: {
        main: "#00CED1",
      },
      ternary: {
        main: "#B6B303",
      },
      background: {
        light: "#2e2e2e",
        main: "#1e1e1e",
        dark: "#0e0e0e",
      },
    },
    typography: {
      htmlFontSize: 18,
    },
  });
  darkTheme = responsiveFontSizes(darkTheme);

  let lightTheme = createMuiTheme({
    colors: {
      ...ThemeColorConstants.LightThemeColors,
    },
    palette: {
      type: ThemeType.Light,
      primary: {
        main: "#9BBE00",
      },
      secondary: {
        main: "#00CED1",
      },
      ternary: {
        main: "#B6B303",
      },
      background: {
        light: "#ffffff",
        main: "#fefefe",
        dark: "#9e9e9e",
      },
    },
  });
  lightTheme = responsiveFontSizes(lightTheme);

  let customTheme = createMuiTheme({
    colors: {
      ...getCustomThemeColors(),
    },
    palette: {
      type: ThemeType.Light,
      primary: {
        main: "#CFA23A",
      },
      secondary: {
        main: "#D7B45B",
      },
      ternary: {
        main: "#F3E8CE",
      },
      background: {
        light: "#ffffff",
        main: "#fefefe",
        dark: "#9e9e9e",
      },
    },
  });
  customTheme = responsiveFontSizes(customTheme);

  const ThemeMappingObject = {
    [ThemeType.Dark]: darkTheme,
    [ThemeType.Light]: lightTheme,
    [ThemeType.Custom]: customTheme,
  };

  return (
    <Provider value={{ themeState, dispatchTheme }}>
      <ThemeProvider theme={ThemeMappingObject[themeState.theme]}>
        {children}
      </ThemeProvider>
    </Provider>
  );
};

export const useThemeStore = () => useContext(store);

export default MyThemeProvider;
