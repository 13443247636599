import React from 'react'
import { DialogCNO } from '../../../library'
import { AddFieldForm } from '../forms'

const AddFieldDialog = ({ open, onClose, fieldInitialValues, onAddField }) => {

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle='Add Field'
      minHeight={200}
    >
      <AddFieldForm fieldInitialValues={fieldInitialValues} onAddField={onAddField} onClose={onClose} />
    </DialogCNO>
  )
}

export default AddFieldDialog