import { Button, makeStyles, useTheme } from '@material-ui/core';
import { mdiFormatText, mdiPencil } from '@mdi/js';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppointmentStatus } from '../../actions/appointmentActions';
import { AppointmentStatus, EmailPreTextType, EmailPreTextTypeReverse } from '../../utils/mappings';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import { fetchCustomerSuggest } from '../../actions/suggestionsActions';
import InputLabelCNO from '../../library/InputLabelCNO';
import { getAppointmentSendSummaryHtmlString, getInvoiceSendSummaryHtmlString, getQuotationSendSummaryHtmlString } from '../../pdfTemplates/templates';
import RightsManager from '../../utils/RightsManager';
import { defaultMessageTop } from '../../pdfTemplates/templates/Appointment/AppointmentSendSummaryTemplateString';
import AppointmentEmailMessageEdit from './AppointmentEmailMessageEdit';
import Icon from '@mdi/react';
import { setCustomerById } from '../../actions/customerActions';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import SelectCNO from '../../library/SelectCNO';

function AppointmentSendDialog({ open, onClose, appointment, closeMoreActions }) {

  const isUserAdmin = RightsManager.isAdmin();

  const theme = useTheme();

  const styles = useStyles();

  const dispatch = useDispatch();

  const suggestions = useSelector(state => state.suggestions);
  const { editAppointmentLoader } = useSelector(state => state.appointments);
  const { customerById } = useSelector(state => state.customers);

  const [isEmailMessageEdit, setIsEmailMessageEdit] = useState(false);

  const authUserCompany = LocalStorageConstants.getItem("authUserCompany");

  const validationSchema = yup.object({
    to: yup.object().required('Sender is required').nullable(),
    subject: yup.string().required('Subject is required'),
    message: yup.string(),
    attachment: yup.object()
  });

  const formik = useFormik({
    initialValues: {
      to: {},
      subject: appointment.title,
      emailData: "",
      message: "",
      attachment: {},
      emailPreTextType: EmailPreTextType.Appointment
    },
    validationSchema,
    onSubmit: (values) => handleSend(values)
  });

  useEffect(() => {

    const emailData = authUserCompany.appointmentEmailTemplate;

    const messageSummary = getAppointmentSendSummaryHtmlString(appointment, emailData);

    formik.setValues({
      ...formik.values,
      to: {
        id: appointment.customerId,
        title: appointment.customerText,
        email: customerById ? customerById.email : null
      },
      subject: appointment.title,
      emailData: emailData,
      message: messageSummary,
      attachment: {
        title: `${appointment.title}.pdf`,
      }
    });

  }, [appointment, customerById]);

  useEffect(() => {

    let emailData = authUserCompany.appointmentEmailTemplate;
    let messageSummary = getAppointmentSendSummaryHtmlString(appointment, emailData);

    switch (formik.values.emailPreTextType) {
      case EmailPreTextType.Quotation:
        emailData = authUserCompany.offerEmailTemplate;
        messageSummary = getQuotationSendSummaryHtmlString(appointment, [], emailData);
        break;

      case EmailPreTextType.Invoice:
        emailData = authUserCompany.invoiceEmailTemplate;
        messageSummary = getInvoiceSendSummaryHtmlString(appointment, [], emailData);
        break;

      case EmailPreTextType.Appointment:
        emailData = authUserCompany.appointmentEmailTemplate;
        messageSummary = getAppointmentSendSummaryHtmlString(appointment, emailData);
        break;

      default:
        break;
    }

    formik.setFieldValue('message', messageSummary);
    // formik.setFieldValue('emailData', emailData);

  }, [formik.values.emailPreTextType]);

  console.log("=====send====emailValue=====", formik.values);

  const handleSend = (values) => {

    const updatePayload = {
      ...appointment,
      status: AppointmentStatus.Sent,
      meta: values.message,
    };

    dispatch(updateAppointmentStatus(updatePayload));

    handleClose();

  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
    dispatch(setCustomerById(null));

    if (closeMoreActions) {
      closeMoreActions();
    }
  };

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Send Appointment"
      loading={editAppointmentLoader}
    >
      <form onSubmit={formik.handleSubmit} className={styles.form}>

        <AutocompleteCNO
          name='to'
          label='Contact Person'
          formik={formik}
          placeholder='Search Customer'
          // fullWidth
          data={suggestions}
          optionLabel={x => x.title || ""}
          renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
          onSuggestionClick={(value) => {
            formik.setFieldValue('customer', value);
          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchCustomerSuggest(value));
            }
          }}
          disabled={true}
        />

        <InputCNO
          name='subject'
          label='Subject'
          formik={formik}
          placeholder={"Enter subject"}
          icon={mdiFormatText}
          fullWidth
        />

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <InputLabelCNO label={'Message'} />

          <Fragment>
            {
              isUserAdmin &&
              <SelectCNO
                formik={formik}
                name="emailPreTextType"
                options={Object.values(EmailPreTextType).map(item => ({ value: item, label: EmailPreTextTypeReverse[item] }))}
              />
            }

            {
              isUserAdmin &&
              <Button
                style={{
                  background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
                  color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
                  borderColor: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
                  fontSize: '0.7rem',
                  fontWeight: 700,
                  marginLeft: 8
                }}
                startIcon={<Icon path={mdiPencil} size="1rem" />}
                onClick={() => setIsEmailMessageEdit(true)}
              >
                Edit Message
              </Button>
            }

          </Fragment>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: formik.values.message }}
          style={{ background: 'white', padding: "20px 10px", borderRadius: 5 }}
        />

        <FormActionButtons
          rightText="Send"
          formik={formik}
          leftClick={handleClose}
          closeMoreActions={closeMoreActions}
        />

      </form>

      {
        isEmailMessageEdit &&
        <AppointmentEmailMessageEdit
          open={isEmailMessageEdit}
          onClose={() => setIsEmailMessageEdit(false)}
          emailData={formik.values.emailData}
          onSave={({ message }) => {
            const messageSummary = getAppointmentSendSummaryHtmlString(appointment, message);
            formik.setFieldValue('emailData', message);
            formik.setFieldValue('message', messageSummary);
          }}
        />
      }

    </DialogCNO>
  )
}

export default AppointmentSendDialog


const useStyles = makeStyles((theme) => ({

  form: {
    '&>*': {
      marginBottom: theme.spacing(2)
    }
  }

}));