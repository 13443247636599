import { useFormik } from 'formik';
import React from 'react';
import { DialogCNO, InputCNO } from '../../../library';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../../../components/FormActionButtons';
import { editEvent } from '../../../actions/eventActions';

const EditEventDescription = ({ open, onClose, event }) => {

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    description: yup.string()
  });

  const handleEditEventDescription = (values) => {

    const payload = {
      ...event,
      ...values,
    }

    dispatch(editEvent(payload, { onSuccess: onClose }));

  }

  const formik = useFormik({
    initialValues: {
      description: event.description
    },
    validationSchema,
    onSubmit: handleEditEventDescription
  });

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogDescription="Edit Event Description"
    >
      <form onSubmit={formik.handleSubmit}>
        <InputCNO
          name="description"
          label="Event Description"
          formik={formik}
          placeholder="Enter Event Description"
          multiline
          fullWidth
        />

        <FormActionButtons formik={formik} rightText="Save" leftClick={onClose} />

      </form>
    </DialogCNO>
  )
}

export default EditEventDescription;