import { InputAdornment, Menu, MenuItem, TextField, Typography, useTheme } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';
import React, { useState } from 'react';
import InputCNO from '../../library/InputCNO';
import InputLabelCNO from '../../library/InputLabelCNO';
import { Currency, InvoiceDueDateAfter, InvoiceDueDateAfterReverse } from '../../utils/mappings';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';

function AddInvoiceMoreDetails({ formik }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const [dateMenuRef, setDateMenuRef] = useState(null);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const openDatePicker = () => setIsDatePickerOpen(true);
  const closeDatePicker = () => setIsDatePickerOpen(false);

  const openDateMenu = (e) => {
    setDateMenuRef(e.currentTarget);
  };

  const closeDateMenu = () => {
    setDateMenuRef(null);
  };
  const open = Boolean(dateMenuRef);

  const handleDueDate = (dueDateType) => {

    let dueDate = new Date();

    switch (dueDateType) {
      case 'OnReceipt':
        dueDate = moment().add(1, 'days').format('MMM DD, YYYY');
        formik.setFieldValue('dueDate', dueDate);
        break;

      case 'In15days':
        dueDate = moment().add(15, 'days').format('MMM DD, YYYY');
        formik.setFieldValue('dueDate', dueDate);
        break;

      case 'DueEndOfMonth':
        dueDate = moment().endOf('month').format('MMM DD, YYYY');
        formik.setFieldValue('dueDate', dueDate);
        break;

      case 'ChooseADate':
        openDatePicker();
        break;

      default:
        break;
    }

    closeDateMenu();

  };

  return (
    <div>

      <div style={{ marginTop: 8 }}>
        <Typography className={GlobalStyles.label}>Currency</Typography>
        <TextField
          select
          value={formik.values.currency}
          className={GlobalStyles.textFieldSelect}
          variant='outlined'
          margin='dense'
          {...formik.getFieldProps('currency')}
          error={formik.touched['currency'] && Boolean(formik.errors['currency'])}
          helperText={formik.errors['currency']}
          fullWidth
        >
          {
            Object.keys(Currency).map((currency, index) => (
              <MenuItem value={index + 1}>{currency}</MenuItem>
            ))
          }
        </TextField>
      </div>

      <div style={{ marginTop: 8 }}>
        <InputLabelCNO label={'Due Date'} isRequired={true} />
        <TextField
          className={GlobalStyles.textField}
          variant='outlined'
          margin='dense'
          fullWidth
          value={formik.values.dueDate}
          onClick={openDateMenu}
          placeholder="Select Due Date"
          error={formik.touched['dueDate'] && Boolean(formik.errors['dueDate'])}
          helperText={formik.errors['dueDate']}
          InputProps={{
            endAdornment: <InputAdornment>
              <Icon color={theme.colors.INPUT_ICON_COLOR} path={mdiChevronDown} size='1.5rem' />
            </InputAdornment>
          }}
        />

        <Menu
          open={open}
          anchorEl={dateMenuRef}
          onClose={closeDateMenu}
        >
          {
            Object.keys(InvoiceDueDateAfter).map((date, index) => {
              return (
                <MenuItem
                  value={index}
                  onClick={() => handleDueDate(date)}
                >
                  {InvoiceDueDateAfterReverse[index]}
                </MenuItem>)
            })
          }
        </Menu>

        <DatePicker
          variant="dialog"
          TextFieldComponent={() => null}
          open={isDatePickerOpen}
          onOpen={openDatePicker}
          onClose={closeDatePicker}
          value={formik.values.dueDate}
          onChange={(date) => {
            formik.setFieldValue('dueDate', moment(date).format('MMM DD, YYYY'));
            closeDatePicker();
          }}
        />
      </div>

      <InputCNO
        name='notes'
        label="Notes"
        formik={formik}
        placeholder='Enter Invoice notes'
        fullWidth
        multiline
        rows='5'
      />

    </div>
  )
}

export default AddInvoiceMoreDetails
