import { Typography, useTheme } from '@material-ui/core';
import { mdiDirections } from '@mdi/js';
import Icon from '@mdi/react';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import * as yup from 'yup';
import { updateQuotation } from '../../actions/quotationActions';
import DialogCNO from '../../library/DialogCNO';
import FormActionButtons from '../FormActionButtons';
import GooglePlaceInputCNO from '../../library/GooglePlaceInputCNO/GooglePlaceInputCNO';
import { QuotationStatus } from '../../utils/mappings';
import RightsManager from '../../utils/RightsManager';

const EditLocationDialog = ({
  open,
  onClose,
  quotation,
}) => {

  const dispatch = useDispatch();
  const { allQuotationLoader: { editLoader } } = useSelector(state => state.quotations);

  const formik = useFormik({
    initialValues: {
      mapAddress: quotation.mapAddress,
      lat: quotation.lat,
      lng: quotation.lng
    },
    validationSchema: yup.object({
      mapAddress: yup.string().required()
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {

    console.log("Quotation edit values: ", values);

    const updateQuotationPayload = {
      ...quotation,
      mapAddress: values.mapAddress,
      lat: values.lat,
      lng: values.lng
    };

    console.log("updateQuotationPayload: ", updateQuotationPayload);

    dispatch(updateQuotation(updateQuotationPayload));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Location"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <GooglePlaceInputCNO
          address={formik.values.mapAddress}
          onSelectAddress={(address) => {
            formik.setFieldValue('mapAddress', address.address);
            formik.setFieldValue('lat', address.lat);
            formik.setFieldValue('lng', address.lng);
          }}
          label="Location"
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )

}

function QuotationLocationDetailsCard({ quotation }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isCompanyAdmin = RightsManager.isGroupLeader();

  const [isLocationEditable, setIsLocationEditable] = useState(false);

  return (
    <div className={GlobalStyles.detailsCardContainer}>
      <Typography className={GlobalStyles.detailsCardSectionTitle}>
        Location
      </Typography>
      <div className={GlobalStyles.detailsCardSection}>
        <div
          className={GlobalStyles.detailsRow}
          onClick={() => setIsLocationEditable(true)}
        >
          <Typography>{quotation.mapAddress || 'Click to add location'}</Typography>
          <a
            href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${quotation.mapAddress}`}
            target="_blank"
            rel="noreferrer"
            onClick={e => e.stopPropagation()}
          >
            <Icon
              path={mdiDirections}
              size='2rem'
              color={theme.colors.GET_DIRECTION_ICON_COLOR}
            />
          </a>
        </div>
      </div>

      {
        isCompanyAdmin &&
        isLocationEditable &&
        <EditLocationDialog
          open={isLocationEditable}
          onClose={() => setIsLocationEditable(false)}
          quotation={quotation}
        />
      }

    </div>
  )
}

export default QuotationLocationDetailsCard;
