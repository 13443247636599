import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import Expandable from '../../library/Expandable';
import * as yup from 'yup';
import InputCNO from '../../library/InputCNO';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import FormActionButtons from '../FormActionButtons';
import { updateCompanyDetails } from '../../actions/customerCompanyActions';
import { useDispatch } from 'react-redux';
import { mdiFileDocumentEditOutline } from '@mdi/js';
import { useTheme } from '@material-ui/core';


function EmailTermsAndConditionsExpandable({ profile, isActive }) {

  const theme = useTheme();

  const authUserCompany = LocalStorageConstants.getItem("authUserCompany");

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    emailTermsAndConditionTemplate: yup.string().required("Terms & Conditions are required.").nullable(),
  });

  const handleSubmit = (values) => {

    const dataToSend = {
      ...profile,
      ...authUserCompany,
      emailTermsAndConditionTemplate: values.emailTermsAndConditionTemplate
    };

    dispatch(updateCompanyDetails(dataToSend));

  };

  const formik = useFormik({
    initialValues: {
      emailTermsAndConditionTemplate: authUserCompany.emailTermsAndConditionTemplate,
    },
    validationSchema,
    onSubmit: values => handleSubmit(values)
  });

  return (
    <Expandable
      title='Email Terms & Conditions Template'
      icon={mdiFileDocumentEditOutline}
      disabled={!isActive}
      containerStyle={{ background: theme.colors.PROFILE_CARD_BACKGROUND_COLOR }}
    >
      <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>

        <InputCNO
          formik={formik}
          name="emailTermsAndConditionTemplate"
          placeholder="Enter your Terms & Condition"
          rows={10}
          isRequired={true}
          multiline={true}
          disabled={!isActive}
          containerStyles={{ width: "100%" }}
        />

        <FormActionButtons
          rightText="Save"
          isLeftButton={false}
          formik={formik}
        />

      </form>
    </Expandable>
  )
}

export default EmailTermsAndConditionsExpandable