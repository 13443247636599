import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_ChatProfileDetails: {
  },
  chatProfileWrapper: {
    padding: theme.spacing(2),
    background: theme.colors.CHAT_PROFILE_DETAILS_BACKGROUND_COLOR,
    borderRadius: 5,
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  chatProfileAvatar: {
    width: 100,
    height: 100,
    // background: theme.palette.background.dark
  },
  nameWrapper: {
    marginTop: theme.spacing(2),
  },
  name: {
    fontSize: '1.1rem'
  },
  date: {
    color: theme.colors.DIALOG_TITLE_COLOR,
    fontSize: '0.7rem'
  },
  addUserBtn: {
    background: "#3F8A02",
    color: 'white',
    marginBottom: theme.spacing(1)
  },
  memberListWrapper: {
    marginTop: theme.spacing(2)
  },
  list: {
    background: theme.colors.CHAT_PROFILE_DETAILS_BACKGROUND_COLOR,
    padding: 0,
    width: '100%'
  },
  listItem: {
    // background: theme.colors.PROFILE_HEADER_BACKGROUND_COLOR,
    background: theme.colors.CHAT_PROFILE_DETAILS_BACKGROUND_COLOR,
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    marginTop: 4,
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.01)'
    },
  },
  listItemText: {
    textDecoration: 'none',
  },
  groupAdmin: {
    fontSize: '0.6rem',
    padding: '3px 8px',
    border: `1px solid ${theme.palette.text.disabled}`,
    color: theme.palette.text.disabled,
    borderRadius: 5
  },
  memberAvatar: {
    width: 40,
    height: 40
  },
  search: {
    flex: 1,
    borderRadius: 5,
    background: 'red',
    '& input': {
      background: 'white',
      color: ({ color }) => color ? color : theme.colors.CHAT_SEARCH_INPUT_COLOR,
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: ({ color }) => color ? color : theme.colors.CHAT_SEARCH_INPUT_COLOR,
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      // "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": theme.colors.CHAT_SEARCH_INPUT_COLOR,
      caretColor: ({ color }) => color ? color : theme.colors.CHAT_SEARCH_INPUT_COLOR
    }
  },
}));