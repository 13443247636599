import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { mdiCheck, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { convertDecimalTimeToTime, convertDistanceToKM } from '../../utils/Tracking';

function JobCalculationValues({
  job,
  hourTotals,
  distanceTotals,
  title,
  showTarget = true,
  isTargetAcheived,
  mode
}) {

  const theme = useTheme();
  const styles = useStyles();

  const isTimeMode = mode === 'time';
  const isDistanceMode = mode === 'distance';
  const isQuantityMode = mode === 'quantity';

  return (
    <div className={styles.container_JobCalculation}>

      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

        <Typography style={{ color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR, fontWeight: 'bold', marginTop: 4, fontSize: '0.9rem', marginBottom: -15 }}>
          {title}
        </Typography>

        {
          showTarget &&
          <div style={{ display: "flex", alignItems: 'center', fontSize: '1rem', marginTop: 10 }}>

            <Icon
              path={isTargetAcheived ? mdiCheck : mdiClose}
              size="1.4rem"
              color={isTargetAcheived ? theme.colors.JOB_END_DATE_COLOR : theme.colors.JOB_START_DATE_COLOR}
            />

            <div style={{ marginLeft: 4, fontSize: '1rem', display: 'flex' }}>
              <span style={{ color: theme.colors.JOB_START_DATE_COLOR, display: 'flex', flexDirection: 'column' }}>
                <span>
                  {
                    isTimeMode ? convertDecimalTimeToTime(hourTotals.hardHoursWithinProximity) : null
                  }
                  {
                    isDistanceMode ? convertDistanceToKM(distanceTotals.hardDistanceWithinProximity) : null
                  }
                </span>
                <span style={{ fontSize: '0.5rem' }}>actual</span>
              </span>
              <span style={{ color: theme.colors.JOB_END_DATE_COLOR, display: 'flex' }}>
                /
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{job.target ? job.target : 0}</span>
                  <span style={{ fontSize: '0.5rem' }}>target</span>
                </span>
              </span>
            </div>

          </div>

        }

        {
          isQuantityMode
            ? <div style={{ display: "flex", fontSize: '1.4rem', marginLeft: 4, marginTop: 12 }}>
              <span style={{ color: theme.colors.JOB_END_DATE_COLOR, display: 'flex', flexDirection: 'column' }}>
                <span>{job.target ? job.target : 0}</span>
                <span style={{ fontSize: '0.5rem' }}>target</span>
              </span>
            </div>
            : null
        }

      </div>

      {
        !isQuantityMode &&
        <div style={{ display: 'flex', flex: 1 }}>

          <div style={{ flex: 0.6, }}>
            <div style={{ color: theme.colors.WORK_HOUR_JOB_TITLE_TEXT_COLOR, marginTop: 4, marginBottom: 4, fontSize: '0.9rem', textAlign: 'right' }}>WP</div>
            <div style={{ color: theme.colors.WORK_HOUR_JOB_TITLE_TEXT_COLOR, fontSize: '0.9rem', textAlign: 'right' }}>OP</div>
          </div>

          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ color: theme.colors.JOB_END_DATE_COLOR, fontWeight: 'bold', marginTop: 4, marginBottom: 4, fontSize: '0.9rem' }}>
              {
                isTimeMode ? convertDecimalTimeToTime(hourTotals.hardHoursWithinProximity) : null
              }
              {
                isDistanceMode ? convertDistanceToKM(distanceTotals.hardDistanceWithinProximity) : null
              }
            </div>
            <div style={{ color: theme.colors.JOB_START_DATE_COLOR, fontWeight: 'bold', fontSize: '0.9rem' }}>
              {
                isTimeMode ? convertDecimalTimeToTime(hourTotals.hardHoursOutsideProximity) : null
              }
              {
                isDistanceMode ? convertDistanceToKM(distanceTotals.hardDistanceOutsideProximity) : null
              }
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '0.9rem', textAlign: 'right' }}>
            <div style={{ color: theme.colors.WORK_HOUR_JOB_TITLE_TEXT_COLOR, fontWeight: 'bold', marginTop: 4, marginBottom: 4 }}>
              {
                isTimeMode ? convertDecimalTimeToTime(hourTotals.softHoursWithinProximity) : null
              }
              {
                isDistanceMode ? convertDistanceToKM(distanceTotals.softDistanceWithinProximity) : null
              }
              <sup style={{ fontSize: '0.6rem' }}>soft</sup>
            </div>
            <div style={{ color: theme.colors.WORK_HOUR_JOB_TITLE_TEXT_COLOR, fontWeight: 'bold', fontSize: '0.9rem' }}>
              {
                isTimeMode ? convertDecimalTimeToTime(hourTotals.softHoursOutsideProximity) : null
              }
              {
                isDistanceMode ? convertDistanceToKM(distanceTotals.softDistanceOutsideProximity) : null
              }
              <sup style={{ fontSize: '0.6rem' }}>soft</sup>
            </div>
          </div>

        </div>
      }

    </div>
  )
}

export default JobCalculationValues;

const useStyles = makeStyles((theme) => ({
  container_JobCalculation: {
    display: 'flex',
    alignItems: 'flex-start',
    background: theme.colors.WORK_HOURS_TOTAL_ROW_BACKGROUND_COLOR,
    borderRadius: 5,
    padding: '8px 12px',
    marginTop: theme.spacing(2),
    '&>*': {
      '&:first-child': {
        fontWeight: 'bold',
        flex: 0.3
      }
    }
  }
}));
