import {
  Button,
  Dialog,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { mdiContentSave, mdiFileCancel } from "@mdi/js";
import Icon from "@mdi/react";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min";
import { deleteJobById } from "../../actions/jobActions";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import InputCNO from "../../library/InputCNO";
import { deletePostById } from "../../actions/postActions";

function ConfirmDialogTest({
  open,
  setOpen,
  message,
  item,
  closeMore,
  onClose,
  handleDelete,
  isPost,
}) {
  const confirmDeleteText = message.slice(0, 20).split("\n").join("").trim();

  const theme = useTheme();

  const tillTablet = useMediaQuery("(max-width: 767px)");

  const styles = useStyles({ tillTablet });

  const [yesDisabled, setYesDisabled] = useState(true);

  const dispatch = useDispatch();
  const { isJobDeleteLoader } = useSelector((state) => state.jobs);
  const { deletePostLoader } = useSelector((state) => state.posts);
  const history = useHistory();
  const routes = useRouteMatch();

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    onClose;
  };

  // const handleDelete = () => {
  //   isPost
  //     ? localStorage.setItem("undoPost", JSON.stringify(item))
  //     : localStorage.setItem("undoJob", JSON.stringify(item));
  //   const path = routes.path;
  //   isPost
  //     ? dispatch(deletePostById(item, history, path))
  //     : dispatch(deleteJobById(item, history, path));
  // };

  const handleConfirm = () => {
    handleDelete();
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      confirmDeleteText: "",
    },
    onSubmit: (values) => handleConfirm(values),
  });

  console.log(
    "========confirmcheck: ",
    formik.values.confirmDeleteText,
    confirmDeleteText,
    formik.values.confirmDeleteText === confirmDeleteText
  );

  return (
    <Dialog
      open={open}
      className={styles.container_ConfirmDialog}
      classes={{ paper: styles.paper }}
    >
      {isJobDeleteLoader || (deletePostLoader && <FullScreenLoaderCNO />)}

      <Typography className={styles.dialogTitle}>
        Are you sure, you want to delete "{message}"?
      </Typography>

      {item && (
        <form onSubmit={formik.handleSubmit} className={styles.deleteForm}>
          <InputCNO
            name="confirmDeleteText"
            formik={formik}
            placeholder="Enter here..."
            fullWidth
            autoFocus
            onChange={(e) => {
              formik.setFieldValue("confirmDeleteText", e.target.value);
              setYesDisabled(!(e.target.value === confirmDeleteText));
            }}
          />

          <Typography>Please enter following text to delete Test.</Typography>

          <Typography component={"pre"} className={styles.confirmDeleteText}>
            {confirmDeleteText}
          </Typography>

          <div className={styles.ctaWrapper}>
            <Button
              variant="contained"
              color="primary"
              startIcon={
                <Icon
                  path={mdiFileCancel}
                  size="1.5rem"
                  color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR}
                />
              }
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={
                <Icon
                  path={mdiContentSave}
                  size="1.5rem"
                  color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR}
                />
              }
              disabled={yesDisabled}
            >
              Yes
            </Button>
          </div>
        </form>
      )}
    </Dialog>
  );
}

export default ConfirmDialogTest;

const useStyles = makeStyles((theme) => ({
  container_ConfirmDialog: {},
  paper: {
    padding: theme.spacing(3),
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    margin: ({ tillTablet }) => (tillTablet ? 8 : 32),
    width: "70vw",
    maxWidth: "90vh",
  },
  deleteForm: {
    "&>*": {
      marginTop: 10,
    },
  },
  dialogTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
  },
  confirmDeleteText: {
    color: theme.colors.ERROR_COLOR,
    marginTop: 5,
  },
  ctaWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(6),
    "&>*": {
      color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
      boxShadow: `2px 2px 5px -1px ${theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR}`,
      border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
      color: theme.palette.text.primary,
      "&:first-child": {
        marginRight: theme.spacing(4),
        background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
        color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
      },
      "&:last-child": {
        background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
      },
    },
  },
}));
