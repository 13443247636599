import { makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import DialogCNO from '../../library/DialogCNO';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import { Currency, SubscriptionMode } from '../../utils/mappings';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutForm } from '../../utils/Payments/CheckoutForm';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import PaymentSuccess from './PaymentSuccess';
import PaymentError from './PaymentError';
import { useDispatch } from 'react-redux';
import { updateCompanyDetails } from '../../actions/customerCompanyActions';
import { fetchCurrentSubscriptionDetails, setPaymentDue } from '../../actions/subscriptionActions';
import moment from 'moment';
import { AppConstants } from '../../utils/AppConstants';


const stripePromise = loadStripe('pk_live_51KwjWRHnJK6hvmrSwVv853ns33LmbjdOhytcnEMclTJn7xP9qMWSOfvM3gkWITbJ7G9ApL88IrpZTdm66xssmyZd00sVt8tYuv');
//const stripePromise = loadStripe('pk_test_51KwjWRHnJK6hvmrS68L0U9yU5NlbLiFFapctJ63YE7qL6K3xyIWf3wSUZSoseA1s5lqGE1Hf84DWzW5T2w9uEmQr00azTAbuXv');

export default function MakePaymentDialog({
  open,
  onClose,
  profile,
  paymentData,
  selectedSubscription,
  isYearly,
  subscriptionTotalUsers,
  resetSubscriptionOnFailOrCancel
}) {

  const styles = useStyles();

  const currencyType = LocalStorageConstants.getItem('currencyType', Currency.GBP);

  const dispatch = useDispatch();

  const [stripeClientSecret, setstripeClientSecret] = useState(
    LocalStorageConstants.getItem('authUserCompany').stripeSecretKey ?
      LocalStorageConstants.getItem('authUserCompany').stripeSecretKey : ''
  );

  const stripeOptions = {
    // passing the client secret obtained from the server
    clientSecret: stripeClientSecret,
    appearance: {
      theme: 'stripe',

      variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '2px',
        borderRadius: '4px',
      }
    }
  };

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(false);

  const handlePaymentSuccess = (succeeded) => {
    setIsSuccess(succeeded);

    if (!succeeded) return;

    const companyPayload = {
      ...profile,
      ...selectedSubscription,
      paymentDate: moment().toISOString(),
      paymentType: isYearly ? SubscriptionMode.Yearly : SubscriptionMode.Monthly,
      userCount: subscriptionTotalUsers,
    };

    const onSuccess = () => {

      const paymentDuePayload = {
        dueDate: moment().add(isYearly ? 12 : 1, 'months').toISOString(),
        subscriptionType: selectedSubscription.subscriptionType
      };

      dispatch(setPaymentDue(paymentDuePayload));

      dispatch(fetchCurrentSubscriptionDetails(selectedSubscription.subscriptionType));

      LocalStorageConstants.removeItem("oldCompanyDetails", true);
      LocalStorageConstants.removeItem("oldPaymentDetails", true);

    }

    dispatch(updateCompanyDetails(companyPayload, { onSuccess }));

  }

  const handlePaymentError = (failed, _error) => {
    setIsError(failed);
    setError(_error);

    resetSubscriptionOnFailOrCancel(isSuccess, isError);
  }

  const handleClose = () => {
    if (typeof onClose === "function") onClose();
    resetSubscriptionOnFailOrCancel(isSuccess, isError);
  }

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle={"Make Payment"}
    >
      <div className={styles.section} >

        {
          stripeClientSecret &&
          !(isSuccess || isError) &&
          (<Elements stripe={stripePromise} options={stripeOptions}>
            <CheckoutForm
              profile={profile}
              paymentData={paymentData}
              selectedSubscription={selectedSubscription}
              isYearly={isYearly}
              subscriptionTotalUsers={subscriptionTotalUsers}
              onSuccess={handlePaymentSuccess}
              onError={handlePaymentError}
              onClose={handleClose}
            />
          </Elements>)
        }

        {
          isSuccess &&
          <PaymentSuccess
            onOkay={handleClose}
          />
        }

        {
          isError &&
          <PaymentError
            error={error}
            onRetry={() => {
              setIsSuccess(false);
              setIsError(false);
              setError(null);
            }}
            onCancel={handleClose}
          />
        }
      </div>
    </DialogCNO>
  )
}

const useStyles = makeStyles((theme) => ({

  section: {
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    padding: '24px 16px',
    marginBottom: 8,
    borderRadius: 5,
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  labelWrapper: {
    display: 'flex'
  },

  label: {

  },

  value: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.BONUS_CALCULATION_VALUE_COLOR
  },

  formula: {
    marginLeft: 5,
    color: theme.colors.BONUS_CALCULATION_FORMULA_COLOR
  },

  valueRow: {
    display: 'flex',
    alignItems: 'center'
  },

  step: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.BONUS_CALCULATION_STEP_COLOR
  }

}));
