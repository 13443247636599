import { makeStyles } from '@material-ui/core';

export const useGlobalStyles = makeStyles((theme) => ({

  listingContainer: {
    padding: theme.spacing(0.8),
    minHeight: 'calc(100vh - 80px)',
    '&>*': {
      marginBottom: theme.spacing(1),
    },
    '& .infinite-scroll-component > *': {
      marginBottom: theme.spacing(1),
    }
  },

  listCardContainer: {
    borderRadius: 10,
    padding: '16px 8px',
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    color: theme.palette.getContrastText(theme.palette.background.main),
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
  },

  listCardFW: {
    width: '100%',
  },

  listCard: {
    width: 'calc(33% - 6.66px)',
    '&:hover': {
      cursor: 'pointer'
    },
  },

  cardInfoWrapper: {
    display: 'flex',
  },
  cardInfo: {
    marginLeft: theme.spacing(2),
    flex: 1,
    marginBottom: theme.spacing(1)
  },
  cardTitle: {
    color: theme.colors.LIST_CARD_TITLE_COLOR,
    fontWeight: '700'
  },
  cardTitleHelper: {
    color: theme.colors.LIST_CARD_USERNAMES_COLOR,
  },
  datesWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: 4
    }
  },
  cardDate: {
    color: theme.colors.META_TEXT_COLOR,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      fontSize: '0.9rem'
    }
  },

  cardInfoRow: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.LIST_CARD_INFO_TEXT_COLOR,
    '&>*:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  cardInfoRowText: {
    fontSize: '0.9rem',
    color: theme.colors.LIST_CARD_INFO_TEXT_COLOR,
  },

  listingNotFound: {
    borderRadius: 10,
    padding: '16px 8px',
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    color: theme.colors.LIST_CARD_USERNAMES_COLOR,
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    textAlign: 'center',
    flex: 1
  },

  cardDescription: {
    background: theme.colors.LIST_CARD_PARA_BACKGROUND_COLOR,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderRadius: 5,
    color: theme.colors.LIST_CARD_PARA_COLOR,
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
  },

  cardStatusBarContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 10,
    flex: 1,
    background: theme.colors.JOB_ACTION_CONTAINER_BACKGROUND_COLOR,
    // boxShadow: `1px 1px 6px -1px ${theme.palette.text.secondary}`,
    '&>*:nth-child(2n+1)': {
      fontSize: ({ tillTablet }) => tillTablet ? '0.8rem' : 'initial'
    },
    '&>*': {
      color: theme.palette.text.secondary,
    }
  },

  moreIcon: {
    '&:hover': {
      cursor: 'pointer',
    }
  },

  detailsContainer: {
    padding: ({ tillTablet }) => tillTablet ? 10 : theme.spacing(2),
    background: theme.colors.JOB_DETAIL_BACKGROUND_COLOR,
    '&>*': {
      marginTop: theme.spacing(2),
      '&:first-child': {
        marginTop: 0
      }
    }
  },

  statusBar: {
    display: 'flex',
    alignItems: 'center',
  },

  detailsCardContainer: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
  },

  detailsCardSectionTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
    fontSize: '0.7rem'
  },

  detailsCardSection: {
    padding: theme.spacing(1),
    '&>*': {
      marginTop: theme.spacing(1)
    }
  },

  detailsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.colors.JOB_DETAIL_SECTION_TEXT_COLOR
  },

  searchToolbar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    position: 'sticky',
    top: 40,
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    zIndex: 1,
    // padding: '0px 0px 5px 10px',
  },

  listingInfiniteLoader: {
    textAlign: 'center'
  },

  container_MorePopover: {
    padding: 5,
    overflow: 'hidden',
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR
  },
  list: {
    background: theme.colors.MORE_ACTIONS_OPTIONS_WRAPPER_BACKGROUND_COLOR,
    // background: theme.palette.background.dark,
    padding: 0
  },
  listItem: {
    background: theme.colors.MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 2,
    // width: 200,
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.01)'
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0,
    },
    '& .MuiIconButton-root': {
      padding: 6
    },
  },
  listItemText: {
    textDecoration: 'none',
  },

  dropdown: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      background: theme.colors.INPUT_BACKGROUND_COLOR,
      color: theme.colors.INPUT_TEXT_COLOR,
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: theme.colors.INPUT_TEXT_COLOR,
    },
    '& .MuiSelect-icon': {
      color: theme.colors.INPUT_TEXT_COLOR,
    }
  },

  chipsWrapper: {
    display: 'flex',
    marginTop: 8,
    '&>*': {
      marginRight: theme.spacing(1)
    }
  },

  verticalScrollChipsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 132,
    overflowY: 'auto',
    '&>*': {
      margin: 4
    },
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#5e5e5e',
      borderRadius: 10,
    },
  },

  timeStampWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  picker: {
    color: theme.colors.JOB_END_DATE_COLOR,
    flex: 1,
    width: '100%',
    '& .MuiInputBase-root': {
      background: theme.colors.INPUT_BACKGROUND_COLOR,
      color: theme.colors.INPUT_TEXT_COLOR,
    },
    '& .MuiIconButton-label': {
      color: theme.colors.FORM_LABEL_COLOR,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      // paddingRight: 0
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: 5,
    },
    '& .MuiIconButton-root': {
      paddingLeft: 0,
      paddingRight: 5,
    }
  },
  time: {
    marginLeft: theme.spacing(2),
    maxWidth: '40%',
  },
  start: {
    '& .MuiSvgIcon-root': {
      color: theme.colors.JOB_START_DATE_COLOR
    }
  },
  end: {
    '& .MuiSvgIcon-root': {
      color: theme.colors.JOB_END_DATE_COLOR
    }
  },
  subTaskDetailRowContaienr: {
    display: 'flex',
    background: '#2e2e2e',
    border: '1px solid #7e7e7e',
    borderRadius: 5,
    padding: '4px 8px',
    alignItems: 'center'
  },

  accordion: {
    background: theme.colors.ACCORDION_BACKGROUND_COLOR,
    padding: 4,
    boxShadow: '1px 1px 4px 0px #868686',
    borderRadius: 8,
    '&.MuiAccordion-rounded:first-child': {
      borderRadius: 8
    },
    '&.MuiAccordion-rounded:last-child': {
      borderRadius: 8
    },
    '& .MuiIconButton-label': {
      padding: 4,
      paddingRight: 8
    },
    '&.MuiAccordion-root:before': {
      display: 'none'
    },
    '&.MuiAccordion-rounded': {
      borderRadius: 8,
    }
  },
  accordionSummary: {
    color: theme.colors.ACCORDION_TITLE_COLOR,
    fontSize: '0.9rem',
    fontWeight: 'bold',
    minHeight: 35,
    paddingLeft: '8px',
    paddingRight: '8px',
    display: "flex",
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiAccordionSummary-content': {
      // flexDirection: 'column'
      width: '100%',
      alignItems: 'center'
    },
    '& .MuiAccordionSummary-expandIcon': {
      padding: 0
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 35,
      borderBottom: `1px solid ${theme.colors.ACCORDION_TITLE_COLOR}`
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0'
    },
  },

  accordionTitleIcon: {
    color: theme.colors.ACCORDION_TITLE_COLOR,
    marginRight: 10
  },

  subItemsListContainer: {
    maxHeight: 500,
    overflowY: 'auto',
    marginTop: 8,
    paddingRight: 8
  },

  subTaskMoreWrapper: {
    '& > *': {
      marginTop: 10
    }
  },

  label: {
    color: theme.colors.FORM_LABEL_COLOR,
    // marginBottom: theme.spacing(1)
  },

  //autocomplete
  textField: {
    borderRadius: 5,
    '& input': {
      background: 'white',
      color: theme.colors.INPUT_TEXT_COLOR,
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: theme.colors.INPUT_TEXT_COLOR
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": theme.colors.INPUT_TEXT_COLOR,
      caretColor: theme.colors.INPUT_TEXT_COLOR,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 14
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0,
      background: 'white',
    },
    '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      padding: 0
    },
  },
  autocompleteNoOptions: {
    color: theme.colors.ERROR_COLOR
  },
  popper: {
    zIndex: 1301
  },
  suggestionsPaper: {
    background: 'white !important',
  },
  autocompleteChip: {
    background: theme.colors.CHIP_BUTTON_BACKGROUND_COLOR
  },

  // select input
  textFieldSelect: {
    borderRadius: 5,
    '& .MuiInputBase-root': {
      background: 'white',
      color: theme.colors.INPUT_TEXT_COLOR,
    },
    '& input': {
      background: 'white',
      color: ({ color }) => color ? color : theme.colors.INPUT_TEXT_COLOR,
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: ({ color }) => color ? color : theme.colors.INPUT_TEXT_COLOR,
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      "-webkit-box-shadow": `0 0 0 100px ${theme.colors.INPUT_BACKGROUND_COLOR} inset`,
      "-webkit-text-fill-color": theme.colors.INPUT_TEXT_COLOR,
      caretColor: ({ color }) => color ? color : theme.colors.INPUT_TEXT_COLOR
    },
    '& .MuiSelect-icon': {
      color: theme.colors.INPUT_TEXT_COLOR,
    }
  },

  confirmDeleteText: {
    color: theme.colors.ERROR_COLOR,
    marginTop: 5
  },

  link: {
    marginTop: theme.spacing(2),
    textDecoration: 'underline',
    color: '#79a8d7',
    '&:hover': {
      cursor: 'pointer',
    }
  },

  //document section styles
  documentUploadWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },

  documentThumbnail: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${theme.colors.DOCUMENT_CARD_BORDER_COLOR}`,
    width: 150,
    height: 150,
    marginRight: 10,
    borderRadius: 5,
    padding: 10,
    overflow: 'hidden',
  },

  documentThumbnailWrapper: {
    display: 'flex',
  },

  documentFileName: {
    color: "#aeaeae",
    fontSize: '0.8rem',
    marginTop: 10,
    textAlign: 'center'
  },

  documentAddMediaAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 8
  },

  documentLoader: {
    width: 150,
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${theme.colors.DOCUMENT_CARD_BORDER_COLOR}`,
    marginRight: 10,
    borderRadius: 5,
  },

  documentCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  documentActionRow: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  documentActions: {
    marginRight: theme.spacing(1),
    cursor: 'pointer'
  },

  drawerItemSeparator: {
    height: 1,
    background: theme.colors.DRAWER_ITEM_SEPARATOR_COLOR,
    margin: '0 12px'
  },

  ctaWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(6),
    '& > *': {
      marginLeft: 4
    }
  },

  greyButton: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
    marginRight: theme.spacing(4),
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
  },
  greenButton: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    marginLeft: '1rem'
  },
  orangeButton: {
    background: theme.colors.FORM_SECONDARY_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_SECONDARY_BUTTON_TEXT_COLOR,
    marginLeft: '1rem'
  },

  moreDetailsContainer: {
    '&>*': {
      marginBottom: theme.spacing(2)
    }
  },

  form: {
    overflowX: "hidden",
    "&>*": {
      marginBottom: theme.spacing(3),
    },
  },
  sectionChildrenContainer: {
    "&>*": {
      marginTop: theme.spacing(2),
    }
  },

  tabs: {
    background: theme.colors.TAB_BACKGROUND_COLOR,
    // active tab
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: theme.colors.TAB_ACTIVE_COLOR,
      fontWeight: 'bold'
    },
  },
  tabPanel: {
    background: theme.colors.TAB_DETAILPANEL_BACKGROUND_COLOR,
    padding: '0'
  },
  activeTabIndicator: {
    background: theme.colors.TAB_ACTIVE_COLOR,
    color: theme.colors.TAB_ACTIVE_COLOR,
  },

  snapListSlider: {
    display: 'flex',
    padding: '5px 0',
    '&>*:not(:first-child)': {
      marginLeft: 15
    }
  }

}));