import { createContext, useContext, useReducer } from "react";
import { TOGGLE_LEFT_SIDEBAR, TOGGLE_RIGHT_SIDEBAR, TOGGLE_SEARCH_SIDEBAR, TOGGLE_SEARCH } from "../utils/actions";

const initialState = {
  isLeftSidebar: false,
  isSearchInSidebar: false,
  isRightSidebar: false,
  // isRightSidebar: window.innerWidth<=768 ? false : true,
  isSearch: window.innerWidth <= 768 ? false : true,
};

export const store = createContext(initialState);
const { Provider } = store;


const globalReducer = (state, { type, payload }) => {
  console.log('action: ', type);
  switch (type) {
    case TOGGLE_LEFT_SIDEBAR:
      return {
        ...state,
        isLeftSidebar: payload
      };

    case TOGGLE_RIGHT_SIDEBAR:
      console.log('payload: ', payload);
      return {
        ...state,
        isRightSidebar: payload
      };

    case TOGGLE_SEARCH_SIDEBAR:
      return {
        ...state,
        isSearchInSidebar: payload
      };

    case TOGGLE_SEARCH:
      return {
        ...state,
        isSearch: payload,
      };

    default:
      return state;
  }
};

const GlobalProvider = ({ children }) => {

  const [globalState, dispatchGlobal] = useReducer(globalReducer, initialState);
  return (
    <Provider value={{ globalState, dispatchGlobal }}>
      {children}
    </Provider>
  )
}

export const useGlobalStore = () => useContext(store);

export default GlobalProvider;