import React, { useState, useEffect, Fragment } from 'react';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
// import { LandingPageTheme } from '../../common/theme/LandingPage';
import { LandingPageTheme as LandingPageDarkTheme } from '../../common/theme/LandingPage/LandingPageDarkTheme';
import { LandingPageTheme as LandingPageLightTheme } from '../../common/theme/LandingPage/LandingPageLightTheme';
import {
  GlobalStyle,
  AppWrapper,
  ConditionWrapper,
} from '../../components/LandingPage/LandingPage.style';
import ResetCSS from '../../common/assets/css/style';
import Navbar from '../../components/LandingPage/Navbar';
import DomainSection from '../../components/LandingPage/Banner';
import FeatureSection from '../../components/LandingPage/FeatureSection';
import ControlSection from '../../components/LandingPage/Control';
import TestimonialSection from '../../components/LandingPage/Testimonial';
import PartnerHistory from '../../components/LandingPage/PartnerHistory';
import PaymentSection from '../../components/LandingPage/PaymentSection';
import Footer from '../../components/LandingPage/Footer';
import FeatureSlider from '../../components/LandingPage/FeatureSlider';
import FeatureSliderTwo from '../../components/LandingPage/FeatureSliderTwo';
import { DrawerProvider } from '../../common/contexts/DrawerContext';
import AvailableStore from '../../components/LandingPage/AvailableStore';
import Pricing from '../../components/LandingPage/Pricing';
import CallToAction from '../../components/LandingPage/CallToAction';
import Faq from '../../components/LandingPage/Faq';
import { useThemeStore } from '../../providers/ThemeProvider';
import { ThemeType } from '../../utils/mappings';
import IndustriesSection from '../../components/LandingPage/IndustriesSection';
import QuickTutorialVideo from '../../components/QuickTutorialVideo';

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

function UseWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function HandleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener('resize', HandleResize);

    return () => {
      window.removeEventListener('resize', HandleResize);
    };
  }, []);

  return windowSize;
}

const LandingPage = () => {
  const size = process.browser && UseWindowSize();
  // const size = process.browser;

  const { themeState } = useThemeStore();
  const { theme } = themeState;

  const activatedTheme = theme === ThemeType.Dark ? LandingPageDarkTheme : LandingPageLightTheme;

  return (
    <ThemeProvider theme={activatedTheme}>

      <QuickTutorialVideo />

      <Fragment>
        <Head>
          <title>meFolia</title>
          <meta name="Description" content="meFolia Application" />
          <meta name="theme-color" content="#ec5555" />
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,700"
            rel="stylesheet"
          />
        </Head>

        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <DomainSection />
          <FeatureSection />
          <ControlSection />
          <ConditionWrapper id="keyfeature">
            {size.innerWidth > 1100 ? <FeatureSlider /> : <FeatureSliderTwo />}
          </ConditionWrapper>
          <IndustriesSection />
          {/* <PartnerHistory /> */}
          {/* <PaymentSection /> */}
          {/*<PricingPolicy/>*/}
          <Pricing />
          {<TestimonialSection />}
          <AvailableStore />
          <Faq />
          {/* <CallToAction /> */}
          <Footer />
        </AppWrapper>
      </Fragment>
    </ThemeProvider>
  )
}

export default LandingPage;
