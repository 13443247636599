import { SET_EVENTS, SET_EVENT_BY_ID, SET_EVENT_LOADERS, TOGGLE_ADD_EVENT_DIALOG } from "../actions/eventActions";

const initialState = {
  events: [],
  eventById: null,

  hasMoreEvents: false,
  isAddEventDialog: false,

  // loaders
  eventsLoader: false,
  addEventLoader: false,
  editEventLoader: false,
  deleteEventLoader: false,

  // filters
  eventSearchParams: {
    eventType: 0,
    categories: [],
    event: null,
    startDate: null,
    endDate: null,
    responseAction: 0
  }
}

export const eventReducer = (state = initialState, { type, payload }) => {

  switch (type) {

    case SET_EVENTS: {
      state = {
        ...state,
        events: payload,
      }
      return state;
    }

    case SET_EVENT_BY_ID: {
      state = {
        ...state,
        eventById: payload,
      }
      return state;
    }

    case SET_EVENT_LOADERS: {

      const { loaderName, loaderState } = payload;

      state = {
        ...state,
        [loaderName]: loaderState
      };
      return state;

    }

    case TOGGLE_ADD_EVENT_DIALOG: {
      console.log("dispatched33")

      state = {
        ...state,
        isAddEventDialog: payload,
      }
      return state;
    }

    default:
      return state;
  }

}