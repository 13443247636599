import { Box, makeStyles } from '@material-ui/core';
import React from 'react'

const Spacer = ({ children, direction = 'column', spacing = '0.75rem', style = {}, ...rest }) => {

  const styles = useStyles({ direction, spacing });

  return (
    <Box
      className={styles.container}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Spacer;

const useStyles = makeStyles(() => ({

  container: ({ direction, spacing, }) => ({
    display: 'flex',
    flexDirection: direction,
    ...direction === 'row' ? { alignItems: 'center' } : {},
    ...direction === 'column' ? { justifyContent: 'center' } : {},
    '& > *': {
      ...direction === 'row' ? { marginRight: spacing } : {},
      ...direction === 'column' ? { marginBottom: spacing } : {},
    }
  })

}))
