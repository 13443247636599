import { SET_CHAT_CONNECTION, SET_NOTIFICATION_CONNECTION } from "../actions/connectionHubActions";

const initialState = {
    chatConnection: null,
    notificationConnection: null,
    loading: false,
};

export default function connectionHubReducer(state = initialState, { type, payload }) {

    switch (type) {
        case SET_CHAT_CONNECTION:
            state = {
                ...state,
                chatConnection: payload
            };
            return state;

        case SET_NOTIFICATION_CONNECTION:
            state = {
                ...state,
                notificationConnection: payload
            };
            return state;

        default:
            return state;
    }
}