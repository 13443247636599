import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import ScreenView from '../../../components/ScreenView/ScreenView';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDynamicFormsData, toggleAddDynamicFormsDataDialog } from '../../../actions/formBuilderActions';
import { DataGrid } from '@material-ui/data-grid';
import { Box } from '@material-ui/core';
import { IconMain, ListEmpty } from '../../../library';
import { AddFormDataDialog } from '../dialogs';
import { AddDynamicFormsDataMode } from '../utils/enums';
import { capitalizeAndBreak, isArrayEmpty } from '../../../utils/Helpers';
import FullScreenLoaderCNO from '../../../library/FullScreenLoaderCNO';
import TooltipCNO from '../../../library/TooltipCNO';

const FormDataDetailPage = () => {

  const { id } = useParams()

  const dispatch = useDispatch();
  const { dynamicFormsData, dynamicFormsDataLoader, isAddDynamicFormsDataDialog } = useSelector(state => state.formBuilder);

  useEffect(() => {
    dispatch(fetchDynamicFormsData({ formId: id }));
  }, [id]);

  const rows = dynamicFormsData.map(d => ({ ...d, actions: '' }));

  console.log("FormDataDetailsPage: rows: ", rows);

  const columns = !isArrayEmpty(dynamicFormsData)
    ? Object.keys(dynamicFormsData[0])
      .slice(0, 5)
      .map(key => ({
        field: key,
        headerName: capitalizeAndBreak(key),
        flex: 1,
      }))
    : [];


  const handleView = (data) => () => {
    dispatch(toggleAddDynamicFormsDataDialog({
      state: true,
      mode: AddDynamicFormsDataMode.ids.View,
      formikValues: data
    }));
  }

  const handleEdit = (data) => () => {
    dispatch(toggleAddDynamicFormsDataDialog({
      state: true,
      mode: AddDynamicFormsDataMode.ids.Edit,
      formikValues: data
    }));
  }

  const actionColumn = {
    field: "actions",
    headerName: 'Actions',
    width: 120,
    renderCell: (params) => {
      console.log("actionColumn: params: ", params)
      return (
        <Box display='flex' alignItems='center' justifyContent='center' style={{ gap: 8 }}>
          <TooltipCNO title='View' arrow placement="right">
            <IconMain name='eye' size='1.2rem' onClick={handleView(params.row)} />
          </TooltipCNO>

          <TooltipCNO title='Edit' >
            <IconMain name='pencil' size='1.2rem' onClick={handleEdit(params.row)} />
          </TooltipCNO>

          <TooltipCNO title='Delete'  >
            <IconMain name='trash-can' size='1.2rem' />
          </TooltipCNO>

        </Box>
      )
    }
  }

  return (
    <ScreenView headerTitle='Form Data'>

      <FullScreenLoaderCNO loading={dynamicFormsDataLoader} />

      <Box p={1} >
        {
          rows.length ? <DataGrid
            rows={rows}
            columns={[...columns, actionColumn]}
            rowHeight={35}
            hideFooterSelectedRowCount
            pageSize={5}
            autoHeight
            headerHeight={40}
            disableSelectionOnClick
          /> : <ListEmpty
            notFoundTextMessage={'No data Present'}
            notFoundIcon={'form-select'}
            addTitle="New Entry"
            onClickAdd={() => dispatch(toggleAddDynamicFormsDataDialog({ state: true, mode: AddDynamicFormsDataMode.ids.Add }))}
          />
        }
      </Box>

      <AddFormDataDialog
        open={isAddDynamicFormsDataDialog}
        onClose={() => dispatch(toggleAddDynamicFormsDataDialog({ state: false }))}
      />

    </ScreenView>
  )
}

export default FormDataDetailPage