import { mdiAccount, mdiBankOutline, mdiNumeric } from '@mdi/js';
import React from 'react';
import InputCNO from '../../library/InputCNO';

function CompanyBankDetails({
  formik,

}) {
  return (
    <div>
      <InputCNO
        name='accNumber'
        label="Account Number"
        formik={formik}
        placeholder="Account Number"
        icon={mdiNumeric}
        fullWidth
      />
      <InputCNO
        name='accHolderName'
        label="Account Holder Name"
        formik={formik}
        placeholder="Account Holder Name"
        icon={mdiAccount}
        fullWidth
      />
      <div style={{ display: 'flex', gap: 20 }}>
        <InputCNO
          name='branch'
          label="Bank Name"
          formik={formik}
          placeholder="Bank Name"
          icon={mdiBankOutline}
          fullWidth
          containerStyles={{ flex: 1 }}
        />
        <InputCNO
          name='code'
          label="Sort Code"
          formik={formik}
          placeholder="Sort Code"
          icon={mdiNumeric}
          fullWidth
          containerStyles={{ flex: 1 }}
        />
      </div>

    </div>
  )
}

export default CompanyBankDetails;