import { Box, Typography } from "@material-ui/core";
import { mdiCurrencyGbp,mdiMapMarkerRadiusOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState,useEffect } from "react";
import { useStyles } from "../ServiceProviderWorkingDetails/ServiceProviderWorkingDetailsStyles";
import DialogCNO from "../../library/DialogCNO";
import { useFormik } from "formik";
import * as yup from "yup";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import { useDispatch, useSelector } from "react-redux";
import { updateServiceProviderDetails } from "../../actions/serviceProviderActions";
import RightsManager from "../../utils/RightsManager";
import * as icons from "@mdi/js";
import { Currency, CurrencyIcon } from "../../utils/mappings";
import { toast } from "react-hot-toast";
import GooglePlaceInputCNO from "../../library/GooglePlaceInputCNO";
function EditLocationDetails({
  open,
  onClose,
  profile,
  editablePropertyName,
  prevLocation,
}) {
  const styles = useStyles();

  const [locationData, setLocationData] = useState([]);

  const getValidation = (property) => {
    const validation = {
      mapAddress: yup.object().required("Required!!!").nullable(),
    };

    return validation[property];
  };

  const getInitialValues = (property) => {
    const initialValues = {
      mapAddress: {
        label: profile.mapAddress,
        value: locationData,
      },
    };

    return initialValues[property];
  };

  useEffect(() => {
    if (editablePropertyName === "mapAddress" && profile.mapAddress) {
      const getLocation = async () => {
        await geocodeByAddress(profile.mapAddress)
          .then((results) => {
            setLocationData(results);
          })
          .catch((error) => {
            console.error("Location Error: ", error);
            toast.error("Location fetch error!!!");
          });
      };

      if (window.google && window.google.maps) {
        getLocation();
      }
    }
  }, [profile.mapAddress]);

  const dispatch = useDispatch();

  const {
    allServiceProvidersLoader: { editLoader: serviceProviderEditLoader },
  } = useSelector((state) => state.serviceProviders);

  // const validationSchema = yup.object({

  // });

  const formik = useFormik({
    initialValues: {
      mapAddress:"",
      lat:"",
      lng:""

    },
    // validationSchema,
    onSubmit: (values) => handleUpdate(values),
  });

  const handleUpdate = async (values) => {


    let dataToSend = {
      ...profile,
      location: [
        ...profile.location.filter(
          (loc) => loc.mapAddress !== prevLocation.mapAddress
        ),
        {
          mapAddress: values.mapAddress,
          lat: values.lat,
          lng: values.lng,
        },
      ],
    };

    dispatch(updateServiceProviderDetails(dataToSend));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Location Details"
      loading={serviceProviderEditLoader }
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        {editablePropertyName === "mapAddress" && (
          // <GooglePlacesAutocomplete
          //   apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          //   selectProps={{
          //     styles: {
          //       option: (provided) => ({
          //         ...provided,
          //         color: "brown",
          //       }),
          //     },
          //     value: formik.values[editablePropertyName],
          //     onChange: (value) =>
          //       formik.setFieldValue(editablePropertyName, value),
          //   }}
          // />
          <GooglePlaceInputCNO
              address={formik.values.mapAddress}
              onSelectAddress={(address) => {
                formik.setFieldValue("mapAddress", address.address);
                formik.setFieldValue("lat", address.lat);
                formik.setFieldValue("lng", address.lng);
              }}
              label={"Service Location"}
              
            />
        )}

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  );
}

function ServiceProviderLocationDetails({ profile,isActiveMember }) {
  let currencyType = localStorage.getItem("currencyType");
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const styles = useStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const [editablePropertyName, setEditablePropertyName] = useState(null);
  const [isContactDetailsEditable, setIsContactDetailsEditable] =
    useState(false);
  const [location, setLocation] = useState(null);

  return (
    <div className={styles.container_UserWorkingDetails}>
      <Typography className={styles.title}>{`Working Location(s)`}</Typography>

      <div className={styles.listing}>
        <div>
          {profile.location.length > 0 ? (
            profile.location.map((loc, index) => (
              <div
                className={styles.row}
                onClick={() => {
                  setIsContactDetailsEditable(true);
                  setEditablePropertyName("mapAddress");
                  setLocation(loc);
                }}
              >
                <Icon path={mdiMapMarkerRadiusOutline} size="1.5rem" className={styles.icon} />
                <Typography key={index}>{loc.mapAddress}</Typography>
              </div>
            ))
          ) : (
            <Typography>{"Click to add Address"}</Typography>
          )}
        </div>
      </div>

      
      {isContactDetailsEditable && isUserAdmin && isActiveMember && (
        <EditLocationDetails
          open={isContactDetailsEditable}
          onClose={() => setIsContactDetailsEditable(false)}
          profile={profile}
          editablePropertyName={editablePropertyName}
          prevLocation={location}
        />
      )}
    </div>
  );
}

export default ServiceProviderLocationDetails;
