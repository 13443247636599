import { makeStyles, useMediaQuery } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useEffect } from 'react';
import { mdiReload, mdiRestore } from '@mdi/js';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Icon from '@mdi/react';
import { useGlobalStore } from '../../providers/GlobalProvider';
import { TOGGLE_SEARCH } from '../../utils/actions';
import HeadingBar from '../HeadingBar/HeadingBar';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import UserSuggestionOption from '../UserSuggestionOption/UserSuggestionOption';
import clsx from 'clsx';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMemberSuggest, fetchTaskSuggest } from '../../actions/suggestionsActions';
import { resetJobSearch, setJobSearchManager, setJobTimesFilter } from '../../actions/jobActions';
import JobChipsFilter from '../JobChipsFilter';
import { JobTimesFilter } from '../../utils/mappings';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';

function CalendarSearchSidebar({ setPage }) {

  const { dispatchGlobal } = useGlobalStore();

  const dispatch = useDispatch();
  const suggestions = useSelector(state => state.suggestions);

  const { jobSearchParams } = useSelector(state => state.jobs);

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const handleClose = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false
    });
  };

  const validationSchema = yup.object({
    job: yup.object().nullable(),
    categories: yup.array(),
    assignedUsers: yup.object().nullable(),
    startDate: yup.date().nullable(),
    endDate: yup.date().nullable()
  });

  const handleJobsearch = (values) => {
    handleClose();
    formik.resetForm();
    setPage(0);
  };


  const formik = useFormik({
    initialValues: {
      searchText: '',
      categories: [],
      assignedUsers: null,
      startDate: null,
      endDate: null
    },
    validationSchema,
    onSubmit: (values) => handleJobsearch(values)
  });

  useEffect(() => {

    formik.setValues(jobSearchParams);

  }, [jobSearchParams]);

  const handleJobSearchOnChange = (params) => {
    dispatch(setJobSearchManager(params));
    setPage(0);
  };

  const handleResetJobFilter = () => {

    formik.resetForm();

    formik.setFieldValue("startDate", null);
    formik.setFieldValue("endDate", null);

    dispatch(resetJobSearch());

    dispatch(setJobTimesFilter(JobTimesFilter.None));

  };

  console.log("======isdirty=====", formik.dirty);
  console.log("======isdirty==values===", formik.values);
  console.log("======isdirty==initial===", formik.initialValues);

  const styles = useStyles({ tillTablet });
  return (
    <div className={styles.container}>

      <HeadingBar
        title={formik.dirty ? "Reset" : "Search"}
        icon={formik.dirty ? mdiRestore : null}
        onHeaderClick={handleResetJobFilter}
        onClose={formik.dirty ? undefined : handleClose}
      />

      <form className={styles.content} onSubmit={formik.handleSubmit}>
        {
          tillTablet &&
          <AutocompleteCNO
            label='Select Job'
            name='job'
            placeholder='Search Job...'
            formik={formik}
            data={suggestions}
            optionLabel={x => x.title || ""}
            renderOption={option => <div style={{ color: 'orangered' }}>{option.title}</div>}
            // fullWidth
            onSuggestionClick={(value) => {
              const params = {
                ...jobSearchParams,
                job: value
              }
              handleJobSearchOnChange(params);
            }}
            onChange={(value) => {
              if (value) {
                dispatch(fetchTaskSuggest(value));
              }
            }}
          />
        }
        <div className={styles.jobTypeChips}>
          <JobChipsFilter formik={formik} onChipToggle={() => setPage(0)} />
        </div>
        <AutocompleteCNO
          label='Select Team Member'
          name='assignedUsers'
          placeholder='Select User'
          formik={formik}
          data={suggestions}
          onSuggestionClick={(value) => {
            const params = {
              ...jobSearchParams,
              assignedUsers: value ? value : null
            };
            handleJobSearchOnChange(params);
          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchMemberSuggest(value));
            }
          }}
          emptyValue={{}}
          optionLabel={x => x.title || ""}
          renderOption={option => <UserSuggestionOption user={option} />}
        // fullWidth
        />
        <DateTimePickerCNO
          dateName="startDate"
          datePlaceholder="Start Date"
          datePickerLabel='Start Date'
          onDateChange={date => {
            formik.setFieldTouched('startDate', true);
            formik.setFieldValue('startDate', date);
            const params = {
              ...jobSearchParams,
              startDate: date
            };
            handleJobSearchOnChange(params);
            dispatch(setJobTimesFilter(JobTimesFilter.None));
          }}

          formik={formik}
          iconColor="red"
          isRequired={false}
        />

        <DateTimePickerCNO
          dateName="endDate"
          datePlaceholder="End Date"
          datePickerLabel='End Date'
          onDateChange={date => {
            formik.setFieldTouched('endDate', true);
            formik.setFieldValue('endDate', date);
            const params = {
              ...jobSearchParams,
              endDate: date
            };
            handleJobSearchOnChange(params);
            dispatch(setJobTimesFilter(JobTimesFilter.None));
          }}

          formik={formik}
          minDate={formik.values.startDate}
          iconColor="green"
          isRequired={false}
        />

        {
          tillTablet &&
          <FormActionButtons
            rightText="Search"
            leftClick={handleClose}
          />
        }
      </form>
    </div>
  )
}

export default CalendarSearchSidebar

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    '&>*:first-child': {
      height: ({ tillTablet }) => tillTablet ? 'auto' : 68
    }
  },
  title: {
    borderBottom: `1px solid ${theme.colors.HEADER_BOTTOM_COLOR}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  content: {
    // marginTop: theme.spacing(2),
    '&>div': {
      marginTop: theme.spacing(2),
      '&:first-child': {
        marginTop: 0
      }
    }
  },
  jobTypeChips: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 132,
    overflowY: 'auto',
    '&>*': {
      margin: 4
    },
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#5e5e5e',
      borderRadius: 10,
    },
  },
  picker: {
    '& .MuiInputBase-root': {
      background: theme.colors.INPUT_BACKGROUND_COLOR,
      color: theme.colors.INPUT_TEXT_COLOR,
    },
    '& .MuiIconButton-label': {
      color: theme.colors.INPUT_TEXT_COLOR,
    }
  },
  start: {
    '& .MuiSvgIcon-root': {
      color: theme.colors.JOB_START_DATE_COLOR
    }
  },
  end: {
    '& .MuiSvgIcon-root': {
      color: theme.colors.JOB_END_DATE_COLOR
    }
  },
}))