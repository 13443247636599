import React, { useEffect, useRef } from 'react';
import { Button, Chip, Dialog, makeStyles, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import HeadingBar from '../HeadingBar';
import { SnapItem, SnapList, useDragToScroll } from 'react-snaplist-carousel';
import ChipCNO from '../../library/ChipCNO';
import InputCNO from '../../library/InputCNO';
import { useFormik } from 'formik';
import { mdiContentSave, mdiFileCancel, mdiFormatText, mdiPencilBoxOutline, mdiPlus } from '@mdi/js';
import AddCustomer from '../AddCustomer/AddCustomer';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';
import { addAppointment, toggleAddAppointmentDialog } from '../../actions/appointmentActions';
import { fetchCustomerSuggest, fetchMemberSuggest, resetSuggestions } from '../../actions/suggestionsActions';
import FlexibleSpaceCNO from '../../library/FlexibleSpaceCNO/FlexibleSpaceCNO';
import FormBox from '../FormBox/FormBox';
import { toggleAddCustomerDialog } from '../../actions/customerActions';
import * as yup from 'yup';
import InputLabelCNO from '../../library/InputLabelCNO';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';
import UserSuggestionOption from '../UserSuggestionOption/UserSuggestionOption';
import { Autocomplete } from '@material-ui/lab';
import GooglePlaceInputCNO from '../../library/GooglePlaceInputCNO/GooglePlaceInputCNO';
import { useDebouncedFormik, useFormikErrors } from '../../hooks';
import { getTitleFromCustomer, validateStartDateEndDate } from '../../utils/Helpers';
import moment from 'moment';
import { setAddModalsCalendarDates } from '../../actions/calendarActions';
import { useHistory } from 'react-router-dom';
import PhoneInputCNO from '../../library/PhoneInputCNO';
import { mdiEmail } from '@mdi/js';

function AddMeeting() {

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const theme = useTheme();
  const styles = useStyles({ tillTablet });

  let localCategories = localStorage.getItem('categories');
  localCategories = localCategories ? JSON.parse(localCategories) : [];

  const { isAddCustomerDialog } = useSelector(state => state.customers);

  const history = useHistory();

  const dispatch = useDispatch();

  const suggestions = useSelector(state => state.suggestions);
  const { isAddAppointmentDialog, addAppointmentLoader } = useSelector(state => state.appointments);
  const { addModalsCalendarDates } = useSelector(state => state.calendar);
  const {
    startDate,
    startTime,
    endDate,
    endTime,
  } = addModalsCalendarDates;

  console.log("======addModalsCalendarDates======", addModalsCalendarDates);

  const handleClose = () => {
    localStorage.removeItem('addAppointment');
    dispatch(toggleAddAppointmentDialog(false));
  };

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .required('Appointment title is required!'),
      visitor: yup
      .string()
      .required('Appointment title is required!'),
      mobile: yup
      .string()
      .required('Appointment title is required!'),
      email: yup
      .string(),
      
    description: yup
      .string(),
    startDate: yup
      .string()
      .required('Appointment start date is required!'),
    endDate: yup
      .string()
      .required('Appointment end date is required!')
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      ),
    startTime: yup
      .string()
      .required('Appointment start time is required!'),
    endTime: yup
      .string()
      .required('Appointment end time is required!')
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      ),
    categories: yup
      .array()
      .required('Category is required!'),
    customer: yup
      .object()
      .required('Contact person is required!'),
    appointmentAssignments: yup
      .array(),
    mapAddress: yup
      .string().required("Location is required.")
  });

  const now = new Date();

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      categories: [],
      startDate: startDate || now,
      startTime: startTime || now,
      endDate: endDate || now,
      endTime: endTime || moment(now).add(30, 'minute').toDate(),
      customer: '',
      status: 1,
      mediaLinks: [],
      appointmentAssignments: [],
      mapAddress: "",
      visibility: 0,
      mobile:"",
      email:"",
      visitor:"",
    },
    validationSchema,
    onSubmit: values => handleAddAppointment(values)
  });

  useFormikErrors(formik);
  useDebouncedFormik(formik, 'addAppointment');

  useEffect(() => {
    return () => {
      dispatch(setAddModalsCalendarDates({
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null
      }));
    };
  }, []);

  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : [];
  const assignedUser = [...formik.values.appointmentAssignments, authUser];

  const unSelectedSuggestions = () => suggestions.filter(item => !assignedUser.some(assign => assign.id === item.id));

  const onAddSuccess = (appointment) => {
    formik.resetForm();
    handleClose();
    // history.push(`/appointment/${appointment.id}`);
  };

  const handleAddAppointment = async (values) => {
    console.log("Add Appointment values: ", values);

    let assignedUsers = authUser ? [...values.appointmentAssignments, authUser] : values.appointmentAssignments;

    console.log("Add Appointment assignedUsers: ", assignedUsers);
    console.log("Add Appointment authUser: ", authUser);

    const payload = {
      ...values,
      customerText: values.customer.title,
      customerId: values.customer.id,
      appointmentAssignments: assignedUsers.map(item => (
        {
          staffId: item.id,
          staffText: item.title || `${item.firstName} ${item.lastName}` || item.staffText,
          startDate: values.startDate,
          startTime: values.startTime,
          endDate: values.endDate,
          endTime: values.endTime
        }
      )),
    };

    console.log("Add Appointment payload: ", payload);
    console.log("z1",payload)
    await dispatch(addAppointment(payload, onAddSuccess));
    console.log("z2",payload)


  };

  console.log("====formikValues========", formik.values);

  return (
    <Dialog open={isAddAppointmentDialog} classes={{ paper: styles.paper }} className={styles.container}>

      {
        addAppointmentLoader &&
        <FullScreenLoaderCNO />
      }

      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <HeadingBar
          title="Add Appointment"
          onClose={handleClose}
        />

        {
          isAddCustomerDialog &&
          <AddCustomer
            onAdd={(newCustomer) => {
              formik.setFieldValue('customer', { ...newCustomer, title: `${newCustomer.firstName} ${newCustomer.lastName}` });
              formik.setFieldValue('mapAddress', newCustomer.mapAddress);
              formik.setFieldValue('lat', newCustomer.lat);
              formik.setFieldValue('lng', newCustomer.lng);
            }}
            jobLocation={formik.values.mapAddress}
          />
        }

        <FormBox className={styles.sectionContainer}>
          <InputCNO
            name='visitor'
            label='Visitor Name'
            formik={formik}
            placeholder='Enter Visitor Name'
            icon={mdiFormatText}
            fullWidth
            isRequired
          />
          <PhoneInputCNO
            value={formik.values.mobile}
            label="Mobile"
            onChange={(value) => formik.setFieldValue("mobile", value)}
            containerStyle={{ marginTop: 10, marginBottom: 10 }}
            showHelperText={formik.errors.mobile}
            helperText={formik.errors.mobile}
            onValidatePhone={(error) => {
              if (!!error) formik.setFieldError("mobile", error);
            }}
            required
          />

          <InputCNO
            name="email"
            label="Email"
            type="email"
            formik={formik}
            placeholder="Email"
            icon={mdiEmail}
            fullWidth
          />

          <div className={styles.customerNameWrapper} style={{ alignItems: formik.errors.customer ? 'center' : 'flex-end' }}>
            <AutocompleteCNO
              name='customer'
              label='Person to meet'
              formik={formik}
              placeholder='Search Customer'
              data={suggestions}
              optionLabel={x => x.title || ""}
              renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
              onSuggestionClick={(value) => {

                if (value) {
                  formik.setValues({
                    ...formik.values,
                    customer: value,
                    mapAddress: value.mapAddress,
                    lat: value.lat,
                    lng: value.lng,
                    title: getTitleFromCustomer(value, formik.values.title)
                  });
                }

              }}
              onChange={(value) => {
                if (value) {
                  dispatch(fetchCustomerSuggest(value));
                }
              }}
              isRequired
            />
            <Icon
              path={mdiPlus}
              size='43px'
              color='goldenrod'
              onClick={() => {
                dispatch(toggleAddCustomerDialog(true))
              }}
            />
          </div>

          <div style={{ marginTop: 8 }}>
            <GooglePlaceInputCNO
              address={formik.values.mapAddress}
              onSelectAddress={(address) => {
                formik.setFieldValue("mapAddress", address.address);
                formik.setFieldValue("lat", address.lat);
                formik.setFieldValue("lng", address.lng);
              }}
              label="Address"
              isRequired={true}
            />
          </div>

          <div className={styles.assignToWrapper}>
            <InputLabelCNO label={'Select Team Member'} />
            <Autocomplete
              className={styles.textField}
              classes={{
                paper: styles.assignPaper,
                noOptions: styles.noOptions,
                popper: styles.popper
              }}
              multiple
              forcePopupIcon={false}
              options={unSelectedSuggestions()}
              //closeIcon={() => null}
              getOptionLabel={option => option.title}
              renderOption={option => <UserSuggestionOption user={option} />}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    key={index}
                    variant="outlined"
                    label={option.title}
                    {...getTagProps({ index })}
                    className={styles.chip}
                  />
                ))
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="Select Team Member" />
              )}
              onChange={(e, newValue) => {
                formik.setFieldValue("appointmentAssignments", newValue);
              }}
              onInputChange={(e) => {
                if (e && e.target.value) {
                  dispatch(fetchMemberSuggest(e.target.value));
                }
              }}
              onBlur={() => {
                dispatch(resetSuggestions());
              }}
            />
          </div>

        </FormBox>

        <FormBox>
          <div className={styles.sectionContainer}>
            <div>
              <InputLabelCNO label={'Category'} isRequired={true} />
              <SnapList ref={snapList} className={styles.jobChipSlider}>
                {
                  localCategories.map((category) => (
                    <SnapItem>
                      <ChipCNO
                        key={category.id}
                        icon={category.iconLink}
                        name={category.title}
                        isCategoryChip={true}
                        active={formik.values.categories[0] === category.id}
                        onClick={() => formik.setFieldValue('categories', [category.id])}
                      />
                    </SnapItem>
                  ))
                }
              </SnapList>
            </div>
            <InputCNO
              name='title'
              label='Appointment Purpose'
              formik={formik}
              placeholder='Enter Purpose Of Meeting'
              icon={mdiFormatText}
              fullWidth
              isRequired
            />
            <InputCNO
              name='description'
              label='Appointment Description'
              formik={formik}
              placeholder='Enter other informations'
              fullWidth
              multiline
            />


            <DateTimePickerCNO
              datePickerLabel='Start Date'
              dateName={'startDate'}
              datePlaceholder={"Start Date"}
              disablePast={true}
              onDateChange={date => formik.setFieldValue('startDate', date)}

              timePlaceholder={"Start Time"}
              timeName={"startTime"}
              onTimeChange={date => {

                formik.setValues({
                  ...formik.values,
                  startTime: date,
                  endTime: moment(date).add(30, 'minute').toDate()
                });

              }}

              formik={formik}
              iconColor={'red'}
            />

            <DateTimePickerCNO
              datePickerLabel='End Date'
              dateName={'endDate'}
              datePlaceholder={"End Date"}
              minDate={formik.values.startDate}
              onDateChange={date => formik.setFieldValue('endDate', date)}

              timePlaceholder={"End Time"}
              timeName={"endTime"}
              onTimeChange={date => formik.setFieldValue('endTime', date)}

              formik={formik}
              iconColor={'green'}
            />

          </div>
        </FormBox>

        <div className={styles.ctaWrapper}>

          <Button
            variant='contained'
            className={styles.left}
            startIcon={<Icon path={mdiFileCancel} size={"1rem"} color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR} />}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <FlexibleSpaceCNO />

          <Button
            type='submit'
            variant='contained'
            className={styles.draftBtn}
            startIcon={<Icon path={mdiPencilBoxOutline} size={"1rem"} color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR} />}
            onClick={() => formik.setFieldValue('status', 1)}
            disabled={(formik && (!formik.dirty || !formik.isValid))}
          >
            <pre>Save as Draft</pre>
          </Button>

          <Button
            type='submit'
            variant='contained'
            className={styles.right}
            startIcon={<Icon path={mdiContentSave} size={"1rem"} color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR} />}
            onClick={() => formik.setFieldValue('status', 2)}
            disabled={(formik && (!formik.dirty || !formik.isValid))}
          >
            <pre>Save</pre>
          </Button>
        </div>

      </form>
    </Dialog>
  )
}

export default AddMeeting;

const useStyles = makeStyles(theme => ({
  container: {
  },
  paper: {
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    borderRadius: 10,
    width: '70vw',
    maxWidth: '90vw',
    margin: ({ tillTablet }) => tillTablet ? 8 : 32
  },
  form: {
    padding: '20px',
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    overflowX: 'hidden',
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`,
    '&>*': {
      marginBottom: theme.spacing(3)
    },
  },

  sectionContainer: {
    '&>*': {
      marginTop: theme.spacing(2)
    }
  },
  jobChipSlider: {
    display: 'flex',
    padding: '5px 0',
    // width: 'calc(100vw - 85px)',
    // overflowX: 'auto',
    '&>*:not(:first-child)': {
      marginLeft: 15
    }
  },
  timeStampWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  picker: {
    '& .MuiInputBase-root': {
      background: 'white',
      color: '#444'
    },
    '& .MuiIconButton-label': {
      color: '#444'
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: 5,
    },
    '& .MuiIconButton-root': {
      paddingLeft: 0,
      paddingRight: 5,
    }
  },
  start: {
    '& .MuiSvgIcon-root': {
      color: '#BE0A02'
    }
  },
  end: {
    '& .MuiSvgIcon-root': {
      color: '#3F8A02'
    }
  },
  time: {
    marginLeft: theme.spacing(2),
    maxWidth: '42%',
  },

  customerNameWrapper: {
    display: 'flex',
    '&>*:first-child': {
      flex: 1
    },
  },
  jobLocationLabel: {
    marginBottom: theme.spacing(1)
  },

  chip: {
    background: theme.palette.background.light
  },
  rateTypeWrapper: {
    '&>*:first-child': {
      marginBottom: theme.spacing(1)
    }
  },
  rateTypeChips: {
    display: 'flex',
    '&>*': {
      marginRight: theme.spacing(1)
    }
  },

  ctaWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(6),
    '& > *': {
      marginLeft: 4
    }
  },
  left: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    // marginRight: theme.spacing(4),
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
  },
  right: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
  },
  draftBtn: {
    background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CANCEL_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_SECONDARY_BUTTON_TEXT_COLOR,
    marginRight: theme.spacing(2)
  },
  textField: {
    borderRadius: 5,
    '& input': {
      background: 'white',
      color: '#444',
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: '#444'
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": "#444",
      caretColor: "#444",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 14
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0
    },
    '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      padding: 0
    }
  },
  assignPaper: {
    background: 'white !important',
    '& ::-webkit-scrollbar': {
      display: 'none'
    }
  },
  noOptions: {
    color: '#BE0A02'
  },
  popper: {
    zIndex: 1301
  },
  assignToWrapper: {
    display: 'flex',
    marginTop: 8,
    flexDirection: 'column',
    '&>*:first-child': {
      flex: 1,
      marginBottom: 8
    }
  },

}))
