import { v4 } from "uuid";
import { getItemFromArray } from "../../../utils/Helpers";

export const AutoCompleteSuggestionTypes = {
  ids: {
    Employee: 1,
    Customer: 2,
    Appointment: 3,
    Quotation: 4,
    Job: 5,
    Invoice: 6
  },
  entities: [
    { id: 1, value: 'employee', label: 'Employee' },
    { id: 2, value: 'customer', label: 'Customer' },
    { id: 3, value: 'appointment', label: 'Appointment' },
    { id: 4, value: 'quotation', label: 'Quotation' },
    { id: 5, value: 'job', label: 'Job' },
    { id: 6, value: 'invoice', label: 'Invoice' },
  ],
  getItem: getItemFromArray
}

export const DateTimePickerType = {
  ids: {
    Date: 1,
    Time: 2,
    DateTimeBoth: 3,
  },
  entities: [
    { id: 1, title: "Date", icon: "calendar-range" },
    { id: 2, title: "Time", icon: "clock" },
    { id: 3, title: "Both Date & Time", icon: "calendar-clock" },
  ],
  getItem: getItemFromArray
}

export const FormFieldsTypes = {
  ids: {
    Text: 1,
    Chips: 2,
    Select: 3,
    AutoComplete: 4,
    DateTime: 5,
    Address: 6,
    Switch: 7,
    CheckBox: 8
  },
  entities: [
    {
      id: 1,
      value: 'text',
      label: 'Text',
    },
    {
      id: 2,
      value: 'chips',
      label: 'Chips',
      options: [{ id: v4(), title: 'General', icon: 'shape-outline' }]
    },
    {
      id: 3,
      value: 'select',
      label: 'Select',
      options: [{ id: v4(), label: '', value: '' }]
    },
    {
      id: 4,
      value: 'autocomplete',
      label: 'AutoComplete',
      suggestionType: AutoCompleteSuggestionTypes.ids.Employee
    },
    {
      id: 5,
      value: 'datetime',
      label: 'Date Time',
      dateTimePickerType: DateTimePickerType.ids.DateTimeBoth
    },
    {
      id: 6,
      value: 'address',
      label: 'Address',
    },
    {
      id: 7,
      value: 'switch',
      label: 'Switch',
    },
    {
      id: 8,
      value: 'checkbox',
      label: 'CheckBox',
    },
  ],
  getItem: getItemFromArray
}

export const FormFeatures = {
  id: {
    Job: 1,
    Quotation: 2,
    Appointment: 3,
    Invoice: 4,
    Events: 5
  },
  entities: [
    { id: 1, value: 1, label: 'Job', icon: "briefcase" },
    { id: 2, value: 2, label: 'Quotation', icon: "quora" },
    { id: 3, value: 3, label: 'Appointment', icon: "clock-check-outline" },
    { id: 4, value: 4, label: 'Invoice', icon: "file-table" },
    { id: 5, value: 5, label: 'Events', icon: "calendar-star" },
  ],
  getItem: getItemFromArray
}

export const FormStatus = {
  id: {
    Draft: 1,
    Published: 2,
  },
  entities: [
    { id: 1, title: 'Draft', icon: 'file-lock-open-outline' },
    { id: 2, title: 'Published', icon: 'file-lock-outline' },
  ],
  getItem: getItemFromArray
}

export const AddDynamicFormsDataMode = {
  ids: {
    Add: 1,
    View: 2,
    Edit: 3,
  },
  entities: [
    { id: 1, title: "New Entry", icon: "plus" },
    { id: 2, title: "View Entry", icon: "eye" },
    { id: 3, title: "Edit Entry", icon: "pencil" },
  ],
  getItem: getItemFromArray
}
