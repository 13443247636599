import {
  SET_INVOICE_ITEMS,
  SET_INVOICE_ITEM_BY_ID,
  TOGGLE_ADD_INVOICE_ITEM_DIALOG,
  SET_INVOICE_ITEM_LOADERS,
  SET_IS_NEW_INVOICE_ITEM_ADDED
} from '../actions/invoiceItemActions';

const initialState = {
  invoiceItems: [],
  invoiceItemById: null,
  isAddInvoiceItemDialog: false,
  invoiceItemsLoader: false,
  invoiceItemDetailLoader: false,
  addLoader: false,
  editLoader: false,
  deleteLoader: false,
  isNewInvoiceItemAdded: false
};

export function invoiceItemReducer(state = initialState, { type, payload }) {


  switch (type) {

    case SET_IS_NEW_INVOICE_ITEM_ADDED:
      state = {
        ...state,
        isNewInvoiceItemAdded: payload
      };
      return state;

    case SET_INVOICE_ITEMS:
      state = {
        ...state,
        invoiceItems: payload
      };
      return state;

    case SET_INVOICE_ITEM_BY_ID:
      state = {
        ...state,
        invoiceItemById: payload
      };
      return state;

    case TOGGLE_ADD_INVOICE_ITEM_DIALOG:
      state = {
        ...state,
        isAddInvoiceItemDialog: payload
      };
      return state;

    case SET_INVOICE_ITEM_LOADERS:

      const { loaderName, loaderValue } = payload;

      state = {
        ...state,
        [loaderName]: loaderValue
      };
      return state;

    default:
      return state;
  }

}