import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_UserTrackingDetailsCard: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    background: theme.colors.PROFILE_CARD_BACKGROUND_COLOR,
    padding: theme.spacing(4),
    borderRadius: 20
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.colors.PROFILE_CARD_TITLE_COLOR,
  },
  chipsWrapper: {
    display: 'flex',
    // background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    '&>*': {
      marginRight: theme.spacing(2)
    }
  }
}));