import { Typography } from "@material-ui/core";
import {
  mdiFacebook,
  mdiFormatText,
  mdiLinkedin,
  mdiSkype,
  mdiTwitter,
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogCNO from "../../library/DialogCNO";
import { useStyles } from "./UserSocialDetailsCardStyles";
import * as yup from "yup";
import { useFormik } from "formik";
import { updateEmployeeDetails } from "../../actions/employeeActions";
import InputCNO from "../../library/InputCNO";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import RightsManager from "../../utils/RightsManager";
import { updateCustomerDetails } from "../../actions/customerActions";
import { updateServiceProviderDetails } from "../../actions/serviceProviderActions";

function EditSocialDetails({
  open,
  onClose,
  profile,
  editablePropertyName,
  isEmployee,
  isServiceProvider,
  localProfilePicUrl,
}) {
  const styles = useStyles();

  const label = {
    facebook: "Facebook",
    twitter: "Twitter",
    linkedIn: "LinkedIn",
    skype: "Skype",
  };
  const placeholder = {
    facebook: "Enter facebook id...",
    twitter: "Enter twitter id...",
    linkedIn: "Enter linkedIn id...",
    skype: "Enter skype id...",
  };

  const dispatch = useDispatch();
  const {
    allEmployeesLoader: { editLoader: employeeEditLoader },
  } = useSelector((state) => state.employees);
  const {
    allCustomersLoader: { editLoader: customerEditLoader },
  } = useSelector((state) => state.customers);

  const validationSchema = yup.object({
    [editablePropertyName]: yup.string().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      [editablePropertyName]: profile[editablePropertyName],
    },
    validationSchema,
    onSubmit: (values) => handleUpdate(values),
  });

  const handleUpdate = (values) => {
    const dataToSend = {
      ...profile,
      [editablePropertyName]: values[editablePropertyName],
      localProfilePicUrl: localProfilePicUrl,
    };

    if (isEmployee) {
      dispatch(updateEmployeeDetails(dataToSend));
    } else if(isServiceProvider){
      dispatch(updateServiceProviderDetails(dataToSend));
    } else {
      dispatch(updateCustomerDetails(dataToSend));
    }

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Social Details"
      loading={employeeEditLoader || customerEditLoader}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <InputCNO
          name={editablePropertyName}
          label={label[editablePropertyName]}
          formik={formik}
          placeholder={placeholder[editablePropertyName]}
          icon={mdiFormatText}
          fullWidth
        />
        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  );
}

function UserSocialDetailsCard({
  profile,
  isActiveMember,
  isEmployee,
  isServiceProvider,
  localProfilePicUrl,
}) {
  const styles = useStyles();

  const isUserAdmin = RightsManager.isAdmin();
  const isUserMember = RightsManager.isMember();

  const [editablePropertyName, setEditablePropertyName] = useState(null);
  const [isSocialDetailsEditable, setIsSocialDetailsEditable] = useState(false);

  return (
    <div className={styles.container_UserSocialDetailsCard}>
      <Typography className={styles.title}>Social Network</Typography>
      <div className={styles.content}>
        <div
          className={styles.row}
          onClick={() => {
            setIsSocialDetailsEditable(true);
            setEditablePropertyName("facebook");
          }}
        >
          <Icon path={mdiFacebook} size="1.5rem" className={styles.icon} />
          <Typography>{profile.facebook || "Click to add Facebook"}</Typography>
        </div>
        <div
          className={styles.row}
          onClick={() => {
            setIsSocialDetailsEditable(true);
            setEditablePropertyName("twitter");
          }}
        >
          <Icon path={mdiTwitter} size="1.5rem" className={styles.icon} />
          <Typography>{profile.twitter || "Click to add Twitter"}</Typography>
        </div>
        <div
          className={styles.row}
          onClick={() => {
            setIsSocialDetailsEditable(true);
            setEditablePropertyName("linkedIn");
          }}
        >
          <Icon path={mdiLinkedin} size="1.5rem" className={styles.icon} />
          <Typography>{profile.linkedIn || "Click to add Linkedin"}</Typography>
        </div>
        <div
          className={styles.row}
          onClick={() => {
            setIsSocialDetailsEditable(true);
            setEditablePropertyName("skype");
          }}
        >
          <Icon path={mdiSkype} size="1.5rem" className={styles.icon} />
          <Typography>{profile.skype || "Click to add Skype"}</Typography>
        </div>
      </div>

      {isSocialDetailsEditable &&
        (isUserAdmin || isUserMember) &&
        isActiveMember && (
          <EditSocialDetails
            open={isSocialDetailsEditable}
            onClose={() => setIsSocialDetailsEditable(false)}
            profile={profile}
            editablePropertyName={editablePropertyName}
            isEmployee={isEmployee}
            isServiceProvider={isServiceProvider}
            localProfilePicUrl={localProfilePicUrl}
          />
        )}
    </div>
  );
}

export default UserSocialDetailsCard;
