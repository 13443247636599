import { Divider, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment, useEffect } from 'react';
import { useDashBoardStyles } from './Dashboard.styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,

  LineElement,
} from 'chart.js';
import {
  DoughnutChart,
  PieChart,
  ReportsBarChart,
  ReportsLineChart
} from '../../library/Charts';
import { ChartType, InvoiceStatus, PaymentByCustomerStatus, QuotationStatus, TaskStatus, Widgets } from '../../utils/mappings';
import Icon from '@mdi/react';
import { mdiEye, mdiRestore } from '@mdi/js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAppointments, setAppointmentSearchParams } from '../../actions/appointmentActions';
import moment from 'moment';
import { setJobSearchParams, setTodos } from '../../actions/jobActions';
import { setQuotations, setQuotationSearchParams } from '../../actions/quotationActions';
import { setInvoices, setInvoiceSearchParams } from '../../actions/invoiceActions';
import { toggleWorkingTodayModal } from '../../actions/trackSearchActions';
import { fetchWidgetById } from '../../actions/dashboardActions';
import WidgetSkeleton from './WidgetSkeleton';
import LinkCNO from '../../library/LinkCNO';
import ResponsiveLinesCNO from '../../library/ResponsiveLinesCNO';
import { formatTimeWithTZ } from '../../utils/Helpers';
import Moment from 'react-moment';
import { calendarStrings, TIME_FORMAT } from '../../utils/formatDate';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,

  PointElement,
  LineElement,
);

const StatsCard = React.forwardRef((
  {
    children,
    style = {},
    className = "",

    // card props
    id,
    title,
    defaultLayout,

    ...props
  },
  ref) => {

  console.log("==defaultLayout====", { defaultLayout });

  const theme = useTheme();
  const styles = useDashBoardStyles();

  const isMobile = useMediaQuery('(max-width: 475px)')

  const history = useHistory();

  const dispatch = useDispatch();
  const dashboardStates = useSelector(state => state.dashboard);

  const widget = dashboardStates[`widget_${id}`];
  const widgetLoader = dashboardStates[`widget_${id}_loader`];

  useEffect(() => {
    dispatch(fetchWidgetById({ id }));
  }, [id]);

  console.log("==== StatsCard dashboardStates", dashboardStates);
  console.log("==== StatsCard widget", style, widget);

  const renderChart = () => {

    if (!widget.showChart || !widget.chart) return null;

    switch (widget.chart.type) {
      case ChartType.BarChart:
        return (
          <Fragment>
            <ReportsBarChart
              chart={widget.chart.chartData}
              bgColor={widget.chart.bgColor}
            />
          </Fragment>
        );

      case ChartType.LineChart:
        return (
          <Fragment>
            <ReportsLineChart
              chart={widget.chart.chartData}
              bgColor={widget.chart.bgColor}
            />
          </Fragment>
        );

      case ChartType.PieChart:
        return (
          <Fragment>
            <PieChart
              chart={widget.chart.chartData}
              bgColor={widget.chart.bgColor}
            />
          </Fragment>
        );

      case ChartType.DoughnutChart:
        return (
          <Fragment>
            <DoughnutChart
              chart={widget.chart.chartData}
              bgColor={widget.chart.bgColor}
            />
          </Fragment>
        );

      default:
        return null;
    }

  }

  const statValuesActions = async () => {

    const { data = [] } = widget;

    console.log("statValuesActions: widget: data: ", data);

    switch (id) {
      case Widgets.Employees:
        history.push("/map");
        dispatch(toggleWorkingTodayModal(true));
        break;

      case Widgets.OngoingJobs:
        const ongoingJobSearchValues = {
          customer: null,
          startDate: moment().startOf('day').toISOString(),
          endDate: moment().endOf('day').toISOString(),
          assignedUsers: null,
          categories: [],
          searchText: "",
          statuses: [TaskStatus.InProgress]
        };
        await dispatch(setJobSearchParams(ongoingJobSearchValues));
        dispatch(setTodos(data));
        history.push("/home");
        break;

      case Widgets.AppointmentsToday:
        history.push("/appointments");
        const appointmentSearchValues = {
          customer: null,
          categories: [],
          appointment: null,
          startDate: moment().startOf('day').toISOString(),
          endDate: moment().endOf('day').toISOString(),
          assignments: null
        };
        await dispatch(setAppointmentSearchParams(appointmentSearchValues));
        dispatch(setAppointments(data));
        break;

      case Widgets.QuotationDue:
        const quotationSearchValues = {
          customer: null,
          categories: [],
          quotation: null,
          offerNumber: "",
          sentOn: null,
          validForDays: null,
          statuses: [QuotationStatus.Sent],
          isToday: true,
          pageSize: 100
        };
        await dispatch(setQuotationSearchParams(quotationSearchValues));
        dispatch(setQuotations(data));
        history.push("/quotations");
        break;

      case Widgets.InvoiceDue:
        const invoiceSearchValues = {
          customer: null,
          categories: [],
          invoice: null,
          invoiceNumber: "",
          billDate: null,
          dueInDays: null,
          statuses: [InvoiceStatus.Sent, InvoiceStatus.SentAccepted],
          paymentByCustomerStatuses: [PaymentByCustomerStatus.Empty, PaymentByCustomerStatus.Open, PaymentByCustomerStatus.Partial, PaymentByCustomerStatus.Cancelled],
          isToday: true
        };
        await dispatch(setInvoiceSearchParams(invoiceSearchValues));
        dispatch(setInvoices(data));
        history.push("/invoices");
        break;

      case Widgets.UpcomingJobs:
        history.push("/home");
        const upcomingJobsSearchValues = {
          customer: null,
          startDate: moment().add(1, 'day').startOf('day').toISOString(),
          endDate: null,
          assignedUsers: null,
          categories: [],
          searchText: "",
          statuses: []
        };
        dispatch(setJobSearchParams(upcomingJobsSearchValues));
        dispatch(setTodos(data));
        break;

      default:
        break;
    }

  }

  const refreshWidget = () => {
    dispatch(fetchWidgetById({ id: id }));
  }

  return (
    <div
      className={clsx(styles.statsCardContainer, className)}
      style={{
        padding: '1rem',
        width: '25%',
        minWidth: isMobile ? '100%' : 350,
        height: (widget && widget.showChart) ? 400 : 200,
        ...style,
      }}
      ref={ref}
      key={id}

      {...props}
    >
      {children}

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography style={{ fontSize: "1.2rem", color: theme.colors.DASHBOARD_TEXT_COLOR }}>
          {title}
        </Typography>
        <Icon
          path={mdiRestore}
          color={theme.colors.DASHBOARD_TEXT_COLOR}
          size="1.5rem"
          onClick={refreshWidget}
        />
      </div>

      {
        widgetLoader && <WidgetSkeleton showChart={widget && widget.showChart} />
      }

      {
        (widget && !widgetLoader) ? (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 'calc(100% - 1.2rem)' }}>
            {
              widget.statValues &&
              <Typography style={{ fontSize: "3.5rem", textAlign: 'center' }} onClick={() => statValuesActions(id)}>
                <span
                  style={{ color: (widget.statValues && widget.statValues.total) ? "green" : theme.colors.DASHBOARD_TEXT_COLOR }}
                  dangerouslySetInnerHTML={{ __html: widget.statValues.actual }}
                />
                {
                  (widget.statValues && widget.statValues.total !== undefined && widget.statValues.total !== null) &&
                  <Fragment>
                    <span>/</span>
                    <span>{widget.statValues.total}</span>
                  </Fragment>
                }
              </Typography>
            }

            {
              widget.listingData && (
                widget.data && Array.isArray(widget.data) && widget.data.length === 0 ? (
                  <Typography style={{ color: theme.colors.LIST_CARD_TITLE_COLOR, marginRight: '1rem' }} align='center'>
                    Not Found
                  </Typography>
                ) : (
                  <div style={{ marginTop: '1rem' }}>
                    {
                      widget.data.map((item) => {

                        const { listingData } = widget;
                        const { itemKeys, itemURL } = listingData;

                        return (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              borderBottom: `1px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
                              alignItems: 'center',
                              padding: '3px 0'
                            }}
                          >

                            <LinkCNO to={`/job/${item.id}`}>
                              <ResponsiveLinesCNO
                                text={item.title}
                                showComponentProp={false}
                                style={{ color: theme.colors.LIST_CARD_TITLE_COLOR, marginRight: '1rem' }}
                              />
                            </LinkCNO>

                            <LinkCNO to={`/job/${item.id}`} style={{ display: 'flex' }}>
                              <Icon
                                path={mdiEye}
                                color={theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR}
                                size="1.3rem"
                                title="View"
                              />
                            </LinkCNO>

                          </div>
                        )
                      })
                    }
                  </div>
                )
              )

            }

            {
              (widget.statDescriptions && widget.statDescriptions.length) &&
              <Fragment>
                <Divider style={{ margin: "1rem auto", width: '100%' }} />
                <div>
                  {
                    widget.statDescriptions.map(stat => (
                      <Typography style={{ fontSize: "1rem", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{stat.label}</span>
                        <span>{stat.value}</span>
                      </Typography>
                    ))
                  }
                </div>
              </Fragment>
            }

            {
              widget.chart
                ? <Divider style={{ margin: "1rem auto" }} />
                : null
            }

            {renderChart()}

          </div>
        ) : (
          null
        )
      }

    </div >
  )

})

export default StatsCard;