import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addQuotationItem, toggleAddQuotationItemDialog } from '../../actions/quotationItemActions';
import DialogCNO from '../../library/DialogCNO';
import FormActionButtons from '../FormActionButtons';
import * as yup from 'yup';
import FormBox from '../FormBox/FormBox';
import { Chip, makeStyles, TextField, Typography, useTheme } from '@material-ui/core';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { SnapItem, SnapList, useDragToScroll } from 'react-snaplist-carousel';
import ChipCNO from '../../library/ChipCNO';
import { RateType } from '../../utils';
import { Currency, CurrencyIcon, JobTargetType, JobTargetTypeIcon } from '../../utils/mappings';
import { mdiFormatText, mdiHours24, mdiLockClock } from '@mdi/js';
import InputCNO from '../../library/InputCNO';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import UserSuggestionOption from '../UserSuggestionOption/UserSuggestionOption';
import { fetchMemberSuggest, resetSuggestions } from '../../actions/suggestionsActions';
import { getMaterialIconString } from '../../utils/icons';
import * as icons from '@mdi/js';
import { combineDateAndTime } from '../../utils/Tracking';
import ConfirmationDialogCNO from '../../library/ConfirmationDialogCNO';
import { QuotationStatus } from '../../utils/mappings';
import { updateQuotation } from '../../actions/quotationActions';
import { toast } from 'react-toastify';
import InputLabelCNO from '../../library/InputLabelCNO';
import { useDebouncedFormik, useFormikErrors } from '../../hooks';
import { validateStartDateEndDate } from '../../utils/Helpers';
import { AppConstants } from '../../utils/AppConstants';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';

function AddQuotationItemDialog({ quotation }) {

  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : null;

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  let localCategories = localStorage.getItem('categories');
  localCategories = localCategories ? JSON.parse(localCategories) : [];

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  const dispatch = useDispatch();
  const { isAddQuotationItemDialog, addLoader, editLoader } = useSelector(state => state.quotationItems);
  const suggestions = useSelector(state => state.suggestions);

  const [isWithinRange, setIsWithinRange] = useState(true);
  // const [newQuotationDates, setNewQuotationDates] = useState({
  //   startDate: quotation.startDate,
  //   startTime: quotation.startTime,
  //   endDate: quotation.endDate,
  //   endTime: quotation.endTime
  // });

  const closeAddQuotationItemDialog = () => {

    localStorage.removeItem('addQuotationItem');

    dispatch(toggleAddQuotationItemDialog(false));
  }

  const validationSchema = yup.object({
    categories: yup.array().min(1, 'Category is required').required("Category is required"),
    title: yup.string().required('Title is required'),
    description: yup.string(),
    startDate: yup.date().required('Start Date is required'),
    startTime: yup.date().required('Start Time is required'),
    endTime: yup.date()
      .required('End Date is required')
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      ),
    endDate: yup.date()
      .required('End Time is required')
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      ),
    offerItemAssignments: yup.array(),
    rate: yup.string(),
    rateType: yup.number(),
    targetType: yup.number(),
    target: yup.string(),
    offerId: yup.string().required("Quotation is required for creating Quotation Item")
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      categories: [],
      status: QuotationStatus.Draft,
      startDate: AppConstants.DEFAULT_START_DATE,
      endDate: AppConstants.DEFAULT_END_DATE,
      startTime: AppConstants.DEFAULT_START_TIME,
      endTime: AppConstants.DEFAULT_END_TIME,
      offerItemAssignments: [],
      rate: 0,
      rateType: RateType.Hourly,
      target: 0,
      targetType: JobTargetType.Hour,
      offerId: quotation.id,
    },
    validationSchema,
    onSubmit: (values) => handleAddQuotationItem(values)
  });

  useFormikErrors(formik);

  const handleAddQuotationItem = (values) => {

    console.log("Add Quotation Item values: ", values);

    let offerItemAssignments = authUser ? [...values.offerItemAssignments, authUser] : values.offerItemAssignments;

    const payload = {
      ...values,
      offerId: quotation ? quotation.id : null,
      offerItemAssignments: offerItemAssignments.map(item => (
        {
          startDate: values.startDate,
          startTime: values.startTime,
          endDate: values.endDate,
          endTime: values.endTime,
          staffId: item.id,
          staffText: item.title || `${item.firstName} ${item.lastName}`,
          paymentToEmployeeStatus: 0,
          paidAmount: 0,
          rate: 0,
          rateType: 0
        }
      )),
      hour: 0,
      taxRate: 0,
      target: values.target ? values.target : 0,
      rate: values.rate ? values.rate : 0,
    };

    console.log("Add Quotation Item payload: ", payload);

    // return;


    const quotationStartDate = combineDateAndTime(quotation.startDate, quotation.startTime);
    const quotationEndDate = combineDateAndTime(quotation.endDate, quotation.endTime);

    const quotationItemStartDate = combineDateAndTime(values.startDate, values.startTime);
    const quotationItemEndDate = combineDateAndTime(values.endDate, values.endTime);


    const _isWithinRange = quotationStartDate > quotationItemStartDate || quotationItemEndDate > quotationEndDate;
    if (_isWithinRange) {
      setIsWithinRange(false);
    }

    const onAddSuccess = () => {
      if (!_isWithinRange) closeAddQuotationItemDialog();

    };

    dispatch(addQuotationItem(payload, false, onAddSuccess));

  };

  const assignedUser = [...formik.values.offerItemAssignments, authUser];

  const unSelectedSuggestions = () => suggestions.filter(item => !assignedUser.some(assign => assign.id === item.id));

  console.log("formikError: ", formik.errors);

  useEffect(() => {
    if (!quotation.id) {
      toast.error("Quotation is required for creating quotation item");
    }
  }, [quotation.id]);

  useDebouncedFormik(formik, 'addQuotationItem');

  return (
    <DialogCNO
      open={isAddQuotationItemDialog}
      onClose={closeAddQuotationItemDialog}
      dialogTitle={"Add Quotation Item"}
      bgColor={theme.colors.MODAL_BACKGROUND_COLOR}
      loading={addLoader || editLoader}
    >
      <form onSubmit={formik.handleSubmit} className={styles.form}>

        <FormBox title={'Quotation Item Details'}>
          <div>
            <InputLabelCNO label={'Category'} isRequired={true} />
            <SnapList ref={snapList} className={GlobalStyles.chipsWrapper}>
              {
                localCategories.map((category) => (
                  <SnapItem>
                    <ChipCNO
                      icon={category.iconLink}
                      name={category.title}
                      isCategoryChip={true}
                      active={formik.values.categories[0] === (category.id)}
                      onClick={() => formik.setFieldValue('categories', [category.id])}
                    />
                  </SnapItem>
                ))
              }
            </SnapList>
          </div>

          <InputCNO
            name='title'
            label='Title'
            formik={formik}
            placeholder={'Quotation Item Title'}
            icon={mdiFormatText}
            fullWidth
            isRequired={true}
          />

          <InputCNO
            name='description'
            label='Description'
            formik={formik}
            placeholder={'Quotation Item Description'}
            fullWidth
            multiline
          />


          <DateTimePickerCNO
            dateName="startDate"
            datePlaceholder="Start Date"
            datePickerLabel='Start by Date'
            onDateChange={date => {
              formik.setFieldValue('startDate', date);
            }}

            timeName="startTime"
            timePlaceholder="Start Time"
            timePickerLabel='Start by Time'
            onTimeChange={date => {
              formik.setFieldValue('startTime', date);
            }}

            formik={formik}
            disablePast={true}
            iconColor="red"
          />

          <DateTimePickerCNO
            dateName="endDate"
            datePlaceholder="Finish Date"
            datePickerLabel='Finish by Date'
            onDateChange={date => {
              formik.setFieldValue('endDate', date);
            }}

            timeName="endTime"
            timePlaceholder="Finish Time"
            timePickerLabel='Finish by Time'
            onTimeChange={date => {
              formik.setFieldValue('endTime', date);
            }}

            formik={formik}
            minDate={formik.values.startDate}
            iconColor="green"
          />

        </FormBox>

        <FormBox title="Internal Details">
          <div>
            <Typography className={GlobalStyles.label}>Types</Typography>
            <div className={GlobalStyles.chipsWrapper}>
              <ChipCNO
                icon={mdiLockClock}
                name="Fixed"
                size="1.5rem"
                active={formik.values.rateType === RateType.Fixed}
                onClick={() => formik.setFieldValue('rateType', RateType.Fixed)}
              />
              <ChipCNO
                icon={mdiHours24}
                name="Hourly"
                size="1.5rem"
                active={formik.values.rateType === RateType.Hourly}
                onClick={() => formik.setFieldValue('rateType', RateType.Hourly)}
              />
            </div>
          </div>
          <InputCNO
            name='rate'
            label='Rate'
            formik={formik}
            placeholder={'Enter Quotation Item Rate'}
            icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            fullWidth
            onChange={(e) => {

              const value = e.target.value;

              const rateValue = !isNaN(value) ? value : formik.values.rate;

              formik.setFieldValue('rate', value === "" ? "" : rateValue);

            }}
          />
        </FormBox>

        <FormBox title="Target Details">
          <div>
            <Typography className={GlobalStyles.label}>Target Types</Typography>
            <div className={GlobalStyles.chipsWrapper}>
              {
                Object.keys(JobTargetType).filter((t) => t !== 'None')
                  .map((target) => (
                    <ChipCNO
                      icon={icons[getMaterialIconString(JobTargetTypeIcon[target])]}
                      name={target}
                      size="1.5rem"
                      active={formik.values.targetType === JobTargetType[target]}
                      onClick={() => formik.setFieldValue('targetType', JobTargetType[target])}
                    />
                  ))
              }
            </div>
          </div>

          <InputCNO
            name='target'
            label='Target'
            formik={formik}
            placeholder='Enter Target'
            icon={mdiFormatText}
            fullWidth
          />
        </FormBox>

        <FormActionButtons
          rightText={'Add Quotation Item'}
          formik={formik}
          leftClick={closeAddQuotationItemDialog}
          disabled={!Boolean(formik.values.categories.length)}
        />
      </form>

      {
        !isWithinRange &&
        <ConfirmationDialogCNO
          open={!isWithinRange}
          onClose={() => {
            closeAddQuotationItemDialog();
            setIsWithinRange(true);
          }}
          dialogTitle="Confirm Quotation timeline update"
          messages={["Start/End Date of Quotation Item is outside the range of Quotation Start/End Date, Do you want to change the dates at Quotation  level as well ?"]}
          yesClick={() => {

            const endDate = formik.values.endDate;
            const endTime = formik.values.endTime;

            const quotationStartDate = combineDateAndTime(quotation.startDate, quotation.startTime);
            const quotationEndDate = combineDateAndTime(endDate, endTime);

            if (quotationStartDate > quotationEndDate) {
              toast.error("There is issue in updating quotation as Start Date Time is after End Date Time.");
              return;
            }

            const payload = {
              ...quotation,
              endDate,
              endTime
            };

            dispatch(updateQuotation(payload));

            console.log("payload edit: ", payload);

            closeAddQuotationItemDialog();

          }}
        />
      }
    </DialogCNO>
  )
}

export default AddQuotationItemDialog;

const useStyles = makeStyles((theme) => ({
  form: {
    '& > *': {
      marginBottom: theme.spacing(1),
      '&>*': {
        marginBottom: theme.spacing(2),
      }
    }
  }
}));