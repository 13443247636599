import React, { useEffect, useState } from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { getUserCountry } from '../../utils/Helpers';
import { useStyles } from './PhoneInputCNOStyles';
import FieldHelperTextCNO from '../FieldHelperTextCNO';
import InputLabelCNO from '../InputLabelCNO';
import { parsePhoneNumber } from 'react-phone-number-input';

function PhoneInputCNO({
  value,
  onChange,
  label,
  required,
  containerStyle,
  showHelperText,
  helperText,
  onValidatePhone,
}) {

  const styles = useStyles();

  const countryLocal = getUserCountry();

  const [country, setCountry] = useState(countryLocal);

  const [showHelperTextLocal, setShowHelperTextLocal] = useState(showHelperText);
  const [helperTextLocal, setHelperTextLocal] = useState(helperText);

  useEffect(() => {
    if (value && typeof (value) === 'string') {

      const isValuePossible = isPossiblePhoneNumber(value);

      const parsedNumber = parsePhoneNumber(value);
      const isCountry = Boolean(parsedNumber && parsedNumber.country);

      const _showHelperText = !isValuePossible || showHelperText;
      const _helperText = helperText ? helperText :
        _showHelperText ?
          !isCountry ? "Please select the country." : "Phone number is not valid."
          : "";

      setShowHelperTextLocal(_showHelperText);
      setHelperTextLocal(_helperText);

      if (onValidatePhone) {
        onValidatePhone(_helperText);
      }

    } else {
      setShowHelperTextLocal(showHelperText);
      setHelperTextLocal(helperText);
    }
  }, [value, showHelperText, helperText]);

  return (
    <div style={containerStyle}>

      <InputLabelCNO
        isRequired={required}
        label={'Mobile'}
      />

      <PhoneInput
        className={styles.phoneInput}
        defaultCountry={country.countryCode}
        placeholder='Enter phone number'
        value={value}
        international={false}
        onChange={(value) => onChange(value)}
        onCountryChange={(countryCode) => {

          setCountry({ countryCode });
          onChange(value);

        }}
      />
      <FieldHelperTextCNO
        showHelperText={showHelperTextLocal}
        helperText={helperTextLocal}
      />
    </div>
  )
}

export default PhoneInputCNO
