import React, { useEffect, useState } from 'react'
import RightsManager from '../../../utils/RightsManager';
import TooltipCNO from '../../../library/TooltipCNO';
import { Collapse, ListItem, ListItemIcon, ListItemText, lighten, makeStyles, useTheme } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiDotsCircle, mdiListBoxOutline, mdiSquareCircle, mdiSquareSmall, mdiStarFourPoints } from '@mdi/js';
import LinkCNO from '../../../library/LinkCNO';
import { useDispatch, useSelector } from 'react-redux';
import { fetchForms, fetchFormsInMenu } from '../../../actions/formBuilderActions';
import { isArrayEmpty } from '../../../utils/Helpers';
import ResponsiveLinesCNO from '../../../library/ResponsiveLinesCNO';

const DynamicFormsMenu = () => {

  const theme = useTheme();
  const styles = useStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const [isVisible, setIsVisible] = useState(false);

  const { formsInMenu, formsInMenuLoader } = useSelector(state => state.formBuilder);

  console.log("Dynamic Forms : formsInMenu: ", formsInMenu);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFormsInMenu());
  }, []);

  return (
    <>
      {
        isUserAdmin &&
        <>
          <TooltipCNO title="Captured Data" arrow placement="right">
            <ListItem button className={styles.listItem} onClick={() => setIsVisible(prev => !prev)}>
              <ListItemIcon>
                <Icon path={mdiListBoxOutline} size='1.5rem' color={theme.colors.DRAWER_ITEM_ICON_COLOR} />
              </ListItemIcon>
              <ListItemText primary='Captured Data' />
            </ListItem>
          </TooltipCNO>

          {/* nested list */}
          <Collapse in={isVisible} className={styles.nestedListContainer}>

            {
              !isArrayEmpty(formsInMenu)
                ? formsInMenu.map(form => (
                  <LinkCNO key={form.id} to={`/forms/details/${form.id}`}>
                    <TooltipCNO title={form.title} arrow placement="right">
                      <ListItem button className={styles.listItem1} onClick={() => setIsVisible(true)} >
                        <ListItemIcon>
                          <Icon path={mdiDotsCircle} size='0.8rem' style={{ marginLeft: '0.4rem' }} color={theme.colors.DRAWER_ITEM_ICON_COLOR} />
                        </ListItemIcon>
                        <ResponsiveLinesCNO text={form.title} />
                      </ListItem>
                    </TooltipCNO>
                  </LinkCNO>
                ))
                : null
            }

          </Collapse>

        </>
      }
    </>
  )
}

export default DynamicFormsMenu

const useStyles = makeStyles(theme => ({
  listItem: {
    borderRadius: 5,
    color: theme.colors.DRAWER_ITEM_TEXT_COLOR,
    height: 50
  },
  nestedListContainer: {
    background: lighten(theme.colors.SIDEBAR_BACKGROUND_COLOR, 0.1)
  },
  listItem1: {
    borderRadius: 5,
    color: theme.colors.DRAWER_ITEM_TEXT_COLOR,
    height: 36
  }
}))