import {
  apiMemberCreate,
  apiMemberEdit,
  apiMemberRemove,
  apiMemberSearch
} from '../api';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { RoleId } from '../utils/mappings';

export const SET_EMPLOYEES = "SET_EMPLOYEES";
export const SET_EMPLOYEES_LOADER = "SET_EMPLOYEES_LOADER";
export const SET_HAS_MORE_EMPLOYEES = "SET_HAS_MORE_EMPLOYEES";
export const SET_EMPLOYEE = "SET_EMPLOYEE";
export const SET_EMPLOYEE_BY_ID = "SET_EMPLOYEE_BY_ID";
export const TOGGLE_ADD_EMPLOYEE_DIALOG = "TOGGLE_ADD_EMPLOYEE_DIALOG";
export const SET_EMPLOYEE_SEARCH_PARAMS = "SET_EMPLOYEE_SEARCH_PARAMS";
export const SET_ALREADY_EXIST_EMPLOYEES = "SET_ALREADY_EXIST_EMPLOYEES";

export function setAlreadyExistEmployees(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_ALREADY_EXIST_EMPLOYEES,
      payload: payload
    });
  };
}

export function setEmployeeSearchParams(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_EMPLOYEE_SEARCH_PARAMS,
      payload: payload
    });

    dispatch(searchEmployees(payload));

  };
}

export function setHasMoreEmployees(status) {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_MORE_EMPLOYEES,
      payload: status
    });
  };
}

export function setEmployeesLoader(loaderType, loaderState) {
  return (dispatch) => {

    const loaderPayload = {
      loaderType,
      loaderState
    };

    dispatch({
      type: SET_EMPLOYEES_LOADER,
      payload: loaderPayload
    });
  };
}

export function setEmployees(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_EMPLOYEES,
      payload: payload
    });
  };
}

// export function setEmployee(payload) {
//   return (dispatch, getState) => {

//     const prevEmployess = getState().employees.employees;

//     dispatch({
//       type: SET_EMPLOYEE,
//       payload: payload
//     });
//   };
// }

export function toggleAddEmployeeDialog(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_EMPLOYEE_DIALOG,
      payload: status
    });
  };
}

export function setEmployeeById(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_EMPLOYEE_BY_ID,
      payload: payload
    });
  };
}

export function getEmployeeById(ids, resolvePic = false, offlineData, onFetchById) {

  return async (dispatch) => {

    dispatch(setEmployeesLoader("employeeDetailLoader", true));

    const payload = {
      ids: ids,
      orderBy: "newest",
      ResolvePic: resolvePic
    };

    await apiMemberSearch.post(payload)
      .then((response) => {

        if (response.status === 200) {

          const responseData = response.data.data;

          console.log("responseData: ", responseData);

          if (onFetchById) {
            onFetchById(responseData[0]);
          }

          dispatch({
            type: SET_EMPLOYEE_BY_ID,
            payload: responseData[0]
          });

        } else {
          if (offlineData) {
            dispatch({
              type: SET_EMPLOYEE_BY_ID,
              payload: offlineData
            });
          } else {
            console.error("Fetch employee by Id ");
            toast.error("There is some issue in fetching details of employee.");
          }
        }

      })
      .catch((error) => {

        if (offlineData) {
          dispatch({
            type: SET_EMPLOYEE_BY_ID,
            payload: offlineData
          });
        } else {
          console.error("Fetch employee by Id Error: ", error);
          toast.error("There is some issue in fetching details of employee.");
        }

      }).finally(() => {
        dispatch(setEmployeesLoader("employeeDetailLoader", false));
      });

  };

}

export function searchEmployees(payload) {
  return (dispatch, getState) => {

    console.log("===> searchEmployees payload", payload);

    const searchParams = {
      employee: payload.employee ? payload.employee : null,
    };

    const searchPayload = {
      "ids": [],
      "orderBy": "newest",
      "pageIndex": 0,
      ...payload.employee ? ({
        ...(payload.employee.enterKey ? { searchText: payload.employee.title } : {}),
        ...(!payload.employee.enterKey ? { ids: [payload.employee.id] } : {}),
      }) : {},
    };

    dispatch(fetchEmployees(searchPayload));

  };
}

export function fetchEmployees(payload) {
  return async (dispatch, getState) => {

    dispatch(setEmployeesLoader('employeesLoader', true));
    const { employeeSearchParams } = getState().employees;

    payload = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      SortDecending: true,
      ids: [],
      ResolvePic: true,
      searchText: employeeSearchParams.employee ? employeeSearchParams.employee.title : null,
      ...payload
    };

    await apiMemberSearch.post(payload)
      .then((response) => {

        if (response.status === 200) {

          let employeesResponse = response.data.data;
          console.log("employeesResponse: ", employeesResponse);
          dispatch(setEmployees(employeesResponse));

          if (employeesResponse.length < 10) {
            dispatch(setHasMoreEmployees(false));
          }

        }
        else {
          console.error("Error: Fetch Employees: ");
        }

      })
      .catch((error) => {
        console.error("Error: Fetch Employees: ", error);
      })
      .finally(() => {
        dispatch(setEmployeesLoader('employeesLoader', false));
      });

  };
}

export function fetchMoreEmployees(payload) {
  return async (dispatch, getState) => {

    const prevEmployees = getState().employees.employees;
    const { employeeSearchParams } = getState().employees;

    dispatch(setEmployeesLoader('moreEmployeesLoader', true));

    payload = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      SortDecending: true,
      ids: [],
      ResolvePic: true,
      searchText: employeeSearchParams.employee ? employeeSearchParams.employee.title : null,
      ...payload
    };

    await apiMemberSearch.post(payload)
      .then((response) => {

        if (response.status === 200) {

          let moreEmployeesResponse = response.data.data;
          console.log("moreEmployeesResponse: ", moreEmployeesResponse);

          const employeespayload = [...prevEmployees, ...moreEmployeesResponse];

          dispatch(setEmployees(employeespayload));

          if (moreEmployeesResponse.length < 10) {
            dispatch(setHasMoreEmployees(false));
          }

        }
        else {
          console.error("Error: Fetch Employees: ");
        }

      })
      .catch((error) => {
        console.error("Error: Fetch Employees: ", error);
      })
      .finally(() => {
        dispatch(setEmployeesLoader('moreEmployeesLoader', false));
      });

  };
}

export function updateEmployeeDetails(payload) {
  return async (dispatch, getState) => {

    dispatch(setEmployeesLoader('editLoader', true));

    const dataToSend = {
      hourlyRate: payload.hourlyRate,
      code: payload.code,
      trackingMode: payload.trackingMode,
      id: payload.id,
      isActive: payload.isActive,
      password: payload.password,
      userId: payload.userId,
      salutation: payload.salutation,
      firstName: payload.firstName,
      lastName: payload.lastName,
      roleId: payload.roleId,
      profilePic: payload.profilePic,
      birthday: payload.birthday,
      gender: payload.gender,
      email: payload.email,
      mobile: payload.mobile,
      skype: payload.skype,
      facebook: payload.facebook,
      twitter: payload.twitter,
      linkedIn: payload.linkedIn,
      abstract: payload.abstract,
      mapAddress: payload.mapAddress,
      lat: payload.lat,
      lng: payload.lng
    };

    console.log("Employee edit payload: ", dataToSend);

    await apiMemberEdit.post(dataToSend)
      .then((response) => {
        if (response.status === 200) {

          const payloadTemp = {
            ...payload,
            profilePic: payload.localProfilePicUrl,
          };
          const { employees } = getState().employees;
          const employeesTemp = employees.map((employee) => employee.id === payload.id ? payloadTemp : employee
          );
          dispatch(setEmployees(employeesTemp));

          dispatch({
            type: SET_EMPLOYEE_BY_ID,
            payload: payload
          });

          toast.success('Employee edited successfully!!');
        } else {
          toast.error('Error in editing employee!!');
        }
      })
      .catch((error) => {
        console.error("Employee Edit Error: ", error);
        toast.error('Error in editing employee!!');
      })
      .finally(() => {
        dispatch(setEmployeesLoader('editLoader', false));
      });

  };
}

export function addEmployee(payload, onAddSuccess, onAddError) {
  return async (dispatch, getState) => {

    dispatch(setEmployeesLoader('addLoader', true));

    const { employees } = getState().employees;

    console.log("Add Employee payloadaction: ", payload);

    payload = {
      ...payload,
      roleId: RoleId.Member,
      hourlyRate: payload.hourlyRate ? payload.hourlyRate : 0
    };

    await apiMemberCreate.post(payload)
      .then((response) => {

        if (response.status === 200) {

          console.log("Add Employee Response: ", response);

          const id = response.data;
          const newEmployee = {
            ...payload,
            userId: id,
            id: id,
            groupId: '',
            isActive: true
          };

          dispatch(setEmployees([newEmployee, ...employees]));

          if (onAddSuccess) onAddSuccess();

          toast.success('Employee added successfully!!');

        } else {
          console.log("Add Employee Error: ");
          toast.error('Error in adding employee!!');
        }

      })
      .catch((error) => {

        if (error.response) {
          if (error.response.status === 400 && error.response.data && typeof error.response.data === 'string') {
            console.error("Add Employee Error: response ", error.response.data);
            toast.error(error.response.data);

            if (onAddError) onAddError(error.response.data);

            return;
          }
        }


        console.error("Add Employee Error: ", error);
        toast.error('Error in adding employee!!');
      })
      .finally(() => {
        dispatch(setEmployeesLoader('addLoader', false));
      });

  };
}

export function deleteEmployee(payload) {
  return async (dispatch, getState) => {

    dispatch(setEmployeesLoader('deleteLoader', true));

    const { employees } = getState().employees;

    console.log("Delete Employee payloadaction: ", payload);

    await apiMemberRemove.post(payload)
      .then((response) => {

        if (response.status === 200) {

          console.log("Remove Employee Response: ", response);

          const employeesTemp = employees.map((employee) => {
            if (employee.id === payload.id) {
              const updatedEmployee = {
                ...employee,
                isActive: false
              };

              dispatch(setEmployeeById(updatedEmployee));
              return updatedEmployee;
            } else {
              return employee;
            }
          });


          dispatch(setEmployees(employeesTemp));
          toast.success('Employee removed successfully!!');

        } else {
          console.log("Delete Employee Error: ");
          toast.error('Error in removing employee!!');
        }

      })
      .catch((error) => {
        console.error("Delete Employee Error: ", error);
        toast.error('Error in removing employee!!');
      })
      .finally(() => {
        dispatch(setEmployeesLoader('deleteLoader', false));
      });

  };
}


export function searchAlreadyEmployees(payload) {
  return async (dispatch) => {

    dispatch(setEmployeesLoader('employeesLoader', true));

    payload = {
      PageIndex: 0,
      OrderBy: "newest",
      SortDecending: true,
      resolvePic: true,
      SearchText: "",
      ...payload
    };

    await apiMemberSearch.post(payload)
      .then((response) => {

        if (response.status === 200) {

          let employeesResponse = response.data.data;
          console.log("employeesResponse: ", employeesResponse);
          dispatch(setAlreadyExistEmployees(employeesResponse));

          if (employeesResponse < 10) {
            dispatch(setHasMoreEmployees(false));
          }

        }
        else {
          console.error("Error: Fetch Employees: ");
        }

      })
      .catch((error) => {
        console.error("Error: Fetch Employees: ", error);
      })
      .finally(() => {
        dispatch(setEmployeesLoader('employeesLoader', false));
      });

  };
}
