import { useEffect, useMemo } from "react";
import debounce from 'lodash.debounce';
import LocalStorageConstants from "../utils/LocalStorageConstants";

const useDebouncedFormik = (formik, storageKey) => {

  useEffect(() => {

    const values = LocalStorageConstants.getItem(storageKey);

    if (values) {
      formik.setValues(values);
    }

  }, []);

  const debounceHandler = (values) => {

    console.log("===debounced formik====", values);

    LocalStorageConstants.setItem(storageKey, values);

  };

  const debouncedChangeHandler = useMemo(() => debounce(debounceHandler, 3000), []);

  useEffect(() => {
    debouncedChangeHandler(formik.values);

    return () => {
      debouncedChangeHandler.cancel();
    };

  }, [formik.values, debouncedChangeHandler]);

};

export default useDebouncedFormik;