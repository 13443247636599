import moment from 'moment';
import { v4 } from 'uuid';

export const SET_POSTS = "SET_POSTS";
export const SET_POST_BY_ID = "SET_POST_BY_ID";
export const SET_POST_LOADERS = "SET_POST_LOADERS";
export const SET_HAS_MORE_POSTS = "SET_HAS_MORE_POSTS";
export const SET_POST_SEARCH_PARAMS = "SET_POST_SEARCH_PARAMS";
export const TOGGLE_ADD_POST_DIALOG = "TOGGLE_ADD_POST_DIALOG";
export const TOGGLE_EDIT_POST_DIALOG = "TOGGLE_EDIT_POST_DIALOG";
export const SET_POST_TIMES_FILTER = "SET_POST_TIMES_FILTER";
export const SET_RESOLVED_POST_MEDIAS = "SET_RESOLVED_POST_MEDIAS";
export const SET_MEDIAS_UPLOAD_COUNT = "SET_MEDIAS_UPLOAD_COUNT";
export const SET_POST_REACTIONS = "SET_POST_REACTIONS";
export const SET_POST_COMMENTS = "SET_POST_COMMENTS";
export const SET_COMMENT_INPUT_DETAILS = "SET_COMMENT_INPUT_DETAILS";
export const SET_POST_DELETE_LOADER="SET_POST_DELETE_LOADER"
export const DELETE_POST_BY_ID = "DELETE_POST_BY_ID";
export const TOGGLE_IS_DELETE_UNDO="TOGGLE_IS_DELETE_UNDO"
export const SET_POST_HIDE_LOADER="SET_POST_HIDE_LOADER"
export const SET_DELETED_POSTS="SET_DELETED_POSTS"
export const SET_HIDDEN_POSTS="SET_HIDDEN_POSTS"
export const SHOW_HIDDEN_POSTS="SHOW_HIDDEN_POSTS"


import postReactionsDummyData from './DummyData/postReactions.json';
import postCommentsDummyData from './DummyData/postComments.json';
import { FileType, MediaSizeType, Privacy } from '../utils/mappings';
import { getPostsByType } from '../pages/PostsManagement/helpers';
import LocalStorageConstants from '../utils/LocalStorageConstants';
import axios from 'axios';
import { combineDateAndTime } from '../utils/Tracking';
import { toast } from 'react-toastify';

import postsDummyData from './DummyData/mixData.json';
import { apiTaskRemove } from '../api';

// const postsDummyData = getPostsByType(postsDummyDataAll);

//apis

import { apiCreateComment } from '../api/Post/apiCreateComment';

export function setPostReactions(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_POST_REACTIONS,
      payload: payload
    });
  };
}

export function markHiddenPosts(payload) {
  return (dispatch) => {
    dispatch({
      type: SHOW_HIDDEN_POSTS,
      payload: payload
    });
  };
}

export function setPostComments(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_POST_COMMENTS,
      payload: payload
    });
  };
}

export function setMediasUploadCount(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_MEDIAS_UPLOAD_COUNT,
      payload: payload
    });
  };
}

export function setResolvedPostMedias(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_RESOLVED_POST_MEDIAS,
      payload: payload
    });
  };
}

export function setPostTimesFilter(value) {
  return (dispatch) => {
    dispatch({
      type: SET_POST_TIMES_FILTER,
      payload: value
    });
  };
}

export const toggleAddPostDialog = (payload) => {
  // console.log("dispatched1")

  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_POST_DIALOG,
      payload: payload
    });
  };
};

export const toggleEditPostDialog = (payload) => {
  

  return (dispatch) => {
    dispatch({
      type: TOGGLE_EDIT_POST_DIALOG,
      payload: payload
    });
  };
};
export function toggleIsDeleteUndo(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_IS_DELETE_UNDO,
      payload: status
    });
  };
}

function setPostDeleteLoader(status) {
  return (dispatch) => {
    dispatch({
      type: SET_POST_DELETE_LOADER,
      payload: status
    });
  };
}



export function deletePostById(deleteItem, history, path) {
  return async function deletePostByIdThunk(dispatch, getState) {

    dispatch(setPostDeleteLoader(true));

    const deletePayload = {
      id: deleteItem.id,
      undo: false
    };

    await apiTaskRemove.post(deletePayload)
      .then(response => {


        console.log("delete response: ", response.data);

        if (path === "/post/:id"){
          history.push("/home");
        }

        const deletedPosts=JSON.parse(localStorage.getItem('deletedPosts')) || [];
        deletedPosts.push(deleteItem.id);
        localStorage.setItem('deletedPosts',JSON.stringify(deletedPosts));

        dispatch({
          type: DELETE_POST_BY_ID,
          payload: deleteItem.id
        });

        const { deletedPostsList } = getState().posts;
        deletedPostsList.push(deleteItem.id);
        dispatch(setDeletedPosts(deletedPostsList));

        dispatch(setPostDeleteLoader(false));

        dispatch(toggleIsDeleteUndo(true));
        toast.success("Post Deleted Successfully");

      })
      .catch((error) => {
        dispatch(setPostDeleteLoader(false));
        toast.error("Error in deleting task");
        console.error("Delete Task Error: ", error);
      });
  };
}

export const setPostSearchParams = (payload) => {
  return (dispatch) => {

    dispatch({
      type: SET_POST_SEARCH_PARAMS,
      payload: payload
    });

  };
};

export const setPostLoaders = (loaderName, loaderState) => {
  return (dispatch) => {
    dispatch({
      type: SET_POST_LOADERS,
      payload: { loaderName, loaderState }
    });
  };
};

export const setPosts = (payload) => {
  console.log("clg4")
  console.log("set post : payload : ", payload);
  
  return (dispatch) => {
    dispatch({
      type: SET_POSTS,
      payload: payload
    });
  };
};

export const setDeletedPosts = (payload) => {
  
  
  return (dispatch) => {
    dispatch({
      type: SET_DELETED_POSTS,
      payload: payload
    });
  };
};

export const setHiddenPosts = (payload) => {
  
  
  return (dispatch) => {
    dispatch({
      type: SET_HIDDEN_POSTS,
      payload: payload
    });
  };
};
export const setHasMorePosts = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_MORE_POSTS,
      payload: payload
    });
  };
};

export const setPostById = (payload) => {
  return (dispatch, getState) => {
 
    if (payload) {

      dispatch({
        type: SET_POST_BY_ID,
        payload: payload
      });

      const { posts } = getState().posts;
      const updatedPosts = posts.map((post) => post.id === payload.id ? payload : post);
      dispatch(setPosts(updatedPosts));
      

    } else {
      dispatch({
        type: SET_POST_BY_ID,
        payload: payload
      });
    }

  };
};

export const fetchPosts = (payload = {}) => {
  return async (dispatch, getState) => {

    dispatch(setPostLoaders('postsLoader', true));
    dispatch(setHasMorePosts(true));

    const { postSearchParams } = getState().posts;

    const dataToSend = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      ids: null,
      customerIds: postSearchParams.customer ? [postSearchParams.customer.id] : null,
      searchText: postSearchParams.post ? postSearchParams.post.title : "",
      categories: postSearchParams.categories,
      startDate: postSearchParams.startDate ? moment(postSearchParams.startDate).startOf('day') : null,
      // endDate: postSearchParams.endDate ? moment(postSearchParams.endDate).endOf('day') : null,
      assignedIds: postSearchParams.assignments ? [postSearchParams.assignments.id] : null,
      ...payload,
      endDate: payload.endDate ? payload.endDate : (
        postSearchParams.endDate ? moment(postSearchParams.endDate).endOf('day') : moment().add(5, 'year').endOf('day').toDate()
      ),
    };

    // await axios.post("v1/post/search", dataToSend)
    await axios.get('https://jsonplaceholder.typicode.com/users', dataToSend)
      .then((response) => {

        if (response.status === 200) {

          const posts = postsDummyData;

          console.log("PostListingPage::fetchPosts posts: ", posts);

          // const posts = response.data.data;

          if (posts.length < 10) {
            dispatch(setHasMorePosts(false));
          }

          dispatch(setPosts(posts));

        } else {
          console.error("Error: Fetch Posts : Response", response);
        }

      })
      .catch((error) => {
        console.error("Error: Fetch Posts: ", error);

      })
      .finally(() => dispatch(setPostLoaders('postsLoader', false)));

  };
};

export const fetchMorePosts = (payload) => {
  return async (dispatch, getState) => {

    const prevPosts = getState().posts.posts;
    const { postSearchParams } = getState().posts;

    dispatch(setPostLoaders('morePostsLoader', true));

    dispatch(setHasMorePosts(true));

    const dataToSend = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      ids: null,
      customerIds: postSearchParams.customer ? [postSearchParams.customer.id] : null,
      searchText: postSearchParams.post ? postSearchParams.post.title : "",
      categories: postSearchParams.categories,
      startDate: postSearchParams.startDate ? moment(postSearchParams.startDate).startOf('day') : null,
      // endDate: postSearchParams.endDate ? moment(postSearchParams.endDate).endOf('day') : null,
      assignedIds: postSearchParams.assignments ? [postSearchParams.assignments.id] : null,
      ...payload,
      endDate: postSearchParams.endDate ? moment(postSearchParams.endDate).endOf('day') : moment().add(5, 'year').endOf('day').toDate(),
    };

    // await axios.post("v1/post/search", dataToSend)
    await axios.get('https://jsonplaceholder.typicode.com/users', dataToSend)
      .then((response) => {

        if (response.status === 200) {

          let posts = postsDummyData;
          // let posts = response.data.data;

          if (posts.length < 10) {
            dispatch(setHasMorePosts(false));
          }

          posts = [...prevPosts, ...posts];

          dispatch(setPosts(posts));

        } else {
          console.error("Error: Fetch more Posts : Response", response);
        }

      })
      .catch((error) => {
        console.error("Error: Fetch more Posts: ", error);

      })
      .finally(() => dispatch(setPostLoaders('morePostsLoader', false)));

  };
};

export const fetchPostById = (postId, offlineData) => {
  return async (dispatch, getState) => {

    dispatch(setPostLoaders('postDetailLoader', true));

    const dataToSend = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      startDate: null,
      endDate: null,
      assignedIds: [],
      categories: [],
      searchText: "",
      ids: [postId],
    };

    // await axios.post("v1/post/search", dataToSend)
    await axios.get('https://jsonplaceholder.typicode.com/users', dataToSend)
      .then((response) => {

        if (response.status === 200) {

          const posts = getState().posts;
          let post = postsDummyData.find(post => post.id === postId);

          // let post = response.data.data[0];

          dispatch(setPostById(post));

        } else {

          if (offlineData) {
            dispatch(setPostById(offlineData));
          } else {
            console.error("Error: Fetch Post : Response", response);
          }
        }

      })
      .catch((error) => {

        if (offlineData) {
          dispatch(setPostById(offlineData));
        } else {
          console.error("Error: Fetch Post: ", error);
        }

      })
      .finally(() => dispatch(setPostLoaders('postDetailLoader', false)));

  };
};

export const addPost = (payload, onAddPost) => {
  
  return async (dispatch, getState) => {

    dispatch(setPostLoaders('addPostLoader', true));

    payload = {
      ...payload,
    };

    const authUser = LocalStorageConstants.getItem('authUser');
    console.log('Adding Post : authUser',authUser);
    
    const dataToSend = {
      postType: "",
      description: "",
      mediaLinks: [],
      categories: [],
      createdAt: moment().toISOString(),
      createdBy: {
        id: authUser.id,
        title: authUser.firstName + " " + authUser.lastName,
        profilePic: authUser.profilePic
      },
      // eventDateAndTime:combineDateAndTime(payload.eventDate, payload.eventTime),
      mapAddress: "",
      lat: "",
      lng: "",
      status: 1,
      visibility: Privacy.ids.Private,
      reactionCounts: 0,
      commentsCounts: 0,
      myReactionId: 0,
      ...payload
    };

    console.log(" addPost: dataToSend: ", dataToSend);

    // await axios.post("v1/post/create", dataToSend)
    await axios.get('https://jsonplaceholder.typicode.com/users', dataToSend)
      .then((response) => {

        if (response.status === 200) {

          // const postId = response.data;

          // console.log(" addPost: postId: ", postId);

          const newPost = {
            id: v4(),
            ...dataToSend,
            // id: postId
          };

          console.log(" addPost: newPost: ", newPost);

          const { posts } = getState().posts;

          const updatedPosts = [newPost, ...posts];

          dispatch(setPosts(updatedPosts));
          dispatch(setPostById(newPost));

          toast.success('Post added successfully!!');
          

          if (onAddPost) {
            onAddPost(newPost);
          }

          console.log(" addPost: end: ");

        } else {

          console.error("Error: Add Posts : Response", response);

          toast.error("Error in adding post.")


        }

      })
      .catch((error) => {

        console.error("Error: Add Posts: ", error);

        toast.error("Error in adding post.")

      })
      .finally(() => dispatch(setPostLoaders('addPostLoader', false)));

  };
};

export const updatePost = (payload, onAddSuccess) => {
  return async (dispatch, getState) => {
    console.log("clg0")

    dispatch(setPostLoaders('editPostLoader', true));
    const authUser = LocalStorageConstants.getItem('authUser');
   
    
      
    payload = {
      ...payload,
      startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startTime: combineDateAndTime(payload.startDate, payload.startTime),
      endDate: combineDateAndTime(payload.endDate, payload.endTime),
      endTime: combineDateAndTime(payload.endDate, payload.endTime),
      createdAt: moment().toISOString(),
      createdBy: {
        id: authUser.id,
        title: authUser.firstName + " " + authUser.lastName,
        profilePic: authUser.profilePic
      },
    };

    const dataToSend = {
      id: "",
      title: "",
      description: "",
      categories: [],
      customerId: "",
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      mapAddress: "",
      lat: "",
      lng: "",
      visibility: 0,
      ...payload
    };

    console.log("update post : payload : ", payload);

    // dispatch(setPostById(payload));
    // dispatch(setPostLoaders('editPostLoader', false));
    dispatch(setPostById(dataToSend))
    console.log("clg1")
    
    dispatch(setPostLoaders('editPostLoader', false))

    if(onAddSuccess){
      onAddSuccess(dataToSend);
    }
  };
};

export const deletePost = (payload) => {
  return async (dispatch, getState) => {

    dispatch(setPostLoaders('deletePostLoader', true));

    const postId = payload.id;
    const dataToSend = {
      id: postId,
      undo: false
    };

    await axios.post("v1/post/remove", dataToSend)
      .then((response) => {

        if (response.status === 200) {

          const { posts } = getState().posts;

          const updatedPosts = posts.filter((post) => post.id !== postId);

          dispatch(setPosts(updatedPosts));


          toast.success("Post deleted successfully!!")


        } else {

          console.error("Error: Delete Posts : Response", response);

          toast.error("Error in deleting post.");


        }

      })
      .catch((error) => {

        console.error("Error: Delete Posts: ", error);
        toast.error("Error in deleting post.");


      })
      .finally(() => dispatch(setPostLoaders('deletePostLoader', false)));

  };
};
export const unhidePost = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setPostLoaders('unhidePostLoader', true));

    const postId = payload.id;

    const { hiddenPostsList } = getState().posts;

    
    const updatedHiddenPostsList = hiddenPostsList.filter(id => id !== postId);

    dispatch(setHiddenPosts(updatedHiddenPostsList));

    
    const hiddenPosts = JSON.parse(localStorage.getItem('hiddenPosts')) || [];
    const updatedHiddenPosts = hiddenPosts.filter(id => id !== postId);
    localStorage.setItem('hiddenPosts', JSON.stringify(updatedHiddenPosts));

    toast.success("Post Unhidden successfully!!");

    dispatch(setPostLoaders('unhidePostLoader', false));
  };
};

export const hidePost = (payload) => {
  return async (dispatch, getState) => {

    dispatch(setPostLoaders('hidePostLoader', true));

    const postId = payload.id;

    const { hiddenPostsList } = getState().posts;

    hiddenPostsList.push(postId)

    dispatch(setHiddenPosts(hiddenPostsList));

    const hiddenPosts=JSON.parse(localStorage.getItem('hiddenPosts')) || [];
    hiddenPosts.push(postId);
    localStorage.setItem('hiddenPosts',JSON.stringify(hiddenPosts));

    toast.success("Post Hidden successfully!!")

  };
};
export function handlePostSearch({ sortAscending, pageSize, ...searchParams } = {}) {
  return async (dispatch) => {

    console.log("Post Filter Values: ", searchParams);

    await dispatch(setPostSearchParams(searchParams));

    const searchPayload = {
      // customerIds: searchParams.customer ? [searchParams.customer.id] : null,
      // ids: searchParams.post ? [searchParams.post.id] : [],
      // categories: searchParams.categories,
      // startDate: searchParams.startDate ? moment(searchParams.startDate).startOf('day') : null,
      // endDate: searchParams.endDate ? moment(searchParams.endDate).endOf('day') : null,
      // assignedIds: searchParams.assignments ? [searchParams.assignments.id] : null,
      ...((sortAscending !== undefined || !isNaN(sortAscending)) ? { sortAscending } : {}),
      ...((pageSize !== undefined || pageSize !== null) ? { pageSize } : {})
    };

    await dispatch(fetchPosts(searchPayload));
  };
}

export const uploadPostMedias = (payload, onSuccess, resolve = false, saveToLocal = false) => {
  return async (dispatch, getState) => {

    const {
      mediasToUpload,
      mediaLinks,
      post
    } = payload;

    dispatch(setPostLoaders(appendIDToName('postMediaUploadLoader', post.id), true));

    dispatch(setMediasUploadCount(mediasToUpload.length));

    const uIds = mediasToUpload.map(item => v4());

    const initRes = await axios.post('v1/post/initupload', {
      "ids": uIds
    });


    // MAKE BLOB
    let uploadMediasPromise = [];
    try {
      uploadMediasPromise = uploadMediaHelper(mediasToUpload, resolve, saveToLocal, initRes);
    } catch (error) {
      console.error("Post: ", error);
      dispatch(setPostLoaders(appendIDToName('postMediaUploadLoader', post.id), false));
      dispatch(setMediasUploadCount(0));
    }


    let uploadSuccessMedias = [];
    uploadSuccessMedias = await Promise.all(uploadMediasPromise);


    uploadSuccessMedias = uploadSuccessMedias.filter(media => Boolean(media));


    if (!uploadSuccessMedias.length) {

      toast.success(`0 of ${mediasToUpload.length} medias uploaded successfully.`);


      dispatch(toggleMediaUploadDoneModal({
        isModalVisible: true,
        totalCount: mediasToUpload.length,
        successCount: 0
      }));

      dispatch(setPostLoaders(appendIDToName('postMediaUploadLoader', post.id), false));
      dispatch(setMediasUploadCount(0));

      return;
    }

    // SAVE TO SERVER
    const newMedias = uploadSuccessMedias.map((media, index) => ({
      id: uIds[index],
      title: media.name,
      fileType: getFileTypeFromMimeType(media.type),
      abstract: "",
      link: uIds[index],
      mediaType: payload.mediaType,
      postId: post.id
    }));

    const medias = [...newMedias, ...mediaLinks].map((media) => {
      return {
        link: media.id,
        mediaType: media.mediaType,
        postId: post.id,
        title: media.title,
        fileType: media.fileType,
      };
    });

    const dataToSend = {
      postId: post.id,
      medias: medias
    };


    await axios.post('v1/post/uploadmedia', dataToSend)
      .then(async (uploadRes) => {
        if (uploadRes.status == 200) {

          let newMedias = uploadSuccessMedias.map(async (media, index) => {

            const fileType = getFileTypeFromMimeType(media.type);
            const { thumbnailFileUrl } = await getThumbnailBlob(fileType, media.fileCopyUri);


            return ({
              id: uIds[index],
              title: media.name,
              fileType: fileType,
              link: thumbnailFileUrl,
              mediaType: payload.mediaType,
              postId: post.id,
            });

          });

          const { postById, posts } = getState().posts;

          newMedias = await Promise.all(newMedias);

          const updatedPost = {
            ...post,
            mediaLinks: [
              ...newMedias,
              ...(post ? post.mediaLinks : [])
            ]
          };

          // update todo if its detail is open
          // else update the todo in listing if available
          if (postById && (postById.id === post.id)) {
            dispatch(setPostById(updatedPost));
          } else {
            const targetPost = posts.find(_post => _post.id === post.id);
            let updatedPosts = posts;
            if (targetPost) {
              updatedPosts = posts.map(_post => _post.id === post.id ? updatedPost : _post);
            } else {
              updatedPosts = [updatedPost, ...posts];
            }
            dispatch(setPosts(updatedPosts));
          }


          toast.success(`${uploadSuccessMedias.length} of ${mediasToUpload.length} medias uploaded successfully.`);


          if (onSuccess) onSuccess(updatedPost);

          dispatch(toggleMediaUploadDoneModal({
            isModalVisible: true,
            totalCount: mediasToUpload.length,
            successCount: uploadSuccessMedias.length
          }));

        }
        else {
          console.log("Upload Image Error: response: ", response);

          toast.error('There is some issues in uploading media, please try again!');

          dispatch(toggleMediaUploadDoneModal({
            isModalVisible: true,
            totalCount: mediasToUpload.length,
            successCount: 0
          }));

        }
      })
      .catch((error) => {
        console.log("Upload Image Error: ", error);

        toast.error('There is some issues in uploading media, please try again!');


        dispatch(toggleMediaUploadDoneModal({
          isModalVisible: true,
          totalCount: mediasToUpload.length,
          successCount: 0
        }));

      })
      .finally(() => {
        dispatch(setPostLoaders(appendIDToName('postMediaUploadLoader', post.id), false));
        dispatch(setMediasUploadCount(0));
      });

  };
};

export const getPostMediaLink = (payload) => {
  return async (dispatch, getState) => {

    dispatch(setPostLoaders('postMediaFetchLoader', true));

    const dataToSend = {
      ids: payload.mediaLinks.map((media) => media.id),
      size: MediaSizeType.Thumbnail
    };

    await axios.post('v1/post/getmedialink', dataToSend)
      .then(response => {
        console.log(response);

        if (response.status == 200) {
          if (response.data.links.length) {

            const mediaLinks = payload.mediaLinks.map((mediaLink) => {

              const link = response.data.links.find((link) => link.id === mediaLink.id);

              if (link) {
                return {
                  ...mediaLink,
                  link: link.link
                };
              }
              else {
                return mediaLink;
              }

            });

            const { postById } = getState().posts;

            const updatedPost = {
              ...postById,
              mediaLinks: mediaLinks
            };

            dispatch(setPostById(updatedPost));

          }

        } else {


          toast.error('Medias cannot be loaded.');
        }
      })
      .catch(error => {
        console.log(error);
        toast.error('Medias cannot be loaded.');

      })
      .finally(() => dispatch(setPostLoaders('postMediaFetchLoader', false)));
  };
};

export const deletePostMedia = (payload) => {
  return async (dispatch, getState) => {

    const {
      postById
    } = getState().posts;

    console.log("===deletePostMedia====payload=", payload);
    console.log("===deletePostMedia====postById=", postById);

    const {
      mediaLinks
    } = postById;

    dispatch(setPostLoaders('postMediaDeleteLoader', true));

    let newMedias = mediaLinks.filter(media => media.id !== payload.id);

    const medias = newMedias.map((media) => {
      return {
        link: media.id,
        mediaType: media.mediaType,
        postId: postById.id
      };
    });
    const dataToSend = {
      postId: postById.id,
      medias,
    };

    console.log("===deletePostMedia====dataToSend=", dataToSend);

    // Using an IIFE
    await axios.post('v1/post/uploadmedia', dataToSend)
      .then((uploadRes) => {
        if (uploadRes.status == 200) {

          const updatedPost = {
            ...postById,
            mediaLinks: newMedias
          };

          dispatch(setPostById(updatedPost));

          toast.success('Post media deleted successfully!!');


        }
        else {
          toast.error('There is some issues in deleting media, please try again!');

        }
      })
      .catch((error) => {
        console.log("Delete Image Error: ", error);
        toast.error('There is issue in deleting image!');

      })
      .finally(() => dispatch(setPostLoaders('postMediaDeleteLoader', false)));

  };
};

export const getResolvedPostMediasByIds = (payload, onResolveMediasByIds, openMedia = true, returnAll = false) => {
  return async (dispatch, getState) => {

    const { clickedMedia = {}, allMedias } = payload;

    const dataToSend = {
      ids: allMedias.map(media => media.id),
      size: MediaSizeType.Mobile
    };

    dispatch(setPostLoaders('postMediaFetchLoader', true));

    await axios.post('v1/post/getmedialink', dataToSend)
      .then(async (response) => {
        console.log(response);

        if (response.status == 200) {

          const mediaLinks = response.data.links;

          if (mediaLinks.length) {

            let allResolvedMedias = [];

            if (returnAll) {
              allResolvedMedias = mediaLinks.map(async (media, index) => {

                return {
                  ...media,
                  mediaType: allMedias.find(m => m.id === media.id).mediaType,
                  uri: media.link,
                  thumbnailUri: allMedias[index].link,
                  fileType: FileType.Image,
                  type: "image/jpg",
                  isResolved: false,
                  isImage: true
                };
              });

              allResolvedMedias = await Promise.all(allResolvedMedias);
              allResolvedMedias = allResolvedMedias.filter(Boolean);

              onResolveMediasByIds(allResolvedMedias, false);
              dispatch(setResolvedPostMedias(allResolvedMedias));
              return;
            }

            let resolvedClickedMedia = mediaLinks.find(media => media.id === clickedMedia.id);

            let fileType = null;
            let contentType = null;
            try {
              const resp = await getContentType(resolvedClickedMedia.link);
              fileType = resp.fileType;
              contentType = resp.contentType;
            } catch (error) {

              toast.error('Please delete and upload the media again');

              return;
            }

            const isImage = fileType === FileType.Image;
            resolvedClickedMedia = {
              ...resolvedClickedMedia,
              mediaType: allMedias.find(m => m.id === resolvedClickedMedia.id).mediaType,
              type: contentType,
              uri: resolvedClickedMedia.link,
              thumbnailUri: clickedMedia.link,
              fileType,
              isResolved: true,
              isImage
            };

            if (!isImage && openMedia) {
              await dispatch(openMediaFromUrl(resolvedClickedMedia));
              return;
            }

            allResolvedMedias = mediaLinks.map((media, index) => {

              if (media.id === resolvedClickedMedia.id) {
                return resolvedClickedMedia;
              } else {
                return {
                  ...media,
                  mediaType: allMedias.find(m => m.id === media.id).mediaType,
                  uri: media.link,
                  thumbnailUri: allMedias[index].link,
                  fileType: FileType.Image,
                  isResolved: false,
                  isImage: true
                };
              }

            });

            allResolvedMedias = await Promise.all(allResolvedMedias);
            allResolvedMedias = allResolvedMedias.filter(Boolean);

            if (onResolveMediasByIds) {

              const isOpeningMedia = openMedia && !isImage;

              onResolveMediasByIds(allResolvedMedias, isOpeningMedia);
              dispatch(setResolvedPostMedias(allResolvedMedias));

            }

          }

        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => dispatch(setPostLoaders('postMediaFetchLoader', false)));

  };
};

export const getPostReactions = ({ postId }) => {
  return async (dispatch, getState) => {

    dispatch(setPostLoaders('postReactionsLoader', true));

    try {
      const response = await axios.get('https://jsonplaceholder.typicode.com/users');

      const { posts } = getState().posts;

      const targetPostReactions = postReactionsDummyData.filter(reaction => reaction.postId === postId);

      dispatch(setPostReactions(targetPostReactions));

    } catch (error) {

    } finally {
      dispatch(setPostLoaders('postReactionsLoader', false));

    }

  }
}

export const getPostComments = ({ postId }) => {
  return async (dispatch, getState) => {

    dispatch(setPostLoaders('postCommentsLoader', true));

    try {
      const response = await axios.get('https://jsonplaceholder.typicode.com/users');

      console.log({ postId })

      const targetPostComments = postCommentsDummyData.filter(comment => comment.postId === postId);

      dispatch(setPostComments(targetPostComments));

    } catch (error) {

    } finally {
      dispatch(setPostLoaders('postCommentsLoader', false));

    }

  }
}

export const addComment = (payload, { onSuccess }) => {
  return async (dispatch, getState) => {

    payload = {
      id: v4(),
      // new added fields
      totalReplies: 0,
      totalLikes: 0,
      ...payload
    }

    dispatch(setPostLoaders('addPostCommentsLoader', true));

    // await apiCreateComment
    //   .post(deletePayload)
    //   .then((response) => {

    //   })
    //   .catch((error) => {
    //     dispatch(etPostLoaders('addPostCommentsLoader', false));
    //     toast.error("Error in deleting task");
    //     console.error("Delete Task Error: ", error);
    //   });

    try {

      const response = await axios.get('https://jsonplaceholder.typicode.com/users');

      const newComment = payload;

      const { postComments } = getState().posts;

      dispatch(setPostComments([
        newComment,
        ...postComments
      ]));

      typeof onSuccess === 'function' && onSuccess(newComment);


    } catch (error) {

      console.error("Error: Add comment : ", error);

    } finally {
      dispatch(setPostLoaders('addPostCommentsLoader', false));
    }

  }
}

export const setCommentInputDetails = (payload) => {
  return (dispatch) => {

    payload = {
      inputType: 'comment',
      parentCommentId: null,
      ...payload
    }

    dispatch({
      type: SET_COMMENT_INPUT_DETAILS,
      payload
    })

  }
}