import axios from "axios";
import { handleResponse, handleError } from "./response";

const BASE_URL = "https://mefolia.com:9000/api/v1";
// const BASE_URL = "https://int.checkndout.com:8000/api/v1";
// const BASE_URL = 'http://adb7f4aebbb934388bb66cd413657c78-639364869.eu-west-2.elb.amazonaws.com/api/v1';

async function getResources(resource, id) {
  let url = "";
  if (id) url = `${BASE_URL}/${resource}/${id}`;
  else url = `${BASE_URL}/${resource}`;

  let response = await axios.get(url);

  if (response.status === 200) {
    response = handleResponse(response);
  } else {
    response = handleError(response);
  }
  return response;
}

async function modifyResource(resource, method, payload, header = {}) {
  const authUser = JSON.parse(localStorage.getItem("authUser")) || {};
  const { token } = authUser;

  const headers = {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "x-requestid": "00000000-0000-0000-0000-000000000001",
    Authorization: `Bearer ${token || ""}`,
    ...header,
  };

  const options = {
    headers: headers,
  };

  let response = axios[method](`${BASE_URL}/${resource}`, payload, options);

  if (response.status === 200) {
    response = handleResponse(response);
  } else {
    response = handleError(response);
  }

  return response;
}

async function removeResource(resource, id) {
  let response = "hello";

  try {
    response = await axios.delete(`${BASE_URL}/${resource}`, id);
  } catch (error) {
    console.log("catch: ", error);
  }

  if (response.status === 200) response = handleResponse(response);
  else response = handleError(response);

  return response;
}

/** @param {string} resource */
const getAll = (resource) => {
  return getResources(resource);
};

/** @param {string} resource */
/** @param {string} id */
const getSingle = (resource, id) => {
  return getResources(resource, id);
};

/** @param {string} resource */
/** @param {object} model */
const post = (resource, payload, header) => {
  return modifyResource(resource, "post", payload, header);
};

/** @param {string} resource */
/** @param {object} model */
const put = (resource, payload) => {
  return modifyResource(resource, "put", payload);
};

/** @param {string} resource */
/** @param {object} model */
const patch = (resource, payload) => {
  return modifyResource(resource, "patch", payload);
};

/** @param {string} resource */
/** @param {string} id */
const remove = (resource, id) => {
  return removeResource(resource, id);
};

export const apiProvider = {
  getAll,
  getSingle,
  post,
  put,
  patch,
  remove,
};
