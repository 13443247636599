import { fade, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import React from 'react';
import { useGlobalStyles } from '../utils/Styles/GlobalStyles';
import InputLabelCNO from './InputLabelCNO';
import * as momenttz from 'moment-timezone';
import { formatTimeWithTZ } from '../utils/Helpers';
import moment from 'moment/moment';

export default function DateTimePickerCNO({
  datePickerLabel = "Date",
  dateName,
  dateDisabled = false,
  minDate,
  maxDate,
  disablePast = false,
  disableFuture = false,
  restDatePicker = {},
  onDateChange,
  datePlaceholder,
  dateFormat = 'MMM dd, yyyy',

  timePickerLabel = "Time",
  timeName,
  timeDisabled = false,
  restTimePicker = {},
  onTimeChange,
  timePlaceholder,

  iconColor,
  formik,
  isRequired = true,
  isMarkedComplete

}) {

  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  return (
    <div style={{ display: 'flex', marginBottom: 10, flex: 1 }}>

      {dateName ? <div style={{ flex: 1, marginRight: 10 }}>

        <InputLabelCNO
          label={datePickerLabel}
          isRequired={isRequired}
        />

        <KeyboardDatePicker
          className={clsx(GlobalStyles.picker, {
            [GlobalStyles.start]: iconColor === 'red',
            [GlobalStyles.end]: iconColor === 'green',
            [styles.pickerDisabled]: dateDisabled
          })}
          margin="dense"
          value={formatTimeWithTZ(formik ? formik.values[dateName] : moment().toISOString())}
          format={dateFormat}
          inputVariant='outlined'
          placeholder={datePlaceholder}
          fullWidth
          onChange={date => {

            if (formik) {
              formik.setFieldTouched(dateName, true);
              formik.setFieldValue(dateName, moment(date).toISOString());
            }

            if (onDateChange) {
              onDateChange(date);
            }

          }}
          {...formik ? { error: formik.touched[dateName] && Boolean(formik.errors[dateName]) } : {}}
          {...formik ? { helperText: formik.errors[dateName] } : {}}
          minDate={minDate}
          maxDate={maxDate}
          disablePast={disablePast}
          disableFuture={disableFuture}
          disabled={dateDisabled}
          {...restDatePicker}
        />

      </div> : null}

      {
        timeName ? (
          <div style={{ maxWidth: dateName ? '42%' : '100%', flex: 1 }}>
            <InputLabelCNO
              label={timePickerLabel}
              isRequired={isRequired}
            />

            <KeyboardTimePicker
              className={clsx(GlobalStyles.picker, {
                [GlobalStyles.start]: iconColor === 'red',
                [GlobalStyles.end]: iconColor === 'green',
                [styles.pickerDisabled]: timeDisabled
              })}
              margin="dense"
              value={formatTimeWithTZ(formik ? formik.values[timeName] : moment().toISOString())}
              format="hh:mm a"
              inputVariant='outlined'
              placeholder={timePlaceholder}
              onChange={time => {

                if (formik) {
                  formik.setFieldTouched(timeName, true);
                  formik.setFieldValue(timeName, moment(time).toISOString());
                }

                if (onTimeChange) {
                  onTimeChange(time);
                }

              }}
              {...formik ? { error: formik.touched[timeName] && Boolean(formik.errors[timeName]) } : {}}
              {...formik ? { helperText: formik.errors[timeName] } : {}}
              disabled={timeDisabled}
              {...restTimePicker}
            />
          </div>
        ) : null
      }

    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  pickerDisabled: {
    opacity: 0.8,
    color: fade(theme.colors.INPUT_TEXT_COLOR, 0.8),
  }
}))
