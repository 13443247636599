import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import DialogCNO from '../../library/DialogCNO';
import FormActionButtons from '../FormActionButtons';
import * as yup from 'yup';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerSuggest } from '../../actions/suggestionsActions';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { toggleAddCustomerDialog } from '../../actions/customerActions';
import AddCustomer from '../AddCustomer/AddCustomer';
import { createJob } from '../../actions/jobActions';
import { useHistory } from 'react-router-dom';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { AppConstants } from '../../utils/AppConstants';
import { TaskStatus } from '../../utils';
import { getTitleFromCustomer, validateStartDateEndDate } from '../../utils/Helpers';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';
import { ItemType, setMediasPendingToLS, triggerUploadMediasLsBg } from '../../utils/MediaUpload';
import LocalStorageConstants from '../../utils/LocalStorageConstants';

function DuplicateJobDialog({
  open,
  onClose,
  job,
  closeMoreActions
}) {

  const styles = useStyles();

  const dispatch = useDispatch();
  const suggestions = useSelector(state => state.suggestions);
  const { subTasks } = useSelector(state => state.subTasks);
  const { isJobLoader, getJobMediaLoader } = useSelector(state => state.jobs);

  const validationSchema = yup.object({
    customer: yup.object().required("Contact person is required.").nullable(),
    startDate: yup.date('invalid date').required("Start date is required."),
    startTime: yup.date('invalid date').required("Start time is required."),
    endDate: yup.date('invalid date')
      .required("End date is required.")
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      ),
    endTime: yup.date('invalid date')
      .required("End time is required.")
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      ),
  });

  const history = useHistory();

  const onDuplicateSuccess = (taskId) => {

    const mediasPending = {
      from: ItemType.Job,
      to: ItemType.Job,
      medias: job.mediaLinks,
      itemId: taskId,
    }

    setMediasPendingToLS([mediasPending]);

    handleClose();
    history.push(`/job/${taskId}`);

    LocalStorageConstants.setItem("mediaPendingLoader", true);
    console.log("window.dispatchEvent: 11111");
    window.dispatchEvent(new Event("media-pending-loader-storage"));

    triggerUploadMediasLsBg();

  };

  const handleCreateDuplicate = async (values) => {

    console.log("handleCreateDuplicate====>  values", values);

    const payload = {
      ...job,
      status: TaskStatus.Open,
      mediaLinks: [],
      customerId: values.customer.id,
      customerText: values.customer.title,
      mapAddress: values.location,
      lat: values.lat,
      lng: values.lng,
      teamChatGroupId: null,
      customerChatGroupId: null,
      startDate: values.startDate,
      startTime: values.startTime,
      endDate: values.endDate,
      endTime: values.endTime,
      title: getTitleFromCustomer(values.customer, job.title)
    };

    console.log("handleCreateDuplicate====>  subTasks", subTasks);
    console.log("handleCreateDuplicate====>  payload", payload);

    dispatch(createJob(
      payload,
      subTasks,
      onDuplicateSuccess,
      "job",
      {
        success: "Duplicate Job created successfully.",
        error: "Error in creating duplicate job."
      }
    ));


  };

  const formik = useFormik({
    initialValues: {
      customer: {
        id: job.customerId,
        title: job.customerText
      },
      location: job.mapAddress,
      lat: job.lat,
      lng: job.lng,
      startDate: AppConstants.DEFAULT_START_DATE,
      startTime: AppConstants.DEFAULT_START_TIME,
      endDate: AppConstants.DEFAULT_END_TIME,
      endTime: AppConstants.DEFAULT_END_TIME,
    },
    validationSchema,
    onSubmit: (values) => handleCreateDuplicate(values)
  });

  const handleAddCustomerDialog = () => {
    dispatch(toggleAddCustomerDialog(true));
  };

  const handleClose = () => {
    formik.resetForm();
    closeMoreActions();
    onClose();
  };

  console.log("DuplicateJobDialog====> ", { open, job, values: formik.values });

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle={`Duplicate Job - ${job.title}`}
      minHeight="fit-content"
      width='50vw'
    >

      {
        (isJobLoader || getJobMediaLoader) &&
        <FullScreenLoaderCNO />
      }

      <form onSubmit={formik.handleSubmit} >
        <div className={styles.customerWrapper} style={{ marginBottom: '1rem' }}>
          <AutocompleteCNO
            name='customer'
            label='Contact Person'
            formik={formik}
            placeholder='Search Customer'
            data={suggestions}
            optionLabel={x => x.title || ""}
            renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
            onSuggestionClick={(value) => {

              if (value) {
                formik.setValues({
                  ...formik.values,
                  customer: value,
                  location: value.mapAddress,
                  lat: value.lat,
                  lng: value.lng
                });
              }

            }}
            onChange={(value) => {
              if (value) {
                dispatch(fetchCustomerSuggest(value));
              }
            }}
            isRequired
          />
          <Icon
            path={mdiPlus}
            size='43px'
            color='goldenrod'
            onClick={handleAddCustomerDialog}
          />
        </div>

        <DateTimePickerCNO
          dateName="startDate"
          datePlaceholder="Start Date"
          datePickerLabel='Start by Date'
          onDateChange={date => {
            formik.setFieldValue('startDate', date);
          }}

          timeName="startTime"
          timePlaceholder="Start Time"
          timePickerLabel='Start by Time'
          onTimeChange={date => {
            formik.setFieldValue('startTime', date);
          }}

          formik={formik}
          disablePast={true}
          iconColor="red"
        />

        <DateTimePickerCNO
          dateName="endDate"
          datePlaceholder="Finish Date"
          datePickerLabel='Finish by Date'
          onDateChange={date => {
            formik.setFieldValue('endDate', date);
          }}

          timeName="endTime"
          timePlaceholder="Finish Time"
          timePickerLabel='Finish by Time'
          onTimeChange={date => {
            formik.setFieldValue('endTime', date);
          }}

          formik={formik}
          minDate={formik.values.startDate}
          iconColor="green"
        />

        <Typography style={{ marginTop: 20 }}>
          Are you sure you want to create duplicate job?
        </Typography>

        <FormActionButtons
          rightText='Yes'
          leftText='No'
          leftClick={handleClose}
          disabled={!formik.isValid}
        />
      </form>

      <AddCustomer
        onAdd={(customer) => {
          console.log("===== customer : add: ", customer);
          const _customer = {
            firstName: customer.firstName,
            id: customer.id,
            isActive: true,
            lastName: customer.lastName,
            title: `${customer.firstName} ${customer.lastName}`,
            profilePic: null
          };
          formik.setFieldValue('customer', _customer);
          formik.setFieldValue('location', customer.mapAddress);
          formik.setFieldValue('lat', customer.lat);
          formik.setFieldValue('lng', customer.lng);
        }}
        jobLocation={formik.values.location}
      />

    </DialogCNO>
  )
}

const useStyles = makeStyles((theme) => ({
  customerWrapper: {
    display: 'flex',
    '&>*:first-child': {
      flex: 1
    },
    '&>*:last-child': {
      alignSelf: 'flex-end',
    }
  }
}))

export default DuplicateJobDialog;