import { Box, Divider, makeStyles } from '@material-ui/core';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { PostReactions } from '../enums';
import { getPostComments, getPostReactions, updatePost } from '../../../actions/postActions';
import { IconMain, MetaText } from '../../../library';
import { nFormatter } from '../../../utils/Helpers';
import PostShareExternal from './PostShareExternal';
import PostReactionsDetailDialog from './PostReactionsDetailDialog';
import PostCommentsDetailDialog from './PostCommentsDetailDialog';

const PostActionsBar = ({ post }) => {

  const styles = useStyles();

  const dispatch = useDispatch();

  const [isPostReactionVisible, setIsPostReactionVisible] = useState(false);
  const [isPostCommentVisible, setIsPostCommentVisible] = useState(false);

  const handlePostReaction = () => {

    const updatedPost = {
      ...post,
      reactionCounts: post.myReactionId ? post.reactionCounts - 1 : post.reactionCounts + 1,
      myReactionId: post.myReactionId ? null : PostReactions.ids.Like
    };

    dispatch(updatePost(updatedPost))

  }

  const openPostReactionDetails = () => {
    dispatch(getPostReactions({ postId: post.id }));
    setIsPostReactionVisible(true);
  }

  const handlePostComment = () => {
    dispatch(getPostComments({ postId: post.id }));
    setIsPostCommentVisible(true);
  }

  console.log("PostActionBar: icon: ", post.myReactionId, post.myReactionId ? 'thumbs-up' : 'thumbs-up-outline')

  return (
    <Box className={styles.container}>

      <Box className={styles.statsWrapper}>
        <Box className={styles.reactionCountsWrapper} onClick={openPostReactionDetails} >
          <IconMain name='thumb-up' size='1.1rem' />
          <MetaText>{nFormatter(post.reactionCounts)}</MetaText>
        </Box>

        <Box className={styles.commentsSharesWrapper} >
          <MetaText>{nFormatter(post.commentsCounts)} comments</MetaText>
        </Box>
      </Box>

      <Divider style={{ marginBottom: 8, marginTop: 8 }} />

      <Box className={styles.actionsWrapper}>
        <Box className={styles.actionItemWrapper} onClick={handlePostReaction}>
          <IconMain
            name={post.myReactionId ? 'thumb-up' : 'thumb-up-outline'}
            size='1.1rem'
            color={'#9BBE00'}
          />
          <MetaText>Like</MetaText>
        </Box>

        <Box className={styles.actionItemWrapper} onClick={handlePostComment} >
          <IconMain name='comment-outline' size='1.1rem' />
          <MetaText>Comment</MetaText>
        </Box>

        <PostShareExternal post={post} />
      </Box>

      <PostReactionsDetailDialog
        open={isPostReactionVisible}
        onClose={() => {
          setIsPostReactionVisible(false);
        }}
      />

      <PostCommentsDetailDialog
        post={post}
        open={isPostCommentVisible}
        onClose={() => {
          setIsPostCommentVisible(false);
        }}
      />

      {/* {
        isPostReactionVisible &&
        <PostReactionsDetail
          isVisible={isPostReactionVisible}
          toggleIsVisible={() => {
            setIsPostReactionVisible(false);
            dispatch(setPostReactions([]));
          }}
        />
      }

      {
        isPostCommentVisible &&
        <PostCommentsDetail
          post={post}
          isVisible={isPostCommentVisible}
          toggleIsVisible={() => {
            setIsPostCommentVisible(false);
            // dispatch(setPostReactions([]));
          }}
        />
      } */}

    </Box>
  )
}

export default PostActionsBar

const useStyles = makeStyles((theme) => ({

  container: {
    marginTop: 12
  },

  statsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 2,
    paddingRight: 2,
  },

  reactionCountsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    cursor: 'pointer'
  },

  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 4,
    paddingRight: 4,
  },
  actionItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    paddingLeft: 20,
    paddingRight: 20,
    cursor: 'pointer'
  }

}))