import React, { useEffect, useState, Fragment } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Switch, Route, Redirect } from "react-router-dom";
import AddCustomer from "./components/AddCustomer/AddCustomer";
import AddJob from "./components/AddJob/AddJob";
import Header from "./components/Header";
import HomePage from "./pages/HomePage/HomePage";
import JobDetailsPage from "./pages/JobDetailsPage/JobDetailsPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import MapPage from "./pages/MapPage/MapPage";
import AdminPanel from "./pages/Admin/AdminPanel";
import UserProfilePage from "./pages/UserProfilePage/UserProfilePage";
import "react-toastify/dist/ReactToastify.css";
import RightsManager from "./utils/RightsManager";
import ChatPage from "./pages/ChatPage/ChatPage";
import CallPage from "./pages/CallPage/CallPage";
import ChatMessagesPage from "./pages/ChatMessagesPage/ChatMessagesPage";
import { useDispatch, useSelector } from "react-redux";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import {
  fetchActiveGroupMembers,
  fetchChatGroups,
  fetchMessageCount,
  fetchMessageProfileCount,
  setChatGroups,
  setChatMessages,
  setChatSnippetList,
  setMessageCount,
  toggleCallModal,
  toggleCallModalOpenOnce,
  setMessageReceived,
} from "./actions/chatActions";

import {
  getChatMessageSnippetListFromLocalStorage,
  getCreatedByUser,
  getCurrentChatProfile,
  getFilteredAndSortedChatList,
  getGroupListFromLocalStorage,
  getProfileListFromLocalStorage,
  sortChatsList,
} from "./utils/ChatUtils/Helpers";

import {
  apiMemberSearch,
  apiMessageGroupSearch,
  apiMessageProfileCount,
} from "./api";

import { setupSignalRConnection } from "./utils/SignaRConnections";
import { GiftedChat } from "react-native-gifted-chat";
import { MessageType } from "./utils";
import { scroller } from "react-scroll";

import LocalStorageConstants from "./utils/LocalStorageConstants";

import chatSnippetListTest from "./utils/ChatUtils/Test Data/ChatUserMemberTestData.json";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import EmployeeListPage from "./pages/EmployeeManagement/EmployeeListPage";
import EmployeeDetailsPage from "./pages/EmployeeManagement/EmployeeDetailsPage";
import CustomerListPage from "./pages/CustomerManagement/CustomerListPage";
import CustomerDetailsPage from "./pages/CustomerManagement/CustomerDetailsPage";
import CompanyListPage from "./pages/CompanyManagement/CompanyListPage";
import CompanyDetailsPage from "./pages/CompanyManagement/CompanyDetailsPage";
import MyCompanyPage from "./pages/CompanyManagement/MyCompanyPage";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import QuotationListingPage from "./pages/QuotationManagement/QuotationListingPage";
import QuotationDetailsPage from "./pages/QuotationManagement/QuotationDetailsPage";
import InvoiceListingPage from "./pages/InvoiceManagement/InvoiceListingPage";
import InvoiceDetailsPage from "./pages/InvoiceManagement/InvoiceDetailsPage";
import ChatCallScreen from "./components/ChatCallScreen/ChatCallScreen";

import { useIsFeatureAllowedInSubscription } from "./hooks";
import { SubscriptionFeaturesList } from "./utils/Subscription/SubscriptionFeatures";
import { getCurrentSubscriptionDetailsFromLocal } from "./utils/Subscription/Helpers";

import {
  fetchCurrentSubscriptionDetails,
  fetchPaymentDue,
  setPaymentDue,
} from "./actions/subscriptionActions";

import SubscriptionNotAllowedDialog from "./components/SubscriptionNotAllowedDialog";
import AppointmentsListingPage from "./pages/AppointmentManagement/AppointmentsListingPage";
import { AppointmentDetailsPage } from "./pages/AppointmentManagement";
import CalendarPage from "./pages/CalendarPage";
import LandingPage from "./pages/LandingPage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import messageReceivedSoundUrl from "./Sounds/messageReceived.mp3";
import { Howl } from "howler";
import Privacy from "./components/LandingPage/PublicPages/Privacy";
import TermsConditions from "./components/LandingPage/PublicPages/TermsConditions";
import EULA from "./components/LandingPage/PublicPages/EULA";
import Disclaimer from "./components/LandingPage/PublicPages/Disclaimer";
import Cookie from "./components/LandingPage/PublicPages/Cookie";
import CacheBuster from "./CacheBuster";
import RouteChangeTracker from "./utils/RouteChangeTracker";
import ReactGA from "react-ga";
import AboutUs from "./components/LandingPage/PublicPages/AboutUs";
import ContactUs from "./components/LandingPage/PublicPages/ContactUs";
import Help from "./components/LandingPage/PublicPages/Help";
import AddInvoice from "./components/Invoice/AddInvoice";
import DashboardPage from "./pages/Dashboard/DashboardPage";

import TrialPeriodSubscriptionModal from "./components/TrialPeriodSubscriptionModal";
import SubscriptionReminderDialog from "./components/SubscriptionReminderDialog";
import moment from "moment";
import { AppConstants } from "./utils/AppConstants";
import { updateCompanyDetails } from "./actions/customerCompanyActions";
import { isTrialPeriod } from "./utils/Helpers";
import AppMessageRibbon from "./library/AppMessageRibbon";
import MediasUploadResultDialog from "./library/MediasUploadResultDialog";
import { EventDetailsPage, EventsHomePage } from "./pages/EventManagement";

import {
  FormBuilderPage,
  FormDataDetailPage,
  FormDesignerPage,
} from "./features/formBuilder";

import JobPage from "./pages/JobPage";
import { PostListingPage } from "./pages/PostsManagement";
import VisitorPass from "./utils/Appointments/VisitorPass";
import NewApp from "./utils/MapUtilsNew/NewApp";
import PostDetailPage from "./pages/PostDetailPage";
import TestPage from "./pages/TestPage/TestPage";
import TestDetailsPage from "./pages/TestDetailsPage/TestDetailsPage";
import AdminTestPage from "./pages/AdminTestPage";
import Countdown from "./pages/TestPage/Countdown";
import TestPaper from "./pages/TestPage/TestPaper"
import Result from "./pages/TestPage/Result";
import ServicesListingPage from "./pages/ServiceManagement/ServiceListingPage";
import ServiceDetailPage from "./pages/serviceDetailPage/ServiceDetailPage";
import ServiceProviderListPage from "./pages/ServiceProviderManagement/ServiceProviderListPage";
import ServiceProviderDetailsPage from "./pages/ServiceProviderManagement/ServiceProviderDetailsPage";

const TRACKING_ID = "UA-202694123-1";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  library.add(fas);

  const PROFILE_LIST_THRESHOLD = 50;

  const { isAddJobDialog } = useSelector((state) => state.jobs);
  const { isAddInvoiceDialog } = useSelector((state) => state.invoices);

  const styles = useStyles();

  const isUserAdmin = RightsManager.isAdmin();
  const isUserMember = RightsManager.isMember();

  console.log("isUserAdmin ==> ", { isUserAdmin });

  const authUser = JSON.parse(localStorage.getItem("authUser")) || {};
  const { token = "" } = authUser;

  const history = useHistory();

  const isLandingPagePath = history.location.pathname === "/";
  const isPrivacyPagePath = history.location.pathname === "/privacy";
  const isTermsPagePath = history.location.pathname === "/terms";
  const isEulaPagePath = history.location.pathname === "/eula";
  const isDisclaimerPagePath = history.location.pathname === "/disclaimer";
  const isCookiePagePath = history.location.pathname === "/cookie";
  const isAboutUsPath = history.location.pathname === "/aboutus";
  const isContactUsPath = history.location.pathname === "/contactus";
  const isHelpPath = history.location.pathname === "/help";

  const isPublicPagePath = !(
    isLandingPagePath ||
    isPrivacyPagePath ||
    isTermsPagePath ||
    isEulaPagePath ||
    isDisclaimerPagePath ||
    isCookiePagePath ||
    isAboutUsPath ||
    isContactUsPath ||
    isHelpPath
  );

  const dispatch = useDispatch();

  const {
    messageCount,
    messages,
    messageReceived,
    activeGroupMembers,
    isCallModalOpen,
    isCallModalOpenOnce,
  } = useSelector((state) => state.chats);

  const [localMessageCount, setLocalMessageCount] = useState(messageCount);
  const [localGroupMembers, setLocalGroupMembers] = useState([]);
  const [localChatMessages, setLocalChatMessages] = useState([]);

  console.log(
    "~~~~~~~~~~~~~~~~~~~~~~~~APP JS isCallModalOpenOnce~~~~~~~~~~~~~~~~~~~~",
    isCallModalOpenOnce
  );

  useEffect(() => {
    setLocalMessageCount(messageCount);
  }, [messageCount]);

  useEffect(() => {
    setLocalGroupMembers(activeGroupMembers);
  }, [activeGroupMembers]);

  useEffect(() => {
    setLocalChatMessages(messages);
  }, [messages]);

  useEffect(() => {
    if (isLandingPagePath) {
      return;
    }

    let currentChatProfile = localStorage.getItem("currentChatProfile");
    if (!currentChatProfile) {
      currentChatProfile = {};
    } else {
      currentChatProfile = JSON.parse(currentChatProfile);
    }
    if (currentChatProfile && currentChatProfile.contactType === "group") {
      const groupMemerPayload = {
        pageSiz: 10,
        pageIndex: 0,
        orderBy: "",
        sortDecending: true,
        groupId: currentChatProfile.id,
      };

      dispatch(fetchActiveGroupMembers(groupMemerPayload));
    }
  }, []);

  // get the total message count from API
  useEffect(() => {
    if (!authUser.token) return;
    dispatch(fetchMessageCount());
  }, []);

  // get the message profile count
  useEffect(() => {
    if (!authUser.token) return;
    dispatch(fetchMessageProfileCount());
  }, []);

  // fetch the chat groups
  useEffect(() => {
    if (!authUser.token) return;

    let localChatGroupList = localStorage.getItem("localChatGroupList");
    if (!localChatGroupList) {
      localChatGroupList = [];
    } else {
      localChatGroupList = JSON.parse(localChatGroupList);
    }

    if (localChatGroupList.length < 10) {
      const chatGroupsPayload = {
        pageSize: 10,
        pageIndex: 1,
        orderBy: "",
        sortDecending: true,
        profileId: authUser.id,
      };

      dispatch(fetchChatGroups(chatGroupsPayload));
    }
  }, []);

  const messageReceivedSound = new Howl({
    src: [messageReceivedSoundUrl],
    loop: false,
    preload: true,
  });

  const fetchChatProfileDetails = async (id, isGroup) => {
    console.log("resolve: started");

    if (isLandingPagePath) {
      return;
    }

    if (isGroup) {
      // resolve group
      await fetchGroupForResolving(authUser, id, updateChatProfile);
    } else {
      // resolve user

      await fetchUserForResolving(id, updateChatProfile);
    }
  };

  const BASE_URL = "https://mefolia.com:9000";
  // const BASE_URL = 'https://int.checkndout.com:8000';
  //const BASE_URL = 'http://adb7f4aebbb934388bb66cd413657c78-639364869.eu-west-2.elb.amazonaws.com';
  const HUB_TOKEN = authUser ? authUser.token : null;
  const getAccessToken = () => {
    return HUB_TOKEN;
  };
  const messageHubUrl = `${BASE_URL}/messagehub`;

  // listener for chat events
  const chatSubscriber = setupSignalRConnection(
    messageHubUrl,
    {
      chatEvent: async (id, message, creationDate, createdBy) => {
        console.log("message received: toMemberId", message);
        console.log("message received: createdBy", createdBy);
        console.log("message received: authUser.id", authUser.id);

        //////////////////////////////////

        console.log("NEW MESSAGE | CHAT", id, message, creationDate, createdBy);

        if (message.messageType === MessageType.VideoCall) {
          console.log(
            "---APPJS----OFFER: VideoCall Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "IncomingVideoCall",
              isVideoCall: true,
            })
          );
          dispatch(toggleCallModal(true));
        } else if (message.messageType === MessageType.AudioCall) {
          console.log(
            "---APPJS----OFFER: AudioCall Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "IncomingAudioCall",
              isVideoCall: false,
            })
          );
          dispatch(toggleCallModal(true));
        } else if (message.messageType === MessageType.AutoOffer) {
          console.log(
            "---APPJS----OFFER: AUTO OFFER Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "AutoOffer",
              isVideoCall: true,
            })
          );
        } else if (message.messageType === MessageType.VideoCallAccepted) {
          console.log(
            "---APPJS----ACCEPT: Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "AnswerVideoCall",
              isVideoCall: true,
            })
          );
        } else if (message.messageType === MessageType.AudioCallAccepted) {
          console.log(
            "---APPJS----ACCEPT: Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "AnswerAudioCall",
              isVideoCall: false,
            })
          );
        } else if (message.messageType === MessageType.AutoAnswer) {
          console.log(
            "---APPJS----ACCEPT: Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "AutoAnswer",
              isVideoCall: true,
            })
          );
        } else if (message.messageType === MessageType.Candidate) {
          console.log(
            "---APPJS----CANDIDATE: Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "CandidateCall",
              isVideoCall: true,
            })
          );
        } else if (message.messageType === MessageType.AudioCallMissed) {
          console.log(
            "---APPJS----AudioCallMissed: Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "AudioCallMissed",
              isVideoCall: false,
            })
          );
        } else if (message.messageType === MessageType.VideoCallMissed) {
          console.log(
            "---APPJS----VideoCallMissed: Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "VideoCallMissed",
              isVideoCall: true,
            })
          );
        } else if (message.messageType === MessageType.AudioCallEnded) {
          console.log(
            "---APPJS----AudioCallEnded: Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "AudioCallEnded",
              isVideoCall: false,
            })
          );
        } else if (message.messageType === MessageType.VideoCallEnded) {
          console.log(
            "---APPJS----VideoCallEnded: Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "VideoCallEnded",
              isVideoCall: true,
            })
          );
        } else if (message.messageType === MessageType.JoinCall) {
          console.log(
            "---APPJS----JoinCall: Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "JoinCall",
              isVideoCall: true,
            })
          );
        } else if (message.messageType === MessageType.VideoCallRejected) {
          console.log(
            "---APPJS----JoinCall: Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "VideoCallRejected",
              isVideoCall: true,
            })
          );
        } else if (message.messageType === MessageType.AudioCallRejected) {
          console.log(
            "---APPJS----JoinCall: Setting local params--------",
            message
          );
          dispatch(
            setMessageReceived({
              id,
              message,
              creationDate,
              createdBy,
              callContext: "AudioCallRejected",
              isVideoCall: false,
            })
          );
        } else {
          console.log("sender receving the message");
          const currentPage = window.location.pathname;

          let currentChatProfile = getCurrentChatProfile();
          const chatId = currentChatProfile ? currentChatProfile.id : null;

          messageReceivedSound.play();
          // to increase the total message count
          handleTotalHeaderMessageCount(
            currentPage,
            setLocalMessageCount,
            dispatch
          );

          // to handle the profile message count
          await handleUpdateChatSnippetList(
            message,
            createdBy,
            currentPage,
            id,
            creationDate,
            fetchChatProfileDetails,
            dispatch
          );

          let createdByUser = {};

          setLocalGroupMembers((members) => {
            createdByUser = getCreatedByUser(createdBy, members);
            return members;
          });

          console.log("message received: createdByuser: ", createdByUser);

          handleTextChatMessage(
            message,
            authUser,
            createdBy,
            chatId,
            id,
            creationDate,
            createdByUser,
            setLocalChatMessages,
            dispatch
          );
        }

        //////////////////////////////////
      },

      groupMessageEvent: (id, message, creationDate, createdBy) => {
        console.log("system_group_message: ", {
          id,
          message,
          creationDate,
          createdBy,
        });
      },
    },
    getAccessToken
  );

  // establish signalR connection for chats
  useEffect(() => {
    if (isLandingPagePath) {
      return;
    }
    // dispatch(chatSubscriber);
  }, []);

  // set initial chat profile list on load of app
  useEffect(() => {
    if (!authUser.token) return;

    (async function saveChatProfileListIIFE() {
      let localChatSnippetList = [];

      localChatSnippetList = getChatMessageSnippetListFromLocalStorage();

      // make api call to fetch offline chat snippets
      localChatSnippetList = await fetchChatSnippets(localChatSnippetList);

      // add group to chat snippet list if chatSnippet length is less
      if (localChatSnippetList.length < PROFILE_LIST_THRESHOLD) {
        let localGroupList = await getGroupListFromLocalStorage();
        console.log(
          "====group==== getGroupListFromLocalStorage ",
          localGroupList
        );

        if (localGroupList.length > 0) {
          localChatSnippetList = getFilteredAndSortedChatList(
            localChatSnippetList,
            localGroupList
          );
        } else {
          localChatSnippetList = await fetchChatGroupsList(
            localChatSnippetList
          );
        }
      }

      // add member to chat snippet list if chatSnippet length is less
      if (localChatSnippetList.length < PROFILE_LIST_THRESHOLD) {
        let localProfileList = await getProfileListFromLocalStorage();
        console.log(
          "====localProfileList==== getProfileListFromLocalStorage ",
          localProfileList
        );

        if (localProfileList.length > 0) {
          localProfileList = getFilteredAndSortedChatList(
            localChatSnippetList,
            localProfileList
          );
        } else {
          localChatSnippetList = await fetchProfileList(localChatSnippetList);
        }
      }

      // finally dispatch chat snippet list to redux
      dispatch(setChatSnippetList(localChatSnippetList));
    })();
  }, []);

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((resp) => resp.json())
      .then((response) => {
        console.log("====response location ====", response);
        localStorage.setItem(
          "country",
          JSON.stringify({
            country: response.country_name,
            countryCode: response.country_code,
          })
        );
        LocalStorageConstants.setItem("timezone", response.timezone);
      })
      .catch((error) => console.log("Network Error"));
  }, []);

  const authUserCompany = LocalStorageConstants.getItem("authUserCompany");
  // fetch subscription details
  useEffect(() => {
    (async function subscriptionIIFE() {
      if (isLandingPagePath) {
        return;
      }

      // const subscriptionDetails = getCurrentSubscriptionDetailsFromLocal();
      // console.log("==========app routes======subscriptionDetails=====", subscriptionDetails);

      if (authUserCompany) {
        dispatch(
          fetchCurrentSubscriptionDetails(authUserCompany.subscriptionType)
        );

        // for first login activate trial period
        if (
          !authUserCompany.paymentDate ||
          authUserCompany.paymentDate === "0001-01-01T00:00:00"
        ) {
          const isTrialPeriodActive = isTrialPeriod();

          if (isTrialPeriodActive) return;

          const paymentDate = new Date();

          const paymentDuePayload = {
            dueDate: moment(paymentDate)
              .add(AppConstants.SUBSCRIPTION_TRIAL_PERIOD, "days")
              .toISOString(),
            subscriptionType: authUserCompany.subscriptionType,
          };

          LocalStorageConstants.setItem(
            `trialEndDate-${authUserCompany.id}`,
            paymentDuePayload.dueDate
          );

          dispatch(setPaymentDue(paymentDuePayload));

          const updateCompanyPayload = {
            ...authUserCompany,
            paymentDate: paymentDate.toISOString(),
          };

          dispatch(updateCompanyDetails(updateCompanyPayload));
        }
      }
    })();
  }, [isLandingPagePath]);

  const {
    isAppointmentAllowed,
    isBasicReportAnalyticsAllowed,
    isCallingAudioVideoAllowed,
    isChatAllowed,
    isCustomerChatAllowed,
    isCustomerLoginAllowed,
    isDashboardAllowed,
    isDetailedReportAnalyticsAllowed,
    isInvoiceAllowed,
    isJobCreationAllowed,
    isManageCompanyAllowed,
    isManageUsersAllowed,
    isPaymentAllowed,
    isQuotationAllowed,
    isShareAllowed,
    isTrackingAllowed,
  } = useIsFeatureAllowedInSubscription();

  console.log("=========FEATURE AVAILABILITY============", {
    isAppointmentAllowed,
    isBasicReportAnalyticsAllowed,
    isCallingAudioVideoAllowed,
    isChatAllowed,
    isCustomerChatAllowed,
    isCustomerLoginAllowed,
    isDashboardAllowed,
    isDetailedReportAnalyticsAllowed,
    isInvoiceAllowed,
    isJobCreationAllowed,
    isManageCompanyAllowed,
    isManageUsersAllowed,
    isPaymentAllowed,
    isQuotationAllowed,
    isShareAllowed,
    isTrackingAllowed,
  });

  const isAudioCall =
    isCallingAudioVideoAllowed &&
    messageReceived &&
    !messageReceived.isVideoCall;
  const isVideoCall =
    isCallingAudioVideoAllowed &&
    messageReceived &&
    messageReceived.isVideoCall;

  useEffect(() => {
    (async function getPaymentDue() {
      if (!authUserCompany) return;

      const payload = {};

      dispatch(fetchPaymentDue(payload));
    })();
  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: "{{CLIENT_SECRET}}",
  };

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <div className={isLandingPagePath ? "" : styles.container}>
            <RouteChangeTracker />

            <SubscriptionReminderDialog />

            {isAddJobDialog && isUserAdmin && <AddJob />}
            {isAddInvoiceDialog && isUserAdmin && <AddInvoice />}

            {isCallModalOpen && (isAudioCall || isVideoCall) && (
              <ChatCallScreen />
            )}

            {isCallModalOpen && !(isAudioCall || isVideoCall) && (
              <SubscriptionNotAllowedDialog
                isAllowed={isAudioCall || isVideoCall}
                featureName={
                  messageReceived && messageReceived.isVideoCall
                    ? "Video Call"
                    : "Audio Call"
                }
                shouldRedirect={false}
                onAction={() => dispatch(toggleCallModal(false))}
              />
            )}

            {token && (
              <>
                <Header />

                <AppMessageRibbon />

                <MediasUploadResultDialog />
              </>
            )}

            <Switch>
              <Route exact path="/">
                {token ? (
                  // isUserAdmin ? (
                  //   <Redirect to="/dashboard">
                  //     <DashboardPage />
                  //   </Redirect>
                  // ) : (
                  <Redirect to="/home">
                    <HomePage />
                  </Redirect>
                ) : (
                  // )
                  <LoginPage />
                  // <LandingPage />
                )}
              </Route>

              <Route exact path="/login">
                <LoginPage />
              </Route>

              {/* Footer link pages */}
              <Route exact path="/privacy">
                <Privacy />
              </Route>

              <Route exact path="/terms">
                <TermsConditions />
              </Route>

              <Route exact path="/eula">
                <EULA />
              </Route>

              <Route exact path="/disclaimer">
                <Disclaimer />
              </Route>

              <Route exact path="/cookie">
                <Cookie />
              </Route>

              <Route exact path="/newmap">
                <NewApp />
              </Route>

              <Route exact path="/aboutus">
                <AboutUs />
              </Route>

              <Route exact path="/contactus">
                <ContactUs />
              </Route>

              <Route exact path="/help">
                <Help />
              </Route>

              <PrivateRoute exact path="/">
                <LandingPage />
              </PrivateRoute>

              <PrivateRoute exact path="/home">
                <PostListingPage />
              </PrivateRoute>

              <PrivateRoute exact path="/post/:id">
                <PostDetailPage />
              </PrivateRoute>

              {isUserAdmin && (
                <PrivateRoute exact path="/dashboard">
                  <DashboardPage />
                </PrivateRoute>
              )}

              <PrivateRoute exact path="/jobs">
                <JobPage />
              </PrivateRoute>

              <PrivateRoute exact path="/services">
                <ServicesListingPage />
              </PrivateRoute>

              <PrivateRoute exact path="/service-providers">
                <ServiceProviderListPage />
              </PrivateRoute>

              <PrivateRoute exact path="/job/:id">
                <JobDetailsPage />
              </PrivateRoute>

              <PrivateRoute exact path="/Admintests">
                <AdminTestPage />
              </PrivateRoute>

              <PrivateRoute exact path="/testq/:testId">
                <TestDetailsPage />
              </PrivateRoute>

              <PrivateRoute exact path="/map">
                {isTrackingAllowed ? (
                  <MapPage />
                ) : (
                  <SubscriptionNotAllowedDialog
                    isAllowed={isTrackingAllowed}
                    featureName="Track Screen"
                  />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/calendar">
                <CalendarPage />
              </PrivateRoute>

              <PrivateRoute exact path="/events">
                <EventsHomePage />
              </PrivateRoute>

              <PrivateRoute exact path="/event/:id">
                <EventDetailsPage />
              </PrivateRoute>

              <PrivateRoute exact path="/test">
                <TestPage />
              </PrivateRoute>

              <PrivateRoute exact path="/test/:testId/countdown">
                <Countdown />
              </PrivateRoute>

              <PrivateRoute exact path="/test/:testId/testpaper">
                <TestPaper/>
              </PrivateRoute>
              <PrivateRoute exact path="/test/:testId/result">
                <Result/>
              </PrivateRoute>

              <PrivateRoute exact path="/profile/:id">
                <UserProfilePage />
              </PrivateRoute>

              <PrivateRoute exact path="/chat">
                {isChatAllowed ? (
                  <ChatPage />
                ) : (
                  <SubscriptionNotAllowedDialog
                    isAllowed={isChatAllowed}
                    featureName="Chat"
                  />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/call">
                <CallPage />
              </PrivateRoute>

              <PrivateRoute exact path="/call/:id">
                <CallPage />
              </PrivateRoute>

              <PrivateRoute exact path="/chat/messages/:id">
                {isChatAllowed ? (
                  <ChatMessagesPage />
                ) : (
                  <SubscriptionNotAllowedDialog
                    isAllowed={isChatAllowed}
                    featureName="Chat"
                  />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/employees">
                {isManageUsersAllowed ? (
                  <EmployeeListPage />
                ) : (
                  <SubscriptionNotAllowedDialog
                    isAllowed={isManageUsersAllowed}
                    featureName="Employee Management"
                  />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/employee/:id">
                {isManageUsersAllowed ? (
                  <EmployeeDetailsPage />
                ) : (
                  <SubscriptionNotAllowedDialog
                    isAllowed={isManageUsersAllowed}
                    featureName="Employee Management"
                  />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/customers">
                {isManageUsersAllowed ? (
                  <CustomerListPage />
                ) : (
                  <SubscriptionNotAllowedDialog
                    isAllowed={isManageUsersAllowed}
                    featureName="Customer Management"
                  />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/customer/:id">
                {isManageUsersAllowed ? (
                  <CustomerDetailsPage />
                ) : (
                  <SubscriptionNotAllowedDialog
                    isAllowed={isManageUsersAllowed}
                    featureName="Customer Management"
                  />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/companies">
                <CompanyListPage />
              </PrivateRoute>

              <PrivateRoute exact path="/company/:id">
                <CompanyDetailsPage />
              </PrivateRoute>

              <PrivateRoute exact path="/mycompany/:id">
                <MyCompanyPage />
              </PrivateRoute>

              <PrivateRoute exact path="/settings">
                <SettingsPage />
              </PrivateRoute>

              <PrivateRoute exact path="/quotations">
                {isQuotationAllowed ? (
                  <QuotationListingPage />
                ) : (
                  <SubscriptionNotAllowedDialog
                    isAllowed={isQuotationAllowed}
                    featureName="Quotation Management"
                  />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/quotation/:id">
                {isQuotationAllowed ? (
                  <QuotationDetailsPage />
                ) : (
                  <SubscriptionNotAllowedDialog
                    isAllowed={isQuotationAllowed}
                    featureName="Quotation Management"
                  />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/invoices">
                {isInvoiceAllowed ? (
                  <InvoiceListingPage />
                ) : (
                  <SubscriptionNotAllowedDialog
                    isAllowed={isInvoiceAllowed}
                    featureName="Invoice Management"
                  />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/invoice/:id">
                {isInvoiceAllowed ? (
                  <InvoiceDetailsPage />
                ) : (
                  <SubscriptionNotAllowedDialog
                    isAllowed={isInvoiceAllowed}
                    featureName="Invoice Management"
                  />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/appointments">
                {isAppointmentAllowed ? (
                  <AppointmentsListingPage />
                ) : (
                  <SubscriptionNotAllowedDialog
                    isAllowed={isAppointmentAllowed}
                    featureName="Appointment Management"
                  />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/appointment/:id">
                {isAppointmentAllowed ? (
                  <AppointmentDetailsPage />
                ) : (
                  <SubscriptionNotAllowedDialog
                    isAllowed={isAppointmentAllowed}
                    featureName="Appointment Management"
                  />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/service/:id">
                <ServiceDetailPage />
              </PrivateRoute>

              <PrivateRoute exact path="/service-provider/:id">
                <ServiceProviderDetailsPage />
              </PrivateRoute>
              <PrivateRoute exact path="/appointments/visitor-pass">
                <VisitorPass />
              </PrivateRoute>

              {/* Form Builder Routes */}
              <PrivateRoute exact path="/forms/builder">
                <FormBuilderPage />
              </PrivateRoute>

              <PrivateRoute exact path="/forms/builder/:id">
                <FormDesignerPage />
              </PrivateRoute>

              <PrivateRoute exact path="/forms/details/:id">
                <FormDataDetailPage />
              </PrivateRoute>

              {/* 
              <PrivateRoute exact path='/forms/data/list'>
                <FormDataListPage />
              </PrivateRoute>

              <PrivateRoute exact path='/forms/data/:id'>
                <FormDataDetailPage />
              </PrivateRoute> */}
            </Switch>
          </div>
        );
      }}
    </CacheBuster>
  );

  function updateChatProfile(id, resolvedProfile) {
    let localChatSnippetList = getChatMessageSnippetListFromLocalStorage();

    localChatSnippetList = localChatSnippetList.map((chatSnippetItem) => {
      if (chatSnippetItem.id === id) {
        let newProfile = {};

        if (resolvedProfile.contactType === "group") {
          newProfile = {
            ...chatSnippetItem,
            contactName: resolvedProfile.contactName,
            contactAvatar: resolvedProfile.contactAvatar,
            createdBy: resolvedProfile.createdBy,
          };
        } else {
          newProfile = {
            ...chatSnippetItem,
            contactName: resolvedProfile.contactName,
            contactAvatar: resolvedProfile.contactAvatar,
          };
        }
        console.log("newProfile: ", newProfile);

        return newProfile;
      } else {
        return chatSnippetItem;
      }
    });

    console.log("resolve: after localChatSnippetList: ", localChatSnippetList);

    dispatch(setChatSnippetList(localChatSnippetList));
  }

  async function fetchChatSnippets(localChatSnippetList) {
    await apiMessageProfileCount
      .post({})
      .then((response) => {
        console.log(
          "======= 11111 fetchChatSnippets ===== response ",
          response.data.profileCounts
        );
        if (response.status === 200) {
          let chatSnippetServerResponse = response.data.profileCounts;

          chatSnippetServerResponse = chatSnippetServerResponse.map(
            (profileCountItem) => {
              return {
                id: profileCountItem.objId,
                count: profileCountItem.count,
                time: profileCountItem.creationDate,
                messageType: profileCountItem.messageType,
                snippet: profileCountItem.text,
                contactName: profileCountItem.name,
                contactAvatar: profileCountItem.profilePic,
                contactType: profileCountItem.isGroup ? "group" : "user",
              };
            }
          );

          localChatSnippetList = getFilteredAndSortedChatList(
            localChatSnippetList,
            chatSnippetServerResponse
          );
          console.log(
            "=========  11111 fetchChatSnippets : after filtersort : ",
            localChatSnippetList
          );
        } else {
          console.log("Chat Snippet Fetch Error: ", response);
        }
      })
      .catch((error) => console.log("Chat Snippet Fetch Error: ", error));

    return localChatSnippetList;
  }

  async function fetchChatGroupsList(localChatSnippetList) {
    let groupParams = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "",
      sortDecending: true,
      profileId: authUser.id,
    };

    console.log(
      "======= 222222 messagegroup ===== groupParams paylaod ",
      groupParams
    );

    await apiMessageGroupSearch
      .post(groupParams)
      .then(async (response) => {
        console.log(
          "======= 222222 messagegroup ===== response ",
          response.data.data
        );

        if (response.status == 200) {
          if (response.data.data.length > 0) {
            let messageGroupResponse = response.data.data;

            messageGroupResponse = messageGroupResponse.map((item) => {
              item.id = item.id;
              item.contactName = item.title;
              item.contactAvatar = item.profilePic;
              item.snippet = "";
              item.time = null; //'Fetch it from local storage
              item.contactType = "group";
              return item;
            });

            localChatSnippetList = getFilteredAndSortedChatList(
              localChatSnippetList,
              messageGroupResponse
            );
            console.log(
              "======= 222222 messagegroup ===after== getFilteredAndSortedChatList ",
              localChatSnippetList
            );

            localStorage.setItem(
              "MyLocalGroupList",
              JSON.stringify(messageGroupResponse)
            );
          }
        } else {
          console.log("Error in message group fetch: ");
        }
      })
      .catch((error) => console.log("Loading Groups Failed----", error));

    return localChatSnippetList;
  }

  async function fetchProfileList(localChatSnippetList) {
    const searchParams = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      searchText: "",
      ResolvePic: true,
    };

    await apiMemberSearch
      .post(searchParams)
      .then(async (response) => {
        console.log(
          "======= 333333 profile ===== response ",
          response.data.data
        );

        if (response.status === 200) {
          if (response.data.data.length > 0) {
            let memberSearchResponse = response.data.data;

            // to filter the current user from chat snippet list
            memberSearchResponse = memberSearchResponse.filter(
              (user) => user.id.toString() !== authUser.id.toString()
            );

            memberSearchResponse = memberSearchResponse.map((profile) => {
              return {
                ...profile,
                id: profile.id,
                contactName: `${profile.firstName} ${profile.lastName}`,
                contactAvatar: profile.profilePic,
                snippet: "",
                messageId: null,
                time: null,
                contactType: "user",
                messageType: null,
                count: 0,
              };
            });

            localChatSnippetList = getFilteredAndSortedChatList(
              localChatSnippetList,
              memberSearchResponse
            );
            console.log(
              "======= 333333  memberSearchResponse ===== getFilteredAndSortedChatList ",
              localChatSnippetList
            );

            localStorage.setItem(
              "MyLocalProfileList",
              JSON.stringify(memberSearchResponse)
            );
          }
        } else {
          console.log("Error in message member fetch: ");
        }
      })
      .catch((error) => console.log("Loading member Failed----", error));

    return localChatSnippetList;
  }
}

export default App;

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.main,
    maxWidth: "100vw",
    minHeight: "100vh",
  },
}));

async function fetchUserForResolving(id, updateChatProfile) {
  const membserSearchPayload = {
    PageIndex: 0,
    OrderBy: "newest",
    SearchText: "",
    Ids: [id],
  };

  await apiMemberSearch
    .post(membserSearchPayload)
    .then(async (response) => {
      if (response && response.status === 200) {
        let memberResponse = response.data.data;
        if (memberResponse.length > 0) {
          let item = memberResponse[0];

          item = {
            ...item,
            contactName: `${item.firstName} ${item.lastName}`,
            contactAvatar: item.profilePic,
            snippet: "",
            time: null,
            contactType: "user",
          };

          await updateChatProfile(id, item);
        }
      }
    })
    .catch((error) => {
      console.log("User Profile Resolve Error: ", error);
    });
}

async function fetchGroupForResolving(authUser, id, updateChatProfile) {
  let groupPayload = {
    pageSize: 10,
    pageIndex: 0,
    orderBy: "",
    sortDecending: true,
    profileId: authUser.id,
  };

  await apiMessageGroupSearch
    .post(groupPayload)
    .then((response) => {
      if (response && response.status === 200) {
        console.log("resolve: group response: ", response);

        if (response.data.data.length > 0) {
          let messageGroupResponse = response.data.data;

          messageGroupResponse = messageGroupResponse.map((item) => {
            item.contactName = item.title;
            item.contactAvatar = item.profilePic;
            item.snippet = "";
            item.time = null; //'Fetch it from local storage
            item.contactType = "group";
            return item;
          });

          console.log("resolve: find group: ", id, messageGroupResponse);

          let resolvedGroup = messageGroupResponse.find(
            (group) => group.id === id
          );

          console.log("resolve: group", resolvedGroup);

          updateChatProfile(id, resolvedGroup);

          localStorage.setItem(
            "localChatGroupList",
            JSON.stringify(messageGroupResponse)
          );
        }
      }
    })
    .catch((error) => {
      console.error("Error while resolving group", error);
    });
}

function handleTextChatMessage(
  message,
  authUser,
  createdBy,
  chatId,
  id,
  creationDate,
  createdByUser,
  setLocalChatMessages,
  dispatch
) {
  if (
    (message.toMemberId === authUser.id &&
      createdBy === chatId &&
      message.toMessageGroupId === null) ||
    (message.toMemberId === chatId && createdBy === authUser.id) ||
    (message.toMessageGroupId === chatId && createdBy !== authUser.id)
  ) {
    const newMessage = {
      ...message,
      _id: id,
      text: message.text
        ? message.text.includes("##Shared_Location##")
          ? null
          : message.text
        : null,
      location: message.text
        ? message.text.includes("##Shared_Location##")
          ? JSON.parse(message.text)["##Shared_Location##"]
          : null
        : null,
      createdAt: creationDate,
      user: createdByUser,
      image: message.messageType === MessageType.Image ? message.url : null,
      video: message.messageType === MessageType.Video ? message.url : null,
    };

    setLocalChatMessages((previousMessages) => {
      const allMessages = [newMessage, ...previousMessages];

      console.log("onreceive: saving messages to localChatmessages");
      localStorage.setItem(
        LocalStorageConstants.localChatMessages(chatId),
        JSON.stringify(allMessages)
      );

      dispatch(setChatMessages(allMessages));

      return GiftedChat.append(previousMessages, newMessage);
    });
  }
}

async function handleUpdateChatSnippetList(
  message,
  createdBy,
  currentPage,
  id,
  creationDate,
  fetchChatProfileDetails,
  dispatch
) {
  let profilePromise = null;

  let messageReceiverId = null;
  let messageContactType = "user";
  if (message.toMessageGroupId) {
    messageReceiverId = message.toMessageGroupId;
    messageContactType = "group";
  } else {
    messageReceiverId = createdBy;
    messageContactType = "user";
  }

  let localChatSnippetList = getChatMessageSnippetListFromLocalStorage();

  let filteredLocalChatSnippetList = localChatSnippetList.filter(
    (profielListItem) => profielListItem.id !== messageReceiverId
  );
  let targetProfile = localChatSnippetList.find(
    (profielListItem) => profielListItem.id === messageReceiverId
  );

  console.log("resolve: targetProfile: before", targetProfile);

  let currentChatProfile = JSON.parse(
    localStorage.getItem("currentChatProfile")
  );

  if (!currentChatProfile) {
    currentChatProfile = {};
  }

  console.log("currentChatProfile: ", currentChatProfile);

  if (currentPage.includes("chat")) {
    // if receiver chat is open
    if (currentChatProfile.id === messageReceiverId) {
      targetProfile = {
        ...targetProfile,
        messageId: id,
        time: creationDate,
        snippet: message.text,
        messageType: message.messageType,
        count: 0,
      };
    }

    // if receiver chat is not open
    else {
      if (targetProfile) {
        console.log("targetProfile receive: before", targetProfile);
        targetProfile = {
          ...targetProfile,
          messageId: id,
          time: creationDate,
          snippet: message.text,
          messageType: message.messageType,
          count: targetProfile.count + 1,
        };
        console.log("targetProfile receive: after", targetProfile);
      } else {
        // if user is not in chat profile list, then resolve and update
        targetProfile = {
          ...targetProfile,
          id: messageReceiverId,
          messageId: id,
          time: creationDate,
          snippet: message.text,
          messageType: message.messageType,
          contactName: "Loading...",
          contactAvatar: null,
          contactType: messageContactType,
          count: 1,
        };
      }
    }

    localChatSnippetList = sortChatsList([
      targetProfile,
      ...filteredLocalChatSnippetList,
    ]);

    console.log("resolve: starting");
    const isGroup = messageContactType === "group" ? true : false;
    profilePromise = fetchChatProfileDetails(messageReceiverId, isGroup);
  }

  // to update chat profile if user is not on chat page
  if (!currentPage.includes("chat")) {
    if (targetProfile) {
      targetProfile = {
        ...targetProfile,
        messageId: id,
        time: creationDate,
        snippet: message.text,
        messageType: message.messageType,
        count: targetProfile.count ? targetProfile.count + 1 : 1,
      };
      localChatSnippetList = sortChatsList([
        targetProfile,
        ...filteredLocalChatSnippetList,
      ]);
    } else {
      // if user is not in chat profile list, then resolve and update
      targetProfile = {
        ...targetProfile,
        id: messageReceiverId,
        messageId: id,
        time: creationDate,
        snippet: message.text,
        messageType: message.messageType,
        contactName: "Loading...",
        contactAvatar: null,
        contactType: messageContactType,
        count: 1,
      };

      localChatSnippetList = sortChatsList([
        targetProfile,
        ...filteredLocalChatSnippetList,
      ]);

      const isGroup = messageContactType === "group" ? true : false;
      profilePromise = fetchChatProfileDetails(messageReceiverId, isGroup);
    }
  }

  console.log("localChatSnippetList: after receive: ", localChatSnippetList);

  dispatch(setChatSnippetList(localChatSnippetList));

  if (profilePromise) {
    await Promise.resolve(profilePromise);
  }
  return currentChatProfile;
}

function handleTotalHeaderMessageCount(
  currentPage,
  setLocalMessageCount,
  dispatch
) {
  if (!currentPage.includes("chat")) {
    setLocalMessageCount((count) => {
      let updatedCount = count + 1;
      dispatch(setMessageCount(updatedCount));
      return updatedCount;
    });
  }
}
