import { SET_DASHBOARD_LOADERS, SET_LAYOUTS, SET_WIDGETS, SET_WIDGET_BY_ID } from "../actions/dashboardActions";
import { getWidgetDefaultLayout } from "../utils/Helpers";

const initialState = {
  widgets: [],
  layouts: null,

  widgetById: null,

  // loaders
  dashboardDataLoader: false,
  widgetsLoader: false,
  updateWidgetsLoader: false,
  widgetByIdLoader: false

};

export const dashboardReducer = (state = initialState, { type, payload }) => {

  switch (type) {
    case SET_WIDGETS:

      // const _widgets = payload.map((widget, index) => ({
      //   ...widget,
      //   defaultLayout: widget.defaultLayout ? widget.defaultLayout : getWidgetDefaultLayout(index)
      // }));

      state = {
        ...state,
        widgets: payload
      };
      return state;

    case SET_LAYOUTS:

      const _layouts = state.widgets.map((widget, index) => ({
        ...widget,
        lg: widget.defaultLayout ? widget.defaultLayout : getWidgetDefaultLayout(index, widget)
      }));

      state = {
        ...state,
        layouts: payload ? payload : _layouts
      };
      return state;

    case SET_DASHBOARD_LOADERS:
      const { loaderName, loaderState } = payload;
      state = {
        ...state,
        [loaderName]: loaderState
      };
      return state;

    case SET_WIDGET_BY_ID:

      const widgets = state.widgets
        .filter(widget => Boolean(widget))
        .map(widget => widget.id === payload.id ? payload : widget);

      state = {
        ...state,
        widgets,
        [`widget_${payload.id}`]: payload
      };
      return state;

    default:
      return state;
  }

};