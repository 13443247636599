import React, { useEffect, useRef, useState } from 'react';
import { IconButton } from '@material-ui/core';
import Icon from '@mdi/react';
import ToggleIcon from "material-ui-toggle-icon";
import { makeStyles } from '@material-ui/core/styles';
import { Videocam, VideocamOff, Mic, MicOff } from '@material-ui/icons';
import {mdiAccountVoice} from '@mdi/js';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  notificationContainer: {
    // display: 'flex',
    //position: 'absolute',
    bottom: 0,
    backgroundColor: '#0E0E0E',
    opacity: '0.8',
    justifyContent: 'center',
    //width: '100%',
    zIndex: 50,
    //borderRadius: 5,
    //marginLeft: -55,
    //height: '15%',
    textAlign: 'center',
    verticalAlign: 'center',
    alignSelf: 'center',
    borderRadius: 10,
  },
}));


function Video({ videoStream, videoType, videoMuted, videoStyles, frameStyle, id, showMuteControls, muted }) {

  const [mic, setMic] = useState(true);
  const [camera, setCamera] = useState(true);
  const [videoVisible, setVideoVisible] = useState(true);
  const [videoTypeLocal, setVideoTypeLocal] = useState('');
  const [videoStreamLocal, setVideoStreamLocal] = useState(null);

  const videoRef = useRef();
  const classes = useStyles();


  useEffect(() => {
    if (videoStream) {
      setVideoTypeLocal(videoType);
      setVideoStreamLocal(videoStream);
      videoRef.current.srcObject = videoStream;
    } else {
      console.log("-----------NO VIDEO STREAM-----------------");
    }
  }, []);

  useEffect(() => {
    // console.log('1. nextProps', this.props.showMuteControls, nextProps.videoStream && nextProps.videoStream.getTracks())
    console.log('1----------videoTypeLocal----------', videoTypeLocal, videoStream);

    // This is done only once
    if (videoStream && videoStream !== videoStreamLocal) {
      // if (!this.props.videoStream) {
      console.log('2----------videoStreamLocal----------', videoTypeLocal, videoStream);
      videoRef.current.srcObject = videoStream;
      setVideoStreamLocal(videoStream);
    }

    // This is done only once when we receive a video track
    const videoTrack = videoStream && videoStream.getVideoTracks();
    if (videoTypeLocal === 'remoteVideo' && videoTrack && videoTrack.length) {

      videoTrack[0].onmute = () => {
        setVideoVisible(false);
        videoMuted(videoStream);
      };

      videoTrack[0].onunmute = () => {
        setVideoVisible(true);
        videoMuted(videoStream);
      };
    }
    
    const audioTrack = videoStream && videoStream.getAudioTracks();
    if (videoTypeLocal === 'remoteVideo' && audioTrack && audioTrack.length) {
      audioTrack[0].onmute = () => {
        alert('muted');
      };
    }

    // if(videoTrack && videoTrack.length === 0 && audioTrack && audioTrack.length > 0){
    //   setVideoVisible(false);
    // }

    //setVideoStreamLocal(videoStream);

  }, [videoStream]);



  const muteMic = () => {
    const stream = videoRef.current.srcObject.getTracks().filter(track => track.kind === 'audio');

    if (stream)
      stream[0].enabled = !mic;
    setMic(!mic);
  };

  const muteCamera = () => {
      const stream = videoRef.current.srcObject.getTracks().filter(track => track.kind === 'video');

      if (stream)
        stream[0].enabled = !camera;
      setCamera(!camera);
  };

  //render() {
  const muteControls = showMuteControls && (
    <div>
      <div className={classes.notificationContainer}>
        {videoStreamLocal && videoStreamLocal.getVideoTracks().length > 0 && <IconButton onClick={muteCamera} data-switch='video'>
          <ToggleIcon
            on={camera}
            onIcon={<Videocam />}
            offIcon={<VideocamOff />}
          />
        </IconButton>}

        <IconButton onClick={muteMic} data-switch='audio'>
          <ToggleIcon
            on={mic}
            onIcon={<Mic />}
            offIcon={<MicOff />}
          />
        </IconButton>
      </div>
    </div>
  );

  return (
    <div style={videoVisible? { ...frameStyle, alignSelf:'center' } : {width: 100, height:100,
      backgroundColor: 'black',
      borderRadius: 10, alignSelf:'center'}}>
      {/* <audio id={this.props.id} muted={this.props.muted} ref={ (ref) => {this.video = ref }}></audio> */
    console.log("-------------videoVisible videoVisible--------------", videoVisible)}
      {<div style={{justifyContent:'center', flex:1, flexDirection:'inherit'}}>
        <video
          id={id}
          muted={muted}
          autoPlay
          style={videoVisible ?{
            visibility: 'visible',
            ...videoStyles,
            borderRadius: 10,
          }: {width: 100, height:100, visibility: 'hidden',}}
          ref={videoRef}
          srcObject={videoStreamLocal}
        /> 
        {videoStreamLocal && videoStreamLocal.getVideoTracks().length <= 0 && <Icon
          path={mdiAccountVoice}
          size='80px'
          color='goldenrod'
          style={{position: 'absolute', left: 10,}}
        />}
        </div>
        }
      {muteControls}
    </div>
  )
  // }
}

export default Video