import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 10,
    padding: '16px 8px',
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    color: theme.palette.getContrastText(theme.palette.background.main),
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    width: '100%'
  },
  jobMeta: {
    display: 'flex',
  },
  meta: {
    marginLeft: theme.spacing(2),
    flex: 1,
    marginBottom: theme.spacing(1)
  },
  title: {
    color: theme.colors.LIST_CARD_TITLE_COLOR,
    fontWeight: '700'
  },
  date: {
    color: theme.colors.LIST_CARD_DATE_COLOR,
    fontSize: '0.8rem'
  },
  username: {
    color: theme.colors.LIST_CARD_USERNAMES_COLOR,
    fontSize: '0.8rem'
  },
  moreIcon: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
  jobDescription: {
    background: theme.colors.LIST_CARD_PARA_BACKGROUND_COLOR,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderRadius: 5,
    color: theme.colors.LIST_CARD_PARA_COLOR,
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
  },
}));
