import React, { useState } from "react";
import axios from "axios";

const SearchLocationInput = ({ selectedLocation, setSelectedLocation }) => {
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = async (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);

    if (newQuery.trim() === "") {
      setSearchResults([]);
      return;
    }

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${newQuery}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
      );
      setSearchResults(response.data.predictions);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handlePlaceSelect = async (placeId) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
      );
      const place = response.data.result;
      setSelectedLocation({
        lat: place.geometry.location.lat,
        lng: place.geometry.location.lng,
      });
      setQuery("");
      setSearchResults([]);
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  return (
    <div className="search-location-input">
      <input
        className="form-control"
        onChange={handleSearchChange}
        placeholder="Search Places ..."
        value={query}
      />
      <ul>
        {searchResults.map((prediction) => (
          <li key={prediction.place_id} onClick={() => handlePlaceSelect(prediction.place_id)}>
            {prediction.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchLocationInput;
