import React from 'react'
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import clsx from 'clsx';
import { MetaText } from '../Typography';
import { IconMain } from '../IconCNO';
import { Box, makeStyles } from '@material-ui/core';

const ListCardItemWrapper = ({ children, className = "", title, CardActionComponent, style = {} }) => {

  const GlobalStyles = useGlobalStyles();

  return (
    <div
      className={clsx(GlobalStyles.listCardContainer, GlobalStyles.listCardFW, className)}
      style={style}
    >
      {
        (title || CardActionComponent) &&
        <Box mb={1} display='flex' position='relative' >
          <MetaText style={{ fontSize: '0.8rem' }}>{title}</MetaText>
          <Box position='absolute' right={1} top={0} >
            {CardActionComponent}
          </Box>
        </Box>
      }
      {children}
    </div>
  )
}

export default ListCardItemWrapper;
