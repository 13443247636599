import { fade, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_ChatsListItem: {
    display: 'flex',
    background: theme.colors.CHAT_PROFILE_LIST_BACKGROUND_COLOR,
    height: 65,
    paddingTop: theme.spacing(1),
    position: 'relative',
    zIndex: -1,
    '&:hover': {
      background: theme.colors.CHAT_PROFILE_LIST_HOVER_COLOR,
      cursor: 'pointer'
    }
  },
  chatItemActive: {
    background: theme.colors.CHAT_PROFILE_LIST_HOVER_COLOR
  },
  avatarWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  avatar: {
    width: 53,
    height: 53,
    borderRadius: "50%"
  },
  chatMetaWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.colors.LIST_DIVIDER_COLOR}`,
    paddingBottom: theme.spacing(1),
    width: '100%'
  },
  chatMetaTop: {
    flex: 1
  },
  contactName: {
    fontWeight: '700',
    color: theme.colors.CHAT_PROFILE_LIST_ITEM_USER_COLOR
  },
  chatSnippet: {
    fontSize: '0.8rem',
    marginTop: 4,
  },
  chatMetaRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  messageProfileCount: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
    height: 25,
    background: theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_COUNT_BACKGROUND_COLOR,
    color: theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_COUNT_COLOR,
    fontWeight: 'bold',
  },
  time: {
    paddingRight: 5,
    marginLeft: theme.spacing(2),
    fontSize: '0.8rem',
    color: theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_TEXT_COLOR
  },
  chatSnippetWrapper: {
    display: 'flex',
    alignItems: 'center'
  }
}));