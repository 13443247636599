import { Typography, useMediaQuery } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import LandingPageWrapper from '../LandingPageWrapper';
import { usePublicPagesStyles } from '../../../utils/Styles/PublicPages.style';
import Expandable from '../../../library/Expandable';
import axios from 'axios';
import Icon from '@mdi/react';
import { mdiYoutube } from '@mdi/js';
import DialogCNO from '../../../library/DialogCNO';
import ResponsiveLinesCNO from '../../../library/ResponsiveLinesCNO/ResponsiveLinesCNO';
import { toast } from 'react-toastify';
import { getYoutubeEmbedUrl } from '../../../utils/Helpers';
import { useQuery } from '../../../hooks';

function Help() {

  const styles = usePublicPagesStyles();

  const [helpPageData, setHelpPageData] = useState([]);
  const [defaultExpandId, setDefaultExpandId] = useState(13);

  const searchQueryId = useQuery().get('id');

  useEffect(() => {

    if (searchQueryId) {
      setDefaultExpandId(isNaN(searchQueryId) ? 13 : parseInt(searchQueryId));
    }

    return () => {
      setDefaultExpandId(13);
    };
  }, [searchQueryId]);

  useEffect(() => {

    (
      async function fetchHelpData() {

        try {

          const response = await axios.get('/data/HelpPageJson.json');

          if (response.status === 200) {
            setHelpPageData(response.data);
          }

        } catch (error) {

          toast.error("There is issue in fetching Help data.");

        }

      }
    )();

  }, []);

  return (
    <LandingPageWrapper pageTitle={"Help"}>

      <Typography variant='h4' align='center' className={styles.pageTitle}>
        Help
      </Typography>

      {
        Object.keys(helpPageData).map((section) => {

          const { id, data, sectionTitle } = helpPageData[section]

          return (
            <Expandable key={id} defaultExpandState={id === defaultExpandId} title={sectionTitle} containerStyle={{ marginTop: 20 }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10, rowGap: 10 }}>
                {
                  data.map(videoItem => (
                    <VideoThumbnail key={videoItem.id} videoItem={videoItem} />
                  ))
                }
              </div>
            </Expandable>
          )

        })
      }

    </LandingPageWrapper>
  )
};


const VideoThumbnail = ({ videoItem }) => {

  // const [videoMetaData, setVideoMetaData] = useState(null);

  const styles = usePublicPagesStyles();

  const [isVideoModal, setIsVideoModal] = useState(false);

  const videoModalBP = useMediaQuery('900px');

  // useEffect(() => {

  //   (
  //     async function fetchVideoMetaData() {

  //       console.log("=========fetchVideoMetaData======videoItem====", !videoItem.videoUrl, videoItem);

  //       if (!videoItem.videoUrl) return;

  //       const response = await axios.get(videoItem.videoUrl, {}, { headers: { "Access-Control-Allow-Origin": "*" } });

  //       console.log("=========fetchVideoMetaData======response====", response);

  //     }
  //   )();

  // }, [videoItem]);

  return (
    <Fragment>

      <div
        className={styles.videoContainer}
        onClick={() => setIsVideoModal(true)}
      >

        <div className={styles.thumbnailContainer}>
          <Icon
            path={mdiYoutube}
            size={'4rem'}
            color={'red'}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}

          />
          <img src={videoItem.thumbnailUrl} alt="" srcset="" style={{ borderRadius: 5 }} />
        </div>

        <ResponsiveLinesCNO
          text={videoItem.title}
          maxLine={2}
          style={{ fontSize: '1rem', padding: '5px 3px' }}
        />

      </div>

      {
        isVideoModal &&
        <DialogCNO
          open={isVideoModal}
          onClose={() => setIsVideoModal(false)}
          height={"60vh"}
          width={videoModalBP ? '90vw' : "50vw"}
          isClickAwayClose={true}
          dialogTitle={videoItem.title}
        >

          <iframe
            width={'100%'}
            height="315"
            src={getYoutubeEmbedUrl(videoItem.videoUrl)}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </DialogCNO>
      }

    </Fragment>
  )

}

export default Help