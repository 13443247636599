import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { mdiCurrencyGbp, mdiHours24, mdiLockClock } from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import { Typography } from '@material-ui/core';
import ChipCNO from '../../library/ChipCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { JobTargetType, JobTargetTypeIcon } from '../../utils/mappings';
import { getMaterialIconString } from '../../utils/icons';
import * as icons from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubTask } from '../../actions/subTaskActions';

function EditSubTaskTarget({
  open,
  onClose,
  subTask,
}) {

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { editLoader } = useSelector(state => state.subTasks);

  const formik = useFormik({
    initialValues: {
      target: subTask.target,
      targetType: subTask.targetType,
    },
    validationSchema: yup.object({
      target: yup.string().required('Target is required'),
      targetType: yup.number().required('Target Type is required'),
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("Edit Subtask: target: ", values);

    const payload = {
      ...subTask,
      target: values.target ? values.target : 0,
      targetType: values.targetType,
    };

    dispatch(updateSubTask(payload));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Subtask Target"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <div style={{ marginBottom: 8 }}>
          <Typography>Subtask Target Types</Typography>
          <div className={GlobalStyles.chipsWrapper}>
            {
              Object.keys(JobTargetType).filter((t) => t !== 'None')
                .map((target) => (
                  <ChipCNO
                    icon={icons[getMaterialIconString(JobTargetTypeIcon[target])]}
                    name={target}
                    size="1.5rem"
                    active={formik.values.targetType === JobTargetType[target]}
                    onClick={() => formik.setFieldValue('targetType', JobTargetType[target])}
                  />
                ))
            }
          </div>
        </div>
        <InputCNO
          name='target'
          label='Subtask Target'
          formik={formik}
          placeholder='Enter Subtask Target'
          icon={icons.mdiBullseyeArrow}
          fullWidth
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditSubTaskTarget
