import { makeStyles } from '@material-ui/core';
import { mdiAccountGroup, mdiAccountMultiple, mdiLink, mdiWeb } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import React from 'react'
import { InputCNO, MetaText } from '../../../../library';
import GooglePlaceInputCNO from '../../../../library/GooglePlaceInputCNO';
import { EventType } from '../../../../utils/mappings';
import { CardHeader } from '../EventDetailsFB';

const AddEventTypeFB = ({ formik }) => {

  const styles = useStyles();

  const handleSelectEventType = (value) => {
    formik.setFieldValue('eventType', value);
  }

  console.log("EventType: ", formik.values.eventType)

  return (
    <div>

      {/* <CardHeader>Event Type</CardHeader> */}

      <div className={styles.contentWrapper}>
        <div
          className={clsx(styles.card,
            {
              [styles.selectedCard]: formik.values.eventType === EventType.ids.Online
            }
          )}
          onClick={() => handleSelectEventType(EventType.ids.Online)}
        >
          <Icon
            path={mdiWeb}
            size='2.5rem'
          />
          <MetaText className={styles.title}>Online</MetaText>
          <MetaText className={styles.description} maxLine={null}>
            Video/Audio Chat with online Chat Rooms, go Live or use an external link.
          </MetaText>
        </div>

        <div
          className={clsx(styles.card,
            {
              [styles.selectedCard]: formik.values.eventType === EventType.ids.InPerson
            }
          )}
          onClick={() => handleSelectEventType(EventType.ids.InPerson)}
        >
          <Icon
            path={mdiAccountMultiple}
            size='2.5rem'
          />
          <MetaText className={styles.title}>In Person</MetaText>
          <MetaText className={styles.description} maxLine={null}>
            Get together with people at a specific location.
          </MetaText>
        </div>
      </div>

      {
        formik.values.eventType === EventType.ids.Online && (
          <InputCNO
            name="joinLink"
            label="Join Url"
            formik={formik}
            placeholder="Enter Event Join Url"
            icon={mdiLink}
            fullWidth
            isRequired
          />
        )
      }

      {
        formik.values.eventType === EventType.ids.InPerson &&
        <div>
          <GooglePlaceInputCNO
            address={formik.values.mapAddress}
            onSelectAddress={(address) => {
              formik.setFieldValue("mapAddress", address.address);
              formik.setFieldValue("lat", address.lat);
              formik.setFieldValue("lng", address.lng);
            }}
            label="Address"
            isRequired
          />
        </div>
      }

    </div>
  )
}

export default AddEventTypeFB;

const useStyles = makeStyles((theme) => ({
  container: {

  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: 20
  },
  card: {
    borderRadius: 8,
    maxWidth: 220,
    background: '#2e2e2e',
    padding: '2rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '2px inset transparent',
    cursor: 'pointer',
    '&:hover': {
      background: '#3e3e3e',
    }
  },
  selectedCard: {
    border: `2px inset #9BBE00 `,
  },
  title: {
    fontSize: '1.1rem',
    marginBottom: '1rem'
  },
  description: {
    textAlign: 'center'
  }
}))