import React, { Fragment, useEffect, useRef, useState } from 'react';
import { SnapItem, SnapList } from 'react-snaplist-carousel';
import { v4 } from 'uuid';
import * as icons from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Icon from '@mdi/react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import RightsManager from '../../../utils/RightsManager';
import { TimesFilter, TimesFilterIcon, TimesFilterReverse } from '../../../utils';
import { isFormikEmpty } from '../../../utils/Helpers';
import ChipCNO from '../../../library/ChipCNO';
import ResetListing from '../../../components/ResetListing';
import { useGlobalStore } from '../../../providers/GlobalProvider';
import { getMaterialIconString } from '../../../utils/icons';


function EventFilterBar() {

  const isUserMember = RightsManager.isMember();

  const theme = useTheme();

  const snapList = useRef(null);

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const dispatch = useDispatch();
  const { eventSearchParams, eventTimesFilter } = useSelector(state => state.events);

  const [activeFilter, setActiveFilter] = useState(eventTimesFilter);

  const { globalState, dispatchGlobal } = useGlobalStore();
  const { isSearch } = globalState;

  const initialValues = {
    customer: null,
    categories: [],
    event: null,
    startDate: null,
    endDate: null,
    // assignments: isUserMember ? getPrefillAssignedUser() : null
  };

  useEffect(() => {
    setActiveFilter(eventTimesFilter);
  }, [eventTimesFilter]);

  const handleEventSearchSidebar = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: !isSearch
    });
  };

  const handleEventTimeFilter = (timeFilter) => {

    console.log("timefilter: ", timeFilter);

    if (timeFilter === activeFilter) {
      timeFilter = TimesFilter.None;
    }

    setActiveFilter(timeFilter);
    // dispatch(setEventTimesFilter(timeFilter));

    let startDate = null;
    let endDate = null;
    let sortAscending = true;

    switch (timeFilter) {
      case TimesFilter.None:
        startDate = null;
        endDate = null;
        sortAscending = false;
        break;

      case TimesFilter.Today:
        startDate = moment(new Date()).startOf('day').toDate();
        endDate = moment(new Date()).endOf('day').toDate();
        break;

      case TimesFilter.Tomorrow:
        startDate = moment(new Date()).add(1, 'days').startOf('day').toDate();
        endDate = moment(new Date()).add(1, 'days').endOf('day').toDate();
        break;

      case TimesFilter.ThisWeek:
        startDate = moment(new Date()).startOf('week').startOf('day').toDate();
        endDate = moment(new Date()).endOf('week').endOf('day').toDate();
        break;

      case TimesFilter.NextWeek:
        startDate = moment(new Date()).endOf('week').add(1, 'day').startOf('day').toDate();
        endDate = moment(new Date()).endOf('week').add(1, 'week').endOf('day').toDate();
        break;

      case TimesFilter.ThisMonth:
        startDate = moment(new Date()).startOf('month').startOf('day').toDate();
        endDate = moment(new Date()).endOf('month').endOf('day').toDate();
        break;

      case TimesFilter.Later:
        startDate = moment(new Date()).add(1, 'month').startOf('day').toDate();
        endDate = null;
        break;

      default:
        startDate = null;
        endDate = null;
        sortAscending = false;
        break;
    }

    console.log("==== event time filter date values ==== ", { startDate, endDate });

    const params = {
      ...eventSearchParams,
      startDate: startDate,
      endDate: endDate,
      sortAscending
    };

    console.log("==== event time filter eventSearchParams ==== ", params);

    // dispatch(handleEventSearch(params));
  };

  const hasSearched = false;
  // const hasSearched = !isFormikEmpty(initialValues, eventSearchParams);

  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        {
          tillTablet &&
          <Icon
            path={icons.mdiMagnify}
            size='2.4rem'
            // onClick={handleEventSearchSidebar}
            style={{
              paddingRight: 10,
              borderRight: `1px solid ${theme.colors.HOME_FILTER_SEARCH_DIVIDER_COLOR}`
            }}
          />
        }

        <SnapList ref={snapList}>
          {
            Object.values(TimesFilter)
              .filter(timeFilter => timeFilter !== TimesFilter.None)
              .map((timeFilter) => (
                <SnapItem key={v4()} margin={{ left: '5px', right: '5px' }}>
                  <ChipCNO
                    icon={icons[getMaterialIconString(TimesFilterIcon[timeFilter])]}
                    name={TimesFilterReverse[timeFilter]}
                    active={activeFilter === timeFilter}
                  // onClick={() => handleEventTimeFilter(timeFilter)}
                  />
                </SnapItem>
              ))
          }
        </SnapList>
      </div>

      <ResetListing
        isReset={hasSearched}
        onReset={() => {
          // dispatch(handleEventSearch(initialValues));
          // dispatch(setEventTimesFilter(TimesFilter.None));
        }}
      />

    </Fragment>
  )
}

export default EventFilterBar;


