import {
  alpha,
  Button,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { mdiPanoramaVariantOutline } from "@mdi/js";
import Icon from "@mdi/react";
import clsx from "clsx";
import React, { useState } from "react";
import {
  DateTimeFormat,
  DateTimeText,
  DotSeparator,
  MetaText,
} from "../../../library";
import ConfirmationDialogCNO from "../../../library/ConfirmationDialogCNO";
import LinkCNO from "../../../library/LinkCNO";
import { formatTimeWithTZ } from "../../../utils/Helpers";
import { EventType } from "../../../utils/mappings";
import { useGlobalStyles } from "../../../utils/Styles/GlobalStyles";
import EventResponseActions from "./EventResponseActions";
import EventShareAction from "./EventShareAction";

const EventListItemCard = ({ event }) => {
  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const theme = useTheme();

  const [isMapConfirmModal, setIsMapConfirmModal] = useState(false);

  return (
    <div
      className={clsx(
        GlobalStyles.listCardContainer,
        GlobalStyles.listCard,
        styles.container
      )}
    >
      <LinkCNO to={`/event/${event.id}`}>
        <div className={styles.imageWrapper}>
          {event && event?.mediaLinks && event?.mediaLinks.length ? (
            <img src={event?.mediaLinks[0]?.link} className={styles.image} />
          ) : (
            <Icon
              path={mdiPanoramaVariantOutline}
              size="60%"
              color={"grey"}
              className={styles.imageNotFound}
            />
          )}
        </div>
        <div className={GlobalStyles.datesWrapper}>
          <DateTimeText format={DateTimeFormat.CalendarString}>
            {formatTimeWithTZ(event.startDate)}
          </DateTimeText>
          <DateTimeText format={DateTimeFormat.TimeFormat}>
            {formatTimeWithTZ(event.startTime)}
          </DateTimeText>
        </div>
        <MetaText maxLine={2} className={GlobalStyles.cardTitle}>
          {event.title}
        </MetaText>

        <MetaText>{EventType.getItem(event.eventType).title}</MetaText>

        <div className={styles.responseActionStatusWrapper}>
          <MetaText>{event.interested.length} Interested</MetaText>
          <DotSeparator />
          <MetaText>{event.going.length} Going</MetaText>
        </div>
      </LinkCNO>

      <div className={styles.responseActionsWrapper}>
        <EventResponseActions event={event} />
        <EventShareAction event={event} />
      </div>

      {isMapConfirmModal && (
        <ConfirmationDialogCNO
          open={isMapConfirmModal}
          onClose={() => setIsMapConfirmModal(false)}
          dialogTitle="Navigate to Event Location"
          messages={["Do you want to navigate to event location?"]}
          yesClick={() => {
            window.open(
              `https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${event.location}`,
              "_blank"
            );
          }}
        />
      )}
    </div>
  );
};

export default EventListItemCard;

const useStyles = makeStyles(() => ({
  container: {
    height: 350,
    maxHeight: 350,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    background: alpha("#808080", 0.2),
  },
  image: {
    width: "100%",
    objectFit: "cover",
    maxHeight: 150,
  },
  imageNotFound: {
    maxHeight: 150,
  },
  responseActionStatusWrapper: {
    display: "flex",
    alignItems: "center",
  },
  responseActionsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
