import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import {
  mdiAccount,
  mdiAccountGroup,
  mdiPhoneCancel,
  mdiPhoneMissed,
  mdiPhoneIncoming,
  mdiImage,
  mdiMapMarker,
  mdiPhone,
  mdiVideo,
} from "@mdi/js";
import Icon from "@mdi/react";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  setActiveChatUser,
  setChatSnippetList,
  setMessageProfileCount,
} from "../../actions/chatActions";
import AvatarCNO from "../../library/AvatarCNO/AvatarCNO";
import { MessageType } from "../../utils";
import { calendarStringsForChat } from "../../utils/formatDate";
import { formatTimeWithTZ } from "../../utils/Helpers";
import { useStyles } from "./ChatsListItemStyles";

function ChatsListItem({ chat, clearSearch }) {
  const theme = useTheme();

  const ResponsiveLines = responsiveHOC()(LinesEllipsis);
  const styles = useStyles();

  const { id, contactName, contactAvatar, snippet, time, contactType, count } =
    chat;

  const tillTablet = useMediaQuery("(max-width: 768px)");

  const dispatch = useDispatch();
  const { activeChatUser, chatSnippetList, messageProfileCount } = useSelector(
    (state) => state.chats
  );

  const history = useHistory();

  const handleChatListClick = () => {
    clearSearch();

    dispatch(setActiveChatUser(chat));
    localStorage.setItem("lastActiveChatUser", JSON.stringify(chat));

    // to handle profile message count
    localStorage.setItem("currentChatProfile", JSON.stringify(chat));

    // set profile message count zero of selected chat
    const isProfileAlreadyInChatList = chatSnippetList.find(
      (profile) => profile.id === chat.id
    );

    let updatedChatProfileList = [...chatSnippetList];
    if (!isProfileAlreadyInChatList) {
      updatedChatProfileList = [chat, ...chatSnippetList];
    }
    updatedChatProfileList = updatedChatProfileList.map((profile) =>
      profile.id === id ? { ...profile, count: 0 } : profile
    );

    console.log(
      "====== handleChatListClick ====chatSnippetList ",
      chatSnippetList
    );
    console.log(
      "====== handleChatListClick ====updatedChatProfileList ",
      updatedChatProfileList
    );

    dispatch(setChatSnippetList(updatedChatProfileList));

    if (tillTablet) {
      history.push(`/chat/messages/${id}`);
    }
  };

  const renderChatSnippet = () => {
    if (
      chat.id &&
      chat.messageType &&
      (chat.messageType === MessageType.Candidate ||
        chat.messageType === MessageType.AutoAnswer ||
        chat.messageType === MessageType.AutoOffer ||
        chat.messageType === MessageType.VideoCall ||
        chat.messageType === MessageType.AudioCall ||
        chat.messageType === MessageType.AudioCallEnded ||
        chat.messageType === MessageType.VideoCallEnded)
    ) {
      return;
    }

    if (
      chat.messageType === MessageType.VideoCallAccepted ||
      chat.messageType === MessageType.AudioCallAccepted
    ) {
      return (
        <div className={styles.chatSnippetWrapper}>
          <Icon
            path={mdiPhoneIncoming}
            size="1rem"
            color={theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_ICON_COLOR}
            style={{
              marginRight: 5,
            }}
          />
          <Typography
            style={{
              color:
                theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_TEXT_COLOR,
            }}
          >
            Received Call
          </Typography>
        </div>
      );
    }

    if (
      chat.messageType === MessageType.VideoCallRejected ||
      chat.messageType === MessageType.AudioCallRejected
    ) {
      return (
        <div className={styles.chatSnippetWrapper}>
          <Icon
            path={mdiPhoneCancel}
            size="1rem"
            color={theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_ICON_COLOR}
            style={{
              marginRight: 5,
            }}
          />
          <Typography
            style={{
              color:
                theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_TEXT_COLOR,
            }}
          >
            Call Rejected
          </Typography>
        </div>
      );
    }

    if (
      chat.messageType === MessageType.AudioCallMissed ||
      chat.messageType === MessageType.VideoCallMissed
    ) {
      return (
        <div className={styles.chatSnippetWrapper}>
          <Icon
            path={mdiPhoneMissed}
            size="1rem"
            color={theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_ICON_COLOR}
            style={{
              marginRight: 5,
            }}
          />
          <Typography
            style={{
              color:
                theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_TEXT_COLOR,
            }}
          >
            Missed Call
          </Typography>
        </div>
      );
    }

    if (chat.messageType === MessageType.Image) {
      return (
        <div className={styles.chatSnippetWrapper}>
          <Icon
            path={mdiImage}
            size="1rem"
            color={theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_ICON_COLOR}
            style={{
              marginRight: 5,
            }}
          />
          <Typography
            style={{
              color:
                theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_TEXT_COLOR,
            }}
          >
            Image
          </Typography>
        </div>
      );
    }

    if (chat.messageType === MessageType.Video) {
      return (
        <div className={styles.chatSnippetWrapper}>
          <Icon
            path={mdiVideo}
            size="1rem"
            color={theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_ICON_COLOR}
            style={{
              marginRight: 5,
            }}
          />
          <Typography
            style={{
              color:
                theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_TEXT_COLOR,
            }}
          >
            Video
          </Typography>
        </div>
      );
    }

    if (chat.messageType === MessageType.Text) {
      if (chat.snippet && chat.snippet.includes("##Shared_Location##")) {
        return (
          <div className={styles.chatSnippetWrapper}>
            <Icon
              path={mdiMapMarker}
              size="1rem"
              color={
                theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_ICON_COLOR
              }
              style={{
                marginRight: 5,
              }}
            />
            <Typography
              style={{
                color:
                  theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_TEXT_COLOR,
              }}
            >
              Location
            </Typography>
          </div>
        );
      } else {
        return (
          <ResponsiveLines
            text={chat.snippet || ""}
            maxLine="1"
            ellipsis="..."
            trimRight
            basedOn="words"
            style={{
              color:
                theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_TEXT_COLOR,
            }}
          />
        );
      }
    }
  };

  return (
    <div
      className={clsx(styles.container_ChatsListItem, {
        [styles.chatItemActive]: activeChatUser?.id === chat.id,
      })}
      onClick={handleChatListClick}
    >
      <div className={styles.avatarWrapper}>
        {contactType === "user" && (
          <AvatarCNO
            src={contactAvatar}
            fallbackIcon={mdiAccount}
            avatarStyle={{
              border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}`,
            }}
          />
        )}
        {contactType === "group" && (
          <AvatarCNO
            src={contactAvatar}
            avatarStyle={{
              border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}`,
            }}
            fallbackIcon={mdiAccountGroup}
          />
        )}
      </div>
      <div className={styles.chatMetaWrapper}>
        <div className={styles.chatMetaTop}>
          <Typography className={styles.contactName}>
            <ResponsiveLines
              text={contactName || "Unknown"}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </Typography>
          <Typography className={styles.chatSnippet}>
            {renderChatSnippet()}
          </Typography>
        </div>
        <div className={styles.chatMetaRight}>
          {time && (
            <Moment className={styles.time} calendar={calendarStringsForChat}>
              {formatTimeWithTZ(time)}
            </Moment>
          )}
          {Boolean(count) && (
            <Typography component="span" className={styles.messageProfileCount}>
              {count}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatsListItem;
