import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react';
import * as yup from 'yup';
import { v4 } from 'uuid';
import InputCNO from '../../library/InputCNO';
import { mdiEmail, mdiFormatText, mdiPlus, mdiWeb } from '@mdi/js';
import Icon from '@mdi/react';
import ChipCNO from '../../library/ChipCNO';
import PhoneInputCNO from '../../library/PhoneInputCNO/PhoneInputCNO';
import { Button, Checkbox, fade, FormControlLabel, makeStyles, Typography, useTheme } from '@material-ui/core';
import {
  SnapList,
  SnapItem,
  useDragToScroll,
} from 'react-snaplist-carousel';
import { useDispatch } from 'react-redux';
import { addCompany } from '../../actions/customerCompanyActions';
import FormBox from '../FormBox';
import GooglePlaceInputCNO from '../../library/GooglePlaceInputCNO/GooglePlaceInputCNO';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import { IconPicker } from '../../library/IconPickerCNO';
import DialogCNO from '../../library/DialogCNO';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { RegexPatterns } from '../../utils/regexHelpers';
import { toast } from 'react-toastify';
import { Currency } from '../../utils/mappings';

function RegisterCompanyLP({ onRegister }) {

  const theme = useTheme();
  const styles = useStyles();

  const dispatch = useDispatch();

  const [isAddNewIcon, setIsAddNewIcon] = useState(false);
  const [notRobot, setNotRobot] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMoreDetailsExpanded, setIsMoreDetailsExpanded] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const validationSchema = yup.object({
    title: yup.string().required("Company name is required"),
    contactPersonFirstName: yup.string(),
    contactPersonLastName: yup.string(),
    abstract: yup.string(),
    profilePic: yup.string(),
    groupType: yup.number(),
    categories: yup.array().nullable(),
    email: yup.string().email('Please enter a valid email').required("Company email is required"),
    phone: yup.string().required("Mobile Number is required."),
    address: yup.string(),
    website: yup.string()
      .matches(RegexPatterns.url, 'Website url is not valid.'),
    currency: yup.number(),
    isActive: yup.boolean(),
    subscriptionType: yup.number(),
    paymentType: yup.number(),
    paymentDate: yup.string(),
    taxNumber: yup.string(),
    companyRegistration: yup.string(),
    defaultPaymentMethodId: yup.string().nullable(),
    defaultPaymentMethod: yup.object(),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      contactPersonFirstName: "",
      contactPersonLastName: "",
      abstract: "",
      profilePic: "",
      groupType: 0,
      categories: [
        {
          id: v4(),
          title: "General",
          // groupId: v4(),
          iconLink: "bullseye"
        }
      ],
      email: "",
      phone: "",
      address: "",
      website: "",
      currency: Currency.GBP,
      isActive: true,
      subscriptionType: 4,
      paymentType: 0,
      paymentDate: new Date(),
      taxNumber: "",
      companyRegistration: "",
      defaultPaymentMethodId: null,
      defaultPaymentMethod: {},
    },
    validationSchema,
    onSubmit: (values) => handleRegisterCompany(values)
  });

  const handleRegisterCompany = async (values) => {

    setLoading(true);

    let dataToSend = {
      title: values.title,
      email: values.email,
      phone: values.phone,
      categories: values.categories,
      address: values.address,
      abstract: values.abstract,
      website: values.website,
      subscriptionType: values.subscriptionType,
      currency: values.currency
    };

    console.log("=========handleRegisterCompany====dataToSend==", dataToSend);

    await dispatch(addCompany(dataToSend, true));

    if (onRegister) {
      onRegister(formik.values.email);
      alert('An email with password has been sent to email address entered during registration.\n\n Please check email for further instructions.\n\nEnjoy the application!!');
      toast.success("An email with password has been sent to email address entered during registration.\n\n Please check email for further instructions.\n\nEnjoy the application!!");
    }

    formik.resetForm();

    setLoading(false);
  };

  const handleChange = () => {
    setIsMoreDetailsExpanded(!isMoreDetailsExpanded);
  };

  return (
    <Fragment>

      {
        loading &&
        <FullScreenLoaderCNO />
      }

      <form
        onSubmit={formik.handleSubmit}
        className={styles.form}
      >

        <FormBox bgColor={theme.colors.MODAL_BACKGROUND_COLOR}>

          <InputCNO
            name='title'
            label='Company Name'
            formik={formik}
            placeholder='Company Name'
            icon={mdiFormatText}
            fullWidth
          />

          <InputCNO
            name='email'
            label='Email'
            type='email'
            formik={formik}
            placeholder='Email'
            icon={mdiEmail}
            fullWidth
          />
          <FormBox bgColor={theme.colors.MODAL_BACKGROUND_COLOR} title="Mobile Number">
            <PhoneInputCNO
              label={'Mobile'}
              placeholder='Enter phone number'
              value={formik.values.phone}
              onChange={(value) => formik.setFieldValue('phone', value)}
              showHelperText={formik.errors.phone}
              helperText={formik.errors.phone}
              required={true}
              onValidatePhone={(error) => {
                setDisabled(!!error)
              }}
            />
          </FormBox>

          <div className={styles.link} onClick={handleChange}>
            {
              (isMoreDetailsExpanded)
                ? 'Less Details'
                : 'More Details'
            }
          </div>

        </FormBox>
        {
          (isMoreDetailsExpanded) &&
          <div
            className={styles.accordionDetails}
          >
            <FormBox bgColor={theme.colors.MODAL_BACKGROUND_COLOR} title={"Categories"}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant='contained'
                  className={styles.addBtn}
                  startIcon={<Icon path={mdiPlus} size="1.5rem" color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR} />}
                  onClick={() => setIsAddNewIcon(true)}
                >
                  <pre>Add New</pre>
                </Button>
              </div>

              <div className={styles.selectChips}>
                <SnapList className={styles.chipsWrapper}>
                  {
                    formik.values.categories.map((category) => (
                      <SnapItem>
                        <ChipCNO
                          icon={category.iconLink}
                          isCategoryChip={true}
                          name={category.title}
                          active={true}
                        // onClick={() => formik.setFieldValue('subscriptionType', CompanySubscription.Silver)}
                        />
                      </SnapItem>
                    ))
                  }
                </SnapList>

              </div>
            </FormBox>

            <FormBox bgColor={theme.colors.MODAL_BACKGROUND_COLOR} title="Contact Details">
              <GooglePlaceInputCNO
                address={formik.values.address}
                onSelectAddress={(addressObj) => {
                  formik.setFieldValue('address', addressObj.address)
                  formik.setFieldValue('lat', addressObj.lat)
                  formik.setFieldValue('lng', addressObj.lng)
                }}
                label="Location"
              />

              <InputCNO
                name='website'
                label='Website'
                formik={formik}
                placeholder='Website'
                icon={mdiWeb}
                fullWidth
              />

              <div style={{ marginTop: 16 }}>
                <InputCNO
                  name='abstract'
                  label='About Company'
                  formik={formik}
                  placeholder='About Company'
                  fullWidth
                  multiline
                />
              </div>
            </FormBox>

          </div>
        }


        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={notRobot}
                onChange={() => {
                  setNotRobot(!notRobot);
                }}
                name="notRobot"
                color="primary"
              />
            }
            label="I am not a Robot"
          />
        </div>

        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          <Button
            type='submit'
            variant='contained'
            disabled={(!formik.dirty || !formik.isValid || !notRobot || disabled)}
            size='large'
            className={styles.registerBtn}
            disableElevation
          >
            Register
          </Button>
        </div>

      </form>

      {
        isAddNewIcon &&
        <AddNewIconModal
          open={isAddNewIcon}
          onClose={() => setIsAddNewIcon(false)}
          formikRegister={formik}
        />
      }

    </Fragment>
  )
}

export default RegisterCompanyLP;


const AddNewIconModal = ({
  open,
  onClose,
  formikRegister
}) => {

  const styles = useStyles();

  const validationSchema = yup.object({
    iconLink: yup.string().required("Required"),
    iconTitle: yup.string().required("Required"),
  })

  const formik = useFormik({
    initialValues: {
      iconLink: '',
      iconTitle: ''
    },
    validationSchema,
    onSubmit: (values) => addNewCategory(values)
  });

  const addNewCategory = (values) => {

    const newCategory = {
      id: v4(),
      title: values.iconTitle,
      // groupId: null,
      iconLink: values.iconLink
    };

    const payload = [newCategory, ...formikRegister.values.categories];

    formikRegister.setFieldValue("categories", payload);

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Add New Category"
    >
      <form className={styles.addNewIconForm} onSubmit={formik.handleSubmit}>

        <Typography className={styles.addNewIconLabel}>Category Icon</Typography>
        <IconPicker
          value={formik.values.iconLink}
          onChange={(value) => formik.setFieldValue('iconLink', value)}
          buttonStyles={{
            background: 'white',
            marginRight: 10,
            width: '100%',
            marginBottom: 10
          }}
        />

        <InputCNO
          name={'iconTitle'}
          label="Category Title"
          formik={formik}
          placeholder='Category Title'
          icon={mdiFormatText}
          fullWidth
        />

        <FormActionButtons
          type="submit"
          rightText="Save"
          leftClick={onClose}
          formik={formik}
          wrapperStyles={{ marginTop: 120 }}
        />
      </form>
    </DialogCNO>
  )
};

const useStyles = makeStyles((theme) => ({

  form: {
    marginTop: theme.spacing(1),
    '&>*': {
      marginBottom: theme.spacing(2)
    },
  },

  link: {
    marginTop: theme.spacing(2),
    textDecoration: 'underline',
    color: '#79a8d7',
    '&:hover': {
      cursor: 'pointer',
    }
  },

  accordionDetails: {
    width: '100%',
    '&>*': {
      marginTop: 20
    }
  },

  selectChips: {
    '&>*:first-child': {
      color: theme.colors.FORM_LABEL_COLOR
    }
  },

  chipsWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    '&>*': {
      marginRight: 10
    }
  },

  addBtn: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    padding: '0 8px',
    '&:hover': {
      background: fade(theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR, 0.7),
    },
  },

  registerBtn: {
    border: `2px solid ${theme.colors.FORM_CANCEL_BUTTON_BORDER_COLOR}`,
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    marginRight: 10,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
  },

}))
