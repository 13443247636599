import moment from "moment";
import {
  SET_CALENDAR_LOADERS,
  SET_CALENDAR_FILTER,
  SET_EVENTS_LIST,
  SET_ADD_MODALS_CALENDAR_DATES,
  SET_API_CALENDAR_DATES_FILTER
} from "../actions/calendarActions";
import { CalendarFilter } from "../utils/mappings";
import RightsManager from "../utils/RightsManager";

const isUserEmployee = RightsManager.isMember();

const initialState = {
  eventsList: [],
  calendarFilter: isUserEmployee ? CalendarFilter.Jobs : CalendarFilter.Appointments,

  // Loaders
  eventsLoader: false,


  // add dates
  addModalsCalendarDates: {
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  },

  // calendar range dates
  apiCalendarDatesFilter: {
    startDate: moment().startOf('D'),
    startTime: moment().startOf('D'),
    endDate: moment().endOf('D'),
    endTime: moment().endOf('D'),
  },


};

export const calendarReducer = (state = initialState, { type, payload }) => {

  switch (type) {
    case SET_EVENTS_LIST: {
      state = {
        ...state,
        eventsList: payload
      };
      return state;
    }

    case SET_CALENDAR_FILTER: {
      state = {
        ...state,
        calendarFilter: payload
      };
      return state;
    }

    case SET_CALENDAR_LOADERS: {
      const { loaderName, loaderState } = payload;
      state = {
        ...state,
        [loaderName]: loaderState
      };
      return state;
    }

    case SET_ADD_MODALS_CALENDAR_DATES: {
      state = {
        ...state,
        addModalsCalendarDates: payload
      };
      return state;
    }

    case SET_API_CALENDAR_DATES_FILTER: {
      state = {
        ...state,
        apiCalendarDatesFilter: payload
      };
      return state;
    }

    default:
      return state;
  }

};