import { Dialog, makeStyles, Typography, useTheme } from '@material-ui/core';
import { mdiClockEnd, mdiClockStart } from '@mdi/js';
import Icon from '@mdi/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useState } from 'react';
import Moment from 'react-moment';
import HeadingBar from '../HeadingBar/HeadingBar';
import clsx from 'clsx';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import { calendarStrings, TIME_FORMAT } from '../../utils/formatDate';
import RightsManager from '../../utils/RightsManager';
import { apiTaskEdit } from '../../api';
import { toast } from 'react-toastify';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { formatTimeWithTZ, validateStartDateEndDate } from '../../utils/Helpers';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';
import { useDispatch } from 'react-redux';
import { editJob } from '../../actions/jobActions';

function EditJobTimelineDetails({ isEditable, setIsEditable, job, setJob }) {

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleClose = () => setIsEditable(false);

  const handleUpdate = async (values) => {
    const payload = {
      ...job,
      startDate: values.startDate,
      startTime: values.startTime,
      endDate: values.endDate,
      endTime: values.endTime,
    };

    setJob(payload);

    dispatch(editJob(payload));

    handleClose();
  };

  const validationSchema = yup.object({
    startDate: yup.date('Invalid Date').required("Start Date is required."),
    startTime: yup.date('Invalid Date').required("Start Time is required."),
    endDate: yup.date('Invalid Date')
      .required("End Date is required.")
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      ),
    endTime: yup.date('Invalid Date')
      .required("End Time is required.")
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      )
  });

  const formik = useFormik({
    initialValues: {
      startDate: new Date(job.startDate).toISOString(),
      startTime: new Date(job.startTime).toISOString(),
      endDate: new Date(job.endDate).toISOString(),
      endTime: new Date(job.endTime).toISOString()
    },
    validationSchema,
    onSubmit: values => handleUpdate(values)
  });

  const useStyles = makeStyles(theme => ({
    container: {
      '&>*': {
        marginTop: theme.spacing(2)
      }
    },
    formTitle: {
      marginLeft: 5
    },
    form: {
      '&>*': {
        marginTop: theme.spacing(2)
      }
    },
    paper: {
      padding: theme.spacing(2),
      background: theme.colors.DIALOG_BACKGROUND_COLOR,
      // color: theme.colors.DIALOG_TITLE_COLOR,
      borderRadius: 10,
      width: '70vw',
      maxWidth: '90vh'
    },
    timeStampWrapper: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    picker: {
      '& .MuiInputBase-root': {
        background: theme.colors.INPUT_BACKGROUND_COLOR,
        color: theme.colors.INPUT_TEXT_COLOR,
      },
      '& .MuiIconButton-label': {
        color: theme.colors.FORM_LABEL_COLOR,
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 0
      },
      '& .MuiOutlinedInput-input': {
        paddingLeft: 5,
      },
      '& .MuiIconButton-root': {
        paddingLeft: 0,
        paddingRight: 5,
      }
    },
    time: {
      marginLeft: theme.spacing(2),
      maxWidth: '40%',
    },
    start: {
      '& .MuiSvgIcon-root': {
        color: theme.colors.JOB_START_DATE_COLOR
      }
    },
    end: {
      '& .MuiSvgIcon-root': {
        color: theme.colors.JOB_END_DATE_COLOR
      }
    },
  }));
  const styles = useStyles();

  if (loading) {
    return (
      <FullScreenLoaderCNO />
    );
  }

  return (
    <Dialog
      open={isEditable}
      className={styles.container}
      classes={{
        paper: styles.paper
      }}
    >
      <HeadingBar title="Edit Job Dates" onClose={handleClose} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>

        <DateTimePickerCNO
          dateName="startDate"
          datePlaceholder='Start Date'
          onDateChange={(date) => {

            formik.setFieldValue('startDate', date);
          }}
          datePickerLabel="Start by Date"

          timeName="startTime"
          timePlaceholder='Start Time'
          onTimeChange={(date) => {
            console.log("=====date====", date);
            formik.setFieldValue('startTime', date);
          }}
          timePickerLabel="Start by Time"

          formik={formik}
          iconColor={"red"}
          disablePast={true}
        />

        <DateTimePickerCNO
          dateName="endDate"
          datePlaceholder='Finish Date'
          onDateChange={(date) => {
            formik.setFieldValue('endDate', date);
          }}
          datePickerLabel="Finish by Date"

          timeName="endTime"
          timePlaceholder='Finish Time'
          onTimeChange={(date) => {
            formik.setFieldValue('endTime', date);
          }}
          timePickerLabel="Finish by Time"

          formik={formik}
          disablePast={true}
          iconColor={"green"}
          minDate={formik.values.startDate}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
        />
      </form>
    </Dialog>
  )
}

function JobTimelineDetailsCard({ job, setJob }) {

  const theme = useTheme();

  const isUserAdmin = RightsManager.isAdmin();

  const [isEditable, setIsEditable] = useState(false);

  const styles = useStyles();
  return (<>
    {
      isUserAdmin && isEditable &&
      <EditJobTimelineDetails
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        job={job}
        setJob={setJob}
      />
    }
    <div className={styles.container} onClick={() => setIsEditable(true)}>
      <Typography className={styles.cardTitle}>
        Timeline
      </Typography>
      <div className={styles.cardContent}>
        <div className={clsx(styles.row, styles.startDate)}>
          <Typography className={styles.startDate}>
            <Moment interval={0} calendar={calendarStrings}>
              {formatTimeWithTZ(job.startDate)}
            </Moment>
            <Moment interval={0} format={TIME_FORMAT} >
              {formatTimeWithTZ(job.startTime)}
            </Moment>
          </Typography>
          <Icon
            path={mdiClockStart}
            size='2rem'
            className={styles.icon}
            color={theme.colors.JOB_START_DATE_COLOR}
          />
        </div>
        {
          isUserAdmin &&
          <div className={clsx(styles.row, styles.endDate)}>
            <Typography>
              <Moment interval={0} calendar={calendarStrings} >
                {formatTimeWithTZ(job.endDate)}
              </Moment>
              <Moment interval={0} format={TIME_FORMAT}>
                {formatTimeWithTZ(job.endTime)}
              </Moment>
            </Typography>
            <Icon
              path={mdiClockEnd}
              size='2rem'
              className={styles.icon}
              color={theme.colors.JOB_END_DATE_COLOR}
            />
          </div>
        }
      </div>
    </div>
  </>)
}

export default JobTimelineDetailsCard

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`
  },
  cardTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
  },
  cardContent: {
    padding: theme.spacing(1),
    '&>*': {
      marginTop: theme.spacing(1)
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  startDate: {
    color: theme.colors.JOB_START_DATE_COLOR,
  },
  endDate: {
    color: theme.colors.JOB_END_DATE_COLOR,
  },
  icon: {
    color: theme.colors.JOB_END_DATE_COLOR,
  }
}))