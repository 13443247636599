import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 10,
    padding: '16px 8px',
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    color: theme.palette.getContrastText(theme.palette.background.main),
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    width: '100%'
  },
  jobMeta: {
    display: 'flex',
  },
  meta: {
    marginLeft: theme.spacing(2),
    flex: 1,
    marginBottom: theme.spacing(1)
  },
  title: {
    color: theme.colors.LIST_CARD_TITLE_COLOR,
    fontWeight: '700'
  },
  date: {
    color: theme.colors.LIST_CARD_DATE_COLOR,
    fontSize: '0.8rem'
  },
  username: {
    color: theme.colors.LIST_CARD_USERNAMES_COLOR,
    fontSize: '0.8rem'
  },
  moreIcon: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
  jobDescription: {
    background: theme.colors.LIST_CARD_PARA_BACKGROUND_COLOR,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderRadius: 5,
    color: theme.colors.LIST_CARD_PARA_COLOR,
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
  },
  btn: {
    marginTop: theme.spacing(1),
    //marginTop : "0.8rem",
    padding : "0.5rem 1rem",
    textTransform : "uppercase",
    fontWeight : "500",
    color : "white",
    backgroundColor : "#3F8A02",
    borderRadius : "4px",
    border: "2px solid #fcb103",
    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
    cursor : "pointer",
    '&:hover':{
        backgroundColor : "#E0E0E0",
    },
    
  },
}));
