import React, { useContext, useEffect, useState } from "react";
import TestCard from "../TestCard/TestCard";
import { makeStyles, useTheme } from "@material-ui/core";
import { mdiCalendarPlus, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import TestInfiniteScrollCNO from "../../library/TestInfiniteScrollCNO";
import Snackbar from "@material-ui/core/Snackbar";
import { TestContext } from "../../common/contexts/TestContext";

function TestListingsInfinite({
  page,
  setPage,
  isGridView,
  setIsGridView,
  searchParams = {}, // Default to an empty object
}) {
  const styles = useStyles();
  const theme = useTheme();
  const { allTests } = useContext(TestContext);
  const [filteredTests, setFilteredTests] = useState(allTests);

  useEffect(() => {
    console.log("All Tests:", allTests); // Debugging log
    console.log("Search Params:", searchParams); // Debugging log

    const filterTests = () => {
      return allTests.filter((test) => {
        const matchesTitle = test.title
          .toLowerCase()
          .includes((searchParams.title || "").toLowerCase());
        const matchesDescription = test.description
          .toLowerCase()
          .includes((searchParams.description || "").toLowerCase());
        const matchesDuration =
          test.duration_minutes >= (searchParams.duration_minutes?.[0] || 0) &&
          test.duration_minutes <= (searchParams.duration_minutes?.[1] || 100);
        const matchesCategory =
          !searchParams.categoryIds ||
          searchParams.categoryIds.length === 0 ||
          searchParams.categoryIds.includes(test.categoryId);
        return (
          matchesTitle &&
          matchesDescription &&
          matchesDuration &&
          matchesCategory
        );
      });
    };

    const filtered = filterTests();
    console.log("Filtered Tests:", filtered); // Debugging log
    setFilteredTests(filtered);
  }, [allTests, searchParams]);

  return (
    <div className={styles.container}>
      <TestInfiniteScrollCNO
        items={filteredTests}
        renderListItem={(test) => <TestCard key={test.testId} item={test} />}
        notFoundTextMessage={"No Test Present"}
        notFoundIcon={mdiCalendarPlus}
        isGridView={isGridView}
        setIsGridView={setIsGridView}
        isJobs={true}
      />

      <Snackbar
        className={styles.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={false}
        autoHideDuration={10000}
        message="Test Deleted Successfully"
        action={
          <React.Fragment>
            <button
              color="secondary"
              size="small"
              onClick={() => {
                /* handle undo */
              }}
            >
              UNDO
            </button>
            <Icon
              path={mdiClose}
              size="1rem"
              aria-label="close"
              color="inherit"
              onClick={() => {
                /* handle close */
              }}
            />
          </React.Fragment>
        }
      />
    </div>
  );
}

export default TestListingsInfinite;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  snackbar: {
    [theme.breakpoints.up("sm")]: {
      bottom: 24,
    },
  },
}));
