import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTestStyles } from './Test.styles';
import ScreenView from '../../components/ScreenView';

const Result = () => {
  const location = useLocation();
  const { answers, testId, test } = location.state || { answers: {}, testId: null, test: { questions: [] } };
  const [correctAnswers, setCorrectAnswers] = useState(null);
  const [error, setError] = useState(null);
  const styles = useTestStyles();

  const fetchAnswers = () => {
    try {
      const data = localStorage.getItem('allAnswers');
      if (!data) {
        throw new Error('Failed to fetch test data');
      }
      const parsedData = JSON.parse(data);
      const selectedTestAnswers = parsedData.answers.find((t) => t.testId === parseInt(testId, 10));
      if (!selectedTestAnswers) {
        throw new Error(`Answers for test id ${testId} not found`);
      }
      setCorrectAnswers(selectedTestAnswers.questions);
    } catch (error) {
      console.error('Error fetching test data:', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    if (testId) {
      fetchAnswers();
    }
  }, [testId]);

  const calculateScore = () => {
    if (!correctAnswers) return { score: 0, correctCount: 0, wrongCount: 0 };
    let score = 0;
    let correctCount = 0;
    let wrongCount = 0;

    correctAnswers.forEach(correctAnswer => {
      const userAnswer = answers[correctAnswer.questionId];
      const question = test.questions.find(q => correctAnswer.questionId === q.id);
      const questionType = question?.type;

      let isCorrect = false;

      if (questionType === 'multiple_choice' || questionType === 'checkbox') {
        const userAnswerIndex = question.options.indexOf(userAnswer);
        if (userAnswerIndex !== -1 && userAnswerIndex === correctAnswer.answer) {
          isCorrect = true;
        }
      } else if (questionType === 'text_reply' || questionType === 'video' || questionType === 'image') {
        if (userAnswer && userAnswer.toLowerCase() === correctAnswer.answer.toLowerCase()) {
          isCorrect = true;
        }
      } else if (questionType === 'number_reply') {
        if (userAnswer === correctAnswer.answer) {
          isCorrect = true;
        }
      }

      if (isCorrect) {
        score++;
        correctCount++;
      } else {
        wrongCount++;
      }
    });

    return { score, correctCount, wrongCount };
  };

  const calculateAttemptedAndUnattempted = () => {
    const totalQuestions = test.questions.length;
    const attemptedQuestions = Object.keys(answers).length;
    const unattemptedQuestions = totalQuestions - attemptedQuestions;

    return { attemptedQuestions, unattemptedQuestions };
  };

  if (!testId) {
    return <div>Invalid test data</div>;
  }

  const { attemptedQuestions, unattemptedQuestions } = calculateAttemptedAndUnattempted();
  const { score, correctCount, wrongCount } = calculateScore();

  const renderContent = () => (
    <div className={styles.resultContainer}>
      {correctAnswers && (
        <div className={styles.resultbox}>
          <h2 className={styles.resultheader}>Test Result {testId}</h2>
          <div className={styles.breaker}></div>
          <br />
          <p>Congratulations! You have completed the test.</p>
          <br />
          <p>Total Questions : {correctAnswers.length}</p>
          <p>Attempted Questions: {attemptedQuestions}</p>
          <p>Unattempted Questions: {unattemptedQuestions}</p>
          <p>Total Correct Answers: {correctCount}</p>
          <p>Total Wrong Answers: {wrongCount}</p>
          <br />
          <p style={{ color: 'red', fontWeight: 'bold' }}>Score: {score} / {correctAnswers.length}</p>
        </div>
      )}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );

  return (
    <div style={{ width: '100%', minHeight: '100vh' }}>
      <ScreenView
        content={renderContent()}
        headerTitle="Tests"
      />
    </div>
  );
};

export default Result;
