import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import FormBox from './FormBox/FormBox';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import InputCNO from '../library/InputCNO';
import { mdiChevronDown, mdiEye, mdiEyeOff, mdiLock } from '@mdi/js';
import FormActionButtons from './FormActionButtons';
import { updateAuthPassword, validateOldPassword } from '../actions/authUserActions';
import LocalStorageConstants from '../utils/LocalStorageConstants';
import FullScreenLoaderCNO from '../library/FullScreenLoaderCNO/FullScreenLoaderCNO';
import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@material-ui/core';
import { useGlobalStyles } from '../utils/Styles/GlobalStyles';
import Icon from '@mdi/react';
import { RegexPatterns } from '../utils/regexHelpers';

function ChangePassword() {

  const authUser = LocalStorageConstants.getItem('authUser');

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();

  const { updateLoader, isOldPasswordValid } = useSelector(state => state.authUser);

  const [isPasswordSecure, setIsPasswordSecure] = useState({
    oldPassword: true,
    newPassword: true,
    confirmPassword: true
  });

  const [isSecurityExpanded, setIsSecurityExpanded] = useState(false);

  useEffect(() => {
    formik.setFieldValue('isOldPasswordValid', isOldPasswordValid);
  }, [isOldPasswordValid]);

  const validationSchema = yup.object({
    oldPassword: yup.string()
      .min(4, "Atleast 4 characters are required")
      .required("Old password is required.")
      .test(
        'oldPassword',
        'Invalid old password',
        function () {
          console.log("Invalid old password", this.parent.isOldPasswordValid);
          return this.parent.isOldPasswordValid;
        }
      ),
    newPassword: yup.string()
      .required("New password is required.")
      .matches(
        RegexPatterns.passwordStrength,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmPassword: yup.string()
      .oneOf([yup.ref('newPassword'), null], 'Confirm Password must be same as New Password.')
      .required("Confirm password is required."),
  });

  const formik = useFormik({
    initialValues: {
      isOldPasswordValid: isOldPasswordValid,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: (values) => handleChangePassword(values)
  });

  const handleChangePassword = (values) => {

    const payload = {
      username: authUser.email,
      password: values.newPassword,
    };

    console.log("=====handleChangePassword: ", payload);

    dispatch(updateAuthPassword(payload));

    formik.resetForm();

  };

  return (
    <Accordion
      expanded={isSecurityExpanded}
      className={GlobalStyles.accordion}
    >
      <AccordionSummary
        expandIcon={<Icon
          path={mdiChevronDown}
          size="1.2rem"
          color={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR} />
        }
        className={GlobalStyles.accordionSummary}
        style={{ flexDirection: 'row' }}
        onClick={async (e) => {
          e.stopPropagation();
          setIsSecurityExpanded(!isSecurityExpanded);
        }}
      >
        Security
      </AccordionSummary>
      <AccordionDetails>
        <div>

          {
            updateLoader &&
            <FullScreenLoaderCNO />
          }

          <div style={{ maxWidth: 400 }}>

            <InputCNO
              name='oldPassword'
              label='Old Password'
              formik={formik}
              placeholder={'Old Password'}
              type={isPasswordSecure.oldPassword ? "password" : 'text'}
              icon={mdiLock}
              isRequired={true}
              containerStyles={{ marginTop: 10 }}
              rightIcon={isPasswordSecure.oldPassword ? mdiEye : mdiEyeOff}
              onRightIconClick={() => setIsPasswordSecure(prev => ({ ...prev, oldPassword: !prev.oldPassword }))}
              onBlur={() => {
                const payload = {
                  username: authUser.email,
                  password: formik.values.oldPassword
                };
                dispatch(validateOldPassword(payload));
              }}
              isCopyAllowed={false}
              isPasteAllowed={false}
            />

            <InputCNO
              name='newPassword'
              label='New Password'
              formik={formik}
              placeholder={'New Password'}
              type={isPasswordSecure.newPassword ? "password" : 'text'}
              icon={mdiLock}
              fullWidth
              isRequired={true}
              containerStyles={{ marginTop: 10 }}
              rightIcon={isPasswordSecure.newPassword ? mdiEye : mdiEyeOff}
              onRightIconClick={() => setIsPasswordSecure(prev => ({ ...prev, newPassword: !prev.newPassword }))}
              isCopyAllowed={false}
              isPasteAllowed={false}
            />

            <InputCNO
              name='confirmPassword'
              label='Confirm Password'
              formik={formik}
              placeholder={'Confirm Password'}
              type={isPasswordSecure.confirmPassword ? "password" : 'text'}
              icon={mdiLock}
              isRequired={true}
              containerStyles={{ marginTop: 10 }}
              rightIcon={isPasswordSecure.confirmPassword ? mdiEye : mdiEyeOff}
              onRightIconClick={() => setIsPasswordSecure(prev => ({ ...prev, confirmPassword: !prev.confirmPassword }))}
            />

            <FormActionButtons
              rightText={'Change Password'}
              rightClick={formik.handleSubmit}
              formik={formik}
              isLeftButton={false}
              wrapperStyles={{ marginTop: 30, marginBottom: 20 }}
            />

          </div>

        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default ChangePassword;
