import { updateIdWithUUID } from "../../utils/Helpers";
import {
  create,
  education,
  Health,
  Home,
  Family,
  Interests,
  Milestones,
  Relationship,
  Remembrance,
  Travel,
  Work,
} from "../../assets/animatedGifStore";

const categoriesData = [
  {
    id: "",
    category: "Work",
    iconOutlined: "mdiBriefcaseOutline",
    example: "achievement, volunteering, new skill or certification",
    events: ["New Job", "Promotion", "Left Job", "Retirement"],
    gif: Work,
    icon:'mdiBriefcase'
  },
  {
    id: "",
    category: "Education",
    iconOutlined: "mdiSchoolOutline",
    example: "achievement, new skill or joined a club or team",
    events: ["New School", "Graduated", "Left school/ Univerity"],
    gif: education,
    icon:'mdiSchool'
    
  },
  {
    id: "",
    category: "Relationship",
    iconOutlined: "mdiHeartMultipleOutline",
    example: "shared milestone, first trip or moved in together",
    events: ["New Relationship", "Engagement", "Marriage", "First Met"],
    gif: Relationship,
    icon:'mdiHeartMultiple'

  },
  {
    id: "",
    category: "Home and Living",
    iconOutlined: "mdiHomeVariantOutline",
    example: "new housemate, DIY, new house or new car",
    events: ["Moved", "Lived", "Home Town"],
    gif: Home,
    icon:'mdiHomeVariant'

  },
  {
    id: "",
    category: "Family",
    iconOutlined: "mdiCrowd",
    example: "new family member, family reunion, family event or adopted a pet",
    events: ["New Child", "Parenthood", "New Pet", "Loss of a loved one"],
    gif: Family,
    icon:'mdiCrowd'

  },
  {
    id: "",
    category: "Travel",
    iconOutlined: "mdiGlobeModel",
    example: "travel, road trip or first flight",
    events: [],
    gif: Travel,
    icon:'mdiGlobeModel'

  },
  {
    id: "",
    category: "Interests and Activities",
    iconOutlined: "mdiCreationOutline",
    example: "concert, event, new hobby or activism",
    events: [],
    gif: Interests,
    icon:'mdiCreation'

  },
  {
    id: "",
    category: "Health and WellBeing",
    iconOutlined: "mdiHandHeartOutline",
    example: "new habit, health update, training or sporting event",
    events: [],
    gif: Health,
    icon:'mdiHandHeart'

  },
  {
    id: "",
    category: "Milestones and Achievements",
    iconOutlined: "mdiStarShootingOutline",
    example:
      "cultural event, religious event, achievement, first or milestone birthday",
    events: [],
    gif: Milestones,
    icon:'mdiStarShooting'

  },
  {
    id: "",
    category: "Remembrance",
    iconOutlined: "mdiCandle",
    example: "remembering a friend, celebration of life, in memory of...",
    events: [],
    gif: Remembrance,
    icon:'mdiCandle'

  },
  {
    id: "",
    category: "Create Your Own",
    iconOutlined: "mdiFlagOutline",
    example: "",
    events: [],
    gif: create,
    icon:'mdiFlag'

  },
];

export const categories = updateIdWithUUID(categoriesData);
