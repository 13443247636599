import { toast } from "react-toastify";
import {
  apiCustomerCreate,
  apiCustomerEdit,
  apiCustomerSearch
} from "../api";
import { RoleId } from "../utils/mappings";

export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const SEARCH_CUSTOMER = "SEARCH_CUSTOMER";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_CUSTOMERS_LOADER = "SET_CUSTOMERS_LOADER";
export const SET_HAS_MORE_CUSTOMERS = "SET_HAS_MORE_CUSTOMERS";
export const SET_CUSTOMER_BY_ID = "SET_CUSTOMER_BY_ID";
export const TOGGLE_ADD_CUSTOMER_DIALOG = "TOGGLE_ADD_CUSTOMER_DIALOG";
export const SET_SEARCHED_CUSTOMERS = "SET_SEARCHED_CUSTOMERS";
export const SET_CUSTOMER_SEARCH_PARAMS = "SET_CUSTOMER_SEARCH_PARAMS";

export function setCustomerSearchParams(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_CUSTOMER_SEARCH_PARAMS,
      payload: payload
    });

    dispatch(findCustomers(payload));

  };
}

export function createCustomer(payload) {
  return async function createCustomerThunk(dispatch, getState) {

    // payload = payload;

    await apiCustomerCreate.post(payload)
      .then(response => {
        if (response.status === 200) {
          toast.success("Customer created successfully!!!");
          dispatch({
            type: CREATE_CUSTOMER,
            payload: response
          });
        }
        else {
          toast.error("Erroe while creating customer!!!");
        }
      })
      .catch(error => {
        console.error("Create Customer Error: ", error);
        toast.error("Error while creating customer!!!");
      });
  };
}

export function searchCustomer(payload) {
  return async function searchCustomerThunk(dispatch, getState) {

    const dataToSend = {
      ids: [],
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortDecending: true,
      searchText: "",
      lat: "",
      lng: "",
      ip: "",
      resolvePic: true,
      ...payload
    };

    await apiCustomerSearch.post(dataToSend)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: SEARCH_CUSTOMER,
            payload: response
          });
        }
        else {
          toast.error("Error while loading customer information!!!");
        }
      })
      .catch(error => {
        console.error("Customer Search Error: ", error);
        toast.error("Error while loading customer information!!!");
      });
  };
}

export function setHasMoreCustomers(status) {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_MORE_CUSTOMERS,
      payload: status
    });
  };
}

export function setCustomersLoader(loaderType, loaderState) {
  return (dispatch) => {

    const loaderPayload = {
      loaderType,
      loaderState
    };

    dispatch({
      type: SET_CUSTOMERS_LOADER,
      payload: loaderPayload
    });
  };
}

export function setCustomers(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_CUSTOMERS,
      payload: payload
    });
  };
}

export function setSearchedCustomers(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_SEARCHED_CUSTOMERS,
      payload: payload
    });
  };
}

export function toggleAddCustomerDialog(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_CUSTOMER_DIALOG,
      payload: status
    });
  };
}

export function searchCustomers(payload) {
  return async (dispatch, getState) => {

    dispatch(setCustomersLoader('customersLoader', true));

    const { customerSearchParams } = getState().customers;

    payload = {
      ids: [],
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortDecending: true,
      lat: "",
      lng: "",
      ip: "",
      resolvePic: true,
      searchText: customerSearchParams.customer ? customerSearchParams.customer.title : null,
      ...payload
    };

    await apiCustomerSearch.post(payload)
      .then((response) => {

        if (response.status === 200) {

          let customersResponse = response.data.data;
          console.log("customersResponse: ", customersResponse);
          dispatch(setSearchedCustomers(customersResponse));

          if (customersResponse < 10) {
            dispatch(setHasMoreCustomers(false));
          }

        }
        else {
          console.error("Error: Fetch Customers: ");
        }

      })
      .catch((error) => {
        console.error("Error: Fetch Customers: ", error);
      })
      .finally(() => {
        dispatch(setCustomersLoader('customersLoader', false));
      });

  };
}

export function findCustomers(payload) {
  return (dispatch, getState) => {

    console.log("===> findCustomers payload", payload);

    const searchParams = {
      customer: payload.customer ? payload.customer : null,
    };

    const searchPayload = {
      ids: [],
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortDecending: true,
      searchText: "",
      lat: "",
      lng: "",
      ip: "",
      resolvePic: true,
      ...payload.customer ? ({
        ...(payload.customer.enterKey ? { searchText: payload.customer.title } : {}),
        ...(!payload.customer.enterKey ? { ids: [payload.customer.id] } : {}),
      }) : {},
    };

    dispatch(fetchCustomers(searchPayload));

  };
}

export function fetchCustomers(payload) {
  return async (dispatch, getState) => {

    dispatch(setCustomersLoader('customersLoader', true));
    dispatch(setHasMoreCustomers(true));

    const { customerSearchParams } = getState().customers;

    payload = {
      ids: [],
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortDecending: true,
      lat: "",
      lng: "",
      ip: "",
      resolvePic: true,
      searchText: customerSearchParams.customer ? customerSearchParams.customer.title : null,
      ...payload,
    };

    await apiCustomerSearch.post(payload)
      .then((response) => {

        if (response.status === 200) {

          let customersResponse = response.data.data;
          console.log("customersResponse: ", customersResponse);
          dispatch(setCustomers(customersResponse));

          if (customersResponse < 10) {
            dispatch(setHasMoreCustomers(false));
          }

        }
        else {
          console.error("Error: Fetch Customers: ");
        }

      })
      .catch((error) => {
        console.error("Error: Fetch Customers: ", error);
      })
      .finally(() => {
        dispatch(setCustomersLoader('customersLoader', false));
      });

  };
}

export function fetchMoreCustomers(payload) {
  return async (dispatch, getState) => {

    const prevCustomers = getState().customers.customers;
    const { customerSearchParams } = getState().customers;

    payload = {
      ids: [],
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortDecending: true,
      lat: "",
      lng: "",
      ip: "",
      resolvePic: true,
      searchText: customerSearchParams.customer ? customerSearchParams.customer.title : null,
      ...payload,
    };

    dispatch(setCustomersLoader('moreCustomersLoader', true));

    await apiCustomerSearch.post(payload)
      .then((response) => {

        if (response.status === 200) {

          let moreCustomersResponse = response.data.data;
          console.log("moreCustomersResponse: ", moreCustomersResponse);

          const customerspayload = [...prevCustomers, ...moreCustomersResponse];

          dispatch(setCustomers(customerspayload));

          if (moreCustomersResponse < 10) {
            dispatch(setHasMoreCustomers(false));
          }

        }
        else {
          console.error("Error: Fetch Customers: ");
        }

      })
      .catch((error) => {
        console.error("Error: Fetch Customers: ", error);
      })
      .finally(() => {
        dispatch(setCustomersLoader('moreCustomersLoader', false));
      });

  };
}

export function updateCustomerDetails(payload) {
  return async (dispatch, getState) => {

    console.log("Customer edit payload: ", payload);

    dispatch(setCustomersLoader('editLoader', true));

    const dataToSend = {
      ...payload,
      userId: payload.userId,
      salutation: payload.salutation,
      firstName: payload.firstName,
      lastName: payload.lastName,
      roleId: payload.roleId,
      profilePic: payload.profilePic,
      birthday: payload.birthday,
      gender: payload.gender,
      skype: payload.skype,
      facebook: payload.facebook,
      twitter: payload.twitter,
      linkedIn: payload.linkedIn,
      abstract: payload.abstract,
      customerCompanyId: payload.customerCompanyId,
      id: payload.id,
      email: payload.email,
    };

    await apiCustomerEdit.post(dataToSend)
      .then((response) => {
        if (response.status === 200) {

          const payloadTemp = {
            ...payload,
            profilePic: payload.localProfilePicUrl || null
          };
          console.log('====================================');
          console.log("payloadTemp: ", payloadTemp);
          console.log('====================================');


          const { customers } = getState().customers;

          console.log("========updateCustomerDetails=======customers===", customers);

          const customerTemp = customers.map((customer) => customer.id === payload.id ? payloadTemp : customer);

          console.log("========updateCustomerDetails=======customerTemp===", customerTemp);

          dispatch(setCustomers(customerTemp));

          dispatch({
            type: SET_CUSTOMER_BY_ID,
            payload: payload
          });

          toast.success('Customer edited successfully!!');
        } else {
          toast.error('Error in editing Customer!!');
        }
      })
      .catch((error) => {
        console.error("Customer Edit Error: ", error);
        toast.error('Error in editing Customer!!');
      })
      .finally(() => {
        dispatch(setCustomersLoader('editLoader', false));
      });

  };
}

export function addCustomer(payload, onAdd) {
  return async (dispatch, getState) => {

    dispatch(setCustomersLoader('addLoader', true));

    const { customers } = getState().customers;

    payload = {
      ...payload,
      roleId: RoleId.Customer
    };

    console.log("Add Customer payloadaction: ", payload);

    await apiCustomerCreate.post(payload)
      .then((response) => {
        console.log("Add Customer Response: ", response);

        if (response.status === 200) {

          const id = response.data;
          const newCustomer = {
            ...payload,
            userId: id,
            id: id,
            groupId: '',
          };

          dispatch(setCustomers([newCustomer, ...customers]));

          // provide customer to parent object 
          // after customer is successfully added
          if (onAdd) {
            onAdd(newCustomer);
          }

          toast.success('Customer added successfully!!');

        }
        else {
          console.log("Add Customer Error: ");
          toast.error('Error in adding Customer!!');
        }

      })
      .catch((error) => {

        if (error.response) {
          if (error.response.status === 400 && error.response.data) {
            console.error("Add Customer Error: response ", error.response.data);
            toast.error(error.response.data);
            return;
          }
        }

        console.error("Add Customer Error: ", error);
        toast.error('Error in adding Customer!!');
      })
      .finally(() => {
        dispatch(setCustomersLoader('addLoader', false));
      });

  };
}

export function setCustomerById(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_CUSTOMER_BY_ID,
      payload: payload
    });
  };
}

export function getCustomerById(ids, resolvePic = false, offlineData) {

  return async (dispatch) => {

    dispatch(setCustomersLoader('customerDetailLoader', true));

    const payload = {
      ids: ids,
      orderBy: "newest",
      ResolvePic: resolvePic,
    };

    console.log("===offline payload ", payload);

    await apiCustomerSearch.post(payload)
      .then((response) => {

        if (response.status === 200) {

          const responseData = response.data.data;

          console.log("responseData: ", responseData);

          dispatch({
            type: SET_CUSTOMER_BY_ID,
            payload: responseData[0]
          });

        } else {

          if (offlineData) {
            dispatch({
              type: SET_CUSTOMER_BY_ID,
              payload: offlineData
            });

          } else {
            console.log("Fetch customer by Id Error: ");
            toast.error("There is some issue in fetching details of customer.");
          }

        }

      })
      .catch((error) => {
        console.error("Fetch customer by Id Error: ", error);
        if (offlineData) {

          dispatch({
            type: SET_CUSTOMER_BY_ID,
            payload: offlineData
          });

        } else {
          console.log("Fetch customer by Id Error: ");
          toast.error("There is some issue in fetching details of customer.");
        }

      }).finally(() => {
        dispatch(setCustomersLoader('customerDetailLoader', false));
      });

  };

}

