import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleQuotationSearch } from '../../actions/quotationActions';
import JobChipsFilter from '../../components/JobChipsFilter';
import { useGlobalStore } from '../../providers/GlobalProvider';
import { TOGGLE_SEARCH } from '../../utils/actions';

function QuotationFilterBar({ customer }) {

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const theme = useTheme();
  const styles = useStyles();

  const { dispatchGlobal } = useGlobalStore();
  const openQuotationFilterDialog = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: true
    });
  };

  const dispatch = useDispatch();
  const { quotationSearchParams } = useSelector(state => state.quotations);

  return (
    <div>
      {
        tillTablet &&
        <div className={styles.toolbar}>
          <Icon
            path={mdiMagnify}
            size='2.5rem'
            className={styles.searchIcon}
            onClick={openQuotationFilterDialog}
            color={theme.colors.HOME_SEARCH_SCROLL_ICON_COLOR}
          />
          <div className={styles.jobChipSlider}>
            <JobChipsFilter
              mode="quotation"
              onChipToggle={(categories) => {
                dispatch(handleQuotationSearch({ ...quotationSearchParams, categories: categories }));
              }}
            />
          </div>

        </div>
      }

    </div>
  )
}

export default QuotationFilterBar;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    // background: theme.colors.HOME_SEARCH_SCROLL_BACKGROUND_COLOR,
    display: 'flex',
    // alignItems: 'center',
    // padding: '8px',
  },
  searchIcon: {
    paddingRight: 5,
    borderRight: `2px solid ${theme.palette.text.disabled}`
  },
  jobChipSlider: {
    display: 'flex',
    padding: '5px 0',
    // width: 'calc(100vw - 85px)',
    overflowX: 'auto',
    marginLeft: 5,
    alignItems: 'center',
    '&>*': {
      marginLeft: 8
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))
