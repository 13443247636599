import React from 'react';
import InvoiceMainDetailsCard from '../../components/Invoice/InvoiceMainDetailsCard';
import InvoiceCustomerDetailsCard from '../../components/Invoice/InvoiceCustomerDetailsCard';
import InvoiceStatusButtonBar from '../../components/Invoice/InvoiceStatusButtonBar';
import InvoiceJobTimeDetailsCard from '../../components/Invoice/InvoiceJobTimeDetailsCard';
import InvoiceMediaDetailsCard from '../../components/Invoice/InvoiceMediaDetailsCard';

import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import MoreActionCNO from '../../library/MoreActionsCNO';
import InvoiceListCardPopover from '../../components/Invoice/InvoiceListCardPopover';
import { useSelector } from 'react-redux';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import AddInvoice from '../../components/Invoice/AddInvoice';
import InvoicePaymentSummaryDetailsCard from '../../components/Invoice/InvoicePaymentSummaryDetailsCard';
import FormBox from '../../components/FormBox';
import InvoiceCurrencyDetailsCard from '../../components/Invoice/InvoiceCurrencyDetailsCard';
import InvoiceNotesDetailsCard from '../../components/Invoice/InvoiceNotesDetailsCard';
import { InvoiceStatus } from '../../utils/mappings';
import InvoiceItemDetailsCard from '../../components/InvoiceItem/InvoiceItemDetailsCard';
import PaidStamp from '../../assets/images/paid-stamp-1.png';
import { useTheme } from '@material-ui/core';

function InvoiceDetails({ invoice }) {

  const theme = useTheme()

  const GlobalStyles = useGlobalStyles();

  const isSent = invoice.status === InvoiceStatus.Sent;
  const isSentAccepted = invoice.status === InvoiceStatus.SentAccepted;


  const { allInvoiceLoader, mode } = useSelector(state => state.invoices);
  const { editLoader } = allInvoiceLoader;

  return (
    <div className={GlobalStyles.detailsContainer}>

      {
        editLoader &&
        <FullScreenLoaderCNO />
      }

      <div className={GlobalStyles.statusBar}>
        <InvoiceStatusButtonBar invoice={invoice} />
        {
          isSentAccepted &&
          <img src={PaidStamp} style={{ width: 60, objectFit: 'contain', marginLeft: '1rem' }} alt="" />
        }
        <MoreActionCNO>
          <InvoiceListCardPopover invoice={invoice} />
        </MoreActionCNO>
      </div>

      <InvoiceMainDetailsCard invoice={invoice} />

      <InvoiceMediaDetailsCard invoice={invoice} />

      <InvoiceItemDetailsCard invoice={invoice} />

      <FormBox title="Payment Summary"
        bgColor={theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR}
        titleColor={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR}
      >
        <InvoicePaymentSummaryDetailsCard invoice={invoice} />
      </FormBox>

      <InvoiceCustomerDetailsCard invoice={invoice} />

      <InvoiceJobTimeDetailsCard invoice={invoice} />

      <InvoiceCurrencyDetailsCard invoice={invoice} />

      {/* <InvoiceNotesDetailsCard invoice={invoice} /> */}

    </div>
  )
}

export default InvoiceDetails
