import React, { useState } from "react";
// import { useStyles } from "../../library/DialogCNO/DialogCNOStyles";
import {
  Dialog,
  makeStyles,
  useTheme,
  Button,
  Tooltip,
  Box,
} from "@material-ui/core";
import HeadingBar from "../HeadingBar";
import { mdiArrowLeft,mdiCurrencyUsd } from "@mdi/js";
import * as yup from "yup";
import { Formik } from "formik";
import { mdiFormatText } from "@mdi/js";
import { InputCNO } from "../../library";
import InputLabelCNO from "../../library";
import { DateTimePickerCNO } from "../../library";
import { useDispatch, useSelector } from "react-redux";
import {
  addService,
  toggleAddServiceDialog,
} from "../../actions/serviceActions";
import { useMediaQuery } from "@material-ui/core";
import GooglePlaceInputCNO from "../../library/GooglePlaceInputCNO";
import { servicesImage } from "../../assets/serviceAssetsIcons";
import { categories } from "../../actions/DummyData/serviceUI";
import { useThemeStore } from "../../providers/ThemeProvider";
import { ThemeType } from "../../utils/mappings";
import { AppConstants } from "../../utils/AppConstants";
import { PostMediaPicker } from "../../pages/PostsManagement/components";
import { toast } from "react-hot-toast";
import { getMediaData } from "../../utils/Helpers";
import { v4 } from "uuid";
import CollageView from "../../library/collageViewOnSelectCNO/CollageView";
import { useHistory } from "react-router-dom";
import { service } from "firebase-functions/lib/providers/analytics";

const AddService = () => {
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);

  const [mediaLinks, setMediaLinks] = useState([]);
  const dispatch = useDispatch();
  const { themeState } = useThemeStore();
  const isDarkTheme = themeState.theme === ThemeType.Dark;

  const tillLargeScreen = useMediaQuery("(max-width: 1130px)");
  const tillTablet = useMediaQuery("(max-width: 768px)");
  const width = tillTablet ? "90vw" : tillLargeScreen ? "65vw" : "50vw";
  const maxHeight = "95vh";
  const minHeight = 450;
  const scrollHeight = 205;
  const history = useHistory();
  const theme = useTheme();
  const styles = useStyles({
    width,
    maxHeight,
    minHeight,
    scrollHeight,
  });

  const { isAddServiceDialog } = useSelector((state) => state.services);

  const onCloseAddServiceDialog = (id) => {
    dispatch(toggleAddServiceDialog(false));
  };

  const initialValues = {
    title: "",
    startDate: AppConstants.DEFAULT_START_DATE,
    startTime: AppConstants.DEFAULT_START_TIME,
    endDate: AppConstants.DEFAULT_END_DATE,
    endTime: AppConstants.DEFAULT_END_TIME,
    lat: "",
    lng: "",
    mapAddress: "",
    description: "",
    categories: [],
    mediaLinks: [],
    iconDark: "",
    service: "",
    budget: "",
  };
  const handleAddService = (values) => {
    console.log("hii");
    let payload = {
      title: `Needs ${values.title}`,
      startDate: values.startDate,
      startTime: values.startTime,
      endDate: values.endDate,
      endTime: values.endTime,
      lat: values.lat,
      lng: values.lng,
      mapAddress: values.mapAddress,
      description: values.description,
      categories: values.categories,
      mediaLinks: values.mediaLinks,
      iconDark: values.iconDark,
      service: values.service,
      budget: values.budget,
    };
    dispatch(toggleAddServiceDialog(false));
    dispatch(addService(payload, onCloseAddServiceDialog));
    console.log("bye");
    setMediaLinks([]);
  };

  const validationSchema = yup.object({
    title: yup.string().required(""),
    mapAddress: yup.string().required(""),
    budget:yup.string().required("Budget is required!")
  });

  const handleClose = () => {
    dispatch(toggleAddServiceDialog(false));
  };

  const addServiceCategoryRender = (formik, onMediaSelect, onMediaUnselect) => {
    return (
      <div className={styles.container}>
        <div>
          {mediaLinks?.length > 0 ? (
            <CollageView
              mediaLinks={mediaLinks}
              onMediaUnselect={onMediaUnselect}
            />
          ) : (
            <div className={styles.hover}>
              <div className={styles.enterTitleBox}>
                <div className={styles.ServiceCategoryIcon}>
                  <img
                    src={formik.values.iconDark}
                    className={styles.categoryIcon}
                  />
                </div>

                <Box className={styles.pickerContainer}>
                  <Tooltip title="Image/Video" placement="bottom">
                    <Box>
                      <PostMediaPicker onMediaSelect={onMediaSelect} />
                    </Box>
                  </Tooltip>

                  <Box className={styles.textAlign}>
                    <h3>{formik.values.service}</h3>
                  </Box>
                </Box>
              </div>
            </div>
          )}
        </div>

        <div>
          <InputCNO
            name="title"
            label="Service Title"
            formik={formik}
            placeholder="Enter Service Title"
            icon={mdiFormatText}
            fullWidth
            isRequired
          />

          <div style={{ marginTop: 10 }}>
            <GooglePlaceInputCNO
              address={formik.values.location}
              onSelectAddress={(address) => {
                formik.setFieldValue("mapAddress", address.address);
                formik.setFieldValue("lat", address.lat);
                formik.setFieldValue("lng", address.lng);
              }}
              label={"Service Location"}
              isRequired
            />
          </div>
          <InputCNO
            name="budget"
            label="Add Budget"
            formik={formik}
            placeholder="Enter Your Budget"
            icon={mdiCurrencyUsd}
            fullWidth
            isRequired
          />
          <InputCNO
            name="description"
            label="Service Description"
            formik={formik}
            placeholder="Enter Service Details"
            fullWidth
            multiline
          />

          <DateTimePickerCNO
            dateName="startDate"
            datePlaceholder="Start Date"
            datePickerLabel="Start by Date"
            onDateChange={(date) => {
              formik.setFieldValue("startDate", date);
            }}
            timeName="startTime"
            timePlaceholder="Start Time"
            timePickerLabel="Start by Time"
            onTimeChange={(date) => {
              formik.setFieldValue("startTime", date);
            }}
            formik={formik}
            disablePast={true}
            iconColor="red"
          />

          <DateTimePickerCNO
            dateName="endDate"
            datePlaceholder="Finish Date"
            datePickerLabel="Finish by Date"
            onDateChange={(date) => {
              formik.setFieldValue("endDate", date);
            }}
            timeName="endTime"
            timePlaceholder="Finish Time"
            timePickerLabel="Finish by Time"
            onTimeChange={(date) => {
              formik.setFieldValue("endTime", date);
            }}
            formik={formik}
            minDate={formik.values.startDate}
            iconColor="green"
          />
        </div>
        <div>
          <Button
            variant="contained"
            className={styles.btnAdd}
            onClick={formik.handleSubmit}
            disabled={!formik.values.title || !formik.values.mapAddress || !formik.values.budget}
          >
            {`Done`}
          </Button>
        </div>
      </div>
    );
  };

  const AddServiceDialogRender = (formik) => {
    const handleCategoryClick = (item) => {
      if (item.category !== "Add a new service") {
        formik.setFieldValue("title", item.category);
      }
      formik.setFieldValue("iconDark", item.iconDark);
      formik.setFieldValue("service", item.service);
      formik.setFieldValue("categories", [item.id]);
      formik.setFieldValue("service", item.service);
      setOpenCategoryDialog(true);
    };

    return (
      <div className={styles.ServiceContainer}>
        <div className={styles.ImageSection}>
          <div className={styles.serviceImage}>
            <img src={servicesImage} />
          </div>
          <div className={styles.categoryDescription}>
            <h2>{`Services`}</h2>
            <p>{`Book a Service, Simplify Your Life.`}</p>
          </div>
        </div>

        <div className={styles.categorySection}>
          <h3>{`SELECT A CATEGORY`}</h3>

          <div className={`${styles.categoryCollectionBox} `}>
            {categories.map((category) => (
              <div
                className={styles.category}
                onClick={() => handleCategoryClick(category)}
              >
                <img
                  src={isDarkTheme ? category?.iconDark : category.icon}
                  className={styles.categoryIcon}
                />
                <p style={{ marginTop: "5px" }}>
                  {category.category.length > 20
                    ? `${category.category.slice(0, 20)}..`
                    : category.category}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={isAddServiceDialog}
      className={styles.container_DialogCNO}
      classes={{ paper: styles.paper }}
    >
      <HeadingBar
        title={"Looking For"}
        onClose={
          openCategoryDialog ? () => setOpenCategoryDialog(false) : handleClose
        }
        icon={openCategoryDialog && mdiArrowLeft}
        backTop={openCategoryDialog && true}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleAddService}
      >
        {(formik) => {
          const onMediaSelect = async (mediasToUpload) => {
            const mediasPromise = mediasToUpload.map(async (media) => {
              const { mediaData, fileType } = await getMediaData(media);
              return {
                id: v4(),
                link: mediaData,
                fileType: fileType,
              };
            });

            const toastId = toast.loading("Uploading...", {
              style: {
                minWidth: "200px",
                color: "black",
              },
            });
            const resolvedMedias = await Promise.all(mediasPromise);
            toast.dismiss(toastId);

            mediaLinks?.length + resolvedMedias?.length >
            AppConstants.MAX_NUMBER_OF_FILES
              ? toast.error(
                  `Maximum number of allowed files are ${AppConstants.MAX_NUMBER_OF_FILES}.`
                )
              : (setMediaLinks((prevMediaLinks) => [
                  ...prevMediaLinks,
                  ...resolvedMedias,
                ]),
                formik.setFieldValue("mediaLinks", [
                  ...formik.values.mediaLinks,
                  ...resolvedMedias,
                ]));
          };

          const onMediaUnselect = (mediaId) => {
            setMediaLinks((prevMedia) => {
              const updatedMediaLinks = prevMedia.filter(
                (media) => media.id !== mediaId
              );
              formik.setFieldValue("mediaLinks", updatedMediaLinks);
              return updatedMediaLinks;
            });
          };
          return (
            <div>
              {openCategoryDialog
                ? addServiceCategoryRender(
                    formik,
                    onMediaSelect,
                    onMediaUnselect
                  )
                : AddServiceDialogRender(formik)}
            </div>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddService;

const useStyles = makeStyles((theme) => ({
  container_DialogCNO: {
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    padding: theme.spacing(2),
    background: ({ bgColor }) =>
      bgColor ? bgColor : theme.colors.DIALOG_BACKGROUND_COLOR,
    minWidth: 340,
    maxWidth: "90vw",
    minHeight: ({ minHeight }) => minHeight,
    width: ({ width }) => width,
    maxHeight: ({ maxHeight }) => maxHeight,
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
  },

  ServiceContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  ImageSection: {
    position: "relative",
    paddingBottom: "30px",
  },
  serviceImage: {
    width: ({ width }) => width - theme.spacing(2),
    "& img": {
      width: "100%",
      maxHeight: ({ gifHeightInLifeEvent }) => gifHeightInLifeEvent,
    },
  },
  categoryDescription: {
    textAlign: "center",
  },
  categorySection: {
    textAlign: "center",
  },
  categoryCollectionBox: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px",
    gap: "20px",
  },
  categoryDetailContainer: {
    margin: "20px",
  },
  category: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "130px",
    height: "130px",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover": {
      background: theme.colors.ITEM_HOVER,
    },
  },
  categoryIcon: {
    // color:theme.colors.CATEGORY_ICON_COLOR,
    color: "white",
    height: "50px",
    width: "50px",
  },
  enterTitleBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    height: "200px",
  },
  hover: {
    "&:hover": {
      background: theme.colors.ITEM_HOVER,
    },
  },
  mediaPicker: {
    display: "flex",
  },
  textAlign: {
    marginTop: "8px",
  },
  pickerContainer: {
    display: "flex",
    gap: "6px",
  },
  ServiceCategoryIcon: {
    height: "80px",
    width: "80px",
    borderRadius: "50%",
    background: "rgb(63,138,2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  btnAdd: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    width: "100%",
  },
}));
