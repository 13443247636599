import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme
} from '@material-ui/core';
import {
  mdiAccountPlus, mdiPlusBoxOutline, mdiQuora
} from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleAddQuotationDialog } from '../../actions/quotationActions';
import RightsManager from '../../utils/RightsManager';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';

function QuotationListHeaderPopover({ closeMoreActions }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const dispatch = useDispatch();

  const openAddCustomerDialog = () => {
    dispatch(toggleAddQuotationDialog(true));

    closeMoreActions();
  };

  return (
    <div className={GlobalStyles.container_MorePopover}>
      <List className={GlobalStyles.list}>
        {
          isUserAdmin &&
          <ListItem className={GlobalStyles.listItem} onClick={openAddCustomerDialog}>
            <ListItemText primary='Add Quotation' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiQuora} size='1.2rem' color={theme.colors.HEADER_ADD_TODO_ICON_COLOR} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

      </List>
    </div>
  )
}

export default QuotationListHeaderPopover