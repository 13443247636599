import { useFormik } from 'formik';
import React from 'react';
import { DialogCNO, InputCNO } from '../../../library';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../../../components/FormActionButtons';
import { editEvent } from '../../../actions/eventActions';

const EditEventTitle = ({ open, onClose, event }) => {

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    title: yup.string().required("Title is required")
  });

  const handleEditEventTitle = (values) => {

    const payload = {
      ...event,
      ...values,
    }

    dispatch(editEvent(payload, { onSuccess: onClose }));

  }

  const formik = useFormik({
    initialValues: {
      title: event.title
    },
    validationSchema,
    onSubmit: handleEditEventTitle
  });

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Event Title"
    >
      <form onSubmit={formik.handleSubmit}>
        <InputCNO
          name="title"
          label="Event Title"
          formik={formik}
          placeholder="Enter Event Title"
          icon={mdiFormatText}
          fullWidth
          isRequired
        />

        <FormActionButtons formik={formik} rightText="Save" leftClick={onClose} />

      </form>
    </DialogCNO>
  )
}

export default EditEventTitle