const LocalStorageConstants = {
  localChatMessages: (chatId) => `local_chat_${chatId}`,
  isLastOfflineMessage: (chatId) => `is_last_offline_message_${chatId}`,
  getItem: function (key, defaultValue = null, companySuffix) {

    let authUser = getAuthUserFromLS()
    key = companySuffix ? `${key}_${authUser.id}` : key;

    let value = localStorage.getItem(key);
    value = value ? JSON.parse(value) : defaultValue;
    return value;
  },
  setItem: function (key, values, companySuffix) {

    const authUser = this.getItem("authUser");
    key = companySuffix ? `${key}_${authUser.id}` : key;

    localStorage.setItem(key, JSON.stringify(values));

    window.dispatchEvent(new Event("localStorage"));
  },
  removeItem: function (key, companySuffix) {
    const authUser = this.getItem("authUser");
    key = companySuffix ? `${key}_${authUser.id}` : key;
    localStorage.removeItem(key);
  }
};

export default LocalStorageConstants;

export function getAuthUserFromLS() {
  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : null;
  return authUser;
}
