import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { mdiHumanMale, mdiShare, mdiShareOff } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import RightsManager from '../../utils/RightsManager';
import CustomerDetailsDialog from '../CustomerDetailsDialog/CustomerDetailsDialog';
import ShareTaskWithCustomer from '../ShareTaskWithCustomer/ShareTaskWithCustomer';


function CustomerDetailsCard({ job, setJob, id, customerId, customerText }) {

  const isUserAdmin = RightsManager.isAdmin();

  const [isEditable, setIsEditable] = useState(false);

  const [isShareWithCustomer, setIsShareWithCustomer] = useState(false);

  const styles = useStyles();
  return (<>
    {
      isUserAdmin && isEditable &&
      <CustomerDetailsDialog
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        customerId={customerId}
      />
    }
    {
      isShareWithCustomer &&
      <ShareTaskWithCustomer
        open={isShareWithCustomer}
        onClose={() => setIsShareWithCustomer(false)}
        todo={job}
        onShareTaskWithCustomer={(todo) => setJob(todo)}
      />
    }
    <div className={styles.container} onClick={(e) => setIsEditable(true)}>
      <div className={styles.cardTitle}>
        Customer
      </div>
      <div className={styles.cardContent}>
        <div className={styles.row}>
          <Typography className={styles.customerName}>{customerText}</Typography>
          {
            isUserAdmin
              ? <Icon
                path={job.visibility ? mdiShareOff : mdiShare}
                size='2rem'
                className={styles.icon}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsShareWithCustomer(true);
                }}
              />
              : <Icon
                path={mdiHumanMale}
                size='2rem'
                className={styles.icon}
              />
          }
        </div>
      </div>
    </div>
  </>)
}

export default CustomerDetailsCard

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`
  },
  cardTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
  },
  cardContent: {
    padding: theme.spacing(1),
    '&>*': {
      marginTop: theme.spacing(1)
    }
  },
  customerName: {
    color: theme.colors.JOB_DETAIL_CUSTOMER_TEXT_COLOR,
    fontWeight: 'bold'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    color: theme.colors.JOB_DETAIL_CUSTOMER_ICON_COLOR
  }

}))