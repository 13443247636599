import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Fade from 'react-reveal/Fade';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import Heading from '../../../common/components/Heading';
import Button from '../../../common/components/Button';
import Card from '../../../common/components/Card';
import FeatureBlock from '../../../common/components/FeatureBlock';
import Container from '../../../common/components/UI/Container';
import TrackingWebDark from '../../../common/assets/image/LandingPage/checkndout/TrackingWebDark.png';
import TrackingWebLight from '../../../common/assets/image/LandingPage/checkndout/TrackingWebLight.png';
import CalendarWebDark from '../../../common/assets/image/LandingPage/checkndout/CalendarWebDark.png';
import CalendarWebLight from '../../../common/assets/image/LandingPage/checkndout/CalendarWebLight.png';
import Image from '../../../common/components/Image';
import { useTheme } from '@material-ui/core';
import { ThemeType } from '../../../utils/mappings';
import darkColors from '../../../common/theme/LandingPage/darkThemeColors';
import colors from '../../../common/theme/LandingPage/colors';
import ComingSoonModal from '../../ComingSoonModal';

import IPadCalendarDark from '../../../common/assets/image/LandingPage/checkndout/mockups/IPadCalendarDark.png';
import IPadCalendarLight from '../../../common/assets/image/LandingPage/checkndout/mockups/IPadCalendarLight.png';
import LaptopTrackingDark from '../../../common/assets/image/LandingPage/checkndout/mockups/LaptopTrackingDark.png';
import LaptopTrackingLight from '../../../common/assets/image/LandingPage/checkndout/mockups/LaptopTrackingLight.png';
import MobileAudioCall from '../../../common/assets/image/LandingPage/checkndout/mockups/MobileAudioCall.png';


const ControlSection = ({
  sectionWrapper,
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
  textAreaRow,
  imageAreaRow,
  imageWrapper,
  imageWrapperOne,
  imageWrapperTwo,
  imageWrapperThree,
  sectionSubTitle,
  btnStyle,
}) => {

  const isDarkTheme = useTheme().palette.type === ThemeType.Dark;

  const [isComingSoon, setIsComingSoon] = useState(false);

  return (
    <Box {...sectionWrapper} id="control" style={{ display: 'flex', paddingRight: 10, paddingLeft: 10 }}>
      {/* <Container fullWidth noGutter className="control-sec-container" style={{ display: 'flex' }}> */}
      <div fullWidth noGutter className="control-sec-container" style={{ width: '50%', marginRight: 10 }}>

        <div className='mockup-wrapper'>

          <Fade left>
            <Image style={{ position: 'absolute', width: '80%' }} src={isDarkTheme ? LaptopTrackingDark : LaptopTrackingLight} alt="Track User" />
          </Fade>

          <Fade delay={400}>
            <Image style={{ position: 'absolute' }} src={isDarkTheme ? IPadCalendarDark : IPadCalendarLight} alt="Calendar" />
          </Fade>

          <Fade right>
            <Image style={{
              position: 'absolute',
              right: '-15%',
              top: '5px',
              width: '80%',
            }} src={MobileAudioCall} alt="Audio Call" />
          </Fade>

        </div>

        {/* <Box {...row} {...imageAreaRow}>
          <Box {...col} {...imageArea}>
            <Card {...imageWrapper} {...imageWrapperOne}>
              <Fade left>
                <Image style={{ maxWidth: '135%' }} src={isDarkTheme ? LaptopTrackingDark : LaptopTrackingLight} alt="Track User" />
              </Fade>
            </Card>
            <Card {...imageWrapper} {...imageWrapperTwo}>
              <Fade bottom>
                <Image style={{ maxWidth: '135%' }} src={isDarkTheme ? IPadCalendarDark : IPadCalendarLight} alt="Calendar" />
              </Fade>
            </Card>
            <Card {...imageWrapper} {...imageWrapperThree}>
              <Fade top>
                <Image style={{ maxWidth: '135%' }} src={MobileAudioCall} alt="Audio Call" />
              </Fade>
            </Card>
          </Box>
        </Box> */}
      </div>
      <div style={{ width: '50%', flex: 1 }}>
        <div {...row} {...textAreaRow}>
          <div {...col} {...textArea}>
            {/* <Text content="EASY DEPLOYMENT" {...sectionSubTitle} /> */}
            <Heading
              content={`One app...all-devices`}
              {...title}
              color={isDarkTheme ? darkColors.headingColor : colors.headingColor}
            />
            <FeatureBlock
              description={
                <Text
                  content="Both mobile & web app has been crafted to support all devices seamlessly."
                  {...description}
                  color={isDarkTheme ? darkColors.textColor : colors.textColor}
                />
              }
              button={
                <a href='/help'>
                  <Button title="Demo" {...button} {...btnStyle} />
                </a>
              }
            />
          </div>
        </div>

        {
          isComingSoon &&
          <ComingSoonModal
            open={isComingSoon}
            onClose={() => setIsComingSoon(false)}
          />
        }

      </div>
    </Box>
  );
};

ControlSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  btnStyle: PropTypes.object,
};

ControlSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['40px', '80px'],
    pb: ['40px', '80px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  textAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '50%', '50%', '50%'],
  },
  imageArea: {
    width: ['0px', '0px', '53%', '50%', '50%'],
    flexBox: true,
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  imageWrapperOne: {
    pointerEvents: 'none',
  },
  imageWrapperTwo: {
    alignSelf: 'flex-end',
    mb: ['0px', '0px', '-55px', '-60px', '-73px'],
    ml: ['0px', '0px', '-215px', '-350px', '-400px'],
    mr: 100,
    pointerEvents: 'none',
  },
  imageWrapperThree: {
    alignSelf: 'flex-end',
    mb: '0px',
    ml: ['0px', '0px', '-280px', '-329px', '-400px'],
    pointerEvents: 'none',
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    fontSize: '14px',
    letterSpacing: '0.11em',
    fontWeight: '700',
    color: '#ff8000',
    textTransform: 'uppercase',
    mb: '10px',
    textAlign: ['center', 'left'],
  },
  title: {
    fontSize: ['24px', '26px', '30px', '36px', '48px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '460px', '460px'],
    textAlign: ['center', 'center', 'left'],
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    textAlign: ['center', 'center', 'left'],
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default ControlSection;
