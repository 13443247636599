import { Dialog, makeStyles, useMediaQuery } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import FormBox from "../FormBox/FormBox";
import JobDetailsForm from "../JobDetailsForm/JobDetailsForm";
import CustomerDetailsForm from "../CustomerDetailsForm/CustomerDetailsForm";
import InternalDetailsForm from "../InternalDetailsForm/InternalDetailsForm";
import HeadingBar from "../HeadingBar/HeadingBar";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { RateType } from "../../utils";
import JobTargetDetailsForm from "../JobTargetDetailsForm/JobTargetDetailsForm";
import { JobTargetType, TrackProximityRadius } from "../../utils/mappings";
import { addJob, toggleAddJobDialog } from "../../actions/jobActions";
import { useHistory } from "react-router-dom";
import { useDebouncedFormik, useFormikErrors } from "../../hooks";
import { validateStartDateEndDate } from "../../utils/Helpers";
import { setAddModalsCalendarDates } from "../../actions/calendarActions";
import { AppConstants } from "../../utils/AppConstants";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";

function AddJob({isPostCreateDialogue,backToCreatePostDialog}) {
  const { addTodoLoader, isAddJobDialog } = useSelector((state) => state.jobs);
  const { addModalsCalendarDates } = useSelector((state) => state.calendar);
  const { startDate, startTime, endDate, endTime } = addModalsCalendarDates;
  const { isAddPostListDialog}=useSelector((state)=>state.postListTypes)
  const tillTablet = useMediaQuery("(max-width: 767px)");

  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : null;

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();

  const [isMoreDetailsExpanded, setIsMoreDetailsExpanded] = useState(false);

  const handleClose = () => {
    if(isPostCreateDialogue) backToCreatePostDialog();
    else{
      localStorage.removeItem("addJob");

      dispatch(toggleAddJobDialog(false));
    }
    
  };

  const history = useHistory();
  const onAddJob = (job) => {
    formik.resetForm();

    handleClose();

    history.push(`/job/${job.id}`);
  };

  const handleAddJob = async (values) => {
    let latitude = 0;
    let longitude = 0;

    await geocodeByAddress(values.location)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        latitude = lat;
        longitude = lng;
      });

    let assignedUsers = authUser
      ? [...values.assignTo, authUser]
      : values.assignTo;

    const jobPayload = {
      categories: [values.jobCategory],
      customerId: values.customer.id,
      description: values.jobDescription,
      endDate: values.endDate,
      endTime: values.endTime,
      lat: latitude.toString(),
      lng: longitude.toString(),
      mapAddress: values.location,
      mediaLinks: [],
      rate: values.jobPrice ? values.jobPrice : 0,
      rateType: values.rateType,
      startDate: values.startDate,
      startTime: values.startTime,
      taskAssignments: assignedUsers.map((item) => ({
        staffId: item.id,
        startDate: values.startDate,
        startTime: values.startTime,
        endDate: values.endDate,
        endTime: values.endTime,
      })),
      title: values.jobTitle,
      targetType: values.targetType,
      target: values.target ? values.target : 0,
      proximity: values.proximity ? values.proximity : TrackProximityRadius,
    };

    console.log("Add Job Payload: ", jobPayload);

    dispatch(addJob(jobPayload, onAddJob));
  };

  const validationSchema = yup.object({
    jobCategory: yup.string().required("Category is required."),
    jobTitle: yup.string("").required("Title is required"),
    jobDescription: yup.string(""),
    startDate: yup.date("invalid date").required("Start date is required."),
    startTime: yup.date("invalid date").required("Start time is required."),
    endDate: yup
      .date("invalid date")
      .required("End date is required.")
      .test(
        "endDateTimeValidation",
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({
            startDate,
            startTime,
            endDate,
            endTime,
          });

          return isValid;
        }
      ),
    endTime: yup
      .date("invalid date")
      .required("End time is required.")
      .test(
        "endDateTimeValidation",
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({
            startDate,
            startTime,
            endDate,
            endTime,
          });

          return isValid;
        }
      ),
    customer: yup.object().required("Contact person is required.").nullable(),
    location: yup.string().required("Location is required."),
    assignTo: yup.array(),
    jobPrice: yup.number(),
    rateType: yup.number(),
    targetType: yup.number(),
    target: yup.string(),
    proximity: yup.number("Not a valid number."),
  });

  const now = new Date();

  const formik = useFormik({
    initialValues: {
      jobCategory: "",
      jobTitle: "",
      jobDescription: "",
      startDate: startDate || AppConstants.DEFAULT_START_DATE,
      startTime: startTime || AppConstants.DEFAULT_START_TIME,
      endDate: endDate || AppConstants.DEFAULT_END_TIME,
      endTime: endTime || AppConstants.DEFAULT_END_TIME,
      customer: "",
      location: "",
      assignTo: [],
      jobPrice: 0,
      rateType: RateType.Hourly,
      targetType: JobTargetType.None,
      target: 0,
      proximity: TrackProximityRadius,
    },
    validationSchema,
    onSubmit: (values) => handleAddJob(values),
  });
  console.log("====formik.values=========", formik.values);
  console.log("====formik.errors=========", formik.errors);
  console.log("====formik.touched=========", formik.touched);

  const styles = useStyles({ tillTablet });

  useFormikErrors(formik);

  useDebouncedFormik(formik, "addJob");

  useEffect(() => {
    return () => {
      dispatch(
        setAddModalsCalendarDates({
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        })
      );
    };
  }, []);

  return (
    <Dialog
      open={isAddJobDialog}
      classes={{ paper: styles.paper }}
      className={styles.container}
    >
      {addTodoLoader && <FullScreenLoaderCNO />}

      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <HeadingBar title="Add Job" onClose={handleClose} />
        <FormBox title={"Customer Details"}>
          <CustomerDetailsForm formik={formik} />
        </FormBox>
        <FormBox title={"Job Details"}>
          <JobDetailsForm formik={formik} />
        </FormBox>

        <div
          className={GlobalStyles.link}
          onClick={() => setIsMoreDetailsExpanded(!isMoreDetailsExpanded)}
        >
          {isMoreDetailsExpanded ? "Less Details" : "More Details"}
        </div>

        {isMoreDetailsExpanded && (
          <div className={GlobalStyles.moreDetailsContainer}>
            <FormBox title={"Internal Details"}>
              <InternalDetailsForm formik={formik} />
            </FormBox>
            <FormBox title={"Job Target Details"}>
              <JobTargetDetailsForm formik={formik} />
            </FormBox>
          </div>
        )}

        <FormActionButtons
          rightText="Add Job"
          leftClick={handleClose}
          formik={formik}
          isPostCreateDialogue={isPostCreateDialogue}
        />
      </form>
    </Dialog>
  );
}

export default AddJob;

const useStyles = makeStyles((theme) => ({
  container: {},
  paper: {
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    borderRadius: 10,
    width: "70vw",
    maxWidth: "90vw",
    margin: ({ tillTablet }) => (tillTablet ? 8 : 32),
  },
  form: {
    padding: "20px",
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    overflowX: "hidden",
    "&>*": {
      marginBottom: theme.spacing(3),
    },
  },
}));
