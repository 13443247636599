import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import ChipCNO from '../../library/ChipCNO';
import { mdiCheck, mdiClose, mdiFormatText } from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuotation } from '../../actions/quotationActions';
import { TaxType, TaxTypeReverse } from '../../utils/mappings';
import { getTaxRateBasedOnTaxType, getTaxValueBasedOnTaxType } from '../../utils/InvoiceHelpers';

function EditQuotationTaxDialog({
  open,
  onClose,
  quotation,
}) {

  const dispatch = useDispatch();
  const { allQuotationLoader: { editLoader } } = useSelector(state => state.quotations);

  const formik = useFormik({
    initialValues: {
      includeTax: quotation.includeTax,
      tax: quotation.tax,
      taxRate: quotation.taxRate,
    },
    validationSchema: yup.object({
      includeTax: yup.boolean().oneOf([true, false], 'Tax type is required.'),
      tax: yup.string().test('emptyString', 'Please enter valid value', value => !(value === "" || isNaN(value))),
      taxRate: yup.string().test('emptyString', 'Please enter valid value', value => !(value === "" || isNaN(value)))
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("Edit Quotation Item: title: ", values);

    const payload = {
      ...quotation,
      includeTax: values.includeTax,
      tax: values.tax,
      taxRate: values.taxRate
    };

    dispatch(updateQuotation(payload, true));

    onClose();
  };

  const handleTaxTypeChange = () => {

    const includeTax = !formik.values.includeTax;
    formik.setFieldValue('includeTax', includeTax);

    console.log("=======handleTaxTypeChange==========", {
      includeTax: includeTax,
      amountAfterDiscount: quotation.amountAfterDiscount,
      taxRate: formik.values.taxRate
    });

    const { tax } = getTaxValueBasedOnTaxType(includeTax, quotation.amountAfterDiscount, formik.values.taxRate);

    formik.setFieldValue('tax', tax);

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Quotation Tax"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <div style={{ display: 'flex', marginBottom: 8 }}>
          <ChipCNO
            active={!formik.values.includeTax}
            icon={mdiClose}
            name={TaxTypeReverse[TaxType.Exclusive]}
            onClick={handleTaxTypeChange}
            style={{ marginRight: 10 }}
          />

          <ChipCNO
            active={formik.values.includeTax}
            icon={mdiCheck}
            name={TaxTypeReverse[TaxType.Inclusive]}
            onClick={handleTaxTypeChange}
          />

        </div>

        <InputCNO
          name="tax"
          label="Tax"
          placeholder="Enter tax"
          icon={mdiFormatText}
          formik={formik}
          onChange={(e) => {

            const value = e.target.value;

            const taxValue = !isNaN(value) ? value : formik.values.tax;

            const taxRate = getTaxRateBasedOnTaxType(formik.values.includeTax, quotation.amountAfterDiscount, taxValue);

            formik.setFieldValue('tax', value === "" ? "" : taxValue);
            formik.setFieldValue('taxRate', !isNaN(taxRate) ? taxRate : "");

          }}
        />

        <InputCNO
          name="taxRate"
          label="Tax %"
          placeholder="Enter tax %"
          icon={mdiFormatText}
          formik={formik}
          onChange={(e) => {

            const value = e.target.value;

            const taxRateValue = !isNaN(value) ? value : formik.values.taxRate;

            const { tax } = getTaxValueBasedOnTaxType(formik.values.includeTax, quotation.amountAfterDiscount, taxRateValue);

            formik.setFieldValue('tax', !isNaN(tax) ? tax : "");
            formik.setFieldValue('taxRate', value === "" ? "" : taxRateValue);

          }}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditQuotationTaxDialog
