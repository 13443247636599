import Companylogo from "../../assets/nameLogo.png";
import userLogo from "../../assets/images/1077114.png";
import React from "react";
import QRCode from "react-qr-code";

const VisitorPassContent = ({ appointment }) => {
  const { visitor, customerText, mobile, email, title } = appointment;
  const value = visitor + " " + mobile + " " + email;
  return (
    <div>
      {/* Visitor card container */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
        }}
      >
        <div
          style={{
            margin: "20px",
            height: "auto",
            width: "450px",
            display: "grid",
            gridTemplateColumns: "10% 90%",
            padding: "15px",
            border: "3px dotted black",
            borderRadius: "10px",
          }}
        >
          {/* Visitor name plate */}
          <div style={{ backgroundColor: "#305b80", wordWrap: "break-word" }}>
            <div
              style={{
                writingMode: "vertical-rl",
                fontSize: "1.5rem",
                color: "whitesmoke",
                fontWeight: "bold",
                paddingTop: "60px",
                letterSpacing: "5px",
              }}
            >
              VISITOR`
            </div>
          </div>

          {/* Visitor details */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
              padding: "15px",
              border: "1px solid black",
            }}
          >
            {/* Profile */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                columnGap: "40px",
              }}
            >
              {/* Profile image */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100px",
                  maxWidth: "150px",
                }}
              >
                <img
                  src={userLogo}
                  alt="Profile Image"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>

              {/* Name logo */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100px",
                  maxWidth: "150px",
                }}
              >
                <img
                  src={Companylogo}
                  alt="Name Logo"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>

            {/* Profile details */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                columnGap: "40px",
                color:'black',
                width:'100%'
              }}
            >
              <div>
                <p>{visitor}</p>
                <p>{mobile}</p>
                <p>
                  <b>To Meet - </b>
                  {customerText}
                </p>
                <p>Tower A4</p>
                <p>
                  <b>Purpose - </b>
                  {title}
                </p>
              </div>

              {/* QR code */}
              <div style={{ height: "100px", width: "100px" }}>
                <div
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: "100px",
                    width: "100%",
                  }}
                >
                  {/* Render QR code here */}
                  <div
                    style={{
                      height: "auto",
                      margin: "0 auto",
                      maxWidth: 100,
                      width: "100%",
                    }}
                  >
                    <QRCode
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={value}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorPassContent;
