import { Drawer, makeStyles, useMediaQuery } from "@material-ui/core";
import React from "react";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { useContentTopSpace } from "../../hooks";
import { useGlobalStore } from "../../providers/GlobalProvider";
import { TOGGLE_RIGHT_SIDEBAR } from "../../utils/actions";

function RightSidebar({ children }) {
  const { globalState, dispatchGlobal } = useGlobalStore();
  const { isRightSidebar } = globalState;

  const tillTablet = useMediaQuery("(max-width: 767px)");
  const contentTopSpace = useContentTopSpace();

  const route = useRouteMatch();
  const isHomePath = route.path.includes("/home");

  const handleClose = () => {
    dispatchGlobal({
      type: TOGGLE_RIGHT_SIDEBAR,
      payload: false,
    });
  };

  const styles = useStyles({ tillTablet, isHomePath, contentTopSpace });

  return (
    <Drawer
      className={styles.container}
      open={isRightSidebar}
      variant={tillTablet ? "temporary" : "persistent"}
      anchor="right"
      classes={{ paper: styles.paper }}
      onClose={handleClose}
    >
      {children}
    </Drawer>
  );
}

export default RightSidebar;

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    position: "fixed",
    top: ({ contentTopSpace }) => contentTopSpace,
    right: 0,
    // boxShadow: `-2px 4px 8px -2px ${theme.palette.text.disabled}`,
    "&>*:first-child": {
      minHeight: "100vh",
    },
  },
  paper: {
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    position: ({ tillTablet }) => !tillTablet && "relative",
    width: 240,
    color: theme.palette.text.primary,
  },
  drawerOpen: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    //   [theme.breakpoints.up('sm')]: {
    //     width: theme.spacing(7) + 1,
    //   },
  },
}));
