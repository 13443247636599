import { Box, Button, Collapse, Divider } from "@material-ui/core";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  DefaultCustomThemeColors,
  getCustomThemeColors,
  getCustomThemeLSKey,
} from "../../providers/CustomThemeColorConstants";
import LocalStorageConstants from "../../utils/LocalStorageConstants";
import { ThemeType } from "../../utils/mappings";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import ColorPickerRow from "./ColorPickerRow";

const CustomThemeColorPickerSection = ({
  isVisible,
  themeType,
  updateTheme,
}) => {
  const themeColors = getCustomThemeColors();

  const GlobalStyles = useGlobalStyles();

  const [colors, setColors] = useState(themeColors);

  const handleCancelTheme = () => {
    updateTheme(themeType);
  };

  const handleResetTheme = () => {
    setColors(DefaultCustomThemeColors);
    LocalStorageConstants.setItem(
      getCustomThemeLSKey(),
      DefaultCustomThemeColors
    );
    updateTheme(ThemeType.Custom);
    toast.success("Theme updated successfully.");
  };

  const handleUpdateTheme = () => {
    LocalStorageConstants.setItem(getCustomThemeLSKey(), colors);
    updateTheme(ThemeType.Custom);
    toast.success("Theme updated successfully.");
  };

  const themeGroups = customThemePickerColors.reduce((groups, item) => {
    if (!Object.keys(groups).includes(item.groupId)) {
      groups = {
        ...groups,
        [item.groupId]: [],
      };
    }
    groups = {
      ...groups,
      [item.groupId]: [...groups[item.groupId], item],
    };

    return groups;
  }, {});

  return (
    <Collapse in={isVisible}>
      <Box width="100%">
        <Divider style={{ marginTop: 10 }} />

        <Box
          marginTop={2}
          display="flex"
          justifyContent="flex-end"
          gridGap={10}
        >
          <Button
            variant="contained"
            className={GlobalStyles.greyButton}
            onClick={handleCancelTheme}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            className={GlobalStyles.orangeButton}
            onClick={handleResetTheme}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            className={GlobalStyles.greenButton}
            onClick={handleUpdateTheme}
          >
            Apply
          </Button>
        </Box>

        {Object.keys(themeGroups).map((group) => {
          return (
            <>
              <Box marginBottom={2} display="flex" flexWrap="wrap">
                {themeGroups[group].map((item) => (
                  <ColorPickerRow
                    key={item.id}
                    item={item}
                    colors={colors}
                    setColors={setColors}
                  />
                ))}
              </Box>
              <Divider />
            </>
          );
        })}
      </Box>
    </Collapse>
  );
};

export default CustomThemeColorPickerSection;

const customThemePickerColors = [
  // navbar
  {
    id: "navbarBg",
    name: "Navbar Background",
    themeConstants: ["HEADER_BACKGROUND_COLOR"],
    groupId: "navbar",
  },
  {
    id: "headerText",
    name: "Header Text",
    themeConstants: [
      "HEADER_TITLE_COLOR",
      "HEADER_ADD_TODO_ICON_COLOR",
      "HEADER_TRACK_MAP_ICON_COLOR",
      "HEADER_CHAT_ICON_COLOR",
      "HEADER_NOTIFICATION_ICON_COLOR",
      "HEADER_USER_ICON_COLOR",
      "HEADER_CALL_ICON_COLOR",
    ],
    groupId: "navbar",
  },
  {
    id: "headerMoreActionsIcon",
    name: "Header More Actions Icon",
    themeConstants: ["HEADER_MORE_ACTIONS_ICON_COLOR"],
    groupId: "navbar",
  },

  // sidebar
  {
    id: "sidebarBg",
    name: "Sidebar Background",
    themeConstants: ["SIDEBAR_BACKGROUND_COLOR"],
    groupId: "sidebar",
  },

  {
    id: "drawerItemText",
    name: "Drawer Item Text",
    themeConstants: ["DRAWER_ITEM_TEXT_COLOR"],
    groupId: "sidebar",
  },
  {
    id: "drawerItemIcon",
    name: "Drawer Item Icon",
    themeConstants: ["DRAWER_ITEM_ICON_COLOR"],
    groupId: "sidebar",
  },

  // modal
  {
    id: "modalBg",
    name: "Modal Background",
    themeConstants: ["DIALOG_BACKGROUND_COLOR", "MODAL_BACKGROUND_COLOR"],
    groupId: "modal",
  },
  {
    id: "modalTitle",
    name: "Modal Title",
    themeConstants: ["DIALOG_TITLE_COLOR", "MODAL_HEADER_TITLE_COLOR"],
    groupId: "modal",
  },
  {
    id: "formSectionBg",
    name: "Form Section Background",
    themeConstants: ["FORM_SECTION_BACKGROUND_COLOR"],
    groupId: "modal",
  },
  {
    id: "formSectionTitle",
    name: "Form Section Title",
    themeConstants: ["FORM_SECTION_TITLE_COLOR"],
    groupId: "modal",
  },

  // Filter Bar
  {
    id: "filterBarBg",
    name: "Filter Bar Background",
    themeConstants: ["FILTER_BAR_BACKGROUND_COLOR"],
    groupId: "filterBar",
  },

  // list
  {
    id: "listCardBg",
    name: "List Card Background",
    themeConstants: ["LIST_CARD_BACKGROUND_COLOR"],
    groupId: "listCard",
  },
  {
    id: "listCardTitle",
    name: "List Card Title",
    themeConstants: ["LIST_CARD_TITLE_COLOR", "JOB_DETAIL_TITLE_COLOR"],
    groupId: "listCard",
  },
  {
    id: "listCardPrimary",
    name: "List Card Primary",
    themeConstants: [
      "JOB_DETAIL_DESCRIPTION_COLOR",
      "LIST_CARD_PARA_COLOR",
      "PROFILE_TEXT_INFO_COLOR",
    ],
    groupId: "listCard",
  },
  {
    id: "listCardSecondary",
    name: "List Card Secondary",
    themeConstants: ["LIST_CARD_USERNAMES_COLOR"],
    groupId: "listCard",
  },
  {
    id: "listCardTertiary",
    name: "List Card Tertiary",
    themeConstants: ["LIST_CARD_DATE_COLOR"],
    groupId: "listCard",
  },
  {
    id: "listCardParaBg",
    name: "List Card Description Background",
    themeConstants: ["LIST_CARD_PARA_BACKGROUND_COLOR"],
    groupId: "listCard",
  },
  {
    id: "listCardBorder",
    name: "List Card Border",
    themeConstants: ["LIST_CARD_BORDER_COLOR"],
    groupId: "listCard",
  },

  // chip
  {
    id: "chipBg",
    name: "Chip Background",
    themeConstants: ["CHIP_BUTTON_BACKGROUND_COLOR"],
    groupId: "chip",
  },
  {
    id: "chipBgActive",
    name: "Active Chip Background",
    themeConstants: ["CHIP_BUTTON_SELECTED_BACKGROUND_COLOR"],
    groupId: "chip",
  },
  {
    id: "chipText",
    name: "Chip Text",
    themeConstants: ["CHIP_BUTTON_TEXT_COLOR"],
    groupId: "chip",
  },
  {
    id: "chipActiveText",
    name: "Active Chip Text",
    themeConstants: ["CHIP_BUTTON_SELECTED_TEXT_COLOR"],
    groupId: "chip",
  },
  {
    id: "chipBorder",
    name: "Chip Border",
    themeConstants: ["CHIP_BUTTON_SHADOW_COLOR"],
    groupId: "chip",
  },

  // Buttons
  {
    id: "primaryButtonBg",
    name: "Primary Button Background",
    themeConstants: ["FORM_CONFIRM_BUTTON_BACKGROUND_COLOR"],
    groupId: "button",
  },
  {
    id: "primaryButtonText",
    name: "Primary Button Text",
    themeConstants: ["FORM_CONFIRM_BUTTON_TEXT_COLOR"],
    groupId: "button",
  },
  {
    id: "secondaryButtonBg",
    name: "Secondary Button Background",
    themeConstants: [
      "FORM_ORANGE_BUTTON_BACKGROUND_COLOR",
      "ADD_SUBTASK_BUTTON_BACKGROUND_COLOR",
    ],
    groupId: "button",
  },
  {
    id: "secondaryButtonText",
    name: "Secondary Button Text",
    themeConstants: ["FORM_SECONDARY_BUTTON_TEXT_COLOR"],
    groupId: "button",
  },
  {
    id: "buttonCancelBg",
    name: "Cancel Button Background",
    themeConstants: ["FORM_CANCEL_BUTTON_BACKGROUND_COLOR"],
    groupId: "button",
  },
  {
    id: "buttonCancelText",
    name: "Cancel Button Text",
    themeConstants: ["FORM_CANCEL_BUTTON_TEXT_COLOR"],
    groupId: "button",
  },
  {
    id: "buttonBorder",
    name: "Button Border",
    themeConstants: [
      "FORM_CANCEL_BUTTON_BORDER_COLOR",
      "FORM_CONFIRM_BUTTON_BORDER_COLOR",
    ],
    groupId: "button",
  },

  // Category Icon
  {
    id: "categoryIconBg",
    name: "Category Icon Background",
    themeConstants: ["JOB_TYPE_ICON_BACKGROUND_COLOR"],
    groupId: "categoryIcon",
  },
  {
    id: "categoryIcon",
    name: "Category Icon",
    themeConstants: [
      "JOB_TYPE_ICON_COLOR",
      "JOB_TYPE_BORDER_COLOR",
      "JOB_TYPE_TEXT_BORDER_COLOR",
    ],
    groupId: "categoryIcon",
  },
  {
    id: "categoryTextBg",
    name: "Category Text Background",
    themeConstants: ["JOB_TYPE_TEXT_BACKGROUND_COLOR"],
    groupId: "categoryIcon",
  },
  {
    id: "categoryText",
    name: "Category Text",
    themeConstants: ["JOB_TYPE_TEXT_COLOR"],
    groupId: "categoryIcon",
  },

  // Status bar
  {
    id: "statusBarBg",
    name: "Status Bar Background",
    themeConstants: ["JOB_ACTION_CONTAINER_BACKGROUND_COLOR"],
    groupId: "statusBar",
  },

  // More Actions
  {
    id: "moreActionsPopoverBg",
    name: "More Actions Popover Background",
    themeConstants: ["MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR"],
    groupId: "moreActions",
  },
  {
    id: "moreActionsSeparator",
    name: "More Actions Separator",
    themeConstants: ["MORE_ACTIONS_OPTIONS_WRAPPER_BACKGROUND_COLOR"],
    groupId: "moreActions",
  },
  {
    id: "moreActionsOptionBg",
    name: "More Actions Option Background",
    themeConstants: ["MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR"],
    groupId: "moreActions",
  },
  {
    id: "moreActionsOptionText",
    name: "More Actions Option Text",
    themeConstants: ["MORE_ACTIONS_OPTION_TEXT_COLOR"],
    groupId: "moreActions",
  },
  {
    id: "moreActionsIcon",
    name: "More Actions Icon",
    themeConstants: [
      "MORE_ACTIONS_OPTION_ICON_COLOR",
      "MORE_ACTIONS_DELETE_OPTION_ICON_COLOR",
      "MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR",
      "MORE_ACTIONS_SHARE_JOB_OPTION_ICON_COLOR",
      "MORE_ACTIONS_WORK_SUMMARY_OPTION_ICON_COLOR",
      "MORE_ACTIONS_GENERATE_INVOICE_OPTION_ICON_COLOR",
      "MORE_ACTIONS_ENABLE_CUSTOMER_CHAT_ICON_COLOR",
      "MORE_ACTIONS_ENABLE_TEAM_CHAT_COLOR",
    ],
    groupId: "moreActions",
  },

  // Details
  {
    id: "detailSectionBg",
    name: "Detail Section Background",
    themeConstants: [
      "JOB_DETAIL_CARD_BACKGROUND_COLOR",
      "PROFILE_CARD_BACKGROUND_COLOR",
      "PROFILE_HEADER_BACKGROUND_COLOR",
    ],
    groupId: "detailsSection",
  },
  {
    id: "detailSectionTitle",
    name: "Detail Section Title",
    themeConstants: ["JOB_DETAIL_CARD_TITLE_COLOR", "PROFILE_CARD_TITLE_COLOR"],
    groupId: "detailsSection",
  },
  {
    id: "detailSectionText",
    name: "Detail Section Text",
    themeConstants: [
      "JOB_DETAIL_LOCATION_COLOR",
      "JOB_DETAIL_CUSTOMER_TEXT_COLOR",
      "JOB_DETAIL_CUSTOMER_ICON_COLOR",
      "JOB_DETAIL_ASSIGN_USER_COLOR",
      "JOB_DETAIL_ASSIGN_USER_ICON_COLOR",
      "JOB_DETAIL_CURRENCY_ICON_COLOR",
      "JOB_DETAIL_RATE_COLOR",
      "JOB_DETAIL_SECTION_TEXT_COLOR",
    ],
    groupId: "detailsSection",
  },

  // Calendar
  {
    id: "calendarBg",
    name: "Calendar Background",
    themeConstants: ["CALENDAR_BACKGROUND_COLOR"],
    groupId: "calendar",
  },
  {
    id: "calendarTimeSlot",
    name: "Calendar Time Slot",
    themeConstants: [
      "CALENDAR_EVENT_ACTIVE_BACKGROUND_COLOR",
      "CALENDAR_TIME_CELL_WRAPPER_BACKGROUND_COLOR",
    ],
    groupId: "calendar",
  },
  {
    id: "calendarUpcomingTimeSlot",
    name: "Calendar Upcoming Time Slot",
    themeConstants: ["CALENDAR_EVENT_INACTIVE_BACKGROUND_COLOR"],
    groupId: "calendar",
  },

  // Dashboard
  {
    id: "dashboardCardBg",
    name: "Dashboard Card Background",
    themeConstants: ["STATS_CARD_BACKGROUND_COLOR"],
    groupId: "dashboard",
  },
  {
    id: "dashboardCardText",
    name: "Dashboard Text",
    themeConstants: ["DASHBOARD_TEXT_COLOR"],
    groupId: "dashboard",
  },
  {
    id: "dashboardCardBorder",
    name: "Dashboard Card Border",
    themeConstants: ["STATS_CARD_BORDER_COLOR"],
    groupId: "dashboard",
  },

  // Accordion
  {
    id: "accordionBg",
    name: "Accordion Background",
    themeConstants: ["ACCORDION_BACKGROUND_COLOR"],
    groupId: "accordion",
  },
  {
    id: "accordionTitle",
    name: "Accordion Title",
    themeConstants: ["ACCORDION_TITLE_COLOR"],
    groupId: "accordion",
  },

  // Chats
  {
    id: "chatProfileListBg",
    name: "Chat Profile List Background",
    themeConstants: [
      "CHAT_PROFILE_LIST_BACKGROUND_COLOR",
      "CHAT_PROFILE_LIST_CONTAINER_BACKGROUND_COLOR",
    ],
    groupId: "chat",
  },
  {
    id: "chatProfileListText",
    name: "Chat Profile List Text",
    themeConstants: [
      "CHAT_PROFILE_LIST_ITEM_USER_COLOR",
      "CHAT_NEW_GROUP_LIST_ITEM_TITLE_COLOR",
    ],
    groupId: "chat",
  },
  {
    id: "chatProfileListSnippetText",
    name: "Chat Profile List Snippet Text",
    themeConstants: [
      "CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_TEXT_COLOR",
      "CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_ICON_COLOR",
      "CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_DATE_COLOR",
    ],
    groupId: "chat",
  },
  {
    id: "chatActiveProfileListBg",
    name: "Active Chat Profile List Background",
    themeConstants: ["CHAT_PROFILE_LIST_HOVER_COLOR"],
    groupId: "chat",
  },
  {
    id: "chatMessagesBg",
    name: "Chat Messages Background",
    themeConstants: ["CHAT_MESSAGE_BACKGROUND_COLOR"],
    groupId: "chat",
  },
  {
    id: "chatSenderTextBg",
    name: "Chat Sender Text Background",
    themeConstants: ["CHAT_MESSAGE_RIGHT_BUBBLE_WRAPPER_BACKGROUND_COLOR"],
    groupId: "chat",
  },
  {
    id: "chatSenderText",
    name: "Chat Sender Text",
    themeConstants: [
      "CHAT_MESSAGE_RIGHT_BUBBLE_TEXT_COLOR",
      "CHAT_RIGHT_MESSAGE_TEXT_COLOR",
    ],
    groupId: "chat",
  },
  {
    id: "chatReceiverTextBg",
    name: "Chat Receiver Text Background",
    themeConstants: ["CHAT_MESSAGE_LEFT_BUBBLE_WRAPPER_BACKGROUND_COLOR"],
    groupId: "chat",
  },
  {
    id: "chatReceiverText",
    name: "Chat Receiver Text",
    themeConstants: [
      "CHAT_MESSAGE_LEFT_BUBBLE_TEXT_COLOR",
      "CHAT_LEFT_MESSAGE_TEXT_COLOR",
    ],
    groupId: "chat",
  },
];
