import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCompanyById } from "../../actions/customerCompanyActions";
import CompanyDetails from "../../components/CompanyDetails";
import ScreenView from "../../components/ScreenView/ScreenView";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import RightsManager from "../../utils/RightsManager";
import LocalStorageConstants from "../../utils/LocalStorageConstants";

function MyCompanyPage() {
  const styles = useStyles();

  const isUserAdmin = RightsManager.isAdmin();
  const isUserMember = RightsManager.isMember();
  const isUserCustomer = RightsManager.isCustomer();

  const authUserCompany = LocalStorageConstants.getItem("authUserCompany");

  const dispatch = useDispatch();
  const { companyById, allCompaniesLoader } = useSelector(
    (state) => state.companies
  );
  const { companyDetailLoader, editLoader } = allCompaniesLoader;

  const { id } = useParams();

  useEffect(() => {
    const companyId = [id];

    dispatch(getCompanyById(companyId));
  }, [id]);

  const profile = {
    ...companyById,
    // defaultPaymentMethod: authUserCompany.defaultPaymentMethod || {},
    // paymentGatewayMeta: authUserCompany.paymentGatewayMeta,
    // defaultPaymentMethodId: authUserCompany.defaultPaymentMethodId,
  };

  return (
    <div className={styles.container_MyCompanyPage}>
      {companyDetailLoader && <FullScreenLoaderCNO />}
      <ScreenView
        content={companyById && <CompanyDetails profile={profile} />}
        headerTitle="My Company"
      />
    </div>
  );
}

export default MyCompanyPage;

const useStyles = makeStyles((theme) => ({
  container_CompanyListPage: {
    background: theme.colors.APP_BACKGROUND_COLOR,
  },
}));
