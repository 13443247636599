import { useTheme } from '@material-ui/core';
import { mdiArrowRight, mdiCheck, mdiPlay, mdiProgressClock, mdiStop } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import CardStatusCNO from '../../library/CardStatusCNO/CardStatusCNO';
import ConfirmationDialogCNO from '../../library/ConfirmationDialogCNO';
import { TaskStatus } from '../../utils';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import TaskStartNotAllowedDialog from '../TaskStartNotAllowedDialog/TaskStartNotAllowedDialog';
import { useDispatch } from 'react-redux';
import { updateSubtaskStatus } from '../../actions/subTaskActions';

function SubTaskStatusBar({ subTask, styles }) {

  const theme = useTheme();

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();

  const [isAllowed, setIsAllowed] = useState(false);

  const [isStartModal, setIsStartModal] = useState(false);
  const [isStopModal, setIsStopModal] = useState(false);

  return (
    <div className={GlobalStyles.cardStatusBarContainer} style={styles}>

      <CardStatusCNO
        title="Start"
        icon={mdiPlay}
        onClick={() => {
          if (subTask.status !== TaskStatus.InProgress) {
            setIsStartModal(true);
          }
        }}
      />

      <Icon path={mdiArrowRight} size='1.2rem' />

      <CardStatusCNO
        title="In Progress"
        icon={mdiProgressClock}
        isActive={subTask.status === TaskStatus.InProgress}
        activeBorderColor={theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_ICON_COLOR}
      />

      <Icon path={mdiArrowRight} size='1.2rem' />

      <CardStatusCNO
        title="Stop"
        icon={mdiStop}
        isActive={(subTask.status === TaskStatus.Done || subTask.status === TaskStatus.Started)}
        activeBorderColor={theme.colors.JOB_ACTION_BUTTON_STOP_ACTIVE_ICON_COLOR}
        hasTopRightIcon={true}
        topRightIcon={mdiCheck}
        isTopRightActive={(subTask.status === TaskStatus.Done)}
        topRightActiveBgColor={subTask.status === TaskStatus.Done && "#c7ff99"}
        topRightActiveBorderColor={subTask.status === TaskStatus.Done && "#72ff00"}
        topRightActiveColor={"#3F8A02"}
        onClick={() => {
          if (subTask.status === TaskStatus.InProgress) {
            setIsStopModal(true);
          }
        }}
      />

      {
        isStopModal &&
        <ConfirmationDialogCNO
          open={isStopModal}
          onClose={() => setIsStopModal(false)}
          dialogTitle="Stop Sub Task"
          messages={["Is the sub task finished?"]}
          yesClick={() => {
            const payload = {
              subTaskId: subTask.id,
              status: TaskStatus.Done
            };

            dispatch(updateSubtaskStatus(payload));
          }}
          noClick={() => {
            const payload = {
              subTaskId: subTask.id,
              status: TaskStatus.Started
            };

            dispatch(updateSubtaskStatus(payload));
          }}
        />
      }

      {
        isStartModal &&
        <ConfirmationDialogCNO
          open={isStartModal}
          onClose={() => setIsStartModal(false)}
          dialogTitle="Start Sub Task"
          messages={["Are you sure you want to start the sub task?"]}
          yesClick={() => {
            const payload = {
              subTaskId: subTask.id,
              status: TaskStatus.InProgress
            };

            dispatch(updateSubtaskStatus(payload));
          }}
        />
      }


    </div>
  )
}

export default SubTaskStatusBar
