import React, { useEffect, useState } from "react";
import GridItemCard from "./GridItemCard";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    borderRadius: 10,
    padding: "16px 8px",
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    color: theme.palette.getContrastText(theme.palette.background.main),
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    marginBottom: "20px",
    gap: "20px",
    flexDirection: "column",
  },
  rows: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexBox: {
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
  },
}));

const GridViewListing = ({ posts, mode }) => {
  const styles = useStyles();
  const [items, setItems] = useState([]);

  const organizePosts = (posts) => {
    if (!posts) return [];

    const groupedPosts = {};

    posts.forEach((post) => {
      const date = new Date(post.createdAt || post.startDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');

      if (!groupedPosts[year]) {
        groupedPosts[year] = {};
      }

      if (!groupedPosts[year][month]) {
        groupedPosts[year][month] = [];
      }

      groupedPosts[year][month].push(post);
    });

    const sortedYears = Object.keys(groupedPosts).sort((a, b) => b - a);

    return sortedYears.map((year) => {
      const months = Object.keys(groupedPosts[year]).sort((a, b) => b - a).map((month) => ({
        month,
        items: groupedPosts[year][month]
      }));
      return {
        year,
        months
      };
    });
  };

  useEffect(() => {
    const groupedAndSortedPosts = organizePosts(posts);
    setItems(groupedAndSortedPosts);
  }, [posts]);

  return (
    <>
      {items.map((yearData) => (
        <div key={yearData.year}>
          <h2>{yearData.year}</h2>
          {yearData.months.map((monthData) => (
            <div className={styles.container} key={monthData.month}>
              <h3>{`${yearData.year}, ${monthData.month}`}</h3>
              <div className={styles.flexBox}>
                {monthData.items.map((post, postIndex) => (
                  <div
                    key={`${yearData.year}-${monthData.month}-${postIndex}`}
                    className={styles.rows}
                  >
                    <GridItemCard post={post} mode={mode} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default GridViewListing;
