import {
  SET_ADD_INVOICE_DIALOG_DATA,
  SET_HAS_MORE_INVOICES,
  SET_INVOICES,
  SET_INVOICES_LOADER,
  SET_INVOICE_BY_ID,
  SET_INVOICE_SEARCH_PARAMS,
  SET_RESOLVED_INVOICE_MEDIAS,
  TOGGLE_ADD_INVOICE_DIALOG,
  TOGGLE_INVOICE_FILTER_DIALOG
} from "../actions/invoiceActions";
import { AddInvoiceDialogMode } from "../utils";

const initialState = {
  invoices: [],
  invoiceById: null,

  resolvedInvoiceMedias: [],

  isAddInvoiceDialog: false,
  mode: AddInvoiceDialogMode.Add,
  addInvoiceData: null,
  addInvoiceDataItems: [],

  isInvoiceFilterDialog: false,
  convertToJobData: null,
  allInvoiceLoader: {
    invoicesLoader: false,
    moreInvoiceLoader: false,
    editLoader: false,
    addLoader: false,
    deleteLoader: false,
    invoiceDetailLoader: false,
    isPdfGenerating: false,
    addInvoiceDialogLoader: false
  },
  hasMoreInvoices: true,
  invoiceSearchParams: {
    customer: null,
    categories: [],
    invoice: null,
    invoiceNumber: "",
    billDate: null,
    dueInDays: null,
    statuses: [],
    paymentByCustomerStatuses: [],
    isToday: false
  },
};

export const invoicesReducer = (state = initialState, { type, payload }) => {

  switch (type) {
    case SET_INVOICES:
      state = {
        ...state,
        invoices: payload
      };
      return state;

    case TOGGLE_ADD_INVOICE_DIALOG:
      state = {
        ...state,
        isAddInvoiceDialog: payload.status,
        mode: payload.mode
      };
      return state;

    case SET_INVOICE_BY_ID:
      state = {
        ...state,
        invoiceById: payload
      };
      return state;

    case SET_INVOICES_LOADER:
      state = {
        ...state,
        allInvoiceLoader: {
          ...state.allInvoiceLoader,
          [payload.loaderType]: payload.loaderState
        }
      };
      return state;

    case SET_HAS_MORE_INVOICES:
      state = {
        ...state,
        hasMoreInvoices: payload
      };
      return state;

    case TOGGLE_INVOICE_FILTER_DIALOG:
      state = {
        ...state,
        isInvoiceFilterDialog: payload
      };
      return state;

    case SET_INVOICE_SEARCH_PARAMS:
      state = {
        ...state,
        invoiceSearchParams: payload
      };
      return state;

    case SET_RESOLVED_INVOICE_MEDIAS:
      state = {
        ...state,
        resolvedInvoiceMedias: payload
      };
      return state;

    case SET_ADD_INVOICE_DIALOG_DATA:
      state = {
        ...state,
        ...payload
      };
      return state;

    default:
      return state;
  }

};