import { fetchAppointmentById } from "../../actions/appointmentActions";
import { getInvoiceById } from "../../actions/invoiceActions";
import { getTaskById } from "../../actions/jobActions";
import { getQuotationById } from "../../actions/quotationActions";
import { getMediaData, isArrayEmpty, isObjectEmpty } from "../Helpers";
import LocalStorageConstants from "../LocalStorageConstants";
import { getAWSAppointmentUploadSignedUrls, getResolvedAppointmentMedias, saveUploadedAppointmentMediasToServer } from "./Appointment";
import { getAWSInvoiceUploadSignedUrls, getResolvedInvoiceMedias, saveUploadedInvoiceMediasToServer } from "./Invoice";
import { getAWSJobUploadSignedUrls, getResolvedJobMedias, saveUploadedJobMediasToServer } from "./Job";
import { getAWSQuotationUploadSignedUrls, getResolvedQuotationMedias, saveUploadedQuotationMediasToServer } from "./Quotation";

export const ItemType = {
  Job: 1,
  Invoice: 2,
  Quotation: 3,
  Appointment: 4
}

export const ItemTypeReverse = {
  [ItemType.Job]: "Job",
  [ItemType.Invoice]: "Invoice",
  [ItemType.Quotation]: "Quotation",
  [ItemType.Appointment]: "Appointment"
}

export const getTriggerActionName = (from, to) => {

  if (from && to && from === to) return `Duplicate ${ItemTypeReverse[from]}`;

  const actionName = `Convert to ${ItemTypeReverse[to]} from ${ItemTypeReverse[from]}`;

  return actionName;

}

export const getMediaViewLink = (to, itemId) => `/${ItemTypeReverse[to].toLowerCase()}/${itemId}`

export const getMediaUploadItemsKey = (prefix = "") => {
  const authUser = LocalStorageConstants.getItem('authUser', null);

  if (!authUser) return "";

  return `${prefix ? prefix + "_" : ""}medias_pending_${authUser.id}`
}

export const getMediasPendingFromLS = (prefix = "") => {

  const mediaPendingKey = getMediaUploadItemsKey(prefix);

  const mediasPending = LocalStorageConstants.getItem(mediaPendingKey, []);

  return mediasPending;

}

export const setMediasPendingToLS = (data, prefix = "") => {

  const mediaPendingKey = getMediaUploadItemsKey(prefix);

  const oldMedias = getMediasPendingFromLS(prefix);

  const updatedMedias = [...data, ...oldMedias];

  LocalStorageConstants.setItem(mediaPendingKey, updatedMedias);

}

export const isMediasPendingUploadExistInLS = (itemId) => {

  const mediaPendingItems = getMediasPendingFromLS();

  const isMediasExistToUpload = mediaPendingItems
    .filter(mediaObj => itemId ? mediaObj.itemId === itemId : true)
    .some(({ medias }) => !isArrayEmpty(medias));

  return isMediasExistToUpload;

}

export const removeMediasPendingFromLS = (itemId, removeItemForcefully = false, clearAll = false) => {

  const mediaPendingKey = getMediaUploadItemsKey();

  if (clearAll) {
    localStorage.removeItem(mediaPendingKey);
    LocalStorageConstants.setItem("mediaPendingLoader", false);
    window.dispatchEvent(new Event("media-pending-loader-storage"));
  }

  // get all pending medias to upload from LS
  const oldMediasPending = getMediasPendingFromLS();

  // get all successfully uploaded medias from LS
  const uploadedMediasIds = getMediasPendingFromLS("success");

  // filter out medias from mediasPending which are successfully uploaded
  // mediasPending medias might be partially uploaded
  const currentPendingMediasObj = oldMediasPending.find(media => media.itemId === itemId);
  let updatedCurrentMedias = currentPendingMediasObj && currentPendingMediasObj.medias
    ? currentPendingMediasObj.medias.filter(media => !uploadedMediasIds.includes(media.id))
    : [];

  const updatedOldMediasPending = oldMediasPending
    .map(media => media.itemId === itemId ? { ...media, medias: removeItemForcefully ? [] : updatedCurrentMedias } : media)

  // filter out the mediasPending if mediasPending medias is Empty []
  let updatedMedias = updatedOldMediasPending
    .map(item => isArrayEmpty(item.medias) ? null : item)
    .filter(Boolean);

  // update the new mediasPending LS 
  // if new array is not empty []
  // else remove it from LS
  if (updatedMedias.length) {
    LocalStorageConstants.setItem(mediaPendingKey, updatedMedias);
  } else {
    localStorage.removeItem(mediaPendingKey);
    LocalStorageConstants.setItem("mediaPendingLoader", false);
    window.dispatchEvent(new Event("media-pending-loader-storage"));
  }

  localStorage.removeItem(getMediaUploadItemsKey("success"))

  window.dispatchEvent(new Event("set-medias-pending-upload-storage"));

}

export const getMediasUploadResultFromLS = () => {

  const mediaUploadResultKey = getMediaUploadItemsKey('result');

  const mediasUploadResultLS = LocalStorageConstants.getItem(mediaUploadResultKey);

  return mediasUploadResultLS;

};

export const setMediasUploadResultToLS = (id, data) => {

  const mediaUploadResultKey = getMediaUploadItemsKey('result');

  const mediasUploadResultLS = getMediasUploadResultFromLS();

  const updatedMediasUploadResultLS = {
    ...mediasUploadResultLS,
    [id]: data
  }

  if (isObjectEmpty(updatedMediasUploadResultLS)) localStorage.removeItem(mediaUploadResultKey);
  else LocalStorageConstants.setItem(mediaUploadResultKey, updatedMediasUploadResultLS);

  window.dispatchEvent(new Event("media-upload-result"));

};

export const removeMediasUploadResultFromLS = (itemId) => {

  if (itemId) {
    setMediasUploadResultToLS(itemId, undefined);
  } else {
    const mediaUploadResultKey = getMediaUploadItemsKey('result');
    localStorage.removeItem(mediaUploadResultKey);
  }

}

export const getMediasUploadingLoaders = () => {

  const key = getMediaUploadItemsKey("loaders");

  const values = LocalStorageConstants.getItem(key, null);

  return values;

}

export const setMediasUploadingLoaders = (id, state) => {

  const key = getMediaUploadItemsKey("loaders");

  const prevLoaders = getMediasUploadingLoaders();

  const newLoaders = {
    ...prevLoaders,
    [id]: state
  }

  if (isObjectEmpty(newLoaders)) localStorage.removeItem(key);
  else LocalStorageConstants.setItem(key, newLoaders);

  window.dispatchEvent(new Event("medias-uploading-loaders"));
  window.dispatchEvent(new Event("media-pending-loader-storage"));

}

export const isAnyMediasUploadingLoadersTrue = () => {

  const loaders = getMediasUploadingLoaders();

  console.log("messageribbon - loaders: ", loaders);

  return loaders ? Object.values(loaders).includes(true) : false;

}

export const handleMediaViewRedirect = (to) => {

  switch (to) {
    case ItemType.Job:
      return getTaskById;

    case ItemType.Invoice:
      return getInvoiceById;

    case ItemType.Quotation:
      return getQuotationById;

    case ItemType.Appointment:
      return fetchAppointmentById;

  }

}

export const triggerUploadMediasLsBg = (itemId = null) => {

  const mediasPending = getMediasPendingFromLS();

  if (isArrayEmpty(mediasPending)) return;

  LocalStorageConstants.setItem("mediaPendingLoader", true);
  window.dispatchEvent(new Event("media-pending-loader-storage"));

  // iterate over each mediasPending object and upload
  mediasPending
    .filter(mediasPendingObj => itemId ? mediasPendingObj.itemId === itemId : true)
    .forEach((mediasPendingObj) => {
      if (isArrayEmpty(mediasPendingObj.medias)) return;
      uploadMedias(mediasPendingObj);
    });

}

export const getResolvedMedias = async (mediasPending, itemType, itemId) => {

  let resolvedMedias = [];

  switch (itemType) {
    case ItemType.Job:
      resolvedMedias = await getResolvedJobMedias(mediasPending, itemId);
      break;

    case ItemType.Invoice:
      resolvedMedias = await getResolvedInvoiceMedias(mediasPending, itemId);
      break;

    case ItemType.Quotation:
      resolvedMedias = await getResolvedQuotationMedias(mediasPending, itemId);
      break;

    case ItemType.Appointment:
      resolvedMedias = await getResolvedAppointmentMedias(mediasPending, itemId);
      break;

  }

  return resolvedMedias;

}

export const getAWSUploadSignedUrls = async (ids = [], itemType) => {

  let awsUploadSignedUrls = [];

  switch (itemType) {
    case ItemType.Job:
      awsUploadSignedUrls = await getAWSJobUploadSignedUrls(ids);
      break;

    case ItemType.Invoice:
      awsUploadSignedUrls = await getAWSInvoiceUploadSignedUrls(ids);
      break;

    case ItemType.Quotation:
      awsUploadSignedUrls = await getAWSQuotationUploadSignedUrls(ids);
      break;

    case ItemType.Appointment:
      awsUploadSignedUrls = await getAWSAppointmentUploadSignedUrls(ids);
      break;

  }

  return awsUploadSignedUrls;

}

export const uploadMediasToAWS = async (medias, awsUploadSignedUrls) => {

  let uploadedMedias = [];

  if (isArrayEmpty(medias) || isArrayEmpty(awsUploadSignedUrls)) return uploadedMedias;

  const uploadMediasPromise = medias.map(async (media, index) => {

    // TODO: make isConvert smart by detecting data type of link
    // if link is of type url then isConvert = true
    // if link is of type id(string) then isConvert = false
    const { mediaBlob, thumbnailBlob } = await getMediaData(media, true);

    try {

      fetch(awsUploadSignedUrls[index].m, { method: 'PUT', body: mediaBlob, headers: { 'Content-Type': media.type } });
      fetch(awsUploadSignedUrls[index].t, { method: 'PUT', body: thumbnailBlob, headers: { 'Content-Type': 'image/png' } });
      fetch(awsUploadSignedUrls[index].b, { method: 'PUT', body: mediaBlob, headers: { 'Content-Type': media.type } });

    } catch (error) {
      media = null;
    }

    return media;

  });

  uploadedMedias = await Promise.all(uploadMediasPromise);

  uploadedMedias = uploadedMedias.filter(Boolean);

  return uploadedMedias;
}

export const saveUploadedMediasToServer = async (uploadedMedias, ids, itemId, itemType) => {

  switch (itemType) {
    case ItemType.Job:
      await saveUploadedJobMediasToServer(uploadedMedias, ids, itemId);
      break;

    case ItemType.Invoice:
      await saveUploadedInvoiceMediasToServer(uploadedMedias, ids, itemId);
      break;


    case ItemType.Quotation:
      await saveUploadedQuotationMediasToServer(uploadedMedias, ids, itemId);
      break;


    case ItemType.Appointment:
      await saveUploadedAppointmentMediasToServer(uploadedMedias, ids, itemId);
      break;

  }

}

export const uploadMedias = async (mediasPending) => {

  const {
    from,
    to,
    medias,
    itemId,
  } = mediasPending;

  setMediasUploadingLoaders(itemId, true);

  const { uploadedMediasAWSSuccess, ids, newIds } = await recursiveUpload(medias, from, to, itemId)


  try {
    await saveUploadedMediasToServer(uploadedMediasAWSSuccess, newIds, itemId, to);

    // push success upload media ids to LS
    setMediasPendingToLS(ids, "success");

    const mediaUploadResult = {
      from,
      to,
      itemId,
      success: uploadedMediasAWSSuccess.length,
      total: medias.length
    };

    setMediasUploadResultToLS(itemId, mediaUploadResult);
  } catch (error) {

    // TODO: if upload failed here then
    // on retry trigger only server call 
    // prevent full upload cycle from media resolve -> aws upload -> server save
    const mediaUploadResult = {
      from,
      to,
      itemId,
      success: 0,
      total: medias.length
    };
    setMediasUploadResultToLS(itemId, mediaUploadResult);

  } finally {

    removeMediasPendingFromLS(itemId);

    LocalStorageConstants.setItem("mediaPendingLoader", false);
    window.dispatchEvent(new Event("media-pending-loader-storage"));

    LocalStorageConstants.setItem("medias-upload-result-dialog", true);
    window.dispatchEvent(new Event("medias-upload-result-dialog"));

    setMediasUploadingLoaders(itemId, undefined);

  }

}

const recursiveUpload = async (medias, from, to, itemId) => {

  if (isArrayEmpty(medias)) return { ids: [], newIds: [], uploadedMediasAWSSuccess: [] };

  // define size for batch upload
  const pageSize = 10;

  // take out first batch of medias to upload
  const paginatedMedias = medias.slice(0, pageSize);

  // store the traveresed media ids
  // to prevent them going in next upload batch
  const traversedIds = paginatedMedias.map(media => media.id);

  // get resolved medias (thumbnail, big screen, mobile) of source
  const resolvedMedias = await getResolvedMedias(paginatedMedias, from, itemId);

  // generate UUIDs for new medias
  const ids = resolvedMedias.map(media => media.newId);

  // get pre-signed aws upload urls
  const awsUploadSignedUrls = await getAWSUploadSignedUrls(ids, to);

  // upload the resolved medias to AWS
  const uploadedMediasAWSSuccess = await uploadMediasToAWS(resolvedMedias, awsUploadSignedUrls);

  // ids for successfully uploaded medias on AWS
  const uploadedMediasAWSSuccessIdsOld = uploadedMediasAWSSuccess.map(media => media.id);
  const uploadedMediasAWSSuccessIdsNew = uploadedMediasAWSSuccess.map(media => media.newId);

  // get the remainig medias by filtering out the traversedIds
  // to prepare them for next batch
  const remainingMedias = medias.filter(media => !traversedIds.includes(media.id));

  // recursively upload medias based on batch size
  // until media array is empty
  const recursiveResult = await recursiveUpload(remainingMedias, from, to, itemId);

  const combinedSuccessUploadData = {
    ids: [...uploadedMediasAWSSuccessIdsOld, ...recursiveResult.ids],
    newIds: [...uploadedMediasAWSSuccessIdsNew, ...recursiveResult.newIds],
    uploadedMediasAWSSuccess: [...uploadedMediasAWSSuccess, ...recursiveResult.uploadedMediasAWSSuccess],
  };

  return combinedSuccessUploadData;

}