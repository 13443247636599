import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addEvent, toggleAddEventDialog } from "../../../actions/eventActions";
import { DialogCNO } from "../../../library";
import {
  AddEventAttendees,
  AddEventCover,
  AddEventDetails,
  AddEventFBSteps,
  AddEventTimeline,
  AddEventTypeFB,
} from "../components";
import * as yup from "yup";
import {
  EventAttendeeRole,
  EventResponseActionStatus,
  EventStatus,
  EventType,
  EventVisibility,
} from "../../../utils/mappings";
import { validateStartDateEndDate } from "../../../utils/Helpers";
import { AppConstants } from "../../../utils/AppConstants";
import { useFormik } from "formik";
import LocalStorageConstants from "../../../utils/LocalStorageConstants";
import { Button, Divider, makeStyles, useTheme } from "@material-ui/core";
import { useGlobalStyles } from "../../../utils/Styles/GlobalStyles";
import { mdiArrowLeft, mdiArrowRight, mdiContentSave } from "@mdi/js";
import Icon from "@mdi/react";
import clsx from "clsx";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toggleAddPostListDialog } from "../../../actions/postListActions";
const AddEventFBDialog = () => {
  const dispatch = useDispatch();
  const { isAddEventDialog } = useSelector((state) => state.events);
  const history=useHistory();
  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const authUser = LocalStorageConstants.getItem("authUser");
  const host = {
    ...authUser,
    title: `${authUser.firstName} ${authUser.lastName}`,
    role: EventAttendeeRole.ids.Host,
    responseStatus: EventResponseActionStatus.ids.Going,
  };

  const validationSchema = yup.object({
    eventType: yup
      .number()
      .oneOf(Object.values(EventType.ids))
      .required("Event type is required."),
    title: yup.string().required("Event title is required."),
    description: yup.string(),
    categories: yup
      .array()
      .typeError("Invalid category")
      .required("Event category is required."),
    joinLink: yup
      .string()
      .url("Invalid URL")
      .nullable()
      .when("eventType", {
        is: EventType.ids.Online,
        then: yup
          .string()
          .url("Invalid URL")
          .nullable()
          .required("Join Link is required."),
      }),
    mapAddress: yup
      .string()
      .nullable()
      .when("eventType", {
        is: EventType.ids.InPerson,
        then: yup.string().nullable().required("Address is required."),
      }),
    lat: yup.string().nullable(),
    lng: yup.string().nullable(),

    startDate: yup.string().required("Event start date is required!"),
    endDate: yup
      .string()
      .required("Event end date is required!")
      .test(
        "endDateTimeValidation",
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({
            startDate,
            startTime,
            endDate,
            endTime,
          });

          return isValid;
        }
      ),
    startTime: yup.string().required("Event start time is required!"),
    endTime: yup
      .string()
      .required("Event end time is required!")
      .test(
        "endDateTimeValidation",
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({
            startDate,
            startTime,
            endDate,
            endTime,
          });

          return isValid;
        }
      ),

    hosts: yup
      .array()
      .typeError("Invalid hosts.")
      .min(1, "Atleast one host is required.")
      .required("Atleast one host is required."),
    coHosts: yup.array().typeError("Invalid co-hosts."),
    audience: yup.array().typeError("Invalid audience."),

    mediaLinks: yup.array(),

    status: yup.number().oneOf(Object.values(EventStatus.ids)),
    visibility: yup.number().oneOf(Object.values(EventVisibility.ids)),
  });

  const initialValues = {
    eventType: EventType.ids.Online,
    title: "",
    description: "",
    categories: [],

    joinLink: null,
    mapAddress: null,
    lat: null,
    lng: null,

    startDate: AppConstants.DEFAULT_START_DATE,
    startTime: AppConstants.DEFAULT_START_TIME,
    endDate: AppConstants.DEFAULT_END_DATE,
    endTime: AppConstants.DEFAULT_END_TIME,

    hosts: [host],
    coHosts: [],
    audience: [],

    mediaLinks: [],

    status: EventStatus.ids.Ready,
    visibility: EventVisibility.ids.Public,
  };

  const handleAddEvent = (values) => {
    console.log("Add Event: Submit: values: ", values);

    const attendees = [...values.hosts, ...values.coHosts, ...values.audience];

    const payload = {
      postType:2,
      ...values,
      attendees,
    };

    const onSuccess = handleClose;

    dispatch(addEvent(payload, { onSuccess }));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => handleAddEvent(values),
    validateOnMount: true,
  });

  const handleClose = () => {
    // localStorage.removeItem('addEvent');
    dispatch(toggleAddEventDialog(false));
    // history.push('/events');

  };

  const [activeStep, setActiveStep] = useState(0);
  const [rightBtnDisabled, setRightBtnDisabled] = useState(true);

  const steps = [
    "Select Event Type",
    "Add Event Details",
    "Add Event Timeline",
    "Invite Attendees",
    "Add Event Cover",
  ];

  const getStepsContent = () => {
    switch (activeStep) {
      case 0:
        return <AddEventTypeFB formik={formik} />;

      case 1:
        return (
          <AddEventDetails
            formik={formik}
            // bgColor={'transparent'}
            title=""
          />
        );

      case 2:
        return (
          <AddEventTimeline
            formik={formik}
            // bgColor={'transparent'}
            title=""
          />
        );

      case 3:
        return (
          <AddEventAttendees
            formik={formik}
            // bgColor={'transparent'}
            title=""
          />
        );

      case 4:
        return (
          <AddEventCover
            formik={formik}
            // bgColor={'transparent'}
            title=""
          />
        );
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    const getStepsValidation = () => {
      let isError = true;

      switch (activeStep) {
        case 0:
          isError =
            Boolean(formik.errors.eventType) ||
            formik.values.eventType === EventType.ids.Online
              ? Boolean(formik.errors.joinLink)
              : false || formik.values.eventType === EventType.ids.InPerson
              ? Boolean(formik.errors.mapAddress)
              : false;
          break;

        case 1:
          isError =
            Boolean(formik.errors.categories) ||
            Boolean(formik.errors.title) ||
            Boolean(formik.errors.description);
          break;

        case 2:
          isError =
            Boolean(formik.errors.startDate) ||
            Boolean(formik.errors.startTime) ||
            Boolean(formik.errors.endDate) ||
            Boolean(formik.errors.endTime);
          break;

        case 3:
          isError =
            Boolean(formik.errors.hosts) ||
            Boolean(formik.errors.coHosts) ||
            Boolean(formik.errors.audience);
          break;

        case 4:
          isError = Boolean(formik.errors.mediaLinks);
          break;

        default:
          break;
      }

      setRightBtnDisabled(isError);
    };

    getStepsValidation();
  }, [formik, activeStep]);

  console.log("Add event: error", formik.errors);

  return (
    <DialogCNO
      dialogTitle="Add Event"
      open={isAddEventDialog}
      onClose={handleClose}
    >
      <AddEventFBSteps activeStep={activeStep} steps={steps} />

      <form
        onSubmit={formik.handleSubmit}
        className={clsx(GlobalStyles.form, styles.form)}
      >
        {activeStep === steps.length ? (
          <div className={styles.buttonWrapper}>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <>
            <div className={styles.contentWrapper}>
              {getStepsContent()}
              <div className={styles.buttonWrapper}>
                <Button
                  startIcon={
                    <Icon
                      path={mdiArrowLeft}
                      size={"1rem"}
                      color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR}
                    />
                  }
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  className={GlobalStyles.greyButton}
                >
                  Back
                </Button>

                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    className={GlobalStyles.greenButton}
                    endIcon={
                      <Icon
                        path={mdiContentSave}
                        size={"1rem"}
                        color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR}
                      />
                    }
                    disabled={rightBtnDisabled}
                    onClick={formik.handleSubmit}
                    // type={'submit'}
                  >
                    Add Event
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={GlobalStyles.orangeButton}
                    endIcon={
                      <Icon
                        path={mdiArrowRight}
                        size={"1rem"}
                        color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR}
                      />
                    }
                    onClick={handleNext}
                    disabled={rightBtnDisabled}
                  >
                    Next
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </form>
    </DialogCNO>
  );
};

export default AddEventFBDialog;

const useStyles = makeStyles(() => ({
  form: {
    display: "flex",
    flexDirection: "column",
    minHeight: 200,
    justifyContent: "space-between",
  },
  contentWrapper: {
    padding: "1rem 3rem",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "2rem",
  },
}));
