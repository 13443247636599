import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons';
import * as icons from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { updateInvoiceItem } from '../../actions/invoiceItemActions';
import { MenuItem, TextField, Typography } from '@material-ui/core';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { InvoiceTaxPercentage } from '../../utils/mappings';

function EditInvoiceItemTaxRate({
  open,
  onClose,
  invoiceItem,
}) {

  const GlobalStyles = useGlobalStyles();

  const [taxAmount, setTaxAmount] = useState(0);

  const dispatch = useDispatch();
  const { editLoader } = useSelector(state => state.invoiceItems);

  const formik = useFormik({
    initialValues: {
      taxRate: invoiceItem.taxRate,
    },
    validationSchema: yup.object({
      taxRate: yup.string().required('Tax Rate is required'),
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  useEffect(() => {
    setTaxAmount(parseFloat((invoiceItem.hour * invoiceItem.rate) * (formik.values.taxRate / 100)).toFixed());
  }, [formik.values.taxRate]);

  const handleUpdate = (values) => {
    console.log("Edit Invoice Item: taxRate: ", values);

    const payload = {
      ...invoiceItem,
      taxRate: values.taxRate ? values.taxRate : 0,
    };

    dispatch(updateInvoiceItem(payload));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Invoice Item Tax Rate"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <Typography className={GlobalStyles.label}>Select Tax Rate</Typography>
        <TextField
          select
          value={formik.values.taxRate}
          className={GlobalStyles.textFieldSelect}
          variant='outlined'
          margin='dense'
          {...formik.getFieldProps('taxRate')}
          error={formik.touched['taxRate'] && Boolean(formik.errors['taxRate'])}
          helperText={formik.errors['taxRate']}
          fullWidth
        >
          {
            Object.keys(InvoiceTaxPercentage).map((taxRate, index) => (
              <MenuItem value={taxRate}>{taxRate}</MenuItem>
            ))
          }
        </TextField>

        <InputCNO
          name='taxAmount'
          value={taxAmount}
          label='Invoice Item Tax Amount'
          formik={formik}
          placeholder='Invoice Item Tax Amount'
          icon={icons.mdiFormatText}
          fullWidth
          disabled
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditInvoiceItemTaxRate
