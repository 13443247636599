import React, { Fragment } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Divider } from '@material-ui/core';

function WidgetSkeleton({ showChart = false }) {
  return (
    <div style={{ marginTop: "2rem" }}>
      <Skeleton variant="rect" animation="wave" height={40} />

      {
        showChart &&
        <Fragment>
          <Divider style={{ margin: "1rem auto" }} />

          <Skeleton variant="rect" animation="wave" height={200} />
        </Fragment>
      }

    </div>
  )
}

export default WidgetSkeleton;