import haversine from "haversine";
import { toast } from "react-toastify";
import { TrackProximityRadius, TrackType } from "./mappings";
import moment from 'moment';

export const TRACK_PROXIMITY_RADIUS = 50;  //edit: value will be in meter

/**
 * 
 * @param start {object}  {lat: number, lng: number}
 * @param {object} end {lat: number, lng: number}
 * @returns {object} {distanceInMeter, distanceInKM}
 * 
 * Returns the calculated distance object between the 
 * start and end location coords
 */
export const calculateDistance = (start, end) => {
  const distanceInMeter = haversine({
    latitude: start.lat,
    longitude: start.lng
  }, {
    latitude: end.lat,
    longitude: end.lng
  }, { unit: 'meter' });

  return {
    distanceInMeter: distanceInMeter.toFixed(2),
    distanceInKM: (distanceInMeter / 1000).toFixed(2)
  };
};

export const convertDistanceToKM = (distance) => (parseFloat(distance) / 1000).toFixed(2);

// Get the timzone based on latitude/longitude
export const getTimeZoneFromLatLang = async (coords) => {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const location = `${coords.lat},${coords.lng}`; //29.6094188,78.33561770000001
  const uri = `https://maps.googleapis.com/maps/api/timezone/json?location=${location}&timestamp=1331161200&key=${API_KEY}`;

  let timezoneResp = await fetch(uri);
  timezoneResp = await timezoneResp.json();

  if (timezoneResp.status === "OK") {
    return timezoneResp.timeZoneId;
  }
  else {
    toast.error("Error in getting timezone");
    return "Europe/London";
  }
};


/**
 * return the time between given two timestamps
 */
export const timeDifference = (startTime, endTime) => {

  //const jobLocationTimezone = localStorage.getItem("jobLocationTimezone");

  // startTime = new Date(new Date(startTime).toLocaleString('en-US', jobLocationTimezone));
  // endTime = new Date(new Date(endTime).toLocaleString('en-US', jobLocationTimezone));

  startTime = new Date(startTime);
  endTime = new Date(endTime);

  // var calculateHours = Math.round((((endTime - startTime) / (1000 * 60 * 60)) + Number.EPSILON) * 100) / 100;

  let calculateSeconds = (endTime - startTime) / 1000;

  // console.log("klm: startTime: ", startTime);
  // console.log("klm: endTime: ", endTime);
  // console.log("klm: timeDiff: ", calculateSeconds);

  return calculateSeconds;
};

export const getTimeDifference = (startDate, endDate, unit = "hours") => {

  const timeDiff = moment(endDate).diff(moment(startDate), unit);

  return timeDiff;

};

// get the time from date and convert it to dateString with time part equals zero
export const getDateWithoutTime = (timestamp, isEnd) => {

  var dateWithoutTime = new Date();

  if (isEnd) {
    dateWithoutTime = moment(timestamp).endOf('day').toDate();
  } else {
    dateWithoutTime = moment(timestamp).startOf('day').toDate();
  }

  console.log("dateWithoutTime: ", { dateWithoutTime });

  return dateWithoutTime;
};

// combine date and time
export const combineDateAndTime = (date, time) => {

  if (!date) return null;

  if (!time) return date;

  const mDate = moment(date);
  const mtime = moment(time);

  const combineDateTime = mDate.set({
    hour: mtime.get('hour'),
    minute: mtime.get('minute'),
    second: 0,
    millisecond: 0,
  });

  return combineDateTime.format();
};

export const isStartDateAndEndDateEquals = (startDate, endDate) => {
  let isEqual = false;
  if (startDate && endDate) {
    isEqual = moment(startDate).format("DD MMM, YYYY") === moment(endDate).format("DD MMM, YYYY");
  }

  return isEqual;
};


/**
 * return timedifference till Midnight 
 */
export const timeDifferenceTillMidnight = (timestamp) => {
  let currentDate = new Date(timestamp);

  var midNightString = new Date();

  midNightString = moment(timestamp).add(1, 'days').startOf('day').toDate();

  // console.log("klm: timedifference till Midnight ", currentDate, midNightString);

  return timeDifference(currentDate, midNightString);

};

export const timeDifferenceFromMidnight = (timestamp) => {
  let currentDate = new Date(timestamp);

  var midNightString = new Date();

  midNightString = moment(timestamp).startOf('day').toDate();

  // console.log("klm: timedifference from Midnight ", currentDate, midNightString);

  return timeDifference(midNightString, currentDate);
};

export const convertDecimalTimeToTime = (totalSeconds) => {
  // return moment.utc(hours * 3600 * 1000).format('HH:mm');

  const decimalHours = totalSeconds / 3600;

  const hours = Math.floor(decimalHours);
  const minutes = (decimalHours % 1) * 60;

  return `${hours}:${(Math.floor(minutes).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  }))}`;

};

export const formatHours = (seconds, type) => {

  let formattedTime = "";

  var duration = moment.duration(seconds, 'seconds');

  switch (type) {
    case 'numeric':
      formattedTime = duration.format("HH:mm");
      break;

    case 'string':
      formattedTime = duration.format("HH [hrs] mm [min]");
      break;

    default:
      break;
  }

  return formattedTime;

};

export const getDecimalHoursFromSeconds = (seconds) => {
  return (seconds / 3600).toFixed(2);
};

/**
 * Return if user is within proximity or outside proximity
 */

export const isWithinTrackingProximity = (start, end, proximity) => {

  proximity = proximity ? proximity : TrackProximityRadius;

  start = {
    lat: start.lat,
    lng: start.lng
  };
  end = {
    lat: end.lat,
    lng: end.lng
  };

  const within = calculateDistance(start, end).distanceInMeter <= proximity;

  // console.log("klm: isWithin: ", within, start, end);

  return within;
};


// sort data by date
export const sortTrackDataByDate = (data) => {
  const sortedData = data.sort((first, second) => (
    new Date(first.modificationDate) > new Date(second.modificationDate) ? 1 : -1
  ));
  return sortedData;
};


/**
 * Group timestamp by Date
 * Takes Array of Objects with object having a key `modificationDate`
 */
export const groupHoursDetailByDate = (hourDetails) => {
  const sortedHourDetails = sortTrackDataByDate(hourDetails);
  const jobHourDetails = sortedHourDetails.reduce((jobHourDetails, hourDetail) => {

    const parsedHourDetail = moment(hourDetail.modificationDate).format();

    const date = parsedHourDetail.split('T')[0];
    if (!jobHourDetails[date]) {
      jobHourDetails[date] = [];
    }
    jobHourDetails[date].push(hourDetail);
    return jobHourDetails;
  }, {});

  return jobHourDetails;
};

export const groupTrackDetailByTask = (hourDetails) => {

  const sortedHourDetails = sortTrackDataByDate(hourDetails);

  const trackByJobs = sortedHourDetails.reduce((result, track) => {

    const isKeyExist = Object.keys(result).includes(track.taskId || "null");

    if (!isKeyExist) {
      result[track.taskId] = [];
    }

    result[track.taskId] = [...result[track.taskId], track];

    return result;

  }, {});

  return trackByJobs;

};

const symbolLocation = {
  path: "M -6,0 0,-6 6,0 0,6 z",
  strokeColor: "grey",
  fillColor: "#ff9100",
  fillOpacity: 1,
};

const symbolLocationSoft = {
  path: "M -6,0 0,-6 6,0 0,6 z",
  strokeColor: "grey",
  fillColor: "#cfcecc",
  fillOpacity: 1,
};

const symbolLastLocation = {
  path: "M -1,0 A 1,1 0 0 0 -3,0 1,1 0 0 0 -1,0M 1,0 A 1,1 0 0 0 3,0 1,1 0 0 0 1,0M -3,3 Q 0,5 3,3",
  strokeColor: "#00F",
  rotation: 45,
};

const symbolThree = {
  path: "M -6,-6 6,6 M 6,-6 -6,6",
  strokeColor: "red",
  strokeWeight: 6,
};

export const getPinColor = (track) => {
  //Allowed colors for Marker are: red, tomato, orange, yellow, green, gold, wheat, linen, tan,
  //blue, aqua, teal, violet, purple, indigo, turquoise, navy and plum --- pinColor={'green'}

  if (track.taskId === null) {
    return (track.trackType === TrackType.CheckIn ?
      `http://maps.google.com/mapfiles/kml/paddle/red-circle.png` :
      track.trackType === TrackType.CheckOut ?
        `http://maps.google.com/mapfiles/kml/paddle/grn-circle.png` :
        track.trackType === TrackType.AppRestart ?
          `http://maps.google.com/mapfiles/kml/paddle/purple-circle.png` :
          symbolLocationSoft);
  } else {
    return (track.trackType === TrackType.CheckIn ?
      `http://maps.google.com/mapfiles/kml/paddle/red-circle.png` :
      track.trackType === TrackType.CheckOut ?
        `http://maps.google.com/mapfiles/kml/paddle/grn-circle.png` :
        track.trackType === TrackType.AppRestart ?
          `http://maps.google.com/mapfiles/kml/paddle/purple-circle.png` :
          track.trackType === TrackType.Done ?
            `http://maps.google.com/mapfiles/kml/paddle/ylw-circle.png` :
            symbolLocation);
  }
};

export const getTrackType = (track) => {
  console.log("getTracktype: ", track);
  if (track.trackType === TrackType.CheckIn) {
    return `Type ${String.fromCodePoint(0x279C)} Check In`;
  } else if (track.trackType === TrackType.CheckOut) {
    return `Type ${String.fromCodePoint(0x279C)} Check Out`;
  } else if ((track.taskId === null) && (track.trackType === TrackType.Location)) {
    return `Type ${String.fromCodePoint(0x279C)} Soft Location`;
  } else if ((track.taskId !== null) && (track.trackType === TrackType.Location)) {
    return `Type ${String.fromCodePoint(0x279C)} Location`;
  } else if (track.trackType === TrackType.AppRestart) {
    return `Type ${String.fromCodePoint(0x279C)} App Restart`;
  } else if (track.trackType === TrackType.Done) {
    return `Type ${String.fromCodePoint(0x279C)} Done`;
  } else {
    return `Type ${String.fromCodePoint(0x279C)} Empty`;
  }
};

export const getCalloutBackgroundColor = (track) => {
  if (track.trackType === TrackType.CheckIn) {
    return '#faa89d';
  } else if (track.trackType === TrackType.CheckOut) {
    return '#b3fa9d';
  } else if (track.trackType === TrackType.Done) {
    return 'yellow';
  } else if ((track.taskId === null) && (track.trackType === TrackType.Location)) {
    return '#cfcecc';
  } else if ((track.taskId !== null) && (track.trackType === TrackType.Location)) {
    return '#fcc658';
  } else {
    return '#B6B303';
  }
};

export const zoomToLocation = (map, locationLatLang) => {
  console.log("ZOOM CALLED", locationLatLang);

  const { lat, lng } = locationLatLang;

  if (isNaN(lat) || isNaN(lng)) {
    toast.success("Unable to zoom to location as Job location is not available");
    return;
  }

  if (map) {
    console.log("ZOOM CALLED map available");
    map.setCenter(locationLatLang);
    map.setZoom(16);
  }
};