import React, { Fragment, useState } from 'react';
import MoreActionCNO from '../../library/MoreActionsCNO';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  useTheme
} from "@material-ui/core";
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import Icon from '@mdi/react';
import { mdiEyeOutline, mdiUngroup } from '@mdi/js';
import ShowHideWidgetsDialog from './ShowHideWidgetsDialog';

function DashboardHeaderActionsPopover({
  onRearrange,
}) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const [isShowHideWidgetsDialog, setIsShowHideWidgetsDialog] = useState(false);

  function ListItems({ closeMoreActions }) {
    return (
      <List className={GlobalStyles.list}>

        {/* <ListItem className={GlobalStyles.listItem}
          onClick={() => {
            onRearrange();
            closeMoreActions();
          }}>
          <ListItemText primary='Rearrange' />
          <ListItemIcon>
            <IconButton edge='end'>
              <Icon path={mdiUngroup} size='1.5rem' color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR} />
            </IconButton>
          </ListItemIcon>
        </ListItem> */}

        <ListItem className={GlobalStyles.listItem}
          onClick={() => {
            setIsShowHideWidgetsDialog(true);
            closeMoreActions();
          }}>
          <ListItemText primary='Show/Hide Widgets' />
          <ListItemIcon>
            <IconButton edge='end'>
              <Icon path={mdiEyeOutline} size='1.5rem' color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR} />
            </IconButton>
          </ListItemIcon>
        </ListItem>

      </List>
    )
  }

  return (
    <Fragment>

      <MoreActionCNO>
        <ListItems />
      </MoreActionCNO>

      <ShowHideWidgetsDialog
        isShowHideWidgetsDialog={isShowHideWidgetsDialog}
        setIsShowHideWidgetsDialog={setIsShowHideWidgetsDialog}
      />

    </Fragment>
  )
}

export default DashboardHeaderActionsPopover;