import { makeStyles } from '@material-ui/core';
import React from 'react'
import { MetaText } from '../../../../library';

const CardHeader = ({ children, ...rest }) => {

  const styles = useStyles();

  return (
    <MetaText className={styles.title} {...rest}>{children}</MetaText>
  )
}

export default CardHeader;

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '1rem'
  }
}));