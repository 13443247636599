const data = {
  testimonials: [
    {
      id: 1,
      description:
        'meFolia solves the major pain area of every business owner like us who has onsite employees working in remote locations and is not able to track their employees. Where they are? What are they doing? Using this app, we can track our employees in real-time and depute our workforce accordingly thus enabling us to make use of our manpower effectively. It really helped us a lot in saving our time and let us focus on growing our business. I would strongly recommend all businesses like us to use it (suits best for field staff).',
      name: 'Gardening Indeed Ltd',
      designation: '',
    },
    {
      id: 2,
      description:
        'Amazing !!! Such an easy and useful app !!! Incredibly easy to make appointments with customers, very easy to send invoices and quotes but especially the payment and tracking system of employees. Definitely 5 stars!',
      name: 'JDM Trading Group Ltd',
      designation: '',
    },

    {
      id: 3,
      description:
        'We opened our landscaping/gardening company back in 2010 and over the years we used a very good amount of apps to organise/keep on top of our work flow but honestly nothing worked 100%. Before this app, I used calendar, notes, invoicing app and used to pay for tracking devices on my vans and I still wasn’t 100% happy with the work flow. Now we only use this app. First the office gets emails from clients, they book appointments for me to go and quote. So in the app we book appointments , while I’m at the meeting I take photos of the garden and notes while I’m with the client. Then the appointment I convert it into quotation (it’s one clink and all the notes and photos get imported). Then we send quotation to client, they accept it, one click and we convert this into jobs. Here I can assign my team to tasks and subtasks, they check in and out at the end of job or schedule, I get reports on how many hours they worked in the week. The biggest plus for me is that I can see every team member when they arrive at work and when they leave. This was my biggest issue, not knowing if they worked for the full day. I used to have 20 guys working for me. If they were 30 mins late or leaving 30 mins earlier, that was a very big loss for my business. Not anymore! The clients also receive all the reports from job, I can enable the client in the chat group so if there is a problem while I’m Offline , my guys can fix it straight away. Once the job is finished I convert it with one click into an invoice and send it to client. The client pays it and for the whole job I only used one app. It saves me lots of time, I have all the details of my schedules, my teams schedule , I can see where each team member is at any moment of the day and also clients get to be part of the job. I highly recommend it!',
      name: 'Ciprian London',
      designation: '',
    },

    {
      id: 4,
      description:
        'Best and cost effective app we have used for our business. It is no. 1 app in our opinion for businesses like us.',
      name: 'The DaVinci Ideas & Concepts Ltd.',
      designation: '',
    },

    {
      id: 5,
      description:
        'meFolia  allows us to focus on what really matters. We now know more about our business and spend less time in managing it, helping us to be a better company. I recommend meFolia  to any business that needs more insight into how their time is spent. I must say that this is the best tool for team & job management and comes in with simplified and easier version to use. I don’t have to ping my employees for works and ask them for reports, I just login into meFolia and that’s it. I would highly recommend it!',
      name: 'Londoners Garden',
      designation: '',
    }
  ],
};
export default data;
