import { MenuItem, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { mdiCreditCardOutline, mdiFormatText, mdiNumeric, mdiText } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './CompanyPersonalDetailsCardStyles';
import DialogCNO from '../../library/DialogCNO';
import * as yup from 'yup';
import { useFormik } from 'formik';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import RightsManager from '../../utils/RightsManager';
import { updateCompanyDetails } from '../../actions/customerCompanyActions';
import InputCNO from '../../library/InputCNO';
import { Currency, CurrencyIcon, CurrencyReverse, PaymentTypeReverse } from '../../utils/mappings';
import * as icons from '@mdi/js';
import { getMaterialIconString } from '../../utils/icons';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import { EditDefaultPaymentTypeDetails } from './EditDefaultPaymentTypeDetails';

function EditPersonalDetails({
  open,
  onClose,
  profile,
  editablePropertyName,
}) {
  const styles = useStyles();

  const taxRateLocal = LocalStorageConstants.getItem('taxRateLocal', 20);

  const label = {
    'companyRegistration': 'Company Registration',
    'taxNumber': 'Tax Number',
    'taxRateLocal': 'VAT %',
    'currency': 'Currency',
  };
  const placeholder = {
    'companyRegistration': 'Company Registration',
    'taxNumber': 'Tax Number',
    'taxRateLocal': 'VAT%',
    'currency': 'Currency',
  };

  const dispatch = useDispatch();
  const {
    allCompaniesLoader: { editLoader }
  } = useSelector(state => state.companies);

  const validationSchema = yup.object({
    [editablePropertyName]: yup.string().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      [editablePropertyName]: editablePropertyName === "taxRateLocal" ? taxRateLocal : profile[editablePropertyName],
    },
    validationSchema,
    onSubmit: values => handleUpdate(values)
  });

  const handleUpdate = (values) => {

    if (editablePropertyName === "taxRateLocal") {
      localStorage.setItem('taxRateLocal', values.taxRateLocal);
      onClose();
      return;
    }

    const dataToSend = {
      ...profile,
      [editablePropertyName]: values[editablePropertyName]
    };

    if (editablePropertyName === 'currency') {
      localStorage.setItem('currencyType', JSON.stringify(values.currency));
    }

    dispatch(updateCompanyDetails(dataToSend));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Company Details"
      loading={editLoader}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>

        {
          editablePropertyName === 'currency' &&
          <TextField
            select
            value={formik.values.currency}
            className={styles.textField}
            variant='outlined'
            margin='dense'
            {...formik.getFieldProps('currency')}
            error={formik.touched['currency'] && Boolean(formik.errors['currency'])}
            helperText={formik.errors['currency']}
            fullWidth
          >
            {
              Object.keys(Currency).map((currency, index) => (
                <MenuItem value={index + 1}>{currency}</MenuItem>
              ))
            }
          </TextField>
        }

        {
          editablePropertyName !== 'currency' &&
          <InputCNO
            name={editablePropertyName}
            label={label[editablePropertyName]}
            formik={formik}
            placeholder={placeholder[editablePropertyName]}
            icon={mdiFormatText}
            fullWidth
          />
        }

        {/* <InputCNO
          name={editablePropertyName}
          label={label[editablePropertyName]}
          formik={formik}
          placeholder={placeholder[editablePropertyName]}
          icon={mdiFormatText}
          fullWidth
        /> */}
        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )
};


function CompanyPersonalDetailsCard({ profile, isActive }) {

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const styles = useStyles({ tillTablet });

  const isUserAdmin = RightsManager.isAdmin();

  const taxRateLocal = LocalStorageConstants.getItem('taxRateLocal', 20);

  const [editablePropertyName, setEditablePropertyName] = useState(null);
  const [isPersonalDetailsEditable, setIsPersonalDetailsEditable] = useState(false);
  const [isPaymentTypeDetailsEditable, setIsPaymentTypeDetailsEditable] = useState(false);

  console.log("==========profile.currency===========", profile.currency);

  return (
    <div className={styles.container_CompanyPersonalDetailsCard}>
      <Typography className={styles.title}>Financial Details</Typography>
      <div className={styles.content}>
        <div
          className={styles.row}
          onClick={() => {
            setIsPersonalDetailsEditable(true);
            setEditablePropertyName('companyRegistration');
          }}
        >
          <Icon path={mdiText} size="1.5rem" className={styles.icon} />
          <Typography>{profile.companyRegistration || 'Click to add Company Registration Number'}</Typography>
        </div>

        <div
          className={styles.row}
          onClick={() => {
            setIsPersonalDetailsEditable(true);
            setEditablePropertyName('taxNumber');
          }}
        >
          <Icon path={mdiNumeric} size="1.5rem" className={styles.icon} />
          <Typography>{profile.taxNumber || 'Click to add Tax Number'}</Typography>
        </div>

        {/* <div
          className={styles.row}
          onClick={() => {
            setIsPersonalDetailsEditable(true);
            setEditablePropertyName('taxRateLocal');
          }}
        >
          <Icon path={icons.mdiPercentOutline} size="1.5rem" className={styles.icon} />
          <Typography>{`VAT ${taxRateLocal} %` || 'Click to add VAT %'}</Typography>
        </div> */}

        <div
          className={styles.row}
          onClick={() => {
            setIsPersonalDetailsEditable(true);
            setEditablePropertyName('currency');
          }}
        >
          <Icon path={icons[getMaterialIconString(CurrencyIcon[profile.currency || Currency.GBP])]} size="1.5rem" className={styles.icon} />
          <Typography>
            {
              profile.currency
                ? CurrencyReverse[profile.currency]
                : 'Click to add Currency'
            }
          </Typography>
        </div>

        <div
          className={styles.row}
          onClick={() => {
            setIsPaymentTypeDetailsEditable(true);
          }}
        >
          <Icon path={mdiCreditCardOutline} size="1.5rem" className={styles.icon} />
          <Typography>
            {/* {
              profile.defaultPaymentMethod && profile.defaultPaymentMethod.type
                ? PaymentTypeReverse[profile.defaultPaymentMethod.type]
                : 'Invoice Bank Details'
            } */}
            Invoice Bank Details
          </Typography>
        </div>
      </div>

      {
        isPersonalDetailsEditable &&
        (isUserAdmin) &&
        isActive &&
        <EditPersonalDetails
          open={isPersonalDetailsEditable}
          onClose={() => setIsPersonalDetailsEditable(false)}
          profile={profile}
          editablePropertyName={editablePropertyName}
        />
      }

      {
        isPaymentTypeDetailsEditable &&
        (isUserAdmin) &&
        isActive &&
        <EditDefaultPaymentTypeDetails
          open={isPaymentTypeDetailsEditable}
          onClose={() => setIsPaymentTypeDetailsEditable(false)}
          profile={profile}
        />
      }

    </div>
  )
}

export default CompanyPersonalDetailsCard
