import { useFormik } from 'formik';
import React from 'react';
import { DialogCNO } from '../../../library';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import FormActionButtons from '../../../components/FormActionButtons';
import { editEvent } from '../../../actions/eventActions';
import { EventAttendeeRole, EventResponseActionStatus } from '../../../utils/mappings';
import { useGlobalStyles } from '../../../utils/Styles/GlobalStyles';
import AutocompleteCNO from '../../../library/AutocompleteCNO';
import { fetchMemberSuggest } from '../../../actions/suggestionsActions';
import UserSuggestionOption from '../../../components/UserSuggestionOption';
import LocalStorageConstants from '../../../utils/LocalStorageConstants';

const EditEventAttendees = ({ open, onClose, event }) => {

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const suggestions = useSelector(state => state.suggestions);

  const validationSchema = yup.object({
    hosts: yup.array().typeError("Invalid hosts."),
    coHosts: yup.array().typeError("Invalid co-hosts."),
    audience: yup.array().typeError("Invalid audience."),
  });

  const handleEditEventAttendees = (values) => {

    const payload = {
      ...event,
      attendees: [
        ...values.hosts,
        ...values.coHosts,
        ...values.audience,
      ],
    }

    dispatch(editEvent(payload, { onSuccess: onClose }));

  }

  const formik = useFormik({
    initialValues: {
      hosts: event.attendees.filter(attendee => attendee.role === EventAttendeeRole.ids.Host),
      coHosts: event.attendees.filter(attendee => attendee.role === EventAttendeeRole.ids.CoHost),
      audience: event.attendees.filter(attendee => attendee.role === EventAttendeeRole.ids.Audience),
    },
    validationSchema,
    onSubmit: handleEditEventAttendees
  });

  console.log("filteredSuggestions: formik", formik.values);
  console.log("filteredSuggestions: suggestions", suggestions);

  const attendees = [
    ...formik.values.hosts,
    ...formik.values.coHosts,
    ...formik.values.audience,
  ];

  console.log("filteredSuggestions: ", attendees);

  const filteredSuggestions = suggestions.filter(item => !attendees.some(attendee => attendee.id === item.id));

  console.log("filteredSuggestions: ", filteredSuggestions);

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Invite Attendees"
    >
      <form onSubmit={formik.handleSubmit}>

        <div className={GlobalStyles.sectionChildrenContainer}>

          <AutocompleteCNO
            name='hosts'
            label='Hosts'
            formik={formik}
            placeholder='Search Hosts'
            data={filteredSuggestions}
            multiple={true}
            forcePopupIcon={false}
            optionLabel={option => option.title || ""}
            renderOption={option => <UserSuggestionOption user={option} />}
            onSuggestionClick={(values) => {

              values = values.map(value => ({
                ...value,
                role: EventAttendeeRole.ids.Host,
                responseStatus: EventResponseActionStatus.ids.Going
              }));

              formik.setFieldValue("hosts", values);
            }}
            onChange={(value) => {
              if (value) {
                dispatch(fetchMemberSuggest(value));
              }
            }}
          />

          <AutocompleteCNO
            name='coHosts'
            label='Co-Host'
            formik={formik}
            placeholder='Search Co-host'
            data={filteredSuggestions}
            multiple={true}
            forcePopupIcon={false}
            optionLabel={option => option.title || ""}
            renderOption={option => <UserSuggestionOption user={option} />}
            onSuggestionClick={(values) => {

              values = values.map(value => ({
                ...value,
                role: EventAttendeeRole.ids.CoHost,
                responseStatus: EventResponseActionStatus.ids.Going
              }));

              formik.setFieldValue("coHosts", values);
            }}
            onChange={(value) => {
              if (value) {
                dispatch(fetchMemberSuggest(value));
              }
            }}
          />

          <AutocompleteCNO
            name='audience'
            label='Audience'
            formik={formik}
            placeholder='Search Audience'
            data={filteredSuggestions}
            multiple={true}
            forcePopupIcon={false}
            optionLabel={option => option.title || ""}
            renderOption={option => <UserSuggestionOption user={option} />}
            onSuggestionClick={(values) => {

              values = values.map(value => ({
                ...value,
                role: EventAttendeeRole.ids.Audience,
                responseStatus: EventResponseActionStatus.ids.Interested
              }));

              formik.setFieldValue("audience", values);
            }}
            onChange={(value) => {
              if (value) {
                dispatch(fetchMemberSuggest(value));
              }
            }}
          />

        </div>

        <FormActionButtons formik={formik} rightText="Save" leftClick={onClose} />

      </form>
    </DialogCNO>
  )
}

export default EditEventAttendees;