import { fade, makeStyles, Typography } from '@material-ui/core';
import { mdiMapSearch } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { useGlobalStore } from '../../providers/GlobalProvider';
import { TOGGLE_SEARCH } from '../../utils/actions';

function StartTracking() {
  const styles = useStyles();

  const { dispatchGlobal } = useGlobalStore();

  const handleMapSearch = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: true
    });
  };

  return (
    <div className={styles.container_StartTracking} onClick={handleMapSearch}>
      <Icon path={mdiMapSearch} size='1.5rem' />
      <Typography>Start Tracking User</Typography>
    </div>
  )
}

export default StartTracking

const useStyles = makeStyles(theme => ({
  container_StartTracking: {
    background: fade(theme.colors.TRACK_USER_START_BUTTON_BACKGROUND_COLOR, 0.8),
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: 20,
    // left: '45%',
    padding: '0.8rem 2.5rem',
    borderRadius: 10,
    border: `1px solid ${theme.colors.TRACK_USER_START_BUTTON_BORDER_COLOR}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.2rem',
    minWidth: 280,
    '&>*:first-child': {
      marginRight: theme.spacing(1)
    },
    '&>*:last-child': {
      color: theme.colors.TRACK_USER_START_BUTTON_TEXT_COLOR
    },
    '&:hover': {
      cursor: 'pointer',
    }
  }
}));