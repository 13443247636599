import React from 'react'
import { FormFieldsTypes } from '../../utils/enums'
import GooglePlaceInputCNO from '../../../../library/GooglePlaceInputCNO'
import FormFieldActions from '../FormFieldActions'
import { Box } from '@material-ui/core'

function AddressRender({ field, formik, onEdit, onDelete, onChange, showFormFieldActions = false, disabled, }) {

  return (
    <>
      {
        field.type === FormFieldsTypes.ids.Address &&
        <Box display='flex' alignItems='flex-start'>
          <GooglePlaceInputCNO
            label={field.label.address}
            address={formik.values[field.name.address]}
            onSelectAddress={onChange}
            isRequired
            disabled={disabled}
          />
          {
            showFormFieldActions &&
            <FormFieldActions fieldInitialValues={field} onEdit={onEdit} onDelete={onDelete} />
          }
        </Box>
      }
    </>
  )
}

export default AddressRender