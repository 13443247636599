import { fade, makeStyles, useMediaQuery } from '@material-ui/core';
import React from 'react';
import UserCard from '../UserCard/UserCard';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  SnapList,
  SnapItem,
  useScroll,
  useDragToScroll,
} from 'react-snaplist-carousel';
import { v4 } from 'uuid';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

function UserCardSlider({ snapList, visible, jobs, totalHoursWithinProximity }) {

  const cardBP = useMediaQuery("(max-width: 500px)");
  // const jobs = useSelector(state => state.taskSearch.data);
  // const [currentJobs, setCurrentJobs] = useState([]);

  const goToSnapItem = useScroll({ ref: snapList });
  useDragToScroll({ ref: snapList });

  const renderCards = () => jobs?.map((job, index) =>
    <SnapItem key={v4()} margin={{ left: '15px', right: '15px' }} className={styles.snapItem}>
      <UserCard
        job={job}
        onClick={() => goToSnapItem(index)}
      // visible={visible === index}
      />
    </SnapItem>
  );

  const handlePrev = () => {
    if (visible === 0) {
      goToSnapItem(jobs.length - 1);
    }
    else {
      goToSnapItem(visible - 1);
    }
  };

  const handleNext = () => {
    if (visible === jobs.length - 1) {
      goToSnapItem(0);
    }
    else {
      goToSnapItem(visible + 1);
    }
  };

  const styles = useStyles({ cardBP });
  return (
    <div className={styles.container_UserCardSlider}>
      <SnapList ref={snapList}>
        {renderCards()}
      </SnapList>
      {
        jobs.length > 1 &&
        <span className={styles.leftArrow} onClick={handlePrev}>
          <Icon
            path={mdiChevronLeft}
            color="#9BBE00"
            size="2rem"
          />
        </span>
      }
      {
        jobs.length > 1 &&
        <span className={styles.rightArrow} onClick={handleNext}>
          <Icon
            path={mdiChevronRight}
            color="#9BBE00"
            size="2rem"
          />
        </span>
      }
    </div>
  )
}

export default UserCardSlider

const useStyles = makeStyles(theme => ({
  container_UserCardSlider: {
    position: 'relative',
    bottom: 5,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'flex-end',
    background: fade(theme.colors.TRACK_JOB_CARD_BACKGROUND_COLOR, 0.8),
    width: ({ cardBP }) => cardBP ? "85vw" : 350,
    scrollbarColor: 'transparent transparent', //for firefox
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& .slide': {
      borderRadius: 10,
      textAlign: 'left',
    },
    '& .carousel.carousel-slider': {
      overflow: 'initial'
    },
    '& .control-dots': {
      bottom: -6
    }
  },
  leftArrow: {
    position: 'absolute',
    top: 'calc(50% - 2rem / 2)',
    left: -16,
    background: fade(theme.colors.TRACK_JOB_CARD_BACKGROUND_COLOR, 0.8),
    borderRadius: '50%',
    width: '2rem',
    height: '2rem',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  rightArrow: {
    position: 'absolute',
    right: -16,
    background: fade(theme.colors.TRACK_JOB_CARD_BACKGROUND_COLOR, 0.8),
    borderRadius: '50%',
    width: '2rem',
    height: '2rem',
    top: 'calc(50% - 1.8rem / 2)',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  snapItem: {
    width: ({ cardBP }) => cardBP ? "85vw" : 350,
    height: 'max-content'
  }
}))