import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubTask,
  toggleAddSubTaskDialog,
} from "../../actions/subTaskActions";
import DialogCNO from "../../library/DialogCNO";
import FormActionButtons from "../FormActionButtons";
import * as yup from "yup";
import FormBox from "../FormBox/FormBox";
import {
  Chip,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import { SnapItem, SnapList, useDragToScroll } from "react-snaplist-carousel";
import ChipCNO from "../../library/ChipCNO";
import { RateType } from "../../utils";
import {
  Currency,
  CurrencyIcon,
  JobTargetType,
  JobTargetTypeIcon,
} from "../../utils/mappings";
import { mdiFormatText, mdiHours24, mdiLockClock } from "@mdi/js";
import InputCNO from "../../library/InputCNO";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import clsx from "clsx";
import { Autocomplete } from "@material-ui/lab";
import UserSuggestionOption from "../UserSuggestionOption/UserSuggestionOption";
import {
  fetchMemberSuggest,
  resetSuggestions,
} from "../../actions/suggestionsActions";
import { getMaterialIconString } from "../../utils/icons";
import * as icons from "@mdi/js";
import { combineDateAndTime } from "../../utils/Tracking";
import ConfirmationDialogCNO from "../../library/ConfirmationDialogCNO";
import { editJob } from "../../actions/jobActions";
import { SubTaskType, TaskStatus } from "../../utils";
import { updateQuotation } from "../../actions/quotationActions";
import { toast } from "react-toastify";
import InputLabelCNO from "../../library/InputLabelCNO";
import { useDebouncedFormik, useFormikErrors } from "../../hooks";
import { validateStartDateEndDate } from "../../utils/Helpers";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { AppConstants } from "../../utils/AppConstants";
import DateTimePickerCNO from "../../library/DateTimePickerCNO";

function AddSubTaskDialog({ job, setJob, subTaskType }) {
  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : null;

  let currencyType = localStorage.getItem("currencyType");
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  let localCategories = localStorage.getItem("categories");
  localCategories = localCategories ? JSON.parse(localCategories) : [];

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  const dispatch = useDispatch();
  const { isAddSubTaskDialog, addLoader } = useSelector(
    (state) => state.subTasks
  );
  const suggestions = useSelector((state) => state.suggestions);

  const [isWithinRange, setIsWithinRange] = useState(true);
  // const [newJobDates, setNewJobDates] = useState({
  //   // startDate: job.startDate,
  //   // startTime: job.startTime,
  //   endDate: job.endDate,
  //   endTime: job.endTime
  // });

  const closeAddSubTaskDialog = () => {
    localStorage.removeItem("addSubTask");
    dispatch(toggleAddSubTaskDialog(false));
  };

  const validationSchema = yup.object({
    categories: yup.array().required("Categoty is required"),
    title: yup.string().required("Title is required"),
    description: yup.string(),
    startDate: yup.date().required("Start Date is required"),
    startTime: yup.date().required("Start Time is required"),
    endTime: yup
      .date()
      .required("End Date is required")
      .test(
        "endDateTimeValidation",
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({
            startDate,
            startTime,
            endDate,
            endTime,
          });

          return isValid;
        }
      ),
    endDate: yup
      .date()
      .required("End Time is required")
      .test(
        "endDateTimeValidation",
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({
            startDate,
            startTime,
            endDate,
            endTime,
          });

          return isValid;
        }
      ),
    subTaskAssignments: yup.array(),
    rate: yup.string(),
    rateType: yup.number(),
    targetType: yup.number(),
    target: yup.string(),
    taskId: yup.string().required("Task is required for creating sub task"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      categories: [],
      status: TaskStatus.Empty,
      startDate: AppConstants.DEFAULT_START_DATE,
      endDate: AppConstants.DEFAULT_END_DATE,
      startTime: AppConstants.DEFAULT_START_TIME,
      endTime: AppConstants.DEFAULT_END_TIME,
      subTaskAssignments: [],
      rate: 0,
      rateType: RateType.Hourly,
      target: 0,
      targetType: JobTargetType.Hour,
      taskId: job.id,
    },
    validationSchema,
    onSubmit: (values) => handleAddSubTask(values),
  });

  useFormikErrors(formik);

  const handleAddSubTask = (values) => {
    console.log("Add Subtask values: ", values);

    let subTaskAssignments = authUser
      ? [...values.subTaskAssignments, authUser]
      : values.subTaskAssignments;

    const payload = {
      ...values,
      taskId: job ? job.id : null,
      subTaskAssignments: subTaskAssignments.map((item) => ({
        startDate: values.startDate,
        startTime: values.startTime,
        endDate: values.endDate,
        endTime: values.endTime,
        staffId: item.id,
        staffText: item.title || `${item.firstName} ${item.lastName}`,
        paymentToEmployeeStatus: 0,
        paidAmount: 0,
        rate: 0,
        rateType: 0,
      })),
      hour: 0,
      taxRate: 0,
      target: values.target ? values.target : 0,
      rate: values.rate ? values.rate : 0,
    };

    console.log("Add Subtask payload: ", payload);

    const jobStartDate = combineDateAndTime(job.startDate, job.startTime);
    const jobEndDate = combineDateAndTime(job.endDate, job.endTime);

    const subTaskStartDate = combineDateAndTime(
      values.startDate,
      values.startTime
    );
    const subTaskEndDate = combineDateAndTime(values.endDate, values.endTime);

    const _isWithinRange =
      jobStartDate > subTaskStartDate || subTaskEndDate > jobEndDate;
    if (_isWithinRange) {
      setIsWithinRange(false);
    }

    const onAddSuccess = () => {
      localStorage.removeItem("addSubTask");
      if (!_isWithinRange) closeAddSubTaskDialog();
    };

    dispatch(addSubTask(payload, false, null, onAddSuccess));
  };

  const assignedUser = [...formik.values.subTaskAssignments, authUser];

  const unSelectedSuggestions = () =>
    suggestions.filter(
      (item) => !assignedUser.some((assign) => assign.id === item.id)
    );

  useEffect(() => {
    if (!job.id) {
      toast.error("Task is required for creating sub task");
    }
  }, [job.id]);

  useDebouncedFormik(formik, "addSubTask");

  return (
    <DialogCNO
      open={isAddSubTaskDialog}
      onClose={closeAddSubTaskDialog}
      dialogTitle={"Add Sub Task"}
      bgColor={theme.colors.MODAL_BACKGROUND_COLOR}
    >
      {addLoader && <FullScreenLoaderCNO />}

      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <FormBox title={"Subtask Details"}>
          <div>
            <InputLabelCNO label={"Category"} isRequired={true} />
            <SnapList ref={snapList} className={styles.jobChipSlider}>
              {localCategories.map((category) => (
                <SnapItem>
                  <ChipCNO
                    icon={category.iconLink}
                    name={category.title}
                    isCategoryChip={true}
                    active={formik.values.categories[0] === category.id}
                    onClick={() =>
                      formik.setFieldValue("categories", [category.id])
                    }
                  />
                </SnapItem>
              ))}
            </SnapList>
          </div>

          <InputCNO
            name="title"
            label="Title"
            formik={formik}
            placeholder={"Sub Task Title"}
            icon={mdiFormatText}
            fullWidth
            isRequired={true}
          />

          <InputCNO
            name="description"
            label="Description"
            formik={formik}
            placeholder={"Enter Sub Task Description"}
            icon={mdiFormatText}
            fullWidth
            multiline
          />

          <DateTimePickerCNO
            dateName="startDate"
            datePlaceholder="Start Date"
            datePickerLabel="Start by Date"
            onDateChange={(date) => {
              formik.setFieldValue("startDate", date);
            }}
            timeName="startTime"
            timePlaceholder="Start Time"
            timePickerLabel="Start by Time"
            onTimeChange={(date) => {
              formik.setFieldValue("startTime", date);
            }}
            formik={formik}
            disablePast={true}
            iconColor="red"
          />

          <DateTimePickerCNO
            dateName="endDate"
            datePlaceholder="Finish Date"
            datePickerLabel="Finish by Date"
            onDateChange={(date) => {
              formik.setFieldValue("endDate", date);
            }}
            timeName="endTime"
            timePlaceholder="Finish Time"
            timePickerLabel="Finish by Time"
            onTimeChange={(date) => {
              formik.setFieldValue("endTime", date);
            }}
            formik={formik}
            minDate={formik.values.startDate}
            iconColor="green"
          />
        </FormBox>

        <FormBox title="Internal Details">
          <div className={GlobalStyles.subTaskAssignmentsWrapper}>
            <Typography className={GlobalStyles.label}>
              Select Team Member
            </Typography>
            <Autocomplete
              className={GlobalStyles.textField}
              classes={{
                paper: GlobalStyles.suggestionsPaper,
                noOptions: GlobalStyles.autocompleteNoOptions,
                popper: GlobalStyles.popper,
              }}
              multiple
              forcePopupIcon={false}
              options={unSelectedSuggestions()}
              closeIcon={() => null}
              getOptionLabel={(option) => option.title}
              renderOption={(option) => <UserSuggestionOption user={option} />}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.title}
                    {...getTagProps({ index })}
                    className={GlobalStyles.autocompleteChip}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Team Member"
                />
              )}
              onChange={(e, newValue) => {
                formik.setFieldValue("subTaskAssignments", newValue);
              }}
              onInputChange={(e) => {
                if (e && e.target.value) {
                  dispatch(fetchMemberSuggest(e.target.value));
                }
              }}
              onBlur={() => {
                dispatch(resetSuggestions());
              }}
            />
          </div>
          <div>
            <Typography className={GlobalStyles.label}>Types</Typography>
            <div className={GlobalStyles.chipsWrapper}>
              <ChipCNO
                icon={mdiLockClock}
                name="Fixed"
                size="1.5rem"
                active={formik.values.rateType === RateType.Fixed}
                onClick={() => formik.setFieldValue("rateType", RateType.Fixed)}
              />
              <ChipCNO
                icon={mdiHours24}
                name="Hourly"
                size="1.5rem"
                active={formik.values.rateType === RateType.Hourly}
                onClick={() =>
                  formik.setFieldValue("rateType", RateType.Hourly)
                }
              />
            </div>
          </div>
          <InputCNO
            name="rate"
            label="Rate"
            formik={formik}
            placeholder={"Enter Sub Task Rate"}
            icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            fullWidth
            onChange={(e) => {
              const value = e.target.value;

              const rateValue = !isNaN(value) ? value : formik.values.rate;

              formik.setFieldValue("rate", value === "" ? "" : rateValue);
            }}
          />
        </FormBox>

        <FormBox title="Target Details">
          <div>
            <Typography className={GlobalStyles.label}>Target Types</Typography>
            <div className={GlobalStyles.chipsWrapper}>
              {Object.keys(JobTargetType)
                .filter((t) => t !== "None")
                .map((target) => (
                  <ChipCNO
                    icon={
                      icons[getMaterialIconString(JobTargetTypeIcon[target])]
                    }
                    name={target}
                    size="1.5rem"
                    active={formik.values.targetType === JobTargetType[target]}
                    onClick={() =>
                      formik.setFieldValue("targetType", JobTargetType[target])
                    }
                  />
                ))}
            </div>
          </div>

          <InputCNO
            name="target"
            label="Target"
            formik={formik}
            placeholder="Enter Target"
            icon={mdiFormatText}
            fullWidth
          />
        </FormBox>

        <FormActionButtons
          rightText={"Add Subtask"}
          formik={formik}
          leftClick={closeAddSubTaskDialog}
          disabled={!Boolean(formik.values.categories.length)}
        />
      </form>

      {!isWithinRange && (
        <ConfirmationDialogCNO
          open={!isWithinRange}
          onClose={() => {
            closeAddSubTaskDialog();
            setIsWithinRange(true);
          }}
          dialogTitle="Confirm Task timeline update"
          messages={[
            "Start/End Date of Sub task is outside the range of Task Start/End Date, Do you want to change the dates at task level as well ?",
          ]}
          yesClick={() => {
            const endDate = formik.values.endDate;
            const endTime = formik.values.endTime;

            const jobStartDate = combineDateAndTime(
              job.startDate,
              job.startTime
            );
            const jobEndDate = combineDateAndTime(endDate, endTime);

            if (jobStartDate > jobEndDate) {
              toast.error(
                "There is issue in updating job as Start Date Time is after End Date Time."
              );
              return;
            }

            const payload = {
              ...job,
              endDate,
              endTime,
            };

            dispatch(editJob(payload));

            console.log("payload edit: ", payload);

            closeAddSubTaskDialog();
          }}
        />
      )}
    </DialogCNO>
  );
}

export default AddSubTaskDialog;

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {
      marginBottom: theme.spacing(1),
      "&>*": {
        marginBottom: theme.spacing(2),
      },
    },
  },
  jobChipSlider: {
    display: "flex",
    padding: "5px 0",
    // width: 'calc(100vw - 85px)',
    // overflowX: 'auto',
    "&>*:not(:first-child)": {
      marginLeft: 15,
    },
  },
}));
