import React, { useState, useRef } from "react";
import { SnapList, SnapItem } from "react-snaplist-carousel";
import { ChipCNO } from "../../library";
import {
  AppointmentFilter,
  AppointmentFilterIcon,
  AppointmentFilterReverse,
} from "../../utils/mappings";
import { getMaterialIconString } from "../../utils/icons";
import AppointmentFilterModal from "./AppointmentFilterModal";
import LeaveFilterModal from "./LeaveFilterModal";
import HolidayFilterModal from "./HolidayFilterModal";
import { v4 } from "uuid";
import * as icons from "@mdi/js";
import { useTheme } from "@material-ui/core";
import { fetchLeaves, setAppointmentType } from "../../actions/appointmentActions";
import { useDispatch } from "react-redux";

const AppointmentTypeFilterModal = ({ setPage }) => {
  const [activeFilter, setActiveFilter] = useState(
    AppointmentFilter.Appointments
  );
  const [appointmentTypeFilter, setAppointmentTypeFilter] = useState(
    AppointmentFilter.Appointments
  );

  const snapList = useRef(null);
  const theme = useTheme();
  const dispatch=useDispatch();

  const handleAppointmentFilter = async (timeFilter) => {
    console.log("rerun timefilter: ", timeFilter);

    dispatch(setAppointmentType(timeFilter));
    setActiveFilter(timeFilter);
    setAppointmentTypeFilter(timeFilter);
  };

  return (
    <div>
      <div style={{
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        paddingLeft: "0.4rem",
        background: theme.colors.FILTER_BAR_BACKGROUND_COLOR,
        borderRadius: 5,
      }}>
        <SnapList ref={snapList}>
          {Object.values(AppointmentFilter)
            .filter((timeFilter) => timeFilter !== AppointmentFilter.None)
            .map((timeFilter) => (
              <SnapItem key={v4()} margin={{ left: "5px", right: "5px" }}>
                <ChipCNO
                  icon={
                    icons[
                      getMaterialIconString(AppointmentFilterIcon[timeFilter])
                    ]
                  }
                  name={AppointmentFilterReverse[timeFilter]}
                  active={activeFilter === timeFilter}
                  onClick={async () => await handleAppointmentFilter(timeFilter)}
                />
              </SnapItem>
            ))}
        </SnapList>
      </div>
      

      {AppointmentFilter.Appointments == appointmentTypeFilter && (
        <AppointmentFilterModal setPage={setPage} />
      )}

      {AppointmentFilter.Leaves == appointmentTypeFilter && (
        <LeaveFilterModal setPage={setPage} />
      )}

      {AppointmentFilter.Holidays == appointmentTypeFilter && (
        <HolidayFilterModal setPage={setPage} />
      )}
    </div>
  );
};

export default AppointmentTypeFilterModal;
