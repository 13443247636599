import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DialogCNO from '../../library/DialogCNO';
import { convertDecimalTimeToTime, groupHoursDetailByDate, groupTrackDetailByTask } from '../../utils/Tracking';
import { calculateDayHourTotal } from '../../utils/WorkHoursCalculation';
import {
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import FormActionButtons from '../FormActionButtons';
import { setTrackSearchData } from '../../actions/trackSearchActions';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';

function JobSummaryModal({
  open,
  onClose,
  closeMoreActions
}) {

  const theme = useTheme();
  const styles = useStyles();

  const dispatch = useDispatch();

  const { jobs } = useSelector(state => state.jobs);

  const { data, isTrackSearchLoading } = useSelector(state => state.trackSearch);
  const trackData = data;

  const [isCalculationRunning, setIsCalculationRunning] = useState(false);
  const [allHours, setAllHours] = useState({
    hardHoursWithinProximity: 0,
    hardHoursOutsideProximity: 0,
    softHoursWithinProximity: 0,
    softHoursOutsideProximity: 0
  });

  useEffect(() => {
    return () => {
      setAllHours({
        hardHoursWithinProximity: 0,
        hardHoursOutsideProximity: 0,
        softHoursWithinProximity: 0,
        softHoursOutsideProximity: 0
      });

      dispatch(setTrackSearchData([]));
    };
  }, []);

  useEffect(() => {
    (async function calculationIIFE() {

      const trackByJobs = groupTrackDetailByTask(trackData);

      let results = Object.keys(trackByJobs).map(async (jobId) => {
        const tracks = trackByJobs[jobId];
        const summary = await calculatJobSummaryHours(tracks, jobId);
        return summary;
      });

      results = await Promise.all(results);

      const hourSummary = results.reduce((result, summary) => {

        result = {
          hardHoursWithinProximity: parseFloat(summary.hardHoursWithinProximity) + parseFloat(result.hardHoursWithinProximity),
          hardHoursOutsideProximity: parseFloat(summary.hardHoursOutsideProximity) + parseFloat(result.hardHoursOutsideProximity),
          softHoursWithinProximity: parseFloat(summary.softHoursWithinProximity) + parseFloat(result.softHoursWithinProximity),
          softHoursOutsideProximity: parseFloat(summary.softHoursOutsideProximity) + parseFloat(result.softHoursOutsideProximity)
        };

        // console.log("========== result final ======, ", result);

        return result;

      }, allHours);

      // console.log("========== result hourSummary ======, ", hourSummary);

      setAllHours({
        hardHoursWithinProximity: hourSummary.hardHoursWithinProximity,
        hardHoursOutsideProximity: hourSummary.hardHoursOutsideProximity,
        softHoursWithinProximity: hourSummary.softHoursWithinProximity,
        softHoursOutsideProximity: hourSummary.softHoursOutsideProximity
      });

    })();
  }, [trackData]);

  const calculatJobSummaryHours = async (hourDetails, jobId) => {

    const task = jobs.find((todo) => todo.id === jobId);

    // console.log("======= task filtered ==== ", task);

    const jobHourDetails = groupHoursDetailByDate(hourDetails);

    const jobHourDetailsKeys = Object.keys(jobHourDetails);
    const jobHourDetailsKeysLength = jobHourDetailsKeys.length;

    const jobSummary = jobHourDetailsKeys.reduce(async (result, date, dayIndex) => {
      setIsCalculationRunning(true);
      result = await calculateDayHourTotal(jobHourDetails, date, dayIndex, jobHourDetailsKeysLength, task);
      setIsCalculationRunning(false);
      return result;
    }, {});

    // console.log("======== calculatJobSummaryHours jobSummary ===== ", await jobSummary);

    return jobSummary;
  };

  // console.log("==== job summary calculation allHours hardHoursWithinProximity====", allHours.hardHoursWithinProximity);
  // console.log("==== job summary calculation allHours hardHoursOutsideProximity====", allHours.hardHoursOutsideProximity);
  // console.log("==== job summary calculation allHours softHoursWithinProximity====", allHours.softHoursWithinProximity);
  // console.log("==== job summary calculation allHours softHoursOutsideProximity====", allHours.softHoursOutsideProximity);

  if (isCalculationRunning || isTrackSearchLoading) {
    return (
      <FullScreenLoaderCNO />
    )
  }

  const handleClose = () => {

    onClose();

    closeMoreActions();

  };

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Job Summary"
    >

      <div className={styles.hourSummaryWrapper}>

        <Typography className={styles.sectionTitle}>
          Total Hours
        </Typography>

        <div style={{ display: 'flex', flex: 1 }}>

          <div>
            <div className={styles.labelText}>Within Proximity</div>
            <div className={styles.labelText}>Outside Proximity</div>
          </div>

          <div className={styles.column}>
            <div className={styles.value} style={{ color: theme.colors.JOB_END_DATE_COLOR }}>
              {convertDecimalTimeToTime(allHours.hardHoursWithinProximity)}
            </div>
            <div className={styles.value} style={{ color: theme.colors.JOB_START_DATE_COLOR }}>
              {convertDecimalTimeToTime(allHours.hardHoursOutsideProximity)}
            </div>
          </div>

          <div className={styles.column}>
            <div className={styles.value}>
              {convertDecimalTimeToTime(allHours.softHoursWithinProximity)}
              <sub style={{ fontSize: '0.6rem' }}>soft</sub>
            </div>
            <div className={styles.value}>
              {convertDecimalTimeToTime(allHours.softHoursOutsideProximity)}
              <sub style={{ fontSize: '0.6rem' }}>soft</sub>
            </div>
          </div>
        </div>
      </div>

      <FormActionButtons
        isLeftButton={false}
        rightText="OK"
        rightClick={onClose}
        closeMoreActions={closeMoreActions}
      />

    </DialogCNO>
  )
}

export default JobSummaryModal;

const useStyles = makeStyles((theme) => ({
  container_AddInvoiceJobItems: {

  },

  hourSummaryWrapper: {
    background: theme.colors.WORK_HOURS_TOTAL_ROW_BACKGROUND_COLOR,
    borderRadius: 5,
    padding: '8px 12px',
    marginTop: theme.spacing(2),
  },

  sectionTitle: {
    color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR,
    fontWeight: 'bold',
    marginTop: 4,
    fontSize: '0.8rem',
  },

  labelText: {
    color: theme.colors.WORK_HOUR_JOB_TITLE_TEXT_COLOR,
    marginTop: 4,
    marginBottom: 4,
  },

  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  value: {
    color: theme.colors.WORK_HOUR_JOB_TITLE_TEXT_COLOR,
    fontWeight: 'bold',
    marginTop: 4,
    marginBottom: 4,
  }

}))
