import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(0.8),
        '&>*': {
            marginBottom: theme.spacing(1),
        },
        '& .infinite-scroll-component >*': {
            marginBottom: theme.spacing(1),
        }
    },
    infiniteLoader: {
        textAlign: 'center'
    },
    snackbar: {
        '& .MuiSnackbarContent-root': {
            background: theme.palette.primary.main,
            color: 'white',
            fontSize: '0.9rem',
            fontWeight: 'bold'
        },
        '& .MuiSnackbarContent-action .MuiButton-textSecondary': {
            color: 'black'
        }
    }
}));