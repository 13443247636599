import { useEffect } from "react";

export const useBrowserTabClose = (callback) => {
  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();

      typeof callback === "function" && callback(event);
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);
};
