import moment from "moment";
import LocalStorageConstants from "../../../utils/LocalStorageConstants";
import { DeepLinkDomainPrefixes } from "../../../utils/mappings";
import { DATE_FORMAT, TIME_FORMAT } from '../../../utils/formatDate';
import { AppConstants } from "../../../utils/AppConstants";

// export const defaultMessageTop = (appointment) => `Hello ${appointment.customerText ? appointment.customerText : ""},\n\nPlease find below details of appointment scheduled for ${appointment.title}.\n\nStarts on: ${moment(appointment.startDate).format(DATE_FORMAT)} at ${moment(appointment.startTime).format(TIME_FORMAT)}\n\nEnds on: ${moment(appointment.endDate).format(DATE_FORMAT)} at ${moment(appointment.endTime).format(TIME_FORMAT)}`;

export const defaultMessageTop = (appointment, appointmentEmailTemplate) => {

  appointmentEmailTemplate = appointmentEmailTemplate ? appointmentEmailTemplate : AppConstants.DefaultAppointmentEmailTemplate;

  return appointmentEmailTemplate
    .replace(/<<Name>>/g, appointment.customerText ? appointment.customerText : "")
    .replace(/<<Title>>/g, appointment.title)
    .replace(/<<StartDate>>/g, moment(appointment.startDate).format(DATE_FORMAT))
    .replace(/<<StartTime>>/g, moment(appointment.startTime).format(TIME_FORMAT))
    .replace(/<<EndDate>>/g, moment(appointment.endDate).format(DATE_FORMAT))
    .replace(/<<EndTime>>/g, moment(appointment.endTime).format(TIME_FORMAT));

};

const messageBottom = (emailSignatureTemplate) => {

  const authUserCompany = LocalStorageConstants.getItem('authUserCompany');

  emailSignatureTemplate = emailSignatureTemplate ? emailSignatureTemplate : AppConstants.DefaultEmailSignatureTemplate;

  return `<div style="color: #4e4e4e; margin-right: 10px; margin-top: 20px; white-space: pre-line;">
      ${emailSignatureTemplate.replace(/<<FromName>>/, authUserCompany.title)}
    </div>`;

};


export const getAppointmentSendSummaryHtmlString = (appointment, emailData) => {

  const authUserCompany = LocalStorageConstants.getItem('authUserCompany');

  emailData = emailData ? defaultMessageTop(appointment, emailData) : defaultMessageTop(appointment);

  const messageTop = `<div style="color: #4e4e4e; white-space: pre-line;">${emailData}</div>`;

  const viewAppointmentButton = `<div style="display: flex; flex: 1; justify-content: center; align-items: center">
    <a href="${DeepLinkDomainPrefixes.Https}/appointment/${appointment.id}" style="background: #008dc9; text-decoration: none;  padding: 10px 20px; border-radius: 5px; color: #fefefe; font-weight: bold; cursor: pointer;">
      View Appointment
    </a>
  </div>`;


  const appBanner = `
    <div style="display: flex; flex: 1; justify-content: center; align-items: center; background: #e8e8e8; border-radius: 5px;">
    <img src="https://checkndout.com/logoAntEmail.png" alt="app-logo" style="width: 40px; object-fit: contain;" />
    <img src="https://checkndout.com/logoTextEmail.png" alt="name-logo" style="height: 40px; object-fit: contain;" />
    </div>
  `;


  const messageSummary = `
      <div style="font-family: Arial, Helvetica, sans-serif;">
        ${messageTop}
        <div style="margin-top: 40px; margin-bottom: 40px;">${viewAppointmentButton}</div>
        ${messageBottom(authUserCompany.emailSignatureTemplate)}
        <div style="margin-top: 40px;">${appBanner}</div>
      </div>
    `;

  return messageSummary;

};
