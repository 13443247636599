import { Button, Divider, makeStyles, useTheme } from "@material-ui/core";
import {
  mdiClockEnd,
  mdiClockStart,
  mdiDirections,
  mdiPanoramaVariantOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import clsx from "clsx";
import React, { useState } from "react";
import {
  DateTimeColor,
  DateTimeFormat,
  DateTimeText,
  MetaText,
} from "../../../../library";
import LinkCNO from "../../../../library/LinkCNO";
import { EventType } from "../../../../utils/mappings";
import RightsManager from "../../../../utils/RightsManager";
import { useGlobalStyles } from "../../../../utils/Styles/GlobalStyles";
import { EditEventTimeline, EditEventTitle } from "../../modals";

const EventHeaderDetailsFB = ({ event }) => {
  const isUserAdmin = RightsManager.isAdmin();

  const theme = useTheme();

  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const [isEditTimeline, setIsEditTimeline] = useState(false);
  const [isEditTitle, setIsEditTitle] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        {event && event?.mediaLinks && event?.mediaLinks.length ? (
          <img
            src={event?.mediaLinks[0]?.link}
            alt=""
            className={styles.image}
          />
        ) : (
          <Icon path={mdiPanoramaVariantOutline} size="30%" color={"grey"} />
        )}
      </div>

      <div className={styles.dataWrapper}>
        <div
          className={styles.dateRow}
          onClick={() => {
            if (!isUserAdmin) return;
            setIsEditTimeline(true);
          }}
        >
          <div className={styles.dateRow}>
            <div className={GlobalStyles.datesWrapper}>
              <DateTimeText
                format={DateTimeFormat.CalendarString}
                color={DateTimeColor.Start}
                className={styles.date}
              >
                {event.startDate}
              </DateTimeText>
              <DateTimeText
                format={DateTimeFormat.TimeFormat}
                color={DateTimeColor.Start}
                className={styles.date}
              >
                {event.startTime}
              </DateTimeText>
            </div>
            <Icon
              path={mdiClockStart}
              size="1.5rem"
              color={theme.colors.JOB_START_DATE_COLOR}
            />
          </div>
          &nbsp;-&nbsp;
          <div className={styles.dateRow}>
            <div className={GlobalStyles.datesWrapper}>
              <DateTimeText
                format={DateTimeFormat.CalendarString}
                color={DateTimeColor.End}
                className={styles.date}
              >
                {event.endDate}
              </DateTimeText>
              <DateTimeText
                format={DateTimeFormat.TimeFormat}
                color={DateTimeColor.End}
                className={styles.date}
              >
                {event.endTime}
              </DateTimeText>
            </div>
            <Icon
              path={mdiClockEnd}
              size="1.5rem"
              color={theme.colors.JOB_END_DATE_COLOR}
            />
          </div>
        </div>

        <MetaText
          maxLine={0}
          className={clsx(GlobalStyles.cardTitle, styles.title)}
          onClick={() => {
            if (!isUserAdmin) return;
            setIsEditTitle(true);
          }}
        >
          {event.title}
        </MetaText>

        <div className={styles.eventTypeWrapper}>
          <MetaText>{EventType.getItem(event.eventType).title}</MetaText>
          {event.eventType === EventType.ids.Online && (
            <Button
              variant="contained"
              className={GlobalStyles.orangeButton}
              // onClick={handeLeftClick}
              style={{ marginLeft: 0 }}
            >
              <LinkCNO to={event.joinUrl} type="absolute">
                Join
              </LinkCNO>
            </Button>
          )}

          {event.eventType === EventType.ids.InPerson && (
            <div className={styles.eventTypeRow}>
              <Icon
                path={mdiDirections}
                size="1.5rem"
                color={theme.colors.META_TEXT_COLOR}
              />
              <MetaText style={{ marginLeft: 4 }}>{event.mapAddress}</MetaText>
            </div>
          )}
        </div>
      </div>

      {isEditTimeline && isUserAdmin && (
        <EditEventTimeline
          open={isEditTimeline}
          onClose={() => setIsEditTimeline(false)}
          event={event}
        />
      )}

      {isEditTitle && isUserAdmin && (
        <EditEventTitle
          open={isEditTitle}
          onClose={() => setIsEditTitle(false)}
          event={event}
        />
      )}
    </div>
  );
};

export default EventHeaderDetailsFB;

const useStyles = makeStyles((theme) => ({
  container: {
    background: "rgb(151, 151, 151)",
    background:
      "linear-gradient(180deg, rgba(130,130,130,1) 4%, rgba(100,100,100,1) 21%, rgba(47,46,46,1) 55%, rgba(32,33,33,1) 75%)",
    minHeight: "60vh",
  },

  imageWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  image: {
    maxHeight: "50vh",
    objectFit: "contain",
    boxShadow: theme.shadows[10],
  },

  dataWrapper: {
    padding: "1rem 15%",
  },

  dateRow: {
    display: "flex",
    alignItems: "center",
  },
  date: {
    fontWeight: "bold",
    fontSize: "1rem",
  },

  title: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },

  eventTypeWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  eventTypeRow: {
    display: "flex",
    alignItems: "center",
  },
}));
