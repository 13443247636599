import { Typography } from '@material-ui/core';
import { mdiCursorMove } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import { AppConstants } from '../utils/AppConstants';

function DraggableLists({
  items,
  listItemKeyName = "id",
  listItemPropName = "item",
  children,

  onDrop,
  lockAxis = 'y',

  loading = false,
  notFoundText,
  loaderText = 'Loading...',
  scrollableTarget,
  ...rest
}) {

  return (
    <Container
      dragHandleSelector='.dragSelector'
      onDrop={(dropEvent) => {

        const { addedIndex, removedIndex } = dropEvent;

        // console.log("=====DraggableLists====onDrop===dropEvent====", { addedIndex, removedIndex });

        if (addedIndex === removedIndex) return;

        const isDragDirectionUp = removedIndex > addedIndex;

        let itemsReordered = [];

        const dragItem = items[removedIndex];
        const replacedItem = items[addedIndex];

        // console.log("=====DraggableLists====onDrop===replacedItem====", replacedItem);

        let updatedDragItem = dragItem;

        // drag to first item
        if (addedIndex === 0) {

          // console.log("=====DraggableLists====onDrop===drag to first item====");

          updatedDragItem = {
            ...dragItem,
            itemOrder: AppConstants.SORTING_MULTIPLIER
          };

          const nextItemToReplacedItem = items[addedIndex + 1];

          // console.log("=====DraggableLists====onDrop===nextItemToReplacedItem====", nextItemToReplacedItem);

          let oldFirstItem;
          // below conditon for when there are only 2 items
          if (items.length === 2) {

            oldFirstItem = {
              ...replacedItem,
              itemOrder: 2.0 * AppConstants.SORTING_MULTIPLIER
            };

          } else {

            oldFirstItem = {
              ...replacedItem,
              itemOrder: parseFloat((updatedDragItem.itemOrder + nextItemToReplacedItem.itemOrder) / 2)
            };

          }

          itemsReordered = [updatedDragItem, oldFirstItem];
        } else if (addedIndex === items.length - 1) {
          // drag to last item

          // console.log("=====DraggableLists====onDrop===drag to last item====");

          updatedDragItem = {
            ...dragItem,
            itemOrder: replacedItem.itemOrder + AppConstants.SORTING_MULTIPLIER
          };

          itemsReordered = [updatedDragItem];

        } else {

          // console.log("=====DraggableLists====onDrop===drag to other case====");

          const referenceItem = items[isDragDirectionUp ? addedIndex - 1 : addedIndex + 1];

          // console.log("=====DraggableLists====onDrop===referenceItem====", referenceItem);

          updatedDragItem = {
            ...dragItem,
            itemOrder: parseFloat((replacedItem.itemOrder + referenceItem.itemOrder) / 2)
          };

          itemsReordered = [updatedDragItem];

        }

        const updatedArray = items.map((item, index) => {

          const findItem = itemsReordered.find(i => i.id === item.id);

          return findItem ? findItem : item;

        });

        const sortedArray = updatedArray.sort((first, second) => first.itemOrder < second.itemOrder ? -1 : 1);

        if (onDrop) onDrop(itemsReordered, sortedArray, dropEvent);

      }}
      lockAxis={lockAxis}
      dragBeginDelay={200}
      {...rest}
    >

      {
        items?.length
          ? items.map((item, index) => (
            <Draggable
              key={item[listItemKeyName]}
              style={{ position: 'relative' }}
            >
              <Icon path={mdiCursorMove} size={"1.2rem"} style={{ cursor: 'move', position: 'absolute', top: 15, right: 5, zIndex: 100, color: 'orange' }} className="dragSelector" />
              {
                React.cloneElement(children, { [listItemPropName]: item })
              }
            </Draggable>
          ))
          : (
            <Typography style={{ marginTop: 8 }}>
              {loading ? loaderText : notFoundText}
            </Typography>
          )
      }

    </Container>
  )
}

export default DraggableLists