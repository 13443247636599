import { Box, Button, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { IconMain, MetaText } from '../../../../library'
import { AddSectionDialog } from '../../dialogs';

const FormSectionBoxTitle = ({ title, initialValues, onEditSection, onRemoveSection }) => {

  const styles = useStyles();

  const [isEditSection, setIsEditSection] = useState(false);

  return (
    <Box mb={1} display='flex' alignItems='center' justifyContent={title ? 'space-between' : 'flex-end'}>
      <MetaText
        fontSize='0.9rem'
        themeColorKey='FORM_SECTION_TITLE_COLOR'
        style={{ marginLeft: -8 }}
      >
        {title}
      </MetaText>

      <Box display='flex' alignItems='center' style={{ gap: 8 }} >
        <Button
          className={styles.editButton}
          variant='contained'
          onClick={() => setIsEditSection(true)}
          startIcon={<IconMain name='pencil' size="0.9rem" color={'#ddd'} />}
        >
          Edit Section
        </Button>
        <Button
          className={styles.removeButton}
          variant='outlined'
          onClick={onRemoveSection}
          startIcon={<IconMain name='trash-can' size="0.9rem" color={'#ddd'} />}
        >
          Remove Section
        </Button>
      </Box>

      <AddSectionDialog
        open={isEditSection}
        onClose={() => setIsEditSection(false)}
        formikInitialValues={initialValues}
        onAddSection={onEditSection}
      />
    </Box>
  )
}

export default FormSectionBoxTitle;

const useStyles = makeStyles((theme) => ({
  editButton: {
    fontSize: '0.6rem',
    background: theme.colors.FORM_SECONDARY_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_SECONDARY_BUTTON_TEXT_COLOR,
  },
  removeButton: {
    fontSize: '0.6rem',
    background: theme.colors.FORM_DELETE_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_DELETE_BUTTON_TEXT_COLOR,
  }
}))