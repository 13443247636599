import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  conatiner_NotificationPopover: {
    padding: 5,
    overflow: 'auto',
    paddingTop: 10,
    height: '70vh',
    minWidth: 300,
    maxWidth: 500,
    // display: 'flex',
    paddingRight: 3,
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR,
    // flexDirection: 'column-reverse',
    '&::-webkit-scrollbar': {
      // display: 'none'
      width: 4
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#5e5e5e',
      borderRadius: 10,
    },
  },
  infiniteLoader: {
    textAlign: 'center',
    marginTop: theme.spacing(1)
  }
}));