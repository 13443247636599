import React, { useEffect, useState } from 'react'
import { DateTimePickerCNO } from '../../../../library';
import { DateTimePickerType, FormFieldsTypes } from '../../utils/enums';
import { Box } from '@material-ui/core';
import FormFieldActions from '../FormFieldActions';
import moment from 'moment';
import { combineDateAndTime } from '../../../../utils/Tracking';

function DateTimePickerRender({
  dateValue,
  timeValue,
  field,
  onChange,
  onEdit,
  onDelete,
  showFormFieldActions = false,
  disabled
}) {

  const renderDateOnly = field.attributes.dateTimePickerType === DateTimePickerType.ids.Date;
  const renderTimeOnly = field.attributes.dateTimePickerType === DateTimePickerType.ids.Time;
  const renderBoth = field.attributes.dateTimePickerType === DateTimePickerType.ids.DateTimeBoth;

  const now = moment().toString();

  const [date, setDate] = useState(dateValue || now);
  const [time, setTime] = useState(timeValue || now);

  const handleDateChange = (value) => setDate(moment(value).toISOString());
  const handleTimeChange = (value) => setTime(moment(value).toISOString());

  const dateProps = (renderDateOnly || renderBoth) ? {
    dateName: field.name.date,
    datePlaceholder: "Date",
    datePickerLabel: field.label.date,
    onDateChange: handleDateChange,
    dateDisabled: disabled,
    restDatePicker: { value: date },
  } : {}

  const timeProps = (renderTimeOnly || renderBoth) ? {
    timeName: field.name.time,
    timePlaceholder: "Time",
    timePickerLabel: field.label.time,
    onTimeChange: handleTimeChange,
    timeDisabled: disabled,
    restTimePicker: { value: time }
  } : {}

  useEffect(() => {

    if (typeof onChange === 'function') {
      onChange({ date, time })
    }

  }, [date, time])

  console.log("DateTimePicker: field: ", field, { renderBoth, renderDateOnly, renderTimeOnly })

  return (
    <>
      {
        field.type === FormFieldsTypes.ids.DateTime &&
        <Box display='flex' alignItems='flex-start'>
          <DateTimePickerCNO

            {...dateProps}
            {...timeProps}

            disablePast={true}
          />

          {
            showFormFieldActions && <FormFieldActions fieldInitialValues={field} onEdit={onEdit} onDelete={onDelete} />
          }


        </Box>
      }
    </>
  )
}

export default DateTimePickerRender