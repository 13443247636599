import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { ImagesViewerSliderCNO } from "../../library/ImagesViewerSliderCNO";
import ImageDocumentDisplayCard from "../DocumentTypeDisplayCard/ImageDocumentDisplayCard";

const GroupMedias = ({
  mediaLinks = [],
  resolvedMediaLinks = [],
  slicedLength = 6,
  onChangeImageIndex,
  onClick,
  imageViewerLoader = false,
  loading = false,
  details,
}) => {
  console.log("details", details);
  console.log("linn", mediaLinks);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isImageViewerVisible, setIsImageViewerVisible] = useState(false);

  // const [lightboxController, setLightboxController] = useState({
  //   toggler: false,
  //   slide: 1,
  // });

  const remainingMediaCount = mediaLinks.length - slicedLength + 1;

  const _onChangeImageIndex = (index) => {
    onChangeImageIndex(index, setIsImageViewerVisible);
    setCurrentImageIndex(index);
  };

  if (loading) return <Typography>Loading...</Typography>;
  console.log("MediaLinks", mediaLinks);
  return (
    <Box display="flex" flexWrap="wrap" padding={1}>
      {mediaLinks.slice(0, slicedLength).map((document, index) => (
        <ImageDocumentDisplayCard
          document={document}
          containerStyle={{
            marginRight: 4,
            marginBottom: 4,
            width: 250,
          }}
          imageStyle={{
            objectFit: "cover",
            maxHeight: "100%",
            width: "100%",
          }}
          onClick={(index) => {
            onClick(index, setIsImageViewerVisible);
            setCurrentImageIndex(index);
            // onClick(index, setLightboxController);
            // setLightboxController((prev) => ({
            //   ...prev,
            //   slide: index + 1,
            // }));
          }}
          moreMediaCount={index === slicedLength - 1 ? remainingMediaCount : 0}
          index={index}
        />
      ))}

      
      <ImagesViewerSliderCNO
        images={mediaLinks.map((image) => ({
          ...image,
          src: image.uri || image.link || image.gif,
        }))}
        currentImageIndex={currentImageIndex}
        isOpen={isImageViewerVisible}
        onClose={() => {
          setIsImageViewerVisible(false);
        }}
        onChangeImageIndex={_onChangeImageIndex}
        isMediaLoading={imageViewerLoader}
        // lightboxController={lightboxController}
        // setLightboxController={setLightboxController}
      />
    </Box>
  );
};

export default GroupMedias;
