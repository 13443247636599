import React, { useState } from 'react';
import DialogCNO from './DialogCNO/DialogCNO';
import FileViewer from 'react-file-viewer';
import { Fragment } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import FullScreenLoaderCNO from './FullScreenLoaderCNO';

export const showFileInViewer = ({
  isFileViewer,
  handleClose,
  dialogTitle,
  mimeType,
  filePath,
  closeMoreActions
}) => {

  return (
    <Fragment>

    </Fragment>
  )
};

export default function FileViewerModal({
  children,
  dialogTitle = "Preview",
  mimeType,
  filePath,
  onClick,
  closeMoreActions,
  loading = false,
  onClose
}) {

  console.log("========FileViewerModal======", { mimeType, filePath });

  const [isFileViewer, setIsFileViewer] = useState(false);

  const handleFileClick = async () => {

    if (onClick) {
      await onClick();
    }

    setIsFileViewer(true);

  };

  const handleClose = () => {
    setIsFileViewer(false);

    if (closeMoreActions) {
      closeMoreActions();
    }

    if (onClose) onClose();

  };

  // let file = {};
  // if (filePath.includes('http')) {
  //   file = { url: filePath };
  // } else if (filePath.includes('base64')) {
  //   file = {
  //     data: getBase64Data(filePath),
  //     mimeType: mimeType,
  //     name: "File"
  //   }
  // }

  // console.log("=====fileviewermodal=====mimetype", mimeType);
  // console.log("=====fileviewermodal=====filePath", filePath);

  const _renderErrorComponent = () => {
    return (
      <Typography style={{ color: 'red' }}>
        There is some issue in viewing file. Please try again.
      </Typography>
    )
  }

  const _renderUnsupportedComponent = () => {
    return (
      <Typography style={{ color: 'red' }}>
        {/* File Type is not supported. */}
      </Typography>
    )
  }

  console.log("=======fileviewer: ", { loading, mimeType });

  return (
    <Fragment>

      <div onClick={handleFileClick} style={{ display: 'flex' }}>
        {children}
      </div>

      {
        isFileViewer && filePath &&
        <DialogCNO
          open={isFileViewer}
          onClose={handleClose}
          dialogTitle={dialogTitle}
          width='720px'
        >
          {
            (loading) ? (
              <Fragment>
                <FullScreenLoaderCNO />
              </Fragment>
            ) : (
             
              <FileViewer
                key={filePath}
                fileType={mimeType}
                filePath={filePath}
                errorComponent={_renderErrorComponent}
                unsupportedComponent={_renderUnsupportedComponent}
                onError={(error) => console.log("Error: File Viewer: ", error)}
              />
              
            )
          }

        </DialogCNO>
      }

    </Fragment>

  )
}
