import React from 'react';
import Particles from "react-tsparticles";
import Img1 from '../../../assets/appLogoEmail.png';
import Img2 from '../../../assets/nameLogoEmail.png';
import Img3 from '../../../common/assets/image/LandingPage/img-4.png';
import Img4 from '../../../common/assets/image/LandingPage/img-5.png';
import Img5 from '../../../common/assets/image/LandingPage/img-6.png';
import Img6 from '../../../common/assets/image/LandingPage/img-8.png';

const ParticlesComponent = () => {
  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };
  return (
    <>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          particles: {
            number: {
              value: 25,
              density: { enable: true, value_area: 800 },
            },

            shape: {
              type: ['images'],
              image: [
                {
                  src: `${Img1}`,
                  width: 100,
                  height: 103,
                },
                {
                  src: `${Img2}`,
                  width: 200,
                  height: 203,
                },
                {
                  src: `${Img3}`,
                  width: 20,
                  height: 23,
                },
                {
                  src: `${Img4}`,
                  width: 20,
                  height: 23,
                },
                {
                  src: `${Img5}`,
                  width: 50,
                  height: 53,
                },
                {
                  src: `${Img6}`,
                  width: 50,
                  height: 53,
                },
              ],
            },
            opacity: {
              value: 0.57626369048095938,
              random: false,
              anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
            },
            size: {
              value: 8,
              random: true,
              anim: { enable: false, speed: 40, size_min: 0.8, sync: false },
            },
            line_linked: {
              enable: false,
              distance: 150,
              color: '#ffffff',
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 3,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              bounce: false,
              attract: { enable: false, rotateX: 600, rotateY: 1200 },
            },
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: { enable: true, mode: 'repulse' },
              onclick: { enable: true, mode: 'push' },
              resize: true,
            },
            modes: {
              grab: { distance: 400, line_linked: { opacity: 1 } },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: { distance: 200, duration: 0.4 },
              push: { particles_nb: 4 },
              remove: { particles_nb: 2 },
            },
          },
          retina_detect: true,
        }}
      />
    </>
  );
};
export default ParticlesComponent;
