import axios from "axios";

export const TOGGLE_LP_LOGIN_MODAL = "TOGGLE_LP_LOGIN_MODAL";
export const SET_LP_LOGIN_MODAL_DEFAULT_TAB = "SET_LP_LOGIN_MODAL_DEFAULT_TAB";
export const SET_APPS_LOADER = "SET_APPS_LOADER";
export const SET_MEDIAS_UPLOAD_RESULT = "SET_MEDIAS_UPLOAD_RESULT";
export const SET_HOME_LIST_DATA = "SET_HOME_LIST_DATA";

export const toggleLPLoginModal = (status) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_LP_LOGIN_MODAL,
      payload: status
    });
  };
};

export const setLPLoginModalDefaultTab = (tabKey) => {
  return (dispatch) => {
    dispatch({
      type: SET_LP_LOGIN_MODAL_DEFAULT_TAB,
      payload: tabKey
    });
  };
};

export const setAppsLoader = (loaderKey, loaderValue) => {
  return (dispatch) => {
    dispatch({
      type: SET_APPS_LOADER,
      payload: { loaderKey, loaderValue }
    });
  };
};

export const setMediasUploadResult = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_MEDIAS_UPLOAD_RESULT,
      payload: payload
    });
  };
};

export const setHomeListData = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_HOME_LIST_DATA,
      payload
    })
  }
}

export const fetchHomeListData = (payload) => {
  return async (dispatch) => {

    payload = {
      ...payload
    }

    dispatch(setAppsLoader('homeListDataLoader', true));

    try {

      const response = await axios.get('/api/mixData.json');

      const data = response.data.data;

      dispatch(setHomeListData(data));

    } catch (error) {

    } finally {
      dispatch(setAppsLoader('homeListDataLoader', false));
    }

  }
}
