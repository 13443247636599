import React from 'react';
import { Box, Switch, withStyles } from "@material-ui/core";
import InputLabelCNO from "./InputLabelCNO";
import { getNestedObjectValue } from '../utils/Helpers';

export const SwitchCNO = withStyles((theme) => ({
  root: {
    // top: -6
  },
  switchBase: {
    color: theme.colors.FINISHED_SWITCH_COLOR,
    '&$checked': {
      color: theme.colors.FINISHED_SWITCH_CHECKED_COLOR,
    },
    '&$checked + $track': {
      backgroundColor: theme.colors.FINISHED_SWITCH_CHECKED_TRACK_COLOR,
    },
  },
  checked: {},
  track: {},
}))(Switch);

export const SwitchMain = ({
  name,
  value,
  onChange,
  formik,
  label,
  required,
  labelProps = {},
  inlineLabel = true,
  justifyContent = 'flex-start',
  ...rest
}) => {

  value = value
    ? value
    : formik ? getNestedObjectValue(formik.values, name) : false;

  const handleChange = (e) => {

    const nextValue = e.target.checked;

    typeof onChange === 'function' && onChange(nextValue);

    if (formik) formik.setFieldValue(name, nextValue);

  }

  return (
    <Box
      display={inlineLabel ? 'flex' : 'block'}
      {...inlineLabel ? { justifyContent } : {}}
      {...inlineLabel ? { alignItems: 'center' } : {}}
    >
      <InputLabelCNO
        label={label}
        isRequired={required}
        {...labelProps}
      />
      <SwitchCNO
        checked={value}
        onChange={handleChange}
        {...rest}
      />
    </Box>
  )
}