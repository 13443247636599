import React, { useEffect } from 'react'
import { DialogCNO } from '../../../library'
import { FormDesignPreview } from '../components';
import { fetchFormById } from '../../../actions/formBuilderActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AddDynamicFormsDataMode } from '../utils/enums';

const AddFormDataDialog = ({ open, onClose }) => {

  const { id: id } = useParams();

  const dispatch = useDispatch();
  const {
    formById,
    formByIdLoader,
    addDynamicFormsDataMode,
    addDynamicFormsDataInitialValues
  } = useSelector(state => state.formBuilder);

  const isAddMode = AddDynamicFormsDataMode.ids.Add === addDynamicFormsDataMode
  const isViewMode = AddDynamicFormsDataMode.ids.View === addDynamicFormsDataMode
  const isEditMode = AddDynamicFormsDataMode.ids.Edit === addDynamicFormsDataMode

  const modeDetails = AddDynamicFormsDataMode.getItem(addDynamicFormsDataMode);

  const modes = { isAddMode, isViewMode, isEditMode };

  useEffect(() => {

    const payload = {
      id
    }

    dispatch(fetchFormById(payload));

    return () => {
      // dispatch(setFormDesignById(null))
    }

  }, []);

  if (!open) return null;

  console.log("formByIdLoader: ", formByIdLoader)

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle={modeDetails.title}
      loading={formByIdLoader}
    >
      {
        !formByIdLoader ? <FormDesignPreview
          formDesignerValues={formById}
          onClose={onClose}
          formikValues={addDynamicFormsDataInitialValues}
          modes={modes}
        /> : 'Loading...'
      }

    </DialogCNO>
  )
}

export default AddFormDataDialog;