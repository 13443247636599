import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addInvoiceItem, toggleAddInvoiceItemDialog } from '../../actions/invoiceItemActions';
import DialogCNO from '../../library/DialogCNO';
import FormActionButtons from '../FormActionButtons';
import * as yup from 'yup';
import FormBox from '../FormBox/FormBox';
import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { SnapItem, SnapList, useDragToScroll } from 'react-snaplist-carousel';
import ChipCNO from '../../library/ChipCNO';
import { RateType, TaskStatus } from '../../utils';
import { Currency, CurrencyIcon, JobTargetType } from '../../utils/mappings';
import { mdiFormatText, mdiHours24, mdiLockClock } from '@mdi/js';
import InputCNO from '../../library/InputCNO';
import * as icons from '@mdi/js';
import { toast } from 'react-toastify';
import { getMaterialIconString } from '../../utils/icons';
import InputLabelCNO from '../../library/InputLabelCNO';
import { useDebouncedFormik, useFormikErrors } from '../../hooks';
import { validateStartDateEndDate } from '../../utils/Helpers';
import { AppConstants } from '../../utils/AppConstants';

function AddInvoiceItemDialog({
  invoice,
  mode = "addInvoice",
  onInvoiceItemSubmit
}) {

  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : null;

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  let localCategories = localStorage.getItem('categories');
  localCategories = localCategories ? JSON.parse(localCategories) : [];

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  const dispatch = useDispatch();
  const { isAddInvoiceItemDialog, addLoader } = useSelector(state => state.invoiceItems);
  const suggestions = useSelector(state => state.suggestions);


  const closeAddInvoiceItemDialog = () => {

    localStorage.removeItem('addInvoiceItem');

    dispatch(toggleAddInvoiceItemDialog(false));

  };

  const validationSchema = yup.object({
    categories: yup.array().min(1, 'Category is required').required("Categotry is required"),
    title: yup.string().required('Title is required'),
    description: yup.string(),
    startDate: yup.date().required('Start Date is required'),
    startTime: yup.date().required('Start Time is required'),
    endTime: yup.date()
      .required('End Date is required')
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      ),
    endDate: yup.date()
      .required('End Time is required')
      .test(
        'endDateTimeValidation',
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({ startDate, startTime, endDate, endTime });

          return isValid;
        }
      ),
    invoiceItemAssignments: yup.array(),
    rate: yup.string(),
    rateType: yup.number(),
    targetType: yup.number(),
    target: yup.string(),
    invoiceId: yup.string().required("Invoice is required for creating invoice item")
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      categories: [],
      status: TaskStatus.Empty,
      startDate: AppConstants.DEFAULT_START_DATE,
      endDate: AppConstants.DEFAULT_END_DATE,
      startTime: AppConstants.DEFAULT_START_TIME,
      endTime: AppConstants.DEFAULT_END_TIME,
      invoiceItemAssignments: [],
      rate: 0,
      rateType: RateType.Hourly,
      target: 0,
      targetType: JobTargetType.Hour,
      invoiceId: invoice ? invoice.id : "",
    },
    validationSchema,
    onSubmit: (values) => handleAddInvoiceItem(values)
  });

  useFormikErrors(formik);

  const handleAddInvoiceItem = (values) => {

    console.log("Add Invoice Item values: ", values);

    if (mode === "addInvoice" && onInvoiceItemSubmit) {
      closeAddInvoiceItemDialog();
      onInvoiceItemSubmit(values);
      return;
    }

    let invoiceItemAssignments = authUser ? [...values.invoiceItemAssignments, authUser] : values.invoiceItemAssignments;

    const payload = {
      ...values,
      invoiceId: invoice ? invoice.id : null,
      invoiceItemAssignments: invoiceItemAssignments.map(item => (
        {
          startDate: values.startDate,
          startTime: values.startTime,
          endDate: values.endDate,
          endTime: values.endTime,
          staffId: item.id,
          staffText: item.title || `${item.firstName} ${item.lastName}`,
          paymentToEmployeeStatus: 0,
          paidAmount: 0,
          rate: 0,
          rateType: 0,
        }
      )),
      hours: 0,
      taxRate: values.taxRate ? values.taxRate : 0,
      target: values.target ? values.target : 0,
      rate: values.rate ? values.rate : 0,
    };

    console.log("Add Invoice Item payload: ", payload);

    const onAddSuccess = () => closeAddInvoiceItemDialog();

    dispatch(addInvoiceItem(payload, false, onAddSuccess));

  };

  useEffect(() => {
    if (!invoice || !invoice.id) {
      toast.error("Invoice is required for creating invoice item");
    }
  }, [invoice]);

  useDebouncedFormik(formik, 'addInvoiceItem');

  return (
    <DialogCNO
      open={isAddInvoiceItemDialog}
      onClose={closeAddInvoiceItemDialog}
      dialogTitle={"Add Invoice Item"}
      bgColor={theme.colors.MODAL_BACKGROUND_COLOR}
      loading={addLoader}
    >
      <form onSubmit={formik.handleSubmit} className={styles.form}>

        <FormBox title={'Invoice Item Details'}>
          <div>
            <InputLabelCNO label={'Category'} isRequired={true} />
            <SnapList ref={snapList} className={GlobalStyles.chipsWrapper}>
              {
                localCategories.map((category) => (
                  <SnapItem>
                    <ChipCNO
                      icon={category.iconLink}
                      name={category.title}
                      isCategoryChip={true}
                      active={formik.values.categories[0] === (category.id)}
                      onClick={() => formik.setFieldValue('categories', [category.id])}
                    />
                  </SnapItem>
                ))
              }
            </SnapList>
          </div>

          <InputCNO
            name='title'
            label='Title'
            formik={formik}
            placeholder={'Invoice Item Title'}
            icon={mdiFormatText}
            fullWidth
            isRequired={true}
          />

          <InputCNO
            name='description'
            label='Description'
            formik={formik}
            placeholder={'Invoice Item Description'}
            fullWidth
            multiline
          />

        </FormBox>

        <FormBox title="Internal Details">

          <div>
            <InputLabelCNO label={'Types'} />
            <div className={GlobalStyles.chipsWrapper}>
              <ChipCNO
                icon={mdiLockClock}
                name="Fixed"
                size="1.5rem"
                active={formik.values.rateType === RateType.Fixed}
                onClick={() => formik.setFieldValue('rateType', RateType.Fixed)}
              />
              <ChipCNO
                icon={mdiHours24}
                name="Hourly"
                size="1.5rem"
                active={formik.values.rateType === RateType.Hourly}
                onClick={() => formik.setFieldValue('rateType', RateType.Hourly)}
              />
            </div>
          </div>
          <InputCNO
            name='rate'
            label='Rate'
            formik={formik}
            placeholder={'Enter Invoice Item Rate'}
            icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            fullWidth
            onChange={(e) => {

              const value = e.target.value;

              const rateValue = !isNaN(value) ? value : formik.values.rate;

              formik.setFieldValue('rate', value === "" ? "" : rateValue);

            }}
          />
        </FormBox>

        <FormActionButtons
          rightText={'Add Invoice Item'}
          formik={formik}
          leftClick={closeAddInvoiceItemDialog}
          disabled={!Boolean(formik.values.categories.length)}
        />
      </form>

    </DialogCNO>
  )
}

export default AddInvoiceItemDialog;

const useStyles = makeStyles((theme) => ({
  form: {
    '& > *': {
      marginBottom: theme.spacing(1),
      '&>*': {
        marginBottom: theme.spacing(2),
      }
    }
  }
}));