import React, { Fragment, useState } from 'react';
import { useStyles } from './SubscriptionDetailsStyles';
import { DataGrid } from '@material-ui/data-grid';
import {
  Button,
  Typography,
} from '@material-ui/core';
import Moment from 'react-moment';
import ConfirmationDialogCNO from '../../library/ConfirmationDialogCNO';
import { SubscriptionType } from '../../utils/SubscriptionPricingDetails';
import { Currency, CurrencyIcon, SubscriptionMode } from '../../utils/mappings';
import * as icons from '@mdi/js';
import Icon from '@mdi/react';
import { getMaterialIconString } from '../../utils/icons';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateCompanyDetails } from '../../actions/customerCompanyActions';
import { toast } from 'react-toastify';
import CancelIcon from '@material-ui/icons/Delete';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import { formatTimeWithTZ } from '../../utils/Helpers';
import { DATE_FORMAT } from '../../utils/formatDate';

function SubscriptionDetails({ profile }) {

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const styles = useStyles();
  const dispatch = useDispatch();

  const [isConfirmPayment, setIsConfirmPayment] = useState(false);
  const [isPayDialog, setIsPayDialog] = useState(false);
  const [activeRow, setActiveRow] = useState(null);

  const subscription = SubscriptionType[profile.subscriptionType];

  const isYearly = profile.paymentType === SubscriptionMode.Yearly ? true : false;
  let { pricingItems } = useSelector(state => state.subscription);
  pricingItems = pricingItems.map(item => ({
    ...item,
    price: isYearly ? item.price.yearly : item.price.monthly,
    strikedPrice: isYearly ? item.strikedPrice.yearly : item.strikedPrice.monthly,
    perUserPrice: isYearly ? item.perUserPrice.yearly : item.perUserPrice.monthly,
  }));
  const pricing = pricingItems.find(item => item.id === profile.subscriptionType);

  const authUserCompany = LocalStorageConstants.getItem('authUserCompany');
  const paymentDueDate = LocalStorageConstants.getItem(`paymentDue-${authUserCompany.id}`, {}) || new Date();

  const handleCancelSubscription = async (e) => {
    e.stopPropagation();
    setIsPayDialog(false);

    const dataToSend = {
      ...profile,
      paymentGatewayMeta: "CancelSubscription"
    };

    await Promise.resolve(dispatch(updateCompanyDetails(dataToSend)))
      .then(() => {
        toast.success('Subscription cancelled successfully!!');
      });
  };

  return (
    <div className={styles.container_PaymentDueDetails}>

      <div className={styles.paymentsDueTableWrapper}>

        <div className={styles.paymentsDueTableInfo}>

          {/* <div className={styles.rowCell}>
            <Typography className={styles.cellTop} style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
                size="1rem"
                style={{ marginRight: 4 }}
              />
              {pricing ? pricing.price : subscription.price}
            </Typography>
            <Typography className={styles.cellBottom}>Payment Due</Typography>
  </div>*/}

          <div className={styles.rowCell}>
            <Typography>
              <Moment format={DATE_FORMAT} className={styles.cellTop}>
                {formatTimeWithTZ(paymentDueDate.dueDate)}
              </Moment>
            </Typography>
            <Typography className={styles.cellBottom}>Due Date</Typography>
          </div>

          <div className={styles.rowCell}>
            <Typography className={styles.cellTop}>{pricing ? pricing.package_name : subscription.title}</Typography>
            <Typography className={styles.cellBottom}>Subscription</Typography>
          </div>

          <div className={styles.rowCell}>
            <Button
              variant='contained'
              startIcon={<CancelIcon />}
              onClick={() => {
                setIsConfirmPayment(true);
                setActiveRow({
                  amount: subscription.price
                });
              }}
              className={styles.inTableBtn}
            >
              Cancel
            </Button>
          </div>


          {
            isConfirmPayment &&
            <ConfirmationDialogCNO
              open={isConfirmPayment}
              onClose={() => setIsConfirmPayment(false)}
              dialogTitle="Confirm Cancel Subscription"
              messages={[
                "Are you sure you want to cancel subscription?"
              ]}
              yesClick={handleCancelSubscription}
            />
          }
        </div>

      </div>

    </div>
  )
}

export default SubscriptionDetails
