import { IconButton, makeStyles, Popover, useTheme } from '@material-ui/core';
import { mdiDotsVertical } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';

function MoreActionCNO({
  children,
  moreActionIcon = mdiDotsVertical,
  moreActionIconSize = '1.8rem',
  moreActionIconColor,
  anchorOrigin = {},
  transformOrigin = {},
  elevation,
}) {

  anchorOrigin = {
    vertical: 'top',
    horizontal: 'left',
    ...anchorOrigin
  };

  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
    ...transformOrigin
  };

  const styles = useStyles();
  const theme = useTheme();

  const [refMore, setRefMore] = useState(null);

  const openMore = (e) => {
    setRefMore(e.currentTarget);
  };

  const closeMore = () => {
    setRefMore(null);
  };
  const open = Boolean(refMore);

  return (
    <div>

      <IconButton
        size="small"
        onClick={openMore}
      >
        <Icon
          className={styles.moreIcon}
          path={moreActionIcon}
          size={moreActionIconSize}
          color={moreActionIconColor ? moreActionIconColor : theme.colors.MORE_ACTIONS_ICON_COLOR}
        />
      </IconButton>

      <Popover
        open={open}
        anchorEl={refMore}
        onClose={closeMore}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        elevation={(elevation === 0 || elevation) ? 0 : 8}
        PaperProps={{
          classes: {
            root: styles.paperRoot
          }
        }}
      >
        <div style={{ minWidth: 200 }}>
          {React.cloneElement(children, { closeMoreActions: closeMore })}
        </div>
      </Popover>
    </div>
  )
}

export default MoreActionCNO;

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    backgroundColor: 'transparent'
  }
}));