import { CircularProgress, Typography, useMediaQuery } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchServices,
  fetchMoreServices,
  setServiceSearchParams,
  toggleAddServiceDialog,
} from "../../actions/serviceActions";
import JobCardSkeleton from "../../components/JobCardSkeleton/JobCardSkeleton";
import ServiceListCard from "../../components/ServiceListCard/ServiceListCard";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import AddService from '../../components/Service/AddService';
import ServiceFilterBar from "./ServiceFilterBar";
import { getPrefillAssignedUser } from "../../utils/Helpers";
import RightsManager from "../../utils/RightsManager";
import InfiniteScrollCNO from "../../library/InfiniteScrollCNO";
import StickyWrapper from "../../library/StickyWrapper";
import { mdiClockCheckOutline } from "@mdi/js";
import Switcher from "../../common/components/GridListToggle/Switcher";

function ServiceListing({ timeFilterVisible = true, customer, page, setPage }) {

  const isUserMember = RightsManager.isMember();

  const GlobalStyles = useGlobalStyles();
  const [isGridView, setIsGridView] = useState(false);

  const dispatch = useDispatch();

  const {
    services,
    servicesLoader,
    deleteServiceLoader,
    addServiceLoader,
    editServiceLoader,
    hasMoreServices,
    isAddServiceDialog,
  } = useSelector((state) => state.services);

  console.log("==Services=======", services);
  console.log("==AddServiceDialog",isAddServiceDialog)

  const initialValues = {
    customer: null,
    categories: [],
    Service: null,
    startDate: null,
    endDate: null,
    assignments: isUserMember ? getPrefillAssignedUser() : null,
  };

  useEffect(() => {
    const payload = {
      assignedIds:
        isUserMember && getPrefillAssignedUser()
          ? [getPrefillAssignedUser().id]
          : null,
      customerIds: customer ? [customer.id] : null,
    };

    dispatch(fetchServices(payload));
  }, []);

  const loadMore = () => {
    console.log("=== hasMoreServices === : ", hasMoreServices);
    if (hasMoreServices) {
      const moreServicesPayload = {
        pageIndex: page + 1,
        orderBy: "newest",
        searchText: "",
        customerIds: customer ? [customer.id] : null,
      };

      dispatch(fetchMoreServices(moreServicesPayload));

      setPage(page + 1);
    }
  };

  console.log("Services: ", services);
  console.log("ServicesLoader: ", servicesLoader);

  const openAddServiceDialog = () => {
    dispatch(toggleAddServiceDialog(true));
  };

  return (
    <div className={GlobalStyles.listingContainer}>
      {(deleteServiceLoader || addServiceLoader || editServiceLoader) && (
        <FullScreenLoaderCNO />
      )}

      {timeFilterVisible && (
        <StickyWrapper>
          <ServiceFilterBar isGridView={isGridView} setIsGridView={setIsGridView}/>
        </StickyWrapper>
      )}
      

      <InfiniteScrollCNO
        items={services}
        next={loadMore}
        hasMore={hasMoreServices}
        loading={servicesLoader}
        renderListItem={(service) => (
          <ServiceListCard key={service.id} service={service} />
        )}
        notFoundTextMessage={"No Service Present"}
        notFoundIcon={mdiClockCheckOutline}
        showListCTAButtons={true}
        listCTAButtonsProps={{
          addTitle: "Add Service",
          exploreId: 4,
          onClickAdd: openAddServiceDialog,
        }}
        isGridView={isGridView}
        setIsGridView={setIsGridView}
      />

      {isAddServiceDialog && <AddService />}
    </div>
  );
}

export default ServiceListing;
