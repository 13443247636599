import { Dialog, Typography } from '@material-ui/core';
import React from 'react';
import HeadingBar from '../../components/HeadingBar/HeadingBar';
import { useStyles } from './ConfirmationDialogCNOStyles';
import FormActionButtons from '../../components/FormActionButtons';

function ConfirmationDialogCNO({
  open,
  onClose,
  dialogTitle,
  yesClick,
  noClick,
  messages,
  leftText = "No",
  rightText = "Yes",
  isLeftButton = true,
  disabled = false,
  closeMoreActions
}) {

  const styles = useStyles();

  const handleClose = (e) => {

    onClose(e);

    if (closeMoreActions) {
      closeMoreActions();
    }

  };

  return (
    <Dialog
      open={open}
      className={styles.container_DialogCNO}
      classes={{ paper: styles.paper }}
    >
      <HeadingBar title={dialogTitle} onClose={handleClose} />

      {
        messages.map((msg, index) => (
          msg
            ? <Typography key={index}>
              {msg}
            </Typography>
            : null
        ))
      }

      <FormActionButtons
        leftText={leftText}
        leftClick={(e) => {
          if (noClick) {
            noClick(e);
          }
          onClose(e);
        }}
        rightText={rightText}
        rightClick={(e) => { yesClick(e); onClose(e); }}
        isLeftButton={isLeftButton}
        disabled={disabled}
        closeMoreActions={closeMoreActions}
      />

    </Dialog>
  )
}

export default ConfirmationDialogCNO
