import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const MapComponent = ({ selectedLocation }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey:process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  if (loadError) return "Error";
  if (!isLoaded) return "Maps";
  console.log("selectedLocation",selectedLocation)
  return (
    <div style={{ marginTop: "50px" }}>
      <GoogleMap
        mapContainerStyle={{
          height: "800px",
        }}
        center={selectedLocation}
        zoom={13}
      >
        {selectedLocation && (
          <Marker position={selectedLocation} />
        )}
      </GoogleMap>
    </div>
  );
};

export default MapComponent;
