import { Box, Button, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { AddSectionDialog } from '../dialogs';
import { IconMain } from '../../../library';

function AddSectionButton({ onAddSection }) {

  const styles = useStyles();

  const [isAddSection, setIsAddSection] = useState(false);

  return (
    <Box className={styles.container}>
      <Button
        className={styles.button}
        variant='contained'
        onClick={() => setIsAddSection(true)}
        startIcon={<IconMain name='plus' size="1.1rem" color={'#ddd'} />}
      >
        Add Section
      </Button>

      <AddSectionDialog
        open={isAddSection}
        onClose={() => setIsAddSection(false)}
        onAddSection={onAddSection}
      />
    </Box>
  )
}

export default AddSectionButton;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  button: {
    backgroundColor: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR
  }
}))