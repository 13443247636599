import { NotificationType, NotificationFeatureGroup } from ".";
import { logout } from "../actions/authUserActions";
import { getCustomerById } from "../actions/customerActions";
import { getCompanyById } from "../actions/customerCompanyActions";
import { getEmployeeById } from "../actions/employeeActions";
import { deleteJobLocally, getTaskById, getTodo, setTodos } from "../actions/jobActions";
import { fetchCurrentSubscriptionDetails } from "../actions/subscriptionActions";
import LocalStorageConstants from "./LocalStorageConstants";
import RightsManager from "./RightsManager";

export const getNotificationText = (type, title) => {

  const NotificationText = {
    [NotificationType.Allocation.toString()]: `${title} task has been assigned to you.`,
    [NotificationType.TaskChanged.toString()]: `${title} task has been updated.`,
    [NotificationType.TaskDeleted.toString()]: `${title} task has been deleted.`,
    [NotificationType.TaskStartReminder.toString()]: `${title} task is scheduled for today.`,
    [NotificationType.PaymentProcessed.toString()]: `Payment for ${title} task has been processed.`,
    [NotificationType.TaskStateChanged.toString()]: `${title} task status has been updated.`,
    [NotificationType.TaskStartDelayed.toString()]: `${title} task has not started as per schedule.`,
    [NotificationType.TaskEndDelayed.toString()]: `${title} task is not finished as per schedule.`,
    [NotificationType.TaskCreated.toString()]: `${title} task has been created.`,
    [NotificationType.TaskMediaUpdated.toString()]: `Media uploaded in ${title} task.`,
    [NotificationType.SubTaskAllocation.toString()]: `${title} subtask has been assigned to you.`,
    [NotificationType.SubTaskChanged.toString()]: `${title} subtask has been updated.`,
    [NotificationType.SubTaskDeleted.toString()]: `${title} subtask has been deleted`,
    [NotificationType.SubTaskStartReminder.toString()]: `${title} subtask is scheduled for today.`,
    [NotificationType.SubPaymentProcessed.toString()]: ` Payment for ${title} subtask has been processed.`,
    [NotificationType.SubTaskStateChanged.toString()]: `${title} subtask status has been updated.`,
    [NotificationType.SubTaskStartDelayed.toString()]: `${title} subtask has not started as per schedule.`,
    [NotificationType.SubTaskEndDelayed.toString()]: `${title} subtask is not finished as per schedule.`,
    [NotificationType.SubTaskCreated.toString()]: `${title} subtask has been created.`,
    [NotificationType.SubTaskMediaUpdated.toString()]: `Media uploaded in ${title} subtask.`,
    [NotificationType.ProfileCreated.toString()]: `Hi ${title}`,
    [NotificationType.InvoiceSent.toString()]: `${title} invoice received.`,
    [NotificationType.InvoiceRejected.toString()]: `${title} invoice has been rejected.`,
    [NotificationType.InvoiceAccepted.toString()]: `${title} invoice has been accepted.`,
    [NotificationType.OfferSent.toString()]: `${title} quotation received.`,
    [NotificationType.OfferRejected.toString()]: `${title} quotation has been rejected.`,
    [NotificationType.OfferAccepted.toString()]: `${title} quotation has been accepted.`,
    [NotificationType.ProfileEdited.toString()]: `Your profile has been updated.`,
    [NotificationType.GroupEdited.toString()]: `Company has been updated.`,
    [NotificationType.GroupRemoved.toString()]: `Company has been removed.`,
    [NotificationType.GroupCategoriesChanged.toString()]: `Company categories has been updated.`,
    [NotificationType.GroupPaymentMethodChanged.toString()]: `Company payment has been updated.`,
    [NotificationType.AppointmentSent.toString()]: `${title} appointment received.`,
    [NotificationType.AppointmentRejected.toString()]: `${title} appointment has been rejected.`,
    [NotificationType.AppointmentAccepted.toString()]: `${title} appointment has been accepted.`,
  };

  const notificationText = NotificationText;

  console.log("===========notificationText====1111==NotificationText[type]======", type, notificationText, NotificationText[type]);

  return notificationText[type];

};

export const getIsNotificationClickable = (type) => {

  let isClickable = false;

  if ([
    NotificationType.Allocation,
    NotificationType.TaskChanged,
    NotificationType.TaskStartReminder,
    NotificationType.PaymentProcessed,
    NotificationType.PaymentProcessed,
    NotificationType.TaskStartDelayed,
    NotificationType.TaskEndDelayed,
    NotificationType.TaskCreated,
    NotificationType.TaskMediaUpdated,
    NotificationType.TaskStateChanged,

    NotificationType.SubTaskAllocation,
    NotificationType.SubTaskChanged,
    NotificationType.SubTaskDeleted,
    NotificationType.SubTaskStartReminder,
    NotificationType.SubPaymentProcessed,
    NotificationType.SubTaskStateChanged,
    NotificationType.SubTaskStartDelayed,
    NotificationType.SubTaskEndDelayed,
    NotificationType.SubTaskCreated,
    NotificationType.SubTaskMediaUpdated,

    NotificationType.InvoiceSent,
    NotificationType.InvoiceRejected,
    NotificationType.InvoiceAccepted,

    NotificationType.OfferSent,
    NotificationType.OfferRejected,
    NotificationType.OfferAccepted,

    NotificationType.AppointmentSent,
    NotificationType.AppointmentRejected,
    NotificationType.AppointmentAccepted,

  ].includes(type)
  ) {
    isClickable = true;
  }

  return isClickable;

};

export const getNotificationClickLink = (type, metaData) => {

  let link = null;

  switch (type) {
    // JOB DETAILS CASE
    case NotificationType.Allocation:
    case NotificationType.TaskChanged:
    case NotificationType.TaskCreated:
    case NotificationType.TaskEndDelayed:
    case NotificationType.TaskMediaUpdated:
    case NotificationType.TaskStartDelayed:
    case NotificationType.TaskStartReminder:
    case NotificationType.TaskStateChanged:
      link = `/job/${metaData}`;
      break;

    // SUBTASK DETAILS CASE
    case NotificationType.SubTaskAllocation:
    case NotificationType.SubTaskChanged:
    case NotificationType.SubTaskCreated:
    case NotificationType.SubTaskEndDelayed:
    case NotificationType.SubTaskMediaUpdated:
    case NotificationType.SubTaskStartDelayed:
    case NotificationType.SubTaskStartReminder:
    case NotificationType.SubTaskStateChanged:
      link = `/job/${metaData}`;
      break;

    // quotation DETAILS CASE
    case NotificationType.OfferSent:
    case NotificationType.OfferRejected:
    case NotificationType.OfferAccepted:
      link = `/quotation/${metaData}`;
      break;

    // Invoice DETAILS CASE
    case NotificationType.InvoiceSent:
    case NotificationType.InvoiceRejected:
    case NotificationType.InvoiceAccepted:
      link = `/invoice/${metaData}`;
      break;

    // Appointment DETAILS CASE
    case NotificationType.AppointmentSent:
    case NotificationType.AppointmentRejected:
    case NotificationType.AppointmentAccepted:
      link = `/appointment/${metaData}`;
      break;

    default:
      link = "";
      break;
  }

  return link;

};

export const handleProfileUpdateFromNotification = (notification, dispatch) => {

  const isUserAdmin = RightsManager.isAdmin();
  const isUserMember = RightsManager.isMember();
  const isUserCustomer = RightsManager.isCustomer();

  const authUser = LocalStorageConstants.getItem('authUser');

  const onFetchById = (profileData) => {

    console.log("handleProfileUpdateFromNotification==== profileData=====", profileData);

    profileData = {
      ...profileData,
      token: authUser.token,
    };
    localStorage.setItem('authUser', JSON.stringify(profileData));

  };

  if (isUserMember || !(authUser.id === notification.toMemberId && isUserAdmin)) {

    const ids = [authUser.id];
    dispatch(getEmployeeById(ids, false, authUser, onFetchById));

  }

  if (isUserCustomer) {

    const ids = [authUser.id];
    dispatch(getCustomerById(ids, false, authUser, onFetchById));

  }

};


export const handleCompanyUpdateFromNotification = (notification, dispatch) => {

  const isUserAdmin = RightsManager.isAdmin();
  const isUserMember = RightsManager.isMember();

  const authUserCompany = LocalStorageConstants.getItem('authUserCompany');

  if (isUserAdmin) {

  }

  const onFetchById = (companyData) => {

    console.log("handleCompanyUpdateFromNotification==== before companyData=====", companyData);
    console.log("handleCompanyUpdateFromNotification==== authUserCompany=====", authUserCompany);

    companyData = {
      ...authUserCompany,
      ...companyData
    };

    console.log("handleCompanyUpdateFromNotification==== after companyData=====", companyData);

    switch (notification.notificationType) {

      case NotificationType.GroupCategoriesChanged:
        localStorage.setItem('categories', JSON.stringify(companyData.categories));
        break;

      case NotificationType.GroupEdited:
        if (authUserCompany.subscriptionType !== companyData.subscriptionType) {
          console.log("handleCompanyUpdateFromNotification==== subscriptionType=====", authUserCompany.subscriptionType, companyData.subscriptionType);

          dispatch(fetchCurrentSubscriptionDetails(companyData.subscriptionType));

        }
        break;

      case NotificationType.GroupPaymentMethodChanged:

        break;

      case NotificationType.GroupRemoved:
        if (isUserMember) {
          dispatch(logout());
        }
        break;

      default:
        break;
    }

    localStorage.setItem('authUserCompany', JSON.stringify(companyData));

  };

  const ids = [authUserCompany.id];
  dispatch(getCompanyById(ids, false, onFetchById));

};

export const handleTaskUpdateFromNotification = (notification, dispatch) => {

  const taskId = notification.metaData;

  switch (notification.notificationType) {

    case NotificationType.Allocation:
    case NotificationType.TaskCreated:
    case NotificationType.TaskChanged:
      dispatch(getTodo({ ids: [taskId] }));
      break;

    case NotificationType.TaskDeleted:
      dispatch(deleteJobLocally(taskId));
      break;

    default:
      break;
  }

};

export const getNotificationsArray = (notificationGroup) => {

  let result = [];

  switch (notificationGroup) {
    case NotificationFeatureGroup.Company:
      result = [
        NotificationType.GroupCategoriesChanged,
        NotificationType.GroupEdited,
        NotificationType.GroupPaymentMethodChanged,
        NotificationType.GroupRemoved,
      ];
      break;

    default:
      break;
  }

  return result;

};