import React from 'react';
import Icon from '@mdi/react';
import { mdiCheckCircleOutline } from '@mdi/js';
import { Typography, useTheme } from '@material-ui/core';
import FormActionButtons from '../FormActionButtons';

function PaymentSuccess({ onOkay }) {

  const theme = useTheme();

  return (
    <div>
      <div
        style={{ textAlign: 'center', marginBottom: '1rem' }}
      >
        <Icon
          path={mdiCheckCircleOutline}
          color={theme.colors.GREEN_COLOR}
          size='4rem'
        />
      </div>

      <Typography align='center' style={{ fontSize: '1rem' }}>
        Payment done! Enjoy your subscription.
      </Typography>

      <FormActionButtons
        rightText='Okay'
        rightClick={onOkay}
        isLeftButton={false}
      />
    </div>
  )
}

export default PaymentSuccess