import { Typography } from '@material-ui/core'
import React, { Fragment } from 'react'
import { usePublicPagesStyles } from '../../../utils/Styles/PublicPages.style';

function ContactUsPara() {

  const styles = usePublicPagesStyles();

  return (
    <Fragment>
      <Typography className={styles.paraTitle}>
        Contact Us
      </Typography>

      <Typography className={styles.para}>
        Don't hesitate to contact us if you have any questions.
      </Typography>

      <Typography className={styles.para}>
        <a href="mailto:contact@checkndout.com" className={styles.linkAction}>-Via Email:  contact@checkndout.com</a>
        <br />
        <a href="tel:+447551576623" className={styles.linkAction}>-Via Phone Number:  +447551576623</a>
      </Typography>
    </Fragment>
  )
}

export default ContactUsPara