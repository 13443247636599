import React from 'react';
import AutocompleteCNO from '../../../../library/AutocompleteCNO';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppointmentSuggest, fetchCustomerSuggest, fetchInvoiceSuggest, fetchMemberSuggest, fetchQuotationSuggest, fetchTaskSuggest } from '../../../../actions/suggestionsActions';
import { AutoCompleteSuggestionTypes, FormFieldsTypes } from '../../utils/enums';
import { Box } from '@material-ui/core';
import FormFieldActions from '../FormFieldActions';

const getSuggestions = (type) => {
  const suggestionType = AutoCompleteSuggestionTypes.getItem(type);

  console.log("AutoCompleteFormRender: getSuggestions: type ", type);
  console.log("AutoCompleteFormRender: getSuggestions: suggestionType ", suggestionType);

  if (!suggestionType) return [];

  const title = suggestionType.label;


  const suggestions = [];

  for (let index = 0; index < 5; index++) {
    suggestions.push({
      id: index + 1,
      title: `${title} ${index + 1}`
    });
  }

  return suggestions;
}

function AutoCompleteFormRender({ field, formik, onEdit, onDelete, showFormFieldActions = false, disabled }) {

  const dispatch = useDispatch();
  const suggestions = useSelector((state) => state.suggestions);

  return (
    <>
      {
        field.type === FormFieldsTypes.ids.AutoComplete ?
          <Box display='flex' alignItems='flex-start' justifyContent='space-between'>
            <AutocompleteCNO
              name={field.name}
              label={field.label}
              formik={formik}
              placeholder={`Search ${field.label}`}
              data={getSuggestions(field.suggestionType || field?.attributes?.suggestionType)}
              // data={suggestions}
              renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
              // onSuggestionClick={(value) => {
              //   console.log("AutocompleteRender: ", value);
              //   formik.setFieldValue(field.name, value.id || null);
              // }}
              disabled={disabled}
            />

            {
              showFormFieldActions &&
              <FormFieldActions fieldInitialValues={field} onEdit={onEdit} onDelete={onDelete} />
            }

          </Box>
          : null
      }
    </>
  )
}

export default AutoCompleteFormRender;