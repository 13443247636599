import { toast } from "react-toastify";
import { v4 } from "uuid";
import { apiMedia, apiTaskSearch } from "../../api";
import { apiInitUpload } from "../../api/apiInitUpload";
import { getContentType, isArrayEmpty } from "../Helpers";
import { FileType, MediaSizeType, MediaType } from "../mappings";
import { apiUploadMedia } from "../../api/apiUploadMedia";

export const getJobMedias = async (taskId) => {

  let medias = [];

  console.log("Get Job Medias: taskId: ", taskId);

  try {
    const response = await apiTaskSearch.post({ ids: [taskId] });

    console.log("Get Job Medias: response: ", response);
    console.log("Get Job Medias: !isArrayEmpty(response.data): ", !isArrayEmpty(response.data));

    if (response && !isArrayEmpty(response.data.data)) {
      const task = response.data.data[0];

      console.log("Get Job Medias: task: ", task);

      medias = task.mediaLinks;
    }
  } catch (error) {
    console.log("Error: Get Job Medias: ", error);
  }

  console.log("Get Job Medias: medias: ", medias);

  return medias;

}

export const getResolvedJobMedias = async (medias, taskId) => {

  const mediaIds = medias.map(media => media.id);

  const dataToSend = {
    ids: mediaIds,
    size: MediaSizeType.BigScreen
  };

  let resolvedMedias = [];

  try {
    const response = await apiMedia.post(dataToSend);

    resolvedMedias = response.data.links;

    const resolvedMediasPromise = resolvedMedias.map(async (media, index) => {

      let fileType = null, contentType = null;

      try {
        // if ([1].includes(index)) {
        //   throw new Error("forcefully error upload");
        // } else {
        const resp = await getContentType(media.link);
        fileType = resp.fileType;
        contentType = resp.contentType;
        // }
      } catch (error) {
        toast.error("Media not found. Please delete and upload the media again.");
        console.log("Error: Resolve Job Medias: Get Content Type: ", error);
        return null;
      }

      const isImage = fileType === FileType.Image;
      return {
        ...media,
        uri: media.link,
        // thumbnailUri: allMedias[index].link,
        fileType: fileType,
        type: contentType,
        isResolved: true,
        isImage,
        taskId,
        newId: v4()
      };

    });

    resolvedMedias = await Promise.all(resolvedMediasPromise);

  } catch (error) {
    resolvedMedias = [];
    console.log("Error: Resolve Job Medias: ", error);
  }

  return resolvedMedias.filter(Boolean);

}

export const getAWSJobUploadSignedUrls = async (ids = [], failThisBatch) => {

  if (isArrayEmpty(ids)) return null;

  let awsUploadSignedUrls = [];

  try {
    const awsUploadSignedUrlsResp = await apiInitUpload.post({ ids: failThisBatch ? ["courrupe-guuid"] : ids });
    awsUploadSignedUrls = awsUploadSignedUrlsResp.data.links;
  } catch (error) {
    awsUploadSignedUrls = [];
    console.log("Error: Get AWS Job Media Signed URLs: ", error);
  }

  return awsUploadSignedUrls;

}

// TODO: group medias based on mediaType and then make server call accordingly
export const saveUploadedJobMediasToServer = async (medias, ids, taskId, mediaType = MediaType.Input) => {

  const prevMedias = await getJobMedias(taskId);

  const newMedias = medias.map((media, index) => ({
    id: ids[index],
    title: media.name,
    fileType: media.type,
    abstract: "",
    link: ids[index],
    mediaType: media.mediaType || mediaType,
    taskId: taskId
  }));

  const mediasToSave = [...newMedias, ...prevMedias];

  const dataToSend = {
    taskId: taskId,
    medias: mediasToSave
  };

  return await apiUploadMedia.post(dataToSend);

}

