import React from 'react';
import DialogCNO from '../library/DialogCNO';
import LinkCNO from '../library/LinkCNO';
import AppLogo from '../assets/appLogo.png';
import AppLogoBlack from '../assets/appLogoBlack.png';
import NameLogo from '../assets/nameLogo.png';
import NameLogoBlack from '../assets/nameLogoBlack.png';
import { makeStyles, Typography, useTheme } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiFacebook, mdiInstagram, mdiLinkedin, mdiYoutube } from '@mdi/js';
import { AppConstants } from '../utils/AppConstants';

function RegisterPromoModal({
  open,
  onClose,
}) {

  const theme = useTheme();

  const styles = useStyles();

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Free Trial"
      maxHeight={'90vh'}
      width="50%"
    >

      <div className={styles.appLogoWrapper}>
        <img style={{ width: 50, marginRight: 8 }} src={theme.palette.type !== 'dark' ? AppLogoBlack : AppLogo} alt="" />
        {
          theme.palette.type !== 'dark'
            ? <img
              src={NameLogoBlack}
              alt=""
              style={{ width: 150 }}
            />
            : <img
              src={NameLogo}
              alt=""
              style={{ width: 150 }}
            />
        }
      </div>

      <Typography variant='h5'>
        Become an EARLY BIRD and get a month FREE.
      </Typography>

      <Typography variant='h5' style={{ marginTop: 18 }}>
        With meFolia, managing your remote workforce is a piece of cake. The platform let to focus on your business & takes away the field staff tracking overhead.
      </Typography>


      <div>
        <Typography variant='h6' className={styles.followText}>
          Follow us for updates -
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LinkCNO to={AppConstants.SOCIAL_LINKS.linkedin} type="absolute" target="_blank" className={styles.iconWrapper} style={{ background: "#0e76a8", }}>
              <Icon
                path={mdiLinkedin}
                size={'1.5rem'}
                style={{ color: '#fff' }}
              />
            </LinkCNO>
            <LinkCNO to={AppConstants.SOCIAL_LINKS.youtube} type="absolute" target="_blank" className={styles.iconWrapper} style={{ background: "#b2071d", }}>
              <Icon
                path={mdiYoutube}
                size={'1.5rem'}
                style={{ color: '#fff' }}
              />
            </LinkCNO>
            <LinkCNO to={AppConstants.SOCIAL_LINKS.facebook} type="absolute" target="_blank" className={styles.iconWrapper} style={{ background: "#39569c", }}>
              <Icon
                path={mdiFacebook}
                size={'1.5rem'}
                style={{ color: '#fff' }}
              />
            </LinkCNO>
            <LinkCNO to={AppConstants.SOCIAL_LINKS.instagram} type="absolute" target="_blank" className={styles.iconWrapper} style={{ background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)" }}>
              <Icon
                path={mdiInstagram}
                size={'1.5rem'}
                style={{ color: '#fff' }}
              />
            </LinkCNO>
          </div>
        </Typography>

      </div>

    </DialogCNO>
  )
}

export default RegisterPromoModal

const useStyles = makeStyles((theme) => ({

  appLogoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24
  },

  followText: {
    marginTop: 18,
    display: 'flex',
    alignItems: 'center'
  },

  iconWrapper: {
    marginLeft: 8,
    background: "#0e76a8",
    borderRadius: '50%',
    width: '2rem',
    height: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

}));