import { Button, Popover, Typography, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { mdiDotsVertical } from "@mdi/js";
import Icon from "@mdi/react";
import LinkCNO from "../../library/LinkCNO";
import TestMorePopover from "../TestMorePopover/TestMorePopover";
import { useStyles } from "./TestCardStyles";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import LinesEllipsis from "react-lines-ellipsis";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { formatTimeWithTZ, getCategory } from "../../utils/Helpers";
import TestTypeIcon from "../TestTypeIcon/TestTypeIcon";
import TestFormDialog from "../TestFormDialog";


const ResponsiveLines = responsiveHOC()(LinesEllipsis);

const TestCard = ({ item }) => {
  const [refMore, setRefMore] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState(null);
  const isTestPath = location.pathname.includes("/test");

  const handleSelectTest = (testId) => {
    setSelectedTestId(testId);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleSaveForm = (testId, formData) => {
    const storedData = JSON.parse(localStorage.getItem('allTests') || '{}');
    if (!storedData[testId]) {
      storedData[testId] = { users: [], nextId: 1 };
    }
    const testData = storedData[testId];
    formData.id = testData.nextId;
    testData.users.push(formData);
    testData.nextId += 1;

    localStorage.setItem('allTests', JSON.stringify(storedData));
  };

  const openMore = (e) => {
    setRefMore(e.currentTarget);
  };

  const closeMore = () => {
    setRefMore(null);
  };
  const open = Boolean(refMore);

  const NativePopover = () => (
    <Popover
      open={open}
      anchorEl={refMore}
      onClose={closeMore}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <TestMorePopover item={item} onClose={closeMore} />
    </Popover>
  );

  const theme = useTheme();
  const styles = useStyles();

  const {
    testId,
    categoryId,
    title,
    description,
    duration_minutes,
  } = item;
  const jobCategory = getCategory(categoryId[0]);

  return (
    <div className={styles.container}>
      <div className={styles.jobMeta}>
        <TestTypeIcon
          iconLink={jobCategory?.iconLink}
          name={title}
          categoryId={categoryId}
        />
        <div className={styles.meta}>
          { !isTestPath && (<LinkCNO to={`/testq/${testId}`}>
            <Typography className={styles.title}>{title}</Typography>
            <Typography className={styles.date}>
              Time Duration: {duration_minutes} min
            </Typography>
          </LinkCNO> 
          )}

          { isTestPath && (
            <>
            <Typography className={styles.title}>{title}</Typography>
            <Typography className={styles.date}>
              Time Duration: {duration_minutes} min
            </Typography>  
          </>
          )
          }

        </div>

        <div
          style={{ display: "flex", alignItems: "center", height: "1.8rem" }}
        >
          { !isTestPath && (
          <Icon
            className={styles.moreIcon}
            path={mdiDotsVertical}
            size="1.8rem"
            color={theme.colors.MORE_ACTIONS_ICON_COLOR}
            onClick={openMore}
          /> )}
          <NativePopover />
        </div>
      </div>
      { !isTestPath &&(
      <LinkCNO to={`/testq/${testId}`}>
        <pre className={styles.jobDescription}>
          <ResponsiveLines
            text={description || "Test details not added"}
            maxLine="10"
            ellipsis="..."
            trimRight
            basedOn="words"
          /> 
        </pre>
      </LinkCNO> 
    )}

      { isTestPath &&(
     <>
        <pre className={styles.jobDescription}>
          <ResponsiveLines
            text={description || "Test details not added"}
            maxLine="10"
            ellipsis="..."
            trimRight
            basedOn="words"
          />
        </pre>
        { isTestPath && (
           <div>
           <button onClick={() => handleSelectTest(item.testId)} className={styles.btn}>Start Now</button>   
         </div>  
        )}
      </> 
    )}
      <TestFormDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onSave={handleSaveForm}
        testId={selectedTestId}
        />

    </div>
  );
};

export default TestCard;
