import {
  SET_SUBTASKS,
  SET_SUBTASK_BY_ID,
  TOGGLE_ADD_SUBTASK_DIALOG,
  SET_SUBTASK_LOADERS,
  SET_IS_NEW_SUBTASK_ADDED,
} from '../actions/subTaskActions';
import { isUserInAssignemts } from '../utils/Helpers';
import LocalStorageConstants from '../utils/LocalStorageConstants';
import RightsManager from '../utils/RightsManager';

const initialState = {
  subTasks: [],
  subTaskById: null,
  isAddSubTaskDialog: false,
  subTasksLoader: false,
  subTaskDetailLoader: false,
  addLoader: false,
  editLoader: false,
  deleteLoader: false,
  isNewSubTaskAdded: false
};

export function subTaskReducer(state = initialState, { type, payload }) {

  const authUser = LocalStorageConstants.getItem('authUser');

  const isUserMember = RightsManager.isMember();

  console.log("Action: ", type);

  switch (type) {

    case SET_IS_NEW_SUBTASK_ADDED:
      state = {
        ...state,
        isNewSubTaskAdded: payload
      };
      return state;

    case SET_SUBTASKS:
      payload = payload.filter(subTask => isUserMember ? isUserInAssignemts(subTask.subTaskAssignments, authUser) : true);
      state = {
        ...state,
        subTasks: payload
      };
      return state;

    case SET_SUBTASK_BY_ID:
      state = {
        ...state,
        subTaskById: payload
      };
      return state;

    case TOGGLE_ADD_SUBTASK_DIALOG:
      state = {
        ...state,
        isAddSubTaskDialog: payload
      };
      return state;

    case SET_SUBTASK_LOADERS:

      const { loaderName, loaderValue } = payload;

      state = {
        ...state,
        [loaderName]: loaderValue
      };
      return state;

    default:
      return state;
  }

}