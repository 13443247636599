import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchServiceProviders,
  fetchMoreServiceProviders,
} from "../../actions/serviceProviderActions";
import AddServiceProvider from "../../components/AddServiceProvider";
import ProfileListings from "../../components/ProfileListings/ProfileListings";
import ScreenView from "../../components/ScreenView/ScreenView";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { ProfileMode } from "../../utils/mappings";
import ServiceProviderFilterModal from "./ServiceProviderFilterModal";

function ServiceProviderListPage() {
  const styles = useStyles();

  const dispatch = useDispatch();
  const {
    serviceProviders,
    allServiceProvidersLoader,
    hasMoreServiceProviders,
    isAddServiceProviderDialog,
  } = useSelector((state) => state.serviceProviders);
  const { serviceProvidersLoader, addLoader, deleteLoader } = allServiceProvidersLoader;

  const suggestions = useSelector((state) => state.suggestions);

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (serviceProviders.length <= 0) {
      const serviceProvidersPayload = {
        pageIndex: 0,
        orderBy: "newest",
        searchText: "",
        SortDecending: true,
      };

      dispatch(fetchServiceProviders(serviceProvidersPayload));
    }
  }, []);

  const loadMore = () => {
    if (hasMoreServiceProviders) {
      const moreServiceProvidersPayload = {
        pageIndex: page + 1,
        orderBy: "newest",
        searchText: "",
      };

      dispatch(fetchMoreServiceProviders(moreServiceProvidersPayload));

      setPage(page + 1);
    }
  };

  return (
    <div className={styles.container_ServiceProviderListPage}>
      {(deleteLoader || addLoader) && <FullScreenLoaderCNO />}
      <ScreenView
        content={
          <ProfileListings
            mode={ProfileMode.ServiceProvider}
            profileList={serviceProviders}
            // profileList={suggestions.length ? suggestions : ServiceProviders}
            profileLoading={serviceProvidersLoader}
            loadMore={loadMore}
            hasMore={hasMoreServiceProviders}
          />
        }
        secondarySidebar={<ServiceProviderFilterModal setPage={setPage} />}
        headerTitle="ServiceProviders"
      />

      {isAddServiceProviderDialog && <AddServiceProvider />}
    </div>
  );
}

export default ServiceProviderListPage;

const useStyles = makeStyles((theme) => ({
  container_ServiceProviderManagementPage: {
    background: theme.colors.APP_BACKGROUND_COLOR,
  },
}));
