import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {
  mdiAccountDetails,
  mdiDelete,
  mdiSquareEditOutline,
  mdiEyeOffOutline,
  mdiEyeRefresh
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import LinkCNO from "../../library/LinkCNO";
import RightsManager from "../../utils/RightsManager";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import EditPostDialog from "../../pages/PostsManagement/components/EditPostDialog";
import { useDispatch, useSelector } from "react-redux";
import ConfirmYesNoDialog from "../ConfirmYesNoDialog/ConfirmYesNoDialog";
import { hidePost, unhidePost } from "../../actions/postActions";
import { toggleEditPostDialog } from "../../actions/postActions";
import { AddPostDialog } from "../../pages/PostsManagement/components";

function MorePostPopover({ item, closeMore }) {
  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : authUser;

  const { showHiddenPosts } = useSelector((state) => state.posts);
  console.log("MorepostPopiver", showHiddenPosts);
  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();
  const isUserAdmin = RightsManager.isAdmin();
  const isPostDetails = useLocation().pathname.includes("/post/");

  const [open, setOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openYesNoDialog, setOpenYesNoDialog] = useState(false);

  const dispatch = useDispatch();
  const { isEditPostDialog } = useSelector((state) => state.posts);

  useEffect(() => {
    console.log("thirst", openEditDialog);
  }, [openEditDialog]);

  const openEditDialogHandler = () => {
    dispatch(toggleEditPostDialog(true));
  };

  return (
    <>
      {open && (
        <ConfirmDialog
          open={open}
          setOpen={setOpen}
          message={`${item?.description.slice(0, 50)}`}
          item={item}
          closeMore={closeMore}
          isPost={true}
        />
      )}

      {isEditPostDialog && (
        <AddPostDialog
          closeMorePopover={() => {
            closeMore();
          }}
          postId={item.id}
        />
      )}

      {openYesNoDialog && (
        <ConfirmYesNoDialog
          open={openYesNoDialog}
          setOpen={() => setOpenYesNoDialog(false)}
          message={
            showHiddenPosts
              ? "Do you want to unhide this post ?"
              : "Are you sure to hide this post ?"
          }
          onConfirm={() => {
            showHiddenPosts
              ? dispatch(unhidePost({ id: item.id }))
              : dispatch(hidePost({ id: item.id }));
              closeMore()
          }}
          onCancel={() => setOpenYesNoDialog(false)}
        />
      )}

      <div className={styles.container_MorePopover}>
        <List className={styles.list}>
          {!isPostDetails && (
            <LinkCNO to={`/post/${item.id}`}>
              <ListItem className={GlobalStyles.listItem}>
                <ListItemText primary="Post Details " />
                <ListItemIcon>
                  <IconButton edge="end">
                    <Icon
                      path={mdiAccountDetails}
                      size="1.5rem"
                      color={
                        theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR
                      }
                    />
                  </IconButton>
                </ListItemIcon>
              </ListItem>
            </LinkCNO>
          )}
          {isUserAdmin && authUser.id === item.createdBy.id && (
            <ListItem
              className={GlobalStyles.listItem}
              onClick={() => {
                setOpen(true);
              }}
            >
              <ListItemText primary="Delete Post " />
              <ListItemIcon>
                <IconButton edge="end">
                  <Icon
                    path={mdiDelete}
                    size="1.5rem"
                    color={theme.colors.ERROR_COLOR}
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          )}

          {isUserAdmin && authUser.id === item.createdBy.id && (
            <ListItem
              className={GlobalStyles.listItem}
              onClick={openEditDialogHandler}
            >
              <ListItemText
                className={GlobalStyles.listItemText}
                primary="Edit Post "
              />
              <ListItemIcon>
                <IconButton edge="end">
                  <Icon
                    path={mdiSquareEditOutline}
                    size="1.5rem"
                    color={
                      theme.colors.MORE_ACTIONS_SHARE_JOB_OPTION_ICON_COLOR
                    }
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          )}

          {isUserAdmin && authUser.id === item.createdBy.id && (
            <ListItem
              className={GlobalStyles.listItem}
              onClick={() => setOpenYesNoDialog(true)}
            >
              <ListItemText
                className={styles.listItemText}
                primary={showHiddenPosts ? "Unhide Post" : "Hide Post"}
              />
              <ListItemIcon>
                <IconButton edge="end">
                  <Icon
                    path={showHiddenPosts ? mdiEyeRefresh :mdiEyeOffOutline }
                    size="1.5rem"
                    color={
                      theme.colors.MORE_ACTIONS_SHARE_JOB_OPTION_ICON_COLOR
                    }
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          )}
        </List>
      </div>
    </>
  );
}

export default MorePostPopover;

const useStyles = makeStyles((theme) => ({
  container_MorePopover: {
    padding: 5,
    overflow: "hidden",
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR,
  },
  list: {
    background: theme.colors.MORE_ACTIONS_OPTIONS_WRAPPER_BACKGROUND_COLOR,
    // background: theme.palette.background.dark,
    padding: 0,
  },
  listItem: {
    background:
      theme.colors.MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 2,
    // width: 200,
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.01)",
    },
  },
  listItemText: {
    textDecoration: "none",
  },
}));
