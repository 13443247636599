import moment from "moment";
import { generateRandomColor } from "./Helpers";
import { combineDateAndTime } from "./Tracking";
import { AppointmentType, LeaveStatus } from "./mappings";
import { AppsOutlined } from "@material-ui/icons";

export const getAppointmentEventsList = (appointments) => {

  const eventsList = appointments.map(appointment => {

    const combinedStart = combineDateAndTime(appointment.startDate, appointment.startTime);
    const combinedEnd = combineDateAndTime(appointment.endDate, appointment.endTime);

    const event = {
      id: appointment.id,
      title: appointment.title,
      description: appointment.description,
      categories: appointment.categories,
      allDay: false,
      start: moment(combinedStart).toDate(),
      end: moment(combinedEnd).toDate(),
      startDate: appointment.startDate,
      startTime: appointment.startTime,
      endDate: appointment.endDate,
      endTime: appointment.endTime,
      mapAddress: appointment.mapAddress,
      lat: appointment.lat,
      lng: appointment.lng,
      hexColor: generateRandomColor(),
    };

    return event;

  });

  return eventsList;

};

export const getJobEventsList = (jobs) => {

  const eventsList = jobs.map(job => {

    const combinedStart = combineDateAndTime(job.startDate, job.startTime);
    const combinedEnd = combineDateAndTime(job.endDate, job.endTime);

    const event = {
      id: job.id,
      title: job.title,
      description: job.description,
      categories: job.categories,
      allDay: false,
      start: moment(combinedStart).toDate(),
      end: moment(combinedEnd).toDate(),
      startDate: job.startDate,
      startTime: job.startTime,
      endDate: job.endDate,
      endTime: job.endTime,
      mapAddress: job.mapAddress,
      lat: job.lat,
      lng: job.lng,
      hexColor: generateRandomColor(),
    };

    return event;

  });

  return eventsList;

};

export const getLeaveEventsList=(appointments)=>{
  // DISPLAY ONLY APPROVED LEAVES
  // const eventsList = appointments.filter(appointment => appointment.appointmentType && appointment.appointmentType === AppointmentType.LEAVE && appointment.status === LeaveStatus.ACCEPTED).map(appointment => {
    const eventsList = appointments.filter(appointment => appointment.appointmentType && appointment.appointmentType === AppointmentType.LEAVE).map(appointment => {
  
    const combinedStart = combineDateAndTime(appointment.startDate, appointment.startTime);
    const combinedEnd = combineDateAndTime(appointment.endDate, appointment.endTime);

    const event = {
      id: appointment.id,
      leaveType: appointment.leaveType,
      description: appointment.description,
      categories: appointment.categories,
      duration:appointment.duration,
      status: appointment.status,
      allDay: false,
      reportingManager: appointment.reportingManager,
      approvalMessage: appointment.approvalMessage,
      rejectionMessage: appointment.rejectionMessage,
      start: moment(combinedStart).toDate(),
      end: moment(combinedEnd).toDate(),
      startDate: appointment.startDate,
      startTime: appointment.startTime,
      endDate: appointment.endDate,
      endTime: appointment.endTime,
      employee:appointment.employee,
      hexColor: generateRandomColor(),
    };

    return event;
  });
  console.log("EventlistFromLeaves",eventsList)
  return eventsList;
};

export const getHolidayEventsList=(appointments)=>{
  const eventsList = appointments.filter(appointment => appointment.appointmentType && appointment.appointmentType === AppointmentType.HOLIDAY).map(appointment => {

    const combinedStart = combineDateAndTime(appointment.startDate, appointment.startTime);
    const combinedEnd = combineDateAndTime(appointment.endDate, appointment.endTime);

    const event = {
      id: appointment.id,
      holidayType: appointment.holidayType,
      holidayName:appointment.holidayName,
      description: appointment.description,
      categories: appointment.categories,
      duration:appointment.duration,
      allDay: true,
      start: moment(combinedStart).toDate(),
      end: moment(combinedEnd).toDate(),
      startDate: appointment.startDate,
      startTime: appointment.startTime,
      endDate: appointment.endDate,
      endTime: appointment.endTime,
      hexColor: generateRandomColor(),
    };
    return event;
  });

  return eventsList;
};
