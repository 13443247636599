import LocalStorageConstants from "../utils/LocalStorageConstants"

export const DefaultCustomThemeColors = {

  DARK_COLOR_SHADE_1: "#0E0E0E",
  DARK_COLOR_SHADE_2: "#1E1E1E",
  DARK_COLOR_SHADE_3: "#2E2E2E",

  LIGHT_COLOR_SHADE_1: "#FEFEFE",
  LIGHT_COLOR_SHADE_2: "#FBF7EE",
  LIGHT_COLOR_SHADE_3: "#D4DDE2",

  BACKGROUND_COLOR_DARK: '#fefefe',
  BACKGROUND_COLOR_LIGHT: '#cecece',
  BACKGROUND_COLOR_LIGHTER: '#FBF7EE',
  COLOR_DARK: "#2e2e2e",
  COLOR_DARKER: '#4E4E4E',
  COLOR_LIGHT: "#6E6E6E",
  PARA_COLOR: "#1e1e1e",

  NOTIFICATION_READ_BACKGROUND_COLOR: '#FEFEFE',

  FINISHED_SWITCH_COLOR: '#d9d9d9',
  FINISHED_SWITCH_CHECKED_COLOR: '#d9d9d9',
  FINISHED_SWITCH_CHECKED_TRACK_COLOR: '#3e3e3e',

  APP_BACKGROUND_COLOR: '#EEEAE7',

  BORDER_COLOR: '#cecece',
  AVATARCNO_BORDER_COLOR: '#ffa703',
  ERROR_COLOR: '#cf143c',
  GREEN_COLOR: '#5ba668',
  PRIMARY_COLOR: '#1E1E1E',
  TRANSPARENT_COLOR: 'transparent',

  HAMBURGER_MENU_ICON_COLOR: '#1e1e1e',

  SCROLL_TO_BOTTOM_ICON_COLOR: '#b44cf5',

  LINK_COLOR: '#307ecc',

  LINK_COLOR_GREY: "#5E5E5E",

  DOCUMENT_CARD_BORDER_COLOR: "#AEAEAE",

  META_TEXT_COLOR: "#5e5e5e",

  DRAWER_PROFILE_PIC_BACKGROUND_COLOR: '#aeaeae',
  DRAWER_PROFILE_PIC_TEXT_COLOR: '#1e1e1e',
  DRAWER_PROFILE_USERNAME_COLOR: '#1e1e1e',
  DRAWER_PROFILE_HEADER_LINE_COLOR: '#4e4e4e',

  DRAWER_ACTIVE_ITEM_BACKGROUND_COLOR: '#7e7e7e',
  DRAWER_ITEM_TEXT_COLOR: '#2e2e2e',
  DRAWER_ITEM_ICON_COLOR: '#4e4e4e',
  DRAWER_ITEM_SEPARATOR_COLOR: '#cecece',

  HOME_FILTER_SEARCH_DIVIDER_COLOR: 'grey',
  FILTER_BAR_BACKGROUND_COLOR: '#FBF7EF',

  HEADER_COLOR: "#9e9e9e",
  HEADER_BOTTOM_COLOR: "#9e9e9e",
  HEADER_TITLE_COLOR: '#1E1E1E',
  HEADER_BACKGROUND_COLOR: '#FBF7EE',
  HEADER_BACK_ICON_COLOR: '#1e1e1e',
  HEADER_ADD_TODO_ICON_COLOR: '#039668',
  HEADER_TRACK_MAP_ICON_COLOR: '#436a93',
  HEADER_CHAT_ICON_COLOR: '#ce6e91',
  HEADER_NOTIFICATION_ICON_COLOR: '#49b679',
  HEADER_USER_ICON_COLOR: '#00e1ff',
  HEADER_MORE_ACTIONS_ICON_COLOR: '#303f9f',
  HEADER_CALL_ICON_COLOR: '#f57e42',

  SIDEBAR_BACKGROUND_COLOR: "#FBF7EE",

  EDIT_MAP_ICON_COLOR: 'orange',

  HEADER_CHECKIN_BUTTON_BACKGROUND_COLOR: '#3F8A02',
  HEADER_CHECKIN_BUTTON_BORDER_COLOR: '#fcb103',
  HEADER_CHECKIN_BUTTON_TEXT_COLOR: '#FEFEFE',
  HEADER_CHECKIN_BUTTON_SHADOW_COLOR: '#FEFEFE',

  HEADER_CHECKOUT_BUTTON_BACKGROUND_COLOR: '#BE0A02',
  HEADER_CHECKOUT_BUTTON_BORDER_COLOR: '#fcb103',
  HEADER_CHECKOUT_BUTTON_TEXT_COLOR: '#FEFEFE',
  HEADER_CHECKOUT_BUTTON_SHADOW_COLOR: '#FEFEFE',

  HOME_SEARCH_SCROLL_BACKGROUND_COLOR: '#E7ECEF',
  HOME_SEARCH_SCROLL_SEPARATOR_COLOR: '#9E9E9E',
  HOME_SEARCH_SCROLL_ICON_COLOR: '#436a93',

  CHIP_BUTTON_BACKGROUND_COLOR: '#F3E8CE',
  CHIP_BUTTON_SHADOW_COLOR: '#e7d29c',
  CHIP_BUTTON_TEXT_COLOR: '#3e3e3e',
  CHIP_BUTTON_ICON_COLOR: '#5e5e5e',
  CHIP_BUTTON_SELECTED_TEXT_COLOR: '#4e4e4e',
  CHIP_BUTTON_SELECTED_BACKGROUND_COLOR: '#D7B45B',
  // CHIP_BUTTON_SELECTED_BACKGROUND_COLOR: '#788f01',
  CHIP_BUTTON_DELETE_ICON_BACKGROUND_COLOR: '#4e4e4e',
  CHIP_BUTTON_DELETE_ICON_COLOR: '#cecece',

  SKELETON_BACKGROUND_COLOR: '#AEAEAE',
  SKELETON_HIGLIGHT_COLOR: '#9E9E9E',
  SKELETON_VIEW_BACKGROUND_COLOR: '#CECECE',
  SKELETON_VIEW_BORDER_COLOR: '#CECECE',

  MORE_ACTIONS_ICON_COLOR: '#3e3e3e',

  MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR: '#F3E8CD',
  MORE_ACTIONS_OPTIONS_WRAPPER_BACKGROUND_COLOR: '#F3E8CE',
  MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR: '#fefefe',
  MORE_ACTIONS_OPTION_TOUCH_UNDERLAY_COLOR: 'red',
  MORE_ACTIONS_OPTION_TEXT_COLOR: '#1e1e1e',
  MORE_ACTIONS_OPTION_ICON_COLOR: '#CFA23A',

  MORE_ACTIONS_DELETE_OPTION_ICON_COLOR: '#CFA23A',
  MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR: '#CFA23A',
  MORE_ACTIONS_SHARE_JOB_OPTION_ICON_COLOR: '#CFA23A',
  MORE_ACTIONS_WORK_SUMMARY_OPTION_ICON_COLOR: '#CFA23A',
  MORE_ACTIONS_GENERATE_INVOICE_OPTION_ICON_COLOR: '#CFA23A',
  MORE_ACTIONS_ENABLE_CUSTOMER_CHAT_ICON_COLOR: '#CFA23A',
  MORE_ACTIONS_ENABLE_TEAM_CHAT_COLOR: '#CFA23A',

  MODAL_BACKGROUND_COLOR: '#EEEAE7',
  MODAL_BORDER_COLOR: '#fefefe',
  MODAL__SHADOW_COLOR: '#fefefe',
  MODAL_HEADER_DIVIDER_COLOR: '#9e9e9e',
  MODAL_HEADER_TITLE_COLOR: '#6E6E6E',
  MODAL_CLOSE_ICON_BACKGROUND_COLOR: '#cf543c',
  MODAL_CLOSE_ICON_COLOR: '#FEFEFE',

  JOB_START_DATE_COLOR: '#cf543c',
  JOB_END_DATE_COLOR: '#5ba668',

  DIALOG_BACKGROUND_COLOR: '#EEEAE7',
  DIALOG_TITLE_COLOR: '#2e2e2e',

  PAGE_BACKGROUND_COLOR: '#fefefe',

  LIST_CARD_BACKGROUND_COLOR: '#fefefe',
  LIST_CARD_BORDER_COLOR: '#F3E8CE',
  LIST_CARD_SHADOW_COLOR: '#2E2E2E',
  LIST_CARD_TITLE_COLOR: '#2e2e2e',
  LIST_CARD_DATE_COLOR: '#2e2e2e',
  LIST_CARD_USERNAMES_COLOR: '#3e3e3e',
  LIST_CARD_PARA_COLOR: '#1e1e1e',
  LIST_CARD_PARA_BACKGROUND_COLOR: '#FBF7EE',
  LIST_CARD_INFO_TEXT_COLOR: '#3e3e3e',
  LIST_CARD_ICON_COLOR: '#6E6E6E',

  LIST_DIVIDER_COLOR: '#FBF7EE',

  USER_SUGGESTION_CARD_BACKGROUND_COLOR: 'transparent',
  USER_SUGGESTION_CARD_BORDER_COLOR: '#DCDCDC',
  USER_SUGGESTION_CARD_TITLE_COLOR: '#014663',
  USER_SUGGESTION_CARD_IMAGE_COLOR: '#014663',
  USER_SUGGESTION_CARD_TEXT_COLOR: '#666',

  JOB_DETAIL_BACKGROUND_COLOR: '#EEEAE7',
  JOB_DETAIL_CARD_BACKGROUND_COLOR: '#fefefe',
  JOB_DETAIL_TITLE_COLOR: '#272932',
  JOB_DETAIL_DESCRIPTION_COLOR: '#3e3e3e',
  JOB_DETAIL_CARD_TITLE_COLOR: '#5e5e5e',
  JOB_DETAIL_CUSTOMER_TEXT_COLOR: '#A89561',
  JOB_DETAIL_CUSTOMER_ICON_COLOR: '#039668',
  JOB_DETAIL_ASSIGN_USER_COLOR: '#4e4e4e',
  JOB_DETAIL_ASSIGN_USER_ICON_COLOR: '#9B6E00',
  JOB_DETAIL_LOCATION_COLOR: '#4e4e4e',
  JOB_DETAIL_CURRENCY_ICON_COLOR: '#4e4e4e',
  JOB_DETAIL_RATE_COLOR: '#4e4e4e',
  JOB_DETAIL_SECTION_TEXT_COLOR: "#4e4e4e",

  FORM_SECTION_BACKGROUND_COLOR: '#FEFEFE',

  FORM_LABEL_COLOR: '#6E6E6E',
  INPUT_BORDER_COLOR: '#E7ECEF',
  INPUT_BACKGROUND_COLOR: '#FEFEFE',
  INPUT_DISABLED_BACKGROUND_COLOR: '#bebebe',
  INPUT_ICON_COLOR: '#9e9e9e',
  INPUT_TEXT_COLOR: '#444',
  HELPER_TEXT_COLOR: "#9e9e9e",

  TEXT_SEARCH_INPUT_ITEM_BORDER_COLOR: '#7E7E7E',
  TEXT_SEARCH_INPUT_NAME_COLOR: '#F36900',
  TEXT_SEARCH_INPUT_NAME_BACKGROUND_COLOR: '#79a8d7',

  SELECTED_USER_CHIPS_VIEW_BACKGROUND_COLOR: '#d4dde2',

  GOOGLE_PLACES_INPUT_ICON_COLOR: 'orange',

  IMAGE_CAMERA_ICON_COLOR: '#436a93',
  IMAGE_UPLOAD_ICON_COLOR: '#BC8034',

  GET_DIRECTION_ICON_COLOR: '#436a93',

  INFO_POP_ICON_COLOR: '#aeaeae',

  JOB_TYPE_ICON_COLOR: '#CFA23A',
  JOB_TYPE_ICON_BACKGROUND_COLOR: '#fafafa',
  JOB_TYPE_BORDER_COLOR: '#CFA23A',
  JOB_TYPE_BACKGROUND_COLOR: '#fafafa',
  JOB_TYPE_TEXT_BACKGROUND_COLOR: '#FBF7EE',
  JOB_TYPE_TEXT_COLOR: '#4E4E4E',
  JOB_TYPE_TEXT_BORDER_COLOR: '#CFA23A',

  PROFILE_USER_PIC_BORDER_COLOR: '#CECECE',
  PROFILE_USER_PIC_BACKGROUND_COLOR: '#CECECE',
  PROFILE_USER_PIC_ICON_COLOR: '#FEFEFE',
  PROFILE_GROUP_PIC_BORDER_COLOR: '#c2abf7',
  PROFILE_GROUP_PIC_ICON_COLOR: '#c2abf7',
  PROFILE_PIC_BORDER_COLOR: '#e4fa1e',

  PROFILE_CONTAINER_BACKGROUND_COLOR: '#f0f0f0',
  PROFILE_HEADER_BACKGROUND_COLOR: '#fefefe',
  PROFILE_HEADER_BORDER_COLOR: '#fefefe',
  PROFILE_HEADER_SHADOW_COLOR: '#FEFEFE',
  PROFILE_USER_NAME_COLOR: '#1E1E1E',
  PROFILE_TEXT_INFO_COLOR: '#1E1E1E',
  PROFILE_CARD_BACKGROUND_COLOR: '#fefefe',
  PROFILE_CARD_BORDER_COLOR: '#dddddd',
  PROFILE_CARD_SHADOW_COLOR: '#FEFEFE',
  PROFILE_CARD_TITLE_COLOR: '#4e4e4e',
  PROFILE_CARD_ICON_COLOR: '#436a93',

  CHAT_TABS_BORDER_COLOR: '#436a93',
  CHAT_TAB_BACKGROUND_COLOR: 'yellow',
  CHAT_TAB_TEXT_COLOR: 'black',
  CHAT_TAB_ACTIVE_BACKGROUND_COLOR: '#0E0E0E',
  CHAT_TAB_ACTIVE_BORDER_COLOR: 'white',
  CHAT_TAB_ACTIVE_BORDER_BOTTOM_COLOR: 'red',
  CHAT_TAB_HEADING_BACKGROUND_COLOR: '#E7ECEF',
  CHAT_TAB_HEADING_ICON_COLOR: '#436a93',
  CHAT_TAB_HEADING_TEXT_COLOR: '#436a93',

  CHAT_PROFILE_LIST_CONTAINER_BACKGROUND_COLOR: '#FEFEFE',
  CHAT_PROFILE_LIST_BACKGROUND_COLOR: '#FEFEFE',
  CHAT_PROFILE_LIST_HOVER_COLOR: '#E7ECEF',
  CHAT_PROFILE_LIST_ITEM_USER_COLOR: '#1e1e1e',
  CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_TEXT_COLOR: '#7e7e7e',
  CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_ICON_COLOR: '#7e7e7e',
  CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_DATE_COLOR: '#7e7e7e',
  CHAT_PROFILE_LIST_ITEM_CHAT_COUNT_BACKGROUND_COLOR: '#bb00fa',
  CHAT_PROFILE_LIST_ITEM_CHAT_COUNT_COLOR: '#FFFFFF',

  CHAT_NEW_GROUP_CONTAINER_BACKGROUND_COLOR: '#cecece',
  CHAT_NEW_GROUP_LIST_ITEM_BACKGROUND_COLOR: '#d4dde2',
  CHAT_NEW_GROUP_LIST_ITEM_COLOR: '#4e4e4e',
  CHAT_NEW_GROUP_LIST_ITEM_TITLE_COLOR: '#4e4e4e',
  CHAT_NEW_GROUP_LIST_ITEM_SUB_TITLE_COLOR: '#6e6e6e',
  CHAT_NEW_GROUP_LIST_ITEM_SELECTED_BACKGROUND_COLOR: '#FA7B5F',
  CHAT_NEW_GROUP_LIST_ITEM_UNSELECTED_BACKGROUND_COLOR: '#FEFEFE',
  CHAT_NEW_GROUP_LIST_ITEM_SEPARATOR_COLOR: "#E7ECEF",
  CHAT_NEW_GROUP_FAB_BACKGROUND_COLOR: '#cf543c',

  CHAT_PROFILE_DETAILS_BACKGROUND_COLOR: '#fefefe',
  CHAT_PROFILE_DIALOG_BACKGROUND_COLOR: '#e6e6e6',

  SEARCH_INPUT_COLOR: '#4E4E4E',
  SEARCH_INPUT_CONTAINER_BACKGROUND_COLOR: '#fefefe',
  SEARCH_INPUT_LEFT_ICON_COLOR: '#9e9e9e',
  SEARCH_INPUT_RIGHT_ICON_COLOR: '#9e9e9e',

  CHAT_SEARCH_INPUT_COLOR: '#4E4E4E',
  CHAT_SEARCH_INPUT_CONTAINER_BACKGROUND_COLOR: '#E7ECEF',
  CHAT_SEARCH_INPUT_LEFT_ICON_COLOR: '#9e9e9e',
  CHAT_SEARCH_INPUT_RIGHT_ICON_COLOR: '#9e9e9e',

  CHAT_MESSAGE_INPUT_TOOLBAR_CONTAINER_BACKGROUND_COLOR: '#E7ECEF',
  CHAT_MESSAGE_INPUT_TOOLBAR_CONTAINER_BORDER_COLOR: '#3e3e3e',

  CHAT_SEND_INPUT_COLOR: '#0E0E0E',
  CHAT_SEND_INPUT_BACKGROUND_COLOR: '#FEFEFE',
  CHAT_SEND_INPUT_BORDER_COLOR: '#d4dde2',

  CHAT_SEND_ICON_CONTAINER_SHADOW_COLOR: '#FEFEFE',
  CHAT_SEND_ICON_COLOR: '#648fb8',

  CHAT_TEXT_MESSAGE_USER_AVATAR_BORDER_COLOR: '#79a8d7',
  CHAT_TEXT_MESSAGE_USER_AVATAR_TEXT_COLOR: '#0E0E0E',

  CHAT_MESSAGE_BUBBLE_USERNAME_COLOR: '#4E4E4E',

  CHAT_MESSAGE_BACKGROUND_COLOR: '#eee5dc',

  CHAT_MESSAGE_TIME_COLOR: '#5e5e5e',

  CHAT_MESSAGE_RIGHT_BUBBLE_CONTAINER_BACKGROUND_COLOR: 'transparent',
  CHAT_MESSAGE_RIGHT_BUBBLE_WRAPPER_BACKGROUND_COLOR: '#daf6c3',
  CHAT_MESSAGE_RIGHT_BUBBLE_WRAPPER_TEXT_COLOR: '#0E0E0E',
  CHAT_MESSAGE_RIGHT_BUBBLE_TEXT_COLOR: '#0E0E0E',
  CHAT_MESSAGE_RIGHT_BUBBLE_CONTAINER_TO_NEXT_BACKGROUND_COLOR: '#c9cc02',
  CHAT_MESSAGE_RIGHT_BUBBLE_CONTAINER_TO_NEXT_TEXT_COLOR: '#0E0E0E',
  CHAT_MESSAGE_RIGHT_BUBBLE_CONTAINER_TO_PREV_BACKGROUND_COLOR: '#c9cc02',
  CHAT_MESSAGE_RIGHT_BUBBLE_CONTAINER_TO_PREV_TEXT_COLOR: '#0E0E0E',

  CHAT_MESSAGE_LEFT_BUBBLE_CONTAINER_BACKGROUND_COLOR: 'transparent',
  CHAT_MESSAGE_LEFT_BUBBLE_WRAPPER_BACKGROUND_COLOR: '#fbf9fa',
  CHAT_MESSAGE_LEFT_BUBBLE_WRAPPER_TEXT_COLOR: '#0E0E0E',
  CHAT_MESSAGE_LEFT_BUBBLE_TEXT_COLOR: '#0E0E0E',
  CHAT_MESSAGE_LEFT_BUBBLE_CONTAINER_TO_NEXT_BACKGROUND_COLOR: '#1E1E1E',
  CHAT_MESSAGE_LEFT_BUBBLE_CONTAINER_TO_NEXT_TEXT_COLOR: '#c9cc02',
  CHAT_MESSAGE_LEFT_BUBBLE_CONTAINER_TO_PREV_BACKGROUND_COLOR: '#1E1E1E',
  CHAT_MESSAGE_LEFT_BUBBLE_CONTAINER_TO_PREV_TEXT_COLOR: '#c9cc02',

  CHAT_SYSTEM_MESSAGE_COLOR: '#1E1E1E',
  CHAT_SYSTEM_MESSAGE_BACKGROUND_COLOR: '#79a8d7',
  CHAT_SYSTEM_MESSAGE_BORDER_COLOR: '#8e8e8e',

  CHAT_LEFT_MESSAGE_COLOR: '#0E0E0E',
  CHAT_LEFT_MESSAGE_BACKGROUND_COLOR: 'transparent',
  CHAT_RIGHT_MESSAGE_COLOR: '#0E0E0E',
  CHAT_RIGHT_MESSAGE_BACKGROUND_COLOR: 'transparent',

  CHAT_LEFT_MESSAGE_TEXT_COLOR: '#0E0E0E',
  CHAT_RIGHT_MESSAGE_TEXT_COLOR: '#0E0E0E',

  CHAT_LEFT_MESSAGE_TEXT_LINK_COLOR: '#b44cf5',
  CHAT_RIGHT_MESSAGE_TEXT_LINK_COLOR: '#b44cf5',

  CHAT_EMPTY_TEXT_COLOR: '#0E0E0E',

  CHAT_MESSAGE_AUDIO_ICON_COLOR: 'red',

  CHAT_EMOJI_PICKER_ICON_BACKGROUND_COLOR: '#faea05',
  CHAT_EMOJI_PICKER_ICON_COLOR: '#648fb8',
  CHAT_EMOJI_PICKER_ICON_SHADOW_COLOR: '#d5f5a6',
  CHAT_EMOJI_PICKER_ICON_RIPPLE_COLOR: '#d5f5a6',
  CHAT_EMOJI_POPUP_CONTAINER_BACKGROUND_COLOR: '#FEFEFE',
  CHAT_EMOJI_POPUP_CONTAINER_BORDER_COLOR: '#c9cc02',
  CHAT_EMOJI_POPUP_CONTAINER_SHADOW_COLOR: '#c9cc02',

  CHAT_ATTACHMENT_PICKER_ICON_BACKGROUND_COLOR: '#e68b02',
  CHAT_ATTACHMENT_PICKER_ICON_COLOR: '#648fb8',
  CHAT_ATTACHMENT_PICKER_ICON_SHADOW_COLOR: '#d5f5a6',
  CHAT_ATTACHMENT_PICKER_ICON_RIPPLE_COLOR: '#d5f5a6',
  CHAT_ATTACHMENT_POPUP_CONTAINER_BACKGROUND_COLOR: '#FEFEFE',
  CHAT_ATTACHMENT_POPUP_CONTAINER_BORDER_COLOR: '#c9cc02',
  CHAT_ATTACHMENT_POPUP_CONTAINER_SHADOW_COLOR: '#c9cc02',

  CHAT_ATTACHMENT_POPUP_IMAGE_VIDEO_ICON_BACKGROUND_COLOR: '#ba9b02',
  CHAT_ATTACHMENT_POPUP_IMAGE_VIDEO_ICON_COLOR: '#FEFEFE',
  CHAT_ATTACHMENT_POPUP_IMAGE_VIDEO_TEXT_COLOR: '#5C5C5C',
  CHAT_ATTACHMENT_POPUP_IMAGE_VIDEO_ICON_SHADOW_COLOR: '#ffbba6',
  CHAT_ATTACHMENT_POPUP_IMAGE_VIDEO_ICON_RIPPLE_COLOR: '#ffbba6',

  CHAT_ATTACHMENT_POPUP_CAMERA_ICON_BACKGROUND_COLOR: '#02a8ba',
  CHAT_ATTACHMENT_POPUP_CAMERA_ICON_COLOR: '#FEFEFE',
  CHAT_ATTACHMENT_POPUP_CAMERA_TEXT_COLOR: '#5C5C5C',
  CHAT_ATTACHMENT_POPUP_CAMERA_ICON_SHADOW_COLOR: '#ffbba6',
  CHAT_ATTACHMENT_POPUP_CAMERA_ICON_RIPPLE_COLOR: '#ffbba6',

  CHAT_ATTACHMENT_POPUP_LOCATION_ICON_BACKGROUND_COLOR: '#95ba02',
  CHAT_ATTACHMENT_POPUP_LOCATION_ICON_COLOR: '#FEFEFE',
  CHAT_ATTACHMENT_POPUP_LOCATION_TEXT_COLOR: '#5C5C5C',
  CHAT_ATTACHMENT_POPUP_LOCATION_ICON_SHADOW_COLOR: '#ffbba6',
  CHAT_ATTACHMENT_POPUP_LOCATION_ICON_RIPPLE_COLOR: '#ffbba6',

  CAMERA_APP_CONTAINER_BORDER_COLOR: '#00cde1',
  CAMERA_APP_CONTAINER_BACKGROUND_COLOR: '#0E0E0E',
  CAMERA_APP_ACTION_FOOTER_BACKGROUND_COLOR: '#1e1e1e',
  CAMERA_APP_MEDIA_CANCEL_ICON_COLOR: '#FEFEFE',
  CAMERA_APP_MEDIA_CONFIRM_ICON_COLOR: '#FEFEFE',
  CAMERA_APP_MEDIA_SWITCH_TEXT_COLOR: '#00cde1',
  CAMERA_APP_TAKE_PHOTO_ICON_COLOR: '#FEFEFE',
  CAMERA_APP_RECORD_VIDEO_START_ICON_COLOR: '#FEFEFE',
  CAMERA_APP_RECORD_VIDEO_STOP_ICON_COLOR: '#FEFEFE',
  CAMERA_APP_CAMERA_FLIP_ICON_COLOR: '#FEFEFE',

  MODAL_CLOSE_ICON_COLOR: '#FEFEFE',
  MODAL_CLOSE_ICON_BACKGROUND_COLOR: 'red',

  FORM_CONFIRM_BUTTON_BACKGROUND_COLOR: '#3F8A02',
  FORM_CONFIRM_BUTTON_TEXT_COLOR: '#FEFEFE',
  FORM_CONFIRM_BUTTON_ICON_COLOR: '#FEFEFE',
  FORM_CONFIRM_BUTTON_BORDER_COLOR: '#fcb103',
  FORM_CONFIRM_BUTTON_SHADOW_COLOR: '#FEFEFE',

  FORM_SECONDARY_BUTTON_BACKGROUND_COLOR: '#ff8800',
  FORM_SECONDARY_BUTTON_TEXT_COLOR: '#FEFEFE',
  FORM_SECONDARY_BUTTON_ICON_COLOR: '#FEFEFE',
  FORM_SECONDARY_BUTTON_BORDER_COLOR: '#fcb103',
  FORM_SECONDARY_BUTTON_SHADOW_COLOR: '#FEFEFE',

  FORM_CANCEL_BUTTON_BACKGROUND_COLOR: '#9E9E9E',
  FORM_CANCEL_BUTTON_TEXT_COLOR: '#FEFEFE',
  FORM_CANCEL_BUTTON_ICON_COLOR: '#FEFEFE',
  FORM_CANCEL_BUTTON_BORDER_COLOR: '#fcb103',
  FORM_CANCEL_BUTTON_SHADOW_COLOR: '#FEFEFE',
  FORM_ORANGE_BUTTON_BACKGROUND_COLOR: '#ff8800',

  FORM_INPUT_ADD_NEW_ITEM_ICON_COLOR: '#BC8034',

  TRACK_USER_START_BUTTON_BACKGROUND_COLOR: '#E7ECEF',
  TRACK_USER_START_BUTTON_BORDER_COLOR: '#436a93',
  TRACK_USER_START_BUTTON_TEXT_COLOR: '#2e2e2e',

  TRACK_SELECTED_USER_CARD_BACKGROUND_COLOR: '#d4dde2',
  TRACK_SELECTED_USER_CARD_BORDER_COLOR: '#436a93',
  TRACK_SELECTED_USER_CARD_SHADOW_COLOR: '#E7ECEF',
  TRACK_SELECTED_USER_CARD_USERNAME_COLOR: '#2e2e2e',
  TRACK_SELECTED_USER_CARD_PHONE_ICON_COLOR: '#BC8034',
  TRACK_SELECTED_USER_CARD_EMAIL_ICON_COLOR: '#5ba668',
  TRACK_SELECTED_USER_CARD_DATE_COLOR: '#2e2e2e',
  TRACK_SELECTED_USER_CARD_DATE_SECTION_BACKGROUND_COLOR: '#E7ECEF',
  TRACK_SELECTED_USER_CARD_DATE_SHADOW_COLOR: '#fa4c11',

  TRACK_SOFT_TRACK_WRAPPER_BACKGORUND_COLOR: '#d4dde2',
  TRACK_SOFT_TRACK_WRAPPER_BORDER_COLOR: '#436a93',
  TRACK_SOFT_TRACK_TEXT_COLOR: '#2e2e2e',

  TRACK_JOB_CARD_BACKGROUND_COLOR: '#d4dde2',
  TRACK_JOB_CARD_TITLE_COLOR: '#2e2e2e',
  TRACK_JOB_CARD_BORDER_COLOR: '#436a93',
  TRACK_JOB_CARD_SHADOW_COLOR: '#E7ECEF',

  TRACK_USER_CALLOUT_CARD_BACKGROUND_COLOR: '#fcb103',
  TRACK_USER_CALLOUT_CARD_BORDER_COLOR: '#9BBE00',
  TRACK_USER_CALLOUT_CARD_SHADOW_COLOR: '#FFFFFF',
  TRACK_USER_CALLOUT_CARD_TEXT_COLOR: '#0E0E0E',

  MAP_PROXIMITY_CIRCLE_FILL_COLOR: '#ffd9ff',
  MAP_PROXIMITY_CIRCLE_STROKE_COLOR: '#85045c',

  MAP_TRACK_POLYINE_STROKE_COLOR: '#6800f0',

  MAP_JOB_MARKER_PIN_COLORl: 'violet',

  WORK_HOUR_JOB_TITLE_BACKGROUND_COLOR: '#F3E8CD',
  WORK_HOUR_JOB_TITLE_TEXT_COLOR: '#4e4e4e',
  WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR: '#4e4e4e',
  WORK_HOURS_TOTAL_ROW_BACKGROUND_COLOR: '#F3E8CD',
  WORK_HOUR_TABLE_BACKGROUND_COLOUR: '#F3E8CD',
  WORK_HOUR_TABLE_HEADER_BACKGROUND_COLOUR: '#F3E8CD',
  WORK_HOUR_TABLE_ROW_BACKGROUND_COLOUR: '#fefefe',


  WORK_HOUR_TABLE_COLUMN_TEXT_COLOUR: '#2e2e2e',

  WORK_HOUR_TABLE_GREEN_COLUMN_BACKGROUND_COLOUR: '#5ba668',
  WORK_HOUR_TABLE_RED_COLUMN_BACKGROUND_COLOUR: '#cf543c',

  WORK_HOUR_TABLE_GREY_COLUMN_BACKGROUND_COLOUR: '#cecece',

  WORK_HOUR_LEGEND_TEXT_COLOR: '#436a93',

  JOB_PAYMENT_HOURS_WORKED_TINT_COLOR: '#5ba668',
  JOB_PAYMENT_MULTIPLY_TINT_COLOR: '#cf543c',
  JOB_PAYMENT_HOURLY_RATE_TINT_COLOR: '#5E5E5E',
  JOB_PAYMENT_TOTAL_PAYMENT_TINT_COLOR: '#5ba668',
  JOB_PAYMENT_BONUS_TINT_COLOR: '#5ba668',
  JOB_PAYMENT_DEDUCTION_TINT_COLOR: '#cf543c',
  JOB_PAYMENT_ALREADY_PAID_TINT_COLOR: '#cf543c',
  JOB_PAYMENT_TOTAL_PAYABLE_TINT_COLOR: '#436a93',

  JOB_ACTION_CONTAINER_BACKGROUND_COLOR: '#F7F0DE',
  JOB_ACTION_CONTAINER_BORDER_COLOR: '#2E2E2E',

  JOB_ACTION_BUTTON_ACTIVE_BACKGROUND_COLOR: 'linear-gradient(180deg, rgb(106 107 106 / 0%) 9%, rgb(56 56 56 / 50%) 53%)',

  JOB_ACTION_BUTTON_BACKGROUND_COLOR: 'linear-gradient(180deg, rgb(255 255 255) 9%, rgb(187 187 187) 53%)',
  JOB_ACTION_BUTTON_BORDER_COLOR: '#ddd',
  JOB_ACTION_BUTTON_ICON_WRAPPER_BORDER_COLOR: '#ddd',
  JOB_ACTION_BUTTON_ICON_COLOR: '#9E9E9E',
  JOB_ACTION_BUTTON_ICON_ACTIVE_COLOR: '#EFF1F3',
  JOB_ACTION_BUTTON_TEXT_COLOR: '#4e4e4e',
  JOB_ACTION_BUTTON_ACTIVE_TEXT_COLOR: '#EFF1F3',

  JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_BACKGROUND_COLOR: '#9E9E9E',
  JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR: 'orange',
  JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_ICON_COLOR: '#ffd280',

  JOB_ACTION_BUTTON_STOP_ACTIVE_BACKGROUND_COLOR: '#9E9E9E',
  JOB_ACTION_BUTTON_STOP_ACTIVE_COLOR: '#44BF02',
  JOB_ACTION_BUTTON_STOP_ACTIVE_ICON_COLOR: '#c7ff99',

  JOB_ACTION_BUTTON_DONE_WRAPPER_ICON_BACKGROUND_COLOR: '#3F8A02',
  JOB_ACTION_BUTTON_DONE_WRAPPER_ICON_COLOR: '#9E9E9E',

  JOB_ACTION_BUTTON_INACTIVE_DONE_WRAPPER_ICON_BACKGROUND_COLOR: 'linear-gradient(180deg, rgb(200 200 200) 9%, rgb(160 160 160) 53%)',
  JOB_ACTION_BUTTON_INACTIVE_DONE_WRAPPER_ICON_BORDER_COLOR: 'rgb(192 192 192)',
  JOB_ACTION_BUTTON_INACTIVE_DONE_TEXT_COLOR: '#5E5E5E',

  JOB_ACTION_BUTTON_DONE_ICON_COLOR: '#9E9E9E',
  JOB_ACTION_BUTTON_ACTIVE_DONE_ICON_COLOR: 'white',

  JOB_ACTION_ARROW_TEXT_COLOR: '#81839C',
  JOB_ACTION_CHECKOUT_ARROW_TEXT_SHADOW_COLOR: '#547AA5',
  JOB_ACTION_CHECKOUT_ARROW_TEXT_COLOR: '#81839C',

  LOGIN_MAIN_BODY_BACKGROUND_COLOR: '#FBF7EE',
  LOGIN_CONTAINER_BACKGROUND_COLOR: '#F3E8CD',
  LOGIN_REGISTER_TEXT_COLOR: '#61C002',

  INACTIVE_USER_CHIP_BACKGROUND_COLOR: '#cf143c',
  INACTIVE_USER_CHIP_TEXT_COLOR: '#DEDEDE',

  PRICING_CARD_BACKGROUND_COLOR: '#DEDEDE',
  PRICING_CARD_ACTIVE_BACKGROUND_COLOR: '#6f6f6f',
  PRICING_CARD_BORDER_COLOR: '#cecece',
  PRICING_CARD_ACTIVE_BORDER_COLOR: '#cecece',
  PRICING_CARD_SHADOW_COLOR: '#9e9e9e',
  PRICING_CARD_ACTIVE_SHADOW_COLOR: '#cecece',
  PRICING_CARD_TITLE_COLOR: '#2e2e2e',
  PRICING_CARD_ACTIVE_TITLE_COLOR: '#2e2e2e',
  PRICING_CARD_PRICE_COLOR: '#9BBE00',
  PRICING_CARD_ACTIVE_PRICE_COLOR: '#1e1e1e',
  PRICING_CARD_PRICE_TAGLINE_COLOR: '#3e3e3e',
  PRICING_CARD_ACTIVE_PRICE_TAGLINE_COLOR: '#2e2e2e',
  PRICING_CARD_FEATURE_COLOR: '#2e2e2e',
  PRICING_CARD_ACTIVE_FEATURE_COLOR: '#2e2e2e',

  TAB_BACKGROUND_COLOR: '#eaeaea',
  TAB_ACTIVE_COLOR: '#436a93',
  TAB_DETAILPANEL_BACKGROUND_COLOR: '#f3f3f3',

  PAYMENT_SECTION_HEADER_BACKGROUND_COLOR: '#eaeaea',
  PAYMENT_TABLE_HEADER_BACKGROUND_COLOR: '#e9e9e9',
  PAYMENT_TABLE_HEADER_TEXT_COLOR: '#3e3e3e',

  SUBTASK_CARD_BACKGROUND_COLOR: '#dedede',
  SUBTASK_STATUS_BACKGROUND_COLOR: '#CECECE',
  ADD_SUBTASK_BUTTON_BACKGROUND_COLOR: '#ff8800',

  BONUS_CALCULATION_VALUE_COLOR: '#4e4e4e',
  BONUS_CALCULATION_FORMULA_COLOR: '#8e8e8e',
  BONUS_CALCULATION_STEP_COLOR: '#4e4e4e',

  CALENDAR_BACKGROUND_COLOR: '#FEFEFE',
  CALENDAR_BORDER_COLOR: '#2F2F2F',
  CALENDAR_DATE_TEXT_COLOR: '#0E0E0E',
  CALENDAR_DATE_CELL_BACKGROUND_COLOR: 'yellow',
  CALENDAR_EVENT_ACTIVE_BACKGROUND_COLOR: '#e8f8ff',
  CALENDAR_EVENT_INACTIVE_BACKGROUND_COLOR: '#d2ecf7',
  CALENDAR_TIME_CELL_WRAPPER_BACKGROUND_COLOR: '#e8f8ff',
  CALENDAR_DAY_HEADER_COLOR: '#40e5ff',
  CALENDAR_DAY_HEADER_ACTIVE_COLOR: '#00a7c2',
  CALENDAR_CURRENT_MONTH_TEXT_COLOR: '#0E0E0E',

  ACCORDION_BACKGROUND_COLOR: '#fefefe',
  ACCORDION_TITLE_COLOR: '#4e4e4e',

  PUBLIC_PAGE_BACKGROUND_COLOR: '#DEDEDE',
  PUBLIC_PAGE_PARA_COLOR: '#2E2E2E',

  DASHBOARD_HEADER_BACKGROUND_COLOR: '#dedede',
  DASHBOARD_HEADER_TITLE_ICON_COLOR: '#9BBE00',
  DASHBOARD_HEADER_TITLE_TEXT_COLOR: '#9BBE00',
  DASHBOARD_RIBBON_BACKGROUND_COLOR: "#4e4e4e",
  DASHBOARD_TEXT_COLOR: '#3e3e3e',
  STATS_CARD_BACKGROUND_COLOR: '#FEFEFE',
  STATS_CARD_BORDER_COLOR: '#c5c5c5',
  STATS_CARD_SHADOW_COLOR: 'rgba(96, 92, 92, 0.2)',

  CHART_BACKGROUND_COLOR: '#DEDEDE',
  CHART_LABEL_TICK_COLOR: '#444',
  CHART_GRID_COLOR: "#777777aa",

}

export const getCustomThemeColors = () => {

  const CustomThemeColorsLS = LocalStorageConstants.getItem(getCustomThemeLSKey(), DefaultCustomThemeColors);

  return {
    ...DefaultCustomThemeColors,
    ...CustomThemeColorsLS
  }

}

export const getCustomThemeLSKey = () => {

  const authUser = LocalStorageConstants.getItem("authUser", {});

  return `customThemeColors_${authUser.id || ""}`

}