import { mdiRestore } from '@mdi/js';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import RightsManager from '../../../utils/RightsManager';
import { useGlobalStyles } from '../../../utils/Styles/GlobalStyles';
import { useGlobalStore } from '../../../providers/GlobalProvider';
import HeadingBar from '../../../components/HeadingBar';
import AutocompleteCNO from '../../../library/AutocompleteCNO';
import { fetchCustomerSuggest, fetchMemberSuggest } from '../../../actions/suggestionsActions';
import { DateTimePickerCNO, InputLabelCNO } from '../../../library';
import JobChipsFilter from '../../../components/JobChipsFilter';
import UserSuggestionOption from '../../../components/UserSuggestionOption/UserSuggestionOption';
import FormActionButtons from '../../../components/FormActionButtons';
import { EventResponseActionStatus, EventType } from '../../../utils/mappings';
import ChipCNO from '../../../library/ChipCNO';
import * as icons from '@mdi/js';
import { getMaterialIconString } from '../../../utils/icons';

function EventsFilterModal() {

  const isUserAdmin = RightsManager.isAdmin();
  const isUserCustomer = RightsManager.isCustomer();
  const isUserMember = RightsManager.isMember();

  const isCalendarPath = useRouteMatch().path.includes('/calendar');

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const styles = useStyles({ tillTablet });
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { eventSearchParams } = useSelector(state => state.events);
  const suggestions = useSelector(state => state.suggestions);

  const { dispatchGlobal } = useGlobalStore();
  const closeEventFilterDialog = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false
    });
  };

  const validationSchema = yup.object({
    categories: yup.array()
  });

  const formik = useFormik({
    initialValues: {
      customer: null,
      categories: [],
      event: null,
      startDate: null,
      endDate: null,
      // assignments: isUserMember ? getPrefillAssignedUser() : null
    },
    validationSchema,
    onSubmit: (values) => {
      // dispatch(handleEventSearch(values));
      closeEventFilterDialog();
    }
  });

  useEffect(() => {

    console.log("==========useEffecteventSearchParams", eventSearchParams);

    formik.setValues(eventSearchParams);
  }, [eventSearchParams]);


  const handleResetForm = () => {
    formik.resetForm();
    // dispatch(handleEventSearch(formik.initialValues));

    // dispatch(setEventTimesFilter(TimesFilter.None));

  };

  const triggerEventSearch = (values) => {
    if (!tillTablet) {
      // dispatch(handleEventSearch(values));
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.container_EventsFilterModal}>

      <HeadingBar
        title={formik.dirty ? "Reset" : "Search"}
        icon={formik.dirty ? mdiRestore : null}
        onHeaderClick={handleResetForm}
        onClose={formik.dirty ? undefined : closeEventFilterDialog}
      />

      <InputLabelCNO label={'Event Type'} />
      <div className={GlobalStyles.chipsWrapper} style={{ marginTop: 12, marginBottom: 12 }}>
        {
          EventType.entities.map(eventType => (
            <ChipCNO
              active={false}
              icon={icons[getMaterialIconString(eventType.icon)]}
              name={eventType.title}
            />
          ))
        }
      </div>


      <InputLabelCNO label={'Categories'} />
      <div className={GlobalStyles.verticalScrollChipsWrapper} style={{ marginTop: 12, marginBottom: 12 }}>
        <JobChipsFilter
          mode="event"
          onChipToggle={(categories) => {
            // formik.setFieldValue('categories', categories);
            // triggerEventSearch({ ...formik.values, categories: categories });
          }}
        />
      </div>


      <div style={{ marginTop: 12 }}></div>

      <DateTimePickerCNO
        dateName="startDate"
        datePlaceholder="Start Date"
        datePickerLabel='Start by Date'
        onDateChange={date => {
          formik.setFieldValue('startDate', date);
        }}

        formik={formik}
        iconColor="red"
        isRequired={false}
      />

      <DateTimePickerCNO
        dateName="endDate"
        datePlaceholder="End Date"
        datePickerLabel='End Date'
        onDateChange={date => {
          formik.setFieldValue('endDate', date);
        }}

        formik={formik}
        minDate={formik.values.startDate}
        iconColor="green"
        isRequired={false}
      />

      <InputLabelCNO label={'Response Status'} />
      <div className={GlobalStyles.chipsWrapper} style={{ marginTop: 12, marginBottom: 12 }}>
        {
          EventResponseActionStatus.entities.map(eventType => (
            <ChipCNO
              active={false}
              icon={icons[getMaterialIconString(eventType.icon)]}
              name={eventType.title}
            />
          ))
        }
      </div>

      {
        tillTablet &&
        <FormActionButtons
          rightText="Search"
          rightClick={formik.handleSubmit}
          leftClick={closeEventFilterDialog}
          formik={formik}
        />
      }

    </form>
  )
}

export default EventsFilterModal

const useStyles = makeStyles((theme) => ({
  container_EventsFilterModal: {
    padding: 8,
    '&>*:first-child': {
      height: ({ tillTablet }) => tillTablet ? 'auto' : 68
    }
  },

  chip: {
    background: '#444'
  },
  textField: {
    borderRadius: 5,
    '& input': {
      background: 'white',
      color: '#444',
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: '#444'
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": "#444",
      caretColor: "#444",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 14
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0
    },
    '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      padding: 0
    }
  },
  paper: {
    background: 'white !important',
    '& ::-webkit-scrollbar': {
      display: 'none'
    }
  },
  noOptions: {
    color: '#BE0A02'
  },
  popper: {
    zIndex: 1301
  },
  assignToWrapper: {
    display: 'flex',
    marginTop: 8,
    flexDirection: 'column',
    '&>*:first-child': {
      flex: 1,
      marginBottom: 8
    }
  },
}));