import { Box, Button, makeStyles } from '@material-ui/core'
import React from 'react'
import FormActionButtons from '../../../components/FormActionButtons'
import PostEmojiPicker from './PostEmojiPicker'
import PostMediaPicker from './PostMediaPicker'

const AddPostActions = ({
  onAddPost,
  onEmojiSelect,
  onMediaSelect,
  isAddPostDisabled,
}) => {

  const styles = useStyles();

  return (

    <Box className={styles.container}>
      <Box className={styles.postInputActionsWrapper}>
        <PostEmojiPicker onEmojiSelect={onEmojiSelect} />
        <PostMediaPicker onMediaSelect={onMediaSelect} />
      </Box>

      <Box>

        <FormActionButtons
          rightText={'Post'}
          rightClick={onAddPost}
          disabled={isAddPostDisabled}
          wrapperStyles={{ marginTop: 0 }}         
        />

      </Box>
    </Box>

  )
}

export default AddPostActions;

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: '0.5rem 0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.SCREEN_BG_COLOR,
    borderRadius: 8,
    position:'sticky',
    bottom:0,
  },
  postInputActionsWrapper: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  }
}))