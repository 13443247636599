import React from 'react'
import ScreenView from '../../../components/ScreenView'
import { EventsFilterModal, EventsListing } from '../components'

const EventsHomePage = () => {

  return (
    <div>
      <ScreenView
        content={
          <EventsListing
          // mode={ProfileMode.Customer}
          // profileList={customers}
          // profileLoading={customersLoader}
          // loadMore={loadMore}
          // hasMore={hasMoreCustomers}
          />
        }
        secondarySidebar={<EventsFilterModal setPage={() => null} />}
        headerTitle="Events"
      />

    </div>
  )
}

export default EventsHomePage