import { Button, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiPlus, mdiTelescope } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import RightsManager from '../utils/RightsManager';

function ListCTAButtons({
  addTitle = "Add",
  onClickAdd,
  exploreTitle = "Watch Tutorial",
  onClickExplore,
  exploreId
}) {

  const isUserAdmin = RightsManager.isAdmin();

  const history = useHistory();

  const fromTablet = useMediaQuery('(min-width: 768px)');

  const showExploreButton = exploreId || typeof onClickExplore === 'function';

  const theme = useTheme();
  const styles = useStyles({ fromTablet, isUserAdmin, showExploreButton });

  const handleAddClick = () => {

    if (onClickAdd) onClickAdd();

  };

  const handleExploreClick = () => {

    if (onClickExplore) {
      onClickExplore();
    } else {
      history.push(exploreId ? `/help?id=${exploreId}` : '/help');
    }

  };

  return (
    <div className={styles.container}>
      {
        isUserAdmin &&
        <Button
          variant='contained'
          className={styles.btnAdd}
          startIcon={<Icon path={mdiPlus} size={"1.5rem"} color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR} />}
          onClick={handleAddClick}
        >
          {addTitle}
        </Button>
      }
      {
        showExploreButton &&
        <Button
          variant='contained'
          className={styles.btnExplore}
          startIcon={<Icon path={mdiTelescope} size={"1.5rem"} color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR} />}
          onClick={handleExploreClick}
        >
          {exploreTitle}
        </Button>
      }
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: ({ isUserAdmin, showExploreButton }) => isUserAdmin
      ? showExploreButton ? 'space-between' : 'center'
      : 'center',
    alignItems: 'center',
    width: ({ fromTablet }) => fromTablet ? 474 : 388,
    margin: '20px auto'
  },

  btnExplore: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    background: 'orange'
  },

  btnAdd: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
  },
}));

export default ListCTAButtons