import { makeStyles, TextField, Typography } from '@material-ui/core';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import * as icons from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { updateInvoice } from '../../actions/invoiceActions';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons';
import { InvoiceStatus } from '../../utils/mappings';
import RightsManager from '../../utils/RightsManager';

function EditNotes({
  open,
  onClose,
  invoice,
}) {
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { allInvoiceLoader: { editLoader } } = useSelector(state => state.invoices);

  const validationSchema = yup.object({
    notes: yup.string().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      notes: invoice.notes,
    },
    validationSchema,
    onSubmit: values => handleUpdate(values)
  });

  const handleUpdate = (values) => {

    const dataToSend = {
      ...invoice,
      notes: values.notes
    };

    dispatch(updateInvoice(dataToSend));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Invoice Invoice"
      loading={editLoader}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>

        <InputCNO
          name='notes'
          label='Invoice Notes'
          formik={formik}
          placeholder={'Add Invoice notes'}
          icon={icons.mdiFormatText}
          multiline
          fullWidth
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )
};

function InvoiceNotesDetailsCard({ invoice }) {

  let authUserCompany = localStorage.getItem('authUserCompany');
  authUserCompany = authUserCompany ? JSON.parse(authUserCompany) : null;

  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const isCompanyAdmin = RightsManager.isGroupLeader();

  const [isNotesEditable, setIsNotesEditable] = useState(false);

  return (
    <div className={GlobalStyles.detailsCardContainer}>
      <Typography className={GlobalStyles.detailsCardSectionTitle}>
        Invoice Notes
      </Typography>

      <div
        className={styles.row}
        onClick={() => {
          setIsNotesEditable(true)
        }}
      >
        <Typography style={{ whiteSpace: 'pre-line' }}>
          {invoice.notes}
        </Typography>
      </div>

      {
        isCompanyAdmin &&
        isNotesEditable &&
        <EditNotes
          open={isNotesEditable}
          onClose={() => setIsNotesEditable(false)}
          invoice={invoice}
        />
      }

    </div>
  )
}

export default InvoiceNotesDetailsCard


const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    color: theme.colors.PROFILE_TEXT_INFO_COLOR,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  icon: {
    color: theme.colors.PROFILE_CARD_ICON_COLOR,
    marginRight: theme.spacing(2)
  },
}));
