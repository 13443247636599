import {
  SET_CURRENT_SUBSCRIPTION_DETAILS,
  SET_HAS_SUBSCRIPTION_EXPIRED,
  SET_PAYMENT_DUE,
  SET_PAYMENT_HISTORY,
  SET_PRICING_ITEMS,
  SET_SUBSCRIPTION_LOADERS
} from "../actions/subscriptionActions";

const intitialValues = {
  subscriptionDetails: null,
  paymentHistory: [],
  paymentDue: null,
  hasSubscriptionExpired: false,
  pricingItems: [],

  subscriptionDetailsLoader: false,
  fetchPaymentHistoryLoader: false,
  addPaymentHistoryLoader: false,
  addPaymentDueLoader: false

};

export const subscriptionReducer = (state = intitialValues, { type, payload }) => {

  switch (type) {
    case SET_CURRENT_SUBSCRIPTION_DETAILS: {
      state = {
        ...state,
        subscriptionDetails: payload
      };

      return state;
    }

    case SET_SUBSCRIPTION_LOADERS: {
      const { loaderName, loaderStatus } = payload;
      state = {
        ...state,
        [loaderName]: loaderStatus
      };

      return state;
    }

    case SET_PAYMENT_HISTORY: {
      state = {
        ...state,
        paymentHistory: payload
      };

      return state;
    }

    case SET_PAYMENT_DUE: {
      state = {
        ...state,
        paymentDue: payload
      };

      return state;
    }

    case SET_HAS_SUBSCRIPTION_EXPIRED: {
      state = {
        ...state,
        hasSubscriptionExpired: payload
      };

      return state;
    }

    case SET_PRICING_ITEMS: {
      state = {
        ...state,
        pricingItems: payload
      };

      return state;
    }


    default:
      return state;
  }

};