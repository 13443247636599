import React, { useEffect } from 'react'
import { ChipCNO, MetaText, StickyWrapper } from '../../../library'
import { FormStatus } from '../utils/enums'
import { Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { handleFormSearch } from '../../../actions/formBuilderActions'

const FormBuilderStatusBar = () => {

  const dispatch = useDispatch();
  const { formSearchParams = {} } = useSelector(state => state.formBuilder);

  const handleSearch = (id) => () => {
    dispatch(handleFormSearch({ status: id }));
  }

  console.log("FormBuilderStatusBar: formSearchParams: ", formSearchParams)

  return (
    <StickyWrapper>
      <Box display='flex' alignItems='center' style={{ gap: 8 }} >
        {
          FormStatus.entities.map(status => (
            <ChipCNO
              key={status.id}
              name={status.title}
              icon={status.icon}
              active={status.id === formSearchParams.status}
              onClick={handleSearch(status.id)}
            />
          ))
        }
      </Box>
    </StickyWrapper>
  )
}

export default FormBuilderStatusBar