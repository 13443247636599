import { TaskStatus } from "../mappings";

// sort the chats according to time
export const sortChatsList = (chats) => {
  console.log("sorting started");

  const sortedChats = chats.sort((first, second) => {

    let firstDate = first.time;
    let secondDate = second.time;

    if (firstDate === null && secondDate === null) {
      return 0;
    }

    if (firstDate !== null) {
      firstDate = new Date(firstDate);
    }
    else {
      return 1;
    };

    if (secondDate !== null) {
      secondDate = new Date(secondDate);
    }
    else {
      return -1;
    };

    return firstDate < secondDate ? 1 : -1;
  });

  return sortedChats;
};

export const getCreatedByUser = (createdById, activeGroupMembers) => {

  let createdByUser = null;

  console.log("get user: activeGroupMembers: ", activeGroupMembers);
  console.log("get user:: createdById: ", createdById);

  if (activeGroupMembers.length > 0) {
    createdByUser = activeGroupMembers.find(user => user.memberId === createdById);
    if (createdByUser) {

      createdByUser = {
        _id: createdById,
        // name: `${createdByUser.contactName}`,
        name: `${createdByUser.firstName} ${createdByUser.lastName}`,
        profilePic: createdByUser.profilePic || createdByUser.contactAvatar
      };
    }
  }

  if (!createdByUser) {
    createdByUser = JSON.parse(localStorage.getItem("lastActiveChatUser"));
    console.log("get user: currentChatProfile: ", createdByUser);
    if (!createdByUser) {
      createdByUser = null;
    }
    else {
      createdByUser = {
        _id: createdById,
        name: createdByUser.contactName,
        // name: createdByUser.contactType === 'user' ? `${createdByUser.firstName} ${createdByUser.lastName}` : createdByUser.contactName,
        profilePic: createdByUser.profilePic || createdByUser.contactAvatar
      };
    }
  }

  console.log("get user: createdByUser: ", createdByUser);

  return createdByUser;
};

export const filterJobsByFinished = (todos) => {
  const filteredTodos = todos.filter((todo) => todo.status === TaskStatus.Done);
  return filteredTodos;
};

export const filterJobsByUnFinished = (todos) => {
  const filteredTodos = todos.filter((todo) => todo.status !== TaskStatus.Done);
  return filteredTodos;
};


export const getChatMessageSnippetListFromLocalStorage = () => {
  let chatSnippetList = localStorage.getItem('ChatMessageSnippetList');

  if (!chatSnippetList) {
    chatSnippetList = [];
  }
  else {
    chatSnippetList = JSON.parse(chatSnippetList);
  }

  return chatSnippetList;
};

export const getFilteredAndSortedChatList = (oldList, newList) => {

  let filteredAndSortedList = [];

  // console.log("===== getFilteredAndSortedChatList ==== oldList ", oldList);
  // console.log("===== getFilteredAndSortedChatList ==== newList ", newList);

  let filteredList = [];
  filteredList = oldList.filter(oldItem => !newList.some(newItem => newItem.id === oldItem.id));

  // console.log("===== getFilteredAndSortedChatList ==== filteredList ", filteredList);

  const mergedList = [...filteredList, ...newList];

  filteredAndSortedList = sortChatsList(mergedList);

  // console.log("===== getFilteredAndSortedChatList ==== filteredAndSortedList ", filteredAndSortedList);

  return filteredAndSortedList;

};

export const getGroupListFromLocalStorage = () => {
  let groupList = localStorage.getItem('MyLocalGroupList');

  if (!groupList) {
    groupList = [];
  }
  else {
    groupList = JSON.parse(groupList);
  }

  return groupList;
};

export const getProfileListFromLocalStorage = () => {
  let profileList = localStorage.getItem('MyLocalProfileList');

  if (!profileList) {
    profileList = [];
  }
  else {
    profileList = JSON.parse(profileList);
  }

  return profileList;
};

export const getCurrentChatProfile = () => {

  let currentChatProfile = localStorage.getItem("currentChatProfile");
  if (!currentChatProfile) {
    currentChatProfile = {};
  }
  else {
    currentChatProfile = JSON.parse(currentChatProfile);
  }

  return currentChatProfile;

};