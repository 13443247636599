import React, { useEffect, useRef, useState } from "react";
import {
  Circle,
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  Marker,
  Polyline,
  MarkerClusterer,
} from "@react-google-maps/api";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import ContactUserCard from "../ContactUserCard";
import WorkHourDetails from "../WorkHourDetails";
import PayUser from "../PayUser";
import UserCardSlider from "../UserCardSlider";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./MapCNOStyles";
import { useVisibleElements } from "react-snaplist-carousel";
import {
  resetTrackSearchMarkers,
  toggleSoftTracking,
} from "../../actions/trackSearchActions";
import Moment from "react-moment";
import StartTracking from "../StartTracking/StartTracking";
import { fetchTaskSearch } from "../../actions/taskSearchActions";
import {
  calculateDistance,
  getCalloutBackgroundColor,
  getDecimalHoursFromSeconds,
  getPinColor,
  getTrackType,
  zoomToLocation,
} from "../../utils/Tracking";
import { calendarStrings, TIME_FORMAT } from "../../utils/formatDate";
import mapPin from "../../assets/pin.png";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { TrackProximityRadius, TrackType } from "../../utils/mappings";
import MapLegends from "./MapLegends";
import WhoIsWorkingToday from "./WhoIsWorkingToday";
import { formatTimeWithTZ } from "../../utils/Helpers";

const containerStyle = {
  width: "100%",
  height: "calc(100vh - 70px)",
  display: "flex",
  padding: 20,
  // justifyContent: 'center',
};

function MapCNO() {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  const styles = useStyles();

  const jobLocationTimezone = localStorage.getItem("jobLocationTimezone");

  const taskSearch = useSelector((state) => state.taskSearch);
  const { isMapData } = taskSearch;

  const cards = taskSearch.data;

  const [data, setData] = useState([]);

  const trackSearch = useSelector((state) => state.trackSearch);
  const trackMarkers = trackSearch.data;
  const {
    isWorkHoursDialog,
    isPayUserDialog,
    isSoftTracking,
    softTrackData,
    isTrackSearchLoading,
    mapSearchParams,
  } = trackSearch;
  const { teamMember } = mapSearchParams;

  // console.log("softTrackData: ", softTrackData);
  // console.log("isSoftTracking: ", isSoftTracking);
  // console.log("length: ", trackMarkers.length, softTrackData.length);

  const dispatch = useDispatch();

  const [center, setCenter] = useState(null);

  const [jobMarker, setJobMarker] = useState(null);

  const [openInfoWindowId, setOpenInfoWindowId] = useState("");

  const [map, setMap] = React.useState(null);

  const [totalHoursWorkedInJob, setTotalHoursWorkedInJob] = useState(0);

  const [totalHardHoursWithinProximity, setTotalHardHoursWithinProximity] =
    useState(0);

  const [totalHoursWithinProximity, setTotalHoursWithinProximity] = useState(0);
  const [totalHoursOutsideProximity, setTotalHoursOutsideProximity] =
    useState(0);

  const [directions, setDirections] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async function initialIIFE() {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);

        const latLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        console.log("zoom: load map", latLng);
        setTimeout(() => {
          //setCenter(latLng);
          zoomToLocation(map, latLng);
        }, 2000);
      });
    })();
  }, [map]);

  useEffect(() => {
    setData([...data, ...cards]);
  }, [cards]);

  useEffect(() => {
    console.log("zoom: useEffect on get data");

    if (data && data.length > 0) {
      const locationLatLang = {
        lat: parseFloat(data[data.length - 1].lat),
        lng: parseFloat(data[data.length - 1].lng),
      };
      setCenter(locationLatLang);
      setJobMarker(locationLatLang);
      console.log("zoom: on get data", locationLatLang);
      zoomToLocation(map, locationLatLang);
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);

        const latLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCenter(latLng);
        console.log("zoom: on no data", latLng);
        zoomToLocation(map, latLng);
      });
    }
  }, [data]);

  const snapList = useRef(null);
  const visible = useVisibleElements(
    { debounce: 10, ref: snapList },
    ([element]) => element
  );
  const currentJob = data[visible];

  console.log("current job: ", currentJob);

  const [page, setPage] = useState(0);
  useEffect(() => {
    if (data && data.length > 0 && currentJob) {
      const locationLatLng = {
        lat: parseFloat(currentJob.lat),
        lng: parseFloat(currentJob.lng),
      };

      setCenter(locationLatLng);
      setJobMarker(locationLatLng);

      console.log("zoom: on card slide", locationLatLng);

      zoomToLocation(map, locationLatLng);
    }

    const nextPage = page + 1;

    console.log("=======visible======", {
      visible: visible + 1,
      nextPage,
      trigger: nextPage * 10 - 1,
    });

    if (visible + 1 === nextPage * 10 - 1) {
      const taskSearchPayload = {
        ids: mapSearchParams.job ? [mapSearchParams.job.id] : null,
        assignedIds: mapSearchParams.teamMember
          ? [mapSearchParams.teamMember.id]
          : [],
        startDate: mapSearchParams.jobStartDate
          ? mapSearchParams.jobStartDate
          : null,
        endDate: mapSearchParams.jobEndDate ? mapSearchParams.jobEndDate : null,
        teamMember: mapSearchParams.teamMember,
        pageIndex: nextPage,
      };
      setPage(nextPage);

      dispatch(fetchTaskSearch(taskSearchPayload));
    }

    dispatch(resetTrackSearchMarkers());
  }, [visible]);

  // useEffect(() => {
  //   if (currentJob) {
  //     (
  //       async function timezone() {
  //         const jobCoords = {
  //           lat: currentJob.lat,
  //           lng: currentJob.lng
  //         };
  //         const jobLocationTimezone = await getTimeZoneFromLatLang(jobCoords);
  //         console.log("jobzonesppp: ", jobLocationTimezone);
  //         localStorage.setItem("jobLocationTimezone", jobLocationTimezone);
  //       }
  //     )();
  //   }
  // }, [data, currentJob]);

  const [lineSymbol, setLineSymbol] = useState(null);

  const [markerSize, setMarkerSize] = useState("");

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);

    if (window.google) {
      setLineSymbol({
        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        strokeOpacity: 1,
        scale: 2,
        strokeWeight: 1,
        strokeColor: "#03fce3",
      });
    }

    // setMarkerSize(window.google.maps.Size(30, 30));

    //}
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (trackMarkers.length <= 0) {
      return;
    }

    if (trackMarkers && trackMarkers.length > 0) {
      const lastMarker = trackMarkers[trackMarkers.length - 1];
      const locationLatLang = {
        lat: parseFloat(lastMarker.lat),
        lng: parseFloat(lastMarker.lng),
      };

      console.log("zoom: on trackmarkers", locationLatLang);
      setCenter(locationLatLang);
      zoomToLocation(map, locationLatLang);
    }

    // const locationLatLang = {
    //   lat: center.lat,
    //   lng: center.lng
    // };

    //drawDrivingRoute(trackMarkers);

    // zoomToLocation(map, locationLatLang);
  }, [trackMarkers]);

  const drawDrivingRoute = (trackMarkers) => {
    if (trackMarkers && trackMarkers.length > 1) {
      console.log(
        "-----------drawDrivingRoute----trackMarkers-----------",
        trackMarkers
      );
      const waypoints = trackMarkers.slice(0, 20).map((p) => ({
        location: { lat: parseFloat(p.lat), lng: parseFloat(p.lng) },
        stopover: false,
      }));

      console.log(
        "-----------drawDrivingRoute----waypoints-----------",
        waypoints
      );
      console.log(
        "-----------drawDrivingRoute----waypoints.shift().location-----------",
        waypoints.shift().location
      );
      console.log(
        "-----------drawDrivingRoute----waypoints.pop().location-----------",
        waypoints.pop().location
      );

      const origin = waypoints.shift().location;
      const destination = waypoints.pop().location;

      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: window.google.maps.TravelMode.WALKING,
          waypoints: waypoints,
          optimizeWaypoints: true,
        },
        (result, status) => {
          console.log(
            "-------drawDrivingRoute--------__DIRECTION_-----------------",
            result
          );
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            setError(result);
          }
        }
      );
    }
  };

  const symbolLastLocationCheckout = {
    path: "M -10,-10 10,10 M 10,-10 -10,10",
    strokeColor: "green",
    strokeWeight: 8,
  };

  const symbolLastLocation = {
    path: "M -10,-10 10,10 M 10,-10 -10,10",
    strokeColor: "turquoise",
    strokeWeight: 10,
  };

  const toggleInfoWindow = (id) => {
    if (openInfoWindowId) {
      setOpenInfoWindowId("");
    } else {
      setOpenInfoWindowId(id);
    }
  };

  const handleSoftTracking = () => {
    console.log("onload soft", isSoftTracking);
    dispatch(toggleSoftTracking(!isSoftTracking));
  };

  useEffect(() => {
    if (map && softTrackData && softTrackData.length && isSoftTracking) {
      const trackData = softTrackData[softTrackData.length - 1];
      console.log("softTrackzoom: ", trackData);
      const locationLatLang = {
        lat: parseFloat(trackData.lat),
        lng: parseFloat(trackData.lng),
      };
      setCenter(locationLatLang);
      console.log("zoom: on soft track", locationLatLang);
      zoomToLocation(map, locationLatLang);
    } else if (data && data.length > 0 && currentJob) {
      const locationLatLng = {
        lat: parseFloat(currentJob.lat),
        lng: parseFloat(currentJob.lng),
      };

      setCenter(locationLatLng);
      setJobMarker(locationLatLng);

      console.log("zoom: on reset soft track", locationLatLng);

      zoomToLocation(map, locationLatLng);
    }
    // }else {
    //     navigator.geolocation.getCurrentPosition(function (position) {
    //       console.log("Latitude is :", position.coords.latitude);
    //       console.log("Longitude is :", position.coords.longitude);

    //       const latLng = {
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude
    //       };

    //       console.log("zoom: load map222");
    //       setCenter(latLng);
    //       zoomToLocation(map, latLng);
    //     });
    //   }
  }, [map, softTrackData, isSoftTracking, data, currentJob]);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (error) {
    return <h1>{error}</h1>;
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      loadingElement={<CircularProgress />}
      zoom={16}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {isTrackSearchLoading && <FullScreenLoaderCNO />}

      {data?.length && currentJob && (
        <Marker
          position={jobMarker}
          key="job_marker"
          onMouseOver={() => toggleInfoWindow(currentJob?.id)}
          onMouseOut={() => toggleInfoWindow(currentJob?.id)}
          icon={{
            url: mapPin,
            //anchor: new window.google.maps.Point(17, 46),
            scaledSize: new window.google.maps.Size(30, 50),
          }}
        >
          {openInfoWindowId === currentJob["id"] && (
            <InfoWindow
              position={center}
              onCloseClick={() => toggleInfoWindow(currentJob.id)}
              containerStyle={styles.outerInfoWindow}
            >
              <Typography
                className={styles.infoWindow}
                style={{ background: getCalloutBackgroundColor(currentJob) }}
              >
                {teamMember.title} is working on {currentJob.title}
              </Typography>
            </InfoWindow>
          )}
        </Marker>
      )}
      {data && data.length && (
        <Circle
          center={jobMarker}
          options={{
            strokeColor: "#85045c",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#ffd9ff",
            fillOpacity: 0.35,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            zIndex: 1,
          }}
          radius={
            currentJob && currentJob.proximity
              ? currentJob.proximity
              : TrackProximityRadius
          }
        />
      )}

      {trackMarkers.map(
        (marker, index) =>
          (marker.trackType === TrackType.CheckIn ||
            marker.trackType === TrackType.CheckOut ||
            marker.trackType === TrackType.Done ||
            marker.trackType === TrackType.AppRestart) && (
            <Marker
              key={marker.id}
              position={{
                lat: parseFloat(marker.lat),
                lng: parseFloat(marker.lng),
              }}
              icon={
                trackMarkers.length - 1 === index && trackMarkers.length !== 1
                  ? marker.trackType === TrackType.CheckOut
                    ? symbolLastLocationCheckout
                    : symbolLastLocation
                  : getPinColor(marker)
              }
              onMouseOver={() => toggleInfoWindow(marker.id)}
              onMouseOut={() => toggleInfoWindow(marker.id)}
            >
              {marker.id === openInfoWindowId && (
                <InfoWindow
                  position={{
                    lat: parseFloat(marker.lat),
                    lng: parseFloat(marker.lng),
                  }}
                  // onCloseClick={() => toggleInfoWindow(marker.id)}
                >
                  <div
                    className={styles.infoWindow}
                    style={{ background: getCalloutBackgroundColor(marker) }}
                  >
                    {currentJob ? (
                      <div className={styles.infoWindowDescriptionWrapper}>
                        <Typography className={styles.infoWindowDescription}>
                          {teamMember.title}'s distance from work location was
                          around{" "}
                          {parseFloat(
                            calculateDistance(
                              { lat: currentJob.lat, lng: currentJob.lng },
                              { lat: marker.lat, lng: marker.lng }
                            ).distanceInMeter
                          ).toFixed(2)}{" "}
                          meter OR{" "}
                          {parseFloat(
                            calculateDistance(
                              { lat: currentJob.lat, lng: currentJob.lng },
                              { lat: marker.lat, lng: marker.lng }
                            ).distanceInKM
                          ).toFixed(2)}{" "}
                          KM
                        </Typography>
                        <Typography className={styles.infoWindowDescription}>
                          {getTrackType(marker)}
                        </Typography>
                      </div>
                    ) : (
                      <div className={styles.infoWindowDescriptionWrapper}>
                        <Typography className={styles.infoWindowDescription}>
                          {teamMember.title} - Soft Hour
                        </Typography>
                        <Typography className={styles.infoWindowDescription}>
                          {getTrackType(marker)}
                        </Typography>
                      </div>
                    )}

                    <div className={styles.infoWindowDate}>
                      <Moment interval={0} calendar={calendarStrings}>
                        {formatTimeWithTZ(marker.modificationDate)}
                      </Moment>
                      <Moment interval={0} format={TIME_FORMAT}>
                        {formatTimeWithTZ(marker.modificationDate)}
                      </Moment>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          )
      )}

      {
        <MarkerClusterer>
          {(clusterer) =>
            trackMarkers.map(
              (marker, index) =>
                (marker.trackType === TrackType.Location ||
                  marker.trackType === TrackType.Empty) && (
                  <Marker
                    key={marker.id}
                    position={{
                      lat: parseFloat(marker.lat),
                      lng: parseFloat(marker.lng),
                    }}
                    clusterer={clusterer}
                    icon={
                      trackMarkers.length - 1 === index &&
                      trackMarkers.length !== 1
                        ? marker.trackType === TrackType.CheckOut
                          ? symbolLastLocationCheckout
                          : symbolLastLocation
                        : getPinColor(marker)
                    }
                    // onClick={() => toggleInfoWindow(marker.id)}
                    onMouseOver={() => toggleInfoWindow(marker.id)}
                    onMouseOut={() => toggleInfoWindow(marker.id)}
                  >
                    {marker.id === openInfoWindowId && (
                      <InfoWindow
                        position={{
                          lat: parseFloat(marker.lat),
                          lng: parseFloat(marker.lng),
                        }}
                        // onCloseClick={() => toggleInfoWindow(marker.id)}
                      >
                        <div
                          className={styles.infoWindow}
                          style={{
                            background: getCalloutBackgroundColor(marker),
                          }}
                        >
                          {currentJob ? (
                            <div
                              className={styles.infoWindowDescriptionWrapper}
                            >
                              <Typography
                                className={styles.infoWindowDescription}
                              >
                                {teamMember.title}'s distance from work location
                                was around{" "}
                                {parseFloat(
                                  calculateDistance(
                                    {
                                      lat: currentJob.lat,
                                      lng: currentJob.lng,
                                    },
                                    { lat: marker.lat, lng: marker.lng }
                                  ).distanceInMeter
                                ).toFixed(2)}{" "}
                                meter OR{" "}
                                {parseFloat(
                                  calculateDistance(
                                    {
                                      lat: currentJob.lat,
                                      lng: currentJob.lng,
                                    },
                                    { lat: marker.lat, lng: marker.lng }
                                  ).distanceInKM
                                ).toFixed(2)}{" "}
                                KM
                              </Typography>
                              <Typography
                                className={styles.infoWindowDescription}
                              >
                                {getTrackType(marker)}
                              </Typography>
                            </div>
                          ) : (
                            <div
                              className={styles.infoWindowDescriptionWrapper}
                            >
                              <Typography
                                className={styles.infoWindowDescription}
                              >
                                {teamMember.title} - Soft Hour
                              </Typography>
                              <Typography
                                className={styles.infoWindowDescription}
                              >
                                {getTrackType(marker)}
                              </Typography>
                            </div>
                          )}

                          <div className={styles.infoWindowDate}>
                            <Moment interval={0} calendar={calendarStrings}>
                              {formatTimeWithTZ(marker.modificationDate)}
                            </Moment>
                            <Moment interval={0} format={TIME_FORMAT}>
                              {formatTimeWithTZ(marker.modificationDate)}
                            </Moment>
                          </div>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                )
            )
          }
        </MarkerClusterer>
      }
      {trackMarkers && trackMarkers.length > 0 && (
        <Polyline
          path={trackMarkers.map((marker) => ({
            lat: parseFloat(marker.lat),
            lng: parseFloat(marker.lng),
          }))}
          geodesic={true}
          options={{
            strokeColor: "#6800f0",
            strokeOpacity: 1,
            strokeWeight: 7,
            icons: [
              {
                icon: lineSymbol,
                offset: "0",
                repeat: "100px",
              },
            ],
          }}
        />
      )}
      {isMapData && <ContactUserCard setData={setData} setPage={setPage} />}
      {/* {
          isMapData &&
          
        } */}
      {isMapData && (
        <div
          style={{
            position: "absolute",
            bottom: 35,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div className={styles.softHourTrackButtonWrapper}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSoftTracking}
                  onChange={handleSoftTracking}
                  className={styles.softHourCheckBox}
                  name="softHourCheckBox"
                />
              }
              label="Include Soft Tracking"
            />
          </div>
          <UserCardSlider snapList={snapList} visible={visible} jobs={data} />
        </div>
      )}
      {isMapData && <MapLegends />}
      {!isMapData && (
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <StartTracking />
          <WhoIsWorkingToday />
        </div>
      )}

      {isWorkHoursDialog && trackMarkers.length && (
        <WorkHourDetails
          job={currentJob}
          totalHoursWorkedInJob={totalHoursWorkedInJob}
          setTotalHoursWithinProximity={setTotalHoursWithinProximity}
          totalHoursWithinProximity={totalHoursWithinProximity}
          setTotalHoursOutsideProximity={setTotalHoursOutsideProximity}
          totalHoursOutsideProximity={totalHoursOutsideProximity}
          totalHardHoursWithinProximity={totalHardHoursWithinProximity}
          setTotalHardHoursWithinProximity={setTotalHardHoursWithinProximity}
        />
      )}
      {isPayUserDialog && (
        <PayUser
          job={currentJob}
          totalHoursWithinProximity={getDecimalHoursFromSeconds(
            totalHardHoursWithinProximity
          )}
        />
      )}
      {/*
          directions && 
            <DirectionsRenderer directions={directions} />
        */}
    </GoogleMap>
  ) : (
    <FullScreenLoaderCNO />
  );
}

export default React.memo(MapCNO);
