import React, { useEffect, useState } from 'react';
import FullScreenLoaderCNO from '../../../library/FullScreenLoaderCNO';
import { makeStyles } from '@material-ui/core';
const ActivityDetails = ({ activity, onClose,onActivityClick,search}) => {
    const [nodes, setNodes] = useState([]);
    const styles=useStyles();
    useEffect(() => {
        setNodes(activity.nodes || []);
    }, [activity.nodes]);

    const handleActivity=(index)=>{
        const node=activity.nodes[index];
        onActivityClick(activity.display_name,node.icon,node.name);
    }
    return (
        <div className={styles.activityIconContainer}>
            {nodes.length > 0 && (
                <div>
                    
                    {nodes.filter((item)=>{
                            return search.toLocaleLowerCase() === '' ? item : item.name.toLocaleLowerCase().includes(search)
                        }).map((node,index) => (
                        <div key={node.id} className={styles.activityElementsEmoji} onClick={()=>handleActivity(index)}>
                            <div className={styles.backgroundDisplay}>
                                <p className={styles.emoji}>{node.icon}</p>
                            </div>
                            <p className={styles.emojiName}>{node.name}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ActivityDetails;

const useStyles=makeStyles((theme)=>(
    {
        activityIconContainer:{
            display:'flex',
            flexDirection:'column',
            flexWrap: "wrap",
            justifyContent: "center", 
            gap: "20px", 
            padding: "10px" ,
        },
        activityElementsEmoji: {
    
            width:"100%",
            
            display:'flex',
            gap:'10px',
            alignItems:'center',
            borderRadius:'7px',
            '&:hover':{
                background:theme.colors.ITEM_HOVER,
            },
            '&:hover .backgroundDisplay': {
              background: 'none'
            },
            // border:'1px solid white',
            padding:'5px'
        
        },
        backgroundDisplay:{
            background:'#e4e6eb1f',
            borderRadius:'50%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        },
        emoji:{
            fontSize:"1.3rem",
            borderRadius:'50%',
            padding:'10px'
        },
        emojiName:{
            fontSize:"1.1rem",
            color:theme.colors.TEXT_COLOR,
        },
        
    }
))