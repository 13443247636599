import { Badge, Box } from '@material-ui/core'
import React from 'react'
import AvatarCNO from '../../../library/AvatarCNO'
import { IconMain, MetaText } from '../../../library'
import { PostReactions } from '../enums'

const PostReactionList = ({ reactions = [] }) => {

  return (
    <Box>
      {
        reactions.map(reaction => {
          const user = reaction.user;
          const resolvedReaction = PostReactions.getItem(reaction.type);
          return (
            <Box display='flex' alignItems='center' style={{ gap: 8, marginTop: '1rem', marginBottom: '1rem' }} >
              <Badge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={
                  <IconMain
                    name={resolvedReaction.icon}
                    size='1.1rem'
                    themeColorKey={'EMOJI_ICON_COLOR'}
                  />
                }
              >
                <AvatarCNO src={user.profilePic} />
              </Badge>

              <MetaText>{user.title}</MetaText>
            </Box>
          )

        })
      }
    </Box>
  )
}

export default PostReactionList