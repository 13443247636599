import { Dialog, makeStyles, Typography } from "@material-ui/core";
import { mdiDirections } from "@mdi/js";
import Icon from "@mdi/react";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import HeadingBar from "../HeadingBar/HeadingBar";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import RightsManager from "../../utils/RightsManager";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { apiTaskEdit } from "../../api";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

function EditJobLocationDetails({ isEditable, setIsEditable, job, setJob }) {
  const [loading, setLoading] = useState(false);

  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    const getLocation = async () => {
      await geocodeByAddress(job?.mapAddress)
        .then((results) => {
          setLocationData(results);
          console.log("jobLocation: ", results);
        })
        .catch((error) => {
          console.error("Location Error: ", error);
          toast.error("Location fetch error!!!");
        });
    };

    if (window?.google?.maps) {
      getLocation();
    }
  }, [job?.mapAddress]);

  const handleClose = () => setIsEditable(false);

  const handleUpdate = async (values) => {
    setLoading(true);

    let latitude = 0;
    let longitude = 0;

    await geocodeByAddress(values.jobLocation.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        latitude = lat;
        longitude = lng;
      });

    job.mapAddress = values.jobLocation.label;
    job.lat = latitude.toString();
    job.lng = longitude.toString();

    setJob({
      ...job,
      mapAddress: values.jobLocation.label,
      lat: latitude.toString(),
      lng: longitude.toString(),
    });

    await apiTaskEdit
      .post(job)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          toast.success("Job location edited successfully!!");
        } else {
          toast.error(
            "There is some issues in editing job detail, please try again!"
          );
        }
      })
      .catch((error) => {
        console.log("Edit Job location Error: ", error);
        toast.error(
          "There is some issues in editing job detail, please try again!"
        );
      });
    handleClose();
  };

  const validationSchema = yup.object({
    jobLocation: yup.object().required("Required!!!").nullable(),
  });

  const formik = useFormik({
    initialValues: {
      jobLocation: {
        label: job?.mapAddress,
        value: locationData,
      },
    },
    validationSchema,
    onSubmit: (values) => handleUpdate(values),
  });

  console.log("jobLocation: ", formik.values);

  const useStyles = makeStyles((theme) => ({
    container: {
      "&>*": {
        marginTop: theme.spacing(2),
      },
    },
    formTitle: {
      marginLeft: 5,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      "&>*": {
        marginTop: theme.spacing(2),
      },
    },
    paper: {
      padding: theme.spacing(2),
      background: theme.colors.DIALOG_BACKGROUND_COLOR,
      // color: theme.colors.DIALOG_TITLE_COLOR,
      borderRadius: 10,
      width: "70vw",
      height: "300px",
      maxWidth: "90vh",
    },
  }));
  const styles = useStyles();

  if (loading) {
    return <FullScreenLoaderCNO />;
  }

  return (
    <Dialog
      open={isEditable}
      className={styles.container}
      classes={{
        paper: styles.paper,
      }}
    >
      <HeadingBar title="Edit Location" onClose={handleClose} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          selectProps={{
            styles: {
              option: (provided) => ({
                ...provided,
                color: "brown",
              }),
            },
            value: formik.values.jobLocation,
            onChange: (value) => formik.setFieldValue("jobLocation", value),
          }}
        />
        <FormActionButtons rightText="Save" leftClick={handleClose} />
      </form>
    </Dialog>
  );
}

function JobLocationDetailsCard({ job, setJob }) {
  const isUserAdmin = RightsManager.isAdmin();

  const [isEditable, setIsEditable] = useState(false);

  const styles = useStyles();
  return (
    <>
      {isUserAdmin && isEditable && (
        <EditJobLocationDetails
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          job={job}
          setJob={setJob}
        />
      )}
      <div className={styles.container} onClick={() => setIsEditable(true)}>
        <Typography className={styles.cardTitle}>Location</Typography>
        <div className={styles.cardContent}>
          <div className={styles.row}>
            <Typography>{job.mapAddress || "Click to add location"}</Typography>
            <a
              // href="https://www.google.es/maps/dir/'52.51758801683297,13.397978515625027'/'52.49083837044266,13.369826049804715'"
              href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${job.mapAddress}`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <Icon path={mdiDirections} size="2rem" className={styles.icon} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobLocationDetailsCard;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`
  },
  cardTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
  },
  cardContent: {
    padding: theme.spacing(1),
    "&>*": {
      marginTop: theme.spacing(1),
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.colors.JOB_DETAIL_LOCATION_COLOR,
  },
  icon: {
    color: theme.colors.GET_DIRECTION_ICON_COLOR,
  },
}));
