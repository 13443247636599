import React, { useRef } from "react";
import domtoimage from "dom-to-image";
import VisitorPassContent from "./VisitorPassContent";

function Visitorpass({ appointment }) {
  console.log("b12", appointment);
  const reportTemplateRef = useRef(null);

  const handleGenerateImage = async () => {
    const input = reportTemplateRef.current;
    try {
      const dataUrl = await domtoimage.toPng(input);
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "visitor_pass.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error generating image:", error);
    }
  };

  return (
    <div>
      <div ref={reportTemplateRef}>
        <VisitorPassContent appointment={appointment} />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <button
          onClick={handleGenerateImage}
          style={{
            padding: "10px",
            background: "#305b80",
            color: "whitesmoke",
            marginTop: "10px",
          }}
        >
          Download Pass
        </button>
      </div>
    </div>
  );
}

export default Visitorpass;
