import { PostType } from "./enums";

export const groupReactionsByType = (postReactions) => {
  const groupedReactions = postReactions.reduce((prev, current) => {

    if (current.type === 0) return prev;

    if (!prev[current.type]) {
      prev = {
        ...prev,
        [current.type]: [current]
      }
      return prev;
    }

    prev = {
      ...prev,
      [current.type]: [
        ...prev[current.type],
        current,
      ]
    }

    return prev;

  }, {});

  return groupedReactions;
}

export const getPostsByType = (posts) => {
  return posts.filter(post => [PostType.ids.Post, PostType.ids.PostEventDiscussion].includes(post.postType))
}

export const addLevelToComments = (comments) => {
  // Create a map to store comments by their parent IDs
  const commentMap = new Map();

  // Group comments by their parent IDs
  comments.forEach(comment => {
    const parentId = comment.parentId || 'root'; // Use 'root' for top-level comments
    if (!commentMap.has(parentId)) {
      commentMap.set(parentId, []);
    }
    commentMap.get(parentId).push(comment);
  });

  // Function to recursively process comment threads and add levels
  const processCommentThread = (commentId, level) => {
    const result = [];
    const childComments = commentMap.get(commentId) || [];

    // Sort child comments by createdAt in descending order
    childComments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Process child comments and their children
    for (const childComment of childComments) {
      const processedChild = {
        ...childComment,
        level,
      };
      const nestedComments = processCommentThread(childComment.id, level + 1);
      result.push(processedChild, ...nestedComments);
    }

    return result;
  };

  // Process the comments starting from top-level comments (parentId = null)
  const result = processCommentThread('root', 0);

  return result;
};