import React, { useContext, useState, useEffect } from "react";
import {
  Modal,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  Dialog
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TestContext } from "../../common/contexts/TestContext";
import { useHistory } from "react-router-dom";
import HeadingBar from "../HeadingBar";
import FormBox from "../FormBox";
import FormActionButtons from "../FormActionButtons";
import { InputCNO, InputLabelCNO } from "../../library";
import SelectCNO from "../../library/SelectCNO";


const AddTestQuestion = () => {
  const {
    allTests,
    isQuestionsModalOpen,
    setIsQuestionsModalOpen,
    saveQuestion,
    newQuestion,
    setNewQuestion,
    editQuestionId,
    setEditQuestionId,
    setCurrentTestId,
    currentTestId,
  } = useContext(TestContext);
  const tillTablet = useMediaQuery("(max-width: 767px)");
  const styles = useStyles({ tillTablet });
  const history = useHistory();

  useEffect(() => {
    const pathParts = history.location.pathname.split("/");
    const testIdFromPath = parseInt(
      pathParts[pathParts.indexOf("testq") + 1],
      10
    );
    setCurrentTestId(testIdFromPath);
    setNewQuestion({
      type: "",
      question: "",
      options: [],
      imageUrl: "",
      videoUrl: "",
      answer: "",
    });
    setIsQuestionsModalOpen(true);
  }, [history.location.pathname]);

  useEffect(() => {
    if (isQuestionsModalOpen) {
      if (editQuestionId && currentTestId) {
        const test = allTests.find((test) => test.testId === currentTestId);
        if (test) {
          const question = test.questions.find((q) => q.id === editQuestionId);
          if (question) {
            setNewQuestion({
              type: question.type,
              question: question.question,
              options: question.options,
              imageUrl: question.imageUrl,
              videoUrl: question.videoUrl,
              answer: question.answer,
            });
          }
        }
      } else {
        setNewQuestion({
          type: "",
          question: "",
          options: [],
          imageUrl: "",
          videoUrl: "",
          answer: "",
        });
      }
    }
  }, [isQuestionsModalOpen, editQuestionId, currentTestId, allTests]);

  const handleClose = () => {
    setNewQuestion({
      type: "",
      question: "",
      options: [],
      imageUrl: "",
      videoUrl: "",
      answer: "",
    });
    setEditQuestionId(null);
    setIsQuestionsModalOpen(false);
  };

  return (
    <Dialog
      open={isQuestionsModalOpen}
      classes={{ paper: styles.paper }}
      className={styles.container}
    >
      <form onSubmit={(e) => { e.preventDefault(); saveQuestion();  }} className={styles.form}>
      <HeadingBar
          title={editQuestionId ? "Edit Question" : "Add New Question"}
          onClose={handleClose}
        />
        <FormBox   title={editQuestionId ? "Edit Question" : "Add New Question"}>
       
        <InputLabelCNO label={"SelectType Of Question"} isRequired={true} />
              <Select
                value={newQuestion.type}
                onChange={(e) =>
                  setNewQuestion({ ...newQuestion, type: e.target.value })   
                }
                fullWidth
               style={{backgroundColor : "white", color: '#444', marginTop :'0.5rem', borderRadius: "5px", width : "99%",
               }} 
               variant="outlined"
               margin="dense"  
               placeholder="Select question type"             
              >
                <MenuItem value="multiple_choice">Multiple Choice</MenuItem>
                <MenuItem value="checkbox">Checkbox</MenuItem>
                <MenuItem value="text_reply">Text</MenuItem>
                <MenuItem value="number_reply">Number</MenuItem>
                <MenuItem value="image">Image</MenuItem>
                <MenuItem value="video">Video</MenuItem>
              </Select>
             <br />
          <br /> 

          <InputCNO
              name="question"
              label="Question"
              value={newQuestion.question}
              onChange={(e) =>
                setNewQuestion({ ...newQuestion, question: e.target.value })
              }
              placeholder="Enter Question"
              fullWidth
              isRequired
            />
             {(newQuestion.type === "text_reply" ) && (
              <InputCNO
                name = "correctAnswer"
                label="Correct Answer"
                value={newQuestion.answer}
                onChange={(e) =>
                  setNewQuestion({ ...newQuestion, answer: e.target.value })
                }
                 placeholder = "Enter correct answer..."
                fullWidth
                required
              />
            )}

            {( newQuestion.type === "number_reply") && (
              <InputCNO
                name = "correctAnswer"
                label="Correct Answer"
                type = "number"
                value={newQuestion.answer}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^\d.-]/g, ''); 
                  setNewQuestion({ ...newQuestion, answer: value });
                }}
                onKeyDown={(e) => {
                  if (
                    (e.key === 'e' || e.key === 'E') ||
                    (!/[\d.-]/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key))
                  ) {
                    e.preventDefault();
                  }
                }}
                placeholder = "Enter correct answer..."
                fullWidth
                required
              />)}

{["image", "video"].includes(newQuestion.type) && (
              <>
                
                 <InputCNO
                 name = "correctAnswer"
                  label={
                    newQuestion.type === "image" ? "Image URL" : "Video URL"
                  }
                  fullWidth
                  value={
                    newQuestion.type === "image"
                      ? newQuestion.imageUrl
                      : newQuestion.videoUrl
                  }
                  onChange={(e) =>
                    setNewQuestion({
                      ...newQuestion,
                      imageUrl:
                        newQuestion.type === "image"
                          ? e.target.value
                          : newQuestion.imageUrl,
                      videoUrl:
                        newQuestion.type === "video"
                          ? e.target.value
                          : newQuestion.videoUrl,
                    })
                  }
                  placeholder = "Paste your URL here..."
                />
                
                <InputCNO
                  name = "correctAnswer"
                  label="Correct Answer"
                  fullWidth
                  value={newQuestion.answer}
                  onChange={(e) =>
                    setNewQuestion({ ...newQuestion, answer: e.target.value })
                  }
                  placeholder = "Enter correct answer..."
                  required
                />
                <br />
              </>
            )}

             {(newQuestion.type === "multiple_choice" ||
              newQuestion.type === "checkbox") && (
                <>
                 <InputLabelCNO label={"Options"}  isRequired={true}/> 
  
                {newQuestion.options.map((option, index) => (
                  <div key={index} >

                    <InputCNO
                     name = "options"
                      value={option}
                      onChange={(e) => {
                        const updatedOptions = [...newQuestion.options];
                        updatedOptions[index] = e.target.value;
                        setNewQuestion({
                          ...newQuestion,
                          options: updatedOptions,
                        });
                      }}
                      fullWidth
                      required
                    />

                    <Button className={styles.removeButton}
                      onClick={() => {
                        const updatedOptions = newQuestion.options.filter(
                          (_, i) => i !== index
                        );
                        setNewQuestion({
                          ...newQuestion,
                          options: updatedOptions,
                        });
                      }}
                    >
                      Remove
                    </Button>

                  </div>
                ))}

                <Button
                  className={styles.addOption}
                  onClick={() =>
                    setNewQuestion({
                      ...newQuestion,
                      options: [...newQuestion.options, ""],
                    })
                  }
                >
                  Add Option
                </Button>
                <br />
                <InputLabel>
                  {newQuestion.type === "multiple_choice"
                    ? "Correct Option"
                    : "Correct Options"}
                  :
                </InputLabel>
                {newQuestion.type === "multiple_choice" ? (
                  <Select
                    value={
                      newQuestion.answer !== null ? newQuestion.answer : ""
                    }
                    onChange={(e) =>
                      setNewQuestion({
                        ...newQuestion,
                        answer: parseInt(e.target.value, 10),
                      })
                      
                    }
                    style={{backgroundColor : "white",color:"#444", marginTop :'0.5rem', borderRadius: "5px", width : "99%",
                    }} 
                    variant="outlined"
                    margin="dense" 
                    fullWidth
                    isRequired = {true}
                  >
                    <MenuItem value="">Select Correct Option</MenuItem>
                    {newQuestion.options.map((option, index) => (
                      <MenuItem key={index} value={index}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  newQuestion.options.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={
                            newQuestion.answer
                              ? newQuestion.answer.includes(index)
                              : false
                          }
                          onChange={(e) => {
                            const updatedAnswers = e.target.checked
                              ? [...(newQuestion.answer || []), index]
                              : (newQuestion.answer || []).filter(
                                  (optIndex) => optIndex !== index
                                );
                            setNewQuestion({
                              ...newQuestion,
                              answer: updatedAnswers,
                            });
                          }}
                        />
                      }
                      label={option}
                    />
                  ))
                )}

                 {["image", "video"].includes(newQuestion.type) && (
              <>
                <TextField
                  label={
                    newQuestion.type === "image" ? "Image URL" : "Video URL"
                  }
                  fullWidth
                  value={
                    newQuestion.type === "image"
                      ? newQuestion.imageUrl
                      : newQuestion.videoUrl
                  }
                  onChange={(e) =>
                    setNewQuestion({
                      ...newQuestion,
                      imageUrl:
                        newQuestion.type === "image"
                          ? e.target.value
                          : newQuestion.imageUrl,
                      videoUrl:
                        newQuestion.type === "video"
                          ? e.target.value
                          : newQuestion.videoUrl,
                    })
                  }
                  margin="normal"
                />
                <br />
                <TextField
                  label="Correct Answer"
                  fullWidth
                  value={newQuestion.answer}
                  onChange={(e) =>
                    setNewQuestion({ ...newQuestion, answer: e.target.value })
                  }
                  margin="normal"
                />
                <br />
              </>
            )}
              
              </>
            )}

        </FormBox>


         <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
          
        />
        </form>
    </Dialog>

   
  );
};

export default AddTestQuestion;


const useStyles = makeStyles((theme) => ({
  container: {},
  paper: {
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    borderRadius: 10,
    width: "70vw",
    maxWidth: "90vw",
    margin: ({ tillTablet }) => (tillTablet ? 8 : 32),
  },
  form: {
    padding: "20px",
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    overflowX: "hidden",
    "&>*": {
      marginBottom: theme.spacing(3),
    },
  },
  optionsContainer: {
    marginTop: theme.spacing(2),
  },
  optionItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    width : "100%"
  },
  removeButton: {
    marginTop: theme.spacing(1),
    marginBottom : theme.spacing(1),
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
    padding: "0.3rem 1rem",
  },
  addOption : {
    marginTop: theme.spacing(1),
    marginBottom : theme.spacing(2),
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
    padding: "0.3rem 1rem",
  }
 
}));