import { Dialog, makeStyles, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { Fragment, useEffect, useState } from "react";
import RightsManager from "../../utils/RightsManager";
import HeadingBar from "../HeadingBar/HeadingBar";
import InputCNO from "../../library/InputCNO";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import * as icons from "@mdi/js";
import Icon from "@mdi/react";
import { useTheme } from "@material-ui/core";
import { updateService } from "../../actions/serviceActions";
import { useDispatch, useSelector } from "react-redux";

function EditServiceBudgetDetails({
  isEditable,
  setIsEditable,
  service,
  setBudget,
}) {
  const styles = useStyles();

  const dispatch = useDispatch();
  const { editserviceLoader } = useSelector((state) => state.services);

  const handleClose = () => setIsEditable(false);

  const handleUpdate = async (values) => {
    const ServiceBudgetPayload = {
      ...service,
      budget: values.budget ? values.budget : 0,
    };

    setBudget(values.budget ? values.budget : 0);

    dispatch(updateService(ServiceBudgetPayload));

    handleClose();
  };

  const validationSchema = yup.object({
    budget: yup.number().required("Budget is required."),
  });

  const formik = useFormik({
    initialValues: {
      budget: service.budget ? service.budget : 0,
    },
    validationSchema,
    onSubmit: (values) => handleUpdate(values),
  });

  return (
    <Dialog
      open={isEditable}
      className={styles.container}
      classes={{
        paper: styles.paper,
      }}
    >
      {editserviceLoader && <FullScreenLoaderCNO />}

      <HeadingBar title="Edit Service Budget" onClose={handleClose} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <InputCNO
          name="budget"
          label="Service Budget in $"
          formik={formik}
          placeholder="Enter Service Budget in $"
          icon={icons.mdiFormatText}
          fullWidth
        />

        <FormActionButtons rightText="Save" leftClick={handleClose} />
      </form>
    </Dialog>
  );
}

function BudgetDetailCard({ service }) {
  const theme = useTheme();

  const styles = useStyles();

  const isUserAdmin = RightsManager.isAdmin();
  const [isEditable, setIsEditable] = useState(false);
  const [budget, setBudget] = useState(null);

  useEffect(() => {
    setBudget(service?.budget);
  }, [service]);

  return (
    <>
      {isUserAdmin && isEditable && (
        <EditServiceBudgetDetails
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          service={service}
          setBudget={setBudget}
        />
      )}
      <div
        className={styles.container_ServiceBudgetDetailsCard}
        onClick={() => setIsEditable(true)}
      >
        <Typography className={styles.cardTitle}>Pricing Options</Typography>
        <div className={styles.cardContent}>
          <div className={styles.row}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>
                {budget && (
                  <p>
                    {`Budget : `}
                    <span style={{ color: "green" }}>{`$${budget}`}</span>
                  </p>
                )}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BudgetDetailCard;

const useStyles = makeStyles((theme) => ({
  container_ServiceBudgetDetailsCard: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
  },
  cardContent: {
    padding: theme.spacing(1),
    "&>*": {
      marginTop: theme.spacing(1),
    },
  },
  cardTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
  },
  row: {
    color: theme.colors.JOB_DETAIL_RATE_COLOR,
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    alignItems: "center",
  },
  formTitle: {
    marginLeft: 5,
  },
  form: {
    "&>*": {
      marginTop: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    borderRadius: 10,
    width: "70vw",
    maxWidth: "90vh",
  },
}));
