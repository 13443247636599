import React from "react";
import { InputToolbar, Composer, Send } from 'react-native-gifted-chat';
import Icon from '@mdi/react';
import { mdiSend } from '@mdi/js';
import ChatActions from "./ChatActions";
import AppLogo from "../../assets/appLogo.png";
import { Typography } from "@material-ui/core";
import LinkPreview from '@ashwamegh/react-link-preview';
import '@ashwamegh/react-link-preview/dist/index.css';
import { Skeleton } from "@material-ui/lab";

export const renderInputToolbar = (props, theme) => (
  <InputToolbar
    {...props}
    containerStyle={{
      backgroundColor: theme.colors.CHAT_MESSAGE_INPUT_TOOLBAR_CONTAINER_BACKGROUND_COLOR,
      marginTop: 10,
      borderRadius: 8,
      borderRightWidth: 2,
      borderRightColor: theme.colors.CHAT_MESSAGE_INPUT_TOOLBAR_CONTAINER_BORDER_COLOR,
      borderLeftWidth: 2,
      borderLeftColor: theme.colors.CHAT_MESSAGE_INPUT_TOOLBAR_CONTAINER_BORDER_COLOR,
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.CHAT_MESSAGE_INPUT_TOOLBAR_CONTAINER_BORDER_COLOR,
    }}
    textInputStyle={{
      marginLeft: 50,
    }}
    primaryStyle={{ alignItems: 'center' }}
  />
);

export const renderActions = (props, setText) => {
  console.log("props",props)
  return (
    <ChatActions props={props} setText={setText} />
  );
}

export const renderComposer = (props, theme) => (
  <Composer
    {...props}
    textInputStyle={{
      color: theme.colors.CHAT_SEND_INPUT_COLOR,
      backgroundColor: theme.colors.CHAT_SEND_INPUT_BACKGROUND_COLOR,
      borderRadius: 20,
      paddingTop: 8,
      paddingLeft: 15,
      borderWidth: 1,
      borderColor: theme.colors.CHAT_SEND_INPUT_BORDER_COLOR,
      padding: 5,
      margin: 5,
      height: 30,
    }}
    composerHeight={35}
    placeholderTextColor={theme.colors.CHAT_SEND_INPUT_PLACEHOLDER_COLOR}
  />
);

export const renderSend = (props, theme) => (
  <Send
    {...props}
    disabled={!props.text}
    containerStyle={{
      width: 40,
      height: 40,
      alignItems: 'center',
      justifyContent: 'center',
      margin: 4,
      // shadowOpacity: 0.35,
      // shadowOffset: { width: 2, height: 2 },
      // shadowColor: "#FEFEFE",
      // shadowRadius: 2
    }}
  >
    <Icon path={mdiSend} size="1.5rem" color={theme.colors.CHAT_SEND_ICON_COLOR} />
  </Send>

);



export const renderChatEmpty = (props, theme) => {
  return (
    <div style={{
      transform: 'scaleY(-1)',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src={AppLogo} alt="" style={{ width: '6rem' }} />
      <Typography
        style={{
          color: theme.colors.CHAT_EMPTY_TEXT_COLOR,
        }}
      >
        Send a message to connect!
      </Typography>
    </div>
  )
}

const CustomUrlPreview = ({ loading, preview }) => {

  console.log("Url Preview: ", preview, loading)

  if (loading) {
    return (
      <div>
        <Skeleton variant="rect" width={200} height={140} />
      </div>
    )
  }
  else {
    return (
      <div
        // target="_blank"
        // href={'https://youtube.com'}
        style={{
          background: '#fff',
          padding: 8
        }}
      >
        <img
          src={preview.img}
          alt=""
          style={{
            width: '200px',
            // height: '200px',
            objectFit: 'contain'
          }}
        />
        <div style={{
          width: '200px'
        }}>
          <p style={{
            // textDecoration: 'none',
            // color: '#0e0e0e',
            fontWeight: 'bold',
            marginBottom: 10
          }}>
            {preview.title}
          </p>
          <p style={{
            // color: '#0e0e0e',
            // textDecoration: 'none'
          }}>
            {preview.domain}
          </p>
        </div>
      </div>
    )
  }

}

export const renderUrlPreview = (url) => {
  return (
    <div>
      <LinkPreview url={url} render={CustomUrlPreview} />
    </div>
  )
}