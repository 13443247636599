import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getQuotationById, setQuotationById } from '../../actions/quotationActions';
import ScreenView from '../../components/ScreenView/ScreenView';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import QuotationDetails from './QuotationDetails';

function QuotationDetailsPage() {

  const dispatch = useDispatch();
  const { quotationById, allQuotationLoader, quotations } = useSelector(state => state.quotations);
  const { quotationDetailLoader } = allQuotationLoader;

  const { id } = useParams();

  useEffect(() => {
    const quotationId = id;

    const quotation = quotations.find(quotation => quotation.id === id);

    dispatch(getQuotationById(quotationId, quotation));
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(setQuotationById(null));
    };
  }, []);

  console.log("synccheck: ", quotationDetailLoader, quotationById);

  return (
    <div>
      <ScreenView
        content={
          quotationById
            ? <QuotationDetails quotation={quotationById} />
            : (quotationDetailLoader ? <FullScreenLoaderCNO /> : null)
        }
        headerTitle="Quotations"
      />
    </div>
  )
}

export default QuotationDetailsPage
