import { IconButton, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@material-ui/core';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import ConfirmationDialogCNO from '../../library/ConfirmationDialogCNO';
import { useDispatch } from 'react-redux';
import { deleteInvoiceItem } from '../../actions/invoiceItemActions';

function InvoiceItemMoreActions({ invoiceItem }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();

  const [isInvoiceItemDeleteConfirmDialog, setIsInvoiceItemDeleteConfirmDialog] = useState(false);

  const handleDeleteInvoiceItem = () => {

    dispatch(deleteInvoiceItem(invoiceItem));

  };

  return (
    <div className={GlobalStyles.container_MorePopover}>
      <List className={GlobalStyles.list}>

        <ListItem className={GlobalStyles.listItem} onClick={() => setIsInvoiceItemDeleteConfirmDialog(true)} >
          <ListItemText primary='Delete' />
          <ListItemIcon>
            <IconButton edge='end'>
              <Icon path={mdiDelete} size='1.5rem'
                color={theme.colors.ERROR_COLOR}
              />
            </IconButton>
          </ListItemIcon>
        </ListItem>

      </List>

      {
        isInvoiceItemDeleteConfirmDialog &&
        <ConfirmationDialogCNO
          open={isInvoiceItemDeleteConfirmDialog}
          onClose={() => setIsInvoiceItemDeleteConfirmDialog(false)}
          dialogTitle="Delete Invoice Item"
          messages={["Are you sure you want to to delete?"]}
          rightText="Delete"
          yesClick={handleDeleteInvoiceItem}
        />
      }

    </div>
  )
}

export default InvoiceItemMoreActions
