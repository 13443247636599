import { fade, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container_MapCNO: {
    width: '100%',
    height: 'calc(100vh - 70px)',
    // display: 'flex',
    // justifyContent: 'center',
  },
  outerInfoWindow: {
    '& .gm-style .gm-style-iw-c': {
      "paddingRight": '10px !important',
      'paddingBottom': '0px !important',
      'maxWidth': '500px !important',
      'maxHeight': '326px !important',
      'minWidth': '0px !important',
      position: 'absolute',
      'boxSizing': 'border - box',
      overflow: 'hidden',
      top: 0,
      left: 0,
      'transform': 'translate(-50 %, -100 %)',
      'backgroundColor': '#dd9191',
      'borderRadius': '8px',
      'boxShadow': '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    }
  },
  infoWindow: {
    background: 'white',
    color: 'black',
    fontSize: 14,
    width: 300,
    padding: theme.spacing(1),

  },
  infoWindowDescriptionWrapper: {
    '&>*:first-child': {
      marginBottom: theme.spacing(2)
    }
  },
  infoWindowDescription: {
    fontSize: '14px',
    fontWeight: '600'
  },
  infoWindowDate: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#1e1e1e'
  },
  softHourTrackButtonWrapper: {
    marginBottom: theme.spacing(1),
    background: fade(theme.colors.TRACK_SOFT_TRACK_WRAPPER_BACKGORUND_COLOR, 0.8),
    borderRadius: 5,
    border: `2px solid ${theme.colors.TRACK_SOFT_TRACK_WRAPPER_BORDER_COLOR}`,
    color: theme.colors.TRACK_SOFT_TRACK_TEXT_COLOR
  },
  softHourCheckBox: {
    marginLeft: 10
  },
  softHourLabel: {
    marginRight: 8,
  }
}));