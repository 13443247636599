import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import { useDispatch } from 'react-redux';
import { getMaterialIconString } from '../../utils/icons';
import { CurrencyIcon } from '../../utils/mappings';
import * as icons from '@mdi/js';
import { getTotalAmountValues } from '../../utils/InvoiceHelpers';
import { parseNumber } from '../../utils/Helpers';

function AddInvoiceDiscount({
  open,
  onClose,
  invoiceFormik,
  paymentSummaryValues,
  setPaymentSummaryValues
}) {

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      discount: paymentSummaryValues.discount,
      discountRate: paymentSummaryValues.discountRate,
    },
    validationSchema: yup.object({
      discount: yup.string().test('emptyString', 'Please enter valid value', value => !isNaN(value)),
      discountRate: yup.string().test('emptyString', 'Please enter valid value', value => !isNaN(value))
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("Edit Invoice Item: title: ", values);


    const paymentValues = getTotalAmountValues(paymentSummaryValues.amount, values.discount, paymentSummaryValues.taxRate, paymentSummaryValues.includeTax);

    setPaymentSummaryValues({ ...paymentValues, includeTax: paymentSummaryValues.includeTax });

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Add Invoice Discount"
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name="discount"
          label="Discount"
          placeholder="Enter discount"
          icon={icons[getMaterialIconString(CurrencyIcon[invoiceFormik.values.currency])]}
          formik={formik}
          value={!isNaN(formik.values.discount) ? formik.values.discount : ""}
          onChange={(e) => {

            const value = e.target.value;

            const discountValue = !isNaN(value) ? value : formik.values.discount;
            const discountRateValue = paymentSummaryValues.amount ? (
              ((parseFloat(discountValue) * 100) / parseFloat(paymentSummaryValues.amount)).toFixed(2)
            ) : "";

            formik.setFieldValue('discount', value === "" ? "" : discountValue);
            formik.setFieldValue('discountRate', !isNaN(discountRateValue) ? discountRateValue : "");


          }}
        />

        <InputCNO
          name="discountRate"
          label="Discount %"
          placeholder="Enter discount %"
          icon={mdiFormatText}
          formik={formik}
          value={!isNaN(formik.values.discountRate) ? formik.values.discountRate : ""}
          onChange={(e) => {

            const value = e.target.value;

            const discountRateValue = !isNaN(value) ? value : formik.values.discountRate;
            const discountValue = !isNaN(discountRateValue) ? parseFloat(discountRateValue * paymentSummaryValues.amount / 100).toFixed(2) : "";

            formik.setFieldValue('discount', !isNaN(discountValue) ? discountValue : "");
            formik.setFieldValue('discountRate', discountRateValue === "" ? "" : discountRateValue);

          }}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default AddInvoiceDiscount
