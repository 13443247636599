import React, { useState } from "react";
import {
  gif,
  location,
  emoji,
  media,
  tag,
  gifDisabledIcon,
} from "../../assets/AddMediaInPost";
import { makeStyles, Tooltip,Box } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiDotsHorizontal } from "@mdi/js";
import { PostMediaPicker } from "../../pages/PostsManagement/components";
import FeelingActivity from "../../pages/PostsManagement/components/FeelingActivity";
import GifDialog from "../../pages/PostsManagement/components/GifDialog";
import TagPeopleDialog from "../../pages/PostsManagement/components/TagPeopleDialog";
import MApDialog from "../../pages/PostsManagement/components/MApDialog";
import MorePostOptions from "./MorePostOptions";
const AddPostOptions = (props) => {
  //destructuring props
  const {
    onMediaSelect,
    formik,
    setFeeling,
    setSelectedActivity,
    onGifSelect,
    setTagsPeople,
    
    openTags,
    setOpenTags,
    openFeeling,
    setOpenFeeling,
    openGif,
    setOpenGif,
    openMap,
    setOpenMap,
    openMore,
    setOpenMore,
    
    gifDisabled,mediaDisabled
  } = props;

  const styles = useStyles();
  // const [openFeeling,setOpenFeeling]=useState(false);
  // const [openGif,setOpenGif]=useState(false);
  // const [openTags,setOpenTags]=useState(false);
  // const [openMap,setOpenMap]=useState(false);
  // const [openMore,setOpenMore]=useState(false);
  return (
    <>
      <div className={styles.container}>
        <div>
          <h3>Add to your post</h3>
        </div>
        <div className={styles.mediaOptions}>
          <Tooltip title="Image/Video" placement="top">
            <Box>
              <PostMediaPicker
                onMediaSelect={onMediaSelect}
                mediaDisabled={mediaDisabled}
              />
            </Box>
          </Tooltip>
          <Tooltip title="Tag People" placement="top">
            <img
              src={tag}
              alt="Tag People"
              className={styles.image}
              onClick={() => setOpenTags(true)}
            />
          </Tooltip>
          <Tooltip title="Feeling/Activity" placement="top">
            <img
              src={emoji}
              alt="Feeling/Activity"
              className={styles.image}
              onClick={() => setOpenFeeling(true)}
            />
          </Tooltip>
          <Tooltip title="Check in" placement="top">
            <img
              src={location}
              alt="Check in"
              className={styles.image}
              onClick={() => setOpenMap(true)}
            />
          </Tooltip>
          <Tooltip title="Gif" placement="top">
            <img
              src={gifDisabled ? gifDisabledIcon : gif}
              alt="Gif"
              className={styles.image}
              onClick={() => !gifDisabled && setOpenGif(true)}
            />
          </Tooltip>
          <Tooltip title="More" placement="top">
            <Icon
              path={mdiDotsHorizontal}
              size={1}
              onClick={() => setOpenMore(true)}
            />
          </Tooltip>
        </div>
      </div>

      {/* <FeelingActivity open={openFeeling} onClose={()=>setOpenFeeling(false)} formik={formik} setFeeling={setFeeling} setSelectedActivity={setSelectedActivity}/> */}
      {/* <GifDialog open={openGif} setOpenGif={setOpenGif} onGifSelect={onGifSelect} gifDisabled={gifDisabled}/> */}
      {/* <TagPeopleDialog open={openTags} setOpenTags={setOpenTags} setTagsPeople={setTagsPeople} formik={formik}/> */}
      {/* <MApDialog open={openMap} setOpenMap={setOpenMap}/> */}
      {/* <MorePostOptions open={openMore} setOpenMore={setOpenMore}/> */}
    </>
  );
};

export default AddPostOptions;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "2px",
    borderRadius: 10,
    display: "flex",
    padding: "10px 10px 0px 10px",
    columnGap: 5,
    justifyContent: "space-between",
    // border: "1px solid #3e4042",
    border:`1px solid ${theme.colors.ADD_POST_OPTION}`,
    marginBottom: "9px",
  },
  mediaOptions: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
  },
  image: {
    width: "35px",
    height: "35px",
  },
}));
