import { Typography } from '@material-ui/core';
import { mdiFormatText } from '@mdi/js';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import FormActionButtons from '../FormActionButtons';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { deleteQuotation } from '../../actions/quotationActions';

function QuotationConfirmDeleteDialog({ open, onClose, quotation, closeMoreActions }) {

  const confirmTextRef = quotation ? quotation.title.slice(0, 20).split("\n").join("").trim() : "";

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { allQuotationLoader: { deleteLoader } } = useSelector(state => state.quotations);

  const [isYesDisabled, setIsYesDisabled] = useState(true);

  const formik = useFormik({
    initialValues: {
      confirmText: ""
    },
    validationSchema: yup.object({
      confirmText: yup.string().required('Confirm Text is required')
    }),
    onSubmit: (values) => handleDelete(values)
  });

  const handleDelete = (values) => {
    dispatch(deleteQuotation(quotation));
    onClose();
  };

  const handleClose = () => {

    onClose();

    if (closeMoreActions) {
      closeMoreActions();
    }

  };

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Delete Quotation"
      loading={deleteLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name='confirmText'
          label={`Are you sure, you want to delete "${quotation.title}"?`}
          formik={formik}
          placeholder={confirmTextRef}
          icon={mdiFormatText}
          fullWidth
          autoFocus
          onChange={(e) => {
            const inputText = e.target.value;
            formik.setFieldValue("confirmText", inputText);
            const confirmTextLocal = confirmTextRef.split("\n").join(' ');
            setIsYesDisabled(!(inputText.trim() === confirmTextLocal.trim()));
          }}
        />

        <Typography style={{ marginTop: 20 }}>
          Please enter following text to delete job.
        </Typography>

        <Typography className={GlobalStyles.confirmDeleteText}>
          {confirmTextRef.split(' ').join(' ')}
        </Typography>

        <FormActionButtons
          rightText="Yes"
          disabled={isYesDisabled}
          leftClick={onClose}
          closeMoreActions={closeMoreActions}
        />

      </form>
    </DialogCNO>
  )
}

export default QuotationConfirmDeleteDialog
