import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_PricingCard: {
    background: ({ isCardActive }) => isCardActive ? theme.colors.PRICING_CARD_ACTIVE_BACKGROUND_COLOR : theme.colors.PRICING_CARD_BACKGROUND_COLOR,
    padding: '8px 20px',
    borderRadius: 10,
    border: ({ isCardActive }) => `2px solid ${isCardActive ? theme.colors.PRICING_CARD_ACTIVE_BORDER_COLOR : theme.colors.PRICING_CARD_BORDER_COLOR}`,
    boxShadow: ({ isCardActive }) => `2px 2px 0 0 ${isCardActive ? theme.colors.PRICING_CARD_SHADOW_COLOR : theme.colors.PRICING_CARD_ACTIVE_SHADOW_COLOR}`,
    '&:hover': {
      cursor: 'pointer',
    }
  },
  pricingType: {
    margin: '6px 0',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: ({ isCardActive }) => isCardActive ? theme.colors.PRICING_CARD_ACTIVE_TITLE_COLOR : theme.colors.PRICING_CARD_TITLE_COLOR,
  },
  pricingDetailsWrapper: {
    margin: '15px 0',
    '&>*:first-child': {
      fontSize: '1.3rem',
      color: ({ isCardActive }) => isCardActive ? theme.colors.PRICING_CARD_ACTIVE_PRICE_COLOR : theme.colors.PRICING_CARD_PRICE_COLOR
    },
    '&>*:last-child': {
      color: ({ isCardActive }) => isCardActive ? theme.colors.PRICING_CARD_ACTIVE_PRICE_TAGLINE_COLOR : theme.colors.PRICING_CARD_PRICE_TAGLINE_COLOR
    },
  },
  featureWrapper: {
    display: 'flex',
    alignItems: 'center',

    '&>*:first-child': {
      marginRight: 8
    },
    '&>*:last-child': {
      lineHeight: 2,
      color: ({ isCardActive }) => isCardActive ? theme.colors.PRICING_CARD_ACTIVE_FEATURE_COLOR : theme.colors.PRICING_CARD_FEATURE_COLOR,
      fontSize: '0.8rem'
    },
  }
}));