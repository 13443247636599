import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_SubscriptionDetails: {
    // border: '1px solid #1e1e1e',
    '&>*>*': {
      // padding: '0 8px'
    }
  },
  paymentsDueSummaryWrapper: {
   
  },
  paymentsDueSummaryHeader: {
    background: theme.colors.PAYMENT_SECTION_HEADER_BACKGROUND_COLOR,
    padding: '12px 8px',
    display: 'flex',
  },

  paymentsDueHeaderTitle: {
    fontSize: '1rem'
  },

  paymentsDueSummaryInfo: {

  },

  paymentsDueTableWrapper: {
    background: theme.colors.PAYMENT_SECTION_HEADER_BACKGROUND_COLOR,
    padding: '12px 8px',
    display: 'flex',
    border: '1px solid #222',
    borderRadius: '20px'
  },
  paymentsDueTableHeader: {
    background: theme.colors.PAYMENT_SECTION_HEADER_BACKGROUND_COLOR,
    padding: '12px 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  paymentsDueTableInfo: {
    flexDirection: 'row',
    padding: '16px 32px',
    // alignItems: 'center',
    '&>*': {
      marginRight: theme.spacing(4)
    }
  },

  dataGrid: {
    '&.MuiDataGrid-root .MuiDataGrid-colCellWrapper': {
      background: theme.colors.PAYMENT_TABLE_HEADER_BACKGROUND_COLOR
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell': {
      padingLeft: 8
    },
    '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': {
      display: 'none'
    }
  },

  paymentDataGridHeader: {
    color: theme.colors.PAYMENT_TABLE_HEADER_TEXT_COLOR, fontSize: '0.8rem',
  },

  noPaymentWrapper: {
    minHeight: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noPaymentText: {
    textAlign: 'center'
  },

  inTableBtn: {
    marginTop: -20,
    '&.MuiButton-solid': {
      padding: '0 16px'
    }
  },

  rowCell: {
    display: 'inline-block'
  },
  cellTop: {
    fontSize: '1rem'
  },
  cellBottom: {
    fontSize: '0.7rem'
  }

}));