import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEmployees,
  fetchMoreEmployees,
} from "../../actions/employeeActions";
import AddEmployee from "../../components/AddEmployee/AddEmployee";
import ProfileListings from "../../components/ProfileListings/ProfileListings";
import ScreenView from "../../components/ScreenView/ScreenView";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { ProfileMode } from "../../utils/mappings";
import EmployeeFilterModal from "./EmployeeFilterModal";

function EmployeeListPage() {
  const styles = useStyles();

  const dispatch = useDispatch();
  const {
    employees,
    allEmployeesLoader,
    hasMoreEmployees,
    isAddEmployeeDialog,
  } = useSelector((state) => state.employees);
  const { employeesLoader, addLoader, deleteLoader } = allEmployeesLoader;

  const suggestions = useSelector((state) => state.suggestions);

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (employees.length <= 0) {
      const employeesPayload = {
        pageIndex: 0,
        orderBy: "newest",
        searchText: "",
        SortDecending: true,
      };

      dispatch(fetchEmployees(employeesPayload));
    }
  }, []);

  const loadMore = () => {
    if (hasMoreEmployees) {
      const moreEmployeesPayload = {
        pageIndex: page + 1,
        orderBy: "newest",
        searchText: "",
      };

      dispatch(fetchMoreEmployees(moreEmployeesPayload));

      setPage(page + 1);
    }
  };

  return (
    <div className={styles.container_EmployeeListPage}>
      {(deleteLoader || addLoader) && <FullScreenLoaderCNO />}
      <ScreenView
        content={
          <ProfileListings
            mode={ProfileMode.Employee}
            profileList={employees}
            // profileList={suggestions.length ? suggestions : employees}
            profileLoading={employeesLoader}
            loadMore={loadMore}
            hasMore={hasMoreEmployees}
          />
        }
        secondarySidebar={<EmployeeFilterModal setPage={setPage} />}
        headerTitle="Employees"
      />

      {isAddEmployeeDialog && <AddEmployee />}
    </div>
  );
}

export default EmployeeListPage;

const useStyles = makeStyles((theme) => ({
  container_EmployeeManagementPage: {
    background: theme.colors.APP_BACKGROUND_COLOR,
  },
}));
