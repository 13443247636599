import React from 'react'
import { makeStyles } from '@material-ui/core'

const TextFieldEmojiInput = ({children}) => {
    const styles=useStyles();
  return (
    <div>
        <div className={styles.container}>
            
            {children}
        </div>
    </div>
  )
}

export default TextFieldEmojiInput;

const useStyles=makeStyles((theme)=>({
    container:{
        display:"flex",
        justifyContent:'flex-end',
        padding:"5px 20px 5px 10px"
    }
}))