import React, { Fragment, useState } from "react";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import LinkCNO from "../../library/LinkCNO";
import { Typography } from "@material-ui/core";
import Moment from "react-moment";
import MoreActionCNO from "../../library/MoreActionsCNO";
import AppointmentStatusButtonBar from "./AppointmentStatusButtonBar";
import AppointmentListCardPopover from "./AppointmentListCardPopover";
import JobTypeIcon from "../JobTypeIcon/JobTypeIcon";
import { formatTimeWithTZ, getCategory } from "../../utils/Helpers";
import { calendarStrings, TIME_FORMAT } from "../../utils/formatDate";
import RightsManager from "../../utils/RightsManager";
import ResponsiveLinesCNO from "../../library/ResponsiveLinesCNO/ResponsiveLinesCNO";
import AppointmentGroupedMedia from "./AppointmentGroupedMedia";
import clsx from "clsx";
import { LeaveType } from "../../utils/mappings";
import LeaveStatusButtonBar from "./LeaveStatusButtonBar";
import LeaveTypeIcon from '../LeaveTypeIcon';
import LeaveListCardPopover from "./LeaveListCardPopover";

function LeaveListCard({ appointment }) {
  const isUserAdmin = RightsManager.isAdmin();
  const isUserCustomer = RightsManager.isCustomer();

  // const category = getCategory(appointment.categories[0]);

  const GlobalStyles = useGlobalStyles();

  return (
    <div
      className={clsx(GlobalStyles.listCardContainer, GlobalStyles.listCardFW)}
    >
      <div className={GlobalStyles.cardInfoWrapper}>
        <LeaveTypeIcon/>
        <div className={GlobalStyles.cardInfo}>
          <LinkCNO to={`/appointment/${appointment.id}`}>
            <Typography className={GlobalStyles.cardTitle}>
              {LeaveType.getItem(appointment.leaveType).label}
            </Typography>
            <div className={GlobalStyles.datesWrapper}>
              <Typography className={GlobalStyles.cardDate}>
                <Fragment>
                  <span>From&nbsp;</span>
                  <Moment interval={0} calendar={calendarStrings}>
                    {formatTimeWithTZ(appointment.startDate)}
                  </Moment>
                  &nbsp;
                  <Moment interval={0} format={TIME_FORMAT}>
                    {formatTimeWithTZ(appointment.startTime)}
                  </Moment>
                  &nbsp;
                </Fragment>

                <Fragment>
                  <span>To&nbsp;</span>
                  <Moment interval={0} calendar={calendarStrings}>
                    {formatTimeWithTZ(appointment.endDate)}
                  </Moment>
                  &nbsp;
                  <Moment interval={0} format={TIME_FORMAT}>
                    {formatTimeWithTZ(appointment.endTime)}
                  </Moment>
                </Fragment>
              </Typography>
            </div>

            {appointment.duration && (
              <Typography
                style={{ marginTop: 4 }}
                className={GlobalStyles.cardInfoRowText}
              >
                {`Leave Duration : ${appointment.duration} days`}
              </Typography>
            )}
            {appointment.reportingManager && (
              <Typography
                style={{ marginTop: 4 }}
                className={GlobalStyles.cardInfoRowText}
              >
                Reporting Manager: {appointment.reportingManager}
              </Typography>
            )}
            
          </LinkCNO>
        </div>

        <div
          style={{ display: "flex", alignItems: "center", height: "1.8rem" }}
        >
          {(isUserAdmin || isUserCustomer) && (
            <MoreActionCNO>
              <LeaveListCardPopover appointment={appointment}/>
            </MoreActionCNO>
          )}
        </div>
      </div>
      <LinkCNO to={`/appointment/${appointment.id}`}>
        <pre className={GlobalStyles.cardDescription}>
          <ResponsiveLinesCNO
            text={appointment.description || "Leave Reason not added"}
            maxLine="10"
            ellipsis="..."
            trimRight
            basedOn="words"
          />
        </pre>
      </LinkCNO>
      <AppointmentGroupedMedia appointment={appointment} /> 
        {/* TODO : MODIFY IF NEEDED FOR LEAVE MEDIALINKS AND ATTACHMENTS  */}
      <LeaveStatusButtonBar appointment={appointment}/>
    </div>
  );
}

export default LeaveListCard;
