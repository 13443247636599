import React, {useState} from 'react';
import ScreenView from '../../components/ScreenView/ScreenView';
import QuotationFilterModal from './QuotationFilterModal';
import QuotationListing from './QuotationListing';

function QuotationListingPage() {

    const [page, setPage] = useState(0);

  return (
    <div>
      <ScreenView
        content={<QuotationListing page={page} setPage={setPage} />}
        secondarySidebar={<QuotationFilterModal setPage={setPage} />}
        headerTitle="Quotations"
      />
    </div>
  )
}

export default QuotationListingPage
