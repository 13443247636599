import React from 'react'
import RightsManager from '../../../utils/RightsManager';
import TooltipCNO from '../../../library/TooltipCNO';
import { ListItem, ListItemIcon, ListItemText, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiFormSelect } from '@mdi/js';
import Icon from '@mdi/react';
import { lighten } from '@material-ui/core/styles';
import LinkCNO from '../../../library/LinkCNO';

const FormBuilderMenu = () => {

  const isUserAdmin = RightsManager.isAdmin();

  const theme = useTheme();

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const styles = useStyles({ tillTablet });

  return (
    <>
      {
        isUserAdmin &&
        <>
          <LinkCNO to={'/forms/builder'}>
            <TooltipCNO title="Form Builder" arrow placement="right">
              <ListItem button className={styles.listItem}>
                <ListItemIcon>
                  <Icon path={mdiFormSelect} size='0.8rem' color={theme.colors.DRAWER_ITEM_ICON_COLOR} />
                </ListItemIcon>
                <ListItemText primary='Form Builder' />
              </ListItem>
            </TooltipCNO>
          </LinkCNO>

        </>
      }
    </>
  )
}

export default FormBuilderMenu;

const useStyles = makeStyles(theme => ({
  listItem: {
    borderRadius: 5,
    color: theme.colors.DRAWER_ITEM_TEXT_COLOR,
    height: 50
  },
  nestedListContainer: {
    background: lighten(theme.colors.SIDEBAR_BACKGROUND_COLOR, 0.1)
  },
  listItem1: {
    borderRadius: 5,
    color: theme.colors.DRAWER_ITEM_TEXT_COLOR,
    height: 36
  }
}))