import {
  mdiArrowRight,
  mdiCheck,
  mdiClose,
  mdiFileDocument,
  mdiSend,
  mdiSquareEditOutline
} from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateInvoice } from '../../actions/invoiceActions';
import CardStatusCNO from '../../library/CardStatusCNO/CardStatusCNO';
import { InvoiceStatus } from '../../utils/mappings';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';

function InvoiceStatusButtonBar({ invoice }) {

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();

  const handleStatusChange = (status) => {
    const payload = {
      ...invoice,
      status: status
    };

    dispatch(updateInvoice(payload));

  };

  return (
    <div className={GlobalStyles.cardStatusBarContainer}>

      <CardStatusCNO
        title="Draft"
        icon={mdiSquareEditOutline}
        isActive={invoice.status === InvoiceStatus.Draft}
        activeBorderColor={'#ffb366'}
      />

      <Icon path={mdiArrowRight} size='1.2rem' />

      <CardStatusCNO
        title="Ready"
        icon={mdiFileDocument}
        isActive={invoice.status === InvoiceStatus.Ready}
        activeBorderColor={'#ff8000'}
      />

      <Icon path={mdiArrowRight} size='1.2rem' />

      <CardStatusCNO
        title="Sent"
        icon={mdiSend}
        isActive={(invoice.status === InvoiceStatus.Sent) || (invoice.status === InvoiceStatus.SentAccepted) || (invoice.status === InvoiceStatus.SentRejected)}
        activeBorderColor={'#00cc00'}
        hasTopRightIcon={true}
        topRightIcon={
          invoice.status === InvoiceStatus.SentRejected ? mdiClose : mdiCheck
        }
        isTopRightActive={(invoice.status === InvoiceStatus.SentAccepted) || (invoice.status === InvoiceStatus.SentRejected)}
        topRightActiveBgColor={invoice.status === InvoiceStatus.SentAccepted ? "#2bb673"
          : invoice.status === InvoiceStatus.SentRejected && "#ef5647"}
        topRightActiveBorderColor={invoice.status === InvoiceStatus.SentAccepted ? "#2bb673"
          : invoice.status === InvoiceStatus.SentRejected && "#ef5647"}
        topRightActiveColor={"#cecece"}
      />

    </div>
  )
}

export default InvoiceStatusButtonBar
