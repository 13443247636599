import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import {
  apiInvoiceItemAssign,
  apiInvoiceItemCreate,
  apiInvoiceItemEdit,
  apiInvoiceItemRemove,
  apiInvoiceItemSearch
} from '../api/invoiceItem';
import { getHoursFromTimeline, parseDisplayNumber, parseNumber, sortArrayBasedOtherArray } from '../utils/Helpers';
import { RateType } from '../utils/mappings';
import {
  setInvoiceById,
  updateInvoice
} from './invoiceActions';
import LocalStorageConstants from '../utils/LocalStorageConstants';
import { AppConstants } from '../utils/AppConstants';
import { getTotalAmountValues } from '../utils/InvoiceHelpers';

export const SET_INVOICE_ITEMS = "SET_INVOICE_ITEMS";
export const SET_INVOICE_ITEM_BY_ID = "SET_INVOICE_ITEM_BY_ID";
export const SET_INVOICE_ITEM_LOADERS = "SET_INVOICE_ITEM_LOADERS";
export const TOGGLE_ADD_INVOICE_ITEM_DIALOG = "TOGGLE_ADD_INVOICE_ITEM_DIALOG";
export const SET_IS_NEW_INVOICE_ITEM_ADDED = "SET_IS_NEW_INVOICE_ITEM_ADDED";

export function setInvoiceItems(payload) {
  return (dispatch) => {

    const sortedItems = payload.sort((first, second) => first.itemOrder < second.itemOrder ? -1 : 1);
    dispatch({
      type: SET_INVOICE_ITEMS,
      payload: sortedItems
    });

  };
}

export function setIsNewInvoiceItemAdd(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_NEW_INVOICE_ITEM_ADDED,
      payload: payload
    });
  };
}

export function setInvoiceItemById(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_INVOICE_ITEM_BY_ID,
      payload: payload
    });
  };
}

export function setInvoiceItemLoader(loaderName, loaderValue) {
  return (dispatch) => {

    dispatch({
      type: SET_INVOICE_ITEM_LOADERS,
      payload: { loaderName, loaderValue }
    });
  };
}

export function toggleAddInvoiceItemDialog(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_INVOICE_ITEM_DIALOG,
      payload: status
    });
  };
}

export function fetchInvoiceItems(payload) {
  return async (dispatch) => {

    console.log("InvoiceItems: dispatched");

    dispatch(setInvoiceItemLoader('invoiceItemsLoader', true));

    const dataToSend = {
      pageSize: 100,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      startDate: null,
      endDate: null,
      assignedIds: [],
      categories: [],
      searchText: "",
      ids: [],
      invoiceId: "",
      ...payload
    };

    await apiInvoiceItemSearch.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          // const invoiceItems = [];
          let invoiceItemsResponse = response.data.data;

          invoiceItemsResponse = invoiceItemsResponse.map((invoiceItem, index) => {

            const hours = getHoursFromTimeline(invoiceItem.startDate, invoiceItem.endDate);
            const amount = invoiceItem.rateType === RateType.Hourly
              ? hours * invoiceItem.rate
              : invoiceItem.rate;

            return ({
              ...invoiceItem,
              hours: hours,
              // taxRate: 0,
              amount: parseNumber(amount, 'float'),
              itemOrder: invoiceItem.itemOrder ? invoiceItem.itemOrder : parseFloat(AppConstants.SORTING_MULTIPLIER * (index + 1))
            });
          });

          dispatch(setInvoiceItems(invoiceItemsResponse));

        } else {
          console.error("Error: Fetch InvoiceItem:");
        }

      })
      .catch((error) => {
        console.error("Error: Fetch InvoiceItem: ", error);
      })
      .finally(() => {
        dispatch(setInvoiceItemLoader('invoiceItemsLoader', false));
      });

  };
}

export function getInvoiceItemById(payload) {
  return async (dispatch) => {

    dispatch(setInvoiceItemLoader('invoiceItemDetailLoader', true));

    const dataToSend = {
      "pageSize": 100,
      "pageIndex": 0,
      "orderBy": "newest",
      "sortAscending": false,
      "startDate": null,
      "endDate": null,
      "assignedIds": [],
      "categories": [],
      "searchText": "",
      "ids": [],
      "invoiceId": "",
      ...payload
    };

    await apiInvoiceItemSearch.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          let invoiceItemsResponse = response.data.data;

          invoiceItemsResponse = invoiceItemsResponse.map((invoiceItem, index) => {

            const hours = getHoursFromTimeline(invoiceItem.startDate, invoiceItem.endDate);
            const amount = invoiceItem.rateType === RateType.Hourly
              ? hours * invoiceItem.rate
              : invoiceItem.rate;

            return ({
              ...invoiceItem,
              hours: hours,
              // taxRate: 0,
              amount: parseNumber(amount, 'float'),
              itemOrder: invoiceItem.itemOrder ? invoiceItem.itemOrder : parseFloat(AppConstants.SORTING_MULTIPLIER * (index + 1))
            });
          });

          dispatch(setInvoiceItemById(invoiceItemsResponse[0]));

        } else {
          console.error("Error: Fetch Invoice Item Details:");
        }

      })
      .catch((error) => {
        console.error("Error: Fetch Invoice Item Details: ", error);
      })
      .finally(() => {
        dispatch(setInvoiceItemLoader('invoiceItemDetailLoader', false));
      });


  };
}

export const addMultipleInvoiceItems = (itemsToAdd, invoice, onAddSuccess) => {
  return async (dispatch, getState) => {

    dispatch(setInvoiceItemLoader('addLoader', true));

    const { invoiceItems } = getState().invoiceItems;

    const maxOrder = invoiceItems.length ? Math.max(...(invoiceItems.map(item => item.itemOrder))) : AppConstants.SORTING_MULTIPLIER;

    const itemsToAddPromises = itemsToAdd.map(async (item, index) => {

      const payload = {
        title: item.title,
        description: item.description,
        categories: item.categories,
        status: item.status,
        startDate: item.startDate,
        endDate: item.endDate,
        startTime: item.startTime,
        endTime: item.endTime,
        invoiceItemAssignments: item.subTaskAssignments,
        rate: item.rate,
        rateType: item.rateType,
        target: item.target,
        targetType: item.targetType,
        invoiceId: invoice.id,
        itemOrder: maxOrder + (index + 1) * AppConstants.SORTING_MULTIPLIER,
      };

      const itemPromiseResponse = await apiInvoiceItemCreate.post(payload)
        .then(async (response) => {

          if (response && response.status === 200) {

            const invoiceItemId = response.data;

            const hours = getHoursFromTimeline(payload.startDate, payload.endDate);
            const amount = payload.rateType === RateType.Hourly
              ? hours * payload.rate
              : payload.rate;

            const newInvoiceItem = {
              ...payload,
              id: invoiceItemId,
              hours,
              amount: parseDisplayNumber(amount, 'float')
            };

            return newInvoiceItem;

          } else {
            return null;
          }

        });

      console.log("addMultipleInvoiceItems: itemPromiseResponse: ", itemPromiseResponse);

      return itemPromiseResponse;

    });

    let itemsAdded = await Promise.all(itemsToAddPromises);

    itemsAdded = itemsAdded.filter(item => item);

    dispatch(setInvoiceItems(itemsAdded));

    const { invoiceById } = getState().invoices;

    // sync oneInvoiceItem*** with invoices
    if (invoiceById && (invoiceById.id === invoice.id)) {
      const firstItem = itemsAdded[0];
      const updatedInvoice = {
        ...invoice,
        oneInvoiceItemId: firstItem.id,
        oneInvoiceItemTitle: firstItem.title,
        oneInvoiceItemCategories: firstItem.categories[0],
      };
      dispatch(setInvoiceById(updatedInvoice));
    }

    dispatch(setInvoiceItemLoader('addLoader', false));

  };
};

export function addInvoiceItem(payload, isFromInvoice = false, onAddSuccess, itemOrder = AppConstants.SORTING_MULTIPLIER) {
  return async (dispatch, getState) => {

    dispatch(setInvoiceItemLoader('addLoader', true));

    let { invoiceItems } = getState().invoiceItems;

    itemOrder = invoiceItems.length ? Math.max(...(invoiceItems.map(item => item.itemOrder))) + AppConstants.SORTING_MULTIPLIER : itemOrder;

    payload = { ...payload, itemOrder: itemOrder };

    const dataToSend = {
      title: "",
      description: "",
      categories: [],
      status: 0,
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      invoiceItemAssignments: [],
      rate: 0,
      rateType: 0,
      target: 0,
      targetType: 0,
      invoiceId: "",
      itemOrder: AppConstants.SORTING_MULTIPLIER,
      ...payload
    };

    await apiInvoiceItemCreate.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          invoiceItems = getState().invoiceItems.invoiceItems;

          const invoiceItemId = response.data;

          const hours = getHoursFromTimeline(payload.startDate, payload.endDate);
          const amount = payload.rateType === RateType.Hourly ?
            hours * payload.rate : payload.rate;

          const newInvoiceItem = {
            ...payload,
            id: invoiceItemId,
            amount: parseNumber(amount, 'float'),
            hours
          };

          const invoiceItemsTemp = [newInvoiceItem, ...invoiceItems];

          // sync oneInvoiceItem*** with invoices
          const { invoiceById } = getState().invoices;

          if (invoiceById.id === payload.invoiceId && !invoiceItems.length) {

            const targetInvoice = {
              ...invoiceById,
              oneInvoiceItemId: invoiceItemId,
              oneInvoiceItemTitle: payload.title,
              oneInvoiceItemCategories: payload.categories,
            };

            dispatch(setInvoiceById(targetInvoice));

          }

          dispatch(setInvoiceItems(invoiceItemsTemp));

          dispatch(setIsNewInvoiceItemAdd(true));

          if (onAddSuccess) onAddSuccess();

          !isFromInvoice && toast.success("Invoice Item created successfully.");

        } else {
          console.error("Error: Add Invoice Item: ", response);
          !isFromInvoice && toast.error("Error in creating Invoice Item.");
        }

      })
      .catch((error) => {
        console.error("Error: Add Invoice Item: ", error);
        !isFromInvoice && toast.error("Error in creating Invoice Item.");
      })
      .finally(() => {
        dispatch(setInvoiceItemLoader('addLoader', false));
      });

  };
}

export function updateInvoiceItem(payload, shouldUpdateOneInvoiceItem = false, onAddSuccess) {
  return async (dispatch, getState) => {

    dispatch(setInvoiceItemLoader('editLoader', true));

    const dataToSend = {
      id: "",
      title: "",
      description: "",
      categories: [],
      status: 2,
      customerId: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      paidAmount: 0,
      rate: 0,
      rateType: 2,
      paymentByCustomerStatus: 2,
      proximity: 50,
      visibility: 1,
      itemOrder: AppConstants.SORTING_MULTIPLIER,
      ...payload
    };

    await apiInvoiceItemEdit.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          const { invoiceItems } = getState().invoiceItems;

          const hours = getHoursFromTimeline(payload.startDate, payload.endDate);
          const amount = payload.rateType === RateType.Hourly ?
            hours * payload.rate : payload.rate;

          const newInvoiceItem = {
            ...payload,
            amount: parseNumber(amount, 'float'),
            hours
          };

          const invoiceItemsTemp = invoiceItems.map((item) => item.id === payload.id ? newInvoiceItem : item);

          dispatch(setInvoiceItemById(newInvoiceItem));
          dispatch(setInvoiceItems(invoiceItemsTemp));


          if (shouldUpdateOneInvoiceItem) {

            const { invoiceById } = getState().invoices;
            if (invoiceById.oneInvoiceItemId === payload.id) {

              const targetInvoice = {
                ...invoiceById,
                oneInvoiceItemId: payload.id,
                oneInvoiceItemCategories: payload.categories,
                oneInvoiceItemTitle: payload.title
              };

              dispatch(setInvoiceById(targetInvoice));

            }

          }

          if (onAddSuccess) onAddSuccess();

          toast.success("Invoice Item edited successfully.");

        } else {
          console.error("Error: Edit Invoice Item:");
          toast.error("Error in editing sub task.");
        }

      })
      .catch((error) => {
        console.error("Error: Edit Invoice Item: ", error);
        toast.error("Error in editing sub task.");
      })
      .finally(() => {
        dispatch(setInvoiceItemLoader('editLoader', false));
      });


  };
}

export function deleteInvoiceItem(payload) {
  return async (dispatch, getState) => {

    dispatch(setInvoiceItemLoader('deleteLoader', true));

    const invoiceItemId = payload.id;

    const dataToSend = {
      "id": invoiceItemId,
      "undo": false,
    };

    await apiInvoiceItemRemove.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          const { invoiceItems } = getState().invoiceItems;

          const invoiceItemsTemp = invoiceItems.filter((invoiceItem) => invoiceItem.id !== invoiceItemId);

          dispatch(setInvoiceItems(invoiceItemsTemp));

          const { invoiceById } = getState().invoices;

          console.log("deleteInvoiceItem: ", invoiceById.oneInvoiceItemId, invoiceItemId);

          if (invoiceById.oneInvoiceItemId === invoiceItemId) {

            let targetInvoice = invoiceById;

            if (invoiceItems.length === 1) {

              const paymentValues = getTotalAmountValues(0, targetInvoice.discount, targetInvoice.taxRate, targetInvoice.includeTax);

              targetInvoice = {
                ...invoiceById,
                ...paymentValues,
                oneInvoiceItemId: null,
                oneInvoiceItemCategories: [],
                oneInvoiceItemTitle: null
              };

              dispatch(updateInvoice(targetInvoice));

            } else {
              const nextOneInvoiceItem = invoiceItems[invoiceItems.length - 2];
              targetInvoice = {
                ...invoiceById,
                oneInvoiceItemId: nextOneInvoiceItem.id,
                oneInvoiceItemCategories: nextOneInvoiceItem.categories,
                oneInvoiceItemTitle: nextOneInvoiceItem.title
              };
            }

            dispatch(setInvoiceById(targetInvoice));

          }

          toast.success("Invoice Item deleted successfully.");

        } else {
          console.error("Error: Delete Invoice Item:");
          toast.error("Error in deleting sub task assignment.");
        }

      })
      .catch((error) => {
        console.error("Error: Delete Invoice Item: ", error);
        toast.error("Error in deleting sub task assignment.");
      })
      .finally(() => {
        dispatch(setInvoiceItemLoader('deleteLoader', false));
      });

  };
}

export function assignInvoiceItem(payload = []) {
  return async (dispatch, getState) => {

    dispatch(setInvoiceItemLoader('editLoader', true));

    const dataToSend = payload.invoiceItemAssignments;

    await apiInvoiceItemAssign.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          const { invoiceItems } = getState().invoiceItems;

          const invoiceItemsTemp = invoiceItems.map((item) => item.id === payload.id ? payload : item);

          dispatch(setInvoiceItems(invoiceItemsTemp));

          toast.success("Invoice Item assigned successfully.");

        } else {
          console.error("Error: Assign Invoice Item:");
          toast.error("Error in sub task assignment.");
        }

      })
      .catch((error) => {
        console.error("Error: Assign Invoice Item: ", error);
        toast.error("Error in sub task assignment.");
      })
      .finally(() => {
        dispatch(setInvoiceItemLoader('editLoader', false));
      });
  };
}

