import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import JobTypeIconSkeleton from '../JobTypeIconSkeleton/JobTypeIconSkeleton';

function JobCardSkeleton() {

  const useStyles = makeStyles(theme => ({
    container_JobCardSkeleton: {
      borderRadius: 10,
      padding: '16px',
      background: theme.palette.background.main,
      border: `2px solid ${theme.palette.background.light}`,
      marginBottom: 10
      // boxShadow: `2px 2px 6px -1px #2e2e2e`,
    },
    jobMeta: {
      display: 'flex',
    },
    meta: {
      marginLeft: theme.spacing(2),
      flex: 1,
      marginBottom: theme.spacing(1)
    },
    statusBar: {
      height: 35,
      marginTop: theme.spacing(2),
      borderRadius: 10
    }
  }));
  const styles = useStyles();

  return (
    <div className={styles.container_JobCardSkeleton}>
      <div className={styles.jobMeta}>
        <JobTypeIconSkeleton />
        <div className={styles.meta}>
          <Skeleton variant="text" animation="wave" width="40%" />
          <Skeleton variant="text" animation="wave" width="20%" />
          <Skeleton variant="text" animation="wave" width="30%" />
        </div>
      </div>
      <Skeleton variant="text" animation="wave" width="80%" />
      <Skeleton variant="rect" animation="wave" className={styles.statusBar} />
    </div>
  )
}

export default JobCardSkeleton
