import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  textField: {
    opacity: ({ disabled }) => disabled ? 0.8 : 1,
    borderRadius: 50,
    '& input': {
      background: theme.colors.SEARCH_INPUT_CONTAINER_BACKGROUND_COLOR,
      color: ({ color }) => (color || theme.colors.SEARCH_INPUT_COLOR),
      borderRadius: 50,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: ({ color }) => color ? color : theme.colors.SEARCH_INPUT_COLOR,
      background: 'white',
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: theme.colors.SEARCH_INPUT_CONTAINER_BACKGROUND_COLOR,
      borderRadius: 50,
    },
    '& .MuiOutlinedInput': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      "-webkit-box-shadow": `0 0 0 100px ${theme.colors.SEARCH_INPUT_CONTAINER_BACKGROUND_COLOR} inset`,
      "-webkit-text-fill-color": theme.colors.SEARCH_INPUT_COLOR,
      caretColor: ({ color }) => color ? color : theme.colors.SEARCH_INPUT_COLOR
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: ({ isBorderHighlight }) => isBorderHighlight && '2px solid orange'
    }
  },
}));