import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import ServiceDetails from "../../components/ServiceDetails";
import ScreenView from "../../components/ScreenView";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchServiceById, setServiceById } from "../../actions/serviceActions";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";


function ServiceDetailPage() {
  const dispatch = useDispatch();
  

  const { serviceById, services, serviceDetailLoader } = useSelector(
    (state) => state.services
  );

  const { id } = useParams();

  useEffect(() => {
    const serviceId = id;

    const service = services.find((service) => service.id === id);

    dispatch(fetchServiceById(serviceId, service));
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(setServiceById(null));
    };
  }, []);

  console.log("serviceById: ", serviceById);
  

  const styles = useStyles();
  return (
    <div className={styles.container}>
      <ScreenView
        content={
          serviceById ? (
            <ServiceDetails service={serviceById}  />
          ) : serviceDetailLoader ? (
            <FullScreenLoaderCNO />
          ) : null
        }
        headerTitle="Service Details"
        
      />
    </div>
  );
}

export default ServiceDetailPage;

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.dark,
    width: "100%",
    minHeight: "100vh",
  },
}));
