import { mdiCalendarStar } from '@mdi/js'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { fetchEvents, toggleAddEventDialog } from '../../../actions/eventActions'
import FullScreenLoaderCNO from '../../../library/FullScreenLoaderCNO'
import InfiniteScrollCNO from '../../../library/InfiniteScrollCNO'
import StickyWrapper from '../../../library/StickyWrapper'
import { useGlobalStyles } from '../../../utils/Styles/GlobalStyles'
import { AddEventFBDialog, AddEventDialog } from '../modals'
import AddEvent from './AddEvent'
import EventListItemCard from './EventListItemCard'
import EventListItemFW from './EventListItemFW'
import EventsFilterBar from './EventsFilterBar'
import Switcher from '../../../common/components/GridListToggle/Switcher'

const EventsListing = () => {

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { events, hasMoreEvents, eventsLoader, isAddEventDialog } = useSelector(state => state.events);
  const [isGridView,setIsGridView]=useState(false);
  useEffect(() => {
    dispatch(fetchEvents());
  }, []);

  const openAddEventDialog = () => {
    dispatch(toggleAddEventDialog(true));
  }

  const loadMore = () => {
    console.log("Loading more events...");
  }

  return (
    <div className={GlobalStyles.listingContainer}>

      <FullScreenLoaderCNO loading={eventsLoader} />

      <StickyWrapper>
        {/* <Switcher title='Events' isGridView={isGridView} setIsGridView={setIsGridView}/> */}
        <EventsFilterBar />
      </StickyWrapper>

      <InfiniteScrollCNO
        items={events}
        next={loadMore}
        hasMore={hasMoreEvents}
        loading={eventsLoader}
        renderListItem={(event) => (
          // <EventListItemFW key={event.id} event={event} />
          <EventListItemCard key={event.id} event={event} />
        )}
        notFoundTextMessage={'No Events Present'}
        notFoundIcon={mdiCalendarStar}
        showListCTAButtons={true}
        listCTAButtonsProps={{ addTitle: 'Add Event', exploreId: 4, onClickAdd: openAddEventDialog }}
        isGridView={isGridView} 
        setIsGridView={setIsGridView}
        mode={'events'}
      />

      {
        isAddEventDialog &&
        // <AddEventDialog />
        <AddEventFBDialog />
      }

    </div>
  )
}

export default EventsListing