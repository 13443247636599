import { toast } from "react-toastify";
import { apiAuth, apiAuthChangePassword } from "../api";
import { ThemeType } from "../utils/mappings";
import LocalStorageConstants from "../utils/LocalStorageConstants";
import { getMediaUploadItemsKey } from "../utils/MediaUpload";

export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const SET_AUTH_USER_PROFILE_PIC = "SET_AUTH_USER_PROFILE_PIC";
export const SET_AUTH_LOADERS = "SET_AUTH_LOADERS";
export const SET_IS_OLD_PASSWORD_VALID = "SET_IS_OLD_PASSWORD_VALID";

export const setIsOldPasswordValid = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_OLD_PASSWORD_VALID,
      payload: payload,
    });
  };
};

export const setAuthLoaders = (loaderName, loaderStatus) => {
  return (dispatch) => {
    dispatch({
      type: SET_AUTH_LOADERS,
      payload: { loaderName, loaderStatus },
    });
  };
};

export function login(username, password, token = "") {
  return async function doLoginThunk(dispatch, getState) {
    dispatch(setAuthLoaders("logInLoader", true));

    await apiAuth
      .post({ username, password, token })
      .then((response) => {
        console.log("authResponse: ", response);

        dispatch({
          type: LOG_IN,
          payload: response,
        });
        toast.success("Logged In Successfully!!!", {
          delay: 6000,
        });
      })
      .catch((error) => {
        toast.error("Invalid username/password!!!");
        console.log("====================================");
        console.log("Auth Error: ", error);
        console.log("====================================");
      })
      .finally(() => {
        let themeMode = localStorage.getItem("theme");

        const isDarkTheme = window.matchMedia(
          "(prefers-color-scheme: dark)"
        ).matches;
        const systemTheme = isDarkTheme ? ThemeType.Dark : ThemeType.Light;

        console.log(
          "================themeMode====================",
          themeMode,
          typeof themeMode
        );

        themeMode = themeMode ? themeMode : systemTheme;

        localStorage.setItem("theme", themeMode);

        dispatch(setAuthLoaders("logInLoader", false));
      });
  };
}

export const logout = () => {
  return async (dispatch) => {
    // const themeMode = localStorage.getItem('theme');
    // const hasShownLoginIntroVideo = localStorage.getItem('hasShownLoginIntroVideo');
    // const hasShownLandingIntroVideo = localStorage.getItem('hasShownLandingIntroVideo');

    localStorage.removeItem(getMediaUploadItemsKey("loaders"));

    const localStorageKeys = Object.keys(localStorage);

    const keysToExclude = [
      "theme",
      "hasShownLoginIntroVideo",
      "hasShownLandingIntroVideo",
      "trialEndDate",
      "customThemeColors",
      "medias_pending_",
      "old",
    ];

    localStorageKeys.forEach((key) => {
      if (!keysToExclude.some((eKey) => key.includes(eKey))) {
        localStorage.removeItem(key);
      }
    });

    dispatch({
      type: LOG_OUT,
      payload: "",
    });
  };
};

export const setAuthUserProfilePic = (payload) => {
  console.log("while posting profilePic",payload)
  return (dispatch) => {
    dispatch({
      type: SET_AUTH_USER_PROFILE_PIC,
      payload: payload,
    });
  };
};

export const updateAuthPassword = (payload) => {
  return async (dispatch) => {
    dispatch(setAuthLoaders("updateLoader", true));

    payload = {
      username: "",
      password: "",
      ...payload,
    };

    await apiAuthChangePassword
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          console.log("====updateAuthPassword====", response.data);

          toast.success("Password updated successfully.");
        } else {
          console.error("Error Response: Update Password: ", response);

          toast.error("There is some issue in updating password.");
        }
      })
      .catch((error) => {
        console.error("Error: Update Password: ", error);

        toast.error("There is some issue in updating password.");
      })
      .finally(() => {
        dispatch(setAuthLoaders("updateLoader", false));
      });
  };
};

export const validateOldPassword = (payload) => {
  return async (dispatch) => {
    await apiAuth
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setIsOldPasswordValid(true));
        } else {
          dispatch(setIsOldPasswordValid(false));

          toast.error(
            "Old password didn't match, please enter a valid password."
          );
        }
      })
      .catch((error) => {
        toast.error(
          "Old password didn't match, please enter a valid password."
        );

        dispatch(setIsOldPasswordValid(false));
      });
  };
};
