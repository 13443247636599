import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { mdiFormatText } from '@mdi/js';
import clsx from 'clsx';
import React, { useRef } from 'react';
import ChipCNO from '../../library/ChipCNO';
import InputCNO from '../../library/InputCNO';
import {
  SnapList,
  SnapItem,
  useDragToScroll,
} from 'react-snaplist-carousel';
import InputLabelCNO from '../../library/InputLabelCNO';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';

function JobDetailsForm({ formik }) {

  const theme = useTheme();

  let localCategories = localStorage.getItem('categories');
  localCategories = localCategories ? JSON.parse(localCategories) : [];

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div>
        <InputLabelCNO label={'Category'} isRequired={true} />
        <SnapList ref={snapList} className={styles.jobChipSlider}>
          {
            localCategories.map((category) => (
              <SnapItem>
                <ChipCNO
                  icon={category.iconLink}
                  name={category.title}
                  isCategoryChip={true}
                  active={formik.values.jobCategory === category.id}
                  onClick={() => formik.setFieldValue('jobCategory', category.id)}
                />
              </SnapItem>
            ))
          }
        </SnapList>
      </div>
      <InputCNO
        name='jobTitle'
        label='Job Title'
        formik={formik}
        placeholder='Enter Job Title'
        icon={mdiFormatText}
        fullWidth
        isRequired
      />
      <InputCNO
        name='jobDescription'
        label='Job Description'
        formik={formik}
        placeholder='Enter Job Details'
        fullWidth
        multiline
      />


      <DateTimePickerCNO
        dateName="startDate"
        datePlaceholder="Start Date"
        datePickerLabel='Start by Date'
        onDateChange={date => {
          formik.setFieldValue('startDate', date);
        }}

        timeName="startTime"
        timePlaceholder="Start Time"
        timePickerLabel='Start by Time'
        onTimeChange={date => {
          formik.setFieldValue('startTime', date);
        }}

        formik={formik}
        disablePast={true}
        iconColor="red"
      />

      <DateTimePickerCNO
        dateName="endDate"
        datePlaceholder="Finish Date"
        datePickerLabel='Finish by Date'
        onDateChange={date => {
          formik.setFieldValue('endDate', date);
        }}

        timeName="endTime"
        timePlaceholder="Finish Time"
        timePickerLabel='Finish by Time'
        onTimeChange={date => {
          formik.setFieldValue('endTime', date);
        }}

        formik={formik}
        minDate={formik.values.startDate}
        iconColor="green"
      />

    </div>
  )
}

export default JobDetailsForm

const useStyles = makeStyles(theme => ({
  container: {
    '&>*': {
      marginTop: theme.spacing(2)
    }
  },
  jobChipSlider: {
    display: 'flex',
    padding: '5px 0',
    // width: 'calc(100vw - 85px)',
    // overflowX: 'auto',
    '&>*:not(:first-child)': {
      marginLeft: 15
    }
  },
  timeStampWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  picker: {
    '& .MuiInputBase-root': {
      background: 'white',
      color: '#444'
    },
    '& .MuiIconButton-label': {
      color: '#444'
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: 5,
    },
    '& .MuiIconButton-root': {
      paddingLeft: 0,
      paddingRight: 5,
    }
  },
  start: {
    '& .MuiSvgIcon-root': {
      color: '#BE0A02'
    }
  },
  end: {
    '& .MuiSvgIcon-root': {
      color: '#3F8A02'
    }
  },
  time: {
    marginLeft: theme.spacing(2),
    // maxWidth: '42%',
    flex: 1
  }
}))