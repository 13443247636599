import React, { useEffect, useState } from "react";
import { useStyles } from "../../../library/DialogCNO/DialogCNOStyles";
import { Dialog, useTheme, Button,Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  Select,
  Grid,
  MenuItem,
  div,
  OutlinedInput,
} from "@material-ui/core";
import HeadingBar from "../../../components/HeadingBar";
import { mdiArrowLeft } from "@mdi/js";
import SelectCNO from "../../../library/SelectCNO";
import { field } from "../../../actions/AddToPostData/MoreFiltersFieldArray";
import { useGlobalStyles } from "../../../utils/Styles/GlobalStyles";

const MoreFilteringDialogue = ({ open, onClose, onApplyFilters }) => {
  const [filterValue, setFilterValue] = useState({
    goto: ``,
    postedBy: "Anyone",
    privacy: "All Posts",
    taggedPosts: "All Posts",
    showHiddenPosts: "false"
  });

  const [yearOptions, setYearOptions] = useState([]);
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  const width = "40vw";
  const maxHeight = "95vh";
  const minHeight = 250;

  const theme = useTheme();
  const styles = useStyles({ width, maxHeight, minHeight });
  const GlobalStyles = useGlobalStyles();
  const { hiddenPostsList} = useSelector(
    (state) => state.posts
  );
  useEffect(() => {
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let year = currentYear; year >= currentYear - 20; year--) {
      years.push({ label: year, value: year });
    }

    setYearOptions(years);
  }, []);

  const onChangeHandler = (name, value) => {
    setFilterValue((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleApplyFilters = () => {
    const filters = {
      createdAt: filterValue.goto,
      postedBy: filterValue.postedBy,
      privacy: filterValue.privacy,
      taggedPosts: filterValue.taggedPosts,
      showHiddenPosts:filterValue.showHiddenPosts,
    };
    onApplyFilters(filters);
    onClose();
  };

  const handleClear = () => {
    setFilterValue({
      goto: "",
      postedBy: "Anyone",
      privacy: "All Posts",
      taggedPosts: "All Posts",
      showHiddenPosts:'false'
    });

    const filters = {
      createdAt: "",
      postedBy: "Anyone",
      privacy: "All Posts",
      taggedPosts: "All Posts",
      showHiddenPosts:'false'

    };
    onApplyFilters(filters);
    onClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        className={styles.container_DialogCNO}
        classes={{ paper: styles.paper }}
      >
        <HeadingBar
          title={"Post Filters"}
          onClose={onClose}
          icon={mdiArrowLeft}
          backTop={true}
        />
        <div>
          <b>
            <h3>Use filters to find posts on your timeline.</h3>
          </b>
          <p>This will not affect how others see your timeline.</p>
          <div className={styles.root}>
            <Box className={styles.scrollableContent}>
              <Grid item xs={6}>
                <SelectCNO
                  name={field[0].name}
                  label={field[0].label}
                  options={
                    yearOptions.length > 0
                      ? yearOptions
                      : field[0].attributes.options
                  }
                  fullWidth
                  containerStyles={{ width: "100%" }}
                  value={filterValue.goto}
                  onChange={(v) => onChangeHandler(field[0].name, v)}
                />
              </Grid>

              <Grid item xs={6}>
                <SelectCNO
                  name={field[1].name}
                  label={field[1].label}
                  options={field[1].attributes.options}
                  fullWidth
                  containerStyles={{ width: "100%" }}
                  value={filterValue.postedBy}
                  onChange={(v) => onChangeHandler(field[1].name, v)}
                />
              </Grid>

              <Grid item xs={6}>
                <SelectCNO
                  name={field[2].name}
                  label={field[2].label}
                  options={field[2].attributes.options}
                  fullWidth
                  containerStyles={{ width: "100%" }}
                  value={filterValue.privacy}
                  onChange={(v) => onChangeHandler(field[2].name, v)}
                />
              </Grid>

              <Grid item xs={6}>
                <SelectCNO
                  name={field[3].name}
                  label={field[3].label}
                  options={field[3].attributes.options}
                  fullWidth
                  containerStyles={{ width: "100%" }}
                  value={filterValue.taggedPosts}
                  onChange={(v) => onChangeHandler(field[3].name, v)}
                />
              </Grid>

              <Grid item xs={6}>
                <SelectCNO
                  name={field[4].name}
                  label={field[4].label}
                  options={field[4].attributes.options}
                  fullWidth
                  containerStyles={{ width: "100%" }}
                  value={filterValue.showHiddenPosts}
                  onChange={(v) => onChangeHandler(field[4].name, v)}
                  disabled={hiddenPostsList?.length == 0}
                />
              </Grid>
            </Box>
          </div>
          <div>
            <div className={styles.buttonWrapper}>
              <Button onClick={handleClear} className={GlobalStyles.greyButton}>
                Clear
              </Button>

              <Button
                variant="contained"
                className={GlobalStyles.greenButton}
                onClick={handleApplyFilters}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default MoreFilteringDialogue;
