import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateInvoiceFromJob } from '../../actions/invoiceActions';
import { getTaskById } from '../../actions/jobActions';
import { fetchSubTasks } from '../../actions/subTaskActions';
import { fetchTaskSuggest } from '../../actions/suggestionsActions';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import { AddInvoiceDialogMode } from '../../utils';
import { FinishedSwitch } from '../JobsTimeFilterBar/JobsTimeFilterBar';

function AddInvoiceJobDetails({
  formik,
  copyMedias,
  setCopyMedias
}) {

  const dispatch = useDispatch();
  const suggestions = useSelector(state => state.suggestions);
  const { mode } = useSelector(state => state.invoices);

  const isAdd = mode === AddInvoiceDialogMode.Add;
  const isGenerateFromJob = mode === AddInvoiceDialogMode.GenerateFromJob;
  const isGenerateFromQuotation = mode === AddInvoiceDialogMode.GenerateFromQuotation;

  return (
    <div>
      <AutocompleteCNO
        label={`Select ${isGenerateFromQuotation ? 'Quotation' : 'Job'}`}
        name='task'
        isRequired={true}
        placeholder='Search Job...'
        formik={formik}
        data={suggestions}
        optionLabel={x => x.title || ""}
        renderOption={option => <div style={{ color: 'orangered' }}>{option.title}</div>}
        // fullWidth
        onSuggestionClick={(value) => {
          console.log("job clicked ", value);
          if (value) {

            formik.setFieldValue('task', value);

            dispatch(generateInvoiceFromJob(value.id));

          }
        }}
        onChange={(value) => {
          if (value) {
            dispatch(fetchTaskSuggest(value));
          }
        }}
        disabled={isGenerateFromJob || isGenerateFromQuotation}
      />

      <div
        style={{
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        {/* TODO: update it with SwitchCNO after dashboard */}
        <Typography style={{ fontSize: '1rem' }}>Copy medias to invoice</Typography>
        <FinishedSwitch
          checked={copyMedias}
          onChange={() => setCopyMedias(v => !v)}
          name="copyMedias"
        />
      </div>
    </div>
  )
}

export default AddInvoiceJobDetails

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.colors.FORM_LABEL_COLOR
  }
}))