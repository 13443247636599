// import React, { useState, useEffect } from "react";
// import { makeStyles, useMediaQuery, TextField, Chip } from "@material-ui/core";
// import HeadingBar from "../HeadingBar/HeadingBar";
// import { mdiRestore, mdiClose } from "@mdi/js";
// import { TOGGLE_SEARCH } from "../../utils/actions";
// import { useGlobalStore } from "../../providers/GlobalProvider";
// import { ChipCNO } from "../../library";

// const questionTypes = [
//   "Text",
//   "Number",
//   "Multiple Choice",
//   "Checkbox",
//   "Image",
//   "Video",
// ];

// function TestQuestionSearchSidebar({ setSearchParams }) {
//   const tillTablet = useMediaQuery("(max-width: 767px)");
//   const [localSearchParams, setLocalSearchParams] = useState({
//     questionTypes: [],
//     questionSearch: "",
//   });

//   const { dispatchGlobal } = useGlobalStore();

//   useEffect(() => {
//     setSearchParams(localSearchParams);
//   }, [localSearchParams, setSearchParams]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setLocalSearchParams((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleQuestionTypeClick = (questionType) => {
//     setLocalSearchParams((prev) => {
//       const newQuestionTypes = prev.questionTypes.includes(questionType)
//         ? prev.questionTypes.filter((type) => type !== questionType)
//         : [...prev.questionTypes, questionType];
//       return { ...prev, questionTypes: newQuestionTypes };
//     });
//   };

//   const handleReset = () => {
//     setLocalSearchParams({
//       questionTypes: [],
//       questionSearch: "",
//     });
//   };

//   const handleClose = () => {
//     dispatchGlobal({
//       type: TOGGLE_SEARCH,
//       payload: false,
//     });
//   };

//   const styles = useStyles({ tillTablet });

//   return (
//     <div className={styles.container}>
//       <HeadingBar
//         title={
//           localSearchParams.questionTypes.length > 0 ||
//           localSearchParams.questionSearch
//             ? "Reset"
//             : "Search"
//         }
//         icon={
//           localSearchParams.questionTypes.length > 0 ||
//           localSearchParams.questionSearch
//             ? mdiRestore
//             : null
//         }
//         onHeaderClick={
//           localSearchParams.questionTypes.length > 0 ||
//           localSearchParams.questionSearch
//             ? handleReset
//             : null
//         }
//         closeIcon={mdiClose}
//         onClose={handleClose}
//       />
//       <div className={styles.content}>
//         <div className={styles.questionTypeList}>
//           {questionTypes.map((type) => (
//             <ChipCNO
//               key={type}
//               name={type}
//               icon="bullseye"
//               clickable
//               active={localSearchParams.questionTypes.includes(type)}
//               onClick={() => handleQuestionTypeClick(type)}
//               isCategoryChip={true}
//             />
//           ))}
//         </div>
//         <TextField
//           label="Search Questions"
//           name="questionSearch"
//           value={localSearchParams.questionSearch}
//           onChange={handleChange}
//           fullWidth
//           margin="normal"
//         />
//       </div>
//     </div>
//   );
// }

// export default TestQuestionSearchSidebar;

// const useStyles = makeStyles((theme) => ({
//   container: {
//     padding: theme.spacing(1),
//     "&>*:first-child": {
//       height: ({ tillTablet }) => (tillTablet ? "auto" : 68),
//     },
//   },
//   content: {
//     "&>div": {
//       marginTop: theme.spacing(2),
//       "&:first-child": {
//         marginTop: 0,
//       },
//     },
//   },
//   questionTypeList: {
//     display: "flex",
//     flexWrap: "wrap",
//     "&>div": {
//       margin: theme.spacing(0.5),
//     },
//   },
//   activeQuestionType: {
//     backgroundColor: theme.palette.primary.main,
//   },
// }));

import React, { useState, useEffect } from "react";
import { makeStyles, useMediaQuery, TextField, Chip } from "@material-ui/core";
import HeadingBar from "../HeadingBar/HeadingBar";
import { mdiRestore, mdiClose } from "@mdi/js";
import { TOGGLE_SEARCH } from "../../utils/actions";
import { useGlobalStore } from "../../providers/GlobalProvider";
import { ChipCNO } from "../../library";

const questionTypes = [
  "text_reply",
  "number_reply",
  "multiple_choice",
  "checkbox",
  "image",
  "video",
];

function TestQuestionSearchSidebar({ setSearchParams }) {
  const tillTablet = useMediaQuery("(max-width: 767px)");
  const [localSearchParams, setLocalSearchParams] = useState({
    questionTypes: [],
    questionSearch: "",
  });

  const { dispatchGlobal } = useGlobalStore();

  useEffect(() => {
    setSearchParams(localSearchParams);
  }, [localSearchParams, setSearchParams]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalSearchParams((prev) => ({ ...prev, [name]: value }));
  };

  const handleQuestionTypeClick = (questionType) => {
    setLocalSearchParams((prev) => {
      const newQuestionTypes = prev.questionTypes.includes(questionType)
        ? prev.questionTypes.filter((type) => type !== questionType)
        : [...prev.questionTypes, questionType];
      return { ...prev, questionTypes: newQuestionTypes };
    });
  };

  const handleReset = () => {
    setLocalSearchParams({
      questionTypes: [],
      questionSearch: "",
    });
  };

  const handleClose = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false,
    });
  };

  const styles = useStyles({ tillTablet });

  return (
    <div className={styles.container}>
      <HeadingBar
        title={
          localSearchParams.questionTypes.length > 0 ||
          localSearchParams.questionSearch
            ? "Reset"
            : "Search"
        }
        icon={
          localSearchParams.questionTypes.length > 0 ||
          localSearchParams.questionSearch
            ? mdiRestore
            : null
        }
        onHeaderClick={
          localSearchParams.questionTypes.length > 0 ||
          localSearchParams.questionSearch
            ? handleReset
            : null
        }
        closeIcon={mdiClose}
        onClose={handleClose}
      />
      <div className={styles.content}>
        <div className={styles.questionTypeList}>
          {questionTypes.map((type) => (
            <ChipCNO
              key={type}
              name={type}
              icon="bullseye"
              clickable
              active={localSearchParams.questionTypes.includes(type)}
              onClick={() => handleQuestionTypeClick(type)}
              isCategoryChip={true}
            />
          ))}
        </div>
        <TextField
          label="Search Questions"
          name="questionSearch"
          value={localSearchParams.questionSearch}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </div>
    </div>
  );
}

export default TestQuestionSearchSidebar;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    "&>*:first-child": {
      height: ({ tillTablet }) => (tillTablet ? "auto" : 68),
    },
  },
  content: {
    "&>div": {
      marginTop: theme.spacing(2),
      "&:first-child": {
        marginTop: 0,
      },
    },
  },
  questionTypeList: {
    display: "flex",
    flexWrap: "wrap",
    "&>div": {
      margin: theme.spacing(0.5),
    },
  },
  activeQuestionType: {
    backgroundColor: theme.palette.primary.main,
  },
}));
