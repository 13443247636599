import { useFormik } from 'formik';
import React, { Fragment } from 'react';
import DialogCNO from '../../library/DialogCNO';
import * as yup from 'yup';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons';
import FieldHelperTextCNO from '../../library/FieldHelperTextCNO';

function QuotationEmailMessageEdit({
  open,
  onClose,
  emailData,
  onSave
}) {

  const validationSchema = yup.object({
    message: yup
      .string()
      .matches(/<<Name>>/, "<<Name>> is required as it will be replaced by the Customer Name.")
      .matches(/<<Title>>/, "<<Title>> is required as it will be replaced by the Quotation Title.")
      .required('Message is required.')
  });

  const formik = useFormik({
    initialValues: {
      message: emailData
    },
    validationSchema,
    onSubmit: (values) => {
      if (onSave) onSave(values);
      onClose();
    }

  });

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Email Message"
    >

      <form onSubmit={formik.handleSubmit} >

        <InputCNO
          name='message'
          label='Message'
          formik={formik}
          placeholder={"Enter message"}
          multiline={true}
          fullWidth
          value={formik.values.message}
        />

        <FieldHelperTextCNO
          helperText={<Fragment>
            <div>In email following will be replaced as given below, please do not remove it from template:</div>
            <div>&lt;&lt;Name&gt;&gt; will be replaced by Customer Name</div>
            <div>&lt;&lt;Title&gt;&gt; will be replaced by Quotation Title</div>
          </Fragment>}
          showHelperText={true}
          errorColor={false}
        />

        <FormActionButtons
          rightText="Save"
          formik={formik}
          leftClick={onClose}
        />

      </form>

    </DialogCNO>
  )
}

export default QuotationEmailMessageEdit