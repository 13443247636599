import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_CompanySubscriptionDetailsCard: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    background: theme.colors.PROFILE_CARD_BACKGROUND_COLOR,
    padding: ({ tillTablet }) => tillTablet ? 12 : theme.spacing(4),
    borderRadius: 20
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.colors.PROFILE_CARD_TITLE_COLOR,
  },
  chipsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    // background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    '&>*': {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    }
  },
  sliderWrapper: {
    //display: 'inline-block',
    width: '100%',
    height: '150px',
    padding: 0
  },

  slider: {
    color: 'red',
    height: '30px'
  },
  // sliderWrapper input: {
  //   width: '150px',
  //   height: '20px',
  //   margin: 0
  //   // transform-origin: '75px 75px',
  //   // transform: rotate(-90deg)
  // }
}));