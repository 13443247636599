import React from 'react';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';

function ResetListing({
  isReset = false,
  onReset = () => null,
  wrapperStyle = {}
}) {

  const theme = useTheme();
  const tillTablet = useMediaQuery('(max-width: 768px)');

  if (!isReset) return null;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",

        position: 'fixed',
        bottom: 0,
        right: tillTablet ? 0 : 50,
        zIndex: 1200,
        padding: '0 5px',
        borderRadius: 3
      }}>
      <Typography
        style={{
          background: '#fff79d',
          color: theme.colors.ERROR_COLOR,
          borderColor: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
          fontSize: '0.8rem',
          padding: '1px 4px',
          cursor: 'pointer',
          borderRadius: 3,
          ...wrapperStyle
        }}
        onClick={onReset}
      >
        Showing results based on provided search criteria. Click to reset.
      </Typography>
    </div>
  )
}

export default ResetListing