import debounce from 'lodash.debounce';
import { useState, useEffect, useMemo } from 'react';

// TODO: make it more flexible
// if ref of an element is passed then it should return dimension/other info about it
// else provide window object info

export default function useElementDimensions() {

  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [elementDimensions, setElementDimensions] = useState(getWindowDimensions());

  const handleResize = () => {
    setElementDimensions(getWindowDimensions());
  };

  const debouncedHandleResize = useMemo(() => debounce(handleResize, 3000), []);

  useEffect(() => {
    if (hasWindow) {

      window.addEventListener('resize', debouncedHandleResize);
      return () => window.removeEventListener('resize', debouncedHandleResize);
    }
  }, [hasWindow, debouncedHandleResize]);

  return elementDimensions;
}