import { useTheme } from '@material-ui/core';
import Icon from '@mdi/react';
import themeGet from '@styled-system/theme-get';
import React, { Fragment, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import colors from '../../common/theme/LandingPage/colors';
import darkColors from '../../common/theme/LandingPage/darkThemeColors';
import { ThemeType } from '../../utils/mappings';

function KeyFeatureFlipCard({ item, flipTriggerEvent = "onMouseEnter" }) {

  const isDarkTheme = useTheme().palette.type === ThemeType.Dark;

  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlipEnter = () => {
    setIsFlipped(true);
  };

  const handleFlipLeave = () => {
    setIsFlipped(false);
  };

  return (
    <Fragment>
      <ReactCardFlip isFlipped={isFlipped} flipDirection='horizontal' flipSpeedBackToFront={0.3} flipSpeedFrontToBack={0.3}>
        <div
          style={{
            display: !isFlipped ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            cursor: 'pointer'
          }}
          onMouseEnter={flipTriggerEvent === 'onMouseEnter' ? handleFlipEnter : null}
          onClick={flipTriggerEvent === 'onClick' ? handleFlipEnter : null}
        >
          <div
            style={{
              background: item.bgColor,
              borderRadius: '50%',
              padding: '1rem',
              width: '6rem',
              height: '6rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon path={item.iconName} color={item.iconColor} size="3rem" />
          </div>
          <p
            style={{
              color: isDarkTheme ? darkColors.textColor : colors.textColor,
              marginTop: '10px',
              fontSize: '19px',
            }}
          >
            {item.title}
          </p>
        </div>

        <div
          style={{
            minWidth: 200,
            display: isFlipped ? 'block' : 'none',
          }}
          onMouseLeave={handleFlipLeave}
          onClick={flipTriggerEvent === 'onClick' ? handleFlipLeave : null}
        >
          <p style={{ fontSize: '16px', lineHeight: 1.5, whiteSpace: 'pre-wrap', background: item.bgColor, padding: '2rem 1rem', borderRadius: 10 }}>{item.desc || item.description}</p>
        </div>

      </ReactCardFlip>
    </Fragment >
  )
}

export default KeyFeatureFlipCard