import * as React from 'react';
import styled from 'styled-components';
import { IconList } from './IconList';
import { IconPickerItem } from './IconListItem';
import { useState, useEffect, useRef } from 'react';
import { FAIconList } from './FontAwesomeIconNameList';
import { MaterialDesignIconList } from './MaterialDesignIconList';

const IconPicker = ({
  value,
  onChange,
  hideSearch,
  containerStyles,
  buttonStyles,
  buttonIconStyles,
  pickerIconStyles,
  searchInputStyles,
  library = 'F' //M
}) => {
  const ref = useRef(null);
  const [display, changeDisplay] = useState(false);
  const [searchString, setSearchString] = useState('');
  useEffect(() => {
    function handleClickOutside(event) {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        changeDisplay(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [ref]);
  const buttonClick = () => changeDisplay(!display);
  const onChangeSearch = (event) => {
    setSearchString(event.target.value);
  };

  const icons = library === 'F' ? FAIconList : MaterialDesignIconList;

  return (
    <Container style={buttonStyles} ref={ref} onClick={() => buttonClick()}>
      <IconPickerItem containerStyles={buttonIconStyles} icon={value}
        library={library}
      />
      {display && (
        <PickerContainer
          style={containerStyles}
          onClick={(e) => e.stopPropagation()}
        >
          {!hideSearch && (
            <AppInput
              style={searchInputStyles}
              onChange={onChangeSearch}
              value={searchString}
              placeholder="Search"
            />
          )}
          {icons
            .filter((i) =>
              i.toLowerCase().includes(searchString.toLowerCase())
            )
            .map((icon) => (
              <IconPickerItem
                key={icon}
                icon={icon}
                containerStyles={pickerIconStyles}
                onClick={(value) => {
                  onChange(value)
                  changeDisplay(false)
                  setSearchString('')
                }}
                library={library}
              />
            ))}
        </PickerContainer>
      )}
    </Container>
  )
}

IconPicker.defaultProps = {
  hideSearch: false,
}

const Container = styled.div`
  position: relative;
  padding: 5px;
  width: 50px;
  min-height: 40px;
  border-radius: 4px;
  border-width: 1px;
  border-color: #000;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`

const PickerContainer = styled.div`
  position: absolute;
  top: 45px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  background-color: #fff;
  padding: 5px;
  // width: 200px;
  max-height: 300px;
  border-radius: 4px;
  border-width: 2px;
  border-color: #000;
  border-style: solid;
  z-index: 10;
`

const AppInput = styled.input`
  width: 100%;
`

export { IconPicker }