import { mdiPlusBox } from "@mdi/js";

import {
  Badge,
  makeStyles,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import Brand from "../Brand/Brand";
import MenuIcon from "@material-ui/icons/Menu";
import Icon from "@mdi/react";
import {
  mdiAccount,
  mdiAccountPlus,
  mdiArrowLeft,
  mdiBell,
  mdiCalendarPlus,
  mdiCalendarStar,
  mdiBriefcase,
  mdiClockCheckOutline,
  mdiFileTable,
  mdiFormSelect,
  mdiLogout,
  mdiMap,
  mdiPlus,
  mdiQuora,
  mdiScriptTextOutline,
  mdiWechat,
  mdiMagnify,
  mdiHandCoin,
} from "@mdi/js";
import { useGlobalStore } from "../../providers/GlobalProvider";
import { TOGGLE_LEFT_SIDEBAR, TOGGLE_SEARCH } from "../../utils/actions";
import LinkCNO from "../../library/LinkCNO";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/authUserActions";
import RightsManager from "../../utils/RightsManager";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  setJobSearchManager,
  setTaskById,
  toggleAddJobDialog,
} from "../../actions/jobActions";
import {
  useHistory,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import { createBrowserHistory } from "history";
import AutocompleteCNO from "../../library/AutocompleteCNO";
import {
  fetchAppointmentSuggest,
  fetchCustomerSuggest,
  fetchInvoiceSuggest,
  fetchMemberSuggest,
  fetchQuotationSuggest,
  fetchTaskSuggest,
  fetchServiceSuggest,
  fetchServiceProviderSuggest,
} from "../../actions/suggestionsActions";
import { toggleMapSearchSidebar } from "../../actions/taskSearchActions";
import NotificationPopover from "../NotificationPopover/NotificationPopover";
import {
  fetchNotifications,
  setNewNotification,
  setNotificationCount,
} from "../../actions/notificationActions";
import { setMessageCount } from "../../actions/chatActions";
import { setupSignalRConnection } from "../../utils/SignaRConnections";
import { toggleAddCompanyDialog } from "../../actions/customerCompanyActions";
import {
  handleQuotationSearch,
  toggleAddQuotationDialog,
} from "../../actions/quotationActions";
import {
  handleInvoiceSearch,
  toggleAddInvoiceDialog,
} from "../../actions/invoiceActions";
import ConfirmationDialogCNO from "../../library/ConfirmationDialogCNO/ConfirmationDialogCNO";
import {
  handleAppointmentSearch,
  toggleAddAppointmentDialog,
  toggleAddHolidayDialog,
  toggleAddLeaveDialog,
} from "../../actions/appointmentActions";

import {
  handleServiceSearch,
  toggleAddServiceDialog,
} from "../../actions/serviceActions";

import {
  CalendarFilter,
  NotificationType,
  AddInvoiceDialogMode,
  NotificationFeatureGroup,
} from "../../utils/mappings";
import messageReceivedSoundFile from "../../Sounds/messageReceived.mp3";
import { Howl } from "howler";
import {
  getNotificationsArray,
  handleCompanyUpdateFromNotification,
  handleProfileUpdateFromNotification,
  handleTaskUpdateFromNotification,
} from "../../utils/NotificationHelpers";
import {
  setEmployeeSearchParams,
  toggleAddEmployeeDialog,
} from "../../actions/employeeActions";

import {
  setServiceProviderSearchParams,
  toggleAddServiceProviderDialog,
} from "../../actions/serviceProviderActions";

import {
  setCustomerSearchParams,
  toggleAddCustomerDialog,
} from "../../actions/customerActions";
import { handleBackButton } from "../../utils/Helpers";
import { toggleAddEventDialog } from "../../actions/eventActions";
import { AddFormDataHeaderButton } from "../../features/formBuilder/components";
import { toggleAddPostListDialog } from "../../actions/postListActions";
import { toggleAddPostDialog } from "../../actions/postActions";
import { CreateFormDialog } from "../../features/formBuilder";
import CreatePostDrawerMenu from "../../pages/SettingsPage/components/CreatePostDrawerMenu";
import { mdiPlusCircle } from "@mdi/js";
import { mdiAccountWrench } from "@mdi/js";

import AddTest from "../AddTest/AddTest";
import AddTestQuestion from "../AddTestQuestion/AddTestQuestion";
import { TestContext } from "../../common/contexts/TestContext";
import PostDrawerMenu from '../../actions/DummyData/postDrawerMenuData.json'
import AppointmentDrawerMenu from '../../actions/DummyData/appointmentDrawerMenu.json'

function Header({ location }) {
  const isUserAdmin = RightsManager.isAdmin();

  const fromTablet = useMediaQuery("(min-width: 769px)");
  const headerIconsBP = useMediaQuery("(min-width: 1100px)");
  const tillTablet = useMediaQuery("(max-width: 768px)");

  const isEmployeesListPath = location.pathname.includes("/employees");
  const isCustomersListPath = location.pathname.includes("/customers");
  const isCompaniesListPath = location.pathname.includes("/companies");
  const isQuotationListPath = location.pathname.includes("/quotations");
  const isInvoiceListPath = location.pathname.includes("/invoices");
  const isAppointmentListPath = location.pathname.includes("/appointments");
  const isServiceListPath = location.pathname.includes("/services");
  const isServiceProviderListPath =
    location.pathname.includes("/service-providers");
  const isCalendarPath = location.pathname.includes("/calendar");
  const isEventListPath = location.pathname.includes("/events");
  const isJobListPath = location.pathname.includes("/jobs");
  const isTestListPath = location.pathname.includes("/Admintests");
  const isQuestionListPath = location.pathname.includes("/testq");
  const isTestPagePath = location.pathname.includes("/countdown");
  const isTestPaperPath = location.pathname.includes("/testpaper");
  const isResultPath = location.pathname.includes("/result");

  const isFormBuilderPath =
    location.pathname === "/forms/builder" ||
    location.pathname === "/forms/builder/";

  console.log("EmployeesRoute: ", location);

  const authUser = JSON.parse(localStorage.getItem("authUser"));

  const { globalState, dispatchGlobal } = useGlobalStore();
  const { isLeftSidebar, isSearch } = globalState;

  const dispatch = useDispatch();
  const { notificationsCount } = useSelector((state) => state.notifications);
  const { messageCount } = useSelector((state) => state.chats);
  const suggestions = useSelector((state) => state.suggestions);
  const { jobSearchParams } = useSelector((state) => state.jobs);
  const { employeeSearchParams } = useSelector((state) => state.employees);
  const { customerSearchParams } = useSelector((state) => state.customers);
  const { quotationSearchParams } = useSelector((state) => state.quotations);
  const { invoiceSearchParams } = useSelector((state) => state.invoices);
  const { appointmentSearchParams } = useSelector(
    (state) => state.appointments
  );
  const { serviceSearchParams } = useSelector((state) => state.services);
  const { serviceProviderSearchParams } = useSelector(
    (state) => state.serviceProviders
  );
  const { calendarFilter } = useSelector((state) => state.calendar);
  const { eventSearchParams } = useSelector((state) => state.events);

  const [openConfirmLogOutDialog, setOpenConfirmLogOutDialog] = useState(false);

  const [localNotificationCount, setLocalNotificationCount] = useState(0);

  const [notificationAnchorRef, setNotificationAnchorRef] = useState(null);
  const isNotification = Boolean(notificationAnchorRef);

  const messageReceivedSound = new Howl({
    src: [messageReceivedSoundFile],
    loop: false,
    preload: true,
  });

  const BASE_URL = "https://mefolia.com:9000";
  // const BASE_URL = 'https://int.checkndout.com:8000';
  const HUB_TOKEN = authUser ? authUser.token : null;
  const getAccessToken = () => {
    return HUB_TOKEN;
  };
  const notificationHubUrl = `${BASE_URL}/notificationhub`;

  const [isPostDrawerVisible, setIsPostDrawerVisible] = useState(false);
  const [isAppointmentDrawerVisible,setIsAppointmentDrawerVisible]=useState(false);

  const notificationSubscriber = setupSignalRConnection(
    notificationHubUrl,
    {
      notificationEvent: (id, message, creationDate, fromUserId) => {
        console.log("New Notification: ", {
          id,
          message,
          creationDate,
          fromUserId,
        });

        const companiesNotifications = getNotificationsArray(
          NotificationFeatureGroup.Company
        );

        if (message.notificationType === NotificationType.ProfileEdited) {
          handleProfileUpdateFromNotification(message, dispatch);
        }

        if (companiesNotifications.includes(message.notificationType)) {
          handleCompanyUpdateFromNotification(message, dispatch);
          return;
        }

        if (
          [
            NotificationType.Allocation,
            NotificationType.TaskCreated,
            NotificationType.TaskChanged,
            NotificationType.TaskDeleted,
          ].includes(message.notificationType)
        ) {
          handleTaskUpdateFromNotification(message, dispatch);
        }

        const notification = {
          id: message.id,
          creationDate: message.creationDate,
          text: message.text,
          toMemberId: message.toMemberId,
          notificationType: message.notificationType,
          metaData: message.metaData,
        };

        dispatch(setNewNotification(notification));

        messageReceivedSound.play();

        setNotificationAnchorRef((isOpen) => {
          if (!Boolean(isOpen)) {
            setLocalNotificationCount((count) => {
              const updatedCount = count + 1;
              dispatch(setNotificationCount(updatedCount));
              return updatedCount;
            });
          }
          return isOpen;
        });
      },
    },
    getAccessToken
  );

  useEffect(() => {
    console.log("disaptching notification connection");
    if (!authUser) return;

    dispatch(notificationSubscriber);
  }, []);

  useEffect(() => {
    setLocalNotificationCount(notificationsCount);
  }, [notificationsCount]);

  useEffect(() => {
    if (!authUser) return;

    const notificationPayload = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "",
      sortDecending: true,
      zeroStart: true,
    };

    dispatch(fetchNotifications(notificationPayload));
  }, []);

  const openNotification = (e) => {
    setNotificationAnchorRef(e.currentTarget);
    const currentDateTime = new Date();
    localStorage.setItem("notificationLastRead", currentDateTime);
    dispatch(setNotificationCount(0));
  };

  const closeNotification = () => {
    setNotificationAnchorRef(null);
  };

  const userId = authUser?.id;

  // const tillTablet = useMediaQuery("(max-width: 768px)");

  const history = useHistory();

  const isHomePath = history.location.pathname.includes("/home");
  const isDashboardPath = history.location.pathname.includes("/dashboard");
  const isRootPath = isHomePath;
  // const isRootPath = (isUserAdmin && isDashboardPath) || (!isUserAdmin && isHomePath);

  const isCallPath = history.location.pathname.includes("/call");

  // const isLandingPagePath = history.location.pathname.includes("/");
  const isLandingPagePath = history.location.pathname === "/";
  const isPrivacyPagePath = history.location.pathname === "/privacy";
  const isTermsPagePath = history.location.pathname === "/terms";
  const isEulaPagePath = history.location.pathname === "/eula";
  const isDisclaimerPagePath = history.location.pathname === "/disclaimer";
  const isCookiePagePath = history.location.pathname === "/cookie";
  const isAboutUsPath = history.location.pathname === "/aboutus";
  const isContactUsPath = history.location.pathname === "/contactus";
  const isHelpPath = history.location.pathname === "/help";

  const [searchPadding, setSearchPadding] = useState(0);

  const {
    isModalOpen,
    setIsModalOpen,
    isQuestionsModalOpen,
    setIsQuestionsModalOpen,
    currentTestId,
  } = useContext(TestContext);
  console.log("header testId", currentTestId);

  useEffect(() => {
    let leftPadding = 0;
    if (isLeftSidebar && isSearch) {
      console.log("open: a");
      leftPadding = 368;
    }

    if (isLeftSidebar && !isSearch) {
      console.log("open: b");
      leftPadding = 18;
    }

    if (!isLeftSidebar && isSearch) {
      console.log("open: c");
      leftPadding = 180;
    }

    setSearchPadding(leftPadding);
  }, [isLeftSidebar, isSearch, tillTablet]);

  const [isCreateForm, setIsCreateForm] = useState(false);

  const openAddEmployeeDialog = () => {
    dispatch(toggleAddEmployeeDialog(true));
  };
  const openAddServiceProviderDialog = () => {
    dispatch(toggleAddServiceProviderDialog(true));
  };
  const openCreateFormDialog = () => {
    setIsCreateForm(true);
  };

  const openAddCustomerDialog = () => {
    dispatch(toggleAddCustomerDialog(true));
  };

  const openAddCompanyDialog = () => {
    dispatch(toggleAddCompanyDialog(true));
  };

  const openAddQuotationDialog = () => {
    dispatch(toggleAddQuotationDialog(true));
  };

  const openAddInvoiceDialog = () => {
    dispatch(setTaskById(null));
    dispatch(
      toggleAddInvoiceDialog({ status: true, mode: AddInvoiceDialogMode.Add })
    );
  };

  const openAddServiceDialog = () => {
    dispatch(toggleAddServiceDialog(true));
  };

  const openAddEventDialog = () => {
    dispatch(toggleAddEventDialog(true));
  };

  const openAddFormDataDialog = () => {
    dispatch(toggleAddEventDialog(true));
  };

  const handleAddJobClick = () => {
    dispatch(toggleAddJobDialog(true));
  };
  

  const handleAddPostClick = (source) => {
    setIsPostDrawerVisible(false);
    
    if (source === "post") {
      dispatch(toggleAddPostDialog(true));
    } else if (source === "job") {
      dispatch(toggleAddJobDialog(true));
    } else if (source === "service") {
      dispatch(toggleAddServiceDialog(true));
    } else if (source === "serviceProvider") {
      dispatch(toggleAddServiceProviderDialog(true));
    } else {
      dispatch(toggleAddEventDialog(true));
    }
  };

  const handleAddAppointment=(source)=>{

    setIsAppointmentDrawerVisible(false);
    if(source=='meeting'){
      dispatch(toggleAddAppointmentDialog(true));
    }else if(source == 'leave'){
      dispatch(toggleAddLeaveDialog(true)); 
    }else if(source == 'holiday'){
      dispatch(toggleAddHolidayDialog(true));
    }
  }

  const theme = useTheme();

  console.log("======theme====", theme);

  const handleLeftDrawer = () => {
    dispatchGlobal({
      type: TOGGLE_LEFT_SIDEBAR,
      payload: !isLeftSidebar,
    });
  };

  const _handleBackButton = () => handleBackButton(history);

  const handleSearchFocus = () => {
    dispatch(toggleMapSearchSidebar(true));
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: true,
    });
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const validationSchema = yup.object({
    job: yup.object().nullable(),
    profile: yup.object(),
  });

  const formik = useFormik({
    initialValues: {
      job: "",
      profile: "",
      employee: employeeSearchParams.employee,
      customer: customerSearchParams.customer,
      quotation: quotationSearchParams.quotation,
      invoice: invoiceSearchParams.invoice,
      appointment: appointmentSearchParams.appointment,
      service: serviceSearchParams.service,
      service: serviceSearchParams.service,
      serviceProvider: serviceProviderSearchParams.serviceProvider,
      event: eventSearchParams.event,
    },
    validationSchema,
    onSubmit: (values) => {
      return;
    },
  });

  const styles = useStyles({
    fromTablet,
    isRootPath,
    isCallPath,
    isLandingPagePath,
    userId,
    isLeftSidebar,
    searchPadding,
    headerIconsBP,
  });

  useEffect(() => {
    formik.setFieldValue("job", jobSearchParams.job);
  }, [jobSearchParams]);

  useEffect(() => {
    formik.setFieldValue("employee", employeeSearchParams.employee);
  }, [employeeSearchParams]);

  useEffect(() => {
    formik.setFieldValue(
      "serviceProvider",
      serviceSearchParams.serviceProvider
    );
  }, [serviceProviderSearchParams]);

  useEffect(() => {
    formik.setFieldValue("customer", customerSearchParams.customer);
  }, [customerSearchParams]);

  useEffect(() => {
    formik.setFieldValue("quotation", quotationSearchParams.quotation);
  }, [quotationSearchParams]);

  useEffect(() => {
    formik.setFieldValue("appointment", appointmentSearchParams.appointment);
  }, [appointmentSearchParams]);

  useEffect(() => {
    formik.setFieldValue("service", serviceSearchParams.service);
  }, [serviceSearchParams]);

  useEffect(() => {
    formik.setFieldValue("invoice", invoiceSearchParams.invoice);
  }, [invoiceSearchParams]);

  useEffect(() => {
    formik.setFieldValue("event", eventSearchParams.invoice);
  }, [eventSearchParams]);

  const isHeaderVisible = !(
    isLandingPagePath ||
    isPrivacyPagePath ||
    isTermsPagePath ||
    isEulaPagePath ||
    isDisclaimerPagePath ||
    isCookiePagePath ||
    isAboutUsPath ||
    isContactUsPath ||
    isHelpPath
  );

  return (
    <Paper className={styles.container_Header} elevation={0}>
      {!isCallPath &&
        userId &&
        isHeaderVisible &&
        !isTestPagePath &&
        !isTestPaperPath && (
          <MenuIcon
            onClick={handleLeftDrawer}
            className={`${styles.icons} ${styles.menuIcon}`}
          />
        )}
      {!isCallPath ? (
        <LinkCNO to="/">
          {" "}
          <Brand />{" "}
        </LinkCNO>
      ) : isCallPath ? (
        <Brand />
      ) : (
        ""
      )}

      {!isCallPath && !isTestPagePath && !isTestPaperPath && !isResultPath && (
        <Icon
          className={styles.headerBackBtn}
          path={mdiArrowLeft}
          size="2rem"
          color={theme.colors.HEADER_BACK_ICON_COLOR}
          onClick={_handleBackButton}
        />
      )}

      <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
        {fromTablet &&
          userId &&
          !isCallPath &&
          (isHomePath ||
            (isCalendarPath && calendarFilter === CalendarFilter.Jobs)) && (
            <form onSubmit={formik.handleSubmit} className={styles.searchForm}>
              <AutocompleteCNO
                name="job"
                className={styles.search}
                placeholder="Search Job..."
                formik={formik}
                onFocus={handleSearchFocus}
                triggerSearchOnEnter={true}
                data={suggestions}
                optionLabel={(x) => x.title || ""}
                renderOption={(option) => (
                  <div style={{ color: "#014663", fontWeight: "500" }}>
                    {option.title}
                  </div>
                )}
                // fullWidth
                onSuggestionClick={(value) => {
                  const params = {
                    ...jobSearchParams,
                    job: value,
                  };
                  dispatch(setJobSearchManager(params));
                }}
                onChange={(value) => {
                  if (value) {
                    dispatch(fetchTaskSuggest(value));
                  }
                }}
              />
            </form>
          )}

        {fromTablet && userId && isEmployeesListPath && !isCallPath && (
          <form onSubmit={formik.handleSubmit} className={styles.searchForm}>
            <AutocompleteCNO
              name="employee"
              formik={formik}
              className={styles.profileSearch}
              placeholder="Search employee..."
              triggerSearchOnEnter={true}
              data={suggestions}
              optionLabel={(x) => x.title || ""}
              renderOption={(option) => (
                <div style={{ color: "orangered" }}>{option.title}</div>
              )}
              onSuggestionClick={(value) => {
                const searchParams = {
                  ...employeeSearchParams,
                  employee: value,
                };
                dispatch(setEmployeeSearchParams(searchParams));
              }}
              onClick={handleSearchFocus}
              onChange={(value) => {
                if (value) {
                  dispatch(fetchMemberSuggest(value));
                }
              }}
            />
          </form>
        )}

        {fromTablet && userId && isServiceProviderListPath && !isCallPath && (
          <form onSubmit={formik.handleSubmit} className={styles.searchForm}>
            <AutocompleteCNO
              name="serviceProvider"
              formik={formik}
              className={styles.profileSearch}
              placeholder="Search ServiceProviders..."
              triggerSearchOnEnter={true}
              data={suggestions}
              optionLabel={(x) => x.title || ""}
              renderOption={(option) => (
                <div style={{ color: "orangered" }}>{option.title}</div>
              )}
              onSuggestionClick={(value) => {
                const searchParams = {
                  ...serviceProviderSearchParams,
                  serviceProvider: value,
                };
                dispatch(setServiceProviderSearchParams(searchParams));
              }}
              onClick={handleSearchFocus}
              onChange={(value) => {
                if (value) {
                  dispatch(fetchServiceProviderSuggest(value));
                }
              }}
            />
          </form>
        )}

        {fromTablet && userId && isCustomersListPath && !isCallPath && (
          <form onSubmit={formik.handleSubmit} className={styles.searchForm}>
            <AutocompleteCNO
              name="customer"
              formik={formik}
              className={styles.profileSearch}
              placeholder="Search customer..."
              triggerSearchOnEnter={true}
              data={suggestions}
              optionLabel={(x) => x.title || ""}
              renderOption={(option) => (
                <div style={{ color: "orangered" }}>{option.title}</div>
              )}
              onSuggestionClick={(value) => {
                const searchParams = {
                  ...customerSearchParams,
                  customer: value,
                };
                dispatch(setCustomerSearchParams(searchParams));
              }}
              onClick={handleSearchFocus}
              onChange={(value) => {
                if (value) {
                  dispatch(fetchCustomerSuggest(value));
                }
              }}
            />
          </form>
        )}

        {fromTablet && userId && isQuotationListPath && !isCallPath && (
          <form onSubmit={formik.handleSubmit} className={styles.searchForm}>
            <AutocompleteCNO
              name="quotation"
              formik={formik}
              className={styles.profileSearch}
              placeholder="Search Quotation..."
              triggerSearchOnEnter={true}
              data={suggestions}
              optionLabel={(x) => x.title || ""}
              renderOption={(option) => (
                <div style={{ color: "orangered" }}>{option.title}</div>
              )}
              onSuggestionClick={(value) => {
                const searchParams = {
                  ...quotationSearchParams,
                  quotation: value,
                };
                dispatch(handleQuotationSearch(searchParams));
              }}
              onClick={handleSearchFocus}
              onChange={(value) => {
                if (value) {
                  dispatch(fetchQuotationSuggest(value));
                }
              }}
            />
          </form>
        )}

        {fromTablet && userId && isInvoiceListPath && !isCallPath && (
          <form onSubmit={formik.handleSubmit} className={styles.searchForm}>
            <AutocompleteCNO
              name="invoice"
              formik={formik}
              className={styles.profileSearch}
              placeholder="Search Invoice..."
              triggerSearchOnEnter={true}
              data={suggestions}
              optionLabel={(x) => x.title || ""}
              renderOption={(option) => (
                <div style={{ color: "orangered" }}>{option.title}</div>
              )}
              onSuggestionClick={(value) => {
                const searchParams = {
                  ...invoiceSearchParams,
                  invoice: value,
                };
                dispatch(handleInvoiceSearch(searchParams));
              }}
              onClick={handleSearchFocus}
              onChange={(value) => {
                if (value) {
                  dispatch(fetchInvoiceSuggest(value));
                }
              }}
            />
          </form>
        )}

        {fromTablet && userId && isAppointmentListPath && !isCallPath && (
          <form onSubmit={formik.handleSubmit} className={styles.searchForm}>
            <AutocompleteCNO
              name="appointment"
              formik={formik}
              className={styles.profileSearch}
              placeholder="Search Appointment..."
              triggerSearchOnEnter={true}
              data={suggestions}
              optionLabel={(x) => x.title || ""}
              renderOption={(option) => (
                <div style={{ color: "orangered" }}>{option.title}</div>
              )}
              onSuggestionClick={(value) => {
                const searchParams = {
                  ...appointmentSearchParams,
                  appointment: value,
                };
                dispatch(handleAppointmentSearch(searchParams));
              }}
              onClick={handleSearchFocus}
              onChange={(value) => {
                if (value) {
                  dispatch(fetchAppointmentSuggest(value));
                }
              }}
            />
          </form>
        )}

        {fromTablet && userId && isServiceListPath && !isCallPath && (
          <form onSubmit={formik.handleSubmit} className={styles.searchForm}>
            <AutocompleteCNO
              name="service"
              formik={formik}
              className={styles.profileSearch}
              placeholder="Search Services..."
              triggerSearchOnEnter={true}
              data={suggestions}
              optionLabel={(x) => x.title || ""}
              renderOption={(option) => (
                <div style={{ color: "orangered" }}>{option.title}</div>
              )}
              onSuggestionClick={(value) => {
                const searchParams = {
                  ...serviceSearchParams,
                  service: value,
                };
                dispatch(handleServiceSearch(searchParams));
              }}
              onClick={handleSearchFocus}
              onChange={(value) => {
                if (value) {
                  dispatch(fetchServiceSuggest(value));
                }
              }}
            />
          </form>
        )}

        

        {fromTablet && userId && isEventListPath && !isCallPath && (
          <form onSubmit={formik.handleSubmit} className={styles.searchForm}>
            <AutocompleteCNO
              name="event"
              formik={formik}
              className={styles.profileSearch}
              placeholder="Search Event..."
              triggerSearchOnEnter={true}
              data={suggestions}
              optionLabel={(x) => x.title || ""}
              renderOption={(option) => (
                <div style={{ color: "orangered" }}>{option.title}</div>
              )}
              onSuggestionClick={(value) => {
                const searchParams = {
                  ...eventSearchParams,
                  event: value,
                };
                // dispatch(handleEventSearch(searchParams));
              }}
              onClick={handleSearchFocus}
              onChange={(value) => {
                if (value) {
                  // dispatch(fetchEventSuggest(value));
                }
              }}
            />
          </form>
        )}

        {fromTablet && userId && isTestListPath && !isCallPath && (
          <form onSubmit={formik.handleSubmit} className={styles.searchForm}>
            <AutocompleteCNO
              name="test"
              formik={formik}
              className={styles.profileSearch}
              placeholder="Search test..."
              triggerSearchOnEnter={true}
              data={suggestions}
              optionLabel={(x) => x.title || ""}
              renderOption={(option) => (
                <div style={{ color: "orangered" }}>{option.title}</div>
              )}
              onSuggestionClick={(value) => {
                const searchParams = {
                  ...eventSearchParams,
                  event: value,
                };
                // dispatch(handleEventSearch(searchParams));
              }}
              onClick={handleSearchFocus}
              onChange={(value) => {
                if (value) {
                  // dispatch(fetchEventSuggest(value));
                }
              }}
            />
          </form>
        )}

        {headerIconsBP && !isCallPath && (
          <nav className={styles.nav}>
            {isUserAdmin && isEmployeesListPath && userId && !isCallPath && (
              <pre onClick={openAddEmployeeDialog} className={styles.navItem}>
                <Icon
                  path={mdiAccountPlus}
                  size="1.5rem"
                  color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
                />
                <Typography>Add Employee</Typography>
              </pre>
            )}

            {isUserAdmin &&
              isServiceProviderListPath &&
              userId &&
              !isCallPath && (
                <pre
                  onClick={openAddServiceProviderDialog}
                  className={styles.navItem}
                >
                  <Icon
                    path={mdiHandCoin}
                    size="1.5rem"
                    color={
                      theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR
                    }
                  />
                  <Typography>Add Service Provider</Typography>
                </pre>
              )}

            {isUserAdmin && isFormBuilderPath && userId && !isCallPath && (
              <pre onClick={openCreateFormDialog} className={styles.navItem}>
                <Icon
                  path={mdiFormSelect}
                  size="1.5rem"
                  color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
                />
                <Typography>Create Form</Typography>
              </pre>
            )}

            {isUserAdmin && isCustomersListPath && userId && !isCallPath && (
              <pre onClick={openAddCustomerDialog} className={styles.navItem}>
                <Icon
                  path={mdiAccountPlus}
                  size="1.5rem"
                  color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
                />
                <Typography>Add Customer</Typography>
              </pre>
            )}

            {isUserAdmin && isCompaniesListPath && userId && !isCallPath && (
              <pre onClick={openAddCompanyDialog} className={styles.navItem}>
                <Icon
                  path={mdiAccountPlus}
                  size="1.5rem"
                  color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
                />
                <Typography>Add Company</Typography>
              </pre>
            )}

            {isUserAdmin && isQuotationListPath && userId && !isCallPath && (
              <pre onClick={openAddQuotationDialog} className={styles.navItem}>
                <Icon
                  path={mdiQuora}
                  size="1.5rem"
                  color={
                    theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR
                  }
                />
                <Typography>Add Quotation</Typography>
              </pre>
            )}

            {isUserAdmin && isInvoiceListPath && userId && !isCallPath && (
              <pre onClick={openAddInvoiceDialog} className={styles.navItem}>
                <Icon
                  path={mdiFileTable}
                  size="1.5rem"
                  color={
                    theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR
                  }
                />
                <Typography>Add Invoice</Typography>
              </pre>
            )}

            {isUserAdmin && isAppointmentListPath && userId && !isCallPath && (
              isAppointmentDrawerVisible ? (
                <CreatePostDrawerMenu
                  drawerMenu={AppointmentDrawerMenu}
                  handleclick={handleAddAppointment}
                  isVisible={isAppointmentDrawerVisible}
                  setIsVisible={setIsAppointmentDrawerVisible}
                />
              ):(
              <pre
                onClick={() => setIsAppointmentDrawerVisible((prev) => !prev)}
                className={styles.navItem}
              >
                <Icon
                  path={mdiClockCheckOutline}
                  size="1.5rem"
                  color={
                    theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR
                  }
                />
                <Typography>Add Appointment</Typography>
              </pre>
                )
            )}

            {isUserAdmin && isServiceListPath && userId && !isCallPath && (
              <pre onClick={openAddServiceDialog} className={styles.navItem}>
                <Icon
                  path={mdiMagnify}
                  size="1.5rem"
                  color={
                    theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR
                  }
                />
                <Typography>Looking For</Typography>
              </pre>
            )}

            {isUserAdmin && isEventListPath && userId && !isCallPath && (
              <pre onClick={openAddEventDialog} className={styles.navItem}>
                <Icon
                  path={mdiCalendarStar}
                  size="1.5rem"
                  color={
                    theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR
                  }
                />
                <Typography>Add Event</Typography>
              </pre>
            )}

            <AddFormDataHeaderButton />

            {isUserAdmin && userId && isJobListPath && !isCallPath && (
              <pre onClick={handleAddJobClick} className={styles.navItem}>
                <Icon
                  path={mdiBriefcase}
                  size="1.5rem"
                  color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
                />
                <Typography>Add Job</Typography>
              </pre>
            )}

            {isUserAdmin && userId && isTestListPath && !isCallPath && (
              <pre
                onClick={() => setIsModalOpen(true)}
                className={styles.navItem}
              >
                <Icon
                  path={mdiCalendarPlus}
                  size="1.5rem"
                  color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
                />
                <Typography>Add Test</Typography>
              </pre>
            )}
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <div>
                <AddTest
                  Open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                />
              </div>
            </Modal>

            {isUserAdmin && userId && isQuestionListPath && !isCallPath && (
              <pre
                onClick={() => setIsQuestionsModalOpen(true)}
                className={styles.navItem}
              >
                <Icon
                  path={mdiCalendarPlus}
                  size="1.5rem"
                  color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
                />
                <Typography>Add Question</Typography>
              </pre>
            )}

            <Modal
              open={isQuestionsModalOpen}
              onClose={() => setIsQuestionsModalOpen(false)}
            >
              <div>
                <AddTestQuestion
                  open={isQuestionsModalOpen}
                  onClose={() => setIsQuestionsModalOpen(false)}
                />
              </div>
            </Modal>

            {isUserAdmin &&
              userId &&
              isHomePath &&
              !isCallPath &&
              (isPostDrawerVisible ? (
                <CreatePostDrawerMenu
                  drawerMenu={PostDrawerMenu}
                  handleclick={handleAddPostClick}
                  isVisible={isPostDrawerVisible}
                  setIsVisible={setIsPostDrawerVisible}
                />
              ) : (
                <pre
                  onClick={() => setIsPostDrawerVisible((prev) => !prev)}
                  className={styles.navItem}
                >
                  <Icon
                    path={mdiPlusCircle}
                    size="1.5rem"
                    color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
                  />
                  <Typography>Create Post</Typography>
                </pre>
              ))}

            {/* New admin test components */}
            {isUserAdmin && userId && (
              <LinkCNO to="/Admintests" className={styles.navItem}>
                <pre className={styles.navItem}>
                  <Icon
                    path={mdiPlusBox}
                    size="1.5rem"
                    color={theme.colors.HEADER_TEST_ICON_COLOR}
                  />
                  <Typography>Admin Tests</Typography>
                </pre>
              </LinkCNO>
            )}

            {isUserAdmin && userId && !isCallPath && (
              <LinkCNO to="/map" className={styles.navItem}>
                <pre className={styles.navItem}>
                  <Icon
                    path={mdiMap}
                    size="1.5rem"
                    color={theme.colors.HEADER_TRACK_MAP_ICON_COLOR}
                  />
                  <Typography>Track User</Typography>
                </pre>
              </LinkCNO>
            )}
            {userId && !isCallPath && (
              <LinkCNO to="/chat">
                <div
                  className={styles.navItem}
                  onClick={() => dispatch(setMessageCount(0))}
                >
                  <Badge badgeContent={messageCount} color="error">
                    <Icon
                      path={mdiWechat}
                      size="1.8rem"
                      color={theme.colors.HEADER_CHAT_ICON_COLOR}
                    />
                  </Badge>
                  <Typography>iConnect</Typography>
                </div>
              </LinkCNO>
            )}
          </nav>
        )}
      </div>

      {!headerIconsBP && !isCallPath && (
        <div className={styles.headerIcons}>
          {userId && !isUserAdmin && (
            <LinkCNO to={`/profile/${userId}`}>
              <Icon
                path={mdiAccount}
                size="1.5rem"
                color={theme.colors.HEADER_USER_ICON_COLOR}
              />
            </LinkCNO>
          )}
          {userId && isUserAdmin && isEmployeesListPath && !isCallPath && (
            <div onClick={openAddEmployeeDialog} className={styles.navItem}>
              <Icon
                path={mdiAccountPlus}
                size="1.5rem"
                color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
              />
            </div>
          )}

          {userId && isUserAdmin && isCustomersListPath && !isCallPath && (
            <div onClick={openAddCustomerDialog} className={styles.navItem}>
              <Icon
                path={mdiAccountPlus}
                size="1.5rem"
                color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
              />
            </div>
          )}

          {userId && isUserAdmin && isCompaniesListPath && !isCallPath && (
            <div onClick={openAddCompanyDialog} className={styles.navItem}>
              <Icon
                path={mdiAccountPlus}
                size="1.5rem"
                color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
              />
            </div>
          )}

          {isUserAdmin && isQuotationListPath && userId && !isCallPath && (
            <div onClick={openAddQuotationDialog} className={styles.navItem}>
              <Icon
                path={mdiQuora}
                size="1.5rem"
                color={theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR}
              />
            </div>
          )}

          {isUserAdmin && isInvoiceListPath && userId && !isCallPath && (
            <div onClick={openAddInvoiceDialog} className={styles.navItem}>
              <Icon
                path={mdiFileTable}
                size="1.5rem"
                color={theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR}
              />
            </div>
          )}

          {isUserAdmin && isAppointmentListPath && userId && !isCallPath && (
            isAppointmentDrawerVisible ? (
              <CreatePostDrawerMenu
                drawerMenu={AppointmentDrawerMenu}
                handleclick={handleAddAppointment}
                isVisible={isAppointmentDrawerVisible}
                setIsVisible={setIsAppointmentDrawerVisible}
                noTypo={true}
              />
            ):(
            <pre onClick={() => setIsAppointmentDrawerVisible((prev) => !prev)} className={styles.navItem}>
              <Icon
                path={mdiClockCheckOutline}
                size="1.5rem"
                color={theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR}
              />
            </pre>
            )
          )}
          {isUserAdmin && isServiceListPath && userId && !isCallPath && (
            <pre onClick={openAddServiceDialog} className={styles.navItem}>
              <Icon
                path={mdiMagnify}
                size="1.5rem"
                color={theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR}
              />
            </pre>
          )}
          {isUserAdmin &&
            isServiceProviderListPath &&
            userId &&
            !isCallPath && (
              <pre
                onClick={openAddServiceProviderDialog}
                className={styles.navItem}
              >
                <Icon
                  path={mdiHandCoin}
                  size="1.5rem"
                  color={
                    theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR
                  }
                />
              </pre>
            )}
          {isUserAdmin && isEventListPath && userId && !isCallPath && (
            <pre onClick={openAddEventDialog} className={styles.navItem}>
              <Icon
                path={mdiCalendarStar}
                size="1.5rem"
                color={theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR}
              />
            </pre>
          )}

          {userId && isUserAdmin && isJobListPath && !isCallPath && (
            <div onClick={handleAddJobClick} className={styles.navItem}>
              <Icon
                path={mdiBriefcase}
                size="1.5rem"
                color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
              />
            </div>
          )}

          {userId && isUserAdmin && isTestListPath && !isCallPath && (
            <div onClick={handleAddJobClick} className={styles.navItem}>
              <Icon
                path={mdiCalendarPlus}
                size="1.5rem"
                color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
              />
            </div>
          )}

          {userId &&
            isUserAdmin &&
            isHomePath &&
            !isCallPath &&
            (isPostDrawerVisible ? (
              <CreatePostDrawerMenu
                drawerMenu={PostDrawerMenu}
                handleclick={handleAddPostClick}
                isVisible={isPostDrawerVisible}
                setIsVisible={setIsPostDrawerVisible}
                noTypo={true}
              />
            ) : (
              <div
                onClick={() => setIsPostDrawerVisible((prev) => !prev)}
                className={styles.navItem}
              >
                <Icon
                  path={mdiPlusCircle}
                  size="1.5rem"
                  color={theme.colors.HEADER_ADD_TODO_ICON_COLOR}
                />
              </div>
            ))}

          {isUserAdmin && userId && !isCallPath && (
            <LinkCNO to="/map" className={styles.navItem}>
              <Icon
                path={mdiMap}
                size="1.5rem"
                color={theme.colors.HEADER_TRACK_MAP_ICON_COLOR}
              />
            </LinkCNO>
          )}
          {userId && !isCallPath && (
            <LinkCNO to="/chat" style={{ marginRight: 5 }}>
              <Badge
                badgeContent={messageCount}
                color="error"
                onClick={() => dispatch(setMessageCount(0))}
              >
                <Icon
                  path={mdiWechat}
                  size="1.8rem"
                  color={theme.colors.HEADER_CHAT_ICON_COLOR}
                />
              </Badge>
            </LinkCNO>
          )}
        </div>
      )}
      {userId && !isCallPath && (
        <div onClick={openNotification} className={styles.navItem}>
          <Badge badgeContent={notificationsCount} color="error">
            <Icon
              path={mdiBell}
              size="1.5rem"
              color={theme.colors.HEADER_NOTIFICATION_ICON_COLOR}
            />
          </Badge>
        </div>
      )}
      {fromTablet && !isCallPath && (
        <div className={styles.headerIcons}>
          {userId && !isUserAdmin && (
            <LinkCNO to={`/profile/${userId}`}>
              <Icon
                path={mdiAccount}
                size="2rem"
                color={theme.colors.HEADER_USER_ICON_COLOR}
              />
            </LinkCNO>
          )}
          {userId && (
            <Icon
              color={theme.colors.DRAWER_ITEM_ICON_COLOR}
              style={{ marginLeft: 5 }}
              path={mdiLogout}
              size="1.5rem"
              onClick={() => setOpenConfirmLogOutDialog(true)}
            />
          )}
        </div>
      )}
      {isNotification && !isCallPath && (
        <NotificationPopover
          // notifications={notifications}
          // setNotifications={setNotifications}
          isNotification={isNotification}
          closeNotification={closeNotification}
          anchorEl={notificationAnchorRef}
        />
      )}

      {openConfirmLogOutDialog && (
        <ConfirmationDialogCNO
          open={openConfirmLogOutDialog}
          onClose={() => setOpenConfirmLogOutDialog(false)}
          dialogTitle="Logout"
          rightText="Logout"
          leftText="Cancel"
          yesClick={handleLogout}
          messages={["Are you sure you want to Logout?"]}
        />
      )}

      {isCreateForm && (
        <CreateFormDialog
          open={isCreateForm}
          onClose={() => setIsCreateForm(false)}
        />
      )}
    </Paper>
  );
}

export default withRouter(Header);

const useStyles = makeStyles((theme) => ({
  container_Header: {
    height: ({ fromTablet }) => (fromTablet ? 60 : 40),
    display: "flex",
    alignItems: "center",
    justifyContent: ({ fromTablet }) =>
      fromTablet ? "flex-start" : "space-between",
    // padding: ({ fromTablet }) => fromTablet ? 0 : 10,
    padding: 10,
    backgroundColor: theme.colors.HEADER_BACKGROUND_COLOR,
    position: "fixed",
    top: 0,
    width: "100vw",
    zIndex: 1300,
    paddingRight: ({ fromTablet }) => (fromTablet ? 30 : 0),
    borderBottom: `1px solid ${theme.colors.BORDER_COLOR}`,
    borderRadius: 0,
  },
  nav: {
    display: "flex",
    marginLeft: theme.spacing(4),
    // marginRight: theme.spacing(3),
    alignItems: "center",
    justifyContent: "flex-end",
  },
  navItem: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    color: theme.colors.HEADER_TITLE_COLOR,
    textDecoration: "none",
    "&>*:nth-child(2)": {
      marginLeft: theme.spacing(1),
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  headerIcons: {
    display: "flex",
    alignItems: "center",
    "&>*:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  headerBackBtn: {
    marginLeft: 38,
    visibility: ({ isRootPath, userId }) =>
      isRootPath || !userId ? "hidden" : "visible",
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchForm: {
    // minWidth: 200,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(7),
    flex: 1,
  },
  search: {
    background: theme.colors.LIGHT_COLOR_SHADE_1,
    marginTop: -8,
    width: "100%",
    borderRadius: 5,
    "& input": {
      color: theme.palette.getContrastText(theme.colors.LIGHT_COLOR_SHADE_1),
      padding: theme.spacing(0.8),
      // paddingLeft: theme.spacing(2),
      // fontSize: '0.9rem',
    },
  },
  profileSearch: {
    background: theme.colors.LIGHT_COLOR_SHADE_1,
    marginTop: -8,
    width: "100%",
    borderRadius: 5,
    "& input": {
      color: theme.palette.getContrastText(theme.colors.LIGHT_COLOR_SHADE_1),
      padding: theme.spacing(0.8),
      // paddingLeft: theme.spacing(2),
      // fontSize: '0.9rem',
    },
  },
  icons: {
    width: 30,
    height: 30,
    marginLeft: ({ fromTablet }) => (fromTablet ? theme.spacing(2) : 5),
    marginRight: ({ fromTablet }) => (fromTablet ? theme.spacing(2) : 5),
  },
  menuIcon: {
    color: theme.colors.HAMBURGER_MENU_ICON_COLOR,
    marginLeft: "3px !important",
    alignSelf: "flex-end",
  },
}));
