import React, { createContext, useState, useEffect } from "react";

export const TestContext = createContext();

export const TestProvider = ({ children }) => {
  const [allTests, setAllTests] = useState([]);
  const [allAnswers, setAllAnswers] = useState({});
  const [newTest, setNewTest] = useState({
    title: "",
    description: "",
    duration_minutes: "",
  });
  const [currentTestId, setCurrentTestId] = useState(null);
  const [newQuestion, setNewQuestion] = useState({
    type: "",
    question: "",
    options: [],
    imageUrl: "",
    videoUrl: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuestionsModalOpen, setIsQuestionsModalOpen] = useState(false);
  //const [editTestIndex, setEditTestIndex] = useState(null);
  const [editQuestionId, setEditQuestionId] = useState(null);
  const [searchParamsQues, setSearchParamsQues] = useState(null);

  useEffect(() => {
    const fetchAndStoreInitialTests = async () => {
      try {
        const answers = await fetch("/data/answers.json").then((res) =>
          res.json()
        );
        const allTests = await fetch("/data/tests.json").then((res) =>
          res.json()
        );
        const testCategory = await fetch("/data/testCategory.json").then(
          (res) => res.json()
        );

        localStorage.setItem("allTests", JSON.stringify(allTests));
        localStorage.setItem("allAnswers", JSON.stringify(answers));
        localStorage.setItem("testCategory", JSON.stringify(testCategory));
      } catch (error) {
        console.error("Error fetching or storing tests:", error);
      }
    };

    fetchAndStoreInitialTests();
  }, []);

  useEffect(() => {
    const storedTests = JSON.parse(localStorage.getItem("allTests"));
    const storedAnswers = JSON.parse(localStorage.getItem("allAnswers")) || {
      answers: [],
    };

    if (storedTests && storedTests.test) {
      setAllTests(storedTests.test);
    }
    if (storedAnswers && storedAnswers.answers) {
      setAllAnswers(storedAnswers.answers);
    }
  }, []);

  const saveAllTests = (updatedAllTests) => {
    console.log("Saving allTests:", updatedAllTests);
    setAllTests(updatedAllTests);
    localStorage.setItem("allTests", JSON.stringify({ test: updatedAllTests }));
  };

  const saveAllAnswers = (updatedAllAnswers) => {
    console.log("Saving allAnswers:", updatedAllAnswers);
    setAllAnswers(updatedAllAnswers);
    localStorage.setItem(
      "allAnswers",
      JSON.stringify({ answers: updatedAllAnswers })
    );
  };

  const handleDeleteTest = (testId) => {
    const updatedTests = allTests.filter((test) => test.testId !== testId);
    const updatedAnswers = allAnswers.filter(
      (answer) => answer.testId !== testId
    );

    saveAllTests(updatedTests);
    saveAllAnswers(updatedAnswers);
  };

  const handleDeleteQuestion = (testId, questionId) => {
    const updatedTests = allTests.map((test) => {
      if (test.testId === testId) {
        return {
          ...test,
          questions: test.questions.filter(
            (question) => question.id !== questionId
          ),
        };
      }
      return test;
    });

    const updatedAnswers = allAnswers.map((answer) => {
      if (answer.testId === testId) {
        return {
          ...answer,
          questions: answer.questions.filter(
            (q) => q.questionId !== questionId
          ),
        };
      }
      return answer;
    });

    saveAllTests(updatedTests);
    saveAllAnswers(updatedAnswers);
  };

  const saveQuestion = () => {
    if (!newQuestion.type || !newQuestion.question) {
      alert("Please fill in question type and question.");
      return;
    }
    const testId = currentTestId;
    const testIndex = allTests.findIndex((test) => test.testId === testId);

    if (testIndex === -1) {
      alert("Test not found.");
      return;
    }

    const updatedAllTests = [...allTests];
    const updatedAllAnswers = [...allAnswers];

    if (editQuestionId !== null) {
      // Update existing question
      const questionIndex = updatedAllTests[testIndex].questions.findIndex(
        (q) => q.id === editQuestionId
      );
      if (questionIndex !== -1) {
        updatedAllTests[testIndex].questions[questionIndex] = {
          id: editQuestionId,
          ...newQuestion,
        };

        // Update the answer
        const testAnswersIndex = updatedAllAnswers.findIndex(
          (answer) => answer.testId === testId
        );
        if (testAnswersIndex !== -1) {
          const questionAnswerIndex = updatedAllAnswers[
            testAnswersIndex
          ].questions.findIndex((q) => q.questionId === editQuestionId);
          if (questionAnswerIndex !== -1) {
            updatedAllAnswers[testAnswersIndex].questions[
              questionAnswerIndex
            ].answer = newQuestion.answer;
          } else {
            // If the answer for this question doesn't exist yet, add it
            updatedAllAnswers[testAnswersIndex].questions.push({
              questionId: editQuestionId,
              answer: newQuestion.answer,
            });
          }
        } else {
          // If the answer for this test doesn't exist yet, create it along with the answer for this question
          updatedAllAnswers.push({
            testId: testId,
            questions: [
              { questionId: editQuestionId, answer: newQuestion.answer },
            ],
          });
        }
      }
    } else {
      // Add new question
      const nextQuestionId =
        updatedAllTests[testIndex].questions.length > 0
          ? Math.max(...updatedAllTests[testIndex].questions.map((q) => q.id)) +
            1
          : 1;
      const newQuestionData = { id: nextQuestionId, ...newQuestion };
      updatedAllTests[testIndex].questions.push(newQuestionData);

      // Add the answer
      const testAnswersIndex = updatedAllAnswers.findIndex(
        (answer) => answer.testId === testId
      );
      if (testAnswersIndex !== -1) {
        updatedAllAnswers[testAnswersIndex].questions.push({
          questionId: nextQuestionId,
          answer: newQuestion.answer,
        });
      } else {
        updatedAllAnswers.push({
          testId: testId,
          questions: [
            { questionId: nextQuestionId, answer: newQuestion.answer },
          ],
        });
      }
    }

    saveAllTests(updatedAllTests);
    saveAllAnswers(updatedAllAnswers);
    setIsQuestionsModalOpen(false);
    setEditQuestionId(null);
  };

  return (
    <TestContext.Provider
      value={{
        allTests,
        setAllTests,
        allAnswers,
        setAllAnswers,
        newTest,
        setNewTest,
        currentTestId,
        setCurrentTestId,
        newQuestion,
        setNewQuestion,
        isModalOpen,
        setIsModalOpen,
        isQuestionsModalOpen,
        setIsQuestionsModalOpen,
        editQuestionId,
        setEditQuestionId,
        saveAllTests,
        saveAllAnswers,
        handleDeleteTest,
        handleDeleteQuestion,
        saveQuestion,
        searchParamsQues,
        setSearchParamsQues,
      }}
    >
      {children}
    </TestContext.Provider>
  );
};
