
import {
  TOGGLE_ADD_POST_LIST_DIALOG,
} from "../actions/postListActions";



const initialState = {
  
  isAddPostListDialog: false,

};

export const postListReducer = (state = initialState, { type, payload }) => {

  switch (type) {

    case TOGGLE_ADD_POST_LIST_DIALOG: {
      state = {
        ...state,
        isAddPostListDialog: payload
      };
      return state;
    }

    default:
      return state;

  }

};