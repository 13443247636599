import React from 'react'
import FormBox from '../../../../components/FormBox'
import { DateTimePickerCNO } from '../../../../library';
import { useGlobalStyles } from '../../../../utils/Styles/GlobalStyles';

const AddEventTimeline = ({ formik, bgColor, title = "Timeline" }) => {

  const GlobalStyles = useGlobalStyles();

  return (
    <FormBox
      {...(bgColor ? { bgColor } : {})}
      {...(title ? { title } : {})}
    >
      <div className={GlobalStyles.sectionChildrenContainer}>

        <DateTimePickerCNO
          dateName="startDate"
          datePlaceholder="Start Date"
          datePickerLabel="Start by Date"
          onDateChange={(date) => {
            formik.setFieldValue("startDate", date);
          }}
          timeName="startTime"
          timePlaceholder="Start Time"
          timePickerLabel="Start by Time"
          onTimeChange={(date) => {
            formik.setFieldValue("startTime", date);
          }}
          formik={formik}
          disablePast={true}
          iconColor="red"
        />

        <DateTimePickerCNO
          dateName="endDate"
          datePlaceholder="Finish Date"
          datePickerLabel="Finish by Date"
          onDateChange={(date) => {
            formik.setFieldValue("endDate", date);
          }}
          timeName="endTime"
          timePlaceholder="Finish Time"
          timePickerLabel="Finish by Time"
          onTimeChange={(date) => {
            formik.setFieldValue("endTime", date);
          }}
          formik={formik}
          minDate={formik.values.startDate}
          iconColor="green"
        />
      </div>
    </FormBox>
  )
}

export default AddEventTimeline