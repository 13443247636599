import {
  Chip,
  Dialog,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { mdiHumanMale } from "@mdi/js";
import Icon from "@mdi/react";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import AutocompleteCNO from "../../library/AutocompleteCNO";
import HeadingBar from "../HeadingBar/HeadingBar";
import UserSuggestionOption from "../UserSuggestionOption/UserSuggestionOption";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import RightsManager from "../../utils/RightsManager";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMemberSuggest,
  resetSuggestions,
} from "../../actions/suggestionsActions";
import { apiTaskAssign } from "../../api";
import { toast } from "react-toastify";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { Autocomplete } from "@material-ui/lab";
import { handleAssignRemoveChatGroupUsers } from "../../actions/chatActions";

function EditAssignedToDetails({ isEditable, setIsEditable, job, setJob }) {
  const dispatch = useDispatch();
  const suggestions = useSelector((state) => state.suggestions);

  const tillTablet = useMediaQuery("(max-width: 767px)");

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => setIsEditable(false);

  const handleUpdate = async (values) => {
    setLoading(true);

    const tasks = values.assignTo.map((item) => ({
      staffId: item.id || item.staffId,
      taskId: job.id,
      startDate: job.startDate,
      endDate: job.endDate,
      startTime: job.startTime,
      endTime: job.endTime,
    }));

    setJob({
      ...job,
      taskAssignments: values.assignTo.map((item) => ({
        staffId: item.id || item.staffId,
        staffText: item.title || item.staffText,
        taskId: job.id,
        startDate: job.startDate,
        endDate: job.endDate,
        startTime: job.startTime,
        endTime: job.endTime,
      })),
    });

    await apiTaskAssign
      .post(tasks)
      .then(async (response) => {
        setLoading(false);
        if (response.status === 200) {
          toast.success("Job Assign To edited successfully!!");

          if (job.teamChatGroupId) {
            // update team chat members on update of assigned users
            await dispatch(
              handleAssignRemoveChatGroupUsers(job.teamChatGroupId, tasks)
            );
          }
        } else {
          toast.error(
            "There is some issues in editing job detail, please try again!"
          );
        }
      })
      .catch((error) => {
        console.log("Edit Job assign Error: ", error);
        toast.error(
          "There is some issues in editing job detail, please try again!"
        );
      });
    handleClose();
  };

  const validationSchema = yup.object({
    assignTo: yup.array().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      assignTo: job.taskAssignments,
    },
    validationSchema,
    onSubmit: (values) => handleUpdate(values),
  });

  const unSelectedSuggestions = () =>
    suggestions.filter((item) => {
      return (
        formik.values.assignTo
          .map((item) => item.staffId || item.id)
          .indexOf(item.id) === -1
      );
    });

  console.log(
    "Formik users: ",
    formik.values.assignTo,
    unSelectedSuggestions()
  );

  const useStyles = makeStyles((theme) => ({
    container: {
      width: tillTablet ? 500 : 600,
      margin: "0 auto",
    },
    // paper: {
    //   width: 400,
    //   margin: '0 auto'
    // },
    formTitle: {
      marginLeft: 5,
    },
    form: {
      "&>*": {
        marginTop: theme.spacing(2),
      },
    },
    textField: {
      borderRadius: 5,
      "& input": {
        background: "white",
        color: theme.colors.INPUT_TEXT_COLOR,
        borderRadius: 5,
        padding: 8,
      },
      "& .MuiOutlinedInput-multiline": {
        color: theme.colors.INPUT_TEXT_COLOR,
      },
      "& .MuiOutlinedInput-adornedStart": {
        background: "white",
      },
      "& .MuiOutlinedInput-input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 100px #fff inset",
        "-webkit-text-fill-color": theme.colors.INPUT_TEXT_COLOR,
        caretColor: theme.colors.INPUT_TEXT_COLOR,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
        {
          paddingLeft: 14,
        },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: 0,
      },
      "& .MuiOutlinedInput-adornedEnd": {
        padding: 0,
      },
      "& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
        padding: 0,
      },
    },
    noOptions: {
      color: theme.colors.ERROR_COLOR,
    },
    popper: {
      zIndex: 1301,
    },
    suggestionsPaper: {
      background: "white !important",
    },
    assignToWrapper: {
      display: "flex",
      // alignItems: 'flex-end',
      flexDirection: "column",
      "&>*:first-child": {
        flex: 1,
        marginBottom: 8,
      },
    },
    chip: {
      background: theme.colors.CHIP_BUTTON_BACKGROUND_COLOR,
    },
    paper: {
      padding: theme.spacing(2),
      background: theme.colors.DIALOG_BACKGROUND_COLOR,
      color: theme.colors.DIALOG_TITLE_COLOR,
      borderRadius: 10,
      minWidth: tillTablet ? 500 : 600,
      width: "70vw",
      maxWidth: "90vh",
    },
  }));
  const styles = useStyles();

  if (loading) {
    return <FullScreenLoaderCNO />;
  }

  return (
    <Dialog
      open={isEditable}
      className={styles.container}
      classes={{
        paper: styles.paper,
      }}
    >
      <HeadingBar title="Select Team Member" onClose={handleClose} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <Autocomplete
          className={styles.textField}
          classes={{
            paper: styles.suggestionsPaper,
            noOptions: styles.noOptions,
            popper: styles.popper,
          }}
          value={formik.values.assignTo}
          open={open}
          multiple
          dense
          closeIcon={() => null}
          forcePopupIcon={false}
          options={unSelectedSuggestions()}
          getOptionLabel={(option) => option.title}
          renderOption={(option) => <UserSuggestionOption user={option} />}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.title || option.staffText}
                {...getTagProps({ index })}
                className={styles.chip}
              />
            ));
          }}
          renderInput={(params) => (
            <TextField
              autoFocus
              {...params}
              variant="outlined"
              placeholder="Select Team Member"
            />
          )}
          onChange={(e, newValue) => {
            formik.setFieldValue("assignTo", newValue);
          }}
          onInputChange={(e) => {
            if (e && e.target.value) {
              dispatch(fetchMemberSuggest(e.target.value));
              setOpen(true);
            } else {
              setOpen(false);
            }
          }}
          onBlur={() => {
            setOpen(false);
            dispatch(resetSuggestions());
          }}
        />
        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
          disabled={formik.values.assignTo.length === 0}
        />
      </form>
    </Dialog>
  );
}

function AssignedToDetailsCard({ job, setJob }) {
  const isUserAdmin = RightsManager.isAdmin();

  const [isEditable, setIsEditable] = useState(false);

  const [assignTo, setAssignTo] = useState(null);
  const [assignId, setAssignId] = useState("");

  useEffect(() => {
    setAssignTo(Object.keys(job).length && job?.taskAssignments);
    setAssignId(Object.keys(job).length && job?.taskAssignments);
  }, [job]);

  console.log("assignments", job?.taskAssignments);

  const styles = useStyles();
  return (
    <>
      {isUserAdmin && isEditable && (
        <EditAssignedToDetails
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          job={job}
          setJob={setJob}
          assignTo={assignTo}
          setAssignTo={setAssignTo}
          assignId={assignId}
        />
      )}
      <div className={styles.container} onClick={() => setIsEditable(true)}>
        <Typography className={styles.cardTitle}>Assigned to</Typography>
        <div className={styles.cardContent}>
          <div className={styles.row}>
            <Typography component="div">
              {job?.taskAssignments?.map((staff) => (
                <p key={staff.staffId}>{staff.staffText}</p>
              ))}
            </Typography>
            <Icon path={mdiHumanMale} size="2rem" className={styles.icon} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AssignedToDetailsCard;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`
  },
  cardTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
  },
  cardContent: {
    padding: theme.spacing(1),
    "&>*": {
      marginTop: theme.spacing(1),
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.colors.JOB_DETAIL_ASSIGN_USER_COLOR,
  },
  icon: {
    color: theme.colors.JOB_DETAIL_ASSIGN_USER_ICON_COLOR,
  },
}));
