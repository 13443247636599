import { CircularProgress, IconButton, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiClose, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerSuggest, fetchMemberSuggest } from '../../actions/suggestionsActions';
import { IconPicker } from '../../library/IconPickerCNO';
import InfiniteScrollCNO from '../../library/InfiniteScrollCNO';
import CompanyListCard from '../CompanyListCard';
import JobCardSkeleton from '../JobCardSkeleton/JobCardSkeleton';
import { useStyles } from './CompanyListingsStyles';

function CompanyListings({
  profileList,
  profileLoading,
  loadMore,
  hasMore
}) {

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const theme = useTheme();
  const styles = useStyles();

  const dispatch = useDispatch();
  const suggestions = useSelector(state => state.suggestions);

  const [profileSearchKey, setProfileSearchKey] = useState("");
  const [searchedProfileList, setSearchedProfileList] = useState([]);

  useEffect(() => {
    if (profileSearchKey.length) {
      setSearchedProfileList(suggestions);
    } else {
      setSearchedProfileList(profileList);
    }
  }, [suggestions, profileSearchKey, profileList]);

  const handleProfileSearch = (e) => {

    const searchKey = e.target.value;
    setProfileSearchKey(searchKey);

    dispatch(fetchCustomerSuggest(searchKey));

  };

  return (
    <div className={styles.container_CompanyListings}>

      {
        tillTablet &&
        <div className={styles.searchToolbar}>
          <TextField
            value={profileSearchKey}
            type="search"
            placeholder="Search Company..."
            className={styles.search}
            variant='outlined'
            margin='dense'
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment>
                <Icon color={theme.colors.INPUT_ICON_COLOR} path={mdiMagnify} size='1.5rem' />
              </InputAdornment>,
              endAdornment: profileSearchKey &&
                (<InputAdornment>
                  <IconButton size="small" onClick={() => setProfileSearchKey("")}>
                    <Icon color={theme.colors.INPUT_ICON_COLOR} path={mdiClose} size='1.5rem' />
                  </IconButton>
                </InputAdornment>),
            }}
            onChange={handleProfileSearch}
          />
        </div>
      }

      <InfiniteScrollCNO
        items={searchedProfileList}
        next={loadMore}
        hasMore={hasMore}
        loading={profileLoading}
        renderListItem={(profile) => (
          <CompanyListCard key={profile.id} profile={profile} />
        )}
        notFoundTextMessage={'Nothing Found...'}
      />

    </ div>
  )
}

export default CompanyListings
