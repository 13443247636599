import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../../common/components/Box';
import Container from '../../../common/components/UI/Container';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import data from '../../../common/data/LandingPage/TestimonialSlider';
import SliderDes from '../sliderDescription';
import Image1 from '../../../common/assets/image/LandingPage/gardeningindeed.png';
import Image2 from '../../../common/assets/image/LandingPage/jdm.png';
import Image3 from '../../../common/assets/image/LandingPage/davinci.jpeg';
import Image4 from '../../../common/assets/image/LandingPage/man_2.png';
import Image5 from '../../../common/assets/image/LandingPage/london.jpeg';
import { useTheme } from '@material-ui/core';
import { ThemeType } from '../../../utils/mappings';

const images = [
  {
    thumbnail: `${Image1}`,
    thumbnailAlt: 'thumb one',
    description: <SliderDes data={data.testimonials[0]} />,
  },
  {
    thumbnail: `${Image2}`,
    thumbnailAlt: 'thumb two',
    description: <SliderDes data={data.testimonials[1]} />,
  },
  {
    thumbnail: `${Image4}`,
    thumbnailAlt: 'thumb three',
    description: <SliderDes data={data.testimonials[2]} />,
  },
  {
    thumbnail: `${Image3}`,
    thumbnailAlt: 'thumb four',
    description: <SliderDes data={data.testimonials[3]} />,
  },
  {
    thumbnail: `${Image5}`,
    thumbnailAlt: 'thumb five',
    description: <SliderDes data={data.testimonials[4]} />,
  },
];

const TestimonialSection = ({ sectionWrapper, row, sectionSubTitle }) => {

  const isDarkTheme = useTheme().palette.type === ThemeType.Dark;

  return (
    <Box
      {...sectionWrapper}
      className="testimonialSlider"
      id="testimonialSection"
    >
      <Container>
        <Box className="testimonialDesWrapper">
          <ImageGallery
            items={images}
            originalClass="Testimonial-img"
            showPlayButton={false}
            showFullscreenButton={false}
          // showNav={false}
          />
        </Box>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  title: PropTypes.object,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: '0px',
    pb: ['20px', '80px', '0px', '80px', '80px'],
  },

  sectionSubTitle: {
    content: 'CLIENT TESTIMONIAL',
    as: 'span',
    display: 'block',
    textAlign: ['center', 'left'],
    fontSize: '14px',
    letterSpacing: '0.11em',
    fontWeight: '700',
    color: '#ff8000',
    textTransform: 'uppercase',
    mb: '10px',
  },
};

export default TestimonialSection;
