import { mdiDirections, mdiPhoneCancel, mdiPhoneMissed, mdiPhoneIncoming } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { View, Text } from 'react-native';
import { Avatar, Bubble, SystemMessage, Message, MessageText } from 'react-native-gifted-chat';
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import AvatarCNO from '../../library/AvatarCNO/AvatarCNO';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { CircularProgress, Typography } from '@material-ui/core';
import LinkCNO from '../../library/LinkCNO';
import moment from 'moment';
import { MessageType } from '../../utils';

export const renderAvatar = (props, theme) => {

  console.log("Avatar Props: ", props);

  return (
    <Avatar
      {...props}
      containerStyle={{ left: {}, right: {} }}
      imageStyle={{ left: { borderWidth: 2, borderColor: theme.colors.CHAT_TEXT_MESSAGE_USER_AVATAR_BORDER_COLOR, marginLeft: -8 }, right: {} }}
      textStyle={{
        color: theme.colors.CHAT_TEXT_MESSAGE_USER_AVATAR_TEXT_COLOR,
        fontWeight: '500'
      }}
    />
  )
};

export const renderBubble = (props, theme) => {

  // console.log("currentMessage: ", props.currentMessage)

  return (
    <Bubble
      {...props}
      renderTime={() =>
        <Text style={{ color: theme.colors.CHAT_MESSAGE_TIME_COLOR, fontSize: 10 }}>
          {moment(props.currentMessage.creationDate).format('hh:mm a')}
        </Text>
      }
      // renderTicks={() => <Text>Ticks</Text>}
      containerStyle={{
        left: {
          backgroundColor: theme.colors.CHAT_MESSAGE_LEFT_BUBBLE_CONTAINER_BACKGROUND_COLOR,
        },
        right: {
          backgroundColor: theme.colors.CHAT_MESSAGE_RIGHT_BUBBLE_CONTAINER_BACKGROUND_COLOR,
        },
      }}
      wrapperStyle={{
        left: { color: theme.colors.CHAT_MESSAGE_LEFT_BUBBLE_WRAPPER_TEXT_COLOR, padding: 5, backgroundColor: theme.colors.CHAT_MESSAGE_LEFT_BUBBLE_WRAPPER_BACKGROUND_COLOR, borderTopRightRadius: 15, marginBottom: 10 },
        right: { color: theme.colors.CHAT_MESSAGE_RIGHT_BUBBLE_WRAPPER_TEXT_COLOR, padding: 5, backgroundColor: theme.colors.CHAT_MESSAGE_RIGHT_BUBBLE_WRAPPER_BACKGROUND_COLOR, borderTopRightRadius: 15, marginBottom: 10 },
      }}
      textStyle={{
        left: { color: theme.colors.CHAT_MESSAGE_LEFT_BUBBLE_TEXT_COLOR },
        right: { color: theme.colors.CHAT_MESSAGE_RIGHT_BUBBLE_TEXT_COLOR },
      }}
      bottomContainerStyle={{
        left: { padding: 5 },
        right: { padding: 5 },
      }}
      tickStyle={{}}
      usernameStyle={{ color: theme.colors.CHAT_MESSAGE_BUBBLE_USERNAME_COLOR, fontWeight: '500' }}
      containerToNextStyle={{
        left: { padding: 5, backgroundColor: theme.colors.CHAT_MESSAGE_LEFT_BUBBLE_CONTAINER_TO_NEXT_BACKGROUND_COLOR, borderTopRightRadius: 15, color: theme.colors.CHAT_MESSAGE_LEFT_BUBBLE_CONTAINER_TO_NEXT_TEXT_COLOR },
        right: { padding: 5, backgroundColor: theme.colors.CHAT_MESSAGE_RIGHT_BUBBLE_CONTAINER_TO_NEXT_BACKGROUND_COLOR, borderTopRightRadius: 15, color: theme.colors.CHAT_MESSAGE_RIGHT_BUBBLE_CONTAINER_TO_NEXT_TEXT_COLOR },
      }}
      containerToPreviousStyle={{
        left: { padding: 5, backgroundColor: theme.colors.CHAT_MESSAGE_LEFT_BUBBLE_CONTAINER_TO_PREV_BACKGROUND_COLOR, borderTopRightRadius: 15, color: theme.colors.CHAT_MESSAGE_LEFT_BUBBLE_CONTAINER_TO_PREV_TEXT_COLOR },
        right: { padding: 5, backgroundColor: theme.colors.CHAT_MESSAGE_RIGHT_BUBBLE_CONTAINER_TO_PREV_BACKGROUND_COLOR, borderTopRightRadius: 15, color: theme.colors.CHAT_MESSAGE_RIGHT_BUBBLE_CONTAINER_TO_PREV_TEXT_COLOR },
      }}
    />
  )
};

export const renderSystemMessage = (props, theme) => (
  <SystemMessage
    {...props}
    // containerStyle={{ backgroundColor: '#1e1e1e' }}
    wrapperStyle={{
      borderWidth: 1,
      borderColor: theme.colors.CHAT_SYSTEM_MESSAGE_BORDER_COLOR,
      borderRadius: 20,
      paddingHorizontal: 8,
      paddingVertical: 2,
      backgroundColor: theme.colors.CHAT_SYSTEM_MESSAGE_BACKGROUND_COLOR,
    }}
    textStyle={{ color: theme.colors.CHAT_SYSTEM_MESSAGE_COLOR }}
  />
);

export const renderMessage = (props, theme) => {
  const { currentMessage } = props;

  //console.log("----renderMessage-------currentMessage-----------", currentMessage.id, currentMessage.messageType);

  if ((currentMessage.id && currentMessage.messageType) &&
    (currentMessage.messageType === MessageType.Candidate ||
    currentMessage.messageType === MessageType.AutoAnswer ||
    currentMessage.messageType === MessageType.AutoOffer ||
    currentMessage.messageType === MessageType.VideoCall ||
    currentMessage.messageType === MessageType.AudioCall ||
    currentMessage.messageType === MessageType.AudioCallEnded ||
    currentMessage.messageType === MessageType.VideoCallEnded)) {
    return;
  }

  if (currentMessage.messageType === MessageType.VideoCallAccepted || currentMessage.messageType === MessageType.AudioCallAccepted) {
    return (
      <div 
      key={currentMessage.id}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        padding: 10
      }}>
        <Icon
          path={mdiPhoneIncoming}
          size="1rem"
          color={theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_ICON_COLOR}
          style={{
            marginRight: 5,
          }}
        />
        <Typography style={{ color: theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_TEXT_COLOR }}>
          Received Call on&nbsp;
          <Text style={{ color: theme.colors.CHAT_MESSAGE_TIME_COLOR, fontSize: 14 }}>
            {moment(currentMessage.creationDate).format('ll [at ] hh:mm a')}
          </Text>
        </Typography>
      </div>
    )
  }

  else if (currentMessage.messageType === MessageType.VideoCallRejected || currentMessage.messageType === MessageType.AudioCallRejected) {
    return (
      <div 
      key={currentMessage.id}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        padding: 10
      }}>
        <Icon
          path={mdiPhoneCancel}
          size="1rem"
          color={theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_ICON_COLOR}
          style={{
            marginRight: 5,
          }}
        />
        <Typography style={{ color: theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_TEXT_COLOR }}>
          Call Rejected on&nbsp;
          <Text style={{ color: theme.colors.CHAT_MESSAGE_TIME_COLOR, fontSize: 14 }}>
            {moment(currentMessage.creationDate).format('ll [at ] hh:mm a')}
          </Text>
        </Typography>
      </div>
    )
  }

  else if (currentMessage.messageType === MessageType.VideoCallMissed || currentMessage.messageType === MessageType.AudioCallMissed) {
    return (
      <div 
      key={currentMessage.id}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        padding: 10
      }}>
        <Icon
          path={mdiPhoneMissed}
          size="1rem"
          color={theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_ICON_COLOR}
          style={{
            marginRight: 5,
          }}
        />
        <Typography style={{ color: theme.colors.CHAT_PROFILE_LIST_ITEM_CHAT_SNIPPET_TEXT_COLOR }}>
          Missed Call on&nbsp;
          <Text style={{ color: theme.colors.CHAT_MESSAGE_TIME_COLOR, fontSize: 14 }}>
            {moment(currentMessage.creationDate).format('ll [at ] hh:mm a')}
          </Text>
        </Typography>
      </div>
    )
  }
  else {
    return (
      <Message
        {...props}
        //renderDay={() => <Text>Date</Text>}
        containerStyle={{
          left: { backgroundColor: theme.colors.CHAT_LEFT_MESSAGE_BACKGROUND_COLOR, padding: 5, color: theme.colors.CHAT_LEFT_MESSAGE_COLOR },
          right: { backgroundColor: theme.colors.CHAT_RIGHT_MESSAGE_BACKGROUND_COLOR, padding: 5, color: theme.colors.CHAT_RIGHT_MESSAGE_COLOR },
        }}
      />)
  };
};

export const renderMessageText = (props, theme) => {
  return (<MessageText
    {...props}
    containerStyle={{
      left: {},
      right: {},
    }}
    textStyle={{
      left: { color: theme.colors.CHAT_LEFT_MESSAGE_TEXT_COLOR },
      right: { color: theme.colors.CHAT_RIGHT_MESSAGE_TEXT_COLOR },
    }}
    linkStyle={{
      left: { color: theme.colors.CHAT_LEFT_MESSAGE_TEXT_LINK_COLOR },
      right: { color: theme.colors.CHAT_RIGHT_MESSAGE_TEXT_LINK_COLOR },
    }}
    customTextStyle={{ fontSize: 14, lineHeight: 20, }}
  />);
}

export const RenderCustomView = (props, theme) => {

  const { currentMessage } = props;

  const containerStyle = {
    width: '225px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 2,
  };

  if (!currentMessage.location) {
    // console.log("location: false")
    return null
  }

  return (
    <div 
    key={currentMessage.id}
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: "center",
      padding: 10
    }}>
      <LinkCNO
        type='absolute'
        target='_blank'
        to={`https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${currentMessage.location.latitude},${currentMessage.location.longitude}`}
      >
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{
              lat: currentMessage.location.latitude,
              lng: currentMessage.location.longitude
            }}
            loadingElement={<CircularProgress />}
            zoom={13}
          >
            <Marker
              position={{
                lat: currentMessage.location.latitude,
                lng: currentMessage.location.longitude
              }}
            />
          </GoogleMap>
        </LoadScript>
      </LinkCNO>

      <LinkCNO
        type='absolute'
        target='_blank'
        to={`https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${currentMessage.location.latitude},${currentMessage.location.longitude}`}
      >
        <Icon
          style={{ marginLeft: 10 }}
          path={mdiDirections} size='2rem' color={theme.colors.GET_DIRECTION_ICON_COLOR} />
      </LinkCNO>
    </div>
  )

};

export const renderMessageVideo = (props) => {
  const { currentMessage } = props;
  return (
    <ReactPlayer
      key={currentMessage.id}
      url={currentMessage.video}
      controls
      width="350px"
      height="250px"
      volume={0.3}
      pip
    />
  );
};

export const renderMessageAudio = (props) => {
  const { currentMessage } = props;
  // console.log("---------currentMessage------------", currentMessage.audio);
  return (
    <ReactAudioPlayer
      key={currentMessage.id}
      src={currentMessage.audio}
      controls
    />
    // <audio
    //   src={currentMessage.audio}
    //   controls
    // ></audio>
  );
};