import { makeStyles, Typography, useTheme } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useRef } from 'react';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-google-places-autocomplete';
import { toast } from 'react-toastify';
import FieldHelperTextCNO from '../FieldHelperTextCNO';
import InputCNO from '../InputCNO';

function GooglePlaceInputCNO({
  address,
  onSelectAddress,
  label = "Address",
  isRequired = false,
  helperText,
  showHelperText,
  disabled,
}) {

  const theme = useTheme();
  const styles = useStyles();

  const [placeValue, setPlaceValue] = useState(null);

  const handleOnSelectAddress = (values) => {
    if (disabled) return;
    typeof onSelectAddress === 'function' && onSelectAddress(values);
  }

  useEffect(() => {

    if (address === null) return;


    if (address && window.google && window.google.maps) {
      handleSelectPlace({ label: address });
    } else {
      setPlaceValue(null);
      handleOnSelectAddress({
        address: "",
        lat: 0,
        lng: 0
      });
    }
  }, [address]);

  const handleSelectPlace = async (place) => {

    console.log("============handleSelectPlace======", place);
    setPlaceValue(place);

    if (!(place && place.label)) {
      handleOnSelectAddress({
        address: "",
        lat: 0,
        lng: 0
      });

      return;
    }

    let latitude = 0;
    let longitude = 0;
    await geocodeByAddress(place.label)
      .then(results => {
        setPlaceValue({
          label: place.label,
          value: results
        });
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        latitude = lat.toString();
        longitude = lng.toString();
        handleOnSelectAddress({
          address: place.label,
          lat: latitude,
          lng: longitude
        });
      })
      .catch(error => {

        console.log("GoogleError: error", error);

        toast.error("Location fetch error!!!");

      });

  };

  const selectRef = useRef();

  return (
    <div style={{ width: '100%' }}>
      <Typography
        className={styles.label}
        component="label"
      >
        {label}
        <span style={{ color: theme.colors.ERROR_COLOR }}>{isRequired ? ' *' : null}</span>
      </Typography>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
        selectProps={{
          ref: selectRef,
          styles: {
            option: (provided) => ({
              ...provided,
              color: 'brown',
            }),
            container: (provided) => ({
              ...provided,
              marginTop: 8,
              marginBottom: 16
            })
          },
          value: placeValue,
          placeholder: "Select location",
          onChange: handleSelectPlace,
          onFocus: (e) => {
            console.log("========focsss: ", e)
            const selectInstance = selectRef;
            console.log("====focsss====selectInstance: ", selectInstance.current);
            setPlaceValue(placeValue);

          },
          onBlur: (e) => {
            const selectInstance = selectRef;
            const value = selectInstance.current.props.value || null;
            handleSelectPlace(value);
          },
          isDisabled: disabled
        }}
      />
      <FieldHelperTextCNO
        helperText={helperText}
        showHelperText={showHelperText}
      />
    </div>
  )
}

export default GooglePlaceInputCNO;

const useStyles = makeStyles((theme) => ({
  label: {
    flex: 1,
    marginRight: 5,
    marginTop: theme.spacing(2),
    color: theme.colors.FORM_LABEL_COLOR
  }
}))
