import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core'
import CloseIcon from '../CloseIcon';
import './index.css';
import { FileType } from '../../utils/mappings';
import { bottom, flexDirection, flexWrap, overflow, overflowX } from 'styled-system';
import ReactPlayer from 'react-player';

const CollageView = ({ mediaLinks, onMediaUnselect }) => {
  const styles = useStyles();

  return (
    <div className={styles.collageContainer}>
      {mediaLinks.map((media, index) => (
        <div className={styles.SelectedMediaContainer} key={index}>
          <div className={styles.mediaWrapper}>
            {media.fileType === FileType.Video ? (
              
              <div className={styles.reactPlayerWrapper}>
                <ReactPlayer
                  url={media.link}
                  controls
                  width="100%"
                  height="100%"
                  className={styles.reactPlayer}
                />
              </div>
            ) : (
              
                <img
                  src={media.link}
                  className={styles.media}
                  alt={`image-${index}`}
                />
                
              
            )}
            <span
              className={styles.removeBtn}
              onClick={() => onMediaUnselect(media.id)}
            >
              <CloseIcon />
            </span>

          </div>
        </div>
      ))}
    </div>
  );
};

export default CollageView;

const useStyles = makeStyles((theme) => ({
  collageContainer: {
    margin: '5px 0px 20px 0px',
    backgroundColor: 'rgba(0, 0, 0, 0.26)',
    borderRadius: 10,
    overflowX: "auto",
    whiteSpace: "nowrap",
    display: 'flex',
    padding: 40,
    columnGap: 5,
    width: 'auto',
    flexWrap: 'wrap',
    justifyContent: 'center', 
  },

  SelectedMediaContainer: {
    flex: '0 0 150px', 
    marginBottom: '5px', 
    
  },

  mediaWrapper:{
    position: 'relative',
    width:'130px',
    height:"110px",

  },
  reactPlayerWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  reactPlayer: {
    width: '100%',
    height: '100%',
  },

  media: {
    position: "absolute", 
    top: 0, 
    left: 0, 
    width: "100% ",
    height: "100% ",
    borderRadius: 20,

  },

  removeBtn: {
    position: 'absolute',
    top: '3px', 
    right: '3px', 
    cursor: 'pointer', 
    borderRadius: '50%', 
    padding: '3px', 
  },

  '@media (max-width: 1160px)': { 
    collageContainer:{
      display: "inline-block",
      width: "100%",
      overflowX: "auto",
      whiteSpace: "nowrap",
      padding: "20px 0",
      
    },

    SelectedMediaContainer: {
      display: "inline-block",
      paddingRight: "5px",
    },  
  },

}));


// const useStyles = makeStyles((theme) => ({
//   collageContainer: {
    
//   },

//   SelectedMediaContainer: {
    
//     flex: '0 0 150px', 
//     margin: '10px', 
//   },

//   mediaWrapper:{
//     position: 'relative',
//     width:'100px',
//     height:"100px",
//   },

//   media: {
//     maxWidth: '100%', 
//     maxHeight: '100%', 
//     display: 'block', 
//     borderRadius: '10px', 

//   },

//   removeBtn: {
//     position: 'absolute',
//     top: '5px', 
//     right: '5px', 
//     cursor: 'pointer', 
//     borderRadius: '50%', 
//     padding: '3px', 
//   },

  
// }));