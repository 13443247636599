import {
  mdiMapMarkerCircle,
  mdiBriefcase,
  mdiEye,
  mdiAccountSwitch,
  mdiProgressCheck,
  mdiChartMultiple
} from '@mdi/js';

const data = {
  features: [
    {
      id: 1,
      icon: mdiMapMarkerCircle,
      title: 'Real-time location tracking',
      description:
        'Track your teams location at real-time. Determine hours spent by your team members with respect to your job location.',
    },
    {
      id: 2,
      icon: mdiBriefcase,
      title: 'Meeting > Offer > Project > Invoice',
      description:
        'Manage full life cycle of scheduled meetings, quotation management, project execution and invoicing.',
    },
    {
      id: 3,
      icon: mdiEye,
      title: 'Watch the on-going work LIVE',
      description: 'Get in a video call with your team members to monitor the progress of your on-going job.',
    },
    {
      id: 4,
      icon: mdiAccountSwitch,
      title: 'Collaborate with customers',
      description: 'Keep your customers up-to-date with the help of instant chatting, notifications and direct access.',
    },
    {
      id: 5,
      icon: mdiProgressCheck,
      title: 'Monitor progress',
      description: 'Get instant notifications about the progress. Check progress details in real-time.',
    },
    {
      id: 6,
      icon: mdiChartMultiple,
      title: 'Analyse your business',
      description: 'Analyse your business with details insights on hidden facts to plan better and ahead of time.',
    },
  ],
};
export default data;
