import React from 'react';
import QuotatioinMainDetailsCard from '../../components/Quotation/QuotatioinMainDetailsCard';
import QuotationCustomerDetailsCard from '../../components/Quotation/QuotationCustomerDetailsCard';
import QuotationLocationDetailsCard from '../../components/Quotation/QuotationLocationDetailsCard';
import QuotationStatusButtonBar from '../../components/Quotation/QuotationStatusButtonBar';
import QuotationJobTimeDetailsCard from '../../components/Quotation/QuotationJobTimeDetailsCard';
import QuotationMediaDetailsCard from '../../components/Quotation/QuotationMediaDetailsCard';

import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import MoreActionCNO from '../../library/MoreActionsCNO';
import QuotationListCardPopover from '../../components/Quotation/QuotationListCardPopover';
import { useSelector } from 'react-redux';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import QuotationTargetDetails from '../../components/Quotation/QuotationTargetDetails';
import { SubTaskType } from '../../utils';
import QuotationTimelineDetailsCard from '../../components/Quotation/QuotationTimelineDetailsCard';
import QuotationNotesDetailsCard from '../../components/Quotation/QuotationNotesDetailsCard';
import { QuotationStatus } from '../../utils/mappings';
import { QuotationItemDetailsCard } from '../../components/QuotationItem';
import QuotationPaymentSummaryDetailsCard from './QuotationPaymentSummaryDetailsCard';

function QuotationDetails({ quotation }) {

  const GlobalStyles = useGlobalStyles();

  const isSent = quotation.status === QuotationStatus.Sent;
  const isSentAccepted = quotation.status === QuotationStatus.SentAccepted;

  const { isJobLoader } = useSelector(state => state.jobs);
  const { allQuotationLoader, quotations } = useSelector(state => state.quotations);
  const { editLoader } = allQuotationLoader;

  console.log("=========> quotation ", quotation);

  return (
    <div className={GlobalStyles.detailsContainer}>

      {
        (editLoader || isJobLoader)
        &&
        <FullScreenLoaderCNO />
      }

      <div className={GlobalStyles.statusBar}>
        <QuotationStatusButtonBar quotation={quotation} />
        <MoreActionCNO>
          <QuotationListCardPopover quotation={quotation} />
        </MoreActionCNO>
      </div>

      <QuotatioinMainDetailsCard quotation={quotation} />

      <QuotationMediaDetailsCard quotation={quotation} />

      <QuotationItemDetailsCard quotation={quotation} quotationItemType={SubTaskType.Quotation} />

      <QuotationPaymentSummaryDetailsCard quotation={quotation} />

      <QuotationCustomerDetailsCard quotation={quotation} />

      <QuotationJobTimeDetailsCard quotation={quotation} />

      <QuotationLocationDetailsCard quotation={quotation} />

      {/* <QuotationCostDetails quotation={quotation} /> */}

      <QuotationTargetDetails quotation={quotation} />

      <QuotationTimelineDetailsCard quotation={quotation} />

      {/* <QuotationNotesDetailsCard quotation={quotation} /> */}

    </div>
  )
}

export default QuotationDetails
