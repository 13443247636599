import { makeStyles, useMediaQuery } from "@material-ui/core";
import React, { useState, useContext } from "react";
import TestListingsInfinite from "../../components/TestListingsInfinite/TestListingsInfinite";
import ScreenView from "../../components/ScreenView/ScreenView";
import Switcher from "../../common/components/GridListToggle/Switcher";
import TestSearchSidebar from "../../components/TestSearchSidebar";
import { TestContext } from "../../common/contexts/TestContext";

function AdminTestPage() {
  const styles = useStyles();
  const tillTablet = useMediaQuery("(max-width: 768px)");
  const [isGridView, setIsGridView] = useState(false);
  const [page, setPage] = useState(0);
  const { allTests, allAnswers, setAllTests, setAllAnswers } =
    useContext(TestContext);
  const [searchParams, setSearchParams] = useState({
    title: "",
    description: "",
    duration_minutes: [0, 100],
  });

  const renderContent = () => (
    <div className={styles.content_JobPage}>
      <div style={{ marginTop: 10 }}>
        <Switcher isGridView={isGridView} setIsGridView={setIsGridView} />
        <TestListingsInfinite
          page={page}
          setPage={setPage}
          isGridView={isGridView}
          setIsGridView={setIsGridView}
          searchParams={searchParams}
        />
      </div>
    </div>
  );

  return (
    <div className={styles.container_JobPage}>
      <ScreenView
        content={renderContent()}
        secondarySidebar={
          <TestSearchSidebar
            setSearchParams={setSearchParams}
            setPage={setPage}
          />
        }
      />
    </div>
  );
}

export default AdminTestPage;

const useStyles = makeStyles((theme) => ({
  container_JobPage: {
    width: "100%",
    minHeight: "100vh",
  },
  content_JobPage: {},
}));
