import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import MyThemeProvider from "./providers/ThemeProvider";
import { BrowserRouter } from "react-router-dom";
import GlobalProvider from "./providers/GlobalProvider";

import { Provider } from "react-redux";
import { store } from "./store";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { ToastContainer } from "react-toastify";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { SubscriptionProvider } from "./hooks/useIsFeatureAllowedInSubscription";
import { OnlineStatusProvider } from "./hooks/useOnlineStatus";
import { Toaster } from "react-hot-toast";
import NewApp from "./utils/MapUtilsNew/NewApp";
import { TestProvider } from "./common/contexts/TestContext";
const Index = () => {
  return (
    <GlobalProvider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Toaster />
      <BrowserRouter>
        <ScrollToTop />
        <MyThemeProvider>
          <Provider store={store}>
            <OnlineStatusProvider>
              <SubscriptionProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TestProvider>
                    <App />
                  </TestProvider>
                  {/* <NewApp/> */}
                </MuiPickersUtilsProvider>
              </SubscriptionProvider>
            </OnlineStatusProvider>
          </Provider>
        </MyThemeProvider>
      </BrowserRouter>
    </GlobalProvider>
  );
};

ReactDOM.render(<Index />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
