import React from 'react';
import { makeStyles, Typography, useTheme } from '@material-ui/core';
import IconCNO from '../../library/IconCNO';
import { getCategory } from '../../utils/Helpers';

function JobTypeIcon({
  iconLink,
  name,
  categoryId,
  iconWidth = 50,
  iconHeight = 50,
  iconSize = "45px",
  ...rest
}) {
  const styles = useStyles();

  const theme = useTheme();

  let selfCategory = getCategory(categoryId);

  console.log("selfCategory: categoryId ", categoryId);
  console.log("selfCategory: ", selfCategory);

  return (
    <div className={styles.container} {...rest}>

      <IconCNO
        name={selfCategory.iconLink}
        size={iconSize}
        color={theme.colors.JOB_TYPE_ICON_COLOR}
        styles={{
          color: theme.colors.JOB_TYPE_ICON_COLOR,
          borderRadius: '50%',
          border: `1px solid ${theme.colors.JOB_TYPE_BORDER_COLOR}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: iconWidth,
          height: iconHeight,
          padding: theme.spacing(1.5),
          paddingTop: 5,
          background: theme.colors.JOB_TYPE_ICON_BACKGROUND_COLOR,
        }}
      />

      <div className={styles.jobCategory}>
        <Typography title={selfCategory.title} noWrap >{selfCategory.title}</Typography>
      </div>
    </div>
  )
}

export default JobTypeIcon

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    color: theme.colors.JOB_TYPE_ICON_COLOR,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.JOB_TYPE_BORDER_COLOR}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 45,
    height: 45,
    padding: theme.spacing(1.1),
    paddingTop: 2,
    background: theme.colors.JOB_TYPE_ICON_BACKGROUND_COLOR,
  },
  jobCategory: {
    border: `1px solid ${theme.colors.JOB_TYPE_TEXT_BORDER_COLOR}`,
    display: 'inline-block',
    padding: '0px 10px',
    borderRadius: 8,
    position: 'relative',
    top: -10,
    background: theme.colors.JOB_TYPE_TEXT_BACKGROUND_COLOR,
    minWidth: 70,
    maxWidth: 80,
    width: 80,
    textAlign: 'center',
    color: theme.colors.JOB_TYPE_TEXT_COLOR,
    '&>*': {
      fontSize: '0.7rem',
    }
  }
}))