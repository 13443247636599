import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import FormErrorsCNO from "../library/FormErrorsCNO";
import React from "react";

const useFormikErrors = (formik) => {

  const [formikErrors, setFormikErrors] = useState([]);

  useEffect(() => {

    toast.dismiss();

    const errors = formik.errors;
    const errorKeys = Object.keys(errors);
    const touched = formik.touched;

    console.log("=======useFormikErrors=====errors====", errors);

    const allErrors = errorKeys
      .filter(errorKey => touched[errorKey])
      .map(errorKey => errors[errorKey]);

    setFormikErrors(allErrors);

    if (allErrors.length) {

      toast(
        <Fragment>
          <FormErrorsCNO errors={allErrors} />
        </Fragment>
      );
    }

  }, [formik.errors, formik.touched]);


  return formikErrors;

};

export default useFormikErrors;