import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import AvatarCNO from '../../../library/AvatarCNO';
import { DotSeparator, MetaText } from '../../../library';
import { formatDateTimeFromNowInShort } from '../../../utils/formatDate';
import { useDispatch } from 'react-redux';
import { setCommentInputDetails } from '../../../actions/postActions';

const CommentListItem = React.forwardRef(({ comment }, inputRef) => {

  const styles = useStyles({ level: comment.level });

  const user = comment.user;

  const dispatch = useDispatch();

  const handleReplyFocus = () => {

    inputRef.current.focus();

    console.log("CommentListItem: ref: ", inputRef.current.focus)

    dispatch(setCommentInputDetails({
      inputType: 'reply',
      parentId: comment.id
    }));
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.contentWrapper}>
        <AvatarCNO
          src={user.profilePic}
          title={user.title}
          avatarSize={28}
        />
        <Box className={styles.rightContentWrapper}>
          <MetaText fontSize={12} fontWeight='bold' >{user.title}</MetaText>
          <MetaText fontSize={12} >{comment.comment}</MetaText>
        </Box>
      </Box>
      <Box className={styles.actions} hitSlop={5} onClick={handleReplyFocus} >
        <MetaText fontSize={10}>{formatDateTimeFromNowInShort(comment.createdAt)}</MetaText>
        <DotSeparator spacing={4} size={2} />
        <MetaText fontSize={10} color='orange' style={{
          cursor: 'pointer',
        }}>Reply</MetaText>
      </Box>
    </Box>
  )
})

export default CommentListItem;

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '0.7rem'
  },
  contentWrapper: {
    display: 'flex',
    gap: 8,
    marginLeft: ({ level }) => level * 48
  },
  rightContentWrapper: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 12,
    backgroundColor: '#1e1e1e',
    flex: 1
  },
  actions: {
    marginLeft: ({ level }) => (level + 1) * 48,
    display: 'flex',
    alignItems: 'center',
  }

}))