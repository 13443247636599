import React from 'react';
import ScreenView from '../../components/ScreenView/ScreenView';
import Settings from '../../components/Settings';
import { makeStyles } from "@material-ui/core";

function SettingsPage() {

  const useStyles = makeStyles((theme) => ({
    container_SettingsPage: {
      background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
      minHeight: '100vh'
    }
  }));

  const styles = useStyles();

  return (
    <div className={styles.container_SettingsPage}>
      <ScreenView
        content={<Settings />}
        headerTitle="Settings"
      />
    </div>
  )
}

export default SettingsPage;
