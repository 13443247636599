import { Box, Button, Divider, Typography, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAppsLoader, setMediasUploadResult } from "../actions/appActions";
import FormActionButtons from "../components/FormActionButtons";
import { isArrayEmpty } from "../utils/Helpers";
import LocalStorageConstants from "../utils/LocalStorageConstants";
import {
  getMediasUploadResultFromLS,
  getMediaViewLink,
  getTriggerActionName,
  handleMediaViewRedirect,
  removeMediasPendingFromLS,
  removeMediasUploadResultFromLS,
  triggerUploadMediasLsBg,
} from "../utils/MediaUpload";
import DialogCNO from "./DialogCNO";
import LinkCNO from "./LinkCNO";

const MediasUploadResultDialog = () => {
  const history = useHistory();

  const theme = useTheme();

  const dispatch = useDispatch();
  const { mediasUploadResult, mediasUploadResultDialogVisible } = useSelector(
    (state) => state.app
  );

  useEffect(() => {
    const onStorageChange = () => {
      const mediasUploadResultLS = getMediasUploadResultFromLS();
      dispatch(setMediasUploadResult(mediasUploadResultLS));
    };

    window.addEventListener("media-upload-result", onStorageChange);

    onStorageChange();

    return () => {
      window.removeEventListener("media-upload-result", onStorageChange);
    };
  }, []);

  useEffect(() => {
    const onStorageChange = () => {
      const mediasUploadResultDialogLS = LocalStorageConstants.getItem(
        "medias-upload-result-dialog",
        false
      );

      dispatch(
        setAppsLoader(
          "mediasUploadResultDialogVisible",
          mediasUploadResultDialogLS
        )
      );
    };

    window.addEventListener("medias-upload-result-dialog", onStorageChange);

    onStorageChange();

    return () => {
      window.removeEventListener(
        "medias-upload-result-dialog",
        onStorageChange
      );
    };
  }, []);

  const handleMediasUploadResultClose = (itemId) => {
    // clear media upload result data on close
    removeMediasUploadResultFromLS(itemId);

    dispatch(setAppsLoader("mediasUploadResultDialogVisible", false));
    localStorage.removeItem("medias-upload-result-dialog");
  };

  const renderList = () => {
    if (!mediasUploadResult) return null;

    const triggerItems = Object.keys(mediasUploadResult);

    if (isArrayEmpty(triggerItems)) return "No data present!";

    return (
      <Box>
        {triggerItems.map((itemIdKey, index) => {
          const item = mediasUploadResult[itemIdKey];

          const { from, to, itemId, success, total } = item;
          const failed = total - success;

          const triggerActionName = getTriggerActionName(from, to);

          const viewLink = getMediaViewLink(to, itemId);

          const handleViewMediasLink = async () => {
            console.log(
              "handleViewMediasLink: history.location.pathname: ",
              history.location.pathname,
              viewLink
            );

            if (history.location.pathname === viewLink) {
              console.log(
                "handleViewMediasLink: handleMediaViewRedirect(to, itemId): ",
                handleMediaViewRedirect(to, itemId)
              );
              await dispatch(handleMediaViewRedirect(to)(itemId));
            }

            handleMediasUploadResultClose(itemId);
          };

          const handleClearMediaItems = () => {
            removeMediasPendingFromLS(itemId, true);
          };

          const handleRetryMediaItemsUpload = () => {
            console.log("handleRetryMediaItemsUpload");

            triggerUploadMediasLsBg(itemId);

            handleMediasUploadResultClose(itemId);
          };

          return (
            <Box key={itemId} style={{ padding: "8px 16px" }}>
              <Typography
                style={{
                  color: theme.colors.LIST_CARD_PARA_COLOR,
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                {triggerActionName}
              </Typography>
              {Boolean(!failed) && (
                <Typography
                  style={{
                    color: theme.colors.GREEN_COLOR,
                    fontSize: "1.05rem",
                    paddingLeft: "1rem",
                  }}
                >
                  {success} of {total} medias uploaded successfully.
                </Typography>
              )}
              {Boolean(failed) && (
                <Typography
                  style={{
                    color: theme.colors.ERROR_COLOR,
                    fontSize: "1.05rem",
                    paddingLeft: "1rem",
                  }}
                >
                  {failed} of {total} medias uploaded failed.
                </Typography>
              )}
              <Box display="flex" justifyContent="flex-end" style={{ gap: 8 }}>
                {Boolean(success) && (
                  <Button onClick={handleViewMediasLink}>
                    <LinkCNO to={viewLink}>View Medias</LinkCNO>
                  </Button>
                )}

                {Boolean(failed) && (
                  <>
                    <Button onClick={handleClearMediaItems}>
                      Clear Failed
                    </Button>
                    <Button onClick={handleRetryMediaItemsUpload}>
                      Retry Failed
                    </Button>
                  </>
                )}
              </Box>
              <Divider style={{ marginTop: "8px" }} />
              {index !== triggerItems.length - 1 ? <Divider /> : null}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <DialogCNO
      open={mediasUploadResultDialogVisible}
      onClose={() => handleMediasUploadResultClose()}
      dialogTitle="Medias Upload Result"
    >
      <Box>{renderList()}</Box>

      <FormActionButtons
        isLeftButton={false}
        rightText="Okay"
        rightClick={() => handleMediasUploadResultClose()}
      />
    </DialogCNO>
  );
};

export default MediasUploadResultDialog;
