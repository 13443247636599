import { makeStyles, Typography } from '@material-ui/core';
import React from 'react'

const DotSeparator = ({ size = 4, color, }) => {

  const styles = useStyles({ size, color });

  return (
    <Typography component={'span'} className={styles.textDotSeprator} />
  )
}

export default DotSeparator;

const useStyles = makeStyles((theme) => ({
  textDotSeprator: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    backgroundColor: ({ color }) => color ? color : theme.colors.META_TEXT_COLOR,
    marginLeft: 6,
    marginRight: 6,
    borderRadius: '50%',
  }
}))