import { toast } from "react-toastify";
import { apiNotificationSearch } from "../api";
import RightsManager from '../utils/RightsManager';
import { NotificationFeatureGroup } from '../utils';
import { getNotificationsArray } from '../utils/NotificationHelpers';

export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_NEW_NOTIFICATIONS = 'SET_NEW_NOTIFICATIONS';
export const SET_NOTIFICATIONS_LOADER = 'SET_NOTIFICATIONS_LOADER';
export const SET_HAS_MORE = "SET_HAS_MORE";

export function setNotifications(payload = []) {
  return async (dispatch) => {
    console.log("set noty payload: ", payload);

    const isUserAdmin = RightsManager.isAdmin();
    const isUserCustomer = RightsManager.isCustomer();
    const isUserMember = RightsManager.isMember();

    const companyNotificationsArray = getNotificationsArray(NotificationFeatureGroup.Company);

    console.log("set noty payload: companyNotificationsArray: ", companyNotificationsArray);

    payload = payload.filter(noti => {

      if (isUserCustomer || isUserMember) {
        if (companyNotificationsArray.includes(noti.notificationType)) return false;
        return true;
      }

      return true;

    });

    dispatch({
      type: SET_NOTIFICATIONS,
      payload: payload
    });

  };
}

export function setNewNotification(notification) {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_NOTIFICATIONS,
      payload: notification
    });
  };
}

export function fetchNotifications(payload) {
  return async (dispatch, getState) => {

    await apiNotificationSearch.post(payload)
      .then((response) => {

        dispatch(setNotifications(response.data.data));

        if (response.data.data.length < 10) {
          dispatch(setHasMore(false));
        }

      })
      .catch((error) => {
        console.log("Notification Fetch Error: ", error);
        toast.success("There is issue in fetching notifications.");

      });
  };
}

export function fetchMoreNotifications(payload) {
  return async (dispatch, getState) => {

    console.log("nnn- request started");

    const { notifications } = getState().notifications;

    await apiNotificationSearch.post(payload)
      .then((response) => {

        if (response.data.data.length < 10) {
          dispatch(setHasMore(false));
        }

        dispatch(setNotifications([...notifications, ...response.data.data]));

      })
      .catch((error) => {
        console.log("Notification Fetch Error: ", error);
        toast.success("There is issue in fetching notifications.");
      });
  };
}

export function setHasMore(status) {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_MORE,
      payload: status
    });
  };
}

export function readNotification(datetime) {
  return async (dispatch, getState) => {

    localStorage.setItem('notificationLastRead', datetime);

    const { notifications } = getState().notifications;
    // dispatch(countNotifications(notifications));

  };
}

export function setNotificationCount(count) {
  return (dispatch) => {
    dispatch({
      type: SET_NOTIFICATION_COUNT,
      payload: count
    });
  };
}