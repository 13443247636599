import { makeStyles, useTheme } from '@material-ui/core'
import { mdiShare } from '@mdi/js'
import Icon from '@mdi/react'
import React from 'react'

const EventShareAction = () => {

  const styles = useStyles();

  return (
    <>
      <div className={styles.iconWrapper}>
        <Icon path={mdiShare} size="1rem" />
      </div>
    </>
  )
}

export default EventShareAction;

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    marginTop: 8,
    background: theme.colors.CHIP_BUTTON_BACKGROUND_COLOR,
    padding: '0.5rem',
    borderRadius: 4,
    border: `1px solid ${theme.colors.CHIP_BUTTON_SHADOW_COLOR}`,
  }
}))