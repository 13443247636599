import { fade, makeStyles, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FormBox from '../../components/FormBox/FormBox';
import { getMaterialIconString } from '../../utils/icons';
import { RateType } from '../../utils';
import * as icons from '@mdi/js';
import { Currency, CurrencyHTMLEntities, CurrencyIcon, QuotationStatus, TaxType } from '../../utils/mappings';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import { parseDisplayNumber, parseNumber } from '../../utils/Helpers';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuotation } from '../../actions/quotationActions';
import EditQuotationDiscountDialog from '../../components/Quotation/EditQuotationDiscountDialog';
import RightsManager from '../../utils/RightsManager';
import { getTotalAmountValues } from '../../utils/InvoiceHelpers';
import EditQuotationTaxDialog from '../../components/Quotation/EditQuotationTaxDialog';

export default function QuotationPaymentSummaryDetailsCard({ quotation }) {

  const theme = useTheme()

  const currencyType = LocalStorageConstants.getItem('currencyType', Currency.GBP);

  const isUserAdmin = RightsManager.isAdmin();

  const styles = useStyles();

  const dispatch = useDispatch();

  const { quotationItems } = useSelector(state => state.quotationItems);

  const quotationItemsAmount = useSelector(state => state.quotationItems.quotationItems
    .reduce((result, quotationItem) => {
      result += parseFloat(quotationItem.amount);

      return result;
    }, 0));

  const [isDiscountEditable, setIsDiscountEditable] = useState(false);
  const [isTaxEditable, setIsTaxEditable] = useState(false);

  useEffect(() => {

    const quotationAmount = quotationItems.length ? quotationItemsAmount : quotation.amount;

    const {
      amount,
      discount,
      discountRate,
      amountAfterDiscount,
      taxableAmount,
      tax,
      taxRate,
      totalAmount
    } = getTotalAmountValues(quotationAmount, quotation.discount, quotation.taxRate, quotation.includeTax);

    const payload = {
      ...quotation,
      amount,
      discount,
      discountRate,
      amountAfterDiscount,
      taxableAmount,
      tax,
      taxRate,
      totalAmount
    };

    if (quotationAmount !== quotation.amount) {
      dispatch(updateQuotation(payload));
    }

  }, [quotationItemsAmount, quotation]);

  return (
    <FormBox title={'Payment Summary'}
      bgColor={theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR}
      titleColor={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR}
    >

      <div className={styles.amountBreakdownWrapper}>

        <div className={styles.row}>
          <Typography className={styles.label}>Amount</Typography>
          <div className={styles.valueWrapper}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography className={styles.value}>{parseDisplayNumber(quotation.amount)}</Typography>
          </div>
        </div>

        <div className={styles.row} onClick={() => setIsDiscountEditable(true)}>
          <Typography className={styles.label}>
            {`Discount @ ${quotation.discountRate}%`}
          </Typography>
          <div className={styles.valueWrapper}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography className={styles.value}>{parseDisplayNumber(quotation.discount)}</Typography>
          </div>

        </div>

        <div className={styles.row}>
          <Typography className={styles.label}>
            {`Amount after discount`}
          </Typography>
          <div className={styles.valueWrapper}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography className={styles.value}>{parseDisplayNumber(quotation.amountAfterDiscount)}</Typography>
          </div>

        </div>

        <div className={styles.row} onClick={() => setIsTaxEditable(true)}>
          <div
            className={styles.label}
            dangerouslySetInnerHTML={{
              __html: `<div>
                ${quotation.includeTax ? 'Inclusive' : 'Exclusive'} VAT @ ${quotation.taxRate}% (<span>${CurrencyHTMLEntities[currencyType]}</span> ${parseDisplayNumber(quotation.taxableAmount, 'float')})
              </div>`
            }}
          />

          <div className={styles.valueWrapper}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography className={styles.value}>{parseDisplayNumber(quotation.tax)}</Typography>
          </div>

        </div>

      </div>


      <div className={styles.totalAmountWraper}>
        <Typography className={styles.label} style={{ fontSize: '1rem' }}>Total Estimated Amount</Typography>
        <div className={styles.amountWrapper}>
          <div className={styles.amount}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography
              className={styles.value}
              style={{
                fontSize: '1rem',
                fontWeight: 'bold',
              }}>
              {parseDisplayNumber(quotation.totalAmount)}
            </Typography>
          </div>
        </div>
      </div>

      {
        isDiscountEditable &&
        isUserAdmin &&
        <EditQuotationDiscountDialog
          open={isDiscountEditable}
          onClose={() => setIsDiscountEditable(false)}
          quotation={quotation}
        />
      }

      {
        isTaxEditable &&
        isUserAdmin &&
        <EditQuotationTaxDialog
          open={isTaxEditable}
          onClose={() => setIsTaxEditable(false)}
          quotation={quotation}
        />
      }

    </FormBox>
  )
}

const useStyles = makeStyles((theme) => ({
  amountBreakdownWrapper: {
    padding: 8
  },

  row: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 8,
  },

  label: {
    marginRight: 16
  },

  valueWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 120,
  },

  value: {
    minWidth: 60,
    textAlign: 'right'
  },

  totalAmountWraper: {
    display: 'flex',
    alignItems: 'center',
    background: theme.colors.WORK_HOURS_TOTAL_ROW_BACKGROUND_COLOR,
    padding: 8,
    justifyContent: 'flex-end',
    borderRadius: 4
  },
  amountWrapper: {
    background: theme.colors.INPUT_DISABLED_BACKGROUND_COLOR,
    width: 120,
    borderRadius: 4,
    padding: '4px 8px',
    display: 'flex',
    justifyContent: 'flex-end',
    color: fade(theme.colors.INPUT_TEXT_COLOR, 0.8),
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }
}))
