import React from 'react'
import { FormFieldsTypes } from '../../utils/enums'
import ChipOptionsArray from './ChipOptionsArray'
import DefaulValueAttribute from './DefaulValueAttribute'
import SelectCNO from '../../../../library/SelectCNO'
import { SwitchMain } from '../../../../library'
import { isArrayEmpty } from '../../../../utils/Helpers'

const ChipFieldAttributes = ({ formik }) => {

  const multiple = formik.values.attributes.multiple;
  const defaultValue = formik.values.attributes.defaultValue;

  const noneValue = multiple ? [] : '';

  const isDefaultVisible = multiple
    ? !isArrayEmpty(defaultValue)
    : Boolean(defaultValue);

  const value = multiple
    ? isArrayEmpty(defaultValue) ? 'none' : defaultValue[0]
    : !defaultValue ? 'none' : defaultValue;

  console.log("ChipFieldAttributes: formik: ", defaultValue, value, formik.values)

  return (
    <>
      {
        formik.values.type === FormFieldsTypes.ids.Chips ? (
          <>
            <ChipOptionsArray formik={formik} />

            <DefaulValueAttribute
              formik={formik}
              initialState={isDefaultVisible}>
              <SelectCNO
                name='attributes.defaultValue'
                label='Select default choice'
                value={value}
                showNoneOption
                noneValue={noneValue}
                options={
                  formik.values.attributes.options.map(option => ({
                    ...option,
                    label: option.title,
                    value: option.id
                  }))
                }
                formik={formik}
                fullWidth
              />
            </DefaulValueAttribute>

            <SwitchMain
              name='attributes.multiple'
              label='Allow multiple chips'
              formik={formik}
              inlineLabel
              onChange={(value) => {

                const valueToSet = value
                  ? defaultValue ? [defaultValue] : []
                  : isArrayEmpty(defaultValue) ? '' : defaultValue[0];

                console.log("ChipFieldAttributes: valueToSet : ", defaultValue[0], value, valueToSet)

                formik.setFieldValue('attributes.defaultValue', valueToSet);

                const validations = {
                  ...formik.values.validations,
                  type: multiple ? 'array' : 'string',
                  options: {
                    value: formik.values.attributes.options.map(option => option.id)
                  }
                }

                formik.setFieldValue('validations', validations);

              }
              }
            />

          </>
        ) : null
      }
    </>
  )
}

export default ChipFieldAttributes