import { Divider, Typography, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import Moment from "react-moment";
import { v4 } from "uuid";
import { calendarStrings, TIME_FORMAT } from "../../utils/formatDate";
import { useStyles } from "./NotificationItemStyles";
import LinkCNO from "../../library/LinkCNO";
import NotificationTypeIconRender from "../NotificationTypeIcon/NotificationTypeIcon";
import {
  getIsNotificationClickable,
  getNotificationClickLink,
  getNotificationText,
} from "../../utils/NotificationHelpers";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import { useHistory } from "react-router-dom";
import { formatTimeWithTZ } from "../../utils/Helpers";

function NotificationItem({ notification, closeNotification }) {
  const notificationLastRead = localStorage.getItem("notificationLastRead");

  console.log("=======notificationText===notification==", notification);

  const notificationText = getNotificationText(
    notification.notificationType,
    notification.text
  );
  const isClickable = getIsNotificationClickable(notification.notificationType);
  const notificationClickLink = getNotificationClickLink(
    notification.notificationType,
    notification.metaData
  );

  console.log("=======notificationText=====", notificationText);

  const styles = useStyles();

  const GlobalStyles = useGlobalStyles();

  const history = useHistory();
  const handleNotificationClick = () => {
    if (isClickable) {
      history.push(notificationClickLink);
    }

    closeNotification();
  };

  return (
    <div
      className={styles.container_NotificationItem}
      onClick={handleNotificationClick}
    >
      <div
        className={clsx(styles.listItem, {
          [styles.readListItem]:
            new Date(notification.creationDate) <=
            new Date(notificationLastRead),
        })}
      >
        <NotificationTypeIconRender type={notification.notificationType} />

        <div className={styles.notificationMessageWrapper}>
          <Typography className={styles.notificationMessage}>
            {notificationText}
          </Typography>
          <Typography className={styles.notificationMessageTime}>
            <Moment interval={0} calendar={calendarStrings}>
              {formatTimeWithTZ(notification.creationDate)}
            </Moment>
            <Moment interval={0} format={TIME_FORMAT}>
              {formatTimeWithTZ(notification.creationDate)}
            </Moment>
          </Typography>
        </div>

        {isClickable ? (
          <div
            className={GlobalStyles.link}
            style={{ textAlign: "right", marginTop: 0 }}
          >
            Open
          </div>
        ) : null}
      </div>
      <Divider />
    </div>
  );
}

export default NotificationItem;
