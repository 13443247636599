import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import { useDispatch } from 'react-redux';
import * as icons from '@mdi/js';
import { getMaterialIconString } from '../../utils/icons';
import { CurrencyIcon, TaxType, TaxTypeReverse } from '../../utils/mappings';
import { getTaxRateBasedOnTaxType, getTaxValueBasedOnTaxType, getTotalAmountValues } from '../../utils/InvoiceHelpers';
import { parseNumber } from '../../utils/Helpers';
import ChipCNO from '../../library/ChipCNO';

function AddInvoiceTax({
  open,
  onClose,
  invoiceFormik,
  paymentSummaryValues,
  setPaymentSummaryValues
}) {

  const formik = useFormik({
    initialValues: {
      includeTax: paymentSummaryValues.includeTax,
      tax: paymentSummaryValues.tax,
      taxRate: paymentSummaryValues.taxRate,
    },
    validationSchema: yup.object({
      includeTax: yup.boolean().oneOf([true, false], 'Tax type is required.'),
      tax: yup.string().test('emptyString', 'Please enter valid value', value => !isNaN(value)),
      taxRate: yup.string().test('emptyString', 'Please enter valid value', value => !isNaN(value))
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("handleUpdate Invoice: ", values);

    const paymentValues = getTotalAmountValues(paymentSummaryValues.amount, paymentSummaryValues.discount, values.taxRate, values.includeTax);

    setPaymentSummaryValues({ ...paymentValues, includeTax: values.includeTax });

    onClose();
  };

  const handleTaxTypeChange = () => {

    const includeTax = !formik.values.includeTax;
    formik.setFieldValue('includeTax', includeTax);

    const { tax } = getTaxValueBasedOnTaxType(includeTax, paymentSummaryValues.amountAfterDiscount, formik.values.taxRate);

    formik.setFieldValue('tax', tax);

  };

  console.log('=============setFieldValue==========values=============', formik.values);
  console.log('=============setFieldValue=============errors==========', formik.errors);

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Invoice Tax"
    >
      <form onSubmit={formik.handleSubmit}>

        <div style={{ display: 'flex', marginBottom: 8 }}>
          <ChipCNO
            active={!formik.values.includeTax}
            icon={icons.mdiClose}
            name={TaxTypeReverse[TaxType.Exclusive]}
            onClick={handleTaxTypeChange}
            style={{ marginRight: 10 }}
          />

          <ChipCNO
            active={formik.values.includeTax}
            icon={icons.mdiCheck}
            name={TaxTypeReverse[TaxType.Inclusive]}
            onClick={handleTaxTypeChange}
          />

        </div>

        <InputCNO
          name="tax"
          label="Tax"
          placeholder="Enter tax"
          icon={icons[getMaterialIconString(CurrencyIcon[invoiceFormik.values.currency])]}
          formik={formik}
          value={!isNaN(formik.values.tax) ? formik.values.tax : ""}
          onChange={(e) => {
            const value = e.target.value;

            const taxValue = !isNaN(value) ? value : formik.values.tax;

            const taxRate = getTaxRateBasedOnTaxType(formik.values.includeTax, paymentSummaryValues.amountAfterDiscount, taxValue);

            formik.setFieldValue('tax', value === "" ? "" : taxValue);
            formik.setFieldValue('taxRate', !isNaN(taxRate) ? taxRate : "");

          }}
        />

        <InputCNO
          name="taxRate"
          label="Tax %"
          placeholder="Enter tax %"
          icon={mdiFormatText}
          formik={formik}
          value={!isNaN(formik.values.taxRate) ? formik.values.taxRate : ""}
          onChange={(e) => {
            const value = e.target.value;

            const taxRateValue = !isNaN(value) ? value : formik.values.taxRate;

            const { tax } = getTaxValueBasedOnTaxType(formik.values.includeTax, paymentSummaryValues.amountAfterDiscount, taxRateValue);

            formik.setFieldValue('tax', !isNaN(tax) ? tax : "");
            formik.setFieldValue('taxRate', value === "" ? "" : taxRateValue);

          }}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default AddInvoiceTax
