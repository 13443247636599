import React from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import ResetCSS from '../../common/assets/css/style';
import { DrawerProvider } from '../../common/contexts/DrawerContext';
import { useThemeStore } from '../../providers/ThemeProvider';
import { ThemeType } from '../../utils/mappings';
import Footer from './Footer';
import { AppWrapper, GlobalStyle } from './LandingPage.style';
import Navbar from './Navbar';
import { LandingPageTheme as LandingPageDarkTheme } from '../../common/theme/LandingPage/LandingPageDarkTheme';
import { LandingPageTheme as LandingPageLightTheme } from '../../common/theme/LandingPage/LandingPageLightTheme';
import Head from 'next/head';
import { usePublicPagesStyles } from '../../utils/Styles/PublicPages.style';

function LandingPageWrapper({ children, pageTitle }) {

  const { themeState } = useThemeStore();
  const { theme } = themeState;

  const activatedTheme = theme === ThemeType.Dark ? LandingPageDarkTheme : LandingPageLightTheme;

  const styles = usePublicPagesStyles();

  const authUser = JSON.parse(localStorage.getItem("authUser")) || {};
  const { token = "" } = authUser;


  return (
    <ThemeProvider theme={activatedTheme}>

      <Head>
        <title>{pageTitle || "meFolia"}</title>
        <meta name="Description" content="meFolia Application" />
        <meta name="theme-color" content="#ec5555" />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,700"
          rel="stylesheet"
        />
      </Head>

      <ResetCSS />
      <GlobalStyle />

      <AppWrapper>
        {
          !token ? (
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar isDrawerVisible={false} />
              </DrawerProvider>
            </Sticky>
          ) : (null)
        }

        <div className={styles.pageWrapper}>
          {children}
        </div>

        <Footer />
      </AppWrapper>
    </ThemeProvider>
  )
}

export default LandingPageWrapper