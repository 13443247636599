import React from "react";

import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import MoreActionCNO from "../../library/MoreActionsCNO";
import { useSelector } from "react-redux";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";

import ServiceCustomerDetailsCard from "../Service/ServiceCustomerDetailsCard";
import ServiceMainDetailsCard from "../Service/ServiceMainDetailsCard";
import ServiceMediaDetailsCard from "../Service/ServiceMediaDetailsCard";
import ServiceAssignedUsersDetailsCard from "../Service/ServiceAssignedUsersDetailsCard";
import ServiceLocationDetailsCard from "../Service/ServiceLocationDetailsCard";
import ServiceStatusButtonBar from "../ServiceListCard/ServiceStatusButtonBar";
import ServiceTimeDetailsCard from "../Service/ServiceTimeDetailsCard";
import ServiceListCardPopover from "../ServiceListCard/ServiceListCardPopover";

import { MediaType } from "../../utils";
import RightsManager from "../../utils/RightsManager";
import BudgetDetailCard from "../Service/BudgetDetailCard";
import ApprovedProviderListing from "../Service/ApprovedProviderListing";

function ServiceDetails({ service }) {
  const isUserAdmin = RightsManager.isAdmin();
  const isUserCustomer = RightsManager.isCustomer();

  const GlobalStyles = useGlobalStyles();

  const { editServiceLoader } = useSelector((state) => state.services);
  console.log("Details Service->", service);

  return (
    <div className={GlobalStyles.detailsContainer}>
      {editServiceLoader && <FullScreenLoaderCNO />}

      <div className={GlobalStyles.statusBar}>
        <ServiceStatusButtonBar service={service} />
        {(isUserAdmin || isUserCustomer) && (
          <MoreActionCNO>
            <ServiceListCardPopover service={service} />
          </MoreActionCNO>
        )}
      </div>

      <ServiceMainDetailsCard service={service} mode={"service"} />

      <BudgetDetailCard service={service} />
      
      <ServiceMediaDetailsCard
        service={service}
        header="Medias"
        mediaType={MediaType.Input}
        userType="employee"
      />

      <ServiceCustomerDetailsCard service={service} mode={"service"} />

      <ApprovedProviderListing service={service}/>

      <ServiceAssignedUsersDetailsCard service={service} mode={"service"} />

      <ServiceLocationDetailsCard service={service} mode={"service"} />

      <ServiceTimeDetailsCard service={service} mode={"service"} />
    </div>
  );
}

export default ServiceDetails;
