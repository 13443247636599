import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addComment, setCommentInputDetails } from '../../../actions/postActions';
import moment from 'moment';
import LocalStorageConstants from '../../../utils/LocalStorageConstants';
import { Formik } from 'formik';
import { InputCNO } from '../../../library';
import * as yup from 'yup';
import { mdiEmoticon, mdiEmoticonOutline, mdiSend } from '@mdi/js';
import EmojiPicker from '../../../utils/ChatUtils/EmojiPicker';

const validationSchema = yup.object({
  comment: yup.string().required("")
});

const initialValues = {
  comment: ""
}

const AddCommentInput = React.forwardRef(({ post }, ref) => {

  const [isEmojiPicker, setIsEmojiPicker] = useState(false);

  const dispatch = useDispatch();
  const { commentInputDetails } = useSelector(state => state.posts);
  const { inputType, parentCommentId } = commentInputDetails || {};

  const resetInputCommentDetails = () => {
    dispatch(setCommentInputDetails({ inputType: 'comment', parentCommentId: null }));
  }

  const handleAddComment = async (values, actions) => {
    console.log("handleAddComment: values:  ", values);

    const authUser = LocalStorageConstants.getItem('authUser');

    const dateNow = moment().toISOString();

    const commentToAdd = {
      // ...values,
      postId: post.id,
      parentCommentId: parentCommentId,
      // user: {
      //   id: authUser.id,
      //   title: `${authUser.firstName} ${authUser.lastName}`,
      //   profilePic: authUser.profilePic
      // },
      userId:authUser.id,
      createdAt: dateNow,
      updatedAt: dateNow,
      text:values.comment
    }

    console.log({ commentToAdd })

    dispatch(addComment(commentToAdd, { onSuccess: resetInputCommentDetails }));

    actions.resetForm();

  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleAddComment}
    >
      {
        function AddCommentForm(formik) {

          return (
            <>
              <InputCNO
                ref={ref}
                name='comment'
                placeholder="What's on your mind"
                formik={formik}
                icon={mdiEmoticonOutline}
                onIconClick={() => setIsEmojiPicker(true)}
                rightIcon={mdiSend}
                onRightIconClick={formik.handleSubmit}
                autoFocus
              />

              {
                isEmojiPicker &&
                <EmojiPicker
                  props={{ text: formik.values.comment }}
                  isEmojiPicker={isEmojiPicker}
                  toggleEmojiPicker={() => setIsEmojiPicker(false)}
                  onChange={(emoji) => formik.setFieldValue('comment', `${formik.values.comment}${emoji}`)}
                />
              }
            </>

          )
        }
      }

    </Formik>
  )
})

export default AddCommentInput;