import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  mdiAccount,
  mdiAccountCog,
  mdiClose,
  mdiCog,
  mdiCrosshairs,
  mdiFaceAgent,
  mdiHome,
  mdiLogout,
  mdiOfficeBuilding,
  mdiPlusCircleOutline,
  mdiWechat,
  mdiHomeCity,
  mdiQuora,
  mdiFileTable,
  mdiClockCheckOutline,
  mdiCalendarClock,
  mdiHelpCircleOutline,
  mdiHelp,
  mdiBriefcase,
  mdiViewDashboardOutline,
  mdiCalendarStar,
  mdiScriptOutline,
  mdiScriptTextOutline,
  mdiMagnify,
  mdiAccountWrench,
  mdiHandCoin,
  mdiTestTube,
  mdiPencil,
} from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppointmentSearchParams } from "../../actions/appointmentActions";
import { logout, setAuthUserProfilePic } from "../../actions/authUserActions";
import { setCustomerSearchParams } from "../../actions/customerActions";
import { setEmployeeSearchParams } from "../../actions/employeeActions";
import {
  resetInvoiceSearch,
  setInvoiceSearchParams,
} from "../../actions/invoiceActions";
import { toggleAddJobDialog } from "../../actions/jobActions";
import {
  resetQuotationSearch,
  setQuotationSearchParams,
} from "../../actions/quotationActions";
import { ApiCore } from "../../api/utils/core";
import AvatarCNO from "../../library/AvatarCNO/AvatarCNO";
import ConfirmationDialogCNO from "../../library/ConfirmationDialogCNO/ConfirmationDialogCNO";
import LinkCNO from "../../library/LinkCNO";
import TooltipCNO from "../../library/TooltipCNO/TooltipCNO";
import { useGlobalStore } from "../../providers/GlobalProvider";
import { TOGGLE_LEFT_SIDEBAR } from "../../utils/actions";
import { getPrefillAssignedUser } from "../../utils/Helpers";
import { ImageSizeType } from "../../utils/mappings";
import RightsManager from "../../utils/RightsManager";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import { DynamicFormsMenu, FormBuilderMenu } from "../../features/formBuilder";
import { SettingsDrawerMenu } from "../../pages/SettingsPage";

function HomePageSidebar() {
  const theme = useTheme();

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const { id, firstName, lastName, profilePic } = authUser;
  const username = `${firstName} ${lastName}`;

  const tillTablet = useMediaQuery("(max-width: 767px)");

  const isUserAdmin = RightsManager.isAdmin();
  const isCompanyAdmin = RightsManager.isGroupLeader();
  const isUserMember = RightsManager.isMember();
  const isUserCustomer = RightsManager.isCustomer();

  const authUserCompany = JSON.parse(localStorage.getItem("authUserCompany"));
  const companyId = authUserCompany.id;

  const { globalState, dispatchGlobal } = useGlobalStore();
  const { isLeftSidebar } = globalState;

  const authUserRedux = useSelector((state) => state.authUser);

  const handleClose = () => {
    dispatchGlobal({
      type: TOGGLE_LEFT_SIDEBAR,
      payload: false,
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    (async function getMediaIIFE() {
      if (!authUser.profilePic) {
        return;
      }

      const dataToSend = {
        ids: [authUser.profilePic],
        size: ImageSizeType.Thumbnail,
      };

      let mediaUrl = "";
      if (isUserAdmin || isUserMember) {
        mediaUrl = "member/getmedialink";
      }

      if (isUserCustomer) {
        mediaUrl = "customer/getmedialink";
      }

      const apiProfileGetMedia = new ApiCore({
        post: true,
        url: mediaUrl,
      });

      await apiProfileGetMedia
        .post(dataToSend)
        .then((response) => {
          if (response.status === 200) {
            const links = response.data.links;
            dispatch(setAuthUserProfilePic(links[0].link));
          } else {
            console.log("Get Media Error: ");
          }
        })
        .catch((error) => {
          console.log("Get Media Error: ", error);
        });
    })();
  }, [authUser.profilePic]);

  const handleAddJobClick = () => {
    dispatch(toggleAddJobDialog(true));
    closeSidebarInTablet();
  };

  const [openConfirmLogOutDialog, setOpenConfirmLogOutDialog] = useState(false);
  const handleLogOut = () => {
    dispatch(logout());
    handleClose();
  };

  const closeSidebarInTablet = () => {
    if (tillTablet) {
      handleClose();
    }
  };

  const styles = useStyles({ tillTablet });
  const GlobalStyles = useGlobalStyles();

  return (
    <div className={styles.container}>
      {tillTablet && isLeftSidebar && (
        <Icon
          path={mdiClose}
          size="1.5rem"
          className={styles.closeIcon}
          onClick={handleClose}
        />
      )}

      <ConfirmationDialogCNO
        open={openConfirmLogOutDialog}
        onClose={() => setOpenConfirmLogOutDialog(false)}
        dialogTitle="Logout"
        rightText="Logout"
        leftText="Cancel"
        yesClick={handleLogOut}
        messages={["Are you sure you want to Logout?"]}
      />

      <List className={styles.profile}>
        <LinkCNO to={`/profile/${id}`}>
          <ListItem onClick={closeSidebarInTablet}>
            <ListItemIcon>
              {authUserRedux.authUserProfilePic ? (
                <AvatarCNO
                  src={authUserRedux.authUserProfilePic}
                  fallbackIcon={mdiAccount}
                  iconSize="2rem"
                  avatarStyle={{
                    border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}`,
                  }}
                />
              ) : (
                <div className={styles.avatar}>
                  {firstName.slice(0, 1).toUpperCase()}
                </div>
              )}
            </ListItemIcon>
            <ListItemText color={theme.colors.DRAWER_ITEM_ICON_COLOR}>
              <Typography className={styles.username}>{username}</Typography>
              <Typography className={styles.linkText}>View Profile</Typography>
            </ListItemText>
          </ListItem>
        </LinkCNO>
      </List>

      <List>
        <LinkCNO to="/home">
          <TooltipCNO title="Posts" arrow placement="right">
            <ListItem
              button
              className={styles.listItem}
              onClick={closeSidebarInTablet}
            >
              <ListItemIcon>
                <Icon
                  path={mdiScriptTextOutline}
                  size="1.5rem"
                  color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                />
              </ListItemIcon>
              <ListItemText primary="Posts" />
            </ListItem>
          </TooltipCNO>
        </LinkCNO>

        {
          <LinkCNO to="/events">
            <TooltipCNO title="Events" arrow placement="right">
              <ListItem
                ListItem
                button
                className={styles.listItem}
                onClick={closeSidebarInTablet}
              >
                <ListItemIcon>
                  <Icon
                    path={mdiCalendarStar}
                    size="1.5rem"
                    color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                  />
                </ListItemIcon>
                <ListItemText primary="Events" />
              </ListItem>
            </TooltipCNO>
          </LinkCNO>
        }

        <div className={GlobalStyles.drawerItemSeparator} />

        <LinkCNO to="/jobs">
          <TooltipCNO title="Job" arrow placement="right">
            <ListItem
              button
              className={styles.listItem}
              onClick={closeSidebarInTablet}
            >
              <ListItemIcon>
                <Icon
                  path={mdiBriefcase}
                  size="1.5rem"
                  color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                />
              </ListItemIcon>
              <ListItemText primary="Job" />
            </ListItem>
          </TooltipCNO>
        </LinkCNO>
        <LinkCNO to="/services">
          <TooltipCNO title="Looking For" arrow placement="right">
            <ListItem
              button
              className={styles.listItem}
              onClick={closeSidebarInTablet}
            >
              <ListItemIcon>
                <Icon
                  path={mdiAccountWrench}
                  size="1.5rem"
                  color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                />
              </ListItemIcon>
              <ListItemText primary="Looking For" />
            </ListItem>
          </TooltipCNO>
        </LinkCNO>

        <LinkCNO to="/service-providers">
          <TooltipCNO title="Service Providers" arrow placement="right">
            <ListItem
              button
              className={styles.listItem}
              onClick={closeSidebarInTablet}
            >
              <ListItemIcon>
                <Icon
                  path={mdiHandCoin}
                  size="1.5rem"
                  color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                />
              </ListItemIcon>
              <ListItemText primary="Service Providers" />
            </ListItem>
          </TooltipCNO>
        </LinkCNO>
        
        {isUserAdmin && (
          <LinkCNO to="/dashboard">
            <TooltipCNO title="Dashboard" arrow placement="right">
              <ListItem
                button
                className={styles.listItem}
                onClick={closeSidebarInTablet}
              >
                <ListItemIcon>
                  <Icon
                    path={mdiViewDashboardOutline}
                    size="1.5rem"
                    color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                  />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            </TooltipCNO>
          </LinkCNO>
        )}

        {/* {
          isUserAdmin &&
          <TooltipCNO title="Add Job" arrow placement="right">
            <ListItem ListItem button className={styles.listItem} onClick={handleAddJobClick}>
              <ListItemIcon>
                <Icon path={mdiPlusCircleOutline} size='1.5rem' color={theme.colors.DRAWER_ITEM_ICON_COLOR} />
              </ListItemIcon>
              <ListItemText primary='Add Job' />
            </ListItem>
          </TooltipCNO>
        } */}
        {isUserAdmin && (
          <LinkCNO to="/map">
            <TooltipCNO title="Track" arrow placement="right">
              <ListItem
                button
                className={styles.listItem}
                onClick={closeSidebarInTablet}
              >
                <ListItemIcon>
                  <Icon
                    path={mdiCrosshairs}
                    size="1.5rem"
                    color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                  />
                </ListItemIcon>
                <ListItemText primary="Track" />
              </ListItem>
            </TooltipCNO>
          </LinkCNO>
        )}

        <DynamicFormsMenu />

        <div className={GlobalStyles.drawerItemSeparator} />

        {
          <LinkCNO to="/calendar">
            <TooltipCNO title="Calendar" arrow placement="right">
              <ListItem
                ListItem
                button
                className={styles.listItem}
                onClick={closeSidebarInTablet}
              >
                <ListItemIcon>
                  <Icon
                    path={mdiCalendarClock}
                    size="1.5rem"
                    color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                  />
                </ListItemIcon>
                <ListItemText primary="Calendar" />
              </ListItem>
            </TooltipCNO>
          </LinkCNO>
        }

        <div className={GlobalStyles.drawerItemSeparator} />

        {(isCompanyAdmin || isUserCustomer) && (
          <LinkCNO to={"/invoices"}>
            <TooltipCNO title="Invoice" arrow placement="right">
              <ListItem
                button
                className={styles.listItem}
                onClick={() => {
                  closeSidebarInTablet();
                  // dispatch(resetInvoiceSearch(true));
                }}
              >
                <ListItemIcon>
                  <Icon
                    path={mdiFileTable}
                    size="1.5rem"
                    color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                  />
                </ListItemIcon>
                <ListItemText primary="Invoice" />
              </ListItem>
            </TooltipCNO>
          </LinkCNO>
        )}

        {(isCompanyAdmin || isUserCustomer) && (
          <LinkCNO to={"/quotations"}>
            <TooltipCNO title="Quotation" arrow placement="right">
              <ListItem
                button
                className={styles.listItem}
                onClick={() => {
                  closeSidebarInTablet();
                  // dispatch(resetQuotationSearch(true));
                }}
              >
                <ListItemIcon>
                  <Icon
                    path={mdiQuora}
                    size="1.5rem"
                    color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                  />
                </ListItemIcon>
                <ListItemText primary="Quotation" />
              </ListItem>
            </TooltipCNO>
          </LinkCNO>
        )}

        <LinkCNO to={"/appointments"}>
          <TooltipCNO title="Appointment" arrow placement="right">
            <ListItem
              button
              className={styles.listItem}
              onClick={() => {
                closeSidebarInTablet();
                dispatch(
                  setAppointmentSearchParams({
                    customer: null,
                    categories: [],
                    appointment: null,
                    startDate: null,
                    endDate: null,
                    assignments: isUserMember ? getPrefillAssignedUser() : null,
                  })
                );
              }}
            >
              <ListItemIcon>
                <Icon
                  path={mdiClockCheckOutline}
                  size="1.5rem"
                  color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                />
              </ListItemIcon>
              <ListItemText primary="Appointment" />
            </ListItem>
          </TooltipCNO>
        </LinkCNO>

        <div className={GlobalStyles.drawerItemSeparator} />

        {
          <LinkCNO to="/test">
            <TooltipCNO title="Online Test" arrow placement="right">
              <ListItem
                ListItem
                button
                className={styles.listItem}
                onClick={closeSidebarInTablet}
              >
                <ListItemIcon>
                  <Icon
                    path={mdiPencil}
                    size="1.5rem"
                    color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                  />
                </ListItemIcon>
                <ListItemText primary="Online Test" />
              </ListItem>
            </TooltipCNO>
          </LinkCNO>
        }

        <div className={GlobalStyles.drawerItemSeparator} />

        {isUserAdmin && (
          <LinkCNO to={"/employees"}>
            <TooltipCNO title="Employee" arrow placement="right">
              <ListItem
                button
                className={styles.listItem}
                onClick={() => {
                  closeSidebarInTablet();
                  dispatch(
                    setEmployeeSearchParams({
                      employee: null,
                      email: "",
                      phone: "",
                      address: "",
                    })
                  );
                }}
              >
                <ListItemIcon>
                  <Icon
                    path={mdiAccountCog}
                    size="1.5rem"
                    color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                  />
                </ListItemIcon>
                <ListItemText primary="Employee" />
              </ListItem>
            </TooltipCNO>
          </LinkCNO>
        )}
        {isUserAdmin && (
          <LinkCNO to={"/customers"}>
            <TooltipCNO title="Customer" arrow placement="right">
              <ListItem
                button
                className={styles.listItem}
                onClick={() => {
                  closeSidebarInTablet();
                  dispatch(
                    setCustomerSearchParams({
                      customer: null,
                      email: "",
                      phone: "",
                      address: "",
                    })
                  );
                }}
              >
                <ListItemIcon>
                  <Icon
                    path={mdiFaceAgent}
                    size="1.5rem"
                    color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                  />
                </ListItemIcon>
                <ListItemText primary="Customer" />
              </ListItem>
            </TooltipCNO>
          </LinkCNO>
        )}

        <div className={GlobalStyles.drawerItemSeparator} />

        <LinkCNO to={`/profile/${id}`}>
          <TooltipCNO title="My Profile" arrow placement="right">
            <ListItem
              button
              className={styles.listItem}
              onClick={closeSidebarInTablet}
            >
              <ListItemIcon>
                <Icon
                  path={mdiAccount}
                  size="1.5rem"
                  color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItem>
          </TooltipCNO>
        </LinkCNO>

        {isCompanyAdmin && (
          <LinkCNO to={`/mycompany/${companyId}`}>
            <TooltipCNO title="My Company" arrow placement="right">
              <ListItem
                ListItem
                button
                className={styles.listItem}
                onClick={closeSidebarInTablet}
              >
                <ListItemIcon>
                  <Icon
                    path={mdiHomeCity}
                    size="1.5rem"
                    color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                  />
                </ListItemIcon>
                <ListItemText primary="My Company" />
              </ListItem>
            </TooltipCNO>
          </LinkCNO>
        )}

        {isUserAdmin && false && (
          <LinkCNO to={"/companies"}>
            <TooltipCNO title="Manage Company" arrow placement="right">
              <ListItem
                button
                className={styles.listItem}
                onClick={closeSidebarInTablet}
              >
                <ListItemIcon>
                  <Icon
                    path={mdiOfficeBuilding}
                    size="1.5rem"
                    color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                  />
                </ListItemIcon>
                <ListItemText primary="Manage Company" />
              </ListItem>
            </TooltipCNO>
          </LinkCNO>
        )}

        <div className={GlobalStyles.drawerItemSeparator} />

        <LinkCNO to={"/chat"}>
          <TooltipCNO title="iConnect" arrow placement="right">
            <ListItem
              button
              className={styles.listItem}
              onClick={closeSidebarInTablet}
            >
              <ListItemIcon>
                <Icon
                  path={mdiWechat}
                  size="1.5rem"
                  color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                />
              </ListItemIcon>
              <ListItemText primary="iConnect" />
            </ListItem>
          </TooltipCNO>
        </LinkCNO>

        <div className={GlobalStyles.drawerItemSeparator} />

        {/* <LinkCNO to={'/settings'}>
          <TooltipCNO title="Settings" arrow placement="right">
            <ListItem button className={styles.listItem} onClick={closeSidebarInTablet}>
              <ListItemIcon>
                <Icon path={mdiCog} size='1.5rem' color={theme.colors.DRAWER_ITEM_ICON_COLOR} />
              </ListItemIcon>
              <ListItemText primary='Settings' />
            </ListItem>
          </TooltipCNO>
        </LinkCNO> */}

        <SettingsDrawerMenu />

        <div className={GlobalStyles.drawerItemSeparator} />

        <LinkCNO to={"/help"}>
          <TooltipCNO title="Help" arrow placement="right">
            <ListItem
              button
              className={styles.listItem}
              onClick={closeSidebarInTablet}
            >
              <ListItemIcon>
                <Icon
                  path={mdiHelpCircleOutline}
                  size="1.5rem"
                  color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                />
              </ListItemIcon>
              <ListItemText primary="Help" />
            </ListItem>
          </TooltipCNO>
        </LinkCNO>

        <TooltipCNO title="Log Out" arrow placement="right">
          <ListItem
            button
            className={styles.listItem}
            onClick={() => setOpenConfirmLogOutDialog(true)}
          >
            <ListItemIcon>
              <Icon
                path={mdiLogout}
                size="1.5rem"
                color={theme.colors.DRAWER_ITEM_ICON_COLOR}
              />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItem>
        </TooltipCNO>
      </List>
    </div>
  );
}

export default HomePageSidebar;

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    top: 0,
    paddingBottom: 60,
    borderRight: `1px solid ${theme.colors.BORDER_COLOR}`,
  },
  profile: {
    height: 75,
    display: "flex",
    alignItems: "center",
    borderBottom: `2px solid ${theme.colors.DRAWER_PROFILE_HEADER_LINE_COLOR}`,
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: "50%",
    background: theme.colors.DRAWER_PROFILE_PIC_BACKGROUND_COLOR,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.colors.DRAWER_PROFILE_PIC_TEXT_COLOR,
    fontSize: "1.2rem",
    marginRight: theme.spacing(2),
  },
  listItem: {
    borderRadius: 5,
    color: theme.colors.DRAWER_ITEM_TEXT_COLOR,
    height: 50,
  },
  username: {},
  linkText: {
    fontSize: "0.7rem",
    color: theme.colors.LINK_COLOR_GREY,
    textDecoration: "underline",
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    // transform: 'translateX(640%)',
    color: theme.colors.ERROR_COLOR,
  },
  tooltip: {
    fontSize: "0.7rem",
  },
}));
