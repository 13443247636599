import { makeStyles } from '@material-ui/core/styles';
import { alignItems, backgroundColor, border, borderRadius, color, display, flexDirection, fontSize, fontWeight, height, justifyContent, marginBottom, marginLeft, marginRight, marginTop, padding, position, textAlign, width } from 'styled-system';

export const useTestStyles = makeStyles((theme) => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: theme.spacing(0.5),
    marginTop :"16px",
  },
  listContainer: {
    display: 'block',
    marginTop :"16px",
  },
  testTile: {
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    borderRadius: 10,
    marginBottom : "0.7rem",
    padding :"16px 8px",
    marginLeft :"0.5rem",
    marginRight :"0.5rem",
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
   // color: theme.palette.getContrastText(theme.palette.background.main),
    color: theme.colors.LIST_CARD_TITLE_COLOR,
    fontSize : "14px",
  },
  uname: {
    color: theme.colors.LIST_CARD_DATE_COLOR,
    fontSize: '0.8rem'
  },
  headerTile: {
    marginTop: theme.spacing(1.5),
    background: theme.colors.LIST_CARD_PARA_BACKGROUND_COLOR,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderRadius: 5,
    color: theme.colors.LIST_CARD_PARA_COLOR,
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
  },
  btn: {
    marginTop: theme.spacing(1),
    //marginTop : "0.8rem",
    padding : "0.5rem 1rem",
    textTransform : "uppercase",
    fontWeight : "500",
    color : "white",
    backgroundColor : "#3F8A02",
    borderRadius : "4px",
    border: "2px solid #fcb103",
    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
    cursor : "pointer",
    '&:hover':{
        backgroundColor : "#E0E0E0",
    },
    
  },
  Container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.getContrastText(theme.palette.background.main),
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    height : "100vh"
  },
  CountdownHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    //boxShadow: `${theme.colors.STATS_CARD_SHADOW_COLOR} 0px 7px 29px 0px`,
    //border: `1px solid ${theme.colors.STATS_CARD_BORDER_COLOR}`,
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    borderRadius: 10,
    background: theme.colors.STATS_CARD_BACKGROUND_COLOR,
    padding: "0.5rem 1.7rem",
  },
  Title: {
    fontSize: '1.2rem',
    fontWeight: 400,
    color : "red",
  },
  Instructions: {
    marginTop: theme.spacing(2),
    textAlign: 'left',
    '& h3': {
      marginBottom: theme.spacing(1),
      color: theme.colors.LIST_CARD_TITLE_COLOR,
    },
    '& ul': {
      listStyleType: 'disc',
      paddingLeft: theme.spacing(4),
    },
    '& li': {
      marginBottom: theme.spacing(1),
      fontSize:"14px"
    },
   '& h4' : {
      color: theme.colors.LIST_CARD_TITLE_COLOR,
     },
  },


  resultbox :{
    boxShadow: `${theme.colors.STATS_CARD_SHADOW_COLOR} 0px 7px 29px 0px`,
   // border: `1px solid ${theme.colors.STATS_CARD_BORDER_COLOR}`,
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
    padding: '14px 8px',
    marginTop: theme.spacing(2),
    marginLeft :"1rem",
    marginRight :"1rem",
    color: theme.palette.getContrastText(theme.palette.background.main),
    display : "flex",
    flexDirection : "column",
    justifyContent : "center",
    alignItems : "center"
  },
  
  breaker:{
    border: `1px solid ${theme.colors.STATS_CARD_BORDER_COLOR}`,
    margin:"5px 5px",
    width : "98%"
},
resultheader :{
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  color: theme.colors.LIST_CARD_TITLE_COLOR,
},
  

}));

