import moment from "moment";
import { DateTimeFormat } from "./mappings";

export const TIME_FORMAT = 'hh:mm a';

export const DATE_FORMAT = 'MMM DD, YYYY';

export const calendarStrings = {
  lastDay: '[Yesterday at ]',
  sameDay: '[Today at ]',
  nextDay: '[Tomorrow at ]',
  lastWeek: '[Last] dddd [at ]',
  nextWeek: '[Coming] dddd [at ]',
  sameElse: 'll [at ]'
};

export const calendarStringsForChat = {
  lastDay: '[Yesterday]',
  sameDay: 'hh:mm a',
  nextDay: 'MMM DD, YYYY',
  lastWeek: 'MMM DD, YYYY',
  nextWeek: 'MMM DD, YYYY',
  sameElse: 'MMM DD, YYYY'
};

export const formatDateTime = (dateTime, formatType = DateTimeFormat.ids.dateTime) => {

  const formatterString = DateTimeFormat.getItem(formatType).title || DateTimeFormat.getItem(DateTimeFormat.ids.dateTime).title;

  if (!dateTime) return dateTime;

  const isCalendarString = formatType === DateTimeFormat.ids.calendarString || formatType === DateTimeFormat.ids.calendarStringWithTime;

  const formattedDateTime = isCalendarString
    ? moment(dateTime).calendar(formatterString)
    : moment(dateTime).format(formatterString);

  return formattedDateTime;

}

export const formatDateTimeFromNow = (date) => {

  if (!date) return '';

  const now = moment();
  const duration = moment.duration(now.diff(date));

  if (duration.asMilliseconds() <= moment.duration(1, 'minute').asMilliseconds()) {
    return 'Just now';
  } else if (duration.asMilliseconds() <= moment.duration(1, 'hour').asMilliseconds()) {
    const minutes = Math.floor(duration.as('minutes'));
    return minutes + ' ' + (minutes === 1 ? 'minute' : 'minutes') + ' ago';
  } else if (duration.asMilliseconds() <= moment.duration(24, 'hours').asMilliseconds()) {
    const hours = Math.floor(duration.as('hours'));
    return hours + ' ' + (hours === 1 ? 'hour' : 'hours') + ' ago';
  } else if (duration.asMilliseconds() <= moment.duration(7, 'days').asMilliseconds()) {
    const days = Math.floor(duration.as('days'));
    return days + ' ' + (days === 1 ? 'day' : 'days') + ' ago';
  } else {
    return formatDateTime(date);
  }

}

export const formatDateTimeFromNowInShort = (date) => {

  if (!date) return '';

  // Calculate the difference between the given date and the current date
  const now = moment();
  const then = moment(date);
  const duration = moment.duration(now.diff(then));

  // Format the duration
  const years = duration.years();
  const months = duration.months();
  const weeks = duration.weeks();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let result = '';

  if (years > 0) {
    result = years + 'y';
  } else if (months > 0) {
    result = months + 'm';
  } else if (weeks > 0) {
    result = weeks + 'w';
  } else if (days > 0) {
    result = days + 'd';
  } else if (hours > 0) {
    result = hours + 'h';
  } else if (minutes > 0) {
    result = minutes + 'min';
  } else if (seconds > 0) {
    result = seconds + 's';
  } else {
    result = 'Just now';
  }

  return result;

}