import React, { useRef, useState } from 'react';
import { useStyles } from './WebCameraStyles';
import Webcam from "react-webcam";
import Icon from '@mdi/react';
import { mdiClose, mdiDelete, mdiSend } from '@mdi/js';
import { Button } from '@material-ui/core';

function WebCamera({ toggleWebCam, processAndUploadMedia }) {

    const webCamRef = useRef(null);

    const [images, setImages] = useState([]);

    const handleTakePicture = () => {
        const image = webCamRef.current.getScreenshot();
        setImages([image, ...images]);
        // console.log("Web image: ", image);
    };

    const handleRemovePreview = (index) => {
        console.log("Remove Image index: ", index);
        const tempImages = [...images];
        tempImages.splice(index, 1);
        setImages(tempImages);
    };

    const handleSendPreview = (index) => {
        console.log("Send Image index: ", index);
        processAndUploadMedia(images[index], 3);
        handleRemovePreview(index);
    };

    const styles = useStyles();
    return (
        <div className={styles.backdrop_WebCamera}>
            <div className={styles.container_WebCamera}>
                <div className={styles.webCamWrapper}>
                    <Webcam
                        ref={webCamRef}
                    />

                    <div className={styles.webCamActions}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleTakePicture}
                            className={styles.button}
                        >
                            Take Picture
                        </Button>
                        {/* {
                            images.length &&
                            <Button
                                variant="contained"
                                color="secondary"
                                className={styles.button}
                            // onClick={handleTakePicture}
                            >
                                Send Pictures
                            </Button>
                        } */}
                    </div>
                </div>
                <div className={styles.previewWrapper}>
                    {
                        images.map((image, index) => (
                            <div>
                                <img
                                    src={image}
                                    alt=""
                                    className={styles.previewImage}
                                />
                                <div className={styles.imageActions}>
                                    <Icon
                                        path={mdiDelete}
                                        size="2rem"
                                        color="#e5b9b7"
                                        className={styles.previewImageBtn}
                                        onClick={() => handleRemovePreview(index)}
                                    />
                                    <Icon
                                        path={mdiSend}
                                        size="2rem"
                                        color="#00ced1"
                                        className={styles.previewImageBtn}
                                        onClick={() => handleSendPreview(index)}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
                <Icon
                    path={mdiClose}
                    size="2rem"
                    color="#BE0A02"
                    className={styles.closeIcon}
                    onClick={() => toggleWebCam(false)}
                />
            </div>
        </div>
    )
}

export default WebCamera
