import React, { Fragment, useState } from 'react';
import { Badge, CircularProgress, makeStyles, useTheme } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiAccount, mdiAccountGroup, mdiCamera, mdiImagePlus } from '@mdi/js';
import Upload from 'rc-upload';
import AvatarCNO from '../../library/AvatarCNO/AvatarCNO';
import { toast } from 'react-toastify';
import { ApiCore } from '../../api/utils/core';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { setActiveChatUser } from '../../actions/chatActions';

function ChatProfileDetailsAvatar({ activeChatUser }) {

  const theme = useTheme();
  const styles = useStyles();

  const dispatch = useDispatch();

  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  // converts base64 image to blob
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const processAndUploadImage = async (base64file) => {
    setLoading(true);
    const imageId = v4();
    const imageURL = base64file;

    // Split the base64 string in data and contentType
    const block = imageURL.split(";");
    // Get the content type of the image
    const contentType = block[0].split(":")[1];
    // get the real base64 content of the file
    const realData = block[1].split(",")[1];

    const blob = b64toBlob(realData, contentType);

    const url = `${activeChatUser.contactType === 'group' ? 'messagegroup' : 'member'}/initupload`;
    const apiGroupInitUpload = new ApiCore({
      post: true,
      url: url
    });

    const initRes = await apiGroupInitUpload.post({ "ids": [imageId] });

    // UPLOAD
    const mRes = await fetch(initRes.data.links[0].m, { method: 'PUT', body: blob });
    const tRes = await fetch(initRes.data.links[0].t, { method: 'PUT', body: blob });
    const bRes = await fetch(initRes.data.links[0].b, { method: 'PUT', body: blob });

    //further upload processing will come here
    // formik.setFieldValue("chatGroupAvatar", imageId);

    dispatch(setActiveChatUser({
      ...activeChatUser,
      contactAvatar: imageURL,
      profilePic: imageURL
    }));

    setImage(imageURL);
    setLoading(false);

  };

  const processMediaUpload = (file) => {

    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      // console.log("File Reader image Result: ", reader.result);
      processAndUploadImage(reader.result);
    };

    reader.onerror = function (error) {
      toast.error("Error in uploading profile");
      console.log('Error: ', error);
    };
  };

  const uploadProps = {
    beforeUpload: (file) => {
      if (file.type.includes('image')) {
        return true;
      }
      toast.error("Unsupported file");
      return false;
    },
    action: (file) => {
      console.log("Upload Action file: ", file);
      processMediaUpload(file);
    },
    multiple: false,
  };

  return (
    <div>
      <Badge
        className={styles.avatarBadge}
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={(
          <div className={styles.pickers}>
            <Icon
              path={mdiCamera}
              size="1.5rem"
              color={theme.colors.IMAGE_CAMERA_ICON_COLOR}
              style={{ visibility: 'hidden' }}
            />
            <Upload {...uploadProps} accept="image/*">
              <Icon
                path={mdiImagePlus}
                size="1.5rem"
                color={theme.colors.IMAGE_UPLOAD_ICON_COLOR}
              />
            </Upload>
          </div>
        )}
      >
        {
          loading
            ? <CircularProgress style={{
              // border: '2px solid #c2abf7',
              padding: '20px',
              borderRadius: '50%',
              width: '5rem',
              height: '5rem'
            }} />
            : <Fragment>
              {
                activeChatUser.contactType === 'user' &&
                <AvatarCNO
                  src={activeChatUser.contactAvatar}
                  avatarStyle={{ border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}` }}
                  fallbackIcon={mdiAccount}
                  iconSize="8rem"
                  openAvatarInImageViewer={true}
                />
              }
              {
                activeChatUser.contactType === 'group' &&
                <AvatarCNO
                  src={activeChatUser.contactAvatar}
                  fallbackIcon={mdiAccountGroup}
                  avatarStyle={{ border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}` }}
                  iconSize="8rem"
                  openAvatarInImageViewer={true}
                />
              }
            </Fragment>
        }
      </Badge>
    </div>
  )
}

export default ChatProfileDetailsAvatar

const useStyles = makeStyles((theme) => ({
  avatarBadge: {
    marginBottom: theme.spacing(2),
    borderRadius: '50%',
    '& .MuiBadge-anchorOriginBottomRightCircle': {
      right: 'calc(50%)',
      bottom: '10%',
      transform: 'scale(1) translate(50%, 50%)',
      transformOrigin: '100% 100%',
      width: 130,
    }
  },
  pickers: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  }
}));
