import { Button } from '@material-ui/core';
import React from 'react';

function ButtonCNO({children}) {
    return (
        <Button>
            {children}
        </Button>
    )
}

export default ButtonCNO
