import { Avatar, Divider, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from '@material-ui/core';
import { List } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react'
import { useStyles } from './ChatGroupListItemStyles';
import AppLogo from '../../assets/appLogo.png';
import AvatarCNO from '../../library/AvatarCNO/AvatarCNO';
import { mdiAccount } from '@mdi/js';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';

function ChatGroupListItem({ user, index, handleUserSelection, selectedUsers }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  console.log("user in create: ", user);

  const styles = useStyles();
  return (
    <div
      className={styles.container_ChatGroupListItem}
      onClick={() => {
        handleUserSelection(user, index);
      }}
    >
      <div
        className={clsx(styles.selectedIndicator, {
          [styles.activeSelectemItem]: selectedUsers.map(user => user.id).includes(user.id)
        })}
      >
      </div>
      <ListItem alignItems="flex-start" className={GlobalStyles.listItem} style={{ width: 'auto' }}>
        <ListItemAvatar>
          <AvatarCNO
            src={user.profilePic}
            fallbackIcon={mdiAccount}
            avatarStyle={{ border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}` }}

          />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography className={styles.username}>{user.firstName} {user.lastName}</Typography>}
          secondary={
            <React.Fragment>
              {user.email} | {user.mobile}
            </React.Fragment>
          }
        />
      </ListItem>
      {/* <Divider /> */}
    </div>
  )
}

export default ChatGroupListItem
