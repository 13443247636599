import { Typography, useTheme } from "@material-ui/core";
import {
  mdiAccount,
  mdiAccountOff,
  mdiBarcode,
  mdiClose,
  mdiEmail,
  mdiPhone,
} from "@mdi/js";
import Icon from "@mdi/react";
import clsx from "clsx";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { resetSuggestions } from "../../actions/suggestionsActions";
import AvatarCNO from "../../library/AvatarCNO/AvatarCNO";
import LinkCNO from "../../library/LinkCNO";
import MoreActionCNO from "../../library/MoreActionsCNO/MoreActionCNO";
import { ProfileMode } from "../../utils";
import ProfileListCardPopover from "../ProfileListCardPopover/ProfileListCardPopover";
import { useStyles } from "./ProfileListCardStyles";

function ProfileListCard({ mode, profile }) {
  const theme = useTheme();
  const styles = useStyles();

  const isDeactivatedProfile =
    mode === (ProfileMode.Employee || ProfileMode.ServiceProvider) &&
    !profile.isActive;

  const dispatch = useDispatch();
  // console.log("ProfileListCardProfile",profile);
  return (
    <div
      className={clsx(styles.container_ProfileListCard, {
        // [styles.deactivate]: isDeactivatedProfile
      })}
      onClick={() => dispatch(resetSuggestions())}
    >
      <div className={styles.jobMeta}>
        <div className={styles.avatarWrapper}>
          <AvatarCNO
            src={profile.profilePic}
            fallbackIcon={mdiAccount}
            iconSize="3rem"
            avatarStyle={{
              border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}`,
            }}
          />
          {isDeactivatedProfile && (
            <Typography component="span" className={styles.avatarStatusText}>
              Not Active
            </Typography>
          )}
        </div>
        <div className={styles.meta}>
          <LinkCNO
            to={`/${
              mode === ProfileMode.Employee
                ? "employee"
                : mode === ProfileMode.ServiceProvider
                ? "service-provider"
                : "customer"
            }/${profile.id}`}
          >
            <Typography className={styles.title}>
              {profile.firstName}&nbsp;{profile.lastName}
            </Typography>
          </LinkCNO>

          {profile.email && (
            <div className={styles.cardInfoRow}>
              <Icon
                path={mdiEmail}
                size={"1rem"}
                color={theme.colors.LIST_CARD_ICON_COLOR}
              />
              <Typography className={styles.cardInfoRowText}>
                {profile.email}
              </Typography>
            </div>
          )}

          {profile.code && (
            <div className={styles.cardInfoRow}>
              <Icon
                path={mdiBarcode}
                size={"1rem"}
                color={theme.colors.LIST_CARD_ICON_COLOR}
              />
              <Typography className={styles.cardInfoRowText}>
                {profile.code}
              </Typography>
            </div>
          )}

          {profile.mobile && (
            <div className={styles.cardInfoRow}>
              <Icon
                path={mdiPhone}
                size={"1rem"}
                color={theme.colors.LIST_CARD_ICON_COLOR}
              />
              <Typography className={styles.cardInfoRowText}>
                {profile.mobile}
              </Typography>
            </div>
          )}
        </div>

        <MoreActionCNO>
          <ProfileListCardPopover
            profile={profile}
            isEmployee={mode === ProfileMode.Employee}
            isServiceProvider={mode === ProfileMode.ServiceProvider}
          />
        </MoreActionCNO>
      </div>
    </div>
  );
}

export default ProfileListCard;
