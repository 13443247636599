import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container_CompanyPersonalDetailsCard: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.colors.PROFILE_CARD_BACKGROUND_COLOR,
    padding: ({ tillTablet }) => tillTablet ? 12 : theme.spacing(4),
    borderRadius: 20
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.colors.PROFILE_CARD_TITLE_COLOR
  },
  row: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.colors.PROFILE_TEXT_INFO_COLOR,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  icon: {
    color: theme.colors.PROFILE_CARD_ICON_COLOR,
    marginRight: theme.spacing(2)
  },
  picker: {
    '& .MuiInputBase-root': {
      background: 'white',
      color: '#444'
    },
    '& .MuiIconButton-label': {
      color: '#444'
    }
  },
  chipsWrapper: {
    display: 'flex',
    padding: theme.spacing(1),
    '&>*': {
      marginRight: 10
    }
  },
  selectChips: {
    marginBottom: theme.spacing(2)
  },
  label: {
    color: theme.colors.FORM_LABEL_COLOR
  },
  textField: {
    borderRadius: 5,
    '& .MuiInputBase-root': {
      background: 'white',
      color: theme.colors.INPUT_TEXT_COLOR,
    },
    '& input': {
      background: 'white',
      color: ({ color }) => color ? color : theme.colors.INPUT_TEXT_COLOR,
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: ({ color }) => color ? color : theme.colors.INPUT_TEXT_COLOR,
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      "-webkit-box-shadow": `0 0 0 100px ${theme.colors.INPUT_BACKGROUND_COLOR} inset`,
      "-webkit-text-fill-color": theme.colors.INPUT_TEXT_COLOR,
      caretColor: ({ color }) => color ? color : theme.colors.INPUT_TEXT_COLOR
    },
    '& .MuiSelect-icon': {
      color: theme.colors.INPUT_TEXT_COLOR,
    }
  }
}));