import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { isMediasPendingUploadExistInLS } from "../utils/MediaUpload";
import { useMediasPendingUploadingForCurrrentItem } from "./useMediasPendingUploadingForCurrrentItem";

export const useMediasPendingExistForCurrrentItem = () => {

  const { id } = useParams();

  const [isExist, setIsExist] = useState(false);

  const isUploadingToCurrent = useMediasPendingUploadingForCurrrentItem();

  useEffect(() => {
    const onStorageChange = () => {
      const _isExist = isMediasPendingUploadExistInLS(id);
      setIsExist(_isExist);
    };

    window.addEventListener("set-medias-pending-upload-storage", onStorageChange);

    onStorageChange();

    return () => {
      window.removeEventListener(
        "set-medias-pending-upload-storage",
        onStorageChange
      );
    };
  }, []);

  return isExist && !isUploadingToCurrent;

}