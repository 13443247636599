import { makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { getMaterialIconString } from '../../utils/icons';
import { Currency, CurrencyIcon, CurrencyReverse, InvoiceStatus } from '../../utils/mappings';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import * as icons from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import DialogCNO from '../../library/DialogCNO';
import { updateInvoice } from '../../actions/invoiceActions';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import RightsManager from '../../utils/RightsManager';

function EditCurrency({
  open,
  onClose,
  invoice,
}) {
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { allInvoiceLoader: { editLoader } } = useSelector(state => state.invoices);

  const validationSchema = yup.object({
    currency: yup.string().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      currency: invoice.currency,
    },
    validationSchema,
    onSubmit: values => handleUpdate(values)
  });

  const handleUpdate = (values) => {

    const dataToSend = {
      ...invoice,
      currency: values.currency
    };

    dispatch(updateInvoice(dataToSend));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Invoice Currency"
      loading={editLoader}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>

        {
          <TextField
            select
            value={formik.values.currency}
            className={GlobalStyles.textFieldSelect}
            variant='outlined'
            margin='dense'
            {...formik.getFieldProps('currency')}
            error={formik.touched['currency'] && Boolean(formik.errors['currency'])}
            helperText={formik.errors['currency']}
            fullWidth
          >
            {
              Object.keys(Currency).map((currency, index) => (
                <MenuItem value={index + 1}>{currency}</MenuItem>
              ))
            }
          </TextField>
        }

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )
};


function InvoiceCurrencyDetailsCard({ invoice }) {

  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const isCompanyAdmin = RightsManager.isGroupLeader();

  const [isCurrencyEditable, setIsCurrencyEditable] = useState(false);

  return (
    <div className={GlobalStyles.detailsCardContainer}>
      <Typography className={GlobalStyles.detailsCardSectionTitle}>
        Currency
      </Typography>

      <div
        className={styles.row}
        onClick={() => {
          setIsCurrencyEditable(true)
        }}
      >
        <Icon path={icons[getMaterialIconString(CurrencyIcon[invoice.currency])]} size="1.5rem" className={styles.icon} />
        <Typography>
          {
            invoice.currency
              ? CurrencyReverse[invoice.currency]
              : 'Click to add Currency'
          }
        </Typography>
      </div>

      {
        isCompanyAdmin &&
        isCurrencyEditable &&
        <EditCurrency
          open={isCurrencyEditable}
          onClose={() => setIsCurrencyEditable(false)}
          invoice={invoice}
        />
      }

    </div>
  )
}

export default InvoiceCurrencyDetailsCard

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    alignItems: 'row',
    color: theme.colors.JOB_DETAIL_CURRENCY_ICON_COLOR,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  icon: {
    color: theme.colors.PROFILE_CARD_ICON_COLOR,
    marginRight: theme.spacing(2)
  },
}));
