import React, { useState } from 'react'
import { InputLabelCNO, SwitchCNO } from '../../../../library'
import { FormFieldsTypes } from '../../utils/enums'
import { Box } from '@material-ui/core';
import FormFieldActions from '../FormFieldActions';

function SwitchRender({ field, formik, onEdit, onDelete, onChange, showFormFieldActions = false, disabled }) {

  const [checked, setChecked] = useState(formik.values[field.name]);

  const handleOnChange = () => {

    setChecked(prev => {
      const nextValue = !prev;

      typeof onChange === 'function' && onChange(nextValue);

      return nextValue;

    });

  }

  return (
    <>
      {
        field.type === FormFieldsTypes.ids.Switch &&
        <Box display='flex' alignItems='flex-start'>
          <Box display='flex' justifyContent='space-between' width='100%' >
            <InputLabelCNO label={field.label} />
            <SwitchCNO
              checked={checked}
              onChange={handleOnChange}
              disabled={disabled}
            />
          </Box>

          {
            showFormFieldActions &&
            <FormFieldActions fieldInitialValues={field} onEdit={onEdit} onDelete={onDelete} />
          }
        </Box>
      }
    </>
  )
}

export default SwitchRender