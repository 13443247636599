export const SubscriptionFeatures = {

  "1": {
    featureName: "Manage Company"
  },
  "2": {
    featureName: "Manage Users"
  },
  "3": {
    featureName: "Job Creation"
  },
  "4": {
    featureName: "Tracking"
  },
  "5": {
    featureName: "Payment"
  },
  "6": {
    featureName: "Quotation"
  },
  "7": {
    featureName: "Invoice"
  },
  "8": {
    featureName: "Appointment"
  },
  "9": {
    featureName: "Customer Login"
  },
  "10": {
    featureName: "Share"
  },
  "11": {
    featureName: "Chat"
  },
  "12": {
    featureName: "Customer Chat"
  },
  "13": {
    featureName: "Basic Report/Analytics"
  },
  "14": {
    featureName: "Dashboard"
  },
  "15": {
    featureName: "Calling (Audio/Video)"
  },
  "16": {
    featureName: "Detailed Report/Analytics"
  }
};

export const SubscriptionFeaturesList = {
  "Manage Company": 1,
  "Manage Users": 2,
  "Job Creation": 3,
  "Tracking": 4,
  "Payment": 5,
  "Quotation": 6,
  "Invoice": 7,
  "Appointment": 8,
  "Customer Login": 9,
  "Share": 10,
  "Chat": 11,
  "Customer Chat": 12,
  "Basic Report/Analytics": 13,
  "Dashboard": 14,
  "Calling (Audio/Video)": 15,
  "Detailed Report/Analytics": 16,
};