import {
  SET_COMPANY,
  SET_COMPANIES,
  SET_COMPANIES_LOADER,
  SET_COMPANY_BY_ID,
  SET_HAS_MORE_COMPANIES,
  TOGGLE_ADD_COMPANY_DIALOG,
  SET_COMPANY_PROFILE_PIC
} from "../actions/customerCompanyActions";

const initialState = {
  companies: [],
  companyById: null,
  isAddCompanyDialog: false,
  companyProfilePic: null,
  allCompaniesLoader: {
    companiesLoader: false,
    moreCompaniesLoader: false,
    editLoader: false,
    addLoader: false,
    deleteLoader: false,
    companyDetailLoader: false,
    companyPicLoader: false
  },
  hasMoreCompanies: true
};

export const companiesReducer = (state = initialState, { type, payload }) => {

  switch (type) {

    case SET_COMPANY_PROFILE_PIC:
      state = {
        ...state,
        companyProfilePic: payload
      };
      return state;

    case SET_COMPANIES:
      state = {
        ...state,
        companies: payload
      };
      return state;

    // case SET_COMPANY:
    //   state = {
    //     ...state,
    //     companies: payload
    //   };
    //   return state;

    case SET_COMPANIES_LOADER:
      state = {
        ...state,
        allCompaniesLoader: {
          ...state.allCompaniesLoader,
          [payload.loaderType]: payload.loaderState
        }
      };
      return state;

    case SET_HAS_MORE_COMPANIES:
      state = {
        ...state,
        hasMoreCompanies: payload
      };
      return state;

    case SET_COMPANY_BY_ID:
      state = {
        ...state,
        companyById: payload
      };
      return state;

    case TOGGLE_ADD_COMPANY_DIALOG:
      state = {
        ...state,
        isAddCompanyDialog: payload
      };
      return state;

    default:
      return state;
  }

};