import { mdiFacebook, mdiInstagram, mdiLinkedin, mdiTwitter, mdiYoutube } from "@mdi/js";
import { AppConstants } from "../../../../utils/AppConstants";

const data = {
  menuWidget: [
    {
      id: 1,
      title: 'About Us',
      menuItems: [
        {
          id: 1,
          url: '/aboutus',
          text: 'About Us',
        },
        {
          id: 2,
          url: '/contactus',
          text: 'Contact Us',
        },
      ],
    },
    {
      id: 2,
      title: 'Our Information',
      menuItems: [
        {
          id: 1,
          url: '/privacy',
          text: 'Privacy Policy',
        },
        {
          id: 2,
          url: '/terms',
          text: 'Terms & Conditions',
        },
      ],
    },
    {
      id: 3,
      title: 'Follow us',
      menuItems: [
        {
          id: 1,
          url: AppConstants.SOCIAL_LINKS.linkedin,
          text: 'LinkedIn',
          icon: mdiLinkedin,
          iconColor: AppConstants.SOCIAL_LINKS_COLOR.linkedin,
        },
        {
          id: 2,
          url: AppConstants.SOCIAL_LINKS.youtube,
          text: 'Youtube',
          icon: mdiYoutube,
          iconColor: AppConstants.SOCIAL_LINKS_COLOR.youtube,
        },
        {
          id: 3,
          url: AppConstants.SOCIAL_LINKS.facebook,
          text: 'Facebook',
          icon: mdiFacebook,
          iconColor: AppConstants.SOCIAL_LINKS_COLOR.facebook,
        },
        {
          id: 4,
          url: AppConstants.SOCIAL_LINKS.instagram,
          text: 'Instagram',
          icon: mdiInstagram,
          iconColor: AppConstants.SOCIAL_LINKS_COLOR.instagram,
        },
        {
          id: 5,
          url: AppConstants.SOCIAL_LINKS.twitter,
          text: 'Twitter',
          icon: mdiTwitter,
          iconColor: AppConstants.SOCIAL_LINKS_COLOR.twitter,
        },
      ],
    },
    {
      id: 4,
      title: 'Policy',
      menuItems: [
        {
          id: 1,
          url: '/cookie',
          text: 'Cookie Policy',
        },
        {
          id: 2,
          url: '/eula',
          text: 'EULA Policy',
        },
      ],
    },
  ],
};
export default data;
