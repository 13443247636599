import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthUserProfilePic } from "../../actions/authUserActions";
import { setCustomerById } from "../../actions/customerActions";
import { setEmployeeById } from "../../actions/employeeActions";
import { ApiCore } from "../../api/utils/core";
import { ProfileMode } from "../../utils";
import { ImageSizeType } from "../../utils/mappings";
import UserContactDetailsCard from "../UserContactDetails";
import UserMainDetailsCard from "../UserMainDetailsCard/UserMainDetailsCard";
import UserPersonalDetailsCard from "../UserPersonalDetailsCard/UserPersonalDetailsCard";
import UserSocialDetailsCard from "../UserSocialDetailsCard/UserSocialDetailsCard";
import UserTrackingDetailsCard from "../UserTrackingDetailsCard/UserTrackingDetailsCard";
import UserWorkingDetails from "../UserWorkingDetails/UserWorkingDetails";
import CustomerEngagementDetailsCard from "../CustomerEngagements/CustomerEngagementDetailsCard";
import { useStyles } from "./UserProfileStyles";
import RightsManager from "../../utils/RightsManager";
import ServiceProviderEngagementDetailsCard from "../ServiceproviderEngagements/ServiceproviderEngagementDetailsCard";
import ServiceProviderWorkingDetails from "../ServiceProviderWorkingDetails/ServiceProviderWorkingDetails";
import ServiceProviderMediasDetailCard from "../ServiceproviderEngagements/ServiceProviderMediasDetailCard";
import ServiceProviderLocationDetails from "../ServiceProviderLocationDetails/ServiceProviderLocationDetails";

function UserProfile({ mode, profile, isActiveMember }) {
  const isUserAdmin = RightsManager.isAdmin();

  const tillTablet = useMediaQuery("(max-width: 767px)");

  const styles = useStyles({ tillTablet });

  const isEmployee = mode === ProfileMode.Employee;
  const isServiceProvider = mode === ProfileMode.ServiceProvider;

  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : null;

  const [localProfilePicUrl, setLocalProfilePicUrl] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setEmployeeById(null));
      dispatch(setCustomerById(null));
    };
  }, []);

  const profilePic = profile ? profile.profilePic : null;
  const profileId = profile ? profile.id : null;

  useEffect(() => {
    (async function getMediaIIFE() {
      if (!profilePic) {
        return;
      }

      const dataToSend = {
        ids: [profilePic],
        size: ImageSizeType.Mobile,
      };

      const apiProfileGetMedia = new ApiCore({
        post: true,
        url: isEmployee
          ? "member/getmedialink"
          : isServiceProvider
          ? "serviceProvider/getmedialink"
          : "customer/getmedialink",
      });

      await apiProfileGetMedia
        .post(dataToSend)
        .then((response) => {
          if (response.status === 200) {
            const links = response.data.links;
            setLocalProfilePicUrl(links[0].link);

            if (authUser.id === profileId) {
              dispatch(setAuthUserProfilePic(links[0].link));
            }
          } else {
            console.log("Get Media Error: ");
          }
        })
        .catch((error) => {
          console.log("Get Media Error: ", error);
        });
    })();
  }, [profilePic, profileId]);

  return (
    <div className={styles.container_UserProfile}>
      <UserMainDetailsCard
        profile={profile}
        isEmployee={isEmployee}
        isServiceProvider={isServiceProvider}
        isActiveMember={isActiveMember}
        localProfilePicUrl={isServiceProvider ? profilePic : localProfilePicUrl}
        setLocalProfilePicUrl={setLocalProfilePicUrl}
      />
      <UserPersonalDetailsCard
        profile={profile}
        isEmployee={isEmployee}
        isServiceProvider={isServiceProvider}
        isActiveMember={isActiveMember}
        localProfilePicUrl={localProfilePicUrl}
      />
      <UserContactDetailsCard
        profile={profile}
        isEmployee={isEmployee}
        isServiceProvider={isServiceProvider}
        isActiveMember={isActiveMember}
        localProfilePicUrl={localProfilePicUrl}
      />
      <UserSocialDetailsCard
        profile={profile}
        isEmployee={isEmployee}
        isServiceProvider={isServiceProvider}
        isActiveMember={isActiveMember}
        localProfilePicUrl={localProfilePicUrl}
      />

      {isEmployee && (
        <UserTrackingDetailsCard
          profile={profile}
          isEmployee={isEmployee}
          isServiceProvider={isServiceProvider}
          isUserAdmin={isUserAdmin}
          isActiveMember={isActiveMember}
          localProfilePicUrl={localProfilePicUrl}
        />
      )}

      {isEmployee && (
        <UserWorkingDetails
          profile={profile}
          isEmployee={isEmployee}
          isActiveMember={isActiveMember}
          localProfilePicUrl={localProfilePicUrl}
        />
      )}

      {isServiceProvider && (
        <ServiceProviderWorkingDetails
          profile={profile}
          isServiceProvider={isServiceProvider}
          isActiveMember={isActiveMember}
          localProfilePicUrl={localProfilePicUrl}
        />
      )}

      {isUserAdmin && !isEmployee && !isServiceProvider && (
        <CustomerEngagementDetailsCard profile={profile} />
      )}
      {isUserAdmin && isServiceProvider && (
        <ServiceProviderLocationDetails
          profile={profile}
          isActiveMember={isActiveMember}
        />
      )}
      {isUserAdmin && isServiceProvider && (
        <ServiceProviderEngagementDetailsCard profile={profile} />
      )}

      {isUserAdmin && isServiceProvider && (
        <ServiceProviderMediasDetailCard profile={profile} />
      )}
    </div>
  );
}

export default UserProfile;
