import React from 'react'
import { useGlobalStyles } from '../utils/Styles/GlobalStyles';
import { Typography, useTheme } from '@material-ui/core';
import ListCTAButtons from '../components/ListCTAButtons';
import { IconMain } from './IconCNO';

const ListEmpty = ({
  notFoundIcon,
  notFoundTextMessage = "Not found.",
  showListCTAButtons = true,

  addTitle,
  onClickAdd,
  exploreId,
  listCTAButtonsProps = {}
}) => {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  return (
    <>
      <div className={GlobalStyles.listingNotFound} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {
          notFoundIcon &&
          <IconMain
            name={notFoundIcon}
            size="4rem"
            color={theme.colors.LIST_CARD_USERNAMES_COLOR}
          />
        }
        <Typography style={{ fontSize: '1.2rem' }}>
          {notFoundTextMessage}
        </Typography>
      </div>
      {
        showListCTAButtons &&
        <ListCTAButtons
          addTitle={addTitle}
          onClickAdd={onClickAdd}
          exploreId={exploreId}
          {...listCTAButtonsProps}
        />
      }
    </>
  )
}

export default ListEmpty