import React, { Fragment, useEffect, useState } from 'react';
import { getMimeTypeFromFileType, resizeFile } from '../utils/Helpers';
import { AppConstants } from '../utils/AppConstants';
import { toast } from 'react-toastify';
import { FileType } from '../utils/mappings';
import { mdiFilePlus, mdiImagePlus, mdiVideoPlus } from '@mdi/js';
import { useTheme } from '@material-ui/core';
import AttachmentItem from '../components/AttachmentItem/AttachmentItem';
import MoreActionCNO from './MoreActionsCNO/MoreActionCNO';
import Dropzone, { ErrorCode } from 'react-dropzone';
import FullScreenLoaderCNO from './FullScreenLoaderCNO';
import {media} from '.././assets/AddMediaInPost'
import { mediaDisabled } from '.././assets/AddMediaInPost';
function DocumentUploadCNO({
  setLoaders,
  pickerIcon = {},
  onUpload,
  multiple = false,
  allowedFileTypes = "image/*",
  mediaDisabled,
  disabled = mediaDisabled,
  maxFileSize,
  pickerOptions = {
    isImage: true,
    isVideo: false,
    isDocument: true,
    document: [
      getMimeTypeFromFileType(FileType.Pdf),
      getMimeTypeFromFileType(FileType.Doc),
      getMimeTypeFromFileType(FileType.Xls),
      getMimeTypeFromFileType(FileType.Xlsx),
      getMimeTypeFromFileType(FileType.Ppt),
      getMimeTypeFromFileType(FileType.Pptx),
      getMimeTypeFromFileType(FileType.Docx),
      getMimeTypeFromFileType(FileType.Text),
    ]
  },
  onError,
}) {

  const theme = useTheme();

  pickerIcon = {
    icon: mdiFilePlus,
    size: '1.8rem',
    color: theme.colors.IMAGE_UPLOAD_ICON_COLOR,
    ...pickerIcon,
  };

  return (
    <Fragment>

      <div>
        {/* <MoreActionCNO
          moreActionIcon={pickerIcon.icon}
          moreActionIconSize={pickerIcon.size}
          moreActionIconColor={pickerIcon.color}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          elevation={0}
        >
          
        </MoreActionCNO> */}
          <DocumentPicker
            open={true}
            onUpload={onUpload}
            multiple={multiple}
            allowedFileTypes={allowedFileTypes}
            disabled={disabled}
            maxFileSize={maxFileSize}
            pickerOptions={pickerOptions}
            onError={onError}
            
          />
      </div>

    </Fragment>
  )
}

export default DocumentUploadCNO;


const DocumentPicker = ({
  closeMoreActions,
  onUpload,
  onError,
  multiple,
  disabled,
  pickerOptions,
  
}) => {

  const theme = useTheme();

  let dropzoneProps = {
    maxFiles: multiple ? AppConstants.MAX_NUMBER_OF_FILES : 1,
    // maxSize: 250 * 1024,
    maxSize: AppConstants.MAX_FILE_SIZE,
    disabled: disabled
  };

  const onUploadSuccess = (acceptedFiles) => {
    if (onUpload) {
      onUpload(acceptedFiles);
    }

    closeMoreActions();

  }

  return (
    <div>
      {/* {
        (pickerOptions.isImage ) &&
        <Dropzone
          {...dropzoneProps}
          accept={[getMimeTypeFromFileType(FileType.Image)]}
          onDropAccepted={async (acceptedFiles) => {
            console.log("===onDrop====acceptedFiles==selected===", acceptedFiles);

            const resizedImagesPromises = acceptedFiles.map(async (image) => {

              let compressionRatio = 80;

              const originalFileSizeInMB = image.size / (1024 * 1024);

              if (originalFileSizeInMB >= 8) {
                compressionRatio = 60;
              } else if (originalFileSizeInMB >= 5 && originalFileSizeInMB < 8) {
                compressionRatio = 70;
              } else if (originalFileSizeInMB >= 3 && originalFileSizeInMB < 5) {
                compressionRatio = 80;
              } else {
                compressionRatio = 90;
              }

              const _resizedImage = await resizeFile(
                image,
                8000,
                8000,
                "JPEG",
                compressionRatio,
                0,
                "file"
              );

              // const resizedFileSizeInMB = _resizedImage.size / (1024 * 1024);

              // console.log("===onDrop====acceptedFiles==values===", { compressionRatio, originalFileSizeInMB, resizedFileSizeInMB });
              // console.log("===onDrop====acceptedFiles==_resizedImage===", _resizedImage);

              return _resizedImage;

            })

            const resolvedResizedImages = await Promise.all(resizedImagesPromises);

            onUploadSuccess(resolvedResizedImages);

          }}
          onDropRejected={(rejectedFiles) => {
            console.log("====onDrop=====rejectedFiles===", rejectedFiles);

            const errors = rejectedFiles
              .reduce((_errors, file) => {

                const errorCodes = _errors.map(err => err.code);

                file.errors.forEach(err => {

                  _errors = errorCodes.includes(err.code) ? _errors : [..._errors, err]

                })

                console.log("====onDrop=====_errors====", _errors);

                return _errors;

              }, []);

            console.log("====onDrop=====errors====", errors);
            console.log("====onDrop=====dropzoneProps====", dropzoneProps);

            errors.forEach(error => {

              switch (error.code) {
                case ErrorCode.FileInvalidType:
                  toast.error("Please select a valid file.");
                  break;

                case ErrorCode.FileTooLarge:
                  toast.error(`Maximum allowed file size is ${(dropzoneProps.maxSize / (1024 * 2024)).toFixed(2)} MB.`);
                  break;

                case ErrorCode.FileTooSmall:

                  break;

                case ErrorCode.TooManyFiles:
                  toast.error(`Maximum number of allowed files are ${dropzoneProps.maxFiles}.`);
                  break;

                default:
                  break;
              }

            })

            if (onError) onError();

          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <AttachmentItem
                  icon={mdiImagePlus}
                  title="Image"
                  color={theme.colors.CHAT_ATTACHMENT_POPUP_IMAGE_VIDEO_ICON_BACKGROUND_COLOR}
                />
              </div>
            </section>
          )}
        </Dropzone>
      } */}

      {/* {
        (pickerOptions.isVideo) &&
        <Dropzone
          {...dropzoneProps}
          accept={[getMimeTypeFromFileType(FileType.Video)]}
          onDropAccepted={(acceptedFiles) => {
            console.log("=======acceptedFiles=====", acceptedFiles);
            // if (onUpload) onUpload(acceptedFiles);
            onUploadSuccess(acceptedFiles);
          }}
          onDropRejected={(rejectedFiles) => {
            console.log("=======acceptedFiles==rejectedFiles===", rejectedFiles);
            if (onError) onError();
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <AttachmentItem
                  icon={mdiVideoPlus}
                  title="Video"
                  color={theme.colors.CHAT_ATTACHMENT_POPUP_IMAGE_VIDEO_ICON_BACKGROUND_COLOR}
                />
              </div>
            </section>
          )}
        </Dropzone>
      } */}

      {
        (pickerOptions.isImage && pickerOptions.isVideo) &&
        <Dropzone
          {...dropzoneProps}
          accept={[getMimeTypeFromFileType(FileType.Image), getMimeTypeFromFileType(FileType.Video)]}
          onDropAccepted={(acceptedFiles) => {
            console.log("=======acceptedFiles=====", acceptedFiles);
            // if (onUpload) onUpload(acceptedFiles);
            onUploadSuccess(acceptedFiles);
          }}
          onDropRejected={(rejectedFiles) => {
            console.log("=======acceptedFiles==rejectedFiles===", rejectedFiles);
            if (onError) onError();
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <AttachmentItem
                  imageSrc={disabled? mediaDisabled : media}
                  title="Image & Video"
                  color={theme.colors.CHAT_ATTACHMENT_POPUP_IMAGE_VIDEO_ICON_BACKGROUND_COLOR}
                />
              </div>
            </section>
          )}
        </Dropzone>
      }

      {
        (pickerOptions.isDocument) &&
        <Dropzone
          {...dropzoneProps}
          accept={pickerOptions.document.length ? pickerOptions.document : []}
          onDropAccepted={(acceptedFiles) => {
            console.log("=======acceptedFiles=====", acceptedFiles);
            // if (onUpload) onUpload(acceptedFiles);
            onUploadSuccess(acceptedFiles);
          }}
          onDropRejected={(rejectedFiles) => {
            console.log("=======acceptedFiles==rejectedFiles===", rejectedFiles);
            if (onError) onError();
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <AttachmentItem
                  icon={mdiFilePlus}
                  title="Documents"
                  color={theme.colors.CHAT_ATTACHMENT_POPUP_CAMERA_ICON_BACKGROUND_COLOR}
                />
              </div>
            </section>
          )}
        </Dropzone>
      }
    </div>
  )

}
