import { KeyboardDatePicker } from '@material-ui/pickers';
import { mdiFormatText, mdiRestore } from '@mdi/js';
import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleInvoiceSearch, resetInvoiceSearch } from '../../actions/invoiceActions';
import { fetchCustomerSuggest, fetchInvoiceSuggest } from '../../actions/suggestionsActions';
import FormActionButtons from '../../components/FormActionButtons';
import JobChipsFilter from '../../components/JobChipsFilter';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import InputCNO from '../../library/InputCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import * as yup from 'yup';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import HeadingBar from '../../components/HeadingBar';
import { useGlobalStore } from '../../providers/GlobalProvider';
import { TOGGLE_SEARCH } from '../../utils/actions';

function InvoiceFilterModal({ setPage }) {

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const styles = useStyles({ tillTablet });
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { invoiceSearchParams } = useSelector(state => state.invoices);
  const suggestions = useSelector(state => state.suggestions);

  const { dispatchGlobal } = useGlobalStore();
  const closeInvoiceFilterDialog = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false
    });
  };

  const validationSchema = yup.object({
    categories: yup.array()
  });

  const formik = useFormik({
    initialValues: {
      customer: null,
      categories: [],
      invoice: null,
      invoiceNumber: "",
      billDate: null,
      dueInDays: null,
      statuses: [],
      paymentByCustomerStatuses: [],
      isToday: false
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(handleInvoiceSearch(values));
      closeInvoiceFilterDialog();
    }
  });

  useEffect(() => {
    formik.setValues(invoiceSearchParams);
    setPage(0);
  }, [invoiceSearchParams]);

  const handleResetForm = () => {
    formik.resetForm();
    dispatch(resetInvoiceSearch());
  };

  const triggerInvoiceSearch = (values) => {
    if (!tillTablet) {
      dispatch(handleInvoiceSearch(values));
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.container_InvoiceFilterModal}>

      <HeadingBar
        title={formik.dirty ? "Reset" : "Search"}
        icon={formik.dirty ? mdiRestore : null}
        onHeaderClick={handleResetForm}
        onClose={formik.dirty ? undefined : closeInvoiceFilterDialog}
      />

      <AutocompleteCNO
        name='customer'
        label='Contact Person'
        formik={formik}
        placeholder='Search Customer'
        // fullWidth
        data={suggestions}
        optionLabel={x => x.title || ""}
        renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
        onChange={(value) => {
          if (value) {
            dispatch(fetchCustomerSuggest(value));
          }
        }}
        onSuggestionClick={(value) => {
          formik.setFieldValue('customer', value);
          triggerInvoiceSearch({ ...formik.values, customer: value });
        }}
      />

      <div style={{ marginTop: 12 }}></div>

      {
        tillTablet &&
        <AutocompleteCNO
          label='Select Invoice'
          name='invoice'
          placeholder='Search Invoice...'
          formik={formik}
          data={suggestions}
          optionLabel={x => x.title || ""}
          renderOption={option => <div style={{ color: 'orangered' }}>{option.title}</div>}
          // fullWidth
          onSuggestionClick={(value) => {
            formik.setFieldValue('invoice', value);
            triggerInvoiceSearch({ ...formik.values, invoice: value });
          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchInvoiceSuggest(value));
            }
          }}
        />
      }

      <div className={GlobalStyles.verticalScrollChipsWrapper} style={{ marginTop: 12, marginBottom: 12 }}>
        <JobChipsFilter
          mode="invoice"
          onChipToggle={(categories) => {
            formik.setFieldValue('categories', categories);
            triggerInvoiceSearch({ ...formik.values, categories: categories });
          }}
        />
      </div>

      <InputCNO
        name='invoiceNumber'
        label='Invoice ID'
        formik={formik}
        placeholder='Enter Invoice number'
        icon={mdiFormatText}
        fullWidth
        onChange={(e) => {
          formik.setFieldValue('invoiceNumber', e.target.value);
          triggerInvoiceSearch({ ...formik.values, invoiceNumber: e.target.value });
        }}
      />

      <div style={{ marginTop: 12 }}>
        <label htmlFor="billDate" className={GlobalStyles.label}>Bill Date</label>
        <KeyboardDatePicker
          className={clsx(GlobalStyles.picker, GlobalStyles.start)}
          id='billDate'
          margin="dense"
          placeholder='Bill Date'
          value={formik.values.billDate}
          format="MMM dd, yyyy"
          inputVariant='outlined'
          onChange={date => {
            formik.setFieldTouched('billDate', true);
            formik.setFieldValue('billDate', date);
            triggerInvoiceSearch({ ...formik.values, billDate: date });
          }}
          error={formik.touched.billDate && Boolean(formik.errors.billDate)}
          fullWidth
          helperText={formik.errors.billDate}
        />
      </div>

      <div style={{ marginTop: 12 }}>
        <InputCNO
          name='dueInDays'
          label='Due in days'
          formik={formik}
          placeholder='Search by due in days'
          icon={mdiFormatText}
          fullWidth
          onChange={(e) => {
            formik.setFieldValue('dueInDays', e.target.value);
            triggerInvoiceSearch({ ...formik.values, dueInDays: e.target.value });
          }}
        />
      </div>

      {
        tillTablet &&
        <FormActionButtons
          rightText="Search"
          rightClick={formik.handleSubmit}
          leftClick={closeInvoiceFilterDialog}
          formik={formik}
        />
      }

    </form>
  )
}

export default InvoiceFilterModal

const useStyles = makeStyles((theme) => ({
  container_InvoiceFilterModal: {
    padding: 8,
    '&>*:first-child': {
      height: ({ tillTablet }) => tillTablet ? 'auto' : 68
    }
  }
}));