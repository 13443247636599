import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme
} from '@material-ui/core';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { useCustomerEngagemenStyles } from './CustomerEngagementDetailsCard';
import clsx from 'clsx';
import { batch, useDispatch, useSelector } from 'react-redux';
import { handleQuotationSearch, setQuotations, setQuotationSearchParams } from '../../actions/quotationActions';
import QuotationListing from '../../pages/QuotationManagement/QuotationListing';
import { mdiChevronDown, mdiQuora } from '@mdi/js';

function CustomerQuotationsSection({ profile }) {

  const theme = useTheme();
  const styles = useCustomerEngagemenStyles();
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { quotations, allQuotationLoader } = useSelector(state => state.quotations);
  const { quotationsLoader } = allQuotationLoader;

  const [isSectionExpanded, setIsSectionExpanded] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    return () => {
      setIsSectionExpanded(false);
    };
  }, []);

  return (
    <div style={{ padding: 0 }}>
      <Accordion
        expanded={isSectionExpanded}
        className={clsx(GlobalStyles.accordion, styles.accordion)}
      >
        <AccordionSummary
          expandIcon={<Icon
            path={mdiChevronDown}
            size="1.2rem"
            color={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR} />
          }
          className={GlobalStyles.accordionSummary}
          onClick={async (e) => {
            e.stopPropagation();

            const isExpanded = !isSectionExpanded;

            setIsSectionExpanded(isExpanded);

            if (isExpanded && !quotations.length) {
              const searchParams = {
                customer: { id: profile.id },
                categories: [],
                quotation: null,
                offerNumber: "",
                sentOn: null,
                validForDays: null,
                statuses: [],
                isToday: false,
              };

              dispatch(handleQuotationSearch(searchParams));

            }

          }}
        >
          <Icon path={mdiQuora} size='1.3rem' className={styles.accordionTitleIcon} />
          <Typography>Quotations</Typography>
        </AccordionSummary>

        {
          isSectionExpanded ? (
            <AccordionDetails className={styles.accordionDetails}>
              <div className={styles.grid}>
                {
                  quotations.length
                    ? <div className={styles.listingWrapper} >
                      <QuotationListing customer={profile} page={page} setPage={setPage} />
                    </div>
                    : <Typography className={styles.loader}>
                      {quotationsLoader ? 'Loading...' : 'No quotations found'}
                    </Typography>
                }
              </div>
            </AccordionDetails>
          ) : (null)
        }

      </Accordion>
    </div>
  );
}

export default CustomerQuotationsSection;
