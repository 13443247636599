import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { InfiniteScrollCNO } from '../../../../library';
import { AddPostDialog, PostListCard } from '../../../PostsManagement/components';
import { mdiScriptTextOutline } from '@mdi/js';
import { fetchEventsDiscussion } from '../../../../actions/eventDiscussionActions';
import FullScreenLoaderCNO from '../../../../library/FullScreenLoaderCNO';
import AddEventDiscussionTrigger from './AddEventDiscussionTrigger';
import { toggleAddPostDialog } from '../../../../actions/postActions';

const EventDiscussionsDetailsCard = ({ event }) => {

  const dispatch = useDispatch();

  const {
    eventDiscussions,
    hasMoreEventDiscussions,
    eventDiscussionsLoader,
  } = useSelector(state => state.eventDiscussions);

  const [page, setPage] = useState(0);

  useEffect(() => {

    console.log("eventDiscussions.length ", eventDiscussions.length)

    if (!eventDiscussions.length && !eventDiscussionsLoader) {
      setPage(0);
      const payload = {
        eventId: event.id
      };

      dispatch(fetchEventsDiscussion(payload));
    }

  }, []);

  const loadMore = () => {

    console.log("onEndReached : loading more.")

    if (hasMoreEventDiscussions) {
      const moreEventDiscussionsPayload = {
        pageIndex: page + 1,
      };

      console.log("moreEventDiscussionsPayload: ", moreEventDiscussionsPayload);

      // dispatch(fetchMorePosts(moreEventDiscussionsPayload));

      setPage(page + 1);
    }

  };

  return (
    <Box padding={2} margin='0 auto'>

      <AddEventDiscussionTrigger onClick={() => {
        console.log("clickAddd")
        dispatch(toggleAddPostDialog(true));
      }} />

      <FullScreenLoaderCNO loading={eventDiscussionsLoader} />

      <InfiniteScrollCNO
        items={eventDiscussions}
        next={loadMore}
        hasMore={hasMoreEventDiscussions}
        loading={eventDiscussionsLoader}
        renderListItem={(post) => (
          <PostListCard key={post.id} post={post} />
        )}
        notFoundTextMessage={'No event discussion present'}
        notFoundIcon={mdiScriptTextOutline}
        showListCTAButtons={true}
        listCTAButtonsProps={{ addTitle: 'Add Discussion' }}
      />

      <AddPostDialog />

    </Box>
  )
}

export default EventDiscussionsDetailsCard