import React, { useState } from 'react';
import ScreenView from '../../components/ScreenView/ScreenView';
import InvoiceFilterModal from './InvoiceFilterModal';
import InvoiceListing from './InvoiceListing';

function InvoiceListingPage() {

  const [page, setPage] = useState(0);

  return (
    <div>
      <ScreenView
        content={<InvoiceListing page={page} setPage={setPage} />}
        secondarySidebar={<InvoiceFilterModal setPage={setPage} />}
        headerTitle="Invoices"
      />
    </div>
  )
}

export default InvoiceListingPage
