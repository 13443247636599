import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_NotificationItem: {

  },
  listItem: {
    background: theme.colors.MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR,
    padding: theme.spacing(2),
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #7e7e7e',
    '&:last-child': {
      borderBottom: 'none'
    },
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.01)',
      overflow: 'hidden'
    }
  },
  readListItem: {
    // background: theme.palette.background.light,
    background: theme.colors.NOTIFICATION_READ_BACKGROUND_COLOR,
  },
  notificationMessageWrapper: {
    flex: 1,
    marginRight: theme.spacing(6),
    marginLeft: theme.spacing(1),
    alignSelf: 'flex-start',
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
  },
  notificationMessage: {
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
    // width: ({ fromTablet }) => fromTablet ? 300 : 150,
  },
  notificationMessageTime: {
    fontSize: "0.8rem",
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
  },
  jobTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
    fontSize: '1rem'
  },
  startDate: {
    color: theme.colors.MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR,
    fontSize: '0.8rem'
  }
}))