import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container_JobTargetDetails: {
    '&>*': {
      marginTop: theme.spacing(2)
    },
  },
  chip: {
    background: theme.palette.background.light
  },
  chipsWrapper: {
    '&>*:first-child': {
      marginBottom: theme.spacing(1),
      color: theme.colors.FORM_LABEL_COLOR,
    }
  },
  chips: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    '&>*': {
      marginRight: theme.spacing(1)
    }
  },
}));