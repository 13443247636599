import React from 'react'
import { FormFieldsTypes } from '../../utils/enums'
import FormInput from './FormInput'
import { InputCNO, InputLabelCNO, SwitchCNO } from '../../../../library'
import { Box } from '@material-ui/core'
import DefaulValueAttribute from './DefaulValueAttribute'
import RequiredValueAttribute from './RequiredValueAttribute'

const CommonFieldAttributes = ({ formik }) => {
  return (
    <>
      {
        ![FormFieldsTypes.ids.DateTime, FormFieldsTypes.ids.Address].includes(formik.values.type) &&
        <FormInput
          label='Field Label'
          placeholder='Enter field label...'
          formik={formik}
        />
      }

      <InputCNO
        name='helperText'
        label='Helper Text'
        placeholder='Enter helper text for the field...'
        formik={formik}
        fullWidth
      />

      <RequiredValueAttribute formik={formik} />

    </>
  )
}

export default CommonFieldAttributes
