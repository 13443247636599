import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import JobListingsInfinite from '../../components/JobListingsInfinite/JobListingsInfinite';
import JobSearchSidebar from '../../components/JobSearchSidebar';
import ScreenView from '../../components/ScreenView/ScreenView';
import JobsTimeFilterBar from '../../components/JobsTimeFilterBar/JobsTimeFilterBar';
import StickyWrapper from '../../library/StickyWrapper';
import QuickTutorialVideo from '../../components/QuickTutorialVideo';

function HomePage() {
  const styles = useStyles();

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const [page, setPage] = useState(0);

  const renderContent = () => (
    <div className={styles.content_HomePage}>

      <StickyWrapper
        containerStyle={{
          paddingLeft: 8,
          paddingRight: 8,
          top: tillTablet ? 47 : 68,
        }}
      >
        <JobsTimeFilterBar setPage={setPage} />
      </StickyWrapper>

      <div style={{ marginTop: 10 }}>
        <JobListingsInfinite
          page={page}
          setPage={setPage}
        />
      </div>
    </div>
  );

  return (
    <div className={styles.container_HomePage}>
      <QuickTutorialVideo />
      <ScreenView
        content={renderContent()}
        secondarySidebar={<JobSearchSidebar setPage={setPage} />}
      />
    </div>
  );
}

export default HomePage

const useStyles = makeStyles(theme => ({
  container_HomePage: {
    // background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    // background: '#2b2b2b',
    width: '100%',
    minHeight: '100vh',
  },
  content_HomePage: {
  },
  toolbar: {
    background: theme.colors.HOME_SEARCH_SCROLL_BACKGROUND_COLOR,
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
  },
  searchIcon: {
    paddingRight: 5,
    borderRight: `2px solid ${theme.palette.text.disabled}`
  },
  jobChipSlider: {
    display: 'flex',
    padding: '5px 0',
    // width: 'calc(100vw - 85px)',
    overflowX: 'auto',
    marginLeft: 5,
    alignItems: 'center',
    '&>*': {
      marginLeft: 8
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))