import { Dialog, makeStyles, Typography } from '@material-ui/core';
import { mdiDirections } from '@mdi/js';
import Icon from '@mdi/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import HeadingBar from '../HeadingBar/HeadingBar';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import RightsManager from '../../utils/RightsManager';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { apiTaskEdit } from '../../api';
import { toast } from 'react-toastify';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { updateService } from '../../actions/serviceActions';

function EditServiceLocationDetails({ isEditable, setIsEditable, service }) {

  const dispatch = useDispatch();
  const { editServiceLoader } = useSelector(state => state.services);

  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    const getLocation = async () => {
      await geocodeByAddress(service?.mapAddress)
        .then(results => {
          setLocationData(results);
          console.log("serviceLocation: ", results);
        })
        .catch(error => {
          console.error("Location Error: ", error);
          toast.error("Location fetch error!!!");
        });
    };

    if (window?.google?.maps) {
      getLocation();
    }
  }, [service?.mapAddress])

  const handleClose = () => setIsEditable(false);

  const handleUpdate = async (values) => {

    let latitude = 0;
    let longitude = 0;

    await geocodeByAddress(values.serviceLocation.label)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        latitude = lat;
        longitude = lng;
      });

    const payload = {
      ...service,
      mapAddress: values.serviceLocation.label,
      lat: latitude.toString(),
      lng: longitude.toString()
    };

    dispatch(updateService(payload));

    handleClose();
  };

  const validationSchema = yup.object({
    serviceLocation: yup.object().required("Required!!!").nullable()
  });

  const formik = useFormik({
    initialValues: {
      serviceLocation: {
        label: service?.mapAddress,
        value: locationData
      }
    },
    validationSchema,
    onSubmit: values => handleUpdate(values)
  });


  const useStyles = makeStyles(theme => ({
    container: {
      '&>*': {
        marginTop: theme.spacing(2),
      }
    },
    formTitle: {
      marginLeft: 5
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      '&>*': {
        marginTop: theme.spacing(2)
      }
    },
    paper: {
      padding: theme.spacing(2),
      background: theme.colors.DIALOG_BACKGROUND_COLOR,
      // color: theme.colors.DIALOG_TITLE_COLOR,
      borderRadius: 10,
      width: '70vw',
      height: '300px',
      maxWidth: '90vh'
    },
  }));
  const styles = useStyles();


  return (
    <Dialog
      open={isEditable}
      className={styles.container}
      classes={{
        paper: styles.paper
      }}
    >
      <HeadingBar title="Edit Location" onClose={handleClose} />

      {
        editServiceLoader &&
        <FullScreenLoaderCNO />
      }

      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          selectProps={{
            styles: {
              option: (provided) => ({
                ...provided,
                color: 'brown',
              }),
            },
            value: formik.values.serviceLocation,
            onChange: (value) => formik.setFieldValue("serviceLocation", value)
          }}
        />
        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
        />
      </form>
    </Dialog>
  )
}

function ServiceLocationDetailsCard({ service }) {

  const isUserAdmin = RightsManager.isAdmin();

  const [isEditable, setIsEditable] = useState(false)

  const styles = useStyles();
  return (<>
    {
      isUserAdmin && isEditable &&
      <EditServiceLocationDetails
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        service={service}
      />
    }
    <div className={styles.container} onClick={() => setIsEditable(true)}>
      <Typography className={styles.cardTitle}>
        Location
      </Typography>
      <div className={styles.cardContent}>
        <div className={styles.row} >
          <Typography>{service.mapAddress || 'Click to add location'}</Typography>
          <a
            href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${service.mapAddress}`}
            target="_blank"
            rel="noreferrer"
            onClick={e => e.stopPropagation()}
          >
            <Icon
              path={mdiDirections}
              size='2rem'
              className={styles.icon}
            />
          </a>
        </div>
      </div>
    </div>
  </>)
}

export default ServiceLocationDetailsCard

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`
  },
  cardTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
  },
  cardContent: {
    padding: theme.spacing(1),
    '&>*': {
      marginTop: theme.spacing(1)
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.colors.JOB_DETAIL_SECTION_TEXT_COLOR,
  },
  icon: {
    color: theme.colors.GET_DIRECTION_ICON_COLOR,
  }
}))