import React from "react";
import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  lighten,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import * as mdi from "@mdi/js";
import { AppointmentType } from "../../../utils/mappings";
import RightsManager from "../../../utils/RightsManager";

const CreatePostDrawerMenu = ({
  drawerMenu,
  handleclick,
  isVisible,
  setIsVisible,
  noTypo,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const isUserAdmin = RightsManager.isAdmin();

  return (
    <div className={styles.container}>
      <ListItem
        button
        className={styles.listItem}
        onClick={() => setIsVisible((prev) => !prev)}
      >
        <ListItemIcon className={styles.icon}>
          <Icon
            path={mdi[drawerMenu.drawerIcon]}
            size="1.5rem"
            color={
              drawerMenu.drawerName === "post"
                ? theme.colors.HEADER_ADD_TODO_ICON_COLOR
                : theme.colors.JOB_ACTION_BUTTON_IN_PROGRESS_ACTIVE_COLOR
            }
          />
        </ListItemIcon>
        {!noTypo && <ListItemText primary={drawerMenu.drawerTitle} />}
      </ListItem>

      {/* nested list */}
      <Collapse in={isVisible} className={styles.nestedListContainer}>
        <div className={styles.nestedList}>
          {drawerMenu.drawerItems
            .filter((item) => {
              // Filter out holiday appointments if the user is not an admin
              if (
                item.appointmentType === AppointmentType.HOLIDAY &&
                !isUserAdmin
              ) {
                return false;
              }
              return true;
            })
            .map((item) => (
              <ListItem
                button
                className={styles.listItem1}
                onClick={() => handleclick(item.name)}
                key={item.name}
              >
                <ListItemIcon className={styles.icon}>
                  <Icon
                    path={mdi[item.icon]}
                    size="1.0rem"
                    style={{ marginLeft: "0.4rem" }}
                    color={theme.colors.DRAWER_ITEM_ICON_COLOR}
                  />
                </ListItemIcon>
                <Typography>{item.title}</Typography>
              </ListItem>
            ))}
        </div>
      </Collapse>
    </div>
  );
};

export default CreatePostDrawerMenu;

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  listItem: {
    borderRadius: 5,
    color: theme.colors.DRAWER_ITEM_TEXT_COLOR,
    height: 40,
  },
  nestedListContainer: {
    position: "absolute",
    top: "calc(100% + 8px)", // Adjust this value as needed
    left: 0, // Ensure it's aligned with the parent
    zIndex: 1,
    background: lighten(theme.colors.SIDEBAR_BACKGROUND_COLOR, 0.1),
  },
  nestedList: {
    minWidth: 180, // Adjust this value as needed
  },
  listItem1: {
    borderRadius: 5,
    color: theme.colors.DRAWER_ITEM_TEXT_COLOR,
    height: 36,
  },
  icon: {
    marginRight: -25,
  },
}));
