import { mdiFormatText } from "@mdi/js";
import { useFormik } from "formik";
import React from "react";
import DialogCNO from "../../library/DialogCNO";
import InputCNO from "../../library/InputCNO";
import FormActionButtons from "../FormActionButtons";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateAppointmentStatus } from "../../actions/appointmentActions";
import { AppointmentStatus } from "../../utils/mappings";
import {
  getAppointmentRejectHtmlString,
  getLeaveRejectHtmlString,
} from "../../pdfTemplates/templates";
import { AppointmentType } from "../../utils/mappings";

function AppointmentRejectDialog({
  open,
  onClose,
  appointment,
  closeMoreActions,
  targetStatus
}) {
  const dispatch = useDispatch();
  const { editAppointmentLoader } = useSelector((state) => state.appointments);

  const formik = useFormik({
    initialValues: {
      rejectReason: "",
    },
    validationSchema: yup.object({
      rejectReason: yup.string().required("Confirm Text is required"),
    }),
    onSubmit: (values) => handleReject(values),
  });

  const handleReject = (values) => {
    const meta = appointment.appointmentType ?
      appointment.appointmentType === AppointmentType.LEAVE
        ? getLeaveRejectHtmlString(appointment, values.rejectReason)
        : getAppointmentRejectHtmlString(appointment, values.rejectReason)
        : getAppointmentRejectHtmlString(appointment, values.rejectReason);
        
    const updatePayload = {
      ...appointment,
      status: targetStatus ? targetStatus : AppointmentStatus.SentRejected,
      meta: meta,
      rejectionMessage:values.rejectReason
    };

    console.log("======updatePayload====", updatePayload);

    dispatch(updateAppointmentStatus(updatePayload));

    onClose();
  };

  const handleClose = () => {
    onClose();

    if (closeMoreActions) {
      closeMoreActions();
    }
  };

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Reject Appointment"
      loading={editAppointmentLoader}
    >
      <form onSubmit={formik.handleSubmit}>
        <InputCNO
          name="rejectReason"
          label={`Are you sure, you want to reject?`}
          formik={formik}
          placeholder={"Please provide reasons for rejection."}
          icon={mdiFormatText}
          fullWidth
          autoFocus
        />

        <FormActionButtons
          rightText="Reject"
          formik={formik}
          leftClick={onClose}
          closeMoreActions={closeMoreActions}
        />
      </form>
    </DialogCNO>
  );
}

export default AppointmentRejectDialog;
