import { makeStyles, Typography } from "@material-ui/core";
import { capitalizeAndBreak } from "../utils/Helpers";

const getValue = (value) => {

  if (typeof value === 'boolean') return capitalizeAndBreak(value.toString())

  if (typeof value === 'number' && value === 0) return '0';

  if (value == null || value === '') return '-';

  return value

}

const DetailsRow = ({ label, value, aligned = false }) => {

  const styles = useStyles({ aligned });

  return (
    <div className={styles.detailsRow}>
      <Typography className={styles.label}>{label}</Typography>
      <Typography className={styles.value}>{getValue(value)}</Typography>
    </div>
  )
};

export default DetailsRow;

const useStyles = makeStyles((theme) => ({
  detailsRow: {
    marginBottom: theme.spacing(2)
  },
  label: {
    color: theme.colors.FORM_LABEL_COLOR,
    fontSize: '0.9rem'
  },
  value: {
    marginLeft: ({ aligned }) => aligned ? 0 : theme.spacing(2),
    color: theme.colors.DIALOG_TITLE_COLOR,
  }
}));