import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import FormActionButtons from '../FormActionButtons';
import AndroidDownload from '../../assets/google_play_download.png';
import IosDownload from '../../assets/ios_app_store_download.png';

function TaskStartNotAllowedDialog({
  open,
  onClose
}) {

  const styles = useStyles();

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Start/Stop not allowed from web"
    >

      <Typography className={styles.content}>
        Start/Stop is allowed only from mobile application of CheckNdOut. Please open CheckNdOut mobile application and Start/Stop from it
      </Typography>
      <div className={styles.downlaodWrapper}>
        <a href="https://play.google.com/" target="_blank" rel="noreferrer">
          <img src={AndroidDownload} alt="" />
        </a>
        <a href="https://www.apple.com/app-store/" target="_blank" rel="noreferrer">
          <img src={IosDownload} alt="" />
        </a>
      </div>
      <FormActionButtons
        leftClick={onClose}
        wrapperStyles={{
          marginTop: "1rem"
        }}
        leftText="Ok"
      />

    </DialogCNO>
  )
}

export default TaskStartNotAllowedDialog

const useStyles = makeStyles((theme) => ({
  content: {
    // paddingTop: theme.spacing(1),
    fontSize: ({ dialogBP }) => dialogBP ? '0.8rem' : '1rem'
  },
  downlaodWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    '& a': {
      width: "30%"
    },
    '& img': {
      width: "100%"
    },
    '&>*:first-child': {
      marginRight: '1rem'
    }
  }
}))