import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container_MobileHeader: {
    position: "fixed",
    margin: "0 auto",
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    // justifyContent: "space-between",
    alignItems: 'center',
    height: 40,
    background: theme.colors.HEADER_BACKGROUND_COLOR,
    color: theme.colors.HEADER_TITLE_COLOR,
    padding: theme.spacing(1),
    zIndex: 1300,
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
  },
  title: {
    flex: 1,
    marginLeft: theme.spacing(2),
    color: theme.colors.HEADER_TITLE_COLOR,
  },
  appLogo: {
    width: 60
  }
}));
