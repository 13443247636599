import { makeStyles, MenuItem, Select, Typography, useMediaQuery } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateInvoice } from '../../actions/invoiceActions';
import DialogCNO from '../../library/DialogCNO';
import { getCategory } from '../../utils/Helpers';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import FormActionButtons from '../FormActionButtons';
import JobTypeIcon from '../JobTypeIcon/JobTypeIcon';
import * as yup from 'yup';
import InputCNO from '../../library/InputCNO';
import { mdiFormatText } from '@mdi/js';
import { InvoiceStatus } from '../../utils/mappings';
import RightsManager from '../../utils/RightsManager';
import { useDebouncedFormik } from '../../hooks';


const EditJobTypeDialog = ({
  open,
  onClose,
  invoice
}) => {

  const GlobalStyles = useGlobalStyles();

  let categories = localStorage.getItem('categories');
  categories = categories ? JSON.parse(categories) : [];

  const dispatch = useDispatch();
  const { allInvoiceLoader: { editLoader } } = useSelector(state => state.invoices);

  const formik = useFormik({
    initialValues: {
      categories: invoice.categories
    },
    validationSchema: yup.object({
      categories: yup.array().required()
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {

    console.log("Invoice edit values: ", values);

    const updateInvoicePayload = {
      ...invoice,
      categories: values.categories
    };

    dispatch(updateInvoice(updateInvoicePayload));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Job Type"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>
        <Select
          fullWidth
          variant="outlined"
          margin="dense"
          onChange={(e) => {
            console.log("upCat: ", e.target.value);
            formik.setFieldValue("categories", [e.target.value])
          }}
          value={formik.values.categories[0]}
          className={GlobalStyles.dropdown}
        >
          {
            categories.map((category) => {
              return <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
            })
          }
        </Select>
        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )

}

function InvoiceMainDetailsCard({ invoice }) {

  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const isCompanyAdmin = RightsManager.isGroupLeader();

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const category = getCategory(invoice.categories[0]);

  const [isJobTypeEditable, setIsJobTypeEditable] = useState(false);
  const [isJobMainEditable, setIsJobMainEditable] = useState(false);
  const [editableProperty, setEditableProperty] = useState(null);

  return (
    <div className={GlobalStyles.detailsCardContainer} style={{ display: 'flex' }}>
      <JobTypeIcon
        iconLink={category.iconLink}
        name={category.title}
        // onClick={() => setIsJobTypeEditable(true)}
        categoryId={category?.id}
      />
      <div className={styles.details}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', flexDirection: tillTablet ? 'column' : 'row' }}>
          <Typography
            variant='h6'
            className={GlobalStyles.cardTitle}
            onClick={() => {
              setIsJobMainEditable(true);
              setEditableProperty('title');
            }}
          >
            {invoice.title}
          </Typography>
          <Typography
            className={GlobalStyles.cardTitleHelper}
            onClick={() => {
              setIsJobMainEditable(true);
              setEditableProperty('invoiceNumber');
            }}
          >
            Invoice# {invoice.invoiceNumber}
          </Typography>
        </div>
        <pre
          className={styles.description}
          onClick={() => {
            setIsJobMainEditable(true);
            setEditableProperty('description');
          }}
        >
          {invoice.description || 'Invoice details not added'}
        </pre>
      </div>

      {
        isCompanyAdmin &&
        isJobTypeEditable &&
        <EditJobTypeDialog
          open={isJobTypeEditable}
          onClose={() => setIsJobTypeEditable(false)}
          invoice={invoice}
        />
      }

      {
        isCompanyAdmin &&
        isJobMainEditable &&
        <EditInvoiceMainDialog
          open={isJobMainEditable}
          onClose={() => setIsJobMainEditable(false)}
          invoice={invoice}
          editableProperty={editableProperty}
        />
      }

    </div>
  )
}

export default InvoiceMainDetailsCard;

const EditInvoiceMainDialog = ({
  open,
  onClose,
  invoice,
  editableProperty
}) => {

  const labels = {
    'title': 'Invoice Title',
    'description': 'Invoice Description',
    'invoiceNumber': 'Invoice Number',
  };

  const placeholder = {
    'title': 'Enter Invoice Title',
    'description': 'Enter Invoice Description',
    'invoiceNumber': 'Edit Invoice Number',
  };

  const dispatch = useDispatch();
  const { allInvoiceLoader: { editLoader } } = useSelector(state => state.invoices);

  const formik = useFormik({
    initialValues: {
      [editableProperty]: invoice[editableProperty]
    },
    validationSchema: yup.object({
      [editableProperty]: yup.string().required()
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleClose = () => {

    formik.resetForm();

    localStorage.removeItem(`editInvoice_${invoice.id}`);

    onClose();

  }

  const handleUpdate = (values) => {

    console.log("Invoice edit values: ", values);

    const updateInvoicePayload = {
      ...invoice,
      [editableProperty]: values[editableProperty]
    };

    const onAddSuccess = () => handleClose();

    dispatch(updateInvoice(updateInvoicePayload, false, onAddSuccess));

  };

  useDebouncedFormik(formik, `editInvoice_${invoice.id}`);

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Edit Invoice"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name={editableProperty}
          label={labels[editableProperty]}
          placeholder={placeholder[editableProperty]}
          formik={formik}
          error={formik.touched[editableProperty] && Boolean(formik.errors[editableProperty])}
          helperText={formik.errors[editableProperty]}
          icon={mdiFormatText}
          fullWidth
          multiline={editableProperty === 'description' ? true : false}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )

}

const useStyles = makeStyles((theme) => ({

  details: {
    marginLeft: theme.spacing(2),
    flex: 1
  },

  description: {
    fontSize: '14px',
    whiteSpace: "pre-wrap",
    minHeight: 15,
    color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR
  },

}));