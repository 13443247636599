import { Chip, Typography, TextField, useTheme, InputLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { mdiCheck, mdiCurrencyGbp, mdiHours24, mdiLockClock } from '@mdi/js';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMemberSuggest, resetSuggestions } from '../../actions/suggestionsActions';
import AutocompleteCNO from '../../library/AutocompleteCNO/AutocompleteCNO';
import ChipCNO from '../../library/ChipCNO';
import InputCNO from '../../library/InputCNO';
import { Currency, CurrencyIcon, RateType } from '../../utils/mappings';
import UserSuggestionOption from '../UserSuggestionOption/UserSuggestionOption';
import { useStyles } from './InternalDetailsFormStyles';
import * as icons from '@mdi/js';
import { getMaterialIconString } from '../../utils/icons';
import InputLabelCNO from '../../library/InputLabelCNO';

function InternalDetailsForm({ formik }) {

  const theme = useTheme();

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const dispatch = useDispatch();

  const suggestions = useSelector(state => state.suggestions);

  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? [JSON.parse(authUser)] : [];

  const assignedUser = [...formik.values.assignTo, ...authUser];

  const unSelectedSuggestions = () => suggestions.filter(item => !assignedUser.some(assign => assign.id === item.id));

  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.assignToWrapper}>
        <InputLabelCNO label={'Select Team Member'} />
        <Autocomplete
          className={styles.textField}
          classes={{
            paper: styles.paper,
            noOptions: styles.noOptions,
            popper: styles.popper
          }}
          multiple
          forcePopupIcon={false}
          options={unSelectedSuggestions()}
          closeIcon={() => null}
          getOptionLabel={option => option.title}
          renderOption={option => <UserSuggestionOption user={option} />}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.title}
                {...getTagProps({ index })}
                className={styles.chip}
              />
            ))
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" placeholder="Select Team Member" />
          )}
          onChange={(e, newValue) => {
            formik.setFieldValue("assignTo", newValue);
          }}
          onInputChange={(e) => {
            if (e && e.target.value) {
              dispatch(fetchMemberSuggest(e.target.value));
            }
          }}
          onBlur={() => {
            dispatch(resetSuggestions());
          }}
        />
      </div>
      <div className={styles.rateTypeWrapper}>
        <InputLabelCNO label={'Job Types'} />
        <div className={styles.rateTypeChips}>
          <ChipCNO
            icon={mdiLockClock}
            name="Fixed"
            size="1.5rem"
            active={formik.values.rateType === RateType.Fixed}
            onClick={() => formik.setFieldValue('rateType', RateType.Fixed)}
          />
          <ChipCNO
            icon={mdiHours24}
            name="Hourly"
            size="1.5rem"
            active={formik.values.rateType === RateType.Hourly}
            onClick={() => formik.setFieldValue('rateType', RateType.Hourly)}
          />
        </div>
      </div>
      <InputCNO
        name='jobPrice'
        label='Job Price'
        formik={formik}
        placeholder='Enter Job Rate'
        icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
        fullWidth
        onChange={(e) => {

          const value = e.target.value;

          const jobPriceValue = !isNaN(value) ? value : formik.values.jobPrice;

          formik.setFieldValue('jobPrice', value === "" ? "" : jobPriceValue);

        }}
      />
    </div>
  )
}

export default InternalDetailsForm
