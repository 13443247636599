import { useMediaQuery } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DialogCNO from '../library/DialogCNO';
import { getYoutubeEmbedUrl } from '../utils/Helpers';
import LocalStorageConstants from '../utils/LocalStorageConstants';

function QuickTutorialVideo() {

  const isLandingPagePath = useHistory().location.pathname === "/";

  const [isVideoModal, setIsVideoModal] = useState(false);
  const videoModalBP = useMediaQuery('(min-width:900px)');

  useEffect(() => {

    if (isLandingPagePath) {
      const hasShownLoginIntroVideo = LocalStorageConstants.getItem("hasShownLandingIntroVideo", false);

      setIsVideoModal(!hasShownLoginIntroVideo);
      LocalStorageConstants.setItem("hasShownLandingIntroVideo", true);
    } else {
      const hasShownLoginIntroVideo = LocalStorageConstants.getItem("hasShownLoginIntroVideo", false);

      setIsVideoModal(!hasShownLoginIntroVideo);
      LocalStorageConstants.setItem("hasShownLoginIntroVideo", true);
    }

  }, []);

  return (
    <Fragment>
      {
        isVideoModal &&
        <DialogCNO
          open={isVideoModal}
          onClose={() => setIsVideoModal(false)}
          height={"60vh"}
          width={videoModalBP ? '50vw' : "90vw"}
          isClickAwayClose={true}
          dialogTitle={"Introduction"}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <iframe
              width={'100%'}
              height="315"
              src={getYoutubeEmbedUrl("https://www.youtube.com/watch?v=TmbiK_bvUxo")}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </DialogCNO>
      }
    </Fragment>
  )
}

export default QuickTutorialVideo;