import { Paper, Typography, useMediaQuery } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiArrowRight, mdiCheck, mdiPlay, mdiProgressClock, mdiStop, mdiTruckCheck } from '@mdi/js';
import React, { useState } from 'react';
import { useStyles } from './UserStatusBarStyles';
import clsx from 'clsx';
import { TaskStatus } from '../../utils/mappings';
import TaskStartNotAllowedDialog from '../TaskStartNotAllowedDialog/TaskStartNotAllowedDialog';

function UserStatusBar({ item }) {

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const dialogBP = useMediaQuery('(max-width: 453px)');

  const buttonBP = useMediaQuery('(max-width: 550px)');

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const styles = useStyles({ tillTablet, dialogBP, buttonBP });
  return (
    <Paper className={styles.container}>
      <div
        className={styles.align}
        onClick={() => setOpen(true)}
      >
        <button
          style={{ padding: buttonBP ? '4px 5px' : '4px 15px' }}
          className={clsx({
            // [styles.statusTextStartActive]: item===1,
            [styles.statusTextInactive]: true
          })}>
          <Icon
            path={mdiPlay}
            size={buttonBP ? '1rem' : '1.3rem'}
            className={clsx({
              // [styles.statusIconStartActive]: item.status===TaskStatus.Started,
              [styles.statusIconInactive]: true
            })} />
          <Typography
            style={{ fontSize: buttonBP ? '0.6rem' : '0.88rem' }}
            component="span">Start</Typography>
        </button>
      </div>
      <Icon path={mdiArrowRight} size='1.2rem' />

      <div
        className={styles.align}
      >
        <button
          style={{ padding: buttonBP ? '4px 5px' : '4px 15px' }}
          className={clsx({
            [styles.statusTextProgressActive]: (item.status === TaskStatus.InProgress),
            [styles.statusTextInactive]: (item.status !== TaskStatus.InProgress),
          })}
        >
          <Icon
            path={mdiProgressClock}
            size={buttonBP ? '1rem' : '1.5rem'}
            // size="1.5rem"
            className={clsx({
              [styles.statusIconProgressActive]: item.status === TaskStatus.InProgress,
              [styles.statusIconInactive]: item.status !== TaskStatus.InProgress,
            })} />
          <Typography
            style={{ fontSize: buttonBP ? '0.6rem' : '0.88rem' }}
            component="pre">In Progess</Typography>
        </button>
      </div>

      <Icon path={mdiArrowRight} size='1.2rem' />
      <div
        className={styles.align}
        onClick={() => setOpen(true)}
      >
        <button
          style={{ padding: buttonBP ? '4px 5px' : '4px 15px' }}
          className={clsx({
            [styles.statusTextStopActive]: (item.status === TaskStatus.Done || item.status === TaskStatus.Started),
            [styles.statusTextInactive]: (item.status !== TaskStatus.Done),
          })}
        >
          <Icon
            path={mdiStop}
            size={buttonBP ? '1rem' : '1.5rem'}
            // size="1.5rem"
            className={clsx({
              [styles.statusIconStopActive]: item.status === TaskStatus.Done || item.status === TaskStatus.Started,
              [styles.statusIconInactive]: item.status !== TaskStatus.Done,
            })} />
          <Typography
            style={{ fontSize: buttonBP ? '0.6rem' : '0.88rem' }}
            component="span">Stop</Typography>
        </button>
        <button
          className={clsx({
            [styles.doneButtonActive]: item.status === TaskStatus.Done,
            [styles.doneButtonInactive]: item.status !== TaskStatus.Done
          })}
        >
          <Icon
            className={clsx({
              [styles.doneIconActive]: item.status === TaskStatus.Done,
              [styles.doneIconInactive]: item.status !== TaskStatus.Done
            })}
            path={mdiCheck}
            size="1.3rem"
          />
        </button>
      </div>

      {
        open &&
        <TaskStartNotAllowedDialog
          open={open}
          onClose={handleClose}
        />
      }
    </Paper>
  )
}

export default UserStatusBar
