import { Typography } from '@material-ui/core';
import { mdiCheck, mdiFormatText, mdiHours24, mdiLockClock } from '@mdi/js';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChipCNO from '../../library/ChipCNO';
import { JobTargetType, JobTargetTypeIcon, JobTargetTypeReverse, QuotationStatus } from '../../utils/mappings';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import * as yup from 'yup';
import { updateQuotation } from '../../actions/quotationActions';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons';
import { getMaterialIconString } from '../../utils/icons';
import * as icons from '@mdi/js';
import Icon from '@mdi/react';
import RightsManager from '../../utils/RightsManager';
import FieldHelperTextCNO from '../../library/FieldHelperTextCNO';

const EditTargetDialog = ({
  open,
  onClose,
  quotation,
}) => {

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { allQuotationLoader: { editLoader } } = useSelector(state => state.quotations);

  const formik = useFormik({
    initialValues: {
      target: quotation.target,
      targetType: quotation.targetType,
    },
    validationSchema: yup.object({
      target: yup.number().required('Target value is required.'),
      targetType: yup.string().required('Target type is required.'),
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {

    console.log("Quotation edit values: ", values);

    const updateQuotationPayload = {
      ...quotation,
      target: values.target ? values.target : 0,
      targetType: values.targetType
    };

    dispatch(updateQuotation(updateQuotationPayload));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Target"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <div>
          <Typography style={{ marginBottom: 8 }}>Job Target Types</Typography>
          <div className={GlobalStyles.chipsWrapper}>
            {
              Object.keys(JobTargetType).filter((t) => t !== 'None')
                .map((target) => (
                  <ChipCNO
                    icon={icons[getMaterialIconString(JobTargetTypeIcon[target])]}
                    name={target}
                    size="1.5rem"
                    active={formik.values.targetType === JobTargetType[target]}
                    onClick={() => formik.setFieldValue('targetType', JobTargetType[target])}
                  />
                ))
            }
          </div>
          <FieldHelperTextCNO
            showHelperText={Boolean(formik.errors.targetType)}
            helperText={formik.errors.targetType}
          />
        </div>

        <InputCNO
          name="target"
          label="Job Target"
          placeholder="Enter Job Target"
          formik={formik}
          error={formik.touched.target && Boolean(formik.errors.target)}
          helperText={formik.errors.target}
          icon={mdiFormatText}
          fullWidth
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
          disabled={!formik.dirty || !formik.isValid}
        />
      </form>
    </DialogCNO>
  )

}

function QuotationTargetDetails({ quotation }) {

  const GlobalStyles = useGlobalStyles();

  const [isTargetEditable, setIsTargetEditable] = useState(false);

  const isCompanyAdmin = RightsManager.isGroupLeader();

  return (
    <div className={GlobalStyles.detailsCardContainer}>
      <Typography className={GlobalStyles.detailsCardSectionTitle}>
        Target
      </Typography>
      <div className={GlobalStyles.detailsCardSection} onClick={() => setIsTargetEditable(true)}>
        <div className={GlobalStyles.detailsRow}>
          <Typography style={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              path={icons.mdiBullseyeArrow}
              size="1rem"
              style={{ marginRight: 4 }}
            />
            {quotation.target}
          </Typography>

          {
            quotation.targetType ?
              <ChipCNO
                icon={icons[getMaterialIconString(JobTargetTypeIcon[JobTargetTypeReverse[quotation.targetType]])]}
                name={JobTargetTypeReverse[quotation.targetType]}
                active={true}
              /> : null
          }
        </div>
      </div>

      {
        isCompanyAdmin &&
        isTargetEditable &&
        <EditTargetDialog
          open={isTargetEditable}
          onClose={() => setIsTargetEditable(false)}
          quotation={quotation}
        />
      }
    </div>
  )
}

export default QuotationTargetDetails
