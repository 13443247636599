import React from 'react'
import { ChipCNO, InputLabelCNO, MetaText } from '../../../../library'
import { DateTimePickerType, FormFieldsTypes } from '../../utils/enums'
import { Box } from '@material-ui/core'
import FormInput from './FormInput'

function DateTimeAttributes({ formik }) {

  console.log("DateTimeAttributes: ", formik.values, formik.errors)

  return (
    <>
      {
        formik.values.type === FormFieldsTypes.ids.DateTime ?
          <Box my={2} px={2} >

            <InputLabelCNO label='Date Time Picker Type' />
            <Box display='flex' alignItems='center' style={{ gap: 8 }} mt={1}>
              {
                DateTimePickerType.entities.map(pickerType => (
                  <ChipCNO
                    key={pickerType.id}
                    name={pickerType.title}
                    active={formik.values.attributes.dateTimePickerType === pickerType.id}
                    icon={pickerType.icon}
                    onClick={() => formik.setFieldValue('attributes.dateTimePickerType', pickerType.id)}
                  />
                ))
              }
            </Box>

            <Box>
              {
                [DateTimePickerType.ids.Date, DateTimePickerType.ids.DateTimeBoth].includes(formik.values.attributes.dateTimePickerType) &&
                <FormInput
                  label='Date Label'
                  placeholder='Enter data name...'
                  formik={formik}
                  nameKey='name.date'
                  labelKey='label.date'
                />
              }

              {
                [DateTimePickerType.ids.Time, DateTimePickerType.ids.DateTimeBoth].includes(formik.values.attributes.dateTimePickerType) &&
                <FormInput
                  label='Time Label'
                  placeholder='Enter time name...'
                  formik={formik}
                  nameKey='name.time'
                  labelKey='label.time'
                />
              }

            </Box>

          </Box>
          : null
      }
    </>
  )
}

export default DateTimeAttributes