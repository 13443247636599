import { alpha, Button, makeStyles, Typography, useTheme } from '@material-ui/core';
import { mdiAccountMultiple } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { EventResponseActionStatus } from '../../../../utils/mappings';
import RightsManager from '../../../../utils/RightsManager';
import { useGlobalStyles } from '../../../../utils/Styles/GlobalStyles';
import { getAttendeesGroupedBasedOnStatus } from '../../helpers';
import { EditEventAttendees, EventAttendeesList } from '../../modals';
import { useEventDetailsFBStyles } from './EventDetails.styles';

const EventAttendiesDetailsCard = ({ event }) => {

  const isUserAdmin = RightsManager.isAdmin();

  const theme = useTheme();

  const EventDetailsFBStyles = useEventDetailsFBStyles();
  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const groupedAttendeesByResponse = getAttendeesGroupedBasedOnStatus(event.attendees);
  const goingAttendees = groupedAttendeesByResponse[EventResponseActionStatus.ids.Going] || [];
  const interestedAttendees = groupedAttendeesByResponse[EventResponseActionStatus.ids.Interested] || [];

  const [eventAttendeeList, setEventAttendeeList] = useState(EventResponseActionStatus.ids.None);
  const [isInviteAttendees, setIsInviteAttendees] = useState(false);

  return (
    <div className={EventDetailsFBStyles.card}>
      <div className={styles.row}>
        <div
          className={styles.row}
          onClick={() => setEventAttendeeList(EventResponseActionStatus.ids.Going)}
        >
          <Icon path={mdiAccountMultiple} size="1.4rem" color={theme.colors.JOB_DETAIL_SECTION_TEXT_COLOR} />
          <Typography className={styles.bodyText}>{event.attendees.length} people responded</Typography>
        </div>

        <Button
          onClick={() => {
            if (!isUserAdmin) return;
            setIsInviteAttendees(true);
          }}
          className={GlobalStyles.orangeButton}
        >
          Invite
        </Button>

      </div>

      <div className={styles.countBoxWrapper}>

        <div
          className={styles.countBox}
          onClick={() => setEventAttendeeList(EventResponseActionStatus.ids.Interested)}
        >
          <Typography className={styles.countNumber}>
            {interestedAttendees.length}
          </Typography>
          <Typography className={styles.countText}>
            Interested
          </Typography>
        </div>

        <div
          className={styles.countBox}
          onClick={() => setEventAttendeeList(EventResponseActionStatus.ids.Going)}
        >
          <Typography className={styles.countNumber}>
            {goingAttendees.length}
          </Typography>
          <Typography className={styles.countText}>
            Going
          </Typography>
        </div>

      </div>

      {
        Boolean(eventAttendeeList) &&
        <EventAttendeesList
          open={eventAttendeeList}
          onClose={() => setEventAttendeeList(EventResponseActionStatus.ids.None)}
          goingAttendees={goingAttendees}
          interestedAttendees={interestedAttendees}
        />
      }

      {
        isInviteAttendees &&
        isUserAdmin &&
        <EditEventAttendees
          open={isInviteAttendees}
          onClose={() => setIsInviteAttendees(false)}
          event={event}
        />
      }

    </div>
  )
}

export default EventAttendiesDetailsCard;

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },

  bodyText: {
    fontSize: '1rem',
    color: theme.colors.JOB_DETAIL_SECTION_TEXT_COLOR,
    marginLeft: 8
  },

  countBoxWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    justifyContent: 'center'
  },

  countBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 120,
    height: 120,
    color: theme.colors.JOB_DETAIL_SECTION_TEXT_COLOR,
    cursor: 'pointer',
    borderRadius: 8,
    '&:hover': {
      background: alpha("#6e6e6e", 0.1)
    }
  },

  countNumber: {
    fontSize: '2rem'
  },

  countText: {
    fontSize: '1.2rem'
  }

}));