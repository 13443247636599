import { Divider, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

function FormBox({
  title,
  renderTitle,
  children,
  titleDivider = false,
  bgColor,
  titleColor,
  className = '',
  style,
}) {
  const styles = useStyles({ bgColor, title, titleColor });
  return (
    <div className={clsx(styles.container, className)} style={style}>
      {title && !renderTitle && (
        <Typography className={styles.title}>{title}</Typography>
      )}
      {renderTitle ? renderTitle() : null}
      {titleDivider && <Divider />}
      {children}
    </div>
  );
}

export default FormBox;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: ({ title }) => (title ? 8 : theme.spacing(2)),
    background: ({ bgColor }) =>
      bgColor ? bgColor : theme.colors.FORM_SECTION_BACKGROUND_COLOR,
    borderRadius: 10,
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.disabled}`
  },
  title: {
    fontSize: "0.9rem",
    color: ({ titleColor }) =>
      titleColor ? titleColor : theme.colors.FORM_SECTION_TITLE_COLOR,
    marginLeft: -8,
    marginBottom: 10,
  },
}));
