import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';

function StickyWrapper({ children, containerStyle = {}, wrapperStyle = {} }) {

  const theme = useTheme();
  const tillTablet = useMediaQuery('(max-width: 768px)');

  return (
    <div style={{
      position: 'sticky',
      top: tillTablet ? 40 : 68,
      ...(tillTablet ? { marginLeft: -7 } : {}),
      ...(tillTablet ? { marginRight: -7 } : {}),
      borderRadius: 5,
      zIndex: 1200,
      gap:5,
      ...containerStyle
    }}>
      <div style={{
        background: theme.colors.FILTER_BAR_BACKGROUND_COLOR,
        borderRadius: 5,
        paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '0.4rem',
        ...wrapperStyle
      }}>
        {children}
      </div>
    </div >
  )
}

export default StickyWrapper