import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

  container_FakeSubTaskCard: {
    background: theme.colors.SUBTASK_CARD_BACKGROUND_COLOR,
    marginTop: 10,
    padding: '16px 8px',
    borderRadius: 5,
    position: 'relative'
  },

  blurEffectContainer: {
    position: 'absolute',
    top: 60, bottom: 0, right: 0, left: 0,
    zIndex: 2,
    backdropFilter: 'blur(3px)',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },

  loadMoreFromServerLink: {
    color: theme.colors.LINK_COLOR_GREY,
    fontSize: '0.8rem',
    border: `1px solid ${theme.colors.LINK_COLOR_GREY}`,
    borderRadius: 4,
    padding: '4px 8px'
  },

  subTaskCardTitleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },

  expandableDetailsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.8rem'
  },

  moreSubTaskDetailsLink: {
    color: theme.colors.LINK_COLOR,
    textDecoration: 'underline',
    textAlign: 'right'
  }

}));