import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { mdiAccountDetails, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { deleteEvent } from '../../../actions/eventActions';
import ConfirmationDialogCNO from '../../../library/ConfirmationDialogCNO';
import FullScreenLoaderCNO from '../../../library/FullScreenLoaderCNO';
import LinkCNO from '../../../library/LinkCNO';
import RightsManager from '../../../utils/RightsManager';
import { useGlobalStyles } from '../../../utils/Styles/GlobalStyles';

function EventListCardPopover({ event, closeMoreActions }) {

  const isUserAdmin = RightsManager.isAdmin();

  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const isEventDetails = useLocation().pathname.includes("/event/");

  const dispatch = useDispatch();
  const { eventsLoader, deleteEventLoader } = useSelector(state => state.events);

  const history = useHistory();

  const [isConfirmDeleteDialog, setIsConfirmDeleteDialog] = useState(false);

  return (
    <div className={styles.container_MorePopover}>

      <FullScreenLoaderCNO loading={eventsLoader || deleteEventLoader} />

      <List className={GlobalStyles.list}>

        {
          !isEventDetails &&
          <LinkCNO
            to={`event/${event.id}`}
          >
            <ListItem className={GlobalStyles.listItem}>
              <ListItemText primary='Details' />
              <ListItemIcon>
                <IconButton edge='end'>
                  <Icon path={mdiAccountDetails} size='1.5rem'
                    color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </LinkCNO>
        }

        {
          isUserAdmin &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setIsConfirmDeleteDialog(true);
            }}
          >
            <ListItemText primary='Delete' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiTrashCan} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }
      </List>

      {
        isConfirmDeleteDialog &&
        <ConfirmationDialogCNO
          open={isConfirmDeleteDialog}
          onClose={() => setIsConfirmDeleteDialog(false)}
          dialogTitle="Comfirm Delete Event"
          messages={["Are you sure you want to delete?"]}
          yesClick={async () => {

            const onSuccess = () => {
              if (isEventDetails) {
                history.push('/events');
              }
            };

            dispatch(deleteEvent(event, { onSuccess }));

          }}
          rightText="Delete"
          closeMoreActions={closeMoreActions}
        />
      }

    </div >
  )
}

export default EventListCardPopover

const useStyles = makeStyles(theme => ({
  container_MorePopover: {
    padding: 5,
    overflow: 'auto',
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR
  },
}));