import { makeStyles } from "@material-ui/core";

const icon = {
    borderRadius: '50%',
    padding: 3,
    // background: '#1e1e1e',
    // background: 'orange',
    color: 'white'
}

export const useStyles = makeStyles((theme) => ({
    container_NotificationTypeIcon: {

    },
    progressIcon: {
        ...icon,
        color: ({ color }) => color
    },
}))