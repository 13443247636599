import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getMediasUploadingLoaders,
  getMediaUploadItemsKey,
  setMediasUploadingLoaders,
} from "../utils/MediaUpload";
import { useBrowserTabClose } from "./useBrowserTabClose";
import { useMediasPendingExistForCurrrentItem } from "./useMediasPendingExistForCurrrentItem";

export const useMediasPendingUploadingForCurrrentItem = () => {
  const { id } = useParams();

  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const onStorageChange = () => {
      const loaders = getMediasUploadingLoaders();

      setUploading(loaders ? loaders[id] : false);
    };

    window.addEventListener("medias-uploading-loaders", onStorageChange);

    onStorageChange();

    return () => {
      window.removeEventListener("medias-uploading-loaders", onStorageChange);
    };
  }, []);

  // useBrowserTabClose(() => {
  //   const key = getMediaUploadItemsKey("loaders");
  //   localStorage.removeItem(key);
  // })

  return uploading;
};
