import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme } from '@material-ui/core';
import { mdiBrightness4, mdiBrightness6, mdiChevronDown, mdiPalette } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import CustomThemeColorPickerSection from '../../components/CustomThemeSection/CustomThemeColorPickerSection';
import FormBox from '../../components/FormBox/FormBox';
import { useStyles } from '../../components/Settings/SettingsStyles';
import ChipCNO from '../../library/ChipCNO';
import { useThemeStore } from '../../providers/ThemeProvider';
import { ThemeType } from '../../utils/mappings';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';

function AppearanceExpandable() {

  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const [isAppearanceExpanded, setIsAppearanceExpanded] = useState(false);

  const { themeState, dispatchTheme } = useThemeStore();
  const { theme: themeType } = themeState;

  const updateTheme = (themeToSet) => {
    dispatchTheme({
      type: "TOGGLE_THEME",
      payload: themeToSet
    });
    setPickerSectionVisible(false);
  };

  const [pickerSectionVisible, setPickerSectionVisible] = useState(false);

  const togglePickerSectionVisible = () => {
    updateTheme(ThemeType.Custom);
    setPickerSectionVisible((prev) => !prev);
  }


  console.log({ pickerSectionVisible });

  return (
    <Accordion
      expanded={isAppearanceExpanded}
      className={GlobalStyles.accordion}
    >
      <AccordionSummary
        expandIcon={<Icon
          path={mdiChevronDown}
          size="1.2rem"
          color={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR} />
        }
        className={GlobalStyles.accordionSummary}
        style={{ flexDirection: 'row' }}
        onClick={async (e) => {
          e.stopPropagation();
          setIsAppearanceExpanded(!isAppearanceExpanded);
        }}
      >
        Appearance
      </AccordionSummary>
      <AccordionDetails>
        <Box flex={1}>
          <div className={styles.themeWrapper}>
            <ChipCNO
              icon={mdiBrightness4}
              name="Dark"
              active={themeType === ThemeType.Dark && !pickerSectionVisible}
              onClick={() => updateTheme('dark')}
            />

            <ChipCNO
              icon={mdiBrightness6}
              name="Light"
              active={themeType === ThemeType.Light && !pickerSectionVisible}
              onClick={() => updateTheme('light')}
            />

            <ChipCNO
              icon={mdiPalette}
              name="Custom"
              active={themeType === ThemeType.Custom}
              onClick={togglePickerSectionVisible}
            />

          </div>

          <CustomThemeColorPickerSection
            isVisible={pickerSectionVisible}
            themeType={themeType}
            updateTheme={updateTheme}
          />

        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default AppearanceExpandable
