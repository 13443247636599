import { Button, makeStyles, Typography, useTheme } from '@material-ui/core';
import { mdiAttachment, mdiFormatText, mdiPencil, mdiContentSave } from '@mdi/js';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuotationStatus } from '../../actions/quotationActions';
import { EmailPreTextType, QuotationStatus, EmailPreTextTypeReverse } from '../../utils/mappings';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import { fetchCustomerSuggest } from '../../actions/suggestionsActions';
import InputLabelCNO from '../../library/InputLabelCNO';
import { getAppointmentSendSummaryHtmlString, getInvoiceSendSummaryHtmlString, getQuotationSendSummaryHtmlString } from '../../pdfTemplates/templates';
import { setQuotationItems } from '../../actions/quotationItemActions';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import RightsManager from '../../utils/RightsManager';
import Icon from '@mdi/react';
import QuotationEmailMessageEdit from './QuotationEmailMessageEdit';
import { defaultMessageTop } from '../../pdfTemplates/templates/Quotation/QuotationSendSummaryTemplateString';
import { setCustomerById } from '../../actions/customerActions';
import mailgo from 'mailgo';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import SelectCNO from '../../library/SelectCNO';

const mailgoConfig = {
  dark: true,
  showFooter: false,
};

function QuotationSendDialog({ open, onClose, quotation, closeMoreActions }) {

  const isUserAdmin = RightsManager.isAdmin();

  const theme = useTheme();

  const styles = useStyles();

  const dispatch = useDispatch();
  const { allQuotationLoader: { editLoader } } = useSelector(state => state.quotations);

  const suggestions = useSelector(state => state.suggestions);
  const { customerById } = useSelector(state => state.customers);

  const { quotationItems } = useSelector(state => state.quotationItems);

  const [isEmailMessageEdit, setIsEmailMessageEdit] = useState(false);

  const authUserCompany = LocalStorageConstants.getItem("authUserCompany");

  const validationSchema = yup.object({
    to: yup.object().required('Sender is required').nullable(),
    subject: yup.string().required('Subject is required'),
    message: yup.string(),
    attachment: yup.object()
  });

  const formik = useFormik({
    initialValues: {
      to: {},
      subject: quotation.title,
      emailData: "",
      message: "",
      attachment: {},
      emailPreTextType: EmailPreTextType.Quotation
    },
    validationSchema,
    onSubmit: (values) => handleSend(values)
  });

  useEffect(() => {
    mailgo(mailgoConfig);
  }, []);

  useEffect(() => {

    const emailData = authUserCompany.offerEmailTemplate;

    const messageSummary = getQuotationSendSummaryHtmlString(quotation, quotationItems, emailData);

    formik.setValues({
      ...formik.values,
      to: {
        id: quotation.customerId,
        title: quotation.customerText,
        email: customerById ? customerById.email : null
      },
      subject: quotation.title,
      emailData: emailData,
      message: messageSummary,
      attachment: {
        title: `${quotation.title}.pdf`,
      }
    });
  }, [quotation, customerById]);

  useEffect(() => {

    let emailData = authUserCompany.offerEmailTemplate;

    switch (formik.values.emailPreTextType) {
      case EmailPreTextType.Quotation:
        emailData = authUserCompany.offerEmailTemplate;
        break;

      case EmailPreTextType.Invoice:
        emailData = authUserCompany.invoiceEmailTemplate;
        break;

      case EmailPreTextType.Appointment:
        emailData = authUserCompany.appointmentEmailTemplate;
        break;

      default:
        break;
    }

    let messageSummary = getQuotationSendSummaryHtmlString(quotation, quotationItems, emailData);
    formik.setFieldValue('message', messageSummary);
    formik.setFieldValue('emailData', emailData);

  }, [formik.values.emailPreTextType]);

  const handleSend = (values) => {

    const updatePayload = {
      ...quotation,
      status: QuotationStatus.Sent,
      meta: values.message,
    };

    dispatch(updateQuotationStatus(updatePayload));

    handleClose();

  };

  const handleClose = () => {
    formik.resetForm();
    dispatch(setCustomerById(null));
    // dispatch(setQuotationItems([]));
    onClose();

    if (closeMoreActions) {
      closeMoreActions();
    }
  };

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Send Quotation"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit} className={styles.form}>

        <AutocompleteCNO
          name='to'
          label='Contact Person'
          formik={formik}
          placeholder='Search Customer'
          // fullWidth
          data={suggestions}
          optionLabel={x => x.title || ""}
          renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
          onSuggestionClick={(value) => {
            formik.setFieldValue('customer', value);
          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchCustomerSuggest(value));
            }
          }}
          disabled={true}
        />

        <InputCNO
          name='subject'
          label='Subject'
          formik={formik}
          placeholder={"Enter subject"}
          icon={mdiFormatText}
          fullWidth
        />

        {/* <InputCNO
          name='subject'
          label='Attachment'
          formik={formik}
          placeholder={"Enter subject"}
          icon={mdiAttachment}
          fullWidth
          disabled
          value={formik.values.attachment.title}
        /> */}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <InputLabelCNO label={'Message'} />

          <Fragment>

            {
              isUserAdmin &&
              <SelectCNO
                formik={formik}
                name="emailPreTextType"
                options={Object.values(EmailPreTextType)
                  .filter(item => item !== EmailPreTextType.Appointment)
                  .map(item => ({ value: item, label: EmailPreTextTypeReverse[item] }))
                }
              />
            }

            {
              isUserAdmin &&
              <Button
                style={{
                  background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
                  color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
                  borderColor: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
                  fontSize: '0.7rem',
                  fontWeight: 700,
                  marginLeft: 8
                }}
                startIcon={<Icon path={mdiPencil} size="1rem" />}
                onClick={() => setIsEmailMessageEdit(true)}
              >
                Edit Message
              </Button>
            }
          </Fragment>

        </div>
        <div
          dangerouslySetInnerHTML={{ __html: formik.values.message }}
          style={{ background: 'white', padding: "20px 10px", borderRadius: 5 }}
        />

        <FormActionButtons
          rightText="Send"
          formik={formik}
          leftClick={handleClose}
          closeMoreActions={closeMoreActions}
        />
      </form>

      {
        isEmailMessageEdit &&
        <QuotationEmailMessageEdit
          open={isEmailMessageEdit}
          onClose={() => setIsEmailMessageEdit(false)}
          emailData={formik.values.emailData}
          onSave={({ message }) => {
            const messageSummary = getQuotationSendSummaryHtmlString(quotation, quotationItems, message);
            formik.setFieldValue('emailData', message);
            formik.setFieldValue('message', messageSummary);
          }}
        />
      }

    </DialogCNO>
  )
}

export default QuotationSendDialog


const useStyles = makeStyles((theme) => ({

  form: {
    '&>*': {
      marginBottom: theme.spacing(2)
    }
  }

}));