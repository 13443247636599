import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useState } from 'react';
import DialogCNO from '../../library/DialogCNO/DialogCNO';
import { useStyles } from './AddToGroupChatModalStyles';
import FormActionButtons from '../FormActionButtons';
import InputCNO from '../../library/InputCNO';
import { mdiAccountGroup, mdiCamera, mdiFormatText, mdiImagePlus } from '@mdi/js';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { assignUserToChatGroup, createChatGroup } from '../../actions/chatActions';
import ReactImageUploading from 'react-images-uploading';
import { Avatar, Badge, useTheme } from '@material-ui/core';
import Icon from '@mdi/react';
import { ApiCore } from '../../api/utils/core';
import AvatarCNO from '../../library/AvatarCNO/AvatarCNO';
import Upload from 'rc-upload';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';

function AddToGroupChatModal({ open, onClose, closeCreateChatGroup, selectedUsers, mode, closeMoreActions }) {

  const dispatch = useDispatch();

  const theme = useTheme();


  const {
    activeChatUser,
    universalLoader,
    loaders: { createGroupLoader }
  } = useSelector(state => state.chats);

  console.log("Assign user: ", selectedUsers);
  console.log("activeChatUser: ", activeChatUser);

  const [loading, setLoading] = useState(false);

  const handleAddToGroup = (values) => {

    if (mode === 'edit') {
      const addUserToGroupPayload = selectedUsers.map((user) => ({
        "MemberId": user.id,
        "MessageGroupId": activeChatUser.id
      }));

      const userPayload = selectedUsers.map((user) => ({
        firstName: user.firstName,
        id: v4(),
        isAdmin: false,
        lastName: user.lastName,
        memberId: user.id,
        profilePic: null,
      }));

      dispatch(assignUserToChatGroup(addUserToGroupPayload, userPayload));
    }
    else {
      const addToGroupPayload = {
        "title": values.chatGroupName,
        "profilePic": values.chatGroupAvatar,
        "members": selectedUsers.map(user => (
          {
            memberId: user.id
          }
        ))
      };
      // console.log("addToGroupPayload: ", addToGroupPayload);
      dispatch(createChatGroup(addToGroupPayload, image));
    }

    onClose();
    closeCreateChatGroup();
    formik.resetForm();
  };

  const validationSchema = yup.object({
    chatGroupName: yup.string().required("Group name is required!")
  });

  const formik = useFormik({
    initialValues: {
      chatGroupName: mode === 'edit' ? activeChatUser.contactName : "",
      chatGroupAvatar: ""
    },
    validationSchema,
    onSubmit: (values) => handleAddToGroup(values)
  });

  // converts base64 image to blob
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const processAndUploadImage = async (base64file) => {
    setLoading(true);
    const imageId = v4();
    const imageURL = base64file;

    // Split the base64 string in data and contentType
    const block = imageURL.split(";");
    // Get the content type of the image
    const contentType = block[0].split(":")[1];
    // get the real base64 content of the file
    const realData = block[1].split(",")[1];

    const blob = b64toBlob(realData, contentType);

    const url = "messagegroup/initupload";
    const apiGroupInitUpload = new ApiCore({
      post: true,
      url: url
    });

    const initRes = await apiGroupInitUpload.post({ "ids": [imageId] });

    // UPLOAD
    const mRes = await fetch(initRes.data.links[0].m, { method: 'PUT', body: blob });
    const tRes = await fetch(initRes.data.links[0].t, { method: 'PUT', body: blob });
    const bRes = await fetch(initRes.data.links[0].b, { method: 'PUT', body: blob });

    //further upload processing will come here
    formik.setFieldValue("chatGroupAvatar", imageId);

    setImage(imageURL);
    setLoading(false);

  };

  const [image, setImage] = useState(null);
  console.log("imageegg: ", image);

  const processMediaUpload = (file) => {

    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      // console.log("File Reader image Result: ", reader.result);
      processAndUploadImage(reader.result);
    };

    reader.onerror = function (error) {
      toast.error("Error in uploading profile");
      console.log('Error: ', error);
    };
  };

  const uploadProps = {
    beforeUpload: (file) => {
      if (file.type.includes('image')) {
        return true;
      }
      toast.error("Unsupported file");
      return false;
    },
    action: (file) => {
      console.log("Upload Action file: ", file);
      processMediaUpload(file);
    },
    multiple: false,
    onStart(file) {
      console.log('onStart Upload', file);
    },
    onSuccess(ret, file, xhr) {
      console.log('onSuccess Upload', ret, file, xhr);
    },
    onError(err, res, file) {
      console.log('onError Upload', err);
    },
  };

  console.log("chatgroupavatar: ", formik.chatGroupAvatar);

  const styles = useStyles();

  const handleClose = () => {

    onClose();

    if (closeMoreActions) {
      closeMoreActions();
    }
  };

  return (
    <DialogCNO
      open={open}
      dialogTitle="Add New Group"
      onClose={handleClose}
      loading={universalLoader || createGroupLoader}
    >
      <form
        onSubmit={formik.handleSubmit}
        className={styles.form}
      >
        <Badge
          className={styles.avatarBadge}
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={mode !== 'edit' ? (
            <div className={styles.pickers}>
              <Icon
                path={mdiCamera}
                size="1.5rem"
                color={theme.colors.IMAGE_CAMERA_ICON_COLOR}
                style={{ visibility: 'hidden' }}
              />
              <Upload {...uploadProps} accept="image/*">
                <Icon
                  path={mdiImagePlus}
                  size="1.5rem"
                  color={theme.colors.IMAGE_UPLOAD_ICON_COLOR}
                />
              </Upload>
            </div>
          ) : ""}
        >
          {
            loading
              ? <CircularProgress style={{
                // border: '2px solid #c2abf7',
                padding: '20px',
                borderRadius: '50%',
                width: '5rem',
                height: '5rem'
              }} />
              : <AvatarCNO
                src={!image && mode === 'edit' ? activeChatUser.profilePic : image}
                fallbackIcon={mdiAccountGroup}
                iconSize="5rem"
                avatarStyle={{ border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}` }}
              />
          }
        </Badge>

        <InputCNO
          formik={formik}
          name="chatGroupName"
          placeholder="Enter Group Name"
          label="Group Name"
          icon={mdiFormatText}
          disabled={mode === 'edit'}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          disabled={!formik.isValid || !formik.values?.chatGroupName?.length || loading}
          closeMoreActions={closeMoreActions}
        />
      </form>
    </DialogCNO>
  )
}

export default AddToGroupChatModal
