import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme
} from '@material-ui/core';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { useCustomerEngagemenStyles } from './CustomerEngagementDetailsCard';
import clsx from 'clsx';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setJobSearchManager, setTodos } from '../../actions/jobActions';
import JobListingsInfinite from '../JobListingsInfinite/JobListingsInfinite';
import { mdiCalendarPlus, mdiChevronDown } from '@mdi/js';

function CustomerJobsSection({ profile }) {

  const theme = useTheme();
  const styles = useCustomerEngagemenStyles();
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { jobs, todosLoader } = useSelector(state => state.jobs);

  console.log("=======customerjobstab====", { jobs, todosLoader, profile });

  const [isSectionExpanded, setIsSectionExpanded] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    return () => {
      setPage(0);
      setIsSectionExpanded(false);
    };
  }, []);

  return (
    <div style={{ padding: 0 }}>
      <Accordion
        expanded={isSectionExpanded}
        className={clsx(GlobalStyles.accordion, styles.accordion)}
      >
        <AccordionSummary
          expandIcon={<Icon
            path={mdiChevronDown}
            size="1.2rem"
            color={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR} />
          }
          className={GlobalStyles.accordionSummary}
          onClick={async (e) => {
            e.stopPropagation();

            const isExpanded = !isSectionExpanded;

            setIsSectionExpanded(isExpanded);

            if (isExpanded && !jobs.length) {
              const searchParams = {
                customer: { id: profile.id },
                startDate: null,
                endDate: null,
                assignedUsers: null,
                categories: [],
                searchText: "",
                statuses: []
              };

              console.log("=======customerjobstab==searchParams==", searchParams);

              dispatch(setJobSearchManager(searchParams));
            }

          }}
        >
          <Icon path={mdiCalendarPlus} size='1.3rem' className={styles.accordionTitleIcon} />
          <Typography>Jobs</Typography>
        </AccordionSummary>

        {
          isSectionExpanded ? (
            <AccordionDetails className={styles.accordionDetails}>
              <div className={styles.grid}>
                {
                  jobs.length
                    ? <div className={styles.listingWrapper}>
                      <JobListingsInfinite page={page} setPage={setPage} customer={profile} />
                    </div>
                    : <Typography className={styles.loader}>
                      {todosLoader ? "Loading..." : 'No jobs found'}
                    </Typography>
                }
              </div>
            </AccordionDetails>
          ) : (null)
        }

      </Accordion>
    </div>
  );
}

export default CustomerJobsSection;
