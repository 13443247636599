import {
  Dialog,
  makeStyles,
  Typography,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useRef, useEffect, useState } from "react";
import ChipCNO from "../../library/ChipCNO";
import {
  mdiCircleOutline,
  mdiDoctor,
  mdiEmail,
  mdiFormatText,
  mdiGenderFemale,
  mdiGenderMale,
  mdiHumanFemale,
  mdiHumanMale,
  mdiPlus,
  mdiCalendar,
  mdiSkype,
  mdiFacebook,
  mdiTwitter,
  mdiLinkedin,
  mdiAccountTie,
} from "@mdi/js";
import InputCNO from "../../library/InputCNO";
import AutocompleteCNO from "../../library/AutocompleteCNO";
import Icon from "@mdi/react";
import "react-phone-number-input/style.css";
import AddCompany from "../AddCompany/AddCompany";
import HeadingBar from "../HeadingBar/HeadingBar";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import { SnapList, SnapItem, useDragToScroll } from "react-snaplist-carousel";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerCompanySuggest } from "../../actions/suggestionsActions";
import { Gender, Salutation } from "../../utils/mappings";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import {
  toggleAddCustomerDialog,
  addCustomer,
  setSearchedCustomers,
  searchCustomers,
} from "../../actions/customerActions";
import { DatePicker } from "@material-ui/pickers";
import GooglePlaceInputCNO from "../../library/GooglePlaceInputCNO/GooglePlaceInputCNO";
import AlreadyExistCustomerList from "../AlreadyExistCustomerList/AlreadyExistCustomerList";
import FormBox from "../FormBox";
import { getGenderBasedOnSalutation } from "../../utils/Helpers";
import PhoneInputCNO from "../../library/PhoneInputCNO";
import { useDebouncedFormik, useFormikErrors } from "../../hooks";
import { toggleAddCompanyDialog } from "../../actions/customerCompanyActions";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";

function AddCustomer({ onAdd, jobLocation }) {
  const tillTablet = useMediaQuery("(max-width: 767px)");

  const theme = useTheme();
  const styles = useStyles({ tillTablet });
  const GlobalStyles = useGlobalStyles();

  const prevValuesRef = useRef(null);

  const dispatch = useDispatch();
  const suggestions = useSelector((state) => state.suggestions);

  const {
    allCustomersLoader: { addLoader },
    isAddCustomerDialog,
    searchedCustomers,
  } = useSelector((state) => state.customers);

  const [isAlreadyExistCustomer, setIsAlreadyExistCustomer] = useState(false);
  const [isUserSelectedFromList, setIsUserSelectedFromList] = useState(false);

  const [isMoreDetailsExpanded, setIsMoreDetailsExpanded] = useState(false);

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  useEffect(() => {
    return () => {
      setIsAlreadyExistCustomer(false);
      formik.resetForm();
      dispatch(setSearchedCustomers([]));
    };
  }, []);

  useEffect(() => {
    setIsAlreadyExistCustomer(searchedCustomers?.length ? true : false);
  }, [searchedCustomers]);

  const handleAddCompanyDialog = () => {
    dispatch(toggleAddCompanyDialog(true));
  };

  const handleClose = () => {
    localStorage.removeItem("addCustomer");

    dispatch(toggleAddCustomerDialog(false));
  };

  const onAddSuccess = (customerObj) => {
    if (onAdd) onAdd(customerObj);

    handleClose();

    formik.resetForm();
  };

  const handleAddCustomer = async (values) => {
    console.log("handleAddCustomer=====values==: ", values);

    const customerObj = {
      salutation: values.salutation,
      firstName: values.firstName,
      lastName: values.lastName,
      gender: values.gender,
      birthday: values.birthday,
      abstract: values.abstract,
      email: values.email,
      mobile: values.mobile,
      mapAddress: values.mapAddress,
      lat: values.lat,
      lng: values.lng,
      skype: values.skype,
      facebook: values.facebook,
      twitter: values.twitter,
      linkedIn: values.linkedIn,
      customerCompanyId: values.company ? values.company.id : null,
    };

    console.log("handleAddCustomer===AddCompany==customerObj==: ", customerObj);

    if (isUserSelectedFromList) {
      onAddSuccess(customerObj);
    } else {
      await dispatch(addCustomer(customerObj, onAddSuccess));
    }
  };

  const validationSchema = yup.object({
    salutation: yup.string().required("Salutation is required."),
    firstName: yup.string().required("First Name is required."),
    lastName: yup.string().required("Last Name is required."),
    birthday: yup.object().nullable(),
    gender: yup.string(),
    abstract: yup.string(),
    email: yup
      .string()
      .email("Please enter valid email.")
      .typeError("Please enter valid email."),
    mobile: yup.string(),
    mapAddress: yup.string().required("Address is required.").nullable(),
    lat: yup.string(),
    lng: yup.string(),
    skype: yup.string(),
    facebook: yup.string(),
    twitter: yup.string(),
    linkedIn: yup.string(),
    company: yup.object(),
  });

  const formik = useFormik({
    initialValues: {
      salutation: "",
      firstName: "",
      lastName: "",
      gender: Gender.Others,
      birthday: new Date(),
      abstract: "",
      email: "",
      mobile: "",
      mapAddress: jobLocation ? jobLocation : null,
      lat: "",
      lng: "",
      skype: "",
      facebook: "",
      twitter: "",
      linkedIn: "",
      company: "",
    },
    validationSchema,
    onSubmit: (values) => handleAddCustomer(values),
  });

  useFormikErrors(formik);
  useDebouncedFormik(formik, "addCustomer");

  const checkIfCustomerAlreadyExist = () => {
    const { firstName, lastName } = formik.values;
    const searchText = `${firstName} ${lastName}`.trim();

    const dataToSend = {
      searchText: searchText,
    };

    if (!searchText) {
      dispatch(setSearchedCustomers([]));
      return;
    }

    dispatch(searchCustomers(dataToSend));
  };

  return (
    <>
      <AddCompany
        onAdd={(company) => {
          console.log("====AddCompany=====onAdd===", company);

          formik.setFieldValue("company", company);
        }}
      />

      <Dialog
        open={isAddCustomerDialog}
        classes={{ paper: styles.paper }}
        className={styles.container}
      >
        {addLoader && <FullScreenLoaderCNO />}

        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <HeadingBar title="Add Customer" onClose={handleClose} />
          <FormBox title="Customer details">
            <div className={styles.selectChips}>
              <Typography component="label">
                Salutation
                <span style={{ color: theme.colors.ERROR_COLOR }}>{" *"}</span>
              </Typography>
              <SnapList className={styles.chipsWrapper}>
                <SnapItem>
                  <ChipCNO
                    icon={mdiAccountTie}
                    name="Sir"
                    active={formik.values.salutation === Salutation.Sir}
                    onClick={() => {
                      formik.setFieldValue("salutation", Salutation.Sir);
                      formik.setFieldValue(
                        "gender",
                        getGenderBasedOnSalutation(Salutation.Sir)
                      );
                    }}
                  />
                </SnapItem>
                <SnapItem>
                  <ChipCNO
                    icon={mdiHumanMale}
                    name="Mr"
                    active={formik.values.salutation === Salutation.Mr}
                    onClick={() => {
                      formik.setFieldValue("salutation", Salutation.Mr);
                      formik.setFieldValue(
                        "gender",
                        getGenderBasedOnSalutation(Salutation.Mr)
                      );
                    }}
                  />
                </SnapItem>
                <SnapItem>
                  <ChipCNO
                    icon={mdiHumanFemale}
                    name="Ms"
                    active={formik.values.salutation === Salutation.Ms}
                    onClick={() => {
                      formik.setFieldValue("salutation", Salutation.Ms);
                      formik.setFieldValue(
                        "gender",
                        getGenderBasedOnSalutation(Salutation.Ms)
                      );
                    }}
                  />
                </SnapItem>
                <SnapItem>
                  <ChipCNO
                    icon={mdiHumanFemale}
                    name="Mrs"
                    active={formik.values.salutation === Salutation.Mrs}
                    onClick={() => {
                      formik.setFieldValue("salutation", Salutation.Mrs);
                      formik.setFieldValue(
                        "gender",
                        getGenderBasedOnSalutation(Salutation.Mrs)
                      );
                    }}
                  />
                </SnapItem>
                <SnapItem>
                  <ChipCNO
                    icon={mdiDoctor}
                    name="Dr"
                    active={formik.values.salutation === Salutation.Dr}
                    onClick={() => {
                      formik.setFieldValue("salutation", Salutation.Dr);
                      formik.setFieldValue(
                        "gender",
                        getGenderBasedOnSalutation(Salutation.None)
                      );
                    }}
                  />
                </SnapItem>
              </SnapList>
            </div>
            <InputCNO
              name="firstName"
              label="First Name"
              formik={formik}
              placeholder="First Name"
              icon={mdiFormatText}
              fullWidth
              isRequired
              onBlur={() => checkIfCustomerAlreadyExist()}
              isBorderHighlight={isAlreadyExistCustomer}
            />
            <InputCNO
              name="lastName"
              label="Last Name"
              formik={formik}
              placeholder="Last Name"
              icon={mdiFormatText}
              fullWidth
              isRequired
              onBlur={() => checkIfCustomerAlreadyExist()}
              isBorderHighlight={isAlreadyExistCustomer}
            />
          </FormBox>

          <FormBox title="Contact details">
            <InputCNO
              name="email"
              label="Email (Login username)"
              type="email"
              formik={formik}
              placeholder="Email"
              icon={mdiEmail}
              fullWidth
            />
            <Typography
              style={{
                color: theme.colors.HELPER_TEXT_COLOR,
                fontSize: "0.8rem",
              }}
            >
              Note: Customer will be able to login as user if email is provided.
            </Typography>

            <PhoneInputCNO
              value={formik.values.mobile}
              label="Mobile"
              onChange={(value) => formik.setFieldValue("mobile", value)}
              containerStyle={{ marginTop: 10, marginBottom: 10 }}
              showHelperText={formik.errors.mobile}
              helperText={formik.errors.mobile}
              onValidatePhone={(error) => {
                if (!!error) formik.setFieldError("mobile", error);
              }}
            />

            <GooglePlaceInputCNO
              address={formik.values.mapAddress}
              onSelectAddress={(addressObj) => {
                formik.setFieldValue("mapAddress", addressObj.address);
                formik.setFieldValue("lat", addressObj.lat);
                formik.setFieldValue("lng", addressObj.lng);
                formik.setFieldTouched("mapAddress", true);
              }}
              isRequired
              helperText={formik.errors.mapAddress}
              showHelperText={
                formik.touched.mapAddress && Boolean(formik.errors.mapAddress)
              }
            />
          </FormBox>

          <div
            className={GlobalStyles.link}
            onClick={() => setIsMoreDetailsExpanded(!isMoreDetailsExpanded)}
          >
            {isMoreDetailsExpanded ? "Less Details" : "More Details"}
          </div>

          {isMoreDetailsExpanded && (
            <div className={GlobalStyles.moreDetailsContainer}>
              <FormBox title="Personal details">
                <div className={styles.selectChips}>
                  <Typography component="label">Gender</Typography>
                  <div className={styles.chipsWrapper}>
                    <ChipCNO
                      icon={mdiGenderFemale}
                      name="Female"
                      active={formik.values.gender === Gender.Female}
                      onClick={() =>
                        formik.setFieldValue("gender", Gender.Female)
                      }
                    />
                    <ChipCNO
                      icon={mdiGenderMale}
                      name="Male"
                      active={formik.values.gender === Gender.Male}
                      onClick={() =>
                        formik.setFieldValue("gender", Gender.Male)
                      }
                    />
                    <ChipCNO
                      icon={mdiCircleOutline}
                      name="Others"
                      active={formik.values.gender === Gender.Others}
                      onClick={() =>
                        formik.setFieldValue("gender", Gender.Others)
                      }
                    />
                  </div>
                </div>

                <Typography component="label" className={styles.label}>
                  Birthday
                </Typography>
                <DatePicker
                  className={styles.picker}
                  margin="dense"
                  value={formik.values.birthday}
                  format="MMM dd, yyyy"
                  inputVariant="outlined"
                  placeholder="Enter birthday"
                  onChange={(date) => formik.setFieldValue("birthday", date)}
                  error={
                    formik.touched.birthday && Boolean(formik.errors.birthday)
                  }
                  helperText={formik.errors.birthday}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <Icon
                          color={theme.colors.INPUT_ICON_COLOR}
                          path={mdiCalendar}
                          size="1.5rem"
                        />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />

                <InputCNO
                  name="abstract"
                  label="Abstract"
                  formik={formik}
                  placeholder="Abstract"
                  icon={mdiFormatText}
                  fullWidth
                  multiline
                />
              </FormBox>

              <FormBox title="Social details">
                <InputCNO
                  name="skype"
                  label="Skype"
                  formik={formik}
                  placeholder="Skype"
                  icon={mdiSkype}
                  fullWidth
                />

                <InputCNO
                  name="facebook"
                  label="Facebook"
                  formik={formik}
                  placeholder="Facebook"
                  icon={mdiFacebook}
                  fullWidth
                />

                <InputCNO
                  name="twitter"
                  label="Twitter"
                  formik={formik}
                  placeholder="Twitter"
                  icon={mdiTwitter}
                  fullWidth
                />

                <InputCNO
                  name="linkedIn"
                  label="LinkedIn"
                  formik={formik}
                  placeholder="LinkedIn"
                  icon={mdiLinkedin}
                  fullWidth
                />
              </FormBox>

              <FormBox title="Company details">
                <div className={styles.selectWithAdd}>
                  <AutocompleteCNO
                    name="company"
                    label="Company"
                    formik={formik}
                    placeholder="Search Company..."
                    // fullWidth
                    data={suggestions}
                    optionLabel={(x) => x.title || ""}
                    renderOption={(option) => (
                      <div style={{ color: "orangered" }}>{option.title}</div>
                    )}
                    onChange={(value) => {
                      if (value) {
                        dispatch(fetchCustomerCompanySuggest(value));
                      }
                    }}
                  />
                  <Icon
                    path={mdiPlus}
                    size="43px"
                    color="goldenrod"
                    onClick={handleAddCompanyDialog}
                  />
                </div>
              </FormBox>
            </div>
          )}

          {isAlreadyExistCustomer && (
            <AlreadyExistCustomerList
              customers={searchedCustomers}
              onSelectItem={(selectedUser) => {
                if (Boolean(selectedUser)) {
                  formik.setValues({ ...selectedUser }, true);
                  prevValuesRef.current = formik.values;
                } else {
                  formik.setValues(prevValuesRef.current, true);
                }
                setIsUserSelectedFromList(Boolean(selectedUser));
              }}
            />
          )}

          <FormActionButtons
            rightText={isUserSelectedFromList ? "OK" : "Add Customer"}
            leftClick={handleClose}
            formik={formik}
          />
        </form>
      </Dialog>
    </>
  );
}

export default AddCustomer;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0 auto",
  },
  paper: {
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    borderRadius: 10,
    margin: ({ tillTablet }) => (tillTablet ? 8 : 32),
    width: "70vw",
    maxWidth: "90vh",
  },
  form: {
    padding: "20px",
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    "&>*": {
      marginBottom: theme.spacing(2),
    },
  },
  chipsWrapper: {
    display: "flex",
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    "&>*": {
      marginRight: 10,
    },
  },
  selectChips: {
    "&>*:first-child": {
      color: theme.colors.FORM_LABEL_COLOR,
    },
  },
  selectWithAdd: {
    display: "flex",
    alignItems: "flex-end",
    "&>*:first-child": {
      flex: 1,
    },
  },
  mobile: {
    "& input": {
      padding: "10px 5px",
      border: "1px solid #0000003b",
      borderRadius: "5px",
    },
  },
  picker: {
    "& .MuiInputBase-root": {
      background: "white",
      color: "#444",
    },
    "& .MuiIconButton-label": {
      color: "#444",
    },
  },
  label: {
    color: theme.colors.FORM_LABEL_COLOR,
  },
}));
