import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SubscriptionFeaturesList } from '../utils/Subscription/SubscriptionFeatures';
import RightsManager from '../utils/RightsManager';

const initialValues = {
  isManageCompanyAllowed: false,
  isManageUsersAllowed: false,
  isJobCreationAllowed: false,
  isTrackingAllowed: false,
  isPaymentAllowed: false,
  isQuotationAllowed: false,
  isInvoiceAllowed: false,
  isAppointmentAllowed: false,
  isCustomerLoginAllowed: false,
  isShareAllowed: false,
  isChatAllowed: false,
  isCustomerChatAllowed: false,
  isBasicReportAnalyticsAllowed: false,
  isDashboardAllowed: false,
  isCallingAudioVideoAllowed: false,
  isDetailedReportAnalyticsAllowed: false,
};

const SubscriptionContext = createContext(initialValues);

export const SubscriptionProvider = ({ children }) => {

  const isUserCustomer = RightsManager.isCustomer();

  const { subscriptionDetails } = useSelector(state => state.subscription);

  const [allAllowedFeatures, setAllAllowedFeatures] = useState(initialValues);

  useEffect(() => {

    console.log("=====useIsFeatureAllowedInSubscription=====subscriptionDetails=====", subscriptionDetails);

    if (subscriptionDetails && subscriptionDetails.features) {
      const allFeatures = subscriptionDetails.features;

      const allFeatureIds = allFeatures
        .filter(feature => feature.isAvailable)
        .map(feature => feature.id);

      console.log("=====useIsFeatureAllowedInSubscription=====allFeatures=====", allFeatures);
      console.log("=====useIsFeatureAllowedInSubscription=====allFeatureIds=====", allFeatureIds);

      setAllAllowedFeatures({
        isManageCompanyAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList["Manage Company"]),
        isManageUsersAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList["Manage Users"]),
        isJobCreationAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList["Job Creation"]),
        isTrackingAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList.Tracking),
        isPaymentAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList.Payment),
        isQuotationAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList.Quotation),
        isInvoiceAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList.Invoice),
        isAppointmentAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList.Appointment),
        isCustomerLoginAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList["Customer Login"]),
        isShareAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList.Share),
        isChatAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList.Chat),
        isCustomerChatAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList["Customer Chat"]),
        isBasicReportAnalyticsAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList["Basic Report/Analytics"]),
        isDashboardAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList.Dashboard),
        isCallingAudioVideoAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList["Calling (Audio/Video)"]),
        isDetailedReportAnalyticsAllowed: isUserCustomer ? true : allFeatureIds.includes(SubscriptionFeaturesList["Detailed Report/Analytics"]),
      });

    }

  }, [subscriptionDetails, isUserCustomer]);

  return (
    <SubscriptionContext.Provider value={allAllowedFeatures}>
      {children}
    </SubscriptionContext.Provider>
  );

};

export const useIsFeatureAllowedInSubscription = () => {
  const store = useContext(SubscriptionContext);
  return store;
};
