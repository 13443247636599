import React, { useState } from "react";
import {
  DotSeparator,
  IconMain,
  ListCardItemWrapper,
  MetaText,
  Stack,
} from "../../../library";
import { Box, makeStyles, useTheme } from "@material-ui/core";
import AvatarCNO from "../../../library/AvatarCNO";
import ResponsiveLinesCNO from "../../../library/ResponsiveLinesCNO";
import { formatDateTimeFromNow } from "../../../utils/formatDate";
import { Privacy } from "../../../utils/mappings";
import PostActionsBar from "./PostActionsBar";
import PostGroupedMedia from "./PostGroupedMedia";
import Icon from "@mdi/react";
import { mdiDirections, mdiDotsVertical } from "@mdi/js";
import { EventListCardPopover } from "../../EventManagement";
import MorePostPopover from "../../../components/MorePostPopover/MorePostPopover";
import { Popover } from "@material-ui/core";
import { formatDate } from "../../../utils/Helpers";
import * as mdi from "@mdi/js";
const PostListCard = ({ post }) => {
  console.log("postListing : post", post);

  const theme = useTheme();
  const styles = useStyles();
  const [refMore, setRefMore] = useState(null);

  const createdByUser = post.createdBy;
  const hasLifeEvent = post?.lifeEventTitle;

  const postCreatedAgoDuration = formatDateTimeFromNow(post.createdAt);
  const privacy = Privacy.getItem(post.visibility);
  const feeling = post.feeling;
  const tagsPeople = post.tags;
  const selectedActivity = post.activity;
  const location = post?.location;

  const openMore = (e) => {
    setRefMore(e.currentTarget);
  };

  const closeMore = () => {
    setRefMore(null);
  };
  const open = Boolean(refMore);

  const NativePopover = () => {
    return (
      <Popover
        open={open}
        anchorEl={refMore}
        onClose={closeMore}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MorePostPopover item={post} closeMore={closeMore} />
      </Popover>
    );
  };

  return (
    <ListCardItemWrapper className={styles.container}>
      <Stack>
        <div className={styles.meta}>
          <Box className={styles.headerWrapper}>
            <AvatarCNO
              src={createdByUser.profilePic}
              title={createdByUser.title}
              iconSize="3.5rem"
            />
            <Box>
              <MetaText fontSize={18} fontWeight="bold">
                {`${createdByUser.title} ${
                  feeling && feeling.feelingEmoji
                    ? `is feeling ${feeling.feelingEmoji} ${feeling.feelingTitle}`
                    : selectedActivity && selectedActivity.display_name
                    ? `is ${selectedActivity.display_name} ${selectedActivity.icon} ${selectedActivity.title}`
                    : ""
                } ${
                  tagsPeople?.length > 0
                    ? `with ${
                        tagsPeople.length >= 4
                          ? `${tagsPeople
                              .slice(0, 3)
                              .map((person) => person.name)
                              .join(", ")} and ${tagsPeople.length - 3} other${
                              tagsPeople.length - 3 > 1 ? "s" : ""
                            }`
                          : tagsPeople.length === 3
                          ? `${tagsPeople
                              .slice(0, 2)
                              .map((person) => person.name)
                              .join(", ")} and ${tagsPeople[2].name}`
                          : tagsPeople.length === 2
                          ? `${tagsPeople[0].name} and ${tagsPeople[1].name}`
                          : tagsPeople[0].name
                      }`
                    : ""
                } ${location ? ` at ${location}` : ""}`}
              </MetaText>

              <Box className={styles.metaWrapper}>
                <IconMain name={privacy.icon} size="1rem" />
                <DotSeparator />
                <MetaText fontSize={12}>{postCreatedAgoDuration}</MetaText>
              </Box>
            </Box>
          </Box>

          <div
            style={{ display: "flex", alignItems: "center", height: "1.8rem" }}
          >
            <Icon
              className={styles.moreIcon}
              path={mdiDotsVertical}
              size="1.8rem"
              color={theme.colors.MORE_ACTIONS_ICON_COLOR}
              onClick={openMore}
            />
            <NativePopover />
          </div>
        </div>

        {/* TODO: add more/less */}
        <ResponsiveLinesCNO
          maxLine={10}
          text={post.description}
          style={{ color: theme.colors.LIST_CARD_PARA_COLOR }}
        />

        {!hasLifeEvent ? (
          <PostGroupedMedia post={post}  />
        ) : (
          <div>
            <div className={styles.imageSection}>
              <div className={styles.lifeEventImage}>
                <img src={post.lifeEventGif} alt="Life Event" />
              </div>
              <div className={styles.LifeEventCategoryIcon} >
                <Icon path={mdi[post.lifeEventIcon]} size={1} />
              </div>
            </div>
            <div className={styles.textCenter}>
              <p>{`${post.lifeEventTitle}`}</p>
              <p>{`${formatDate(post.eventDate)}`}</p>
            </div>
          </div>
        )}

        <PostActionsBar post={post} />
      </Stack>
    </ListCardItemWrapper>
  );
};

export default PostListCard;

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  headerWrapper: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "flex-start",
  },
  metaWrapper: {
    display: "flex",
    alignItems: "center",
  },
  meta: {
    marginLeft: theme.spacing(2),
    flex: 1,
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
  },
  ImageSection: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0",
  },
  lifeEventImage: {
    display: "flex",
    // flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: "center",
    "& img": {
      maxWidth: "100%",
      height: "auto",
      borderRadius: "8px",
    },
  },
  LifeEventCategoryIcon: {
    height: "70px",
    width: "70px",
    background: "rgb(63,138,2)",
    color: "white",
    position: "absolute",
    bottom: "-28px",
    left: "48%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },

  textCenter: {
    textAlign: "center",
    marginTop: "40px",
    "& p": {
      margin: 0,
    },
  },
}));
