import React, { useState } from "react";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import TableRowsIcon from "@material-ui/icons/Reorder";
import { makeStyles } from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import MoreFilteringDialogue from "./MoreFilteringDialogue";
import { Tooltip } from "@material-ui/core";
import { background } from "styled-system";

const Switcher = ({ isGridView, setIsGridView, isPost, setFilters }) => {
  const [openMore, setOpenMore] = useState(false);

  const styles = useStyles();

  const handleOpenMore = () => {
    setOpenMore(true);
  };

  const closeOpenMore = () => {
    setOpenMore(false);
  };

  const handleApplyFilters = (filters) => {
    setFilters(filters);
  };

  return (
    <div style={isPost ? {padding:'1rem 1.2rem 0 1.2rem'} : {margin:'0px 5px -10px 0'}}>
      <div className={styles.container}>
        <div className={styles.toggleItem}>
          <Tooltip title="List View" placement="bottom">
            <div
              className={
                isGridView
                  ? styles.borderRight
                  : `${styles.borderRight} ${styles.active}`
              }
              onClick={() => setIsGridView(false)}
            >
              <TableRowsIcon />
            </div>
          </Tooltip>

          <Tooltip title="Grid View" placement="bottom">
            <div
              className={
                isGridView
                  ? `${styles.active} ${styles.border} `
                  : styles.border
              }
              onClick={() => setIsGridView(true)}
            >
              <ViewQuiltIcon />
            </div>
          </Tooltip>
        </div>

        {isPost && (
          <div className={styles.filterButton} onClick={handleOpenMore}>
            <TuneIcon />
            <p>Filters</p>
          </div>
        )}
      </div>
      <MoreFilteringDialogue
        open={openMore}
        onClose={closeOpenMore}
        onApplyFilters={handleApplyFilters}
      />
    </div>
  );
};

export default Switcher;

const useStyles = makeStyles((theme) => ({
  // fixedContainer: {
  //   padding:`${isPost && `1rem 1.2rem 0 1.2rem` }`,
  // },
  container: {
    display: "flex",
    marginBottom: "10px",
    justifyContent: "flex-end",
    gap: "20px",
  },
  filterButton: {
    display: "flex",
    border: `1px solid ${theme.colors.SWITCHER_CONTAINER_BORDER}`,
    padding: "6px",
    fontSize: "14px",
    cursor: "pointer",
    borderRadius: 6,
    gap: "10px",
    background: theme.colors.LIST_GRID_SWITCHER,
    fontWeight: "bold",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.colors.LIST_GRID_SWITCHER_HOVER,
    },
  },

  active: {
    background: theme.colors.LIST_GRID_SWITCHER,
    color:`${theme.colors.SWITCHER_ACTIVE_COLOR} !important`,
    "&:hover": {
      backgroundColor: theme.colors.LIST_GRID_SWITCHER_HOVER,
      
    },
  },

  toggleItem: {
    display: "flex",
    justifyContent: "space-between",

    border: `1px solid ${theme.colors.SWITCHER_CONTAINER_BORDER}`,

    borderRadius: 6,
    overflow:"hidden"
  },

  border: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: theme.colors.SWITCHER_COLOR,
  },
  borderRight: {
    borderRight: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: theme.colors.SWITCHER_COLOR,
  },
}));
