import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { mdiClockEnd, mdiClockStart, mdiFormatText } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateInvoice } from '../../actions/invoiceActions';
import DialogCNO from '../../library/DialogCNO';
import { calendarStrings, DATE_FORMAT, TIME_FORMAT } from '../../utils/formatDate';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import FormActionButtons from '../FormActionButtons';
import * as yup from 'yup';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { InvoiceStatus } from '../../utils/mappings';
import InputCNO from '../../library/InputCNO';
import RightsManager from '../../utils/RightsManager';
import { formatTimeWithTZ } from '../../utils/Helpers';

const EditTimelineDialog = ({
  open,
  onClose,
  invoice,
}) => {

  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const dispatch = useDispatch();
  const { allInvoiceLoader: { editLoader } } = useSelector(state => state.invoices);

  console.log("==============>>>>>>>", invoice);

  const formik = useFormik({
    initialValues: {
      billDate: invoice.billDate,
      dueDate: invoice.dueInDays,
    },
    validationSchema: yup.object({
      billDate: yup.date('Invalid Date').required("Required!!!"),
      dueDate: yup.number('Invalid Date').required("Required!!!"),
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {

    console.log("Invoice edit values: ", values);

    const updateInvoicePayload = {
      ...invoice,
      billDate: values.billDate,
      dueInDays: values.dueDate,
    };

    dispatch(updateInvoice(updateInvoicePayload));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Invoice"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <div>
          <div>
            <Typography component='label'>Bill Date</Typography>
            <KeyboardDatePicker
              className={clsx(GlobalStyles.picker, styles.start)}
              margin="dense"
              value={formik.values.billDate}
              format="MMM dd, yyyy"
              inputVariant='outlined'
              placeholder='Bill Date'
              name='billDate'
              onChange={date => {
                formik.setFieldTouched('billDate', true);
                formik.setFieldValue('billDate', date);
              }}
              error={formik.touched.billDate && Boolean(formik.errors.billDate)}
              helperText={formik.errors.billDate}
              fullWidth
            />
          </div>
        </div>

        <InputCNO
          name='dueDate'
          label='Due in days'
          formik={formik}
          placeholder='Enter due in days'
          icon={mdiFormatText}
          fullWidth
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )

}

function InvoiceJobTimeDetailsCard({ invoice }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isCompanyAdmin = RightsManager.isGroupLeader();

  const [isTimelineEditable, setIsTimelineEditable] = useState(false);

  return (
    <div className={GlobalStyles.detailsCardContainer}>
      <Typography className={GlobalStyles.detailsCardSectionTitle}>
        Bill Date & Due Date
      </Typography>
      <div className={GlobalStyles.detailsCardSection} onClick={() => setIsTimelineEditable(true)}>
        <div className={GlobalStyles.detailsRow}>
          <Typography style={{ color: theme.colors.JOB_START_DATE_COLOR }}>
            <Moment format={DATE_FORMAT} >
              {formatTimeWithTZ(invoice.billDate)}
            </Moment>
          </Typography>
          <Icon
            path={mdiClockStart}
            size='2rem'
            color={theme.colors.JOB_START_DATE_COLOR}
          />
        </div>
        {
          // isUserAdmin &&
          <div className={GlobalStyles.detailsRow}>
            <Typography style={{ color: theme.colors.JOB_END_DATE_COLOR }}>
              Due in {invoice.dueInDays} days
            </Typography>
            <Icon
              path={mdiClockEnd}
              size='2rem'
              color={theme.colors.JOB_END_DATE_COLOR}
            />
          </div>
        }
      </div>

      {
        isCompanyAdmin &&
        isTimelineEditable &&
        <EditTimelineDialog
          open={isTimelineEditable}
          onClose={() => setIsTimelineEditable(false)}
          invoice={invoice}
        />
      }

    </div>
  )
}

export default InvoiceJobTimeDetailsCard;

const useStyles = makeStyles((theme) => ({
  start: {
    '& .MuiSvgIcon-root': {
      color: theme.colors.JOB_START_DATE_COLOR
    }
  },
  end: {
    '& .MuiSvgIcon-root': {
      color: theme.colors.JOB_END_DATE_COLOR
    }
  },
}));
