import { useTheme, makeStyles, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import Moment from "react-moment";
import { v4 } from "uuid";
import { formatTimeWithTZ } from "../../utils/Helpers";
import {
  WorkHourTypeBackgroundColor,
  WorkHourTypeColor,
  WorkHourTypeReverse,
} from "../../utils/mappings";
import { convertDecimalTimeToTime } from "../../utils/Tracking";

function SuspiciousHoursTable({ suspiciousHours }) {
  const theme = useTheme();

  const styles = useStyles();

  const columns = [
    {
      field: "datetime",
      headerName: "Date",
      flex: 4,
      renderHeader: () => {
        return (
          <div
            style={{
              color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR,
              fontSize: "0.8rem",
            }}
          >
            When
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <Moment
            style={{ color: theme.colors.WORK_HOUR_TABLE_COLUMN_TEXT_COLOUR }}
            format="DD MMM, YYYY hh:mm a"
          >
            {formatTimeWithTZ(params.value)}
          </Moment>
        );
      },
    },
    {
      field: "timeDiff",
      headerName: "Suspicious Hour",
      flex: 3,
      renderHeader: () => {
        return (
          <div
            style={{
              color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR,
              fontSize: "0.8rem",
            }}
          >
            Hours
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <Typography
            style={{
              background:
                theme.colors[WorkHourTypeBackgroundColor[params.row.type]],
              width: "100%",
              borderRadius: 5,
              textAlign: "center",
              color: theme.colors[WorkHourTypeColor[params.row.type]],
              fontWeight: "bold",
            }}
          >
            {convertDecimalTimeToTime(params.value)}
          </Typography>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 3,
      renderHeader: () => {
        return (
          <div
            style={{
              color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR,
              fontSize: "0.8rem",
            }}
          >
            &nbsp;
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <div
            style={{ color: theme.colors.WORK_HOUR_TABLE_COLUMN_TEXT_COLOUR }}
            dangerouslySetInnerHTML={{
              __html: WorkHourTypeReverse[params.value],
            }}
          />
        );
      },
    },
  ];
  const rows = suspiciousHours.map((entry) => ({
    id: v4(),
    datetime: entry.datetime,
    timeDiff: entry.timeDiff,
    type: entry.type,
  }));

  return (
    <div className={styles.container_SuspiciousHourDetails}>
      <div className={styles.grid}>
        {suspiciousHours.length ? (
          <DataGrid
            rows={rows}
            columns={columns}
            rowHeight={35}
            hideFooterSelectedRowCount
            pageSize={5}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
          />
        ) : (
          <div
            style={{
              color: theme.colors.WORK_HOUR_TABLE_COLUMN_TEXT_COLOUR,
              textAlign: "center",
              fontSize: "0.8rem",
            }}
          >
            No Suspicious hours found
          </div>
        )}
      </div>
    </div>
  );
}

export default SuspiciousHoursTable;

const useStyles = makeStyles((theme) => ({
  container_SuspiciousHourDetails: {
    marginTop: -20,
    width: "100%",

    margin: "0 auto",
    "& .MuiDataGrid-root": {
      border: `2px solid ${theme.colors.BORDER_COLOR}`,
    },
    "& .MuiDataGrid-root > *": {
      outline: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-colCellSortable": {
      background: theme.colors.WORK_HOUR_TABLE_HEADER_BACKGROUND_COLOUR,
    },
    "& .MuiDataGrid-root .MuiDataGrid-dataContainer": {
      background: theme.colors.WORK_HOUR_TABLE_ROW_BACKGROUND_COLOUR,
    },
    "& .MuiDataGrid-root .MuiDataGrid-footer": {
      background: theme.colors.WORK_HOUR_TABLE_HEADER_BACKGROUND_COLOUR,
      minHeight: 35,
      height: 35,
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-colCell, .MuiDataGrid-root .MuiDataGrid-cell":
      {
        paddingRight: 0,
      },
    "& .MuiDataGrid-root .MuiDataGrid-columnsContainer": {
      background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    },
  },
  grid: {
    marginTop: theme.spacing(2),
  },
}));
