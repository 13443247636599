import moment from "moment";
import { toast } from "react-toastify";
import {
  apiInvoiceAssign,
  apiInvoiceChangeStatus,
  apiInvoiceCreate,
  apiInvoiceEdit,
  apiInvoiceGetMediaLink,
  apiInvoiceInitUpload,
  apiInvoiceRemove,
  apiInvoiceSearch,
  apiInvoiceUploadMedia,
} from "../api/invoice";
import { getTotalAmountValues } from "../utils/InvoiceHelpers";
import {
  Currency,
  InvoiceStatus,
  JobTargetType,
  RateType,
  JobVisibilityForCustomer,
  MediaSizeType,
  FileType,
  MediaType,
} from "../utils/mappings";
import {
  addInvoiceItem,
  addMultipleInvoiceItems,
  fetchInvoiceItems,
} from "./invoiceItemActions";
import { fetchSubTasks, setIsNewSubTaskAdded } from "./subTaskActions";
import { createBrowserHistory } from "history";
import LocalStorageConstants from "../utils/LocalStorageConstants";
import { getCompanyById, getCompanyProfilePic } from "./customerCompanyActions";
import { getCustomerById } from "./customerActions";
import { generateInvoiceTemplate } from "../utils/PDFHelpers";
import { AppConstants } from "../utils/AppConstants";
import { generatePdf } from "../utils/PDFHelpers/PDFHelpers";
import {
  b64toBlob,
  getBase64FromURL,
  getContentType,
  getFileDataAsync,
  getFileDataFromLinkAsync,
  getFileTypeFromMimeType,
  getMediaData,
  getThumbnailBlob,
  getTodayInvoices,
  parseNumber,
} from "../utils/Helpers";
import { v4 } from "uuid";
import { getResolvedJobMediasByIds, getTaskById } from "./jobActions";
import {
  getQuotationById,
  getResolvedQuotationByIds,
} from "./quotationActions";
import { fetchQuotationItems } from "./quotationItemActions";
import { combineDateAndTime } from "../utils/Tracking";
import axios from "axios";

export const SET_INVOICES = "SET_INVOICES";
export const TOGGLE_ADD_INVOICE_DIALOG = "TOGGLE_ADD_INVOICE_DIALOG";
export const SET_INVOICE_BY_ID = "SET_INVOICE_BY_ID";
export const SET_INVOICES_LOADER = "SET_INVOICES_LOADER";
export const SET_HAS_MORE_INVOICES = "SET_HAS_MORE_INVOICES";
export const TOGGLE_INVOICE_FILTER_DIALOG = "TOGGLE_INVOICE_FILTER_DIALOG";
export const SET_INVOICE_SEARCH_PARAMS = "SET_INVOICE_SEARCH_PARAMS";
export const SET_RESOLVED_INVOICE_MEDIAS = "SET_RESOLVED_INVOICE_MEDIAS";
export const SET_ADD_INVOICE_DIALOG_DATA = "SET_ADD_INVOICE_DIALOG_DATA";

export const setAddInvoiceDialogData = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_ADD_INVOICE_DIALOG_DATA,
      payload,
    });
  };
};

export function setResolvedInvoiceMedias(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_RESOLVED_INVOICE_MEDIAS,
      payload: payload,
    });
  };
}

export function setInvoiceLoader(loaderType, loaderState) {
  return (dispatch) => {
    const loaderPayload = {
      loaderType,
      loaderState,
    };

    dispatch({
      type: SET_INVOICES_LOADER,
      payload: loaderPayload,
    });
  };
}

export function setInvoiceSearchParams(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_INVOICE_SEARCH_PARAMS,
      payload: payload,
    });
  };
}

export function setHasMoreInvoices(status) {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_MORE_INVOICES,
      payload: status,
    });
  };
}

export function toggleAddInvoiceDialog(payload) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_INVOICE_DIALOG,
      payload: payload,
    });
  };
}

export function toggleInvoiceFilterDialog(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_INVOICE_FILTER_DIALOG,
      payload: status,
    });
  };
}

export function setInvoices(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_INVOICES,
      payload: payload,
    });
  };
}

export function fetchInvoices(payload = {}) {
  return async (dispatch, getState) => {
    dispatch(setInvoiceLoader("invoicesLoader", true));
    dispatch(setHasMoreInvoices(true));

    const { invoiceSearchParams } = getState().invoices;

    payload = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      endDate: null,
      assignedIds: [],
      ids: null,
      customerIds: invoiceSearchParams.customer
        ? [invoiceSearchParams.customer.id]
        : null,
      searchText: invoiceSearchParams.invoice
        ? invoiceSearchParams.invoice.title
        : "",
      categories: invoiceSearchParams.categories,
      invoiceNumbers: invoiceSearchParams.invoiceNumber
        ? [invoiceSearchParams.invoiceNumber]
        : null,
      startDate: invoiceSearchParams.billDate
        ? moment(invoiceSearchParams.billDate).startOf("day")
        : null,
      dueInDays: invoiceSearchParams.dueInDays
        ? invoiceSearchParams.dueInDays
        : null,
      statuses: invoiceSearchParams.statuses
        ? invoiceSearchParams.statuses
        : [],
      paymentByCustomerStatuses: invoiceSearchParams.paymentByCustomerStatuses
        ? invoiceSearchParams.paymentByCustomerStatuses
        : [],
      ...payload,
    };

    await apiInvoiceSearch
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          const { isToday } = invoiceSearchParams;

          let invoicesResponse = response.data.data;

          let currencyType = localStorage.getItem("currencyType");
          currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

          invoicesResponse = getTodayInvoices(invoicesResponse, isToday);

          invoicesResponse = invoicesResponse.map((invoice) => {
            const {
              totalAmount,
              amount,
              amountAfterDiscount,
              discount,
              discountRate,
              taxableAmount,
              tax,
              taxRate,
            } = getTotalAmountValues(
              invoice.rate,
              invoice.discount,
              invoice.taxRate,
              invoice.includeTax
            );

            return {
              ...invoice,
              amount,
              discountRate,
              discount,
              amountAfterDiscount,
              taxableAmount,
              tax,
              taxRate,
              totalAmount,
              currency: invoice.currency ? invoice.currency : currencyType,
              notes: invoice.notes ? invoice.notes : AppConstants.INVOICE_NOTES,
            };
          });

          console.log("InvoicesResponse: ", invoicesResponse);
          dispatch(setInvoices(invoicesResponse));

          if (invoicesResponse.length < 10) {
            dispatch(setHasMoreInvoices(false));
          }
        } else {
          console.error("Error: Fetch invoices: ");
        }
      })
      .catch((error) => {
        console.error("Error: Fetch invoices: ", error);
      })
      .finally(() => {
        dispatch(setInvoiceLoader("invoicesLoader", false));
      });
  };
}

export function fetchMoreInvoices(payload) {
  return async (dispatch, getState) => {
    const prevInvoices = getState().invoices.invoices;
    const { invoiceSearchParams } = getState().invoices;

    dispatch(setInvoiceLoader("moreInvoicesLoader", true));
    dispatch(setHasMoreInvoices(true));

    payload = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      endDate: null,
      assignedIds: [],
      ids: null,
      customerIds: invoiceSearchParams.customer
        ? [invoiceSearchParams.customer.id]
        : null,
      searchText: invoiceSearchParams.invoice
        ? invoiceSearchParams.invoice.title
        : "",
      categories: invoiceSearchParams.categories,
      invoiceNumbers: invoiceSearchParams.invoiceNumber
        ? [invoiceSearchParams.invoiceNumber]
        : null,
      startDate: invoiceSearchParams.billDate
        ? moment(invoiceSearchParams.billDate).startOf("day")
        : null,
      dueInDays: invoiceSearchParams.dueInDays
        ? invoiceSearchParams.dueInDays
        : null,
      statuses: invoiceSearchParams.statuses
        ? invoiceSearchParams.statuses
        : [],
      paymentByCustomerStatuses: invoiceSearchParams.paymentByCustomerStatuses
        ? invoiceSearchParams.paymentByCustomerStatuses
        : [],
      ...payload,
    };

    await apiInvoiceSearch
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          const { isToday } = invoiceSearchParams;

          let moreInvoicesResponse = response.data.data;

          let currencyType = localStorage.getItem("currencyType");
          currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

          moreInvoicesResponse = getTodayInvoices(
            moreInvoicesResponse,
            isToday
          );

          moreInvoicesResponse = moreInvoicesResponse.map((invoice) => {
            const {
              totalAmount,
              amount,
              amountAfterDiscount,
              discount,
              discountRate,
              taxableAmount,
              tax,
              taxRate,
            } = getTotalAmountValues(
              invoice.rate,
              invoice.discount,
              invoice.taxRate,
              invoice.includeTax
            );

            return {
              ...invoice,
              amount,
              discountRate,
              discount,
              amountAfterDiscount,
              taxableAmount,
              tax,
              taxRate,
              totalAmount,
              currency: invoice.currency ? invoice.currency : currencyType,
              notes: invoice.notes ? invoice.notes : AppConstants.INVOICE_NOTES,
            };
          });

          if (moreInvoicesResponse.length < 10) {
            dispatch(setHasMoreInvoices(false));
          }

          console.log("moreInvoicesResponse: ", moreInvoicesResponse);

          const invoicesPayload = [...prevInvoices, ...moreInvoicesResponse];

          dispatch(setInvoices(invoicesPayload));
        } else {
          console.error("Error: Fetch more Invoices: ");
        }
      })
      .catch((error) => {
        console.error("Error: Fetch more Invoices: ", error);
      })
      .finally(() => {
        dispatch(setInvoiceLoader("moreInvoicesLoader", false));
      });
  };
}

export function setInvoiceById(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_INVOICE_BY_ID,
      payload: payload,
    });

    if (payload) {
      const { invoices } = getState().invoices;
      const invoicesTemp = invoices.map((invoice) =>
        invoice.id === payload.id ? payload : invoice
      );

      dispatch(setInvoices(invoicesTemp));
    }
  };
}

export function getInvoiceById(invoiceId, offlineData) {
  return async (dispatch, getState) => {
    dispatch(setInvoiceLoader("invoiceDetailLoader", true));

    const payload = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      startDate: null,
      endDate: null,
      assignedIds: [],
      categories: [],
      searchText: "",
      ids: [invoiceId],
      statuses: [],
      paymentByCustomerStatuses: [],
    };

    console.log("====================================");
    console.log("getInvoiceById: ", invoiceId);
    console.log("====================================");

    await apiInvoiceSearch
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          let invoiceResponse = response.data.data;

          let currencyType = localStorage.getItem("currencyType");
          currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;
          invoiceResponse = invoiceResponse.map((invoice) => {
            const {
              totalAmount,
              amount,
              amountAfterDiscount,
              discount,
              discountRate,
              taxableAmount,
              tax,
              taxRate,
            } = getTotalAmountValues(
              invoice.rate,
              invoice.discount,
              invoice.taxRate,
              invoice.includeTax
            );

            return {
              ...invoice,
              amount,
              discountRate,
              discount,
              amountAfterDiscount,
              taxableAmount,
              tax,
              taxRate,
              totalAmount,
              currency: invoice.currency ? invoice.currency : currencyType,
              notes: invoice.notes ? invoice.notes : AppConstants.INVOICE_NOTES,
            };
          });

          console.log("getInvoiceById: invoice: ", invoiceResponse);

          dispatch(setInvoiceById(invoiceResponse[0]));
        } else {
          if (offlineData) {
            dispatch(setInvoiceById(offlineData));
          } else {
            console.log("Fetch invoice by Id Error: ");
            toast.error("There is some issue in fetching invoice.");
          }
        }
      })
      .catch((error) => {
        if (offlineData) {
          dispatch(setInvoiceById(offlineData));
        } else {
          console.error("Fetch invoice by Id Error: ", error);
          toast.error("There is some issue in fetching invoice.");
        }
      })
      .finally(() => {
        dispatch(setInvoiceLoader("invoiceDetailLoader", false));
      });
  };
}

export function addInvoice(payload, invoiceItemPayload = [], onAddInvoice) {
  return async (dispatch, getState) => {
    dispatch(setInvoiceLoader("addLoader", true));

    payload = {
      ...payload,
      startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startTime: combineDateAndTime(payload.startDate, payload.startTime),
      endDate: combineDateAndTime(payload.endDate, payload.endTime),
      endTime: combineDateAndTime(payload.endDate, payload.endTime),
    };

    console.log("Add Invoice payloadaction: ", payload);

    const dataToSend = {
      title: "",
      description: "",
      mediaLinks: [],
      categories: [],
      status: 2,
      customerId: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      invoiceAssignments: [],
      paidAmount: 0,
      rate: 0,
      rateType: RateType.Hourly,
      target: 0,
      targetType: JobTargetType.Hour,
      paymentByCustomerStatus: 1,
      mapAddress: null,
      lat: "",
      lng: "",
      proximity: 0,
      visibility: 0,
      taskId: "",
      ...payload,
    };

    console.log("Add Invoice dataToSend: ", dataToSend);

    await apiInvoiceCreate
      .post(dataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          console.log("Add Invoice Response: ", response);

          const invoiceId = response.data;

          const {
            totalAmount,
            amount,
            amountAfterDiscount,
            discount,
            discountRate,
            taxableAmount,
            tax,
            taxRate,
          } = getTotalAmountValues(
            payload.rate,
            payload.discount,
            payload.taxRate,
            payload.includeTax
          );

          const newInvoice = {
            ...payload,
            id: invoiceId,
            amount,
            discountRate,
            discount,
            amountAfterDiscount,
            taxableAmount,
            tax,
            taxRate,
            totalAmount,
          };

          dispatch(setInvoiceById(newInvoice));

          dispatch(setIsNewSubTaskAdded(true));

          // invoice item processing
          if (invoiceItemPayload.length) {
            await dispatch(
              addMultipleInvoiceItems(invoiceItemPayload, newInvoice)
            );
          }

          if (onAddInvoice) {
            onAddInvoice(invoiceId);
          }

          toast.success("Invoice added successfully!!");
        } else {
          console.log("Add Invoice Error: ");
          toast.error("Error in adding Invoice!!");
        }
      })
      .catch((error) => {
        console.error("Add Invoice Error: ", error);
        toast.error("Error in adding Invoice!!");
      })
      .finally(() => {
        dispatch(setInvoiceLoader("addLoader", false));
      });
  };
}

export function updateInvoice(payload, showToast = false, onAddSuccess) {
  return async (dispatch, getState) => {
    dispatch(setInvoiceLoader("editLoader", true));

    payload = {
      ...payload,
      startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startTime: combineDateAndTime(payload.startDate, payload.startTime),
      endDate: combineDateAndTime(payload.endDate, payload.endTime),
      endTime: combineDateAndTime(payload.endDate, payload.endTime),
      rate: parseNumber(payload.amount, "float"),
    };

    const dataToSend = {
      id: "",
      title: "",
      description: "",
      categories: [],
      customerId: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      rate: 0,
      rateType: 0,
      target: 0,
      targetType: 0,
      mapAddress: "",
      lat: "",
      lng: "",
      proximity: 0,
      visibility: 1,
      taskId: "",
      ...payload,
    };

    console.log("=======invoice edit dataToSend====> ", dataToSend);

    await apiInvoiceEdit
      .post(dataToSend)
      .then((response) => {
        if (response && response.status === 200) {
          const {
            amount,
            discount,
            amountAfterDiscount,
            discountRate,
            taxableAmount,
            tax,
            taxRate,
            totalAmount,
          } = getTotalAmountValues(
            payload.amount,
            payload.discount,
            payload.taxRate,
            payload.includeTax
          );

          const newInvoice = {
            ...payload,
            amount,
            discount,
            amountAfterDiscount,
            discountRate,
            taxableAmount,
            tax,
            taxRate,
            totalAmount,
          };

          dispatch(setInvoiceById(newInvoice));

          if (showToast) {
            toast.success("Invoice edited successfully!!");
          }

          if (onAddSuccess) {
            onAddSuccess();
          }

          return newInvoice;
        } else {
          console.log("Edit Invoice Error: ");
          if (showToast) {
            toast.success("There is issue in updating invoice!!");
          }
        }
      })
      .then((payload) => {
        if (payload.status === InvoiceStatus.SentRejected) {
          const invoiceStatusPayload = {
            ...payload,
            status:
              payload.status === InvoiceStatus.SentRejected
                ? InvoiceStatus.Draft
                : payload.status,
            meta: "",
          };

          dispatch(updateInvoiceStatus(invoiceStatusPayload));
        }
      })
      .catch((error) => {
        console.error("Edit Invoice Error: ", error);
        if (showToast) {
          toast.error("There is issue in updating invoice");
        }
      })
      .finally(() => {
        dispatch(setInvoiceLoader("editLoader", false));
      });
  };
}

export function deleteInvoice(payload) {
  return async (dispatch, getState) => {
    dispatch(setInvoiceLoader("deleteLoader", true));

    const { invoices } = getState().invoices;

    console.log("Delete Invoice payloadaction: ", payload);

    const dataToSend = {
      ids: [],
      orderBy: "newest",
      ResolvePic: true,
    };

    await apiInvoiceRemove
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          console.log("Remove Employee Response: ", response);

          const tempInvoices = invoices.filter(
            (invoice) => invoice.id !== payload.id
          );

          dispatch(setInvoices(tempInvoices));
          toast.success("Invoice removed successfully!!");
        } else {
          console.log("Delete Invoice Error: ");
          toast.error("Error in removing Invoice!!");
        }
      })
      .catch((error) => {
        console.error("Delete Invoice Error: ", error);
        toast.error("Error in removing Invoice!!");
      })
      .finally(() => {
        dispatch(setInvoiceLoader("deleteLoader", false));
      });
  };
}

export const resetInvoiceSearch = (onlyClientSide = false) => {
  return (dispatch) => {
    const invoiceSearchParams = {
      customer: null,
      categories: [],
      invoice: null,
      invoiceNumber: "",
      billDate: null,
      dueInDays: null,
      statuses: [],
      paymentByCustomerStatuses: [],
      isToday: false,
    };

    if (onlyClientSide) {
      dispatch(setInvoiceSearchParams(invoiceSearchParams));
    } else {
      dispatch(handleInvoiceSearch(invoiceSearchParams));
    }
  };
};

export function handleInvoiceSearch({ pageSize, ...searchParams }) {
  return async (dispatch) => {
    console.log("Invoice Filter Values: ", searchParams);

    await dispatch(setInvoiceSearchParams(searchParams));

    const searchPayload = {
      customerIds: searchParams.customer ? [searchParams.customer.id] : null,
      categories: searchParams.categories,
      invoiceNumbers: searchParams.invoiceNumber
        ? [searchParams.invoiceNumber]
        : null,
      startDate: searchParams.billDate
        ? moment(searchParams.billDate).startOf("day")
        : null,
      dueInDays: searchParams.dueInDays ? searchParams.dueInDays : null,
      statuses: searchParams.statuses ? searchParams.statuses : [],
      paymentByCustomerStatuses: searchParams.paymentByCustomerStatuses
        ? searchParams.paymentByCustomerStatuses
        : [],
      ...(searchParams.invoice
        ? {
            ...(searchParams.invoice.enterKey
              ? { searchText: searchParams.invoice.title }
              : {}),
            ...(!searchParams.invoice.enterKey
              ? { ids: [searchParams.invoice.id] }
              : {}),
          }
        : {}),
      ...(pageSize !== undefined || pageSize !== null ? { pageSize } : {}),
    };

    await dispatch(fetchInvoices(searchPayload));
  };
}

export function assignInvoice(payload) {
  return async (dispatch, getState) => {
    dispatch(setInvoiceLoader("editLoader", true));

    const dataToSend = payload.invoiceAssignments;

    await apiInvoiceAssign
      .post(dataToSend)
      .then((response) => {
        if (response && response.status === 200) {
          const { invoices } = getState().invoices;

          const tempInvoices = invoices.map((invoice) =>
            invoice.id === payload.id ? payload : invoice
          );

          dispatch(setInvoices(tempInvoices));
          dispatch(setInvoiceById(payload));

          toast.success("Invoice assigned successfully!!");
        } else {
          console.log("Assign Invoice Error: ");
          toast.success("There is issue in assigning invoice!!");
        }
      })
      .catch((error) => {
        console.error("Assign Invoice Error: ", error);
        toast.error("There is issue in assigning invoice");
      })
      .finally(() => {
        dispatch(setInvoiceLoader("editLoader", false));
      });
  };
}

export const updateInvoiceStatus = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setInvoiceLoader("editLoader", true));

    const dataToSend = {
      invoiceId: payload.id,
      status: payload.status,
      meta: payload.meta || "",
    };

    await apiInvoiceChangeStatus
      .post(dataToSend)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(setInvoiceById(payload));

          toast.success("Invoice status updated successfully!!");
        } else {
          console.log("Error: Invoice status: Response", response);
          toast.error("There is issue in updating the status of invoice.");
        }
      })
      .then(() => {
        if (
          payload.status === InvoiceStatus.Sent &&
          payload.visibility === JobVisibilityForCustomer.NotVisible
        ) {
          payload = {
            ...payload,
            visibility: JobVisibilityForCustomer.Visible,
          };
          dispatch(updateInvoice(payload, false));
        }
      })
      .catch((error) => {
        console.log("Error: Invoice status: ", error);
        toast.error("There is issue in updating the status of invoice.");
      })
      .finally(() => dispatch(setInvoiceLoader("editLoader", false)));
  };
};

export const generateInvoicePdf = (
  invoice,
  onGeneratePdfComplete,
  download,
  invoiceItemsFromAdd
) => {
  return async (dispatch, getState) => {
    dispatch(setInvoiceLoader("isPdfGenerating", true));

    try {
      let authUserCompany = LocalStorageConstants.getItem(
        "authUserCompany",
        {}
      );

      await dispatch(getCompanyById([authUserCompany.id]));

      const { companyById } = getState().companies;

      await dispatch(getCompanyProfilePic(companyById.profilePic));

      const customerPayload = [invoice.customerId];
      await dispatch(getCustomerById(customerPayload));

      //handle Add Invoice case
      let invoiceItems = getState().invoiceItems.invoiceItems;

      if (!invoiceItemsFromAdd) {
        if (!invoiceItems.length) {
          const invoiceItemsPayload = {
            invoiceId: invoice.id,
          };
          await dispatch(fetchInvoiceItems(invoiceItemsPayload));
        }

        invoiceItems = getState().invoiceItems.invoiceItems;
      } else {
        invoiceItems = invoiceItemsFromAdd;
      }

      const { companyProfilePic } = getState().companies;
      const { customerById } = getState().customers;

      const htmlString = await generateInvoiceTemplate(
        invoice,
        invoiceItems,
        customerById,
        companyById,
        companyProfilePic
      );

      const watermark = {
        text: "Invoice",
        image: companyProfilePic || AppConstants.DEFAULT_PDF_LOGO,
      };

      await generatePdf(
        `${invoice.title}.pdf`,
        htmlString,
        watermark,
        onGeneratePdfComplete,
        download
      );

      if (download) {
        toast.success(`${invoice.title} PDF downloaded successfully.`);
      }
    } catch (error) {
      console.error("Error: PDF Generate: ", error);
      toast.error("Error in generating PDF, please try again.");
    } finally {
      dispatch(setInvoiceLoader("isPdfGenerating", false));
    }
  };
};

export const processAndUploadInvoiceMedia = (payload, isConvert = false) => {
  return async (dispatch, getState) => {
    const { mediasToUpload, mediaLinks } = payload;

    console.log("processAndUploadMedia: mediasToUpload: ", mediasToUpload);

    dispatch(setInvoiceLoader("uploadInvoiceMediaLoader", true));

    console.log("processAndUploadMedia STARTED");
    const uIds = mediasToUpload.map((item) => v4());

    // INIT UPLOAD
    const initRes = await apiInvoiceInitUpload
      .post({ ids: uIds })
      .catch((error) =>
        console.log("processAndUploadMedia: initupload error: ", error)
      );

    console.log("processAndUploadMedia: initRes: ", initRes);

    // UPLOAD
    const uploadMediasPromise = mediasToUpload.map(async (media, index) => {
      console.log("processAndUploadMedia: media: ", media);

      const { mediaBlob, thumbnailBlob } = await getMediaData(media, isConvert);

      // UPLOAD
      try {
        fetch(initRes.data.links[index].m, {
          method: "PUT",
          body: mediaBlob,
          headers: { "Content-Type": media.type },
        });
        fetch(initRes.data.links[index].t, {
          method: "PUT",
          body: thumbnailBlob,
          headers: { "Content-Type": "image/png" },
        });
        fetch(initRes.data.links[index].b, {
          method: "PUT",
          body: mediaBlob,
          headers: { "Content-Type": media.type },
        });
      } catch (error) {
        console.log(
          "===processAndUploadMedia====apiInitUpload error: ==",
          error
        );
        media = null;
      }

      return media;
    });

    let uploadSuccessMedias = [];
    uploadSuccessMedias = await Promise.all(uploadMediasPromise);
    uploadSuccessMedias = uploadSuccessMedias.filter((media) => Boolean(media));

    if (!uploadSuccessMedias.length) {
      toast.error(
        `0 of ${mediasToUpload.length} medias uploaded successfully.`
      );
      dispatch(setInvoiceLoader("uploadInvoiceMediaLoader", false));

      return;
    }

    const newMedias = uploadSuccessMedias.map((media, index) => ({
      id: uIds[index],
      title: media.name,
      fileType: media.type,
      abstract: "",
      link: uIds[index],
      mediaType: payload.mediaType,
      invoiceId: payload.invoiceId,
    }));
    console.log("uploadSuccessMedias",uploadSuccessMedias);
    console.log("newMedias",newMedias);
    
    const medias = [...newMedias, ...mediaLinks].map((media) => {
      return {
        link: media.id,
        mediaType: media.mediaType,
        invoiceId: payload.invoiceId,
        title: media.title,
        fileType: media.fileType,
      };
    });

    const dataToSend = {
      invoiceId: payload.invoiceId,
      medias: medias,
    };

    console.log("processAndUploadMedia: dataToSend: ", dataToSend);

    await apiInvoiceUploadMedia
      .post(dataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          const { invoiceById } = getState().invoices;

          let newMedias = uploadSuccessMedias.map(async (media, index) => {
            const { thumbnailBase64, fileType } = await getMediaData(
              media,
              isConvert
            );

            console.log(
              "======processAppointmentMediaUpload===thumbnailBase64===",
              thumbnailBase64
            );

            return {
              id: uIds[index],
              title: media.name,
              fileType: fileType,
              link: thumbnailBase64,
              mediaType: payload.mediaType,
              invoiceId: payload.id,
            };
          });

          newMedias = await Promise.all(newMedias);

          if (invoiceById) {
            dispatch(
              setInvoiceById({
                ...invoiceById,
                mediaLinks: [...newMedias, ...invoiceById.mediaLinks],
              })
            );
          }

          toast.success(
            `${uploadSuccessMedias.length} of ${mediasToUpload.length} medias uploaded successfully.`
          );
        } else {
          console.error("Upload Media Error: response: ", response);
          toast.error(
            "There is some issues in uploading job media, please try again!"
          );
        }
      })
      .catch((error) => {
        console.error("Upload Media Error: ", error);
        toast.error(
          "There is some issues in uploading job media, please try again!"
        );
      })
      .finally(() => {
        dispatch(setInvoiceLoader("uploadInvoiceMediaLoader", false));
      });
  };
};

export const getInvoiceMediaLinks = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setInvoiceLoader("getInvoiceMediaLoader", true));

    let dataToSend = {
      ids: payload.mediaLinks.map((image) => image.id),
      size: MediaSizeType.Thumbnail,
    };

    console.log("getMediaLinks: dataToSend: ", dataToSend);

    await apiInvoiceGetMediaLink
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          let newMedias = [];
          console.log("media fetch response: ", response.data.links);
          if (response.data.links.length) {
            newMedias = response.data.links.map((link) => {
              let mediaLink = payload.mediaLinks.find(
                (mediaLink) => link.id === mediaLink.id
              );

              if (mediaLink) {
                return {
                  ...mediaLink,
                  link: link.link,
                };
              } else {
                return mediaLink;
              }
            });

            const { invoiceById } = getState().invoices;

            console.log("====getMediaLinks====invoiceById=====", invoiceById);

            const updatedInvoice = {
              ...invoiceById,
              mediaLinks: newMedias,
            };
            dispatch(setInvoiceById(updatedInvoice));

            console.log(
              "====getMediaLinks====updatedInvoice=====",
              updatedInvoice
            );

            console.log("====getMediaLinks====newMedias=====", newMedias);
          }
        } else {
          console.error("Media Load Error: response: ", response);
        }
      })
      .catch((error) => {
        console.error("Media Load Error: ", error);
      })
      .finally(() => {
        dispatch(setInvoiceLoader("getInvoiceMediaLoader", false));
      });
  };
};

export const deleteInvoiceMedia = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setInvoiceLoader("deleteInvoiceMediaLoader", true));

    const docToDeleteId = payload.id;
    const newList = payload.mediaLinks.filter(
      (image) => image.id !== docToDeleteId
    );

    const medias = newList.map((doc) => {
      return {
        link: doc.id,
        invoiceId: payload.invoiceId,
        mediaType: doc.mediaType,
      };
    });

    const dataToSend = {
      invoiceId: payload.invoiceId,
      medias: medias,
    };

    console.log("DELETE MEDIA", dataToSend);
    console.log(docToDeleteId);

    await apiInvoiceUploadMedia
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          console.log("delete media response: ", response);

          const { invoiceById } = getState().invoices;

          const updatedInvoice = {
            ...invoiceById,
            mediaLinks: newList,
          };

          dispatch(setInvoiceById(updatedInvoice));

          toast.success("Media deleted successfully!!!");
        } else {
          console.log("Delete media Error: ", response);
          toast.error("There was an issue with deleting media!!!");
        }
      })
      .catch((error) => {
        console.log("Delete media Error: ", error);
        toast.error("There was an issue with deleting media!!!");
      })
      .finally(() => {
        dispatch(setInvoiceLoader("deleteInvoiceMediaLoader", false));
      });
  };
};

export const getResolvedInvoiceMediasByIds = (
  payload,
  onResolveMediasByIds,
  openMedia = true,
  returnAll = false
) => {
  return async (dispatch, getState) => {
    const { clickedMedia = {}, allMedias = [] } = payload;

    const ids = returnAll
      ? allMedias.map((media) => media.id)
      : clickedMedia
      ? [clickedMedia.id]
      : [];

    const dataToSend = {
      ids: ids,
      size: MediaSizeType.BigScreen,
    };

    dispatch(setInvoiceLoader("getInvoiceMediaLoader", true));

    console.log("getMediaLinks: dataToSend: ", dataToSend);

    await apiInvoiceGetMediaLink
      .post(dataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          console.log("media fetch response: ", response.data.links);

          const mediaLinks = response.data.links;

          if (mediaLinks.length) {
            let allResolvedMedias = [];
            if (returnAll) {
              allResolvedMedias = mediaLinks.map(async (media, index) => {
                let fileType = null;
                let contentType = null;
                try {
                  const resp = await getContentType(media.link);
                  fileType = resp.fileType;
                  contentType = resp.contentType;
                } catch (error) {
                  toast.error(
                    "Media not found. Please delete and upload the media again."
                  );
                  return;
                }

                const isImage = fileType === FileType.Image;

                return {
                  ...media,
                  uri: media.link,
                  thumbnailUri: allMedias[index].link,
                  fileType: fileType,
                  type: contentType,
                  isResolved: true,
                  isImage,
                };
              });

              allResolvedMedias = await Promise.all(allResolvedMedias);
              allResolvedMedias = allResolvedMedias.filter(Boolean);

              onResolveMediasByIds(null, allResolvedMedias, false);
              dispatch(setResolvedInvoiceMedias(allResolvedMedias));
              return;
            }

            const media = mediaLinks.find(
              (_media) => _media.id === clickedMedia.id
            );

            let fileType = null;
            let contentType = null;
            try {
              const resp = await getContentType(media.link);
              fileType = resp.fileType;
              contentType = resp.contentType;
            } catch (error) {
              toast.error(
                "Media not found. Please delete and upload the media again."
              );
              return;
            }

            const isImage = fileType === FileType.Image;
            let resolvedMedia = {
              ...media,
              type: contentType,
              uri: media.link,
              thumbnailUri: clickedMedia.link,
              fileType,
              isResolved: true,
              isImage,
            };

            if (!isImage && openMedia) {
              window.open(resolvedMedia.uri, "_blank");
            }

            allResolvedMedias = mediaLinks.map((media, index) => {
              if (media.id === resolvedMedia.id) {
                return resolvedMedia;
              } else {
                return {
                  ...media,
                  uri: media.link,
                  thumbnailUri: allMedias[index].link,
                  fileType: FileType.Image,
                  isResolved: false,
                  isImage: true,
                };
              }
            });

            allResolvedMedias = await Promise.all(allResolvedMedias);
            allResolvedMedias = allResolvedMedias.filter(Boolean);

            if (onResolveMediasByIds) {
              const isOpeningMedia = openMedia && !isImage;
              onResolveMediasByIds(
                resolvedMedia,
                allResolvedMedias,
                isOpeningMedia
              );
            }

            dispatch(setResolvedInvoiceMedias(allResolvedMedias));
          }
        } else {
          console.log("Media Load Error: response: ", response);
        }
      })
      .catch((error) => {
        console.error("Media Load Error: ", error);
        toast.error("There is some issue in opening media.");
      })
      .finally(() => {
        dispatch(setInvoiceLoader("getInvoiceMediaLoader", false));
      });
  };
};

export const generateInvoiceFromJob = (taskId) => {
  return async (dispatch, getState) => {
    dispatch(setInvoiceLoader("addInvoiceDialogLoader", true));

    try {
      await dispatch(getTaskById(taskId));

      await dispatch(fetchSubTasks({ taskId }));

      let { taskById } = getState().jobs;
      let { subTasks } = getState().subTasks;

      taskById = { ...taskById, invoiceAssignments: taskById.taskAssignments };
      subTasks = subTasks.map((item) => ({
        ...item,
        invoiceItemAssignments: item.subTaskAssignments,
      }));

      await dispatch(
        setAddInvoiceDialogData({
          addInvoiceData: taskById,
          addInvoiceDataItems: subTasks,
        })
      );
    } catch (error) {
      console.error("Error: Generate Invoice: Job: ", error);
    } finally {
      dispatch(setInvoiceLoader("addInvoiceDialogLoader", false));
    }
  };
};

export const generateInvoiceFromQuotation = (offerId) => {
  return async (dispatch, getState) => {
    dispatch(setInvoiceLoader("addInvoiceDialogLoader", true));

    try {
      await dispatch(getQuotationById(offerId));
      await dispatch(fetchQuotationItems({ offerId }));

      let { quotationById } = getState().quotations;
      let { quotationItems } = getState().quotationItems;

      console.log("== generateInvoiceFromQuotation ====", {
        quotationById,
        quotationItems,
      });

      quotationById = {
        ...quotationById,
        invoiceAssignments: quotationById.offerAssignments,
      };
      quotationItems = quotationItems.map((item) => ({
        ...item,
        invoiceItemAssignments: item.offerItemAssignments,
      }));

      await dispatch(
        setAddInvoiceDialogData({
          addInvoiceData: quotationById,
          addInvoiceDataItems: quotationItems,
        })
      );
    } catch (error) {
      console.error("Error: Generate Invoice: Quotation: ", error);
    } finally {
      dispatch(setInvoiceLoader("addInvoiceDialogLoader", false));
    }
  };
};
