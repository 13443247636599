import { Dialog, makeStyles, useMediaQuery } from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";
import React, { useState } from "react";
import InputCNO from "../../library/InputCNO";
import { mdiFormatText } from "@mdi/js";
import HeadingBar from "../HeadingBar/HeadingBar";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import { apiCustomerCompanyCreate } from "../../api";
import { toast } from "react-toastify";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { useFormikErrors } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { toggleAddCompanyDialog } from "../../actions/customerCompanyActions";

function AddCompany({ onAdd }) {
  const [loading, setLoading] = useState(false);

  const tillTablet = useMediaQuery("(max-width: 768px)");

  const dispatch = useDispatch();
  const { isAddCompanyDialog } = useSelector((state) => state.companies);

  const handleClose = () => {
    dispatch(toggleAddCompanyDialog(false));
  };

  const handleAddCompany = async (values) => {
    setLoading(true);

    const companyPayload = {
      title: values.companyName,
      abstract: values.companyDescription,
    };

    await apiCustomerCompanyCreate
      .post(companyPayload)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          toast.success("Company Added Successfully");

          console.log("====AddCompany=====response===", response);

          if (onAdd) {
            onAdd({
              id: response.data,
              ...companyPayload,
            });
          }
        } else {
          toast.error(
            "There is issue while creating company! Please try again!!!"
          );
        }
      })
      .catch((error) => {
        console.error("Add Company Error: ", error);
        toast.error(
          "There is issue while creating company! Please try again!!!"
        );
      })
      .finally(() => setLoading(false));

    formik.resetForm();
    handleClose();
  };

  const validationSchema = yup.object({
    companyName: yup.string().required("Required!!!"),
    companyDescription: yup.string("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      companyName: "",
      companyDescription: "",
    },
    validationSchema,
    onSubmit: (values) => handleAddCompany(values),
  });

  useFormikErrors(formik);

  const styles = useStyles({ tillTablet });

  if (loading) {
    return (
      <>
        <FullScreenLoaderCNO />
      </>
    );
  }

  return (
    <Dialog
      className={styles.container_AddCompany}
      open={isAddCompanyDialog}
      classes={{ paper: styles.paper }}
    >
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <HeadingBar title="Add Company" onClose={handleClose} />
        <InputCNO
          name="companyName"
          label="Name"
          placeholder="Company Name"
          icon={mdiFormatText}
          formik={formik}
          fullWidth
        />
        <InputCNO
          name="companyDescription"
          label="Description"
          placeholder="Enter Description"
          icon={mdiFormatText}
          formik={formik}
          fullWidth
          multiline
        />
        <FormActionButtons
          rightText="Add Company"
          leftClick={handleClose}
          formik={formik}
        />
      </form>
    </Dialog>
  );
}

export default AddCompany;

const useStyles = makeStyles((theme) => ({
  container_AddCompany: {},
  paper: {
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    borderRadius: 10,
    width: "70vw",
    maxWidth: "90vh",
  },
  form: {
    padding: "20px",
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    color: theme.palette.text.secondary,
    "&>*": {
      marginBottom: theme.spacing(2),
    },
  },
}));
