import { InputAdornment, TextField, useTheme, IconButton } from '@material-ui/core';
import Icon from '@mdi/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useStyles } from './SearchInputCNOStyles';
import { mdiClose, mdiMagnify } from '@mdi/js';

function SearchInputCNO({
  onSearch,
  placeholder = "Search..."
}) {

  const theme = useTheme();
  const styles = useStyles();

  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    onSearch(searchKey);
  }, [searchKey]);

  return (
    <Fragment>
      <TextField
        value={searchKey}
        onChange={(e) => {
          const value = e.target.value;
          setSearchKey(value);
        }}
        className={styles.textField}
        variant='outlined'
        margin='dense'
        placeholder={placeholder}
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment>
            <Icon color={theme.colors.SEARCH_INPUT_LEFT_ICON_COLOR} path={mdiMagnify} size='1.5rem' />
          </InputAdornment>,
          endAdornment: <InputAdornment>
            <IconButton onClick={() => setSearchKey("")} size="small">
              <Icon
                color={theme.colors.SEARCH_INPUT_RIGHT_ICON_COLOR}
                path={searchKey && mdiClose}
                size='1.5rem'
              />
            </IconButton>
          </InputAdornment>
        }}
      />
    </Fragment>
  )
}

export default SearchInputCNO
