import { makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';
import RelaxImg from '../../assets/relax.gif';

function NotFound() {

	const fromTablet = useMediaQuery('(min-width: 768px)');

    const useStyles = makeStyles(theme => ({
        wrapper_404: {
            marginTop: theme.spacing(10),
            textAlign: 'center',
        },
        relaxImg: {
            width: fromTablet ? 'auto' : "70%",
            marginBottom: theme.spacing(3)
        },
        notFoundText: {
            fontSize: fromTablet ? 'auto' : '1.1rem'
        }
    }));
    const styles = useStyles();

    return (
        <div className={styles.wrapper_404}>
            <img src={RelaxImg} alt='' className={styles.relaxImg}/>
            <Typography variant="h6" className={styles.notFoundText}>
                No Allocation here, relax and enjoy the break.
            </Typography>
        </div>
    )
}

export default NotFound
