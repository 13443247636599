import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FileType } from '../../utils/mappings';
import ReactPlayer from 'react-player';

function ImageDocumentDisplayCard({ document, containerStyle = {}, imageStyle = {}, onClick, moreMediaCount = 0,index }) {

  // console.log("========ImageDocumentDisplayCard=========document.link=====", document.link);

  const styles = useStyles();

  console.log(`DocumentLink${document.fileType == FileType.Video && document.link}`)
  return (
    <Box
      className={styles.container}
      style={containerStyle}>
        {
          document && document.fileType == FileType.Video ?
          (
            <ReactPlayer
              url={document.link}
              controls
              width="100%"
              height="100%"
              className={styles.reactPlayer}
              autoPlay
              onClick={()=>onClick(index)}
            />
          )
          :
          (
            <LazyLoadImage
              src={document.link || document.gif}
              effect="blur"
              className={styles.image}
              style={imageStyle}
              visibleByDefault={true}
              placeholder={<CircularProgress />}
              alt={document.title || "Image"}
              onClick={()=>onClick(index)}
            />
          )
        }
      

      {
        Boolean(moreMediaCount) && <Box className={styles.moreMediaLayer} onClick={onClick}>
          <Typography className={styles.moreMediaText}>+{moreMediaCount}</Typography>
        </Box>
      }

    </Box>
  )
}

export default ImageDocumentDisplayCard

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    maxHeight: 150,
    minWidth: "100%",
    minHeight: "100%",
    '& [alt]': {
      marginLeft: -16,
      textIndent: 16
    }
  },
  moreMediaLayer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    background: '#00000099',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center"
  },
  moreMediaText: {
    fontSize: '3rem',
    // fontWeight: 'bold'
  }
}))
