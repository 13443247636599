import { makeStyles, useMediaQuery, Dialog } from "@material-ui/core";
import { mdiClose, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { useContentTopSpace } from "../../hooks";
import { useGlobalStore } from "../../providers/GlobalProvider";
import { TOGGLE_RIGHT_SIDEBAR } from "../../utils/actions";
import LeftSidebar from "../LeftSidebar/LeftSidebar";
import MobileHeader from "../MobileHeader";
import RightSidebar from "../RightSidebar/RightSidebar";
import Sidebar from "../Sidebar";

function ScreenView({
  children,
  content,
  secondarySidebar,
  isCustomMobileHeader = false,
  customMobileHeader,
  headerTitle,
  bgColor,
  secondarySidebarWidth = 350,
  isSearchOpenByDefault = false
}) {
  const { globalState, dispatchGlobal } = useGlobalStore();
  const { isRightSidebar, isSearch, isLeftSidebar } = globalState;

  const tillTablet = useMediaQuery("(max-width: 768px)");
  const {serviceById} =useSelector(state => state.services)

  const { isMapSearch } = useSelector((state) => state.taskSearch);

  const handleRightSidebarOpen = () => {
    dispatchGlobal({
      type: TOGGLE_RIGHT_SIDEBAR,
      payload: true,
    });
  };

  const handleRightSidebarClose = () => {
    dispatchGlobal({
      type: TOGGLE_RIGHT_SIDEBAR,
      payload: false,
    });
  };

  const route = useRouteMatch();

  const isMapPath = route.path === "/map";
  // useEffect(() => {
  //     if (isMapPath) {
  //         handleRightSidebarClose();
  //     }
  // }, []);

  const isHomePath = route.path.includes("/home");
  const [isSearchOpen, setIsSearchOpen] = useState(isSearchOpenByDefault);

  const isJobDetailsPath = route.path.includes("/job/");
  const isProfileDetailsPath = route.path.includes("/profile/");
  const isChatPath = route.path.includes("/chat");
  const isCallPath = route.path.includes("/call");
  const isSettingsPath = route.path.includes("/settings");
  const isEmployeeDetailsPath = route.path.includes("/employee/");
  const isEmployeesPath = route.path.includes("/employee");
  const isServiceProviderDetailsPath = route.path.includes("/service-provider/");
  const isServiceDetailsPath = route.path.includes("/service/");
  const isPostDetailsPath = route.path.includes("/post/");
  const isCustomersPath = route.path.includes("/customer");
  const isCustomerDetailPath = route.path.includes("/customer/");
  const isCompaniesPath = route.path.includes("/compan");
  const isMyCompanyPath = route.path.includes("/mycompany");
  const isQuotationDetailsPath = route.path.includes("/quotation/");
  const isInvoiceDetailsPath = route.path.includes("/invoice/");
  const isAppointmentDetailsPath = route.path.includes("/appointment/");
  const isCalendarPath = route.path.includes("/calendar/");
  const isCalendarDetailsPath = route.path.includes("/calendar/");
  const isLandingPagePath = route.path === "/";
  const isDashboardPath = route.path === "/dashboard";
  const isEventDetailsPath = route.path.includes("/event/");
  const isFormBuilderPath = route.path.includes("/forms/builder");
  const isFormsDataDetailsPath = route.path.includes("/forms/details");
 // const isTestPath = route.path.includes("/test");
  const isResultPath = route.path.includes("/result");


  useEffect(() => {
    if (!tillTablet && (isHomePath || isMapPath)) {
      setIsSearchOpen(isSearch);
    } else {
      setIsSearchOpen(isSearch);
    }

    if (
      isJobDetailsPath ||
      isProfileDetailsPath ||
      isChatPath ||
      isCallPath ||
      isSettingsPath ||
      isEmployeeDetailsPath ||
      isServiceProviderDetailsPath ||
      isServiceDetailsPath ||
      isPostDetailsPath ||
      isCustomerDetailPath ||
      isCompaniesPath ||
      isMyCompanyPath ||
      isQuotationDetailsPath ||
      isInvoiceDetailsPath ||
      isAppointmentDetailsPath ||
      isCalendarDetailsPath ||
      isLandingPagePath ||
      isDashboardPath ||
      isEventDetailsPath ||
      isFormBuilderPath ||
      isFormsDataDetailsPath ||
     // isTestPath ||
      isResultPath ||
      isHomePath
    ) {
      setIsSearchOpen(false);
    }
  }, [
    isMapSearch,
    isHomePath,
    tillTablet,
    isMapPath,
    isSearch,
    isJobDetailsPath,
    isProfileDetailsPath,
    isCustomerDetailPath,
    isCompaniesPath,
    isChatPath,
    isCallPath,
    isSettingsPath,
    isEmployeeDetailsPath,
    isServiceProviderDetailsPath,
    isServiceDetailsPath,
    isPostDetailsPath,
    isMyCompanyPath,
    isQuotationDetailsPath,
    isInvoiceDetailsPath,
    isAppointmentDetailsPath,
    isCalendarPath,
    isCalendarDetailsPath,
    isLandingPagePath,
    isDashboardPath,
    isEventDetailsPath,
    isFormBuilderPath,
    isFormsDataDetailsPath,
    isHomePath,
    bgColor
  ]);

  console.log("======pathpathpath========isSearchOpen====", isSearchOpen);

  const [contentLeftPadding, setContentLeftPadding] = useState(0);
  useEffect(() => {
    let leftPadding = 0;
    if (isLeftSidebar && isSearchOpen) {
      console.log("open: ", 1);
      leftPadding = 240 + 350;
      // leftPadding = 240 + 350 + 16;
    }

    if (isLeftSidebar && !isSearchOpen) {
      console.log("open: ", 2);
      leftPadding = tillTablet ? 8 : 240;
      // leftPadding = tillTablet ? 8 : 240 + 16;
    }

    if (!isLeftSidebar && isSearchOpen) {
      console.log("open: ", 3);
      leftPadding = tillTablet ? 8 : 56 + 350;
      // leftPadding = tillTablet ? 8 : 56 + 350 + 16;
    }

    if (!isLeftSidebar && !isSearchOpen) {
      console.log("open: ", 4);
      leftPadding = tillTablet ? 8 : 56;
      // leftPadding = tillTablet ? 8 : 56 + 16;
    }

    // if (!secondarySidebar) {
    //   leftPadding = leftPadding - 350;
    // }

    const customWidthDiff = secondarySidebarWidth - 350;

    leftPadding = leftPadding + customWidthDiff;

    setContentLeftPadding(leftPadding);


  }, [isLeftSidebar, isSearchOpen, tillTablet]);

  const [contentRightPadding, setContentRightPadding] = useState(0);
  useEffect(() => {
    let rightPadding = 0;

    if (isRightSidebar) {
      rightPadding = 240 + 16;
    } else {
      rightPadding = tillTablet ? 8 : 42;
    }

    setContentRightPadding(rightPadding);
  }, [tillTablet, isRightSidebar]);

  let contentTopSpace = useContentTopSpace();

  const styles = useStyles({
    tillTablet,
    isLeftSidebar,
    contentLeftPadding,
    contentRightPadding,
    isHomePath,
    contentTopSpace,
    bgColor
  });

  console.log("ScreenView: ", { tillTablet, contentLeftPadding })

  return (
    <div className={styles.container}>
      {!isCallPath &&
        !isLandingPagePath &&
        tillTablet &&
        !isHomePath &&
        (isCustomMobileHeader ? (
          customMobileHeader
        ) : (
          <MobileHeader title={headerTitle} />
        ))}

      {/* {
        (tillTablet && !isHomePath) &&
        <MobileHeader title={headerTitle} />
      } */}

      {!isCallPath && !isLandingPagePath && <LeftSidebar />}
      {isSearchOpen && !tillTablet && secondarySidebar && (
        <Sidebar open={isSearchOpen} fullCollapsed={true} width={secondarySidebarWidth}>
          {secondarySidebar}
        </Sidebar>
      )}
      {!isCallPath &&
        !isLandingPagePath &&
        isSearchOpen &&
        tillTablet &&
        secondarySidebar && (
          <Dialog
            className={styles.container_AddCompany}
            open={isSearchOpen}
            classes={{ paper: styles.paper }}
          >
            {secondarySidebar}
          </Dialog>
        )}
      <div id="content" className={styles.content}>
        {children || content}
      </div>
      {!isCallPath && !isLandingPagePath && !isRightSidebar && !tillTablet && (
        <Icon
          onClick={handleRightSidebarOpen}
          className={styles.menuOpen}
          path={mdiMenu}
          size="2rem"
        />
      )}
      {!isCallPath && !isLandingPagePath && isRightSidebar && (
        <RightSidebar>
          <Icon
            onClick={handleRightSidebarClose}
            className={styles.menuClose}
            path={mdiClose}
            size="3rem"
          />
        </RightSidebar>
      )}
    </div>
  );
}

export default ScreenView;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    paddingTop: ({ contentTopSpace }) => contentTopSpace,
    background:theme.colors.SEARCH_SIDEBAR_BACKGROUND,
    
  },
  content: {
    flex: 1,
    width: "calc(100vw - 100px)",
    minHeight: ({ tillTablet }) =>
      `calc(100vh - ${tillTablet ? "40px" : "60px"})`,
    color: theme.palette.text.secondary,
    background: ({ bgColor }) => bgColor || theme.colors.APP_BACKGROUND_COLOR,
    paddingBottom: 20,
    paddingRight: ({ contentRightPadding, tillTablet }) =>
      tillTablet ? 0 : contentRightPadding,
    paddingLeft: ({ contentLeftPadding, tillTablet }) => {

      console.log("ScreenView: useStyles: ", { contentLeftPadding, tillTablet })

      return tillTablet ? 0 : contentLeftPadding

    },
    margin: "0 auto",
  },
  menuOpen: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1.5),
    marginRight: 10,
    position: "fixed",
    top: 50,
    right: 0,
  },
  menuClose: {
    padding: 10,
    color: theme.palette.text.secondary,
  },
  paper: {
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    borderRadius: 10,
    margin: ({ tillTablet }) => (tillTablet ? 8 : 32),
    width: "70vw",
    maxWidth: "90vh",
  },
}));
