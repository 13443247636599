import { isArrayEmpty, isObject } from "../../../utils/Helpers";
import * as Yup from 'yup';

export const getFormikInitialValues = (json) => {
  return json.sections.reduce((result, item) => {

    const fieldsObject = item.fields.reduce((res, field) => {
      if (!field.name) return res;

      if (typeof field.name === 'string') {
        res = {
          ...res,
          [field.name]: field?.attributes?.defaultValue || ''
        }
      }

      if (isObject(field.name)) {
        const nameObjects = Object.keys(field.name).reduce((result, nameKey) => {
          return {
            ...result,
            [field.name[nameKey]]: field?.attributes?.defaultValue || ''
          }
        }, {});

        res = {
          ...res,
          ...nameObjects
        }

      }

      return res;

    }, {})

    return {
      ...result,
      ...fieldsObject
    }

  }, {});
}

function getAllFields(jsonData) {
  // Initialize an empty array to store all fields
  const allFields = [];

  // Iterate through the "sections" array in the JSON data
  for (const section of jsonData.sections) {
    // Check if the section has a "fields" property
    if (section.fields && Array.isArray(section.fields)) {
      // Concatenate the "fields" array of the section to the allFields array
      allFields.push(...section.fields);
    }
  }

  // Return the array of all fields
  return allFields;
}

// Custom Yup validation function to check date and time
const dateAndTimeValidator = (startDateTime, endDateTime, message) => {
  return Yup
    .mixed()
    .test(
      'dateAndTime',
      message,
      function (value) {
        const startDate = new Date(startDateTime);
        const endDate = new Date(endDateTime);
        const inputDate = new Date(value);
        return startDate <= inputDate && inputDate <= endDate;
      }
    );
};

export const generateYupValidation = (json) => {

  const fieldsJson = getAllFields(json);

  let yupSchema = {};

  for (const field of fieldsJson) {

    const fieldName = field.name;

    const fieldRules = field.validations;

    let fieldSchema;

    switch (fieldRules.type) {
      case 'string': {
        fieldSchema = Yup.string();

        if (fieldRules.maxLength && fieldRules.maxLength.value) {
          fieldSchema = fieldSchema.max(fieldRules.maxLength.value, fieldRules.maxLength.message || `Maximum ${fieldRules.maxLength.value} characters are allowed.`)
        }

        if (fieldRules.minLength && fieldRules.minLength.value) {
          fieldSchema = fieldSchema.min(fieldRules.minLength.value, fieldRules.minLength.message || `Atleast ${fieldRules.minLength.value} characters are required.`);
        }

        if (fieldRules.email && fieldRules.email.value) {
          fieldSchema = fieldSchema.email(fieldRules.email.message || "Email is invalid.");
        }

        if (fieldRules.phoneRegex && fieldRules.phoneRegex.value) {
          fieldSchema.matches(
            new RegExp("^[0-9]{10}$"),
            fieldRules.phoneRegex.message || 'Invalid phone number format'
          );
        }

        if (fieldRules.lowercaseOnly && fieldRules.lowercaseOnly.value) {
          fieldSchema.matches(
            new RegExp("^[a-z]+$"),
            fieldRules.lowercaseOnly.message || 'Only lowercase [a-z] letters are allowed.'
          );
        }

        break;
      }

      case 'date': {
        fieldSchema = Yup.date();

        if (fieldRules.min) {
          fieldSchema.min(new Date(fieldRules.min.value), fieldRules.min.message || 'Date is too early');
        }

        if (fieldRules.max) {
          fieldSchema.max(new Date(fieldRules.max.value), fieldRules.max.message || 'Date is too late');
        }

        if (fieldRules.reference) {
          const combinedDateTime = Yup.ref(fieldRules.reference).concat(' ', Yup.ref(fieldName));

          // Use references from the JSON schema for end date-time
          const endDateTimeField = jsonSchema[fieldRules.reference].reference;
          const combinedEndDateTime = Yup.ref(endDateTimeField).concat(' ', Yup.ref(fieldName));

          // Add a custom validation rule to check combined date-time
          fieldSchema.concat(
            dateAndTimeValidator(
              combinedDateTime,
              combinedEndDateTime,
              'Start date and time must be before end date and time'
            )
          );
        }

        break;
      }

      case 'time': {
        fieldSchema = Yup
          .string()
          .matches(
            /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/,
            fieldRules.message || 'Invalid time format'
          );
        break;
      }

      case 'array': {

        fieldSchema = Yup.array();

        if (fieldRules.required && fieldRules.required.value) {
          fieldSchema.test(
            'atLeastOne',
            fieldRules.required.message || 'Select at least one item',
            (value) => value && value.length > 0
          );
        }

        if (!isArrayEmpty(fieldRules.options.value)) {
          console.log("getValidationSchema: options: ", fieldRules.options.value)
          fieldSchema.of(
            Yup.string().oneOf(fieldRules.options.value, "Invalid option selected")
          )
          // fieldSchema.test(
          //   'validOptions',
          //   fieldRules.options.message || 'Invalid option selected',
          //   (value) => {

          //     console.log("getValidationSchema: value: ", value)

          //     if (!value || !Array.isArray(value)) {
          //       return true; // No selection, no error
          //     }

          //     // Check if all selected options are valid
          //     return value.every((item) => fieldRules.options.includes(item));
          //   }
          // );
        }

        console.log("getValidationSchema: fieldSchema: ", fieldSchema)

        break;
      }

      case 'object': {

        fieldSchema = Yup.object();

        console.log("getValidationSchema: fieldSchema: ", fieldSchema)

        break;
      }

      default:
        fieldSchema = Yup.string();
        break;
    }

    if (fieldRules.required && fieldRules.required.value) {
      fieldSchema = fieldSchema.required(fieldRules.required.message || "This field is required.");
    }

    yupSchema[fieldName] = fieldSchema;

  }

  console.log("getValidationSchema: yupSchema::", yupSchema);

  const finalSchema = Yup.object().shape(yupSchema);

  return finalSchema;

}
