import { Typography } from '@material-ui/core';
import { mdiFormatText } from '@mdi/js';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import FormActionButtons from '../FormActionButtons';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { deleteQuotation, updateQuotation, updateQuotationStatus } from '../../actions/quotationActions';
import { QuotationStatus } from '../../utils/mappings';
import { getQuotationRejectHtmlString } from '../../pdfTemplates/templates';

function QuotationRejectDialog({ open, onClose, quotation, closeMoreActions }) {

  const rejectReasonRef = quotation ? quotation.description.slice(0, 20) : "";

  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { allQuotationLoader: { editLoader } } = useSelector(state => state.quotations);

  const formik = useFormik({
    initialValues: {
      rejectReason: ""
    },
    validationSchema: yup.object({
      rejectReason: yup.string().required('Confirm Text is required')
    }),
    onSubmit: (values) => handleReject(values)
  });

  const handleReject = (values) => {

    const updatePayload = {
      ...quotation,
      status: QuotationStatus.SentRejected,
      meta: getQuotationRejectHtmlString(quotation, values.rejectReason)
    };

    console.log("======updatePayload====", updatePayload);

    dispatch(updateQuotationStatus(updatePayload));

    onClose();

  };

  const handleClose = () => {

    onClose();

    if (closeMoreActions) {
      closeMoreActions();
    }

  };

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Reject Quotation"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name='rejectReason'
          label={`Are you sure, you want to reject?`}
          formik={formik}
          placeholder={"Please provide reasons for rejection."}
          icon={mdiFormatText}
          fullWidth
          autoFocus
        />

        <FormActionButtons
          rightText="Reject"
          formik={formik}
          leftClick={onClose}
          closeMoreActions={closeMoreActions}
        />

      </form>
    </DialogCNO>
  )
}

export default QuotationRejectDialog
