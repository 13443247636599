import React, { useEffect, useRef } from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { useDispatch, useSelector } from 'react-redux';
import { setMessageText } from '../../actions/chatActions';

function EmojiPicker({ props = {}, toggleEmojiPicker, setText, onChange }) {
  const { text } = props;
  const dispatch = useDispatch();
  const { messageText } = useSelector(state => state.chats);

  const emojiPickerRef = useRef(null);

  const onSelectEmoji = (emoji) => {
    console.log("selected emoji: ", emoji.native, emoji);
    typeof setText === 'function' && setText(`${text || ''} ${emoji.native}`);
    typeof onChange === 'function' && onChange(emoji.native);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        // Click occurred outside of the emoji picker, close the picker
        toggleEmojiPicker();
      }
    };

    // Add event listener to handle clicks outside of the emoji picker
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleEmojiPicker]);

  return (
    <div ref={emojiPickerRef}>
      <Picker
        style={{
          position: 'absolute',
          right: "5px",
          zIndex: 10000,
          borderTop: '0px',
          bottom:"30%",
          height:250,
          borderRadius:"10px",
          overflow:'hidden',
          marginBottom:'20px'
        }}
        title=""
        emoji=""
        showPreview={false}
        showSkinTones={false}
        onSelect={onSelectEmoji}
      />
    </div>
  );
}

export default EmojiPicker;
