import React, { useEffect } from 'react'
import { InputCNO } from '../../../../library';
import { getNestedObjectValue } from '../../../../utils/Helpers';

function FormInput({
  formik,
  nameKey = 'name',
  label,
  labelKey = 'label',
  placeholder,
  onChange,
  ...rest
}) {

  const handleOnChange = (e) => {
    const value = e.target.value;

    formik.setFieldValue(labelKey, value);

    typeof onChange === 'function' && onChange(value);

  }

  const labelValue = getNestedObjectValue(formik.values, labelKey);

  useEffect(() => {

    if (!labelValue) return;

    let name = labelValue.split(' ').join('');
    name = name.slice(0, 1).toLowerCase() + name.slice(1);

    formik.setFieldValue(nameKey, name);

  }, [labelValue])


  return (
    <InputCNO
      name={labelKey}
      label={label}
      placeholder={placeholder}
      formik={formik}
      onChange={handleOnChange}
      fullWidth
      {...rest}
    />
  )
}

export default FormInput