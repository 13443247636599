import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCellphone, mdiDirections, mdiEmail, mdiFormatText, mdiHome, mdiWeb } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { useStyles } from './CompanyContactDetailsCardStyles';
import { useDispatch, useSelector } from 'react-redux';
import DialogCNO from '../../library/DialogCNO';
import * as yup from 'yup';
import { useFormik } from 'formik';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import RightsManager from '../../utils/RightsManager';
import { updateCompanyDetails } from '../../actions/customerCompanyActions';
import { getUserCountry } from '../../utils/Helpers';
import PhoneInputCNO from '../../library/PhoneInputCNO';

function EditContactDetails({
  open,
  onClose,
  profile,
  editablePropertyName,
}) {
  const styles = useStyles();

  const country = getUserCountry();

  const {
    allCompaniesLoader: { editLoader }
  } = useSelector(state => state.companies);

  const [locationData, setLocationData] = useState([]);

  const label = {
    'address': 'Address',
    'email': 'Email',
    'mobile': 'Mobile',
    'website': 'Website'
  };
  const placeholder = {
    'address': 'Enter address',
    'email': 'Enter email',
    'mobile': 'Enter mobile',
    'website': 'Enter website'
  };

  const getValidation = (property) => {

    const validation = {
      'address': yup.object().required("Required!!!").nullable(),
      'email': yup.string().email('Please enter a valid email!').required("Required!!!"),
      'phone': yup.string().required("Required!!!"),
      'website': yup.string().required("Required!!!"),
    };

    return validation[property];

  };

  const getInitialValues = (property) => {

    const initialValues = {
      'address': {
        label: profile.address,
        value: locationData
      },
      'email': profile.email,
      'phone': profile.phone,
      'website': profile.website
    };

    return initialValues[property];

  };

  useEffect(() => {

    if (editablePropertyName === 'address' && profile.address) {
      const getLocation = async () => {
        await geocodeByAddress(profile.address)
          .then(results => {
            setLocationData(results);
            console.log("jobLocation: ", results);
          })
          .catch(error => {
            console.error("Location Error: ", error);
            toast.error("Location fetch error!!!");
          });
      };

      if (window.google && window.google.maps) {
        getLocation();
      }
    }

  }, [profile.address]);

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    [editablePropertyName]: getValidation(editablePropertyName),
  });

  const formik = useFormik({
    initialValues: {
      [editablePropertyName]: getInitialValues(editablePropertyName),
    },
    validationSchema,
    onSubmit: values => handleUpdate(values)
  });

  const handleUpdate = async (values) => {

    let latitude = 0;
    let longitude = 0;
    if (editablePropertyName === 'address') {
      await geocodeByAddress(values.address.label)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          latitude = lat;
          longitude = lng;
        });
    }

    let dataToSend = { ...profile };

    if (editablePropertyName === 'address') {
      dataToSend.address = values.address.label;
      dataToSend.lat = latitude.toString();
      dataToSend.lng = longitude.toString();
    }
    else {
      dataToSend = {
        ...profile,
        [editablePropertyName]: values[editablePropertyName]
      };
    }

    dispatch(updateCompanyDetails(dataToSend));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Contact Details"
      loading={editLoader}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>

        {
          editablePropertyName === 'address' &&
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            selectProps={{
              styles: {
                option: (provided) => ({
                  ...provided,
                  color: 'brown',
                }),
              },
              value: formik.values[editablePropertyName],
              onChange: (value) => formik.setFieldValue(editablePropertyName, value)
            }}
          />
        }

        {
          (editablePropertyName === 'email' ||
            editablePropertyName === 'website'
          ) &&
          <InputCNO
            name={editablePropertyName}
            label={label[editablePropertyName]}
            formik={formik}
            placeholder={placeholder[editablePropertyName]}
            icon={mdiFormatText}
            fullWidth
          />
        }

        {
          editablePropertyName === 'phone' &&
          <PhoneInputCNO
            value={formik.values.phone}
            onChange={(value) => formik.setFieldValue('phone', value)}
            showHelperText={formik.errors.phone}
            helperText={formik.errors.phone}
            onValidatePhone={(error) => {
              if (!!error) formik.setFieldError('phone', error)
            }}
          />
        }

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )
};

function CompanyContactDetailsCard({ profile, isActive }) {

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const styles = useStyles({ tillTablet });

  const theme = useTheme();

  const isUserAdmin = RightsManager.isAdmin();

  const [editablePropertyName, setEditablePropertyName] = useState(null);
  const [isContactDetailsEditable, setIsContactDetailsEditable] = useState(false);

  return (
    <div className={styles.container_CompanyContactDetailsCardStyles}>
      <div className={styles.title_wrapper}>
        <Typography className={styles.title}>Contact Details</Typography>
        <a
          href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${profile.address}`}
          target="_blank"
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
        >
          <Icon
            path={mdiDirections}
            size='2rem'
            className={styles.icon}
            color={theme.colors.GET_DIRECTION_ICON_COLOR}
          />
        </a>
      </div>
      <div className={styles.content}>
        <div
          className={styles.row}
          onClick={() => {
            setIsContactDetailsEditable(true);
            setEditablePropertyName('website');
          }}
        >
          <Icon path={mdiWeb} size="1.5rem" className={styles.icon} />
          <Typography>{profile.website || 'Click to add Website'}</Typography>
        </div>
        <div
          className={styles.row}
          onClick={() => {
            setIsContactDetailsEditable(true);
            setEditablePropertyName('address');
          }}
        >
          <Icon path={mdiHome} size="1.5rem" className={styles.icon} />
          <Typography>{profile.address || 'Click to add Address'}</Typography>
        </div>
        <div
          className={styles.row}
          onClick={() => {
            setIsContactDetailsEditable(true);
            setEditablePropertyName('email');
          }}
        >
          <a href={`mailto:${profile.email}`}>
            <Icon path={mdiEmail} size="1.5rem" className={styles.icon} />
          </a>
          <Typography>{profile.email || 'Click to add Email'}</Typography>
        </div>
        <div
          className={styles.row}
          onClick={() => {
            setIsContactDetailsEditable(true);
            setEditablePropertyName('phone');
          }}
        >
          <a href={`tel:${profile.phone}`}>
            <Icon path={mdiCellphone} size="1.5rem" className={styles.icon} />
          </a>
          <Typography>{profile.phone || 'Click to add Mobile'}</Typography>
        </div>
      </div>

      {
        isContactDetailsEditable &&
        (isUserAdmin) &&
        isActive &&
        <EditContactDetails
          open={isContactDetailsEditable}
          onClose={() => setIsContactDetailsEditable(false)}
          profile={profile}
          editablePropertyName={editablePropertyName}
        />
      }

    </div >
  )
}

export default CompanyContactDetailsCard
