import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import NavbarWrapper from '../../../common/components/Navbar';
import Drawer from '../../../common/components/Drawer';
// import Button from '../../../common/components/Button';
import Logo from '../../../common/components/UIElements/Logo';
import HamburgMenu from '../../../common/components/HamburgMenu';
import ScrollSpyMenu from '../../../common/components/ScrollSpyMenu';
import { Container } from './navbar.style';
import LoginModal from '../LoginModal';

import LogoImageDark from '../../../assets/nameLogoBlack.png';
import LogoImage from '../../../assets/nameLogo.png';

import { DrawerContext } from '../../../common/contexts/DrawerContext';

import data from '../../../common/data/LandingPage/MenuItems';
import Icon from '@mdi/react';
import { mdiBrightness4, mdiBrightness5 } from '@mdi/js';
import DialogCNO from '../../../library/DialogCNO';
import { useThemeStore } from '../../../providers/ThemeProvider';
import { ThemeType } from '../../../utils/mappings';
import { IconButton, makeStyles, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setLPLoginModalDefaultTab, toggleLPLoginModal } from '../../../actions/appActions';
import RegisterPromoModal from '../../RegisterPromoModal';


const Navbar = ({ navbarStyle, logoStyle, buttonStyle, isDrawerVisible }) => {

  const { state, dispatch } = useContext(DrawerContext);

  const reduxDispatch = useDispatch();
  const { isLPLoginModalOpen, loginModalDefaultTab } = useSelector(state => state.app);

  const [isRegisterPromoModal, setIsRegisterPromoModal] = useState(false);

  const closeLoginModal = () => {
    reduxDispatch(toggleLPLoginModal(false));
    reduxDispatch(setLPLoginModalDefaultTab('loginForm'));
  };

  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const { themeState, dispatchTheme } = useThemeStore();
  const { theme } = themeState;

  const isDarkTheme = theme === ThemeType.Dark;

  const toggleTheme = () => {
    const themeToSet = isDarkTheme ? ThemeType.Light : ThemeType.Dark;
    dispatchTheme({
      type: "TOGGLE_THEME",
      payload: themeToSet
    });
  };

  const styles = useStyles();

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Logo
          href="/"
          logoSrc={isDarkTheme ? LogoImage : LogoImageDark}
          title="meFolia"
          logoStyle={{
            ...logoStyle,
            marginLeft: isDarkTheme ? '-38px !important' : '-16px !important',
            width: isDarkTheme ? ['280px', '280px'] : ['200px', '200px']
          }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <Button
            variant="textButton"
            onClick={handleSearchModal}
            icon={<Icon path={mdiMagnify} size={"4rem"} />}
            aria-label="search"
          /> */}
          <IconButton
            onClick={toggleTheme}
            style={{
              marginRight: '20px',
            }}
          >
            <Icon
              path={theme === ThemeType.Dark ? mdiBrightness4 : mdiBrightness5}
              size={"2rem"}
            />
          </IconButton>

          <Button
            color='primary'
            type='submit'
            variant='contained'
            className={styles.loginBtn}
            onClick={() => {

              // setIsRegisterPromoModal(true);
              reduxDispatch(toggleLPLoginModal(true));
              reduxDispatch(setLPLoginModalDefaultTab('registerForm'));
            }}
          >
            <pre>Free Trial</pre>
          </Button>

          <Button
            color='primary'
            type='submit'
            variant='contained'
            className={styles.freeTrialBtn}
            onClick={() => {
              reduxDispatch(toggleLPLoginModal(true));
              reduxDispatch(setLPLoginModalDefaultTab('loginForm'));
            }}
          >
            Login
          </Button>

          {/* <Button
            variant="textButton"
            onClick={() => setIsLoginModal(true)}
            // icon={<Icon path={mdiAccount} size={"4rem"} />}
            title={'Login'}
            style={{
              color: '#ffffff',
              background: '#10ac84',
              borderRadius: '5px',
              padding: '5px 10px',
              minHeight: 0,
              marginRight: '20px',
            }}
            aria-label="login"
          /> */}

          {
            isDrawerVisible &&
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                menuItems={data.menuItems}
                drawerClose={true}
                offset={-100}
              />
            </Drawer>
          }

        </div>

        <DialogCNO
          open={isLPLoginModalOpen}
          onClose={closeLoginModal}
          isClickAwayClose={true}
          // maxHeight={'300px'}
          // height="300px"
          maxHeight="80%"
          width="50%"
          dialogTitle={loginModalDefaultTab === "loginForm" ? 'Log in' : "Register your company"}
        >
          <LoginModal />
        </DialogCNO>

      </Container>

      {
        isRegisterPromoModal &&
        <RegisterPromoModal
          open={isRegisterPromoModal}
          onClose={() => setIsRegisterPromoModal(false)}
        />
      }

    </NavbarWrapper>
  );
};

// Navbar style props
Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  wrapperStyle2: PropTypes.object,
  isDrawerVisible: PropTypes.bool
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
  },
  logoStyle: {
    width: ['280px', '280px'],
  },
  buttonStyle: {
    minHeight: '70px',
    color: '#fff',
  },
  isDrawerVisible: true
};

export default Navbar;

const useStyles = makeStyles(theme => ({

  loginBtn: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    background: '#00bdbd ',
    fontWeight: 'bold',
    marginRight: '20px',
    fontSize: '0.9rem',
    color: 'white',
    '&:hover': {
      boxShadow: `2px 2px 6px -1px ${theme.palette.common.white}`,
    }
  },
  registerBtn: {
    border: `2px solid ${theme.colors.FORM_CANCEL_BUTTON_BORDER_COLOR}`,
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    marginRight: 10,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
  },

  freeTrialBtn: {
    border: `2px solid ${theme.colors.FORM_CANCEL_BUTTON_BORDER_COLOR}`,
    background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
    marginRight: 10,
    fontWeight: 'bold',
    fontSize: '0.9rem',
    color: 'white',
    '&:hover': {
      boxShadow: `2px 2px 6px -1px ${theme.palette.common.white}`,
    }
  },

}))
