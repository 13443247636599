import { AppConstants } from "../../../utils/AppConstants";
import LocalStorageConstants from "../../../utils/LocalStorageConstants";
import { Currency, CurrencyHTMLEntities, DeepLinkDomainPrefixes } from "../../../utils/mappings";

// export const defaultMessageTop = (quotation) => `Hello ${quotation.customerText ? quotation.customerText : ""}\n\nThank you for the opportunity on the ${quotation.title} job.\n\nPlease find below summary of the Quotation for your kind reference.`;

export const defaultMessageTop = (quotation, offerEmailTemplate) => {

  offerEmailTemplate = offerEmailTemplate ? offerEmailTemplate : AppConstants.DefaultQuotationEmailTemplate;

  return offerEmailTemplate
    .replace(/<<Name>>/g, quotation.customerText ? quotation.customerText : "")
    .replace(/<<Title>>/g, quotation.title);

};

const messageBottom = (emailSignatureTemplate) => {

  const authUserCompany = LocalStorageConstants.getItem('authUserCompany');

  emailSignatureTemplate = emailSignatureTemplate ? emailSignatureTemplate : AppConstants.DefaultEmailSignatureTemplate;

  return `<div style="color: #4e4e4e; margin-right: 10px; margin-top: 20px; white-space: pre-line;">
      ${emailSignatureTemplate.replace(/<<FromName>>/, authUserCompany.title)}
    </div>`;

};

export const getQuotationSendSummaryHtmlString = (quotation, quotationItems, emailData) => {

  const authUserCompany = LocalStorageConstants.getItem('authUserCompany');
  const currencyType = LocalStorageConstants.getItem('currencyType', Currency.GBP);
  const currency = CurrencyHTMLEntities[currencyType];

  // const messageTop = `
  //     <div style="color: #4e4e4e;">
  //       <p>
  //       Hello ${quotation.customerText ? quotation.customerText : ""},
  //       </p>
  //       <br/>
  //       <p>
  //         Thank you for the opportunity on the ${quotation.title} job.
  //       </p>
  //       <br/>
  //       <p>
  //         Please find below summary of the Quotation for your kind reference.
  //       </p>
  //       <br/>
  //     </div>
  //   `;

  emailData = emailData ? defaultMessageTop(quotation, emailData) : defaultMessageTop(quotation);

  const messageTop = `<div style="color: #4e4e4e; white-space: pre-line ">${emailData}</div>`;

  const quotationItemsString = quotationItems.length ? quotationItems.map((item, index) => (`
                <div style="display: flex; padding: 16px; font-size: 14px;">
                      <div style="flex: 1;">
                        <div style="color: #4e4e4e;">${item.title}</div>
                        <pre style=" white-space: pre-line; margin: 0; margin-top: 10px; padding-left: 16px; font-family: Arial, Helvetica, sans-serif; font-size: 12px; color: #8f8f8f; ">
                          ${item.description}
                        </pre>
                      </div>
                      <div style="flex: 0.2; text-align: right; color: #4e4e4e; ">${item.amount}</div>
                    </div>
                    ${quotationItems.length - 1 === index ? '' : '<div style="height: 2px; background-color: #d4d4d4; margin-left: 2%; margin-right: 2%; "></div>'}
                    `)).join("\n")
    : '<div style="display: flex; padding: 16px; font-size: 14px;">No item found.</div>';

  const quotationSummaryTable = `
      <div>
                <div style="display: flex; background: #eaeaea; color: #5e5e5e; margin-top: 16px; border: 2px solid #d9d9d9; border-radius: 6px; padding: 8px 12px">
                    <div style="flex: 1; font-weight: 700; font-size: 14px;">Item</div>
                    <div style="flex: 0.2; text-align: right; font-weight: 700; font-size: 14px; margin-right: 12px;">Amount</div>
                </div>

                ${quotationItemsString}

                <div style="height: 2px; background-color: #9e9e9e; "></div>
            </div>
    `;

  const totalSection = `
    <div style="color: #4e4e4e; flex: 1">
        <div style="text-align: right; display: flex; margin-right: 16px; margin-bottom: 16px; ">
            <span style="flex: 1; font-size: 15px; ">Amount</span>
            <span style="flex: 0.2; text-align: right; font-size: 15px; ">
            ${currency}
            </span>
            <span style="flex: 0.2; font-size: 15px; ">${quotation.amount || 0}</span>
        </div>
        <div style="text-align: right; display: flex; margin-right: 18px; margin-bottom: 18px; ">
            <span style="flex: 1; font-size: 15px; ">Discount @ ${quotation.discountRate || 0}%</span>
            <span style="flex: 0.2; text-align: right; font-size: 15px; ">
            ${currency}
            </span>
            <span style="flex: 0.2; font-size: 15px; ">${quotation.discount || 0}</span>
        </div>
        <div style="text-align: right; display: flex; margin-right: 18px; margin-bottom: 18px; ">
            <span style="flex: 1; font-size: 15px; ">Amount after Discount</span>
            <span style="flex: 0.2; text-align: right; font-size: 15px; ">
            ${currency}
            </span>
            <span style="flex: 0.2; font-size: 15px; ">${quotation.amountAfterDiscount || 0}</span>
        </div>
        <div style="text-align: right; display: flex; margin-right: 18px; margin-bottom: 18px; ">
            <span style="flex: 1; font-size: 15px; ">
            ${quotation.includeTax ? 'Inclusive' : 'Exclusive'} VAT @ ${quotation.taxRate || 0}% (${currency} ${quotation.taxableAmount || 0} )
            </span>
            <span style="flex: 0.2; text-align: right; font-size: 15px; ">
            ${currency}
            </span>
            <span style="flex: 0.2; font-size: 15px; ">${quotation.tax || 0}</span>
        </div>

        <div style="box-sizing: border-box; display: flex; justify-content: space-between;  font-size: 16px; padding: 5px; align-items: center; background: #dedede70; color: #3e3e3e; border-radius: 8px; border: 2px solid #bebebe8f; margin-top: 8px;">
            <div style="flex: 0.5; font-weight: 700; font-size: 16px;">Total Costing*</div>
            <div style="display: flex; background: #fefefe; padding: 6px 8px; flex: 0.5; border-radius: 6px; text-align: center; ">
                <span style="flex: 0.7; text-align: right; font-size: 16px; font-weight: 700;">${currency}</span>
                <span style="flex: 0.3; font-size: 16px; font-weight: 700; text-align: right; ">${quotation.totalAmount || 0}</span>
            </div>
        </div>
    </div>
    `;

  const viewQuotationButton = `<div style="display: flex; flex: 1; justify-content: center; align-items: center">
    <a href="${DeepLinkDomainPrefixes.Https}/quotation/${quotation.id}" style="background: #008dc9; text-decoration: none;  padding: 10px 20px; border-radius: 5px; color: #fefefe; font-weight: bold; cursor: pointer;">
      View Quotation
    </a>
  </div>`;


  const appBanner = `
    <div style="display: flex; flex: 1; justify-content: center; align-items: center; background: #e8e8e8; border-radius: 5px;">
    <img src="https://checkndout.com/logoAntEmail.png" alt="app-logo" style="width: 40px; object-fit: contain;" />
    <img src="https://checkndout.com/logoTextEmail.png" alt="name-logo" style="height: 40px; object-fit: contain;" />
    </div>
  `;


  const messageSummary = `
      <div style="font-family: Arial, Helvetica, sans-serif;">
        ${messageTop}
        <div style="color: #4e4e4e; font-weight: bold;  margin-top: 10px;">${quotation.title}</div>
        <div>${quotationSummaryTable}</div>
        <div style="margin-top: 10px;">${totalSection}</div>
        <div style="margin-top: 40px; margin-bottom: 40px;">${viewQuotationButton}</div>
        ${messageBottom(authUserCompany.emailSignatureTemplate)}
        <div style="margin-top: 40px;">${appBanner}</div>
      </div>
    `;

  return messageSummary;

};
