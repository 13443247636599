import { SET_EVENT_DISCUSSIONS, SET_EVENT_DISCUSSIONS_LOADERS, SET_HAS_MORE_EVENT_DISCUSSIONS } from "../actions/eventDiscussionActions";

const initialState = {
  eventDiscussions: [],
  hasMoreEventDiscussions: true,

  eventDiscussionsLoader: false,
  moreEventDiscussionsLoader: false,
}

export const eventDiscussionsReducer = (state = initialState, { type, payload }) => {


  switch (type) {

    case SET_EVENT_DISCUSSIONS: {
      state = {
        ...state,
        eventDiscussions: payload
      };
      return state;
    }

    case SET_EVENT_DISCUSSIONS_LOADERS: {

      const { loaderName, loaderState } = payload;

      state = {
        ...state,
        [loaderName]: loaderState
      };
      return state;
    }

    case SET_HAS_MORE_EVENT_DISCUSSIONS: {
      state = {
        ...state,
        hasMoreEventDiscussions: payload
      };
      return state;
    }

    default:
      return state;
  }

}