import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../actions/authUserActions';
import DialogCNO from '../library/DialogCNO';
import LocalStorageConstants from '../utils/LocalStorageConstants';
import RightsManager from '../utils/RightsManager';
import FormActionButtons from './FormActionButtons';

function SubscriptionExpiredDialog() {

  const isUserAdmin = RightsManager.isAdmin();

  const history = useHistory();

  const authUserCompany = LocalStorageConstants.getItem('authUserCompany');

  const dispatch = useDispatch();
  const { hasSubscriptionExpired, fetchPaymentHistoryLoader } = useSelector(state => state.subscription);

  console.log("=========SubscriptionExpiredDialog=====hasSubscriptionExpired====", hasSubscriptionExpired);

  const [open, setOpen] = useState(hasSubscriptionExpired);

  useEffect(() => {

    const isMyCompanyPath = history.location.pathname.toLowerCase().includes('/mycompany/');

    if (!isMyCompanyPath) {
      setOpen(hasSubscriptionExpired);
    }

  }, [hasSubscriptionExpired, history.location.pathname]);

  const handleClose = () => {

    setOpen(false);

  };

  if (!isUserAdmin) return null;

  if (fetchPaymentHistoryLoader) return null;

  return (
    <DialogCNO
      open={open}
      onClose={null}
      dialogTitle="Renew Subscription"
    >

      <Typography style={{ fontSize: '1.1rem' }}>
        Your subscription has expired, please renew your subscription to continue using the application.
      </Typography>

      <FormActionButtons
        rightText={'Renew Subscription'}
        rightClick={() => {

          if (authUserCompany) {
            history.push(`/mycompany/${authUserCompany.id}#subscriptions`);
            handleClose();
          }

        }}
        leftText={'Logout'}
        leftClick={() => {
          dispatch(logout());
        }}
      />

    </DialogCNO>
  )
}

export default SubscriptionExpiredDialog