import {
  apiMemberSuggest,
  apiCustomerCompanySuggest,
  apiCustomerSuggest,
  apiTaskSuggest,
} from "../api";
import { apiAppointmentSuggest } from "../api/appointment";
import { apiserviceSuggest } from "../api/service";
import { apiserviceProviderSuggest } from "../api/service";


import { apiInvoiceSuggest } from "../api/invoice";
import { apiQuotationSuggest } from "../api/quotation";

export const RESET_SUGGESTIONS = "RESET_SUGGESTIONS";
export const FETCH_TASK_SUGGEST = "FETCH_TASK_SUGGEST";
export const FETCH_MEMBER_SUGGEST = "FETCH_MEMBER_SUGGEST";
export const FETCH_CUSTOMER_SUGGEST = "FETCH_CUSTOMER_SUGGEST";
export const FETCH_CUSTOMER_COMPANY_SUGGEST = "FETCH_CUSTOMER_COMPANY_SUGGEST";
export const FETCH_INVOICE_SUGGEST = "FETCH_INVOICE_SUGGEST";
export const FETCH_QUOTATION_SUGGEST = "FETCH_QUOTATION_SUGGEST";
export const FETCH_APPOINTMENT_SUGGEST = "FETCH_APPOINTMENT_SUGGEST";
export const FETCH_SERVICE_PROVIDER_SUGGEST = "FETCH_SERVICE_PROVIDER_SUGGEST";
export const FETCH_SERVICE_SUGGEST = "FETCH_SERVICE_SUGGEST";


export function resetSuggestions() {
  return (dispatch) => {
    dispatch({
      type: RESET_SUGGESTIONS,
    });
  };
}

export function fetchAppointmentSuggest(payload) {
  return async function fetchAppointmentSuggestThunk(dispatch, getState) {
    payload = {
      SearchText: payload || "",
    };

    const data = await apiAppointmentSuggest.post(payload);

    dispatch({
      type: FETCH_APPOINTMENT_SUGGEST,
      payload: data,
    });
  };
}
export function fetchServiceSuggest(payload) {
  return async function fetchServiceSuggestThunk(dispatch, getState) {
    payload = {
      SearchText: payload || "",
    };

    const data = await apiserviceSuggest.post(payload);

    dispatch({
      type: FETCH_SERVICE_SUGGEST,
      payload: data,
    });
  };
}

export function fetchServiceProviderSuggest(payload) {
  return async function fetchServiceProviderSuggestThunk(dispatch, getState) {
    payload = {
      SearchText: payload || "",
    };

    const data = await apiserviceProviderSuggest.post(payload);

    dispatch({
      type: FETCH_SERVICE_PROVIDER_SUGGEST,
      payload: data,
    });
  };
}

export function fetchTaskSuggest(payload) {
  return async function fetchTaskSuggestThunk(dispatch, getState) {
    payload = {
      SearchText: payload || "",
    };

    const data = await apiTaskSuggest.post(payload);

    dispatch({
      type: FETCH_TASK_SUGGEST,
      payload: data,
    });
  };
}

export function fetchMemberSuggest(payload) {
  return async function fetchTaskSuggestThunk(dispatch, getState) {
    payload = {
      SearchText: payload || "",
    };

    const data = await apiMemberSuggest.post(payload);

    const suggestPayload = data.data.data.map((suggest) => ({
      ...suggest,
      firstName: suggest.title.split(" ")[0],
      lastName: suggest.title.split(" ")[1],
      isActive: true,
    }));

    dispatch({
      type: FETCH_MEMBER_SUGGEST,
      payload: suggestPayload,
    });
  };
}

export function fetchCustomerSuggest(payload) {
  return async function fetchCustomerSuggestThunk(dispatch, getState) {
    payload = {
      SearchText: payload || "",
    };

    const data = await apiCustomerSuggest.post(payload);

    const suggestPayload = data.data.data.map((suggest) => ({
      ...suggest,
      firstName: suggest.title.split(" ")[0],
      lastName: suggest.title.split(" ")[1],
      isActive: true,
    }));

    dispatch({
      type: FETCH_CUSTOMER_SUGGEST,
      payload: suggestPayload,
    });
  };
}

export function fetchCustomerCompanySuggest(payload) {
  return async function fetchCustomerSuggestThunk(dispatch, getState) {
    payload = {
      SearchText: payload || "",
    };

    const data = await apiCustomerCompanySuggest.post(payload);

    dispatch({
      type: FETCH_CUSTOMER_COMPANY_SUGGEST,
      payload: data,
    });
  };
}

export function fetchInvoiceSuggest(payload) {
  return async function fetchInvoiceSuggestThunk(dispatch, getState) {
    payload = {
      SearchText: payload || "",
    };

    apiInvoiceSuggest
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          const suggestions = response.data.invoices;
          dispatch({
            type: FETCH_INVOICE_SUGGEST,
            payload: suggestions,
          });
        }
      })
      .catch((error) => console.log("Error: Invoice suggestions"));
  };
}

export function fetchQuotationSuggest(payload) {
  return async function fetchQuotationSuggestThunk(dispatch, getState) {
    payload = {
      SearchText: payload || "",
    };

    apiQuotationSuggest
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          const suggestions = response.data.offers;
          dispatch({
            type: FETCH_QUOTATION_SUGGEST,
            payload: suggestions,
          });
        }
      })
      .catch((error) => console.log("Error: Quotation suggestions: ", error));
  };
}
