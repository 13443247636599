import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme
} from '@material-ui/core';
import {
  mdiFileTable
} from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleAddInvoiceDialog } from '../../actions/invoiceActions';
import { AddInvoiceDialogMode } from '../../utils';
import RightsManager from '../../utils/RightsManager';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';

function InvoiceListHeaderPopover() {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const dispatch = useDispatch();

  const openAddInvoiceDialog = () => {
    dispatch(toggleAddInvoiceDialog({ status: true, mode: AddInvoiceDialogMode.Add }));
  };

  return (
    <div className={GlobalStyles.container_MorePopover}>
      <List className={GlobalStyles.list}>
        {
          isUserAdmin &&
          <ListItem className={GlobalStyles.listItem} onClick={openAddInvoiceDialog}>
            <ListItemText primary='Add Invoice' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiFileTable} size='1.2rem' color={theme.colors.HEADER_ADD_TODO_ICON_COLOR} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

      </List>
    </div>
  )
}

export default InvoiceListHeaderPopover