import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTestStyles } from './Test.styles';

const Countdown = () => {
  const { testId } = useParams();
  const history = useHistory();
  const styles = useTestStyles();

  const startTest = () => {
    history.push(`/test/${testId}/testpaper`);
  };

  return (
    <div className={styles.Container}>
      <div className={styles.CountdownHeader}>
        <h2 style={{ fontWeight: 500, fontSize: '1.4rem' }}>Test - {testId}</h2>
        <div className={styles.Instructions}>
          <h3>Test Instructions</h3>
          <ul>
            <li>Read all questions carefully before answering.</li>
            <li>Manage your time efficiently. You have a limited amount of time to complete the test.</li>
            <li>Ensure you have a stable internet connection during the test.</li>
            <li>Do not refresh the page or close the browser during the test.</li>
            <li>Answer all questions to the best of your ability.</li>
          </ul>
          <h4 style={{marginTop:"0.7rem"}}>Click on Start Test to start the test.</h4>
        </div>
        <br />
        <div className={styles.Title}>
          <button onClick={startTest} className={styles.btn} style={{marginBottom : "1rem"}}>
            Start Test
          </button>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
