import React, { useState } from 'react'
import { DialogCNO } from '../../../library'
import { useDispatch, useSelector } from 'react-redux'
import { toggleAddPostListDialog } from '../../../actions/postListActions';
import { Box, makeStyles } from '@material-ui/core';
import AddPostListActions from './AddPostListActions';
import PostListTypesDopDown from './PostListTypesDopDown';
import { MainPostType } from '../enums';
import { toggleAddEventDialog } from '../../../actions/eventActions';
import { toggleAddJobDialog } from '../../../actions/jobActions';
import { toggleAddPostDialog } from '../../../actions/postActions';

const AddPostListDialogue= () => {

  console.log("AddPostListDialogue")
  const styles = useStyles();

  const dispatch = useDispatch();
  const { isAddPostListDialog } = useSelector(state => state.postListTypes);

  console.log("AddPostListDialogue->",isAddPostListDialog)

  const onClose = () => dispatch(toggleAddPostListDialog(false));

  const [choosedPostType,setChoosedPostType]=useState(MainPostType.ids.Post)
  
  const renderAddPostItem = () => {
    console.log("choosedPosttype.postType ",choosedPostType);
    if(choosedPostType === MainPostType.ids.Post){
      console.log("post dispatched")
      dispatch(toggleAddPostDialog(true));
      onClose();
    }else if(choosedPostType=== MainPostType.ids.Job){
      console.log("job dispatched")

      dispatch(toggleAddJobDialog(true));
      onClose();
    }else if(choosedPostType === MainPostType.ids.Event){
      console.log("event dispatched")
        
      dispatch(toggleAddEventDialog(true));
      onClose();
    }else{
      return null;
    }
  
  }

  return (
    <DialogCNO
      open={isAddPostListDialog}
      onClose={onClose}
      dialogTitle='Create a Post'
      width = '40vw'
    >

      <Box>
        <Box>
          <PostListTypesDopDown
            onChange={(selectedPostType) => setChoosedPostType(selectedPostType.postType)}
            value={MainPostType.ids.Post}
          />
        </Box>
        
        <AddPostListActions
          onAddPost={renderAddPostItem}
          text={'Next'}
        />

      </Box>
    </DialogCNO>
  )
}

export default AddPostListDialogue;

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: RFValue(12),
  },
  userWrapper: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    marginBottom: 12
  },
  formContainer: {
    // flex: 1,
  },
  input: {
    '& .MuiInput-underline:before': {
      borderBottom: 0
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0
    }
  }
}))