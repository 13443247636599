import React, { useEffect, useRef, useState } from "react";
import { SnapItem, SnapList } from "react-snaplist-carousel";
import { v4 } from "uuid";
import ChipCNO from "../../library/ChipCNO";
import { getMaterialIconString } from "../../utils/icons";
import {
  CalendarFilter,
  CalendarFilterIcon,
  CalendarFilterReverse,
} from "../../utils/mappings";
import * as icons from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@mdi/react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { TOGGLE_SEARCH } from "../../utils/actions";
import { useGlobalStore } from "../../providers/GlobalProvider";
import {
  setAddModalsCalendarDates,
  setCalendarFilter,
  setCalendarLoaders,
} from "../../actions/calendarActions";
import {
  fetchAppointments,
  handleAppointmentSearch,
  handleHolidaySearch,
  handleLeaveSearch,
} from "../../actions/appointmentActions";
import { setJobSearchManager } from "../../actions/jobActions";
import LocalStorageConstants from "../../utils/LocalStorageConstants";
import {
  getPrefillAssignedUser,
  getPrefillCustomer,
} from "../../utils/Helpers";
import moment from "moment";
import RightsManager from "../../utils/RightsManager";

function CalendarFilterBar({
  setPage,
  isCalendarAddDialog,
  setIsCalendarAddDialog,
}) {
  const theme = useTheme();

  const isUserAdmin = RightsManager.isAdmin();

  const authUser = LocalStorageConstants.getItem("authUser", null);

  const snapList = useRef(null);

  const tillTablet = useMediaQuery("(max-width: 768px)");

  const dispatch = useDispatch();
  const { calendarFilter, apiCalendarDatesFilter } = useSelector(
    (state) => state.calendar
  );

  const { jobSearchParams } = useSelector((state) => state.jobs);

  const { appointmentSearchParams, leaveSearchParams, holidaySearchParams } =
    useSelector((state) => state.appointments);

  const [activeFilter, setActiveFilter] = useState(calendarFilter);

  const { globalState, dispatchGlobal } = useGlobalStore();
  const { isSearch } = globalState;

  useEffect(() => {
    setActiveFilter(calendarFilter);
  }, [calendarFilter]);

  const handleCalendarSearchSidebar = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: !isSearch,
    });
  };

  const handleCalendarFilter = async (timeFilter) => {
    console.log("rerun timefilter: ", timeFilter);

    dispatch(setCalendarLoaders("eventsLoader", true));

    setActiveFilter(timeFilter);
    await dispatch(setCalendarFilter(timeFilter));

    let payload = apiCalendarDatesFilter;

    switch (timeFilter) {
      case CalendarFilter.None:
        break;

      case CalendarFilter.Appointments:
        payload = {
          ...appointmentSearchParams,
          ...payload,
          pageSize: 500,
          customer: appointmentSearchParams.customer
            ? appointmentSearchParams.customer
            : getPrefillCustomer(),
          assignments: appointmentSearchParams.assignments
            ? appointmentSearchParams.assignments
            : getPrefillAssignedUser(),
        };
        await dispatch(handleAppointmentSearch(payload));
        break;

      case CalendarFilter.Jobs:
        payload = {
          ...jobSearchParams,
          ...payload,
          pageSize: 500,
          assignedUsers: jobSearchParams.assignedUsers
            ? jobSearchParams.assignedUsers
            : getPrefillAssignedUser(),
        };

        await dispatch(setJobSearchManager(payload));
        break;

      case CalendarFilter.Leaves:
        payload = {
          ...leaveSearchParams,
          ...payload,
          pageSize: 500,
        };

        await dispatch(handleLeaveSearch(payload));
        break;

      case CalendarFilter.Holidays:
        payload = {
          ...holidaySearchParams,
          ...payload,
          pageSize: 500,
        };

        await dispatch(handleHolidaySearch(payload));
        break;

      default:
        break;
    }

    dispatch(setCalendarLoaders("eventsLoader", false));
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {tillTablet && (
        <Icon
          path={icons.mdiMagnify}
          size="2.4rem"
          onClick={handleCalendarSearchSidebar}
          style={{
            paddingRight: 10,
            borderRight: `1px solid ${theme.colors.HOME_FILTER_SEARCH_DIVIDER_COLOR}`,
          }}
        />
      )}

      <SnapList ref={snapList}>
        {Object.values(CalendarFilter)
          .filter((timeFilter) => timeFilter !== CalendarFilter.None)
          .map((timeFilter) => (
            <SnapItem key={v4()} margin={{ left: "5px", right: "5px" }}>
              <ChipCNO
                icon={
                  icons[getMaterialIconString(CalendarFilterIcon[timeFilter])]
                }
                name={CalendarFilterReverse[timeFilter]}
                active={activeFilter === timeFilter}
                onClick={async () => await handleCalendarFilter(timeFilter)}
              />
            </SnapItem>
          ))}
      </SnapList>

      {isUserAdmin && (
        <Icon
          path={icons.mdiCalendarPlus}
          size="2.2rem"
          style={{ paddingRight: 5 }}
          color="orange"
          onClick={async () => {
            const now = new Date();

            await dispatch(
              setAddModalsCalendarDates({
                startDate: now,
                startTime: now,
                endDate: now,
                endTime: moment(now).add(30, "minute").toDate(),
              })
            );

            setIsCalendarAddDialog(true);
          }}
        />
      )}
    </div>
  );
}

export default CalendarFilterBar;
