import { Button, Popover, Typography, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import JobTypeIcon from "../JobTypeIcon/JobTypeIcon";
import { mdiDirections, mdiDotsVertical } from "@mdi/js";
import Icon from "@mdi/react";
import UserStatusBar from "../UserStatusBar/UserStatusBar";
import LinkCNO from "../../library/LinkCNO";
import Moment from "react-moment";
import MorePopover from "../MorePopover/MorePopover";
import { calendarStrings, TIME_FORMAT } from "../../utils/formatDate";
import { useStyles } from "./JobCardStyles";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import LinesEllipsis from "react-lines-ellipsis";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { setTodos } from "../../actions/jobActions";
import ConfirmationDialogCNO from "../../library/ConfirmationDialogCNO";
import RightsManager from "../../utils/RightsManager";
import { formatTimeWithTZ, getCategory } from "../../utils/Helpers";
import JobGroupedMedia from "./JobGroupedMedia";

function JobCard({ item }) {
  const isUserAdmin = RightsManager.isAdmin();
  const isUserMember = RightsManager.isMember();

  const ResponsiveLines = responsiveHOC()(LinesEllipsis);

  const [refMore, setRefMore] = useState(null);

  const dispatch = useDispatch();

  const { isJobDeleteLoader, isShareJobLoader, jobs } = useSelector(
    (state) => state.jobs
  );

  const [isJobNavigationConfirmModal, setIsJobNavigationConfirmModal] =
    useState(false);

  const openMore = (e) => {
    setRefMore(e.currentTarget);
  };

  const closeMore = () => {
    setRefMore(null);
  };
  const open = Boolean(refMore);

  const NativePopover = () => {
    return (
      <Popover
        open={open}
        anchorEl={refMore}
        onClose={closeMore}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MorePopover
          item={item}
          closeMore={closeMore}
          onShareTaskWithCustomer={(todo) => {
            const jobsTemp = jobs.map((job) =>
              job.id === item.id ? todo : job
            );

            dispatch(setTodos(jobsTemp));
          }}
        />
      </Popover>
    );
  };

  const theme = useTheme();

  const {
    id = "",
    categories = [],
    title,
    startDate,
    startTime,
    description,
  } = item;

  const jobCategory = getCategory(categories[0]);

  const assignUsers = item?.taskAssignments
    .map((user) => user.staffText)
    .filter((user) => (user === null ? false : true))
    .join(", ");

  const styles = useStyles();

  return (
    <div className={styles.container}>
      {(isJobDeleteLoader || isShareJobLoader) && <FullScreenLoaderCNO />}
      <div className={styles.jobMeta}>
        <JobTypeIcon
          iconLink={jobCategory?.iconLink}
          name={jobCategory?.title}
          categoryId={categories[0]}
        />
        <div className={styles.meta}>
          <LinkCNO to={`/job/${id}`}>
            <Typography className={styles.title}>{title}</Typography>
            <Typography className={styles.date}>
              <Moment interval={0} calendar={calendarStrings}>
                {formatTimeWithTZ(startDate)}
              </Moment>
              <Moment interval={0} format={TIME_FORMAT}>
                {formatTimeWithTZ(startTime)}
              </Moment>
            </Typography>
            {(isUserAdmin || isUserMember) && (
              <Typography className={styles.username}>{assignUsers}</Typography>
            )}
          </LinkCNO>
        </div>

        <div
          style={{ display: "flex", alignItems: "center", height: "1.8rem" }}
        >
          <Icon
            className={styles.moreIcon}
            path={mdiDirections}
            size="1.5rem"
            color={"grey"}
            onClick={() => setIsJobNavigationConfirmModal(true)}
          />

          <Icon
            className={styles.moreIcon}
            path={mdiDotsVertical}
            size="1.8rem"
            color={theme.colors.MORE_ACTIONS_ICON_COLOR}
            onClick={openMore}
          />
          <NativePopover />
        </div>
      </div>
      <LinkCNO to={`/job/${id}`}>
        <pre className={styles.jobDescription}>
          <ResponsiveLines
            text={description || "Job details not added"}
            maxLine="10"
            ellipsis="..."
            trimRight
            basedOn="words"
          />
        </pre>
      </LinkCNO>

      <JobGroupedMedia job={item} />

      <UserStatusBar item={item} />

      {isJobNavigationConfirmModal && (
        <ConfirmationDialogCNO
          open={isJobNavigationConfirmModal}
          onClose={() => setIsJobNavigationConfirmModal(false)}
          dialogTitle="Navigate to Job"
          messages={["Do you want to navigate to work location?"]}
          yesClick={() => {
            window.open(
              `https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${item.mapAddress}`,
              "_blank"
            );
          }}
        />
      )}
    </div>
  );
}

export default JobCard;
