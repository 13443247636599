import {
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useStyles } from './CompanyPaymentDetailsCardStyles';
import { TabContext, TabPanel } from '@material-ui/lab';
import PaymentDueDetails from '../PaymentDueDetails/PaymentDueDetails';
import PaymentDoneDetails from '../PaymentDoneDetails/PaymentDoneDetails';

function CompanyPaymentDetailsCard({ profile, isActive }) {

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const styles = useStyles({ tillTablet });

  const [activeTab, setActiveTab] = useState("paymentsDue");

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  return (
    <div className={styles.container_CompanyPaymentDetailsCard} id="paymentDue">
      <Typography className={styles.title}>Payment Details</Typography>

      <TabContext value={activeTab}>

        <Tabs
          value={activeTab}
          className={styles.tabs}
          classes={{
            indicator: styles.activeTabIndicator
          }}
          onChange={handleTabChange}
        >
          <Tab label="Payments Due" value="paymentsDue" />
          <Tab label="Payments History" value="paymentsHistory" />
        </Tabs>

        <TabPanel
          value="paymentsDue"
          className={styles.tabPanel}
        >
          <PaymentDueDetails profile={profile} />
        </TabPanel>

        <TabPanel
          value="paymentsHistory"
          className={styles.tabPanel}
        >
          <PaymentDoneDetails />
        </TabPanel>

      </TabContext>

    </div>
  )
}

export default CompanyPaymentDetailsCard;
