import { makeStyles, useTheme } from '@material-ui/core';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';

function CloseIcon({ size = '1.3rem', iconStyles, ...rest }) {

  const theme = useTheme();

  const useStyles = makeStyles(theme => ({
    closeIcon: {
      background: theme.colors.MODAL_CLOSE_ICON_BACKGROUND_COLOR,
      borderRadius: '50%',
      padding: 1,
      ...iconStyles
    }
  }));

  const styles = useStyles();

  return (
    <Icon
      path={mdiClose}
      size={size}
      className={styles.closeIcon}
      color={theme.colors.MODAL_CLOSE_ICON_COLOR}
      {...rest}
    />
  );
}

export default CloseIcon;

