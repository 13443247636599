import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { mdiAccount, mdiBarcode, mdiCheck, mdiEmail, mdiPhone } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import React, { useState } from 'react';
import AvatarCNO from '../../library/AvatarCNO/AvatarCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import FormBox from '../FormBox';

function AlreadyExistCustomerList({ customers, onSelectItem }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const [selectedCustomerId, setSelectedCustomerId] = useState("");



  return (
    <FormBox title="Following users with similar parameters already exist in system. You can select or ignore the list." bgColor={theme.colors.JOB_DETAIL_BACKGROUND_COLOR}>

      {
        customers.map((profile) => (
          <div
            key={profile.id}
            className={clsx(GlobalStyles.listCardContainer, GlobalStyles.listCardFW, {
              [styles.selectedUser]: selectedCustomerId === profile.id
            })}
            style={{ marginTop: theme.spacing(1), display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            onClick={() => {
              let valueToSet = "";
              if (selectedCustomerId === profile.id) {
                valueToSet = "";
              } else {
                valueToSet = profile;
              }

              setSelectedCustomerId(valueToSet ? valueToSet.id : valueToSet);
              if (onSelectItem) {
                onSelectItem(valueToSet);
              }

            }}
          >
            <div className={GlobalStyles.cardInfoWrapper} >
              <div>
                <AvatarCNO
                  src={profile.profilePic}
                  fallbackIcon={mdiAccount}
                  iconSize="3rem"
                  avatarStyle={{ border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}` }}
                />
              </div>
              <div
                style={{ marginLeft: theme.spacing(2), flex: 1 }}
              >
                <Typography className={GlobalStyles.cardTitle}>
                  {profile.firstName}&nbsp;{profile.lastName}
                </Typography>

                {
                  profile.email &&
                  <div className={GlobalStyles.cardInfoRow}>
                    <Icon
                      path={mdiEmail}
                      size={"1rem"}
                      color={theme.colors.LIST_CARD_ICON_COLOR}
                    />
                    <Typography className={GlobalStyles.cardInfoRowText}>{profile.email}</Typography>
                  </div>
                }

                {
                  profile.code &&
                  <div className={GlobalStyles.cardInfoRow}>
                    <Icon
                      path={mdiBarcode}
                      size={"1rem"}
                      color={theme.colors.LIST_CARD_ICON_COLOR}
                    />
                    <Typography className={GlobalStyles.cardInfoRowText}>{profile.code}</Typography>
                  </div>
                }

                {
                  profile.mobile &&
                  <div className={GlobalStyles.cardInfoRow}>
                    <Icon
                      path={mdiPhone}
                      size={"1rem"}
                      color={theme.colors.LIST_CARD_ICON_COLOR}
                    />
                    <Typography className={GlobalStyles.cardInfoRowText}>{profile.mobile}</Typography>
                  </div>
                }

              </div>

            </div>
            {
              selectedCustomerId === profile.id
                ? (
                  <Icon
                    path={mdiCheck}
                    color={theme.colors.JOB_END_DATE_COLOR}
                    size={"1.8rem"}
                  />
                )
                : null
            }

          </div>
        ))
      }
    </FormBox>
  )
}

export default AlreadyExistCustomerList;

const useStyles = makeStyles((theme) => ({
  selectedUser: {
    border: `2px solid ${theme.colors.BORDER_COLOR}`
  }
}));
