import { makeStyles } from "@material-ui/core";
import { margin, marginBottom } from "styled-system";

export const useStyles = makeStyles((theme) => ({
  // container: {
  //   padding: ({ tillTablet }) => (tillTablet ? 10 : theme.spacing(2)),
  //   background: theme.colors.JOB_DETAIL_BACKGROUND_COLOR,
  //   // display: 'flex',
  //   // flexWrap: 'wrap',
  //   "&>*": {
  //     marginTop: theme.spacing(2),
  //     "&:first-child": {
  //       marginTop: 0,
  //     },
  //   },
  // },
  container: {
    borderRadius: 10,
    padding: "16px 8px",
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    color: theme.palette.getContrastText(theme.palette.background.main),
    border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
    //width: "100%",
    marginBottom: theme.spacing(2),
    fontSize : "16px",
    fontWeight : "bold"
  },

  jobDescription: {
    background: theme.colors.LIST_CARD_PARA_BACKGROUND_COLOR,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 5,
    color: theme.colors.LIST_CARD_PARA_COLOR,
    fontSize: "14px",
    whiteSpace: "pre-wrap",
  },
  title: {
    color: theme.colors.LIST_CARD_TITLE_COLOR,
    fontWeight: "700",
  },
  hover: {
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.01)",
    },
  },
}));
