import {
    mdiArrowRight,
    mdiCheck,
    mdiClose,
    mdiSend,
    mdiSquareEditOutline
  } from '@mdi/js';
  import Icon from '@mdi/react';
  import React from 'react';
  import CardStatusCNO from '../../library/CardStatusCNO/CardStatusCNO';
  import { LeaveStatus } from '../../utils/mappings';
  import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
  
  function LeaveStatusButtonBar({ appointment }) {
  
    const GlobalStyles = useGlobalStyles();
    console.log("appointmentStatus",appointment.status);
    const pendingStatus = appointment.status === LeaveStatus.PENDING ;
    const rejectionStatus = appointment.status === LeaveStatus.REJECTED ;
    const acceptanceStatus = appointment.status === LeaveStatus.ACCEPTED;



    return (
      <div className={GlobalStyles.cardStatusBarContainer}>
  
        <CardStatusCNO
          title="Applied"
          icon={mdiSquareEditOutline}
          isActive={pendingStatus}
          activeBorderColor={'#ffb366'}
        />
  
        <Icon path={mdiArrowRight} size='1.2rem' />
  
        <CardStatusCNO
          title={rejectionStatus ? 'Rejected' : 'Approved'}
          icon={mdiSend}
          isActive={rejectionStatus || acceptanceStatus}
          activeBorderColor={acceptanceStatus ? "#00cc00"
            : "#ef5647"}
          hasTopRightIcon={true}
          topRightIcon={
            rejectionStatus ? mdiClose : mdiCheck
          }
          isTopRightActive={rejectionStatus || acceptanceStatus}
          topRightActiveBgColor={acceptanceStatus ? "#2bb673"
            : "#ef5647"}
          topRightActiveBorderColor={acceptanceStatus ? "#2bb673"
            : "#ef5647"}
          topRightActiveColor={"#cecece"}
        />
  
      </div>
    )
  }
  
  export default LeaveStatusButtonBar
  