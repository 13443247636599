import { FieldArray, useFormikContext } from 'formik'
import React from 'react'
import FormBox from '../../../components/FormBox';
import { IconMain, InputCNO, MetaText } from '../../../library';
import { Box, Button, Divider, makeStyles, useTheme } from '@material-ui/core';
import { AddSectionButton } from '../components';
import FormFieldsArray from './FormFieldsArray';
import { FormFieldsTypes } from '../utils/enums';
import { FormSectionBoxTitle } from './component';

function FormSectionArray({ formik }) {


  return (
    <FieldArray name='sections'>
      {
        function FormSectionItem(fieldArray) {

          const handleAddSection = (value) => {
            fieldArray.push(
              {
                type: 'section',
                title: value.title,
                icon: null,
                order: 1,
                fields: []
              }
            )

          }

          return (
            <>
              {
                formik.values.sections.map((section, index) => {

                  const handleEditSection = (value) => {
                    fieldArray.replace(index,
                      {
                        ...formik.values.sections[index],
                        title: value.title,
                      }
                    )

                  }

                  return (
                    <>
                      <FormBox
                        style={{ position: 'relative' }}
                        renderTitle={() =>
                          <FormSectionBoxTitle
                            title={formik.values.sections[index]['title']}
                            initialValues={{ title: formik.values.sections[index]['title'] }}
                            onEditSection={handleEditSection}
                            onRemoveSection={() => fieldArray.remove(index)}
                          />
                        }
                      >
                        <FormFieldsArray formik={formik} sectionIndex={index} />
                      </FormBox>
                    </>
                  )
                })
              }

              <AddSectionButton onAddSection={handleAddSection} />

            </>
          )
        }
      }
    </FieldArray>
  )
}

export default FormSectionArray;
