import React, { Fragment } from "react";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import LinkCNO from "../../library/LinkCNO";
import { Typography } from "@material-ui/core";
import Moment from "react-moment";
import MoreActionCNO from "../../library/MoreActionsCNO";
import AppointmentListCardPopover from "./AppointmentListCardPopover";
import JobTypeIcon from "../JobTypeIcon/JobTypeIcon";
import { formatTimeWithTZ, getCategory } from "../../utils/Helpers";
import { calendarStrings, TIME_FORMAT } from "../../utils/formatDate";
import RightsManager from "../../utils/RightsManager";
import ResponsiveLinesCNO from "../../library/ResponsiveLinesCNO/ResponsiveLinesCNO";
import clsx from "clsx";
import { HolidayType } from "../../utils/mappings";
import HolidayTypeIcon from '../HolidayTypeIcon';

function HolidayListCard({ appointment }) {
  const isUserAdmin = RightsManager.isAdmin();
  const isUserCustomer = RightsManager.isCustomer();

  // const category = getCategory(appointment.categories[0]);

  const GlobalStyles = useGlobalStyles();

  return (
    <div
      className={clsx(GlobalStyles.listCardContainer, GlobalStyles.listCardFW)}
    >
      <div className={GlobalStyles.cardInfoWrapper}>
        <HolidayTypeIcon/>
        <div className={GlobalStyles.cardInfo}>
          {/* <LinkCNO to={`/holiday/${appointment.id}`}> */}
            <Typography className={GlobalStyles.cardTitle}>
              {appointment.holidayName} 
            </Typography>

            {appointment.holidayName && (
              <Typography
                style={{ marginTop: 4 }}
                className={GlobalStyles.cardInfoRowText}
              >
                {HolidayType.getItem(appointment.holidayType).label}
              </Typography>
            )}

            <div className={GlobalStyles.datesWrapper}>
              <Typography className={GlobalStyles.cardDate}>
                <Fragment>
                  {/* <span>From&nbsp;</span> */}
                  <Moment interval={0} calendar={calendarStrings}>
                    {formatTimeWithTZ(appointment.startDate)}
                  </Moment>
                  &nbsp;
                  <Moment interval={0} format={TIME_FORMAT}>
                    {formatTimeWithTZ(appointment.startTime)}
                  </Moment>
                  &nbsp;
                </Fragment>

                {/* <Fragment>
                  <span>To&nbsp;</span>
                  <Moment interval={0} calendar={calendarStrings}>
                    {formatTimeWithTZ(appointment.endDate)}
                  </Moment>
                  &nbsp;
                  <Moment interval={0} format={TIME_FORMAT}>
                    {formatTimeWithTZ(appointment.endTime)}
                  </Moment>
                </Fragment> */}
              </Typography>
            </div>

            {/* {appointment.duration && (
              <Typography
                style={{ marginTop: 4 }}
                className={GlobalStyles.cardInfoRowText}
              >
                {`Holiday Duration : ${appointment.duration} days`}
              </Typography>
            )} */}
          {/* </LinkCNO> */}
        </div>

        {/* <div
          style={{ display: "flex", alignItems: "center", height: "1.8rem" }}
        >
          {(isUserAdmin || isUserCustomer) && (
            <MoreActionCNO>
              <AppointmentListCardPopover appointment={appointment} />
            </MoreActionCNO>
          )}
        </div> */}
        
      </div>

      {/* <LinkCNO to={`/holiday/${appointment.id}`}> */}
        <pre className={GlobalStyles.cardDescription}>
          <ResponsiveLinesCNO
            text={appointment.description || "Holiday Details not added"}
            maxLine="10"
            ellipsis="..."
            trimRight
            basedOn="words"
          />
        </pre>
      {/* </LinkCNO> */}
    </div>
  );
}

export default HolidayListCard;


