import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, makeStyles, Typography, useTheme } from '@material-ui/core';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChatGroups, setActiveChatUser, setChatSnippetList } from '../../actions/chatActions';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import RightsManager from '../../utils/RightsManager';
import ChatMessages from '../ChatMessages';

function JobCustomerChatDetailsCard({
  job,
  handleEnableCustomerChat,
  isChatAccordionVisible,
  setIsChatAccordionVisible,
}) {

  const authUser = LocalStorageConstants.getItem("authUser");

  const isUserAdmin = RightsManager.isAdmin();
  const isUserCustomer = RightsManager.isCustomer();

  const theme = useTheme();

  const styles = useStyles();

  const dispatch = useDispatch();

  const { chatGroups, chatSnippetList } = useSelector(state => state.chats);

  useEffect(() => {

    if (isChatAccordionVisible.customerChatGroup) {
      handleToggleAssignChatAccordion();
    } else {
      dispatch(setActiveChatUser(null));
      localStorage.setItem("lastActiveChatUser", JSON.stringify(null));
    }

  }, [isChatAccordionVisible.customerChatGroup]);

  const handleToggleAssignChatAccordion = () => {

    console.log("==customerChatGroup==jobchatgroupdetails=====isChatAccordionVisible==", isChatAccordionVisible);

    if (!isChatAccordionVisible.customerChatGroup) {
      return;
    }

    // console.log("====jobchatgroupdetails=====chatGroup==", chatGroup);
    console.log("==customerChatGroup==jobchatgroupdetails=====chatGroups==", chatGroups);

    const chatGroupDetails = chatGroups.find(chat => chat.id === job.customerChatGroupId);

    console.log("==customerChatGroup==jobchatgroupdetails=====chatGroupDetails==", chatGroupDetails);

    if (chatGroupDetails) {
      dispatch(setActiveChatUser(chatGroupDetails));
      localStorage.setItem("lastActiveChatUser", JSON.stringify(chatGroupDetails));

      // to handle profile message count
      localStorage.setItem("currentChatProfile", JSON.stringify(chatGroupDetails));

      // set profile message count zero of selected chat
      const isProfileAlreadyInChatList = chatSnippetList.find(profile => profile.id === chatGroupDetails.id);

      let updatedChatProfileList = [...chatSnippetList];
      if (!isProfileAlreadyInChatList) {
        updatedChatProfileList = [chatGroupDetails, ...chatSnippetList];
      }
      updatedChatProfileList = updatedChatProfileList.map(profile => profile.id === chatGroupDetails.id ? { ...profile, count: 0 } : profile);

      // console.log("====== handleChatListClick ====chatSnippetList ", chatGroupDetails);
      // console.log("====== handleChatListClick ====updatedChatProfileList ", updatedChatProfileList);

      dispatch(setChatSnippetList(updatedChatProfileList));
    }

  };

  if (!job.customerChatGroupId && !isUserAdmin) {
    return null;
  }

  return (
    <div className={styles.container_JobChatDetailsCard}>

      {
        !job.customerChatGroupId &&
        <div className={styles.buttonWrapper}>
          <Button
            variant='contained'
            className={styles.button}
            onClick={handleEnableCustomerChat}
          >
            <pre>Enable Customer Chat</pre>
          </Button>
        </div>
      }

      {
        job.customerChatGroupId &&
        <Accordion
          expanded={isChatAccordionVisible.customerChatGroup}
          className={styles.accordion}
        >
          <AccordionSummary
            expandIcon={<Icon path={mdiChevronDown} size="1.2rem" color={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR} />}
            className={styles.accordionSummary}
            onClick={async () => {

              if (!isChatAccordionVisible.customerChatGroup) {

                const chatGroupsPayload = {
                  pageSize: 10,
                  pageIndex: 0,
                  orderBy: "",
                  sortDecending: true,
                  profileId: isUserCustomer ? job.customerId : authUser.id,
                  // custId: job.customerId
                };

                await dispatch(fetchChatGroups(chatGroupsPayload));

              } else {
                dispatch(setActiveChatUser(null));
                localStorage.setItem("lastActiveChatUser", JSON.stringify(null));
              }

              setIsChatAccordionVisible({
                customerChatGroup: !isChatAccordionVisible.customerChatGroup,
                teamChatGroup: false
              });

            }}
          >
            Customer Chat
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.cardContent}>
              {
                job.customerChatGroupId ? (
                  <ChatMessages />
                ) : (
                  <Typography className={styles.cardTitle}>
                    Chat is not enabled yet
                  </Typography>
                )
              }
            </div>
          </AccordionDetails>
        </Accordion>
      }

    </div>
  )
}

export default JobCustomerChatDetailsCard


const useStyles = makeStyles(theme => ({
  container_JobChatDetailsCard: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
    width: '100%'
  },
  cardContent: {
    padding: theme.spacing(1),
    width: '100%',
    '&>*': {
      marginTop: theme.spacing(1)
    }
  },
  cardTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    minWidth: 190
  }
}));
