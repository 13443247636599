import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({

  container_Settings: {
    padding: '20px 8px',
    // height: 'calc(100vh - 60px)',
    background: theme.colors.APP_BACKGROUND_COLOR,
    '&>*': {
      marginTop: theme.spacing(2)
    }
  },
  themeWrapper: {
    display: 'flex',
    // background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    marginTop: theme.spacing(2),
    '&>*': {
      marginRight: theme.spacing(2)
    }
  }

}));