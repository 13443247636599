import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles((theme) => ({
  container_DialogCNO: {
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    padding: theme.spacing(2),
    background: ({ bgColor }) =>
      bgColor ? bgColor : theme.colors.DIALOG_BACKGROUND_COLOR,
    minWidth: 340,
    maxWidth: "90vw",
    minHeight: ({ minHeight }) => minHeight,
    width: ({ width }) => width,
    maxHeight: ({ maxHeight }) => maxHeight,
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  content: {
    padding: "20px ",
  },
  container: {
    // padding: RFValue(12),
  },
  userWrapper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    position: "sticky",
    top: 0,
    gap: 12,
    // background: theme.palette.background.default,
    zIndex: 1000,
  },
  scrollableContent: {
    flexGrow: 1,
    overflowY: "auto",
    padding: theme.spacing(2),
    maxHeight: ({ scrollHeight }) => scrollHeight,
  },
  footer: {
    padding: theme.spacing(2),
    // background: theme.palette.background.default,
    position: "sticky",
    bottom: 0,
    zIndex: 1000,
  },
  formContainer: {
    // flex: 1,
  },
  input: {
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
    opacity: ({ disabled }) => (disabled ? 0.8 : 1),
    width: "100%",
    borderRadius: 5,
    "& input": {
      background: "white",
      color: ({ color, disabled }) =>
        disabled
          ? fade(color || theme.colors.INPUT_TEXT_COLOR, 0.8)
          : color || theme.colors.INPUT_TEXT_COLOR,
      borderRadius: 5,
      padding: 8,
    },
    "& .MuiOutlinedInput-multiline": {
      color: ({ color }) => (color ? color : theme.colors.INPUT_TEXT_COLOR),
      background: "white",
    },
    "& .MuiOutlinedInput-adornedStart": {
      background: "white",
      borderRadius: 5,
    },
    "& .MuiOutlinedInput": {
      background: "white",
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
      "-webkit-box-shadow": `0 0 0 100px ${theme.colors.INPUT_BACKGROUND_COLOR} inset`,
      "-webkit-text-fill-color": theme.colors.INPUT_TEXT_COLOR,
      caretColor: ({ color }) =>
        color ? color : theme.colors.INPUT_TEXT_COLOR,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: ({ isBorderHighlight }) =>
        isBorderHighlight && "2px solid orange",
    },
  },
  feelingActivityBox: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "40px",
  },
  feelingElements: {
    padding: "15px",
  },
  activeBar: {
    borderBottom: `3px solid ${theme.colors.FEELING_ACTIVITY_BORDER_COLOR}`,
  },
  searchBox: {
    width: "100%",
    height: "25px",
    // borderRadius: "10px",
    padding: "18px",
    margin: "15px 4px 15px 4px",
    background: theme.colors.TAG_DIALOG_SEARCH_BACKGROUND_COLOR,
    color: theme.colors.INPUT_TEXT_COLOR,
    outline: "none",
    border: "none",
  },
  feelingIconContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "20px",
    padding: "10px",
  },
  activityIconContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
    padding: "10px",
    flexDirection: "column",
  },
  feelingElementsEmoji: {
    flex: "0 0 calc(50% - 20px)",
    // maxWidth: "calc(50% - 20px)",
    borderRadius: "7px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    "&:hover": {
      background: theme.colors.ITEM_HOVER,
    },
    cursor: "pointer",

    "&:hover .backgroundDisplay": {
      background: "none",
    },
    // border:'1px solid white',
    padding: "5px",
  },
  activityElementsEmoji: {
    width: "100%",

    display: "flex",
    gap: "10px",
    alignItems: "center",
    "&:hover": {
      // background: "#e4e6eb1f",
      background: theme.colors.ITEM_HOVER,
    },
    "&:hover .backgroundDisplay": {
      background: "none",
    },
    // border:'1px solid white',
    padding: "5px",
    borderRadius: "7px",
  },
  activityBox: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "7px",
    alignItems: "center",
    "&:hover": {
      background: "#e4e6eb1f",
    },
  },
  emoji: {
    fontSize: "1.3rem",
    borderRadius: "50%",
    padding: "10px",
  },
  emojiName: {
    fontSize: "1.1rem",
    // color: "#f5f5f5c7",
    color: theme.colors.TEXT_COLOR,
  },
  backgroundDisplay: {
    background: "#e4e6eb1f",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchBtnBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
    paddingRight: "10px",
  },
  tagBtn: {
    color: "#6060fa",
    background: "transparent",
    border: "none",
    padding: "6px",
    fontSize: "1.4rem",
    borderRadius: "7px",
    fontWeight: "600",
    cursor: "pointer",
  },
  suggestion: {
    margin: "15px auto",
  },
  root: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  privacyDropdownContainer: {
    background: theme.colors.BACKGROUND_COLOR_LIGHTER,
    display: "flex",
    alignItems: "center",
    gap: 4,
    width: "fit-content",
    padding: "2px 4px",
    borderRadius: 4,
    cursor:"pointer"
  },
  LifeEventContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  ImageSection: {
    // borderBottom: `2px solid #9e9e9e`,
    
    position:'relative',
    paddingBottom: "30px",
  },
  lifeEventImage: {
    width: ({ width }) => width - theme.spacing(2),
    "& img": {
      width: "100%",
      maxHeight: ({ gifHeightInLifeEvent }) => gifHeightInLifeEvent,
    },
  },
  lifeEventViewer:{
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      maxHeight: ({ gifHeightInLifeEvent }) => gifHeightInLifeEvent,
    },
  },
  LifeEventDescription: {
    textAlign: "center",
  },
  categorySection: {
    textAlign: "center",
  },
  category: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "130px",
    height: "130px",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover": {
      background: theme.colors.ITEM_HOVER,
    },
  },
  categoryCollectionBox: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px",
    gap: "20px",
  },
  categoryDetailContainer: {
    margin: "20px",
  },
  enterTitleBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    height: "200px",
    "&:hover": {
      background: theme.colors.ITEM_HOVER,
    },
  },
  LifeEventCategoryIcon: {

    height: "70px",
    width: "70px",
    borderRadius: "50%",
    // backgroundColor: "#5f5858",
    // background:'#0866ff',
    background:'rgb(63,138,2)',

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  LifeEventCategoryViewerIcon :{
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    height: "50px",
    width: "50px",
    height: "70px",
    width: "70px",
    borderRadius: "50%",
    // backgroundColor: "#5f5858",
    // background:'#0866ff',
    background:'rgb(63,138,2)',
  },
  titleBox: {
    color: "#797979",
    display: "flex",
    gap: "5px",
    // marginTop:'5px'
  },
  lifeEventCategoryOptionsContainer: {
    borderTop: "1px solid grey",
  },
  lifeEventCategoryOptions: {
    borderBottom: "1px solid grey",
    padding: "15px 0px 15px 0px",
    textAlign: "center",
    fontSize: "1.7rem",
    cursor: "pointer",
    "&:hover": {
      background: theme.colors.ITEM_HOVER,
    },
  },
  examplePara:{
    
    color: theme.colors.FORM_LABEL_COLOR,
    padding:"5px 0 5px 0"
  },
  categoryOption: {},
  btnAdd: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    width:'100%'
  },
  disabled :{
    opacity: 0.5,
    pointerEvents: "none"
  },
}));
