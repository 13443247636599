import React, { useState } from 'react';
import { useDashBoardStyles } from './Dashboard.styles';
import DashboardHeader from './DashboardHeader';
import DashboardStats from './DashboardStats';
import FlexibleSpaceCNO from '../../library/FlexibleSpaceCNO';
import LayoutRearrangeRibbon from './LayoutRearrangeRibbon';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import { useDispatch, useSelector } from 'react-redux';
import { updateWidgetsLayout } from '../../actions/dashboardActions';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { useMediaQuery } from '@material-ui/core';

function Dashboard() {

  const styles = useDashBoardStyles();

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const dispatch = useDispatch();
  const {
    updateWidgetsLoader
  } = useSelector(state => state.dashboard);

  const [isDraggable, setIsDraggable] = useState(false);
  const [isResizeable, setIsResizeable] = useState(false);

  const onRearrange = () => {
    setIsDraggable(true);
    setIsResizeable(true);
  };

  const onSaveRearrange = () => {
    setIsDraggable(false);
    setIsResizeable(false);

    const layouts = LocalStorageConstants.getItem('layouts', {});
    dispatch(updateWidgetsLayout(layouts));

  };

  const onCancelRearrange = () => {
    setIsDraggable(false);
    setIsResizeable(false);
  };

  return (
    <div className={styles.dashboardContainer}>

      {
        updateWidgetsLoader &&
        <FullScreenLoaderCNO />
      }

      {
        !tillTablet &&
        <DashboardHeader
          onRearrange={onRearrange}
        />
      }

      {/* {
        (isDraggable && isResizeable) &&
        <div className={styles.ribbonContainer} style={{ marginTop: "1rem" }}>
          <LayoutRearrangeRibbon
            onSave={onSaveRearrange}
            onCancel={onCancelRearrange}
          />
        </div>
      } */}

      <DashboardStats
        isDraggable={isDraggable}
        isResizeable={isResizeable}
      />
    </div>
  )
}

export default Dashboard;