import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    useTheme
  } from '@material-ui/core';
  import Icon from '@mdi/react';
  import React, { useEffect, useState } from 'react';
  import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
  import { useServiceProviderEngagemenStyles } from './ServiceproviderEngagementDetailsCard';
  import clsx from 'clsx';
  import { batch, useDispatch, useSelector } from 'react-redux';
  import { handleServiceSearch, setServices, setServiceSearchParams } from '../../actions/serviceActions';
  import FormBox from '../FormBox';
  import ServiceListing from '../../pages/ServiceManagement/ServiceListing';
  import IconCNO from '../../library/IconCNO';
  import { mdiChevronDown, mdiClockCheckOutline } from '@mdi/js';
  
  function ServiceProviderServicesSection({ profile }) {
  
    const theme = useTheme();
    const styles = useServiceProviderEngagemenStyles();
    const GlobalStyles = useGlobalStyles();
  
    const dispatch = useDispatch();
    const { services, servicesLoader, serviceSearchParams } = useSelector(state => state.services);
  
    const [isSectionExpanded, setIsSectionExpanded] = useState(false);
    const [page, setPage] = useState(0);
  
    useEffect(() => {
      return () => {
        setIsSectionExpanded(false);
      };
    }, []);
  
    console.log("=====ServiceProviderServicesSection====Services=Services", services);
    console.log("=====ServiceProviderServicesSection====Services=ServiceSearchParams", serviceSearchParams);
  
    return (
      <div style={{ padding: 0 }}>
        <Accordion
          expanded={isSectionExpanded}
          className={clsx(GlobalStyles.accordion, styles.accordion)}
        >
          <AccordionSummary
            expandIcon={<Icon
              path={mdiChevronDown}
              size="1.2rem"
              color={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR} />
            }
            className={GlobalStyles.accordionSummary}
            onClick={async (e) => {
              e.stopPropagation();
  
              const isExpanded = !isSectionExpanded;
  
              setIsSectionExpanded(isExpanded);
  
              if (isExpanded && !services.length) {
  
                const searchParams = {
                  customer: null,
                  categories: [],
                  service: null,
                  startDate: null,
                  endDate: null,
                  assignments: null,
                  serviceProvider:{id : profile.id}
                };
  
                console.log("=====ServiceProviderServicesSection====isExpanded=searchParams", { isExpanded, searchParams });
  
                dispatch(handleServiceSearch(searchParams));
  
              }
  
            }}
          >
            <Icon path={mdiClockCheckOutline} size='1.2rem' className={styles.accordionTitleIcon} />
            <Typography>Assigned Services</Typography>
          </AccordionSummary>
  
          {
            isSectionExpanded ? (
              <AccordionDetails className={styles.accordionDetails}>
                <div className={styles.grid}>
                  {
                    services.length
                      ? <div className={styles.listingWrapper} >
                        <ServiceListing timeFilterVisible={false} customer={profile} page={page} setPage={setPage} />
                      </div>
                      : <Typography className={styles.loader}>
                        {
                          servicesLoader ? "Loading..." : 'No Services found'
                        }
                      </Typography>
                  }
                </div>
              </AccordionDetails>
            ) : (null)
          }
  
        </Accordion>
      </div>
    );
  }
  
  export default ServiceProviderServicesSection;
  