import {
  SET_APPOINTMENTS,
  SET_APPOINTMENT_BY_ID,
  SET_APPOINTMENT_LOADERS,
  SET_APPOINTMENT_SEARCH_PARAMS,
  SET_APPOINTMENT_TIMES_FILTER,
  SET_HAS_MORE_APPOINTMENTS,
  SET_RESOLVED_APPOINTMENT_MEDIAS,
  TOGGLE_ADD_APPOINTMENT_DIALOG,
  TOGGLE_ADD_HOLIDAY_DIALOG,
  TOGGLE_ADD_LEAVE_DIALOG,
  TOGGLE_ADD_MEETING_DIALOG,
  SET_LEAVE_SEARCH_PARAMS,
  SET_HOLIDAY_SEARCH_PARAMS,
  SET_APPOINTMENT_TYPE
} from "../actions/appointmentActions";
import { TimesFilter } from "../utils";
import { getPrefillAssignedUser } from "../utils/Helpers";
import { AppointmentType } from "../utils/mappings";
import RightsManager from "../utils/RightsManager";

const isUserMember = RightsManager.isMember();

const initialState = {
  appointments: [],
  appointmentById: null,

  // Loaders
  appointmentsLoader: false,
  moreAppointmentsLoader: false,
  quotationsLoader: false,
  appointmentDetailLoader: false,
  addAppointmentLoader: false,
  editAppointmentLoader: false,
  deleteAppointmentLoader: false,
  uploadAppointmentMediaLoader: false,
  getAppointmentMediaLoader: false,
  deleteAppointmentMediaLoader: false,

  resolvedAppointmentMedias: [],

  hasMoreAppointments: true,
  isAddMeetingDialog: false,
  isAddLeaveDialog: false,
  isAddHolidayDialog: false,
  isAddAppointmentDialog: false,

  appointmentTimesFilter: TimesFilter.None,
  appointmentType:AppointmentType.MEETING,
  appointmentSearchParams: {
    customer: null,
    categories: [],
    appointment: null,
    startDate: null,
    endDate: null,
    assignments: isUserMember ? getPrefillAssignedUser() : null
  },
  leaveSearchParams:{
    leaveType:null,
    leave:null,
    startDate: null,
    endDate: null
  },
  holidaySearchParams:{
    holidayType:null,
    holiday:null,
    startDate: null,
    endDate: null
  }

};

export const appointmentReducer = (state = initialState, { type, payload }) => {

  switch (type) {

    case SET_APPOINTMENTS: {
      state = {
        ...state,
        appointments: payload
      };
      return state;

    }

    case SET_APPOINTMENT_BY_ID: {
      state = {
        ...state,
        appointmentById: payload
      };
      return state;

    }

    case SET_APPOINTMENT_LOADERS: {

      const { loaderName, loaderState } = payload;

      state = {
        ...state,
        [loaderName]: loaderState
      };
      return state;

    }

    case SET_HAS_MORE_APPOINTMENTS: {
      state = {
        ...state,
        hasMoreAppointments: payload
      };
      return state;
    }

    case TOGGLE_ADD_APPOINTMENT_DIALOG: {
      state = {
        ...state,
        isAddAppointmentDialog: payload
      };
      return state;
    }

    case TOGGLE_ADD_MEETING_DIALOG: {
      state = {
        ...state,
        isAddMeetingDialog: payload
      };
      return state;
    }

    case TOGGLE_ADD_LEAVE_DIALOG: {
      state = {
        ...state,
        isAddLeaveDialog: payload
      };
      return state;
    }

    case TOGGLE_ADD_HOLIDAY_DIALOG: {
      state = {
        ...state,
        isAddHolidayDialog: payload
      };
      return state;
    }

    case SET_APPOINTMENT_TIMES_FILTER: {
      state = {
        ...state,
        appointmentTimesFilter: payload
      };
      return state;
    }

    case SET_RESOLVED_APPOINTMENT_MEDIAS: {
      state = {
        ...state,
        resolvedAppointmentMedias: payload
      };
      return state;
    }

    case SET_APPOINTMENT_SEARCH_PARAMS: {
      state = {
        ...state,
        appointmentSearchParams: payload
      };
      return state;
    }

    case SET_LEAVE_SEARCH_PARAMS: {
      state = {
        ...state,
        leaveSearchParams: payload
      };
      return state;
    }

    case SET_HOLIDAY_SEARCH_PARAMS: {
      state = {
        ...state,
        holidaySearchParams: payload
      };
      return state;
    }

    case SET_APPOINTMENT_TYPE: {
      state = {
        ...state,
        appointmentType: payload
      };
      return state;
    }

    default:
      return state;

  }

};