import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanies,
  fetchMoreCompanies,
} from "../../actions/customerCompanyActions";
import CompanyListings from "../../components/CompanyListings";
import ScreenView from "../../components/ScreenView/ScreenView";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import RegisterCompany from "../../components/RegisterCompany";

function CompanyListPage() {
  const styles = useStyles();

  const dispatch = useDispatch();
  const {
    companies,
    allCompaniesLoader,
    hasMoreCompanies,
    isAddCompanyDialog,
  } = useSelector((state) => state.companies);
  const { companiesLoader, addLoader, deleteLoader } = allCompaniesLoader;

  const suggestions = useSelector((state) => state.suggestions);

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (companies.length <= 0) {
      const companiesPayload = {
        pageIndex: 0,
        orderBy: "newest",
        searchText: "",
        SortDecending: true,
      };

      dispatch(fetchCompanies(companiesPayload));
    }
  }, []);

  const loadMore = () => {
    if (hasMoreCompanies) {
      const moreCompaniesPayload = {
        pageIndex: page + 1,
        orderBy: "newest",
        searchText: "",
      };

      // dispatch(fetchMoreCompanies(moreCompaniesPayload));

      setPage(page + 1);
    }
  };

  return (
    <div className={styles.container_CompanyListPage}>
      {(deleteLoader || addLoader) && <FullScreenLoaderCNO />}
      <ScreenView
        content={
          <CompanyListings
            profileList={companies}
            profileLoading={companiesLoader}
            loadMore={loadMore}
            hasMore={hasMoreCompanies}
          />
        }
        headerTitle="Companies"
      />

      {isAddCompanyDialog && <RegisterCompany isRegister={false} />}
    </div>
  );
}

export default CompanyListPage;

const useStyles = makeStyles((theme) => ({
  container_CompanyListPage: {
    background: theme.colors.APP_BACKGROUND_COLOR,
  },
}));
