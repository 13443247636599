import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './CalendarPageStyles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ScreenView from '../../components/ScreenView';
import CalendarCNO from '../../library/CalendarCNO';
import CalendarSearchSidebar from '../../components/CalendarSearchSidebar';
import CalendarFilterBar from '../../components/CalendarFilterBar';
import { CalendarFilter } from '../../utils/mappings';
import { AppointmentFilterModal } from '../AppointmentManagement';
import JobSearchSidebar from '../../components/JobSearchSidebar/JobSearchSidebar';
import LeaveFilterModal from '../AppointmentManagement/LeaveFilterModal';
import HolidayFilterModal from '../AppointmentManagement/HolidayFilterModal';

function CalendarPage() {


  const tillTablet = useMediaQuery('(max-width: 768px)');

  const styles = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const { calendarFilter } = useSelector(state => state.calendar);

  const [page, setPage] = useState(0);
  const [isCalendarAddDialog, setIsCalendarAddDialog] = useState(false);

  const renderContent = () => (
    <div>

      <div style={{ marginLeft: 8, marginRight: 8, position: 'sticky', top: tillTablet ? 40 : 70, zIndex: 1200, borderRadius: 5 }}>

        <div style={{
          paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '0.4rem',
          background: theme.colors.FILTER_BAR_BACKGROUND_COLOR,
          borderRadius: 5,
        }}>
          <CalendarFilterBar
            setPage={setPage}
            isCalendarAddDialog={isCalendarAddDialog}
            setIsCalendarAddDialog={setIsCalendarAddDialog}
          />
        </div>
      </div>

      <CalendarCNO
        isCalendarAddDialog={isCalendarAddDialog}
        setIsCalendarAddDialog={setIsCalendarAddDialog}
      />
    </div>
  );

  const renderSearchSidebar = () => {

    switch (calendarFilter) {
      case CalendarFilter.Appointments: {
        return (
          <AppointmentFilterModal />
        )
      }

      case CalendarFilter.Jobs: {
        return (
          <JobSearchSidebar setPage={setPage} />
        )
      }

      case CalendarFilter.Leaves: {
        return (
          <LeaveFilterModal />
        )
      }

      case CalendarFilter.Holidays: {
        return (
          <HolidayFilterModal />
        )
      }

      default: {
        return (
          <AppointmentFilterModal />
        )
      }
    }

  }

  return (
    <div className={styles.container_CalendarPage}>
      <ScreenView
        content={renderContent()}
        secondarySidebar={renderSearchSidebar()}
      />
    </div>
  )
}

export default CalendarPage;
