import React, { useContext, useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { mdiDelete, mdiAccountDetails } from "@mdi/js";
import Icon from "@mdi/react";
import AddTest from "../AddTest/AddTest";
import { TestContext } from "../../common/contexts/TestContext";
import ConfirmDialogTest from "../ConfirmDialogTest/ConfirmDialogTest";
import LinkCNO from "../../library/LinkCNO";

function TestMorePopover({ item, onClose }) {
  const theme = useTheme();
  const styles = useStyles();

  const { handleDeleteTest, setIsModalOpen, setNewTest, setCurrentTestId } =
    useContext(TestContext);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleDelete = () => {
    handleDeleteTest(item.testId);
    setOpenConfirm(false);
    onClose();
  };

  const handleEdit = () => {
    setCurrentTestId(item.testId);
    setNewTest(item);
    onClose();
    setIsModalOpen(true);
  };

  return (
    <>
      {openConfirm && (
        <ConfirmDialogTest
          open={openConfirm}
          setOpen={setOpenConfirm}
          message={item?.title}
          item={item}
          handleDelete={handleDelete}
          onClose={onClose}
        />
      )}
      <AddTest />

      <div className={styles.container_MorePopover}>
        <List className={styles.list}>
       
          <LinkCNO to={`/testq/${item.testId}`}>
            <ListItem className={styles.listItem} onClick={onclose} >
              <ListItemText primary='Details' />
              <ListItemIcon>
                <IconButton edge='end'>
                  <Icon path={mdiAccountDetails} size='1.5rem' color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR} />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </LinkCNO>
      
          <ListItem className={styles.listItem} onClick={handleEdit}>
            <ListItemText primary="Edit Test" />
            <ListItemIcon>
              <IconButton edge="end">
                <Icon
                  path={mdiAccountDetails}
                  size="1.5rem"
                  color={
                    theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR
                  }
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
          <ListItem
            className={styles.listItem}
            onClick={() => {
              setOpenConfirm(true);
              // onClose(); // Assuming onClose is a function you want to call when confirming
            }}
          >
            <ListItemText primary="Delete Test" />
            <ListItemIcon>
              <IconButton edge="end">
                <Icon
                  path={mdiDelete}
                  size="1.5rem"
                  color={theme.colors.ERROR_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        </List>
      </div>
    </>
  );
}

export default TestMorePopover;

const useStyles = makeStyles((theme) => ({
  container_MorePopover: {
    padding: 5,
    overflow: "hidden",
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR,
  },
  list: {
    background: theme.colors.MORE_ACTIONS_OPTIONS_WRAPPER_BACKGROUND_COLOR,
    padding: 0,
  },
  listItem: {
    background:
      theme.colors.MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 2,
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.01)",
    },
  },
  listItemText: {
    textDecoration: "none",
  },
}));
