import { SET_VISITOR_DETAILS,SET_TO_MEEET } from "../actions/visitorActions";

const initialState={
  fullName:"",
  phoneNumber:"",
  toMeet:[],
  tower:"",
  purpose:"",
}

export const visitorReducer = (state = initialState, { type, payload }) => {

    switch (type) {
  
      case SET_VISITOR_DETAILS:
        state = {
          ...state,
          fullName: payload.fullName,
          phoneNumber:payload.phoneNumber,
          toMeet:payload.toMeet,
          tower:payload?.tower ? payload.tower : "",
          purpose:payload.purpose,
        };
        return state;
  
      case SET_TO_MEEET:
        state = {
          ...state,
          toMeet: payload.toMeet,
        };
        return state;
  
      default:
        return state;
    }
  
};