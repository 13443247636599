import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_ChatGroupListItem: {
    border: `1px solid ${theme.colors.CHAT_NEW_GROUP_LIST_ITEM_BACKGROUND_COLOR}`,
    marginBottom: 5,
    borderRadius: 3,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  selectedIndicator: {
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    padding: 2,
    background: theme.colors.CHAT_NEW_GROUP_LIST_ITEM_UNSELECTED_BACKGROUND_COLOR
  },
  activeSelectemItem: {
    background: theme.colors.CHAT_NEW_GROUP_LIST_ITEM_SELECTED_BACKGROUND_COLOR
  },
  username: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: theme.colors.CHAT_NEW_GROUP_LIST_ITEM_TITLE_COLOR
  },
  listItem: {
    paddingTop: 8,
    background: theme.colors.CHAT_NEW_GROUP_LIST_ITEM_BACKGROUND_COLOR
  },

}));