import React, { useState } from 'react';
import ScreenView from '../../components/ScreenView/ScreenView';
import AppointmentsListing from './AppointmentsListing';
import { AppointmentFilterModal } from '.';
import AppointmentTypeFilterModal from './AppointmentTypeFilterModal';
function AppointmentsListingPage() {

  const [page, setPage] = useState(0);

  return (
    <div>
      <ScreenView
        content={<AppointmentsListing page={page} setPage={setPage} />}
        secondarySidebar={<AppointmentTypeFilterModal setPage={setPage} />}
        headerTitle="Appointments"
      />
    </div>
  )
}

export default AppointmentsListingPage
