import { toast } from "react-toastify";
import { v4 } from "uuid";
import {
  apiInvoiceGetMediaLink,
  apiInvoiceSearch,
  apiInvoiceUploadMedia,
  apiInvoiceInitUpload
} from "../../api/invoice";
import { getContentType, isArrayEmpty } from "../Helpers";
import { FileType, MediaSizeType, MediaType } from "../mappings";

export const getInvoiceMedias = async (invoiceId) => {

  let medias = [];

  console.log("Get Invoice Medias: invoiceId: ", invoiceId);

  try {
    const response = await apiInvoiceSearch.post({ ids: [invoiceId] });

    console.log("Get Invoice Medias: response: ", response);
    console.log("Get Invoice Medias: !isArrayEmpty(response.data): ", !isArrayEmpty(response.data));

    if (response && !isArrayEmpty(response.data.data)) {
      const invoice = response.data.data[0];

      console.log("Get Invoice Medias: invoice: ", invoice);

      medias = invoice.mediaLinks;
    }
  } catch (error) {
    console.log("Error: Get Invoice Medias: ", error);
  }

  console.log("Get Invoice Medias: medias: ", medias);

  return medias;

}

export const getResolvedInvoiceMedias = async (medias, invoiceId) => {

  const mediaIds = medias.map(media => media.id);

  const dataToSend = {
    ids: mediaIds,
    size: MediaSizeType.BigScreen
  };

  let resolvedMedias = [];

  try {
    const response = await apiInvoiceGetMediaLink.post(dataToSend);

    resolvedMedias = response.data.links;

    const resolvedMediasPromise = resolvedMedias.map(async (media, index) => {

      let fileType = null, contentType = null;

      try {
        // if ([1].includes(index)) {
        //   throw new Error("forcefully error upload");
        // } else {
        const resp = await getContentType(media.link);
        fileType = resp.fileType;
        contentType = resp.contentType;
        // }
      } catch (error) {
        toast.error("Media not found. Please delete and upload the media again.");
        console.log("Error: Resolve Invoice Medias: Get Content Type: ", error);
        return null;
      }

      const isImage = fileType === FileType.Image;
      return {
        ...media,
        uri: media.link,
        // thumbnailUri: allMedias[index].link,
        fileType: fileType,
        type: contentType,
        isResolved: true,
        isImage,
        invoiceId,
        newId: v4()
      };

    });

    resolvedMedias = await Promise.all(resolvedMediasPromise);

  } catch (error) {
    resolvedMedias = [];
    console.log("Error: Resolve Invoice Medias: ", error);
  }

  return resolvedMedias.filter(Boolean);

}

export const getAWSInvoiceUploadSignedUrls = async (ids = []) => {

  if (isArrayEmpty(ids)) return null;

  let awsUploadSignedUrls = [];

  try {
    const awsUploadSignedUrlsResp = await apiInvoiceInitUpload.post({ ids });
    awsUploadSignedUrls = awsUploadSignedUrlsResp.data.links;
  } catch (error) {
    awsUploadSignedUrls = [];
    console.log("Error: Get AWS Invoice Media Signed URLs: ", error);
  }

  return awsUploadSignedUrls;

}

// TODO: group medias based on mediaType and then make server call accordingly
export const saveUploadedInvoiceMediasToServer = async (medias, ids, invoiceId, mediaType = MediaType.Input) => {

  const prevMedias = await getInvoiceMedias(invoiceId);

  const newMedias = medias.map((media, index) => ({
    id: ids[index],
    title: media.name,
    fileType: media.type,
    abstract: "",
    link: ids[index],
    mediaType: media.mediaType || mediaType,
    invoiceId: invoiceId
  }));

  const mediasToSave = [...newMedias, ...prevMedias];

  const dataToSend = {
    invoiceId: invoiceId,
    medias: mediasToSave
  };

  return await apiInvoiceUploadMedia.post(dataToSend);

}

