import {
  SET_QUOTATION_ITEMS,
  SET_QUOTATION_ITEM_BY_ID,
  TOGGLE_ADD_QUOTATION_ITEM_DIALOG,
  SET_QUOTATION_ITEM_LOADERS,
  SET_IS_NEW_QUOTATION_ITEM_ADDED
} from '../actions/quotationItemActions';

const initialState = {
  quotationItems: [],
  quotationItemById: null,
  isAddQuotationItemDialog: false,
  quotationItemsLoader: false,
  quotationItemDetailLoader: false,
  addLoader: false,
  editLoader: false,
  deleteLoader: false,
  isNewQuotationItemAdded: false
};

export function quotationItemReducer(state = initialState, { type, payload }) {


  switch (type) {

    case SET_IS_NEW_QUOTATION_ITEM_ADDED:
      state = {
        ...state,
        isNewQuotationItemAdded: payload
      };
      return state;

    case SET_QUOTATION_ITEMS:
      state = {
        ...state,
        quotationItems: payload
      };
      return state;

    case SET_QUOTATION_ITEM_BY_ID:
      state = {
        ...state,
        quotationItemById: payload
      };
      return state;

    case TOGGLE_ADD_QUOTATION_ITEM_DIALOG:
      state = {
        ...state,
        isAddQuotationItemDialog: payload
      };
      return state;

    case SET_QUOTATION_ITEM_LOADERS:

      const { loaderName, loaderValue } = payload;

      state = {
        ...state,
        [loaderName]: loaderValue
      };
      return state;

    default:
      return state;
  }

}