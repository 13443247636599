import React from 'react';
import { useStyles } from './SettingsStyles';
import ChangePassword from '../ChangePassword';
import AppearanceExpandable from '../../pages/SettingsPage/AppearanceExpandable';

function Settings() {

  const styles = useStyles();

  return (
    <div className={styles.container_Settings}>

      <AppearanceExpandable />

      <ChangePassword />

    </div>
  )
}

export default Settings
