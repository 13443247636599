import {
  Button,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect } from "react";
import AppLogo from "../../assets/appLogo.png";
import AppLogoBlack from "../../assets/appLogoBlack.png";
import NameLogo from "../../assets/nameLogo.png";
import NameLogoBlack from "../../assets/nameLogoBlack.png";
import { useFormik } from "formik";
import * as yup from "yup";
import LinkCNO from "../../library/LinkCNO/LinkCNO";
import { login } from "../../actions/authUserActions";
import { useDispatch, useSelector } from "react-redux";
import InputCNO from "../../library/InputCNO";
import { mdiEmail, mdiLock } from "@mdi/js";
import { toggleAddCompanyDialog } from "../../actions/customerCompanyActions";
import RegisterCompany from "../../components/RegisterCompany/RegisterCompany";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";

function LoginPage() {
  const theme = useTheme();

  const tillTablet = useMediaQuery("(max-width: 768px)");

  const dispatch = useDispatch();

  const { logInLoader } = useSelector((state) => state.authUser);
  const { isAddCompanyDialog, allCompaniesLoader } = useSelector(
    (state) => state.companies
  );
  const { addLoader } = allCompaniesLoader;

  const handleLogin = (values) => {
    dispatch(login(values.email, values.password));
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required."),
    password: yup
      .string()
      .min(4, "Atleast 4 characters are required")
      .required("Password is required."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => handleLogin(values),
  });

  const openRegisterCompany = () => {
    dispatch(toggleAddCompanyDialog(true));
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.key === "Enter" || event.key === "NumpadEnter") {
        event.preventDefault();
        if (formik.isValid) {
          handleLogin(formik.values);
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [formik.values, formik.isValid]);

  const styles = useStyles({ tillTablet });
  return (
    <div className={styles.container}>
      {(addLoader || logInLoader) && <FullScreenLoaderCNO />}
      <LinkCNO to="/home" className={styles.brand}>
        <img
          className={styles.appLogo}
          src={theme.palette.type !== "dark" ? AppLogoBlack : AppLogo}
          alt=""
        />
        <img
          className={styles.nameLogo}
          src={theme.palette.type !== "dark" ? NameLogoBlack : NameLogo}
          alt=""
        />
      </LinkCNO>
      <Paper className={styles.loginCard}>
        <Typography
          variant="h5"
          styles={{ color: theme.colors.LOGIN_REGISTER_TEXT_COLOR }}
        >
          Login
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <InputCNO
            name="email"
            formik={formik}
            placeholder="Email"
            icon={mdiEmail}
            fullWidth
          />
          <InputCNO
            name="password"
            type="password"
            formik={formik}
            placeholder="Password"
            icon={mdiLock}
            fullWidth
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              className={styles.registerBtn}
              onClick={openRegisterCompany}
            >
              Register
            </Button>

            <Button
              color="primary"
              type="submit"
              variant="contained"
              className={styles.loginBtn}
              disabled={!formik.dirty || !formik.isValid}
            >
              Login
            </Button>
          </div>

          {isAddCompanyDialog && (
            <RegisterCompany
              isRegister={true}
              onRegister={(email) => {
                formik.setFieldValue("email", email);
              }}
            />
          )}
        </form>
      </Paper>
    </div>
  );
}

export default LoginPage;

const useStyles = makeStyles((theme) => ({
  container: {
    // width: ({ tillTablet }) => tillTablet ? '95%' : '40%',
    height: "100vh",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    margin: "0 auto",
    background: theme.colors.LOGIN_MAIN_BODY_BACKGROUND_COLOR,
  },
  brand: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  appLogo: {
    width: 50,
    marginRight: 10,
  },
  nameLogo: {
    width: 180,
  },
  loginCard: {
    background: theme.colors.LOGIN_CONTAINER_BACKGROUND_COLOR,
    width: ({ tillTablet }) => (tillTablet ? "95%" : "40%"),
    margin: "0 auto",
    marginTop: ({ tillTablet }) =>
      tillTablet ? theme.spacing(3) : theme.spacing(5),
    padding: ({ tillTablet }) =>
      tillTablet ? theme.spacing(2) : theme.spacing(4),
    paddingBottom: theme.spacing(2),
    textAlign: "center",
    borderRadius: 10,
    "& form": {
      textAlign: "center",
    },
  },
  textField: {
    background: theme.colors.INPUT_BACKGROUND_COLOR,
    borderRadius: 5,
    marginTop: theme.spacing(2),
    "& input": {
      color: theme.colors.INPUT_TEXT_COLOR,
    },
  },
  icon: {
    fill: theme.colors.INPUT_ICON_COLOR,
  },
  loginBtn: {
    // marginTop: ({ tillTablet }) => tillTablet ? theme.spacing(2) : theme.spacing(5),
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    boxShadow: `2px 2px 6px -1px ${theme.palette.common.white}`,
    "&:focus": {
      boxShadow: `2px 2px 6px -1px ${theme.palette.common.white}`,
    },
  },
  registerBtn: {
    border: `2px solid ${theme.colors.FORM_CANCEL_BUTTON_BORDER_COLOR}`,
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
    // boxShadow: `2px 2px 6px -1px ${theme.palette.common.white}`,
    // '&:focus': {
    //   boxShadow: `2px 2px 6px -1px ${theme.palette.common.white}`,
    // }
  },
  registerLink: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(5),
    textDecoration: "underline",
    color: "#79a8d7",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
