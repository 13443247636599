import {
  carpenter,
  contractor,
  welder,
  gardener,
  servicesImage,
  technician,
  tiler,
  mechanic,
  painter,
  plumber,
  cleaner,
  electrician,
  electricianDark,
  cleanerDark,
  gardenerDark,
  contractorDark,
  tilerDark,
  mechanicDark,
  welderDark,
  plumberDark,
  painterDark,
  technicianDark,
  carpenterDark,
  createService,
  createServiceDark
} from "../../assets/serviceAssetsIcons";
import { updateIdWithUUID } from "../../utils/Helpers";

export const categories = [
  {
    id: "1b9b93bb-5f0b-4df8-b879-690b414efb42",
    category: "Plumber",
    icon: plumber,
    iconDark: plumberDark,
    service:'Plumbing Services'
  },
  {
    id: "6e02567b-0b1f-4779-8654-0fb1e4087635",
    category: "Painter",
    icon: painter,
    iconDark: painterDark,
    service:'Painting Services'

  },
  {
    id: "c1524e92-6fd2-409e-91ac-01d5035d6f4c",
    category: "Carpenter",
    icon: carpenter,
    iconDark: carpenterDark,
    service:'Carpentry Services'

  },
  {
    id: "67188f14-316e-431f-9927-8fbf4ccb8de9",
    category: "Mechanic",
    icon: mechanic,
    iconDark: mechanicDark,
    service:'Mechanichal Services'

  },
  {
    id: "9496926e-2278-42ff-ae0e-45aa4c986884",
    category: "Electrician",
    icon: electrician,
    iconDark: electricianDark,
    service:'Electrical Services'

  },

  {
    id: "31f8c3ad-87a5-4466-a113-d201ac82bb4b",
    category: "Welder",
    icon: welder,
    iconDark: welderDark,
    service:'Welding Services'

  },
  {
    id: "1d53c095-0c44-45e6-bb13-9e41add6c34c",
    category: "Gardener",
    icon: gardener,
    iconDark: gardenerDark,
    service:'Gardening Services'

  },
  {
    id: "1138ad12-3359-4e3a-95f5-194540e4b684",
    category: "Technician",
    icon: technician,
    iconDark: technicianDark,
    service:'Technichal Services'

  },
  {
    id: "7f859171-99e4-4100-8150-8491d17a22d0",
    category: "Barber",
    icon: tiler,
    iconDark: tilerDark,
    service:'Tiling Services'

  },
  {
    id: "e0900f10-4ebe-487d-b540-a0180e4824ba",
    category: "Cleaner",
    icon: cleaner,
    iconDark: cleanerDark,
    service:'Cleaning Services'

  },
  {
    id: "aef2b3d4-4bf1-40c9-a2d7-cfb1af4ee08c",
    category: "Contractor",
    icon: contractor,
    iconDark: contractorDark,
    service:'Contracting Services'

  },
  {
    id: "d97a28b6-6f20-443b-8e31-8028c2069103",
    category: "Add a new service",
    icon: createService,
    iconDark: createServiceDark,
    service:'New Service'

  },
];

// export const categories = updateIdWithUUID(categoriesData);

