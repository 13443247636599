import React, { useState } from 'react';
import ScreenView from '../../components/ScreenView/ScreenView';
import ServiceListing from './ServiceListing';
import ServiceFilterModal from './ServiceFilterModal';

function ServicesListingPage() {

  const [page, setPage] = useState(0);

  return (
    <div>
      <ScreenView
        content={<ServiceListing page={page} setPage={setPage} />}
        secondarySidebar={<ServiceFilterModal setPage={setPage} />}
        headerTitle="Services"
      />
    </div>
  )
}

export default ServicesListingPage;
