import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import Icon from "@mdi/react";
import React from "react";
import CloseIcon from "../../library/CloseIcon";

function HeadingBar({ title, onClose, onHeaderClick, icon, backTop }) {
  const theme = useTheme();

  const dialogBP = useMediaQuery("(max-width: 453px)");

  const useStyles = makeStyles((theme) => ({
    title: {
      borderBottom: `2px solid #9e9e9e`,
      paddingLeft: dialogBP ? "2px" : theme.spacing(1),
      paddingRight: dialogBP ? "2px" : theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: backTop ? "flex-start" : "space-between",
      alignItems: "center",
      fontSize: dialogBP ? "3vw !important" : "1rem",
      width: "100%",
    },
    listItem: {
      fontSize: "1.2rem", // Increase font size of list items
      position: "relative", // Position relative to contain absolute elements
      padding: "8px 0", // Adjust padding for list items
    },
    dimBackground: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      // backgroundColor: "rgba(0, 0, 0, 0.5)", 
      background:theme.colors.SEARCH_SIDEBAR_BACKGROUND,

      zIndex: -1, 
    },
  }));
  const styles = useStyles({ dialogBP });

  return (
    <div
      className={styles.title}
      onClick={icon ? onHeaderClick : null}
      style={{ ...(icon ? { cursor: "pointer" } : {}) }}
    >
      {backTop ? (
        <>
          {onClose || onHeaderClick ? (
            <CloseIcon path={icon} onClick={onClose} />
          ) : null}
          <div style={{ color: theme.colors.DIALOG_TITLE_COLOR, textAlign: "center", flex: 1 }}>{title}</div>
        </>
      ) : (
        <>
          <div style={{ color: theme.colors.DIALOG_TITLE_COLOR }}>{title}</div>
          {onClose || onHeaderClick ? (
            icon ? (
              <CloseIcon path={icon} onClick={icon ? onHeaderClick : onClose} />
            ) : (
              <CloseIcon onClick={onClose} />
            )
          ) : null}
        </>
      )}
      {/* Dimmed background */}
      <div className={styles.dimBackground} />
    </div>
  );
}

export default HeadingBar;
