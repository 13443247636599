import React, { Fragment, useEffect, useState } from 'react';
import { useStyles } from './CompanyHeaderDetailsCardStyles';
import { Badge, CircularProgress, Typography, useTheme } from '@material-ui/core';
import AvatarCNO from '../../library/AvatarCNO/AvatarCNO';
import { mdiOfficeBuilding, mdiCamera, mdiFormatText, mdiImagePlus } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import DialogCNO from '../../library/DialogCNO';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { updateEmployeeDetails } from '../../actions/employeeActions';
import { updateCustomerDetails } from '../../actions/customerActions';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import Icon from '@mdi/react';
import Upload from 'rc-upload';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { ApiCore } from '../../api/utils/core';
import RightsManager from '../../utils/RightsManager';
import { updateCompanyDetails } from '../../actions/customerCompanyActions';

function EditMainDetails({
  open,
  onClose,
  profile,
  editablePropertyName
}) {
  const styles = useStyles();

  const label = {
    'title': 'Company Name',
    'abstract': 'Abstract',
  };
  const placeholder = {
    'title': 'Enter Company Name',
    'abstract': 'Enter abstract'
  };

  const dispatch = useDispatch();

  const {
    allCompaniesLoader: { editLoader }
  } = useSelector(state => state.companies);

  const validationSchema = yup.object({
    [editablePropertyName]: yup.string().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      [editablePropertyName]: profile[editablePropertyName],
    },
    validationSchema,
    onSubmit: values => handleUpdate(values)
  });

  const handleUpdate = (values) => {

    const dataToSend = {
      ...profile,
      [editablePropertyName]: values[editablePropertyName]
    };

    dispatch(updateCompanyDetails(dataToSend));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Details"
      loading={editLoader}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <InputCNO
          name={editablePropertyName}
          label={label[editablePropertyName]}
          formik={formik}
          placeholder={placeholder[editablePropertyName]}
          icon={mdiFormatText}
          fullWidth
        />
        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )
};

function CompanyHeaderDetailsCard({
  profile,
  isActive
}) {

  const styles = useStyles();

  const theme = useTheme();

  const isUserAdmin = RightsManager.isAdmin();

  const dispatch = useDispatch();
  const { hasSubscriptionExpired } = useSelector(state => state.subscription);

  const [editablePropertyName, setEditablePropertyName] = useState(null);
  const [isMainDetailsEditable, setIsMainDetailsEditable] = useState(false);

  const [avatarLoading, setAvatarLoading] = useState(false);

  const [localAvatar, setLocalAvatar] = useState(null);

  useEffect(() => {

    (async function getMediaIIFE() {

      if (!profile.profilePic) {
        return;
      }

      const dataToSend = {
        "ids": [profile.profilePic],
        "size": 3
      };

      const apiGroupGetMedia = new ApiCore({
        post: true,
        url: "group/getmedialink"
      });

      await apiGroupGetMedia.post(dataToSend)
        .then((response) => {
          if (response.status === 200) {
            const links = response.data.links;
            setLocalAvatar(links[0].link);
          } else {
            console.log("Get Media Error: ");
          }

        })
        .catch((error) => {
          console.log("Get Media Error: ", error);
        });

    })();

  }, []);

  // converts base64 image to blob
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const processAndUploadImage = async (base64file) => {
    setAvatarLoading(true);
    const imageId = v4();
    const imageURL = base64file;

    // Split the base64 string in data and contentType
    const block = imageURL.split(";");
    // Get the content type of the image
    const contentType = block[0].split(":")[1];
    // get the real base64 content of the file
    const realData = block[1].split(",")[1];

    const blob = b64toBlob(realData, contentType);

    // const url = "member/initupload";
    const url = "group/initupload";
    const apiProfileInitUpload = new ApiCore({
      post: true,
      url: url
    });

    const initRes = await apiProfileInitUpload.post({ "ids": [imageId] });

    console.log("initResgroup: ", initRes);

    // UPLOAD
    const mRes = await fetch(initRes.data.links[0].m, { method: 'PUT', body: blob });
    const tRes = await fetch(initRes.data.links[0].t, { method: 'PUT', body: blob });
    const bRes = await fetch(initRes.data.links[0].b, { method: 'PUT', body: blob });

    const updatedProfile = {
      ...profile,
      profilePic: imageId,
      localProfilePicURI: imageURL
    };

    setLocalAvatar(imageURL);

    dispatch(updateCompanyDetails(updatedProfile));

    setAvatarLoading(false);

  };

  const processMediaUpload = (file) => {

    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      // console.log("File Reader image Result: ", reader.result);
      processAndUploadImage(reader.result);
    };

    reader.onerror = function (error) {
      toast.error("Error in uploading profile");
      console.log('Error: ', error);
    };
  };

  const uploadProps = {
    beforeUpload: (file) => {
      if (file.type.includes('image')) {
        return true;
      }
      toast.error("Unsupported file");
      return false;
    },
    action: (file) => {
      console.log("Upload Action file: ", file);
      processMediaUpload(file);
    },
    multiple: false,
  };

  return (
    <div className={styles.container_CompanyHeaderDetailsCard}>

      <Badge
        className={styles.avatarBadge}
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          (isUserAdmin) && isActive && !hasSubscriptionExpired
            ? <div className={styles.pickers}>
              <Icon
                path={mdiCamera}
                size="1.5rem"
                color={theme.colors.IMAGE_CAMERA_ICON_COLOR}
                style={{ visibility: 'hidden' }}
              />
              <Upload {...uploadProps} accept="image/*">
                <Icon
                  path={mdiImagePlus}
                  size="1.5rem"
                  color={theme.colors.IMAGE_UPLOAD_ICON_COLOR}
                />
              </Upload>
            </div>
            : null
        }
      >
        {
          avatarLoading
            ? <CircularProgress
              style={{
                background: '#4e4e4e',
                padding: '20px',
                borderRadius: '50%',
                width: '8rem',
                height: '8rem'
              }}
            />
            : <div className={styles.avatarWrapper}>
              <AvatarCNO
                src={localAvatar}
                fallbackIcon={mdiOfficeBuilding}
                avatarStyle={{ border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}` }}
                iconSize="8rem"
                openAvatarInImageViewer={true}
              />
              {
                !isActive &&
                <Typography
                  component="span"
                  className={styles.avatarStatusText}
                >
                  Not Active
                </Typography>
              }
            </div>

        }

      </Badge>

      <Typography
        variant="h5"
        className={styles.username}
        onClick={() => {
          setIsMainDetailsEditable(true);
          setEditablePropertyName('title');
        }}
      >
        {profile.title}
      </Typography>

      <Typography
        className={styles.abstract}
        component="pre"
        onClick={() => {
          setIsMainDetailsEditable(true);
          setEditablePropertyName('abstract');
        }}
      >
        {profile.abstract || 'Click to add description'}
      </Typography>

      {
        isMainDetailsEditable &&
        (isUserAdmin) &&
        isActive &&
        !hasSubscriptionExpired &&
        <EditMainDetails
          open={isMainDetailsEditable}
          onClose={() => setIsMainDetailsEditable(false)}
          profile={profile}
          editablePropertyName={editablePropertyName}
        />
      }

    </div>
  )
}

export default CompanyHeaderDetailsCard
