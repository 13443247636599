import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_CreateChatGroupModal: {
    '& ::-webkit-scrollbar': {
      display: 'none'
    },
  },
  paper: {
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`,
    borderRadius: 10,
    minHeight: 620,
    width: '70vw',
    maxWidth: '90vh',
    margin: ({ tillTablet }) => tillTablet ? 8 : 32
  },
  form: {
    position: 'relative',
    padding: '20px',
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    minHeight: 620,
    overflowX: 'hidden',
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`,
    '&>*': {
      marginBottom: theme.spacing(3)
    },
  },
  textField: {
    borderRadius: 5,
    '& input': {
      background: theme.colors.INPUT_BACKGROUND_COLOR,
      color: theme.colors.INPUT_TEXT_COLOR,
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: theme.colors.INPUT_TEXT_COLOR,
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: theme.colors.INPUT_BACKGROUND_COLOR,
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      // "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": theme.colors.INPUT_TEXT_COLOR,
      caretColor: theme.colors.INPUT_TEXT_COLOR,
    }
  },
  selectionWrapper: {
    background: theme.colors.CHAT_NEW_GROUP_LIST_ITEM_BACKGROUND_COLOR,
    padding: "0.5rem 1rem",
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
    position: 'sticky',
    top: 0,
    zIndex: 1
  },
  badgeWrapper: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  badgeUsername: {
    fontSize: '0.7rem',
    color: theme.colors.CHAT_NEW_GROUP_LIST_ITEM_TITLE_COLOR
  },
  selectedUseradge: {
    margin: 4,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  selectedUserAvatar: {
    // border: '1px solid orange',
    padding: 1
  },
  selectedUserCross: {
    background: theme.colors.ERROR_COLOR,
    borderRadius: '50%',
    color: 'white'
  },
  fabContainer: {
    width: '100%',
    // border: '1px solid blue',
    position: 'sticky',
    bottom: 0,
    right: 20,
    textAlign: 'right',
  },
  fab: {
    background: theme.colors.GREEN_COLOR,
    color: 'white',
  }
}));