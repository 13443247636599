import { getAppointmentEventsList, getHolidayEventsList, getJobEventsList, getLeaveEventsList } from "../utils/CalendarHelpers";
import { CalendarFilter } from "../utils/mappings";

export const SET_EVENTS_LIST = "SET_EVENTS_LIST";
export const SET_CALENDAR_FILTER = "SET_CALENDAR_FILTER";
export const SET_CALENDAR_LOADERS = "SET_CALENDAR_LOADERS";
export const SET_ADD_MODALS_CALENDAR_DATES = "SET_ADD_MODALS_CALENDAR_DATES";
export const SET_API_CALENDAR_DATES_FILTER = "SET_API_CALENDAR_DATES_FILTER";

export const setApiCalendarDatesFilter = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_API_CALENDAR_DATES_FILTER,
      payload: payload
    });
  };
};


export const setAddModalsCalendarDates = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_ADD_MODALS_CALENDAR_DATES,
      payload: payload
    });
  };
};

export const setCalendarLoaders = (loaderName, loaderState) => {
  return (dispatch) => {
    dispatch({
      type: SET_CALENDAR_LOADERS,
      payload: { loaderName, loaderState }
    });
  };
};

export const setEventsList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_EVENTS_LIST,
      payload: payload
    });
  };
};

export const setCalendarFilter = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_CALENDAR_FILTER,
      payload: payload
    });
  };
};

export const fetchEventsList = (data) => {
  return (dispatch, getState) => {

    const { calendarFilter } = getState().calendar;

    let eventsList = [];

    switch (calendarFilter) {
      case CalendarFilter.Appointments: {
        eventsList = getAppointmentEventsList(data);
        break;
      }

      case CalendarFilter.Jobs: {
        eventsList = getJobEventsList(data);
        break;
      }

      case CalendarFilter.Leaves: {
        console.log("CalendarActionLeaveData",data);
        eventsList = getLeaveEventsList(data);
        console.log("CalendarActionEventList",eventsList);
        break;
      }

      case CalendarFilter.Holidays: {
        eventsList = getHolidayEventsList(data);
        break;
      }

      default: {
        eventsList = getAppointmentEventsList(data);
        break;
      }
    }

    dispatch(setEventsList(eventsList));

  };
};

