import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  mdiCalculatorVariant,
  mdiChevronDown,
  mdiClose,
  mdiCurrencyGbp,
  mdiFileCancel,
  mdiHistory,
  mdiHours24,
} from "@mdi/js";
import Icon from "@mdi/react";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { togglePayUserDialog } from "../../actions/trackSearchActions";
import { apiPaymentStaff, apiTaskGetAssignment } from "../../api";
import CloseIcon from "../../library/CloseIcon/CloseIcon";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import InputCNO from "../../library/InputCNO";
import PaymentHistoryTable from "../PaymentHistoryTable/PaymentHistoryTable";
import { AppConstants } from "../../utils/AppConstants";
import {
  fetchTaskPaymentHistory,
  setHasMorePaymentHistory,
  setTaskPaymentHistory,
} from "../../actions/taskSearchActions";
import { v4 } from "uuid";
import { Currency, CurrencyIcon } from "../../utils/mappings";
import * as icons from "@mdi/js";
import { getMaterialIconString } from "../../utils/icons";
import { parseInputFieldNumber, parseNumber } from "../../utils/Helpers";
import BonusCalculationDetailsDialog from "../BonusCalculationDetailsDialog/BonusCalculationDetailsDialog";
import { getBonusValues } from "../../utils/TrackPayUser";
import HeadingBar from "../HeadingBar";
import InputLabelCNO from "../../library/InputLabelCNO";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import MakePaymentDialog from "../MakePaymentDialog/MakePaymentDialog";

function PayUser({ job, totalHoursWithinProximity }) {
  let currencyType = localStorage.getItem("currencyType");
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();

  const { isPayUserDialog } = useSelector((state) => state.trackSearch);

  let { target } = job;
  target = 10;
  const isTaskCompleted = true;

  const closePayUser = () => {
    dispatch(togglePayUserDialog(false));
    dispatch(setHasMorePaymentHistory(true));
  };

  const [paidAmount, setPaidAmount] = useState(0);
  const [paidBonus, setPaidBonus] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalPayable, setTotalPayable] = useState(0);
  const [currentBonus, setCurrentBonus] = useState(0);

  const [isBonusCalculation, setIsBonusCalculation] = useState(false);
  const [isPaymentDialog, setIsPaymentDialog] = useState(false);

  const deductionAmount = 0;

  const tillTablet = useMediaQuery("(max-width: 768px)");

  const { taskPaymentHistory } = useSelector((state) => state.taskSearch);

  const teamMember = useSelector(
    (state) => state.trackSearch.mapSearchParams.teamMember
  );
  const teamMemberName = teamMember ? teamMember.title : "User";

  const validationSchema = yup.object({
    hoursWorked: yup
      .string()
      .test("nan", "Please enter valid value", (value) => !isNaN(value))
      .required("Total working hours is required!"),
    hourlyRate: yup
      .string()
      .test("nan", "Please enter valid value", (value) => !isNaN(value))
      .required("Hourly rate is required!"),
    totalPayable: yup
      .string()
      .test("nan", "Please enter valid value", (value) => !isNaN(value))
      .required("Total payable is required!"),
    bonus: yup
      .string()
      .test("nan", "Please enter valid value", (value) => !isNaN(value)),
  });

  const formik = useFormik({
    initialValues: {
      hoursWorked: totalHoursWithinProximity,
      hourlyRate: teamMember.hourlyRate,
      totalPayment: totalPayment,
      alreadyPaid: paidAmount,
      alreadyPaidBonus: paidAmount,
      totalPayable: totalPayable,
      bonus: 0,
    },
    validationSchema,
    onSubmit: (values) => handlePayment(values),
  });

  useEffect(() => {
    const payload = {
      pageIndex: 0,
      memberId: teamMember.id,
      taskId: job.id,
      zeroStart: true,
    };
    dispatch(fetchTaskPaymentHistory(payload));
  }, [teamMember, job.id]);

  useEffect(() => {
    const alreadyPaid = taskPaymentHistory.reduce((total, payItem) => {
      total = total + parseNumber(payItem.amount);
      return total;
    }, 0.0);

    setPaidAmount(alreadyPaid);
    formik.setFieldValue("alreadyPaid", alreadyPaid);

    const alreadyPaidBonus = taskPaymentHistory.reduce((total, payItem) => {
      total = total + parseNumber(payItem.bonus);
      return total;
    }, 0.0);

    setPaidBonus(alreadyPaidBonus);
    formik.setFieldValue("alreadyPaidBonus", alreadyPaidBonus);

    const totalPayment = Math.round(
      parseNumber(totalHoursWithinProximity.toString()) *
        parseNumber(teamMember.hourlyRate.toString())
    );

    const { netBonusValue } = getBonusValues(
      target,
      teamMember.hourlyRate,
      totalHoursWithinProximity,
      alreadyPaidBonus,
      isTaskCompleted
    );

    // const netBonus = 0;
    const totalPayable = Math.round(
      parseNumber(totalPayment) +
        parseNumber(netBonusValue) -
        parseNumber(alreadyPaid) -
        parseNumber(deductionAmount)
    );

    setTotalPayment(totalPayment);
    setTotalPayable(totalPayable);
    setCurrentBonus(netBonusValue);
    formik.setFieldValue("bonus", parseInputFieldNumber(netBonusValue));
    formik.setFieldValue(
      "totalPayment",
      parseInputFieldNumber(totalPayment, "float")
    );
    formik.setFieldValue("totalPayable", parseInputFieldNumber(totalPayable));
  }, [taskPaymentHistory]);

  const handleHoursUpdate = (e) => {
    const hoursWorked = e.target.value;
    const totalPayment =
      parseNumber(hoursWorked) * parseNumber(formik.values.hourlyRate);

    const { netBonusValue } = getBonusValues(
      target,
      formik.values.hourlyRate,
      hoursWorked,
      paidBonus,
      isTaskCompleted
    );

    const totalPayable =
      parseNumber(totalPayment) +
      parseNumber(netBonusValue) -
      parseNumber(formik.values.alreadyPaid);

    formik.setFieldValue("hoursWorked", hoursWorked);
    formik.setFieldValue("bonus", parseInputFieldNumber(netBonusValue));
    formik.setFieldValue(
      "totalPayment",
      parseInputFieldNumber(totalPayment, "float")
    );
    formik.setFieldValue("totalPayable", parseInputFieldNumber(totalPayable));
  };

  const handleRateUpdate = (e) => {
    const hourlyRate = e.target.value;
    const totalPayment =
      parseNumber(hourlyRate) * parseNumber(formik.values.hoursWorked);

    const { netBonusValue } = getBonusValues(
      target,
      hourlyRate,
      formik.values.hoursWorked,
      paidBonus,
      isTaskCompleted
    );

    const totalPayable =
      parseNumber(totalPayment) +
      parseNumber(netBonusValue) -
      parseNumber(formik.values.alreadyPaid);

    setCurrentBonus(netBonusValue);
    formik.setFieldValue("hourlyRate", hourlyRate);
    formik.setFieldValue("bonus", parseInputFieldNumber(netBonusValue));
    formik.setFieldValue(
      "totalPayment",
      parseInputFieldNumber(totalPayment, "float")
    );
    formik.setFieldValue("totalPayable", parseInputFieldNumber(totalPayable));
  };

  const handleBonusChange = (e) => {
    let netBonusValue = e.target.value;

    netBonusValue = netBonusValue < 0 ? 0 : netBonusValue;

    const newTotalPayable =
      parseNumber(formik.values.totalPayment) +
      parseNumber(netBonusValue || 0) -
      parseNumber(formik.values.alreadyPaid);

    console.log("========handleBonusChange======", {
      netBonusValue,
      newTotalPayable,
    });

    formik.setFieldValue(
      "totalPayable",
      parseInputFieldNumber(newTotalPayable)
    );
    formik.setFieldValue("bonus", netBonusValue);
  };

  const [paymentLoading, setPaymentLoading] = useState(false);
  const handlePayment = async (values) => {
    const payingBonus = parseNumber(values.bonus);
    let payingAmount =
      parseNumber(values.totalPayable) - parseNumber(payingBonus);

    const payload = {
      staffId: teamMember.id,
      taskId: job.id,
      action: 1,
      amount: parseNumber(payingAmount, "round"),
      bonus: parseNumber(payingBonus, "round"),
    };

    console.log("====================================");
    console.log("payment payload: ", payload);
    console.log("====================================");

    // return;

    setPaymentLoading(true);
    await apiPaymentStaff
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          const newPaidAmount =
            parseNumber(paidAmount) + parseNumber(payingAmount);

          setPaidAmount(newPaidAmount);
          formik.setFieldValue(
            "alreadyPaid",
            parseInputFieldNumber(newPaidAmount)
          );

          const _paidBonus = parseNumber(paidBonus) + parseNumber(payingBonus);
          setPaidBonus(_paidBonus);
          formik.setFieldValue("alreadyPaidBonus", _paidBonus);

          const newNetBonus =
            parseNumber(formik.values.bonus) - parseNumber(payingBonus);
          formik.setFieldValue("bonus", parseInputFieldNumber(newNetBonus));

          setCurrentBonus(newNetBonus);

          const newTotalPayable =
            parseNumber(formik.values.totalPayment) +
            parseNumber(newNetBonus) -
            parseNumber(newPaidAmount);

          setTotalPayable(newTotalPayable);
          formik.setFieldValue(
            "totalPayable",
            parseInputFieldNumber(newTotalPayable)
          );

          const paymentHistoryTobeAdded = {
            id: v4(),
            creationDate: new Date().toISOString(),
            amount: parseNumber(payingAmount, "round"),
            bonus: parseNumber(values.bonus, "round"),
          };
          dispatch(
            setTaskPaymentHistory([
              paymentHistoryTobeAdded,
              ...taskPaymentHistory,
            ])
          );

          toast.success("Payment done successfully!!!");
        } else {
          toast.error("Error while making payment!!!");
        }
        setPaymentLoading(false);
      })
      .catch((error) => {
        console.log("Payment Error: ", error);
        toast.error("Error while making payment!!!");
        setPaymentLoading(false);
      });
  };

  const styles = useStyles({ tillTablet });
  return (
    <Dialog
      open={isPayUserDialog}
      className={styles.container_PayUser}
      classes={{
        paper: styles.paper,
      }}
    >
      {paymentLoading && <FullScreenLoaderCNO />}
      <HeadingBar title={`Pay ${teamMemberName}`} onClose={closePayUser} />
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.calculatePay}>
          <InputCNO
            label="Hours Worked"
            placeholder="Hours Worked..."
            name="hoursWorked"
            formik={formik}
            icon={mdiHours24}
            iconColor={theme.colors.JOB_PAYMENT_HOURS_WORKED_TINT_COLOR}
            onChange={handleHoursUpdate}
          />
          <Icon
            className={styles.multiply}
            path={mdiClose}
            size="1.5rem"
            color={theme.colors.JOB_PAYMENT_MULTIPLY_TINT_COLOR}
          />
          <InputCNO
            label="Hourly Rate"
            placeholder="Hourly Rate..."
            name="hourlyRate"
            formik={formik}
            icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            onChange={handleRateUpdate}
          />
        </div>
        <div className={styles.payDetails}>
          <InputCNO
            label="Total Payment"
            labelPosition="left"
            placeholder="Total Payment"
            name="totalPayment"
            formik={formik}
            icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            iconColor={theme.colors.JOB_PAYMENT_TOTAL_PAYMENT_TINT_COLOR}
            color={theme.colors.JOB_PAYMENT_TOTAL_PAYMENT_TINT_COLOR}
            disabled
            fullWidth={false}
            style={{ width: "43%" }}
          />
          <InputCNO
            renderLabel={() => {
              return (
                <div
                  onClick={() => setIsBonusCalculation(true)}
                  style={{ cursor: "pointer" }}
                >
                  <InputLabelCNO label={"Bonus Amount"} />
                  <div
                    className={GlobalStyles.link}
                    style={{ marginTop: 0, fontSize: "0.6rem" }}
                  >
                    Calculation Summary
                  </div>
                </div>
              );
            }}
            labelPosition="left"
            placeholder="Bonus Amount"
            name="bonus"
            formik={formik}
            icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            iconColor={theme.colors.JOB_PAYMENT_TOTAL_PAYMENT_TINT_COLOR}
            color={theme.colors.JOB_PAYMENT_TOTAL_PAYMENT_TINT_COLOR}
            fullWidth={false}
            onChange={handleBonusChange}
            style={{ width: "43%" }}
          />
          {false && (
            <InputCNO
              renderLabel={() => {
                return (
                  <div
                    onClick={() => setIsPaymentDialog(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <InputLabelCNO label={"Make Payment"} />
                    <div
                      className={GlobalStyles.link}
                      style={{ marginTop: 0, fontSize: "0.6rem" }}
                    >
                      Trigger Payment
                    </div>
                  </div>
                );
              }}
              labelPosition="left"
              placeholder="Bonus Amount"
              name="bonus"
              formik={formik}
              icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
              iconColor={theme.colors.JOB_PAYMENT_TOTAL_PAYMENT_TINT_COLOR}
              color={theme.colors.JOB_PAYMENT_TOTAL_PAYMENT_TINT_COLOR}
              fullWidth={false}
              onChange={handleBonusChange}
              style={{ width: "43%" }}
            />
          )}
          <InputCNO
            label="Already Paid"
            labelPosition="left"
            placeholder="Already Paid..."
            name="alreadyPaid"
            formik={formik}
            icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            iconColor={theme.colors.JOB_PAYMENT_ALREADY_PAID_TINT_COLOR}
            color={theme.colors.JOB_PAYMENT_ALREADY_PAID_TINT_COLOR}
            fullWidth={false}
            disabled
            style={{ width: "43%" }}
          />
          <InputCNO
            label="Total Payable"
            labelPosition="left"
            placeholder="Total Payable..."
            name="totalPayable"
            formik={formik}
            icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            fullWidth={false}
            iconColor={theme.colors.JOB_PAYMENT_TOTAL_PAYABLE_TINT_COLOR}
            color={theme.colors.JOB_PAYMENT_TOTAL_PAYABLE_TINT_COLOR}
            style={{ width: "43%" }}
          />
        </div>

        <div style={{ marginTop: theme.spacing(2) }}>
          <Accordion defaultExpanded className={GlobalStyles.accordion}>
            <AccordionSummary
              expandIcon={
                <Icon
                  path={mdiChevronDown}
                  size="1.2rem"
                  color={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR}
                />
              }
              className={GlobalStyles.accordionSummary}
            >
              Payment History
            </AccordionSummary>
            <AccordionDetails>
              <PaymentHistoryTable job={job} />
            </AccordionDetails>
          </Accordion>
        </div>

        <div className={styles.ctaWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<Icon path={mdiFileCancel} size="1.5rem" />}
            onClick={closePayUser}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<Icon path={mdiCalculatorVariant} size="1.5rem" />}
            disabled={
              !formik.dirty ||
              !formik.isValid ||
              paymentLoading ||
              formik.values.totalPayable == 0
            }
          >
            Pay
          </Button>
        </div>
      </form>

      {isBonusCalculation && (
        <BonusCalculationDetailsDialog
          open={isBonusCalculation}
          onClose={() => setIsBonusCalculation(false)}
          target={target}
          values={formik.values}
          isTaskCompleted={isTaskCompleted}
        />
      )}

      {isPaymentDialog && (
        <MakePaymentDialog
          open={isPaymentDialog}
          onClose={() => setIsPaymentDialog(false)}
          target={target}
          values={formik.values}
          isTaskCompleted={isTaskCompleted}
        />
      )}
    </Dialog>
  );
}

export default PayUser;

const useStyles = makeStyles((theme) => ({
  container_PayUser: {
    width: ({ tillTablet }) => (tillTablet ? "auto" : 500),
    margin: "0 auto",
  },
  paper: {
    background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    padding: theme.spacing(2),
    width: "70vw",
    maxWidth: "90vh",
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "2px solid white",
    paddingBottom: 5,
    "&>*:first-child": {
      marginRight: theme.spacing(2),
      fontSize: "0.9rem",
    },
    "&>*:last-child": {
      background: theme.colors.MODAL_CLOSE_ICON_BACKGROUND_COLOR,
      borderRadius: 20,
      padding: 2,
    },
  },
  calculatePay: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "&>*": {
      marginTop: theme.spacing(2),
      width: "45%",
    },
  },
  multiply: {
    marginTop: theme.spacing(6),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  payDetails: {
    marginTop: theme.spacing(3),
    "&>*": {
      marginTop: theme.spacing(2),
    },
  },
  ctaWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
    "&>*": {
      // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`,
      border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
      color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
      "&:first-child": {
        marginRight: theme.spacing(4),
        background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
        color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
      },
      "&:last-child": {
        background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
      },
    },
  },
  accordion: {
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
  },
  accordionSummary: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
    fontSize: "0.8rem",
    minHeight: 35,
    "& .MuiAccordionSummary-expandIcon": {
      padding: 0,
    },
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 35,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
  },
}));
