import {
  SET_CONVERT_TO_JOB_DATA,
  SET_HAS_MORE_QUOTATIONS,
  SET_QUOTATIONS,
  SET_QUOTATIONS_LOADER,
  SET_QUOTATION_BY_ID,
  SET_QUOTATION_SEARCH_PARAMS,
  SET_RESOLVED_QUOTATION_MEDIAS,
  TOGGLE_ADD_QUOTATION_DIALOG,
  TOGGLE_QUOTATION_FILTER_DIALOG,
  TOOGLE_PDF_DIALOG,
} from "../actions/quotationActions";

const initialState = {
  quotations: [],
  quotationById: null,
  resolvedQuotationMedias: [],
  isAddQuotationDialog: false,
  isQuotationFilterDialog: false,
  convertToJobData: null,
  allQuotationLoader: {
    quotationsLoader: false,
    moreQuotationLoader: false,
    editLoader: false,
    addLoader: false,
    deleteLoader: false,
    quotationDetailLoader: false,
    isPdfGenerating: false,
    uploadQuotationMediaLoader: false,
    getQuotationMediaLoader: false,
    deleteQuotationMediaLoader: false
  },
  hasMoreQuotations: true,
  quotationSearchParams: {
    customer: null,
    categories: [],
    quotation: null,
    offerNumber: "",
    sentOn: null,
    validForDays: null,
    statuses: [],
    isToday: false
  },
  isQuotationPdfDialog: false
};

export const quotationsReducer = (state = initialState, { type, payload }) => {

  switch (type) {
    case SET_QUOTATIONS:
      state = {
        ...state,
        quotations: payload
      };
      return state;

    case TOOGLE_PDF_DIALOG:
      state = {
        ...state,
        isQuotationPdfDialog: payload
      };
      return state;

    case TOGGLE_ADD_QUOTATION_DIALOG:
      state = {
        ...state,
        isAddQuotationDialog: payload
      };
      return state;

    case SET_QUOTATION_BY_ID:
      state = {
        ...state,
        quotationById: payload
      };
      return state;

    case SET_QUOTATIONS_LOADER:
      state = {
        ...state,
        allQuotationLoader: {
          ...state.allQuotationLoader,
          [payload.loaderType]: payload.loaderState
        }
      };
      return state;

    case SET_HAS_MORE_QUOTATIONS:
      state = {
        ...state,
        hasMoreQuotations: payload
      };
      return state;

    case SET_CONVERT_TO_JOB_DATA:
      state = {
        ...state,
        convertToJobData: payload
      };
      return state;

    case TOGGLE_QUOTATION_FILTER_DIALOG:
      state = {
        ...state,
        isQuotationFilterDialog: payload
      };
      return state;

    case SET_QUOTATION_SEARCH_PARAMS:
      state = {
        ...state,
        quotationSearchParams: payload
      };
      return state;

    case SET_RESOLVED_QUOTATION_MEDIAS:
      state = {
        ...state,
        resolvedQuotationMedias: payload
      };
      return state;

    default:
      return state;
  }

};