import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import { mdiHours24, mdiLockClock } from '@mdi/js';
import Icon from '@mdi/react';
import React, { Fragment, useEffect, useState } from 'react';
import ResponsiveLinesCNO from '../../library/ResponsiveLinesCNO/ResponsiveLinesCNO';
import MoreActionCNO from '../../library/MoreActionsCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { RateType } from '../../utils';
import ChipCNO from '../../library/ChipCNO';
import {
  Currency,
  CurrencyIcon,
  InvoiceStatus,
  RateTypeReverse
} from '../../utils/mappings';
import { getMaterialIconString } from '../../utils/icons';
import * as icons from '@mdi/js';
import { formatTimeWithTZ, getCategory, parseDisplayNumber, parseNumber } from '../../utils/Helpers';
import JobTypeIcon from '../../components/JobTypeIcon';
import RightsManager from '../../utils/RightsManager';
import {
  EditInvoiceItemCost,
  EditInvoiceItemDescription,
  EditInvoiceItemHour,
  EditInvoiceItemTaxRate,
  EditInvoiceItemTimeline,
  EditInvoiceItemTitle,
  InvoiceItemMoreActions
} from '.';
import Moment from 'react-moment';
import { calendarStrings, DATE_FORMAT } from '../../utils/formatDate';

function InvoiceItemDetailsListItem({ invoiceItem, invoice }) {

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const isSent = invoice.status === InvoiceStatus.Sent;
  const isSentAccepted = invoice.status === InvoiceStatus.SentAccepted;

  const invoiceCategory = getCategory(invoiceItem.categories[0]);

  const [expanded, setExpanded] = useState(false);

  const [isTitleEditable, setIsTitleEditable] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  const [isCostEditable, setIsCostEditable] = useState(false);
  const [isHourEditable, setisHourEditable] = useState(false);
  const [isTaxRateEditable, setIsTaxRateEditable] = useState(false);
  const [isTimelineEditable, setIsTimelineEditable] = useState(false);

  const [hours, setHours] = useState(invoiceItem.hours);
  const [rate, setRate] = useState(invoiceItem.rate);
  const [taxRate, setTaxRate] = useState(invoiceItem.taxRate);
  const [taxAmount, setTaxAmount] = useState(0);
  const [amount, setAmount] = useState(0);

  useEffect(() => {

    setHours(invoiceItem.hours);
    setRate(invoiceItem.rate);
    setTaxRate(invoiceItem.taxRate);

    const localAmount = invoiceItem.hours * invoiceItem.rate;

    const localTaxAmount = parseFloat((localAmount * invoiceItem.taxRate) / 100).toFixed(0);

    const localNetAmount = parseFloat(localAmount) + parseFloat(localTaxAmount);

    setTaxAmount(localTaxAmount);
    setAmount(localNetAmount);

  }, [invoiceItem]);

  return (
    <Fragment>
      <Accordion expanded={expanded} className={GlobalStyles.accordion} style={{ background: theme.colors.SUBTASK_CARD_BACKGROUND_COLOR, marginTop: 10 }}>
        <AccordionSummary
          className={GlobalStyles.accordionSummary}
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ width: '100%' }}
          >
            <div style={{ flex: 1, display: 'flex' }}>
              <JobTypeIcon
                iconLink={invoiceCategory?.iconLink}
                name={invoiceCategory?.title}
                categoryId={invoiceCategory.id}
              />
              <div style={{ flex: 1, marginLeft: 8 }}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>

                  <Typography
                    style={{ color: theme.colors.JOB_DETAIL_TITLE_COLOR, fontSize: '1rem' }}
                    onClick={() => setIsTitleEditable(true)}
                  >
                    {invoiceItem.title}
                  </Typography>

                  {
                    isUserAdmin &&
                    <MoreActionCNO>
                      <InvoiceItemMoreActions invoiceItem={invoiceItem} />
                    </MoreActionCNO>
                  }

                </div>

                <ResponsiveLinesCNO
                  text={invoiceItem.description || 'Invoice Item details not added'}
                  maxLine={!expanded ? 2 : 1000}
                  style={{ flex: 1, color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR }}
                  onClick={() => setIsDescription(true)}
                />

              </div>

            </div>

            <div className={styles.row} style={{ marginTop: 10 }}>
              {
                !expanded
                  ? (
                    <Typography onClick={() => setIsCostEditable(true)} style={{ display: 'flex', alignItems: 'center', color: theme.colors.LIST_CARD_USERNAMES_COLOR }}>
                      <Icon
                        path={icons[getMaterialIconString(CurrencyIcon[invoice.currency])]}
                        size="1rem"
                        color={theme.colors.JOB_DETAIL_DESCRIPTION_COLOR}
                      />
                      {parseDisplayNumber(invoiceItem.amount)}
                    </Typography>
                  ) : null
              }
              <pre
                style={{ color: theme.colors.LINK_COLOR, textDecoration: 'underline', width: '100%', textAlign: 'right' }}
                onClick={() => expanded ? setExpanded(null) : setExpanded('1')}
              >
                {expanded ? 'Less Details' : 'More Details'}
              </pre>
            </div>

          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={GlobalStyles.invoiceItemMoreWrapper} style={{ width: '100%' }}>

            {
              invoiceItem.rateType === RateType.Hourly &&
              <Fragment>
                <div
                  className={styles.row}
                  onClick={() => setIsTimelineEditable(true)}
                  style={{ paddingTop: 8, paddingBottom: 8 }}
                >
                  <Typography style={{ color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR, minWidth: 100, alignSelf: 'flex-start' }}>
                    Timeline
                  </Typography>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <div className={GlobalStyles.detailsRow}>
                      <Typography style={{ color: theme.colors.JOB_START_DATE_COLOR }}>
                        <Moment format={DATE_FORMAT} >
                          {formatTimeWithTZ(invoiceItem.startDate)}
                        </Moment>
                      </Typography>
                      <Icon
                        path={icons.mdiClockStart}
                        size='1.2rem'
                        color={theme.colors.JOB_START_DATE_COLOR}
                      />
                    </div>
                    {
                      isUserAdmin &&
                      <Fragment>
                        <span>-</span>
                        <div className={GlobalStyles.detailsRow}>
                          <Typography style={{ color: theme.colors.JOB_END_DATE_COLOR }}>
                            <Moment format={DATE_FORMAT} >
                              {formatTimeWithTZ(invoiceItem.endDate)}
                            </Moment>
                          </Typography>
                          <Icon
                            path={icons.mdiClockEnd}
                            size='1.2rem'
                            color={theme.colors.JOB_END_DATE_COLOR}
                          />
                        </div>
                      </Fragment>
                    }
                  </div>
                </div>

                <Divider />

              </Fragment>
            }
            <div
              className={styles.row}
              onClick={() => setIsCostEditable(true)}
            >
              <Typography style={{ color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR, minWidth: 100 }}>
                Cost
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                <Typography style={{ display: 'flex', alignItems: 'center', color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR }}>
                  <Icon
                    path={icons[getMaterialIconString(CurrencyIcon[invoice.currency])]}
                    size="1rem"
                    color={theme.colors.JOB_DETAIL_DESCRIPTION_COLOR}
                  />
                  {invoiceItem.rateType === RateType.Fixed ? parseDisplayNumber(rate) : `${parseDisplayNumber(rate)} per hour`}
                </Typography>
                {
                  invoiceItem.rateType ?
                    <ChipCNO
                      icon={invoiceItem.rateType === RateType.Hourly ? mdiHours24 : mdiLockClock}
                      name={RateTypeReverse[invoiceItem.rateType]}
                      active={true}
                    /> : null
                }
              </div>
            </div>

            <Divider />

            {/* <Fragment>
              <div
                className={styles.row}
                onClick={() => setIsTaxRateEditable(true)}
              >
                <Typography style={{ color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR, minWidth: 100 }}>
                  Tax
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                  <Typography style={{ display: 'flex', alignItems: 'center', color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR }}>
                    <Icon
                      path={icons[getMaterialIconString(CurrencyIcon[invoice.currency])]}
                      size="1rem"
                      color={theme.colors.JOB_DETAIL_DESCRIPTION_COLOR}
                    />
                    {taxAmount} @ {taxRate}%
                  </Typography>
                </div>
              </div>
              <Divider />
            </Fragment> */}

            <Fragment>
              <div className={styles.row}>
                <Typography style={{ color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR, minWidth: 100 }}>
                  Amount
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                  <Typography style={{ display: 'flex', alignItems: 'center', color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR }}>
                    <Icon
                      path={icons[getMaterialIconString(CurrencyIcon[invoice.currency])]}
                      size="1rem"
                      color={theme.colors.JOB_DETAIL_DESCRIPTION_COLOR}
                    />
                    {parseDisplayNumber(invoiceItem.amount)}
                  </Typography>
                </div>
              </div>
            </Fragment>

          </div>
        </AccordionDetails>
      </Accordion>

      {
        isTitleEditable &&
        isUserAdmin &&
        <EditInvoiceItemTitle
          open={isTitleEditable}
          onClose={() => setIsTitleEditable(false)}
          invoiceItem={invoiceItem}
        />
      }

      {
        isDescription &&
        isUserAdmin &&
        <EditInvoiceItemDescription
          open={isDescription}
          onClose={() => setIsDescription(false)}
          invoiceItem={invoiceItem}
        />
      }

      {
        isTimelineEditable &&
        isUserAdmin &&
        <EditInvoiceItemTimeline
          open={isTimelineEditable}
          onClose={() => setIsTimelineEditable(false)}
          invoiceItem={invoiceItem}
        />
      }

      {
        isCostEditable &&
        isUserAdmin &&
        <EditInvoiceItemCost
          open={isCostEditable}
          onClose={() => setIsCostEditable(false)}
          invoiceItem={invoiceItem}
          invoice={invoice}
        />
      }

      {
        isHourEditable &&
        isUserAdmin &&
        <EditInvoiceItemHour
          open={isHourEditable}
          onClose={() => setisHourEditable(false)}
          invoiceItem={invoiceItem}
        />
      }

      {
        isTaxRateEditable &&
        isUserAdmin &&
        <EditInvoiceItemTaxRate
          open={isTaxRateEditable}
          onClose={() => setIsTaxRateEditable(false)}
          invoiceItem={invoiceItem}
        />
      }

    </Fragment>

  )
}

export default InvoiceItemDetailsListItem;

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 4,
    paddingBottom: 4
  }
}))
