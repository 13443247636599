import React, { useEffect } from 'react';
import { useStyles } from './CompanyDetailsStyles';
import CompanyHeaderDetailsCard from '../../components/CompanyHeaderDetailsCard';
import CompanyPersonalDetailsCard from '../../components/CompanyPersonalDetailsCard';
import CompanyContactDetailsCard from '../../components/CompanyContactDetailsCard';
import CompanySocialDetailsCard from '../../components/CompanySocialDetailsCard';
import CompanyCategoryDetailsCard from '../../components/CompanyCategoryDetailsCard';
import CompanySubscriptionDetailsCard from '../../components/CompanySubscriptionDetailsCard';
import CompanyPaymentDetailsCard from '../CompanyPaymentDetailsCard';
import CurrentSubscriptionDetailsCard from '../CurrentSubscriptionDetails/CurrentSubscriptionDetailsCard';
import { useDispatch, useSelector } from 'react-redux';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { setCompanyById } from '../../actions/customerCompanyActions';
import QuotationEmailTemplateExpandable from './QuotationEmailTemplateExpandable';
import InvoiceEmailTemplateExpandable from './InvoiceEmailTemplateExpandable';
import AppointmentEmailTemplateExpandable from './AppointmentEmailTemplateExpandable';
import EmailSignatureTemplateExpandable from './EmailSignatureTemplateExpandable';
import EmailTermsAndConditionsExpandable from './EmailTermsAndConditionsExpandable';
import { useScrollTo } from '../../hooks';

function CompanyDetails({
  profile,
}) {

  const styles = useStyles();

  const isActive = profile.isActive;

  const dispatch = useDispatch();
  const { allCompaniesLoader } = useSelector(state => state.companies);
  const { editLoader } = allCompaniesLoader;

  const { hasSubscriptionExpired } = useSelector(state => state.subscription);

  useEffect(() => {
    return () => {
      dispatch(setCompanyById(null));
    };
  }, []);

  useScrollTo();

  return (
    <div className={styles.container_CompanyDetails}>

      {
        editLoader &&
        <FullScreenLoaderCNO />
      }

      <CompanyHeaderDetailsCard profile={profile} isActive={isActive} />


      <CompanyContactDetailsCard profile={profile} isActive={isActive && !hasSubscriptionExpired} />

      {/* <CompanySocialDetailsCard profile={profile} isActive={isActive} /> */}

      <CompanyCategoryDetailsCard profile={profile} isActive={isActive && !hasSubscriptionExpired} />

      <InvoiceEmailTemplateExpandable profile={profile} isActive={isActive && !hasSubscriptionExpired} />
      <QuotationEmailTemplateExpandable profile={profile} isActive={isActive && !hasSubscriptionExpired} />
      <AppointmentEmailTemplateExpandable profile={profile} isActive={isActive && !hasSubscriptionExpired} />
      <EmailSignatureTemplateExpandable profile={profile} isActive={isActive && !hasSubscriptionExpired} />
      <EmailTermsAndConditionsExpandable profile={profile} isActive={isActive && !hasSubscriptionExpired} />

      <CompanyPersonalDetailsCard profile={profile} isActive={isActive && !hasSubscriptionExpired} />

      <CompanySubscriptionDetailsCard profile={profile} isActive={isActive} />

      {/*<CompanyPaymentDetailsCard profile={profile} isActive={isActive} />*/}
      <CurrentSubscriptionDetailsCard profile={profile} isActive={isActive} />

    </div>
  )
}

export default CompanyDetails
