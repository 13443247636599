export const capitalize = (word) => {
  return word ? word[0].toUpperCase() + word.slice(1) : "";
};

export const getMaterialIconString = (iconName = "pipe-leak") => {

  const removedHyphenWords = iconName.split('-');

  const capitalizedWords = removedHyphenWords.map((word) => capitalize(word));

  const suffixString = capitalizedWords.join('');

  const materialIconString = `mdi${suffixString}`;

  return materialIconString;

};