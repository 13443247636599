import moment from "moment";
import * as momenttz from "moment-timezone";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import {
  CurrencyCode,
  EventResponseActionStatus,
  FileType,
  Gender,
  InvoiceDueDateAfter,
  MimeType,
  MimeTypeReverse,
  Salutation,
  SalutationReverse,
} from "./mappings";
import mime from "mime-types";
import PdfIcon from "../assets/images/media-icons/icon-pdf.png";
import ExcelIcon from "../assets/images/media-icons/icon-excel.png";
import DocIcon from "../assets/images/media-icons/icon-msword.png";
import PptIcon from "../assets/images/media-icons/icon-ppt.png";
import TextIcon from "../assets/images/media-icons/icon-text.png";
import { getThumbnails } from "video-metadata-thumbnails";
import { combineDateAndTime } from "./Tracking";
import RightsManager from "./RightsManager";
import LocalStorageConstants from "./LocalStorageConstants";
import Resizer from "react-image-file-resizer";
import { getTotalAmountValues } from "./InvoiceHelpers";
import { MainPostType } from "../pages/PostsManagement/enums";
import { useMediaQuery } from "@material-ui/core";
import { v4 } from "uuid";
import { ServiceCategories } from "./mappings";

export const getTillTablet = () => {
  const tillTablet = useMediaQuery("(max-width: 767px)");
  return tillTablet;
};
export const getUserCountry = () => {
  let country = localStorage.getItem("country");
  country = country
    ? JSON.parse(country)
    : { country: "United Kingdom", countryCode: "GB" };

  return country;
};

export const getCategory = (id) => {
  let categories = localStorage.getItem("categories");
  categories = categories ? JSON.parse(categories) : [];

  let category = categories.find((catg) => catg.id === id);
 
  if (category) {
    return category;
  } else {
    let serviceCategory = ServiceCategories.entities.find((catg) => catg.id === id);
    console.log("serviceCategory",serviceCategory)
    if(serviceCategory){
      return serviceCategory;
    }else{
      return { iconLink: "bullseye", title: "General" };
    }
  }
  
};

export const getTestCategory = (id) => {
  let testCategory = localStorage.getItem("testCategory");
 
  testCategory = testCategory ? JSON.parse(testCategory) : { categories: [] };

  let categories = testCategory.categories;

  if (!Array.isArray(categories)) {
    return { iconLink: "bullseye", title: "General" };
  }

  let category = categories.find((catg) => catg.categoryId === id);

  if (category) {
    return category;
  } else {
    return { iconLink: "bullseye", title: "General" };
  }
};

export const getLatLngFromAddress = async (address) => {
  let latitude = 0;
  let longitude = 0;

  if (window.google && window.google.maps) {
    await geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        latitude = lat;
        longitude = lng;
      });
  }

  return { latitude, longitude };
};

export const getAssignUsersString = (assignments, isFullString = true) => {
  const assignUsers = assignments
    .map((user) => user.staffText)
    .filter((user) => (user === null ? false : true));

  let resultString = "";

  if (isFullString) {
    resultString = assignUsers.join(", ");
  } else {
    resultString = `${assignUsers[0]} ${
      assignUsers.length > 1 ? `+${assignUsers.length - 1}` : ""
    }`;
  }

  return resultString;
};

export const isUserInAssignemts = (assignments, user) => {
  const findUser = assignments.find(
    (assignment) => assignment.staffId === user.id
  );

  return findUser ? true : false;
};

export const getDueDate = (dueDateType) => {
  switch (dueDateType) {
    case InvoiceDueDateAfter.OnReceipt:
      break;

    case InvoiceDueDateAfter.In15days:
      break;

    case InvoiceDueDateAfter.DueEndOfMonth:
      break;

    case InvoiceDueDateAfter.ChooseADate:
      break;

    default:
      break;
  }
};

export const getCurrentUser = () => {
  let authUser = localStorage.getItem("authUser");

  authUser = authUser ? JSON.parse(authUser) : null;

  return authUser;
};

export const getHoursFromTimeline = (startDate, endDate) => {
  const noOfDays = moment(endDate).diff(moment(startDate), "days") + 1;

  console.log(
    "---------getHoursFromTimeline moment days ",
    moment(endDate).format(),
    moment(startDate).format()
  );
  console.log("---------getHoursFromTimeline ", {
    startDate,
    endDate,
    noOfDays,
  });

  const hours = noOfDays * 8;

  return hours;
};

export const parseNumber = (
  number,
  parseMethod,
  defaultValue = 0,
  precision = 2,
  isDisplay = false
) => {
  let parsedNumber = isNaN(number) ? defaultValue : Number(number);

  if (isNaN(Number(number))) {
    return parsedNumber;
  }

  switch (parseMethod) {
    case "int":
      parsedNumber = parseInt(parsedNumber);
      break;

    case "float":
      parsedNumber = parseFloat(parsedNumber).toFixed(precision);
      break;

    case "round":
      parsedNumber = Math.round(parsedNumber);
      break;

    default:
      break;
  }

  return isDisplay ? parsedNumber : Number(parsedNumber);
};

export const parseDisplayNumber = (
  value,
  parseMethod = "float",
  precision = 2
) => {
  let parsedNumber = value;

  if (value === "" || isNaN(value)) {
    parsedNumber = 0;
  } else {
    parsedNumber = parseNumber(value, parseMethod, "", precision, true);
  }

  if (parseMethod === "float") {
    parsedNumber = addTrailingZeros(parsedNumber);
  }

  return parsedNumber;
};

export const addTrailingZeros = (number) => {
  const totalLength = number.length;
  return String(number).padEnd(totalLength, "0");
};

export const parseInputFieldNumber = (
  value,
  parseMethod = "float",
  precision = 2
) => {
  let parsedNumber = value;

  if (value === "" || isNaN(value)) {
    parsedNumber = "";
    return parsedNumber;
  }

  return parseNumber(value, parseMethod, 0, precision);
};

export const getFileDataAsync = async (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(new Error("Error in reading file."));
  });
};

export const getBase64Data = (b64Data) => {
  // Split the base64 string in data and contentType
  const block = b64Data.split(";");
  // Get the content type of the image
  let contentType = block[0].split(":")[1];
  contentType = contentType || "";

  console.log("b64toBlob: block: contentType: ", block, contentType);

  // get the real base64 content of the file
  const realData = block[1].split(",")[1];

  return realData;
};

// converts base64 image to blob
export function b64toBlob(
  b64Data,
  defaultContentType = "image/jpg",
  sliceSize = 512
) {
  console.log("splitIssue", b64Data);
  const data = b64Data.split(":")[1];

  if (!data) {
    var blob = new Blob([], { type: defaultContentType });
    return blob;
  }

  // Split the base64 string in data and contentType
  const block = b64Data.split(";");
  // Get the content type of the image
  let contentType = block[0].split(":")[1];
  contentType = contentType || "";

  console.log("b64toBlob: block: contentType: ", block, contentType);

  // get the real base64 content of the file
  const realData = block[1].split(",")[1];

  console.log("b64toBlob: realData: ", realData);

  var byteCharacters = atob(realData);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    console.log("b64toBlob: slice: ", slice, typeof slice);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const getBase64FromURL = async (url) => {
  const fileContent = await fetch(url);
  const blob = await fileContent.blob();

  return blobToBase64(blob);
};

export const getMediaData = async (media, isConvert = false) => {
  const fileType = getFileTypeFromMimeType(media.type);

  let mediaData;
  let mediaBlob;
  if (isConvert) {
    mediaData = await getBase64FromURL(media.link);
  } else {
    mediaData = await getFileDataAsync(media);
  }

  mediaBlob = b64toBlob(mediaData, media.type);

  const { thumbnailBlob, thumbnailBase64 } = await getThumbnailBlob(
    fileType,
    mediaData,
    mediaBlob
  );

  return {
    mediaData,
    mediaBlob,
    thumbnailBlob,
    thumbnailBase64,
    fileType,
  };
};

export const getMediaMimeType = (fileType) => {
  return mime.lookup(fileType);
};

export const getMimeTypeFromFileType = (fileType) => MimeType[fileType];

export const getFileTypeFromMimeType = (mimeType) => {
  if (mimeType.includes("image")) {
    mimeType = "image/*";
  }

  if (mimeType.includes("video")) {
    mimeType = "video/*";
  }

  return MimeTypeReverse[mimeType];
};

export const getFileViewerFileType = (fileType) => {
  let fileViewerFileType = null;

  switch (fileType) {
    case FileType.Image:
      fileViewerFileType = "png";
      break;

    case FileType.Doc:
      fileViewerFileType = "docx";
      break;

    case FileType.Pdf:
      fileViewerFileType = "pdf";
      break;

    case FileType.Video:
      fileViewerFileType = "mp4";
      break;

    default:
      break;
  }

  return fileViewerFileType;
};

export const generateRandomColor = () => {
  var letters = "0123456789ABCDEF";
  // html color code starts with #
  var color = "#";
  // generating 6 times as HTML color code consist
  // of 6 letter or digits
  for (var i = 0; i < 6; i++) color += letters[Math.floor(Math.random() * 16)];
  return color;
};

export const getThumbnailBlob = async (fileType, fileBase64, fileBlob) => {
  console.log("===getThumbnailBlob===fileType", fileType);

  let thumbnail = {
    blob: fileBlob,
    base64: fileBase64,
  };

  switch (fileType) {
    case FileType.Pdf:
      thumbnail = generatePdfThumbnail(fileBase64);
      break;

    case FileType.Doc:
    case FileType.Docx:
      thumbnail = generateDocThumbnail(fileBase64);
      break;

    case FileType.Video:
      thumbnail = await generateVideoThumbnail(fileBase64);
      break;

    case FileType.Xls:
    case FileType.Xlsx:
      thumbnail = generateExcelThumbnail(fileBase64);
      break;

    case FileType.Ppt:
    case FileType.Pptx:
      thumbnail = generatePptThumbnail(fileBase64);
      break;

    case FileType.Text:
      thumbnail = generateTextThumbnail(fileBase64);
      break;

    case FileType.Image:
      thumbnail = await generateImageThumbnail(fileBase64, fileBlob);
      break;

    default:
      break;
  }

  return {
    thumbnailBlob: thumbnail.blob,
    thumbnailBase64: thumbnail.base64,
  };
};

export const generatePdfThumbnail = (pdfBase64) => {
  console.log("=====generate thumbnail ==== PdfIcon ====", PdfIcon);

  const blob = b64toBlob(PdfIcon);

  console.log("=====generate thumbnail ==== blob ====", blob);

  return { blob, base64: PdfIcon };
};

export const generateExcelThumbnail = (excelBase64) => {
  console.log("=====generate thumbnail ==== ExcelIcon ====", ExcelIcon);

  const blob = b64toBlob(ExcelIcon);

  return { blob, base64: ExcelIcon };
};

export const generatePptThumbnail = (pptBase64) => {
  console.log("=====generate thumbnail ==== PptIcon ====", PptIcon);

  const blob = b64toBlob(PptIcon);

  return { blob, base64: PptIcon };
};

export const generateTextThumbnail = (textBase64) => {
  console.log("=====generate thumbnail ==== TextIcon ====", TextIcon);

  const blob = b64toBlob(TextIcon);

  return { blob, base64: TextIcon };
};

export const generateDocThumbnail = (docBase64) => {
  console.log("=====generate thumbnail ==== DocIcon ====", DocIcon);

  const blob = b64toBlob(DocIcon);

  return { blob, base64: DocIcon };
};

export const generateVideoThumbnail = async (videoBase64) => {
  console.log("=====generate thumbnail ==== Video ====", videoBase64);

  const videoBlob = b64toBlob(videoBase64);

  console.log("=====generate thumbnail ==== videoBlob ====", videoBlob);

  const videoThumbnail = await getThumbnails(videoBlob, {
    quality: 0.6,
  });

  console.log(
    "=====generate thumbnail ==== videoThumbnail ====",
    videoThumbnail
  );

  const blob = videoThumbnail[0].blob;

  return { blob, base64: videoBase64 };
};

export const generateThumbnailImage = async (videoBase64) => {
  const videoBlob = b64toBlob(videoBase64);
  const videoThumbnail = await getThumbnails(videoBlob, {
    quality: 0.6,
  });

  const thumbnailBlob = videoThumbnail[0].blob;

  // Convert the thumbnail Blob to a base64 data URL
  const base64Thumbnail = await blobToBase64(thumbnailBlob);

  return { blob: thumbnailBlob, base64: base64Thumbnail };
};

export const resizeFile = (
  file,
  maxWidth = 300,
  maxHeight = 300,
  compressFormat = "JPEG",
  quality = 50,
  rotation = 0,
  // responseUriFunc,
  outputType = "base64"
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      rotation,
      (uri) => {
        resolve(uri);
      },
      outputType
    );
  });

export const generateImageThumbnail = async (base64, blob) => {
  let thumbnail = base64;
  try {
    thumbnail = await resizeFile(blob);
  } catch (err) {
    thumbnail = base64;
    console.error("==========generateImageThumbnail===err====", err);
  }

  return {
    base64: thumbnail,
    blob: b64toBlob(thumbnail),
  };
};

export const getGenderBasedOnSalutation = (salutationType) => {
  let genderType = Gender.None;

  switch (salutationType) {
    case Salutation.Dr:
      genderType = Gender.None;
      break;

    case Salutation.Mr:
    case Salutation.Sir:
      genderType = Gender.Male;
      break;

    case Salutation.Mrs:
    case Salutation.Ms:
      genderType = Gender.Female;
      break;

    default:
      genderType = Gender.None;
      break;
  }

  return genderType;
};

export const validateStartDateEndDate = ({
  startDate,
  startTime,
  endDate,
  endTime,
}) => {
  const combinedStartDateTime = combineDateAndTime(startDate, startTime);
  const combinedEndDateTime = combineDateAndTime(endDate, endTime);

  return combinedEndDateTime >= combinedStartDateTime;
};

export const getPrefillAssignedUser = () => {
  const isUserAdmin = RightsManager.isAdmin();
  const isUserCustomer = RightsManager.isCustomer();
  const isUserMember = RightsManager.isMember();

  const authUser = LocalStorageConstants.getItem("authUser");

  let assignments = null;

  if (isUserAdmin || isUserMember) {
    assignments = {
      id: authUser.id,
      title: authUser.firstName + " " + authUser.lastName,
    };
  }

  return assignments;
};

export const getPrefillCustomer = () => {
  const isUserCustomer = RightsManager.isCustomer();

  const authUser = LocalStorageConstants.getItem("authUser");

  let customer = null;

  if (isUserCustomer) {
    customer = {
      id: authUser.id,
      title: authUser.firstName + " " + authUser.lastName,
    };
  }

  return customer;
};

export const handleBackButton = (history) => {
  const pathname = history.location.pathname;

  if (pathname.includes("/job/")) history.goBack();
  // if (pathname.includes('/job/')) history.push('/');
  else if (pathname.includes("/map")) history.push("/");
  else if (pathname.includes("/calendar")) history.push("/");
  else if (pathname.includes("/chat")) history.push("/");
  else if (pathname.includes("/profile/")) history.push("/");
  else if (pathname.includes("/mycompany/")) history.push("/");
  else if (pathname.includes("/employees")) history.push("/");
  // else if (pathname.includes('/employee/')) history.push('/employees');
  else if (pathname.includes("/customers")) history.push("/");
  // else if (pathname.includes('/customer/')) history.push('/customers');
  else if (pathname.includes("/quotations")) history.push("/");
  // else if (pathname.includes('/quotation/')) history.push('/quotations');
  else if (pathname.includes("/invoices")) history.push("/");
  // else if (pathname.includes('/invoice/')) history.push('/invoices');
  else if (pathname.includes("/appointments")) history.push("/");
  // else if (pathname.includes('/appointment/')) history.push('/appointments');
  else if (pathname.includes("/settings")) history.push("/");
  else history.goBack();
};

export const sortArrayBasedOtherArray = (updatedArray, arrayToBeUpdated) => {
  let sortedItems = arrayToBeUpdated;

  sortedItems = arrayToBeUpdated
    .map((item) => {
      const updatedItem = updatedArray.find((i) => i.id === item.id);

      // map the updated order to original array
      return updatedItem ? { ...item, order: updatedItem.order } : item;
    })
    .sort((first, second) => (first.order < second.order ? -1 : 1)) // sort the array
    .filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id)
    ); //to remove duplicate in any case

  return sortedItems;
};

export const isFormikEmpty = (initialValues, currentValues) => {
  let isEmpty = true;

  const keysInitial = Object.keys(initialValues);

  const hasDifference = keysInitial.some(
    (key) =>
      JSON.stringify(initialValues[key]) !== JSON.stringify(currentValues[key])
  );

  isEmpty = !hasDifference;

  return isEmpty;
};

export const getYoutubeEmbedUrl = (url) => {
  const [_, id] = url.split("?v=");

  return `https://www.youtube.com/embed/${id}`;
};

export const getValidFileName = (filename) => {
  return filename.replace(/[/\\?%*:|"<>]/g, "-");
};

export const formatTimeWithTZ = (timestamp) => {
  // const timezone = LocalStorageConstants.getItem("timezone");
  const timezone = "Europe/London";

  return momenttz.tz(timestamp, timezone).format("L LT");
};

export const getWidgetDefaultLayout = (index, widget) => {
  const row = index % 3;
  const height = 2;

  const layout = {
    x: 3 * row + 1,
    y: Math.floor(index / 3) * height,
    w: 3,
    h: widget.showChart ? 2.4 : 1.4,
    minW: 3,
    minH: widget.showChart ? 2.4 : 1.4,
  };

  console.log("=======getWidgetDefaultLayout====layout=====", {
    index,
    widget,
    layout,
  });

  return layout;
};

export const getContentType = async (url) => {
  console.log("=======getContentType====url=====", url);

  let contentType = null;
  let fileType = null;

  let response = null;
  response = await fetch(url);

  if (response && response.status === 200) {
    console.log("=======getContentType====response=====", response);

    contentType = response.headers.get("Content-Type");
    fileType = getFileTypeFromMimeType(contentType);

    console.log("=======getContentType====response=====", {
      fileType,
      contentType,
    });
  } else {
    throw new Error("Content not found.");
  }

  return { fileType, contentType };
};

export const getTitleFromCustomer = (customer, defaultValue = "") => {
  let title = defaultValue;

  return title; //remove this line when releasing fill title on customer selection

  if (!customer) return title;

  const salutationText = customer.salutation
    ? SalutationReverse[customer.salutation]
    : "";
  const customerName = customer.title ? customer.title : "";
  title = `${salutationText}${salutationText ? " " : ""}${customerName}`;

  return title;
};

export const isTrialPeriod = () => {
  const authUserCompany = LocalStorageConstants.getItem("authUserCompany");
  const authUserId = authUserCompany ? authUserCompany.id : null;

  if (!authUserId) return null;

  const trialEndDate = LocalStorageConstants.getItem(
    `trialEndDate-${authUserCompany.id}`
  );

  console.log("====fetchPaymentDue====trialEndDate=", trialEndDate);

  const isTrialPeriodActive = trialEndDate
    ? moment(trialEndDate).diff(moment(), "day") >= 0
    : null;

  console.log(
    "====fetchPaymentDue====trialEndDate=== diff",
    moment(trialEndDate).diff(moment(), "day")
  );
  console.log(
    "====fetchPaymentDue====isTrialPeriodActive=",
    isTrialPeriodActive
  );

  return isTrialPeriodActive;
};
export const formatNumber = (number, options = {}) => {
  const formatter = Intl.NumberFormat("en", { ...options });

  return formatter.format(number);
};

export const formatCurrency = (amount, options = { notation: "compact" }) => {
  const currencyType = LocalStorageConstants.getItem("currencyType");
  const currencyCode = CurrencyCode[currencyType] || "GBP";

  return formatNumber(amount, {
    style: "currency",
    currency: currencyCode,
    ...options,
  });
};

export const getTodayInvoices = (invoices = [], isToday) => {
  if (!isToday) return invoices;

  const todayInvoices = invoices.filter((invoice) => {
    const billDate = invoice.billDate;
    const dueInDays = invoice.dueInDays;

    const dueDate = moment(billDate).add(dueInDays, "days").format("L");
    const todayDate = moment().format("L");

    return dueDate === todayDate;
  });

  return todayInvoices;
};

export const getTotalInvoicesAmount = (invoices = []) => {
  let total = 0;

  total = invoices.reduce((total, invoice) => {
    const { totalAmount } = getTotalAmountValues(
      invoice.rate,
      invoice.discount,
      invoice.taxRate,
      invoice.includeTax
    );

    total = total + parseNumber(totalAmount, "float");

    return total;
  }, 0);

  return total;
};

export const getTodayQuotations = (quotations = [], isToday) => {
  if (!isToday) return quotations;

  const todayQuotations = quotations.filter((quotation) => {
    const sentOnData = quotation.offerDate;
    const validTillDays = quotation.dueInDays;

    const expireDate = moment(sentOnData)
      .add(validTillDays, "days")
      .format("L");
    const todayDate = moment().format("L");

    return expireDate === todayDate;
  });

  return todayQuotations;
};

export const getHiddenWidgetsLocal = () => {
  const authUserCompany = LocalStorageConstants.getItem("authUserCompany");
  const key = `hiddenWidgets_${authUserCompany.id}`;

  const hiddenWidgetsLocal = LocalStorageConstants.getItem(key, []);
  return hiddenWidgetsLocal;
};

export const setHiddenWidgetsToLocal = (currentWidgetId) => {
  const authUserCompany = LocalStorageConstants.getItem("authUserCompany");
  const key = `hiddenWidgets_${authUserCompany.id}`;

  const hiddenWidgetsLocal = getHiddenWidgetsLocal();

  let updatedHiddenWidgets = hiddenWidgetsLocal;

  if (hiddenWidgetsLocal.includes(currentWidgetId)) {
    updatedHiddenWidgets = hiddenWidgetsLocal.filter(
      (widgetId) => widgetId !== currentWidgetId
    );
  } else {
    updatedHiddenWidgets = [...hiddenWidgetsLocal, currentWidgetId];
  }

  LocalStorageConstants.setItem(key, updatedHiddenWidgets);
};

export const isArrayEmpty = (array) => !(Array.isArray(array) && array.length);

export const isObject = (obj) => {
  if (typeof obj === "object" && !Array.isArray(obj) && obj !== null) {
    return true;
  }

  return false;
};

export const isObjectEmpty = (obj) => {
  obj = JSON.parse(JSON.stringify(obj));

  for (let key in obj) return !!!key;
  return true;
};
export const formatDate = (isoString) => {
  const date = new Date(isoString);
  const options = { year: "numeric", month: "long", day: "numeric" };

  return date.toLocaleDateString(undefined, options);
};
export function getItemFromArray(value, key = "id") {
  return this.entities.find((entity) => entity[key] === value);
}

export function getItemByTitleArray(value, key = "title") {
  return this.entities.find((entity) => entity[key] === value);
}
export const getPersonName = (person) => {
  if (!person) return "";

  if (person.title) return person.title;

  if (person.firstName || person.lastName)
    return `${person.firstName || ""} ${person.lastName || ""}`;
};

export function getNestedObjectValue(obj, accessorKey, defaultValue = null) {
  const keys = accessorKey.split(".");
  let currentObj = obj;

  for (const key of keys) {
    if (currentObj.hasOwnProperty(key)) {
      currentObj = currentObj[key];
    } else {
      return defaultValue; // Key doesn't exist, return undefined
    }
  }

  return currentObj;
}

export function capitalizeAndBreak(inputString) {
  // Capitalize the first letter of the input string
  const capitalizedString =
    inputString.charAt(0).toUpperCase() + inputString.slice(1);

  // Use regular expression to split the string at capital letters, underscores, and hyphens
  const parts = capitalizedString.split(/(?=[A-Z])|_|-/);

  // Join the parts with spaces to create the final string
  const resultString = parts.join(" ");

  return resultString;
}

export function nFormatter(num, digits = 1) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export const transformListData = (item) => {
  switch (item.postType) {
    case MainPostType.ids.Post: {
      return item;
    }

    case MainPostType.ids.Job: {
      const amount = parseNumber(item.rate, "float");
      const totalAmount = amount;

      return {
        ...item,
        amount,
        totalAmount,
      };
    }

    case MainPostType.ids.Event: {
      const interested = item.attendees.filter(
        (person) =>
          person.responseStatus === EventResponseActionStatus.ids.Interested
      );

      const going = item.attendees.filter(
        (person) =>
          person.responseStatus === EventResponseActionStatus.ids.Going
      );

      return {
        ...item,
        interested,
        going,
      };
    }

    default:
      return item;
  }
};

export const updateIdWithUUID = (data) => {
  return data.map((item) => {
    item.id = v4();
    return item;
  });
};
