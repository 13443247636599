import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Popover,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useStyles } from "./TestDetailsStyles";
import Icon from "@mdi/react";
import { mdiDotsVertical } from "@mdi/js";
import TestQuestionMorePopover from "../TestQuestionMorePopover/TestQuestionMorePopover";
import { TestContext } from "../../common/contexts/TestContext";
import { StickyWrapper } from "../../library";
import { borderRadius, padding, paddingBottom, paddingLeft } from "styled-system";

const TestDetails = ({ searchParams }) => {
  const { testId } = useParams();
  const [test, setTest] = useState(null);
  const [answers, setAnswers] = useState([]);
  const theme = useTheme();
  const styles = useStyles();
  const [refMore, setRefMore] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const { currentTestId, allTests } = useContext(TestContext);
  const tillTablet = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const storedTests = JSON.parse(localStorage.getItem("allTests"));
    const storedAnswers = JSON.parse(localStorage.getItem("allAnswers")) || {
      answers: [],
    };

    if (storedTests && storedTests.test) {
      const foundTest = storedTests.test.find(
        (test) => test.testId === parseInt(testId)
      );
      setTest(foundTest);
    }
    if (storedAnswers && storedAnswers.answers) {
      const foundAnswers = storedAnswers.answers.find(
        (answer) => answer.testId === parseInt(testId)
      );
      setAnswers(foundAnswers ? foundAnswers.questions : []);
    }
  }, [testId, allTests]);

  const openMore = (e, question) => {
    setRefMore(e.currentTarget);
    setSelectedQuestion(question);
  };

  const closeMore = () => {
    setRefMore(null);
    setSelectedQuestion(null);
  };

  const open = Boolean(refMore);

  const NativePopover = () => (
    <Popover
      open={open}
      anchorEl={refMore}
      onClose={closeMore}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <TestQuestionMorePopover
        question={selectedQuestion}
        testId={currentTestId}
        closeMore={closeMore}
        key={test ? test.length : 0}
        test={test}
      />
    </Popover>
  );

  if (!test) {
    return <Typography>Test not found.</Typography>;
  }

  const filteredQuestions = test.questions.filter((question) => {
    const matchesType = searchParams.questionTypes?.length
      ? searchParams.questionTypes.includes(question.type)
      : true;
    const matchesSearch = searchParams.questionSearch
      ? question.question
          .toLowerCase()
          .includes(searchParams.questionSearch.toLowerCase())
      : true;
    return matchesType && matchesSearch;
  });

  return (
    <div style={{padding : "16px",paddingTop : "0px"}}>
       
        <StickyWrapper  containerStyle={{
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop :10,
          paddingBottom : 10,
          top: tillTablet ? 47 : 68,
          border: `2px solid ${theme.colors.LIST_CARD_BORDER_COLOR}`,
          borderRadius : "10px"
        }}>
          
          <Typography className={styles.title} variant="h6">
            {test.title}
          </Typography>
          <Typography variant="body1">{test.description}</Typography>
          <Typography variant="body2">
            Duration: {test.duration_minutes} minutes
          </Typography>      
        
        </StickyWrapper>
     
      <Typography variant="h6" style={{ margin: "12px 0px 10px 4px" }}>
        Questions:
      </Typography>

      {filteredQuestions.map((question, index) => {
        const answer = answers.find((ans) => ans.questionId === question.id);
        return (
          <div className={styles.container} key={question.id}>
            <CardContent style={{ padding: theme.spacing(1), // Adjust padding as needed
    '&:last-child': {
      paddingBottom: theme.spacing(1), // Ensure consistent padding at the bottom
    },}}>
              <div
                className={styles.jobDescription}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                  width: "100%",
                }}
                key={question.id}
              >
                <div>
                  <div className={styles.title}>
                    {index + 1}. {question.question}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  <Icon
                    className={styles.moreIcon}
                    path={mdiDotsVertical}
                    size="1.8rem"
                    color={theme.colors.MORE_ACTIONS_ICON_COLOR}
                    onClick={(e) => openMore(e, question)}
                  />
                  <NativePopover />
                </div>
              </div>
              {question.type === "checkbox" && (
                <ul>
                  {question.options.map((option, idx) => (
                    <div
                      key={idx}
                      className={`${styles.jobDescription} ${styles.hover}`}
                      style={{
                        backgroundColor:
                          answer && answer.answer.includes(idx)
                            ? "#C6EBC5"
                            : "#ff7270",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {option}
                    </div>
                  ))}
                </ul>
              )}

              {question.type === "image" && (
                <img
                  src={question.image}
                  alt="Question"
                  style={{ maxWidth: "100px", maxHeight: "100px", marginBottom : "1rem" }}
                />
              )}
              {question.type === "video" && (
                <video
                  controls
                  style={{ maxWidth: "120px", maxHeight: "120px" , marginBottom : "1rem" }}
                >
                  <source src={question.videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}

              {question.type !== "checkbox" &&
                question.type === "multiple_choice" &&
                question.options &&
                question.options.length > 0 && (
                  <div>
                    {question.options.map((option, idx) => {
                      const isSelected = answer && answer.answer == idx;
                      return (
                        <div
                          key={idx}
                          className={`${styles.jobDescription} ${styles.hover}`}
                          style={{
                            backgroundColor: isSelected ? "#C6EBC5" : "#ff7270",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          <Typography>{option}</Typography>
                        </div>
                      );
                    })}
                  </div>
                )}

              {(question.type === "text_reply" ||
                question.type === "number_reply"|| question.type === "image" || question.type === "video") && (
                <div
                  className={`${styles.jobDescription} ${styles.hover}`}
                  style={{
                    backgroundColor: answer ? "#C6EBC5" : "#ff7270",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <Typography>
                    {answer ? answer.answer : "No answer provided"}
                  </Typography>
                </div>
              )}
            </CardContent>
          </div>
        );
      })}
    </div>
  );
};

export default TestDetails;
