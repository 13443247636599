const data = {
  menuItems: [
    {
      label: 'Core Features',
      path: '#services',
      offset: '90',
    },
    {
      label: 'Multi-device Support',
      path: '#control',
      offset: '90',
    },
    {
      label: 'Key Features',
      path: '#keyfeature',
      offset: '90',
    },
    // {
    //   label: 'Partners',
    //   path: '#partners',
    //   offset: '-90',
    // },
    {
      label: 'Industries',
      path: '#industries',
      offset: '90',
    },
    {
      label: 'Pricing',
      path: '#pricing',
      offset: '90',
    },
    // {
    //   label: 'Testimonial',
    //   path: '#testimonialSection',
    //   offset: '90',
    // },
    {
      label: 'Get Mobile Application',
      path: '#download',
      offset: '90',
    },
    {
      label: 'FAQ',
      path: '#faq',
      offset: '90',
    },


  ],
};
export default data;
