
export const TOGGLE_ADD_POST_LIST_DIALOG = "TOGGLE_ADD_POST_LIST_DIALOG";


export const toggleAddPostListDialog = (payload) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_POST_LIST_DIALOG,
      payload: payload
    });
  };
};

