import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerById } from '../../actions/customerActions';
import { getEmployeeById } from '../../actions/employeeActions';
import ScreenView from '../../components/ScreenView/ScreenView';
import UserProfile from '../../components/UserProfile/UserProfile';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { ProfileMode } from '../../utils';
import RightsManager from '../../utils/RightsManager';
import { useStyles } from './UserProfilePageStyles';

function UserProfilePage() {
  const styles = useStyles();

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  console.log("useer",authUser);
  const isUserAdmin = RightsManager.isAdmin();
  const isUserMember = RightsManager.isMember();
  const isUserCustomer = RightsManager.isCustomer();

  let mode = ProfileMode.Employee;
  if (isUserAdmin || isUserMember) {
    mode = ProfileMode.Employee;
  }

  if (isUserCustomer) {
    mode = ProfileMode.Customer;
  }

  const dispatch = useDispatch();
  const { employeeById, allEmployeesLoader } = useSelector(state => state.employees);
  const { employeeDetailLoader, editLoader } = allEmployeesLoader;

  const { customerById, allCustomersLoader } = useSelector(state => state.customers);
  const { customerDetailLoader } = allCustomersLoader;

  useEffect(() => {

    const profileId = [authUser.id];

    if (mode === ProfileMode.Employee) {
      dispatch(getEmployeeById(profileId, false, authUser));
    } else {
      dispatch(getCustomerById(profileId, false, authUser));
    }

  }, [authUser.id, mode]);

  return (
    <div className={styles.container_UserProfilePage}>
      {
        (
          employeeDetailLoader ||
          editLoader ||
          customerDetailLoader
        ) &&
        <FullScreenLoaderCNO />
      }
      <ScreenView
        content={
          (employeeById || customerById) &&
          <UserProfile
            mode={mode}
            profile={mode === ProfileMode.Employee ? employeeById : customerById}
            isActiveMember={true}
          />
        }
        headerTitle="Profile"
      />
    </div>
  )
}

export default UserProfilePage
