import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getInvoiceById, setInvoiceById } from '../../actions/invoiceActions';
import ScreenView from '../../components/ScreenView/ScreenView';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import InvoiceDetails from './InvoiceDetails';

function InvoiceDetailsPage() {

  const dispatch = useDispatch();
  const { invoiceById, allInvoiceLoader, invoices } = useSelector(state => state.invoices);
  const { invoiceDetailLoader } = allInvoiceLoader;

  const { id } = useParams();

  useEffect(() => {
    const invoiceId = id;

    const invoice = invoices.find(invoice => invoice.id === id);

    dispatch(getInvoiceById(invoiceId, invoice));

  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(setInvoiceById(null));
    };
  }, []);

  console.log("invoiceById: ", invoiceById);

  return (
    <div>
      <ScreenView
        content={
          invoiceById
            ? <InvoiceDetails invoice={invoiceById} />
            : (invoiceDetailLoader ? <FullScreenLoaderCNO /> : null)
        }
        headerTitle="Invoices"
      />
    </div>
  )
}

export default InvoiceDetailsPage
