import { useFormik } from 'formik';
import React from 'react';
import Expandable from '../../library/Expandable';
import * as yup from 'yup';
import InputCNO from '../../library/InputCNO';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import FormActionButtons from '../FormActionButtons';
import { updateCompanyDetails } from '../../actions/customerCompanyActions';
import { useDispatch } from 'react-redux';
import { mdiSignatureFreehand } from '@mdi/js';
import { useTheme } from '@material-ui/core';


function EmailSignatureTemplateExpandable({ profile, isActive }) {

  const theme = useTheme();

  const authUserCompany = LocalStorageConstants.getItem("authUserCompany");

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    emailSignatureTemplate: yup.string().required("Email Signature Template is required."),
  });

  const handleSubmit = (values) => {

    const dataToSend = {
      ...profile,
      ...authUserCompany,
      emailSignatureTemplate: values.emailSignatureTemplate
    };

    dispatch(updateCompanyDetails(dataToSend));

  };

  const formik = useFormik({
    initialValues: {
      emailSignatureTemplate: authUserCompany.emailSignatureTemplate,
    },
    validationSchema,
    onSubmit: values => handleSubmit(values)
  });

  return (
    <Expandable
      title='Email Signature Template'
      icon={mdiSignatureFreehand}
      disabled={!isActive}
      containerStyle={{ background: theme.colors.PROFILE_CARD_BACKGROUND_COLOR }}
    >
      <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>

        <InputCNO
          formik={formik}
          name="emailSignatureTemplate"
          placeholder="Enter your Email signature"
          rows={10}
          isRequired={true}
          multiline={true}
          disabled={!isActive}
          containerStyles={{ width: "100%" }}
        />

        <FormActionButtons
          rightText="Save"
          isLeftButton={false}
          formik={formik}
        />

      </form>
    </Expandable>
  )
}

export default EmailSignatureTemplateExpandable