import { CircularProgress, Typography, useMediaQuery } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAppointments,
  fetchMoreAppointments,
  setAppointmentSearchParams,
  toggleAddAppointmentDialog,
} from "../../actions/appointmentActions";
import JobCardSkeleton from "../../components/JobCardSkeleton/JobCardSkeleton";
import AppointmentListCard from "../../components/Appointment/AppointmentListCard";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import {
  AddAppointment,
  AddMeeting,
  AddLeave,
  AddHoliday,
} from "../../components/Appointment";
import AppointmentFilterBar from "./AppointmentFilterBar";
import { getPrefillAssignedUser } from "../../utils/Helpers";
import RightsManager from "../../utils/RightsManager";
import InfiniteScrollCNO from "../../library/InfiniteScrollCNO";
import StickyWrapper from "../../library/StickyWrapper";
import { mdiClockCheckOutline } from "@mdi/js";
import Switcher from "../../common/components/GridListToggle/Switcher";
import { AppointmentType } from "../../utils/mappings";
import LeaveListCard from "../../components/Appointment/LeaveListCard";
import HolidayListCard from "../../components/Appointment/HolidayListCard";

function AppointmentListing({
  timeFilterVisible = true,
  customer,
  page,
  setPage,
}) {
  const isUserMember = RightsManager.isMember();

  const GlobalStyles = useGlobalStyles();
  const [isGridView, setIsGridView] = useState(false);

  const dispatch = useDispatch();

  const {
    appointments,
    appointmentsLoader,
    deleteAppointmentLoader,
    addAppointmentLoader,
    editAppointmentLoader,
    hasMoreAppointments,
    isAddAppointmentDialog,
    isAddMeetingDialog,
    isAddLeaveDialog,
    isAddHolidayDialog,
  } = useSelector((state) => state.appointments);

  console.log("==appointments=======", appointments);

  const initialValues = {
    customer: null,
    categories: [],
    appointment: null,
    startDate: null,
    endDate: null,
    assignments: isUserMember ? getPrefillAssignedUser() : null,
  };

  useEffect(() => {
    const payload = {
      assignedIds:
        isUserMember && getPrefillAssignedUser()
          ? [getPrefillAssignedUser().id]
          : null,
      customerIds: customer ? [customer.id] : null,
      visitor: appointments[0]?.visitor ? appointments[0].visitor : "visitor",
    };

    dispatch(fetchAppointments(payload));
  }, []);

  const loadMore = () => {
    console.log("=== hasMoreAppointments === : ", hasMoreAppointments);
    if (hasMoreAppointments) {
      const moreAppointmentsPayload = {
        pageIndex: page + 1,
        orderBy: "newest",
        searchText: "",
        customerIds: customer ? [customer.id] : null,
      };

      dispatch(fetchMoreAppointments(moreAppointmentsPayload));

      setPage(page + 1);
    }
  };

  console.log("appointments: ", appointments);
  console.log("appointmentsLoader: ", appointmentsLoader);

  const openAddAppointmentDialog = () => {
    dispatch(toggleAddAppointmentDialog(true));
  };

  console.log("addleaveDialog", isAddLeaveDialog);
  console.log("addHolidayDialog", isAddHolidayDialog);

  const renderListCard = (appointment) => {
    if (appointment.appointmentType) {
      return appointment.appointmentType === AppointmentType.LEAVE ? (
        <LeaveListCard key={appointment.id} appointment={appointment} />
      ) : appointment.appointmentType === AppointmentType.HOLIDAY ? (
        <HolidayListCard key={appointment.id} appointment={appointment} />
      ) : (
        <AppointmentListCard key={appointment.id} appointment={appointment} />
      );
    } else {
      return (
        <AppointmentListCard key={appointment.id} appointment={appointment} />
      );
    }
  };

  return (
    <div className={GlobalStyles.listingContainer}>
      {(deleteAppointmentLoader ||
        addAppointmentLoader ||
        editAppointmentLoader) && <FullScreenLoaderCNO />}

      {timeFilterVisible && (
        <StickyWrapper>
          <AppointmentFilterBar
            isGridView={isGridView}
            setIsGridView={setIsGridView}
          />
        </StickyWrapper>
      )}
      {/* <Switcher isGridView={isGridView} setIsGridView={setIsGridView} /> */}

      <InfiniteScrollCNO
        items={appointments}
        next={loadMore}
        hasMore={hasMoreAppointments}
        loading={appointmentsLoader}
        renderListItem={(appointment) => renderListCard(appointment)}
        notFoundTextMessage={"No Appointment Present"}
        notFoundIcon={mdiClockCheckOutline}
        showListCTAButtons={true}
        listCTAButtonsProps={{
          addTitle: "Add Appointment",
          exploreId: 4,
          onClickAdd: openAddAppointmentDialog,
        }}
        isGridView={isGridView}
        setIsGridView={setIsGridView}
        mode={"appointments"}
      />

      {isAddAppointmentDialog && <AddAppointment />}

      {isAddMeetingDialog && <AddMeeting />}

      {isAddLeaveDialog && <AddLeave />}

      {isAddHolidayDialog && <AddHoliday />}
    </div>
  );
}

export default AppointmentListing;
