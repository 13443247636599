import { useFormik } from 'formik';
import React, { Fragment } from 'react';
import Expandable from '../../library/Expandable';
import * as yup from 'yup';
import InputCNO from '../../library/InputCNO';
import FieldHelperTextCNO from '../../library/FieldHelperTextCNO';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import FormActionButtons from '../FormActionButtons';
import { updateCompanyDetails } from '../../actions/customerCompanyActions';
import { useDispatch } from 'react-redux';
import { mdiFileTable } from '@mdi/js';
import { useTheme } from '@material-ui/core';

function InvoiceEmailTemplateExpandable({ profile, isActive }) {

  const theme = useTheme()

  const authUserCompany = LocalStorageConstants.getItem("authUserCompany");

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    invoiceEmailTemplate: yup
      .string()
      // .matches(/<<Name>>((.|\n)*)<<Title>>/, "<<Name>> & <<Title>> is required as it will be replaced by the Customer Name and Invoice Title.")
      .matches(/<<Name>>/, "<<Name>> is required as it will be replaced by the Customer Name.")
      .matches(/<<Title>>/, "<<Title>> is required as it will be replaced by the Invoice Title.")
      .required("Invoice Email Template is required."),
  });

  const handleSubmit = (values) => {

    const dataToSend = {
      ...profile,
      ...authUserCompany,
      invoiceEmailTemplate: values.invoiceEmailTemplate
    };

    dispatch(updateCompanyDetails(dataToSend));

  };

  const formik = useFormik({
    initialValues: {
      invoiceEmailTemplate: authUserCompany.invoiceEmailTemplate,
    },
    validationSchema,
    onSubmit: values => handleSubmit(values)
  });

  return (
    <Expandable
      title='Invoice Email Template'
      icon={mdiFileTable}
      disabled={!isActive}
      containerStyle={{ background: theme.colors.PROFILE_CARD_BACKGROUND_COLOR }}
    >
      <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>

        <InputCNO
          formik={formik}
          name="invoiceEmailTemplate"
          placeholder="Enter your Invoice message"
          rows={10}
          isRequired={true}
          multiline={true}
          disabled={!isActive}
          containerStyles={{ width: "100%" }}
        />

        <FieldHelperTextCNO
          helperText={<Fragment>
            <div>In email following will be replaced as given below, please do not remove it from template:</div>
            <div>&lt;&lt;Name&gt;&gt; will be replaced by Customer Name</div>
            <div>&lt;&lt;Title&gt;&gt; will be replaced by Invoice Title</div>
          </Fragment>}
          showHelperText={true}
          errorColor={false}
        />

        <FormActionButtons
          rightText="Save"
          isLeftButton={false}
          formik={formik}
        />

      </form>
    </Expandable>
  )
}

export default InvoiceEmailTemplateExpandable