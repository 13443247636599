import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  container_PaymentHistoryModal: {
    width: '100%',
  },
  grid: {
    // width: ({ tillTablet }) => tillTablet ? "auto" : 740,
    // maxWidth: 740,
    width: '100%',
    margin: "0 auto",
    '& .MuiDataGrid-root > *': {
      // border: '2px solid #BE0A02'
      outline: 'none'
    },
    '& .MuiDataGrid-root .MuiDataGrid-colCellSortable': {
      background: theme.colors.WORK_HOUR_TABLE_HEADER_BACKGROUND_COLOUR,
      // lineHeight: 3,
    },
    '& .MuiDataGrid-root .MuiDataGrid-dataContainer': {
      background: theme.colors.WORK_HOUR_TABLE_ROW_BACKGROUND_COLOUR,
    },
    '& .MuiDataGrid-root .MuiDataGrid-colCellWrapper .MuiDataGrid-cell': {
      background: theme.colors.WORK_HOUR_TABLE_HEADER_BACKGROUND_COLOUR,
    },
    '& .MuiDataGrid-root .MuiDataGrid-footer': {
      background: theme.colors.WORK_HOUR_TABLE_HEADER_BACKGROUND_COLOUR,
      minHeight: 35,
      height: 35
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '& .MuiDataGrid-root .MuiDataGrid-colCell, .MuiDataGrid-root .MuiDataGrid-cell': {
      // paddingRight: 0,

      // height: 80
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnsContainer': {
      background: theme.colors.LIST_CARD_BACKGROUND_COLOR,
    }
  },
}));