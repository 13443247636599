import { CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "../../library/FullScreenLoaderCNO/FullScreenLoaderCNOStyles";

function FullScreenLoaderCNO({ zIndex, loading = true, message = "" }) {
  const styles = useStyles();

  if (!loading) return null;

  return (
    <div
      className={styles.container_FullScreenLoader}
      style={{ ...(zIndex ? { zIndex } : {}) }}
    >
      <div className={styles.loader}>
        <CircularProgress />
        {message ? <Typography color="black" style={{ marginTop: 12 }} >{message}</Typography> : null}
      </div>
    </div>
  );
}

export default FullScreenLoaderCNO;
