import { Button, makeStyles, Typography, useTheme } from '@material-ui/core';
import React, { Fragment, useState } from 'react'
import Moment from 'react-moment';
import JobTypeIcon from '../../../components/JobTypeIcon/JobTypeIcon';
import { ButtonCNO, DateTimeFormat, DateTimeText, DotSeparator, MetaText } from '../../../library';
import ChipCNO from '../../../library/ChipCNO';
import LinkCNO from '../../../library/LinkCNO';
import MoreActionCNO from '../../../library/MoreActionsCNO';
import { calendarStrings, TIME_FORMAT } from '../../../utils/formatDate';
import { formatTimeWithTZ, getCategory } from '../../../utils/Helpers';
import { getMaterialIconString } from '../../../utils/icons';
import { EventResponseActionStatus, EventType } from '../../../utils/mappings';
import { useGlobalStyles } from '../../../utils/Styles/GlobalStyles';
import * as icons from '@mdi/js';
import { getAttendeeResponseStatus } from '../helpers';
import ConfirmationDialogCNO from '../../../library/ConfirmationDialogCNO';
import { AppointmentListCardPopover } from '../../../components/Appointment';
import EventListCardPopover from './EventListCardPopover';
import Icon from '@mdi/react';
import clsx from 'clsx';

const EventListItemFW = ({ event }) => {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const category = getCategory(event.categories[0]);

  const [isMapConfirmModal, setIsMapConfirmModal] = useState(false);

  return (
    <div className={clsx(GlobalStyles.listCardContainer, GlobalStyles.listCardFW)}>

      <div className={GlobalStyles.cardInfoWrapper}>
        <JobTypeIcon
          iconLink={category.iconLink}
          name={category.title}
          categoryId={category?.id}
        />
        <div className={GlobalStyles.cardInfo}>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-start", }}>

            <LinkCNO to={`/event/${event.id}`}>
              <Typography className={GlobalStyles.cardTitle}>{event.title}</Typography>

              <div className={GlobalStyles.datesWrapper}>
                <MetaText component={'span'}>From</MetaText>
                <DateTimeText format={DateTimeFormat.CalendarString} >
                  {formatTimeWithTZ(event.startDate)}
                </DateTimeText>
                <DateTimeText format={DateTimeFormat.TimeFormat} >
                  {formatTimeWithTZ(event.startTime)}
                </DateTimeText>

                <MetaText component={'span'}>To</MetaText>
                <DateTimeText format={DateTimeFormat.CalendarString}>
                  {formatTimeWithTZ(event.endDate)}
                </DateTimeText>
                <DateTimeText format={DateTimeFormat.TimeFormat} >
                  {formatTimeWithTZ(event.endTime)}
                </DateTimeText>
              </div>

              <div className={styles.responseActionStatusWrapper}>
                <MetaText>
                  {event.interested.length} Interested
                </MetaText>
                <DotSeparator />
                <MetaText>
                  {event.going.length} Going
                </MetaText>
              </div>

            </LinkCNO>

            {
              // (isUserAdmin || isUserCustomer) &&
              <MoreActionCNO>
                <EventListCardPopover event={event} />
              </MoreActionCNO>
            }

          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-end", flexWrap: 'nowrap', minHeight: 60 }}>
            <div className={GlobalStyles.chipsWrapper}>
              {
                getAttendeeResponseStatus(event.attendees).map(action => (
                  <ChipCNO
                    active={action.isTrue}
                    icon={icons[getMaterialIconString(action.icon)]}
                    name={action.title}
                  />
                ))
              }
            </div>

            <div
              style={{
                minWidth: 100, display: 'flex', justifyContent: 'flex-end'
              }}>

              {
                event.eventType === EventType.ids.Online ? (
                  <div>
                    <MetaText>
                      {EventType.getItem(event.eventType).title}
                    </MetaText>
                    <Button
                      variant="contained"
                      className={GlobalStyles.orangeButton}
                      // onClick={handeLeftClick}
                      style={{ marginLeft: 0 }}
                    >
                      <LinkCNO to={event.joinUrl} type='absolute' >Join</LinkCNO>
                    </Button>
                  </div>
                ) : null
              }

              {
                event.eventType === EventType.ids.InPerson ? (
                  <div
                    style={{ cursor: 'pointer', textAlign: 'right' }}
                    onClick={() => setIsMapConfirmModal(true)}
                  >
                    <MetaText>
                      {EventType.getItem(event.eventType).title}
                    </MetaText>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon
                        path={icons.mdiDirections}
                        size="1.5rem"
                        color={theme.colors.META_TEXT_COLOR}
                      />
                      <MetaText style={{ marginLeft: 4 }}>
                        {event.mapAddress}
                      </MetaText>
                    </div>

                  </div>
                ) : null
              }
            </div>
          </div>

        </div>

      </div>

      {
        isMapConfirmModal &&
        <ConfirmationDialogCNO
          open={isMapConfirmModal}
          onClose={() => setIsMapConfirmModal(false)}
          dialogTitle="Navigate to Event Location"
          messages={["Do you want to navigate to event location?"]}
          yesClick={() => {
            window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${event.location}`, '_blank');
          }}
        />
      }

    </div>
  )
}

export default EventListItemFW;

const useStyles = makeStyles((theme) => ({
  textDotSeprator: {
    width: 4,
    height: 4,
    backgroundColor: 'grey',
    marginLeft: 6,
    marginRight: 6,
    borderRadius: '50%',
  },
  metaText: {
    fontSize: '0.9rem',
    color: theme.colors.META_TEXT_COLOR
  },
  responseActionStatusWrapper: {
    display: 'flex',
    alignItems: 'center',
  }
}))