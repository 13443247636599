import servicesData from "../actions/DummyData/servicesData.json";

import { batch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
// import {
//   apiServiceAssign,
//   apiServiceChangeStatus,
//   apiServiceCreate,
//   apiServiceEdit,
//   apiServiceGetMediaLink,
//   apiServiceInitUpload,
//   apiServiceRemove,
//   apiServiceSearch,
//   apiServiceUploadMedia
// } from '../api/service';
import moment from "moment";
import { v4 } from "uuid";
import { addQuotation } from "./quotationActions";
import { generateId } from "../utils/IdGeneratorHelpers";
import { TaskStatus, RateType } from "../utils";
import {
  ServiceStatus,
  QuotationStatus,
  JobVisibilityForCustomer,
  MediaSizeType,
  FileType,
} from "../utils/mappings";
import {
  b64toBlob,
  getContentType,
  getFileDataAsync,
  getFileTypeFromMimeType,
  getMediaData,
  getThumbnailBlob,
} from "../utils/Helpers";
import { addJob, createJob } from "./jobActions";
import { AppConstants } from "../utils/AppConstants";
import { combineDateAndTime } from "../utils/Tracking";
import LocalStorageConstants from "../utils/LocalStorageConstants";

export const SET_SERVICES = "SET_SERVICES";
export const SET_SERVICE_BY_ID = "SET_SERVICE_BY_ID";
export const SET_SERVICE_LOADER = "SET_SERVICE_LOADER";
export const SET_HAS_MORE_SERVICES = "SET_HAS_MORE_SERVICES";
export const SET_SERVICE_SEARCH_PARAMS = "SET_SERVICE_SEARCH_PARAMS";
export const TOGGLE_ADD_SERVICE_DIALOG = "TOGGLE_ADD_SERVICE_DIALOG";
export const SET_SERVICE_TIMES_FILTER = "SET_SERVICE_TIMES_FILTER";
export const SET_RESOLVED_SERVICE_MEDIAS = "SET_RESOLVED_SERVICE_MEDIAS";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const SET_SERVICE_DELETE_LOADER = "SET_SERVICE_DELETE_LOADER";
export const SET_SHARE_SERVICE_LOADER = "SET_SHARE_SERVICE_LOADER";
export const SHARE_SERVICE = "SHARE_SERVICE";
export const TOGGLE_IS_DELETE_UNDO = "TOGGLE_IS_DELETE_UNDO";
export const TOGGLE_SHARE_SERVICE_MODAL = "TOGGLE_SHARE_SERVICE_MODAL";
export const DELETE_SERVICE_BY_ID = "DELETE_SERVICE_BY_ID";
export const UNDO_DELETE = "UNDO_DELETE";
export const SET_TASK_BY_ID = "SET_TASK_BY_ID";
export const SET_SERVICE_MEDIA_LOADER = "SET_SERVICE_MEDIA_LOADER";
export const SET_IS_SERVICE_FINISHED_FILTER = "SET_IS_SERVICE_FINISHED_FILTER";
export const SET_SERVICE_MEDIA_UPLOAD_LOADER =
  "SET_SERVICE_MEDIA_UPLOAD_LOADER";
export const SET_TODOS = "SET_TODOS";
export const SET_HAS_MORE_TODOS = "SET_HAS_MORE_TODOS";
export const SET_TASK_LOADERS = "SET_TASK_LOADERS";

export function setResolvedServiceMedias(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_RESOLVED_SERVICE_MEDIAS,
      payload: payload,
    });
  };
}

export function setServiceTimesFilter(value) {
  return (dispatch) => {
    dispatch({
      type: SET_SERVICE_TIMES_FILTER,
      payload: value,
    });
  };
}

export const toggleAddServiceDialog = (payload) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_SERVICE_DIALOG,
      payload: payload,
    });
  };
};

export const setServiceSearchParams = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_SERVICE_SEARCH_PARAMS,
      payload: payload,
    });
  };
};

export const setServiceLoaders = (loaderName, loaderState) => {
  return (dispatch) => {
    dispatch({
      type: SET_SERVICE_LOADER,
      payload: { loaderName, loaderState },
    });
  };
};

export const setServices = (payload) => {
  console.log("z14", payload);
  return (dispatch) => {
    dispatch({
      type: SET_SERVICES,
      payload: payload,
    });
  };
};

export const setHasMoreServices = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_MORE_SERVICES,
      payload: payload,
    });
  };
};

export const setServiceById = (payload) => {
  return (dispatch, getState) => {
    if (payload) {
      batch(() => {
        dispatch({
          type: SET_SERVICE_BY_ID,
          payload: payload,
        });

        const { services } = getState().services;
        const updatedServices = services.map((service) =>
          service.id === payload.id ? payload : service
        );
        dispatch(setServices(updatedServices));
      });
    } else {
      dispatch({
        type: SET_SERVICE_BY_ID,
        payload: payload,
      });
    }
  };
};

export const filterServices = (services, filterData) => {
  function matches(service, filters) {
    console.log("service-filters",{service,filters});
    let isMatching = false;

    // Check categories
    if (
      filters.categories &&
      filters.categories.some((cat) => service.categories.includes(cat))
    ) {
      isMatching = true;
    }

    // Check location (lat, lng, mapAddress)
    if (filters.lat && filters.lat.includes(service.lat)) {
      isMatching = true;
    }
    if (filters.lng && filters.lng.includes(service.lng)) {
      isMatching = true;
    }
    if (filters.mapAddress && filters.mapAddress.includes(service.mapAddress)) {
      isMatching = true;
    }

    if (filters.serviceProviderIds && service.serviceAssignments.some(provider => filters.serviceProviderIds.includes(provider.id))) {
      isMatching = true;
  }
  

    // Check endDate
    const serviceEndDate = moment(service.endDate);
    const filterEndDate = filters.endDate ? moment(filters.endDate._d) : null;
    const serviceStartDate = moment(service.startDate);
    const filterStartDate = filters.startDate
      ? moment(filters.startDate._d)
      : null;

    // console.log("filterStartDate", filterStartDate);
    // console.log("serviceStartDate", serviceStartDate);
    // console.log("serviceEndDate", serviceEndDate);
    // console.log("filterEndDate", filterEndDate);

    if (filterEndDate && filterStartDate) {
      if (
        filterStartDate.isSameOrBefore(serviceStartDate) &&
        serviceEndDate.isSameOrBefore(filterEndDate)
      ) {
        // console.log("match1");
        return true;
      }
    } else if (filterEndDate) {
      if (serviceEndDate.isSameOrBefore(filterEndDate)) {
        // console.log("match2");
        return true;
      }
    } else if (filterStartDate) {
      if (serviceStartDate.isSameOrAfter(filterStartDate)) {
        // console.log("match3");
        return true;
      }
    }

    return isMatching;
  }

  const filteredServices = services.filter((service) =>
    matches(service, filterData)
  );
  return filteredServices;
};

export const fetchServices = (payload) => {
  console.log("f1", payload);
  return async (dispatch, getState) => {
    dispatch(setServiceLoaders("ServicesLoader", true));
    dispatch(setHasMoreServices(true));

    const { serviceSearchParams } = getState().services;

    const dataToSend = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      customerIds: serviceSearchParams.customer
        ? [serviceSearchParams.customer.id]
        : null,
      searchText: serviceSearchParams.service
        ? serviceSearchParams.service.title
        : "",
      serviceProviderIds: serviceSearchParams.serviceProvider
        ? [serviceSearchParams.serviceProvider.id]
        : null,
      categories: serviceSearchParams.categories,
      startDate: serviceSearchParams.startDate
        ? moment(serviceSearchParams.startDate).startOf("day")
        : null,
      // endDate: serviceSearchParams.endDate ? moment(serviceSearchParams.endDate).endOf('day') : null,
      assignedIds: serviceSearchParams.assignments
        ? [serviceSearchParams.assignments.id]
        : null,
      ...payload,

      endDate: payload?.endDate
        ? payload.endDate
        : serviceSearchParams.endDate
        ? moment(serviceSearchParams.endDate).toISOString()
        : null,
    };
    console.log("f2FilterData", dataToSend);

    // await apiServiceSearch.post(dataToSend)
    await axios
      .get("https://jsonplaceholder.typicode.com/users", dataToSend)
      .then((response) => {
        if (response.status === 200) {
          // console.log("f4", response);
          // const services = response.data.data;
          const initialServices = servicesData;

          const servicesArray = initialServices;

          let newServices = [];
          console.log("services====fetchServices", servicesArray);
          console.log("prevDataToSend", dataToSend);
          if (
            (dataToSend?.categories && dataToSend.categories.length > 0) ||
            (dataToSend?.mapAddress && dataToSend.mapAddress.length > 0) ||
            dataToSend?.startDate ||
            dataToSend?.endDate ||
            (dataToSend?.lat && dataToSend.lat.length > 0) ||
            (dataToSend?.lng && dataToSend.lng.length > 0) 
            || (dataToSend?.serviceProviderIds && dataToSend.serviceProviderIds.length > 0)
          ) {
            const services = getState().services.services;
            console.log("Reducerservices==", services);

            if(dataToSend?.serviceProviderIds){
              newServices = filterServices(initialServices, dataToSend);
              // Initilly no data is present in services reducer, so we will use dummy data 
            }else{
              newServices = filterServices(services, dataToSend);
            }
            // console.log("dataTosend", dataToSend);
            console.log("newServices====fetchServices", newServices);

            if (newServices.length < 10) {
              dispatch(setHasMoreServices(false));
            }

            dispatch(setServices(newServices));
          } else {
            if (servicesArray.length < 10) {
              dispatch(setHasMoreServices(false));
            }

            dispatch(setServices(servicesArray));
          }
        } else {
          console.error("Error: Fetch Services : Response", response);
        }
      })
      .catch((error) => {
        console.error("Error: Fetch Services: ", error);
      })
      .finally(() => dispatch(setServiceLoaders("ServicesLoader", false)));
  };
};

export const fetchMoreServices = (payload) => {
  return async (dispatch, getState) => {
    const prevServices = getState().services.services;
    const { serviceSearchParams } = getState().services;

    dispatch(setServiceLoaders("moreServicesLoader", true));

    dispatch(setHasMoreServices(true));

    const dataToSend = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      // endDate: null,
      ids: null,
      customerIds: serviceSearchParams.customer
        ? [serviceSearchParams.customer.id]
        : null,
      searchText: serviceSearchParams.Service
        ? serviceSearchParams.service.title
        : "",
      categories: serviceSearchParams.categories,
      startDate: serviceSearchParams.startDate
        ? moment(serviceSearchParams.startDate).startOf("day")
        : null,
      // endDate: ServiceSearchParams.endDate ? moment(serviceSearchParams.endDate).endOf('day') : null,
      assignedIds: serviceSearchParams.assignments
        ? [serviceSearchParams.assignments.id]
        : null,
      ...payload,
      endDate: serviceSearchParams.endDate
        ? serviceSearchParams.endDate
        : moment().add(5, "year").endOf("day").toDate(),
    };

    // await apiServiceSearch.post(dataToSend)
    await axios
      .get("https://jsonplaceholder.typicode.com/users", dataToSend)
      .then((response) => {
        if (response.status === 200) {
          let services = response.data.data;

          if (services.length < 10) {
            dispatch(setHasMoreServices(false));
          }

          services = [...prevServices, ...services];

          dispatch(setServices(services));
        } else {
          console.error("Error: Fetch more Services : Response", response);
        }
      })
      .catch((error) => {
        console.error("Error: Fetch more Services: ", error);
      })
      .finally(() => dispatch(setServiceLoaders("moreServicesLoader", false)));
  };
};

export const fetchServiceById = (serviceId, offlineData) => {
  return async (dispatch, getState) => {
    dispatch(setServiceLoaders("ServiceDetailLoader", true));

    const dataToSend = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      startDate: null,
      endDate: null,
      assignedIds: [],
      categories: [],
      searchText: "",
      ids: [serviceId],
    };

    // await apiServiceSearch.post(dataToSend)
    await axios
      .get("https://jsonplaceholder.typicode.com/users", dataToSend)
      .then((response) => {
        if (response.status === 200) {
          // const Services = getState().Services;

          let service = response.data.data[0];
          // Service = Service ? Service : null;
          // console.log("Service details: Service== ", Service);
          // console.log("Service details: servicesData== ", servicesData);

          dispatch(setServiceById(service));
        } else {
          if (offlineData) {
            dispatch(setServiceById(offlineData));
          } else {
            console.error("Error: Fetch Service : Response", response);
          }
        }
      })
      .catch((error) => {
        if (offlineData) {
          dispatch(setServiceById(offlineData));
        } else {
          console.error("Error: Fetch Service: ", error);
        }
      })
      .finally(() => dispatch(setServiceLoaders("ServiceDetailLoader", false)));
  };
};

// Assuming you have the JSON server running locally on port 3030
// const API_BASE_URL = "http://localhost:3030";

// export const addService = (payload, onAddSuccess) => {
//   return async (dispatch, getState) => {
//     dispatch(setServiceLoaders('addServiceLoader', true));

//     // Combine date and time fields if necessary
//     payload = {
//       ...payload,
//       startDate: combineDateAndTime(payload.startDate, payload.startTime),
//       startTime: combineDateAndTime(payload.startDate, payload.startTime),
//       endDate: combineDateAndTime(payload.endDate, payload.endTime),
//       endTime: combineDateAndTime(payload.endDate, payload.endTime),
//     };

//     const dataToSend = {
//       title: "",
//       description: "",
//       mediaLinks: [],
//       categories: [],
//       status: ServiceStatus.Draft,
//       customerId: "",
//       startDate: new Date().toISOString(),
//       endDate: new Date().toISOString(),
//       startTime: new Date().toISOString(),
//       endTime: new Date().toISOString(),
//       markedCompleteDate:"",
//       markedCompleteTime:"",
//       mapAddress: "",
//       lat: "",
//       lng: "",
//       visibility: JobVisibilityForCustomer.NotVisible,
//       ...payload
//     };

//     try {
//       // Make POST request to JSON server endpoint
//       const response = await fetch(`${API_BASE_URL}/posts`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json"
//         },
//         body: JSON.stringify(dataToSend)
//       });

//       if (response.ok) {
//         const ServiceId = "1111";

//           const newService = {
//             ...payload,
//             id: ServiceId
//           };

//           const { Services } = getState().Services;

//           const updatedServices = [newService, ...Services];

//         // const newService = await response.json();

//         // // Update Services state with new Service
//         // const { Services } = getState().Services;
//         // const updatedServices = [newService, ...Services];
//         dispatch(setServices(updatedServices));

//         toast.success('Service added successfully!!');

//         if (onAddSuccess) {
//           onAddSuccess(newService);
//         }
//       } else {
//         console.error("Error: Add Services : Response", response);
//         toast.error('Error in adding Service.');
//       }
//     } catch (error) {
//       console.error("Error: Add Services: ", error);
//       toast.error('Error in adding Service.');
//     } finally {
//       dispatch(setServiceLoaders('addServiceLoader', false));
//     }
//   };
// };

export const addService = (payload, onAddSuccess) => {
  return async (dispatch, getState) => {
    dispatch(setServiceLoaders("addServiceLoader", true));
    const authUser = LocalStorageConstants.getItem("authUser");

    payload = {
      ...payload,
      startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startTime: combineDateAndTime(payload.startDate, payload.startTime),
      endDate: combineDateAndTime(payload.endDate, payload.endTime),
      endTime: combineDateAndTime(payload.endDate, payload.endTime),
      status: 1,
      createdAt: moment().toISOString(),
      createdBy: {
        id: authUser.id,
        title: authUser.firstName + " " + authUser.lastName,
        profilePic: authUser.profilePic,
      },
      postType:4,
      serviceProviders:[],
      serviceAssignments:[]
    };

    const dataToSend = {
      ...payload,
    };

    await axios
      .get("https://jsonplaceholder.typicode.com/users", dataToSend)
      // await apiServiceCreate.post(dataToSend)
      .then((response) => {
        console.log("dataResponse", response);
        if (response.status === 200) {
          // const serviceId = response.data;

          const newService = {
            id: v4(),
            ...dataToSend,
            // id: ServiceId
          };

          const { services } = getState().services;

          const updatedServices = [newService, ...services];
          console.log("z8", updatedServices);

          dispatch(setServices(updatedServices));
          console.log("z9", updatedServices);
          toast.success("Service added successfully!!");

          if (onAddSuccess) {
            console.log("z10", newService);
            onAddSuccess(newService.id);
          }
        } else {
          console.error("Error: Add Services : Response", response);
          toast.error("Error in adding Service.");
        }
      })
      .catch((error) => {
        console.error("Error: Add Services: ", error);
        toast.error("Error in adding Service2.");
      })
      .finally(() => dispatch(setServiceLoaders("addServiceLoader", false)));
  };
};

export const updateService = (payload, onAddSuccess) => {
  console.log("Updated->", payload);
  return async (dispatch, getState) => {
    dispatch(setServiceLoaders("editServiceLoader", true));

    payload = {
      ...payload,
      startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startTime: combineDateAndTime(payload.startDate, payload.startTime),
      endDate:
        payload.endDate &&
        payload.endTime &&
        combineDateAndTime(payload.endDate, payload.endTime),
      endTime:
        payload.endDate &&
        payload.endTime &&
        combineDateAndTime(payload.endDate, payload.endTime),
      // completeDate:payload.completeDate && payload.completeTime && combineDateAndTime(payload.completeDate, payload.completeTime),
      // completeTime:payload.completeDate && payload.completeTime && combineDateAndTime(payload.completeDate, payload.completeTime),
    };
    console.log("Updated->12", payload);
    const dataToSend = {
      id: "",
      title: "",
      description: "",
      categories: [],
      customerId: "",
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      completeDate: null,
      completeTime: null,
      mapAddress: "",
      lat: "",
      lng: "",
      visibility: 0,
      ...payload,
    };
    await axios
      .get("https://jsonplaceholder.typicode.com/users", dataToSend)
      // await apiServiceEdit.post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          const updatedService = {
            ...payload,
          };

          dispatch(setServiceById(updatedService));

          if (onAddSuccess) {
            onAddSuccess(updatedService);
          }
          
          toast.success("Service edit successfully!!");
        } else {
          console.error("Error: Edit Services : Response", response);
          toast.error("Error in editing Service12.");
        }
      })
      .then(() => {
        if (payload.status === ServiceStatus.SentRejected) {
          const updateStatusPayload = {
            ...payload,
            status:
              payload.status === ServiceStatus.SentRejected
                ? ServiceStatus.Draft
                : payload.status,
            meta: "",
          };

          dispatch(updateServiceStatus(updateStatusPayload));
        }
      })
      .catch((error) => {
        console.error("Error: Edit Services: ", error);
        toast.error("Error in editing Service23.");
      })
      .finally(() => dispatch(setServiceLoaders("editServiceLoader", false)));
  };
};

export const deleteService = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setServiceLoaders("deleteServiceLoader", true));

    const serviceId = payload.id;
    const dataToSend = {
      id: serviceId,
      undo: false,
    };

    // await apiServiceRemove.post(dataToSend)
    await axios
      .get("https://jsonplaceholder.typicode.com/users", dataToSend)
      .then((response) => {
        if (response.status === 200) {
          const { services } = getState().services;

          const updatedServices = services.filter(
            (service) => service.id !== serviceId
          );

          dispatch(setServices(updatedServices));

          toast.success("Service deleted successfully!!");
        } else {
          console.error("Error: Delete Services : Response", response);
          toast.error("Error in deleting Service.");
        }
      })
      .catch((error) => {
        console.error("Error: Delete Services: ", error);
        toast.error("Error in deleting Service.");
      })
      .finally(() => dispatch(setServiceLoaders("deleteServiceLoader", false)));
  };
};

export const assignService = (payload) => {
  return async (dispatch) => {
    dispatch(setServiceLoaders("editServiceLoader", true));

    const dataToSend = payload.ServiceAssignments;

    await apiServiceAssign
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          const newService = {
            ...payload,
          };

          dispatch(setServiceById(newService));

          toast.success("Service assigned successfully!!");
        } else {
          console.error("Error: Assign Services : Response", response);
          toast.error("Error in assigning Service.");
        }
      })
      .catch((error) => {
        console.error("Error: Assign Services: ", error);
        toast.error("Error in assigning Service.");
      })
      .finally(() => dispatch(setServiceLoaders("editServiceLoader", false)));
  };
};

export const updateServiceStatus = (payload) => {
  return async (dispatch) => {
    dispatch(setServiceLoaders("editServiceLoader", true));

    const dataToSend = {
      serviceId: payload.id,
      status: payload.status,
      // meta: payload.meta,
    };

    await axios
      .get("https://jsonplaceholder.typicode.com/users", dataToSend)
    // await apiServiceChangeStatus.post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          const newService = {
            ...payload,
          };

          dispatch(setServiceById(newService));

          toast.success("Service status updated successfully!!");
        } else {
          console.error("Error: Update status Services : Response", response);
          toast.error("Error in updating status Service.");
        }
      })
      .then(() => {
        if (
          payload.status === ServiceStatus.Sent &&
          payload.visibility === JobVisibilityForCustomer.NotVisible
        ) {
          payload = {
            ...payload,
            visibility: JobVisibilityForCustomer.Visible,
          };
          dispatch(updateService(payload, false));
        }
      })
      .catch((error) => {
        console.error("Error: Update status Services: ", error);
        toast.error("Error in updating status Service.");
      })
      .finally(() => dispatch(setServiceLoaders("editServiceLoader", false)));
  };
};

export function handleServiceSearch(
  { sortAscending, pageSize, ...searchParams },
  payload = {}
) {
  return async (dispatch) => {
    console.log("Service Filter Values: setServiceSearchParams", searchParams);

    await dispatch(setServiceSearchParams(searchParams));

    const searchPayload = {
      customerIds: searchParams.customer ? [searchParams.customer.id] : null,
      searchText: searchParams.service ? searchParams.service.title : "",
      categories: searchParams.categories,
      startDate: searchParams.startDate
        ? moment(searchParams.startDate).startOf("day")
        : null,
      endDate: searchParams.endDate
        ? moment(searchParams.endDate).endOf("day")
        : null,
      assignedIds: searchParams.assignments
        ? [searchParams.assignments.id]
        : null,
      serviceProviderIds: searchParams.serviceProvider
        ? [searchParams.serviceProvider.id]
        : null,
      mapAddress: searchParams.mapAddress ? [searchParams.mapAddress] : null,
      lat: searchParams.lat ? [searchParams.lat] : null,
      lng: searchParams.lng ? [searchParams.lng] : null,
      ...(sortAscending !== undefined || !isNaN(sortAscending)
        ? { sortAscending }
        : {}),
      ...(searchParams.Service
        ? {
            ...(searchParams.Service.enterKey
              ? { searchText: searchParams.Service.title }
              : {}),
            ...(!searchParams.Service.enterKey
              ? { ids: [searchParams.Service.id] }
              : {}),
          }
        : {}),
      ...(pageSize !== undefined || pageSize !== null ? { pageSize } : {}),
      ...payload,
    };
    console.log("fetchServicessearchPayload",searchPayload)
    await dispatch(fetchServices(searchPayload));
  };
}

export const convertServiceToJob = (payload, onConvertToService) => {
  return async (dispatch, getState) => {
    dispatch(setServiceLoaders("editServiceLoader", true));

    const jobPayload = {
      ...payload,
      mediaLinks: [],
      rateType: RateType.Fixed,
      startDate: AppConstants.DEFAULT_START_DATE,
      startTime: AppConstants.DEFAULT_START_TIME,
      endDate: AppConstants.DEFAULT_END_TIME,
      endTime: AppConstants.DEFAULT_END_TIME,
      taskAssignments: payload.ServiceAssignments,
      status: TaskStatus.Open,
      taxRate: AppConstants.DEFAULT_TAX_RATE,
    };

    console.log("convertServiceToJob=======jobPayload", jobPayload);

    delete jobPayload.id;

    await dispatch(
      createJob(jobPayload, [], onConvertToService, "Service", {
        success: "Service converted to job successfully.",
        error: "There is issue is converting to job",
      })
    );

    dispatch(setServiceLoaders("editServiceLoader", false));
  };
};

export const convertServiceToQuotation = (payload, onConvertToQuotation) => {
  return async (dispatch, getState) => {
    dispatch(setServiceLoaders("editServiceLoader", true));

    const now = new Date();

    const quotationPayload = {
      ...payload,
      mediaLinks: [],
      startDate: AppConstants.DEFAULT_START_DATE,
      startTime: AppConstants.DEFAULT_START_TIME,
      endDate: AppConstants.DEFAULT_END_TIME,
      endTime: AppConstants.DEFAULT_END_TIME,
      offerDate: now,
      sentDate: now,
      dueInDays: 1,
      offerNumber: generateId(
        "quotation",
        payload.customerText ? payload.customerText : "No Customer"
      ),
      offerAssignments: payload.ServiceAssignments,
      status: QuotationStatus.Draft,
      taxRate: AppConstants.DEFAULT_TAX_RATE,
    };

    delete quotationPayload.id;

    await dispatch(addQuotation(quotationPayload, onConvertToQuotation));

    dispatch(setServiceLoaders("editServiceLoader", false));
  };
};

export const processAndUploadServiceMedia = (payload, isConvert = false) => {
  return async (dispatch, getState) => {
    const { mediasToUpload, mediaLinks } = payload;

    dispatch(setServiceLoaders("uploadServiceMediaLoader", true));

    console.log("processAndUploadMedia STARTED");
    const uIds = mediasToUpload.map((item) => v4());

    console.log("processAndUploadMedia: uIds: ", uIds);

    // INIT UPLOAD
    // const initRes = await apiServiceInitUpload.post({ "ids": uIds })
    //   .catch((error) => console.log("processAndUploadMedia: initupload error: ", error));

    // console.log("processAndUploadMedia: initRes: ", initRes);

    console.log(
      "===kkk===processServiceMediaUpload===mediasToUpload===",
      mediasToUpload
    );

    const uploadMediasPromise = mediasToUpload.map(async (media, index) => {
      console.log("processAndUploadMedia: media: ", media);

      const { mediaBlob, thumbnailBlob } = await getMediaData(media, isConvert);

      console.log("====== media.type media.type media.type=====", media.type);

      // UPLOAD
      // try {

      //   fetch(initRes.data.links[index].m, { method: 'PUT', body: mediaBlob, headers: { 'Content-Type': media.type } });
      //   fetch(initRes.data.links[index].t, { method: 'PUT', body: thumbnailBlob, headers: { 'Content-Type': 'image/png' } });
      //   fetch(initRes.data.links[index].b, { method: 'PUT', body: mediaBlob, headers: { 'Content-Type': media.type } });

      // } catch (error) {
      //   console.log("===processAndUploadMedia====apiInitUpload error: ==", error);
      //   media = null;
      // }

      return media;
    });

    let uploadSuccessMedias = [];
    uploadSuccessMedias = await Promise.all(uploadMediasPromise);
    uploadSuccessMedias = uploadSuccessMedias.filter((media) => Boolean(media));

    if (!uploadSuccessMedias.length) {
      toast.error(
        `0 of ${mediasToUpload.length} medias uploaded successfully.`
      );
      dispatch(setServiceLoaders("uploadServiceMediaLoader", false));
      return;
    }

    const newMedias = uploadSuccessMedias.map((media, index) => ({
      id: uIds[index],
      title: media.name,
      fileType: media.type,
      abstract: "",
      link: uIds[index],
      mediaType: payload.mediaType,
      ServiceId: payload.ServiceId,
    }));

    const medias = [...newMedias, ...mediaLinks].map((media) => {
      return {
        link: media.id,
        mediaType: media.mediaType,
        ServiceId: payload.ServiceId,
        title: media.title,
        fileType: media.fileType,
      };
    });

    const dataToSend = {
      ServiceId: payload.ServiceId,
      medias: medias,
    };

    console.log("processAndUploadMedia: dataToSend: ", dataToSend);

    // await apiServiceUploadMedia.post(dataToSend)
    await axios
      .get("https://jsonplaceholder.typicode.com/users", dataToSend)

      .then(async (response) => {
        if (response.status === 200) {
          const { serviceById } = getState().services;

          let newMedias = uploadSuccessMedias.map(async (media, index) => {
            const { thumbnailBase64, fileType } = await getMediaData(
              media,
              isConvert
            );

            console.log(
              "======processServiceMediaUpload===thumbnailBase64===",
              thumbnailBase64
            );

            return {
              id: uIds[index],
              title: media.name,
              fileType: fileType,
              link: thumbnailBase64,
              mediaType: payload.mediaType,
              ServiceId: payload.ServiceId,
            };
          });

          newMedias = await Promise.all(newMedias);

          console.log(
            "===kkk===processServiceMediaUpload===newMedias===",
            newMedias
          );

          if (serviceById) {
            dispatch(
              setServiceById({
                ...serviceById,
                mediaLinks: [...newMedias, ...serviceById.mediaLinks],
              })
            );
          }

          toast.success(
            `${uploadSuccessMedias.length} of ${mediasToUpload.length} medias uploaded successfully.`
          );
        } else {
          console.log("Upload Media Error: response: ", response);
          toast.error(
            "There is some issues in uploading job media, please try again!"
          );
        }
      })
      .catch((error) => {
        console.log("Upload Media Error: ", error);
        toast.error(
          "There is some issues in uploading job media, please try again!"
        );
      })
      .finally(() => {
        dispatch(setServiceLoaders("uploadServiceMediaLoader", false));
      });
  };
};

export const getServiceMediaLinks = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setServiceLoaders("getServiceMediaLoader", true));

    let dataToSend = {
      ids: payload.mediaLinks.map((image) => image.id),
      size: MediaSizeType.Thumbnail,
    };

    console.log("getMediaLinks: dataToSend: ", dataToSend);

    // await apiServiceGetMediaLink.post(dataToSend)
    await axios
      .get("https://jsonplaceholder.typicode.com/users", dataToSend)
      .then((response) => {
        if (response.status === 200) {
          let newMedias = [];
          console.log("media fetch response: ", response.data.links);
          if (response.data.links.length) {
            newMedias = response.data.links.map((link) => {
              let mediaLink = payload.mediaLinks.find(
                (mediaLink) => link.id === mediaLink.id
              );

              if (mediaLink) {
                return {
                  ...mediaLink,
                  link: link.link,
                };
              } else {
                return mediaLink;
              }
            });

            const { ServiceById } = getState().Services;

            console.log("====getMediaLinks====ServiceById=====", ServiceById);

            const updatedService = {
              ...ServiceById,
              mediaLinks: newMedias,
            };
            dispatch(setServiceById(updatedService));

            console.log(
              "====getMediaLinks====updatedService=====",
              updatedService
            );

            console.log("====getMediaLinks====newMedias=====", newMedias);
          }
        } else {
          console.log("Media Load Error: response: ", response);
        }
      })
      .catch((error) => {
        console.log("Media Load Error: ", error);
      })
      .finally(() => {
        dispatch(setServiceLoaders("getServiceMediaLoader", false));
      });
  };
};

export const deleteServiceMedia = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setServiceLoaders("deleteServiceMediaLoader", true));

    const docToDeleteId = payload.id;
    const newList = payload.mediaLinks.filter(
      (image) => image.id !== docToDeleteId
    );

    const medias = newList.map((doc) => {
      return {
        link: doc.id,
        ServiceId: payload.ServiceId,
        mediaType: doc.mediaType,
      };
    });

    const dataToSend = {
      ServiceId: payload.ServiceId,
      medias: medias,
    };

    console.log("DELETE MEDIA", dataToSend);
    console.log(docToDeleteId);

    // await apiServiceUploadMedia.post(dataToSend)
    await axios
      .get("https://jsonplaceholder.typicode.com/users", dataToSend)
      .then((response) => {
        if (response.status === 200) {
          console.log("delete media response: ", response);

          const { serviceById } = getState().services;

          const updatedService = {
            ...serviceById,
            mediaLinks: newList,
          };

          dispatch(setServiceById(updatedService));

          toast.success("Media deleted successfully!!!");
        } else {
          console.log("Delete media Error: ", response);
          toast.error("There was an issue with deleting media!!!");
        }
      })
      .catch((error) => {
        console.log("Delete media Error: ", error);
        toast.error("There was an issue with deleting media!!!");
      })
      .finally(() => {
        dispatch(setServiceLoaders("deleteServiceMediaLoader", false));
      });
  };
};

export const getResolvedServiceMediasByIds = (
  payload,
  onResolveMediasByIds,
  openMedia = true,
  returnAll = false
) => {
  return async (dispatch, getState) => {
    const { clickedMedia = {}, allMedias = [] } = payload;

    const ids = returnAll
      ? allMedias.map((media) => media.id)
      : clickedMedia
      ? [clickedMedia.id]
      : [];

    const dataToSend = {
      ids: ids,
      size: MediaSizeType.BigScreen,
    };

    dispatch(setServiceLoaders("getServiceMediaLoader", true));

    // await axios.get('https://jsonplaceholder.typicode.com/users', dataToSend)
    await axios
      .post("v1/post/getmedialink", dataToSend)

      // await apiServiceGetMediaLink.post(dataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          const mediaLinks = response.data.links;

          if (mediaLinks.length) {
            let allResolvedMedias = [];
            if (returnAll) {
              allResolvedMedias = mediaLinks.map(async (media, index) => {
                let fileType = null;
                let contentType = null;
                try {
                  const resp = await getContentType(media.link);
                  fileType = resp.fileType;
                  contentType = resp.contentType;
                } catch (error) {
                  toast.error(
                    "Media not found. Please delete and upload the media again."
                  );
                  return;
                }
                const isImage = fileType === FileType.Image;

                return {
                  ...media,
                  uri: media.link,
                  thumbnailUri: allMedias[index].link,
                  fileType: fileType,
                  type: contentType,
                  isResolved: true,
                  isImage,
                };
              });

              allResolvedMedias = await Promise.all(allResolvedMedias);
              allResolvedMedias = allResolvedMedias.filter(Boolean);

              onResolveMediasByIds(null, allResolvedMedias, false);
              dispatch(setResolvedServiceMedias(allResolvedMedias));
              return;
            }

            const media = mediaLinks.find(
              (_media) => _media.id === clickedMedia.id
            );

            let fileType = null;
            let contentType = null;
            try {
              const resp = await getContentType(media.link);
              fileType = resp.fileType;
              contentType = resp.contentType;
            } catch (error) {
              toast.error(
                "Media not found. Please delete and upload the media again."
              );
              return;
            }

            const isImage = fileType === FileType.Image;
            let resolvedMedia = {
              ...media,
              type: contentType,
              uri: media.link,
              thumbnailUri: clickedMedia.link,
              fileType,
              isResolved: true,
              isImage,
            };

            if (!isImage && openMedia) {
              window.open(resolvedMedia.uri, "_blank");
            }

            allResolvedMedias = mediaLinks.map((media, index) => {
              if (media.id === resolvedMedia.id) {
                return resolvedMedia;
              } else {
                return {
                  ...media,
                  uri: media.link,
                  thumbnailUri: allMedias[index].link,
                  fileType: FileType.Image,
                  isResolved: false,
                  isImage: true,
                };
              }
            });

            allResolvedMedias = await Promise.all(allResolvedMedias);
            allResolvedMedias = allResolvedMedias.filter(Boolean);

            if (onResolveMediasByIds) {
              const isOpeningMedia = openMedia && !isImage;

              onResolveMediasByIds(
                resolvedMedia,
                allResolvedMedias,
                isOpeningMedia
              );
              dispatch(setResolvedServiceMedias(allResolvedMedias));
            }
          }
        }
      })
      .catch((error) => {
        console.error("Media Load Error: ", error);
        toast.error("There is some issue in opening media.");
      })
      .finally(() => {
        dispatch(setServiceLoaders("getServiceMediaLoader", false));
      });
  };
};
