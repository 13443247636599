import { makeStyles, Step, StepLabel, Stepper } from '@material-ui/core';
import React from 'react';

const AddEventFBSteps = ({ activeStep, steps }) => {

  const styles = useStyles();

  return (
    <div>
      <Stepper activeStep={activeStep} alternativeLabel className={styles.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

export default AddEventFBSteps;

const useStyles = makeStyles(() => ({
  stepper: {
    background: 'transparent'
  }
}))