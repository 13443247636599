import { Box } from '@material-ui/core'
import React, { useRef } from 'react'
import { DialogCNO } from '../../../library'
import { useSelector } from 'react-redux';
import { addLevelToComments } from '../helpers';
import CommentListItem from './CommentListItem';
import AddCommentInput from './AddCommentInput';

const PostCommentsDetailDialog = ({ post, open, onClose }) => {

  const { postComments, postCommentsLoader } = useSelector(state => state.posts);

  const flattenedComments = addLevelToComments(postComments)

  const inputRef = useRef();

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Post Comments"
      loading={postCommentsLoader}
    >
      <AddCommentInput post={post} ref={inputRef} />
      <Box>
        {
          flattenedComments.map(comment => <CommentListItem comment={comment} ref={inputRef} />)
        }
      </Box>
    </DialogCNO>
  )
}

export default PostCommentsDetailDialog