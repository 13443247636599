const darkColors = {
  transparent: 'transparent',
  labelColor: '#767676',
  inactiveField: '#f2f2f2',
  inactiveButton: '#b7dbdd',
  inactiveIcon: '#EBEBEB',
  primaryHover: '#F29E02',
  secondary: '#ff5b60',
  secondaryHover: '#FF282F',
  yellow: '#fdb32a',
  yellowHover: '#F29E02',
  borderColor: '#dadada',
  black: '#000000',
  white: '#ffffff',
  primary: '#ff8000',
  headingColor: '#fefefe',
  quoteText: '#343d48',
  textColor: '#dedede',
  linkColor: '#2b9eff',
  backgroundColor: '#1e1e1e',
  featureRippleColor: 'rgba(255, 255, 255, 0.5)',
  boxShadowColor: 'rgba(255, 255, 255, 0.75)',
  footerBackgroundColor: '#00000087',
  footerHeadingColor: '#cecece',
  footerTextColor: '#dedede',
  navbarShadow: 'rgba(255, 255, 255, 0.1)',
  blueColor: '#436a93',
  heroSquareShapeColor: '#c68a4d',
  heroCircleShapeColor: '#6d9468',
  pricingTableBorder: '#4e4e4e',
  pricingTableShadow: 'rgba(255, 255, 255, 0.2)',
  exploreLabelShadow: "#81858969"
};

export default darkColors;
