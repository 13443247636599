import { Divider, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react'
import { MetaText, TabPanel } from '../../../../library';
import { useGlobalStyles } from '../../../../utils/Styles/GlobalStyles'
import EventResponseActions from '../EventResponseActions';
import EventShareAction from '../EventShareAction';
import EventAboutDetailsCard from './EventAboutDetailsCard';
import EventAttendiesDetailsCard from './EventAttendiesDetailsCard';
import EventDetailsContainer from './EventDetailsContainer';
import EventDetailsTabs from './EventDetailsTabs';
import EventHeaderDetailsFB from './EventHeaderDetailsFB';
import EventHostsDetailsCard from './EventHostsDetailsCard';
import EventDiscussionsDetailsCard from './EventDiscussionsDetailsCard';

const EventDetailsFB = ({ event }) => {

  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const [tabValue, setTabValue] = useState(0);

  return (
    <div>

      <EventHeaderDetailsFB event={event} />

      <EventDetailsContainer className={styles.container}>
        <Divider />
        <div className={styles.tabDetailsContainer}>
          <EventDetailsTabs tabValue={tabValue} setTabValue={setTabValue} />
          <div className={styles.responseActionsContainer}>
            <EventResponseActions event={event} />
            <EventShareAction event={event} />
          </div>
        </div>
      </EventDetailsContainer>

      {/* tab content */}
      <EventDetailsContainer>
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2} style={{ padding: '16px 0' }}>

            <Grid item lg={7}>
              <EventAboutDetailsCard event={event} />
            </Grid>

            <Grid item lg={5}>
              <EventAttendiesDetailsCard event={event} />
            </Grid>

            <Grid item lg={7}>
              <EventHostsDetailsCard event={event} />
            </Grid>

          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <EventDiscussionsDetailsCard event={event} />
        </TabPanel>
      </EventDetailsContainer>

    </div>
  )
}

export default EventDetailsFB;

const useStyles = makeStyles((theme) => ({

  container: {
    background: 'rgba(32,33,33,1)',
  },

  tabDetailsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: 'rgba(32,33,33,1)'
  },

  responseActionsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },

}));