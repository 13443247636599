import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ScreenView from "../../components/ScreenView";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { getCompanyById } from "../../actions/customerCompanyActions";
import CompanyDetails from "../../components/CompanyDetails";

function CompanyDetailsPage() {
  const styles = useStyles();

  const dispatch = useDispatch();
  const { companyById, allCompaniesLoader } = useSelector(
    (state) => state.companies
  );
  const { companyDetailLoader, editLoader } = allCompaniesLoader;

  const { id } = useParams();

  useEffect(() => {
    const companyId = [id];

    dispatch(getCompanyById(companyId));
  }, [id]);

  return (
    <div className={styles.container_CompanyDetailsPage}>
      {(companyDetailLoader || editLoader) && <FullScreenLoaderCNO />}
      <ScreenView
        content={companyById && <CompanyDetails profile={companyById} />}
        headerTitle="Company Detail"
      />
    </div>
  );
}

export default CompanyDetailsPage;

const useStyles = makeStyles((theme) => ({
  container_CompanyDetailsPage: {
    background: theme.colors.APP_BACKGROUND_COLOR,
  },
}));
