import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { mdiClockEnd, mdiClockStart } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import Moment from 'react-moment';
import { calendarStrings, TIME_FORMAT } from '../../utils/formatDate';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { ServiceStatus } from '../../utils/mappings';
import RightsManager from '../../utils/RightsManager';
import EditServiceTimelineDialog from './EditServiceTimelineDialog';
import { formatTimeWithTZ } from '../../utils/Helpers';

function ServiceTimeDetailsCard({ service }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isCompanyAdmin = RightsManager.isGroupLeader();

  const [isTimelineEditable, setIsTimelineEditable] = useState(false);

  return (
    <div className={GlobalStyles.detailsCardContainer}>
      <Typography className={GlobalStyles.detailsCardSectionTitle}>
        Start Date & End Date
      </Typography>
      <div className={GlobalStyles.detailsCardSection} onClick={() => setIsTimelineEditable(true)}>
        <div className={GlobalStyles.detailsRow}>
          <Typography style={{ color: theme.colors.JOB_START_DATE_COLOR }}>
            <Moment interval={0} calendar={calendarStrings} >
              {formatTimeWithTZ(service.startDate)}
            </Moment>
            <Moment interval={0} format={TIME_FORMAT}>
              {formatTimeWithTZ(service.startTime)}
            </Moment>
          </Typography>
          <Icon
            path={mdiClockStart}
            size='2rem'
            color={theme.colors.JOB_START_DATE_COLOR}
          />
        </div>
        {
          // isUserAdmin &&
          <div className={GlobalStyles.detailsRow}>
            <Typography style={{ color: theme.colors.JOB_END_DATE_COLOR }}>
              <Moment interval={0} calendar={calendarStrings} >
                {formatTimeWithTZ(service.endDate)}
              </Moment>
              <Moment interval={0} format={TIME_FORMAT}>
                {formatTimeWithTZ(service.endTime)}
              </Moment>
            </Typography>
            <Icon
              path={mdiClockEnd}
              size='2rem'
              color={theme.colors.JOB_END_DATE_COLOR}
            />
          </div>
        }
      </div>

      {
        isCompanyAdmin &&
        isTimelineEditable &&
        <EditServiceTimelineDialog
          open={isTimelineEditable}
          onClose={() => setIsTimelineEditable(false)}
          service={service}
        />
      }

    </div>
  )
}

export default ServiceTimeDetailsCard;

const useStyles = makeStyles((theme) => ({
  start: {
    '& .MuiSvgIcon-root': {
      color: theme.colors.JOB_START_DATE_COLOR
    }
  },
  end: {
    '& .MuiSvgIcon-root': {
      color: theme.colors.JOB_END_DATE_COLOR
    }
  },
}));
