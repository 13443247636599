import { FieldArray } from 'formik'
import React from 'react'
import { getNestedObjectValue } from '../../../utils/Helpers';
import AddFieldButton from './AddFieldButton';
import { AddressRender, AutoCompleteFormRender, CheckBoxRender, ChipsGroupFormRender, DateTimePickerRender, InputFormRender, SelectFormRender, SwitchRender } from './component';
import { Spacer } from '../../../library';

function FormFieldsArray({ sectionIndex, title, formik }) {

  const sectionAccessorKey = `sections.${sectionIndex}`;
  const sectionFieldsAccessorKey = `${sectionAccessorKey}.fields`;

  return (
    <FieldArray name={sectionFieldsAccessorKey}>
      {
        function FormFieldItem(fieldArray) {
          const handleAddField = (values) => {
            fieldArray.push(values);
          }

          return (
            <>
              {
                getNestedObjectValue(formik.values, sectionFieldsAccessorKey, []).map((field, index) => {

                  const fieldAccessorKey = `${sectionFieldsAccessorKey}.${index}`;
                  const fieldAccessorValue = getNestedObjectValue(formik.values, fieldAccessorKey);

                  const handleEdit = (values) => fieldArray.replace(index, values);

                  const handleDelete = () => fieldArray.remove(index);

                  return (
                    <Spacer>

                      <InputFormRender
                        field={fieldAccessorValue}
                        formik={formik}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        showFormFieldActions
                      />

                      <ChipsGroupFormRender
                        field={fieldAccessorValue}
                        formik={formik}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        showFormFieldActions
                      />

                      <SelectFormRender
                        field={fieldAccessorValue}
                        formik={formik}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        showFormFieldActions
                      />

                      <AutoCompleteFormRender
                        field={fieldAccessorValue}
                        formik={formik}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        showFormFieldActions
                      />

                      <DateTimePickerRender
                        field={fieldAccessorValue}
                        formik={formik}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        showFormFieldActions
                      />

                      <AddressRender
                        field={fieldAccessorValue}
                        formik={formik}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        showFormFieldActions
                      />

                      <SwitchRender
                        field={fieldAccessorValue}
                        formik={formik}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        showFormFieldActions
                      />

                      <CheckBoxRender
                        field={fieldAccessorValue}
                        formik={formik}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        showFormFieldActions
                      />

                    </Spacer>
                  )
                })
              }

              <AddFieldButton onAddField={handleAddField} />

            </>
          )

        }
      }
    </FieldArray>
  )
}

export default FormFieldsArray;

