import { HubConnectionBuilder } from "@microsoft/signalr";

export const SET_CHAT_CONNECTION = "SET_CHAT_CONNECTION";
export const SET_NOTIFICATION_CONNECTION = "SET_NOTIFICATION_CONNECTION";

const authUser = JSON.parse(localStorage.getItem("authUser"));

const BASE_URL = "https://mefolia.com:9000";
// const BASE_URL = 'https://int.checkndout.com:8000';
//const BASE_URL = 'http://adb7f4aebbb934388bb66cd413657c78-639364869.eu-west-2.elb.amazonaws.com';
const HUB_TOKEN = authUser ? authUser.token : null;

export function setChatConnection() {
  return async (dispatch) => {
    const connectionHub = new HubConnectionBuilder()
      .withUrl(`${BASE_URL}/messagehub?access_token=${HUB_TOKEN}`)
      .withAutomaticReconnect()
      .build();

    //await connectionHub.start(); //TODO:Check why it is reconnecting in loop

    dispatch({
      type: SET_CHAT_CONNECTION,
      payload: connectionHub,
    });
  };
}

export function setNotificationConnection() {
  return async (dispatch) => {
    const connectionHub = new HubConnectionBuilder()
      .withUrl(`${BASE_URL}/notificationhub?access_token=${HUB_TOKEN}`)
      .withAutomaticReconnect()
      .build();

    await connectionHub.start();

    dispatch({
      type: SET_NOTIFICATION_CONNECTION,
      payload: connectionHub,
    });
  };
}
