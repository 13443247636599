import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import {
  apiQuotationItemAssign,
  apiQuotationItemCreate,
  apiQuotationItemEdit,
  apiQuotationItemRemove,
  apiQuotationItemSearch
} from '../api/quotationItem';
import { getHoursFromTimeline, parseDisplayNumber, parseNumber, sortArrayBasedOtherArray } from '../utils/Helpers';
import { RateType, TrackProximityRadius } from '../utils/mappings';
import {
  setQuotationById,
  updateQuotation
} from './quotationActions';
import LocalStorageConstants from '../utils/LocalStorageConstants';
import { AppConstants } from '../utils/AppConstants';
import { getTotalAmountValues } from '../utils/InvoiceHelpers';

export const SET_QUOTATION_ITEMS = "SET_QUOTATION_ITEMS";
export const SET_QUOTATION_ITEM_BY_ID = "SET_QUOTATION_ITEM_BY_ID";
export const SET_QUOTATION_ITEM_LOADERS = "SET_QUOTATION_ITEM_LOADERS";
export const TOGGLE_ADD_QUOTATION_ITEM_DIALOG = "TOGGLE_ADD_QUOTATION_ITEM_DIALOG";
export const SET_IS_NEW_QUOTATION_ITEM_ADDED = "SET_IS_NEW_QUOTATION_ITEM_ADDED";

export function setQuotationItems(payload) {
  return (dispatch) => {

    let sortedItems = payload.sort((first, second) => first.itemOrder < second.itemOrder ? -1 : 1);

    dispatch({
      type: SET_QUOTATION_ITEMS,
      payload: sortedItems
    });

  };
}

export function setIsNewQuotationItemAdd(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_NEW_QUOTATION_ITEM_ADDED,
      payload: payload
    });
  };
}

export function setQuotationItemById(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_QUOTATION_ITEM_BY_ID,
      payload: payload
    });
  };
}

export function setQuotationItemLoader(loaderName, loaderValue) {
  return (dispatch) => {

    dispatch({
      type: SET_QUOTATION_ITEM_LOADERS,
      payload: { loaderName, loaderValue }
    });
  };
}

export function toggleAddQuotationItemDialog(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_QUOTATION_ITEM_DIALOG,
      payload: status
    });
  };
}

export function fetchQuotationItems(payload) {
  return async (dispatch) => {

    console.log("QuotationItems: dispatched");

    dispatch(setQuotationItemLoader('quotationItemsLoader', true));

    const dataToSend = {
      pageSize: 100,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      startDate: null,
      endDate: null,
      assignedIds: [],
      categories: [],
      searchText: "",
      ids: [],
      offerId: "",
      ...payload
    };

    await apiQuotationItemSearch.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          // const quotationItems = [];
          let quotationItemsResponse = response.data.data;

          quotationItemsResponse = quotationItemsResponse.map((quotationItem, index) => {

            const hours = getHoursFromTimeline(quotationItem.startDate, quotationItem.endDate);
            const amount = quotationItem.rateType === RateType.Hourly
              ? hours * quotationItem.rate
              : quotationItem.rate;

            return ({
              ...quotationItem,
              hours,
              amount: parseNumber(amount, 'float'),
              itemOrder: quotationItem.itemOrder ? quotationItem.itemOrder : parseFloat(AppConstants.SORTING_MULTIPLIER * (index + 1))
            });

          });

          dispatch(setQuotationItems(quotationItemsResponse));

        } else {
          console.error("Error: Fetch QuotationItem:");
        }

      })
      .catch((error) => {
        console.error("Error: Fetch QuotationItem: ", error);
      })
      .finally(() => {
        dispatch(setQuotationItemLoader('quotationItemsLoader', false));
      });

  };
}

export function getQuotationItemById(payload) {
  return async (dispatch) => {

    dispatch(setQuotationItemLoader('quotationItemDetailLoader', true));

    const dataToSend = {
      "pageSize": 100,
      "pageIndex": 0,
      "orderBy": "newest",
      "sortAscending": false,
      "startDate": null,
      "endDate": null,
      "assignedIds": [],
      "categories": [],
      "searchText": "",
      "ids": [],
      "offerId": "",
      ...payload
    };

    await apiQuotationItemSearch.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          let quotationItemsResponse = response.data.data;

          quotationItemsResponse = quotationItemsResponse.map((quotationItem, index) => {

            const hours = getHoursFromTimeline(quotationItem.startDate, quotationItem.endDate);
            const amount = quotationItem.rateType === RateType.Hourly ? hours * quotationItem.rate
              : quotationItem.rate;

            return ({
              ...quotationItem,
              hours,
              amount: parseNumber(amount, 'float'),
              itemOrder: quotationItem.itemOrder ? quotationItem.itemOrder : parseFloat(AppConstants.SORTING_MULTIPLIER * (index + 1))
            });

          });

          dispatch(setQuotationItemById(quotationItemsResponse[0]));

        } else {
          console.error("Error: Fetch Quotation Item Details:");
        }

      })
      .catch((error) => {
        console.error("Error: Fetch Quotation Item Details: ", error);
      })
      .finally(() => {
        dispatch(setQuotationItemLoader('quotationItemDetailLoader', false));
      });


  };
}

export const addMultipleQuotationItems = (itemsToAdd, quotation, onAddSuccess) => {
  return async (dispatch, getState) => {

    dispatch(setQuotationItemLoader('addLoader', true));

    const { quotationItems } = getState().quotationItems;

    const maxOrder = quotationItems.length ? Math.max(...(quotationItems.map(item => item.itemOrder))) : AppConstants.SORTING_MULTIPLIER;

    const itemsToAddPromises = itemsToAdd.map(async (item, index) => {

      const payload = {
        title: item.title,
        description: item.description,
        categories: item.categories,
        status: item.status,
        startDate: item.startDate,
        endDate: item.endDate,
        startTime: item.startTime,
        endTime: item.endTime,
        offerItemAssignments: item.offerItemAssignments,
        hours: item.hours,
        rate: item.rate,
        amount: item.amount,
        rateType: item.rateType,
        target: item.target,
        targetType: item.targetType,
        offerId: quotation.id,
        itemOrder: maxOrder + (index + 1) * AppConstants.SORTING_MULTIPLIER
      };

      const itemPromiseResponse = await apiQuotationItemCreate.post(payload)
        .then(async (response) => {

          if (response && response.status === 200) {

            const quotationItemId = response.data;

            const hours = getHoursFromTimeline(payload.startDate, payload.endDate);
            const amount = payload.rateType === RateType.Hourly
              ? hours * payload.rate
              : payload.rate;

            const newQuotationItem = {
              ...payload,
              id: quotationItemId,
              hours,
              amount: parseDisplayNumber(amount, 'float')
            };

            return newQuotationItem;

          } else {
            return null;
          }

        });

      return itemPromiseResponse;

    });

    let itemsAdded = await Promise.all(itemsToAddPromises);
    itemsAdded = itemsAdded.filter(item => item);

    dispatch(setQuotationItems(itemsAdded));

    const { quotationById } = getState().quotations;

    // sync oneOfferItem*** with quotations
    if (quotationById.id === quotation.id) {
      const firstItem = itemsAdded[0];
      const updatedTodo = {
        ...quotation,
        oneOfferItemId: firstItem.id,
        oneOfferItemTitle: firstItem.title,
        oneOfferItemCategories: firstItem.categories[0],
      };
      dispatch(setQuotationById(updatedTodo));
    }

    dispatch(setQuotationItemLoader('addLoader', false));

  };
};

export function addQuotationItem(payload, isFromServer = false, onAddSuccess, itemOrder = AppConstants.SORTING_MULTIPLIER) {
  return async (dispatch, getState) => {

    dispatch(setQuotationItemLoader('addLoader', true));

    let { quotationItems } = getState().quotationItems;

    itemOrder = quotationItems.length ? Math.max(...(quotationItems.map(item => item.itemOrder))) + AppConstants.SORTING_MULTIPLIER : itemOrder;

    payload = { ...payload, itemOrder: itemOrder };

    const dataToSend = {
      title: "",
      description: "",
      categories: [],
      status: 0,
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      offerItemAssignments: [],
      rate: 0,
      rateType: 0,
      target: 0,
      targetType: 0,
      offerId: "",
      itemOrder: AppConstants.SORTING_MULTIPLIER,
      ...payload
    };

    await apiQuotationItemCreate.post(dataToSend)
      .then(async (response) => {

        if (response && response.status === 200) {


          const quotationItemId = response.data;

          const hours = getHoursFromTimeline(payload.startDate, payload.endDate);
          const amount = payload.rateType === RateType.Hourly ? hours * payload.rate
            : payload.rate;

          const newQuotationItem = {
            ...payload,
            id: quotationItemId,
            hours,
            amount: parseNumber(amount, 'float')
          };

          quotationItems = getState().quotationItems.quotationItems;

          const quotationItemsTemp = [newQuotationItem, ...quotationItems];

          // sync oneOffetItem*** with quotations
          const { quotationById } = getState().quotations;

          if (quotationById.id === payload.offerId && !quotationItems.length) {

            const updatedQuotation = {
              ...quotationById,
              oneOfferItemId: quotationItemId,
              oneOfferItemTitle: payload.title,
              oneOfferItemCategories: payload.categories,
            };

            dispatch(setQuotationById(updatedQuotation));

          }

          dispatch(setQuotationItems(quotationItemsTemp));

          dispatch(setIsNewQuotationItemAdd(true));

          if (onAddSuccess) onAddSuccess();

          !isFromServer && toast.success("Quotation Item created successfully.");

        } else {
          console.error("Error: Add Quotation Item: ", response);
          !isFromServer && toast.error("Error in creating Quotation Item.");
        }

      })
      .catch((error) => {
        console.error("Error: Add Quotation Item: ", error);
        !isFromServer && toast.error("Error in creating Quotation Item.");
      })
      .finally(() => {
        dispatch(setQuotationItemLoader('addLoader', false));
      });

  };
}

export function updateQuotationItem(payload, shouldUpdateOneOfferItem, onAddSuccess) {
  return async (dispatch, getState) => {

    dispatch(setQuotationItemLoader('editLoader', true));

    const dataToSend = {
      id: "",
      title: "",
      description: "",
      categories: [],
      status: 2,
      customerId: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      paidAmount: 0,
      rate: 0,
      rateType: 2,
      paymentByCustomerStatus: 2,
      proximity: TrackProximityRadius,
      visibility: 1,
      itemOrder: AppConstants.SORTING_MULTIPLIER,
      ...payload
    };

    await apiQuotationItemEdit.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          const { quotationItems } = getState().quotationItems;

          const hours = getHoursFromTimeline(payload.startDate, payload.endDate);
          const amount = payload.rateType === RateType.Hourly
            ? hours * payload.rate
            : payload.rate;

          const newQuotationItem = {
            ...payload,
            hours,
            amount: parseNumber(amount, 'float')
          };

          const quotationItemsTemp = quotationItems.map((item) => item.id === payload.id ? newQuotationItem : item);

          dispatch(setQuotationItems(quotationItemsTemp));

          if (shouldUpdateOneOfferItem) {

            const { quotationById } = getState().quotations;

            if (quotationById.oneOfferItemId === payload.id) {

              const targetQuotation = {
                ...quotationById,
                oneOfferItemId: payload.id,
                oneOfferItemCategories: payload.categories,
                oneOfferItemTitle: payload.title
              };
              dispatch(setQuotationById(targetQuotation));

            }
          }

          if (onAddSuccess) onAddSuccess();

          toast.success("Quotation Item edited successfully.");

        } else {
          console.error("Error: Edit Quotation Item:");
          toast.error("Error in editing quotation item.");
        }

      })
      .catch((error) => {
        console.error("Error: Edit Quotation Item: ", error);
        toast.error("Error in editing quotation item.");
      })
      .finally(() => {
        dispatch(setQuotationItemLoader('editLoader', false));
      });


  };
}

export function deleteQuotationItem(payload) {
  return async (dispatch, getState) => {

    dispatch(setQuotationItemLoader('deleteLoader', true));

    const quotationItemId = payload.id;

    const dataToSend = {
      "id": quotationItemId,
      "undo": true,
    };

    await apiQuotationItemRemove.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          const { quotationItems } = getState().quotationItems;

          const quotationItemsTemp = quotationItems.filter((quotationItem) => quotationItem.id !== quotationItemId);

          dispatch(setQuotationItems(quotationItemsTemp));

          const { quotationById } = getState().quotations;

          if (quotationById.oneOfferItemId === quotationItemId) {

            let targetQuotation = quotationById;

            if (quotationItems.length === 1) {

              const paymentValues = getTotalAmountValues(0, targetQuotation.discount, targetQuotation.taxRate, targetQuotation.includeTax);

              targetQuotation = {
                ...targetQuotation,
                ...paymentValues,
                oneOfferItemId: null,
                oneOfferItemCategories: [],
                oneOfferItemTitle: null
              };

              dispatch(updateQuotation(targetQuotation));

            } else {
              const nextOneOfferItem = quotationItems[quotationItems.length - 2];
              targetQuotation = {
                ...targetQuotation,
                oneOfferItemId: nextOneOfferItem.id,
                oneOfferItemCategories: nextOneOfferItem.categories,
                oneOfferItemTitle: nextOneOfferItem.title
              };
            }

            dispatch(setQuotationById(targetQuotation));

          }

          toast.success("Quotation Item deleted successfully.");

        } else {
          console.error("Error: Delete Quotation Item");
          toast.error("Error in deleting Quotation Item.");
        }

      })
      .catch((error) => {
        console.error("Error: Delete Quotation Item: ", error);
        toast.error("Error in deleting Quotation Item.");
      })
      .finally(() => {
        dispatch(setQuotationItemLoader('deleteLoader', false));
      });

  };
}

export function assignQuotationItem(payload = []) {
  return async (dispatch, getState) => {

    dispatch(setQuotationItemLoader('editLoader', true));

    const dataToSend = payload.offerItemAssignments;

    await apiQuotationItemAssign.post(dataToSend)
      .then((response) => {

        if (response && response.status === 200) {

          const { quotationItems } = getState().quotationItems;

          const quotationItemsTemp = quotationItems.map((item) => item.id === payload.id ? payload : item);

          dispatch(setQuotationItems(quotationItemsTemp));

          toast.success("Quotation Item assigned successfully.");

        } else {
          console.error("Error: Assign Quotation Item:");
          toast.error("Error in quotation item assignment.");
        }

      })
      .catch((error) => {
        console.error("Error: Assign Quotation Item: ", error);
        toast.error("Error in quotation item assignment.");
      })
      .finally(() => {
        dispatch(setQuotationItemLoader('editLoader', false));
      });
  };
}

