

import React, { useState } from 'react';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import './index.css'

const data=[
  {
  url: "https://cdn.pixabay.com/photo/2022/09/20/22/58/sand-7468945_1280.jpg",
  type: "photo",
  altTag: "some image",
  },
  {
  url: "https://www.youtube.com/embed/csqBMqvW17Y?autoplay=1&mute=1",
  type: "video",
  title: "some video",
  },
  {
  url: "https://cdn.pixabay.com/photo/2017/02/21/16/30/mexico-2086549_1280.jpg",
  type: "photo",
  altTag: "some other image",
  },
  {
  url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
  type: "video",
  title: "some other video",
  }
];

const Lightbox = ({images,isOpen,currImg,onClose,onClickPrev,onClickNext}) => {
  // const [currentIndex, setCurrentIndex] = useState(currImg);
  console.log("currImg=",currImg);
  // console.log("currIndex=",currentIndex);

  // console.log("imagesBefore->",images);

  const handleNavigation = (newIndex) => {
    if(newIndex == currImg+1) onClickNext();
    else onClickPrev();
    // setCurrentIndex(newIndex);
    // console.log(`Current index: ${newIndex}`);
  };

  const newMediaLinks=images.map((media)=>(
    {
    url: media.link,
    type: `${media.filetype == 4? 'video' : 'photo'}`,
    title: `${media.filetype == 4? 'some other video' : 'some other photo'}`,
    }

  ))

  

  return (
    <div>
      {
        isOpen && 
        <div style={{
          top: '0px',
          left: '0px',
          overflow: 'hidden',
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(0,0,0,1)',
          zIndex: 10000
        }}
         >
          
          
            <ReactImageVideoLightbox
              data={newMediaLinks}
              startIndex={currImg}
              showResourceCount={true}
              onCloseCallback={onClose}
              onNavigationCallback={handleNavigation}
              className=".lightbox"
            />
          
        </div>
      }
    </div>
    
  );
}

export default Lightbox;

