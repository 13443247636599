import { parseDisplayNumber } from "../../../utils/Helpers";
import { CurrencyHTMLEntities, Currency } from "../../../utils/mappings";
import LocalStorageConstants from "../../../utils/LocalStorageConstants";
import { generateId } from "../../../utils/IdGeneratorHelpers";

export const generateSubscriptionReceiptTemplate = (company, subscription) => {

  const currencyType = LocalStorageConstants.getItem('currencyType', Currency.GBP);
  const authUserCompany = LocalStorageConstants.getItem('authUserCompany');

  let customer = LocalStorageConstants.getItem('authUser');

  console.log("========generateSubscriptionReceiptTemplate====customer====", customer);
  customer = { ...customer, title: `${customer.firstName} ${customer.lastName}` };

  console.log("========generateSubscriptionReceiptTemplate====customer======2222======", customer);

  const receiptNumber = generateId('subscription', customer.title);

  const itemsString = `
                <div style="display: flex; padding: 16px; font-size: 14px;">
                    <div style="flex: 1;">
                        <div style="color: #4e4e4e;">${subscription.title}</div>
                        <pre style=" white-space: pre-line; margin: 0; margin-top: 10px; padding-left: 16px; font-family: Arial, Helvetica, sans-serif; font-size: 12px; color: #8f8f8f; ">
                            per month
                        </pre>
                        <pre style=" white-space: pre-line; margin: 0; margin-top: 10px; padding-left: 16px; font-family: Arial, Helvetica, sans-serif; font-size: 12px; color: #8f8f8f; ">
                            No. of users: ${subscription.noOfUsers}
                        </pre>
                    </div>
                    <div style="flex: 0.2; text-align: right ">${parseDisplayNumber(subscription.amount)}</div>
                </div>`;


  const htmlString = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </head>
  <div class="header" style="width: 800px; box-sizing: border-box; padding: 0px 16px; margin: 0; font-family: Arial, Helvetica, sans-serif; -webkit-print-color-adjust: exact;">

    <!-- header -->
    <header style="display: flex; flex-direction: column; align-items: center;">
        <div style="display: flex; width: 100%; align-items: center; padding-left: 8px; padding-bottom: 16px;  border-bottom: 3px solid #9e9e9e;">
            <img src=${company.profilePic} alt="" style="display: block; border-radius: 50%; width: 80px; height: 80px;">
            <h1 style="color: #5e5e5e; font-weight: 500; font-size: 34px; margin-left: 16px; opacity: 0.3;">
            ${company.title}
            </h1>
        </div>

        <div style="display: inline-block; background: #dedede70; color: #7e7e7e; padding: 5px 20px; margin: 16px auto; border-radius: 5px; font-weight: bold; font-size: 16px;">Subscription Receipt</div>
    </header>


    <div>

        <div>
            <!-- left detail -->
            <div style="width: 300px; float: left; margin-top: 8px;">
                <div style="background: #dedede70; color: #5e5e5e; font-size: 18px; padding: 10px 8px; border: 2px solid #bebebe8f; border-top-right-radius: 8px; border-top-left-radius: 8px;">
                    ${customer.title}
                </div>
                <div style="color: #606060; background: transparent; padding: 8px; border: 2px solid #e6e6e6; border-top: 0px; border-bottom-right-radius: 8px; border-bottom-left-radius: 8px;">
                    <div style="margin-bottom: 12px;">
                        <div>${customer.email || ""}</div>
                        <div>${customer.mobile || ""}</div>
                    </div>
                    <div>
                        <div>${customer.address || customer.mapAddress || ""}</div>
                    </div>
                </div>
            </div>

            <!-- right details -->
            <div style="float: right; width: 300px;">

                <div style="box-sizing: border-box; float: left; width: 100%; font-size: 14px; vertical-align: middle; padding: 5px; background: #dedede70; border-radius: 8px; border: 2px solid #bebebe8f; margin-top: 8px;">
                    <div style="color: #4e4e4e; float: left; padding: 5px;">Subscription No.</div>
                    <div style="width: 140px; float: right; background: #fefefe; color: #3e3e3e; padding: 5px;  border-radius: 6px; text-align: center;">
                    ${receiptNumber}
                    </div>
                </div>

                <div style="box-sizing: border-box; float: left; width: 100%; font-size: 14px; padding: 5px; background: #dedede70; border-radius: 8px; border: 2px solid #bebebe8f; margin-top: 8px;">
                    <div style="color: #4e4e4e; float: left; padding: 5px;">Subscription Date</div>
                    <div style="width: 140px; float: right; background: #fefefe; color: #3e3e3e; padding: 5px;  border-radius: 6px; text-align: center;">
                    ${subscription.billDate}
                    </div>
                </div>

            </div>

        </div>

        <div style="clear: both; height: 40px;"></div>

        <!-- amount details -->
        <div style="clear: both;">

            <div>
                <div style="display: flex; background: #eaeaea; color: #5e5e5e; margin-top: 16px; border: 2px solid #d9d9d9; border-radius: 6px; padding: 8px 12px">
                    <div style="flex: 1; font-weight: 700; font-size: 14px;">Type</div>
                    <div style="flex: 0.2; text-align: right; font-weight: 700; font-size: 14px;">Amount</div>
                </div>

                ${itemsString}

                <div style="height: 2px; background-color: #9e9e9e; "></div>
            </div>

            <div style="margin-top: 16px; display: flex; ">
                <div style="color: #707070; font-size: 12px; margin-left: 16px; flex: 1; line-height: 2; ">
                  Thank you for your payment. <br/> - Any query write us at ${authUserCompany.email || ''} <br /> - This is a digital receipt and does not requires signature
                </div>

                <div style="color: #4e4e4e; flex: 1;">
                    <div style="text-align: right; display: flex; margin-right: 16px; margin-bottom: 16px; ">
                        <span style="flex: 1; font-size: 15px; ">Amount</span>
                        <span style="flex: 0.2; text-align: right; font-size: 15px; ">
                        ${CurrencyHTMLEntities[currencyType]}
                        </span>
                        <span style="flex: 0.3; font-size: 15px; ">${parseDisplayNumber(subscription.amount)}</span>
                    </div>
                    <div style="text-align: right; display: flex; margin-right: 18px; margin-bottom: 18px; ">
                        <span style="flex: 1; font-size: 15px; ">Discount @ ${parseDisplayNumber(subscription.discountRate)}%</span>
                        <span style="flex: 0.2; text-align: right; font-size: 15px; ">
                        ${CurrencyHTMLEntities[currencyType]}
                        </span>
                        <span style="flex: 0.3; font-size: 15px; ">${parseDisplayNumber(subscription.discount)}</span>
                    </div>
                    <div style="text-align: right; display: flex; margin-right: 18px; margin-bottom: 18px; ">
                        <span style="flex: 1; font-size: 15px; ">Amount after Discount</span>
                        <span style="flex: 0.2; text-align: right; font-size: 15px; ">
                        ${CurrencyHTMLEntities[currencyType]}
                        </span>
                        <span style="flex: 0.3; font-size: 15px; ">${parseDisplayNumber(subscription.amountAfterDiscount)}</span>
                    </div>
                    <div style="text-align: right; display: flex; margin-right: 18px; margin-bottom: 18px; ">
                        <span style="flex: 1; font-size: 15px; ">
                          <span style="margin-right: 3px">
                            ${subscription.includeTax ? 'Inclusive' : 'Exclusive'}
                          </span>
                          <span style="margin-right: 3px">
                            VAT
                          </span>
                          <span style="margin-right: 3px">
                            @
                          </span>
                          <span style="margin-right: 3px">
                            ${parseDisplayNumber(subscription.taxRate)}%
                          </span>
                          <span style="margin-right: 3px">
                            (${CurrencyHTMLEntities[currencyType]} ${parseDisplayNumber(subscription.taxableAmount)})
                          </span>
                        </span>
                        <span style="flex: 0.2; text-align: right; font-size: 15px; ">
                        ${CurrencyHTMLEntities[currencyType]}
                        </span>
                        <span style="flex: 0.3; font-size: 15px; ">${parseDisplayNumber(subscription.tax)}</span>
                    </div>
                    <div style="box-sizing: border-box; display: flex; justify-content: space-between; minWidth: 320px; width: '100%'; font-size: 16px; padding: 5px; align-items: center; background: #dedede70; color: #3e3e3e; border-radius: 8px; border: 2px solid #bebebe8f; margin-top: 8px; ">
                        <div style="flex: 0.5; font-weight: 700; font-size: 16px;">Total Amout Received</div>
                        <div style="display: flex; background: #fefefe; padding: 6px 8px; flex: 0.5; border-radius: 6px; text-align: center; ">
                            <span style="flex: 0.6; text-align: right; font-size: 16px; font-weight: 700; ">
                            ${CurrencyHTMLEntities[currencyType]}
                            </span>
                            <span style="flex: 0.4; font-size: 16px; font-weight: 700; text-align: right; ">${parseDisplayNumber(subscription.totalAmount)}</span>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>

    <!-- footer -->
    <footer style="display: flex; border-top: 3px solid #9e9e9e; margin-top: 15px; padding: 8px; color: #707070; font-size: 12px; line-height: 1.4; ">

        <!-- left -->
        <div style="flex: 1; ">
            <div>${company.address}</div>
            <div style="display: flex; ">
                <div><span style="margin-right: 4px;">E: </span><span>${company.email}</span></div> &nbsp; &nbsp;
                <div><span style="margin-right: 4px;">Ph: </span><span>${company.mobile}</span></div>
            </div>
            <div>
                <label>CIN#&nbsp;</label><span>${company.cinNumber}</span>
            </div>
            <div>
                <label>TAX#&nbsp;</label><span>${company.taxNumber}</span>
            </div>
        </div>


    </footer>

</div>
  </html>

  `;

  return htmlString;
};