import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    // color: theme.palette.text.secondary,
    borderRadius: 10,
    width: '70vw',
    maxWidth: '90vh'
  },
  detailsRow: {
    marginBottom: theme.spacing(2)
  },
  label: {
    color: theme.colors.FORM_LABEL_COLOR,
    fontSize: '0.9rem'
  },
  value: {
    marginLeft: theme.spacing(2),
    color: theme.colors.DIALOG_TITLE_COLOR,
  }
}));