import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container: {
    padding: ({ tillTablet }) => tillTablet ? 10 : theme.spacing(2),
    background: theme.colors.JOB_DETAIL_BACKGROUND_COLOR,
    // display: 'flex',
    // flexWrap: 'wrap',
    '&>*': {
      marginTop: theme.spacing(2),
      '&:first-child': {
        marginTop: 0
      }
    }
  },
  userStatusBar: {
    display: 'flex',
    alignItems: 'center',
    '&>*:first-child': {
      marginRight: '1rem'
    }
  },
  accordion: {
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
  },
  accordionSummary: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
    fontSize: '0.8rem',
    minHeight: 35,
    '& .MuiAccordionSummary-expandIcon': {
      padding: 0
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 35,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0'
    }
  },
}));