import {
    makeStyles
  } from '@material-ui/core';
  import React, { Fragment, useEffect } from 'react';
  import { batch, useDispatch } from 'react-redux';
  import { setServices,setServiceSearchParams } from '../../actions/serviceActions';
  import ServiceProviderServicesSection from './ServiceProviderServicesSection';
  
  
  function ServiceProviderEngagementDetailsCard({ profile }) {
  
    const dispatch = useDispatch();
  
    const resetServiceProviderEngagementData = () => {
  
  
      const serviceSearchParams = {
        customer: null,
        categories: [],
        appointment: null,
        startDate: null,
        endDate: null,
        assignments: null
      };
  
  
      batch(() => {
  
        console.log('=====START ====resetServiceProviderEngagementData======');
  

  
        dispatch(setServiceSearchParams(serviceSearchParams));
        dispatch(setServices([]));
  
        console.log('=====END ====resetServiceProviderEngagementData======');
  
      });
  
    };
  
    useEffect(() => {
  
      resetServiceProviderEngagementData();
  
      return () => {
        resetServiceProviderEngagementData();
      };
    }, []);
  
    return (
      <Fragment>

  
        <ServiceProviderServicesSection profile={profile} />
  
  
      </Fragment>
    );
  }
  
  export default ServiceProviderEngagementDetailsCard;
  
  export const useServiceProviderEngagemenStyles = makeStyles(theme => ({
  
    accordion: {
      background: theme.colors.PROFILE_CARD_BACKGROUND_COLOR,
    },
  
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      background: theme.colors.ACCORDION_BACKGROUND_COLOR,
      padding: 0
    },
  
    accordionTitleIcon: {
      color: theme.colors.ACCORDION_TITLE_COLOR,
      marginRight: 10
    },
  
    listingWrapper: {
      maxHeight: 400,
      overflowY: 'auto'
    },
  
    loader: {
      fontSize: '1rem',
      textAlign: 'center',
      color: theme.colors.FORM_LABEL_COLOR, padding: 10
    },
  
  }));