import {
  CREATE_SERVICE,
  DELETE_SERVICE_BY_ID,
  SET_SERVICE_DELETE_LOADER,
  SET_SERVICE_LOADER,
  SET_SERVICE_SEARCH_PARAMS,
  SET_SHARE_SERVICE_LOADER,
  SHARE_SERVICE,
  TOGGLE_IS_DELETE_UNDO,
  TOGGLE_SHARE_SERVICE_MODAL,
  UNDO_DELETE,
  SET_TASK_BY_ID,
  SET_SERVICE_TIMES_FILTER,
  SET_IS_SERVICE_FINISHED_FILTER,
  SET_SERVICE_MEDIA_LOADER,
  SET_SERVICE_MEDIA_UPLOAD_LOADER,
  SET_TODOS,
  SET_HAS_MORE_TODOS,
  SET_TASK_LOADERS,
  SET_RESOLVED_SERVICE_MEDIAS,
  TOGGLE_ADD_SERVICE_DIALOG,
  SET_SERVICES,
  SET_SERVICE_BY_ID,
} from "../actions/serviceActions";
import { TimesFilter } from "../utils";
import RightsManager from "../utils/RightsManager";
import { getPrefillAssignedUser } from "../utils/Helpers";
const isUserMember = RightsManager.isMember();

const initialState = {
  // services: [],
  // taskById: null,

  // isAddServiceDialog: false,
  // hasMoreTodos: true,
  // serviceById: null,
  // resolvedServiceMedias: [],

  // serviceSearchParams: {
  //   customer: null,
  //   startDate: null,
  //   endDate: null,
  //   // assignedUsers: null,
  //   assignments: null,

  //   categories: [],
  //   service: null,
  //   // statuses: []
  // },
  // serviceTimesFilter: serviceTimesFilter.None,

  // todosLoader: false,
  // todoDetailsLoader: false,
  // addTodoLoader: false,
  // editTodoLoader: false,
  // deleteTodoLoader: false,
  // moreTodosLoader: false,
  // todoShareLoader: false,
  // taskPaymentHistoryLoader: false,
  // mediaUploadLoader: false,
  // mediaDeleteLoader: false,
  // mediaFetchLoader: false,
  // getServiceMediaLoader: false,

  // isShareServiceModal: false,
  // isServiceDeleteUndo: false,
  // isServiceDeleteLoader: false,
  // isShareServiceLoader: false,
  // isServiceLoader: false,
  // isServiceUnFinishedFilter: false,
  // mediaLoader: false,

  services: [],
  serviceById: null,

  // Loaders
  servicesLoader: false,
  moreservicesLoader: false,
  quotationsLoader: false,
  serviceDetailLoader: false,
  addserviceLoader: false,
  editserviceLoader: false,
  deleteserviceLoader: false,
  uploadserviceMediaLoader: false,
  getserviceMediaLoader: false,
  deleteserviceMediaLoader: false,

  resolvedserviceMedias: [],

  hasMoreservices: true,
  isAddserviceDialog: false,

  serviceTimesFilter: TimesFilter.None,

  serviceSearchParams: {
    customer: null,
    categories: [],
    service: null,
    startDate: null,
    endDate: null,
    assignments: isUserMember ? getPrefillAssignedUser() : null,
    mapAddress: null,
    lat: null,
    lng: null,
  },
};

export default function servicesReducer(
  state = initialState,
  { type, payload }
) {
  console.log("action ", type);
  console.log("actionService", payload);
  let newState = {};
  switch (type) {
    case TOGGLE_ADD_SERVICE_DIALOG: {
      console.log("dispatched22");

      return {
        ...state,
        isAddServiceDialog: payload,
      };
    }

    case SET_SERVICES: {
      return {
        ...state,
        services: payload,
      };
    }

    case SET_SERVICE_BY_ID: {
      state = {
        ...state,
        serviceById: payload,
      };
      return state;
    }
    case SET_HAS_MORE_TODOS: {
      return {
        ...state,
        hasMoreTodos: payload,
      };
    }

    case SET_TASK_LOADERS:
      const { loaderName, loaderState } = payload;
      state = {
        ...state,
        [loaderName]: loaderState,
      };
      return state;

    case DELETE_SERVICE_BY_ID:
      const tempServices = state.services.filter(
        (service) => service.id !== payload
      );
      newState = {
        ...state,
        services: tempServices,
      };
      return newState;

    case UNDO_DELETE:
      newState = {
        ...state,
        services: [payload, ...state.services],
      };
      return newState;

    case SET_SERVICE_DELETE_LOADER:
      newState = {
        ...state,
        isServiceDeleteLoader: payload,
      };
      return newState;

    case CREATE_SERVICE:
      newState = {
        ...state,
        services: [payload, ...state.services],
        taskById: payload,
      };
      return newState;

    case SET_SERVICE_SEARCH_PARAMS:
      newState = {
        ...state,
        serviceSearchParams: {
          ...state.serviceSearchParams,
          ...payload,
        },
      };
      return newState;

    case TOGGLE_SHARE_SERVICE_MODAL:
      newState = {
        ...state,
        isShareServiceModal: payload,
      };
      return newState;

    case SET_SHARE_SERVICE_LOADER:
      newState = {
        ...state,
        isShareServiceLoader: payload,
      };
      return newState;

    case SHARE_SERVICE:
      const tempShareServices = state.services.map((service) => {
        if (service.id === payload.serviceId) {
          return {
            ...service,
            taskAssignments: payload.tasks,
          };
        } else {
          return service;
        }
      });
      newState = {
        ...state,
        services: tempShareServices,
      };
      return newState;

    case TOGGLE_IS_DELETE_UNDO:
      newState = {
        ...state,
        isServiceDeleteUndo: payload,
      };
      return newState;

    case SET_SERVICE_LOADER:
      newState = {
        ...state,
        addServiceLoader: payload.loaderState,
      };
      return newState;

    case SET_TASK_BY_ID:
      newState = {
        ...state,
        taskById: payload,
      };
      return newState;

    case SET_SERVICE_TIMES_FILTER:
      newState = {
        ...state,
        serviceTimesFilter: payload,
      };
      return newState;

    case SET_IS_SERVICE_FINISHED_FILTER:
      newState = {
        ...state,
        isServiceUnFinishedFilter: payload,
      };
      return newState;

    case SET_SERVICE_MEDIA_LOADER:
      newState = {
        ...state,
        mediaLoader: payload,
      };
      return newState;

    case SET_SERVICE_MEDIA_UPLOAD_LOADER:
      newState = {
        ...state,
        mediaDeleteLoader: payload,
      };
      return newState;

    case SET_RESOLVED_SERVICE_MEDIAS:
      newState = {
        ...state,
        resolvedServiceMedias: payload,
      };
      return newState;

    default:
      return state;
  }
}
