import { Button, Dialog, makeStyles, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { Fragment, useEffect, useState } from "react";
import RightsManager from "../../utils/RightsManager";
import HeadingBar from "../HeadingBar/HeadingBar";
import InputCNO from "../../library/InputCNO";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import * as icons from "@mdi/js";
import Icon from "@mdi/react";
import { useTheme } from "@material-ui/core";
import { updateService } from "../../actions/serviceActions";
import { useDispatch, useSelector } from "react-redux";
import LinkCNO from "../../library/LinkCNO";
import ConfirmationDialogCNO from "../../library/ConfirmationDialogCNO";
import { toast } from "react-toastify";

function EditServiceBudgetDetails({
  isEditable,
  setIsEditable,
  service,
  setBudget,
}) {
  const styles = useStyles();

  const dispatch = useDispatch();
  const { editserviceLoader } = useSelector((state) => state.services);

  const handleClose = () => setIsEditable(false);

  const handleUpdate = async (values) => {
    const ServiceBudgetPayload = {
      ...service,
      budget: values.budget ? values.budget : 0,
    };

    setBudget(values.budget ? values.budget : 0);

    dispatch(updateService(ServiceBudgetPayload));

    handleClose();
  };

  const validationSchema = yup.object({
    budget: yup.number().required("Budget is required."),
  });

  const formik = useFormik({
    initialValues: {
      budget: service.budget ? service.budget : 0,
    },
    validationSchema,
    onSubmit: (values) => handleUpdate(values),
  });

  return (
    <Dialog
      open={isEditable}
      className={styles.container}
      classes={{
        paper: styles.paper,
      }}
    >
      {editserviceLoader && <FullScreenLoaderCNO />}

      <HeadingBar title="Edit Service Budget" onClose={handleClose} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <InputCNO
          name="budget"
          label="Service Budget in $"
          formik={formik}
          placeholder="Enter Service Budget in $"
          icon={icons.mdiFormatText}
          fullWidth
        />

        <FormActionButtons rightText="Save" leftClick={handleClose} />
      </form>
    </Dialog>
  );
}

function ApprovedProviderListing({ service }) {
  const theme = useTheme();

  const styles = useStyles();
  const dispatch = useDispatch();
  const isUserAdmin = RightsManager.isAdmin();

  const [openApprovementDialog, setOpenApprovementDialog] = useState(false);
  const [approvedprovider, setApprovedProvider] = useState(null);

  const handleApprovement = (provider) => {
    setApprovedProvider(provider);
    setOpenApprovementDialog(true);
  };
  return (
    <>
      <div className={styles.container_ServiceBudgetDetailsCard}>
        <Typography className={styles.cardTitle}>Service Providers</Typography>
        <div className={styles.cardContent}>
          <div className={styles.providers}>
            {service?.serviceProviders.length > 0 ? (
              service.serviceProviders.map((provider) => (
                <div className={styles.providerDetails}>
                  <div className={styles.profileDetails}>
                    <div>
                      <img src={provider.profilePic} />
                    </div>
                    <div>
                      <p>{provider.title}</p>
                      <p>
                        {`Rating : ${provider.rating}`}
                        <Icon path={icons.mdiStar} size={0.5} />
                      </p>
                    </div>
                  </div>

                  <div className={styles.actionButtons}>
                    <LinkCNO to={`/profile/${provider.id}`}>
                      <Button
                        style={{
                          backgroundColor:
                            theme.colors.VIEW_BUTTON_BACKGROUND_COLOR,
                          color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
                        }}
                        variant="contained"
                        startIcon={
                          <Icon path={icons.mdiEyeOutline} size={1.1} />
                        }
                      >
                        {`View`}
                      </Button>
                    </LinkCNO>

                    <div>
                      <Button
                        style={{
                          backgroundColor:
                            theme.colors.APPROVE_BUTTON_BACKGROUND_COLOR,
                          color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
                        }}
                        variant="contained"
                        startIcon={
                          <Icon
                            path={icons.mdiCheckDecagramOutline}
                            size={1.1}
                          />
                        }
                        onClick={() => handleApprovement(provider)}
                        // onClick={() => setOpenApprovementDialog(true)}
                      >
                        {`Approve`}
                      </Button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Typography
                style={{
                  color: theme.colors.JOB_DETAIL_CUSTOMER_TEXT_COLOR,
                  fontWeight: "bold",
                }}
              >
                {"Currently there is no service providers. "}
              </Typography>
            )}
          </div>
        </div>

        {openApprovementDialog && (
          <ConfirmationDialogCNO
            open={openApprovementDialog}
            onClose={() => setOpenApprovementDialog(false)}
            dialogTitle="Comfirm Approvement of Service Provider"
            messages={["Are you sure you want to approve?"]}
            yesClick={async () => {
              const provider={
                ...approvedprovider,
                approveStatus:true
              }
              const payload = {
                ...service,
                serviceAssignments: [
                  ...service.serviceAssignments,
                  provider,
                ],
                serviceProviders: service.serviceProviders.filter(
                  (provider) => provider.id !== approvedprovider.id
                ),
              };
              const onAddSuccess=()=>{
                toast.success("Service Provider Assigned Succefully.")
              }
              try {
                dispatch(updateService(payload,onAddSuccess));
              } catch (error) {
                console.log("Error: Approvement error");
              }
            }}
            rightText="Approve"
          />
        )}
      </div>
    </>
  );
}

export default ApprovedProviderListing;

const useStyles = makeStyles((theme) => ({
  container_ServiceBudgetDetailsCard: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
  },
  cardContent: {
    padding: theme.spacing(1),
    "&>*": {
      marginTop: theme.spacing(1),
    },
    maxHeight: "700px",
    overflow: "auto",
  },
  cardTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
  },
  row: {
    // color: theme.colors.JOB_DETAIL_RATE_COLOR,
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    alignItems: "center",
  },
  formTitle: {
    marginLeft: 5,
  },
  form: {
    "&>*": {
      marginTop: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    borderRadius: 10,
    width: "70vw",
    maxWidth: "90vh",
  },
  providers: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
  },
  providerDetails: {
    display: "flex",
    // flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "8px",
    backgroundColor: theme.palette.background.paper,
  },
  profileDetails: {
    display: "flex",
    // alignItems: "center",
    // marginBottom: "16px",
    "& img": {
      width: "60px",
      height: "60px",
      borderRadius: "50%",
      marginRight: "16px",
    },
    "& p": {
      margin: "0",
      fontSize: "16px",
      color: theme.colors.TEXT_COLOR,
    },
  },
  actionButtons: {
    display: "flex",
    gap: "8px",
    // justifyContent: "space-between",
    // "& button": {
    //   marginLeft: "8px",
    // },
  },
}));
