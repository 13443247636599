import React, { useEffect } from "react";
import {
  DialogCNO,
  InputLabelCNO,
  MetaText,
  InputCNO,
  DateTimePickerCNO,
  ButtonCNO,
} from "../../../library";
import { useDispatch, useSelector } from "react-redux";
import {
  addPost,
  toggleAddPostDialog,
  toggleEditPostDialog,
  updatePost,
} from "../../../actions/postActions";
import * as yup from "yup";
import { Privacy } from "../../../utils/mappings";
import LocalStorageConstants from "../../../utils/LocalStorageConstants";
import { Formik } from "formik";
import { Box, Input, TextField, makeStyles, Button } from "@material-ui/core";
import AvatarCNO from "../../../library/AvatarCNO";
// import AddPostActions from './AddPostActions';
import PrivacyDropdown from "./PrivacyDropdown";
import { getMediaData, formatDate } from "../../../utils/Helpers";
import { v4 } from "uuid";
import { FileType } from "../../../utils/mappings";
import { useState } from "react";
import CollageView from "../../../library/collageViewOnSelectCNO/CollageView";
// import { toast } from 'react-toastify';
import { toast } from "react-hot-toast";
import { AppConstants } from "../../../utils/AppConstants";

import FullScreenLoaderCNO from "../../../library/FullScreenLoaderCNO";
// import PrivacyAudienceDialog from './PrivacyAudienceDialog';
import AddPostOptions from "../../../components/AddToYourPost/AddPostOptions";
import { useStyles } from "../../../library/DialogCNO/DialogCNOStyles";
import { Dialog, useTheme } from "@material-ui/core";
import HeadingBar from "../../../components/HeadingBar";
import TextFieldEmojiInput from "./TextFieldEmojiInput";
import PostEmojiPicker from "./PostEmojiPicker";
// import FeelingActivity from './FeelingActivity'
import FormActionButtons from "../../../components/FormActionButtons";
import { useMediaQuery } from "@material-ui/core";
import { IconMain } from "../../../library";
import { Typography } from "@material-ui/core";
import PrivacyOptionsDescription from "./PrivacyOptionsDescription";
import TagChipDisplay from "../../../utils/PostUtils/TagChipDisplay";
import friends from "../../../actions/AddToPostData/friends.json";
import ActivityDetails from "./ActivityDetails";
import { mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import Feelings from "../../../actions/AddToPostData/feelings.json";
import Activities from "../../../actions/AddToPostData/activity.json";
import GifGenerator from "../../../utils/PostUtils/GifGenerator";
import App from "../../../utils/MapUtilsNew/NewApp";
import { options } from "../../../actions/AddToPostData/moreOptions";
import PostMediaPicker from "./PostMediaPicker";
import { MentionsInput, Mention } from "react-mentions";
import { categories } from "../../../actions/AddToPostData/categories";
import { LifeEvent } from "../../../assets/animatedGifStore";
import * as mdi from "@mdi/js";
import { mdiFormatText } from "@mdi/js";
import SelectCNO from "../../../library/SelectCNO";
import GooglePlaceInputCNO from "../../../library/GooglePlaceInputCNO";
import { event } from "react-ga";
import CloseIcon from "../../../library/CloseIcon";

const AddPostDialog = ({ postId, closeMorePopover }) => {
  const [loading, setLoading] = useState(false);
  const [feeling, setFeeling] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [tagsPeople, setTagsPeople] = useState(null);
  const [gif, setGif] = useState(null);
  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false);
  const [currentValue, setCurrentValue] = useState(1);
  const [mediaLinks, setMediaLinks] = useState([]);
  const [post, setPost] = useState(null);

  //tags
  const [openTags, setOpenTags] = useState(false);
  const [search, setSearch] = useState("");
  const [friendsList, setFriendsList] = useState(friends);
  const [taggedFriends, setTaggedFriends] = useState([]);

  //feeling
  const [openFeeling, setOpenFeeling] = useState(false);
  const [isActivityBox, setIsActivityBox] = useState(false);
  const [isFeelingBox, setIsFeelingBox] = useState(true);
  const [activity, setActivity] = useState(null);
  const [searchFeelingActivity, setSearchFeelingActivity] = useState("");

  //gif
  const [openGif, setOpenGif] = useState(false);

  //map
  const [openMap, setOpenMap] = useState(false);

  //Life Event

  const [openLifeEvent, setOpenLifeEvent] = useState(false);
  const [openCategoryDialog, setCategoryDialog] = useState(false);
  const [category, setCategory] = useState(null);
  const [openAddLifeEvent, setOpenAddLifeEvent] = useState(false);
  const [lifeEvent, setLifeEvent] = useState("");
  const [createEvent, setCreateEvent] = useState(false);

  //openMore
  const [openMore, setOpenMore] = useState(false);

  const dispatch = useDispatch();
  const {
    editPostLoader,
    isAddPostDialog,
    isEditPostDialog,
    addPostLoader,
    posts,
  } = useSelector((state) => state.posts);

  useEffect(() => {
    setLoading(true);
    const post = posts.find((item) => item.id === postId);
    if (post) {
      setPost(post);
      setFeeling(post.feeling);
      setSelectedActivity(post.activity);
      setTagsPeople(post.tags);
      setMediaLinks(post.mediaLinks || []);
      setGif(post.gifs ? post.gifs[0] : null);
    }
    setLoading(false);
  }, [postId, posts]);

  const validationSchema = yup.object({
    post: yup.string().required(""),
    visibility: yup.number().oneOf([1, 2, 3, 4, 5], ""),
  });

  const initialValues = post
    ? {
        post: post.description,
        visibility: post.visibility || Privacy.ids.Private,
        mediaLinks: post.mediaLinks || [],
        feeling: post.feeling || null,
        activity: post.activity || null,
        tags: post.tags || [],
        gifs: post.gifs || [],
        taggedUsers: post.taggedUsers || [],
        location: post.location || "",
        lifeEventTitle: post.lifeEventTitle || "",
        eventDate: post.eventDate || "",
        // eventTime: post.eventTime || "",
        workplace: post.workplace || "",
        schoolType: post.schoolType || "",
        school: post.school || "",
        partner: post.partner || "",
        homeLivinglocation: post.homeLivinglocation || "",
        travelLocation: post.travelLocation || "",
        lifeEventGif: post.lifeEventGif || "",
        lifeEventIcon: post.lifeEventIcon || "",
      }
    : {
        post: "",
        visibility: Privacy.ids.Private,
        mediaLinks: [],
        feeling: null,
        activity: null,
        tags: [],
        gifs: [],
        taggedUsers: [],
        location: "",
        lifeEventTitle: "",
        eventDate: "",
        workplace: "",
        schoolType: "",
        school: "",
        partner: "",
        homeLivinglocation: "",
        travelLocation: "",
        lifeEventGif: "",
        lifeEventIcon: "",
        // eventTime:"",
      };

  useEffect(() => {
    if (isAddPostDialog) {
      dispatch(toggleAddPostDialog(true));
    }
  }, [isAddPostDialog, dispatch]);

  const onCloseAddPostDialog = () => dispatch(toggleAddPostDialog(false));
  const onCloseEditPostDialog = () => {
    dispatch(toggleEditPostDialog(false));
    closeMorePopover();
  };

  const authUser = LocalStorageConstants.getItem("authUser");
  const userName = `${authUser.firstName} ${authUser.lastName}`;

  const handleAddPost = (values, createEvent) => {
    let payload = {
      postType: 1,
      description: values.post,
      visibility: values.visibility,
      mediaLinks: values.mediaLinks,
      feeling: values.feeling,
      activity: values.activity,
      taggedUsers: values.tags,
      gifs: values.gifs,
      location: values.location,
      lifeEventTitle: "",
      eventDate: "",
      // eventTime: "",
      workplace: "",
      schoolType: "",
      school: "",
      partner: "",
      homeLivinglocation: "",
      travelLocation: "",
    };

    if (createEvent) {
      payload = {
        ...payload,
        lifeEventTitle: values.school
          ? `${values.lifeEventTitle} from ${values.school}`
          : values.partner
          ? `${values.lifeEventTitle} with ${values.partner}`
          : values.lifeEventTitle,
        eventDate: values.eventDate,
        workplace: values.workplace,
        schoolType: values.schoolType,
        school: values.school,
        partner: values.partner,
        homeLivinglocation: values.homeLivinglocation,
        travelLocation: values.travelLocation,
        lifeEventGif: values.lifeEventGif,
        lifeEventIcon: values.lifeEventIcon,
        // eventTime: values.eventTime,
      };
    }

    dispatch(addPost(payload, onCloseAddPostDialog));
    setMediaLinks([]);
  };

  const handleUpdatePost = (values) => {
    const payload = {
      id: postId,
      postType: 1,
      description: values.post,
      visibility: values.visibility,
      mediaLinks: values.mediaLinks,
      feeling: values.feeling,
      activity: values.activity,
      tags: values.tags,
      gifs: values.gifs,
      location: values.location,
      lifeEventTitle: values.lifeEventTitle,
      eventDate: values.eventDate,
      workplace: values.workplace,
      schoolType: values.schoolType,
      school: values.school,
      partner: values.partner,
      homeLivinglocation: values.homeLivinglocation,
      travelLocation: values.travelLocation,
      lifeEventGif: values.lifeEventGif,
      lifeEventIcon: values.lifeEventIcon,
    };
    console.log("papaload", payload);
    dispatch(updatePost(payload, onCloseEditPostDialog));
    setMediaLinks([]);
  };

  const tillLargeScreen = useMediaQuery("(max-width: 1130px)");
  const tillTablet = useMediaQuery("(max-width: 768px)");
  const width = tillTablet ? "90vw" : tillLargeScreen ? "65vw" : "50vw";
  const maxHeight = "95vh";
  const minHeight = 450;
  const scrollHeight = openFeeling ? 350 : 205;
  const gifHeightInLifeEvent = 313;

  const theme = useTheme();
  const styles = useStyles({
    width,
    maxHeight,
    minHeight,
    scrollHeight,
    gifHeightInLifeEvent,
  });

  const PrivacyDialogRender = (formik, onPrivacyChange) => {
    const onDone = () => {
      onPrivacyChange(currentValue);
      setOpenPrivacyDialog(false);
    };

    return (
      <div>
        <div className="content">
          <Typography variant="h5">Who can see your post?</Typography>
          <br />
          <Typography>
            Your post will appear in Feed, on your profile and in search
            results. Your default audience is set to Only me, but you can change
            the audience of this specific post.
          </Typography>
          <div className={styles.scrollableContent}>
            <PrivacyOptionsDescription
              setValue={setCurrentValue}
              value={currentValue}
            />
          </div>
        </div>
        <FormActionButtons
          rightText="Done"
          leftClick={() => setOpenPrivacyDialog(false)}
          rightClick={onDone}
        />
      </div>
    );
  };

  const TagPeopleDialogRender = (open, setOpenTags, formik) => {
    const addFriend = (newfriend) => {
      setTaggedFriends((prev) => [...prev, newfriend]);

      const updatedList = friendsList.filter(
        (friend) => friend.id !== newfriend.id
      );
      setFriendsList(updatedList);
    };
    const addRemovedTaggedIntoList = (friend) => {
      setFriendsList((list) => [...list, friend]);
    };
    const onDone = (taggedFriends) => {
      setTagsPeople(taggedFriends);
      formik.setFieldValue("tags", [...formik.values.tags, ...taggedFriends]);

      setOpenTags(false);
    };

    return (
      <div>
        <div className={styles.searchBtnBox}>
          <input
            type="search"
            placeholder="Search"
            className={styles.searchBox}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {taggedFriends?.length > 0 && (
          <TagChipDisplay
            taggedPeople={taggedFriends}
            addFriend={addRemovedTaggedIntoList}
            setTaggedFriends={setTaggedFriends}
          />
        )}
        <h4 className={styles.suggestion}>SUGGESTIONS</h4>
        <div className={styles.scrollableContent}>
          <div className={styles.activityIconContainer}>
            {friends.length > 0 && (
              <div>
                {friendsList
                  .filter((item) => {
                    return search.toLocaleLowerCase() === ""
                      ? item
                      : item.name.toLocaleLowerCase().includes(search);
                  })
                  .map((friend) => (
                    <div
                      key={friend.id}
                      className={styles.activityElementsEmoji}
                      onClick={() => addFriend(friend)}
                    >
                      <div className={styles.backgroundDisplay}>
                        <img
                          className={styles.emoji}
                          src={friend.image}
                          height="50px"
                          width="50px"
                        />
                      </div>
                      <p className={styles.emojiName}>{friend.name}</p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <FormActionButtons
            rightText="Done"
            leftClick={() => setOpenTags(false)}
            rightClick={() => onDone(taggedFriends)}
          />
        </div>
      </div>
    );
  };

  const FeelingActivityDialogRender = (formik) => {
    const onEmojiClick = (emoji, title) => {
      const feeling = {
        feelingEmoji: emoji,
        feelingTitle: title,
      };

      setFeeling(feeling);
      formik.setFieldValue("feeling", feeling);

      setSelectedActivity(null);
      formik.setFieldValue("activity", null);

      setOpenFeeling(false);
    };
    const onActivityClick = (displayName, icon, title) => {
      const activity = {
        display_name: displayName,
        icon: icon,
        title: title,
      };

      setSelectedActivity(activity);
      formik.setFieldValue("activity", activity);

      setFeeling(null);
      formik.setFieldValue("feeling", null);

      setOpenFeeling(false);
    };
    const setBoxData = (source) => {
      if (source === "feeling") {
        setIsFeelingBox(true);
        setIsActivityBox(false);
      } else {
        setIsFeelingBox(false);
        setIsActivityBox(true);
      }
    };

    const handleActivity = (activity) => {
      setActivity(activity);
      setIsActivityBox(false);
    };

    return (
      <div>
        <div className={styles.feelingActivityBox}>
          <div
            className={
              isFeelingBox
                ? `${styles.activeBar} ${styles.feelingElements}`
                : styles.feelingElements
            }
            onClick={() => setBoxData("feeling")}
          >
            Feelings
          </div>
          <div
            className={
              isActivityBox
                ? `${styles.activeBar} ${styles.feelingElements}`
                : styles.feelingElements
            }
            onClick={() => setBoxData("activity")}
          >
            Activities
          </div>
        </div>

        <input
          type="search"
          placeholder="Search"
          className={styles.searchBox}
          onChange={(e) => setSearchFeelingActivity(e.target.value)}
        />

        <Box className={styles.scrollableContent}>
          {isFeelingBox && (
            <div className={styles.feelingIconContainer}>
              {Feelings.filter((item) => {
                return searchFeelingActivity.toLocaleLowerCase() === ""
                  ? item
                  : item.feeling
                      .toLocaleLowerCase()
                      .includes(searchFeelingActivity);
              }).map((feeling) => (
                <div
                  key={feeling.id}
                  className={styles.feelingElementsEmoji}
                  onClick={() => onEmojiClick(feeling.emoji, feeling.feeling)}
                >
                  <div className={styles.backgroundDisplay}>
                    <p className={styles.emoji}>{feeling.emoji}</p>
                  </div>
                  <p className={styles.emojiName}>{feeling.feeling}</p>
                </div>
              ))}
            </div>
          )}
          {isActivityBox && (
            <div className={styles.activityIconContainer}>
              {Activities.filter((item) => {
                return search.toLocaleLowerCase() === ""
                  ? item
                  : item.display_name.toLocaleLowerCase().includes(search);
              }).map((activity) => (
                <div
                  className={styles.activityBox}
                  onClick={() => handleActivity(activity)}
                >
                  <div
                    key={activity.id}
                    className={styles.activityElementsEmoji}
                  >
                    <div className={styles.backgroundDisplay}>
                      <img src={activity.iconsrc} className={styles.emoji} />
                    </div>
                    <p className={styles.emojiName}>{activity.display_name}</p>
                  </div>

                  <div>
                    <Icon path={mdiChevronRight} size={2} />
                  </div>
                </div>
              ))}
            </div>
          )}
          {activity && (
            <ActivityDetails
              activity={activity}
              styles={styles}
              onActivityClick={onActivityClick}
              search={search}
            />
          )}
        </Box>
      </div>
    );
  };

  const GifDialogRender = (
    onGifSelect,
    gifDisabled = mediaLinks?.length > 0 ? true : false
  ) => {
    return (
      <div>
        <GifGenerator
          onGifSelect={onGifSelect}
          setOpenGif={setOpenGif}
          gifDisabled={gifDisabled}
        />
      </div>
    );
  };

  const MapDialogDialogRender = (formik) => {
    return (
      <div>
        <App
          formik={formik}
          setOpenMap={setOpenMap}
          setOpenMore={setOpenMore}
        />
      </div>
    );
  };

  const handleLifeEventCancel = (formik) => {
    formik.setFieldValue("lifeEventTitle", "");
    formik.setFieldValue("eventDate", "");
    formik.setFieldValue("workplace", "");
    formik.setFieldValue("schoolType", "");
    formik.setFieldValue("school", "");
    formik.setFieldValue("partner", "");
    formik.setFieldValue("homeLivinglocation", "");
    formik.setFieldValue("travelLocation", "");
    formik.setFieldValue("lifeEventGif", "");
    formik.setFieldValue("lifeEventIcon", "");
  };

  const AddLifeEventDialogRender = (category, eventTitle, formik) => {
    const onChangeHandler = (eventFormik, v) => {
      eventFormik.setFieldValue("schoolType", v);
    };

    const lifeEventFormik = {
      initialValues: {
        lifeEventTitle: eventTitle.nativeEvent ? "" : eventTitle,
        eventDate: AppConstants.DEFAULT_START_DATE,
        eventTime: AppConstants.DEFAULT_START_TIME,
        workplace: "",
        schoolType: "",
        school: "",
        partner: "",
        homeLivinglocation: "",
        travelLocation: "",
        lifeEventGif: category.gif,
        lifeEventIcon: category.icon,
      },
      onSubmit: (values) => {
        // When the life event form is submitted, merge the values into the main form

        Object.keys(values).forEach((key) => {
          formik.setFieldValue(key, values[key]);
        });
        setCreateEvent(true);
        setOpenMore(false);
        setOpenLifeEvent(false);
        setCategoryDialog(false);
        setOpenAddLifeEvent(false);
      },
    };

    return (
      <div>
        <Formik
          initialValues={lifeEventFormik.initialValues}
          onSubmit={lifeEventFormik.onSubmit}
        >
          {(eventFormik) => (
            <div>
              <div className={styles.ImageSection}>
                <div className={styles.lifeEventImage}>
                  <img src={category.gif} alt="Life Event" />
                </div>
                <div
                  className={styles.LifeEventCategoryIcon}
                  style={{
                    position: "absolute",
                    bottom: 7,
                    left: "45%",
                    height: "50px",
                    width: "50px",
                  }}
                >
                  <Icon path={mdi[category.icon]} size={1} />
                </div>
              </div>
              <div>
                <InputCNO
                  name="lifeEventTitle"
                  label="Title"
                  formik={eventFormik}
                  placeholder="Enter a title..."
                  icon={mdiFormatText}
                  fullWidth
                  isRequired
                />
                <div className={styles.examplePara}>
                  {`Example : ${category.example}`}
                </div>

                {category.category === "Work" && (
                  <GooglePlaceInputCNO
                    address=""
                    label="Workplace"
                    onSelectAddress={(address) => {
                      console.log("workplace", address);
                      eventFormik.setFieldValue("workplace", address.address);
                      if (address.address)
                        eventFormik.setFieldValue(
                          "lifeEventTitle",
                          `${eventFormik.values.lifeEventTitle} at ${address.address}`
                        );
                    }}
                  />
                )}

                {category.category === "Education" && (
                  <div>
                    <SelectCNO
                      name="schoolType"
                      label="School/University Type"
                      options={[
                        { id: 0, value: "School", label: "School" },
                        { id: 1, value: "University", label: "University" },
                      ]}
                      fullWidth
                      containerStyles={{ width: "98%" }}
                      value={eventFormik.values.schoolType}
                      onChange={(v) => onChangeHandler(eventFormik, v)}
                    />
                    <InputCNO
                      name="school"
                      label="School/University Name"
                      formik={eventFormik}
                      placeholder="Add a school/University..."
                      icon={mdi.mdiTownHall}
                      fullWidth
                    />
                  </div>
                )}

                {category.category === "Relationship" && (
                  <InputCNO
                    name="partner"
                    label="Partner"
                    formik={eventFormik}
                    placeholder="Add a partner..."
                    icon={mdi.mdiHeartCircleOutline}
                    fullWidth
                  />
                )}

                {category.category === "Home and Living" && (
                  <GooglePlaceInputCNO
                    address=""
                    label="Location"
                    onSelectAddress={(address) => {
                      console.log("homeLivinglocationaddress", address);
                      eventFormik.setFieldValue(
                        "homeLivinglocation",
                        address.address
                      );
                      if (address.address)
                        eventFormik.setFieldValue(
                          "lifeEventTitle",
                          `${eventFormik.values.lifeEventTitle} at ${address.address}`
                        );
                    }}
                  />
                )}

                {category.category === "Travel" && (
                  <GooglePlaceInputCNO
                    address=""
                    label="Location"
                    onSelectAddress={(address) => {
                      console.log("Traveldress", address);
                      eventFormik.setFieldValue(
                        "travelLocation",
                        address.address
                      );
                      if (address.address)
                        eventFormik.setFieldValue(
                          "lifeEventTitle",
                          `${eventFormik.values.lifeEventTitle} at ${address.address}`
                        );
                    }}
                  />
                )}

                <DateTimePickerCNO
                  dateName="eventDate"
                  datePlaceholder="Event Date"
                  datePickerLabel="Event Date"
                  onDateChange={(date) => {
                    eventFormik.setFieldValue("eventDate", date);
                    console.log(date);
                  }}
                  // timeName="eventTime"
                  // timePlaceholder="Event Time"
                  // timePickerLabel="Event Time"
                  // onTimeChange={(date) => {
                  //   eventFormik.setFieldValue("eventTime", date);
                  // }}
                  formik={eventFormik}
                  iconColor="red"
                  isRequired
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  className={styles.btnAdd}
                  onClick={eventFormik.handleSubmit}
                  disabled={
                    !eventFormik.values.lifeEventTitle ||
                    !eventFormik.values.eventDate
                  }
                >
                  {`Done`}
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  };

  const LifeEventBox = (category, formik) => {
    // const [categoryEvent, setCategoryEvent] = useState("");
    const clickHandler = (option) => {
      option ? setLifeEvent(option) : setLifeEvent("");

      setOpenAddLifeEvent(true);
      // AddLifeEventDialogRender(category,option,formik);
    };
    return (
      <div>
        {loading && <FullScreenLoaderCNO />}
        {/* {openAddLifeEvent ? (
          <AddLifeEventDialogRender
            category={category}
            event={categoryEvent}
            formik={formik}
          />
        ) : ( */}
        <div className={styles.categoryDetailContainer}>
          <div className={styles.enterTitleBox} onClick={clickHandler}>
            <div className={styles.LifeEventCategoryIcon}>
              <Icon path={mdi[category.icon]} size={1.5} />
            </div>
            <div className={styles.titleBox}>
              <Icon path={mdi.mdiPencilOutline} size={1} />
              <p>{`Enter a title...`}</p>
            </div>
            <div>{`Example : ${category.example}`}</div>
          </div>

          <div className={styles.lifeEventCategoryOptionsContainer}>
            {category.events.map((event, index) => (
              <div
                className={styles.lifeEventCategoryOptions}
                onClick={() => clickHandler(event)}
              >
                <p key={index} className={styles.categoryOption}>
                  {event}
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* )} */}
        {/* {category ? (
          
        ) : (
          <p>Loading...</p>
        )} */}
      </div>
    );
  };

  const LifeEventDialogRender = () => {
    const handleCategoryClick = (item) => {
      console.log("clicked", item);
      setCategory(item);
      item.events.length > 0
        ? setCategoryDialog(true)
        : setOpenAddLifeEvent(true);
    };

    return (
      <div className={styles.LifeEventContainer}>
        <div className={styles.ImageSection}>
          <div className={styles.lifeEventImage}>
            <img src={LifeEvent} />
          </div>
          <div className={styles.LifeEventDescription}>
            <h2>{`Life Events`}</h2>
            <p>{`Share and remember important moments from your life.`}</p>
          </div>
        </div>

        <div className={styles.categorySection}>
          <h3>SELECT A CATEGORY</h3>
          <div className={`${styles.categoryCollectionBox} `}>
            {categories.map((category) => (
              <div
                className={styles.category}
                onClick={() => handleCategoryClick(category)}
              >
                <Icon path={mdi[category.iconOutlined]} size={2} />
                <p>
                  {category.category.length > 20
                    ? `${category.category.slice(0, 20)}..`
                    : category.category}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  const MorePostOptionsRender = (
    onMediaSelect,
    gifDisabled,
    mediaDisabled,
    lifeEventDisabled
  ) => {
    const handleClick = (index) => {
      if (index === 1) {
        setOpenTags(true);
      } else if (index === 2) {
        setOpenFeeling(true);
      } else if (index === 3) {
        setOpenMap(true);
      } else if (index === 4 && !gifDisabled) {
        // Disable GIF option
        setOpenGif(true);
      } else if (index === 5 && !lifeEventDisabled) {
        // Disable Life Event option
        setOpenLifeEvent(true);
      }
    };

    return (
      <div className={styles.feelingIconContainer}>
        {options?.length > 0 &&
          options.map((item, index) => {
            const isDisabled =
              (index === 0 && mediaDisabled) ||
              (index === 4 && gifDisabled) ||
              (index === 5 && lifeEventDisabled);

            return (
              <div
                key={index}
                className={`${styles.feelingElementsEmoji} ${
                  isDisabled ? styles.disabled : ""
                }`}
                onClick={() => !isDisabled && handleClick(index)}
              >
                <div className={styles.backgroundDisplay}>
                  {index === 0 ? (
                    <PostMediaPicker
                      onMediaSelect={onMediaSelect}
                      mediaDisabled={mediaDisabled}
                    />
                  ) : (
                    <img
                      className={styles.emoji}
                      src={item.icon}
                      height="50px"
                      width="50px"
                      alt={item.function_name}
                    />
                  )}
                </div>
                <p className={styles.emojiName}>{item.function_name}</p>
              </div>
            );
          })}
      </div>
    );
  };

  const AddPostDialogRender = (
    formik,
    onEmojiSelect,
    onGifSelect,
    onGifUnselect,
    onMediaSelect,
    onMediaUnselect,
    gifDisabled,
    mediaDisabled,
    lifeEventDisabled
  ) => {
    return (
      <Box className={styles.dialogContent}>
        <Box className={styles.formContainer}>
          <Box className={styles.userWrapper}>
            <AvatarCNO src={authUser.profilePic} title={userName} />
            <Box>
              <MetaText fontWeight="bold" fontSize={16}>
                {`${userName} ${
                  feeling && feeling.feelingEmoji
                    ? `is feeling ${feeling.feelingEmoji} ${feeling.feelingTitle}`
                    : selectedActivity && selectedActivity.display_name
                    ? `is ${selectedActivity.display_name} ${selectedActivity.icon} ${selectedActivity.title}`
                    : ""
                } ${
                  tagsPeople?.length > 0
                    ? `with ${
                        tagsPeople.length >= 4
                          ? `${tagsPeople
                              .slice(0, 3)
                              .map((person) => person.name)
                              .join(", ")} and ${tagsPeople.length - 3} other${
                              tagsPeople.length - 3 > 1 ? "s" : ""
                            }`
                          : tagsPeople.length === 3
                          ? `${tagsPeople
                              .slice(0, 2)
                              .map((person) => person.name)
                              .join(", ")} and ${tagsPeople[2].name}`
                          : tagsPeople.length === 2
                          ? `${tagsPeople[0].name} and ${tagsPeople[1].name}`
                          : tagsPeople[0].name
                      }`
                    : ""
                } ${
                  formik.values.location ? `at ${formik.values.location}` : ``
                }`}
              </MetaText>

              <div>
                <Box
                  onClick={() => setOpenPrivacyDialog(true)}
                  className={styles.privacyDropdownContainer}
                >
                  <IconMain
                    name={Privacy.getItem(formik.values.visibility).icon}
                    size="0.8rem"
                  />
                  <MetaText fontSize="0.8rem">
                    {Privacy.getItem(formik.values.visibility).title}
                  </MetaText>
                  <IconMain name={"menu-down"} size="1rem" />
                </Box>
              </div>
            </Box>
          </Box>
          <Box className={styles.scrollableContent}>
            <TextField
              name="post"
              placeholder="What's on your mind?"
              value={formik.values.post}
              onChange={formik.handleChange("post")}
              multiline
              minRows={5}
              variant="outlined"
              fullWidth
              className={styles.input}
            />
            
             {/* <MentionsInput
              value={formik.values.post}
              onChange={formik.handleChange("post")}
              placeholder="What's on your mind?"
              className="mentions"
              style={{
                input: {
                  minHeight: "5em",
                  width: "100%",
                },
                highlighter: {
                  boxSizing: "border-box",
                  overflow: "hidden",
                },
                control: {
                  backgroundColor: "#fff",
                  fontSize: 14,
                  fontWeight: "normal",
                },
                "&multiLine": {
                  control: {
                    fontFamily: "monospace",
                    minHeight: 63,
                  },
                  highlighter: {
                    padding: 9,
                    border: "1px solid transparent",
                  },
                  input: {
                    padding: 9,
                    border: "1px solid silver",
                  },
                },
                suggestions: {
                  list: {
                    backgroundColor: "white",
                    border: "1px solid rgba(0,0,0,0.15)",
                    fontSize: 14,
                  },
                  item: {
                    padding: "5px 15px",
                    borderBottom: "1px solid rgba(0,0,0,0.15)",
                    "&focused": {
                      backgroundColor: "#cee4e5",
                    },
                  },
                },
              }}
            >
              <Mention
                trigger="@"
                data={friendsList}
                markup="@[__name__](__id__)"
                displayTransform={(id, name) => `@${name}`}
              />
            </MentionsInput>  */}

            <TextFieldEmojiInput>
              <PostEmojiPicker onEmojiSelect={onEmojiSelect} />
            </TextFieldEmojiInput>
            {formik.values.lifeEventGif && (
              <div>
                <div className={styles.ImageSection}>
                  <div className={styles.lifeEventImage}>
                    <img src={formik.values.lifeEventGif} alt="Life Event" />
                  </div>
                  <div
                    className={styles.LifeEventCategoryIcon}
                    style={{
                      position: "absolute",
                      bottom: 8,
                      left: "46%",
                      height: "50px",
                      width: "50px",
                    }}
                  >
                    <Icon path={mdi[formik.values.lifeEventIcon]} size={1} />
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      cursor: "pointer",
                      padding: "3px",
                    }}
                    onClick={() => handleLifeEventCancel(formik)}
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "center",

                    marginTop: "10px",
                  }}
                >
                  <p>{`${
                    formik.values.school
                      ? `${formik.values.lifeEventTitle} from ${formik.values.school}`
                      : formik.values.partner
                      ? `${formik.values.lifeEventTitle} with ${formik.values.partner}`
                      : formik.values.lifeEventTitle
                  }`}</p>
                  <p>{`${formatDate(formik.values.eventDate)}`}</p>
                </div>
              </div>
            )}
            {gif?.gif && (
              <Box
                style={{
                  position: "relative",
                }}
              >
                <img
                  src={gif.gif}
                  width="100%"
                  style={{ borderRadius: "7px" }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    cursor: "pointer",
                    padding: "3px",
                  }}
                  onClick={onGifUnselect}
                >
                  <CloseIcon />
                </div>
              </Box>
            )}
            {mediaLinks && mediaLinks.length > 0 && (
              <CollageView
                mediaLinks={mediaLinks}
                onMediaUnselect={onMediaUnselect}
              />
            )}
          </Box>
          <Box className={styles.footer}>
            <AddPostOptions
              onMediaSelect={onMediaSelect}
              formik={formik}
              setFeeling={setFeeling}
              setSelectedActivity={setSelectedActivity}
              onGifSelect={onGifSelect}
              setTagsPeople={setTagsPeople}
              gifDisabled={gifDisabled}
              mediaDisabled={mediaDisabled}
              lifeEventDisabled={lifeEventDisabled}
              openTags={openTags}
              setOpenTags={setOpenTags}
              openFeeling={openFeeling}
              setOpenFeeling={setOpenFeeling}
              openGif={openGif}
              setOpenGif={setOpenGif}
              openMap={openMap}
              setOpenMap={setOpenMap}
              openMore={openMore}
              setOpenMore={setOpenMore}
            />

            <FormActionButtons
              rightText={"Post"}
              rightClick={formik.handleSubmit}
              disabled={
                !formik.isValid ||
                !formik.dirty ||
                addPostLoader ||
                editPostLoader
              }
              wrapperStyles={{ marginTop: 0 }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      open={postId ? isEditPostDialog : isAddPostDialog}
      className={styles.container_DialogCNO}
      classes={{ paper: styles.paper }}
    >
      {addPostLoader && <FullScreenLoaderCNO message="Loading..." />}

      <HeadingBar
        title={
          openPrivacyDialog
            ? "Post Audience"
            : openTags
            ? "Tag People"
            : openFeeling
            ? "How Are You feeling?"
            : openGif
            ? "Choose A GIF"
            : openMap
            ? "Share Your Location"
            : openLifeEvent
            ? "Create Life Event"
            : openMore
            ? "Add To Your Post"
            : postId
            ? "Edit Post"
            : "Add Post"
        }
        onClose={
          openPrivacyDialog
            ? () => setOpenPrivacyDialog(false)
            : openTags
            ? openMore
              ? setOpenMore(false)
              : () => setOpenTags(false)
            : openFeeling
            ? openMore
              ? setOpenMore(false)
              : () => setOpenFeeling(false)
            : openGif
            ? openMore
              ? setOpenMore(false)
              : () => setOpenGif(false)
            : openMap
            ? () => setOpenMap(false)
            : openAddLifeEvent
            ? () => {
                setOpenAddLifeEvent(false);
                setLifeEvent("");
              }
            : openCategoryDialog
            ? () => setCategoryDialog(false)
            : openLifeEvent
            ? () => setOpenLifeEvent(false)
            : openMore
            ? () => setOpenMore(false)
            : postId
            ? onCloseEditPostDialog
            : onCloseAddPostDialog
        }
        icon={
          (openFeeling ||
            openGif ||
            openPrivacyDialog ||
            openTags ||
            openMore ||
            openLifeEvent ||
            openCategoryDialog ||
            openAddLifeEvent ||
            openMap) &&
          mdiArrowLeft
        }
        backTop={
          openFeeling ||
          openGif ||
          openPrivacyDialog ||
          openTags ||
          openMap ||
          openLifeEvent ||
          openCategoryDialog ||
          openAddLifeEvent ||
          openMore
            ? true
            : false
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={postId ? handleUpdatePost : handleAddPost}
      >
        {function AddPostForm(formik) {
          const onEmojiSelect = (emoji) => {
            formik.setFieldValue("post", formik.values.post + emoji);
          };

          const onMediaSelect = async (mediasToUpload) => {
            const mediasPromise = mediasToUpload.map(async (media) => {
              const { mediaData, fileType } = await getMediaData(media);
              return {
                id: v4(),
                link: mediaData,
                fileType: fileType,
              };
            });

            const toastId = toast.loading("Uploading...", {
              style: {
                minWidth: "200px",
                color: "black",
              },
            });
            const resolvedMedias = await Promise.all(mediasPromise);
            toast.dismiss(toastId);

            mediaLinks?.length + resolvedMedias?.length >
            AppConstants.MAX_NUMBER_OF_FILES
              ? toast.error(
                  `Maximum number of allowed files are ${AppConstants.MAX_NUMBER_OF_FILES}.`
                )
              : (setMediaLinks((prevMediaLinks) => [
                  ...prevMediaLinks,
                  ...resolvedMedias,
                ]),
                formik.setFieldValue("mediaLinks", [
                  ...formik.values.mediaLinks,
                  ...resolvedMedias,
                ]),
                formik.setFieldValue("gifs", []));

            openMore && setOpenMore(false);
          };

          const onMediaUnselect = (mediaId) => {
            setMediaLinks((prevMedia) => {
              const updatedMediaLinks = prevMedia.filter(
                (media) => media.id !== mediaId
              );
              formik.setFieldValue("mediaLinks", updatedMediaLinks);
              return updatedMediaLinks;
            });
          };

          const onGifSelect = (gif) => {
            console.log("gif2", gif);
            const gifData = [
              {
                id: v4(),
                gif: gif,
                fileType: FileType.Gif,
              },
            ];

            setGif(gifData[0]);
            formik.setFieldValue("gifs", gifData);
            formik.setFieldValue("mediaLinks", []);
          };

          const onGifUnselect = () => {
            setGif(null);
            formik.setFieldValue("gifs", []);
          };
          const gifDisabled =
            mediaLinks?.length > 0 || formik.values.lifeEventTitle;
          const mediaDisabled = gif || formik.values.lifeEventTitle;
          const lifeEventDisabled =
            mediaLinks?.length > 0 || gif || formik.values.lifeEventTitle;

          return (
            <div>
              {openPrivacyDialog
                ? PrivacyDialogRender(formik, (value) =>
                    formik.setFieldValue("visibility", value)
                  )
                : openTags
                ? TagPeopleDialogRender(openTags, setOpenTags, formik)
                : openFeeling
                ? FeelingActivityDialogRender(formik)
                : openGif
                ? GifDialogRender(onGifSelect)
                : openMap
                ? MapDialogDialogRender(formik)
                : openAddLifeEvent
                ? AddLifeEventDialogRender(category, lifeEvent, formik)
                : openCategoryDialog
                ? LifeEventBox(category, formik)
                : openLifeEvent
                ? LifeEventDialogRender()
                : openMore
                ? MorePostOptionsRender(
                    onMediaSelect,
                    gifDisabled,
                    mediaDisabled,
                    lifeEventDisabled
                  )
                : AddPostDialogRender(
                    formik,
                    onEmojiSelect,
                    onGifSelect,
                    onGifUnselect,
                    onMediaSelect,
                    onMediaUnselect,
                    gifDisabled,
                    mediaDisabled,
                    lifeEventDisabled
                  )}
            </div>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddPostDialog;
