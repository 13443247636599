import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react'

const EventDetailsContainer = ({
  children,
  style = {},
  className = ''
}) => {

  const styles = useStyles();

  return (
    <div
      className={clsx(styles.container, className)}
      style={style}
    >
      {children}
    </div>
  )
}

export default EventDetailsContainer;

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 15%'
  }
}))