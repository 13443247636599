import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_CompanyPaymentDetailsCard: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    background: theme.colors.PROFILE_CARD_BACKGROUND_COLOR,
    padding: ({ tillTablet }) => tillTablet ? 12 : theme.spacing(4),
    borderRadius: 20
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.colors.PROFILE_CARD_TITLE_COLOR,
  },

  tabs: {
    background: theme.colors.TAB_BACKGROUND_COLOR,
    // active tab
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: theme.colors.TAB_ACTIVE_COLOR,
      fontWeight: 'bold'
    },
  },
  tabPanel: {
    background: theme.colors.TAB_DETAILPANEL_BACKGROUND_COLOR,
    padding: '0'
  },
  activeTabIndicator: {
    background: theme.colors.TAB_ACTIVE_COLOR,
    color: theme.colors.TAB_ACTIVE_COLOR,
  }

}));