import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { addInvoice, generateInvoicePdf, setAddInvoiceDialogData, toggleAddInvoiceDialog } from '../../actions/invoiceActions';
import DialogCNO from '../../library/DialogCNO';
import FormBox from '../FormBox';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, makeStyles, Typography, useTheme } from '@material-ui/core';
import { setSubTasks } from '../../actions/subTaskActions';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { Currency, InvoiceStatus, RateType, TrackProximityRadius, AddInvoiceDialogMode } from '../../utils/mappings';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiContentSave, mdiEye, mdiFileCancel, mdiPlus, mdiSquareEditOutline } from '@mdi/js';
import AddInvoiceJobDetails from './AddInvoiceJobDetails';
import AddInvoiceJobItems from './AddInvoiceJobItems';
import AddInvoicePaymentSummary from './AddInvoicePaymentSummary';
import FlexibleSpaceCNO from '../../library/FlexibleSpaceCNO/FlexibleSpaceCNO';
import AddInvoiceMoreDetails from './AddInvoiceMoreDetails';
import { generateId } from '../../utils/IdGeneratorHelpers';
import { getHoursFromTimeline } from '../../utils/Helpers';
import { toggleAddInvoiceItemDialog } from '../../actions/invoiceItemActions';
import FieldHelperTextCNO from '../../library/FieldHelperTextCNO';
import { useHistory } from 'react-router-dom';
import FileViewerModal from '../../library/FileViewerModal';
import { toast } from 'react-toastify';
import { getTotalAmountValues } from '../../utils/InvoiceHelpers';
import { useDebouncedFormik, useFormikErrors } from '../../hooks';
import { AppConstants } from '../../utils/AppConstants';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import { setTaskById } from '../../actions/jobActions';
import { ItemType, setMediasPendingToLS, triggerUploadMediasLsBg } from '../../utils/MediaUpload';

const AddInvoice = ({ closeMoreActions }) => {

  const isJobDetails = useHistory().location.pathname.includes('/job/');
  const isQuotationDetails = useHistory().location.pathname.includes('/quotation/');

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  let authUserCompany = localStorage.getItem('authUserCompany');
  authUserCompany = authUserCompany ? JSON.parse(authUserCompany) : null;

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const dispatch = useDispatch();
  const {
    isAddInvoiceDialog,
    mode,
    allInvoiceLoader,
    addInvoiceData,
    addInvoiceDataItems
  } = useSelector(state => state.invoices);
  const { isPdfGenerating, addLoader, addInvoiceDialogLoader } = allInvoiceLoader;

  console.log("AddInvoice: ", { addInvoiceData, addInvoiceDataItems });

  const [pdfFileSource, setPdfFileSource] = useState(false);

  const [selectedSubTasks, setSelectedSubTasks] = useState([]);

  const [paymentSummaryValues, setPaymentSummaryValues] = useState({
    amount: 0,
    discountRate: 0,
    discount: 0,
    amountAfterDiscount: 0,
    taxRate: AppConstants.DEFAULT_TAX_RATE,
    taxableAmount: 0,
    tax: 0,
    totalAmount: 0,
    includeTax: false,
  });

  const [isMoreDetails, setIsMoreDetails] = useState(false);

  const [copyMedias, setCopyMedias] = useState(false);

  const isAdd = mode === AddInvoiceDialogMode.Add;
  const isGenerateFromJob = mode === AddInvoiceDialogMode.GenerateFromJob;
  const isGenerateFromQuotation = mode === AddInvoiceDialogMode.GenerateFromQuotation;

  const resetForm = () => {
    formik.resetForm();
    formik.setValues(formik.initialValues);
    setPaymentSummaryValues({
      amount: 0,
      discountRate: 0,
      discount: 0,
      amountAfterDiscount: 0,
      taxRate: AppConstants.DEFAULT_TAX_RATE,
      taxableAmount: 0,
      tax: 0,
      totalAmount: 0,
      includeTax: false,
    });

    localStorage.removeItem('addInvoice');

    dispatch(setAddInvoiceDialogData({ addInvoiceData: null, addInvoiceDataItems: [] }));

    setCopyMedias(false);

  };


  const closeAddInvoiceDialog = () => {
    resetForm();
    dispatch(toggleAddInvoiceDialog({ status: false, mode: AddInvoiceDialogMode.Add }));

    if (closeMoreActions) closeMoreActions();

  };



  useEffect(() => {
    if (addInvoiceData) {

      const generateFormik = {
        task: addInvoiceData,
        currency: currencyType,
        dueDate: moment().add(1, 'days').format('MMM DD, YYYY'),
        notes: `*After due date interest @10% will be charged\n- Any query write us at ${authUserCompany.email}\n- This is a digital invoice and does not requires signature`,
        subTasks: addInvoiceDataItems,
        status: InvoiceStatus.Draft
      };

      // console.log("========formikUse===========addInvoiceDataItems", addInvoiceDataItems);

      const subTaskFormik = addInvoiceDataItems.map(subTask => {

        const hours = getHoursFromTimeline(subTask.startDate, subTask.endDate);
        const amount = subTask.rateType === RateType.Hourly ? hours * subTask.rate
          : subTask.rate;

        return {
          [`title-${subTask.id}`]: subTask.title,
          [`description-${subTask.id}`]: subTask.description,
          [`startDate-${subTask.id}`]: subTask.startDate,
          [`endDate-${subTask.id}`]: subTask.endDate,
          [`hours-${subTask.id}`]: hours,
          [`rate-${subTask.id}`]: subTask.rate,
          [`rateType-${subTask.id}`]: subTask.rateType,
          [`amount-${subTask.id}`]: amount,
        };

      });

      // console.log("========formikUse===========subTaskFormik", subTaskFormik);

      const flattenedSubtasksFormik = subTaskFormik.length ? Object.assign(...subTaskFormik) : {};

      // console.log("========formikUse===========flattenedSubtasksFormik", flattenedSubtasksFormik);

      formik.setValues({
        ...generateFormik,
        ...flattenedSubtasksFormik
      });

      const tempSelected = addInvoiceDataItems.map((subTask) => subTask.id);
      setSelectedSubTasks(tempSelected);

    }
  }, [addInvoiceData, addInvoiceDataItems]);

  const validationSchema = yup.object({
    task: yup.object().required('Task is required').nullable(),
    currency: yup.number(),
    dueDate: yup.string().required('Due date is required'),
    notes: yup.string(),
    subTasks: yup.array().min(1, 'Atleast one invoice item is required.').required(),
    discountRate: yup.number(),
    discount: yup.number(),
    status: yup.number(),
    includeTax: yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      task: null,
      currency: currencyType,
      dueDate: moment().add(1, 'days').format('MMM DD, YYYY'),
      notes: `*After due date interest @10% will be charged\n- Any query write us at ${authUserCompany.email || null}\n- This is a digital invoice and does not requires signature`,
      subTasks: [],
      status: InvoiceStatus.Draft,
      includeTax: false,
    },
    validationSchema,
    onSubmit: (values) => handleAddInvoice(values)
  });

  useFormikErrors(formik);

  useDebouncedFormik(formik, 'addInvoice');

  // to make subtask selected
  useEffect(() => {

    const values = LocalStorageConstants.getItem('addInvoice');

    let selectedSubTaskIds = [];

    if (values && Array.isArray(values.subTasks)) {
      selectedSubTaskIds = values.subTasks.map(subTask => subTask.id);
      setSelectedSubTasks(selectedSubTaskIds);
    }

  }, []);

  const history = useHistory();
  const onAddInvoice = (invoiceId) => {

    closeAddInvoiceDialog();
    history.push(`/invoice/${invoiceId}`);

    if (!copyMedias) return;

    const mediasPending = {
      from: isGenerateFromJob ? ItemType.Job : ItemType.Quotation,
      to: ItemType.Invoice,
      medias: formik.values.task.mediaLinks || [],
      itemId: invoiceId,
    }

    setMediasPendingToLS([mediasPending]);

    LocalStorageConstants.setItem("mediaPendingLoader", true);
    window.dispatchEvent(new Event("media-pending-loader-storage"));

    triggerUploadMediasLsBg();

  };

  const handleAddInvoice = (values) => {
    console.log("Add Invoice: values: ", values);
    console.log("Add Invoice: formik: ", formik.errors);

    const { addInvoicePayload, invoiceItemPayload } = makeAddInvoicePayload(values, paymentSummaryValues, formik, selectedSubTasks, copyMedias);

    console.log("Add Invoice: addInvoicePayload: ", addInvoicePayload);

    dispatch(addInvoice(addInvoicePayload, invoiceItemPayload, onAddInvoice));

  };

  const toggleMoreDetails = () => {
    setIsMoreDetails(!isMoreDetails);
  };


  const hanlePreviewPdf = async () => {

    var { addInvoicePayload, invoiceItemPayload } = makeAddInvoicePayload(formik.values, paymentSummaryValues, formik, selectedSubTasks, copyMedias);

    console.log("====hanlePreviewPdf====addInvoicePayload=", addInvoicePayload);
    console.log("====hanlePreviewPdf====invoiceItemPayload=", invoiceItemPayload);

    const onGeneratePdfComplete = (fileSource) => setPdfFileSource(fileSource);

    try {
      await dispatch(generateInvoicePdf(addInvoicePayload, onGeneratePdfComplete, false, invoiceItemPayload));
    } catch (error) {
      console.log("Error: Preview PDF: ", error);
      toast.error("There is issue in generating pdf.");
    }

  };

  // console.log("====isAddInvoiceDialog======isAddInvoiceDialog====", isAddInvoiceDialog);
  // console.log("=====formik======values=====", formik.values);
  // console.log("=====formik======errors=====", formik.errors);

  return (
    <DialogCNO
      open={isAddInvoiceDialog}
      onClose={closeAddInvoiceDialog}
      dialogTitle="Add Invoice"
      bgColor={theme.colors.MODAL_BACKGROUND_COLOR}
      loading={addLoader}
    >
      {
        (isPdfGenerating || addInvoiceDialogLoader) &&
        <FullScreenLoaderCNO />
      }

      <form onSubmit={formik.handleSubmit} className={styles.container_AddInvoice}>
        <FormBox>
          <AddInvoiceJobDetails formik={formik} copyMedias={copyMedias} setCopyMedias={setCopyMedias} />
        </FormBox>

        <FormBox
          renderTitle={() => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography className={styles.title}>
                  Invoice Items
                  <span style={{ color: theme.colors.ERROR_COLOR }}>*</span>
                </Typography>
                <FieldHelperTextCNO
                  showHelperText={!selectedSubTasks.length}
                  helperText={"Atleast one invoice item is required."}
                />
              </div>
              <div style={{ marginTop: 5 }}>

                {
                  formik.values.task ? (
                    <Button
                      style={{
                        background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
                        color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
                        borderColor: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
                        fontSize: '0.7rem',
                        fontWeight: 700
                      }}
                      startIcon={<Icon path={mdiPlus} size="1rem" />}
                      //onClick={() => { dispatch(toggleAddInvoiceItemDialog(true)); if (closeMoreActions) closeMoreActions(); }}
                      onClick={async () => {
                        batch(() => {
                          dispatch(toggleAddInvoiceItemDialog(true));
                        })
                      }}
                    >
                      Add Invoice Item
                    </Button>
                  ) : (null)
                }

              </div>
            </div>
          )}
        >
          <AddInvoiceJobItems
            formik={formik}
            selectedSubTasks={selectedSubTasks}
            setSelectedSubTasks={setSelectedSubTasks}
          />
        </FormBox>

        <FormBox title="Invoice Payment Summary">
          <AddInvoicePaymentSummary
            selectedSubTasks={selectedSubTasks}
            formik={formik}
            paymentSummaryValues={paymentSummaryValues}
            setPaymentSummaryValues={setPaymentSummaryValues}
          />
        </FormBox>

        <div className={GlobalStyles.link} onClick={toggleMoreDetails} style={{ textAlign: 'right' }}>
          {
            (isMoreDetails)
              ? 'Less Details'
              : 'More Details'
          }
        </div>

        {
          isMoreDetails &&
          <FormBox title="More Details">
            <AddInvoiceMoreDetails
              formik={formik}
            />
          </FormBox>
        }


        <div className={styles.ctaWrapper}>
          <Button
            variant='contained'
            className={styles.cancelBtn}
            startIcon={<Icon path={mdiFileCancel} size={"1.3rem"} color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR} />}
            onClick={closeAddInvoiceDialog}
          >
            Cancel
          </Button>

          <FlexibleSpaceCNO />

          <FileViewerModal
            mimeType={'pdf'}
            filePath={pdfFileSource}
            dialogTitle={`Invoice Preview - ${formik.values.task ? formik.values.task.title : 'Invoice'}`}
            onClick={hanlePreviewPdf}
          >
            <Button
              variant='contained'
              className={styles.draftBtn}
              startIcon={<Icon path={mdiEye} size={"1.3rem"} color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR} />}
              onClick={() => formik.setFieldValue('status', InvoiceStatus.Draft)}
              disabled={!formik.dirty || !formik.isValid || !selectedSubTasks.length}
            >
              <pre>Preview</pre>
            </Button>
          </FileViewerModal>

          <Button
            variant='contained'
            className={styles.draftBtn}
            startIcon={<Icon path={mdiSquareEditOutline} size={"1.3rem"} color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR} />}
            type="submit"
            onClick={() => formik.setFieldValue('status', InvoiceStatus.Draft)}
            disabled={!formik.dirty || !formik.isValid || !selectedSubTasks.length || (isNaN(paymentSummaryValues.amount) || !paymentSummaryValues.amount)}
          >
            <pre>Save as Draft</pre>
          </Button>

          <Button
            variant='contained'
            className={styles.saveBtn}
            startIcon={<Icon path={mdiContentSave} size={"1.3rem"} color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR} />}
            type="submit"
            onClick={() => formik.setFieldValue('status', InvoiceStatus.Ready)}
            disabled={!formik.dirty || !formik.isValid || !selectedSubTasks.length || (isNaN(paymentSummaryValues.amount) || !paymentSummaryValues.amount)}
          >
            Save
          </Button>

        </div>

      </form>

    </DialogCNO>
  )
}

export default AddInvoice;

const useStyles = makeStyles((theme) => ({
  container_AddInvoice: {
    '&>*': {
      marginBottom: theme.spacing(2)
    }
  },
  ctaWrapper: {
    display: 'flex',
  },
  saveBtn: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
  },
  draftBtn: {
    background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CANCEL_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_SECONDARY_BUTTON_TEXT_COLOR,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  cancelBtn: {
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CANCEL_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
  },
  title: {
    fontSize: '0.8rem',
    color: theme.colors.FORM_SECTION_TITLE_COLOR,
    marginLeft: -8
  }
}))


function makeAddInvoicePayload(values, paymentSummaryValues, formik, selectedSubTasks, copyMedias) {
  const localBillDate = new Date();
  const localDueDate = new Date(values.dueDate);
  const localDueInDays = moment(localDueDate).diff(moment(localBillDate), 'days');

  console.log("Add Invoice: 111111111111111: ", { localBillDate, localDueDate, localDueInDays });

  const {
    amount,
    amountAfterDiscount,
    discount,
    discountRate,
    taxableAmount,
    tax,
    taxRate,
    totalAmount
  } = getTotalAmountValues(paymentSummaryValues.amount, paymentSummaryValues.discount, paymentSummaryValues.taxRate, paymentSummaryValues.includeTax);

  const addInvoicePayload = {
    invoiceNumber: generateId('invoice', values.task.customerText ? values.task.customerText : 'No Customer'),

    billDate: localBillDate.toISOString(),
    dueInDays: localDueInDays,
    currency: values.currency,

    amount,
    amountAfterDiscount,
    discount,
    discountRate,
    taxableAmount,
    tax,
    taxRate,
    totalAmount,

    status: values.status,
    includeTax: paymentSummaryValues.includeTax,

    // task data
    customerId: values.task.customerId,
    customerText: values.task.customerText,
    customerProfile: null,
    categories: values.task.categories,
    title: values.task.title,
    description: values.task.description,
    rate: amount,
    rateType: values.task.rateType,
    target: values.task.target ? values.task.target : 0,
    targetType: values.task.targetType,
    mapAddress: values.task.mapAddress,
    lat: values.task.lat.toString(),
    lng: values.task.lng.toString(),
    startDate: AppConstants.DEFAULT_START_DATE,
    startTime: AppConstants.DEFAULT_START_TIME,
    endDate: AppConstants.DEFAULT_END_TIME,
    endTime: AppConstants.DEFAULT_END_TIME,
    taskId: values.task.id,
    mediaLinks: [],
    paidAmount: values.task.paidAmount,
    paymentByCustomerStatus: values.task.paymentByCustomerStatus,
    proximity: values.task.proximity ? values.task.proximity : TrackProximityRadius,
    invoiceAssignments: values.task.invoiceAssignments,
  };

  console.log("Add Invoice: payload: ", addInvoicePayload);

  // return;
  // invoice item payload
  let invoiceItemPayload = formik.values.subTasks
    .filter((st) => selectedSubTasks.some((selectedId) => selectedId === st.id));
  invoiceItemPayload = invoiceItemPayload.map((invoiceItem) => ({
    ...invoiceItem,
    rateType: RateType.Fixed,
    rate: formik.values[`amount-${invoiceItem.id}`],
    amount: formik.values[`amount-${invoiceItem.id}`],
    title: formik.values[`title-${invoiceItem.id}`],
    description: formik.values[`description-${invoiceItem.id}`],
    startDate: formik.values[`startDate-${invoiceItem.id}`],
    endDate: formik.values[`endDate-${invoiceItem.id}`],
    hours: formik.values[`hours-${invoiceItem.id}`],
    invoiceItemAssignments: invoiceItem.invoiceItemAssignments
    // taxRate: values[`taxRate-${invoiceItem.id}`],
  }));
  return { addInvoicePayload, invoiceItemPayload };
}

