import { fade, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container_CostDetailsCard: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
  },
  cardContent: {
    padding: theme.spacing(1),
    '&>*': {
      marginTop: theme.spacing(1)
    }
  },
  cardTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR
  },
  formTitle: {
    marginLeft: 5
  },
  form: {
    '&>*': {
      marginTop: theme.spacing(2)
    }
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    borderRadius: 10,
    minWidth: 300,
    width: '70vw',
    maxWidth: '90vh'
  },
  rateTypeWrapper: {
    '&>*:first-child': {
      marginBottom: theme.spacing(1)
    }
  },
  rateTypeChips: {
    display: 'flex',
    '&>*': {
      marginRight: theme.spacing(1)
    }
  },

  amountBreakdownWrapper: {
    padding: 8
  },

  row: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 8,
  },

  label: {
    marginRight: 16
  },

  valueWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 120,
  },

  value: {
    minWidth: 60,
    textAlign: 'right'
  },

  totalAmountWraper: {
    display: 'flex',
    alignItems: 'center',
    background: theme.colors.WORK_HOURS_TOTAL_ROW_BACKGROUND_COLOR,
    padding: 8,
    justifyContent: 'flex-end',
    borderRadius: 4
  },
  amountWrapper: {
    background: theme.colors.INPUT_DISABLED_BACKGROUND_COLOR,
    width: 120,
    borderRadius: 4,
    padding: '4px 8px',
    display: 'flex',
    justifyContent: 'flex-end',
    color: fade(theme.colors.INPUT_TEXT_COLOR, 0.8),
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }
}));