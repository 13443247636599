import React from 'react'
import { DialogCNO, InputCNO } from '../../../library'
import { Formik } from 'formik'
import { Box } from '@material-ui/core'
import * as yup from 'yup';
import FormActionButtons from '../../../components/FormActionButtons';

function AddSectionDialog({ open, onClose, formikInitialValues, onAddSection }) {

  const initialValues = {
    title: "",
    ...formikInitialValues
  }

  const validationSchema = yup.object().shape({
    title: yup.string().required("Section name is required.")
  })

  const handleAddSection = values => {

    console.log("handleAddSection: values: ", values);

    typeof onAddSection === 'function' && onAddSection(values);
    typeof onClose === 'function' && onClose();

  }

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle='Add Section'
      minHeight={200}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleAddSection}
      >
        {
          function AddSectionForm(formik) {
            return (
              <Box>
                <InputCNO
                  name='title'
                  label='Section Name'
                  placeholder="Enter your section name..."
                  formik={formik}
                />
                <FormActionButtons
                  rightText={formikInitialValues ? 'Update Section' : 'Add Section'}
                  rightClick={formik.handleSubmit}
                  leftClick={onClose}
                />
              </Box>
            )
          }
        }
      </Formik>
    </DialogCNO>
  )
}

export default AddSectionDialog;