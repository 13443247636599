import { SET_APPS_LOADER, SET_LP_LOGIN_MODAL_DEFAULT_TAB, SET_MEDIAS_UPLOAD_RESULT, TOGGLE_LP_LOGIN_MODAL } from "../actions/appActions";

const initialState = {
  isLPLoginModalOpen: false,
  loginModalDefaultTab: 'loginForm',
  mediaPendingLoader: false,
  mediasUploadResultDialogVisible: false,
  mediasUploadResult: null,

  homeListData: [],
  homeListDataLoader: false
};

export const appReducer = (state = initialState, { type, payload }) => {

  switch (type) {
    case TOGGLE_LP_LOGIN_MODAL:
      state = {
        ...state,
        isLPLoginModalOpen: payload
      };
      return state;

    case SET_LP_LOGIN_MODAL_DEFAULT_TAB:
      state = {
        ...state,
        loginModalDefaultTab: payload
      };
      return state;

    case SET_APPS_LOADER:
      const { loaderKey, loaderValue } = payload;
      state = {
        ...state,
        [loaderKey]: loaderValue
      };
      return state;

    case SET_MEDIAS_UPLOAD_RESULT:
      state = {
        ...state,
        mediasUploadResult: payload
      };
      return state;

    default:
      return state;
  }

};