import { Box, Typography, useTheme } from "@material-ui/core";
import React from "react";
import Moment from "react-moment";
import { DATE_FORMAT } from "../../utils/formatDate";
import { formatTimeWithTZ } from "../../utils/Helpers";
import { CalendarFilter } from "../../utils/mappings";
import DialogCNO from "../DialogCNO";
import LinkCNO from "../LinkCNO";

const ShowMorePopup = ({ open, onClose, showMoreData, calendarFilter }) => {
  const theme = useTheme();

  const { events, date } = showMoreData;

  const getTitle = (event) => {
    const title = `${event.title}  ${event.mapAddress ? ` | ${event.mapAddress}` : null
      }`;

    return title;
  };

  const getOpenLink = (event) => {
    switch (calendarFilter) {
      case CalendarFilter.Appointments:
        return `appointment/${event.id}`;

      case CalendarFilter.Jobs:
        return `job/${event.id}`;

      default:
        break;
    }
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      isClickAwayClose={true}
      minHeight="auto"
      width={600}
    >
      <Moment element={Typography} variant="h6" format={DATE_FORMAT}>
        {formatTimeWithTZ(date)}
      </Moment>
      {events.map((event) => (
        <Box
          style={{
            background: event.hexColor,
            margin: "6px 0",
            padding: "8px",
          }}
        >
          <LinkCNO to={getOpenLink(event)} style={{ color: theme.palette.getContrastText(event.hexColor) }}>
            {getTitle(event)}
          </LinkCNO>
        </Box>
      ))}
    </DialogCNO>
  );
};

export default ShowMorePopup;
