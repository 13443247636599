import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_PaymentDoneDetails: {
    // border: '1px solid #1e1e1e',
    '&>*>*': {
      // padding: '0 8px'
    }
  },
  paymentsDoneSummaryWrapper: {

  },
  paymentsDoneSummaryHeader: {
    background: theme.colors.PAYMENT_SECTION_HEADER_BACKGROUND_COLOR,
    padding: '12px 8px',
    display: 'flex',
  },

  paymentsDoneHeaderTitle: {
    fontSize: '1rem'
  },

  paymentsDoneSummaryInfo: {

  },

  paymentsDoneTableWrapper: {

  },
  paymentsDoneTableHeader: {
    background: theme.colors.PAYMENT_SECTION_HEADER_BACKGROUND_COLOR,
    padding: '12px 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  paymentsDoneTableInfo: {

  },

  dataGrid: {
    '&.MuiDataGrid-root .MuiDataGrid-colCellWrapper': {
      background: theme.colors.PAYMENT_TABLE_HEADER_BACKGROUND_COLOR
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell': {
      padingLeft: 8
    },
    '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': {
      display: 'none'
    }
  },

  paymentDataGridHeader: {
    color: theme.colors.PAYMENT_TABLE_HEADER_TEXT_COLOR, fontSize: '0.8rem',
  },

  noPaymentWrapper: {
    minHeight: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noPaymentText: {
    textAlign: 'center'
  },

  inTableBtn: {
    '&.MuiButton-outlined': {
      padding: '0 16px'
    }
  }

}));