import {
  mdiAccountHardHat,
  mdiTools,
  mdiAmbulance,
  mdiTruckFast,
  mdiTrainCar,
  mdiFormatListChecks,
  mdiFinance,
  mdiShieldAccountOutline,
  mdiNotebookCheckOutline,
  mdiCogs,
  mdiShareVariant,
  mdiViewGridPlus,
  mdiShapePlus
} from '@mdi/js';

const data = {
  features: [
    {
      id: 1,
      icon: mdiAccountHardHat,
      title: 'Construction',
      description:
        'Track hours spent within job location proximity of on-site engineers. Payments and collaboration with the help of chat and video call simplifies the business further.',
      bgColor: '#e8dfd4',
      color: "#fe940b"
    },
    {
      id: 2,
      icon: mdiTools,
      title: 'Service Providers',
      description:
        'The service providers of housekeeping, baby-sitting, plumbing, home appliances repair and installation can manage daily activities of team with the location-based tracking feature.',
      bgColor: '#d4e4dd',
      color: "#57d39f"
    },
    {
      id: 3,
      icon: mdiAmbulance,
      title: 'Healthcare',
      description: 'The real-time and historic location of a healthcare professional during emergencies like accident, on-field workforce and medical sales representative’s location can be monitored easily.',
      bgColor: '#d6dde6',
      color: "#458be8"
    },
    {
      id: 4,
      icon: mdiTruckFast,
      title: 'Logistics',
      description: 'Operations of supply chain and logistics, the real-time location of cab drivers and delivery executives can be captured with CheckNdOut.',
      bgColor: '#d9e3d8',
      color: "#5eca59"
    },
    {
      id: 5,
      icon: mdiTrainCar,
      title: 'Automobile',
      description: 'The test-drive vehicles and sales professional’s route, the service engineers home pick-up and drop-off can be managed with CheckNdOut.',
      bgColor: '#e8dddd',
      color: "#fe7373"
    },
    {
      id: 6,
      icon: mdiFormatListChecks,
      title: 'Events Management',
      description: 'Monitor with ease hundreds of staff managing multiple events at different locations.',
      bgColor: '#e8dbdf',
      color: "#ff588b"
    },
    {
      id: 7,
      icon: mdiFinance,
      title: 'Financial',
      description: 'Collecting documents or money from the customers is one of the important tasks in the financial sector. The location, meeting notes and tasks of the agents can be easily organized to increase productivity at the corporate level.',
      bgColor: '#e8dfd4',
      color: "#fe940b"
    },
    {
      id: 8,
      icon: mdiShieldAccountOutline,
      title: 'Insurance',
      description: 'Insurance companies can assign location-based incidents to their agents to get real-time location, incident notes and task completion with CheckNdOut.',
      bgColor: '#d4e4dd',
      color: "#57d39f"
    },
    {
      id: 9,
      icon: mdiNotebookCheckOutline,
      title: 'Hospitality',
      description: 'Market aggregators can manage their tasks, attendance and meeting notes of their on-field workforce with CheckNdOut very easily and effectively.',
      bgColor: '#d6dde6',
      color: "#458be8"
    },
    {
      id: 10,
      icon: mdiCogs,
      title: 'Manufacturing',
      description: 'Upfront weekly planning and monthly visits for retails and distributions outlets with scheduled task and also capable of capturing new business opportunities with regular check-in can be done with CheckNdOut.',
      bgColor: '#d9e3d8',
      color: "#5eca59"
    },
    {
      id: 11,
      icon: mdiShareVariant,
      title: 'Marketing Agencies',
      description: 'CheckNdOut helps marketing agencies in automating the daily activities as well as processing their daily and travel allowances. With CheckNdOut, they can manage theirs over time and task location easily.',
      bgColor: '#e8dddd',
      color: "#fe7373"
    },
    {
      id: 12,
      icon: mdiShapePlus,
      title: 'Many more',
      description: 'Any business with tracking requirement can use it. The app has been designed to stay business independent.',
      bgColor: '#e8dbdf',
      color: "#ff588b"
    },
  ],
};
export default data;
