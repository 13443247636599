import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container_FullScreenLoader: {
    position: 'fixed',
    top: -16,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#0a0a0a1a',
    zIndex: 1300,
    minWidth: 200
  },
  loader: {
    padding: '3rem',
    background: "white",
    borderRadius: '1rem',
    textAlign: 'center'
  }
}));