import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTestStyles } from './TestPaper.styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import HeadingBar from '../../components/HeadingBar';
import FormActionButtons from '../../components/FormActionButtons';
import FormBox from '../../components/FormBox';

const TestPaper = () => {
  const styles = useTestStyles();
  const { testId } = useParams();
  const history = useHistory();
  const [test, setTest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [markedForReview, setMarkedForReview] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchTest = async () => {
      try {
        const data = JSON.parse(localStorage.getItem('allTests'));
        if (!data || !Array.isArray(data.test)) {
          throw new Error('No test data found in local storage or incorrect format');
        }
        const selectedTest = data.test.find(t => t.testId === parseInt(testId));
        if (!selectedTest) {
          throw new Error(`Test with ID ${testId} not found`);
        }
        setTest(selectedTest);
        setTimeLeft(selectedTest.duration_minutes * 60);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching test data:', error);
        setLoading(false);
      }
    };

    fetchTest();
  }, [testId]);

  useEffect(() => {
    if (timeLeft === 0) {
      handleSubmit();
    }
    const timer = timeLeft > 0 && setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleNext = () => {
    if (currentQuestionIndex < test.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const toggleMark = (questionIndex) => {
    if (markedForReview.includes(questionIndex)) {
      setMarkedForReview(markedForReview.filter(index => index !== questionIndex));
    } else {
      setMarkedForReview([...markedForReview, questionIndex]);
    }
  };

  const isMarkedForReview = (questionIndex) => {
    return markedForReview.includes(questionIndex);
  };

  const isAnswered = (questionIndex) => {
    return answers.hasOwnProperty(questionIndex);
  };

  const handleAnswerChange = (e) => {
    const { name, value } = e.target;
    setAnswers((prev) => ({ ...prev, [name]: value }));
  };

  const countAnswers = () => {
    const answeredQuestions = Object.keys(answers).length;
    const unansweredQuestions = test.questions.length - answeredQuestions;
    return { answeredQuestions, unansweredQuestions };
  };

  const handleSubmit = () => {
    setOpenDialog(true);
  };

  const handleConfirmSubmit = () => {
    setOpenDialog(false);
    console.log(answers);
    history.push('/test/${testId}/result', { answers, testId, test });
  };

  const handleCancelSubmit = () => {
    setOpenDialog(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!test) {
    return <div>Test not found</div>;
  }

  const question = test.questions[currentQuestionIndex];

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const { answeredQuestions, unansweredQuestions } = countAnswers();

  return (
    <div className={styles.mainbox}>
      <div className={styles.headContainer}>
        <div className={styles.topic}>
          <div>TEST {test.testId} : {test.title}</div>
        </div>
        <div className={styles.time}>{formatTime(timeLeft)} minutes</div>
      </div>

      <div className={styles.headAnsStatus}>
        <div className={styles.ansHead}>ANSWER STATUS</div>
        <div className={styles.gridpattern}>
          {test.questions.map((question, index) => (
            <button
              key={index}
              onClick={() => setCurrentQuestionIndex(index)}
              className={styles.btn}
              style={{
                backgroundColor: isMarkedForReview(index) ? 'yellow' : (isAnswered(question.id) ? 'green' : 'white'),
                color: isAnswered(question.id) ? 'white' : 'black',
                border: isMarkedForReview(index) ? '0.1rem solid #FDDA0D' : '0.1rem solid green'
              }}
            >
              {question.id}
            </button>
          ))}
        </div>
      </div>

      <div className={styles.mainQuestionArea}>
        <div className={styles.questionContainer}>
          {test.questions.map((question, index) => (
            index === currentQuestionIndex && (
              <div key={index}>
                <p><h3>QUESTION {question.id} :</h3></p>
                <div className={styles.quesHeading}>{question.question}</div>
                {question.type === "image" && (
                  <div className={styles.images}>
                    <img src={question.image} alt={`Question ${question.id}`} style={{ maxWidth: '100%', marginBottom: '10px' }} />
                    <input
                      className={styles.imgAns}
                      type="text"
                      name={question.id.toString()}
                      placeholder='Enter your Answer'
                      value={answers[question.id] || ""}
                      onChange={handleAnswerChange}
                    />
                  </div>
                )}
                {question.type === "multiple_choice" && (
                  <div className={styles.optionAnswer}>
                    {question.options.map((option, optionIndex) => (
                      <div key={optionIndex}>
                        <input
                          type="radio"
                          id={option}
                          name={question.id.toString()}
                          value={option}
                          onChange={handleAnswerChange}
                          checked={answers[question.id] === option}
                          style={{ marginBottom: "0.8rem" }}
                        />
                        <label htmlFor={option}>{'\u00A0'}{'\u00A0'}{option}</label>
                      </div>
                    ))}
                  </div>
                )}
                {question.type === "text_reply" && (
                  <input
                    className={styles.ansHeading}
                    type="text"
                    name={question.id.toString()}
                    placeholder='Enter your Answer'
                    value={answers[question.id] || ""}
                    onChange={handleAnswerChange}
                  />
                )}
                {question.type === "number_reply" && (
                  <input
                    className={styles.ansHeading}
                    type="number"
                    name={question.id.toString()}
                    placeholder='Enter your Answer'
                    value={answers[question.id] || ""}
                    onChange={handleAnswerChange}
                  />
                )}
                {question.type === 'video' && (
                  <div className={styles.videoContainer}>
                    <video controls className={styles.video} autoPlay muted>
                      <source src={question.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video> <br />
                    <input
                      className={styles.videoAns}
                      type="text"
                      name={question.id.toString()}
                      placeholder='Enter your Answer'
                      value={answers[question.id] || ""}
                      onChange={handleAnswerChange}
                    />
                  </div>
                )}
              </div>
            )
          ))}
        </div>
      </div>

      <div className={styles.headButtons}>
        <div className={styles.prevNext}>
          <Button className={styles.btn1} onClick={() => toggleMark(currentQuestionIndex)}>Mark for Review</Button>
          <Button className={styles.btn1} onClick={handlePrevious} disabled={currentQuestionIndex === 0}>Previous</Button>
          <Button className={styles.btn1} onClick={handleNext} disabled={currentQuestionIndex === test.questions.length - 1}>Next</Button>
        </div>
        <div>
          <Button className={styles.btn4} onClick={handleSubmit}>Submit</Button>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={handleCancelSubmit}
        classes={{ paper: styles.paper }}
        className={styles.container}
      >
        <form onSubmit={handleConfirmSubmit} className={styles.form}>
          <HeadingBar title="Submit Test" onClose={handleCancelSubmit} />
          <FormBox>
            Number of Questions you have answered : {answeredQuestions}<br /> <br />
            Number of Questions left : {unansweredQuestions} <br /> <br />
            Are you sure you want to submit ?
          </FormBox>
          <FormActionButtons
            rightText="Yes"
            leftText='No'
            leftClick={handleCancelSubmit}
            rightClick={handleConfirmSubmit}
          />
        </form>
      </Dialog>
    </div>
  );
};

export default TestPaper;
