import { Typography, useTheme } from '@material-ui/core';
import { mdiCellphone, mdiDirections, mdiEmail, mdiFormatText, mdiHome, } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { useStyles } from './UserContactDetailsCardStyles';
import { useDispatch, useSelector } from 'react-redux';
import DialogCNO from '../../library/DialogCNO';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { updateEmployeeDetails } from '../../actions/employeeActions';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import RightsManager from '../../utils/RightsManager';
import { ProfileMode } from '../../utils';
import { updateCustomerDetails } from '../../actions/customerActions';
import { getUserCountry } from '../../utils/Helpers';
import PhoneInputCNO from '../../library/PhoneInputCNO';
import { updateServiceProviderDetails } from '../../actions/serviceProviderActions';

function EditContactDetails({
  open,
  onClose,
  profile,
  editablePropertyName,
  isEmployee,
  isServiceProvider,
  localProfilePicUrl
}) {
  const styles = useStyles();

  const country = getUserCountry();

  const [locationData, setLocationData] = useState([]);

  const label = {
    'mapAddress': 'Address',
    'email': 'Email',
    'mobile': 'Mobile',
  };
  const placeholder = {
    'mapAddress': 'Enter address',
    'email': 'Enter email',
    'mobile': 'Enter mobile',
  };

  const getValidation = (property) => {

    const validation = {
      'mapAddress': yup.object().required("Required!!!").nullable(),
      'email': yup.string().email('Please enter a valid email').required("Required!!!"),
      'mobile': yup.string().required("Required!!!"),
    };

    return validation[property];

  };

  const getInitialValues = (property) => {

    const initialValues = {
      'mapAddress': {
        label: profile.mapAddress,
        value: locationData
      },
      'email': profile.email,
      'mobile': profile.mobile,
    };

    return initialValues[property];

  };

  useEffect(() => {

    if (editablePropertyName === 'mapAddress' && profile.mapAddress) {
      const getLocation = async () => {
        await geocodeByAddress(profile.mapAddress)
          .then(results => {
            setLocationData(results);
            console.log("jobLocation: ", results);
          })
          .catch(error => {
            console.error("Location Error: ", error);
            toast.error("Location fetch error!!!");
          });
      };

      if (window.google && window.google.maps) {
        getLocation();
      }
    }

  }, [profile.mapAddress]);

  const dispatch = useDispatch();
  const { allEmployeesLoader: { editLoader: employeeEditLoader } } = useSelector(state => state.employees);
  const { allCustomersLoader: { editLoader: customerEditLoader } } = useSelector(state => state.customers);

  const validationSchema = yup.object({
    [editablePropertyName]: getValidation(editablePropertyName),
  });

  const formik = useFormik({
    initialValues: {
      [editablePropertyName]: getInitialValues(editablePropertyName),
    },
    validationSchema,
    onSubmit: values => handleUpdate(values)
  });

  const handleUpdate = async (values) => {

    let latitude = 0;
    let longitude = 0;
    if (editablePropertyName === 'mapAddress') {
      await geocodeByAddress(values.mapAddress.label)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          latitude = lat;
          longitude = lng;
        });
    }

    let dataToSend = { ...profile, localProfilePicUrl: localProfilePicUrl };

    if (editablePropertyName === 'mapAddress') {
      dataToSend.mapAddress = values.mapAddress.label;
      dataToSend.lat = latitude.toString();
      dataToSend.lng = longitude.toString();
    }
    else {
      dataToSend = {
        ...dataToSend,
        [editablePropertyName]: values[editablePropertyName]
      };
    }

    if (isEmployee) {
      dispatch(updateEmployeeDetails(dataToSend));
    }else if(isServiceProvider){
      dispatch(updateServiceProviderDetails(dataToSend));
    } else {
      dispatch(updateCustomerDetails(dataToSend));
    }

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Contact Details"
      loading={employeeEditLoader || customerEditLoader}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>

        {
          editablePropertyName === 'mapAddress' &&
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            selectProps={{
              styles: {
                option: (provided) => ({
                  ...provided,
                  color: 'brown',
                }),
              },
              value: formik.values[editablePropertyName],
              onChange: (value) => formik.setFieldValue(editablePropertyName, value)
            }}
          />
        }

        {
          editablePropertyName === 'email' &&
          <InputCNO
            name={editablePropertyName}
            label={label[editablePropertyName]}
            formik={formik}
            placeholder={placeholder[editablePropertyName]}
            icon={mdiFormatText}
            fullWidth
          />
        }

        {
          editablePropertyName === 'mobile' &&
          <PhoneInputCNO
            value={formik.values.mobile}
            onChange={(value) => formik.setFieldValue('mobile', value)}
            showHelperText={formik.errors.mobile}
            helperText={formik.errors.mobile}
            onValidatePhone={(error) => {
              if (!!error) {
                console.log("FormActionButtons: inside");
                formik.setFieldError('mobile', error)
              }
            }}
          />
        }

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )
};

function UserContactDetailsCard({ profile, isEmployee,isServiceProvider, isActiveMember, localProfilePicUrl }) {
  const styles = useStyles();

  const theme = useTheme();

  const isUserAdmin = RightsManager.isAdmin();
  const isUserMember = RightsManager.isMember();

  const [editablePropertyName, setEditablePropertyName] = useState(null);
  const [isContactDetailsEditable, setIsContactDetailsEditable] = useState(false);

  return (
    <div className={styles.container_UserContactDetailsCardStyles}>
      <div className={styles.title_wrapper}>
        <Typography className={styles.title}>Contact Details</Typography>
        <a
          href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${profile.mapAddress}`}
          target="_blank"
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
        >
          <Icon
            path={mdiDirections}
            size='2rem'
            className={styles.icon}
            color={theme.colors.GET_DIRECTION_ICON_COLOR}
          />
        </a>
      </div>
      <div className={styles.content}>
        <div
          className={styles.row}
          onClick={() => {
            setIsContactDetailsEditable(true);
            setEditablePropertyName('mapAddress');
          }}
        >
          <Icon path={mdiHome} size="1.5rem" className={styles.icon} />
          <Typography>{profile.mapAddress || 'Click to add Address'}</Typography>
        </div>
        <div
          className={styles.row}
          onClick={() => {
            if (isUserAdmin && !isEmployee && !isServiceProvider && !profile.email) {
              setIsContactDetailsEditable(true);
              setEditablePropertyName('email');
            }
          }}
        >
          <a href={`mailto:${profile.email}`}>
            <Icon path={mdiEmail} size="1.5rem" className={styles.icon} />
          </a>
          <Typography>{profile.email || 'Click to add Email'}</Typography>
        </div>
        <div
          className={styles.row}
          onClick={() => {
            setIsContactDetailsEditable(true);
            setEditablePropertyName('mobile');
          }}
        >
          <a href={`tel:${profile.mobile}`}>
            <Icon path={mdiCellphone} size="1.5rem" className={styles.icon} />
          </a>
          <Typography>{profile.mobile || 'Click to add Mobile'}</Typography>
        </div>
      </div>

      {
        isContactDetailsEditable &&
        (isUserAdmin ||
          (isUserMember && ['mapAddress'].includes(editablePropertyName))
        ) && isActiveMember &&
        <EditContactDetails
          open={isContactDetailsEditable}
          onClose={() => setIsContactDetailsEditable(false)}
          profile={profile}
          editablePropertyName={editablePropertyName}
          isEmployee={isEmployee}
          isServiceProvider={isServiceProvider}
          localProfilePicUrl={localProfilePicUrl}
        />
      }

    </div>
  )
}

export default UserContactDetailsCard
