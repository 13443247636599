import moment from "moment";
import {
  SET_POSTS,
  SET_POST_BY_ID,
  SET_POST_LOADERS,
  SET_POST_SEARCH_PARAMS,
  SET_POST_TIMES_FILTER,
  SET_HAS_MORE_POSTS,
  SET_MEDIAS_UPLOAD_COUNT,
  SET_RESOLVED_POST_MEDIAS,
  TOGGLE_ADD_POST_DIALOG,
  TOGGLE_EDIT_POST_DIALOG,
  SET_POST_REACTIONS,
  SET_POST_COMMENTS,
  SET_COMMENT_INPUT_DETAILS,
  SET_POST_DELETE_LOADER,
  DELETE_POST_BY_ID,
  TOGGLE_IS_DELETE_UNDO,
  SET_POST_HIDE_LOADER,
  SET_DELETED_POSTS,
  SET_HIDDEN_POSTS,
  SHOW_HIDDEN_POSTS,
  
} from "../actions/postActions";
import RightsManager from "../utils/RightsManager";
import { TimesFilter } from "../utils";

const isUserMember = RightsManager.isMember();

const initialState = {
  posts: [],
  deletedPostsList:[],
  hiddenPostsList:[],

  postById: null,

  resolvedPostMedias: [],

  // Loaders
  postsLoader: false,
  morePostsLoader: false,
  quotationsLoader: false,
  postDetailLoader: false,
  addPostLoader: false,
  editPostLoader: false,
  deletePostLoader: false,

  postMediaDeleteLoader: false,
  postMediaFetchLoader: false,
  postMediaUploadLoader: false,
  hidePostLoader:false,
  hasMorePosts: true,
  isAddPostDialog: false,
  isEditPostDialog: false,
  showHiddenPosts:false,
  postTimesFilter: TimesFilter.None,

  postSearchParams: {
    categories: [],
    post: null,
    startDate: null,
    endDate: null,
  },

  mediasUploadCount: 0,

  postReactions: [],
  postReactionsLoader: false,

  postComments: [],
  postCommentsLoader: false,

  commentInputDetails: {
    commentType: "comment",
    parentCommentId: null,
  },

  isPostDeleteUndo: false,
};

export const postReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_POSTS: {
      state = {
        ...state,
        posts: payload,
      };
      return state;
    }

    case SET_DELETED_POSTS: {
      state = {
        ...state,
        deletedPostsList: payload,
      };
      return state;
    }

    case SET_HIDDEN_POSTS: {
      state = {
        ...state,
        hiddenPostsList: payload,
      };
      return state;
    }
    case SET_POST_BY_ID: {
      state = {
        ...state,
        postById: payload,
      };
      return state;
    }

    case SET_POST_LOADERS: {
      const { loaderName, loaderState } = payload;

      state = {
        ...state,
        [loaderName]: loaderState,
      };
      return state;
    }

    case SET_HAS_MORE_POSTS: {
      state = {
        ...state,
        hasMorePosts: payload,
      };
      return state;
    }

    case TOGGLE_ADD_POST_DIALOG: {
      // console.log("dispatched11")

      state = {
        ...state,
        isAddPostDialog: payload,
      };
      return state;
    }

    case TOGGLE_EDIT_POST_DIALOG: {
      console.log("dispatched11", payload);

      state = {
        ...state,
        isEditPostDialog: payload,
      };
      return state;
    }
    case SET_POST_SEARCH_PARAMS: {
      state = {
        ...state,
        postSearchParams: payload,
      };
      return state;
    }

    case SET_POST_TIMES_FILTER: {
      state = {
        ...state,
        postTimesFilter: payload,
      };
      return state;
    }

    case SET_RESOLVED_POST_MEDIAS: {
      state = {
        ...state,
        resolvedPostMedias: payload,
      };
      return state;
    }

    case SET_MEDIAS_UPLOAD_COUNT: {
      state = {
        ...state,
        mediasUploadCount: payload,
      };
      return state;
    }

    case SET_POST_REACTIONS: {
      state = {
        ...state,
        postReactions: payload,
      };
      return state;
    }

    case SET_POST_COMMENTS: {
      const sortedComments = payload.sort((a, b) => {
        const dateA = moment(a.createdAt);
        const dateB = moment(b.createdAt);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      });

      state = {
        ...state,
        postComments: sortedComments,
      };
      return state;
    }

    case SET_COMMENT_INPUT_DETAILS: {
      state = {
        ...state,
        commentInputDetails: payload,
      };
      return state;
    }
    case SET_POST_DELETE_LOADER:
      state = {
        ...state,
        deletePostLoader: payload,
      };
      return state;
    case SET_POST_HIDE_LOADER:
    state = {
      ...state,
      hidePostLoader: payload,
    };
    return state;

    case DELETE_POST_BY_ID:
      const tempPosts = state.posts.filter((post) => post.id !== payload);
      state = {
        ...state,
        posts: tempPosts,
      };
      return state;

    case TOGGLE_IS_DELETE_UNDO:
      state = {
        ...state,
        isPostDeleteUndo: payload,
      };
      return state;
    case SHOW_HIDDEN_POSTS:
    state = {
      ...state,
      showHiddenPosts: payload,
    };
    return state;

    default:
      return state;
  }
};
