import { Box } from '@material-ui/core'
import { Form, Formik } from 'formik';
import React from 'react'
import FormBox from '../../../components/FormBox';
import { ChipCNO, FieldHelperTextCNO, InputCNO, InputLabelCNO, MetaText } from '../../../library';
import FormActionButtons from '../../../components/FormActionButtons';
import { useParams } from 'react-router-dom';
import { isArrayEmpty, isObject } from '../../../utils/Helpers';
import SelectCNO from '../../../library/SelectCNO';
import { AddressRender, AutoCompleteFormRender, CheckBoxRender, DateTimePickerRender, SwitchRender } from '../forms/component';
import { AddDynamicFormsDataMode, FormFieldsTypes } from '../utils/enums';
import { useDispatch } from 'react-redux';
import { addDynamicFormsData, toggleAddDynamicFormsDataDialog } from '../../../actions/formBuilderActions';
import { toast } from 'react-toastify';
import { createYupValidation, generateYupValidation, getFormikInitialValues, getValidationSchema } from '../utils/helpers'
import * as Yup from 'yup';

function FormDesignPreview({
  formDesignerValues = {},
  formikValues = {},
  onClose,
  modes
}) {

  const { isViewMode } = modes;

  const { id } = useParams();

  const dispatch = useDispatch();

  const initialValues = {
    ...getFormikInitialValues(formDesignerValues),
    ...formikValues,
    categories: [],
  };

  console.log("FormDesignPreview: initialValues: ", initialValues)
  // console.log("FormDesignPreview: formDesignerValues: ", formDesignerValues)

  // const validationSchema = Yup.object().shape({
  //   fullName: Yup.string().required("it is required.")
  // });
  // const validationSchema = createYupValidation(formDesignerValues);
  const validationSchema = generateYupValidation(formDesignerValues);

  console.log("FormDesignPreview: validationSchema:", validationSchema);

  const handleFormSubmit = (values) => {

    values = {
      ...values,
      title: "",
      formId: id
    }

    console.log("handleFormDataSubmit : values : ", values);

    dispatch(addDynamicFormsData(values, { onSuccess: onClose }));

  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {
        function FormDesignPreviewForm(formik) {

          // const draftJSON = LocalStorageConstants.getItem(`form_draft_${id}`, { sections: [] });

          const values = formDesignerValues;
          // const values = isArrayEmpty(formDesignerValues) ? draftJSON : formDesignerValues;

          if (isArrayEmpty(values?.sections)) return (
            <Box display='flex' justifyContent='center' mt={4}>
              <MetaText>No form details present, please design your form first...</MetaText>
            </Box>
          )

          console.log("FormDesignPreview: values: ", formik.values);
          console.log("FormDesignPreview: errors: ", formik.errors);
          console.log("FormDesignPreview: touched: ", formik.touched);

          return (
            <Form style={{ padding: 8 }} >

              {
                values?.sections.map((section) => {
                  return (
                    <FormBox title={section.title} style={{ marginBottom: 8 }} >
                      {
                        section.fields.map((field) => {

                          console.log("SelectCNO: inline: ", field.type === FormFieldsTypes.ids.Select && !isArrayEmpty(field.attributes.options))

                          return (
                            <>
                              {
                                field.type === FormFieldsTypes.ids.Text &&
                                <>
                                  <InputCNO
                                    name={field.name}
                                    label={field.label}
                                    placeholder={`Enter ${field.label}`}
                                    formik={formik}
                                    disabled={isViewMode}
                                    multiline={field.attributes.multiline}
                                  />
                                  <FieldHelperTextCNO
                                    errorColor={false}
                                    helperText={field.helperText}
                                    showHelperText
                                  />
                                </>
                              }

                              {
                                field.type === FormFieldsTypes.ids.Chips && !isArrayEmpty(field.attributes.options) &&
                                <>
                                  <InputLabelCNO label={field.label} />
                                  <Box display='flex' alignItems='self' style={{ gap: 8 }} >
                                    {
                                      field.attributes.options.map(chip => (
                                        <ChipCNO
                                          name={chip.title}
                                          icon={chip.icon}
                                          onClick={() => formik.setFieldValue(field.name, chip.id)}
                                          active={formik.values[field.name] === chip.id}
                                          disabled={isViewMode}
                                        />
                                      ))
                                    }
                                  </Box>
                                </>
                              }

                              {
                                field.type === FormFieldsTypes.ids.Select && !isArrayEmpty(field.attributes.options) &&
                                <SelectCNO
                                  name={field.name}
                                  label={field.label}
                                  formik={formik}
                                  options={field.attributes.options}
                                  fullWidth
                                  disabled={isViewMode}
                                />
                              }

                              <AutoCompleteFormRender
                                field={field}
                                formik={formik}

                                disabled={isViewMode}
                              />

                              <DateTimePickerRender
                                field={field}
                                dateValue={formik.values[field.name.date]}
                                timeValue={formik.values[field.name.time]}
                                onChange={({ date, time }) => {
                                  if (field.name.date) formik.setFieldValue(field.name.date, date);
                                  if (field.name.time) formik.setFieldValue(field.name.time, time);
                                }}

                                disabled={isViewMode}
                              />

                              <AddressRender
                                field={field}
                                formik={formik}
                                onChange={({ address, lat, lng }) => {

                                  console.log("AddressRender: field: ", field)
                                  formik.setFieldValue(field.name.address, address);
                                  formik.setFieldValue(field.name.lat, lat);
                                  formik.setFieldValue(field.name.lng, lng);
                                }}

                                disabled={isViewMode}
                              />

                              <SwitchRender
                                field={field}
                                formik={formik}
                                onChange={(value) => formik.setFieldValue(field.name, value)}

                                disabled={isViewMode}
                              />

                              <CheckBoxRender
                                field={field}
                                formik={formik}
                                onChange={(value) => formik.setFieldValue(field.name, value)}

                                disabled={isViewMode}
                              />

                            </>
                          )
                        })
                      }
                    </FormBox>
                  )
                })
              }

              {
                isViewMode
                  ? <FormActionButtons
                    rightText='Edit'
                    leftClick={onClose}
                    rightButtonType='button'
                    rightClick={() => {

                      console.log("handeRightClick: ")

                      dispatch(toggleAddDynamicFormsDataDialog({
                        state: true,
                        mode: AddDynamicFormsDataMode.ids.Edit,
                        formikValues: formik.values
                      }));

                      toast.info('You can start editing data now!')
                    }}
                  />
                  : <FormActionButtons
                    rightText='Save'
                    formik={formik}
                    leftClick={onClose}
                    disabled={isViewMode}
                  />
              }


            </Form>
          )
        }
      }
    </Formik>
  )
}

export default FormDesignPreview
