import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomers,
  fetchMoreCustomers,
} from "../../actions/customerActions";
import { fetchEmployees } from "../../actions/employeeActions";
import AddCustomer from "../../components/AddCustomer/AddCustomer";
import ProfileListings from "../../components/ProfileListings/ProfileListings";
import ScreenView from "../../components/ScreenView/ScreenView";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { ProfileMode } from "../../utils/mappings";
import CustomerFilterModal from "./CustomerFilterModal";

function CustomerListPage() {
  const styles = useStyles();

  const dispatch = useDispatch();
  const {
    customers,
    allCustomersLoader,
    hasMoreCustomers,
    isAddCustomerDialog,
  } = useSelector((state) => state.customers);
  const { customersLoader, addLoader, deleteLoader } = allCustomersLoader;

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (customers.length <= 0) {
      const customersPayload = {
        pageIndex: 0,
        orderBy: "newest",
        searchText: "",
        SortDecending: true,
        resolvePic: true,
      };

      dispatch(fetchCustomers(customersPayload));
    }
  }, []);

  const loadMore = () => {
    if (hasMoreCustomers) {
      const moreEmployeesPayload = {
        pageIndex: page + 1,
        orderBy: "newest",
        searchText: "",
        resolvePic: true,
      };

      dispatch(fetchMoreCustomers(moreEmployeesPayload));

      setPage(page + 1);
    }
  };

  return (
    <div className={styles.container_CustomerListPage}>
      {(deleteLoader || addLoader) && <FullScreenLoaderCNO />}
      <ScreenView
        content={
          <ProfileListings
            mode={ProfileMode.Customer}
            profileList={customers}
            profileLoading={customersLoader}
            loadMore={loadMore}
            hasMore={hasMoreCustomers}
          />
        }
        secondarySidebar={<CustomerFilterModal setPage={setPage} />}
        headerTitle="Customers"
      />

      {isAddCustomerDialog && <AddCustomer />}
    </div>
  );
}

export default CustomerListPage;

const useStyles = makeStyles((theme) => ({
  container_CustomerManagementPage: {
    background: theme.colors.APP_BACKGROUND_COLOR,
  },
}));
