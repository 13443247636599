import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import Heading from '../../../common/components/Heading';
import Button from '../../../common/components/Button';
import Input from '../../../common/components/Input';
import FeatureBlock from '../../../common/components/FeatureBlock';
import Container from '../../../common/components/UI/Container';
import { Icon } from 'react-icons-kit';
import { ic_arrow_forward } from 'react-icons-kit/md/ic_arrow_forward';
import { BannerSquareShape, BannerCircleShape } from '../LandingPage.style';
import ImageGallery from 'react-image-gallery';
import AndroidDownload from '../../../assets/google_play_download.png';
import IosDownload from '../../../assets/ios_app_store_download.png';

import {
  ButtonWrapper,
  DiscountLabel,
  DiscountWrapper,
  EmailInputWrapper,
  VideoWrapper,
} from './banner.style';

import TrackingScreenLight from '../../../common/assets/image/LandingPage/checkndout/TrackingScreenLight.png';
import TrackingScreenDark from '../../../common/assets/image/LandingPage/checkndout/TrackingScreenDark.png';

import FeatureSliderWrapper from '../FeatureSlider/featureSlider.style';
import DialogCNO from '../../../library/DialogCNO';
import { ThemeType } from '../../../utils/mappings';
import colors from '../../../common/theme/LandingPage/colors';
import darkColors from '../../../common/theme/LandingPage/darkThemeColors';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

import ParticlesComponent from '../particles';
import { AppConstants } from '../../../utils/AppConstants';
import { getYoutubeEmbedUrl } from '../../../utils/Helpers';

const ModalContent = ({ videoSrc }) => {

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >

      <iframe
        width={'100%'}
        height="315"
        src={getYoutubeEmbedUrl(videoSrc)}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />

    </div>
  );

}


const DomainSection = ({
  SectionWrapper,
  row,
  col,
  title,
  description,
  button,
  image,
  imageArea,
  btnStyle,
  btnStyleTwo,
  discountAmount,
  discountText,
}) => {

  const videoModalBP = useMediaQuery('(min-width:900px)');

  const [isVideoModal, setIsVideoModal] = useState(false);
  const [videoSrc, setVideoSrc] = useState({
    uri: AppConstants.NEWS_VIDEO,
    modalTitle: "Introduction"
  });

  const handleVideoModal = (video, modalTitle) => {

    setVideoSrc({
      uri: video,
      modalTitle: modalTitle
    });

    setIsVideoModal(true);

  };

  const isDarkTheme = useTheme().palette.type === ThemeType.Dark;

  return (
    <Box {...SectionWrapper}>
      {
        isVideoModal &&
        <DialogCNO
          open={isVideoModal}
          onClose={() => setIsVideoModal(false)}
          height={"60vh"}
          width={videoModalBP ? '90vw' : "50vw"}
          isClickAwayClose={true}
          dialogTitle={videoSrc.modalTitle}
        >
          <ModalContent videoSrc={videoSrc.uri} />
        </DialogCNO>
      }
      <ParticlesComponent />
      <BannerSquareShape />
      <BannerCircleShape />
      <Container>
        <Box {...row}>
          <Box {...col}>
            <Box>
              <DiscountWrapper>
                <DiscountLabel style={{ cursor: 'pointer', boxShadow: 'none' }}>
                  <a href={AppConstants.PLAY_STORE_LINK} target="_blank" rel="noreferrer">
                    <img src={AndroidDownload} alt="" style={{
                      width: '150px',
                      marginRight: '10px',
                      cursor: 'pointer',
                      alignContent: 'center'
                    }} onclick={() => window.open(AppConstants.PLAY_STORE_LINK, '_blank')} />
                  </a>
                  <a href={AppConstants.APP_STORE_LINK} target="_blank" rel="noreferrer">
                    <img src={IosDownload} alt="" style={{
                      width: '150px',
                      marginRight: '10px',
                      cursor: 'pointer',
                      alignContent: 'center'
                    }} onclick={() => window.open(AppConstants.APP_STORE_LINK, '_blank')} />
                  </a>
                </DiscountLabel>
              </DiscountWrapper>
            </Box>
            <Box>
              <DiscountWrapper style={{ cursor: 'pointer' }}>
                <DiscountLabel onClick={() => handleVideoModal(AppConstants.NEWS_VIDEO, "Introduction")}>
                  <Text {...discountAmount} className="discountAmount" />
                  <Text {...discountText} color={isDarkTheme ? darkColors.textColor : colors.textColor} />
                </DiscountLabel>
              </DiscountWrapper>
            </Box>

            <FeatureBlock
              title={<Heading {...title} color={isDarkTheme ? darkColors.headingColor : colors.headingColor} />}
              description={<Text {...description} color={isDarkTheme ? darkColors.textColor : colors.textColor} />}
            />
            {/* <EmailInputWrapper>
              <Input
                inputType="email"
                placeholder="Enter Email Address"
                iconPosition="left"
                aria-label="email"
              />
            </EmailInputWrapper> */}
            <ButtonWrapper>

              <Button
                {...button}
                {...btnStyle}
                onClick={() => handleVideoModal(AppConstants.TELL_ME_MORE_VIDEO, "Introduction")}
              />

              <a href='/help'>
                <Button
                  {...button}
                  {...btnStyleTwo}
                  icon={<Icon icon={ic_arrow_forward} style={{ color: '#fff' }} />}
                //style={{margin:'10px', marginLeft: '-20px', width:'150px'}}
                // onClick={() => handleVideoModal(video2Src, "Demo")}
                />
              </a>

            </ButtonWrapper>
            {/* <VideoGroup>
              <Button
                {...button}
                {...btnStyleTwo}
                icon={<Icon icon={ic_arrow_forward} />}
                className="withoutBg"
                onClick={handleVideoModal}
              />
              <Image
                src={AddJob}
                onClick={handleVideoModal}
                alt="meFolia"
              />
            </VideoGroup> */}
          </Box>

          <Box {...col} mt={'20px'} {...imageArea}>
            <FeatureSliderWrapper style={{ zIndex: 3 }}>
              <Box className="FeatureSlider heroFeatureSlider" style={{ zIndex: 3 }}>
                <ImageGallery
                  items={[
                    {
                      original: `${isDarkTheme ? TrackingScreenDark : TrackingScreenLight}`,
                      originalAlt: 'slide one',
                      originalHeight: '710px',
                      originalWidth: '300px',
                      originalClass: "hero-phone-image-class"
                    },
                  ]}
                  className="Slider-img"
                  showPlayButton={false}
                  showFullscreenButton={false}
                  showNav={false}
                  showBullets={false}
                  autoPlay={false}
                />
                {/* <Image src={TrackingScreenLight} alt="CheckNd'Out Listing" {...image} width='425px' height='845px' /> */}
              </Box>
            </FeatureSliderWrapper>
          </Box>

        </Box>
      </Container>
    </Box>
  );
};

DomainSection.propTypes = {
  SectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  btnStyle: PropTypes.object,
  btnStyleTwo: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
};

DomainSection.defaultProps = {
  SectionWrapper: {
    as: 'section',
    pt: '80px',
    pb: '80px',
    overflow: 'hidden',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '0px',
    width: ['100%', '100%', '50%', '44%', '44%'],
    mt: '-40px',
  },
  imageArea: {
    width: ['100%', '0%', '43%', '35%', '40%'],
    ml: 'auto',
  },
  title: {
    content: 'Remote Tracking Simplified',
    fontSize: ['26px', '30px', '30px', '48px', '60px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.01px',
    mb: '20px',
  },
  description: {
    content: AppConstants.AppSlogan,
    fontSize: '16px',
    color: '#343d48',
    lineHeight: '33px',
    mb: '10px',
  },
  button: {
    title: 'Tell Me More',
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
  image: {
    ml: 'auto',
    mt: '70px',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  btnStyleTwo: {
    title: 'Watch Demo',
    type: 'button',
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    ml: '15px',
    bg: '#4e4e4e',
    color: '#fff',
  },
  textArea: {
    width: ['100%', '100%', '50%', '55%', '55%'],
  },
  discountAmount: {
    content: 'News',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    bg: 'rgb(26, 115, 232)',
  },
  discountText: {
    content: 'Bulk data import coming soon!',
    fontSize: '13px',
    fontWeight: '400',
    color: '#0f2137',
    mb: 0,
    as: 'span',
    ml: '10px',
  },
};

export default DomainSection;
