import { Typography, useTheme } from '@material-ui/core';
import { mdiInformation } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import FormBox from '../FormBox/FormBox';

function DetailsInfoSection({ message, bgColor, icon = {} }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  return (
    <FormBox {...(bgColor ? { bgColor } : {})}>
      <div className={GlobalStyles.detailsRow} style={{ paddingTop: 8 }}>
        <Typography style={{ display: 'flex', alignItems: 'center', color: theme.colors.JOB_START_DATE_COLOR }}>
          <Icon
            path={mdiInformation}
            size="1rem"
            style={{ marginRight: 4 }}
            {...icon}
          />
          {message}
        </Typography>
      </div>
    </FormBox>
  )
}

export default DetailsInfoSection
