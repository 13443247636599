import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomerSuggest,
  fetchMemberSuggest,
  fetchServiceProviderSuggest,
} from "../../actions/suggestionsActions";
import InfiniteScrollCNO from "../../library/InfiniteScrollCNO";
import SearchInputCNO from "../../library/SearchInputCNO/SearchInputCNO";
import { ProfileMode } from "../../utils";
import ProfileListCard from "../ProfileListCard/ProfileListCard";
import ResetListing from "../ResetListing";
import { useStyles } from "./ProfileListingsStyles";
import StickyWrapper from "../../library/StickyWrapper";
import { isFormikEmpty } from "../../utils/Helpers";
import {
  setEmployeeSearchParams,
  toggleAddEmployeeDialog,
} from "../../actions/employeeActions";
import {
  setCustomerSearchParams,
  toggleAddCustomerDialog,
} from "../../actions/customerActions";
import { mdiAccountCog, mdiFaceAgent, mdiHandCoin } from "@mdi/js";
import {
  setServiceProviderSearchParams,
  toggleAddServiceProviderDialog,
} from "../../actions/serviceProviderActions";

function ProfileListings({
  mode,
  profileList,
  profileLoading,
  loadMore,
  hasMore,
}) {
  const tillTablet = useMediaQuery("(max-width: 768px)");

  const theme = useTheme();
  const styles = useStyles();

  const isEmployee = mode === ProfileMode.Employee;
  const isServiceProvider = mode === ProfileMode.ServiceProvider;

  const dispatch = useDispatch();
  const suggestions = useSelector((state) => state.suggestions);

  const { employeeSearchParams } = useSelector((state) => state.employees);
  const { customerSearchParams } = useSelector((state) => state.customers);
  const { serviceProviderSearchParams } = useSelector(
    (state) => state.serviceProviders
  );

  const [profileSearchKey, setProfileSearchKey] = useState("");
  const [searchedProfileList, setSearchedProfileList] = useState([]);

  const employeeInitialValues = {
    employee: null,
    email: "",
    phone: "",
    address: "",
  };
  const serviceProviderInitialValues = {
    serviceProvider: null,
    email: "",
    phone: "",
    address: "",
  };
  const customerInitialValues = {
    customer: null,
    email: "",
    phone: "",
    address: "",
  };

  useEffect(() => {
    if (profileSearchKey.length) {
      setSearchedProfileList(suggestions);
    } else {
      setSearchedProfileList(profileList);
    }
  }, [suggestions, profileSearchKey, profileList]);

  const handleProfileSearch = (searchKey) => {
    setProfileSearchKey(searchKey);

    if (searchKey) {
      isEmployee
        ? dispatch(fetchMemberSuggest(searchKey))
        : isServiceProvider
        ? dispatch(fetchServiceProviderSuggest(searchKey))
        : dispatch(fetchCustomerSuggest(searchKey));
    }
  };

  const initialValues = isEmployee
    ? employeeInitialValues
    : isServiceProvider
    ? serviceProviderInitialValues
    : customerInitialValues;

  const searchParams = isEmployee
    ? employeeSearchParams
    : isServiceProvider
    ? serviceProviderSearchParams
    : customerSearchParams;

  const hasSearched = !isFormikEmpty(initialValues, searchParams);

  const openAddProfileDialog = () => {
    if (isEmployee) {
      dispatch(toggleAddEmployeeDialog(true));
    } else if (isServiceProvider) {
      dispatch(toggleAddServiceProviderDialog(true));
    } else {
      dispatch(toggleAddCustomerDialog(true));
    }
  };

  console.log("searchedProfileList",searchedProfileList)
  return (
    <div className={styles.container_ProfileListings}>
      {tillTablet && (
        <div className={styles.searchToolbar}>
          <SearchInputCNO
            placeholder={
              isEmployee
                ? "Search Employee..."
                : isServiceProvider
                ? "Search Service Provider..."
                : "Search Customer..."
            }
            onSearch={(value) => handleProfileSearch(value)}
          />
        </div>
      )}

      <ResetListing
        isReset={hasSearched}
        onReset={() => {
          if (isEmployee) {
            dispatch(setEmployeeSearchParams(employeeInitialValues));
          } else if (isServiceProvider) {
            dispatch(
              setServiceProviderSearchParams(serviceProviderInitialValues)
            );
          } else {
            dispatch(setCustomerSearchParams(customerInitialValues));
          }
        }}
        wrapperStyle={{ marginTop: 0 }}
      />

      <InfiniteScrollCNO
        items={searchedProfileList}
        next={loadMore}
        hasMore={hasMore}
        loading={profileLoading}
        renderListItem={(profile) => (
          <ProfileListCard key={profile.id} mode={mode} profile={profile} />
        )}
        notFoundTextMessage={
          mode === ProfileMode.Employee
            ? "No Employee Present"
            : mode === ProfileMode.ServiceProvider
            ? "No Service Provider Present"
            : "No Customer Present"
        }
        notFoundIcon={
          isEmployee
            ? mdiAccountCog
            : isServiceProvider
            ? mdiHandCoin
            : mdiFaceAgent
        }
        showListCTAButtons={true}
        listCTAButtonsProps={{
          addTitle: `Add ${
            isEmployee
              ? "Employee"
              : isServiceProvider
              ? "Service Provider"
              : "Customer"
          }`,
          exploreId: isEmployee ? 7 : isServiceProvider ? 9 : 8,
          onClickAdd: openAddProfileDialog,
        }}
      />
    </div>
  );
}

export default ProfileListings;
