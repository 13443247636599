export const TOGGLE_THEME = "TOGGLE_THEME";
export const TOGGLE_LEFT_SIDEBAR = "TOGGLE_LEFT_SIDEBAR";
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const TOGGLE_SEARCH_SIDEBAR = "TOGGLE_SEARCH_SIDEBAR";
export const TOGGLE_SEARCH = "TOGGLE_SEARCH";
export const ADD_JOB = "ADD_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const EDIT_JOB = "EDIT_JOB";
export const FETCH_JOB = "FETCH_JOB";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_COMPANY = "ADD_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const INIT_STATE = "INIT_STATE";