import {
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateService } from "../../actions/serviceActions";
import DialogCNO from "../../library/DialogCNO";
import { getCategory } from "../../utils/Helpers";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import FormActionButtons from "../FormActionButtons";
import JobTypeIcon from "../JobTypeIcon/JobTypeIcon";
import * as yup from "yup";
import InputCNO from "../../library/InputCNO";
import { mdiFormatText } from "@mdi/js";
import { ServiceStatus } from "../../utils/mappings";
import RightsManager from "../../utils/RightsManager";
import { useDebouncedFormik } from "../../hooks";
import { green } from "@material-ui/core/colors";

const EditServiceTypeDialog = ({ open, onClose, service }) => {
  const GlobalStyles = useGlobalStyles();

  let categories = localStorage.getItem("categories");
  categories = categories ? JSON.parse(categories) : [];

  const dispatch = useDispatch();
  const { editServiceLoader } = useSelector((state) => state.services);

  const formik = useFormik({
    initialValues: {
      categories: service.categories,
    },
    validationSchema: yup.object({
      categories: yup.array().required(),
    }),
    onSubmit: (values) => handleUpdate(values),
  });

  const handleUpdate = (values) => {
    console.log("Service edit values: ", values);

    const updateServicePayload = {
      ...service,
      categories: values.categories,
    };

    dispatch(updateService(updateServicePayload));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Service Type"
      loading={editServiceLoader}
    >
      <form onSubmit={formik.handleSubmit}>
        <Select
          fullWidth
          variant="outlined"
          margin="dense"
          onChange={(e) => {
            console.log("upCat: ", e.target.value);
            formik.setFieldValue("categories", [e.target.value]);
          }}
          value={formik.values.categories[0]}
          className={GlobalStyles.dropdown}
        >
          {categories.map((category) => {
            return (
              <MenuItem key={category.id} value={category.id}>
                {category.title}
              </MenuItem>
            );
          })}
        </Select>
        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  );
};

function ServiceMainDetailsCard({ service, mode }) {
  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const isCompanyAdmin = RightsManager.isGroupLeader();
  const isUserMember = RightsManager.isMember();

  const tillTablet = useMediaQuery("(max-width: 767px)");

  const category = getCategory(service?.categories[0]);

  const [isServiceTypeEditable, setIsServiceTypeEditable] = useState(false);
  const [isServiceMainEditable, setIsServiceMainEditable] = useState(false);
  const [editableProperty, setEditableProperty] = useState(null);

  return (
    <div
      className={GlobalStyles.detailsCardContainer}
      style={{ display: "flex" }}
    >
      <JobTypeIcon
        iconLink={category.iconLink}
        name={category.title}
        onClick={() => setIsServiceTypeEditable(true)}
        categoryId={category?.id}
      />
      <div className={styles.details}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            flexDirection: tillTablet ? "column" : "row",
          }}
        >
          <Typography
            variant="h6"
            className={GlobalStyles.cardTitle}
            onClick={() => {
              setIsServiceMainEditable(true);
              setEditableProperty("title");
            }}
          >
            {service.title}
          </Typography>
        </div>
        <pre
          className={styles.description}
          onClick={() => {
            setIsServiceMainEditable(true);
            setEditableProperty("description");
          }}
        >
          {service.description || "Service details not added"}
        </pre>

        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          {service?.budget && (
            <b>
              {`Budget : `}
              <span style={{ color: "green" }}>{service.budget}</span>
            </b>
          )}
          
          {service?.rateByHour && (
            <b>
              {`Hourly Rate : `}
              <span style={{ color: "green" }}>{service.rateByHour}</span>
            </b>
          )}

          {service?.rateByDay && (
            <b>
              {`Daily Rate : `}
              <span style={{ color: "green" }}>{service.rateByDay}</span>
            </b>
          )}
        </div> */}
      </div>

      {isCompanyAdmin && isServiceTypeEditable && (
        <EditServiceTypeDialog
          open={isServiceTypeEditable}
          onClose={() => setIsServiceTypeEditable(false)}
          service={service}
        />
      )}

      {(isUserMember || isCompanyAdmin) && isServiceMainEditable && (
        <EditServiceMainDialog
          open={isServiceMainEditable}
          onClose={() => setIsServiceMainEditable(false)}
          service={service}
          editableProperty={editableProperty}
        />
      )}
    </div>
  );
}

export default ServiceMainDetailsCard;

const EditServiceMainDialog = ({
  open,
  onClose,
  service,
  editableProperty,
}) => {
  if (!service) return <div></div>;

  const labels = {
    title: "Service Title",
    description: "Service Description",
  };

  const placeholder = {
    title: "Enter Service Title",
    description: "Enter Service Description",
  };

  const dispatch = useDispatch();
  const { editServiceLoader } = useSelector((state) => state.services);

  const formik = useFormik({
    initialValues: {
      [editableProperty]: service[editableProperty],
    },
    validationSchema: yup.object({
      [editableProperty]: yup.string().required(),
    }),
    onSubmit: (values) => handleUpdate(values),
  });

  const handleClose = () => {
    formik.resetForm();
    localStorage.removeItem(`editService_${service.id}`);
    onClose();
  };

  const handleUpdate = (values) => {
    console.log("Service edit values: ", values);

    const updateServicePayload = {
      ...service,
      [editableProperty]: values[editableProperty],
    };

    const onAddSuccess = () => handleClose();

    dispatch(updateService(updateServicePayload, onAddSuccess));
  };

  useDebouncedFormik(formik, `editService_${service.id}`);

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Edit Service"
      loading={editServiceLoader}
    >
      <form onSubmit={formik.handleSubmit}>
        <InputCNO
          name={editableProperty}
          label={labels[editableProperty]}
          placeholder={placeholder[editableProperty]}
          formik={formik}
          error={
            formik.touched[editableProperty] &&
            Boolean(formik.errors[editableProperty])
          }
          helperText={formik.errors[editableProperty]}
          icon={mdiFormatText}
          fullWidth
          multiline={editableProperty === "description" ? true : false}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  );
};

const useStyles = makeStyles((theme) => ({
  details: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  description: {
    fontSize: "14px",
    whiteSpace: "pre-wrap",
    minHeight: 15,
    color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR,
  },

  priceDetails: {
    fontSize: "14px",
    whiteSpace: "pre-wrap",
    color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR,
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    marginTop: "10px",
  },
}));
