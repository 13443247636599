import React, { useState } from 'react';
import { InputAdornment, makeStyles, Menu, MenuItem, TextField, Typography, useTheme } from '@material-ui/core';
import { useFormik } from 'formik';
import DialogCNO from '../../library/DialogCNO';
import FormActionButtons from '../FormActionButtons';
import * as yup from 'yup';
import AutocompleteCNO from '../../library/AutocompleteCNO';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerSuggest } from '../../actions/suggestionsActions';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiPlus } from '@mdi/js';
import { toggleAddCustomerDialog } from '../../actions/customerActions';
import AddCustomer from '../AddCustomer/AddCustomer';
import { addInvoice, createJob, getResolvedInvoiceMediasByIds } from '../../actions/invoiceActions';
import { useHistory } from 'react-router-dom';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { setInvoiceItems } from '../../actions/invoiceItemActions';
import { InvoiceDueDateAfter, InvoiceDueDateAfterReverse, InvoiceStatus } from '../../utils/mappings';
import moment from 'moment';
import { DATE_FORMAT } from '../../utils/formatDate';
import InputLabelCNO from '../../library/InputLabelCNO';
import { DatePicker } from '@material-ui/pickers';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { getTitleFromCustomer } from '../../utils/Helpers';
import { ItemType, setMediasPendingToLS, triggerUploadMediasLsBg } from '../../utils/MediaUpload';
import LocalStorageConstants from '../../utils/LocalStorageConstants';

function DuplicateInvoiceDialog({
  open,
  onClose,
  invoice,
  closeMoreActions
}) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const dispatch = useDispatch();
  const suggestions = useSelector(state => state.suggestions);
  const { invoiceItems } = useSelector(state => state.invoiceItems);
  const { allInvoiceLoader: { addLoader, getInvoiceMediaLoader } } = useSelector(state => state.invoices);

  const [dateMenuRef, setDateMenuRef] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const openDatePicker = () => setIsDatePickerOpen(true);
  const closeDatePicker = () => setIsDatePickerOpen(false);

  const openDateMenu = (e) => setDateMenuRef(e.currentTarget);

  const closeDateMenu = () => setDateMenuRef(null);
  const openDateRef = Boolean(dateMenuRef);

  const validationSchema = yup.object({
    customer: yup.object().required("Contact person is required.").nullable(),
    dueDate: yup.string().required('Due date is required'),
  });

  const history = useHistory();

  const onDuplicateSuccess = (invoiceId) => {

    const mediasPending = {
      from: ItemType.Invoice,
      to: ItemType.Invoice,
      medias: invoice.mediaLinks,
      itemId: invoiceId,
    }

    setMediasPendingToLS([mediasPending]);

    handleClose();
    history.push(`/invoice/${invoiceId}`);

    LocalStorageConstants.setItem("mediaPendingLoader", true);
    window.dispatchEvent(new Event("media-pending-loader-storage"));

    triggerUploadMediasLsBg();
  };

  const handleCreateDuplicate = async (values) => {

    console.log("handleCreateDuplicate====>  values", values);

    dispatch(setInvoiceItems([]));

    const localBillDate = new Date();
    const localDueDate = new Date(values.dueDate);
    const localDueInDays = moment(localDueDate).diff(moment(localBillDate), 'days') + 1;

    const payload = {
      ...invoice,
      status: InvoiceStatus.Draft,
      mediaLinks: [],
      customerId: values.customer.id,
      customerText: values.customer.title,
      mapAddress: values.location,
      lat: values.lat,
      lng: values.lng,
      dueInDays: localDueInDays,

      title: getTitleFromCustomer(values.customer, invoice.title)
    };

    console.log("handleCreateDuplicate====>  invoiceItems", invoiceItems);
    console.log("handleCreateDuplicate====>  payload", payload);

    dispatch(addInvoice(payload, invoiceItems, onDuplicateSuccess));

  };

  const formik = useFormik({
    initialValues: {
      customer: {
        id: invoice.customerId,
        title: invoice.customerText
      },
      location: invoice.mapAddress,
      lat: invoice.lat,
      lng: invoice.lng,
      dueDate: moment().add(1, 'days').format(DATE_FORMAT),
    },
    validationSchema,
    onSubmit: (values) => handleCreateDuplicate(values)
  });

  const handleAddCustomerDialog = () => {
    dispatch(toggleAddCustomerDialog(true));
  };

  const handleClose = () => {
    formik.resetForm();
    // dispatch(setSubTasks([]));
    closeMoreActions();
    onClose();
  };

  const handleDueDate = (dueDateType) => {

    let dueDate = new Date();

    switch (dueDateType) {
      case 'OnReceipt':
        dueDate = moment().add(1, 'days').format('MMM DD, YYYY');
        formik.setFieldValue('dueDate', dueDate);
        break;

      case 'In15days':
        dueDate = moment().add(15, 'days').format('MMM DD, YYYY');
        formik.setFieldValue('dueDate', dueDate);
        break;

      case 'DueEndOfMonth':
        dueDate = moment().endOf('month').format('MMM DD, YYYY');
        formik.setFieldValue('dueDate', dueDate);
        break;

      case 'ChooseADate':
        openDatePicker();
        break;

      default:
        break;
    }

    closeDateMenu();

  };

  console.log("DuplicateInvoiceDialog====> ", { open, invoice, values: formik.values });

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle={`Duplicate Invoice - ${invoice.title}`}
      minHeight="fit-content"
      width='50vw'
    >

      {
        (addLoader || getInvoiceMediaLoader) &&
        <FullScreenLoaderCNO />
      }

      <form onSubmit={formik.handleSubmit} >
        <div className={styles.customerWrapper}>
          <AutocompleteCNO
            name='customer'
            label='Contact Person'
            formik={formik}
            placeholder='Search Customer'
            data={suggestions}
            optionLabel={x => x.title || ""}
            renderOption={option => <div style={{ color: '#014663', fontWeight: '500' }}>{option.title}</div>}
            onSuggestionClick={(value) => {

              if (value) {
                formik.setValues({
                  ...formik.values,
                  customer: value,
                  location: value.mapAddress,
                  lat: value.lat,
                  lng: value.lng
                });
              }

            }}
            onChange={(value) => {
              if (value) {
                dispatch(fetchCustomerSuggest(value));
              }
            }}
            isRequired
          />
          <Icon
            path={mdiPlus}
            size='43px'
            color='goldenrod'
            onClick={handleAddCustomerDialog}
          />
        </div>

        <div style={{ marginTop: 8 }}>
          <InputLabelCNO label={'Due Date'} isRequired={true} />
          <TextField
            className={GlobalStyles.textField}
            variant='outlined'
            margin='dense'
            fullWidth
            value={formik.values.dueDate}
            onClick={openDateMenu}
            placeholder="Select Due Date"
            error={formik.touched['dueDate'] && Boolean(formik.errors['dueDate'])}
            helperText={formik.errors['dueDate']}
            InputProps={{
              endAdornment: <InputAdornment>
                <Icon color={theme.colors.INPUT_ICON_COLOR} path={mdiChevronDown} size='1.5rem' />
              </InputAdornment>
            }}
          />

          <Menu
            open={openDateRef}
            anchorEl={dateMenuRef}
            onClose={closeDateMenu}
          >
            {
              Object.keys(InvoiceDueDateAfter).map((date, index) => {
                return (
                  <MenuItem
                    value={index}
                    onClick={() => handleDueDate(date)}
                  >
                    {InvoiceDueDateAfterReverse[index]}
                  </MenuItem>)
              })
            }
          </Menu>

          <DatePicker
            variant="dialog"
            TextFieldComponent={() => null}
            open={isDatePickerOpen}
            onOpen={openDatePicker}
            onClose={closeDatePicker}
            value={formik.values.dueDate}
            onChange={(date) => {
              formik.setFieldValue('dueDate', moment(date).format(DATE_FORMAT));
              closeDatePicker();
            }}
          />
        </div>

        <Typography style={{ marginTop: 20 }}>
          Are you sure you want to create duplicate invoice?
        </Typography>

        <FormActionButtons
          rightText='Yes'
          leftText='No'
          leftClick={handleClose}
          disabled={!formik.isValid}
        />
      </form>

      <AddCustomer
        onAdd={(customer) => {
          console.log("===== customer : add: ", customer);
          const _customer = {
            firstName: customer.firstName,
            id: customer.id,
            isActive: true,
            lastName: customer.lastName,
            title: `${customer.firstName} ${customer.lastName}`,
            profilePic: null
          };
          formik.setFieldValue('customer', _customer);
          formik.setFieldValue('location', customer.mapAddress);
          formik.setFieldValue('lat', customer.lat);
          formik.setFieldValue('lng', customer.lng);
        }}
        invoiceLocation={formik.values.location}
      />

    </DialogCNO>
  )
}

const useStyles = makeStyles((theme) => ({
  customerWrapper: {
    display: 'flex',
    '&>*:first-child': {
      flex: 1
    },
    '&>*:last-child': {
      alignSelf: 'flex-end',
    }
  }
}))

export default DuplicateInvoiceDialog;