import CurrentSubscriptionDummy from './DummyData/SubscriptionDummyData.json';
import SubscriptionDetailsDummy from './DummyData/SubscriptionDetailsDummyData.json';
import { toast } from 'react-toastify';
import LocalStorageConstants from '../utils/LocalStorageConstants';
import axios from 'axios';
import v4 from 'uuid/dist/v4';
import moment from 'moment';
import { generateSubscriptionReceipt } from '../utils/PDFHelpers/Receipts';
import { AppConstants } from '../utils/AppConstants';
import { generatePdf } from '../utils/PDFHelpers/PDFHelpers';
import { getCompanyById, getCompanyProfilePic } from './customerCompanyActions';
import { CompanySubscription, SubscriptionMode } from '../utils';
import { isTrialPeriod } from '../utils/Helpers';

export const SET_CURRENT_SUBSCRIPTION_DETAILS = "SET_CURRENT_SUBSCRIPTION_DETAILS";
export const SET_SUBSCRIPTION_LOADERS = "SET_SUBSCRIPTION_LOADERS";
export const SET_PAYMENT_HISTORY = "SET_PAYMENT_HISTORY";
export const SET_PAYMENT_DUE = "SET_PAYMENT_DUE";
export const SET_HAS_SUBSCRIPTION_EXPIRED = "SET_HAS_SUBSCRIPTION_EXPIRED";
export const SET_PRICING_ITEMS = "SET_PRICING_ITEMS";

export const setPricingItems = (payload) => {
  return (dispatch) => {

    dispatch({
      type: SET_PRICING_ITEMS,
      payload: payload
    });

  };
};

export const setHasSubscriptionExpired = (payload) => {
  return (dispatch) => {

    dispatch({
      type: SET_HAS_SUBSCRIPTION_EXPIRED,
      payload: payload
    });

  };
};

export const setPaymentHistory = (payload) => {
  return (dispatch) => {

    dispatch({
      type: SET_PAYMENT_HISTORY,
      payload: payload
    });

    const authUserCompany = LocalStorageConstants.getItem('authUserCompany');

    LocalStorageConstants.setItem(`paymentHistory-${authUserCompany.id}`, payload);

  };
};

export const setPaymentDue = (payload) => {
  return (dispatch) => {

    dispatch({
      type: SET_PAYMENT_DUE,
      payload: payload
    });

    const authUserCompany = LocalStorageConstants.getItem('authUserCompany');

    LocalStorageConstants.setItem(`paymentDue-${authUserCompany.id}`, payload);

  };
};

export const setCurrentSubscriptionDetails = (payload) => {
  return async (dispatch) => {

    dispatch({
      type: SET_CURRENT_SUBSCRIPTION_DETAILS,
      payload: payload
    });

    localStorage.setItem('currentSubscriptionDetails', JSON.stringify(payload));

  };
};

export const setSubscriptionLoaders = (loaderName, loaderStatus) => {
  return (dispatch) => {
    dispatch({
      type: SET_SUBSCRIPTION_LOADERS,
      payload: { loaderName, loaderStatus }
    });
  };
};

export const fetchPricingItems = () => {
  return async (dispatch, action) => {

    dispatch(setSubscriptionLoaders('subscriptionDetailsLoader', true));

    await axios.get('/data/pricing.json')
      .then((response) => {

        const { pricingItems } = response.data;
        dispatch(setPricingItems(pricingItems));

      })
      .catch(error => {
        console.log("Fetch Pricing Items: Error: ", error);
      })
      .finally(() => {
        dispatch(setSubscriptionLoaders('subscriptionDetailsLoader', false));
      });

  };
};

export const fetchSubscriptionDetails = (subscriptionType) => {
  return async (dispatch, action) => {

    console.log("=========fetchSubscriptionDetails======subscriptionType====", subscriptionType);

    dispatch(setSubscriptionLoaders('subscriptionDetailsLoader', true));

    await axios.get('/data/pricing.json')
      .then(response => {

        if (response.status === 200) {

          const { pricingItems } = response.data;

          console.log("=========fetchSubscriptionDetails======pricingItems====", pricingItems);

          dispatch(setPricingItems(pricingItems));

          const subscriptionDetails = pricingItems.find(item => item.id === subscriptionType);

          console.log("=========fetchSubscriptionDetails======subscriptionDetails====", subscriptionDetails);

          if (!subscriptionDetails) {
            throw `Subscription ${subscriptionType} not found.`;
          }

          dispatch(setCurrentSubscriptionDetails(subscriptionDetails));

        }

      })
      .catch(error => {

        console.error("Fetch Subscription Details: Error: ", error);

      })
      .finally(() => {
        dispatch(setSubscriptionLoaders('subscriptionDetailsLoader', false));
      });

  };
};

export const fetchCurrentSubscriptionDetails = (subscriptionType) => {
  return async (dispatch, getState) => {

    console.log("====fetchCurrentSubscriptionDetails====subscriptionType=", subscriptionType);

    dispatch(setSubscriptionLoaders('subscriptionDetailsLoader', true));

    // const dataToSend = subscriptionType;

    const currentSubscriptionPayload = {
      assignedIds: [],
      categories: [],
      orderBy: "newest",
      pageIndex: 0,
      pageSize: 10,
      searchText: "",
      sortAscending: false,
      startDate: null,
      endDate: null,
      ids: [],
    };


    try {
      const paymentDuePayload = {};
      await dispatch(fetchPaymentDue(paymentDuePayload));

      const { paymentDue } = getState().subscription;

      console.log("=========fetchSubscriptionDetails======paymentDue====", paymentDue);

      await dispatch(fetchSubscriptionDetails(paymentDue.subscriptionType));

    } catch (error) {

    } finally {
      dispatch(setSubscriptionLoaders('subscriptionDetailsLoader', false));
    }

  };
};

export const fetchPaymentHistory = (payload) => {
  return async (dispatch, getState) => {

    const dataToSend = {
      ...payload
    };

    dispatch(setSubscriptionLoaders('fetchPaymentHistoryLoader', true));

    await axios.get('/data/paymentHistory.json')
      .then((response) => {

        if (response.status === 200) {

          // const { data } = response.data;

          const authUserCompany = LocalStorageConstants.getItem('authUserCompany');
          const paymentHistory = LocalStorageConstants.getItem(`paymentHistory-${authUserCompany.id}`);

          dispatch(setPaymentHistory(paymentHistory || []));

        }

      })
      .catch(error => {
        console.error('Fetch: Payment History: Error: ', error);
        toast.error("There is issue in fetching payment history.");
      })
      .finally(() => {
        dispatch(setSubscriptionLoaders('fetchPaymentHistoryLoader', false));
      });

  };
};

export const addPaymentHistory = (payload, onSuccess) => {
  return async (dispatch, getState) => {

    const { paymentHistory } = getState().subscription;

    if (!paymentHistory.length) {
      const payload = {};
      await dispatch(fetchPaymentHistory(payload));
    }

    const dataToSend = {
      ...payload
    };

    dispatch(setSubscriptionLoaders('addPaymentHistoryLoader', true));

    await axios.get('/data/paymentHistory.json')
      .then((response) => {

        if (response.status === 200) {

          const id = v4();
          // const id = response.data;

          const newPaymentHistory = { ...payload, id };

          const { paymentHistory } = getState().subscription;

          console.log("=====addPaymentHistory====paymentHistory==", paymentHistory);

          const _paymentHistory = [newPaymentHistory, ...paymentHistory];

          dispatch(setPaymentHistory(_paymentHistory));

          if (onSuccess) {
            onSuccess(newPaymentHistory);
          }

          return newPaymentHistory;

        }

      }).then((paymentHistory) => {

        const dueDate = moment(paymentHistory.date).add(1, 'month').toISOString();

        const payload = {
          amount: paymentHistory.amount,
          subscriptionType: paymentHistory.subscriptionType,
          dueDate,
        };

        dispatch(addPaymentDue(payload));

      })
      .catch(error => {

        console.error("Add: Payment History: Error: ", error);

        toast.error("There is issue in making payment");

      })
      .finally(() => {
        dispatch(setSubscriptionLoaders('addPaymentHistoryLoader', false));
      });

  };
};

export const fetchPaymentDue = (payload) => {
  return async (dispatch, getState) => {

    dispatch(setSubscriptionLoaders('addPaymentHistoryLoader', true));

    const authUserCompany = LocalStorageConstants.getItem('authUserCompany', null);

    if (!authUserCompany) {
      dispatch(setHasSubscriptionExpired(true));
      return;
    }

    let paymentDateLocal = LocalStorageConstants.getItem(`paymentDue-${authUserCompany.id}`);

    if (!paymentDateLocal) {

      const paymentDate = authUserCompany ? authUserCompany.paymentDate : null;
      const subscriptionType = authUserCompany ? authUserCompany.subscriptionType : CompanySubscription.Platinum;
      const paymentType = authUserCompany ? authUserCompany.paymentType : SubscriptionMode.Monthly;
      const isYearly = paymentType === SubscriptionMode.Yearly ? true : false;

      console.log("====fetchCurrentSubscriptionDetails====setPaymentDue=", { paymentDate, paymentType, isYearly });

      const isFirstLogin = (paymentDate && paymentDate === '0001-01-01T00:00:00') ? true : false;

      console.log("====fetchPaymentDue====isFirstLogin=", isFirstLogin);

      let trialEndDate = null;
      if (isFirstLogin) {
        trialEndDate = moment().add(AppConstants.SUBSCRIPTION_TRIAL_PERIOD, 'days').toISOString();
        console.log("====fetchPaymentDue====trialEndDate=", trialEndDate);
        LocalStorageConstants.setItem(`trialEndDate-${authUserCompany.id}`, trialEndDate);
      }

      const isTrialPeriodActive = isTrialPeriod();

      if (isTrialPeriodActive) {
        trialEndDate = LocalStorageConstants.getItem(`trialEndDate-${authUserCompany.id}`);
      }

      console.log("====fetchPaymentDue====isTrialPeriodActive=", isTrialPeriodActive);

      console.log("====fetchPaymentDue====trialEndDate=", trialEndDate);

      const paymentDuePayload = {
        dueDate: (isFirstLogin || isTrialPeriodActive) ? trialEndDate : moment(paymentDate).add(isYearly ? 12 : 1, 'month').toISOString(),
        subscriptionType
      };
      console.log("====fetchPaymentDue====paymentDuePayload=", paymentDuePayload);

      console.log("====fetchCurrentSubscriptionDetails====paymentDuePayload=", paymentDuePayload);

      dispatch(setPaymentDue(paymentDuePayload));

      paymentDateLocal = paymentDuePayload;
    }

    const dueDate = paymentDateLocal.dueDate;
    const hasSubscriptionExpired = moment(new Date()).isAfter(moment(dueDate));
    dispatch(setHasSubscriptionExpired(hasSubscriptionExpired));
    dispatch(setPaymentDue(paymentDateLocal));

  };
};

export const addPaymentDue = (payload) => {
  return async (dispatch, getState) => {

    dispatch(setSubscriptionLoaders('addPaymentDueLoader', true));

    await axios.get('/data/paymentHistory.json')
      .then((response) => {

        if (response.status === 200) {

          const { data } = response.data;
          const id = v4();

          const newPaymentDue = { ...payload, id, };

          dispatch(setPaymentDue(newPaymentDue));

        }

      })
      .catch(error => {

        console.error("Add: Payment due: Error: ", error);

      })
      .finally(() => {
        dispatch(setSubscriptionLoaders('addPaymentDueLoader', false));
      });

  };
};

export const generateSubscriptionReceiptPdf = (subscription, onGeneratePdfComplete, download) => {
  return async (dispatch, getState) => {

    dispatch(setSubscriptionLoaders('isPdfGenerating', true));

    try {

      let authUserCompany = LocalStorageConstants.getItem('authUserCompany', {});

      await dispatch(getCompanyById([authUserCompany.id]));

      const { companyById } = getState().companies;

      await dispatch(getCompanyProfilePic(companyById.profilePic));

      const { companyProfilePic } = getState().companies;
      const { customerById } = getState().customers;

      const htmlString = generateSubscriptionReceipt(subscription, customerById, companyProfilePic);

      const watermark = {
        text: 'Receipt',
        image: companyProfilePic || AppConstants.DEFAULT_PDF_LOGO
      };

      await generatePdf(`${subscription.title}.pdf`, htmlString, watermark, onGeneratePdfComplete, download);

      if (download) {
        toast.success(`${subscription.title} PDF downloaded successfully.`);
      }

    } catch (error) {
      console.error("Error: PDF Generate: ", error);
      toast.error("Error in generating PDF, please try again.");
    } finally {
      dispatch(setSubscriptionLoaders('isPdfGenerating', false));
    }

  };
};