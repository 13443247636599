import { Typography, useTheme } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

function ResponsiveLinesCNO({
  text,
  maxLine = 1,
  showMoreDetails,
  style,
  showComponentProp = true,
  className,
  ...rest
}) {

  const ResponsiveLines = responsiveHOC()(LinesEllipsis);

  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(!!!maxLine);

  return (
    <Fragment>
      <ResponsiveLines
        text={text}
        title={text}
        maxLine={isExpanded ? 1000 : maxLine}
        ellipsis='...'
        trimRight
        basedOn='words'
        style={{
          ...style,
          whiteSpace: 'pre-wrap'
        }}
        {...(showComponentProp ? { component: Typography } : {})}
        {...rest}
      />

      {
        showMoreDetails && text ? (
          <pre
            style={{ color: theme.colors.LINK_COLOR, textDecoration: 'underline', width: isExpanded ? '100%' : 'auto', textAlign: 'right', fontSize: 12, cursor: 'pointer' }}
            onClick={() => isExpanded ? setIsExpanded(false) : setIsExpanded(true)}
          >
            {isExpanded ? 'Less Details' : 'More Details'}
          </pre>
        ) : null
      }

    </Fragment>
  )
}

export default ResponsiveLinesCNO
