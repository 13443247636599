import { Box, CircularProgress, Typography, useTheme } from '@material-ui/core';
import { mdiDelete, mdiFilePlus } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SnapItem, SnapList, useDragToScroll } from 'react-snaplist-carousel';
import { deleteJobMedia, getMediaLinks, getResolvedJobMediasByIds, processAndUploadImage } from '../actions/jobActions';
import FullScreenLoaderCNO from '../library/FullScreenLoaderCNO';
import DocumentUploadCNO from '../library/DocumentUploadCNO';
import ConfirmYesNoDialog from './ConfirmYesNoDialog/ConfirmYesNoDialog';
import FormBox from './FormBox';
import DocumentSelectDisplayCard from './DocumentTypeDisplayCard/DocumentSelectDisplayCard';
import { useGlobalStyles } from '../utils/Styles/GlobalStyles';
import { ImagesViewerSliderCNO } from '../library/ImagesViewerSliderCNO';
import MediaUploadRetryClearButtons from '../library/MediaUploadRetryClearButtons';

function JobDocumentsDetailsCard({
  job,
  mediaType,
  title,
  isUserAllowed
}) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  console.log("==========JobDocumentsDetailsCard=======jobs=====", job);

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  const dispatch = useDispatch();
  const { mediaLoader, getJobMediaLoader, mediaDeleteLoader, } = useSelector(state => state.jobs);

  const jobDocuments = job.mediaLinks.filter(media => media.mediaType === mediaType);
  const jobMediaLinks = job.mediaLinks;
  console.log("==========JobDocumentsDetailsCard=======jobDocuments=====", jobDocuments);

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isImageViewerVisible, setIsImageViewerVisible] = useState(false);
  const [mediasUploadCount, setMediasUploadCount] = useState(0);
  const [resolvedMediasLocal, setResolvedMediasLocal] = useState([]);

  console.log("=====JobDocumentsDetailsCard===job.id====job.id", job.id);

  useEffect(() => {

    if (jobMediaLinks && jobMediaLinks.length && jobMediaLinks.length > 0) {
      if (jobMediaLinks[0].link.startsWith('http')) {
      } else {
        const payload = {
          mediaLinks: jobMediaLinks
        };
        dispatch(getMediaLinks(payload));
      }
    }

  }, [jobMediaLinks]);

  const handleDocumentUpload = async (files) => {

    if (files && files.length) {
      setMediasUploadCount(files.length);

      const payload = {
        mediasToUpload: files,
        mediaLinks: job.mediaLinks,
        taskId: job.id,
        mediaType: mediaType,
      };

      await dispatch(processAndUploadImage(payload));

    }

  };

  const handleDelete = () => {

    const payload = {
      id: selectedCard.id,
      taskId: job.id,
      mediaLinks: jobMediaLinks,
    };

    dispatch(deleteJobMedia(payload));

  };

  const onResolveMediasByIds = async (resolvedMedia, _, openMedia) => {

    if (openMedia) {
      return;
    }

    if (resolvedMedia) {

      const resolvedMediaIdsLocal = resolvedMediasLocal.map(image => image.id);

      const _resolvedJobMedias = jobDocuments.map((media, index) => {

        if (media.id === resolvedMedia.id) {
          return {
            ...resolvedMedia,
            uri: resolvedMedia.isImage ? resolvedMedia.link : media.link,
            mediaOpenLink: resolvedMedia.link
          };
        } else if (resolvedMediaIdsLocal.includes(media.id)) {

          return resolvedMediasLocal[index];

        } else {
          return {
            ...media,
            uri: media.link,
            thumbnailUri: media.link,
            isResolved: false,
            isImage: false
          };
        }

      });

      console.log("====_resolvedJobMedias=====", _resolvedJobMedias);

      setResolvedMediasLocal(_resolvedJobMedias);
      setIsImageViewerVisible(true);

    }

  };

  console.log("====resolvedMediasLocal=====", resolvedMediasLocal);


  const onChangeImageIndex = (index) => {

    const nextImage = resolvedMediasLocal[index];
    setCurrentImageIndex(index);

    if (nextImage.isResolved) return;

    const payload = {
      clickedMedia: nextImage,
      allMedias: jobDocuments
    };

    dispatch(getResolvedJobMediasByIds(payload, onResolveMediasByIds, false));

  };


  const renderDocumentThumbnail = (file, index) => {
    return (
      <SnapItem key={file.id} margin={{ right: 5, left: 5 }}>
        <div className={GlobalStyles.documentCard}>
          <div
            className={GlobalStyles.documentThumbnail}
            onClick={async () => {

              const payload = {
                clickedMedia: file,
                allMedias: jobDocuments
              };

              dispatch(getResolvedJobMediasByIds(payload, onResolveMediasByIds));

              setCurrentImageIndex(index);

            }}
          >
            <DocumentSelectDisplayCard document={file} />
          </div>
          {
            isUserAllowed &&
            <div className={GlobalStyles.documentActionRow} onClick={(e) => e.stopPropagation()}>
              {
                <div className={GlobalStyles.documentActions} onClick={() => {
                  setIsDeleteConfirm(true);
                  setSelectedCard(file);
                }}>
                  <Icon
                    path={mdiDelete}
                    size='1.5rem'
                    color={theme.colors.ERROR_COLOR}
                  />
                </div>
              }
            </div>
          }
        </div>
      </SnapItem>
    )
  };

  return (
    <FormBox title={title}
      bgColor={theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR}
      titleColor={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR}
    >

      {
        (mediaDeleteLoader || getJobMediaLoader)
        &&
        <FullScreenLoaderCNO />
      }

      {
        (!jobDocuments.length && !mediaLoader && isUserAllowed) ? (
          <div className={GlobalStyles.documentUploadWrapper}>
            <DocumentUploadCNO
              pickerIcon={{
                icon: mdiFilePlus,
                size: '3rem',
                color: theme.colors.IMAGE_UPLOAD_ICON_COLOR
              }}
              multiple={true}
              onUpload={async (files) => {

                console.log("====onUpload====", files);

                handleDocumentUpload(files);

              }}
            />
          </div>
        ) : (
          <Typography align='center'>
            {(jobDocuments.length || mediaLoader) ? '' : 'No document found.'}
          </Typography>
        )
      }

      <div className={GlobalStyles.documentThumbnailWrapper}>
        <SnapList ref={snapList}>
          {
            mediaLoader
              ? (
                [...Array(mediasUploadCount).keys()].map((_, id) => (
                  <SnapItem key={id} margin={{ right: 5, left: 5 }}>
                    <div className={GlobalStyles.documentLoader}>
                      <CircularProgress />
                    </div>
                  </SnapItem>
                ))
              )
              : null
          }
          {
            jobDocuments.length ? (
              jobDocuments.map((media, index) => renderDocumentThumbnail(media, index))
            ) : null
          }
        </SnapList>
      </div>

      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <MediaUploadRetryClearButtons />
        {
          (jobDocuments.length && isUserAllowed) ?
            <div className={GlobalStyles.documentAddMediaAction}>
              <DocumentUploadCNO
                pickerIcon={{
                  icon: mdiFilePlus,
                  size: '1.5rem',
                  color: theme.colors.IMAGE_UPLOAD_ICON_COLOR
                }}
                multiple={true}
                onUpload={async (files) => {

                  console.log("====onUpload====", files);

                  handleDocumentUpload(files);

                }}
              />
            </div> : null
        }
      </Box>

      <ImagesViewerSliderCNO
        images={resolvedMediasLocal.map((image) => ({ ...image, src: image.uri }))}
        currentImageIndex={currentImageIndex}
        isOpen={isImageViewerVisible}
        onClose={() => {
          setIsImageViewerVisible(false);
          setResolvedMediasLocal([]);
        }}
        onChangeImageIndex={onChangeImageIndex}
        isMediaLoading={getJobMediaLoader}
      />

      {
        isDeleteConfirm &&
        <ConfirmYesNoDialog
          open={isDeleteConfirm}
          setOpen={setIsDeleteConfirm}
          message={`Are you sure, you want to delete?`}
          onConfirm={handleDelete}
        />
      }

    </FormBox>
  )
}

export default JobDocumentsDetailsCard;