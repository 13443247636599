import { Tab, Tabs } from '@material-ui/core'
import React, { useState } from 'react'
import { MetaText, TabPanel } from '../../../../library';
import { useGlobalStyles } from '../../../../utils/Styles/GlobalStyles';

const EventDetailsTabs = ({ tabValue, setTabValue }) => {

  const GlobalStyles = useGlobalStyles();


  const handleTabChange = (e, value) => setTabValue(value);

  return (
    <div>
      <Tabs
        classes={{
          indicator: GlobalStyles.activeTabIndicator
        }}
        value={tabValue}
        onChange={handleTabChange}
      >
        <Tab label='About' />
        <Tab label='Discussion' />
      </Tabs>

    </div>
  )
}

export default EventDetailsTabs