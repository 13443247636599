import { Drawer, makeStyles, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { useContentTopSpace } from "../../hooks";
import { useGlobalStore } from "../../providers/GlobalProvider";
import { TOGGLE_LEFT_SIDEBAR } from "../../utils/actions";
import HomePageSidebar from "../HomePageSidebar/HomePageSidebar";

function LeftSidebar() {
  const { globalState, dispatchGlobal } = useGlobalStore();
  const { isLeftSidebar } = globalState;

  const tillTablet = useMediaQuery("(max-width: 768px)");

  const contentTopSpace = useContentTopSpace();

  const route = useRouteMatch();
  const isHomePath = route.path.includes("/home");

  const handleClose = () => {
    dispatchGlobal({
      type: TOGGLE_LEFT_SIDEBAR,
      payload: false,
    });
  };

  const styles = useStyles({ tillTablet, isHomePath, contentTopSpace });
  return (
    <Drawer
      className={clsx(styles.container_LeftSidebar, {
        [styles.drawerOpen]: isLeftSidebar,
        [styles.drawerClose]: !isLeftSidebar && !tillTablet,
      })}
      open={isLeftSidebar}
      variant={tillTablet ? "temporary" : "permanent"}
      // variant={ (tillTablet && isLeftSidebar) ? 'temporary' : 'permanent'}
      anchor="left"
      classes={{
        paper: clsx(styles.paper, {
          [styles.drawerOpen]: isLeftSidebar,
          [styles.drawerClose]: !isLeftSidebar,
        }),
      }}
      onClose={handleClose}
    >
      <HomePageSidebar />
    </Drawer>
  );
}

export default LeftSidebar;

const useStyles = makeStyles((theme) => ({
  container_LeftSidebar: {
    minHeight: "100vh",
    // boxShadow: `2px 4px 8px -2px ${theme.palette.text.disabled}`,
    flexShrink: 0,
    top: ({ contentTopSpace }) => contentTopSpace,
    left: 0,
    zIndex: theme.zIndex.drawer + 2,
    position: "fixed",
    '& ::-webkit-scrollbar': {
      width: '0 !important',
      height: 5
    }
  },
  paper: {
    position: "absolute",
    background: theme.colors.SIDEBAR_BACKGROUND_COLOR,
    minHeight: "100vh",
    // color: theme.palette.text.primary,
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    overflowY: "visible",
    width: ({ fullCollapsed }) => (fullCollapsed ? 0 : theme.spacing(7) + 1),
    //   [theme.breakpoints.up('sm')]: {
    //     width: theme.spacing(7) + 1,
    //   },
  },
}));
