import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { mdiAccount, mdiEmail, mdiPhone } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import AvatarCNO from '../../library/AvatarCNO/AvatarCNO';

function UserSuggestionOption({ user }) {

  const theme = useTheme();
  const styles = useStyles();

  return (
    <div className={styles.container_UserSuggestionOption}>

      <AvatarCNO
        src={user.profilePic}
        fallbackIcon={mdiAccount}
        // iconColor="#014663"
        avatarStyle={{
          background: 'white',
          marginRight: '8px',
          border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}`
        }}
      />

      <div className={styles.infoWrapper}>
        <Typography className={styles.username}>
          {user.title}
        </Typography>
        {
          user && user.email && user.email.length > 0 &&
          <Typography className={styles.row}>
            <Icon path={mdiEmail} size='0.8rem' />
            {user.email}
          </Typography>
        }
        {
          user && user.mobile && user.mobile.length > 0 &&
          <Typography className={styles.row}>
            <Icon path={mdiPhone} size='0.8rem' />
            {user.mobile}
          </Typography>
        }
      </div>
    </div>
  )
}

export default UserSuggestionOption

const useStyles = makeStyles(theme => ({
  container_UserSuggestionOption: {
    display: 'flex',
    color: '#444',
    // width: '100%',
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    border: '2px solid orange',
    marginRight: theme.spacing(1)
  },
  username: {
    color: '#014663',
    fontWeight: '500'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8rem',
    '&>*:first-child': {
      marginRight: theme.spacing(1)
    }
  }
}))