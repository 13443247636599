import { CircularProgress, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMoreQuotations, fetchQuotations, setQuotationSearchParams, handleQuotationSearch, toggleAddQuotationDialog, resetQuotationSearch } from '../../actions/quotationActions';
import AddQuotation from '../../components/AddQuotation/AddQuotation';
import JobCardSkeleton from '../../components/JobCardSkeleton/JobCardSkeleton';
import QuotationListCard from '../../components/Quotation/QuotationListCard';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import InfiniteScrollCNO from '../../library/InfiniteScrollCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import QuotationFilterBar from './QuotationFilterBar';
import StickyWrapper from '../../library/StickyWrapper';
import { isFormikEmpty } from '../../utils/Helpers';
import ResetListing from '../../components/ResetListing';
import { useHistory } from 'react-router-dom';
import { mdiQuora } from '@mdi/js';
import Switcher from '../../common/components/GridListToggle/Switcher';
function QuotationListing({ customer, page, setPage }) {

  const tillTablet = useMediaQuery('(max-width: 768px)');
  const [isGridView,setIsGridView]=useState(false);

  const GlobalStyles = useGlobalStyles();

  const pathname = useHistory().location.pathname;
  const isCustomerDetailsPath = pathname.includes('/customer/');

  const dispatch = useDispatch();

  const { isJobLoader } = useSelector(state => state.jobs);
  const {
    quotations,
    allQuotationLoader,
    isAddQuotationDialog,
    hasMoreQuotations,
    quotationSearchParams
  } = useSelector(state => state.quotations);
  const { quotationsLoader, deleteLoader, addLoader, editLoader } = allQuotationLoader;

  const initialValues = {
    customer: null,
    categories: [],
    quotation: null,
    offerNumber: "",
    sentOn: null,
    validForDays: null,
    statuses: [],
    isToday: false,
  };

  useEffect(() => {

    const payload = {
      customer: customer ? [customer.id] : null,
      pageIndex: page,
      ...quotationSearchParams,
    };

    dispatch(fetchQuotations(payload));
  }, []);

  const loadMore = () => {
    if (hasMoreQuotations) {
      const moreQuotationsPayload = {
        pageIndex: page + 1,
        orderBy: "newest",
        searchText: "",
        customer: customer ? [customer.id] : null
      };

      dispatch(fetchMoreQuotations(moreQuotationsPayload));

      setPage(page + 1);
    }
  };

  console.log("quotations: ", quotations);

  const hasSearched = !isFormikEmpty(initialValues, quotationSearchParams);

  const openAddQuotationDialog = () => {
    dispatch(toggleAddQuotationDialog(true));
  };

  return (
    <div className={GlobalStyles.listingContainer}>

      {
        (deleteLoader || isJobLoader || addLoader || editLoader) &&
        <FullScreenLoaderCNO />
      }

      {
        tillTablet && !isCustomerDetailsPath &&
        <StickyWrapper>
          <QuotationFilterBar customer={customer} />
        </StickyWrapper>
      }

      {
        !isCustomerDetailsPath &&
        <ResetListing
          isReset={hasSearched}
          onReset={() => {
            dispatch(resetQuotationSearch());
          }}
        />
      }
      <Switcher isGridView={isGridView} setIsGridView={setIsGridView} />
      <InfiniteScrollCNO
        items={quotations}
        next={loadMore}
        hasMore={hasMoreQuotations}
        loading={quotationsLoader}
        renderListItem={(quotation) => (
          <QuotationListCard key={quotation.id} quotation={quotation} />
        )}
        notFoundTextMessage={'No Quotation Present'}
        notFoundIcon={mdiQuora}
        showListCTAButtons={true}
        listCTAButtonsProps={{ addTitle: 'Add Quotation', exploreId: 5, onClickAdd: openAddQuotationDialog }}
        isGridView={isGridView} 
        setIsGridView={setIsGridView}
        mode={'quotations'}

      />

      {
        isAddQuotationDialog &&
        <AddQuotation />
      }

    </div>
  )
}

export default QuotationListing
