import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { Button, Icon, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { mdiContentSave, mdiFileCancel } from "@mdi/js";
import { toast } from "react-toastify";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";

export const CheckoutForm = ({
  open,
  onClose,
  profile,
  paymentData,
  selectedSubscription,
  isYearly,
  subscriptionTotalUsers,
  onSuccess,
  onError,
}) => {

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const theme = useTheme();
  const styles = useStyles({ tillTablet });

  const appearance = {
    theme: 'stripe',

    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
      // See all possible variables below
    }
  };

  const stripe = useStripe();
  const elements = useElements({ appearance });

  const [errorMessage, setErrorMessage] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isStripeUILoading, setIsStripeUILoading] = useState(true);

  const handleChange = async (event) => {
    //Listen for changes in the CardElement and display any errors as the customer types their card details
    setDisabled(event.empty);
    setErrorMessage(event.error ? event.error.message : "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("--------------STRIPE OPTIONS----elements---elements---", elements);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    console.log("--------------STRIPE OPTIONS----cardElement---", cardElement);

    setProcessing(true);

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      // payment_method: {
      //   card: cardElement,
      //   billing_details: {
      //     name: profile.title,
      //     address: {
      //       postal_code: '201010', // pass customer postal code
      //     },
      //   }
      // },
      confirmParams: {
        receipt_email: profile.email,
        shipping: {
          address: {
            city: profile.address,
          },
          name: profile.title,
          phone: profile.phone
        }
      },
      redirect: 'if_required'
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage("Payment failed--->", error.message);
      toast.error("Payment Failed! Please try again.");
      setProcessing(false);

      onSuccess(false);
      onError(true, error);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setErrorMessage(null);
      setProcessing(false);
      toast.success("Payment was successfull! Subcription plan is now active.");
      setSucceeded(true);

      onSuccess(true);
      onError(false, null);
    }

  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>

      {
        isStripeUILoading && <Typography>Loading...</Typography>
      }

      {
        processing &&
        <FullScreenLoaderCNO />
      }

      <PaymentElement
        id="payment-element"
        options={{}}
        onChange={handleChange}
        onReady={() => setIsStripeUILoading(false)}
      />
      <div className={styles.ctaWrapper}>
        <Button
          variant='contained'
          className={styles.left}
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          type='submit'
          id="submit"
          variant='contained'
          className={styles.right}
          disabled={processing || disabled || succeeded}
        >
          <pre>Pay</pre>
        </Button>
      </div>
    </form>
  );
}


const useStyles = makeStyles(theme => ({
  container: {
  },
  paper: {
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    borderRadius: 10,
    width: '70vw',
    maxWidth: '90vw',
    margin: ({ tillTablet }) => tillTablet ? 8 : 32
  },
  form: {
    padding: '20px',
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    overflowX: 'hidden',
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`,
    '&>*': {
      marginBottom: theme.spacing(3)
    },
  },

  sectionContainer: {
    '&>*': {
      marginTop: theme.spacing(2)
    }
  },
  jobChipSlider: {
    display: 'flex',
    padding: '5px 0',
    // width: 'calc(100vw - 85px)',
    // overflowX: 'auto',
    '&>*:not(:first-child)': {
      marginLeft: 15
    }
  },
  timeStampWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  picker: {
    '& .MuiInputBase-root': {
      background: 'white',
      color: '#444'
    },
    '& .MuiIconButton-label': {
      color: '#444'
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: 5,
    },
    '& .MuiIconButton-root': {
      paddingLeft: 0,
      paddingRight: 5,
    }
  },
  start: {
    '& .MuiSvgIcon-root': {
      color: '#BE0A02'
    }
  },
  end: {
    '& .MuiSvgIcon-root': {
      color: '#3F8A02'
    }
  },
  time: {
    marginLeft: theme.spacing(2),
    maxWidth: '42%',
  },

  customerNameWrapper: {
    display: 'flex',
    '&>*:first-child': {
      flex: 1
    },
  },
  jobLocationLabel: {
    marginBottom: theme.spacing(1)
  },

  chip: {
    background: theme.palette.background.light
  },
  rateTypeWrapper: {
    '&>*:first-child': {
      marginBottom: theme.spacing(1)
    }
  },
  rateTypeChips: {
    display: 'flex',
    '&>*': {
      marginRight: theme.spacing(1)
    }
  },

  ctaWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(6),
    '& > *': {
      marginLeft: 4
    }
  },
  left: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    // marginRight: theme.spacing(4),
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
  },
  right: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
  },
  draftBtn: {
    background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CANCEL_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_SECONDARY_BUTTON_TEXT_COLOR,
    marginRight: theme.spacing(2)
  },


  textField: {
    borderRadius: 5,
    '& input': {
      background: 'white',
      color: '#444',
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: '#444'
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": "#444",
      caretColor: "#444",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 14
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0
    },
    '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      padding: 0
    }
  },
  assignPaper: {
    background: 'white !important',
    '& ::-webkit-scrollbar': {
      display: 'none'
    }
  },
  noOptions: {
    color: '#BE0A02'
  },
  popper: {
    zIndex: 1301
  },
  assignToWrapper: {
    display: 'flex',
    marginTop: 8,
    flexDirection: 'column',
    '&>*:first-child': {
      flex: 1,
      marginBottom: 8
    }
  },

}))
