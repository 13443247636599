import { makeStyles, Typography } from '@material-ui/core';
import Icon from '@mdi/react';
import React, { Fragment } from 'react';
import DialogCNO from '../../library/DialogCNO';
import { AppConstants } from '../../utils/AppConstants';
import { parseNumber } from '../../utils/Helpers';
import { getMaterialIconString } from '../../utils/icons';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import { Currency, CurrencyIcon } from '../../utils/mappings';
import { getBonusValues } from '../../utils/TrackPayUser';
import * as icons from '@mdi/js';

export default function BonusCalculationDetailsDialog({
  open,
  onClose,
  target,
  values,
  isTaskCompleted
}) {

  const {
    hourlyRate,
    hoursWorked,
    alreadyPaidBonus
  } = values;

  const {
    jobTotalAmount,
    jobNetAmount,
    jobSavings,
    calcBonus,
    isBonusNegative,
    netBonusValue
  } = getBonusValues(target, hourlyRate, hoursWorked, alreadyPaidBonus, isTaskCompleted);

  const styles = useStyles();

  const currencyType = LocalStorageConstants.getItem('currencyType', Currency.GBP);

  const renderCurrency = () => {
    return (
      <Fragment>
        <Icon
          path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
          size="0.9rem"
        />
      </Fragment>
    )
  }

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle={"Bonus Calculation Summary"}
    >

      <div className={styles.section}>
        <div className={styles.row}>
          <Typography className={styles.valueRow}>
            <Typography component={'span'} className={styles.label}>Target Hours:&nbsp;</Typography> <Typography component={'span'} className={styles.value}>{target} hr</Typography>
          </Typography>
          <Typography className={styles.valueRow}>
            <Typography component={'span'} className={styles.label}>Hourly Rate:&nbsp;</Typography> <Typography component={'span'} className={styles.value}>{renderCurrency()} {hourlyRate}</Typography>
          </Typography>
          <Typography className={styles.valueRow}>
            <Typography component={'span'} className={styles.label}>Hours Worked:&nbsp;</Typography> <Typography component={'span'} className={styles.value}>{hoursWorked} hr</Typography>
          </Typography>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.labelWrapper}>
          <Typography className={styles.label}>
            Total Amount
          </Typography>
          <Typography className={styles.formula}>
            (Target Hours x Hourly Rate)
          </Typography>
        </div>
        <Typography className={styles.step}>
          {target} x {hourlyRate} = {renderCurrency()} {parseNumber(jobTotalAmount, 'round')}
        </Typography>
      </div>

      <div className={styles.section}>
        <div className={styles.labelWrapper}>
          <Typography className={styles.label}>
            Billable Amount
          </Typography>
          <Typography className={styles.formula}>
            (Hours Worked x Hourly Rate)
          </Typography>
        </div>
        <Typography className={styles.step}>
          {hoursWorked} x {hourlyRate} = {renderCurrency()} {parseNumber(jobNetAmount, 'round')}
        </Typography>
      </div>

      <div className={styles.section}>
        <div className={styles.labelWrapper}>
          <Typography className={styles.label}>
            Saved Amount
          </Typography>
          <Typography className={styles.formula}>
            (Total Amount - Billable Amount)
          </Typography>
        </div>
        <Typography className={styles.step}>
          {jobTotalAmount} - {parseNumber(jobNetAmount, 'round')} = {renderCurrency()} {parseNumber(jobSavings, 'round')}
        </Typography>
      </div>

      <div className={styles.section}>
        <div className={styles.labelWrapper}>
          <Typography className={styles.label}>
            Bonus Amount
          </Typography>
          <Typography className={styles.formula}>
            ({AppConstants.JOB_BONUS_PERCENTAGE}% of Saved Amount)
          </Typography>
        </div>
        <Typography className={styles.step}>
          {AppConstants.JOB_BONUS_PERCENTAGE}% of {parseNumber(jobSavings, 'round')} = {renderCurrency()} {parseNumber(calcBonus, 'round')}
        </Typography>
      </div>

      <div className={styles.section}>
        <Typography className={styles.valueRow}>
          <Typography component={'span'}>Bonus Amount Already Paid =&nbsp;</Typography> <Typography component={'span'} className={styles.value}>{renderCurrency()} {parseNumber(alreadyPaidBonus, 'round')}</Typography>
        </Typography>
      </div>

      <div className={styles.section}>
        <div className={styles.labelWrapper}>
          <Typography className={styles.label}>
            Bonus Payable
          </Typography>
          <Typography className={styles.formula}>
            (Bonus Amount - Bonus Amount Already Paid)
          </Typography>
        </div>
        <Typography className={styles.step}>
          {parseNumber(calcBonus, 'round')} - {parseNumber(alreadyPaidBonus, 'round')} = {renderCurrency()} {parseNumber(netBonusValue, 'round')} {isBonusNegative && "( Since amount is negative)"}
        </Typography>
      </div>

    </DialogCNO>
  )
}

const useStyles = makeStyles((theme) => ({

  section: {
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    padding: '8px 16px',
    marginBottom: 8,
    borderRadius: 5,
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  labelWrapper: {
    display: 'flex'
  },

  label: {

  },

  value: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.BONUS_CALCULATION_VALUE_COLOR
  },

  formula: {
    marginLeft: 5,
    color: theme.colors.BONUS_CALCULATION_FORMULA_COLOR
  },

  valueRow: {
    display: 'flex',
    alignItems: 'center'
  },

  step: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.BONUS_CALCULATION_STEP_COLOR
  }

}));
