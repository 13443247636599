import { AppConstants } from "./AppConstants";
import { parseNumber } from "./Helpers";

export const getJobTotalAmount = (amount) => {

  let totalAmount = 0;

  amount = !isNaN(amount) ? parseNumber(amount, 'float') : 0;

  totalAmount = amount;

  return {
    amount,
    totalAmount: parseNumber(totalAmount, 'float')
  };

};


export const getTaxValueBasedOnTaxType = (includeTax, amountAfterDiscount, taxRate) => {

  let tax = 0;
  let taxableAmount = 0;

  taxRate = taxRate ? taxRate : 20;

  if (includeTax) {

    taxableAmount = amountAfterDiscount * 100 / (100 + Number(taxRate));

    tax = amountAfterDiscount - taxableAmount;

  } else {
    tax = !isNaN(taxRate) ? parseFloat(Number(taxRate) * Number(amountAfterDiscount) / 100).toFixed(2) : 0;
  }

  tax = parseFloat(tax).toFixed(2);

  return {
    tax,
    taxableAmount,
  };

};


export const getTaxRateBasedOnTaxType = (includeTax, amountAfterDiscount, tax) => {

  let taxRate = 0;

  if (includeTax) {
    amountAfterDiscount = amountAfterDiscount - Number(tax);
  }

  taxRate = amountAfterDiscount ? (
    (parseFloat(Number(tax) * 100) / amountAfterDiscount).toFixed(2)
  ) : 0;

  return parseFloat(taxRate).toFixed(2);

};

export const getTotalAmountValues = (amount, discount, taxRate, includeTax) => {

  taxRate = (taxRate === null || taxRate === undefined) ? AppConstants.DEFAULT_TAX_RATE : taxRate;

  if (includeTax) {
    return getTaxIncludedTotalAmount(amount, discount, taxRate);
  } else {
    return getTaxExcludedTotalAmount(amount, discount, taxRate);
  }

};

export const getTaxIncludedTotalAmount = (amount, discount, taxRate) => {

  let amountAfterDiscount = 0;
  let totalAmount = 0;

  amount = !isNaN(amount) ? parseNumber(amount, 'float') : 0;
  discount = !isNaN(discount) ? parseNumber(discount, 'float') : 0;
  amountAfterDiscount = amount - discount;

  taxRate = !isNaN(taxRate) ? parseNumber(taxRate, 'float') : 0;

  let taxableAmount = amountAfterDiscount * 100 / (100 + Number(taxRate));
  let tax = Number(amountAfterDiscount) - Number(taxableAmount);

  const discountRate = amount ? (discount * 100 / amount) : 0;

  totalAmount = amountAfterDiscount;

  return {
    amount,
    discount,
    discountRate: parseNumber(discountRate, 'float'),
    amountAfterDiscount: parseNumber(amountAfterDiscount, 'float'),
    taxableAmount: parseNumber(taxableAmount, 'float'),
    tax: parseNumber(tax, 'float'),
    taxRate,
    totalAmount: parseNumber(totalAmount, 'float'),
  };

};

export const getTaxExcludedTotalAmount = (amount, discount, taxRate) => {

  let amountAfterDiscount = 0;
  let totalAmount = 0;

  amount = !isNaN(amount) ? parseNumber(amount, 'float') : 0;
  discount = !isNaN(discount) ? parseNumber(discount, 'float') : 0;
  amountAfterDiscount = Number(amount) - Number(discount);
  taxRate = !isNaN(taxRate) ? parseNumber(taxRate, 'float') : 0;

  const discountRate = amount ? (discount * 100 / amount) : 0;

  const tax = taxRate * amountAfterDiscount / 100;

  totalAmount = Number(amountAfterDiscount) + Number(tax);

  return {
    amount,
    discount,
    discountRate: parseNumber(discountRate, 'float'),
    amountAfterDiscount: parseNumber(amountAfterDiscount, 'float'),
    taxableAmount: parseNumber(amountAfterDiscount, 'float'),
    tax: parseNumber(tax, 'float'),
    taxRate,
    totalAmount: parseNumber(totalAmount, 'float'),
  };

};