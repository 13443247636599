import { Typography, useTheme } from '@material-ui/core';
import { mdiAccount, mdiBarcode, mdiEmail, mdiPhone } from '@mdi/js';
import Icon from '@mdi/react';
import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { resetSuggestions } from '../../actions/suggestionsActions';
import AvatarCNO from '../../library/AvatarCNO/AvatarCNO';
import LinkCNO from '../../library/LinkCNO';
import MoreActionCNO from '../../library/MoreActionsCNO/MoreActionCNO';
import CompanyListCardPopover from '../CompanyListCardPopover/CompanyListCardPopover';
import ProfileListCardPopover from '../ProfileListCardPopover/ProfileListCardPopover';
import { useStyles } from './CompanyListCardStyles';

function CompanyListCard({
  profile
}) {

  const theme = useTheme();
  const styles = useStyles();

  const dispatch = useDispatch();

  const isActiveProfile = profile.isActive;

  return (
    <div
      className={styles.container_CompanyListCard}
      onClick={() => dispatch(resetSuggestions())}
    >
      <div className={styles.jobMeta}>
        <div className={styles.avatarWrapper}>
          <AvatarCNO
            src={profile.profilePic}
            fallbackIcon={mdiAccount}
            iconSize="3rem"
            avatarStyle={{ border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}` }}
          />
          {
            !isActiveProfile &&
            <Typography
              component="span"
              className={styles.avatarStatusText}
            >
              Not Active
            </Typography>
          }
        </div>
        <div className={styles.meta}>
          <LinkCNO
            to={`company/${profile.id}`}
          >
            <Typography className={styles.title}>
              {profile.title}
            </Typography>
          </LinkCNO>

          {
            profile.email &&
            <div className={styles.cardInfoRow}>
              <Icon
                path={mdiEmail}
                size={"1rem"}
                color={theme.colors.LIST_CARD_ICON_COLOR}
              />
              <Typography className={styles.cardInfoRowText}>{profile.email}</Typography>
            </div>
          }

          {
            profile.mobile &&
            <div className={styles.cardInfoRow}>
              <Icon
                path={mdiPhone}
                size={"1rem"}
                color={theme.colors.LIST_CARD_ICON_COLOR}
              />
              <Typography className={styles.cardInfoRowText}>{profile.mobile}</Typography>
            </div>
          }

        </div>

        <MoreActionCNO>
          <CompanyListCardPopover
            profile={profile}
          />
        </MoreActionCNO>

      </div>

    </div>
  )
}

export default CompanyListCard
