import React, { useState } from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import LinkCNO from '../../library/LinkCNO';
import { Typography, useTheme } from '@material-ui/core';
import Moment from 'react-moment';
import MoreActionCNO from '../../library/MoreActionsCNO';
import InvoiceStatusButtonBar from './InvoiceStatusButtonBar';
import InvoiceListCardPopover from './InvoiceListCardPopover';
import Icon from '@mdi/react';
import * as icons from '@mdi/js';
import { Currency, CurrencyIcon, InvoiceStatus } from '../../utils/mappings';
import { getMaterialIconString } from '../../utils/icons';
import { useSelector } from 'react-redux';
import JobTypeIcon from '../JobTypeIcon/JobTypeIcon';
import { formatTimeWithTZ, getCategory, parseDisplayNumber } from '../../utils/Helpers';
import PaidStamp from '../../assets/images/paid-stamp-1.png';
import ResponsiveLinesCNO from '../../library/ResponsiveLinesCNO';
import InvoiceGroupedMedia from './InvoiceGroupedMedia';
import clsx from 'clsx';

function InvoiceListCard({ invoice }) {

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const category = getCategory(invoice.categories[0]);

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isSentAccepted = invoice.status === InvoiceStatus.SentAccepted;

  const { isAddInvoiceDialog, mode } = useSelector(state => state.invoices);

  const [editableInvoiceId, setEditableInvoiceId] = useState(false);

  return (
    <div className={clsx(GlobalStyles.listCardContainer, GlobalStyles.listCardFW)}>
      {/* {
        (isJobDeleteLoader || isShareJobLoader) &&
        <FullScreenLoaderCNO />
      } */}
      <div className={GlobalStyles.cardInfoWrapper}>
        <JobTypeIcon
          iconLink={category.iconLink}
          name={category.title}
          categoryId={category?.id}
        />
        <div className={GlobalStyles.cardInfo}>
          <LinkCNO to={`/invoice/${invoice.id}`}>
            <Typography className={GlobalStyles.cardTitle}>{invoice.title}</Typography>
            <Typography className={GlobalStyles.cardTitleHelper}>Invoice# {invoice.invoiceNumber}</Typography>
            <div className={GlobalStyles.datesWrapper}>
              <div className={GlobalStyles.cardDate}>
                <p>Bill Date:&nbsp;</p>
                <Moment format="MMM DD, YYYY" >
                  {formatTimeWithTZ(invoice.billDate)}
                </Moment>
              </div>
              <span>&nbsp; &nbsp;</span>
              <div className={GlobalStyles.cardDate}>
                <p>Due in days:&nbsp;{invoice.dueInDays}</p>
                {/* <Moment format="MMM DD, YYYY" >
                  {new Date(invoice.dueDate)}
                </Moment> */}
              </div>
            </div>
            <Typography style={{ display: 'flex', alignItems: 'center', color: theme.colors.LIST_CARD_USERNAMES_COLOR }}>
              Invoice Amount:
              <Icon
                path={icons[getMaterialIconString(CurrencyIcon[invoice.currency])]}
                size="1rem"
                style={{ marginLeft: 4 }}
              />
              {parseDisplayNumber(invoice.totalAmount)}
            </Typography>
          </LinkCNO>
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          {
            isSentAccepted &&
            <img src={PaidStamp} style={{ width: 60, objectFit: 'contain', marginLeft: '1rem' }} alt="" />
          }
          <MoreActionCNO>
            <InvoiceListCardPopover invoice={invoice} onEditSelect={(invoice) => setEditableInvoiceId(invoice.id)} />
          </MoreActionCNO>
        </div>

      </div>

      {/* <LinkCNO to={`/invoice/${invoice.id}`}>
        <pre className={GlobalStyles.cardDescription}>
          <ResponsiveLinesCNO
            text={invoice.description || "Invoice details not added"}
            maxLine='10'
            ellipsis='...'
            trimRight
            basedOn='words'
          />
        </pre>
      </LinkCNO> */}

      <InvoiceGroupedMedia invoice={invoice} />

      <InvoiceStatusButtonBar invoice={invoice} />

    </div>
  )
}

export default InvoiceListCard
