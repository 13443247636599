import { IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, useTheme } from '@material-ui/core';
import { mdiAccountDetails, mdiCalendarPlus, mdiCancel, mdiCheck, mdiHistory, mdiQuora, mdiSend, mdiStamper, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState ,useEffect} from 'react';
import LinkCNO from '../../library/LinkCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import ConfirmationDialogCNO from '../../library/ConfirmationDialogCNO';
import { useDispatch, useSelector } from 'react-redux';
import { convertAppointmentToJob, convertAppointmentToQuotation, deleteAppointment, updateAppointmentStatus } from '../../actions/appointmentActions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { AppointmentStatus } from '../../utils/mappings';
import RightsManager from '../../utils/RightsManager';
import EditAppointmentTimelineDialog from './EditAppointmentTimelineDialog';
import { useHistory } from 'react-router-dom';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import AppointmentSendDialog from './AppointmentSendDialog';
import { getAppointmentAcceptHtmlString } from '../../pdfTemplates/templates';
import AppointmentRejectDialog from './AppointmentRejectDialog';
import AddToCalendar from '@culturehq/add-to-calendar';
import '@culturehq/add-to-calendar/dist/styles.css';
import './AddToCalendar.styles.css';
import { getCustomerById } from '../../actions/customerActions';
import { ItemType, setMediasPendingToLS, triggerUploadMediasLsBg } from '../../utils/MediaUpload';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import { mdiCardAccountDetailsOutline } from '@mdi/js';
import {setVisitorDetails} from '../../actions/visitorActions';
import { mdiCheckboxMarkedCircleAutoOutline } from '@mdi/js';
import VisitorPassDialogue from './VisitorPassDialogue';

function AppointmentListCardPopover({ appointment, closeMoreActions }) {

  const isUserAdmin = RightsManager.isAdmin();
  const isUserCustomer = RightsManager.isCustomer();

  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const isDraft = appointment.status === AppointmentStatus.Draft;
  const isReady = appointment.status === AppointmentStatus.Ready;
  const isSent = appointment.status === AppointmentStatus.Sent;
  const isSentAccepted = appointment.status === AppointmentStatus.SentAccepted;
  const isSentRejected = appointment.status === AppointmentStatus.SentRejected;

  const isAppointmentDetails = useLocation().pathname.includes("/appointment/");

  const dispatch = useDispatch();
  const { deleteAppointmentLoader, editAppointmentLoader } = useSelector(state => state.appointments);

  const customerId=appointment.customerId;
  console.log("customerId2: ", customerId);

  const { customerById, allCustomersLoader: { customerDetailLoader } } = useSelector(state => state.customers);

  useEffect(() => {
    const payload = {
      ids: [customerId],
      orderBy: "newest"
    };
    dispatch(getCustomerById([customerId], false));
  }, [customerId, dispatch]);

  const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isReadyToShare, setIsReadyToShare] = useState(false);
  const [targetStatus, setTargetStatus] = useState(AppointmentStatus.Draft);
  const [isReschedule, setIsReschedule] = useState(false);
  const [isConvertToJob, setIsConvertToJob] = useState(false);
  const [isConvertToQuotation, setIsConvertToQuotation] = useState(false);
  const [isMarkedComplete,setMarkedComplete]=useState(false);
  const [visitorPass,setVisitorPass]=useState(false);

  const history = useHistory();
  const handleConvertToJob = () => {

    const onConvertToJob = (todoId) => {

      const mediasPending = {
        from: ItemType.Appointment,
        to: ItemType.Job,
        medias: appointment.mediaLinks,
        itemId: todoId,
      }

      setMediasPendingToLS([mediasPending]);

      history.push(`/job/${todoId}`);

      LocalStorageConstants.setItem("mediaPendingLoader", true);
      window.dispatchEvent(new Event("media-pending-loader-storage"));

      triggerUploadMediasLsBg();
    };

    dispatch(convertAppointmentToJob(appointment, onConvertToJob));

  };

  const handleConvertToQuotation = () => {

    const onConvertToQuotation = ({ id }) => {

      const mediasPending = {
        from: ItemType.Appointment,
        to: ItemType.Quotation,
        medias: appointment.mediaLinks,
        itemId: id,
      }

      setMediasPendingToLS([mediasPending]);

      history.push(`/quotation/${id}`);

      LocalStorageConstants.setItem("mediaPendingLoader", true);
      window.dispatchEvent(new Event("media-pending-loader-storage"));

      triggerUploadMediasLsBg();

    };
    dispatch(convertAppointmentToQuotation(appointment, onConvertToQuotation));

  };

  // const routeToVisitorPass=()=>{
  //   const payload={
  //     toMeet:appointment?.customerText ? appointment.customerText :"Customer",
  //     visitorName:appointment?.visitor ? appointment.visitor : "Old Visitor",
  //     mobile:appointment?.mobile ? appointment.mobile :"old mobile",
  //     email:appointment?.email ? appointment.email :"old Email",
  //     purpose:appointment?.title,
  //   }

  //   dispatch(setVisitorDetails(payload));

  //   history.push('/appointments/visitor-pass')
  // }

  let event = {
    name: appointment.title,
    details: appointment.description,
    location: appointment.mapAddress,
    startsAt: new Date(appointment.startTime),
    endsAt: new Date(appointment.endTime)
  };

  return (
    <div className={styles.container_MorePopover}>

      {
        (deleteAppointmentLoader || editAppointmentLoader)
        &&
        <FullScreenLoaderCNO />
      }

      <List className={GlobalStyles.list}>

        {
          !isAppointmentDetails &&
          <LinkCNO
            to={`appointment/${appointment.id}`}
          >
            <ListItem className={GlobalStyles.listItem}>
              <ListItemText primary='Details' />
              <ListItemIcon>
                <IconButton edge='end'>
                  <Icon path={mdiAccountDetails} size='1.5rem'
                    color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </LinkCNO>
        }

        {
          // (isUserAdmin || isUserCustomer) &&
          isUserAdmin &&
          (!isDraft) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setIsReschedule(true);
            }}
          >
            <ListItemText primary='Reschedule Appointment' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiHistory} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }
        {
          // (isUserAdmin || isUserCustomer) &&
          isUserAdmin && 
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setMarkedComplete(true);
            }}
          >
            <ListItemText primary='Mark Appointment Complete' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiCheckboxMarkedCircleAutoOutline} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }
        {
          // (isUserAdmin || isUserCustomer) &&
          isUserAdmin &&
          (!isDraft) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setVisitorPass(true);
            }}
          >
            <ListItemText primary='Visitor Pass' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiCardAccountDetailsOutline} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }
        {
          isUserAdmin &&
          (isDraft) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setTargetStatus(AppointmentStatus.Ready);
              setIsReadyToShare(true);
            }}
          >
            <ListItemText primary='Mark as Ready' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiCheck} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          (isSent || isSentAccepted) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={async () => {
              setTargetStatus(AppointmentStatus.Sent);
              await dispatch(getCustomerById([appointment.customerId]));
              setIsSend(true);
            }}
          >
            <ListItemText primary='Resend Appointment' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiSend} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          (isReady) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={async () => {
              setTargetStatus(AppointmentStatus.Sent);
              await dispatch(getCustomerById([appointment.customerId]));
              setIsSend(true);
            }}
          >
            <ListItemText primary='Send Appointment' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiSend} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          (isSent) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setTargetStatus(AppointmentStatus.SentAccepted);
              setIsAccept(true);
            }}
          >
            <ListItemText primary='Accept Appointment' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiStamper} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          (isSent) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setTargetStatus(AppointmentStatus.SentRejected);
              setIsReject(true);
            }}
          >
            <ListItemText primary='Reject Appointment' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiCancel} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setIsConfirmDeleteModal(true);
            }}
          >
            <ListItemText primary='Delete' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiTrashCan} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          isSentAccepted &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => setIsConvertToJob(true)}
          >
            <ListItemText primary='Convert to Job' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiCalendarPlus} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          isUserAdmin &&
          isSentAccepted &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => setIsConvertToQuotation(true)}
          >
            <ListItemText primary='Convert to Quotation' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiQuora} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {false && <ListItem
          className={GlobalStyles.listItem}
        >
          <AddToCalendar
            event={event}
          >
            {/* Add to external calendar */}
            <ListItem
              className={GlobalStyles.listItem}
              style={{
                padding: 0, margin: 0
              }}
            >
              <ListItemText primary='Add to external calendar' />
              <ListItemIcon>
                <IconButton edge='end'>
                  <Icon path={mdiCalendarPlus} size='1.5rem'
                    color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </AddToCalendar>
        </ListItem>}

      </List>

      {
        isConfirmDeleteModal &&
        <ConfirmationDialogCNO
          open={isConfirmDeleteModal}
          onClose={() => setIsConfirmDeleteModal(false)}
          dialogTitle="Comfirm Delete Appointment"
          messages={["Are you sure you want to delete?"]}
          yesClick={async () => {

            const onDeleteAppointment = () => {
              if (isAppointmentDetails) {
                history.push('/appointments');
              }
            };

            try {
              await dispatch(deleteAppointment(appointment));
              onDeleteAppointment();
            } catch (error) {
              console.log("Error: Appointment delete error");
            }

          }}
          rightText="Delete"
          closeMoreActions={closeMoreActions}
        />
      }

      {
        (isAccept || isReadyToShare || isReject) &&
        <ConfirmationDialogCNO
          open={isAccept || isReadyToShare || isReject}
          onClose={() => {
            setIsAccept(false);
            setIsReadyToShare(false);
            setIsSend(false);
            setIsReject(false);
          }}
          dialogTitle="Update Appointment Status"
          messages={[
            isAccept ? "Are you sure you want to accept the appointment?" : null,
            isReadyToShare ? "Are you sure draft is ready to share?" : null,
            isReject ? "Are you sure you want to reject the appointment?" : null,
          ]}
          yesClick={() => {

            const meta = getAppointmentAcceptHtmlString(appointment);

            const appointmentPayload = {
              ...appointment,
              status: targetStatus,
              meta: meta || ""
            };

            dispatch(updateAppointmentStatus(appointmentPayload));

          }}
          rightText={"Yes"}
          closeMoreActions={closeMoreActions}
        />
      }

      {
        isSend &&
        <AppointmentSendDialog
          open={isSend}
          onClose={() => setIsSend(false)}
          appointment={appointment}
          closeMoreActions={closeMoreActions}
        />
      }

      {
        isReject &&
        <AppointmentRejectDialog
          open={isReject}
          onClose={() => setIsReject(false)}
          appointment={appointment}
          closeMoreActions={closeMoreActions}
        />
      }

      {
        isConvertToJob &&
        <ConfirmationDialogCNO
          open={isConvertToJob}
          onClose={() => {
            setIsConvertToJob(false);
          }}
          dialogTitle="Convert to Job"
          messages={[
            "Are you sure you want to convert to job?"
          ]}
          yesClick={handleConvertToJob}
          rightText={"Convert to Job"}
          closeMoreActions={closeMoreActions}
        />
      }

      {
        isConvertToQuotation &&
        <ConfirmationDialogCNO
          open={isConvertToQuotation}
          onClose={() => {
            setIsConvertToQuotation(false);
          }}
          dialogTitle="Convert to Quotation"
          messages={[
            "Are you sure you want to convert to quotation?"
          ]}
          yesClick={handleConvertToQuotation}
          rightText={"Convert to Quotation"}
          closeMoreActions={closeMoreActions}
        />
      }

      {
        // (isUserAdmin || isUserCustomer) &&
        isUserAdmin &&
        isReschedule &&
        <EditAppointmentTimelineDialog
          open={isReschedule}
          onClose={() => setIsReschedule(false)}
          appointment={appointment}
          closeMoreActions={closeMoreActions}
          isReschedule={true}
        />
      }

      {
        // (isUserAdmin || isUserCustomer) &&
        isUserAdmin &&
        isMarkedComplete &&
        <EditAppointmentTimelineDialog
          open={isMarkedComplete}
          onClose={() => setMarkedComplete(false)}
          appointment={appointment}
          closeMoreActions={closeMoreActions}
          isMarkedComplete={true}
          
        />
      }

      {
        // (isUserAdmin || isUserCustomer) &&
        isUserAdmin &&
        visitorPass &&
        <VisitorPassDialogue
          open={visitorPass}
          onClose={() => setVisitorPass(false)}
          appointment={appointment}
          closeMoreActions={closeMoreActions}
          visitorPass={true}
          
        />
      }

    </div >
  )
}

export default AppointmentListCardPopover

const useStyles = makeStyles(theme => ({
  container_MorePopover: {
    padding: 5,
    overflow: 'auto',
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR
  },
}));