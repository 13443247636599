import { mdiArrowLeft, mdiDotsVertical } from '@mdi/js';
import React from 'react';
import { useStyles } from './MobileHeaderStyles';
import Icon from "@mdi/react";
import { Typography, useMediaQuery } from '@material-ui/core';
import AppLogo from '../../assets/appLogo.png';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import MoreActionsCNO from '../../library/MoreActionsCNO';
import EmployeeListHeaderMorePopover from '../../pages/EmployeeManagement/EmployeeListHeaderMorePopover';
import CustomerListHeaderMorePopover from '../../pages/CustomerManagement/CustomerListHeaderMorePopover';
import CompanyListHeaderMorePopover from '../../pages/CompanyManagement/CompanyListHeaderMorePopover';
import QuotationListHeaderPopover from '../QuotationListHeaderPopover/QuotationListHeaderPopover';
import MoreActionCNO from '../../library/MoreActionsCNO';
import InvoiceListHeaderPopover from '../Invoice/InvoiceListHeaderPopover';
import { handleBackButton } from '../../utils/Helpers';
import DashboardHeaderActionsPopover from '../../pages/Dashboard/DashboardHeaderActionsPopover';

function MobileHeader({ title }) {
  const tillTablet = useMediaQuery("(max-width: 768px)");
  const styles = useStyles({ tillTablet });

  const route = useRouteMatch();
  const isEmployeesListPath = route.path.includes('/employees');
  const isCustomersListPath = route.path.includes('/customers');
  const isCompaniesListPath = route.path.includes('/companies');
  const isQuotationListPath = route.path.includes('/quotations');
  const isInvoiceListPath = route.path.includes('/invoices');
  const isDashboardPath = route.path.includes('/dashboard');


  const history = useHistory();
  const _handleBackButton = () => handleBackButton(history);

  return (
    <div className={styles.container_MobileHeader}>
      <Icon
        path={mdiArrowLeft}
        size="1.5rem"
        onClick={_handleBackButton}
      />
      <Typography className={styles.title}>{title}</Typography>

      {
        isEmployeesListPath &&
        <MoreActionsCNO
          moreActionIcon={mdiDotsVertical}
        >
          <EmployeeListHeaderMorePopover />
        </MoreActionsCNO>
      }

      {
        isCustomersListPath &&
        <MoreActionsCNO
          moreActionIcon={mdiDotsVertical}
        >
          <CustomerListHeaderMorePopover />
        </MoreActionsCNO>
      }

      {
        isCompaniesListPath &&
        <MoreActionsCNO
          moreActionIcon={mdiDotsVertical}
        >
          <CompanyListHeaderMorePopover />
        </MoreActionsCNO>
      }

      {
        isQuotationListPath &&
        <MoreActionsCNO
          moreActionIcon={mdiDotsVertical}
        >
          <QuotationListHeaderPopover />
        </MoreActionsCNO>
      }

      {
        isInvoiceListPath &&
        <MoreActionCNO>
          <InvoiceListHeaderPopover />
        </MoreActionCNO>
      }

      {
        isDashboardPath &&
        <DashboardHeaderActionsPopover />
      }

    </div>
  )
}

export default MobileHeader
