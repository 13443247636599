import React, { useEffect, useState } from 'react'
import { FormFieldsTypes } from '../../utils/enums'
import { isArrayEmpty } from '../../../../utils/Helpers'
import { ChipCNO, InputLabelCNO } from '../../../../library'
import { Box } from '@material-ui/core'
import FormFieldActions from '../FormFieldActions'

const ChipsGroupFormRender = ({ field, formik, onEdit, onDelete }) => {

  const [values, setValues] = useState(formik.values[field.name]);

  useEffect(() => {
    setValues(field?.attributes?.defaultValue);
  }, [field?.attributes?.defaultValue]);

  if (field.type === FormFieldsTypes.ids.Chips)
    console.log("ChipFieldAttributes : field : ", field)

  return (
    <>
      {
        field.type === FormFieldsTypes.ids.Chips && !isArrayEmpty(field.attributes.options) &&

        <Box display='flex' alignItems='flex-start' >
          <Box width='100%' >
            <InputLabelCNO label={field.label} />
            <Box display='flex' alignItems='self' style={{ gap: 8 }} >
              {
                field.attributes.options.map(chip => (
                  <ChipCNO
                    key={chip.id}
                    name={chip.title}
                    icon={chip.icon}
                    onClick={() => setValues(values === chip.id ? null : chip.id)}
                    active={values === chip.id}
                  />
                ))
              }
            </Box>
          </Box>
          <FormFieldActions fieldInitialValues={field} onEdit={onEdit} onDelete={onDelete} />
        </Box>
      }
    </>
  )
}

export default ChipsGroupFormRender