import { toast } from "react-toastify";
import {
  apiMedia,
  apiTaskAssign,
  apiTaskCreate,
  apiTaskEdit,
  apiTaskRemove,
  apiTaskSearch,
} from "../api";
import { filterJobsByUnFinished } from "../utils/ChatUtils/Helpers";
import { addMultipleSubTasks, addSubTask } from "./subTaskActions";
import {
  b64toBlob,
  getContentType,
  getFileDataAsync,
  getFileTypeFromMimeType,
  getHoursFromTimeline,
  getImageThumbnail,
  getMediaData,
  getThumbnailBlob,
  parseNumber,
} from "../utils/Helpers";
import { v4 } from "uuid";
import { apiInitUpload } from "../api/apiInitUpload";
import { apiUploadMedia } from "../api/apiUploadMedia";
import { RateType, MediaType } from "../utils";
import { FileType, MediaSizeType } from "../utils/mappings";
import moment from "moment";
import { AppConstants } from "../utils/AppConstants";
import { combineDateAndTime } from "../utils/Tracking";
import { handleAssignRemoveChatGroupUsers } from "./chatActions";
export const CREATE_JOB = "CREATE_JOB";
export const SET_JOB_SEARCH_MANAGER = "SET_JOB_SEARCH_MANAGER";
export const DELETE_JOB_BY_ID = "DELETE_JOB_BY_ID";
export const UNDO_DELETE = "UNDO_DELETE";
export const TOGGLE_SHARE_JOB_MODAL = "TOGGLE_SHARE_JOB_MODAL";
export const SHARE_JOB = "SHARE_JOB";
export const TOGGLE_IS_DELETE_UNDO = "TOGGLE_IS_DELETE_UNDO";
export const SET_JOB_DELETE_LOADER = "SET_JOB_DELETE_LOADER";
export const SET_SHARE_JOB_LOADER = "SET_SHARE_JOB_LOADER";
export const SET_JOB_LOADER = "SET_JOB_LOADER";
export const SET_TASK_BY_ID = "SET_TASK_BY_ID";
export const SET_TODO_SHARE_LOADER = "SET_TODO_SHARE_LOADER";
export const SET_JOB_TIMES_FILTER = "SET_JOB_TIMES_FILTER";
export const SET_IS_JOB_FINISHED_FILTER = "SET_IS_JOB_FINISHED_FILTER";
export const SET_JOB_MEDIA_LOADER = "SET_JOB_MEDIA_LOADER";
export const SET_JOB_MEDIA_UPLOAD_LOADER = "SET_JOB_MEDIA_UPLOAD_LOADER";
export const SET_TASK_LOADERS = "SET_TASK_LOADERS";
export const SET_HAS_MORE_TODOS = "SET_HAS_MORE_TODOS";
export const SET_TODOS = "SET_TODOS";
export const SET_RESOLVED_JOB_MEDIAS = "SET_RESOLVED_JOB_MEDIAS";
export const TOGGLE_ADD_JOB_DIALOG = "TOGGLE_ADD_JOB_DIALOG";

export const toggleAddJobDialog = (payload) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_JOB_DIALOG,
      payload: payload,
    });
  };
};

export const setResolvedJobMedias = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_RESOLVED_JOB_MEDIAS,
      payload: payload,
    });
  };
};

export const setJobSearchParams = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_JOB_SEARCH_MANAGER,
      payload: payload,
    });
  };
};

export function setJobMediaLoader(status) {
  return (dispatch) => {
    dispatch({
      type: SET_JOB_MEDIA_LOADER,
      payload: status,
    });
  };
}

export function setJobMediaDeleteLoader(status) {
  return (dispatch) => {
    dispatch({
      type: SET_JOB_MEDIA_UPLOAD_LOADER,
      payload: status,
    });
  };
}

export function setJobTimesFilter(value) {
  return (dispatch) => {
    dispatch({
      type: SET_JOB_TIMES_FILTER,
      payload: value,
    });
  };
}

export function setJobLoader(status) {
  return (dispatch) => {
    dispatch({
      type: SET_JOB_LOADER,
      payload: status,
    });
  };
}

export const setTodos = (payload) => {
  return (dispatch) => {
    console.log("=====setTodospayload", payload);

    dispatch({
      type: SET_TODOS,
      payload: payload,
    });
  };
};

export function setTaskLoaders(loaderName, loaderState) {
  return (dispatch) => {
    dispatch({
      type: SET_TASK_LOADERS,
      payload: { loaderName, loaderState },
    });
  };
}

export const setHasMoreTodos = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_MORE_TODOS,
      payload: payload,
    });
  };
};

export function setTaskById(payload, include = false) {
  return (dispatch, getState) => {
    if (payload) {
      dispatch({
        type: SET_TASK_BY_ID,
        payload: payload,
      });

      const { jobs } = getState().jobs;

      const updatedTodos = jobs.map((job) =>
        job.id === payload.id ? payload : job
      );

      dispatch(setTodos(updatedTodos));
    } else {
      dispatch({
        type: SET_TASK_BY_ID,
        payload: payload,
      });
    }
  };
}

export const fetchTodos = (payload = {}) => {
  return async (dispatch, getState) => {
    const { jobSearchParams, isJobUnfinishedFilter, isJobLoader } =
      getState().jobs;

    dispatch(setTaskLoaders("todosLoader", true));
    dispatch(setHasMoreTodos(true));

    console.log("fetchTodos: jobSearchParams ", jobSearchParams);

    const dataToSend = {
      customerIds: jobSearchParams.customer
        ? [jobSearchParams.customer.id]
        : null,
      searchText: jobSearchParams.searchText
        ? jobSearchParams.searchText.title
        : "",
      categories: jobSearchParams.categories,
      startDate: jobSearchParams.startDate
        ? moment(jobSearchParams.startDate).toISOString()
        : null,
      // endDate: jobSearchParams.endDate ? jobSearchParams.endDate.toISOString() : null,
      assignedIds: jobSearchParams.assignedUsers
        ? [jobSearchParams.assignedUsers.id]
        : [],
      statuses: jobSearchParams.statuses ? jobSearchParams.statuses : [],
      orderBy: "newest",
      pageIndex: 0,
      pageSize: 10,
      sortAscending: false,
      ids: [],
      ...payload,
      endDate: payload.endDate
        ? payload.endDate
        : jobSearchParams.jobEndDate
        ? moment(jobSearchParams.jobEndDate).toISOString()
        : moment().add(5, "year").endOf("day").toDate(),
    };

    console.log("fetchTodos: dataToSend ", dataToSend);
    dispatch(setJobLoader(true));
    await apiTaskSearch
      .post(dataToSend)
      .then((response) => {
        if (response && response.status === 200) {
          let todosResponse = response.data.data;

          todosResponse = todosResponse.map((todo) => {
            const amount = parseNumber(todo.rate, "float");
            const totalAmount = amount;

            return {
              ...todo,
              amount,
              totalAmount,
            };
          });

          if (isJobUnfinishedFilter) {
            todosResponse = filterJobsByUnFinished(todosResponse);
          }
          dispatch(setTodos(todosResponse));

          if (todosResponse.length < 10) {
            dispatch(setHasMoreTodos(false));
          }
        } else {
          console.log("Error: Fetch todos response: ", response);
          // toast.error('There is some issue in fetching job!');
        }
      })
      .catch((error) => {
        console.log("Error: Fetch todos: ", error);
        dispatch(setHasMoreTodos(false));
        // toast.error('There is some issue in fetching job!');
      })
      .finally(() => {
        dispatch(setTaskLoaders("todosLoader", false));
      });
    dispatch(setJobLoader(false));
  };
};

export const fetchMoreTodos = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setTaskLoaders("moreTodosLoader", true));
    dispatch(setHasMoreTodos(true));

    const { jobSearchParams, isJobUnfinishedFilter } = getState().jobs;

    const dataToSend = {
      customerIds: jobSearchParams.customer
        ? [jobSearchParams.customer.id]
        : null,
      searchText: jobSearchParams.searchText
        ? jobSearchParams.searchText.title
        : "",
      categories: jobSearchParams.categories,
      startDate: jobSearchParams.startDate
        ? moment(jobSearchParams.startDate).toISOString()
        : null,
      // endDate: jobSearchParams.endDate ? jobSearchParams.endDate.toISOString() : null,
      assignedIds: jobSearchParams.assignedUsers
        ? [jobSearchParams.assignedUsers.id]
        : [],
      statuses: jobSearchParams.statuses ? jobSearchParams.statuses : [],
      orderBy: "newest",
      pageIndex: 0,
      pageSize: 10,
      sortAscending: false,
      ids: [],
      ...payload,
      endDate: jobSearchParams.endDate
        ? moment(jobSearchParams.endDate).toISOString()
        : moment().add(5, "year").endOf("day").toDate(),
    };

    console.log("fetchMoreTodos: dataToSend ", dataToSend);

    await apiTaskSearch
      .post(dataToSend)
      .then((response) => {
        if (response && response.status === 200) {
          let moreTodosResponse = response.data.data;

          moreTodosResponse = moreTodosResponse.map((todo) => {
            const amount = parseNumber(todo.rate, "float");
            const totalAmount = amount;

            return {
              ...todo,
              amount,
              totalAmount,
            };
          });

          const { jobs } = getState().jobs;
          let todosPayload = [...jobs, ...moreTodosResponse];

          if (isJobUnfinishedFilter) {
            todosPayload = filterJobsByUnFinished(todosPayload);
          }
          dispatch(setTodos(todosPayload));

          if (moreTodosResponse.length < 10) {
            dispatch(setHasMoreTodos(false));
          }
        } else {
          console.log("Error: Fetch more todos response: ", response);
        }
      })
      .catch((error) => {
        console.log("Error: Fetch more todos: ", error);
        dispatch(setHasMoreTodos(false));
      })
      .finally(() => {
        dispatch(setTaskLoaders("moreTodosLoader", false));
      });
  };
};

export function getTaskById(taskId, offlineData) {
  return async (dispatch, getState) => {
    dispatch(setJobLoader(true));

    const dataToSend = {
      ids: [taskId],
      assignedIds: [],
      categories: [],
      orderBy: "newest",
      pageIndex: 0,
      pageSize: 10,
      searchText: "",
      sortAscending: false,
      startDate: null,
      endDate: null,
      statuses: [],
    };

    await apiTaskSearch
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          let taskResponse = response.data.data[0];

          console.log("taskById: taskResponse: ", taskResponse);

          const amount = parseNumber(taskResponse.rate, "float");
          const totalAmount = amount;

          taskResponse = {
            ...taskResponse,
            amount: parseNumber(amount, "float"),
            totalAmount: parseNumber(totalAmount, "float"),
          };

          dispatch(setTaskById(taskResponse));
        } else {
          if (offlineData) {
            dispatch(setTaskById(offlineData));
          } else {
            console.log("Error: Fetch Task Error By Id: ", response);
          }
        }
      })
      .catch((error) => {
        if (offlineData) {
          dispatch(setTaskById(offlineData));
        } else {
          console.error("Error: Fetch Task Error By Id: ", error);
        }
      })
      .finally(() => dispatch(setJobLoader(false)));
  };
}

export const getTodo = (payload) => {
  return async (dispatch, getState) => {
    const dataToSend = {
      assignedIds: [],
      categories: [],
      orderBy: "newest",
      pageIndex: 0,
      pageSize: 10,
      searchText: "",
      sortAscending: false,
      startDate: null,
      endDate: null,
      ids: null,
      statuses: [],
      ...payload,
    };

    await apiTaskSearch
      .post(dataToSend)
      .then((response) => {
        if (response && response.status === 200) {
          let newTodo = response.data.data[0];

          if (!newTodo) return;

          const { jobs } = getState().jobs;

          const isTodoAlreadyPresent = jobs.find(
            (job) => job.id === newTodo.id
          );

          let updatedTodos = jobs;
          if (isTodoAlreadyPresent) {
            updatedTodos = updatedTodos.map((job) =>
              job.id === newTodo.id ? newTodo : job
            );
          } else {
            updatedTodos = [newTodo, ...jobs];
          }

          dispatch(setTodos(updatedTodos));
        }
      })
      .catch((error) => console.log(error));
  };
};

export function addJob(payload, onAddJob) {
  return async (dispatch, getState) => {
    payload = {
      ...payload,
      startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startTime: combineDateAndTime(payload.startDate, payload.startTime),
      endDate: combineDateAndTime(payload.endDate, payload.endTime),
      endTime: combineDateAndTime(payload.endDate, payload.endTime),
    };

    let todoId = null;

    const { jobs } = getState().jobs;

    dispatch(setTaskLoaders("addTodoLoader", true));

    await apiTaskCreate
      .post(payload)
      .then((response) => {
        if (response && response.status === 200) {
          todoId = response.data;

          const hours = getHoursFromTimeline(
            payload.startDate,
            payload.endDate
          );
          const amount =
            payload.rateType === RateType.Hourly
              ? hours * payload.rate
              : payload.rate;
          const totalAmount = amount;

          const newTodo = {
            ...payload,
            id: todoId,
            amount: parseNumber(amount, "float"),
            totalAmount: parseNumber(totalAmount, "float"),
          };

          const newTodos = [newTodo, ...jobs];

          dispatch(setTodos(newTodos));

          if (onAddJob) {
            onAddJob(newTodo);
          }

          toast.success("Job created successfully.");
        } else {
          console.log("Error: Add Job: Response", response);
          toast.success("Error while adding job.");
        }
      })
      .catch((error) => {
        console.log("Error: Add Job: ", error);
        toast.success("Job created successfully.");
      })
      .finally(() => dispatch(setTaskLoaders("addTodoLoader", false)));

    return todoId;
  };
}

export function toggleIsDeleteUndo(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_IS_DELETE_UNDO,
      payload: status,
    });
  };
}

function setDeleteLoader(status) {
  return (dispatch) => {
    dispatch({
      type: SET_JOB_DELETE_LOADER,
      payload: status,
    });
  };
}

export function deleteJobById(deleteItem, history, path) {
  return async function deleteJobByIdThunk(dispatch, getState) {
    dispatch(setDeleteLoader(true));

    const deletePayload = {
      id: deleteItem.id,
      undo: false,
    };

    await apiTaskRemove
      .post(deletePayload)
      .then((response) => {
        toast.success("Job Deleted Successfully");

        console.log("delete response: ", response.data);

        if (path === "/job/:id") {
          history.push("/home");
        }

        dispatch({
          type: DELETE_JOB_BY_ID,
          payload: deleteItem.id,
        });

        dispatch(setDeleteLoader(false));

        dispatch(toggleIsDeleteUndo(true));
      })
      .catch((error) => {
        dispatch(setDeleteLoader(false));
        toast.error("Error in deleting task");
        console.error("Delete Task Error: ", error);
      });
  };
}

export const deleteJobLocally = (taskId) => {
  return (dispatch, getState) => {
    const { jobs } = getState().jobs;

    const updatedJobs = jobs.filter((job) => job.id !== taskId);

    dispatch(setTodos(updatedJobs));
  };
};

export function undoDeleteJob() {
  return async function undoDeleteJobThunk(dispatch, getState) {
    const undoItem = JSON.parse(localStorage.getItem("undoJob"));
    if (undoItem) {
      localStorage.removeItem("undoJob");
    }

    const deletePayload = {
      id: undoItem.id,
      undo: true,
    };

    // return null;
    await apiTaskRemove
      .post(deletePayload)
      .then((response) => {
        toast.success("Job Undo Done Successfully");
        console.log("delete undo response: ", response.data);
        dispatch({
          type: UNDO_DELETE,
          payload: undoItem,
        });
        dispatch(toggleIsDeleteUndo(false));
      })
      .catch((error) => {
        toast.error("Error in undo delete image");
        console.error("Undo Delete Task Error: ", error);
      });
  };
}

export function createJob(
  payload,
  subTasks = [],
  onCreateJob,
  source = "",
  toastMessage = {
    success: "Quotation converted to job successfully.",
    error: "Error while converting quotation to job.",
  }
) {
  return async function createJobThunk(dispatch, getState) {
    payload = {
      ...payload,
      startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startTime: combineDateAndTime(payload.startDate, payload.startTime),
      endDate: combineDateAndTime(payload.endDate, payload.endTime),
      endTime: combineDateAndTime(payload.endDate, payload.endTime),
    };

    dispatch(setJobLoader(true));

    console.log("createJob : subTasks: ", subTasks);

    await apiTaskCreate
      .post(payload)
      .then(async (response) => {
        if (response.status === 200) {
          const taskId = response.data;

          const amount = parseNumber(payload.rate, "float");
          const totalAmount = amount;

          const newJob = {
            ...payload,
            id: taskId,
            amount: parseNumber(amount, "float"),
            totalAmount: parseNumber(totalAmount, "float"),
          };

          dispatch({
            type: CREATE_JOB,
            payload: newJob,
          });

          if (subTasks.length) {
            await dispatch(addMultipleSubTasks(subTasks, newJob));
          }

          if (onCreateJob) {
            onCreateJob(taskId);
          }

          toast.success(toastMessage.success);

          return taskId;
        } else {
          console.error("Create Job Error: ", response);
          toast.error(toastMessage.error);
        }
      })
      .catch((error) => {
        console.error("Create Job Error: ", error);
        toast.error(toastMessage.error);
      })
      .finally(() => dispatch(setJobLoader(false)));
  };
}

export const resetJobSearch = (onlyClientSide = false) => {
  return (dispatch) => {
    const resetParams = {
      customer: null,
      startDate: null,
      endDate: null,
      assignedUsers: null,
      categories: [],
      job: null,
      statuses: [],
    };

    if (onlyClientSide) {
      dispatch(setJobSearchParams(resetParams));
    } else {
      dispatch(setJobSearchManager(resetParams));
    }
  };
};

export function setJobSearchManager({ sortAscending, pageSize, ...payload }) {
  return async function setJobSearchManagerThunk(dispatch, getState) {
    const searchParams = {
      customer: payload.customer || null,
      startDate: payload.startDate || null,
      endDate: payload.endDate || null,
      assignedUsers: payload.assignedUsers,
      categories: payload.categories.length ? payload.categories : [],
      job: payload.job ? payload.job : null,
      statuses: payload.statuses ? payload.statuses : [],
    };

    console.log("fetchTodos: setJobSearchManager searchParams", searchParams);

    let searchJob = {};
    if (payload.job) {
      searchJob = {
        ...(payload.job.enterKey ? { searchText: payload.job.title } : {}),
        ...(!payload.job.enterKey ? { ids: [payload.job.id] } : {}),
      };
    }

    const searchParamsPayload = {
      ...searchParams,
      customerIds: payload.customer ? [payload.customer.id] : null,
      assignedIds: payload.assignedUsers ? [payload.assignedUsers.id] : null,
      ...(sortAscending !== undefined || sortAscending !== null
        ? { sortAscending }
        : {}),
      ...(pageSize !== undefined || pageSize !== null ? { pageSize } : {}),
      ...searchJob,
    };

    console.log(
      "fetchTodos: setJobSearchManager searchParamsPayload",
      searchParamsPayload
    );

    await dispatch({
      type: SET_JOB_SEARCH_MANAGER,
      payload: searchParams,
    });

    await dispatch(handleJobFilter(searchParamsPayload));
  };
}

export function handleJobFilter(payload, isHeaderSearch = false) {
  return async function setHandleJobFilter(dispatch, getState) {
    const params = payload;

    await dispatch(fetchTodos(params));
  };
}

export function toggleShareJobModal(payload) {
  return async function toggleShareJobModalThunk(dispatch, getState) {
    dispatch({
      type: TOGGLE_SHARE_JOB_MODAL,
      payload: payload,
    });
  };
}

export function setShareJobLoader(status) {
  return (dispatch) => {
    dispatch({
      type: SET_SHARE_JOB_LOADER,
      payload: status,
    });
  };
}

export function shareJob(values, job) {
  return async function shareJobThunk(dispatch, getState) {
    console.log("Share Job Users: ", values);

    dispatch(setShareJobLoader(true));

    // make call to server API
    const tasks = values.assignTo.map((item) => ({
      staffId: item.id || item.staffId,
      taskId: job.id,
      startDate: job.startDate,
      endDate: job.endDate,
      startTime: job.startTime,
      endTime: job.endTime,
      staffText: item.title || item.staffText,
    }));

    await apiTaskAssign
      .post(tasks)
      .then(async (response) => {
        // setLoading(false);
        if (response.status === 200) {
          toast.success("Job Shared successfully!!");
          dispatch({
            type: SHARE_JOB,
            payload: {
              tasks: tasks,
              jobId: job.id,
            },
          });
          dispatch(setShareJobLoader(false));

          if (job.teamChatGroupId) {
            // update team chat members on update of assigned users
            await dispatch(
              handleAssignRemoveChatGroupUsers(job.teamChatGroupId, tasks)
            );
          }
        } else {
          toast.error("There is some issues in sharing job, please try again!");
          dispatch(setShareJobLoader(false));
        }
      })
      .catch((error) => {
        dispatch(setShareJobLoader(false));
        console.log("Edit Share job Error: ", error);
        toast.error("There is some issues in sharing job, please try again!");
      });

    // toast.success("Job Shared Successfully!!!");

    dispatch(toggleShareJobModal(false));
  };
}

export function setTodoShareLoader(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_TODO_SHARE_LOADER,
      payload: payload,
    });
  };
}

export function editJob(payload, showToast = true) {
  return async function createJobThunk(dispatch, getState) {
    // dispatch(setTaskLoaders('editTodoLoader', true));

    payload = {
      ...payload,
      startDate: combineDateAndTime(payload.startDate, payload.startTime),
      startTime: combineDateAndTime(payload.startDate, payload.startTime),
      endDate: combineDateAndTime(payload.endDate, payload.endTime),
      endTime: combineDateAndTime(payload.endDate, payload.endTime),
      rate: parseNumber(payload.amount, "float"),
      amount: parseNumber(payload.amount, "float"),
      totalAmount: parseNumber(payload.totalAmount, "float"),
    };

    await apiTaskEdit
      .post(payload)
      .then((response) => {
        if (response.status === 200) {
          const totalAmount = payload.amount;

          const editedJob = {
            ...payload,
            totalAmount,
          };

          dispatch(setTaskById(editedJob));

          if (showToast) {
            toast.success("Job edited successfully!!");
          }
        } else {
          if (showToast) {
            toast.error(
              "There is some issues in editing job detail, please try again!"
            );
          }
        }
      })
      .catch((error) => {
        console.log("Edit Job Dates Error: ", error);
        if (showToast) {
          toast.error(
            "There is some issues in editing job detail, please try again!"
          );
        }
      })
      .finally(() => {
        dispatch(setTaskLoaders("editTodoLoader", false));
      });
  };
}

export function setIsJobUnFinishedFilter(value) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_JOB_FINISHED_FILTER,
      payload: value,
    });

    dispatch(handleJobFinishedFilter(value));
  };
}

export const handleJobFinishedFilter = (isJobUnFinishedFilter) => {
  return (dispatch, getState) => {
    dispatch(setTaskLoaders("todosLoader", true));

    const { jobs, jobSearchParams } = getState().jobs;

    let newJobs = [];

    if (isJobUnFinishedFilter) {
      newJobs = filterJobsByUnFinished(jobs);
      dispatch(setTodos(newJobs));

      setTimeout(() => {
        dispatch(setTaskLoaders("todosLoader", false));
      }, 1000);
    } else {
      dispatch(setTodos([]));
      dispatch(handleJobFilter(jobSearchParams));
    }
  };
};

export const processAndUploadImage = (payload, isConvert = false) => {
  return async (dispatch, getState) => {
    const { mediasToUpload, mediaLinks } = payload;

    dispatch(setJobMediaLoader(true));

    console.log("processAndUploadImage STARTED");
    const uIds = mediasToUpload.map((item) => v4());

    // INIT UPLOAD
    const initRes = await apiInitUpload
      .post({ ids: uIds })
      .catch((error) =>
        console.log("processAndUploadMedia: initupload error: ", error)
      );

    // UPLOAD
    const uploadMediasPromise = mediasToUpload.map(async (media, index) => {
      console.log("processAndUploadMedia: media: ", media);

      const { mediaBlob, thumbnailBlob } = await getMediaData(media, isConvert);

      console.log("====== media.type media.type media.type=====", media.type);

      // UPLOAD
      try {
        fetch(initRes.data.links[index].m, {
          method: "PUT",
          body: mediaBlob,
          headers: { "Content-Type": media.type },
        });
        fetch(initRes.data.links[index].t, {
          method: "PUT",
          body: thumbnailBlob,
          headers: { "Content-Type": "image/png" },
        });
        fetch(initRes.data.links[index].b, {
          method: "PUT",
          body: mediaBlob,
          headers: { "Content-Type": media.type },
        });
      } catch (error) {
        console.log(
          "===processAndUploadMedia====apiInitUpload error: ==",
          error
        );
        media = null;
      }

      return media;
    });

    let uploadSuccessMedias = [];
    uploadSuccessMedias = await Promise.all(uploadMediasPromise);
    uploadSuccessMedias = uploadSuccessMedias.filter((media) => Boolean(media));

    if (!uploadSuccessMedias.length) {
      toast.error(
        `0 of ${mediasToUpload.length} medias uploaded successfully.`
      );
      dispatch(setJobMediaLoader(false));
      return;
    }

    const newMedias = uploadSuccessMedias.map((media, index) => ({
      id: uIds[index],
      title: media.name,
      fileType: media.type,
      abstract: "",
      link: uIds[index],
      mediaType: payload.mediaType,
      taskId: payload.taskId,
    }));

    const medias = [...newMedias, ...mediaLinks].map((media) => {
      return {
        link: media.id,
        mediaType: media.mediaType,
        taskId: media.taskId,
        title: media.title,
        fileType: media.fileType,
      };
    });

    const dataToSend = {
      taskId: payload.taskId,
      medias: medias,
    };

    console.log("processAndUploadImage: dataToSend: ", dataToSend);

    await apiUploadMedia
      .post(dataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          const { taskById } = getState().jobs;

          let newMedias = uploadSuccessMedias.map(async (media, index) => {
            const { thumbnailBase64, fileType } = await getMediaData(
              media,
              isConvert
            );

            console.log(
              "======processAppointmentMediaUpload===thumbnailBase64===",
              thumbnailBase64
            );

            return {
              id: uIds[index],
              title: media.name,
              fileType: fileType,
              link: thumbnailBase64,
              mediaType: payload.mediaType,
              taskId: payload.taskId,
            };
          });
          newMedias = await Promise.all(newMedias);

          console.log("processAndUploadImage: newMedias: ", newMedias);

          if (taskById) {
            dispatch(
              setTaskById({
                ...taskById,
                mediaLinks: [...newMedias, ...taskById.mediaLinks],
              })
            );
          }

          toast.success(
            `${uploadSuccessMedias.length} of ${mediasToUpload.length} medias uploaded successfully.`
          );
        } else {
          console.error("Upload Media Error: response: ", response);
          toast.error(
            "There is some issues in uploading job media, please try again!"
          );
        }
      })
      .catch((error) => {
        console.error("Upload Media Error: ", error);
        toast.error(
          "There is some issues in uploading job media, please try again!"
        );
      })
      .finally(() => {
        dispatch(setJobMediaLoader(false));
      });
  };
};

export const getMediaLinks = (payload) => {
  return async (dispatch, getState) => {
    let dataToSend = {
      ids: payload.mediaLinks.map((image) => image.id),
      size: MediaSizeType.Thumbnail,
    };

    console.log("getMediaLinks: dataToSend: ", dataToSend);

    await apiMedia
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          let newMedias = [];
          console.log("image fetch response: ", response.data.links);
          if (response.data.links.length) {
            newMedias = response.data.links.map((link) => {
              let mediaLink = payload.mediaLinks.find(
                (mediaLink) => link.id === mediaLink.id
              );

              if (mediaLink) {
                return {
                  ...mediaLink,
                  link: link.link,
                };
              } else {
                return mediaLink;
              }
            });

            const { taskById } = getState().jobs;

            console.log("====getMediaLinks====taskById=====", taskById);

            const updatedTask = {
              ...taskById,
              mediaLinks: newMedias,
            };
            dispatch(setTaskById(updatedTask));

            console.log("====getMediaLinks====updatedTask=====", updatedTask);

            console.log("====getMediaLinks====newMedias=====", newMedias);
          }
        } else {
          console.log("Media Load Error: response: ", response);
        }
      })
      .catch((error) => {
        console.log("Media Load Error: ", error);
      });
  };
};

export const deleteJobMedia = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setJobMediaDeleteLoader(true));

    const docToDeleteId = payload.id;
    const newList = payload.mediaLinks.filter(
      (image) => image.id !== docToDeleteId
    );

    const medias = newList.map((doc) => {
      return {
        link: doc.id,
        taskId: payload.taskId,
        mediaType: doc.mediaType,
      };
    });

    const dataToSend = {
      taskId: payload.taskId,
      medias: medias,
    };

    console.log("DELETE MEDIA", dataToSend);
    console.log(docToDeleteId);

    await apiUploadMedia
      .post(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          console.log("delete document response: ", response);

          const { taskById } = getState().jobs;

          const updatedTask = {
            ...taskById,
            mediaLinks: newList,
          };

          dispatch(setTaskById(updatedTask));

          toast.success("Document deleted successfully!!!");
        } else {
          console.log("Delete document Error: ", response);
          toast.error("There was an issue with deleting document!!!");
        }
      })
      .catch((error) => {
        console.log("Delete document Error: ", error);
        toast.error("There was an issue with deleting document!!!");
      })
      .finally(() => dispatch(setJobMediaDeleteLoader(false)));
  };
};

export const getResolvedJobMediasByIds = (
  payload,
  onResolveMediasByIds,
  openMedia = true,
  returnAll = false
) => {
  return async (dispatch, getState) => {
    const { clickedMedia = {}, allMedias = [] } = payload;

    const ids = returnAll
      ? allMedias.map((media) => media.id)
      : clickedMedia
      ? [clickedMedia.id]
      : [];

    const dataToSend = {
      ids: ids,
      size: MediaSizeType.BigScreen,
    };

    dispatch(setTaskLoaders("getJobMediaLoader", true));

    await apiMedia
      .post(dataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          console.log("media fetch response: ", response.data.links);

          const mediaLinks = response.data.links;

          if (mediaLinks.length) {
            let allResolvedMedias = [];
            if (returnAll) {
              allResolvedMedias = mediaLinks.map(async (media, index) => {
                let fileType = null;
                let contentType = null;
                try {
                  const resp = await getContentType(media.link);
                  fileType = resp.fileType;
                  contentType = resp.contentType;
                } catch (error) {
                  toast.error(
                    "Media not found. Please delete and upload the media again."
                  );
                  return;
                }

                const isImage = fileType === FileType.Image;

                return {
                  ...media,
                  uri: media.link,
                  thumbnailUri: allMedias[index].link,
                  fileType: fileType,
                  type: contentType,
                  isResolved: true,
                  isImage,
                };
              });

              allResolvedMedias = await Promise.all(allResolvedMedias);
              allResolvedMedias = allResolvedMedias.filter(Boolean);

              onResolveMediasByIds(null, allResolvedMedias, false);
              dispatch(setResolvedJobMedias(allResolvedMedias));
              return;
            }

            const media = mediaLinks.find(
              (_media) => _media.id === clickedMedia.id
            );

            let fileType = null;
            let contentType = null;
            try {
              const resp = await getContentType(media.link);
              fileType = resp.fileType;
              contentType = resp.contentType;
            } catch (error) {
              toast.error(
                "Media not found. Please delete and upload the media again."
              );
              return;
            }

            const isImage = fileType === FileType.Image;
            let resolvedMedia = {
              ...media,
              type: contentType,
              uri: media.link,
              thumbnailUri: clickedMedia.link,
              fileType,
              isResolved: true,
              isImage,
            };

            if (!isImage && openMedia) {
              window.open(resolvedMedia.uri, "_blank");
            }

            allResolvedMedias = mediaLinks.map((media, index) => {
              if (media.id === resolvedMedia.id) {
                return resolvedMedia;
              } else {
                return {
                  ...media,
                  uri: media.link,
                  thumbnailUri: allMedias[index].link,
                  fileType: FileType.Image,
                  isResolved: false,
                  isImage: true,
                };
              }
            });

            allResolvedMedias = await Promise.all(allResolvedMedias);
            allResolvedMedias = allResolvedMedias.filter(Boolean);

            if (onResolveMediasByIds) {
              const isOpeningMedia = openMedia && !isImage;
              onResolveMediasByIds(
                resolvedMedia,
                allResolvedMedias,
                isOpeningMedia
              );
            }

            dispatch(setResolvedJobMedias(allResolvedMedias));
          }
        }
      })
      .catch((error) => {
        console.error("Media Load Error: ", error);
        toast.error("There is some issue in opening media.");
      })
      .finally(() => {
        dispatch(setTaskLoaders("getJobMediaLoader", false));
      });
  };
};
