import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

export default function FieldHelperTextCNO({
  showHelperText,
  helperText,
  containerStyle,
  errorColor = true
}) {

  const useStyles = makeStyles((theme) => ({
    containerStyle: {
      marginLeft: 5,
      marginBottom: 10,
      ...containerStyle
    },
    textStyle: {
      fontSize: 12,
      color: errorColor ? theme.colors.ERROR_COLOR : theme.colors.PRIMARY_COLOR
    }
  }));

  const styles = useStyles();

  if (!showHelperText) {
    return null;
  }

  return (
    <div className={styles.containerStyle}>
      <Typography className={styles.textStyle}>{helperText}</Typography>
    </div>
  )
}
