import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect,useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { fetchInvoices, fetchMoreInvoices, setInvoiceSearchParams, handleInvoiceSearch, toggleAddInvoiceDialog, resetInvoiceSearch } from '../../actions/invoiceActions';
import InvoiceListCard from '../../components/Invoice/InvoiceListCard';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import AddInvoice from '../../components/Invoice/AddInvoice';
import InvoiceFilterBar from './InvoiceFilterBar';
import InfiniteScrollCNO from '../../library/InfiniteScrollCNO';
import StickyWrapper from '../../library/StickyWrapper';
import { isFormikEmpty } from '../../utils/Helpers';
import ResetListing from '../../components/ResetListing';
import { useHistory } from 'react-router-dom';
import { setTaskById } from '../../actions/jobActions';
import { mdiFileTable } from '@mdi/js';
import { AddInvoiceDialogMode } from '../../utils';
import Switcher from '../../common/components/GridListToggle/Switcher';

function InvoiceListing({ customer, page, setPage }) {

  const tillTablet = useMediaQuery('(max-width: 768px)');
  const [isGridView,setIsGridView]=useState(false);

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const pathname = useHistory().location.pathname;
  const isCustomerDetailsPath = pathname.includes('/customer/');

  const dispatch = useDispatch();

  const {
    invoices,
    allInvoiceLoader,
    isAddInvoiceDialog,
    mode,
    hasMoreInvoices,
    invoiceSearchParams
  } = useSelector(state => state.invoices);
  const { invoicesLoader, deleteLoader, addLoader, editLoader } = allInvoiceLoader;

  const initialValues = {
    customer: null,
    categories: [],
    invoice: null,
    invoiceNumber: "",
    billDate: null,
    dueInDays: null,
    statuses: [],
    paymentByCustomerStatuses: []
  };

  useEffect(() => {

    const payload = {
      customer: customer ? [customer.id] : null
    };

    dispatch(fetchInvoices(payload));
  }, []);

  const loadMore = () => {
    console.log("=== hasMoreInvoices === : ", hasMoreInvoices);
    if (hasMoreInvoices) {
      const moreInvoicesPayload = {
        pageIndex: page + 1,
        orderBy: "newest",
        searchText: "",
        customer: customer ? [customer.id] : null
      };

      dispatch(fetchMoreInvoices(moreInvoicesPayload));

      setPage(page + 1);
    }
  };

  // console.log("invoices: ", invoices);
  // console.log("invoicesLoader: ", invoicesLoader);

  const hasSearched = !isFormikEmpty(initialValues, invoiceSearchParams);

  console.log("invoices: hasSearched ", hasSearched);
  console.log("invoices: initialValues, invoiceSearchParams ", {
    initialValues, invoiceSearchParams
  });

  const openAddInvoiceDialog = () => {
    batch(() => {
      dispatch(setTaskById(null));
      dispatch(toggleAddInvoiceDialog({ status: true, mode: AddInvoiceDialogMode.Add }));
    });
  };

  return (
    <div className={GlobalStyles.listingContainer}>

      {
        (deleteLoader || addLoader || editLoader)
        &&
        <FullScreenLoaderCNO />
      }

      {
        tillTablet && !isCustomerDetailsPath &&
        <StickyWrapper>
          <InvoiceFilterBar customer={customer} />
        </StickyWrapper >
      }

      {
        !isCustomerDetailsPath &&
        <ResetListing
          isReset={hasSearched}
          onReset={() => {
            dispatch(resetInvoiceSearch());
          }}
        />
      }
      <Switcher isGridView={isGridView} setIsGridView={setIsGridView} />

      <InfiniteScrollCNO
        items={invoices}
        next={loadMore}
        hasMore={hasMoreInvoices}
        loading={invoicesLoader}
        renderListItem={(invoice) => (
          <InvoiceListCard key={invoice.id} invoice={invoice} />
        )}
        notFoundTextMessage={'No Invoice Present'}
        notFoundIcon={mdiFileTable}
        showListCTAButtons={true}
        listCTAButtonsProps={{ addTitle: 'Add Invoice', exploreId: 6, onClickAdd: openAddInvoiceDialog }}
        isGridView={isGridView} 
        setIsGridView={setIsGridView}
        mode={'invoices'}

      />

      {/* {
        isAddInvoiceDialog && mode === 'add' &&
        <AddInvoice />
      } */}

    </div>
  )
}

export default InvoiceListing
