import { Popover, useMediaQuery, CircularProgress, useTheme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './NotificationPopoverStyles';
import { mdiAccount, mdiCheck, mdiProgressClock, mdiStop } from '@mdi/js';
import HeadingBar from '../HeadingBar';
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchMoreNotifications } from '../../actions/notificationActions';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { getTaskById } from '../../actions/jobActions';
import NotificationItem from '../NotificationItem/NotificationItem';
import { NotificationType } from '../../utils';
import { handleCompanyUpdateFromNotification, handleProfileUpdateFromNotification } from '../../utils/NotificationHelpers';
import InfiniteScrollCNO from '../../library/InfiniteScrollCNO';

function NotificationPopover({
  isNotification,
  closeNotification,
  anchorEl
}) {

  const theme = useTheme();

  const fromTablet = useMediaQuery('(min-width: 768px)');

  const [page, setPage] = useState(0);
  // const [currentData, setCurrentData] = useState([]);

  const dispatch = useDispatch();
  const { notifications, hasMore, notificationsCount } = useSelector(state => state.notifications);

  console.log("========NotificationPopover=====notificationsCount====", notificationsCount);

  const dataLength = notifications.length;

  // handle open job 
  // make a action to get job by Id
  useEffect(() => {

    const getJobs = async () => {
      const payload = notifications.map((notification) => notification.metaData);
      dispatch(getTaskById(payload));
    };

    getJobs();

  }, [notifications]);

  const loadMore = () => {

    setPage(page + 1);

    const payload = {
      pageSize: 10,
      pageIndex: page + 1,
      orderBy: "",
      sortDecending: true,
      zeroStart: false
    };

    dispatch(fetchMoreNotifications(payload));

  };

  useEffect(() => {

    console.log("OFFline Notification: fetching from offline=====");

    if (notificationsCount) {

      console.log("OFFline Notification: notificationsCount=====", notificationsCount);

      // profile  notification
      const firstProfileEditNotification = notifications.find(noti => noti.notificationType === NotificationType.ProfileEdited);

      if (firstProfileEditNotification) {
        handleProfileUpdateFromNotification(firstProfileEditNotification, dispatch);
      }

      // company  notification
      const firstCompanyCategoriesEditNotification = notifications.find(noti => noti.notificationType === NotificationType.GroupCategoriesChanged);
      if (firstCompanyCategoriesEditNotification) {
        handleCompanyUpdateFromNotification(firstCompanyCategoriesEditNotification, dispatch);
      }

      const firstCompanyEditNotification = notifications.find(noti => noti.notificationType === NotificationType.GroupEdited);
      if (firstCompanyEditNotification) {
        handleCompanyUpdateFromNotification(firstCompanyEditNotification, dispatch);
      }

      const firstCompanyPaymentChangedNotification = notifications.find(noti => noti.notificationType === NotificationType.GroupPaymentMethodChanged);
      if (firstCompanyPaymentChangedNotification) {
        handleCompanyUpdateFromNotification(firstCompanyPaymentChangedNotification, dispatch);
      }

      const firstCompanyRemoveNotification = notifications.find(noti => noti.notificationType === NotificationType.GroupRemoved);
      if (firstCompanyRemoveNotification) {
        handleCompanyUpdateFromNotification(firstCompanyRemoveNotification, dispatch);
      }

    }

  }, [notifications.length]);

  const renderNotifications = () => {
    return (
      notifications.map((notification, index) => (
        <NotificationItem
          key={v4()}
          notification={notification}
          // job={filteredJobs[notification.metaData]}
          closeNotification={closeNotification}
        />
      ))
    );

  };

  const styles = useStyles({ fromTablet });
  return (
    <Popover
      open={isNotification}
      anchorEl={anchorEl}
      onClose={closeNotification}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className={styles.conatiner_NotificationPopover} id="scrollableRef">
        <HeadingBar
          title="Notification"
          onClose={closeNotification}
        />

        <InfiniteScrollCNO
          scrollableTarget="scrollableRef"
          items={notifications}
          next={loadMore}
          hasMore={hasMore}
          renderListItem={(notification) => (
            <NotificationItem
              key={v4()}
              notification={notification}
              closeNotification={closeNotification}
            />
          )}
          notFoundTextMessage="You are all caught up."
          renderLoader={<CircularProgress />}
        />

      </div>
    </Popover>
  )
}

export default NotificationPopover
