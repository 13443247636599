import { makeStyles, MenuItem, Select, Typography, useMediaQuery } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppointment } from '../../actions/appointmentActions';
import DialogCNO from '../../library/DialogCNO';
import { getCategory } from '../../utils/Helpers';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import FormActionButtons from '../FormActionButtons';
import JobTypeIcon from '../JobTypeIcon/JobTypeIcon';
import * as yup from 'yup';
import InputCNO from '../../library/InputCNO';
import { mdiFormatText } from '@mdi/js';
import { AppointmentStatus, AppointmentType, LeaveType } from '../../utils/mappings';
import RightsManager from '../../utils/RightsManager';
import { useDebouncedFormik } from '../../hooks';
import LeaveTypeIcon from '../LeaveTypeIcon';


const EditAppointmentTypeDialog = ({
  open,
  onClose,
  appointment
}) => {

  const GlobalStyles = useGlobalStyles();

  let categories = localStorage.getItem('categories');
  categories = categories ? JSON.parse(categories) : [];

  const dispatch = useDispatch();
  const { editAppointmentLoader } = useSelector(state => state.appointments);

  const formik = useFormik({
    initialValues: {
      categories: appointment.categories
    },
    validationSchema: yup.object({
      categories: yup.array().required()
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {

    console.log("Appointment edit values: ", values);

    const updateAppointmentPayload = {
      ...appointment,
      categories: values.categories
    };

    dispatch(updateAppointment(updateAppointmentPayload));

    onClose();

  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Appointment Type"
      loading={editAppointmentLoader}
    >
      <form onSubmit={formik.handleSubmit}>
        <Select
          fullWidth
          variant="outlined"
          margin="dense"
          onChange={(e) => {
            console.log("upCat: ", e.target.value);
            formik.setFieldValue("categories", [e.target.value])
          }}
          value={formik.values.categories[0]}
          className={GlobalStyles.dropdown}
        >
          {
            categories.map((category) => {
              return <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
            })
          }
        </Select>
        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )

}

function AppointmentMainDetailsCard({ appointment }) {

  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const isCompanyAdmin = RightsManager.isGroupLeader();
  const isUserMember = RightsManager.isMember();
  const isLeave = appointment.appointmentType
    ? appointment.appointmentType === AppointmentType.LEAVE
    : false;

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const category = getCategory(appointment.categories[0]);

  const [isAppointmentTypeEditable, setIsAppointmentTypeEditable] = useState(false);
  const [isAppointmentMainEditable, setIsAppointmentMainEditable] = useState(false);
  const [editableProperty, setEditableProperty] = useState(null);

  return (
    <div className={GlobalStyles.detailsCardContainer} style={{ display: 'flex' }}>
      {isLeave ? <LeaveTypeIcon/> :
      <JobTypeIcon
        iconLink={category.iconLink}
        name={category.title}
        onClick={() => setIsAppointmentTypeEditable(true)}
        categoryId={category?.id}
      />
    }
      <div className={styles.details}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', flexDirection: tillTablet ? 'column' : 'row' }}>
          <Typography
            variant='h6'
            className={GlobalStyles.cardTitle}
            onClick={() => {
              if(!isLeave){
                setIsAppointmentMainEditable(true)  //  LEAVE IS NOT EDITABLE
                setEditableProperty('title')
              }
            }}
          >
            {isLeave ? LeaveType.getItem(appointment.leaveType).label :appointment.title }
          </Typography>
        </div>
        <pre
          className={styles.description}
          onClick={() => {
            if(!isLeave){
              setIsAppointmentMainEditable(true);
              setEditableProperty('description');
            }
          }}
        >
          
          {appointment.description ?  appointment.description : isLeave ? 'Leave details not added':'Appointment details not added'}
        </pre>

        {appointment.rejectionMessage && (
          <pre
          className={styles.description}
        >
          <p>{'Rejection Reason : '}<span className={styles.warning}>{appointment.rejectionMessage}</span></p>
        </pre>
        )}
      </div>
      
      {
        isCompanyAdmin &&
        isAppointmentTypeEditable &&
        <EditAppointmentTypeDialog
          open={isAppointmentTypeEditable}
          onClose={() => setIsAppointmentTypeEditable(false)}
          appointment={appointment}
        />
      }

      {
        (isUserMember || isCompanyAdmin) &&
        isAppointmentMainEditable &&
        <EditAppointmentMainDialog
          open={isAppointmentMainEditable}
          onClose={() => setIsAppointmentMainEditable(false)}
          appointment={appointment}
          editableProperty={editableProperty}
        />
      }

    </div>
  )
}

export default AppointmentMainDetailsCard;

const EditAppointmentMainDialog = ({
  open,
  onClose,
  appointment,
  editableProperty
}) => {

  const labels = {
    'title': 'Appointment Title',
    'description': 'Appointment Description',
  };

  const placeholder = {
    'title': 'Enter Appointment Title',
    'description': 'Enter Appointment Description',
  };

  const dispatch = useDispatch();
  const { editAppointmentLoader } = useSelector(state => state.appointments);

  const formik = useFormik({
    initialValues: {
      [editableProperty]: appointment[editableProperty]
    },
    validationSchema: yup.object({
      [editableProperty]: yup.string().required()
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleClose = () => {
    formik.resetForm();
    localStorage.removeItem(`editAppointment_${appointment.id}`);
    onClose();
  }

  const handleUpdate = (values) => {

    console.log("Appointment edit values: ", values);

    const updateAppointmentPayload = {
      ...appointment,
      [editableProperty]: values[editableProperty]
    };

    const onAddSuccess = () => handleClose();

    dispatch(updateAppointment(updateAppointmentPayload, onAddSuccess));

  };

  useDebouncedFormik(formik, `editAppointment_${appointment.id}`);

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Edit Appointment"
      loading={editAppointmentLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name={editableProperty}
          label={labels[editableProperty]}
          placeholder={placeholder[editableProperty]}
          formik={formik}
          error={formik.touched[editableProperty] && Boolean(formik.errors[editableProperty])}
          helperText={formik.errors[editableProperty]}
          icon={mdiFormatText}
          fullWidth
          multiline={editableProperty === 'description' ? true : false}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  )

}

const useStyles = makeStyles((theme) => ({

  details: {
    marginLeft: theme.spacing(2),
    flex: 1
  },

  description: {
    fontSize: '14px',
    whiteSpace: "pre-wrap",
    minHeight: 15,
    color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR
  },

  warning:{
    fontSize: '14px',
    whiteSpace: "pre-wrap",
    minHeight: 15,
    color: "red"
  }

}));