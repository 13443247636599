import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Radio from "@material-ui/core/Radio";
import privacyOptions from "../../../actions/DummyData/privacyOptions.json";

export default function PrivacyOptionsDescription({ value, setValue }) {
  const [selectedValue, setSelectedValue] = useState(value.toString());
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(privacyOptions);
  }, []);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setValue(parseInt(event.target.value));
  };

  const handleListItemClick = (value) => {
    setSelectedValue(value.toString());
    setValue(parseInt(value));
  };

  return (
    <List dense style={{ width: "100%" }}>
      {options.map((option) => {
        const labelId = `checkbox-list-secondary-label-${option.id}`;
        return (
          <ListItem
            key={option.id}
            disablePadding
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              columnGap: "10px",
            }}
            onClick={() => handleListItemClick(option.id)}
          >
            <ListItemAvatar>
              <Avatar
                alt={`${option.title}'s icon`}
                src={`/static/images/avatar/${option.id + 1}.jpg`}
                style={{ border: "4px solid #a3a2a0" }}
              />
            </ListItemAvatar>

            <ListItemText
              id={labelId}
              primary={`${option.title}`}
              secondary={`${option.description}`}
            />

            <Radio
              checked={selectedValue === option.id.toString()}
              onChange={handleChange}
              value={option.id.toString()}
              name="radio-buttons"
              inputProps={{ "aria-label": option.id }}
              onClick={(e) => e.stopPropagation()} // Prevent the ListItem onClick from triggering
            />
          </ListItem>
        );
      })}
    </List>
  );
}
