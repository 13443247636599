import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

  statusIcon: {
    borderRadius: '50%',
    borderColor: 'grey',
    borderStyle: "ridge",
    padding: 2,
    marginRight: 5
  },

  statusText: {
    display: 'flex',
    padding: '4px 15px',
    alignItems: 'center',
    borderRadius: '50px',
    background: theme.colors.JOB_ACTION_BUTTON_BACKGROUND_COLOR,
    borderColor: theme.colors.JOB_ACTION_BUTTON_BORDER_COLOR,
    color: "#a5a4a4",
    '&:hover': {
      cursor: 'pointer'
    }
  },

  topRightButton: {
    borderRadius: '50%',
    padding: 2,
    position: "relative",
    top: -10,
    marginLeft: 5,
    width: '1.5rem',
    height: "1.5rem",
    '&:hover': {
      cursor: 'pointer'
    }
  },

  topRightIcon: {
    position: "relative",
    top: -3,
    marginLeft: -2
  }


}));