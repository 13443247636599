import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import HeadingBar from "../HeadingBar";
import { SnapList, useDragToScroll,SnapItem } from "react-snaplist-carousel";
import InputCNO from "../../library/InputCNO";
import { useFormik } from "formik";
import {
  mdiContentSave,
  mdiFileCancel,
  mdiText,
  mdiTextAccount,
} from "@mdi/js";
import Icon from "@mdi/react";
import { useDispatch, useSelector } from "react-redux";
import {
  addHoliday,
  toggleAddHolidayDialog
} from "../../actions/appointmentActions";

import FlexibleSpaceCNO from "../../library/FlexibleSpaceCNO/FlexibleSpaceCNO";
import FormBox from "../FormBox/FormBox";
import * as yup from "yup";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import DateTimePickerCNO from "../../library/DateTimePickerCNO";
import { useDebouncedFormik, useFormikErrors } from "../../hooks";
import { validateStartDateEndDate } from "../../utils/Helpers";
import moment from "moment";
import { setAddModalsCalendarDates } from "../../actions/calendarActions";
import { HolidayType } from "../../utils/mappings";
import SelectCNO from "../../library/SelectCNO/SelectCNO";

function AddHoliday() {
  const tillTablet = useMediaQuery("(max-width: 767px)");

  const theme = useTheme();
  const styles = useStyles({ tillTablet });

  const dispatch = useDispatch();

  const { addAppointmentLoader, isAddHolidayDialog } = useSelector(
    (state) => state.appointments
  );
  const { addModalsCalendarDates } = useSelector((state) => state.calendar);
  const { startDate, startTime, endDate, endTime } = addModalsCalendarDates;

  console.log("======addModalsCalendarDates======", addModalsCalendarDates);

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  // const [holidayDuration, setHolidayDuration] = useState(0);
  const handleClose = () => {
    localStorage.removeItem("addHoliday");
    dispatch(toggleAddHolidayDialog(false));
    formik.handleReset();
  };

  const validationSchema = yup.object().shape({
    holidayType: yup.number().required("Holiday type is required!"),
    holidayName:yup.string().required("Holiday name is required!"),
    description: yup.string(),
    startDate: yup.string().required("Holiday start date is required!"),
    // endDate: yup
    //   .string()
    //   .required("Holiday end date is required!")
    //   .test(
    //     "endDateTimeValidation",
    //     "End Date can't be less than Start Date.",
    //     function () {
    //       const startDate = this.parent.startDate;
    //       const startTime = this.parent.startTime;
    //       const endDate = this.parent.endDate;
    //       const endTime = this.parent.endTime;

    //       const isValid = validateStartDateEndDate({
    //         startDate,
    //         startTime,
    //         endDate,
    //         endTime,
    //       });

    //       return isValid;
    //     }
    //   ),
    // startTime: yup.string().required("Holiday start time is required!"),
    // endTime: yup
    //   .string()
    //   .required("Holiday end time is required!")
    //   .test(
    //     "endDateTimeValidation",
    //     "End Date can't be less than Start Date.",
    //     function () {
    //       const startDate = this.parent.startDate;
    //       const startTime = this.parent.startTime;
    //       const endDate = this.parent.endDate;
    //       const endTime = this.parent.endTime;

    //       const isValid = validateStartDateEndDate({
    //         startDate,
    //         startTime,
    //         endDate,
    //         endTime,
    //       });

    //       return isValid;
    //     }
    //   ),
    // duration: yup.number()
    
  });

  const now = new Date();
  const formik = useFormik({
    initialValues: {
      holidayType: "",
      holidayName:"",
      description: "",
      startDate: startDate || now,
      // startTime: startTime || now,
      // endDate: endDate || now,
      // endTime: endTime || moment(now).add(30, "minute").toDate(),
      // duration: holidayDuration,
    },
    validationSchema,
    onSubmit: (values) => handleAddHoliday(values),
  });

  useFormikErrors(formik);
  useDebouncedFormik(formik, "addHoliday");

  useEffect(() => {
    return () => {
      dispatch(
        setAddModalsCalendarDates({
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        })
      );
    };
  }, []);

  // useEffect(() => {
  //   const startDate = new Date(formik.values.startDate);
  //   const endDate = new Date(formik.values.endDate);

  //   const differenceInMilliseconds = endDate - startDate;

  //   let durationInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  //   durationInDays = durationInDays < 1 ? 1 : Math.ceil(durationInDays);

  //   setHolidayDuration(durationInDays);
  // }, [formik.values.startDate, formik.values.endDate]);

  const onAddSuccess = () => {
    formik.resetForm();
    handleClose();
  };

  const handleAddHoliday = async (values) => {
    console.log("Add Holiday values: ", values);

    const payload = {
      ...values,
      // duration:holidayDuration
    };

    console.log("Add Holiday payload: ", payload);

    await dispatch(addHoliday(payload, onAddSuccess));
  };

  console.log("====formikValues========", formik.values);

  return (
    <Dialog
      open={isAddHolidayDialog}
      classes={{ paper: styles.paper }}
      className={styles.container}
    >
      {addAppointmentLoader && <FullScreenLoaderCNO />}

      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <HeadingBar title="Add Holiday" onClose={handleClose} />

        <FormBox className={styles.sectionContainer}>
          

          <SelectCNO
            name="holidayType"
            label="Select a Holiday Type"
            options={HolidayType.entities}
            fullWidth
            containerStyles={{ width: "98%" }}
            value={formik.values.holidayType}
            formik={formik}
            isRequired
          />

          <div className={styles.sectionContainer}>
            <DateTimePickerCNO
              datePickerLabel="Start Date"
              dateName={"startDate"}
              datePlaceholder={"Start Date"}
              disablePast={true}
              onDateChange={(date) => formik.setFieldValue("startDate", date)}
              // timePlaceholder={"Start Time"}
              // timeName={"startTime"}
              // onTimeChange={(date) => {
              //   formik.setValues({
              //     ...formik.values,
              //     startTime: date,
              //     endTime: moment(date).add(30, "minute").toDate(),
              //   });
              // }}
              formik={formik}
              iconColor={"red"}
            />

            {/* <DateTimePickerCNO
              datePickerLabel="End Date"
              dateName={"endDate"}
              datePlaceholder={"End Date"}
              minDate={formik.values.startDate}
              onDateChange={(date) => formik.setFieldValue("endDate", date)}
              timePlaceholder={"End Time"}
              timeName={"endTime"}
              onTimeChange={(date) => formik.setFieldValue("endTime", date)}
              formik={formik}
              iconColor={"green"}
            /> */}
          </div>

          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              className={styles.text}
            >{`Holiday Duration : ${holidayDuration} days`}</Typography>
          </div> */}
        </FormBox>

        <FormBox className={styles.sectionContainer}>
          {/* <div>
            <InputLabelCNO label={"Category"} isRequired={true} />
            <SnapList ref={snapList} className={styles.jobChipSlider}>
              {localCategories.map((category) => (
                <SnapItem>
                  <ChipCNO
                    key={category.id}
                    icon={category.iconLink}
                    name={category.title}
                    isCategoryChip={true}
                    active={formik.values.categories[0] === category.id}
                    onClick={() =>
                      formik.setFieldValue("categories", [category.id])
                    }
                  />
                </SnapItem>
              ))}
            </SnapList>
          </div> */}
          <InputCNO
            name="holidayName"
            label="Holiday Name"
            formik={formik}
            placeholder="write holiday name.."
            icon={mdiText}
            fullWidth
            isRequired={true}
          />
          <div>
            <InputCNO
              name='description'
              label='Holiday Description'
              formik={formik}
              fullWidth
              multiline
            />
          </div>
        </FormBox>
        <div className={styles.ctaWrapper}>
          <Button
            variant="contained"
            className={styles.left}
            startIcon={
              <Icon
                path={mdiFileCancel}
                size={"1rem"}
                color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR}
              />
            }
            onClick={handleClose}
          >
            Cancel
          </Button>

          <FlexibleSpaceCNO />

          <Button
            type="submit"
            variant="contained"
            className={styles.right}
            startIcon={
              <Icon
                path={mdiContentSave}
                size={"1rem"}
                color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR}
              />
            }
            disabled={formik && (!formik.dirty || !formik.isValid)}
          >
            <pre>Save</pre>
          </Button>
        </div>
      </form>
    </Dialog>
  );
}

export default AddHoliday;

const useStyles = makeStyles((theme) => ({
  container: {},
  paper: {
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    borderRadius: 10,
    width: "70vw",
    maxWidth: "90vw",
    margin: ({ tillTablet }) => (tillTablet ? 8 : 32),
  },
  form: {
    padding: "20px",
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    overflowX: "hidden",
    "&>*": {
      marginBottom: theme.spacing(3),
    },
  },

  sectionContainer: {
    "&>*": {
      marginTop: theme.spacing(2),
    },
  },
  jobChipSlider: {
    display: "flex",
    padding: "5px 0",
    "&>*:not(:first-child)": {
      marginLeft: 15,
    },
  },
  timeStampWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  picker: {
    "& .MuiInputBase-root": {
      background: "white",
      color: "#444",
    },
    "& .MuiIconButton-label": {
      color: "#444",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& .MuiOutlinedInput-input": {
      paddingLeft: 5,
    },
    "& .MuiIconButton-root": {
      paddingLeft: 0,
      paddingRight: 5,
    },
  },
  start: {
    "& .MuiSvgIcon-root": {
      color: "#BE0A02",
    },
  },
  end: {
    "& .MuiSvgIcon-root": {
      color: "#3F8A02",
    },
  },
  time: {
    marginLeft: theme.spacing(2),
    maxWidth: "42%",
  },

  customerNameWrapper: {
    display: "flex",
    "&>*:first-child": {
      flex: 1,
    },
  },
  jobLocationLabel: {
    marginBottom: theme.spacing(1),
  },

  chip: {
    background: theme.palette.background.light,
  },
  rateTypeWrapper: {
    "&>*:first-child": {
      marginBottom: theme.spacing(1),
    },
  },
  rateTypeChips: {
    display: "flex",
    "&>*": {
      marginRight: theme.spacing(1),
    },
  },

  ctaWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(6),
    "& > *": {
      marginLeft: 4,
    },
  },
  left: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
  },
  right: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
  },
  draftBtn: {
    background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CANCEL_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_SECONDARY_BUTTON_TEXT_COLOR,
    marginRight: theme.spacing(2),
  },
  textField: {
    borderRadius: 5,
    "& input": {
      background: "white",
      color: "#444",
      borderRadius: 5,
      padding: 8,
    },
    "& .MuiOutlinedInput-multiline": {
      color: "#444",
    },
    "& .MuiOutlinedInput-adornedStart": {
      background: "white",
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": "#444",
      caretColor: "#444",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
      {
        paddingLeft: 14,
      },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    "& .MuiOutlinedInput-adornedEnd": {
      padding: 0,
    },
    "& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
      padding: 0,
    },
  },
  assignPaper: {
    background: "white !important",
    "& ::-webkit-scrollbar": {
      display: "none",
    },
  },
  noOptions: {
    color: "#BE0A02",
  },
  popper: {
    zIndex: 1301,
  },
  assignToWrapper: {
    display: "flex",
    marginTop: 8,
    flexDirection: "column",
    "&>*:first-child": {
      flex: 1,
      marginBottom: 8,
    },
  },
  text: {
    padding: "1px",
  },
}));
