import { Typography } from '@material-ui/core';
import React from 'react';
import { usePublicPagesStyles } from '../../../utils/Styles/PublicPages.style';
import LandingPageWrapper from '../LandingPageWrapper';
import ContactUsPara from './ContactUsPara';

function Disclaimer() {

  const styles = usePublicPagesStyles();

  return (
    <LandingPageWrapper pageTitle={"Disclaimer"}>

      <Typography variant='h4' align='center' className={styles.pageTitle}>
        Disclaimer
      </Typography>

      <Typography className={styles.para}>
        meFolia hereby grants you access to <a href="https://checkndout.com" className={styles.link}>checkndout.com</a> (“the Website”) and invites you to purchase the services offered here.
      </Typography>

      <Typography className={styles.paraTitle}>
        Definitions and key terms
      </Typography>

      <Typography className={styles.para}>
        To help explain things as clearly as possible in this Disclaimer, every time any of these terms are referenced, are strictly defined as:
      </Typography>

      <ul className={styles.list}>
        <li>
          <strong>Cookie: </strong>small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.
        </li>
        <li>
          <strong>Company: </strong>when this policy mentions “Company,” “we,” “us,” or “our,” it refers to BETCHOULI LTD, (75 South Road, Feltham, England, TW13 6UG) that is responsible for your information under this Disclaimer.
        </li>
        <li>
          <strong>Service: </strong>refers to the service provided by meFolia as described in the relative terms (if available) and on this platform.
        </li>
        <li>
          <strong>Website: </strong>meFolia."’s" site, which can be accessed via this URL: <a href="https://checkndout.com" className={styles.link}>checkndout.com</a>
        </li>
        <li>
          <strong>You: </strong>a person or entity that is registered with meFolia to use the Services.
        </li>
      </ul>

      <Typography className={styles.paraTitle}>
        Limited liability
      </Typography>

      <Typography className={styles.para}>
        meFolia endeavours to update and/or supplement the content of the website/app on a regular basis. Despite our care and attention, content may be incomplete and/or incorrect.
      </Typography>

      <Typography className={styles.para}>
        The materials offered on the website/app are offered without any form of guarantee or claim to their correctness. These materials can be changed at any time without prior notice from meFolia.
      </Typography>

      <Typography className={styles.para}>
        Particularly, all prices on the website/app are stated subject to typing and programming errors. No liability is assumed for the implications of such errors. No agreement is concluded on the basis of such errors.
      </Typography>

      <Typography className={styles.para}>
        meFolia shall not bear any liability for hyperlinks to websites or services of third parties included on the website/app. From our website/app, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone ‘bad’.
      </Typography>

      <Typography className={styles.para}>
        Please be also aware that when you leave our website/app, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.
      </Typography>

      <Typography className={styles.paraTitle}>
        Links to Other Websites Disclaimer
      </Typography>

      <Typography className={styles.para}>
        This Disclaimer applies only to the Services. The Services may contain links to other websites not operated or controlled by meFolia. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy and Terms of every site You visit.
      </Typography>

      <Typography className={styles.para}>
        We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
      </Typography>

      <Typography className={styles.paraTitle}>
        Errors and Omissions Disclaimer
      </Typography>

      <Typography className={styles.para}>
        meFolia is not responsible for any content, code or any other imprecision.
      </Typography>

      <Typography className={styles.para}>
        meFolia does not provide warranties or guarantees.
      </Typography>

      <Typography className={styles.para}>
        In no event shall meFolia be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. meFolia reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.
      </Typography>

      <Typography className={styles.paraTitle}>
        General Disclaimer
      </Typography>

      <Typography className={styles.para}>
        The meFolia Service and its contents are provided "as is" and "as available" without any warranty or representations of any kind, whether express or implied. meFolia is a distributor and not a publisher of the content supplied by third parties; as such, meFolia exercises no editorial control over such content and makes no warranty or representation as to the accuracy, reliability or currency of any information, content, service or merchandise provided through or accessible via the meFolia Service. Without limiting the foregoing, meFolia specifically disclaims all warranties and representations in any content transmitted on or in connection with the meFolia Service or on sites that may appear as links on the meFolia Service, or in the products provided as a part of, or otherwise in connection with, the meFolia Service, including without limitation any warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or written information given by meFolia or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty. Price and availability information is subject to change without notice. Without limiting the foregoing, meFolia does not warrant that the meFolia Service will be uninterrupted, uncorrupted, timely, or error-free.
      </Typography>

      <Typography className={styles.paraTitle}>
        Affiliate Links Disclosure
      </Typography>

      <Typography className={styles.para}>
        meFolia has affiliate links and in this section of the Disclaimer we will address how we use those affiliate links from other websites/companies and products. These “affiliate links” are specific URLs that contain the affiliate's ID or username.
      </Typography>

      <Typography className={styles.para}>
        In compliance with the FTC guidelines, please assume the following about links and posts on this site:
      </Typography>

      <ul className={styles.list}>
        <li>
          Any/all of the links on meFolia are affiliate links of which we receive a small commission from sales of certain items, but the price is the same for you. As meFolia has grown, so have costs associated with running and maintaining it, and affiliate links are a way we help offset these costs.
        </li>
        <li>
          If we post an affiliate link to a product, it is something that we personally use, support and would recommend without an affiliate link.
        </li>
        <li>
          Unless otherwise noted, all reviews are of items we have purchased and we are not paid or compensated in any way.
        </li>
      </ul>


      <Typography className={styles.paraTitle}>
        Your Consent
      </Typography>

      <Typography className={styles.para}>
        We've updated our Disclaimer to provide you with complete transparency into what is being set when you visit our site and how it's being used. By using our website/app, registering an account, or making a purchase, you hereby consent to our Disclaimer and agree to its terms.
      </Typography>

      <Typography className={styles.paraTitle}>
        Changes To Our Disclaimer
      </Typography>

      <Typography className={styles.para}>
        Should we update, amend or make any changes to this document so that they accurately reflect our Service and policies. Unless otherwise required by law, those changes will be prominently posted here. Then, if you continue to use the Service, you will be bound by the updated Disclaimer. If you do not want to agree to this or any updated Disclaimer, you can delete your account.
      </Typography>


      <ContactUsPara />

    </LandingPageWrapper>
  )
}

export default Disclaimer