import React, { useRef, useState } from "react";
import {
  Button,
  Dialog,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import HeadingBar from "../HeadingBar";
import { SnapItem, SnapList, useDragToScroll } from "react-snaplist-carousel";
import ChipCNO from "../../library/ChipCNO";
import InputCNO from "../../library/InputCNO";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import clsx from "clsx";
import { useFormik } from "formik";
import { RateType } from "../../utils";
import {
  Currency,
  CurrencyIcon,
  JobTargetType,
  JobTargetTypeIcon,
} from "../../utils/mappings";
import {
  mdiContentSave,
  mdiFileCancel,
  mdiFormatText,
  mdiHours24,
  mdiLockClock,
  mdiPencilBoxOutline,
  mdiPlus,
} from "@mdi/js";
import AddCustomer from "../AddCustomer/AddCustomer";
import AutocompleteCNO from "../../library/AutocompleteCNO";
import Icon from "@mdi/react";
import { useDispatch, useSelector } from "react-redux";
import {
  addQuotation,
  generateQuotationPdf,
  toggleAddQuotationDialog,
} from "../../actions/quotationActions";
import { fetchCustomerSuggest } from "../../actions/suggestionsActions";
import FlexibleSpaceCNO from "../../library/FlexibleSpaceCNO/FlexibleSpaceCNO";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import { getMaterialIconString } from "../../utils/icons";
import * as icons from "@mdi/js";
import FormBox from "../FormBox/FormBox";
import GooglePlaceInputCNO from "../../library/GooglePlaceInputCNO/GooglePlaceInputCNO";
import { generateId } from "../../utils/IdGeneratorHelpers";
import { toggleAddCustomerDialog } from "../../actions/customerActions";
import moment from "moment";
import * as yup from "yup";
import InputLabelCNO from "../../library/InputLabelCNO";
import FileViewerModal from "../../library/FileViewerModal";
import { toast } from "react-toastify";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import LocalStorageConstants from "../../utils/LocalStorageConstants";
import { useDebouncedFormik, useFormikErrors } from "../../hooks";
import {
  getTitleFromCustomer,
  validateStartDateEndDate,
} from "../../utils/Helpers";
import { AppConstants } from "../../utils/AppConstants";
import DateTimePickerCNO from "../../library/DateTimePickerCNO";

function AddQuotation() {
  let currencyType = localStorage.getItem("currencyType");
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const authUser = LocalStorageConstants.getItem("authUser");

  let authUserCompany = localStorage.getItem("authUserCompany");
  authUserCompany = authUserCompany ? JSON.parse(authUserCompany) : null;

  const tillTablet = useMediaQuery("(max-width: 767px)");

  const theme = useTheme();
  const styles = useStyles({ tillTablet });

  const GlobalStyles = useGlobalStyles();

  let localCategories = localStorage.getItem("categories");
  localCategories = localCategories ? JSON.parse(localCategories) : [];

  const { isAddCustomerDialog } = useSelector((state) => state.customers);

  const dispatch = useDispatch();

  const suggestions = useSelector((state) => state.suggestions);
  const { isAddQuotationDialog, allQuotationLoader } = useSelector(
    (state) => state.quotations
  );
  const { isPdfGenerating, addLoader } = allQuotationLoader;

  const [pdfFileSource, setPdfFileSource] = useState(false);

  const [isMoreDetailsExpanded, setIsMoreDetailsExpanded] = useState(false);

  const handleClose = () => {
    localStorage.removeItem("addQuotation");

    dispatch(toggleAddQuotationDialog(false));
  };

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  const validationSchema = yup.object().shape({
    title: yup.string().required("Quotation title is required!"),
    description: yup.string(),
    startDate: yup.string().required("Quotation start date is required!"),
    endDate: yup
      .string()
      .required("Quotation end date is required!")
      .test(
        "endDateTimeValidation",
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({
            startDate,
            startTime,
            endDate,
            endTime,
          });

          return isValid;
        }
      ),
    startTime: yup.string().required("Quotation start time is required!"),
    endTime: yup
      .string()
      .required("Quotation end time is required!")
      .test(
        "endDateTimeValidation",
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({
            startDate,
            startTime,
            endDate,
            endTime,
          });

          return isValid;
        }
      ),
    categories: yup.array().required("Quotation end time is required!"),
    customer: yup.object().required("Contact person is required!"),
    rate: yup.string().required("Rate is required!"),
    rateType: yup.number().required("Rate is required!"),
    targetType: yup.number(),
    target: yup.string(),
    validDate: yup.string().required("Valid till date is required!"),
    notes: yup.string(),
    mapAddress: yup.string().nullable(),
  });

  const now = new Date();

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      categories: [],
      startDate: AppConstants.DEFAULT_START_DATE,
      startTime: AppConstants.DEFAULT_START_TIME,
      endDate: AppConstants.DEFAULT_END_DATE,
      endTime: AppConstants.DEFAULT_END_TIME,
      customer: "",
      rate: 0,
      rateType: RateType.Hourly,
      targetType: JobTargetType.None,
      target: 0,
      validTill: now,
      notes: `*Taxes as applicable\n - Any query write us at ${
        authUserCompany.email || ""
      }\n - This is a digital quotation and does not requires signature`,
      status: 1,
      mapAddress: "",
      lat: "",
      lng: "",
      mediaLinks: [],
      offerAssignments: [],
      paidAmount: 0,
      paymentByCustomerStatus: 1,
      proximity: 0,
      visibility: 0,
      sentDate: now,
      validDate: now,
    },
    validationSchema,
    onSubmit: (values) => handleAddQuotation(values),
  });

  useFormikErrors(formik);

  useDebouncedFormik(formik, "addQuotation");

  const handleAddQuotation = (values) => {
    console.log("Add Quotation values: ", values);

    const taxRateLocal = AppConstants.DEFAULT_TAX_RATE;
    // const taxRateLocal = LocalStorageConstants.getItem('taxRateLocal', 20);

    const localValidTillDate = values.validTill;
    const localSentOnDate = moment(values.sentDate).toISOString();
    const localValidTillDays = moment(localValidTillDate).diff(
      moment(localSentOnDate),
      "days"
    );

    const payload = {
      ...values,
      offerAssignments: [
        {
          staffId: authUser.id,
          staffText: `${authUser.firstName} ${authUser.lastName}`,
          startDate: values.startDate,
          startTime: values.startTime,
          endDate: values.endDate,
          endTime: values.endTime,
        },
      ],
      offerNumber: generateId(
        "quotation",
        values.customer.title ? values.customer.title : "No Customer"
      ),
      customerText: values.customer.title,
      customerId: values.customer.id,
      target: values.target ? values.target : 0,
      rate: values.rate ? values.rate : 0,
      taxRate: taxRateLocal,

      offerDate: localSentOnDate,
      dueInDays: localValidTillDays,
    };

    console.log("Add Quotation payload: ", payload);

    const onAddSuccess = () => {
      handleClose();
      formik.resetForm();
    };

    dispatch(addQuotation(payload, onAddSuccess));
  };

  const hanlePreviewPdf = async () => {
    const localValidTillDate = formik.values.validTill;
    const localSentOnDate = formik.values.sentDate.toISOString();
    const localValidTillDays = moment(localValidTillDate).diff(
      moment(localSentOnDate),
      "days"
    );

    const quoatation = {
      ...formik.values,
      offerNumber: generateId(
        "quotation",
        formik.values.customer.title
          ? formik.values.customer.title
          : "No Customer"
      ),
      customerId: formik.values.customer.id,
      dueInDays: localValidTillDays,
    };

    const onGeneratePdfComplete = (fileSource) => setPdfFileSource(fileSource);

    try {
      await dispatch(
        generateQuotationPdf(quoatation, onGeneratePdfComplete, false)
      );
    } catch (error) {
      console.log("Error: Preview PDF: ", error);
      toast.error("There is issue in generating pdf.");
    }
  };

  return (
    <Dialog
      open={isAddQuotationDialog}
      classes={{ paper: styles.paper }}
      className={styles.container}
    >
      {(isPdfGenerating || addLoader) && <FullScreenLoaderCNO />}

      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <HeadingBar title="Add Quotation" onClose={handleClose} />

        {isAddCustomerDialog && (
          <AddCustomer
            onAdd={(newCustomer) => {
              formik.setFieldValue("customer", {
                ...newCustomer,
                title: `${newCustomer.firstName} ${newCustomer.lastName}`,
              });
              formik.setFieldValue("mapAddress", newCustomer.mapAddress);
              formik.setFieldValue("lat", newCustomer.lat);
              formik.setFieldValue("lng", newCustomer.lng);
            }}
            jobLocation={formik.values.mapAddress}
          />
        )}

        <FormBox className={styles.sectionContainer}>
          <div className={styles.sectionContainer}>
            <div
              className={styles.customerNameWrapper}
              style={{
                alignItems: formik.errors.customer ? "center" : "flex-end",
              }}
            >
              <AutocompleteCNO
                name="customer"
                label="Contact Person"
                formik={formik}
                placeholder="Search Customer"
                // fullWidth
                data={suggestions}
                optionLabel={(x) => x.title || ""}
                renderOption={(option) => (
                  <div style={{ color: "#014663", fontWeight: "500" }}>
                    {option.title}
                  </div>
                )}
                onSuggestionClick={(value) => {
                  if (value) {
                    formik.setValues({
                      ...formik.values,
                      customer: value,
                      mapAddress: value.mapAddress,
                      lat: value.lat,
                      lng: value.lng,
                      title: getTitleFromCustomer(value, formik.values.title),
                    });
                  }
                }}
                onChange={(value) => {
                  if (value) {
                    dispatch(fetchCustomerSuggest(value));
                  }
                }}
                isRequired
              />
              <Icon
                path={mdiPlus}
                size="43px"
                color="goldenrod"
                onClick={() => {
                  dispatch(toggleAddCustomerDialog(true));
                }}
              />
            </div>

            <div style={{ marginTop: 8 }}>
              <GooglePlaceInputCNO
                address={formik.values.mapAddress}
                onSelectAddress={(address) => {
                  formik.setFieldValue("mapAddress", address.address);
                  formik.setFieldValue("lat", address.lat);
                  formik.setFieldValue("lng", address.lng);
                }}
                label="Address"
              />
            </div>
          </div>
        </FormBox>

        <FormBox>
          <div className={styles.sectionContainer}>
            <div>
              <InputLabelCNO label={"Category"} isRequired={true} />
              <SnapList ref={snapList} className={styles.jobChipSlider}>
                {localCategories.map((category) => (
                  <SnapItem>
                    <ChipCNO
                      icon={category.iconLink}
                      name={category.title}
                      isCategoryChip={true}
                      active={formik.values.categories[0] === category.id}
                      onClick={() =>
                        formik.setFieldValue("categories", [category.id])
                      }
                    />
                  </SnapItem>
                ))}
              </SnapList>
            </div>
            <InputCNO
              name="title"
              label="Quotation Title"
              formik={formik}
              placeholder="Enter Quotation Title"
              icon={mdiFormatText}
              fullWidth
              isRequired
            />
            <InputCNO
              name="description"
              label="Quotation Description"
              formik={formik}
              placeholder="Enter Quotation Details"
              fullWidth
              multiline
            />

            <DateTimePickerCNO
              dateName="startDate"
              datePlaceholder="Start Date"
              datePickerLabel="Start by Date"
              onDateChange={(date) => {
                formik.setFieldValue("startDate", date);
              }}
              timeName="startTime"
              timePlaceholder="Start Time"
              timePickerLabel="Start by Time"
              onTimeChange={(date) => {
                formik.setFieldValue("startTime", date);
              }}
              formik={formik}
              disablePast={true}
              iconColor="red"
            />

            <DateTimePickerCNO
              dateName="endDate"
              datePlaceholder="Finish Date"
              datePickerLabel="Finish by Date"
              onDateChange={(date) => {
                formik.setFieldValue("endDate", date);
              }}
              timeName="endTime"
              timePlaceholder="Finish Time"
              timePickerLabel="Finish by Time"
              onTimeChange={(date) => {
                formik.setFieldValue("endTime", date);
              }}
              formik={formik}
              minDate={formik.values.startDate}
              iconColor="green"
            />
          </div>
        </FormBox>

        <div
          className={GlobalStyles.link}
          onClick={() => setIsMoreDetailsExpanded(!isMoreDetailsExpanded)}
        >
          {isMoreDetailsExpanded ? "Less Details" : "More Details"}
        </div>

        {isMoreDetailsExpanded && (
          <div className={GlobalStyles.moreDetailsContainer}>
            <FormBox>
              <div className={styles.sectionContainer}>
                <div className={styles.rateTypeWrapper}>
                  <InputLabelCNO label={"Job Types"} isRequired={true} />
                  <div className={styles.rateTypeChips}>
                    <ChipCNO
                      icon={mdiLockClock}
                      name="Fixed"
                      size="1.5rem"
                      active={formik.values.rateType === RateType.Fixed}
                      onClick={() =>
                        formik.setFieldValue("rateType", RateType.Fixed)
                      }
                    />
                    <ChipCNO
                      icon={mdiHours24}
                      name="Hourly"
                      size="1.5rem"
                      active={formik.values.rateType === RateType.Hourly}
                      onClick={() =>
                        formik.setFieldValue("rateType", RateType.Hourly)
                      }
                    />
                  </div>
                </div>
                <InputCNO
                  name="rate"
                  label="Rate"
                  formik={formik}
                  placeholder="Enter Quotation Rate"
                  icon={
                    icons[getMaterialIconString(CurrencyIcon[currencyType])]
                  }
                  fullWidth
                  isRequired={true}
                  onChange={(e) => {
                    const value = e.target.value;

                    const rateValue = !isNaN(value)
                      ? value
                      : formik.values.rate;

                    formik.setFieldValue("rate", value === "" ? "" : rateValue);
                  }}
                />
              </div>
            </FormBox>

            <FormBox>
              <div className={styles.sectionContainer}>
                <div>
                  <Typography className={GlobalStyles.label}>
                    Target Types
                  </Typography>
                  <div className={GlobalStyles.chipsWrapper}>
                    {Object.keys(JobTargetType)
                      .filter((t) => t !== "None")
                      .map((target) => (
                        <ChipCNO
                          icon={
                            icons[
                              getMaterialIconString(JobTargetTypeIcon[target])
                            ]
                          }
                          name={target}
                          size="1.5rem"
                          active={
                            formik.values.targetType === JobTargetType[target]
                          }
                          onClick={() =>
                            formik.setFieldValue(
                              "targetType",
                              JobTargetType[target]
                            )
                          }
                        />
                      ))}
                  </div>
                </div>

                <InputCNO
                  name="target"
                  label="Job Target"
                  placeholder="Enter Job Target"
                  formik={formik}
                  error={formik.touched.target && Boolean(formik.errors.target)}
                  helperText={formik.errors.target}
                  icon={mdiFormatText}
                  fullWidth
                />
              </div>
            </FormBox>

            <FormBox>
              <div className={styles.sectionContainer}>
                <DateTimePickerCNO
                  dateName="validTill"
                  datePlaceholder="Valid Till"
                  datePickerLabel="Valid Till Date"
                  onDateChange={(date) => {
                    formik.setFieldValue("validTill", date);
                  }}
                  formik={formik}
                  disablePast={true}
                  iconColor="red"
                />

                <InputCNO
                  name="notes"
                  label="Quotation notes"
                  formik={formik}
                  placeholder="Enter Quotation notes"
                  fullWidth
                  multiline
                  rows={"5"}
                />
              </div>
            </FormBox>
          </div>
        )}

        <div className={styles.ctaWrapper}>
          <Button
            variant="contained"
            className={styles.left}
            startIcon={
              <Icon
                path={mdiFileCancel}
                size={"1rem"}
                color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR}
              />
            }
            onClick={handleClose}
          >
            Cancel
          </Button>

          <FlexibleSpaceCNO />

          <FileViewerModal
            mimeType={"pdf"}
            filePath={pdfFileSource}
            dialogTitle={`Quotation Preview - ${formik.values.title}`}
            onClick={hanlePreviewPdf}
          >
            <Button
              variant="contained"
              className={styles.draftBtn}
              startIcon={
                <Icon path={icons.mdiEye} size={"1rem"} color={"#cecece"} />
              }
              disabled={formik && (!formik.dirty || !formik.isValid)}
            >
              <pre>Preview</pre>
            </Button>
          </FileViewerModal>
          <Button
            type="submit"
            variant="contained"
            className={styles.draftBtn}
            startIcon={
              <Icon
                path={mdiPencilBoxOutline}
                size={"1rem"}
                color={"#cecece"}
              />
            }
            onClick={() => formik.setFieldValue("status", 1)}
            disabled={formik && (!formik.dirty || !formik.isValid)}
          >
            <pre>Save as Draft</pre>
          </Button>
          <Button
            type="submit"
            variant="contained"
            className={styles.right}
            startIcon={
              <Icon
                path={mdiContentSave}
                size={"1rem"}
                color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR}
              />
            }
            onClick={() => formik.setFieldValue("status", 2)}
            disabled={formik && (!formik.dirty || !formik.isValid)}
          >
            <pre>Save</pre>
          </Button>
        </div>
      </form>
    </Dialog>
  );
}

export default AddQuotation;

const useStyles = makeStyles((theme) => ({
  container: {},
  paper: {
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    borderRadius: 10,
    width: "70vw",
    maxWidth: "90vw",
    margin: ({ tillTablet }) => (tillTablet ? 8 : 32),
  },
  form: {
    padding: "20px",
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    overflowX: "hidden",
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`,
    "&>*": {
      marginBottom: theme.spacing(3),
    },
  },

  sectionContainer: {
    "&>*": {
      marginTop: theme.spacing(2),
    },
  },
  jobChipSlider: {
    display: "flex",
    padding: "5px 0",
    // width: 'calc(100vw - 85px)',
    // overflowX: 'auto',
    "&>*:not(:first-child)": {
      marginLeft: 15,
    },
  },
  timeStampWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  picker: {
    "& .MuiInputBase-root": {
      background: "white",
      color: "#444",
    },
    "& .MuiIconButton-label": {
      color: "#444",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& .MuiOutlinedInput-input": {
      paddingLeft: 5,
    },
    "& .MuiIconButton-root": {
      paddingLeft: 0,
      paddingRight: 5,
    },
  },
  start: {
    "& .MuiSvgIcon-root": {
      color: "#BE0A02",
    },
  },
  end: {
    "& .MuiSvgIcon-root": {
      color: "#3F8A02",
    },
  },
  time: {
    marginLeft: theme.spacing(2),
    maxWidth: "42%",
  },

  customerNameWrapper: {
    display: "flex",
    "&>*:first-child": {
      flex: 1,
    },
  },
  jobLocationLabel: {
    marginBottom: theme.spacing(1),
  },

  chip: {
    background: theme.palette.background.light,
  },
  rateTypeWrapper: {
    "&>*:first-child": {
      marginBottom: theme.spacing(1),
    },
  },
  rateTypeChips: {
    display: "flex",
    "&>*": {
      marginRight: theme.spacing(1),
    },
  },

  ctaWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(6),
    "& > *": {
      marginLeft: 4,
    },
  },
  left: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    // marginRight: theme.spacing(4),
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
  },
  right: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
  },
  draftBtn: {
    background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CANCEL_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_SECONDARY_BUTTON_TEXT_COLOR,
    marginRight: theme.spacing(2),
  },
}));
