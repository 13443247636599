import React, { useEffect, useState } from "react";
import { useStyles } from "./PaymentDueDetailsStyles";
import { Button, Typography } from "@material-ui/core";
import Moment from "react-moment";
import ConfirmationDialogCNO from "../../library/ConfirmationDialogCNO";
import { Currency, CurrencyIcon } from "../../utils/mappings";
import * as icons from "@mdi/js";
import Icon from "@mdi/react";
import { getMaterialIconString } from "../../utils/icons";
import MakePaymentDialog from "../MakePaymentDialog/MakePaymentDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPaymentDue,
  fetchPricingItems,
} from "../../actions/subscriptionActions";
import { formatTimeWithTZ } from "../../utils/Helpers";

function PaymentDueDetails({ profile }) {
  let currencyType = localStorage.getItem("currencyType");
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const styles = useStyles();

  const dispatch = useDispatch();
  // amount, subtype, dueDate
  const { paymentDue, fetchPaymentHistoryLoader, pricingItems } = useSelector(
    (state) => state.subscription
  );

  const [isConfirmPayment, setIsConfirmPayment] = useState(false);
  const [isPayDialog, setIsPayDialog] = useState(false);
  const [activeRow, setActiveRow] = useState(null);

  const isYearly = false;

  pricingItems = pricingItems.map((item) => ({
    ...item,
    price: isYearly ? item.price.yearly : item.price.monthly,
    strikedPrice: isYearly
      ? item.strikedPrice.yearly
      : item.strikedPrice.monthly,
    perUserPrice: isYearly
      ? item.perUserPrice.yearly
      : item.perUserPrice.monthly,
  }));

  let paymentDate = profile.paymentDate;
  if (new Date(paymentDate).getFullYear() == "1" || !paymentDate) {
    paymentDate = new Date().toISOString();
  }

  const handlePay = () => {
    setIsPayDialog(true);
  };

  useEffect(() => {
    const payload = {};

    dispatch(fetchPaymentDue(payload));

    if (!pricingItems.length) {
      dispatch(fetchPricingItems());
    }
  }, []);

  if (!paymentDue) {
    return (
      <Typography style={{ margin: "10px 5px" }}>
        {fetchPaymentHistoryLoader ? "Loading..." : "No subscription selected."}
      </Typography>
    );
  }

  let subscription = pricingItems.find(
    (item) => item.id === paymentDue.subscriptionType
  );
  subscription = {
    ...subscription,
    subscriptionType: paymentDue.subscriptionType,
  };
  console.log("==== PaymentDueDetails ====", { subscription, paymentDue });

  return (
    <div className={styles.container_PaymentDueDetails}>
      <div className={styles.paymentsDueTableWrapper}>
        <div className={styles.paymentsDueTableInfo}>
          <div className={styles.rowCell}>
            <Typography
              className={styles.cellTop}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Icon
                path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
                size="1rem"
                style={{ marginRight: 4 }}
              />
              {paymentDue.amount}
            </Typography>
            <Typography className={styles.cellBottom}>Payment Due</Typography>
          </div>

          <div className={styles.rowCell}>
            <Moment
              element={Typography}
              format="MMM DD, YYYY"
              className={styles.cellTop}
            >
              {formatTimeWithTZ(paymentDue.dueDate)}
            </Moment>
            <Typography className={styles.cellBottom}>Due Date</Typography>
          </div>

          <div className={styles.rowCell}>
            <Typography className={styles.cellTop}>
              {subscription.package_name}
            </Typography>
            <Typography className={styles.cellBottom}>Subscription</Typography>
          </div>

          <div className={styles.rowCell}>
            <Button
              variant="outlined"
              onClick={() => {
                setIsConfirmPayment(true);
              }}
              className={styles.inTableBtn}
            >
              Pay Now
            </Button>
          </div>

          {isConfirmPayment && (
            <ConfirmationDialogCNO
              open={isConfirmPayment}
              onClose={() => setIsConfirmPayment(false)}
              dialogTitle="Confirm Payment"
              messages={["Are you sure you want to pay?"]}
              yesClick={handlePay}
            />
          )}

          {
            isPayDialog && (
              <MakePaymentDialog
                open={isPayDialog}
                onClose={() => {
                  setIsConfirmPayment(false);
                  setIsPayDialog(false);
                }}
                paymentData={activeRow}
              />
            )
            /*<CompanyPaymentDialog
              open={isPayDialog}
              paymentData={activeRow}
              onClose={() => {
                setIsConfirmPayment(false);
                setIsPayDialog(false);
              }}
            />*/
          }
        </div>
      </div>
    </div>
  );
}

export default PaymentDueDetails;
