import axios from "axios";
import { v4 } from "uuid";
import { toast } from "react-toastify";

export const SET_FORMS = "SET_FORMS";
export const SET_FORMS_LOADER = "SET_FORMS_LOADER";
export const SET_FORM_BY_ID = "SET_FORM_BY_ID";
export const SET_FORM_SEARCH_PARAMS = "SET_FORM_SEARCH_PARAMS";
export const SET_FORMS_IN_MENUS = "SET_FORMS_IN_MENUS";

export const SET_DYNAMIC_FORMS_DATA = "SET_DYNAMIC_FORMS_DATA";
export const TOGGLE_ADD_DYNAMIC_DATA_FORMS_DIALOG =
  "TOGGLE_ADD_DYNAMIC_DATA_FORMS_DIALOG";

import FormBuilderJson from "./DummyData/formBuilder.json";
import { FormStatus } from "../features/formBuilder/utils/enums";

export const setDynamicFormsData = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_DYNAMIC_FORMS_DATA,
      payload,
    });
  };
};

export const toggleAddDynamicFormsDataDialog = (payload) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_DYNAMIC_DATA_FORMS_DIALOG,
      payload,
    });
  };
};

export const setForms = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_FORMS,
      payload,
    });
  };
};

export const setFormsInMenu = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_FORMS_IN_MENUS,
      payload,
    });
  };
};

export const setFormById = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_FORM_BY_ID,
      payload,
    });
  };
};

export const updateFormStatus = (payload) => {
  console.log(payload, "updated payload form updateFormStatus");
  return (dispatch, getState) => {
    const currentForm = getState().formBuilder.forms;
    console.log(currentForm, "current form data form getstate");
    const updatedForm = currentForm.map((item) => {
      if (item.id === payload.id) return payload;
      else return item;
    });
    console.log(currentForm, "from current data ");
    console.log(updatedForm, "from payload data");
    dispatch(setForms([]));
    dispatch(setForms(updatedForm));
  };
};

export const setFormLoaders = (loaderName, loaderState) => {
  return (dispatch) => {
    dispatch({
      type: SET_FORMS_LOADER,
      payload: { loaderName, loaderState },
    });
  };
};

export const setFormSearchParams = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_FORM_SEARCH_PARAMS,
      payload: payload,
    });
  };
};

export const fetchForms = (payload = {}) => {
  return async (dispatch, getState) => {
    payload = {
      status: payload.status || FormStatus.id.Draft,
      ...payload,
    };

    dispatch(setFormLoaders("formsLoader", true));

    try {
      const resp = await axios.get(
        "https://jsonplaceholder.typicode.com/users"
      );

      const { forms } = getState().formBuilder;

      console.log("fetchForms:: forms: ", forms);

      const allForms = [...forms, ...FormBuilderJson];
      // const searchedForms = forms.filter(form => form.status === payload.status);
      const searchedForms = allForms.filter(
        (form) => form.status === payload.status
      );
      console.log(
        "fetchForms:: searchedForms: ",
        payload.status,
        searchedForms
      );

      dispatch(setForms(searchedForms));
    } catch (error) {
      console.error("ERROR: fetchForms: ", error);

      toast.error("There is issue in getting form!");
    } finally {
      dispatch(setFormLoaders("formsLoader", false));
    }
  };
};

export const fetchFormById = (payload = {}) => {
  return async (dispatch, getState) => {
    payload = {
      ...payload,
    };

    dispatch(setFormLoaders("formByIdLoader", true));

    try {
      const resp = await axios.get(
        "https://jsonplaceholder.typicode.com/users"
      );

      const { forms } = getState().formBuilder;

      const allForms = [...forms, ...FormBuilderJson];

      // const formById = forms.find(form => form.id === payload.id);
      const formById = allForms.find((form) => form.id === payload.id);

      console.log("fetchFormById: formById: ", formById);

      dispatch(setFormById(formById));
    } catch (error) {
      console.error("ERROR: formById: ", error);

      toast.error("There is issue in getting form!");
    } finally {
      dispatch(setFormLoaders("formByIdLoader", false));
    }
  };
};

export const addForms = (payload = {}, { onSuccess }) => {
  return async (dispatch, getState) => {
    payload = {
      ...payload,
    };

    dispatch(setFormLoaders("addFormLoader", true));

    try {
      const resp = await axios.get(
        "https://jsonplaceholder.typicode.com/users"
      );

      const { forms } = getState().formBuilder;

      const newForm = {
        id: v4(),
        ...payload,
      };

      dispatch(setForms([newForm, ...forms]));

      typeof onSuccess === "function" && onSuccess(newForm);

      toast.success("Form created successfully. Start designing!");
    } catch (error) {
      console.error("ERROR: fetchForms: ", error);

      toast.error("There is issue in getting form!");
    } finally {
      dispatch(setFormLoaders("addFormLoader", false));
    }
  };
};

export const handleFormSearch = (searchParams = {}) => {
  return async (dispatch, getState) => {
    await dispatch(setFormSearchParams(searchParams));

    const searchPayload = {
      status: searchParams.status || FormStatus.id.Draft,
    };

    await dispatch(fetchForms(searchPayload));
  };
};

export const fetchFormsInMenu = (payload = {}) => {
  return async (dispatch, getState) => {
    payload = {
      status: FormStatus.id.Published,
      ...payload,
    };

    dispatch(setFormLoaders("formsInMenuLoader", true));

    try {
      const resp = await axios.get(
        "https://jsonplaceholder.typicode.com/users"
      );

      const { forms } = getState().formBuilder;

      const allForms = [...forms, ...FormBuilderJson];
      // const searchedForms = forms.filter(form => form.status === payload.status);
      const searchedForms = allForms.filter(
        (form) => form.status === payload.status
      );

      dispatch(setFormsInMenu(searchedForms));
    } catch (error) {
      console.error("ERROR: formsInMenu: ", error);

      toast.error("There is issue in getting form!");
    } finally {
      dispatch(setFormLoaders("formsInMenuLoader", false));
    }
  };
};

export const fetchDynamicFormsData = (payload = {}) => {
  return async (dispatch, getState) => {
    payload = {
      formId: "",
      ...payload,
    };

    dispatch(setFormLoaders("dynamicFormsDataLoader", true));

    try {
      const resp = await axios.get(
        "https://jsonplaceholder.typicode.com/users"
      );

      const { dynamicFormsData } = getState().formBuilder;

      const searchedFormData = dynamicFormsData.filter(
        (form) => form.formId === payload.formId
      );

      dispatch(setDynamicFormsData(searchedFormData));
    } catch (error) {
      console.error("ERROR: dynamicFormsData: ", error);

      toast.error("There is issue in getting form data!");
    } finally {
      dispatch(setFormLoaders("dynamicFormsDataLoader", false));
    }
  };
};

export const addDynamicFormsData = (
  payload = {},
  { onSuccess, onError } = {}
) => {
  return async (dispatch, getState) => {
    payload = {
      ...payload,
      id: v4(),
    };

    dispatch(setFormLoaders("addDynamicFormsDataLoader", true));

    try {
      const resp = await axios.get(
        "https://jsonplaceholder.typicode.com/users"
      );

      const { dynamicFormsData } = getState().formBuilder;

      dispatch(setDynamicFormsData([payload, ...dynamicFormsData]));

      typeof onSuccess === "function" && onSuccess();
    } catch (error) {
      console.error("ERROR: dynamicFormsData: ", error);

      toast.error("There is issue in getting form data!");

      typeof onError === "function" && onError();
    } finally {
      dispatch(setFormLoaders("addDynamicFormsDataLoader", false));
    }
  };
};
