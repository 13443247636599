import { Drawer, makeStyles, useMediaQuery } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { useGlobalStore } from "../../providers/GlobalProvider";
import { TOGGLE_SEARCH } from "../../utils/actions";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { useContentTopSpace } from "../../hooks";

function Sidebar({ children, fullCollapsed = false, open, width }) {
  const { globalState, dispatchGlobal } = useGlobalStore();
  const { isLeftSidebar } = globalState;

  const route = useRouteMatch();
  const isHomePath = route.path.includes("/home");

  const isDrawer = open;

  const handleClose = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false,
    });
  };

  const tillTablet = useMediaQuery("(max-width: 767px)");

  let contentTopSpace = useContentTopSpace();

  const styles = useStyles({
    fullCollapsed,
    tillTablet,
    isLeftSidebar,
    isHomePath,
    contentTopSpace,
    width
  });
  return (
    <Drawer
      className={clsx(styles.container, {
        [styles.drawerOpen]: isDrawer,
        [styles.drawerClose]: !isDrawer,
      })}
      open={isDrawer}
      variant={tillTablet ? "temporary" : "permanent"}
      anchor="left"
      classes={{
        // drawer: {
        //     zIndex: 1301,
        // },
        paper: clsx(styles.paper, {
          [styles.drawerOpen]: isDrawer,
          [styles.drawerClose]: !isDrawer && !tillTablet,
        }),
      }}
      onClose={handleClose}
    >
      {children}
    </Drawer>
  );
}

export default Sidebar;

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    // boxShadow: `2px 4px 8px -2px ${theme.palette.text.disabled}`,
    flexShrink: 0,
    position: "fixed",
    top: ({ contentTopSpace }) => contentTopSpace,
    // top: 60,
    left: ({ isLeftSidebar }) => (isLeftSidebar ? 240 : theme.spacing(7)),
    zIndex: 1300,
  },
  paper: {
    position: "relative",
    background: theme.colors.SIDEBAR_BACKGROUND_COLOR,
    // background: theme.palette.background.main,
    height: "calc(100vh - 60px)",
    color: theme.colors.LIST_CARD_PARA_COLOR,
  },
  drawerOpen: ({ width }) => ({
    minWidth: 240,
    maxWidth: width,
    width: width,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: ({ fullCollapsed }) => (fullCollapsed ? 0 : theme.spacing(7) + 1),
    //   [theme.breakpoints.up('sm')]: {
    //     width: theme.spacing(7) + 1,
    //   },
  },
}));
