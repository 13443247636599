import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuotation } from '../../actions/quotationActions';

function EditQuotationDiscountDialog({
  open,
  onClose,
  quotation,
}) {

  const dispatch = useDispatch();
  const { allQuotationLoader: { editLoader } } = useSelector(state => state.quotations);

  const formik = useFormik({
    initialValues: {
      discount: quotation.discount,
      discountRate: quotation.discountRate,
    },
    validationSchema: yup.object({
      discount: yup.string().test('emptyString', 'Please enter valid value', value => !(value === "" || isNaN(value))),
      discountRate: yup.string().test('emptyString', 'Please enter valid value', value => !(value === "" || isNaN(value)))
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("Edit Quotation Item: title: ", values);


    const payload = {
      ...quotation,
      discount: values.discount,
      discountRate: values.discountRate
    };

    dispatch(updateQuotation(payload, true));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Quotation Discount"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name="discount"
          label="Discount"
          placeholder="Enter discount"
          icon={mdiFormatText}
          formik={formik}
          onChange={(e) => {

            const value = e.target.value;

            const discountValue = !isNaN(value) ? value : formik.values.discount;
            const discountRateValue = quotation.amount ? (
              ((parseFloat(discountValue) * 100) / parseFloat(quotation.amount)).toFixed(2)
            ) : "";

            formik.setFieldValue('discount', value === "" ? "" : discountValue);
            formik.setFieldValue('discountRate', !isNaN(discountRateValue) ? discountRateValue : "");

          }}
        />

        <InputCNO
          name="discountRate"
          label="Discount %"
          placeholder="Enter discount %"
          icon={mdiFormatText}
          formik={formik}
          onChange={(e) => {

            const value = e.target.value;

            const discountRateValue = !isNaN(value) ? value : formik.values.discountRate;
            const discountValue = !isNaN(discountRateValue) ? parseFloat(discountRateValue * quotation.amount / 100).toFixed(2) : "";

            formik.setFieldValue('discount', !isNaN(discountValue) ? discountValue : "");
            formik.setFieldValue('discountRate', discountRateValue === "" ? "" : discountRateValue);

          }}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditQuotationDiscountDialog
