import { makeStyles } from '@material-ui/core';
import { mdiTimer } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import React from 'react'
import { MetaText } from '../../../../library';

const CardDetailsText = ({
  children,
  icon,
  containerStyle = {},
  labelStyle = {},
  ...rest
}) => {

  const styles = useStyles();

  let containerClassName = '';
  if (typeof containerStyle === 'string') {
    containerClassName = containerStyle;
  }

  let labelClassName = '';
  if (typeof labelStyle === 'string') {
    labelClassName = containerStyle;
  }


  return (
    <div className={clsx(styles.container, containerClassName)} style={containerStyle}>
      {
        icon && <Icon size={'1.4rem'} {...icon} />
      }
      <MetaText
        {...rest}
        className={clsx(styles.label, labelClassName)}
        style={labelStyle}
      >
        {children}
      </MetaText>
    </div>
  )
}

export default CardDetailsText;

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 12
  },

  label: {
    fontSize: '1rem'
  }

}));