import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { updateInvoice } from '../../actions/invoiceActions';

function EditInvoiceDiscountDialog({
  open,
  onClose,
  invoice,
}) {

  const dispatch = useDispatch();
  const { allInvoiceLoader: { editLoader } } = useSelector(state => state.invoices);

  const formik = useFormik({
    initialValues: {
      discount: invoice.discount,
      discountRate: invoice.discountRate,
    },
    validationSchema: yup.object({
      discount: yup.string().test('emptyString', 'Please enter valid value', value => !(value === "" || isNaN(value))),
      discountRate: yup.string().test('emptyString', 'Please enter valid value', value => !(value === "" || isNaN(value)))
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("Edit Invoice Item: title: ", values);


    const payload = {
      ...invoice,
      discount: values.discount,
      discountRate: values.discountRate
    };

    dispatch(updateInvoice(payload, true));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Invoice Discount"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name="discount"
          label="Discount"
          placeholder="Enter discount"
          icon={mdiFormatText}
          formik={formik}
          onChange={(e) => {

            const value = e.target.value;

            const discountValue = !isNaN(value) ? value : formik.values.discount;
            const discountRateValue = invoice.amount ? (
              ((parseFloat(discountValue) * 100) / parseFloat(invoice.amount)).toFixed(2)
            ) : "";

            formik.setFieldValue('discount', value === "" ? "" : discountValue);
            formik.setFieldValue('discountRate', !isNaN(discountRateValue) ? discountRateValue : "");

          }}
        />

        <InputCNO
          name="discountRate"
          label="Discount %"
          placeholder="Enter discount %"
          icon={mdiFormatText}
          formik={formik}
          onChange={(e) => {

            const value = e.target.value;

            const discountRateValue = !isNaN(value) ? value : formik.values.discountRate;
            const discountValue = !isNaN(discountRateValue) ? parseFloat(discountRateValue * invoice.amount / 100).toFixed(2) : "";

            formik.setFieldValue('discount', !isNaN(discountValue) ? discountValue : "");
            formik.setFieldValue('discountRate', discountRateValue === "" ? "" : discountRateValue);

          }}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditInvoiceDiscountDialog
