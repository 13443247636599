import React, { useContext, useEffect, useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { mdiDelete, mdiAccountDetails } from "@mdi/js";
import Icon from "@mdi/react";
import AddTestQuestion from "../AddTestQuestion/AddTestQuestion";
import { TestContext } from "../../common/contexts/TestContext";

const useStyles = makeStyles((theme) => ({
  container_MorePopover: {
    padding: 5,
    overflow: "hidden",
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR,
  },
  list: {
    background: theme.colors.MORE_ACTIONS_OPTIONS_WRAPPER_BACKGROUND_COLOR,
    padding: 0,
  },
  listItem: {
    background:
      theme.colors.MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 2,
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.01)",
    },
  },
  listItemText: {
    textDecoration: "none",
  },
}));

function TestQuestionMorePopover({ question, closeMore, test }) {
  const theme = useTheme();
  const styles = useStyles();
  const {
    //setTest,
    allTests,
    allAnswers,
    handleDeleteQuestion,
    setEditQuestionId,
    setCurrentTestId,
    setIsQuestionsModalOpen,
    setNewQuestion,
    currentTestId,
  } = useContext(TestContext);
  const [openEdit, setOpenEdit] = useState(false);
  // useEffect(() => setTest(test), []);

  const handleDelete = () => {
    handleDeleteQuestion(test.testId, question.id);
    closeMore();
  };
  console.log("test", test);

  const handleEdit = () => {
    const testAnswers = allAnswers.find(
      (answer) => answer.testId === test.testId
    );
    const answerData = testAnswers ? testAnswers.questions : [];
    const answer = answerData.find((q) => q.questionId === question.id);

    setCurrentTestId(currentTestId);
    setNewQuestion({
      type: question.type,
      question: question.question,
      options: question.options,
      imageUrl: question.imageUrl,
      videoUrl: question.videoUrl,
      answer:
        question.type === "checkbox" ? answer.answer : answer.answer || "",
    });
    setEditQuestionId(question.id);
    setIsQuestionsModalOpen(true);
    setOpenEdit(false);
    closeMore();
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  return (
    <>
      {openEdit && (
        <AddTestQuestion
          isQuesModalOpen={openEdit}
          onClose={handleCloseEdit}
          question={question}
        />
      )}

      <div className={styles.container_MorePopover}>
        <List className={styles.list}>
          <ListItem className={styles.listItem} onClick={handleEdit}>
            <ListItemText primary="Edit Question" />
            <ListItemIcon>
              <IconButton edge="end">
                <Icon
                  path={mdiAccountDetails}
                  size="1.5rem"
                  color={
                    theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR
                  }
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
          <ListItem className={styles.listItem} onClick={handleDelete}>
            <ListItemText primary="Delete Question" />
            <ListItemIcon>
              <IconButton edge="end">
                <Icon
                  path={mdiDelete}
                  size="1.5rem"
                  color={theme.colors.ERROR_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        </List>
      </div>
    </>
  );
}

export default TestQuestionMorePopover;
