import { fade, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './CardStatusCNOStyles';

function CardStatusCNO({
  title,
  icon,
  onClick,

  hasTopRightIcon = false,
  topRightIcon,
  isTopRightActive,
  topRightActiveBgColor,
  topRightActiveBorderColor,
  topRightActiveColor,

  isActive,
  activeTextColor,
  activeBgColor,
  activeBorderColor,
  activeShadowColor
}) {

  const buttonBP = useMediaQuery('(max-width: 550px)');

  const theme = useTheme();
  const styles = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
      onClick={onClick}
    >
      <button
        className={styles.statusText}
        style={{
          padding: buttonBP ? '4px 5px' : '4px 15px',
          borderColor: isActive ? activeBorderColor : theme.colors.JOB_ACTION_BUTTON_BORDER_COLOR,
          background: isActive ? activeBgColor : theme.colors.JOB_ACTION_BUTTON_BACKGROUND_COLOR,
          textShadow: isActive ? `0px 0px 10px ${fade(activeBorderColor, 0.6)}` : '0 0 0 white',
          color: "white",
        }}
      >
        {
          icon
            ? <Icon
              path={icon}
              size={buttonBP ? '1rem' : '1.3rem'}
              className={styles.statusIcon}
              style={{
                borderColor: isActive ? fade(activeBorderColor, 0.5) : theme.colors.JOB_ACTION_BUTTON_BORDER_COLOR,
                // background: isActive ? fade(activeBgColor, 0.6) : theme.colors.JOB_ACTION_BUTTON_BACKGROUND_COLOR,
              }}
            />
            : null
        }
        <Typography
          component="span"
          style={{ fontSize: buttonBP ? '0.6rem' : '0.88rem', whiteSpace: 'pre', color: "#a5a4a4", textShadow: !isActive && 'none' }}
        >
          {title}
        </Typography>
      </button>

      {
        hasTopRightIcon
          ? <button
            className={styles.topRightButton}
            style={{
              background: isTopRightActive ? topRightActiveBgColor : 'linear-gradient(180deg, rgb(106 107 106) 9%, rgb(86 87 86) 53%)',
              borderColor: isTopRightActive ? topRightActiveBorderColor : 'rgb(116 119 115)',
              color: isTopRightActive ? topRightActiveColor : "#a5a4a4",
            }}
          >
            <Icon
              className={styles.topRightIcon}
              path={topRightIcon}
              size="1.3rem"
            />
          </button>
          : null
      }
    </div>
  )
}

export default CardStatusCNO;
