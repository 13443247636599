import { makeStyles, Typography, useTheme } from "@material-ui/core";
import Icon from "@mdi/react";
import React from "react";

function AttachmentItem({ icon, title, imageSrc, color, onClick }) {
  const styles = useStyles();

  const theme = useTheme();

  return (
    <div className={styles.listWrapper} onClick={onClick}>
      {imageSrc ? (
        <img src={imageSrc} style={{ width: "35px", marginTop: "5px" }} />
      ) : (
        <Icon
          path={icon}
          size="3rem"
          // color={color}
          className={styles.icon}
          style={{ background: color }}
        />
      )}

      {/* <Typography
        className={styles.title}
        style={{ background: color }}
      >
        {title}
      </Typography> */}
    </div>
  );
}

export default AttachmentItem;

const useStyles = makeStyles((theme) => ({
  listWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    padding: 10,
    borderRadius: "50%",
    marginRight: 15,
    boxShadow: `1px 1px 3px 0px #fce46ha8`,
    color: "white",
  },
  title: {
    padding: "5px 10px",
    boxShadow: `1px 1px 3px 0px ${theme.colors.LIST_CARD_SHADOW_COLOR}`,
    borderRadius: 5,
    width: 150,
    color: "white",
  },
}));
