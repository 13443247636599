import { Box, Button } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import { useMediasPendingExistForCurrrentItem } from "../hooks";
import {
  removeMediasPendingFromLS,
  triggerUploadMediasLsBg,
} from "../utils/MediaUpload";

const MediaUploadRetryClearButtons = () => {
  const { id } = useParams();

  const isMediasPendingUploadExist = useMediasPendingExistForCurrrentItem();

  const handleClearMediaItems = () => {
    removeMediasPendingFromLS(id, true);
  };

  const handleRetryMediaItemsUpload = () => {
    triggerUploadMediasLsBg(id);
  };

  if (!isMediasPendingUploadExist) return null;

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      style={{ gap: 8, marginTop: 8 }}
    >
      <Button onClick={handleClearMediaItems}>Clear Failed</Button>
      <Button onClick={handleRetryMediaItemsUpload}>Retry Failed</Button>
    </Box>
  );
};

export default MediaUploadRetryClearButtons;
