import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchAppointmentById, setAppointmentById } from '../../actions/appointmentActions';
import ScreenView from '../../components/ScreenView/ScreenView';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import AppointmentDetails from './AppointmentDetails';

function AppointmentDetailsPage() {

  const dispatch = useDispatch();
  const { appointmentById, appointmentDetailsLoader, appointments } = useSelector(state => state.appointments);

  const { id } = useParams();

  useEffect(() => {
    const appointmentId = id;

    const appointment = appointments.find(appointment => appointment.id === id);
    console.log("detailsItem",appointment);
    dispatch(fetchAppointmentById(appointmentId, appointment));

  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(setAppointmentById(null));
    };
  }, []);

  console.log("appointmentById: ", appointmentById);

  return (
    <div>
      <ScreenView
        content={
          appointmentById
            ? <AppointmentDetails appointment={appointmentById} />
            : (appointmentDetailsLoader ? <FullScreenLoaderCNO /> : null)
        }
        headerTitle="Appointments"
      />
    </div>
  )
}

export default AppointmentDetailsPage
