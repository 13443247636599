import { Dialog, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerById, searchCustomer } from '../../actions/customerActions';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { useStyles } from '../CustomerDetailsDialog/CustomerDetailsDialogStyles';
import HeadingBar from '../HeadingBar/HeadingBar';

function CustomerDetailsDialog({ isEditable, setIsEditable, customerId }) {
  const styles = useStyles();

  console.log("customerId: ", customerId);

  const dispatch = useDispatch();
  const { customerById, allCustomersLoader: { customerDetailLoader } } = useSelector(state => state.customers);

  useEffect(() => {
    const payload = {
      ids: [customerId],
      orderBy: "newest"
    };
    dispatch(getCustomerById([customerId], false));
  }, [customerId, dispatch]);

  const handleClose = () => setIsEditable(false);

  const DetailsRow = ({ label, value }) => {
    return (
      <div className={styles.detailsRow}>
        <Typography className={styles.label}>{label}</Typography>
        <Typography className={styles.value}>{value}</Typography>
      </div>
    )
  };

  if (customerDetailLoader) {
    return (<><FullScreenLoaderCNO /></>)
  }


  return (
    <Dialog
      open={isEditable}
      className={styles.container_CustomerDetailsDialog}
      classes={{
        paper: styles.paper
      }}
    >
      <HeadingBar title="Customer Details" onClose={handleClose} />
      <DetailsRow label="Name" value={`${customerById?.firstName} ${customerById?.lastName}`} />
      <DetailsRow label="About" value={customerById?.abstract} />
      <DetailsRow label="Company" value={customerById?.customerCompanyText || "Self"} />
      <DetailsRow label="Mobile" value={customerById?.mobile} />
      <DetailsRow label="Email" value={customerById?.email} />
    </Dialog>
  )
}

export default CustomerDetailsDialog
