import React from 'react'
import FormInput from './FormInput'
import { FormFieldsTypes } from '../../utils/enums'

function AddressAttributes({ formik }) {
  return (
    <>
      {
        formik.values.type === FormFieldsTypes.ids.Address ?
          <FormInput
            label='Address Label'
            placeholder='Enter label...'
            formik={formik}
            nameKey='name.address'
            labelKey='label.address'
            onChange={() => {
              formik.setFieldValue('name.lat', 'lat');
              formik.setFieldValue('name.lng', 'lng');
            }}
          />
          : null
      }
    </>
  )
}

export default AddressAttributes