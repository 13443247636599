import {
  Button,
  FormControlLabel,
  Slider,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useStyles } from "./CompanySubscriptionDetailsCardStyles";
import ChipCNO from "../../library/ChipCNO";
import { SubscriptionMode } from "../../utils";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
// import { mdiCar, mdiMapMarkerPath, mdiPlus, mdiWalk } from '@mdi/js';
import * as icons from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";
import PricingCard from "../PricingCard/PricingCard";
import {
  fetchCurrentSubscriptionDetails,
  fetchPricingItems,
  fetchPaymentDue,
  setPaymentDue,
} from "../../actions/subscriptionActions";
import MakePaymentDialog from "../MakePaymentDialog/MakePaymentDialog";
import ConfirmationDialogCNO from "../../library/ConfirmationDialogCNO";
import { updateCompanyDetails } from "../../actions/customerCompanyActions";
import LocalStorageConstants from "../../utils/LocalStorageConstants";
import SubscribeIcon from "@material-ui/icons/PaymentRounded";
import { parseNumber } from "../../utils/Helpers";
import { CurrencyHTMLEntities } from "../../utils/mappings";

function CompanySubscriptionDetailsCard({ profile, isActive }) {
  const theme = useTheme();

  const tillTablet = useMediaQuery("(max-width: 767px)");

  const styles = useStyles({ tillTablet });

  const dispatch = useDispatch();
  let { pricingItems, subscriptionDetailsLoader, paymentDue } = useSelector(
    (state) => state.subscription
  );

  //const currentPaymentData = pricingItems.find(profile.subscriptionType);

  console.log("----------profile-----ABHI----", profile);

  const [subscriptionMode, setSubscriptionMode] = useState(profile.paymentType);
  //const isYearly = profile.paymentType === 4? true : false;
  const isYearly = subscriptionMode === SubscriptionMode.Yearly;

  const [subscriptionTotalUsers, setSubscriptionTotalUsers] = useState(
    profile.userCount
  );
  // const [subscriptionTotalUsersCost, setSubscriptionTotalUsersCost] = useState(profile.userCount * (currentPaymentData.isYearly ? currentPaymentData.perUserPrice.yearly : currentPaymentData.perUserPrice.monthly));
  const [enableSlider, setEnableSlider] = useState(false);
  const [checked, setChecked] = useState(false);

  const [selectedSubscription, setSelectedSubscription] = useState({
    subscriptionType: profile.subscriptionType,
    paymentGatewayMeta: "Subscribe",
  });

  pricingItems = pricingItems.map((item) => ({
    ...item,
    price: isYearly ? item.price.yearly : item.price.monthly,
    strikedPrice: isYearly
      ? item.strikedPrice.yearly
      : item.strikedPrice.monthly,
    perUserPrice: isYearly
      ? item.perUserPrice.yearly
      : item.perUserPrice.monthly,
  }));

  useEffect(() => {
    if (!pricingItems.length) {
      dispatch(fetchPricingItems());
    }
  }, []);

  const users = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 5,
      label: "+5",
    },
    {
      value: 10,
      label: "+10",
    },
    {
      value: 15,
      label: "+15",
    },
    {
      value: 20,
      label: "+20",
    },
    {
      value: 25,
      label: "+25",
    },
    {
      value: 30,
      label: "+30",
    },
    {
      value: 35,
      label: "+35",
    },
    {
      value: 40,
      label: "+40",
    },
    {
      value: 45,
      label: "+45",
    },
    {
      value: 50,
      label: "+50",
    },
  ];

  function valuetext(value) {
    return `+${value} Users`;
  }

  const handleSliderChange = (event, newValue) => {
    setSubscriptionTotalUsers(newValue);
  };

  const handleSwitchChange = (event, newValue) => {
    setChecked(event.target.checked);
    setEnableSlider(!enableSlider);
    if (!event.target.checked) {
      setSubscriptionTotalUsers(profile.userCount);
    }
  };

  const [isConfirmSubscriptioin, setIsConfirmSubscriptioin] = useState(false);
  const [isPayDialog, setIsPayDialog] = useState(false);

  const handleSubscriptionUpdate = async (e) => {
    e.stopPropagation();
    setIsConfirmSubscriptioin(false);

    LocalStorageConstants.setItem("oldCompanyDetails", profile, true);
    const authUserCompany = LocalStorageConstants.getItem('authUserCompany');
    const oldPaymentDetails = LocalStorageConstants.getItem(`paymentDue-${authUserCompany.id}`);
    LocalStorageConstants.setItem("oldPaymentDetails", oldPaymentDetails, true);


    const dataToSend = {
      ...profile,
      ...selectedSubscription,
      paymentType: isYearly
        ? SubscriptionMode.Yearly
        : SubscriptionMode.Monthly,
      userCount: subscriptionTotalUsers,
    };

    console.log(
      "-------------------subscriptionRequest----------dataToSend-----",
      dataToSend
    );

    await Promise.resolve(dispatch(updateCompanyDetails(dataToSend))).then(
      () => {
        const subscriptionPayload = selectedSubscription.subscriptionType;
        dispatch(fetchCurrentSubscriptionDetails(subscriptionPayload));
        if (
          LocalStorageConstants.getItem("authUserCompany").stripeSecretKey !==
          "true"
        ) {
          handlePay();
        }
      }
    );

    // handlePay();

    console.log(
      "-------------------subscriptionResponse---------------",
      LocalStorageConstants.getItem("authUserCompany").stripeSecretKey
    );
  };

  const handlePay = () => {
    setIsPayDialog(true);
  };

  const [moreUserPrice, setMoreUserPrice] = useState(0);
  const [totalSubscriptionPrice, setTotalSubscriptionPrice] = useState(0);

  useEffect(() => {
    const currentPricing = pricingItems.find(
      (pricing) => selectedSubscription.subscriptionType === pricing.id
    );

    if (!currentPricing) return;

    const additionalUserCharge = parseNumber(
      subscriptionTotalUsers * currentPricing.perUserPrice,
      "float",
      0,
      2,
      true
    );
    const totalPricing = parseNumber(
      parseNumber(additionalUserCharge, "float", 0) +
      parseNumber(currentPricing.price, "float", 0),
      "float",
      0,
      2,
      true
    );

    setMoreUserPrice(additionalUserCharge);
    setTotalSubscriptionPrice(totalPricing);
  }, [
    pricingItems,
    selectedSubscription.subscriptionType,
    subscriptionTotalUsers,
  ]);

  const currencyHtmlEntity = profile.currency
    ? CurrencyHTMLEntities[profile.currency]
    : "&#163;";
  useEffect(() => {
    if (!pricingItems.length) {
      dispatch(fetchPricingItems());
    }

    if (!paymentDue) {
      dispatch(fetchPaymentDue());
    }
  }, []);

  console.log("=========pricingItems======", pricingItems);

  const resetSubscriptionOnFailOrCancel = async (isSuccess, isError) => {
    if (isSuccess || isError) return;
    const oldCompanyDetails = LocalStorageConstants.getItem("oldCompanyDetails", null, true);
    const oldPaymentDetails = LocalStorageConstants.getItem("oldPaymentDetails", null, true);
    const onSuccess = async () => {
      await dispatch(setPaymentDue(oldPaymentDetails));
      await dispatch(fetchCurrentSubscriptionDetails(oldCompanyDetails.subscriptionType));
      LocalStorageConstants.removeItem("oldCompanyDetails", true);
      LocalStorageConstants.removeItem("oldPaymentDetails", true);
      setSelectedSubscription({
        subscriptionType: oldCompanyDetails.subscriptionType,
        paymentGatewayMeta: "Subscribe"
      });
      setSubscriptionTotalUsers(oldCompanyDetails.userCount);
    }
    await dispatch(updateCompanyDetails(oldCompanyDetails, { onSuccess }));
  }
  useEffect(() => {
    const oldCompanyDetails = LocalStorageConstants.getItem("oldCompanyDetails", null, true);
    const oldPaymentDetails = LocalStorageConstants.getItem("oldPaymentDetails", null, true);
    if (oldCompanyDetails && oldPaymentDetails) {
      resetSubscriptionOnFailOrCancel();
    }
  }, []);

  return (
    <div
      className={styles.container_CompanySubscriptionDetailsCard}
      id="subscriptions"
    >
      {subscriptionDetailsLoader && <FullScreenLoaderCNO />}

      <Typography className={styles.title}>Subscription</Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            marginBottom: 5,
          }}
        >
          <ChipCNO
            active={!isYearly}
            name="Monthly"
            icon={icons.mdiCalendar}
            onClick={() => setSubscriptionMode(SubscriptionMode.Monthly)}
          />
          <ChipCNO
            active={isYearly}
            name="Yearly"
            icon={icons.mdiCalendarMonth}
            onClick={() => setSubscriptionMode(SubscriptionMode.Yearly)}
          />
        </div>
        <Typography
          style={{
            visibility: isYearly ? "visible" : "hidden",
            color: theme.colors.PROFILE_TEXT_INFO_COLOR,
          }}
        >
          10-20% discount in yearly mode!
        </Typography>
      </div>

      <div className={styles.chipsWrapper}>
        {pricingItems.map((pricing) => (
          <PricingCard
            key={pricing.id}
            profile={profile}
            type={pricing.id}
            isCardActive={selectedSubscription.subscriptionType === pricing.id}
            pricing={pricing}
            isActive={isActive}
            isYearly={isYearly}
            onSelectCard={(data) => {
              setSelectedSubscription(data);
            }}
          />
        ))}
      </div>

      <div style={{ marginTop: 50 }} id="more-users">
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={handleSwitchChange}
              color="primary"
              value="dynamic-class-name"
            />
          }
          label={
            <Fragment>
              <p
                dangerouslySetInnerHTML={{
                  __html: `More Users (${currencyHtmlEntity} ${moreUserPrice} for ${subscriptionTotalUsers} users)`,
                }}
              ></p>
            </Fragment>
          }
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsConfirmSubscriptioin(true);
          }}
          startIcon={<SubscribeIcon />}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: `Apply Subscription for ${currencyHtmlEntity} ${totalSubscriptionPrice}`,
            }}
          ></p>
        </Button>

        <div style={{ display: !enableSlider ? "none" : "block" }}>
          <Slider
            aria-label="Add more users"
            defaultValue={5}
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            step={5}
            marks={users}
            min={0}
            max={50}
            value={subscriptionTotalUsers}
            onChange={handleSliderChange}
            disabled={!enableSlider}
          />
        </div>
      </div>

      {isConfirmSubscriptioin && (
        <ConfirmationDialogCNO
          open={isConfirmSubscriptioin}
          onClose={(e) => {
            e.stopPropagation();
            setIsConfirmSubscriptioin(false);
          }}
          dialogTitle="Confirm Subscription"
          messages={[
            "Are you sure you want to update Subscription and proceed with Payment?",
          ]}
          yesClick={handleSubscriptionUpdate}
        />
      )}

      {isPayDialog && (
        <MakePaymentDialog
          open={isPayDialog}
          onClose={() => {
            setIsPayDialog(false);
          }}
          profile={profile}
          paymentData={pricingItems.find(
            (pricing) => selectedSubscription.subscriptionType === pricing.id
          )}
          selectedSubscription={selectedSubscription}
          isYearly={isYearly}
          subscriptionTotalUsers={subscriptionTotalUsers}
          resetSubscriptionOnFailOrCancel={resetSubscriptionOnFailOrCancel}
        />
      )}
    </div>
  );
}

export default CompanySubscriptionDetailsCard;
