import { Box, Button, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { mdiContentSave, mdiFileCancel, mdiPencil,mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import FlexibleSpaceCNO from "../../library/FlexibleSpaceCNO/FlexibleSpaceCNO";

function FormActionButtons({
  leftText = "Cancel",
  secondaryText,
  rightText,

  isLeftButton = true,

  leftClick,
  secondaryClick,
  rightClick,

  secondaryButtonType = 'submit',
  rightButtonType = 'submit',

  formik,
  wrapperStyles,
  disabled,
  closeMoreActions,
}) {
  const theme = useTheme();

  const btnLimit = useMediaQuery("(max-width: 768px)");

  const useStyles = makeStyles((theme) => ({
    ctaWrapper: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(6),
      ...wrapperStyles,
    },
    left: {
      border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
      marginRight: (rightText || secondaryText) ? theme.spacing(4) : 0,
      background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
      color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
    },

    secondary: {
      background: theme.colors.FORM_SECONDARY_BUTTON_BACKGROUND_COLOR,
      border: `2px solid ${theme.colors.FORM_SECONDARY_BUTTON_BORDER_COLOR}`,
      color: theme.colors.FORM_SECONDARY_BUTTON_TEXT_COLOR,
      marginRight: theme.spacing(3)
    },
    right: {
      background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
      border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
      color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    },
  }));
  const styles = useStyles();

  const handleCloseMore = () => {
    if (closeMoreActions) {
      closeMoreActions();
    }
  };

  const handeLeftClick = (e) => {
    
      if (leftClick) {
        leftClick(e);
      }
  
      handleCloseMore();
    
    
  };

  const handeRightClick = (e) => {
    if (rightClick) {
      rightClick(e);
    }

    handleCloseMore();
  };

  const handleSecondaryClick = (e) => {
    if (secondaryClick) {
      secondaryClick(e);
    }

    handleCloseMore();
  }

  return (
    <div className={styles.ctaWrapper}>
      {isLeftButton && (
        <Button
          variant="contained"
          className={styles.left}
          startIcon={
            <Icon
              path={mdiFileCancel}
              size={btnLimit ? "1rem" : "1.5rem"}
              color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR}
            />
          }
          onClick={handeLeftClick}
        >
          {leftText}
        </Button>
      )}
      <FlexibleSpaceCNO />

      <Box>
        {secondaryText && (
          <Button
            type={secondaryButtonType}
            variant="contained"
            className={styles.secondary}
            startIcon={
              <Icon
                path={mdiPencil}
                size={btnLimit ? "1rem" : "1.5rem"}
                color={theme.colors.FORM_SECONDARY_BUTTON_ICON_COLOR}
              />
            }
            onClick={handleSecondaryClick}
            disabled={(formik && (!formik.dirty || !formik.isValid)) || disabled}
          >
            <pre>{secondaryText}</pre>
          </Button>
        )}

        {rightText && (
          <Button
            type={rightButtonType}
            variant="contained"
            className={styles.right}
            startIcon={
              <Icon
                path={mdiContentSave}
                size={btnLimit ? "1rem" : "1.5rem"}
                color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR}
              />
            }
            onClick={handeRightClick}
            disabled={(formik && (!formik.dirty || !formik.isValid)) || disabled}
          >
            <pre>{rightText}</pre>
          </Button>
        )}
      </Box>

    </div>
  );
}

export default FormActionButtons;
