import React from 'react';
import QuickTutorialVideo from '../../components/QuickTutorialVideo';
import ScreenView from '../../components/ScreenView';
import Dashboard from './Dashboard';

function DashboardPage() {



  return (
    <div style={{ width: '100%', minHeight: '100vh' }}>
      <QuickTutorialVideo />
      <ScreenView
        content={<Dashboard />}
        headerTitle="Dashboard"
      />
    </div>
  )
}

export default DashboardPage