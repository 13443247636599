import { Button, Dialog, makeStyles, Typography } from "@material-ui/core";
import { mdiContentSave, mdiFileCancel } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

function ConfirmYesNoDialog({ open, setOpen, message, onConfirm, onCancel }) {
  const styles = useStyles();

  const handleClose = () => {
    setOpen(false);
    if (typeof onCancel === "function") onCancel();
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      className={styles.container_ConfirmDialog}
      classes={{ paper: styles.paper }}
    >
      <Typography>{message}</Typography>

      <div className={styles.ctaWrapper}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Icon path={mdiFileCancel} size="1.5rem" />}
          onClick={handleClose}
        >
          No
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<Icon path={mdiContentSave} size="1.5rem" />}
          onClick={handleConfirm}
        >
          Yes
        </Button>
      </div>
    </Dialog>
  );
}

export default ConfirmYesNoDialog;

const useStyles = makeStyles((theme) => ({
  container_ConfirmDialog: {},
  paper: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    background: theme.palette.background.main,
    width: "70vw",
    maxWidth: "90vh",
  },
  deleteForm: {
    "&>*": {
      marginTop: 10,
    },
  },
  confirmDeleteText: {
    color: "#BE0A02",
    marginTop: 5,
  },
  ctaWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(6),
    "&>*": {
      boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`,
      border: `2px solid ${theme.palette.ternary.main}`,
      color: theme.palette.text.primary,
      "&:first-child": {
        marginRight: theme.spacing(4),
        background: theme.palette.text.disabled,
      },
      "&:last-child": {
        background: theme.palette.primary.main,
      },
    },
  },
}));
