import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { mdiAccountDetails, mdiDelete, mdiLockReset } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinkCNO from "../../library/LinkCNO";
import RightsManager from "../../utils/RightsManager";
import { deleteEmployee } from "../../actions/employeeActions";
import { deleteServiceProvider } from "../../actions/serviceProviderActions";
import DialogCNO from "../../library/DialogCNO";
import FormActionButtons from "../FormActionButtons";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import { useRouteMatch } from "react-router";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import ProfileResetPassword from "../ProfileResetPassword";

function ProfileListCardPopover({
  profile,
  isEmployee,
  isServiceProvider,
  closeMoreActions,
}) {
  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const dispatch = useDispatch();
  const { allEmployeesLoader } = useSelector((state) => state.employees);
  const { deleteLoader: employeeDeleteLoader } = allEmployeesLoader;

  const { allServiceProvidersLoader } = useSelector(
    (state) => state.serviceProviders
  );
  const { deleteLoader: serviceProviderDeleteLoader } =
    allServiceProvidersLoader;

  // const [isDeactivateProfile, setIsDeactivateProfile] = useState(false);
  const [
    isDeactivateServiceProviderProfile,
    setIsDeactivateServiceProviderProfile,
  ] = useState(false);
  const [isDeactivateEmployeeProfile, setIsDeactivateEmployeeProfile] =
    useState(false);

  // const [isResetPasswordModal, setIsResetPasswordModal] = useState(false);
  const [isResetEmployeePasswordModal, setIsResetEmployeePasswordModal] =
    useState(false);
  const [
    isResetServiceProviderPasswordModal,
    setIsResetServiceProviderPasswordModal,
  ] = useState(false);

  const route = useRouteMatch();
  const isEmployeeDetailsPath = route.path.includes("/employee/");
  const isServiceProviderDetailsPath =
    route.path.includes("/service-provider/");

  const removeEmployee = () => {
    const removeEmployeePayload = {
      id: profile.id,
      profileId: profile.id,
    };

    dispatch(deleteEmployee(removeEmployeePayload));
    // setIsDeactivateProfile(false);
    setIsDeactivateEmployeeProfile(false);
  };

  const removeServiceProvider = () => {
    const removeServiceProviderPayload = {
      id: profile.id,
      profileId: profile.id,
    };

    dispatch(deleteServiceProvider(removeServiceProviderPayload));
    // setIsDeactivateProfile(false);
    setIsDeactivateServiceProviderProfile(false);
  };

  return (
    <div className={styles.container_MorePopover}>
      {employeeDeleteLoader ||
        (serviceProviderDeleteLoader && <FullScreenLoaderCNO />)}
      <List className={styles.list}>
        {(!(isEmployeeDetailsPath || isServiceProviderDetailsPath)) && (
          <LinkCNO
            to={`${
              isEmployee
                ? "employee"
                : isServiceProvider
                ? "service-provider"
                : "customer"
            }/${profile.id}`}
          >
            <ListItem className={GlobalStyles.listItem}>
              <ListItemText primary="Details" />
              <ListItemIcon>
                <IconButton edge="end">
                  <Icon
                    path={mdiAccountDetails}
                    size="1.5rem"
                    color={
                      theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR
                    }
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </LinkCNO>
        )}

        {/* {!isServiceProviderDetailsPath && (
          <LinkCNO
            to={`${
              isEmployee
                ? "employee"
                : isServiceProvider
                ? "service-provider"
                : "customer"
            }/${profile.id}`}
          >
            <ListItem className={GlobalStyles.listItem}>
              <ListItemText primary="Details" />
              <ListItemIcon>
                <IconButton edge="end">
                  <Icon
                    path={mdiAccountDetails}
                    size="1.5rem"
                    color={
                      theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR
                    }
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </LinkCNO>
        )} */}

        {isUserAdmin && isEmployee && profile.isActive && (
          <ListItem className={GlobalStyles.listItem}>
            <ListItemText
              primary="Deactivate"
              onClick={() => {
                // setIsDeactivateProfile(true)
                setIsDeactivateEmployeeProfile(true);
              }}
            />
            <ListItemIcon>
              <IconButton edge="end">
                <Icon
                  path={mdiDelete}
                  size="1.5rem"
                  color={theme.colors.ERROR_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        )}

        {isUserAdmin && isServiceProvider && profile.isActive && (
          <ListItem className={GlobalStyles.listItem}>
            <ListItemText
              primary="Deactivate"
              onClick={() => {
                // setIsDeactivateProfile(true)
                setIsDeactivateServiceProviderProfile(true);
              }}
            />
            <ListItemIcon>
              <IconButton edge="end">
                <Icon
                  path={mdiDelete}
                  size="1.5rem"
                  color={theme.colors.ERROR_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        )}

        {isUserAdmin && ((isEmployee && profile.isActive) || (!isServiceProvider && !isEmployee )) && (
          <ListItem className={GlobalStyles.listItem}>
            <ListItemText
              primary="Reset Password"
              onClick={() => setIsResetEmployeePasswordModal(true)}
            />
            <ListItemIcon>
              <IconButton edge="end">
                <Icon
                  path={mdiLockReset}
                  size="1.5rem"
                  color={
                    theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR
                  }
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        )}

        {isUserAdmin &&
          ((isServiceProvider && profile.isActive) || (!isServiceProvider && !isEmployee)) && (
            <ListItem className={GlobalStyles.listItem}>
              <ListItemText
                primary="Reset Password"
                onClick={() => setIsResetServiceProviderPasswordModal(true)}
              />
              <ListItemIcon>
                <IconButton edge="end">
                  <Icon
                    path={mdiLockReset}
                    size="1.5rem"
                    color={
                      theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR
                    }
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          )}
      </List>

      {isUserAdmin && isResetEmployeePasswordModal && (
        <ProfileResetPassword
          open={isResetEmployeePasswordModal}
          onClose={() => {
            setIsResetEmployeePasswordModal(false);
            closeMoreActions();
          }}
          profile={profile}
        />
      )}

      {isUserAdmin && isResetServiceProviderPasswordModal && (
        <ProfileResetPassword
          open={isResetServiceProviderPasswordModal}
          onClose={() => {
            setIsResetServiceProviderPasswordModal(false);
            closeMoreActions();
          }}
          profile={profile}
        />
      )}

      {isDeactivateEmployeeProfile && (
        <DialogCNO
          open={isDeactivateEmployeeProfile}
          onClose={() => setIsDeactivateEmployeeProfile(false)}
          dialogTitle="Deactivate Employee"
        >
          <Typography>Are you sure you want to deactivate user?</Typography>
          <FormActionButtons
            rightText="Deactivate"
            rightClick={removeEmployee}
            leftClick={() => setIsDeactivateEmployeeProfile(false)}
            closeMoreActions={closeMoreActions}
          />
        </DialogCNO>
      )}

      {isDeactivateServiceProviderProfile && (
        <DialogCNO
          open={isDeactivateServiceProviderProfile}
          onClose={() => setIsDeactivateServiceProviderProfile(false)}
          dialogTitle="Deactivate ServiceProvider"
        >
          <Typography>Are you sure you want to deactivate user?</Typography>
          <FormActionButtons
            rightText="Deactivate"
            rightClick={removeServiceProvider}
            leftClick={() => setIsDeactivateServiceProviderProfile(false)}
            closeMoreActions={closeMoreActions}
          />
        </DialogCNO>
      )}
    </div>
  );
}

export default ProfileListCardPopover;

const useStyles = makeStyles((theme) => ({
  container_MorePopover: {
    padding: 5,
    overflow: "hidden",
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR,
  },
  list: {
    background: theme.colors.MORE_ACTIONS_OPTIONS_WRAPPER_BACKGROUND_COLOR,
    // background: theme.palette.background.dark,
    padding: 0,
  },
  listItem: {
    background:
      theme.colors.MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 2,
    width: 200,
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.01)",
    },
  },
  listItemText: {
    textDecoration: "none",
  },
}));
