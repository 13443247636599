import { LOG_IN, LOG_OUT, SET_AUTH_LOADERS, SET_AUTH_USER_PROFILE_PIC, SET_IS_OLD_PASSWORD_VALID } from "../actions/authUserActions";
import { createBrowserHistory } from 'history';
import { Currency } from "../utils/mappings";
import LocalStorageConstants from "../utils/LocalStorageConstants";
import { AppConstants } from "../utils/AppConstants";

const initialState = {
  id: "",
  firstName: "",
  lastName: "",
  token: "",
  authUserProfilePic: null,
  isOldPasswordValid: false,

  //loaders
  updateLoader: false,
  logInLoader: false,
};

const history = createBrowserHistory();

export default function authUserReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOG_IN:
      if (payload.status === 200) {
        const path = history.createHref({ pathname: '/' });
        history.push(path);
        history.go();
      }

      let profile = payload.data.member || payload.data.customer;
      profile = { ...profile, token: payload.data.token };

      const {
        categories,
        invoiceEmailTemplate,
        offerEmailTemplate,
        appointmentEmailTemplate,
        emailSignatureTemplate,
        emailTermsAndConditionTemplate,
      } = payload.data.group;

      const group = {
        ...payload.data.group,
        currency: Currency.GBP,
        offerEmailTemplate: offerEmailTemplate ? offerEmailTemplate : AppConstants.DefaultQuotationEmailTemplate,
        invoiceEmailTemplate: invoiceEmailTemplate ? invoiceEmailTemplate : AppConstants.DefaultInvoiceEmailTemplate,
        appointmentEmailTemplate: appointmentEmailTemplate ? appointmentEmailTemplate : AppConstants.DefaultAppointmentEmailTemplate,
        emailSignatureTemplate: emailSignatureTemplate ? emailSignatureTemplate : AppConstants.DefaultEmailSignatureTemplate,
        emailTermsAndConditionTemplate: emailTermsAndConditionTemplate
      };
      console.log("authReducer",profile);
      localStorage.setItem('authUser', JSON.stringify(profile));
      localStorage.setItem('categories', JSON.stringify(categories));
      localStorage.setItem('authUserCompany', JSON.stringify(group));

      localStorage.setItem('currencyType', JSON.stringify(group.currency || Currency.GBP));

      console.log('====================================');
      console.log({ profile, categories, group });
      console.log('====================================');

      state = {
        ...state,
        ...profile
      };

      return state;

    case LOG_OUT:
      state = {};
      const path = history.createHref({ pathname: '/' });
      history.push(path);
      history.go();
      return state;


    case SET_AUTH_USER_PROFILE_PIC:
      state = {
        ...state,
        authUserProfilePic: payload
      };
      return state;

    case SET_AUTH_LOADERS:
      const { loaderName, loaderStatus } = payload;
      state = {
        ...state,
        [loaderName]: loaderStatus
      };
      return state;

    case SET_IS_OLD_PASSWORD_VALID:
      return {
        ...state,
        isOldPasswordValid: payload
      };

    default:
      return state;
  }
}
