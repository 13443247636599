import { generateSubscriptionReceiptTemplate } from "../../pdfTemplates/templates";
import { getCompanyMap } from "./PDFHelpers";

export const generateSubscriptionReceipt = (subscription, companyById, companyProfilePic) => {

  let templateString = "";

  const companyMap = getCompanyMap(companyById, companyProfilePic);

  templateString = generateSubscriptionReceiptTemplate(companyMap, subscription);

  return templateString;

};
