import { InputAdornment, Typography, useTheme } from "@material-ui/core";
import { mdiCalendar } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./UserPersonalDetailsCardStyles";
import DialogCNO from "../../library/DialogCNO";
import * as yup from "yup";
import { useFormik } from "formik";
import { updateEmployeeDetails } from "../../actions/employeeActions";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import { DatePicker } from "@material-ui/pickers";
import RightsManager from "../../utils/RightsManager";
import { updateCustomerDetails } from "../../actions/customerActions";
import { formatTimeWithTZ } from "../../utils/Helpers";
import { updateServiceProviderDetails } from "../../actions/serviceProviderActions";

const EditPersonalDetails = ({
  open,
  onClose,
  profile,
  isEmployee,
  isServiceProvider,
  localProfilePicUrl,
}) => {
  const styles = useStyles();

  const theme = useTheme();

  const dispatch = useDispatch();
  const {
    allEmployeesLoader: { editLoader: employeeEditLoader },
  } = useSelector((state) => state.employees);
  const {
    allCustomersLoader: { editLoader: customerEditLoader },
  } = useSelector((state) => state.customers);

  const validationSchema = yup.object({
    birthday: yup.string().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      birthday: profile.birthday,
    },
    validationSchema,
    onSubmit: (values) => handleUpdate(values),
  });

  const handleUpdate = (values) => {
    const dataToSend = {
      ...profile,
      birthday: values.birthday,
      localProfilePicUrl: localProfilePicUrl,
    };

    if (isEmployee) {
      dispatch(updateEmployeeDetails(dataToSend));
    } else if (isServiceProvider) {
      dispatch(updateServiceProviderDetails(dataToSend));
    } else {
      dispatch(updateCustomerDetails(dataToSend));
    }

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Personal Details"
      loading={employeeEditLoader || customerEditLoader}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <DatePicker
          className={styles.picker}
          margin="dense"
          value={formik.values.birthday}
          format="MMM dd, yyyy"
          inputVariant="outlined"
          placeholder="Enter birthday"
          onChange={(date) => formik.setFieldValue("birthday", date)}
          error={formik.touched.birthday && Boolean(formik.errors.birthday)}
          helperText={formik.errors.birthday}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <Icon
                  color={theme.colors.INPUT_ICON_COLOR}
                  path={mdiCalendar}
                  size="1.5rem"
                />
              </InputAdornment>
            ),
          }}
          fullWidth
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  );
};

function UserPersonalDetailsCard({
  profile,
  isActiveMember,
  isEmployee,
  isServiceProvider,
  localProfilePicUrl,
}) {
  const styles = useStyles();

  const isUserAdmin = RightsManager.isAdmin();
  const isUserMember = RightsManager.isMember();

  const [isPersonalDetailsEditable, setIsPersonalDetailsEditable] =
    useState(false);

  return (
    <div className={styles.container_UserPersonalDetailsCard}>
      <Typography className={styles.title}>Personal Details</Typography>
      <div
        className={styles.row}
        onClick={() => setIsPersonalDetailsEditable(true)}
      >
        <Icon path={mdiCalendar} size="1.5rem" className={styles.icon} />
        <Typography>
          <Moment format="MMM DD, yyyy">
            {formatTimeWithTZ(profile.birthday)}
          </Moment>
        </Typography>
      </div>

      {isPersonalDetailsEditable &&
        (isUserAdmin || isUserMember) &&
        isActiveMember && (
          <EditPersonalDetails
            open={isPersonalDetailsEditable}
            onClose={() => setIsPersonalDetailsEditable(false)}
            profile={profile}
            isEmployee={isEmployee}
            isServiceProvider={isServiceProvider}
            localProfilePicUrl={localProfilePicUrl}
          />
        )}
    </div>
  );
}

export default UserPersonalDetailsCard;
