import React, { useEffect, useRef, useState } from 'react';
import { SnapItem, SnapList } from 'react-snaplist-carousel';
import { v4 } from 'uuid';
import ChipCNO from '../../library/ChipCNO';
import { getMaterialIconString } from '../../utils/icons';
import { JobTimesFilter, JobTimesFilterIcon, JobTimesFilterReverse } from '../../utils/mappings';
import * as icons from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { resetJobSearch, setIsJobUnFinishedFilter, setJobSearchManager, setJobTimesFilter } from '../../actions/jobActions';
import moment from 'moment';
import Icon from '@mdi/react';
import { Switch, useMediaQuery, useTheme, withStyles, Typography } from '@material-ui/core';
import { TOGGLE_SEARCH } from '../../utils/actions';
import { useGlobalStore } from '../../providers/GlobalProvider';
import { isFormikEmpty } from '../../utils/Helpers';
import ResetListing from '../ResetListing';
import Switcher from '../../common/components/GridListToggle/Switcher';
function JobsTimeFilterBar({ setPage,isGridView,setIsGridView }) {

  const theme = useTheme();

  const snapList = useRef(null);

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const dispatch = useDispatch();
  const { jobSearchParams, jobTimesFilter, isJobUnFinishedFilter } = useSelector(state => state.jobs);

  const [activeFilter, setActiveFilter] = useState(jobTimesFilter);

  const { globalState, dispatchGlobal } = useGlobalStore();
  const { isSearch } = globalState;

  const initialValues = {
    customer: null,
    startDate: null,
    endDate: null,
    assignedUsers: null,
    categories: [],
    job: null
  };

  useEffect(() => {
    setActiveFilter(jobTimesFilter);
  }, [jobTimesFilter]);

  const toggleJobFinishedFilter = () => {

    if (isJobUnFinishedFilter) {
      setPage(0);
    }

    dispatch(setIsJobUnFinishedFilter(!isJobUnFinishedFilter));
  };

  const handleJobSearchSidebar = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: !isSearch
    });
  };

  const handleJobTimeFilter = (timeFilter) => {

    console.log("timefilter: ", timeFilter);

    if (timeFilter === activeFilter) {
      timeFilter = JobTimesFilter.None;
    }

    setActiveFilter(timeFilter);
    dispatch(setJobTimesFilter(timeFilter));

    let startDate = null;
    let endDate = null;
    let sortAscending = true;

    switch (timeFilter) {
      case JobTimesFilter.None:
        startDate = null;
        endDate = null;
        sortAscending = false;
        break;

      case JobTimesFilter.Today:
        startDate = moment(new Date()).startOf('day').toDate();
        endDate = moment(new Date()).endOf('day').toDate();
        break;

      case JobTimesFilter.Tomorrow:
        startDate = moment(new Date()).add(1, 'days').startOf('day').toDate();
        endDate = moment(new Date()).add(1, 'days').endOf('day').toDate();
        break;

      case JobTimesFilter.ThisWeek:
        startDate = moment(new Date()).startOf('week').startOf('day').toDate();
        endDate = moment(new Date()).endOf('week').endOf('day').toDate();
        break;

      case JobTimesFilter.NextWeek:
        startDate = moment(new Date()).endOf('week').add(1, 'day').startOf('day').toDate();
        endDate = moment(new Date()).endOf('week').add(1, 'week').endOf('day').toDate();
        break;

      case JobTimesFilter.ThisMonth:
        startDate = moment(new Date()).startOf('month').startOf('day').toDate();
        endDate = moment(new Date()).endOf('month').endOf('day').toDate();
        break;

      case JobTimesFilter.Later:
        startDate = moment(new Date()).add(1, 'month').startOf('day').toDate();
        endDate = null;
        break;

      default:
        startDate = null;
        endDate = null;
        sortAscending = false;
        break;
    }

    console.log("==== job time filter date values ==== ", { startDate, endDate });

    const params = {
      ...jobSearchParams,
      startDate: startDate,
      endDate: endDate,
      sortAscending
    };

    console.log("==== job time filter jobSearchParams ==== ", params);

    dispatch(setJobSearchManager(params));
  };

  const hasSearched = !isFormikEmpty(initialValues, jobSearchParams);

  return (
    <div>
      <div style={{ display: 'flex' }}>
        {
          tillTablet &&
          <Icon
            path={icons.mdiMagnify}
            size='2.4rem'
            onClick={handleJobSearchSidebar}
            style={{
              paddingRight: 10,
              borderRight: `1px solid ${theme.colors.HOME_FILTER_SEARCH_DIVIDER_COLOR}`
            }}
          />
        }

        <div
          style={{
            paddingRight: 10,
            marginRight: 10,
            paddingLeft: 10,
            borderRight: `1px solid ${theme.colors.HOME_FILTER_SEARCH_DIVIDER_COLOR}`,
            position: 'relative'
          }}
        >
          <FinishedSwitch
            checked={isJobUnFinishedFilter}
            onChange={toggleJobFinishedFilter}
            name="unfinished"
          />
          <Typography style={{ fontSize: '0.7rem', position: 'absolute', bottom: -4 }}>Un-finished</Typography>
        </div>


        <SnapList ref={snapList}>
          {
            Object.values(JobTimesFilter)
              .filter(timeFilter => timeFilter !== JobTimesFilter.None)
              .map((timeFilter) => (
                <SnapItem key={v4()} margin={{ left: '5px', right: '5px' }}>
                  <ChipCNO
                    icon={icons[getMaterialIconString(JobTimesFilterIcon[timeFilter])]}
                    name={JobTimesFilterReverse[timeFilter]}
                    active={activeFilter === timeFilter}
                    onClick={() => handleJobTimeFilter(timeFilter)}
                  />
                </SnapItem>
              ))
          }
        </SnapList>
        <Switcher isGridView={isGridView} setIsGridView={setIsGridView}/>
        

      </div>

      <ResetListing
        isReset={hasSearched}
        onReset={() => {
          dispatch(resetJobSearch());
          dispatch(setJobTimesFilter(JobTimesFilter.None));
        }}
      />

    </div>
  )
}

export default JobsTimeFilterBar;

export const FinishedSwitch = withStyles((theme) => ({
  root: {
    top: -6
  },
  switchBase: {
    color: theme.colors.FINISHED_SWITCH_COLOR,
    '&$checked': {
      color: theme.colors.FINISHED_SWITCH_CHECKED_COLOR,
    },
    '&$checked + $track': {
      backgroundColor: theme.colors.FINISHED_SWITCH_CHECKED_TRACK_COLOR,
    },
  },
  checked: {},
  track: {},
}))(Switch);

