import { Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateWidget } from '../../actions/dashboardActions';
import DialogCNO from '../../library/DialogCNO';
import { SwitchCNO } from '../../library/SwitchCNO';
import { setHiddenWidgetsToLocal } from '../../utils/Helpers';

function ShowHideWidgetsDialog({
  isShowHideWidgetsDialog,
  setIsShowHideWidgetsDialog
}) {

  const dispatch = useDispatch();
  const { widgets } = useSelector(state => state.dashboard);

  console.log(" ====== showHideWidgets: widgets ==== ", widgets);

  const showHideWidgets = (widget) => {

    console.log(" ====== showHideWidgets: widget", widget);

    setHiddenWidgetsToLocal(widget.id);

    dispatch(updateWidget({ ...widget, hidden: !widget.hidden }));

  };

  return (
    <DialogCNO
      open={isShowHideWidgetsDialog}
      onClose={() => setIsShowHideWidgetsDialog(false)}
      dialogTitle="Show/Hide Widgets"
    >

      <div style={{ padding: "2rem" }}>
        {
          widgets.map(widget => (
            <div key={widget.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography style={{ fontSize: '1rem' }}>{widget.title}</Typography>
              <SwitchCNO checked={!widget.hidden} onChange={() => showHideWidgets(widget)} />
            </div>
          ))
        }
      </div>

    </DialogCNO>
  )
}

export default ShowHideWidgetsDialog;