import { Box,makeStyles} from '@material-ui/core'
import React, { useState } from 'react'
import { Privacy } from '../../../utils/mappings';
import { IconMain, MetaText } from '../../../library';
import PrivacyAudienceDialog from './PrivacyAudienceDialog';

const PrivacyDropdown = ({ value, onChange }) => {

  const styles = useStyles();

  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false); 

  const privacy = Privacy.getItem(value);

 
  return (
    <>
      <Box className={styles.privacyDropdownContainer}>

        <Box onClick={()=>setOpenPrivacyDialog(true)} className={styles.container} >
          <IconMain name={privacy.icon} size='0.8rem' />
          <MetaText fontSize='0.8rem'>{privacy.title}</MetaText>
          <IconMain name={'menu-down'} size='1rem' />
        </Box>
      </Box>

      <PrivacyAudienceDialog 
        open={openPrivacyDialog} 
        onClose={() => setOpenPrivacyDialog(false)} 
        onChange={onChange} 
        value={value}
        /> 
    </>
        
  )
}

export default PrivacyDropdown;

const useStyles = makeStyles((theme) => ({

  privacyDropdownContainer: {
    background: theme.colors.BACKGROUND_COLOR_LIGHTER,
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    width: 'fit-content',
    padding: '2px 4px',
    borderRadius: 4
  }

}))