import React, { useState } from "react";
import MoreActionCNO from "../../../library/MoreActionsCNO";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { IconMain } from "../../../library";
import { useGlobalStyles } from "../../../utils/Styles/GlobalStyles";
import { FormStatusToDraft, FormStatusToPublish } from "../dialogs";

function FormActionsPopover({ form }) {
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();
  console.log(form, "form in form actipns pop over");
  const [publishForm, setPublishForm] = useState(false);
  const [draftFrom, setDraftForm] = useState(false);

  return (
    <MoreActionCNO>
      <div className={styles.container_MorePopover}>
        <List className={GlobalStyles.list}>
          {/* <ListItem className={GlobalStyles.listItem}>
            <ListItemText primary='Edit' />
            <ListItemIcon>
              <IconButton edge='end'>
                <IconMain name='pencil' />
              </IconButton>
            </ListItemIcon>
          </ListItem> */}
          {form.status == 1 && (
            <ListItem
              className={GlobalStyles.listItem}
              onClick={() => setPublishForm(true)}
            >
              <ListItemText primary="Publish" />
              <ListItemIcon>
                <IconButton edge="end">
                  <IconMain name="file-lock-outline" />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          )}

          {form.status == 2 && (
            <ListItem
              className={GlobalStyles.listItem}
              onClick={() => setDraftForm(true)}
            >
              <ListItemText primary="Revert to Draft" />
              <ListItemIcon>
                <IconButton edge="end">
                  <IconMain name="file-lock-open-outline" />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          )}

          <ListItem className={GlobalStyles.listItem}>
            <ListItemText primary="Delete" />
            <ListItemIcon>
              <IconButton edge="end">
                <IconMain name="trash-can" />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        </List>
        <FormStatusToPublish
          open={publishForm}
          onClose={() => setPublishForm(false)}
          formData={form}
        />
        <FormStatusToDraft
          open={draftFrom}
          onClose={() => setDraftForm(false)}
          formData={form}
        />
      </div>
    </MoreActionCNO>
  );
}

export default FormActionsPopover;

const useStyles = makeStyles((theme) => ({
  container_MorePopover: {
    padding: 5,
    overflow: "auto",
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR,
  },
}));
