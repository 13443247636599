import { Typography } from '@material-ui/core';
import React from 'react';
import { usePublicPagesStyles } from '../../../utils/Styles/PublicPages.style';
import LandingPageWrapper from '../LandingPageWrapper';

function AboutUs() {

  const styles = usePublicPagesStyles();

  return (
    <LandingPageWrapper pageTitle={"Disclaimer"}>

      <Typography variant='h4' align='center' className={styles.pageTitle}>
        About Us
      </Typography>

      <Typography className={styles.para}>
        meFolia started out as an in-house application to solve our on-field communication and productivity challenges. We are a distributed team, having our presence in the UK.
      </Typography>

      <Typography className={styles.para}>
        We needed to know on-field activities like real time workforce location, customer meeting notes and task completion without having to make back and forth calls. The managers in our teams also needed data to make real time decisions.
      </Typography>

      <Typography className={styles.para}>
        We built a fully functional mobile app in a short period of time with a fully responsive web application supporting multiple devices. It worked wonders for us in solving the challenges we faced! It was then a natural progression to build the solution as an enterprise grade solution for organisations like us. Over the years we have made our app increasingly user friendly and added many more features listening to our customers.
      </Typography>

      <Typography className={styles.para}>
        As a technology company we had to build this solution with cutting edge technologies for speed, scalability, security and affordability. Now, meFolia enables organisations to increase their productivity and sales, avoid assumptions and make educated decisions. meFolia application helps organisations to save both time and resources by covering full lifecycle of a job from Appointment &rarr; Quotation &rarr; Job &rarr; Job Tracking &rarr; Invoice &rarr; Payments. We are working on enhancing  application further based on the valuable feedback of our customers.
      </Typography>

    </LandingPageWrapper>
  )
}

export default AboutUs