import { makeStyles, useTheme } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import Icon from '@mdi/react';
import React, { Fragment, useState } from 'react';
import ImgsViewer from "react-images-viewer";

function AvatarCNO({
  src,
  avatarStyle,
  fallbackIcon,
  fallbackText,
  iconSize = "2.5rem",
  iconColor,
  iconStyle,
  alt,
  openAvatarInImageViewer = false,
  title
}) {

  const theme = useTheme();

  if (src) {
    avatarStyle = {
      ...avatarStyle,
      border: 'none'
    };
  }

  const useStyles = makeStyles((theme) => ({
    avatar: {
      background: theme.colors.PROFILE_USER_PIC_BACKGROUND_COLOR,
      // border: '2px solid #5e5e5e',
      width: iconSize || 'auto',
      height: iconSize || 'auto',
      boxShadow: theme.shadows[3],
      ...avatarStyle,
    },
    icon: {

      ...iconStyle
    }
  }));

  const styles = useStyles();

  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);

  return (
    <Fragment>

      <Avatar
        src={src}
        className={styles.avatar}
        onClick={() => {
          if (openAvatarInImageViewer) {
            setIsImageViewerOpen(true);
          }
        }}
      >
        {
          fallbackIcon &&
          <Icon
            path={fallbackIcon}
            size={iconSize}
            color={iconColor || theme.colors.PROFILE_USER_PIC_ICON_COLOR}
            className={styles.icon}
          />
        }
        {
          fallbackText &&
          fallbackText
        }
        {/* {title} */}
      </Avatar>

      {
        src &&
        <ImgsViewer
          imgs={[{ src }]}
          isOpen={isImageViewerOpen}
          onClose={() => setIsImageViewerOpen(false)}
          showImgCount={false}
        />
      }

    </Fragment>

  )
}

export default AvatarCNO;


