export const SubscriptionType = {
  '0': {
    title: 'Free',
    price: 0,
    noOfUsers: 1,
    features: []
  },
  '1': {
    title: 'Silver',
    price: 99,
    noOfUsers: 4,
    features: [
      'Lorem ipsum dolor sit amet',
      'adipisicing elitId vero cum',
      'Debitis consectetur',
      'animi architecto',
    ]
  },
  '2': {
    title: 'Gold',
    price: 199,
    noOfUsers: 10,
    features: [
      'exercitationem quo',
      'Lorem ipsum dolor sit amet',
      'quo totam',
      'adipisicing elitId vero cum',
      'Debitis consectetur',
      'animi architecto',
    ]
  },
  '3': {
    title: 'Platinum',
    price: 299,
    noOfUsers: 20,
    features: [
      'Debitis consectetur',
      'exercitationem quo',
      'Lorem ipsum dolor sit amet',
      'quo totam',
      'adipisicing elitId vero cum',
      'Debitis consectetur',
      'animi architecto',
      'Lorem ipsum dolor sit amet',
    ]
  },
  '4': {
    title: 'Private',
    price: 399,
    noOfUsers: 'Unlimited',
    features: [
      'Debitis consectetur',
      'exercitationem quo',
      'Lorem ipsum dolor sit amet',
      'quo totam',
      'adipisicing elitId vero cum',
      'Debitis consectetur',
      'exercitationem quo',
      'animi architecto',
      'adipisicing elitId vero cum',
      'Lorem ipsum dolor sit amet',
      'exercitationem quo',
    ]
  },
};
