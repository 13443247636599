
import { Typography } from '@material-ui/core';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import FormActionButtons from '../FormActionButtons/FormActionButtons';

function AddServiceProviderErrorDialog({ open, error, onClose }) {

  console.log("Add ServiceProvider AddServiceProviderErrorDialog: ", { open, error });

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle='Add ServiceProvider Error'
      minHeight={100}
      width={'50vw'}
    >
      <Typography style={{ fontSize: '1rem' }}>
        {error ? error : "There is some issue in adding ServiceProvider. Please check your network connection."}
      </Typography>

      <FormActionButtons
        rightText={'Okay'}
        rightClick={onClose}
        isLeftButton={false}
      />

    </DialogCNO>
  )
}

export default AddServiceProviderErrorDialog