import { Button, makeStyles, useTheme } from "@material-ui/core";
import { mdiCalendarPlus, mdiClockCheckOutline, mdiFileCancel,mdiCalendarWeekend,mdiCalendarBlank } from "@mdi/js";
import Icon from "@mdi/react";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleAddAppointmentDialog,
  toggleAddHolidayDialog,
  toggleAddLeaveDialog,
} from "../../actions/appointmentActions";
import DateTimePickerCNO from "../../library/DateTimePickerCNO";
import DialogCNO from "../../library/DialogCNO";
import FlexibleSpaceCNO from "../../library/FlexibleSpaceCNO/FlexibleSpaceCNO";
import * as yup from "yup";
import { validateStartDateEndDate } from "../../utils/Helpers";
import { setAddModalsCalendarDates } from "../../actions/calendarActions";
import moment from "moment";
import { toggleAddJobDialog } from "../../actions/jobActions";
import RightsManager from "../../utils/RightsManager";

function CalendarAddModal({ open, onClose }) {
  const theme = useTheme();
  const styles = useStyles();

  const dispatch = useDispatch();
  const isAdmin = RightsManager.isAdmin();
  const { addModalsCalendarDates } = useSelector((state) => state.calendar);

  const validationSchema = yup.object({
    startDate: yup.date("Invalid Date"),
    startTime: yup.date("Invalid Date"),
    endDate: yup
      .date("Invalid Date")
      .test(
        "endDateTimeValidation",
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({
            startDate,
            startTime,
            endDate,
            endTime,
          });

          return isValid;
        }
      ),
    endTime: yup
      .date("Invalid Date")
      .test(
        "endDateTimeValidation",
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({
            startDate,
            startTime,
            endDate,
            endTime,
          });

          return isValid;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      ...addModalsCalendarDates,
    },
    validationSchema,
  });

  const openAddAppointmentDialog = () => {
    dispatch(toggleAddAppointmentDialog(true));
  };

  const openAddJobDialog = () => {
    dispatch(toggleAddJobDialog(true));
  };

  const openAddLeaveDialog = () => {
    dispatch(toggleAddLeaveDialog(true));
  };

  const openAddHolidayDialog = () => {
    dispatch(toggleAddHolidayDialog(true));
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    dispatch(
      setAddModalsCalendarDates({
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
      })
    );
  };

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle={"Add to Calendar"}
    >
      <form onSubmit={formik.handleSubmit}>
        <DateTimePickerCNO
          datePickerLabel="Start Date"
          dateName={"startDate"}
          datePlaceholder={"Start Date"}
          disablePast={true}
          onDateChange={(date) => {
            formik.setFieldValue("startDate", date);
            dispatch(
              setAddModalsCalendarDates({ ...formik.values, startDate: date })
            );
          }}
          timePlaceholder={"Start Time"}
          timeName={"startTime"}
          onTimeChange={(date) => {
            const startTime = date;
            const endTime = moment(date).add(30, "minute").toDate();

            formik.setValues({
              ...formik.values,
              startTime,
              endTime,
            });

            dispatch(
              setAddModalsCalendarDates({
                ...formik.values,
                startTime,
                endTime,
              })
            );
          }}
          formik={formik}
          iconColor={"red"}
        />

        <DateTimePickerCNO
          datePickerLabel="End Date"
          dateName={"endDate"}
          datePlaceholder={"End Date"}
          minDate={formik.values.startDate}
          onDateChange={(date) => {
            formik.setFieldValue("endDate", date);
            dispatch(
              setAddModalsCalendarDates({ ...formik.values, endDate: date })
            );
          }}
          timePlaceholder={"End Time"}
          timeName={"endTime"}
          onTimeChange={(date) => {
            formik.setFieldValue("endTime", date);
            dispatch(
              setAddModalsCalendarDates({ ...formik.values, endTime: date })
            );
          }}
          formik={formik}
          iconColor={"green"}
        />

        <div className={styles.ctaWrapper}>
          <Button
            variant="contained"
            className={styles.left}
            startIcon={
              <Icon
                path={mdiFileCancel}
                size={"1rem"}
                color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR}
              />
            }
            onClick={handleClose}
          >
            Cancel
          </Button>

          <FlexibleSpaceCNO />

          <Button
            type="submit"
            variant="contained"
            className={styles.draftBtn}
            startIcon={
              <Icon
                path={mdiClockCheckOutline}
                size={"1rem"}
                color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR}
              />
            }
            onClick={() => {
              openAddAppointmentDialog(), onClose();
            }}
            disabled={!formik.isValid}
          >
            <pre>Add Appointment</pre>
          </Button>

          <Button
            type="submit"
            variant="contained"
            className={styles.right}
            startIcon={
              <Icon
                path={mdiCalendarPlus}
                size={"1rem"}
                color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR}
              />
            }
            onClick={() => {
              openAddJobDialog(), onClose();
            }}
            disabled={!formik.isValid}
          >
            <pre>Add Job</pre>
          </Button>

          <Button
            type="submit"
            variant="contained"
            className={styles.leaveBtn}
            startIcon={
              <Icon
                path={mdiCalendarBlank}
                size={"1rem"}
                color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR}
              />
            }
            onClick={() => {
              openAddLeaveDialog(), onClose();
            }}
            disabled={!formik.isValid}
          >
            <pre>Add Leave</pre>
          </Button>
          {isAdmin && (
            <Button
              type="submit"
              variant="contained"
              className={styles.holidayBtn}
              startIcon={
                <Icon
                  path={mdiCalendarWeekend}
                  size={"1rem"}
                  color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR}
                />
              }
              onClick={() => {
                openAddHolidayDialog(), onClose();
              }}
              disabled={!formik.isValid}
            >
              <pre>Add Holiday</pre>
            </Button>
          )}
        </div>
      </form>
    </DialogCNO>
  );
}

export default CalendarAddModal;

const useStyles = makeStyles((theme) => ({
  ctaWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(6),
    "& > *": {
      marginLeft: 4,
    },
  },
  left: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    // marginRight: theme.spacing(4),
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
  },
  right: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
  },
  draftBtn: {
    background: theme.colors.FORM_SECONDARY_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CANCEL_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_SECONDARY_BUTTON_TEXT_COLOR,
    // marginRight: theme.spacing(2),
  },
  leaveBtn: {
    background: theme.colors.FORM_CONFIRM_BUTTON_PRIMARY_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
  },
  holidayBtn: {
    background: theme.colors.FORM_CONFIRM_BUTTON_SECONDARY_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
  },
}));
