import axios from "axios";
import { batch } from "react-redux";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { EventResponseActionStatus } from "../utils/mappings";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export const SET_EVENTS = "SET_EVENTS";
export const SET_EVENT_BY_ID = "SET_EVENT_BY_ID";
export const SET_EVENT_LOADERS = "SET_EVENT_LOADERS";
export const SET_HAS_MORE_EVENTS = "SET_HAS_MORE_EVENTS";
export const SET_EVENT_SEARCH_PARAMS = "SET_EVENT_SEARCH_PARAMS";
export const TOGGLE_ADD_EVENT_DIALOG = "TOGGLE_ADD_EVENT_DIALOG";
export const SET_EVENT_TIMES_FILTER = "SET_EVENT_TIMES_FILTER";


export const setEvents = (payload) => {
  return (dispatch) => {

    console.log("Events: actions: setEvents: payload: before ", payload);

    payload = payload.map(event => {

      const interested = event.attendees.filter(person => person.responseStatus === EventResponseActionStatus.ids.Interested);

      const going = event.attendees.filter(person => person.responseStatus === EventResponseActionStatus.ids.Going);

      return {
        ...event,
        interested,
        going,
      }

    });

    console.log("Events: actions: setEvents: payload: ", payload);


    dispatch({
      type: SET_EVENTS,
      payload: payload,
    })
  }
}

export const setEventById = (payload) => {
  return (dispatch, getState) => {

    console.log("Events: actions: setEventById: payload: outer ", payload);

    if (payload) {

      batch(() => {

        dispatch({
          type: SET_EVENT_BY_ID,
          payload: payload
        });

        console.log("Events: actions: setEventById: payload: ", payload);

        const { events } = getState().events;

        console.log("Events: actions: setEventById: events: ", events);

        const updatedEvents = events.map((event) => event.id === payload.id ? payload : event);

        console.log("Events: actions: setEventById: updatedEvents: ", updatedEvents);

        dispatch(setEvents(updatedEvents));

      });

    } else {
      dispatch({
        type: SET_EVENT_BY_ID,
        payload,
      });
    }

  };
};

export const setEventLoaders = (loaderName, loaderState) => {
  return (dispatch) => {
    dispatch({
      type: SET_EVENT_LOADERS,
      payload: { loaderName, loaderState }
    });
  };
};

export const toggleAddEventDialog = (payload) => {
  console.log("dspatched3")
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_EVENT_DIALOG,
      payload: payload
    });
  };
};


export const fetchEvents = (payload = {}) => {
  return async (dispatch, getState) => {

    const dataToSend = {
      ...payload
    };

    dispatch(setEventLoaders('eventsLoader', true));

    await axios.get('/data/events.json')
      .then((resp) => {
        console.log("Events: actions: fetchEvents: resp: ", resp);

        const events = resp.data;

        console.log("Events: actions: fetchEvents: events: ", events);

        dispatch(setEvents(events.slice(0, 23)));

      })
      .catch((error) => {

      })
      .finally(() => {
        dispatch(setEventLoaders('eventsLoader', false));

      })

  }
}

export const fetchEventById = (eventId, offlineData) => {
  return async (dispatch, getState) => {

    dispatch(setEventLoaders('eventDetailLoader', true));

    const dataToSend = {
      ids: [eventId]
    }

    await axios.get('/data/events.json')
      .then((resp) => {
        console.log("Events: actions: fetchEvents: resp: ", resp);

        const { events } = getState().events;

        // const events = resp.data;

        const eventById = events.find(event => event.id === eventId);

        console.log("Events: actions: fetchEvents: events: ", events);
        console.log("Events: actions: fetchEvents: eventById: ", eventById);

        dispatch(setEventById(eventById));

      })
      .catch((error) => {

      })
      .finally(() => {
        dispatch(setEventLoaders('eventDetailLoader', true));
      })

  }
}

export const addEvent = (payload, { onSuccess }) => {

  return async (dispatch, getState) => {
    dispatch(setEventLoaders('addEventLoader', true));

    const dataToSend = {
      ...payload
    }

    await axios.get('/data/events.json')
      .then((resp) => {

        const { events: prevEvents } = getState().events;

        const newEvent = {
          ...dataToSend,
          id: v4(),
          // id: resp.data
        }

        dispatch(setEvents([newEvent, ...prevEvents]));

        if (typeof onSuccess === 'function') onSuccess(newEvent);

        toast.success("Event added successfully.")

      })
      .catch(error => {

        console.log("Error: Add Event: ", error);
        toast.error("There is issue in adding event.")

      })
      .finally(() => {
        dispatch(setEventLoaders('addEventLoader', false));
      })

  }
}

export const editEvent = (payload, { onSuccess } = {}) => {
  return async (dispatch) => {
    dispatch(setEventLoaders('editEventLoader', true));

    const dataToSend = {
      ...payload
    }

    console.log("Events: handleEditEventTitle:  dataToSend: ", dataToSend);

    await axios.get('/data/events.json')
      .then((resp) => {

        const updatedEvent = {
          ...dataToSend,
        }

        dispatch(setEventById(updatedEvent));

        if (typeof onSuccess === 'function') onSuccess(updatedEvent);

        toast.success("Event updated successfully.")

      })
      .catch(error => {

        console.log("Error: Edit Event: ", error);
        toast.error("There is issue in updating event.")

      })
      .finally(() => {
        dispatch(setEventLoaders('editEventLoader', false));
      })


  }
}

export const deleteEvent = (payload, { onSuccess }) => {
  return async (dispatch, getState) => {

    dispatch(setEventLoaders('deleteEventLoader', true));

    const dataToSend = {
      id: payload.id,
      undo: false
    }

    await axios.get('/data/events.json')
      .then((resp) => {

        const { events } = getState().events;

        const updatedEvents = events.filter(event => event.id !== payload.id);

        dispatch(setEvents(updatedEvents));

        if (typeof onSuccess === 'function') onSuccess();

        toast.success("Event deleted successfully.")

      })
      .catch(error => {

        console.log("Error: Delete Event: ", error);
        toast.error("There is issue in deleting event.")

      })
      .finally(() => {
        dispatch(setEventLoaders('deleteEventLoader', false));
      })

  }
}