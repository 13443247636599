import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { scroller } from 'react-scroll';

export const useScrollTo = () => {

  const history = useHistory();


  useEffect(() => {

    const hash = history.location.hash.slice(1);

    if (hash) {

      scroller.scrollTo(hash);

    }

  }, [history]);

};