import {
  makeStyles
} from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { setAppointments, setAppointmentSearchParams } from '../../actions/appointmentActions';
import { resetInvoiceSearch, setInvoices, setInvoiceSearchParams } from '../../actions/invoiceActions';
import { resetJobSearch, setJobSearchManager, setJobSearchParams, setTodos } from '../../actions/jobActions';
import { setQuotations, setQuotationSearchParams } from '../../actions/quotationActions';
import CustomerAppointmentsSection from './CustomerAppointmentsSection';
import CustomerInvoicesSection from './CustomerInvoicesSection';
import CustomerJobsSection from './CustomerJobsSection';
import CustomerQuotationsSection from './CustomerQuotationsSection';

function CustomerEngagementDetailsCard({ profile }) {

  const dispatch = useDispatch();

  const resetCustomerEngagementData = () => {

    const jobSearchParams = {
      customer: null,
      startDate: null,
      endDate: null,
      assignedUsers: null,
      categories: [],
      job: null
    };

    const quotationSearchParams = {
      customer: null,
      categories: [],
      quotation: null,
      offerNumber: "",
      sentOn: null,
      validForDays: null
    };

    const appointmentSearchParams = {
      customer: null,
      categories: [],
      appointment: null,
      startDate: null,
      endDate: null,
      assignments: null
    };

    const invoiceSearchParams = {
      customer: null,
      categories: [],
      invoice: null,
      invoiceNumber: "",
      billDate: null,
      dueInDays: null
    };

    batch(() => {

      console.log('=====START ====resetCustomerEngagementData======');

      dispatch(resetJobSearch(true));
      dispatch(setTodos([]));

      dispatch(setAppointmentSearchParams(appointmentSearchParams));
      dispatch(setAppointments([]));

      dispatch(resetInvoiceSearch(true));
      dispatch(setQuotations([]));

      dispatch(resetInvoiceSearch(true));
      dispatch(setInvoices([]));

      console.log('=====END ====resetCustomerEngagementData======');

    });

  };

  useEffect(() => {

    resetCustomerEngagementData();

    return () => {
      resetCustomerEngagementData();
    };
  }, []);

  return (
    <Fragment>

      <CustomerJobsSection profile={profile} />

      <CustomerQuotationsSection profile={profile} />

      <CustomerAppointmentsSection profile={profile} />

      <CustomerInvoicesSection profile={profile} />

    </Fragment>
  );
}

export default CustomerEngagementDetailsCard;

export const useCustomerEngagemenStyles = makeStyles(theme => ({

  accordion: {
    background: theme.colors.PROFILE_CARD_BACKGROUND_COLOR,
  },

  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.colors.ACCORDION_BACKGROUND_COLOR,
    padding: 0
  },

  accordionTitleIcon: {
    color: theme.colors.ACCORDION_TITLE_COLOR,
    marginRight: 10
  },

  listingWrapper: {
    maxHeight: 400,
    overflowY: 'auto'
  },

  loader: {
    fontSize: '1rem',
    textAlign: 'center',
    color: theme.colors.FORM_LABEL_COLOR, padding: 10
  },

}));