import React from 'react';
import { useSelector } from 'react-redux';
import ChatMessages from '../../components/ChatMessages/ChatMessages';
import ChatMessagesHeader from '../../components/ChatMessagesHeader';
import ScreenView from '../../components/ScreenView';
import { useStyles } from '../ChatMessagesPage/ChatMessagesPageStyles';

function ChatMessagesPage() {

  // let activeChatUser = localStorage.getItem('lastActiveChatUser');
  // activeChatUser = activeChatUser ? JSON.parse(activeChatUser) : null;

  const chatsStore = useSelector(state => state.chats);
  const { activeChatUser } = chatsStore;

  const styles = useStyles();
  return (
    <div className={styles.container_ChatMessagesPage}>

      <ScreenView
        content={<ChatMessages />}
        isCustomMobileHeader={true}
        customMobileHeader={<ChatMessagesHeader activeChatUser={activeChatUser} />}
      />

    </div>
  )
}

export default ChatMessagesPage
