import { mdiAccount, mdiAccountMultiple, mdiEarth, mdiMapMarker, mdiPhone, mdiTimer } from '@mdi/js';
import React, { useRef, useState } from 'react';
import DetailsInfoSection from '../../../../components/DetailsInfoSection/DetailsInfoSection';
import { ChipCNO, MetaText, Stack } from '../../../../library';
import LinkCNO from '../../../../library/LinkCNO';
import { EventAttendeeRole, EventType } from '../../../../utils/mappings';
import RightsManager from '../../../../utils/RightsManager';
import { getAttendeesGroupedBasedOnRole, getAttendeesGroupedBasedOnStatus, getEventCategory, getEventDuration } from '../../helpers';
import { EditEventDescription } from '../../modals';
import CardDetailsText from './CardDetailsText';
import CardHeader from './CardHeader';
import { useEventDetailsFBStyles } from './EventDetails.styles';
import EventCategories from '../../EventCategories.json';
import { useGlobalStyles } from '../../../../utils/Styles/GlobalStyles';
import { getMaterialIconString } from '../../../../utils/icons';
import * as icons from '@mdi/js';
import { SnapItem, SnapList, useDragToScroll } from 'react-snaplist-carousel';

const EventAboutDetailsCard = ({ event }) => {

  const isUserAdmin = RightsManager.isAdmin();

  const GlobalStyles = useGlobalStyles();
  const EventDetailsFBStyles = useEventDetailsFBStyles();

  const groupedAttendees = getAttendeesGroupedBasedOnRole(event.attendees);

  const hosts = groupedAttendees[EventAttendeeRole.ids.Host];
  const organizer = hosts[0];

  const openGoogleMap = () => {
    window.open(
      `https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${event.mapAddress}`,
      "_blank"
    );
  }

  const [isEditDescription, setIsEditDescription] = useState(false);

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  console.log("EventCategories: ", event.categories);

  const eventCategories = getEventCategory(event.categories);

  console.log({ eventCategories })

  return (
    <div className={EventDetailsFBStyles.card}>
      <CardHeader>
        Details
      </CardHeader>

      <Stack spacing={2}>

        {
          event.eventType === EventType.ids.InPerson &&
          <CardDetailsText icon={{ path: mdiMapMarker }} onClick={openGoogleMap}>
            {event.mapAddress}
          </CardDetailsText>
        }

        <CardDetailsText icon={{ path: mdiAccountMultiple }}>
          {event.attendees.length} peoples responded
        </CardDetailsText>

        <CardDetailsText icon={{ path: mdiAccount }}>
          Event by {organizer.title || `${organizer.firstName} ${organizer.lastName}`}
        </CardDetailsText>

        <CardDetailsText icon={{ path: mdiTimer }}>
          {getEventDuration(event.startTime, event.endTime)} hours
        </CardDetailsText>

        <MetaText
          maxLine={0}
          onClick={() => {
            if (!isUserAdmin) return;
            setIsEditDescription(true)
          }}
        >
          {event.description}
        </MetaText>

        <div>
          <SnapList ref={snapList} className={GlobalStyles.verticalScrollChipsWrapper}>
            {eventCategories.map((category) => (
              <SnapItem>
                <ChipCNO
                  icon={category.iconLink}
                  name={category.title}
                  isCategoryChip={true}
                />
              </SnapItem>
            ))}
          </SnapList>
        </div>

      </Stack>

      {
        isEditDescription &&
        isUserAdmin &&
        <EditEventDescription
          open={isEditDescription}
          onClose={() => setIsEditDescription(false)}
          event={event}
        />
      }

    </div>
  )
}

export default EventAboutDetailsCard;


