import {
  SET_ALREADY_EXIST_EMPLOYEES,
  SET_EMPLOYEE,
  SET_EMPLOYEES,
  SET_EMPLOYEES_LOADER,
  SET_EMPLOYEE_BY_ID,
  SET_EMPLOYEE_SEARCH_PARAMS,
  SET_HAS_MORE_EMPLOYEES,
  TOGGLE_ADD_EMPLOYEE_DIALOG
} from "../actions/employeeActions";

const initialState = {
  employees: [],
  employeeById: null,
  alreadyExistEmployees: [],
  isAddEmployeeDialog: false,
  allEmployeesLoader: {
    employeesLoader: false,
    moreEmployeesLoader: false,
    editLoader: false,
    addLoader: false,
    deleteLoader: false,
    employeeDetailLoader: false
  },
  hasMoreEmployees: true,
  employeeSearchParams: {
    employee: null,
    email: "",
    phone: "",
    address: ""
  },
};

export const employeesReducer = (state = initialState, { type, payload }) => {

  switch (type) {
    case SET_EMPLOYEES:
      state = {
        ...state,
        employees: payload
      };
      return state;

    // case SET_EMPLOYEE:
    //   state = {
    //     ...state,
    //     employees: payload
    //   };
    //   return state;

    case SET_EMPLOYEES_LOADER:
      state = {
        ...state,
        allEmployeesLoader: {
          ...state.allEmployeesLoader,
          [payload.loaderType]: payload.loaderState
        }
      };
      return state;

    case SET_HAS_MORE_EMPLOYEES:
      state = {
        ...state,
        hasMoreEmployees: payload
      };
      return state;

    case SET_EMPLOYEE_BY_ID:
      state = {
        ...state,
        employeeById: payload
      };
      return state;

    case TOGGLE_ADD_EMPLOYEE_DIALOG:
      state = {
        ...state,
        isAddEmployeeDialog: payload
      };
      return state;

    case SET_EMPLOYEE_SEARCH_PARAMS:
      state = {
        ...state,
        employeeSearchParams: payload
      };
      return state;

    case SET_ALREADY_EXIST_EMPLOYEES:
      state = {
        ...state,
        alreadyExistEmployees: payload
      };
      return state;


    default:
      return state;
  }

};