import moment from "moment";

export const generateId = (typeOfId, nameForInitials,) => {

  const prefixTypes = {
    quotation: 'O',
    invoice: 'I',
    subscription: 'S'
  };
  const prefixLetter = prefixTypes[typeOfId];

  const dateNumber = moment().format('YYYYMMDD');

  const splittedNames = nameForInitials.split(' ');
  const nameInitials = splittedNames.reduce((initials, word) => {
    initials += word.slice(0, 1);
    return initials;
  }, '');

  const generatedId = `${prefixLetter}-${dateNumber}-${nameInitials}`;

  return generatedId;

};