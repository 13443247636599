import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Calendar as BigCalendar,
    Views,
    momentLocalizer,
} from 'react-big-calendar';
import _ from 'lodash';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Button, makeStyles } from '@material-ui/core';
import { useTheme } from 'styled-components';
const localizer = momentLocalizer(moment);

const CustomToolbar = (props) => {
    const theme = useTheme();
    const styles = useStyles();

    const [viewState, setViewState] = useState('day');

    const [isMonthSelected, setIsMonthSelected] = useState(false);
    const [isWeekSelected, setIsWeekSelected] = useState(false);
    const [isWorkWeekSelected, setIsWorkWeekSelected] = useState(false);
    const [isAgendaSelected, setIsAgendaSelected] = useState(false);
    const [isDaySelected, setIsDaySelected] = useState(false);
    const [isTodaySelected, setIsTodaySelected] = useState(false);

    function addMonths(date, months) {
        const d = date.getDate();
        date.setMonth(date.getMonth() + months);
        if (date.getDate() != d) {
            date.setDate(0);
        }
        console.log(date);
        return date;
    }

    function addWeeks(date, weeks) {
        date.setDate(date.getDate() + 7 * weeks);
        return date;
    }

    function getCalendarHeader() {
        if (viewState === 'month'){
            return moment(props.date).format('MMM - YYYY');
        }else if (viewState === 'week'){
            return moment(props.date).format('MMM - YYYY');
        }else if (viewState === 'work_week'){
            return moment(props.date).format('MMM - YYYY');
        }else if (viewState === 'agenda'){
            return moment(props.date).format('MMM - YYYY');
        }else{
            return moment(props.date).format('ddd, MMM Do YYYY');
        }   
    }

    function addDays(date, days) {
        date.setDate(date.getDate() + days);
        console.log(date);
        return date;
    }

    const goToDayView = () => {
        props.onView('day');
        setViewState('day');
        setIsMonthSelected(false);
        setIsWeekSelected(false);
        setIsWorkWeekSelected(false);
        setIsAgendaSelected(false);
        setIsDaySelected(true);
        if(props.date.getDate() === moment().toDate().getDate()){
            setIsTodaySelected(true);
        }else{
            setIsTodaySelected(false);
        }
    };
    const goToWeekView = () => {
        props.onView('week');
        setViewState('week');
        setIsMonthSelected(false);
        setIsWeekSelected(true);
        setIsWorkWeekSelected(false);
        setIsAgendaSelected(false);
        setIsDaySelected(false);
        setIsTodaySelected(false);
    };
    const goToMonthView = () => {
        props.onView('month');
        setViewState('month');
        setIsMonthSelected(true);
        setIsWeekSelected(false);
        setIsWorkWeekSelected(false);
        setIsAgendaSelected(false);
        setIsDaySelected(false);
        setIsTodaySelected(false);
    };

    const goToWorkWeekView = () => {
        props.onView('work_week');
        setViewState('work_week');
        setIsMonthSelected(false);
        setIsWeekSelected(false);
        setIsWorkWeekSelected(true);
        setIsAgendaSelected(false);
        setIsDaySelected(false);
        setIsTodaySelected(false);
    };

    const goToAgendaView = () => {
        props.onView('agenda');
        setViewState('agenda');
        setIsMonthSelected(false);
        setIsWeekSelected(false);
        setIsWorkWeekSelected(false);
        setIsAgendaSelected(true);
        setIsDaySelected(false);
        setIsTodaySelected(false);
    };

    const goToBack = () => {
        console.log("----------goToBack-----------props.date-----", props.date);
        console.log("----------goToBack------moment----------", moment());
        if(props.date.getDate() - 1 === moment().toDate().getDate()){
            setIsTodaySelected(true);
        }else{
            setIsTodaySelected(false);
        }
        
        if (viewState === 'month') {
            props.onNavigate('prev', addMonths(props.date, -1));
            getCalendarHeader();
        } else if (viewState === 'week') {
            props.onNavigate('prev', addWeeks(props.date, -1));
            getCalendarHeader();
        } else if (viewState === 'work_week') {
            props.onNavigate('next', addWeeks(props.date, -1));
            getCalendarHeader();
        } else if (viewState === 'agenda') {
            props.onNavigate('next', addMonths(props.date, -1));
            getCalendarHeader();
        }else {
            props.onNavigate('prev', addDays(props.date, -1));
            getCalendarHeader();
        }
    };

    const goToNext = () => {
        console.log("----------goToNext-----------props.date-----", props.date);
        console.log("----------goToNext------moment----------", moment());
        if(props.date.getDate() + 1 === moment().toDate().getDate()){
            setIsTodaySelected(true);
        }else{
            setIsTodaySelected(false);
        }

        if (viewState === 'month') {
            props.onNavigate('next', addMonths(props.date, +1));
            getCalendarHeader();
        } else if (viewState === 'week') {
            props.onNavigate('next', addWeeks(props.date, +1));
            getCalendarHeader();
        } else if (viewState === 'work_week') {
            props.onNavigate('next', addWeeks(props.date, +1));
            getCalendarHeader();
        } else if (viewState === 'agenda') {
            props.onNavigate('next', addMonths(props.date, +1));
            getCalendarHeader();
        }else {
            props.onNavigate('next', addDays(props.date, +1));
            getCalendarHeader();
        }
    };

    const goToToday = () => {
        const now = new Date();
        props.date.setMonth(now.getMonth());
        props.date.setYear(now.getFullYear());
        props.date.setDate(now.getDate());
        props.onNavigate('current');
        setIsTodaySelected(true);
    };

    useEffect(() => {
        console.log(props);
        setIsMonthSelected(false);
        setIsWeekSelected(false);
        setIsWorkWeekSelected(false);
        setIsAgendaSelected(false);
        setIsDaySelected(true);
        if(props.date.getDate() === moment().toDate().getDate()){
            setIsTodaySelected(true);
        }else{
            setIsTodaySelected(false);
        }
    }, []);

    return (
        <div className={styles.buttonContainer}>
            <div className={styles.buttonSection}>
                <Button
                    variant='contained'
                    className={styles.toolBarBtn}
                    onClick={goToBack}
                >
                    &#8249;&#8249;
                </Button>
                <Button
                    variant='contained'
                    className={isTodaySelected? styles.toolBarBtnSelected: styles.toolBarBtn}
                    onClick={goToToday}
                >
                    Today
                </Button>
                <Button
                    variant='contained'
                    className={styles.toolBarBtn}
                    onClick={goToNext}
                >
                    &#8250;&#8250;
                </Button>
            </div>
            <label className={styles.dateLabel}>
                {getCalendarHeader()}
            </label>
            <div className={styles.buttonSection}>
                <Button
                    variant='contained'
                    className={isMonthSelected? styles.toolBarBtnSelected: styles.toolBarBtn}
                    onClick={goToMonthView}
                >
                    Month
                </Button>
                <Button
                    variant='contained'
                    className={isWeekSelected? styles.toolBarBtnSelected: styles.toolBarBtn}
                    onClick={goToWeekView}
                >
                    Week
                </Button>
                <Button
                    variant='contained'
                    className={isWorkWeekSelected? styles.toolBarBtnSelected: styles.toolBarBtn}
                    onClick={goToWorkWeekView}
                >
                    Work Week
                </Button>
                <Button
                    variant='contained'
                    className={isDaySelected? styles.toolBarBtnSelected: styles.toolBarBtn}
                    onClick={goToDayView}
                >
                    Day
                </Button>
                <Button
                    variant='contained'
                    className={isAgendaSelected? styles.toolBarBtnSelected: styles.toolBarBtn}
                    onClick={goToAgendaView}
                >
                    Agenda
                </Button>
            </div>

        </div>
    );
};

export default CustomToolbar;

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px',
        fontSize: '16px'
    },
    buttonSection: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
    },
    dateLabel: {
        flexGrow: 1,
        padding: '0 10px',
        textAlign: 'center',
        color:'orange',
        fontWeight:'bold'
    },
    
    toolBarBtn: {
        background: '#fcf9c7',
        border: `2px solid #ffa600`,
        color: '#000',
        marginRight: theme.spacing(0.5)
    },

    toolBarBtnSelected: {
        background: '#383830',
        border: `2px solid #ffa600`,
        color: '#fcf9c7',
        marginRight: theme.spacing(0.5)
    },

}))
