import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setJobSearchManager } from '../../actions/jobActions';
import ChipCNO from '../../library/ChipCNO';
import { v4 } from 'uuid';
import { ServiceCategories } from '../../utils/mappings';
import { setHolidaySearchParams, setLeaveSearchParams } from '../../actions/appointmentActions';

function JobChipsFilter({ formik, mode = "task", onChipToggle }) {
  let localCategories;
  
  if(mode == "service"){
    localCategories=ServiceCategories.entities;
  }else{
    localCategories = localStorage.getItem('categories');
    localCategories = localCategories ? JSON.parse(localCategories) : [];
  }
  

  const dispatch = useDispatch();
  const { jobSearchParams } = useSelector(state => state.jobs);
  const { quotationSearchParams } = useSelector(state => state.quotations);
  const { invoiceSearchParams } = useSelector(state => state.invoices);
  const { appointmentSearchParams,leaveSearchParams,holidaySearchParams } = useSelector(state => state.appointments);
  const { serviceSearchParams } = useSelector(state => state.services);
  

  const [searchValues, setSearchValues] = useState(null);

  useEffect(() => {

    switch (mode) {
      case 'task':
        setSearchValues(jobSearchParams);
        break;

      case 'quotation':
        setSearchValues(quotationSearchParams);
        break;

      case 'invoice':
        setSearchValues(invoiceSearchParams);
        break;

      case 'appointment':
        setSearchValues(appointmentSearchParams);
        break;

      case 'event':
        setSearchValues(appointmentSearchParams);
        break;

      case 'service':
        setSearchValues(serviceSearchParams);

      case 'leave':
        setLeaveSearchParams(leaveSearchParams);

      case 'holiday':
        setHolidaySearchParams(holidaySearchParams);
      default:
        break;
    }

  }, [mode, jobSearchParams, quotationSearchParams, invoiceSearchParams, appointmentSearchParams,serviceSearchParams,leaveSearchParams,holidaySearchParams]);

  const handleCategoryToggle = (params) => {

    setSearchValues(params);

    switch (mode) {
      case 'task':
        dispatch(setJobSearchManager(params));
        break;

      case 'quotation':
        // dispatch(setQuotationSearchParams(params));
        break;

      case 'invoice':
        // dispatch(setInvoiceSearchParams(params));
        break;

      default:
        break;
    }

  };

  if (!searchValues) {
    return null;
  }

  return (
    <React.Fragment>
      {
        localCategories.map((category) => {
          return (
            <ChipCNO
              key={v4()}
              icon={category.iconLink}
              name={category.title}
              active={searchValues.categories.includes(category.id)}
              isCategoryChip={true}
              onClick={() => {
                let isActive = searchValues.categories.includes(category.id);

                let params = searchValues;

                if (isActive) {
                  formik && formik.setFieldValue('categories', []);
                  params = {
                    ...searchValues,
                    categories: searchValues.categories.filter(categoryId => !(categoryId === category.id))
                  };
                }
                else {
                  formik && formik.setFieldValue('categories', [
                    ...formik.values.categories,
                    category.id
                  ]);
                  params = {
                    ...searchValues,
                    categories: [...searchValues.categories, category.id]
                  };
                }

                handleCategoryToggle(params);
                if (onChipToggle) {
                  onChipToggle(params.categories);
                }

              }}
            />
          )
        })
      }
    </React.Fragment>
  )
}

export default JobChipsFilter
