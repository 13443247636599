import React, { useEffect, useState } from 'react';
import { useDashBoardStyles } from './Dashboard.styles';
import StatsCard from './StatsCard';
import { WidthProvider, Responsive, } from 'react-grid-layout';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import Icon from '@mdi/react';
import { mdiResizeBottomRight } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import FullScreenLoader from '../../library/FullScreenLoaderCNO';
import { getDashboardData, setLayouts } from '../../actions/dashboardActions';
import { useMediaQuery } from '@material-ui/core';

const ResponsiveGridLayout = WidthProvider(Responsive);

const ResizeHandle = React.forwardRef((props, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className="react-resizable-handle"
      style={{
        position: "absolute",
        bottom: 0,
        right: 0,
        cursor: "se-resize",
        backgroundImage: 'none',
      }}
    >
      <Icon path={mdiResizeBottomRight} color="white" size="1.5rem" style={{ marginRight: -5 }} />
    </div>
  );
});

function DashboardStats({
  isDraggable,
  isResizeable,
}) {

  const styles = useDashBoardStyles();

  const dispatch = useDispatch();
  let {
    widgets,
    layouts,
    widgetsLoader
  } = useSelector(state => state.dashboard);


  // const [layouts, setLayouts] = useState(layoutsOriginal || {});

  useEffect(() => {
    dispatch(getDashboardData({}));
  }, []);

  useEffect(() => {

    if (!widgets.length) return;

    // const layoutsOriginal = LocalStorageConstants.getItem('layouts');

    // if (layoutsOriginal) {
    //   dispatch(setLayouts(layoutsOriginal));
    // } else {

    //   const defaultLayouts = widgets.map(widget => widget.defaultLayout);

    //   if (!layouts) dispatch(setLayouts(defaultLayouts));

    // }

    // const defaultLayouts = widgets.filter(w => !w.hidden).map(widget => widget.defaultLayout);
    // dispatch(setLayouts(defaultLayouts));

  }, [widgets]);


  const tillTablet = useMediaQuery('(max-width: 768px)');

  return (
    <div className={styles.dashboardStatsContainer}>

      {
        widgetsLoader &&
        <FullScreenLoader />
      }

      {
        layouts &&
        // <ResponsiveGridLayout
        //   className="layout"
        //   cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        //   isDraggable={isDraggable}
        //   isResizable={isResizeable}
        //   layouts={layouts || {}}
        //   onLayoutChange={(currentLayout, allLayouts) => {

        //     console.log("DashboardStats: onLayoutChange===> ", { currentLayout, allLayouts });

        //     LocalStorageConstants.setItem('layouts', allLayouts);
        //     dispatch(setLayouts(allLayouts));

        //   }}
        //   resizeHandle={<ResizeHandle />}
        // >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 20,
            width: tillTablet ? '100%' : '85%', margin: '0 auto'
          }}
        >
          {
            widgets.filter(widget => !widget.hidden)
              .map((widget, index) => (
                <StatsCard
                  key={widget.id}
                  id={widget.id}
                  title={widget.title}
                  data-grid={widget.defaultLayout}
                />
              ))
          }
        </div>

        // </ResponsiveGridLayout>
      }

    </div>
  )
}

export default DashboardStats