import {
  Button,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import AutocompleteCNO from "../../library/AutocompleteCNO";
import { KeyboardDatePicker, TimePicker } from "@material-ui/pickers";
import { mdiFileCancel, mdiFileSearch } from "@mdi/js";
import Icon from "@mdi/react";
import { useGlobalStore } from "../../providers/GlobalProvider";
import { TOGGLE_SEARCH } from "../../utils/actions";
import UserSuggestionOption from "../UserSuggestionOption/UserSuggestionOption";
import HeadingBar from "../HeadingBar/HeadingBar";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMemberSuggest,
  fetchTaskSuggest,
} from "../../actions/suggestionsActions";
import {
  handleMapTrackSearch,
  resetSoftTrackData,
} from "../../actions/trackSearchActions";
import { getDateWithoutTime } from "../../utils/Tracking";
import moment from "moment";

function MapSidebar() {
  const tillTablet = useMediaQuery("(max-width: 767px)");

  const styles = useStyles({ tillTablet });

  const { dispatchGlobal } = useGlobalStore();

  const dispatch = useDispatch();

  const suggestions = useSelector((state) => state.suggestions);
  const { mapSearchParams } = useSelector((state) => state.trackSearch);

  const validationSchema = yup.object({
    teamMember: yup
      .object()
      .typeError("Invalid value.")
      .nullable()
      .required("Team Member is required!"),
    trackStartDate: yup
      .date()
      .typeError("Invalid date.")
      .required("Track Start Date is required!"),
    trackEndDate: yup.date().typeError("Invalid date.").nullable(),
    trackStartTime: yup
      .date()
      .typeError("Invalid date.")
      .required("Track Start Date is required!"),
    trackEndTime: yup.date().typeError("Invalid date.").nullable(),
    job: yup.object().typeError("Invalid value.").nullable(),
    jobStartDate: yup.date().typeError("Invalid date.").nullable(),
    jobEndDate: yup.date().typeError("Invalid date.").nullable(),
  });

  const formik = useFormik({
    initialValues: {
      teamMember: null,
      trackStartDate: new Date(),
      trackEndDate: null,
      trackStartTime: moment().startOf("day").toDate(),
      trackEndTime: moment().endOf("day").toDate(),
      job: null,
      jobStartDate: null,
      jobEndDate: null,
    },
    validationSchema,
    onSubmit: (values) => handleSearch(values),
  });

  const [isTrackEndTimeDisabled, setIsTrackEndTimeDisabled] = useState(
    formik.values.trackEndDate ? false : true
  );

  useEffect(() => {
    setIsTrackEndTimeDisabled(formik.values.trackEndDate ? false : true);

    return () => {};
  }, [formik.values.trackEndDate]);

  console.log(
    "====isTrackEndTimeDisabled=====",
    isTrackEndTimeDisabled,
    formik.values.trackEndDate
  );

  const closeSidebar = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false,
    });
  };

  const handleSearch = async (values) => {
    dispatch(resetSoftTrackData());

    dispatch(handleMapTrackSearch(values));

    closeSidebar();
  };

  console.log("======mapSearchParams=======", mapSearchParams);

  useEffect(() => {
    formik.setValues(mapSearchParams);
  }, [mapSearchParams]);

  return (
    <form onSubmit={formik.handleSubmit} className={styles.contaier_MapSidebar}>
      <HeadingBar title="Track User" onClose={closeSidebar} />

      <div className={styles.card}>
        <AutocompleteCNO
          label="Team Member"
          name="teamMember"
          placeholder="Select team member..."
          // fullWidth
          formik={formik}
          data={suggestions}
          onSuggestionClick={(value) => {
            console.log("memeber Value: ", value);
            formik.setFieldValue("teamMember", value);
          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchMemberSuggest(value));
            }
          }}
          optionLabel={(x) => x.title || ""}
          renderOption={(option) => <UserSuggestionOption user={option} />}
        />
      </div>

      <div className={styles.card}>
        <div className={styles.picker}>
          <Typography component="label">Track Start Date</Typography>
          <div style={{ display: "flex", flex: 1, width: "100%" }}>
            <KeyboardDatePicker
              margin="dense"
              value={formik.values.trackStartDate}
              format="MMM dd, yyyy"
              inputVariant="outlined"
              placeholder="Track Start Date"
              onChange={(date) => {
                formik.setFieldTouched("trackStartDate", true);
                formik.setFieldValue(
                  "trackStartDate",
                  getDateWithoutTime(date, false)
                );
              }}
              error={
                formik.touched.trackStartDate &&
                Boolean(formik.errors.trackStartDate)
              }
              helperText={formik.errors.trackStartDate}
              fullWidth
              className={styles.startDate}
            />
            <TimePicker
              margin="dense"
              value={formik.values.trackStartTime}
              format="hh:mm a"
              inputVariant="outlined"
              placeholder="Start Time"
              onChange={(time) => {
                formik.setFieldValue("trackStartTime", time);
              }}
              style={{ marginLeft: "10px" }}
            />
          </div>
        </div>
        <div className={styles.picker}>
          <Typography component="label">Track End Date</Typography>
          <div style={{ display: "flex", flex: 1, width: "100%" }}>
            <KeyboardDatePicker
              margin="dense"
              value={formik.values.trackEndDate}
              format="MMM dd, yyyy"
              inputVariant="outlined"
              placeholder="Track End Date"
              minDate={formik.values.trackStartDate}
              maxDate={new Date()}
              onChange={(date) => {
                formik.setFieldTouched("trackEndDate", true);
                formik.setFieldValue(
                  "trackEndDate",
                  getDateWithoutTime(date, true)
                );

                if (date === null) {
                  setIsTrackEndTimeDisabled(true);
                } else if (date && date.toString().trim() === "Invalid Date") {
                  setIsTrackEndTimeDisabled(true);
                } else {
                  setIsTrackEndTimeDisabled(false);
                }
              }}
              error={
                formik.touched.trackEndDate &&
                Boolean(formik.errors.trackEndDate)
              }
              helperText={formik.errors.trackEndDate}
              fullWidth
              className={styles.endDate}
            />
            <TimePicker
              margin="dense"
              value={formik.values.trackEndTime}
              format="hh:mm a"
              inputVariant="outlined"
              placeholder="End Time"
              onChange={(time) => {
                formik.setFieldValue("trackEndTime", time);
              }}
              style={{ marginLeft: "10px", color: "red" }}
              disabled={isTrackEndTimeDisabled}
            />
          </div>
        </div>
      </div>

      <div className={styles.card}>
        <AutocompleteCNO
          label="Job Search"
          name="job"
          placeholder="Select job..."
          formik={formik}
          data={suggestions}
          onSuggestionClick={(value) => {
            console.log("Job Value: ", value);
          }}
          onChange={(value) => {
            if (value) {
              dispatch(fetchTaskSuggest(value));
            }
          }}
          optionLabel={(x) => x.title || ""}
          renderOption={(option) => (
            <div style={{ color: "#014663", fontWeight: "500" }}>
              {option.title}
            </div>
          )}
          // fullWidth
        />
        <div className={styles.picker}>
          <Typography component="label">Job Start Date</Typography>
          <KeyboardDatePicker
            margin="dense"
            value={formik.values.jobStartDate}
            format="MMM dd, yyyy"
            inputVariant="outlined"
            placeholder="Job Start Date"
            onChange={(date) => {
              formik.setFieldTouched("jobStartDate", true);
              formik.setFieldValue(
                "jobStartDate",
                getDateWithoutTime(date, false)
              );
            }}
            error={
              formik.touched.jobStartDate && Boolean(formik.errors.jobStartDate)
            }
            helperText={formik.errors.jobStartDate}
            fullWidth
            className={styles.startDate}
          />
        </div>
        <div className={styles.picker}>
          <Typography component="label">Job End Date</Typography>
          <KeyboardDatePicker
            margin="dense"
            value={formik.values.jobEndDate}
            format="MMM dd, yyyy"
            inputVariant="outlined"
            minDate={formik.values.jobStartDate}
            placeholder="Job Start Date"
            onChange={(date) => {
              formik.setFieldTouched("jobEndDate", true);
              formik.setFieldValue(
                "jobEndDate",
                getDateWithoutTime(date, true)
              );
            }}
            error={
              formik.touched.jobEndDate && Boolean(formik.errors.jobEndDate)
            }
            helperText={formik.errors.jobEndDate}
            fullWidth
            className={styles.endDate}
            disablePast
          />
        </div>
      </div>

      <div className={styles.ctaWrapper}>
        <Button
          variant="contained"
          startIcon={<Icon path={mdiFileCancel} size="1.5rem" />}
          onClick={closeSidebar}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<Icon path={mdiFileSearch} size="1.5rem" />}
          disabled={!formik.dirty || !formik.isValid}
        >
          Search
        </Button>
      </div>
    </form>
  );
}

export default MapSidebar;

const useStyles = makeStyles((theme) => ({
  contaier_MapSidebar: {
    width: ({ tillTablet }) => (tillTablet ? "auto" : 344),
    padding: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    "&>*": {
      marginTop: theme.spacing(1),
    },
    "&>*:first-child": {
      height: ({ tillTablet }) => (tillTablet ? "auto" : 60),
    },
  },
  card: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    background: theme.colors.FORM_SECTION_BACKGROUND_COLOR,
  },
  picker: {
    width: "100%",
    "& .MuiInputBase-root": {
      background: theme.colors.INPUT_BACKGROUND_COLOR,
      color: theme.colors.INPUT_TEXT_COLOR,
    },
    "& .MuiIconButton-label": {
      color: theme.colors.INPUT_TEXT_COLOR,
    },
  },
  startDate: {
    "& .MuiSvgIcon-root": {
      color: theme.colors.JOB_START_DATE_COLOR,
    },
  },
  endDate: {
    "& .MuiSvgIcon-root": {
      color: theme.colors.JOB_END_DATE_COLOR,
    },
  },
  ctaWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
    "&>*": {
      // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`,
      border: `1px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
      color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
      "&:first-child": {
        marginRight: theme.spacing(4),
        background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
        color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
        "&:last-child": {
          background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
          marginRight: theme.spacing(1.5),
        },
      },
    },
  },
}));
