import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Tab, Tabs, Typography } from '@material-ui/core';
import { mdiAccount } from '@mdi/js';
import React, { useEffect, useState } from 'react';
import { DialogCNO } from '../../../library'
import AvatarCNO from '../../../library/AvatarCNO';
import { EventAttendeeRole, EventResponseActionStatus } from '../../../utils/mappings';

const EventAttendeesList = ({
  open = 0,
  onClose,
  goingAttendees = [],
  interestedAttendees = []
}) => {

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, value) => {
    console.log("handleTabChange: ", { e, value });
    setTabValue(value);
  }

  useEffect(() => {
    setTabValue(open - 1);
  }, [open]);

  console.log("handleTabChange: ", { tabValue, open });


  return (
    <DialogCNO
      open={Boolean(open)}
      onClose={onClose}
      dialogTitle="Attendees"
    >
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label={`Interested (${interestedAttendees.length})`} />
        <Tab label={`Going (${goingAttendees.length})`} />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <AttendeeList attendees={interestedAttendees} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <AttendeeList attendees={goingAttendees} />
      </TabPanel>

    </DialogCNO>
  )
}

export default EventAttendeesList;

const TabPanel = ({ children, value, index, ...rest }) => {

  return value === index ? children : null;

}

const AttendeeList = ({ attendees = [] }) => {

  return (
    <List>
      {
        attendees.map(attendee => (
          <ListItem>
            <ListItemAvatar>
              <AvatarCNO
                alt={attendee.title}
                src={attendee.profilePic}
                fallbackIcon={mdiAccount}
                iconSize={'2rem'}
              />
            </ListItemAvatar>
            <ListItemText>
              <Typography component='span' style={{ fontSize: '1rem' }}>{attendee.title}</Typography>
              <Typography component='span' style={{ fontSize: '0.8rem' }}>
                {" "}({EventAttendeeRole.getItem(attendee.role).title})
              </Typography>
            </ListItemText>
          </ListItem>
        ))
      }
    </List>
  )

}