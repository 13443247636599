import { Box, Button } from '@material-ui/core'
import { FieldArray } from 'formik'
import React from 'react'
import { getNestedObjectValue, isArrayEmpty } from '../../../../utils/Helpers'
import FormInput from './FormInput'
import { IconPicker, InputCNO, InputLabelCNO } from '../../../../library'
import IconCNO, { IconMain } from '../../../../library/IconCNO'
import { v4 } from 'uuid'
import { FormFieldsTypes } from '../../utils/enums'

function ChipOptionsArray({ formik }) {

  return (
    <>
      {
        formik.values.type === FormFieldsTypes.ids.Chips ?
          <FieldArray name='attributes.options'>
            {
              function ChipOptions(fieldArray) {

                const handleAddChipOption = () => {
                  fieldArray.push(
                    {
                      id: v4(),
                      title: 'General',
                      icon: 'shape-outline'
                    }
                  )
                }

                return (
                  <Box py={1} px={2}>
                    {
                      formik.values.attributes.options.map((option, index) => {

                        const optionAccessorKey = `attributes.options.${index}`;

                        return (
                          <Box display='flex' alignItems='center' >
                            <IconPicker
                              value={getNestedObjectValue(formik.values, `${optionAccessorKey}.icon`)}
                              onChange={(value) => formik.setFieldValue(`${optionAccessorKey}.icon`, value)}
                              buttonStyles={{
                                background: 'white',
                                marginRight: 10,
                                minHeight: 34,
                                marginTop: 10,
                                marginBottom: 8,
                              }}
                              buttonIconStyles={{
                                fontSize: 18
                              }}
                              library='M'
                            />

                            <InputCNO
                              name={`${optionAccessorKey}.title`}
                              placeholder='Enter chip title...'
                              formik={formik}
                              fullWidth
                              containerStyles={{ width: '100%' }}
                            />

                            <IconMain name='trash-can' onClick={() => fieldArray.remove(index)} />
                          </Box>
                        )
                      })
                    }
                    <Button variant='contained' onClick={handleAddChipOption}>Add Chip Option</Button>
                  </Box>
                )

              }
            }
          </FieldArray>
          : null
      }
    </>
  )
}

export default ChipOptionsArray