import { makeStyles, Typography, useTheme } from "@material-ui/core";
import { mdiClockEnd, mdiClockStart } from "@mdi/js";
import Icon from "@mdi/react";
import clsx from "clsx";
import { useFormik } from "formik";
import React, { useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { updateQuotation } from "../../actions/quotationActions";
import DialogCNO from "../../library/DialogCNO";
import { calendarStrings, TIME_FORMAT } from "../../utils/formatDate";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import FormActionButtons from "../FormActionButtons";
import * as yup from "yup";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { QuotationStatus } from "../../utils/mappings";
import RightsManager from "../../utils/RightsManager";
import {
  formatTimeWithTZ,
  validateStartDateEndDate,
} from "../../utils/Helpers";
import DateTimePickerCNO from "../../library/DateTimePickerCNO";

const EditTimelineDialog = ({ open, onClose, quotation }) => {
  const GlobalStyles = useGlobalStyles();
  const styles = useStyles();

  const dispatch = useDispatch();
  const {
    allQuotationLoader: { editLoader },
  } = useSelector((state) => state.quotations);

  const formik = useFormik({
    initialValues: {
      startDate: quotation.startDate,
      startTime: quotation.startTime,
      endDate: quotation.endDate,
      endTime: quotation.endTime,
    },
    validationSchema: yup.object({
      startDate: yup.date("Invalid Date").required("Start Date is required."),
      startTime: yup.date("Invalid Date").required("Start Time is required."),
      endDate: yup
        .date("Invalid Date")
        .required("End Date is required.")
        .test(
          "endDateTimeValidation",
          "End Date can't be less than Start Date.",
          function () {
            const startDate = this.parent.startDate;
            const startTime = this.parent.startTime;
            const endDate = this.parent.endDate;
            const endTime = this.parent.endTime;

            const isValid = validateStartDateEndDate({
              startDate,
              startTime,
              endDate,
              endTime,
            });

            return isValid;
          }
        ),
      endTime: yup
        .date("Invalid Date")
        .required("End Time is required.")
        .test(
          "endDateTimeValidation",
          "End Date can't be less than Start Date.",
          function () {
            const startDate = this.parent.startDate;
            const startTime = this.parent.startTime;
            const endDate = this.parent.endDate;
            const endTime = this.parent.endTime;

            const isValid = validateStartDateEndDate({
              startDate,
              startTime,
              endDate,
              endTime,
            });

            return isValid;
          }
        ),
    }),
    onSubmit: (values) => handleUpdate(values),
  });

  const handleUpdate = (values) => {
    console.log("Quotation edit values: ", values);

    const updateQuotationPayload = {
      ...quotation,
      startDate: values.startDate,
      startTime: values.startTime,
      endDate: values.endDate,
      endTime: values.endTime,
    };

    dispatch(updateQuotation(updateQuotationPayload));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Quotation"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>
        <DateTimePickerCNO
          dateName="startDate"
          datePlaceholder="Start Date"
          datePickerLabel="Start by Date"
          onDateChange={(date) => {
            formik.setFieldValue("startDate", date);
          }}
          timeName="startTime"
          timePlaceholder="Start Time"
          timePickerLabel="Start by Time"
          onTimeChange={(date) => {
            formik.setFieldValue("startTime", date);
          }}
          formik={formik}
          disablePast={true}
          iconColor="red"
        />

        <DateTimePickerCNO
          dateName="endDate"
          datePlaceholder="Finish Date"
          datePickerLabel="Finish by Date"
          onDateChange={(date) => {
            formik.setFieldValue("endDate", date);
          }}
          timeName="endTime"
          timePlaceholder="Finish Time"
          timePickerLabel="Finish by Time"
          onTimeChange={(date) => {
            formik.setFieldValue("endTime", date);
          }}
          formik={formik}
          minDate={formik.values.startDate}
          iconColor="green"
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  );
};

function QuotationJobTimeDetailsCard({ quotation }) {
  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isCompanyAdmin = RightsManager.isGroupLeader();

  const [isTimelineEditable, setIsTimelineEditable] = useState(false);

  return (
    <div className={GlobalStyles.detailsCardContainer}>
      <Typography className={GlobalStyles.detailsCardSectionTitle}>
        Job Timeline
      </Typography>
      <div
        className={GlobalStyles.detailsCardSection}
        onClick={() => setIsTimelineEditable(true)}
      >
        <div className={GlobalStyles.detailsRow}>
          <Typography style={{ color: theme.colors.JOB_START_DATE_COLOR }}>
            <Moment interval={0} calendar={calendarStrings}>
              {formatTimeWithTZ(quotation.startDate)}
            </Moment>
            <Moment interval={0} format={TIME_FORMAT}>
              {formatTimeWithTZ(quotation.startTime)}
            </Moment>
          </Typography>
          <Icon
            path={mdiClockStart}
            size="2rem"
            color={theme.colors.JOB_START_DATE_COLOR}
          />
        </div>
        {
          // isUserAdmin &&
          <div className={GlobalStyles.detailsRow}>
            <Typography style={{ color: theme.colors.JOB_END_DATE_COLOR }}>
              <Moment interval={0} calendar={calendarStrings}>
                {formatTimeWithTZ(quotation.endDate)}
              </Moment>
              <Moment interval={0} format={TIME_FORMAT}>
                {formatTimeWithTZ(quotation.endTime)}
              </Moment>
            </Typography>
            <Icon
              path={mdiClockEnd}
              size="2rem"
              color={theme.colors.JOB_END_DATE_COLOR}
            />
          </div>
        }
      </div>

      {isCompanyAdmin && isTimelineEditable && (
        <EditTimelineDialog
          open={isTimelineEditable}
          onClose={() => setIsTimelineEditable(false)}
          quotation={quotation}
        />
      )}
    </div>
  );
}

export default QuotationJobTimeDetailsCard;

const useStyles = makeStyles((theme) => ({
  start: {
    "& .MuiSvgIcon-root": {
      color: theme.colors.JOB_START_DATE_COLOR,
    },
  },
  end: {
    "& .MuiSvgIcon-root": {
      color: theme.colors.JOB_END_DATE_COLOR,
    },
  },
}));
