import React, { useState, useEffect } from 'react';
import Text from '../../../common/components/Text';
import Heading from '../../../common/components/Heading';
import { Icon } from 'react-icons-kit';
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle';
import { closeCircled } from 'react-icons-kit/ionicons/closeCircled';
import Container from '../../../common/components/UI/Container';
import Button from '../../../common/components/Button';
import { SectionHeader } from '../LandingPage.style';
import illustration from '../../../common/assets/image/LandingPage/shapeLeft2.png';
import illustration2 from '../../../common/assets/image/LandingPage/shapeRight2.png';
import SectionWrapper, {
  SectionBgArea,
  ContentWrapper,
  ContentPricing,
  PriceTable,
  PricingFeature,
  FeatureItem,
} from './pricing.style';
import { pricing } from '../../../common/data/LandingPage';
import Image from '../../../common/components/Image';
import { Typography, useTheme } from '@material-ui/core';
import { SubscriptionMode, ThemeType } from '../../../utils/mappings';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPricingItems } from '../../../actions/subscriptionActions';
import ChipCNO from '../../../library/ChipCNO';
import { mdiCalendar, mdiCalendarMonth } from '@mdi/js';
import darkColors from '../../../common/theme/LandingPage/darkThemeColors';
import colors from '../../../common/theme/LandingPage/colors';
import { setLPLoginModalDefaultTab, toggleLPLoginModal } from '../../../actions/appActions';

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

function UseWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

const Pricing = () => {
  const size = process.browser && UseWindowSize();
  const isTablet = Boolean(size.innerWidth <= 768);
  const { slogan, title } = pricing;

  const dispatch = useDispatch();

  let { pricingItems } = useSelector(state => state.subscription);

  let pricingFeature = [];
  if (pricingItems.length) {
    pricingFeature = pricingItems[0].features;
  }

  const [subscriptionMode, setSubscriptionMode] = useState(SubscriptionMode.Monthly);
  const isYearly = subscriptionMode === SubscriptionMode.Yearly;

  pricingItems = pricingItems.map(item => ({
    ...item,
    price: isYearly ? item.price.yearly : item.price.monthly,
    strikedPrice: isYearly ? item.strikedPrice.yearly : item.strikedPrice.monthly,
    perUserPrice: isYearly ? item.perUserPrice.yearly : item.perUserPrice.monthly,
  }));

  useEffect(() => {

    if (!pricingItems.length) {
      dispatch(fetchPricingItems());
    }

  }, []);

  const isDarkTheme = useTheme().palette.type === ThemeType.Dark;

  return (
    <SectionWrapper id="pricing">
      <SectionBgArea>
        <div className="shape-one">
          <Image src={illustration} alt="Shape" />
        </div>
        <div className="shape-two">
          <Image src={illustration2} alt="Shape" />
        </div>
      </SectionBgArea>
      <Container className={'pricing-container'}>
        <SectionHeader className="text-white on-top">
          <Heading content={title} />
          <Text content={slogan} />
        </SectionHeader>
        <ContentWrapper style={{ paddingTop: 40 }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 60 }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, marginBottom: 5 }}>
              <ChipCNO active={!isYearly} name="Monthly" icon={mdiCalendar} onClick={() => setSubscriptionMode(SubscriptionMode.Monthly)} />
              <ChipCNO active={isYearly} name="Yearly" icon={mdiCalendarMonth} onClick={() => setSubscriptionMode(SubscriptionMode.Yearly)} />
            </div>
            <Typography
              style={{
                visibility: isYearly ? 'visible' : 'hidden',
                color: isDarkTheme ? darkColors.textColor : colors.textColor
              }}>
              10-20% discount in yearly mode!
            </Typography>
          </div>
          <ContentPricing>
            {!isTablet && (
              <PricingFeature>
                {pricingFeature.map((feature) => (
                  <FeatureItem key={feature.id}>{feature.name}</FeatureItem>
                ))}
              </PricingFeature>
            )}
            {pricingItems.map((priceTable) => (
              <PriceTable
                key={priceTable.id}
                className={priceTable.isRecommended && 'isRecommended'}
              >
                {priceTable.isRecommended && (
                  <div className="recommended">Recommended</div>
                )}

                <h2 className="title">{priceTable.package_name}</h2>
                <div className="price">
                  <p>&#163; {priceTable.price}</p>
                  <p style={{ fontSize: '1.1rem', textDecoration: 'line-through', fontWeight: 'normal' }}>&#163; {priceTable.strikedPrice}</p>
                  <span style={{ fontSize: '16px' }}>/{isYearly ? 'year' : 'month'}</span>
                </div>
                <div className='user'>
                  <p>{priceTable.noOfUsers} users</p>
                  <p className='perUser'>({priceTable.perUserPrice} per user)</p>
                </div>
                <ul className="featureList">
                  {priceTable.features.map((feature) => (
                    <FeatureItem key={feature.id}>
                      {isTablet ? (
                        feature.isAvailable ? (
                          feature.name
                        ) : (
                          <Icon
                            icon={closeCircled}
                            size={18}
                            style={{ color: '#CED7E1' }}
                          />
                        )
                      ) : feature.isAvailable ? (
                        <Icon
                          icon={ic_check_circle}
                          size={18}
                          style={{ color: '#3CC68A' }}
                        />
                      ) : (
                        <Icon
                          icon={closeCircled}
                          size={18}
                          style={{ color: '#CED7E1' }}
                        />
                      )}
                    </FeatureItem>
                  ))}
                </ul>
                <Button
                  title="Choose Plan"
                  className={`${!priceTable.isRecommended && 'primaryOutlined'
                    } choosePlan`}
                  onClick={() => {
                    dispatch(toggleLPLoginModal(true));
                    dispatch(setLPLoginModalDefaultTab('registerForm'));
                  }}
                />
                <p className="trial">{priceTable.trial_day} days free trial</p>
              </PriceTable>
            ))}
          </ContentPricing>
          {/* <ContentWrap>
            <Heading
              as="h3"
              content="Are you looking for custom price? Let’s talk about it."
              color={isDarkTheme ? darkColors.textColor : colors.textColor}
            />
            <ButtonWrap>
              <Button title="Contact Us Now!" />
            </ButtonWrap>
          </ContentWrap> */}
        </ContentWrapper>

      </Container>
    </SectionWrapper>
  );
};

export default Pricing;
