import { mdiFormatText } from '@mdi/js';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons';

export default function EditAddInvoiceItemTitle({
  open,
  onClose,
  onSave,
  value,
}) {

  const formik = useFormik({
    initialValues: {
      title: value,
    },
    validationSchema: yup.object({
      title: yup.string().required('Title is required')
    }),
    onSubmit: (values) => handleUpdate(values)
  });


  const handleUpdate = (values) => {
    console.log("Edit Add Invoice Item: title: ", values);

    if (onSave) {
      onSave(values.title);
    }

    onClose();
  };


  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Invoice Item Title"
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name="title"
          label="Invoice Item Title"
          placeholder="Enter Invoice Item Title"
          icon={mdiFormatText}
          formik={formik}
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}
