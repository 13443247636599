import React from 'react';
import DialogCNO from "../../library/DialogCNO";
import Visitorpass from '../../utils/Appointments/VisitorPass';

const VisitorPassDialogue = ({
    open,
    onClose,
    appointment,
    closeMoreActions,
    visitorPass,
  
}) => {
    const handleClose = () => {

        onClose();
    
        if (closeMoreActions) {
          closeMoreActions();
        }
    
      };
  
  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Visitor Pass"
      width = '35vw'
    >
      <Visitorpass appointment={appointment}/>
    </DialogCNO>
  )

}

export default VisitorPassDialogue;