import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Popover,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { mdiChevronDown, mdiDotsVertical } from "@mdi/js";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { MediaType } from "../../utils/mappings";
import AssignedToDetailsCard from "../AssignedToDetailsCard";
import CostDetailsCard from "../CostDetailsCard/CostDetailsCard";
import CustomerDetailsCard from "../CustomerDetailsCard";
import JobDetailsCard from "../JobDetailsCard";
import JobImagesDetailsCard from "../JobImagesDetailsCard";
import JobLocationDetailsCard from "../JobLocationDetailsCard";
import JobTimelineDetailsCard from "../JobTimelineDetailsCard";
import MorePopover from "../MorePopover/MorePopover";
import NotFound from "../NotFound/NotFound";
import UserStatusBar from "../UserStatusBar";
import { useStyles } from "./JobDetailsStyles";
import { editJob, getTaskById, setTaskById } from "../../actions/jobActions";
import JobTargetDetailsCard from "../JobTargetDetailsCard/JobTargetDetailsCard";
import SubTaskDetailsCard from "../SubTaskDetailsCard/SubTaskDetailsCard";
import { SubTaskType } from "../../utils";
import { setSubTasks } from "../../actions/subTaskActions";
import RightsManager from "../../utils/RightsManager";
import JobDocumentsDetailsCard from "../JobDocumentsDetailsCard";
import JobProximityDetailsCard from "./JobProximityDetailsCard";
import JobAssigneeChatDetailsCard from "./JobAssigneeChatDetailsCard";
import LocalStorageConstants from "../../utils/LocalStorageConstants";
import {
  createChatGroup,
  fetchChatGroups,
  fetchChats,
} from "../../actions/chatActions";
import JobCustomerChatDetailsCard from "./JobCustomerChatDetailsCard";
import { useMediasPendingUploadingForCurrrentItem } from "../../hooks/useMediasPendingUploadingForCurrrentItem";

function JobDetails() {
  const authUser = LocalStorageConstants.getItem("authUser");

  const theme = useTheme();

  const tillTablet = useMediaQuery("(max-width: 767px)");

  const isUserAdmin = RightsManager.isAdmin();
  const isCustomer = RightsManager.isCustomer();
  const isUserMember = RightsManager.isMember();

  const { id } = useParams();

  const {
    isJobDeleteLoader,
    isShareJobLoader,
    editTodoLoader,
    taskById,
    isJobLoader,
    jobs,
  } = useSelector((state) => state.jobs);

  const {
    loaders: { createGroupLoader },
    universalLoader,
  } = useSelector((state) => state.chats);

  const [job, setJob] = useState(null);

  const [isChatAccordionVisible, setIsChatAccordionVisible] = useState({
    teamChatGroup: false,
    customerChatGroup: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const job = jobs.find((job) => job.id === id);

    dispatch(setSubTasks([]));

    dispatch(getTaskById(id, job));
  }, [id]);

  useEffect(() => {
    if (taskById) {
      setJob(taskById);
    }
  }, [taskById]);

  useEffect(() => {
    return () => {
      dispatch(setSubTasks([]));
      dispatch(setTaskById(null));
    };
  }, []);

  // console.log("joooob: ", job);

  const [refMore, setRefMore] = useState(null);

  const openMore = (e) => {
    setRefMore(e.currentTarget);
  };

  const closeMore = () => {
    setRefMore(null);
  };
  const open = Boolean(refMore);

  // create chat group with customer
  const handleEnableCustomerChat = async () => {
    setIsChatAccordionVisible({
      teamChatGroup: false,
      customerChatGroup: false,
    });

    const addToGroupPayload = {
      title: `Customer - ${job.title}`,
      profilePic: null,
      members: [{ customerId: job.customerId }],
    };

    const onChatGroupCreate = async (customerChatGroupId) => {
      await dispatch(
        editJob({ ...job, customerChatGroupId: customerChatGroupId }, false)
      );
    };

    await dispatch(createChatGroup(addToGroupPayload, null, onChatGroupCreate));
  };

  // create chat group with assignees
  const handleEnableTeamChat = async () => {
    setIsChatAccordionVisible({
      teamChatGroup: false,
      customerChatGroup: false,
    });

    const addToGroupPayload = {
      title: `Assignee - ${job.title}`,
      profilePic: null,
      members: job.taskAssignments
        .filter((assignee) => assignee.staffId !== authUser.id)
        .map((assignee) => ({ memberId: assignee.staffId })),
    };

    const onChatGroupCreate = async (teamChatGroupId) => {
      await dispatch(editJob({ ...job, teamChatGroupId: teamChatGroupId }));
    };

    await dispatch(createChatGroup(addToGroupPayload, null, onChatGroupCreate));
  };

  const NativePopover = () => {
    return (
      <Popover
        open={open}
        anchorEl={refMore}
        onClose={closeMore}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MorePopover
          item={job}
          closeMore={closeMore}
          onShareTaskWithCustomer={(job) => setJob(job)}
          handleEnableCustomerChat={handleEnableCustomerChat}
          handleEnableTeamChat={handleEnableTeamChat}
        />
      </Popover>
    );
  };

  console.log("=======createGroupLoader==========", createGroupLoader);

  const isMediaUploadingLs = useMediasPendingUploadingForCurrrentItem();

  const styles = useStyles({ tillTablet });
  if (job)
    return (
      <div className={styles.container}>
        {(isJobDeleteLoader ||
          isShareJobLoader ||
          editTodoLoader ||
          createGroupLoader ||
          universalLoader ||
          isJobLoader) && <FullScreenLoaderCNO />}
        <div className={styles.userStatusBar}>
          <UserStatusBar item={job} />
          {
            // remove customer check once customer has its own action option
            !isCustomer && (
              <Fragment>
                <Icon
                  className={styles.moreIcon}
                  path={mdiDotsVertical}
                  size="1.8rem"
                  color={theme.colors.MORE_ACTIONS_ICON_COLOR}
                  onClick={openMore}
                />
                <NativePopover />
              </Fragment>
            )
          }
        </div>
        <JobDetailsCard id={id} job={job} setJob={setJob} />
        <SubTaskDetailsCard
          id={id}
          job={job}
          setJob={setJob}
          subTaskType={SubTaskType.Task}
        />
        {isUserAdmin && <CostDetailsCard job={job} setJob={setJob} />}

        <JobImagesDetailsCard
          job={job}
          setJob={setJob}
          header=""
          mediaType={MediaType.Input}
          userType="employee"
        />

        <JobDocumentsDetailsCard
          job={job}
          mediaType={MediaType.Input}
          isUserAllowed={isUserAdmin && !isMediaUploadingLs}
        />

        <CustomerDetailsCard
          job={job}
          setJob={setJob}
          id={id}
          customerId={job.customerId}
          customerText={job.customerText}
        />
        {(isUserAdmin || isUserMember) && (
          <AssignedToDetailsCard job={job} setJob={setJob} />
        )}
        <JobTimelineDetailsCard job={job} setJob={setJob} />
        <JobLocationDetailsCard job={job} setJob={setJob} />

        {isUserAdmin && <JobProximityDetailsCard job={job} setJob={setJob} />}

        {isUserAdmin && <JobTargetDetailsCard job={job} setJob={setJob} />}

        <JobDocumentsDetailsCard
          job={job}
          mediaType={MediaType.Output}
          title="Work Progress"
          isUserAllowed={(isUserAdmin || isUserMember) && !isMediaUploadingLs}
        />

        {/* <JobImagesDetailsCard
          job={job}
          setJob={setJob}
          header="Work Progress Images"
          mediaType={MediaType.Output}
          userType="assignee"
        /> */}

        {(isUserAdmin || isCustomer) && (
          <JobCustomerChatDetailsCard
            job={job}
            handleEnableCustomerChat={handleEnableCustomerChat}
            isChatAccordionVisible={isChatAccordionVisible}
            setIsChatAccordionVisible={setIsChatAccordionVisible}
          />
        )}

        {(isUserAdmin || isUserMember) && (
          <JobAssigneeChatDetailsCard
            job={job}
            handleEnableTeamChat={handleEnableTeamChat}
            isChatAccordionVisible={isChatAccordionVisible}
            setIsChatAccordionVisible={setIsChatAccordionVisible}
          />
        )}
      </div>
    );
  else return <>{isJobLoader ? <FullScreenLoaderCNO /> : <NotFound />}</>;
}

export default JobDetails;
