const { id, roleId } = JSON.parse(localStorage.getItem("authUser")) || {};

const RoleId = {
  Member: '64ebaf14-392c-11eb-9b5c-00ffba546a08',
  GroupLeader: 'b2922c7e-392d-11eb-9b5c-00ffba546a08',
  CompanyAdmin: 'c1e3de57-392d-11eb-9b5c-00ffba546a08', //app admin / super
  Customer: '94ebaf14-392c-11eb-9b5c-00ffba546a07'
};

const RightsManager = {

  isAdmin: () => {
    return id && (roleId === RoleId.GroupLeader || roleId === RoleId.CompanyAdmin);
  },

  isCustomer: () => {
    return id && (roleId === RoleId.Customer);
  },

  isMember: () => {
    return id && (roleId === RoleId.Member);
  },

  isGroupLeader: () => {
    return id && (roleId === RoleId.GroupLeader);
  }

};

export default RightsManager;