import React, { Fragment, useEffect, useState } from "react";
import { useStyles } from "./UserMainDetailsCardStyles";
import {
  Badge,
  CircularProgress,
  Typography,
  useTheme,
} from "@material-ui/core";
import AvatarCNO from "../../library/AvatarCNO/AvatarCNO";
import { mdiAccount, mdiCamera, mdiFormatText, mdiImagePlus } from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";
import DialogCNO from "../../library/DialogCNO";
import * as yup from "yup";
import { useFormik } from "formik";
import { updateEmployeeDetails } from "../../actions/employeeActions";
import { updateCustomerDetails } from "../../actions/customerActions";
import InputCNO from "../../library/InputCNO";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import Icon from "@mdi/react";
import Upload from "rc-upload";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { ApiCore } from "../../api/utils/core";
import RightsManager from "../../utils/RightsManager";
import { setAuthUserProfilePic } from "../../actions/authUserActions";
import { ImageSizeType } from "../../utils/mappings";
import MoreActionCNO from "../../library/MoreActionsCNO";
import ProfileListCardPopover from "../ProfileListCardPopover/ProfileListCardPopover";
import { updateServiceProviderDetails } from "../../actions/serviceProviderActions";

function EditUsername({
  open,
  onClose,
  profile,
  isEmployee,
  isServiceProvider,
  localProfilePicUrl,
}) {
  const styles = useStyles();

  const dispatch = useDispatch();
  const {
    allEmployeesLoader: { editLoader: employeeEditLoader },
  } = useSelector((state) => state.employees);

  const {
    allServiceProvidersLoader: { editLoader: serviceProviderEditLoader },
  } = useSelector((state) => state.serviceProviders);

  const {
    allCustomersLoader: { editLoader: customerEditLoader },
  } = useSelector((state) => state.customers);

  const validationSchema = yup.object({
    firstName: yup.string().required("Required!!!"),
    lastName: yup.string().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: profile.firstName,
      lastName: profile.lastName,
    },
    validationSchema,
    onSubmit: (values) => handleUpdate(values),
  });

  const handleUpdate = (values) => {
    const dataToSend = {
      ...profile,
      firstName: values.firstName,
      lastName: values.lastName,
      localProfilePicUrl: localProfilePicUrl,
    };

    if (isEmployee) {
      dispatch(updateEmployeeDetails(dataToSend));
    } else if (isServiceProvider) {
      dispatch(updateServiceProviderDetails(dataToSend));
    } else {
      dispatch(updateCustomerDetails(dataToSend));
    }

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Details"
      loading={
        employeeEditLoader || customerEditLoader || serviceProviderEditLoader
      }
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <InputCNO
          name={"firstName"}
          label="First Name"
          formik={formik}
          placeholder="Enter First Name"
          icon={mdiFormatText}
          fullWidth
        />

        <InputCNO
          name={"lastName"}
          label="Last Name"
          formik={formik}
          placeholder="Enter Last Name"
          icon={mdiFormatText}
          fullWidth
        />

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  );
}

function EditMainDetails({
  open,
  onClose,
  profile,
  editablePropertyName,
  isEmployee,
  isServiceProvider,
  localProfilePicUrl,
}) {
  const styles = useStyles();

  const label = {
    code: "Code",
    abstract: "Abstract",
  };
  const placeholder = {
    code: "Enter code",
    abstract: "Enter abstract",
  };

  const dispatch = useDispatch();
  const {
    allEmployeesLoader: { editLoader: employeeEditLoader },
  } = useSelector((state) => state.employees);

  const {
    allServiceProvidersLoader: { editLoader: serviceProviderEditLoader },
  } = useSelector((state) => state.serviceProviders);

  const {
    allCustomersLoader: { editLoader: customerEditLoader },
  } = useSelector((state) => state.customers);

  const validationSchema = yup.object({
    [editablePropertyName]: yup.string().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      [editablePropertyName]: profile[editablePropertyName],
    },
    validationSchema,
    onSubmit: (values) => handleUpdate(values),
  });

  const handleUpdate = (values) => {
    const dataToSend = {
      ...profile,
      [editablePropertyName]: values[editablePropertyName],
      localProfilePicUrl: localProfilePicUrl,
    };

    if (isEmployee) {
      dispatch(updateEmployeeDetails(dataToSend));
    } else if (isServiceProvider) {
      dispatch(updateServiceProviderDetails(dataToSend));
    } else {
      dispatch(updateCustomerDetails(dataToSend));
    }

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Details"
      loading={
        employeeEditLoader || customerEditLoader || serviceProviderEditLoader
      }
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <InputCNO
          name={editablePropertyName}
          label={label[editablePropertyName]}
          formik={formik}
          placeholder={placeholder[editablePropertyName]}
          icon={mdiFormatText}
          fullWidth
        />
        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />
      </form>
    </DialogCNO>
  );
}

function UserMainDetailsCard({
  profile,
  isEmployee,
  isActiveMember,
  localProfilePicUrl,
  setLocalProfilePicUrl,
  isServiceProvider,
}) {
  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : null;

  const styles = useStyles();
  console.log("firstlocalProfilePicUrl",localProfilePicUrl)
  const theme = useTheme();

  const isUserAdmin = RightsManager.isAdmin();
  const isUserMember = RightsManager.isMember();

  const dispatch = useDispatch();

  const [isUsernameEditable, setIsUsernameEditable] = useState(false);

  const [editablePropertyName, setEditablePropertyName] = useState(null);
  const [isMainDetailsEditable, setIsMainDetailsEditable] = useState(false);

  const [avatarLoading, setAvatarLoading] = useState(false);

  // converts base64 image to blob
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const processAndUploadImage = async (base64file) => {
    setAvatarLoading(true);
    const imageId = v4();
    const imageURL = base64file;

    // Split the base64 string in data and contentType
    const block = imageURL.split(";");
    // Get the content type of the image
    const contentType = block[0].split(":")[1];
    // get the real base64 content of the file
    const realData = block[1].split(",")[1];

    const blob = b64toBlob(realData, contentType);

    const memberUrl = "member/initupload";
    const customerUrl = "customer/initupload";
    const serviceProviderUrl = "serviceProvider/initupload";
    const apiProfileInitUpload = new ApiCore({
      post: true,
      url: isEmployee
        ? memberUrl
        : isServiceProvider
        ? serviceProviderUrl
        : customerUrl,
    });

    const initRes = await apiProfileInitUpload.post({ ids: [imageId] });

    // UPLOAD
    const mRes = await fetch(initRes.data.links[0].m, {
      method: "PUT",
      body: blob,
    });
    const tRes = await fetch(initRes.data.links[0].t, {
      method: "PUT",
      body: blob,
    });
    const bRes = await fetch(initRes.data.links[0].b, {
      method: "PUT",
      body: blob,
    });

    setLocalProfilePicUrl(imageURL);

    const updatedProfile = {
      ...profile,
      profilePic: imageId,
      localProfilePicUrl: imageURL,
    };

    let authUser = JSON.parse(localStorage.getItem("authUser"));
    authUser = {
      ...authUser,
      // profilePic: imageId,
      profilePic: imageURL,
    };

    if (authUser.id === profile.id) {
      localStorage.setItem("authUser", JSON.stringify(authUser));
      dispatch(setAuthUserProfilePic(imageURL));
    }

    if (isEmployee) {
      dispatch(updateEmployeeDetails(updatedProfile));
    } else if (isServiceProvider) {
      dispatch(updateServiceProviderDetails(updatedProfile));
    } else {
      dispatch(updateCustomerDetails(updatedProfile));
    }

    setAvatarLoading(false);
  };

  const processMediaUpload = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      // console.log("File Reader image Result: ", reader.result);
      processAndUploadImage(reader.result);
    };

    reader.onerror = function (error) {
      toast.error("Error in uploading profile");
      console.log("Error: ", error);
    };
  };

  const uploadProps = {
    beforeUpload: (file) => {
      if (file.type.includes("image")) {
        return true;
      }
      toast.error("Unsupported file");
      return false;
    },
    action: (file) => {
      console.log("Upload Action file: ", file);
      processMediaUpload(file);
    },
    multiple: false,
  };
  console.log("localProfilePicUrl",localProfilePicUrl)
  return (
    <div className={styles.container_UserMainDetails}>
      {console.log(authUser.id, profile.id)}
      {authUser.id !== profile.id && (
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <MoreActionCNO>
            <ProfileListCardPopover
              profile={profile}
              isEmployee={isEmployee}
              isServiceProvider={isServiceProvider}
            />
          </MoreActionCNO>
        </div>
      )}

      <Badge
        className={styles.avatarBadge}
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={
          (isUserAdmin || isUserMember) && isActiveMember ? (
            <div className={styles.pickers}>
              <Icon
                path={mdiCamera}
                size="1.5rem"
                color={theme.colors.IMAGE_CAMERA_ICON_COLOR}
                style={{ visibility: "hidden" }}
              />
              <Upload {...uploadProps} accept="image/*">
                <Icon
                  path={mdiImagePlus}
                  size="1.5rem"
                  color={theme.colors.IMAGE_UPLOAD_ICON_COLOR}
                />
              </Upload>
            </div>
          ) : null
        }
      >
        {avatarLoading ? (
          <CircularProgress
            style={{
              background: "#4e4e4e",
              padding: "20px",
              borderRadius: "50%",
              width: "8rem",
              height: "8rem",
            }}
          />
        ) : (
          <div className={styles.avatarWrapper}>
            <AvatarCNO
              src={localProfilePicUrl}
              fallbackIcon={mdiAccount}
              avatarStyle={{
                border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}`,
              }}
              iconSize="8rem"
              openAvatarInImageViewer={true}
            />
            {!isActiveMember && (
              <Typography component="span" className={styles.avatarStatusText}>
                Not Active
              </Typography>
            )}
          </div>
        )}
      </Badge>

      <Typography
        variant="h5"
        className={styles.username}
        onClick={() => {
          setIsUsernameEditable(true);
        }}
      >
        {profile.firstName}&nbsp;{profile.lastName}
      </Typography>

      {isEmployee && (
        <Typography
          className={styles.code}
          onClick={() => {
            setIsMainDetailsEditable(true);
            setEditablePropertyName("code");
          }}
        >
          {profile.code || "Click to add Code"}
        </Typography>
      )}

      {isServiceProvider && (
        <Typography
          className={styles.code}
          onClick={() => {
            setIsMainDetailsEditable(true);
            setEditablePropertyName("bio");
          }}
        >
          {profile.bio || "Click to add bio"}
        </Typography>
      )}

      {isServiceProvider && (
        <Typography
          className={styles.code}
          onClick={() => {
            setIsMainDetailsEditable(true);
            setEditablePropertyName("code");
          }}
        >
          {profile.code || "Click to add Code"}
        </Typography>
      )}

      <Typography
        className={styles.abstract}
        component="pre"
        onClick={() => {
          setIsMainDetailsEditable(true);
          setEditablePropertyName("abstract");
        }}
      >
        {profile.abstract || "Click to add Abstract"}
      </Typography>

      {isUsernameEditable && isUserAdmin && isActiveMember && (
        <EditUsername
          open={isUsernameEditable}
          onClose={() => setIsUsernameEditable(false)}
          profile={profile}
          isEmployee={isEmployee}
          isServiceProvider={isServiceProvider}
          localProfilePicUrl={localProfilePicUrl}
        />
      )}

      {isMainDetailsEditable &&
        (isUserAdmin ||
          (isUserMember && editablePropertyName === "abstract")) &&
        isActiveMember && (
          <EditMainDetails
            open={isMainDetailsEditable}
            onClose={() => setIsMainDetailsEditable(false)}
            profile={profile}
            editablePropertyName={editablePropertyName}
            isEmployee={isEmployee}
            isServiceProvider={isServiceProvider}
            localProfilePicUrl={localProfilePicUrl}
          />
        )}
    </div>
  );
}

export default UserMainDetailsCard;
