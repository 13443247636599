import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {
  mdiBullseyeArrow,
  mdiClockEnd,
  mdiClockStart,
  mdiHours24,
  mdiLockClock,
} from '@mdi/js';
import Icon from '@mdi/react';
import React, { Fragment, useState } from 'react';
import ResponsiveLinesCNO from '../../library/ResponsiveLinesCNO/ResponsiveLinesCNO';
import MoreActionCNO from '../../library/MoreActionsCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import Moment from 'react-moment';
import { calendarStrings, TIME_FORMAT } from '../../utils/formatDate';
import { RateType } from '../../utils';
import ChipCNO from '../../library/ChipCNO';
import {
  Currency,
  CurrencyIcon,
  JobTargetTypeIcon,
  JobTargetTypeReverse,
  JobTargetTypeUnit,
  RateTypeReverse
} from '../../utils/mappings';
import EditSubTaskTitle from '../EditSubTaskTitle/EditSubTaskTitle';
import EditSubTaskDescription from '../EditSubTaskDescription/EditSubTaskDescription';
import EditSubTaskTimeline from '../EditSubTaskTimeline/EditSubTaskTimeline';
import EditSubTaskAssignUsers from '../EditSubTaskAssignUsers';
import EditSubTaskCost from '../EditSubTaskCost/EditSubTaskCost';
import EditSubTaskTarget from '../EditSubTaskTarget/EditSubTarget';
import SubTaskMoreActions from '../SubTaskMoreActions/SubTaskMoreActions';
import { getMaterialIconString } from '../../utils/icons';
import * as icons from '@mdi/js';
import { formatTimeWithTZ, getAssignUsersString, getCategory, parseDisplayNumber, parseNumber } from '../../utils/Helpers';
import JobTypeIcon from '../JobTypeIcon/JobTypeIcon';
import EditSubTaskCategory from '../EditSubTaskCategory/EditSubTaskCategory';
import SubTaskStatusBar from '../SubTaskStatusBar/SubTaskStatusBar';
import RightsManager from '../../utils/RightsManager';

function SubTaskDetailsListItem({ subTask }) {

  let currencyType = localStorage.getItem('currencyType');
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isUserAdmin = RightsManager.isAdmin();
  const isUserMember = RightsManager.isMember();

  const jobCategory = getCategory(subTask.categories ? subTask.categories[0] : "");

  const [expanded, setExpanded] = useState(false);

  const [isCategoryEditable, setIsCategoryEditable] = useState(false);
  const [isTitleEditable, setIsTitleEditable] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  const [isAssignEditable, setIsAssignEditable] = useState(false);
  const [isTimlineEditable, setIsTimlineEditable] = useState(false);
  const [isCostEditable, setIsCostEditable] = useState(false);
  const [isTargetEditable, setIsTargetEditable] = useState(false);

  return (
    <Fragment>
      <Accordion expanded={expanded} className={GlobalStyles.accordion} style={{ background: theme.colors.SUBTASK_CARD_BACKGROUND_COLOR, marginTop: 10 }}>
        <AccordionSummary
          className={GlobalStyles.accordionSummary}
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ width: '100%' }}
          >

            <div style={{ flex: 1, display: 'flex' }}>
              <JobTypeIcon
                iconLink={jobCategory?.iconLink}
                name={jobCategory?.title}
                categoryId={jobCategory.id}
                onClick={() => setIsCategoryEditable(true)}
              />
              <div style={{ flex: 1, marginLeft: 8 }}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>

                  <Typography
                    style={{ color: theme.colors.JOB_DETAIL_TITLE_COLOR, fontSize: '1rem' }}
                    onClick={() => setIsTitleEditable(true)}
                  >
                    {subTask.title}
                  </Typography>

                  {
                    isUserAdmin &&
                    <MoreActionCNO>
                      <SubTaskMoreActions subTask={subTask} />
                    </MoreActionCNO>
                  }

                </div>

                <ResponsiveLinesCNO
                  text={subTask.description || 'Sub Task details not added'}
                  maxLine={!expanded ? 2 : 1000}
                  style={{ flex: 1, color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR }}
                  onClick={() => setIsDescription(true)}
                  component={'pre'}
                />

              </div>

            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10, flexWrap: 'wrap' }}>
              {
                !expanded
                  ?
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Fragment>
                      <Moment element={'pre'} onClick={() => setIsTimlineEditable(true)} format={"MMM DD, YYYY"} style={{ color: theme.colors.LIST_CARD_USERNAMES_COLOR }}>{formatTimeWithTZ(subTask.startDate)}</Moment>
                      {
                        (isUserAdmin || isUserMember) &&
                        <Fragment>
                          <Typography style={{ marginLeft: 3, marginRight: 3 }}>|</Typography>
                          <pre
                            onClick={() => {
                              if (isUserAdmin) {
                                setIsAssignEditable(true)
                              }
                            }}
                            style={{ color: theme.colors.LIST_CARD_USERNAMES_COLOR }}>
                            {getAssignUsersString(subTask.subTaskAssignments, false)}
                          </pre>
                        </Fragment>
                      }
                    </Fragment>

                    {
                      isUserAdmin &&
                      <Fragment>
                        <Typography style={{ marginLeft: 3, marginRight: 3 }}>|</Typography>

                        <Typography onClick={() => setIsCostEditable(true)} style={{ display: 'flex', alignItems: 'center', color: theme.colors.LIST_CARD_USERNAMES_COLOR }}>
                          <Icon
                            path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
                            size="1rem"
                            color={theme.colors.JOB_DETAIL_DESCRIPTION_COLOR}
                          />
                          {parseDisplayNumber(subTask.amount)}
                        </Typography>
                      </Fragment>
                    }

                  </div>
                  : null
              }
              <pre
                style={{ color: theme.colors.LINK_COLOR, textDecoration: 'underline', width: expanded ? '100%' : 'auto', textAlign: 'right' }}
                onClick={() => expanded ? setExpanded(null) : setExpanded('1')}
              >
                {expanded ? 'Less Details' : 'More Details'}
              </pre>
            </div>

            {
              !expanded
                ? <div style={{ marginTop: theme.spacing(2) }}>
                  <SubTaskStatusBar
                    subTask={subTask}
                    styles={{ background: theme.colors.SUBTASK_STATUS_BACKGROUND_COLOR }}
                  />
                </div>
                : null
            }

          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={GlobalStyles.subTaskMoreWrapper} style={{ width: '100%' }}>
            {
              (isUserAdmin || isUserMember) &&
              <Fragment>
                <div
                  style={{ display: 'flex', }}
                  onClick={() => {
                    if (isUserAdmin) {
                      setIsAssignEditable(true)
                    }
                  }}
                >
                  <Typography style={{ color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR, minWidth: 100, alignSelf: 'flex-start' }}>
                    Assigned To
                  </Typography>
                  <Typography style={{ color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR, }}>
                    {getAssignUsersString(subTask.subTaskAssignments)}
                  </Typography>
                </div>
                <Divider />
              </Fragment>
            }

            {
              subTask.rateType === RateType.Hourly &&
              <Fragment>
                <div
                  style={{ display: 'flex', }}
                  onClick={() => setIsTimlineEditable(true)}
                >
                  <Typography style={{ color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR, minWidth: 100, alignSelf: 'flex-start' }}>
                    Timeline
                  </Typography>
                  <div style={{ display: 'flex', flexDirection: tillTablet ? 'column' : 'row' }}>
                    <div className={GlobalStyles.detailsRow}>
                      <Typography style={{ color: theme.colors.JOB_START_DATE_COLOR }}>
                        <Moment interval={0} calendar={calendarStrings}>
                          {formatTimeWithTZ(subTask.startDate)}
                        </Moment>
                        <Moment interval={0} format={TIME_FORMAT}>
                          {formatTimeWithTZ(subTask.startTime)}
                        </Moment>
                      </Typography>
                      <Icon
                        path={mdiClockStart}
                        size='1.2rem'
                        color={theme.colors.JOB_START_DATE_COLOR}
                      />
                      {
                        isUserAdmin &&
                        <span style={{ marginLeft: 5, marginRight: 5 }}>-</span>
                      }
                    </div>
                    {
                      isUserAdmin &&
                      <div className={GlobalStyles.detailsRow}>
                        <Typography style={{ color: theme.colors.JOB_END_DATE_COLOR }}>
                          <Moment interval={0} calendar={calendarStrings} >
                            {formatTimeWithTZ(subTask.endDate)}
                          </Moment>
                          <Moment interval={0} format={TIME_FORMAT}>
                            {formatTimeWithTZ(subTask.endTime)}
                          </Moment>
                        </Typography>
                        <Icon
                          path={mdiClockEnd}
                          size='1.2rem'
                          color={theme.colors.JOB_END_DATE_COLOR}
                        />
                      </div>
                    }
                  </div>
                </div>

                {
                  isUserAdmin &&
                  <Divider />
                }

              </Fragment>
            }

            {
              isUserAdmin &&
              <Fragment>
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => setIsCostEditable(true)}
                >
                  <Typography style={{ color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR, minWidth: 100 }}>
                    Cost
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                    <Typography style={{ display: 'flex', alignItems: 'center', color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR }}>
                      <Icon
                        path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
                        size="1rem"
                        color={theme.colors.JOB_DETAIL_DESCRIPTION_COLOR}
                      />
                      {subTask.rate}
                    </Typography>
                    {
                      subTask.rateType ?
                        <ChipCNO
                          icon={subTask.rateType === RateType.Hourly ? mdiHours24 : mdiLockClock}
                          name={RateTypeReverse[subTask.rateType]}
                          active={true}
                        /> : null
                    }
                  </div>
                </div>

                <Divider />
              </Fragment>
            }

            {
              isUserAdmin &&
              <Fragment>
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography style={{ color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR, minWidth: 100 }}>
                    Amount
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                    <Typography style={{ display: 'flex', alignItems: 'center', color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR }}>
                      <Icon
                        path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
                        size="1rem"
                        color={theme.colors.JOB_DETAIL_DESCRIPTION_COLOR}
                      />
                      {parseDisplayNumber(subTask.amount)}
                    </Typography>
                  </div>
                </div>

                <Divider />
              </Fragment>
            }

            {
              isUserAdmin &&
              <Fragment>
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => setIsTargetEditable(true)}
                >
                  <Typography style={{ color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR, minWidth: 100 }}>
                    Target
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon
                        path={mdiBullseyeArrow}
                        size="1.2rem"
                        color={theme.colors.JOB_DETAIL_RATE_COLOR}
                        style={{ marginRight: theme.spacing(1) }}
                      />
                      <Typography style={{ color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR }}>
                        {`${subTask.target} ${JobTargetTypeUnit[subTask.targetType]}`}
                      </Typography>
                    </div>
                    {
                      subTask.targetType ?
                        <ChipCNO
                          icon={icons[getMaterialIconString(JobTargetTypeIcon[JobTargetTypeReverse[subTask.targetType]])]}
                          name={JobTargetTypeReverse[subTask.targetType]}
                          active={true}
                        /> : null
                    }
                  </div>
                </div>

              </Fragment>
            }

            {
              expanded
                ? <div style={{ marginTop: theme.spacing(2) }}>
                  <SubTaskStatusBar
                    subTask={subTask}
                    styles={{ background: theme.colors.SUBTASK_STATUS_BACKGROUND_COLOR }}
                  />
                </div>
                : null
            }

          </div>
        </AccordionDetails>
      </Accordion>

      {
        isCategoryEditable &&
        isUserAdmin &&
        <EditSubTaskCategory
          open={isCategoryEditable}
          onClose={() => setIsCategoryEditable(false)}
          subTask={subTask}
        />
      }

      {
        isTitleEditable &&
        isUserAdmin &&
        <EditSubTaskTitle
          open={isTitleEditable}
          onClose={() => setIsTitleEditable(false)}
          subTask={subTask}
        />
      }

      {
        isDescription &&
        isUserAdmin &&
        <EditSubTaskDescription
          open={isDescription}
          onClose={() => setIsDescription(false)}
          subTask={subTask}
        />
      }

      {
        isAssignEditable &&
        isUserAdmin &&
        <EditSubTaskAssignUsers
          open={isAssignEditable}
          onClose={() => setIsAssignEditable(false)}
          subTask={subTask}
        />
      }

      {
        isTimlineEditable &&
        isUserAdmin &&
        <EditSubTaskTimeline
          open={isTimlineEditable}
          onClose={() => setIsTimlineEditable(false)}
          subTask={subTask}
        />
      }

      {
        isCostEditable &&
        isUserAdmin &&
        <EditSubTaskCost
          open={isCostEditable}
          onClose={() => setIsCostEditable(false)}
          subTask={subTask}
        />
      }

      {
        isTargetEditable &&
        isUserAdmin &&
        <EditSubTaskTarget
          open={isTargetEditable}
          onClose={() => setIsTargetEditable(false)}
          subTask={subTask}
        />
      }
    </Fragment>

  )
}

export default SubTaskDetailsListItem
