import moment from "moment";
import {
  getCompanyMap,
  getCustomerMap,
} from ".";
import { generateQuotationFixedTemplate } from "../../pdfTemplates/templates";
import { getTotalAmountValues } from "../InvoiceHelpers";
import LocalStorageConstants from "../LocalStorageConstants";
import { Currency, CurrencyHTMLEntities, RateType } from "../mappings";

export const getHourlyQuotationItemsMap = (quotationItems) => {

  let quotationItemsMap = [];

  let subTotal = 0;

  if (quotationItems && quotationItems.length) {
    quotationItemsMap = quotationItems.map((item) => ({
      title: item.title,
      description: item.description,
      rate: item.rate,
      rateType: item.rateType,
      hours: item.hours,
      amount: parseInt(item.rate * item.hours)
    }));

    subTotal = quotationItems.reduce((total, item) => {
      total += item.rate * item.hours;
      return total;
    }, 0);
  }

  return ({
    quotationItemsMap: quotationItemsMap,
    subTotal: subTotal
  });

};

export const getFixedQuotationItemsMap = (quotationItems) => {

  let quotationItemsMap = [];
  let subTotal = 0;

  if (quotationItems && quotationItems.length) {

    quotationItemsMap = quotationItems.map((item) => ({
      title: item.title,
      description: item.description,
      rate: item.rate,
      rateType: item.rateType,
      amount: item.rate
    }));

    subTotal = quotationItems.reduce((total, item) => {
      const amount = item.rateType === RateType.Hourly ? item.rate * item.hours : item.rate;
      total += amount;
      return total;
    }, 0);
  }

  return ({
    quotationItemsMap: quotationItemsMap,
    subTotal: subTotal
  });

};

export const getQuotationItemsMap = (quotationItems, rateType) => {

  let quotationItemsMap = [];
  let subTotal = 0;

  if (quotationItems && quotationItems.length) {
    quotationItemsMap = quotationItems.map((item) => ({
      title: item.title,
      description: item.description,
      rate: item.rate,
      rateType: item.rateType,
      hours: item.hours,
      amount: item.rateType === RateType.Hourly ? Math.round(item.rate * item.hours) : item.rate
    }));

    subTotal = quotationItems.reduce((total, item) => {
      total += item.amount;
      return total;
    }, 0);
  }

  return ({
    quotationItemsMap: quotationItemsMap,
    subTotal: subTotal
  });

};

export const getQuotationMap = (quotation) => {

  const {
    totalAmount,
    amount,
    amountAfterDiscount,
    discount,
    discountRate,
    taxableAmount,
    tax,
    taxRate
  } = getTotalAmountValues(quotation.rate, quotation.discount, quotation.taxRate, quotation.includeTax);

  const validTillDate = moment(quotation.offerDate).add(quotation.dueInDays, 'days').format('MMM DD, YYYY');
  const quotationMap = {
    offerNumber: quotation.offerNumber,
    offerDate: moment(quotation.offerDate).format('MMM DD, YYYY'),
    validTillDate: validTillDate,
    // billingMode: ItemsRateTypeReverse[rateType],
    startDate: moment(quotation.startDate).format('MMM DD, YYYY'),
    endDate: moment(quotation.endDate).format('MMM DD, YYYY'),
    title: quotation.title,
    description: quotation.description,
    notes: quotation.notes,

    includeTax: quotation.includeTax,

    totalAmount,
    amount,
    amountAfterDiscount,
    discount,
    discountRate,
    taxableAmount,
    tax,
    taxRate
  };

  return quotationMap;

};

export const generateQuotationTemplate = async (quotation, quotationItems, customerById, companyById, companyProfilePic) => {

  let templateString = "";

  // const rateType = getRateType(quotationItems);

  const rateType = RateType.Fixed;

  const quotationItemsMap = quotationItems;
  const quotationMap = getQuotationMap(quotation);
  const companyMap = await getCompanyMap(companyById, companyProfilePic);
  const customerMap = getCustomerMap(customerById);

  templateString = generateQuotationFixedTemplate(companyMap, customerMap, quotationMap, quotationItemsMap);

  return templateString;

};

