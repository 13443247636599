import { IconButton, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@material-ui/core';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import ConfirmationDialogCNO from '../../library/ConfirmationDialogCNO';
import { useDispatch } from 'react-redux';
import { deleteSubTask } from '../../actions/subTaskActions';

function SubTaskMoreActions({ subTask }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();

  const [isSubTaskDeleteConfirmDialog, setIsSubTaskDeleteConfirmDialog] = useState(false);

  const handleDeleteSubTask = () => {

    dispatch(deleteSubTask(subTask));

  };

  return (
    <div className={GlobalStyles.container_MorePopover}>
      <List className={GlobalStyles.list}>

        <ListItem className={GlobalStyles.listItem} onClick={() => setIsSubTaskDeleteConfirmDialog(true)} >
          <ListItemText primary='Delete' />
          <ListItemIcon>
            <IconButton edge='end'>
              <Icon path={mdiDelete} size='1.5rem'
                color={theme.colors.ERROR_COLOR}
              />
            </IconButton>
          </ListItemIcon>
        </ListItem>

      </List>

      {
        isSubTaskDeleteConfirmDialog &&
        <ConfirmationDialogCNO
          open={isSubTaskDeleteConfirmDialog}
          onClose={() => setIsSubTaskDeleteConfirmDialog(false)}
          dialogTitle="Delete Sub Task"
          messages={["Are you sure you want to to delete?"]}
          rightText="Delete"
          yesClick={handleDeleteSubTask}
        />
      }

    </div>
  )
}

export default SubTaskMoreActions
