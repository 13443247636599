import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme
} from '@material-ui/core';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { useCustomerEngagemenStyles } from './CustomerEngagementDetailsCard';
import clsx from 'clsx';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setInvoiceSearchParams, setInvoices, handleInvoiceSearch } from '../../actions/invoiceActions';
import InvoiceListing from '../../pages/InvoiceManagement/InvoiceListing';
import { mdiChevronDown, mdiFileTable } from '@mdi/js';

function CustomerInvoicesSection({ profile }) {

  const theme = useTheme();
  const styles = useCustomerEngagemenStyles();
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { invoices, allInvoiceLoader } = useSelector(state => state.invoices);
  const { invoicesLoader } = allInvoiceLoader;

  const [isSectionExpanded, setIsSectionExpanded] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    return () => {
      setIsSectionExpanded(false);
    };
  }, []);

  return (
    <div style={{ padding: 0 }}>
      <Accordion
        expanded={isSectionExpanded}
        className={clsx(GlobalStyles.accordion, styles.accordion)}
      >
        <AccordionSummary
          expandIcon={<Icon
            path={mdiChevronDown}
            size="1.2rem"
            color={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR} />
          }
          className={GlobalStyles.accordionSummary}
          onClick={async (e) => {
            e.stopPropagation();

            const isExpanded = !isSectionExpanded;

            setIsSectionExpanded(isExpanded);

            if (isExpanded && !invoices.length) {

              const searchParams = {
                customer: { id: profile.id },
                categories: [],
                invoice: null,
                invoiceNumber: "",
                billDate: null,
                dueInDays: null,
                statuses: [],
                paymentByCustomerStatuses: [],
                isToday: false
              };

              dispatch(handleInvoiceSearch(searchParams));

            }

          }}
        >
          <Icon path={mdiFileTable} size='1.3rem' className={styles.accordionTitleIcon} />
          <Typography>Invoices</Typography>
        </AccordionSummary>

        {
          isSectionExpanded ? (
            <AccordionDetails className={styles.accordionDetails}>
              <div className={styles.grid}>
                {
                  invoices.length
                    ? <div className={styles.listingWrapper} >
                      <InvoiceListing customer={profile} page={page} setPage={setPage} />
                    </div>
                    : <Typography className={styles.loader}>
                      {invoicesLoader ? "Loading..." : 'No invoices found'}
                    </Typography>
                }
              </div>
            </AccordionDetails>
          ) : (null)
        }

      </Accordion>
    </div>
  );
}

export default CustomerInvoicesSection;
