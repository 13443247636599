import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container_CompanyContactDetailsCardStyles: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    background: theme.colors.PROFILE_CARD_BACKGROUND_COLOR,
    padding: ({ tillTablet }) => tillTablet ? 12 : theme.spacing(4),
    borderRadius: 20
  },
  title_wrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.colors.PROFILE_CARD_TITLE_COLOR
  },
  title_icon: {
    color: theme.colors.PROFILE_CARD_BACKGROUND_COLOR,
  },
  row: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    color: theme.colors.PROFILE_TEXT_INFO_COLOR,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  icon: {
    color: theme.colors.PROFILE_CARD_ICON_COLOR,
    marginRight: theme.spacing(2)
  },
  mobile: {
    '& input': {
      padding: '10px 5px',
      border: 'none',
      borderRadius: '5px'
    }
  }
}));