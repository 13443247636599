import { Box, Button, makeStyles } from '@material-ui/core'
import React from 'react'
import FormActionButtons from '../../../components/FormActionButtons'
import { toggleAddPostListDialog } from '../../../actions/postListActions'
import { useDispatch } from 'react-redux'
const AddPostListActions = ({  onAddPost}) => {

  console.log("AddPostListActions")
  const styles = useStyles();
  const dispatch=useDispatch();

  const closeDialog = () => {
    dispatch(toggleAddPostListDialog(false));
  };

  return (

    <Box className={styles.container}>

      <Box>

        <FormActionButtons
          rightText='Next'
          rightClick={onAddPost}
          wrapperStyles={{ marginTop: 0 }}
          closeMoreActions={closeDialog}
          isLeftButton={false}
        />

      </Box>
    </Box>

  )
}

export default AddPostListActions;

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: '0.5rem 0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.SCREEN_BG_COLOR,
    borderRadius: 8
  },
  
}))