import {
  fade,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import Icon from '@mdi/react';
import React, { Fragment, useState } from 'react';
import { RateType } from '../../utils/mappings';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import JobTypeIcon from '../JobTypeIcon';
import * as icons from '@mdi/js';
import InputCNO from '../../library/InputCNO';
import { getHoursFromTimeline, parseNumber } from '../../utils/Helpers';
import { AddInvoiceItemDialog } from '../InvoiceItem';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import ResponsiveLinesCNO from '../../library/ResponsiveLinesCNO/ResponsiveLinesCNO';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';
import EditAddInvoiceItemTitle from './EditAddInvoiceItemTitle';
import EditAddInvoiceItemDescription from './EditAddInvoiceItemDescription';
import moment from 'moment';

function AddInvoiceJobItems({
  formik,
  selectedSubTasks,
  setSelectedSubTasks,
}) {

  const { subTasks } = formik.values;

  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  console.log("======NAddInvoiceJobItems==selectedSubTasks", selectedSubTasks);
  console.log("======NAddInvoiceJobItems==subTasks", subTasks);

  const {
    isAddInvoiceItemDialog,
  } = useSelector(state => state.invoiceItems);

  const [isTitleEditable, setIsTitleEditable] = useState(false);
  const [isDescription, setIsDescription] = useState(false);

  const handleInvoiceCalculation = (value, key, subTaskId) => {

    let hours = key === 'hours' ? value : formik.values[`hours-${subTaskId}`];
    const startDate = key === 'startDate' ? value : formik.values[`startDate-${subTaskId}`];
    const endDate = key === 'endDate' ? value : formik.values[`endDate-${subTaskId}`];
    const rate = key === 'rate' ? value : formik.values[`rate-${subTaskId}`];
    const rateType = formik.values[`rateType-${subTaskId}`];
    let amount = key === 'amount' ? value : formik.values[`amount-${subTaskId}`];
    // const taxRate = key === 'taxRate' ? value : formik.values[`taxRate-${subTaskId}`];

    if (key === 'startDate' || key === 'endDate') {
      hours = parseFloat(getHoursFromTimeline(startDate, endDate));
    }

    if (key !== 'amount') {
      console.log("handleInvoiceCalculation: amount", { rateType, hours, rate });
      amount = rateType === RateType.Hourly ? (parseNumber(hours) * parseNumber(rate)) : parseNumber(rate);
      console.log("handleInvoiceCalculation: amount", amount);
    }

    console.log("handleInvoiceCalculation: ", key, hours, amount);

    formik.setFieldValue(`hours-${subTaskId}`, hours);

    formik.setFieldValue(`startDate-${subTaskId}`, new Date(startDate).toISOString());
    formik.setFieldValue(`endDate-${subTaskId}`, new Date(endDate).toISOString());
    // formik.setFieldValue(`rate-${subTaskId}`, amount);
    // formik.setFieldValue(`taxRate-${subTaskId}`, `${taxRate}`);
    // formik.setFieldValue(`taxAmount-${subTaskId}`, `${taxAmount}`);
    formik.setFieldValue(`amount-${subTaskId}`, !isNaN(amount) ? amount : "");

  };

  console.log("======AddinvoiceItems=======isAddInvoiceItemDialog==========", isAddInvoiceItemDialog);

  return (
    <div className={styles.container_AddInvoiceJobItems}>

      {
        isAddInvoiceItemDialog &&
        <AddInvoiceItemDialog
          invoice={formik.values.task}
          mode="addInvoice"
          onInvoiceItemSubmit={(invoiceItem) => {
            console.log("invoiceItem==>", invoiceItem);

            const subTask = {
              id: v4(),
              categories: invoiceItem.categories,
              title: invoiceItem.title,
              description: invoiceItem.description,
              startDate: invoiceItem.startDate? moment(invoiceItem.startDate).toISOString(): null,
              endDate: invoiceItem.endDate? moment(invoiceItem.endDate).toISOString(): null,
              rate: invoiceItem.rate,
              rateType: invoiceItem.rateType,
            };

            const hours = getHoursFromTimeline(subTask.startDate, subTask.endDate);
            const amount = subTask.rateType === RateType.Hourly ? hours * subTask.rate
              : subTask.rate;

            formik.setValues({
              ...formik.values,
              [`title-${subTask.id}`]: subTask.title,
              [`description-${subTask.id}`]: subTask.description,
              [`startDate-${subTask.id}`]: subTask.startDate,
              [`endDate-${subTask.id}`]: subTask.endDate,
              [`hours-${subTask.id}`]: hours,
              [`rate-${subTask.id}`]: subTask.rate,
              [`rateType-${subTask.id}`]: subTask.rateType,
              [`amount-${subTask.id}`]: amount,
              'subTasks': [subTask, ...formik.values.subTasks]
            });

            setSelectedSubTasks(prevSubTasks => [subTask.id, ...prevSubTasks]);

          }}
        />
      }

      {
        subTasks && subTasks.length ? (
          subTasks.map((subTask) => (
            <div
              className={styles.listCardWrapper}
              style={{
                border: selectedSubTasks.includes(subTask.id) ? `2px solid ${theme.colors.BORDER_COLOR}` : 0
              }}
            >
              <div style={{ flex: 1, display: 'flex' }}>
                <JobTypeIcon
                  iconLink={subTask.categories[0].iconLink}
                  name={subTask.categories[0].title}
                  categoryId={subTask.categories[0].id}
                />
                <div style={{ flex: 1, marginLeft: 8 }}>
                  <Typography
                    style={{ color: theme.colors.JOB_DETAIL_TITLE_COLOR, fontSize: '1rem' }}
                    onClick={() => setIsTitleEditable(subTask.id)}
                  >
                    {formik.values[`title-${subTask.id}`]}
                  </Typography>

                  <ResponsiveLinesCNO
                    text={formik.values[`description-${subTask.id}`] || 'Invoice Item details not added'}
                    maxLine={2}
                    style={{ flex: 1, color: theme.colors.JOB_DETAIL_DESCRIPTION_COLOR, fontSize: 12 }}
                    component={'pre'}
                    onClick={() => setIsDescription(subTask.id)}
                    showMoreDetails={true}
                  />

                </div>
                <Icon
                  path={icons[selectedSubTasks.includes(subTask.id) ? 'mdiCheckBold' : 'mdiCloseThick']}
                  size='1.5rem'
                  color={selectedSubTasks.includes(subTask.id) ? theme.colors.JOB_END_DATE_COLOR : theme.colors.JOB_START_DATE_COLOR}
                  onClick={() => {
                    let ids = selectedSubTasks;
                    if (selectedSubTasks.includes(subTask.id)) {
                      ids = ids.filter(id => id !== subTask.id);
                    } else {
                      ids = [...ids, subTask.id];
                    }
                    setSelectedSubTasks(ids);
                  }}
                />
              </div>

              <div>
                <Fragment>
                  <div className={styles.row} style={{ justifyContent: 'space-between' }}>

                    <DateTimePickerCNO
                      datePickerLabel='Start by Date'
                      dateName={`startDate-${subTask.id}`}
                      formik={formik}
                      datePlaceholder={'Start Date'}
                      onDateChange={date => handleInvoiceCalculation(date, 'startDate', subTask.id)}
                      disablePast={true}
                      dateDisabled={!selectedSubTasks.includes(subTask.id)}
                      iconColor={'red'}
                    />

                    <DateTimePickerCNO
                      datePickerLabel='Finish by Date'
                      dateName={`endDate-${subTask.id}`}
                      formik={formik}
                      datePlaceholder={'Finish Date'}
                      onDateChange={date => handleInvoiceCalculation(date, 'endDate', subTask.id)}
                      disablePast={true}
                      dateDisabled={!selectedSubTasks.includes(subTask.id)}
                      iconColor={'green'}
                      minDate={formik.values[`startDate-${subTask.id}`]}
                    />

                  </div>
                </Fragment>

                <div className={styles.row} style={{ justifyContent: 'space-between' }}>

                  {
                    subTask.rateType === RateType.Hourly &&
                    <InputCNO
                      name={`hours-${subTask.id}`}
                      label='Hours'
                      formik={formik}
                      onChange={e => {
                        const hours = e.target.value;
                        handleInvoiceCalculation(hours, 'hours', subTask.id)
                      }}
                      placeholder='Hours'
                      icon={icons.mdiFormatText}
                      fullWidth
                      disabled={!selectedSubTasks.includes(subTask.id)}
                    />
                  }

                  <InputCNO
                    name={`amount-${subTask.id}`}
                    label={`Amount (${subTask.rateType === RateType.Hourly ? 'Hourly' : 'Fixed'})`}
                    formik={formik}
                    onChange={e => {
                      const amount = e.target.value;
                      handleInvoiceCalculation(amount, 'amount', subTask.id)
                    }}
                    placeholder='Amount'
                    icon={icons.mdiFormatText}
                    fullWidth
                    containerStyles={{ flex: subTask.rateType === RateType.Fixed && 1 }}
                    disabled={!selectedSubTasks.includes(subTask.id)}
                  />
                </div>

              </div>

              {
                isTitleEditable === subTask.id &&
                selectedSubTasks.includes(subTask.id) &&
                <EditAddInvoiceItemTitle
                  open={isTitleEditable === subTask.id}
                  onClose={() => setIsTitleEditable(null)}
                  value={formik.values[`title-${subTask.id}`]}
                  onSave={(title) => formik.setFieldValue(`title-${subTask.id}`, title)}
                />
              }

              {
                isDescription === subTask.id &&
                selectedSubTasks.includes(subTask.id) &&
                <EditAddInvoiceItemDescription
                  open={isDescription === subTask.id}
                  onClose={() => setIsDescription(null)}
                  value={formik.values[`description-${subTask.id}`]}
                  onSave={(description) => formik.setFieldValue(`description-${subTask.id}`, description)}
                />
              }

            </div>
          ))
        ) : "No invoice item found"
      }


    </div>
  )
}

export default AddInvoiceJobItems;

const useStyles = makeStyles((theme) => ({
  container_AddInvoiceJobItems: {
    maxHeight: 300,
    overflowY: 'auto',
    marginTop: 2,
  },

  listCardWrapper: {
    background: theme.colors.SUBTASK_CARD_BACKGROUND_COLOR,
    marginTop: 10,
    padding: '16px',
    borderRadius: 5,
    marginRight: 5
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 4,
    paddingBottom: 4,
    flex: 1
  },

  pickerDisabled: {
    opacity: 0.8,
    color: fade(theme.colors.INPUT_TEXT_COLOR, 0.8),
  }

}))
