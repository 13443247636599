import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container_UserProfile: {
    paddingLeft: 8,
    paddingRight: 8,
    background: theme.colors.APP_BACKGROUND_COLOR,
    '&>*': {
      marginTop: theme.spacing(2),
      padding: ({ tillTablet }) => tillTablet ? 12 : theme.spacing(4)
    }
  }
}));