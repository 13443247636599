import {
  mdiArrowRight,
  mdiCheck,
  mdiClose,
  mdiFileDocument,
  mdiSend,
  mdiSquareEditOutline
} from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import CardStatusCNO from '../../library/CardStatusCNO/CardStatusCNO';
import { AppointmentStatus } from '../../utils/mappings';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';

function AppointmentStatusButtonBar({ appointment }) {

  const GlobalStyles = useGlobalStyles();

  return (
    <div className={GlobalStyles.cardStatusBarContainer}>

      <CardStatusCNO
        title="Scheduled"
        icon={mdiSquareEditOutline}
        isActive={appointment.status === AppointmentStatus.Draft}
        activeBorderColor={'#ffb366'}
      />

      <Icon path={mdiArrowRight} size='1.2rem' />

      <CardStatusCNO
        title="In Progress"
        icon={mdiFileDocument}
        isActive={appointment.status === AppointmentStatus.Ready}
        activeBorderColor={'#ff8000'}
      />

      <Icon path={mdiArrowRight} size='1.2rem' />

      <CardStatusCNO
        title="Completed"
        icon={mdiSend}
        isActive={(appointment.status === AppointmentStatus.Sent) || (appointment.status === AppointmentStatus.SentAccepted) || (appointment.status === AppointmentStatus.SentRejected)}
        activeBorderColor={'#00cc00'}
        hasTopRightIcon={true}
        topRightIcon={
          appointment.status === AppointmentStatus.SentRejected ? mdiClose : mdiCheck
        }
        isTopRightActive={(appointment.status === AppointmentStatus.SentAccepted) || (appointment.status === AppointmentStatus.SentRejected)}
        topRightActiveBgColor={appointment.status === AppointmentStatus.SentAccepted ? "#2bb673"
          : appointment.status === AppointmentStatus.SentRejected && "#ef5647"}
        topRightActiveBorderColor={appointment.status === AppointmentStatus.SentAccepted ? "#2bb673"
          : appointment.status === AppointmentStatus.SentRejected && "#ef5647"}
        topRightActiveColor={"#cecece"}
      />

    </div>
  )
}

export default AppointmentStatusButtonBar
