import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";

import {
  Modal,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Checkbox,
} from "@material-ui/core";

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@material-ui/icons";
import { color, fontWeight, marginLeft, marginTop } from "styled-system";
import LeftSidebar from "../../components/LeftSidebar";

const AdminPanel = () => {
  const [allTests, setAllTests] = useState([]);
  const [allAnswers, setAllAnswers] = useState({});
  const [newTest, setNewTest] = useState({
    title: "",
    description: "",
    duration_minutes: "",
  });
  const [currentTestId, setCurrentTestId] = useState(null);
  const [newQuestion, setNewQuestion] = useState({
    type: "",
    question: "",
    options: [],
    imageUrl: "",
    videoUrl: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuestionsModalOpen, setIsQuestionsModalOpen] = useState(false);
  //const [editTestIndex, setEditTestIndex] = useState(null);
  const [editQuestionId, setEditQuestionId] = useState(null);
  const classes = useStyles();

  const [searchQuery, setSearchQuery] = useState("");
  const [openRow, setOpenRow] = useState({});

  const handleRowClick = (testId) => {
    setOpenRow((prev) => ({
      ...prev,
      [testId]: !prev[testId],
    }));
  };

  useEffect(() => {
    const fetchAndStoreInitialTests = async () => {
      try {
        const answers = await fetch("/data/answers.json").then((res) =>
          res.json()
        );
        const allTests = await fetch("/data/tests.json").then((res) =>
          res.json()
        );

        localStorage.setItem("allTests", JSON.stringify(allTests));
        localStorage.setItem("allAnswers", JSON.stringify(answers));
      } catch (error) {
        console.error("Error fetching or storing tests:", error);
      }
    };

    fetchAndStoreInitialTests();
  }, []);

  useEffect(() => {
    const storedTests = JSON.parse(localStorage.getItem("allTests"));
    const storedAnswers = JSON.parse(localStorage.getItem("allAnswers")) || {
      answers: [],
    };

    if (storedTests && storedTests.test) {
      setAllTests(storedTests.test);
    }
    if (storedAnswers && storedAnswers.answers) {
      setAllAnswers(storedAnswers.answers);
    }
  }, []);

  const saveAllTests = (updatedAllTests) => {
    console.log("Saving allTests:", updatedAllTests);
    setAllTests(updatedAllTests);
    localStorage.setItem("allTests", JSON.stringify({ test: updatedAllTests }));
  };

  const saveAllAnswers = (updatedAllAnswers) => {
    console.log("Saving allAnswers:", updatedAllAnswers);
    setAllAnswers(updatedAllAnswers);
    localStorage.setItem(
      "allAnswers",
      JSON.stringify({ answers: updatedAllAnswers })
    );
  };

  const handleDeleteTest = (testId) => {
    const updatedTests = allTests.filter((test) => test.testId !== testId);
    const updatedAnswers = allAnswers.filter(
      (answer) => answer.testId !== testId
    );

    saveAllTests(updatedTests);
    saveAllAnswers(updatedAnswers);
  };

  const handleDeleteQuestion = (testId, questionId) => {
    const updatedTests = allTests.map((test) => {
      if (test.testId === testId) {
        return {
          ...test,
          questions: test.questions.filter(
            (question) => question.id !== questionId
          ),
        };
      }
      return test;
    });

    const updatedAnswers = allAnswers.map((answer) => {
      if (answer.testId === testId) {
        return {
          ...answer,
          questions: answer.questions.filter(
            (q) => q.questionId !== questionId
          ),
        };
      }
      return answer;
    });

    saveAllTests(updatedTests);
    saveAllAnswers(updatedAnswers);
  };

  const saveQuestion = () => {
    if (!newQuestion.type || !newQuestion.question) {
      alert("Please fill in question type and question.");
      return;
    }

    const testId = currentTestId;
    const testIndex = allTests.findIndex((test) => test.testId === testId);

    if (testIndex === -1) {
      alert("Test not found.");
      return;
    }

    const updatedAllTests = [...allTests];
    const updatedAllAnswers = [...allAnswers];

    if (editQuestionId !== null) {
      // Update existing question
      const questionIndex = updatedAllTests[testIndex].questions.findIndex(
        (q) => q.id === editQuestionId
      );
      if (questionIndex !== -1) {
        updatedAllTests[testIndex].questions[questionIndex] = {
          id: editQuestionId,
          ...newQuestion,
        };

        // Update the answer
        const testAnswersIndex = updatedAllAnswers.findIndex(
          (answer) => answer.testId === testId
        );
        if (testAnswersIndex !== -1) {
          const questionAnswerIndex = updatedAllAnswers[
            testAnswersIndex
          ].questions.findIndex((q) => q.questionId === editQuestionId);
          if (questionAnswerIndex !== -1) {
            updatedAllAnswers[testAnswersIndex].questions[
              questionAnswerIndex
            ].answer = newQuestion.answer;
          } else {
            // If the answer for this question doesn't exist yet, add it
            updatedAllAnswers[testAnswersIndex].questions.push({
              questionId: editQuestionId,
              answer: newQuestion.answer,
            });
          }
        } else {
          // If the answer for this test doesn't exist yet, create it along with the answer for this question
          updatedAllAnswers.push({
            testId: testId,
            questions: [
              { questionId: editQuestionId, answer: newQuestion.answer },
            ],
          });
        }
      }
    } else {
      // Add new question
      const nextQuestionId =
        updatedAllTests[testIndex].questions.length > 0
          ? Math.max(...updatedAllTests[testIndex].questions.map((q) => q.id)) +
            1
          : 1;
      const newQuestionData = { id: nextQuestionId, ...newQuestion };
      updatedAllTests[testIndex].questions.push(newQuestionData);

      // Add the answer
      const testAnswersIndex = updatedAllAnswers.findIndex(
        (answer) => answer.testId === testId
      );
      if (testAnswersIndex !== -1) {
        updatedAllAnswers[testAnswersIndex].questions.push({
          questionId: nextQuestionId,
          answer: newQuestion.answer,
        });
      } else {
        updatedAllAnswers.push({
          testId: testId,
          questions: [
            { questionId: nextQuestionId, answer: newQuestion.answer },
          ],
        });
      }
    }

    saveAllTests(updatedAllTests);
    saveAllAnswers(updatedAllAnswers);
    setIsQuestionsModalOpen(false);
    setEditQuestionId(null);
  };

  const filteredTests = allTests.filter((test) =>
    test.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className={classes.container_AdminTest}>
      {/* <LeftSidebar /> */}
      <Button
        variant="contained"
        onClick={() => {
          setCurrentTestId(null);
          setNewTest({ title: "", description: "", duration_minutes: "" });
          setIsModalOpen(true);
        }}
      >
        Add Test
      </Button>
      {/* <LeftSidebar /> */}

      <TextField
        label="Search by Title"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
        margin="normal"
      />

      <h3>Existing Tests</h3>
      <hr />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell />
              <TableCell>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Title
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Description
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Duration(minutes)
                </Typography>{" "}
              </TableCell>
              <TableCell>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {allTests && allTests.length > 0 ? (
                            allTests.map((test) => ( */}
            {filteredTests && filteredTests.length > 0 ? (
              filteredTests.map((test) => (
                <React.Fragment key={test.testId}>
                  <TableRow
                  // sx={{ backgroundColor: 'lightblue', color: 'yellowgreen', '&:hover': { backgroundColor: 'lightgray' } }}
                  >
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleRowClick(test.testId)}
                      >
                        {openRow[test.testId] ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <h3 style={{ color: "#9BBE00" }}>{test.title}</h3>
                    </TableCell>
                    <TableCell>
                      <h3 style={{ color: "#9BBE00" }}>{test.description}</h3>
                    </TableCell>
                    <TableCell>
                      <h3 style={{ color: "#9BBE00" }}>
                        {test.duration_minutes}
                      </h3>
                    </TableCell>
                    <TableCell>
                      <Button
                        style={{ marginLeft: "4px" }}
                        variant="text"
                        onClick={() => {
                          setCurrentTestId(test.testId);
                          setNewTest({
                            title: test.title,
                            description: test.description,
                            duration_minutes: test.duration_minutes,
                          });
                          setIsModalOpen(true);
                        }}
                      >
                        Edit Test
                      </Button>
                      <Button
                        style={{ marginLeft: "4px" }}
                        variant="text"
                        onClick={() => handleDeleteTest(test.testId)}
                      >
                        Delete Test
                      </Button>
                      <Button
                        style={{ marginLeft: "4px" }}
                        variant="text"
                        sx={{ marginLeft: 5 }}
                        onClick={() => {
                          setCurrentTestId(test.testId);
                          setNewQuestion({
                            type: "",
                            question: "",
                            options: [],
                            imageUrl: "",
                            videoUrl: "",
                          });
                          setIsQuestionsModalOpen(true);
                        }}
                      >
                        Add Question
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={openRow[test.testId]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Typography variant="h6" gutterBottom component="div">
                            Questions
                          </Typography>
                          <Table size="small" aria-label="questions">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  {" "}
                                  <Typography style={{ fontSize: "15px" }}>
                                    {" "}
                                    Question No.
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <Typography style={{ fontSize: "15px" }}>
                                    Type
                                  </Typography>{" "}
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <Typography style={{ fontSize: "15px" }}>
                                    Question
                                  </Typography>{" "}
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <Typography style={{ fontSize: "15px" }}>
                                    Options
                                  </Typography>{" "}
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <Typography style={{ fontSize: "15px" }}>
                                    Correct Option
                                  </Typography>{" "}
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <Typography style={{ fontSize: "15px" }}>
                                    Action
                                  </Typography>{" "}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {test.questions.map((question) => {
                                const testAnswers = allAnswers.find(
                                  (answer) => answer.testId === test.testId
                                );
                                const answerData = testAnswers
                                  ? testAnswers.questions
                                  : [];
                                const answer = answerData.find(
                                  (q) => q.questionId === question.id
                                );

                                return (
                                  <TableRow key={question.id}>
                                    <TableCell>{question.id}</TableCell>
                                    <TableCell>{question.type}</TableCell>
                                    <TableCell>
                                      <div>
                                        <p>{question.question}</p>
                                        {question.type === "image" && (
                                          <img
                                            src={question.imageUrl}
                                            alt="Question"
                                            style={{
                                              maxWidth: "100px",
                                              maxHeight: "100px",
                                            }}
                                          />
                                        )}
                                        {question.type === "video" && (
                                          <video
                                            controls
                                            style={{
                                              maxWidth: "100px",
                                              maxHeight: "100px",
                                            }}
                                          >
                                            <source
                                              src={question.videoUrl}
                                              type="video/mp4"
                                            />
                                            Your browser does not support the
                                            video tag.
                                          </video>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      {question.options &&
                                      question.options.length > 0 ? (
                                        <ul>
                                          {question.options.map(
                                            (option, index) => (
                                              <li key={index}>{option}</li>
                                            )
                                          )}
                                        </ul>
                                      ) : (
                                        "N/A"
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {answer
                                        ? question.type === "multiple_choice" ||
                                          question.type === "checkbox"
                                          ? Array.isArray(answer.answer)
                                            ? answer.answer
                                                .map(
                                                  (optIndex) =>
                                                    question.options[optIndex]
                                                )
                                                .join(", ")
                                            : question.options[answer.answer]
                                          : answer.answer
                                        : "NA"}
                                    </TableCell>

                                    <TableCell>
                                      <IconButton
                                        className={classes.iconButton} // Apply any custom styles here if needed
                                        onClick={() => {
                                          setCurrentTestId(test.testId);
                                          setNewQuestion({
                                            type: question.type,
                                            question: question.question,
                                            options: question.options,
                                            imageUrl: question.imageUrl,
                                            videoUrl: question.videoUrl,
                                            answer:
                                              question.type === "checkbox"
                                                ? answer.answer
                                                : answer.answer || "",
                                          });
                                          setEditQuestionId(question.id);
                                          setIsQuestionsModalOpen(true);
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton
                                        className={classes.iconButton} // Apply any custom styles here if needed
                                        onClick={() =>
                                          handleDeleteQuestion(
                                            test.testId,
                                            question.id
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>No tests available.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={classes.modalContent}>
          <h2>{currentTestId ? "Edit Test" : "Add New Test"}</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (currentTestId) {
                // Update existing test
                const updatedAllTests = allTests.map((test) =>
                  test.testId === currentTestId ? { ...test, ...newTest } : test
                );
                saveAllTests(updatedAllTests);
              } else {
                // Add new test
                const newTestId =
                  allTests.length > 0
                    ? Math.max(...allTests.map((test) => test.testId)) + 1
                    : 1;
                const newTestEntry = {
                  testId: newTestId,
                  ...newTest,
                  questions: [],
                };
                saveAllTests([...allTests, newTestEntry]);
              }
              setIsModalOpen(false);
            }}
          >
            <TextField
              label="Title"
              value={newTest.title}
              onChange={(e) =>
                setNewTest({ ...newTest, title: e.target.value })
              }
              fullWidth
              required
            />
            <TextField
              label="Description"
              value={newTest.description}
              onChange={(e) =>
                setNewTest({ ...newTest, description: e.target.value })
              }
              fullWidth
              required
            />
            <TextField
              label="Duration (minutes)"
              type="number"
              value={newTest.duration_minutes}
              onChange={(e) =>
                setNewTest({ ...newTest, duration_minutes: e.target.value })
              }
              fullWidth
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "8px" }}
            >
              Save
            </Button>
          </form>
        </div>
      </Modal>

      <Modal
        open={isQuestionsModalOpen}
        onClose={() => setIsQuestionsModalOpen(false)}
      >
        <div className={classes.modalContent}>
          <div className={classes.close}>
            <IconButton onClick={() => setIsQuestionsModalOpen(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <h2 style={{ color: "white" }}>
            {editQuestionId ? "Edit Question" : "Add New Question"}
          </h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              saveQuestion();
            }}
          >
            <FormControl fullWidth required>
              <InputLabel>Type</InputLabel>
              <Select
                value={newQuestion.type}
                onChange={(e) =>
                  setNewQuestion({ ...newQuestion, type: e.target.value })
                }
              >
                <MenuItem value="multiple_choice">Multiple Choice</MenuItem>
                <MenuItem value="checkbox">Checkbox</MenuItem>
                <MenuItem value="text_reply">Text</MenuItem>
                <MenuItem value="number_reply">Number</MenuItem>
                <MenuItem value="image">Image</MenuItem>
                <MenuItem value="video">Video</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Question"
              value={newQuestion.question}
              onChange={(e) =>
                setNewQuestion({ ...newQuestion, question: e.target.value })
              }
              fullWidth
              required
            />

            {(newQuestion.type === "multiple_choice" ||
              newQuestion.type === "checkbox") && (
              <div className={classes.optionsContainer}>
                <InputLabel>Options:</InputLabel>
                {newQuestion.options.map((option, index) => (
                  <div key={index} className={classes.optionItem}>
                    <TextField
                      value={option}
                      onChange={(e) => {
                        const updatedOptions = [...newQuestion.options];
                        updatedOptions[index] = e.target.value;
                        setNewQuestion({
                          ...newQuestion,
                          options: updatedOptions,
                        });
                      }}
                      fullWidth
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      margin="8px"
                      className={classes.removeButton}
                      onClick={() => {
                        const updatedOptions = newQuestion.options.filter(
                          (_, i) => i !== index
                        );
                        setNewQuestion({
                          ...newQuestion,
                          options: updatedOptions,
                        });
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
                <Button
                  className="addOptionButton"
                  variant="outlined"
                  size="small"
                  // color="primary"

                  onClick={() =>
                    setNewQuestion({
                      ...newQuestion,
                      options: [...newQuestion.options, ""],
                    })
                  }
                >
                  Add Option
                </Button>
                <br />
                <InputLabel>
                  {newQuestion.type === "multiple_choice"
                    ? "Correct Option"
                    : "Correct Options"}
                  :
                </InputLabel>
                {newQuestion.type === "multiple_choice" ? (
                  <Select
                    value={newQuestion.answer}
                    onChange={(e) =>
                      setNewQuestion({
                        ...newQuestion,
                        answer: parseInt(e.target.value, 10),
                      })
                    }
                  >
                    <MenuItem value="">Select Correct Option</MenuItem>
                    {newQuestion.options.map((option, index) => (
                      <MenuItem key={index} value={index}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  newQuestion.options.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={
                            newQuestion.answer
                              ? newQuestion.answer.includes(index)
                              : false
                          }
                          onChange={(e) => {
                            const updatedAnswers = e.target.checked
                              ? [...(newQuestion.answer || []), index]
                              : (newQuestion.answer || []).filter(
                                  (optIndex) => optIndex !== index
                                );
                            setNewQuestion({
                              ...newQuestion,
                              answer: updatedAnswers,
                            });
                          }}
                        />
                      }
                      label={option}
                    />
                  ))
                )}
              </div>
            )}

            {["image", "video"].includes(newQuestion.type) && (
              <>
                <TextField
                  label={
                    newQuestion.type === "image" ? "Image URL" : "Video URL"
                  }
                  fullWidth
                  value={
                    newQuestion.type === "image"
                      ? newQuestion.imageUrl
                      : newQuestion.videoUrl
                  }
                  onChange={(e) =>
                    setNewQuestion({
                      ...newQuestion,
                      imageUrl:
                        newQuestion.type === "image"
                          ? e.target.value
                          : newQuestion.imageUrl,
                      videoUrl:
                        newQuestion.type === "video"
                          ? e.target.value
                          : newQuestion.videoUrl,
                    })
                  }
                  margin="normal"
                />
                <br />
                <TextField
                  label="Correct Answer"
                  fullWidth
                  value={newQuestion.answer}
                  onChange={(e) =>
                    setNewQuestion({ ...newQuestion, answer: e.target.value })
                  }
                  margin="normal"
                />
                <br />
              </>
            )}

            {["text_reply", "number_reply"].includes(newQuestion.type) && (
              <>
                <TextField
                  label="Correct Answer"
                  fullWidth
                  type={newQuestion.type === "number_reply" ? "number" : "text"}
                  value={newQuestion.answer}
                  onChange={(e) =>
                    setNewQuestion({ ...newQuestion, answer: e.target.value })
                  }
                  margin="normal"
                />
                <br />
              </>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "12px" }}
            >
              Save
            </Button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AdminPanel;

const useStyles = makeStyles((theme) => ({
  container_AdminTest: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
    color: "white",
    padding: "16px",
    marginTop: "56px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // marginLeft: '20px'
  },

  modalContent: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    gap: "2px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
  },
  close: {
    cursor: "pointer",
    float: "right",
    fontSize: 28,
    fontWeight: "bold",
    "&:hover": {
      color: "red",
    },
  },

  removeButton: {
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    padding: "8px",
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },
  addOptionButton: {
    backgroundColor: "#ff9800",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    padding: "8px",
    margin: "8px",
    "&:hover": {
      backgroundColor: "#f57c00",
    },
  },
  optionItem: {
    // backgroundColor: '#f57c00',
    display: "flex",
    width: "100%",
    margin: "5px 0px",
    padding: "4px",
  },
  optionsContainer: {
    margin: "8px 0px",
  },

  tableHead: {
    backgroundColor: theme.palette,
    "& th": {
      color: theme.palette.common.white,
    },
    fontWeight: "bold",
    fontSize: "1.25rem",
  },
  Button: {
    margin: "5px 0px",
  },
}));
