import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuotationItem } from '../../actions/quotationItemActions';
import { useDebouncedFormik } from '../../hooks';

function EditQuotationItemDescription({
  open,
  onClose,
  quotationItem,
}) {

  const dispatch = useDispatch();
  const { editLoader } = useSelector(state => state.quotationItems);

  const formik = useFormik({
    initialValues: {
      description: quotationItem.description,
    },
    validationSchema: yup.object({
      description: yup.string()
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleClose = () => {

    formik.resetForm();

    localStorage.removeItem(`editQuotationItem_${quotationItem.id}`);

    onClose();

  };

  const handleUpdate = (values) => {
    console.log("Edit Quotation Item: description: ", values);

    const payload = {
      ...quotationItem,
      description: values.description
    };

    const onAddSuccess = () => handleClose();
    dispatch(updateQuotationItem(payload, false, onAddSuccess));

  };

  useDebouncedFormik(formik, `editQuotationItem_${quotationItem.id}`);

  return (
    <DialogCNO
      open={open}
      onClose={handleClose}
      dialogTitle="Edit Quotation Item Description"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <InputCNO
          name="description"
          label="Quotation Item Description"
          placeholder="Enter Quotation Item Description"
          icon={mdiFormatText}
          formik={formik}
          multiline
        />

        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditQuotationItemDescription
