import { Badge, CircularProgress, Dialog, Fab, InputAdornment, List, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiAccount, mdiArrowRight, mdiClose, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddToGroupChatModal from '../AddToGroupChatModal/AddToGroupChatModal';
import ChatGroupListItem from '../ChatGroupListItem/ChatGroupListItem';
import HeadingBar from '../HeadingBar/HeadingBar';
import { useStyles } from './CreateChatGroupModalStyles';
import AvatarCNO from '../../library/AvatarCNO/AvatarCNO';
import { fetchEmployees, fetchMoreEmployees } from '../../actions/employeeActions';
import { fetchMemberSuggest } from '../../actions/suggestionsActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import InfiniteScrollCNO from '../../library/InfiniteScrollCNO';

function CreateChatGroupModal({
  isCreateChatGroup,
  closeCreateChatGroup,
  alreadySelectedUsers = [],
  mode = "new",
  closeMoreActions
}) {

  const authUser = JSON.parse(localStorage.getItem('authUser'));

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const theme = useTheme();

  const [searchKey, setSearchKey] = useState();
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isAddToGroup, setIsAddToGroup] = useState();
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();
  const suggestions = useSelector(state => state.suggestions);
  const {
    employees,
    allEmployeesLoader,
    hasMoreEmployees
  } = useSelector(state => state.employees);
  const { employeesLoader } = allEmployeesLoader;

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (mode === 'new') {
      setSelectedUsers(alreadySelectedUsers);
    }
  }, []);

  useEffect(() => {

    if (searchKey) {
      console.log("==== addGroup: searching====");

      const newMembers = suggestions
        .filter(member => member.id !== authUser.id)
        .filter((member) => {
          return alreadySelectedUsers.
            map(item => item.memberId).indexOf(member.id) === -1;
        });

      console.log("==== addGroup: newMembers====", newMembers);

      const profileList = newMembers.map((user) => (
        {
          ...user,
          id: user.id,
          firstName: user.title.split(" ")[0],
          lastName: user.title.split(" ")[1],
          profilePic: user.profilePic,
          isActive: true
        }
      ));

      console.log("==== addGroup: profileList====", profileList);

      setAllUsers(profileList);

    } else {

      console.log("==== addGroup: initial list====");

      const profileList = employees
        .filter(member => member.id !== authUser.id)
        .filter((member) => {
          return alreadySelectedUsers.
            map(item => item.memberId).indexOf(member.id) === -1;
        });

      console.log("==== addGroup: profileList====", profileList);

      setAllUsers(profileList);
    }

  }, [suggestions, searchKey, employees]);

  const onClose = () => {
    setSelectedUsers([]);
    closeCreateChatGroup();
  };

  const fetchUsers = async (key = "") => {
    const searchParams = {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "newest",
      sortAscending: false,
      searchText: key,
    };
    dispatch(fetchEmployees(searchParams));
  };

  const handleUserSearch = (e) => {
    const key = e.target.value;
    setSearchKey(key);

    if (key) {
      dispatch(fetchMemberSuggest(key));
    }

  };

  const handleUserSelection = (item, index) => {
    item.isSelect = !item.isSelect;

    setSearchKey('');

    allUsers[index] = item;

    // setAllUsers(allUsers);

    let isSelected = selectedUsers.includes(item);
    if (isSelected) {
      setSelectedUsers(selectedUsers.filter(user => user.id !== item.id));
    } else {
      setSelectedUsers([item, ...selectedUsers]);
    }
  };

  const handleSelectedUserRemoval = (user) => {
    const newUsers = selectedUsers.filter(selectedUser => selectedUser.id !== user.id);
    setSelectedUsers(newUsers);
  };


  const toggleAddToGroupChatModal = (status) => {
    setIsAddToGroup(status);
  };
  console.log("loadMore: hasMoreEmployees", hasMoreEmployees);

  const loadMore = () => {
    console.log("loadMore: start");
    if (hasMoreEmployees) {
      const moreEmployeesPayload = {
        pageIndex: page + 1,
        orderBy: "newest",
        searchText: "",
      };

      dispatch(fetchMoreEmployees(moreEmployeesPayload));

      setPage(page + 1);
    }
  };

  const styles = useStyles({ tillTablet });
  return (
    <Dialog
      open={isCreateChatGroup}
      classes={{ paper: styles.paper }}
      className={styles.container_CreateChatGroupModal}
      onClose={onClose}
    >
      <form className={styles.form}>
        <HeadingBar title="iConnect New Group" onClose={onClose} />
        <TextField
          type="search"
          className={styles.textField}
          value={searchKey}
          placeholder="Type Here..."
          variant='outlined'
          margin='dense'
          onChange={handleUserSearch}
          InputProps={{
            startAdornment: <InputAdornment>
              <Icon color={theme.colors.INPUT_ICON_COLOR} path={mdiMagnify} size='1.5rem' />
            </InputAdornment>
          }}
          fullWidth
        />
        {
          selectedUsers.length > 0 &&
          <div className={styles.selectionWrapper}>
            {
              selectedUsers.map((user) => {
                return (
                  <div key={user.email} className={styles.badgeWrapper}>
                    <Badge
                      className={styles.selectedUseradge}
                      overlap="circle"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      badgeContent={<Icon
                        path={mdiClose}
                        size="1rem"
                        className={styles.selectedUserCross}
                      />}
                      onClick={() => handleSelectedUserRemoval(user)}
                    >
                      <AvatarCNO
                        src={user.profilePic}
                        fallbackIcon={mdiAccount}
                        avatarStyle={{ border: `2px solid ${theme.colors.AVATARCNO_BORDER_COLOR}` }}
                      />
                    </Badge>
                    <div className={styles.badgeUsername}>{user.firstName}</div>
                  </div>

                )
              })
            }
          </div>
        }
        <div id="scrollableDiv" style={{ height: '550px', overflow: "auto" }} className={styles.suggestionsWrapper}>

          <InfiniteScrollCNO
            scrollableTarget="scrollableDiv"
            items={allUsers}
            next={loadMore}
            hasMore={hasMoreEmployees}
            loading={employeesLoader}
            renderListItem={(user, index) => (
              <ChatGroupListItem
                user={user}
                key={user.email}
                index={index}
                handleUserSelection={handleUserSelection}
                selectedUsers={selectedUsers}
              />
            )}
            notFoundTextMessage="No member found."
            renderLoader={<div style={{ textAlign: 'center' }}><CircularProgress /></div>}
          />

        </div>
        <div className={styles.fabContainer}>
          {
            selectedUsers.length > 0 &&
            <Fab
              color="primary"
              className={styles.fab}
              onClick={() => toggleAddToGroupChatModal(true)}
            >
              <Icon
                path={mdiArrowRight}
                size="1.5rem"
              />
            </Fab>
          }
        </div>
      </form>
      {
        isAddToGroup &&
        <AddToGroupChatModal
          open={isAddToGroup}
          onClose={() => toggleAddToGroupChatModal(false)}
          selectedUsers={selectedUsers}
          closeCreateChatGroup={onClose}
          mode={mode}
          closeMoreActions={closeMoreActions}
        />
      }
    </Dialog>
  )
}

export default CreateChatGroupModal
