import { Typography, useTheme } from '@material-ui/core';
import { mdiEmail, mdiWhatsapp } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import LandingPageWrapper from '../LandingPageWrapper';
import { usePublicPagesStyles } from '../../../utils/Styles/PublicPages.style';
import { ThemeType } from '../../../utils/mappings';
import darkColors from '../../../common/theme/LandingPage/darkThemeColors';
import colors from '../../../common/theme/LandingPage/colors';

function ContactUs() {

  const theme = useTheme();
  const styles = usePublicPagesStyles();

  const isDarkTheme = useTheme().palette.type === ThemeType.Dark;

  const fontSize = '1.1rem';
  const textColor = isDarkTheme ? darkColors.textColor : colors.textColor;
  const iconSize = '1.6rem';
  const iconMargin = '1rem';

  const iconWrapper = {
    background: "rgb(26, 115, 232)",
    borderRadius: '50%',
    width: '2.5rem',
    height: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: iconMargin
  };

  const row = {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4em',
    boxShadow: `0px 7px 25px 0px ${isDarkTheme ? darkColors.exploreLabelShadow : colors.exploreLabelShadow}`,
    padding: '10px 15px',
    background: isDarkTheme ? "#2e2e2e" : "#fefefe"
  };

  return (
    <LandingPageWrapper pageTitle={"Contact Us"}>

      <Typography variant='h4' align='center' className={styles.pageTitle}>
        Contact Us
      </Typography>

      <div className={styles.contactItemWrapper}>

        <div style={row}>

          <Typography
            component={'a'}
            href="mailto:contact@checkndout.com"
            className={styles.contactItem}
            style={{
              fontSize: fontSize,
              color: textColor
            }}
          >

            <div style={iconWrapper}>
              <Icon
                path={mdiEmail}
                size={iconSize}
                style={{ color: '#fff' }}
              />
            </div>
            <span>Send an Email</span>
          </Typography>
        </div>

        <div style={row}>

          <Typography
            component={'a'}
            href="https://wa.me/+447551576623"
            targer="_blank"
            className={styles.contactItem}
            style={{
              fontSize: fontSize,
              color: textColor,
            }}
          >

            <div style={{ ...iconWrapper, background: '#25D366' }}>
              <Icon
                path={mdiWhatsapp}
                size={iconSize}
                style={{ color: '#fff' }}
              />
            </div>
            <span>Send WhatsApp message</span>
          </Typography>
        </div>

      </div>

    </LandingPageWrapper>
  )
}

export default ContactUs