import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@material-ui/core';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { useGlobalStyles } from '../utils/Styles/GlobalStyles';

function Expandable({
  children,
  title = "Show",
  icon,
  defaultExpandState = false,
  containerStyle = {}
}) {

  const [isExpanded, setIsExpanded] = useState(defaultExpandState);

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  return (
    <Accordion
      expanded={isExpanded}
      style={containerStyle}
      className={GlobalStyles.accordion}
    >
      <AccordionSummary
        expandIcon={<Icon
          path={mdiChevronDown}
          size="1.2rem"
          color={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR} />
        }
        className={GlobalStyles.accordionSummary}
        style={{ flexDirection: 'row' }}
        onClick={async (e) => {
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
      >
        {
          icon &&
          <Icon path={icon} size='1.3rem' className={GlobalStyles.accordionTitleIcon} />
        }
        {title}
      </AccordionSummary>

      <AccordionDetails>
        {isExpanded ? children : null}
      </AccordionDetails>
    </Accordion>
  )

}

export default Expandable