import { IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, useTheme } from '@material-ui/core';
import { mdiAccountDetails, mdiCalendarPlus, mdiCancel, mdiCheck, mdiHistory, mdiQuora, mdiSend, mdiStamper, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState ,useEffect} from 'react';
import LinkCNO from '../../library/LinkCNO';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import ConfirmationDialogCNO from '../../library/ConfirmationDialogCNO';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppointmentStatus,deleteAppointment } from '../../actions/appointmentActions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { LeaveStatus } from '../../utils/mappings';
import RightsManager from '../../utils/RightsManager';
import { useHistory } from 'react-router-dom';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import AppointmentRejectDialog from './AppointmentRejectDialog';
import { getLeaveAcceptHtmlString } from '../../pdfTemplates/templates';

function LeaveListCardPopover({ appointment, closeMoreActions }) {

  const isUserAdmin = RightsManager.isAdmin();
  const isUserCustomer = RightsManager.isCustomer();
  const isUserEmployee = RightsManager.isMember();

  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const isApplied  = appointment.status === LeaveStatus.PENDING;

  const isLeaveDetails = useLocation().pathname.includes("/appointment/");

  const dispatch = useDispatch();
  const { deleteAppointmentLoader, editAppointmentLoader } = useSelector(state => state.appointments);

  const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [targetStatus, setTargetStatus] = useState(LeaveStatus.PENDING);
  

  const history = useHistory();

  return (
    <div className={styles.container_MorePopover}>

      {
        (deleteAppointmentLoader || editAppointmentLoader)
        &&
        <FullScreenLoaderCNO />
      }

      <List className={GlobalStyles.list}>

        {
          !isLeaveDetails &&
          <LinkCNO
            to={`appointment/${appointment.id}`}
          >
            <ListItem className={GlobalStyles.listItem}>
              <ListItemText primary='Details' />
              <ListItemIcon>
                <IconButton edge='end'>
                  <Icon path={mdiAccountDetails} size='1.5rem'
                    color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </LinkCNO>
        }

        {
          isUserAdmin &&
          (isApplied) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setTargetStatus(LeaveStatus.ACCEPTED);
              setIsApprove(true);
            }}
          >
            <ListItemText primary='Approve Leave' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiCheck} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }


        {
          isUserAdmin &&
          (isApplied) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setTargetStatus(LeaveStatus.REJECTED);
              setIsReject(true);
            }}
          >
            <ListItemText primary='Reject Leave' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiCancel} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

        {
          (isUserCustomer || isUserEmployee) &&
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setIsConfirmDeleteModal(true);
            }}
          >
            <ListItemText primary='Delete' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiTrashCan} size='1.5rem'
                  color={theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR}
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

      </List>

      {
        isConfirmDeleteModal &&
        <ConfirmationDialogCNO
          open={isConfirmDeleteModal}
          onClose={() => setIsConfirmDeleteModal(false)}
          dialogTitle="Comfirm Delete Leave Application"
          messages={["Are you sure you want to delete?"]}
          yesClick={async () => {

            const onDeleteAppointment = () => {
              if (isAppointmentDetails) {
                history.push('/appointments');
              }
            };

            try {
              await dispatch(deleteAppointment(appointment));
              onDeleteAppointment();
            } catch (error) {
              console.log("Error: Leave delete error");
            }

          }}
          rightText="Delete"
          closeMoreActions={closeMoreActions}
        />
      }

      {
        (isApprove || isReject) &&
        <ConfirmationDialogCNO
          open={isApprove || isReject}
          onClose={() => {
            setIsApprove(false);
            setIsReject(false);
          }}
          dialogTitle="Update Leave Status"
          messages={[
            isApprove ? "Are you sure you want to accept the appointment?" : null,
            isReject ? "Are you sure you want to reject the appointment?" : null,
          ]}
          yesClick={() => {

            const meta = getLeaveAcceptHtmlString(appointment);

            const appointmentPayload = {
              ...appointment,
              status: targetStatus,
              meta: meta || ""
            };

            dispatch(updateAppointmentStatus(appointmentPayload));

          }}
          rightText={"Yes"}
          closeMoreActions={closeMoreActions}
        />
      }

      {
        isReject &&
        <AppointmentRejectDialog
          open={isReject}
          onClose={() => setIsReject(false)}
          appointment={appointment}
          closeMoreActions={closeMoreActions}
          targetStatus={targetStatus}
        />
      }
    </div >
  )
}

export default LeaveListCardPopover

const useStyles = makeStyles(theme => ({
  container_MorePopover: {
    padding: 5,
    overflow: 'auto',
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR
  },
}));