
import { Typography, useTheme } from '@material-ui/core';
import { mdiHumanMale } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { ServiceStatus } from '../../utils/mappings';
import RightsManager from '../../utils/RightsManager';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import CustomerDetailsDialog from '../CustomerDetailsDialog/CustomerDetailsDialog';

function ServiceCustomerDetailsCard({ service }) {

  if(!service) return  <div></div>;

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isCompanyAdmin = RightsManager.isGroupLeader();

  const [isCustomerEditable, setIsCustomerEditable] = useState(false);

  return (
    <div className={GlobalStyles.detailsCardContainer}>
      <div className={GlobalStyles.detailsCardSectionTitle}>
        Customer
      </div>
      <div className={GlobalStyles.detailsCardSection}>
        <div className={GlobalStyles.detailsRow}>
          <Typography
            // className={styles.customerName}
            style={{
              color: theme.colors.JOB_DETAIL_CUSTOMER_TEXT_COLOR,
              fontWeight: 'bold'
            }}
            onClick={() => setIsCustomerEditable(true)}
          >
            {service.customerText? service.customerText : "Customer not added"}
          </Typography>
          
          <Icon
            path={mdiHumanMale}
            size='2rem'
            color={theme.colors.JOB_DETAIL_CUSTOMER_ICON_COLOR}
          />
        </div>
      </div>

      {
        isCompanyAdmin &&
        isCustomerEditable &&
        <CustomerDetailsDialog
          isEditable={isCustomerEditable}
          setIsEditable={setIsCustomerEditable}
          customerId={service.customerId}
        />
      }
    </div>
  )
}

export default ServiceCustomerDetailsCard
