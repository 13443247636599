import React, { Fragment, useEffect, useState } from 'react';
import { v4 } from 'uuid';

function FormErrorsCNO({ errors }) {

  return (
    <Fragment>
      <ul>
        {
          errors
            .map((error) => (
              <li key={v4()} style={{ color: '#cf143c', marginLeft: 16 }}>{error}</li>
            ))
        }
      </ul>
    </Fragment>
  )
}

export default FormErrorsCNO
