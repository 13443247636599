import jsPDF from "jspdf";
import { AppConstants } from "../AppConstants";
import { getBase64FromURL, getValidFileName } from "../Helpers";
import LocalStorageConstants from "../LocalStorageConstants";
import { RateType } from "../mappings";


export const getCompanyMap = async (companyById, companyProfilePic) => {
  let authUserCompany = companyById || LocalStorageConstants.getItem('authUserCompany', {});

  console.log("====getCompanyMap ====: authUserCompany === ", authUserCompany);

  const profilePicUrl = companyProfilePic ? companyProfilePic : AppConstants.DEFAULT_PDF_LOGO;

  console.log("====getCompanyMap ====: companyProfilePic === ", { profilePicUrl, companyProfilePic });

  const profileBase64 = await getBase64FromURL(profilePicUrl);

  const {
    branch,
    code,
    accNumber,
    accHolderName
  } = authUserCompany.defaultPaymentMethod || {};

  const companyMap = {
    title: authUserCompany.title,
    profilePic: profileBase64,
    address: authUserCompany.address || authUserCompany.mapAddress || "Address Line 1, Address Line 2, State, Country, Zip",
    email: authUserCompany.email || 'contact@company.com',
    mobile: authUserCompany.phone || "+00-123-4567-890",
    cinNumber: authUserCompany.companyRegistration || "Company-Reg-No#-OF-COMPANY",
    taxNumber: authUserCompany.taxNumber || "VAT-Reg-No#-OF-COMPANY",
    bankName: branch || "NAME-OF-BANK",
    bankAccountName: accHolderName || 'ACCOUNT NAME',
    bankAccountNumber: accNumber || "XXXX-XXXX-XXXX-XXXX",
    sortCode: code || 'SORT-CODE#',
    swiftCode: 'SWIFT-CODE#',
  };

  return companyMap;

};

export const getCustomerMap = (customer) => {

  const customerMap = {
    title: `${customer.firstName} ${customer.lastName}`,
    email: customer.email || "",
    mobile: customer.mobile || "",
    customerCompanyAddress: customer.customerCompanyAddress || customer.address || customer.mapAddress || "",
    customerCompanyName: customer.customerCompanyText || "",
  };

  return customerMap;

};

export const getRateType = (items) => {

  let rateType = RateType.Fixed;

  return rateType;

};

export const generatePdf = async (pdfTitle, htmlString, watermark, onGeneratePdfComplete, download) => {

  pdfTitle = getValidFileName(pdfTitle);

  const watermarkText = watermark.text;
  const watermarkImage = watermark.image;

  var pdf = new jsPDF('p', 'pt', 'a4');

  var pageCount = pdf.internal.getNumberOfPages(); //Total Page Number
  for (let i = 0; i < pageCount; i++) {
    pdf.setPage(i);
    let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber; //Current Page

    pdf.setFontSize(8);
    pdf.text(pageCurrent + '/' + pageCount, 300, 785);
    // pdf.setFont('../../assets/arial-narrow.js');

    // set the opacity for whole pdf
    pdf.setGState(new pdf.GState({ opacity: 0.1, strokeOpacity: 0.1 }));

    //add picture in square format
    pdf.addImage(watermarkImage, 'JPEG', 210, 160, 180, 180, 3, 3);

    //create a circle and increased the line width until 
    // it covers all the corners of image in circuralar format
    pdf.setLineWidth(40);
    pdf.setDrawColor(255);
    pdf.setFillColor(255);
    pdf.circle(300, 250, 110);

    // add text watermark
    pdf.setFontSize(100);
    pdf.setTextColor(180);
    pdf.text(200, 600, watermarkText, { angle: 45 });

    pdf.setGState(new pdf.GState({ opacity: 1, strokeOpacity: 1 }));

  }

  return await pdf.html(htmlString, {
    options: { autoPaging: "text" },
    callback: function (pdf) {

      const pdfFileSource = pdf.output("dataurlstring");

      if (onGeneratePdfComplete) {
        onGeneratePdfComplete(pdfFileSource);
      }

      if (download) {
        pdf.save(pdfTitle);
      }

    },
    html2canvas: {
      scale: 0.7
    },
    margin: [30, 20, 30, 20],
    autoPaging: 'text'
  });


};