import { makeStyles, useTheme } from '@material-ui/core';
import { mdiCameraOutline, mdiImageOutline, mdiMapMarkerRadius } from '@mdi/js';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { apiMessageInitUpload } from '../../api';
import { geolocated } from "react-geolocated";
import AttachmentItem from '../../components/AttachmentItem/AttachmentItem';
import Upload from 'rc-upload';
import WebCamera from '../../components/WebCamera/WebCamera';
import ConfirmYesNoDialog from '../../components/ConfirmYesNoDialog/ConfirmYesNoDialog';
import { setNewChat } from '../../actions/chatActions';
import { useDispatch } from 'react-redux';
import { MessageType } from '../mappings';
import { v4 } from 'uuid';

function AttachmentPicker({ props, toggleAttachmentPicker, ...location }) {

  const theme = useTheme();

  const { onSend } = props;

  const authUser = JSON.parse(localStorage.getItem('authUser'));

  const styles = useStyles();

  const [isWebCam, setIsWebCam] = useState(false);
  const [confirmLocation, setConfirmLocation] = useState(false);

  const dispatch = useDispatch();

  const toggleWebCam = (status) => {

    setIsWebCam(status);
    // toggleAttachmentPicker(false);
  };

  /**
   * Convert a base64 string in a Blob according to the data and contentType.
   * 
   * @param b64Data {String} Pure base64 string without contentType
   * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
   * @param sliceSize {Int} SliceSize to process the byteCharacters
   * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
   * @return Blob
   */
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const processAndUploadMedia = async (base64File, messageType) => {

    const uId = v4();

    dispatch(setNewChat({
      _id: uId,
      loading: true,
      text: `${String.fromCodePoint(0x23F3)} Processing Please Wait...`,
      messageType: messageType,
      user: {
        _id: authUser.id,
        name: authUser.firstName + " " + authUser.lastName,
        avatar: authUser.profilePic || ""
      }
    }));

    const mediaUrl = base64File;

    // Split the base64 string in data and contentType
    const block = mediaUrl.split(";");
    // Get the content type of the image
    const contentType = block[0].split(":")[1];
    // get the real base64 content of the file
    const realData = block[1].split(",")[1];

    // console.log("Block: ", block)
    // console.log("contentType: ", contentType);
    // console.log("realData: ", realData);

    const blob = b64toBlob(realData, contentType);

    // INIT UPLOAD
    const initRes = await apiMessageInitUpload.post({ "ids": [uId] });
    console.log("initRes initRes: ", initRes);

    // UPLOAD
    const mRes = await fetch(initRes.data.links[0].m, { method: 'PUT', body: blob });
    const tRes = await fetch(initRes.data.links[0].t, { method: 'PUT', body: blob });
    const bRes = await fetch(initRes.data.links[0].b, { method: 'PUT', body: blob });

    if (messageType === MessageType.Video) {
      console.log("sending video message");
      onSend([{ video: mediaUrl, url: uId, messageType: messageType, _id: uId }]);
    }

    if (messageType === MessageType.Image) {
      console.log("sending image message");
      onSend([{ image: mediaUrl, url: uId, messageType: messageType, _id: uId }]);
    }
  };

  const processMediaUpload = (file) => {
    toggleAttachmentPicker(false);

    let reader = new FileReader();
    reader.readAsDataURL(file);

    if (file.type.includes("image")) {

      reader.onload = function () {
        console.log("File Reader image Result: ", reader.result);
        processAndUploadMedia(reader.result, 3);
      };

      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }

    if (file.type.includes("video")) {
      reader.onload = function () {
        console.log("File Reader video Result: ", reader.result);
        processAndUploadMedia(reader.result, 2);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }

  };

  const handleLocation = () => {
    toggleAttachmentPicker();

    console.log("Location prop: ", location);

    if (!location.isGeolocationAvailable) {
      toast.error("Ooops! Your browser doesn't support Location.");
      return;
    }

    if (!location.isGeolocationEnabled) {
      toast.error("Ooops! Location is not enabled.");
      return;
    }

    const locationObj = {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
    };

    const locationString = {
      "##Shared_Location##": locationObj
    };

    const text = JSON.stringify(locationString);

    onSend([{
      text: text,
      messageType: MessageType.Text,
      location: locationObj
    }]);
  };

  const uploadProps = {
    beforeUpload: (file) => {
      console.log("Before Upload ", file.type.includes('video'));
      if (file.type.includes('image') || file.type.includes('video')) {
        return true;
      }
      toast.error("Unsupported file");
      return false;
    },
    action: (file) => {
      console.log("Upload Action file: ", file);
      processMediaUpload(file);
    },
    multiple: false,
    onStart(file) {
      console.log('onStart Upload', file);
    },
    onSuccess(ret, file, xhr) {
      console.log('onSuccess Upload', ret, file, xhr);
    },
    onError(err, res, file) {
      console.log('onError Upload', err);
    },
  };

  return (
    <div className={styles.container_AttachmentPicker}>

      <Upload {...uploadProps} accept="image/*,video/*">
        <AttachmentItem
          icon={mdiImageOutline}
          title="Image & Video"
          color={theme.colors.CHAT_ATTACHMENT_POPUP_IMAGE_VIDEO_ICON_BACKGROUND_COLOR}
        />
      </Upload>

      <AttachmentItem
        icon={mdiCameraOutline}
        title="Camera"
        color={theme.colors.CHAT_ATTACHMENT_POPUP_CAMERA_ICON_BACKGROUND_COLOR}
        onClick={() => toggleWebCam(true)}
      />

      <AttachmentItem
        icon={mdiMapMarkerRadius}
        title="Location"
        color={theme.colors.CHAT_ATTACHMENT_POPUP_LOCATION_ICON_BACKGROUND_COLOR}
        onClick={() => setConfirmLocation(true)}
      />

      {
        isWebCam &&
        <WebCamera
          toggleWebCam={toggleWebCam}
          processAndUploadMedia={processAndUploadMedia}
        />
      }

      {
        confirmLocation &&
        <ConfirmYesNoDialog
          open={confirmLocation}
          setOpen={setConfirmLocation}
          message="Do you want to share your current location?"
          onConfirm={handleLocation}
        />
      }

    </div>
  )
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(AttachmentPicker);

const useStyles = makeStyles((theme) => ({
  container_AttachmentPicker: {
    position: 'absolute',
    bottom: 50,
    left: 40,
    background: 'transparent',
    color: 'white'
  }
}));