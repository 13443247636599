import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function LinkCNO({children, to, type='relative', ...rest}) {
    const styles = useStyles();

    const MyLink = React.forwardRef((props, ref) => {
        let href;
        if (type === 'absolute') {
            href = props.href.slice(1);
        }
        if (type === 'relative') {
            href = props.href;
        }

        return (
            <a ref={ref} {...props} href={href} navigate="true" >
                {props.children}
            </a>
        );
    })

    if(type==="absolute")
        return (
            <Link component={MyLink} to={to} className={styles.container} {...rest}>
                {children}
            </Link>
        )
    else
        return (
            <Link to={to} className={styles.container} {...rest}>
                {children}
            </Link>
        )
}

export default LinkCNO

const useStyles = makeStyles(theme => ({
    container: {
        textDecoration: 'none',
        display: 'block',
        color: theme.palette.text.primary
    }
}))
