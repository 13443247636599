import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";

export const useContentTopSpace = () => {
  const tillTablet = useMediaQuery("(max-width: 767px)");
  const { mediaPendingLoader } = useSelector((state) => state.app);

  let contentTopSpace = tillTablet ? 40 : 60;
  contentTopSpace += mediaPendingLoader ? 20 : 0;

  return contentTopSpace;
};
