import { makeStyles } from '@material-ui/core';
import { mdiAccount } from '@mdi/js';
import React, { useRef } from 'react'
import { SnapItem, SnapList, useDragToScroll } from 'react-snaplist-carousel';
import { MetaText } from '../../../../library';
import AvatarCNO from '../../../../library/AvatarCNO';
import { getPersonName } from '../../../../utils/Helpers';
import { EventAttendeeRole } from '../../../../utils/mappings';
import { getAttendeesGroupedBasedOnRole } from '../../helpers';
import CardHeader from './CardHeader';
import { useEventDetailsFBStyles } from './EventDetails.styles';
import { useGlobalStyles } from '../../../../utils/Styles/GlobalStyles';

const EventHostsDetailsCard = ({ event }) => {

  const GlobalStyles = useGlobalStyles();
  const EventDetailsFBStyles = useEventDetailsFBStyles();
  const styles = useStyles();

  const groupedAttendees = getAttendeesGroupedBasedOnRole(event.attendees);

  console.log("GroupedAttendees: ", groupedAttendees);

  const hosts = groupedAttendees[EventAttendeeRole.ids.Host];
  const coHosts = groupedAttendees[EventAttendeeRole.ids.CoHost] || [];

  const allHosts = [...hosts, ...coHosts];

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });


  return (
    <div className={EventDetailsFBStyles.card}>
      <CardHeader>
        Meet Your Hosts
      </CardHeader>

      <SnapList ref={snapList} className={GlobalStyles.snapListSlider}>
        {
          allHosts.map((host) => (
            <SnapItem className={styles.hostSectionWrapper}>
              <AvatarCNO
                src={host.profilePic}
                fallbackIcon={mdiAccount}
                iconSize="10rem"
                openAvatarInImageViewer={true}
              />
              <MetaText className={styles.personName}>{getPersonName(host)}</MetaText>
            </SnapItem>
          ))
        }
      </SnapList>
    </div>
  )
}

export default EventHostsDetailsCard;

const useStyles = makeStyles((theme) => ({
  hostSectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
    border: '1px solid #4e4e4e',
    borderRadius: 8,
    width: '100%'
  },
  personName: {
    fontSize: '1.2rem',
    marginTop: 8,
    width: '100%',
    textAlign: 'center'
  }

}))