import React, { Fragment, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { reportsBarChartConfigs } from './reportsBarChartConfigs';
import { Box, useTheme } from "@material-ui/core";

function ReportsBarChart({
  chart = { labels: [], datasets: [] },
  bgColor = "#1A73E8",
}) {

  const theme = useTheme();

  const { data, options } = reportsBarChartConfigs(chart.labels || [], chart.datasets || {}, theme);

  return (
    <Fragment>
      <Box>
        {useMemo(
          () => (
            <Box
              style={{
                backgroundColor: 'transparent',
                padding: "2px 0.5px",
                // marginTop: 30,
                height: "12.5rem",
                borderRadius: 5
              }}
            >
              <Bar data={data} options={options} />
            </Box>
          ),
          [chart, bgColor]
        )}
      </Box>
    </Fragment>
  );
}

export default ReportsBarChart