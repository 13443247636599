import { fade, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container_RegisterCompany: {
    margin: '0 auto',
    '& ::-webkit-scrollbar': {
      display: 'none'
    }
  },
  paper: {
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    // boxShadow: `2px 2px 5px -1px ${theme.palette.text.secondary}`,
    borderRadius: 10,
    width: '70vw',
    maxWidth: '90vh'
  },
  form: {
    padding: '20px',
    // color: theme.palette.text.secondary,
    '&>*': {
      marginBottom: theme.spacing(2)
    },
  },
  label: {
    color: theme.colors.FORM_LABEL_COLOR,
    marginTop: theme.spacing(2),
  },
  addNewIconLabel: {
    marginBottom: 5
  },
  chipsWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    '&>*': {
      marginRight: 10
    }
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  avatarBadge: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    borderRadius: '50%',
    '& .MuiBadge-anchorOriginBottomRightCircle': {
      right: 'calc(50%)',
      bottom: '10%',
      transform: 'scale(1) translate(50%, 50%)',
      transformOrigin: '100% 100%',
      width: 130,
    }
  },
  pickers: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  selectChips: {
    '&>*:first-child': {
      color: theme.colors.FORM_LABEL_COLOR
    }
  },
  selectWithAdd: {
    display: 'flex',
    alignItems: 'flex-end',
    '&>*:first-child': {
      flex: 1,
      color: theme.colors.FORM_LABEL_COLOR
    }
  },
  mobile: {
    '& input': {
      padding: '10px 5px',
      border: 'none',
      borderRadius: '5px'
    }
  },
  picker: {
    '& .MuiInputBase-root': {
      background: 'white',
      color: '#444'
    },
    '& .MuiIconButton-label': {
      color: '#444'
    }
  },
  accordionDetails: {
    width: '100%',
    '&>*': {
      marginTop: 20
    }
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.FORM_LABEL_COLOR,
    '&>*:first-child': {
      marginRight: theme.spacing(2)
    }
  },
  accordion: {
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR
  },
  link: {
    marginTop: theme.spacing(2),
    textDecoration: 'underline',
    color: '#79a8d7',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  addBtn: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    padding: '0 8px',
    '&:hover': {
      background: fade(theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR, 0.7),
    },
  },
  formSection: {
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    padding: '16px 18px',
    borderRadius: 10
  },
  formSectionTitle: {
    fontSize: '0.8rem',
    marginLeft: -8,
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  }
}));