import React, { Fragment, useEffect } from 'react';
import JobCard from '../JobCard/JobCard';
import NotFound from '../NotFound/NotFound';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMoreTodos, fetchTodos, setJobSearchManager, toggleAddJobDialog, toggleIsDeleteUndo, undoDeleteJob } from '../../actions/jobActions';
import { useStyles } from '../JobListingsInfinite';
import { Button, Snackbar, useTheme } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiCalendarClock, mdiCalendarEdit, mdiCalendarPlus, mdiClose, mdiFileTable } from '@mdi/js';
import InfiniteScrollCNO from '../../library/InfiniteScrollCNO';
import ListCTAButtons from '../ListCTAButtons';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import RightsManager from '../../utils/RightsManager';
import Switcher from '../../common/components/GridListToggle/Switcher';
const isUserAdmin = RightsManager.isAdmin();

function JobListingsInfinite({ page, setPage, customer,isGridView,setIsGridView }) {

  const styles = useStyles();

  const dispatch = useDispatch();

  const {
    jobs,
    todosLoader,
    isJobDeleteUndo,
    hasMoreTodos,
    isJobLoader,
    jobSearchParams,
  } = useSelector(state => state.jobs);

  const handleJobUndo = () => {
    dispatch(undoDeleteJob());
  };

  const handleCloseUndo = () => {
    dispatch(toggleIsDeleteUndo(false));
    const undoItem = JSON.parse(localStorage.getItem("undoJob"));
    if (undoItem) {
      localStorage.removeItem("undoJob");
    }
  };

  useEffect(() => {

    const payload = {
      ...jobSearchParams,
      customer: customer ? customer : (
        jobSearchParams && jobSearchParams.customer ? jobSearchParams.customer : null
      )
    };

    console.log("statValuesActions: useEffect: payload:", payload);
    console.log("statValuesActions: useEffect: jobSearchParams:", jobSearchParams);

    dispatch(setJobSearchManager(payload));
  }, []);

  const openAddJobDialog = () => {
    dispatch(toggleAddJobDialog(true));
  };

  const loadMore = () => {

    if (hasMoreTodos) {
      const nextPage = page + 1;
      const payload = {
        pageIndex: nextPage,
        customerIds: customer ? [customer.id] : null
      };

      dispatch(fetchMoreTodos(payload));

      setPage(nextPage);
    }

  };

  const renderNotFound = () => {
    return (
      <Fragment>
        {!isUserAdmin && <NotFound />}
      </Fragment>
    )
  }
  console.log("jobspage",jobs)
  return (
    <div className={styles.container}>
{/* isJobLoader || todosLoader || moreTodosLoader */}
      {
        isJobLoader  &&
        <FullScreenLoaderCNO />
      }

      <InfiniteScrollCNO
        items={jobs}
        next={loadMore}
        hasMore={hasMoreTodos}
        loading={todosLoader}
        renderListItem={(job) => (
          <JobCard key={job.id} item={job} />
        )}
        // renderNotFound={renderNotFound()}
        notFoundTextMessage={'No Job Present'}
        notFoundIcon={mdiCalendarPlus}
        showListCTAButtons={true}
        listCTAButtonsProps={{ addTitle: 'Add Job', exploreId: 3, onClickAdd: openAddJobDialog }}
        isGridView={isGridView} 
        setIsGridView={setIsGridView}
        mode={'jobs'}
      />

      <Snackbar
        className={styles.snackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isJobDeleteUndo}
        autoHideDuration={10000}
        onClose={handleCloseUndo}
        message="Job Deleted Successfully"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleJobUndo}>
              UNDO
            </Button>
            <Icon
              path={mdiClose}
              size="1rem"
              aria-label="close"
              color="inherit"
              onClick={handleCloseUndo}
            />
          </React.Fragment>
        }
      />
    </div>
  )
}


export default JobListingsInfinite;