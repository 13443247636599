import { useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import DialogCNO from '../../library/DialogCNO';
import InputCNO from '../../library/InputCNO';
import { mdiFormatText } from '@mdi/js';
import FormActionButtons from '../FormActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubTask } from '../../actions/subTaskActions';
import { MenuItem, Select } from '@material-ui/core';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';

function EditSubTaskCategory({
  open,
  onClose,
  subTask,
}) {

  const GlobalStyles = useGlobalStyles();

  const localCategories = JSON.parse(localStorage.getItem("categories"));


  const dispatch = useDispatch();
  const { editLoader } = useSelector(state => state.subTasks);

  const formik = useFormik({
    initialValues: {
      categories: subTask.categories,
    },
    validationSchema: yup.object({
      categories: yup.array().required('Category is required')
    }),
    onSubmit: (values) => handleUpdate(values)
  });

  const handleUpdate = (values) => {
    console.log("Edit Subtask: categories: ", values);

    const payload = {
      ...subTask,
      categories: values.categories
    };

    dispatch(updateSubTask(payload, true));

    onClose();
  };

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Edit Subtask categories"
      loading={editLoader}
    >
      <form onSubmit={formik.handleSubmit}>

        <Select
          fullWidth
          variant="outlined"
          margin="dense"
          onChange={(e) => formik.setFieldValue("categories", [e.target.value])}
          value={formik.values.categories}
          className={GlobalStyles.dropdown}
        >
          {
            localCategories.map((category, index) => {
              return <MenuItem value={category.id}>{category.title}</MenuItem>
            })
          }
        </Select>

        <FormActionButtons
          rightText="Save"
          leftClick={onClose}
          formik={formik}
        />

      </form>
    </DialogCNO>
  )
}

export default EditSubTaskCategory
