import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme
} from '@material-ui/core';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { useCustomerEngagemenStyles } from './CustomerEngagementDetailsCard';
import clsx from 'clsx';
import { batch, useDispatch, useSelector } from 'react-redux';
import { handleAppointmentSearch, setAppointments, setAppointmentSearchParams } from '../../actions/appointmentActions';
import FormBox from '../FormBox';
import AppointmentsListing from '../../pages/AppointmentManagement/AppointmentsListing';
import IconCNO from '../../library/IconCNO';
import { mdiChevronDown, mdiClockCheckOutline } from '@mdi/js';

function CustomerAppointmentsSection({ profile }) {

  const theme = useTheme();
  const styles = useCustomerEngagemenStyles();
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { appointments, appointmentsLoader, appointmentSearchParams } = useSelector(state => state.appointments);

  const [isSectionExpanded, setIsSectionExpanded] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    return () => {
      setIsSectionExpanded(false);
    };
  }, []);

  console.log("=====CustomerAppointmentsSection====appointments=appointments", appointments);
  console.log("=====CustomerAppointmentsSection====appointments=appointmentSearchParams", appointmentSearchParams);

  return (
    <div style={{ padding: 0 }}>
      <Accordion
        expanded={isSectionExpanded}
        className={clsx(GlobalStyles.accordion, styles.accordion)}
      >
        <AccordionSummary
          expandIcon={<Icon
            path={mdiChevronDown}
            size="1.2rem"
            color={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR} />
          }
          className={GlobalStyles.accordionSummary}
          onClick={async (e) => {
            e.stopPropagation();

            const isExpanded = !isSectionExpanded;

            setIsSectionExpanded(isExpanded);

            if (isExpanded && !appointments.length) {

              const searchParams = {
                customer: { id: profile.id },
                categories: [],
                appointment: null,
                startDate: null,
                endDate: null,
                assignments: null
              };

              console.log("=====CustomerAppointmentsSection====isExpanded=searchParams", { isExpanded, searchParams });

              dispatch(handleAppointmentSearch(searchParams));

            }

          }}
        >
          <Icon path={mdiClockCheckOutline} size='1.2rem' className={styles.accordionTitleIcon} />
          <Typography>Appointments</Typography>
        </AccordionSummary>

        {
          isSectionExpanded ? (
            <AccordionDetails className={styles.accordionDetails}>
              <div className={styles.grid}>
                {
                  appointments.length
                    ? <div className={styles.listingWrapper} >
                      <AppointmentsListing timeFilterVisible={false} customer={profile} page={page} setPage={setPage} />
                    </div>
                    : <Typography className={styles.loader}>
                      {
                        appointmentsLoader ? "Loading..." : 'No appointments found'
                      }
                    </Typography>
                }
              </div>
            </AccordionDetails>
          ) : (null)
        }

      </Accordion>
    </div>
  );
}

export default CustomerAppointmentsSection;
