import React from 'react';
import DialogCNO from '../library/DialogCNO';
import LinkCNO from '../library/LinkCNO';
import AppLogo from '../assets/appLogo.png';
import AppLogoBlack from '../assets/appLogoBlack.png';
import NameLogo from '../assets/nameLogo.png';
import NameLogoBlack from '../assets/nameLogoBlack.png';
import { makeStyles, Typography, useTheme } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiFacebook, mdiInstagram, mdiLinkedin, mdiYoutube } from '@mdi/js';
import { AppConstants } from '../utils/AppConstants';
import data from '../common/data/LandingPage/Footer';

function ComingSoonModal({
  open,
  onClose,
}) {

  const theme = useTheme();

  const styles = useStyles();

  const socialData = data.menuWidget.find(item => item.id === 3);

  return (
    <DialogCNO
      open={open}
      onClose={onClose}
      dialogTitle="Coming Soon"
      maxHeight={'90vh'}
      width="50%"
    >

      <div className={styles.appLogoWrapper}>
        <img style={{ width: 50, marginRight: 8 }} src={theme.palette.type !== 'dark' ? AppLogoBlack : AppLogo} alt="" />
        {
          theme.palette.type !== 'dark'
            ? <img
              src={NameLogoBlack}
              alt=""
              style={{ width: 150 }}
            />
            : <img
              src={NameLogo}
              alt=""
              style={{ width: 150 }}
            />
        }
      </div>

      <Typography variant='h3'>
        Coming Soon...
      </Typography>

      <Typography variant='h5' style={{ marginTop: 18 }}>
        {AppConstants.AppSlogan}
      </Typography>


      <div>
        <Typography variant='h6' className={styles.followText}>
          Follow us for updates -
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {
              socialData.menuItems.map(social => (
                <LinkCNO to={social.url} type="absolute" target="_blank" className={styles.iconWrapper} style={{ background: social.iconColor, }}>
                  <Icon
                    path={social.icon}
                    size={'1.5rem'}
                    style={{ color: '#fff' }}
                  />
                </LinkCNO>
              ))
            }
          </div>
        </Typography>
      </div>

    </DialogCNO>
  )
}

export default ComingSoonModal

const useStyles = makeStyles((theme) => ({

  appLogoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24
  },

  followText: {
    marginTop: 18,
    display: 'flex',
    alignItems: 'center'
  },

  iconWrapper: {
    marginLeft: 8,
    background: "#0e76a8",
    borderRadius: '50%',
    width: '2rem',
    height: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

}));