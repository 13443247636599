import { Dialog, makeStyles, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from "yup";
import React, { Fragment, useState } from 'react';
import RightsManager from '../../utils/RightsManager';
import HeadingBar from '../HeadingBar/HeadingBar';
import InputCNO from '../../library/InputCNO';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import * as icons from '@mdi/js';
import Icon from '@mdi/react';
import { useTheme } from '@material-ui/core';
import { editJob } from '../../actions/jobActions';
import { useDispatch, useSelector } from 'react-redux';
import { TrackProximityRadius } from '../../utils/mappings';

function EditJobProximityDetails({ isEditable, setIsEditable, job, setJob }) {

  const styles = useStyles();

  const dispatch = useDispatch();
  const { editTodoLoader } = useSelector(state => state.jobs);

  const handleClose = () => setIsEditable(false);

  const handleUpdate = async (values) => {

    const jobProximityPayload = {
      ...job,
      proximity: values.proximity ? values.proximity : 0,
    };

    setJob({
      ...job,
      proximity: values.proximity ? values.proximity : 0,
    });

    dispatch(editJob(jobProximityPayload));

    handleClose();
  };

  const validationSchema = yup.object({
    proximity: yup.string().required("Proximity is required."),
  });

  const formik = useFormik({
    initialValues: {
      proximity: job.proximity ? job.proximity : 0,
    },
    validationSchema,
    onSubmit: values => handleUpdate(values)
  });

  return (
    <Dialog
      open={isEditable}
      className={styles.container}
      classes={{
        paper: styles.paper
      }}
    >

      {
        editTodoLoader &&
        <FullScreenLoaderCNO />
      }

      <HeadingBar title="Edit Job Proximity" onClose={handleClose} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>

        <InputCNO
          name='proximity'
          label='Job Proximity in meter'
          formik={formik}
          placeholder='Enter Job Proximity in meter'
          icon={icons.mdiFormatText}
          fullWidth
        />

        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
        />

      </form>
    </Dialog>
  )
}


function JobProximityDetailsCard({ job, setJob }) {

  const theme = useTheme();

  const styles = useStyles();

  const isUserAdmin = RightsManager.isAdmin();
  const [isEditable, setIsEditable] = useState(false);

  return (
    <>
      {
        isUserAdmin && isEditable &&
        <EditJobProximityDetails
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          job={job}
          setJob={setJob}
        />
      }
      <div className={styles.container_JobProximityDetailsCard} onClick={() => setIsEditable(true)}>
        <Typography className={styles.cardTitle}>
          Proximity in meter
        </Typography>
        <div className={styles.cardContent}>
          <div className={styles.row}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                path={icons.mdiMapMarkerRadiusOutline}
                size="1.2rem"
                color={theme.colors.JOB_DETAIL_RATE_COLOR}
                style={{ marginRight: theme.spacing(1) }}
              />
              <Typography>
                {`${job.proximity ? job.proximity : TrackProximityRadius} meter`}
              </Typography>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default JobProximityDetailsCard

const useStyles = makeStyles(theme => ({
  container_JobProximityDetailsCard: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
  },
  cardContent: {
    padding: theme.spacing(1),
    '&>*': {
      marginTop: theme.spacing(1)
    }
  },
  cardTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR
  },
  row: {
    color: theme.colors.JOB_DETAIL_RATE_COLOR,
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'center'
  },
  formTitle: {
    marginLeft: 5
  },
  form: {
    '&>*': {
      marginTop: theme.spacing(2)
    }
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    borderRadius: 10,
    width: '70vw',
    maxWidth: '90vh'
  },

}));
