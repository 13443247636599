import React from 'react'
import * as yup from 'yup';
import FormBox from '../../../components/FormBox';
import { InputCNO } from '../../../library';
import SelectCNO from '../../../library/SelectCNO';
import { Form, Formik } from 'formik';
import FormActionButtons from '../../../components/FormActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { addForms } from '../../../actions/formBuilderActions';
import FullScreenLoaderCNO from '../../../library/FullScreenLoaderCNO';
import { FormFeatures, FormStatus } from '../utils/enums';
import moment from 'moment';
import LocalStorageConstants from '../../../utils/LocalStorageConstants';

function CreateForm({ onClose }) {

  const authUser = LocalStorageConstants.getItem('authUser');

  const validationSchema = yup.object().shape({
    title: yup.string().required("Form Name is required"),
    description: yup.string(),
    feature: yup.string().required("Feature is required to link the Form")
  })

  const initialValues = {
    title: '',
    description: '',
    feature: '',
    status: FormStatus.id.Draft
  }

  const dispatch = useDispatch();
  const { addFormLoader } = useSelector(state => state.formBuilder);

  const handleAddForm = (values) => {

    const dateNow = moment().toISOString();

    const payload = {
      createdAt: dateNow,
      updatedAt: dateNow,
      createdBy: {
        id: authUser.id,
        title: `${authUser.firstName} ${authUser.lastName}`
      },

      sections: [],
      ...values
    }

    dispatch(addForms(payload, { onSuccess: onClose }));

  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleAddForm}
    >
      {
        function FormBuilderForm(formik) {

          return (
            <Form>

              <FullScreenLoaderCNO loading={addFormLoader} />

              <InputCNO
                name="title"
                label="Form Name"
                formik={formik}
                placeholder="Enter form name..."
                fullWidth
                isRequired
              />

              <InputCNO
                name="description"
                label="Description"
                formik={formik}
                placeholder="Provide description for your form..."
                fullWidth
                multiline
                rows={4}
              />

              <SelectCNO
                name='feature'
                formik={formik}
                label='Feature'
                placeholder="Select..."
                options={FormFeatures.entities}
                fullWidth
              />

              <FormActionButtons
                rightText="Save"
                formik={formik}
                leftClick={onClose}
              />

            </Form>
          )
        }
      }
    </Formik>
  )
}

export default CreateForm