import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ScreenView from "../../components/ScreenView";
import UserProfile from "../../components/UserProfile/UserProfile";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { getCustomerById } from "../../actions/customerActions";
import { ProfileMode } from "../../utils";

function CustomerDetailsPage() {
  const styles = useStyles();

  const dispatch = useDispatch();
  const { customerById, allCustomersLoader, customers } = useSelector(
    (state) => state.customers
  );
  const { customerDetailLoader, editLoader } = allCustomersLoader;

  const { id } = useParams();

  useEffect(() => {
    const customerId = [id];

    const customer = customers.find((customer) => customer.id === id);

    console.log("===offline customer===", customer);

    dispatch(getCustomerById(customerId, false, customer));
  }, [id]);

  console.log("=======customerById", customerById);

  return (
    <div className={styles.container_CustomerDetailsPage}>
      {(customerDetailLoader || editLoader) && <FullScreenLoaderCNO />}
      <ScreenView
        content={
          customerById && (
            <UserProfile
              mode={ProfileMode.Customer}
              profile={customerById}
              isActiveMember={true}
            />
          )
        }
        headerTitle="Customer Detail"
      />
    </div>
  );
}

export default CustomerDetailsPage;

const useStyles = makeStyles((theme) => ({
  container_CustomerDetailsPage: {
    background: theme.colors.APP_BACKGROUND_COLOR,
  },
}));
