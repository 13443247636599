import {
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useStyles } from './CurrentSubscriptionDetailsCardStyles';
import SubscriptionDetails from '../SubscriptionDetails';

function CurrentSubscriptionDetailsCard({ profile, isActive }) {

  const tillTablet = useMediaQuery('(max-width: 767px)');

  const styles = useStyles({ tillTablet });

  return (
    <div className={styles.container_CurrentSubscriptionDetailsCard}>
      <Typography className={styles.title}>Active Subscription Details</Typography>
      <SubscriptionDetails profile={profile} />
    </div>
  )
}

export default CurrentSubscriptionDetailsCard;
