import moment from "moment";
import {
  getCompanyMap,
  getCustomerMap,
  getRateType
} from ".";
import { generateInvoiceFixedTemplate } from "../../pdfTemplates/templates";
import { RateType } from "../mappings";

export const getHourlyInvoiceItemsMap = (invoiceItems) => {

  let invoiceItemsMap = [];

  let subTotal = 0;

  if (invoiceItems && invoiceItems.length) {
    invoiceItemsMap = invoiceItems.map((item) => ({
      title: item.title,
      description: item.description,
      rate: item.rate,
      rateType: item.rateType,
      hours: item.hours,
      taxRate: item.taxRate,
      tax: item.taxAmount,
      amount: item.amount
    }));

    subTotal = invoiceItems.reduce((total, item) => {
      total += item.rate * item.hours;
      return total;
    }, 0);
  }

  return ({
    invoiceItemsMap: invoiceItemsMap,
    subTotal: subTotal
  });

};

export const getFixedInvoiceItemsMap = (invoiceItems) => {

  let invoiceItemsMap = [];
  let subTotal = 0;

  if (invoiceItems && invoiceItems.length) {

    invoiceItemsMap = invoiceItems.map((item) => ({
      title: item.title,
      description: item.description,
      rate: item.rate,
      rateType: item.rateType,
      amount: item.rate
    }));

    subTotal = invoiceItems.reduce((total, item) => {
      total += item.rate;
      return total;
    }, 0);
  }

  return ({
    invoiceItemsMap: invoiceItemsMap,
    subTotal: subTotal
  });

};

export const getInvoiceItemsMap = (invoiceItems, rateType) => {

  let invoiceItemsMap = [];
  let subTotal = 0;

  if ((rateType === RateType.Fixed)) {
    const fixedInvoiceResult = getFixedInvoiceItemsMap(invoiceItems);
    invoiceItemsMap = fixedInvoiceResult.invoiceItemsMap;
    subTotal = fixedInvoiceResult.subTotal;
  }

  if (rateType === RateType.Hourly) {
    const hourlyInvoiceResult = getHourlyInvoiceItemsMap(invoiceItems);
    invoiceItemsMap = hourlyInvoiceResult.invoiceItemsMap;
    subTotal = hourlyInvoiceResult.subTotal;
  }

  return ({
    invoiceItemsMap, subTotal
  });

};

export const getInvoiceMap = (invoice) => {

  console.log("getInvoiceMap=======invoice====", invoice);

  const dueDate = moment(invoice.billDate).add(invoice.dueInDays, 'days').format('MMM DD, YYYY');
  const invoiceMap = {
    ...invoice,
    invoiceNumber: invoice.invoiceNumber,
    billDate: moment(invoice.billDate).format('MMM DD, YYYY'),
    dueDate: dueDate,
    // billingMode: AppConstants.ItemsRateTypeReverse[invoice.rateType],
    startDate: moment(invoice.startDate).format('MMM DD, YYYY'),
    endDate: moment(invoice.endDate).format('MMM DD, YYYY'),
  };

  return invoiceMap;

};

export const generateInvoiceTemplate = async (invoice, invoiceItems, customerById, companyById, companyProfilePic) => {

  let templateString = "";

  const rateType = getRateType(invoiceItems);

  // const { invoiceItemsMap, subTotal } = getInvoiceItemsMap(invoiceItems, rateType);
  const invoiceItemsMap = invoiceItems;
  const invoiceMap = getInvoiceMap(invoice);
  const companyMap = await getCompanyMap(companyById, companyProfilePic);
  const customerMap = getCustomerMap(customerById);

  console.log("getInvoiceMap=======invoiceMap====", invoiceMap);

  templateString = generateInvoiceFixedTemplate(companyMap, customerMap, invoiceMap, invoiceItemsMap);

  return templateString;

};

