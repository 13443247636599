import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container_ConfirmationDialogCNO: {
    '& ::-webkit-scrollbar': {
      // display: 'none'
      width: 4,
      marginRight: 2
    },
    '& ::-webkit-scrollbar-thumb': {
      background: '#5e5e5e',
      borderRadius: 10,
    }
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.colors.DIALOG_TITLE_COLOR,
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    minWidth: 350,
    width: '70vw',
    maxWidth: '90vh'
  },
}));