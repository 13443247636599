import { Dialog, Typography, useTheme } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import RightsManager from "../../utils/RightsManager";
import { useStyles } from "./CostDetailsCardStyles";
import HeadingBar from "../HeadingBar/HeadingBar";
import InputCNO from "../../library/InputCNO";
import { mdiCheck, mdiHours24, mdiLockClock } from "@mdi/js";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import ChipCNO from "../../library/ChipCNO";
import {
  Currency,
  CurrencyIcon,
  RateType,
  RateTypeReverse,
} from "../../utils/mappings";
import Icon from "@mdi/react";
import * as icons from "@mdi/js";
import { getMaterialIconString } from "../../utils/icons";
import { parseDisplayNumber, parseInputFieldNumber } from "../../utils/Helpers";
import FormBox from "../FormBox";
import { useDispatch, useSelector } from "react-redux";
import { editJob } from "../../actions/jobActions";
import { getJobTotalAmount } from "../../utils/InvoiceHelpers";

function EditCostDetails({ isEditable, setIsEditable, job, setJob }) {
  let currencyType = localStorage.getItem("currencyType");
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const dispatch = useDispatch();

  const { editTodoLoader } = useSelector((state) => state.jobs);

  const styles = useStyles();

  const handleClose = () => setIsEditable(false);

  const handleUpdate = async (values) => {
    const jobRatePayload = {
      ...job,
      amount: values.jobPrice ? values.jobPrice : 0,
      rate: values.jobPrice ? values.jobPrice : 0,
      rateType: values.rateType,
    };

    setJob({
      ...job,
      rate: values.jobPrice,
      rateType: values.rateType,
    });

    dispatch(editJob(jobRatePayload));

    handleClose();
  };

  const validationSchema = yup.object({
    jobPrice: yup.string().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      jobPrice: job.rate,
      rateType: job.rateType,
    },
    validationSchema,
    onSubmit: (values) => handleUpdate(values),
  });

  return (
    <Dialog
      open={isEditable}
      className={styles.container}
      classes={{
        paper: styles.paper,
      }}
    >
      {editTodoLoader && <FullScreenLoaderCNO />}

      <HeadingBar title="Edit Cost Details" onClose={handleClose} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.rateTypeWrapper}>
          <Typography>Job Types</Typography>
          <div className={styles.rateTypeChips}>
            <ChipCNO
              icon={mdiCheck}
              name="Fixed"
              size="1.5rem"
              active={formik.values.rateType === RateType.Fixed}
              onClick={() => formik.setFieldValue("rateType", RateType.Fixed)}
            />
            <ChipCNO
              icon={mdiHours24}
              name="Hourly"
              size="1.5rem"
              active={formik.values.rateType === RateType.Hourly}
              onClick={() => formik.setFieldValue("rateType", RateType.Hourly)}
            />
          </div>
        </div>
        <InputCNO
          name="jobPrice"
          label="Job Price"
          formik={formik}
          placeholder="Enter Job Rate"
          icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
          fullWidth
          onChange={(e) => {
            const value = e.target.value;

            const jobPriceValue = !isNaN(value)
              ? value
              : formik.values.jobPrice;

            formik.setFieldValue("jobPrice", value === "" ? "" : jobPriceValue);
          }}
        />
        <FormActionButtons rightText="Save" leftClick={handleClose} />
      </form>
    </Dialog>
  );
}

function CostDetailsCard({ job, setJob }) {
  const theme = useTheme();

  let currencyType = localStorage.getItem("currencyType");
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const styles = useStyles();

  const isUserAdmin = RightsManager.isAdmin();
  const [isEditable, setIsEditable] = useState(false);

  const dispatch = useDispatch();

  const { subTasks } = useSelector((state) => state.subTasks);

  const subTasksAmount = useSelector((state) =>
    state.subTasks.subTasks.reduce((result, subTask) => {
      result += parseFloat(subTask.amount);

      return result;
    }, 0)
  );

  useEffect(() => {
    const jobAmount = subTasks.length ? subTasksAmount : job.amount;

    const { amount, totalAmount } = getJobTotalAmount(jobAmount);

    const payload = {
      ...job,
      amount,
      totalAmount,
    };

    if (jobAmount !== job.amount) {
      dispatch(editJob(payload));
    }
  }, [subTasksAmount, subTasks.length]);

  return (
    <FormBox
      title={"Cost"}
      bgColor={theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR}
      titleColor={theme.colors.JOB_DETAIL_CARD_TITLE_COLOR}
    >
      <div
        className={styles.totalAmountWraper}
        onClick={() => {
          if (isUserAdmin && !job.oneSubTaskId) {
            setIsEditable(true);
          }
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {job.rateType ? (
            <ChipCNO
              icon={
                job.rateType === RateType.Hourly ? mdiHours24 : mdiLockClock
              }
              name={RateTypeReverse[job.rateType]}
              active={true}
            />
          ) : null}
          <Typography className={styles.label} style={{ fontSize: "1rem" }}>
            Total Job Cost
          </Typography>
        </div>
        <div className={styles.amountWrapper}>
          <div className={styles.amount}>
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
              size="1.2rem"
              style={{ marginRight: 4 }}
            />
            <Typography
              className={styles.value}
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              {parseDisplayNumber(job.totalAmount)}
            </Typography>
          </div>
        </div>
      </div>

      {isUserAdmin && isEditable && (
        <EditCostDetails
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          job={job}
          setJob={setJob}
        />
      )}
    </FormBox>
  );
}

export default CostDetailsCard;
