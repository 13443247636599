import LocalStorageConstants from "../../../utils/LocalStorageConstants";
import { DeepLinkDomainPrefixes } from "../../../utils/mappings";

export const getAppointmentRejectHtmlString = (appointment, rejectReason) => {

  const authUser = LocalStorageConstants.getItem('authUser');

  const htmlString = `
    <div style="padding: 10px 30px; font-family: Arial, Helvetica, sans-serif; font-size: 14px;">

      <div style="color: #4e4e4e; ">
          <p>
              Hello ${authUser.firstName ? authUser.firstName : ""},
          </p>
      </div>

      <p style="margin-top: 40px;">Your <strong>Appointment</strong> has been rejected.</p>

      <h4 style="margin-top: 40px;">Rejection Reason</h4>
      <p style="color: #cf543c">
          ${rejectReason}
      </p>

      <div style="text-align: center; margin: 80px 0;">
          <a href="${DeepLinkDomainPrefixes.Https}/appointment/${appointment.id}" style="background: #008dc9; text-decoration: none; padding: 10px 20px; border-radius: 5px; color: #fefefe; font-weight: bold; cursor: pointer;">
          View Appointment
        </a>
      </div>

      <div style="color: #4e4e4e; margin-right: 10px; margin-top: 20px">
          <div>Thanks & Regards</div>
          <div>${appointment.customerText ? appointment.customerText : ""}</div>
      </div>

      <div style="display: flex; flex: 1; justify-content: center; align-items: center; background: #e8e8e8; border-radius: 5px; margin-top: 60px;">
      <img src="https://checkndout.com/logoAntEmail.png" alt="app-logo" style="width: 40px; object-fit: contain;" />
      <img src="https://checkndout.com/logoTextEmail.png" alt="name-logo" style="height: 40px; object-fit: contain;" />
      </div>

    </div>
  `;

  return htmlString;

};