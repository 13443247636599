import { Button, Typography, useTheme } from '@material-ui/core';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import { batch, useDispatch, useSelector } from 'react-redux';
import { fetchInvoiceItems, setInvoiceItems, setIsNewInvoiceItemAdd, toggleAddInvoiceItemDialog, updateInvoiceItem } from '../../actions/invoiceItemActions';
import AddInvoiceItemDialog from './AddInvoiceItemDialog';
import InvoiceItemDetailsListItem from './InvoiceItemDetailsListItem';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import RightsManager from '../../utils/RightsManager';
import { InvoiceStatus } from '../../utils/mappings';
import FakeSubTaskCard from '../../components/FakeSubTaskCard';
import DraggableLists from '../../library/DraggableLists';


function InvoiceItemDetailsCard({ invoice, invoiceItemType }) {

  const theme = useTheme();
  const GlobalStyles = useGlobalStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const dispatch = useDispatch();
  const {
    isAddInvoiceItemDialog,
    invoiceItems,
    invoiceItemsLoader,
    addLoader,
    editLoader,
    deleteLoader,
    isNewInvoiceItemAdded
  } = useSelector(state => state.invoiceItems);

  const [isServerInvoiceItemsVisible, setIsServerInvoiceItemsVisible] = useState(false);

  const toggleIsServerInvoiceItemsVisible = () => setIsServerInvoiceItemsVisible(!isServerInvoiceItemsVisible);

  useEffect(() => {
    return () => {
      setIsServerInvoiceItemsVisible(false);
      dispatch(setInvoiceItems([]));
    };
  }, []);

  const loadInvoiceItemsFromServer = () => {
    setIsServerInvoiceItemsVisible(true);
    if (invoice) {

      let refreshData = true;

      if (invoiceItems.length) {
        const isItemsOfSameId = invoiceItems.every(item => item.invoiceId === invoice.id);
        if (isItemsOfSameId) {
          refreshData = true;
        }
      } else {
        refreshData = true;
      }

      if (refreshData) {
        const payload = {
          invoiceId: invoice.id,
        };

        dispatch(fetchInvoiceItems(payload));
      }

    }
  };

  useEffect(() => {
    if (isNewInvoiceItemAdded) {
      loadInvoiceItemsFromServer();
    }
    return () => {
      dispatch(setIsNewInvoiceItemAdd(false));
    };
  }, [isNewInvoiceItemAdded]);

  useEffect(() => {
    if (!invoiceItems.length) {
      setIsServerInvoiceItemsVisible(false);
    }
  }, [invoiceItems]);

  return (
    <div className={GlobalStyles.detailsCardContainer} style={{ padding: '16px 8px' }}>
      <div className={GlobalStyles.detailsCardSectionTitle} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography>Invoice Items</Typography>
        {
          isUserAdmin &&
          <Button
            style={{
              background: theme.colors.ADD_SUBTASK_BUTTON_BACKGROUND_COLOR,
              color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
              borderColor: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
              fontSize: '0.7rem',
              fontWeight: 700
            }}
            startIcon={<Icon path={mdiPlus} size="1rem" />}
            onClick={async () => {

              batch(() => {

                dispatch(toggleAddInvoiceItemDialog(true));

                dispatch(fetchInvoiceItems({ invoiceId: invoice.id }));

              })

            }}
          >
            Add Invoice Item
          </Button>
        }

      </div>

      {
        !isServerInvoiceItemsVisible &&
        <FakeSubTaskCard
          subItem={{
            id: invoice.oneInvoiceItemId,
            title: invoice.oneInvoiceItemTitle,
            categories: invoice.oneInvoiceItemCategories,
          }}
          onLoadFromServer={() => loadInvoiceItemsFromServer()}
          emptyMessage={"No Invoice Item found."}
        />
      }

      {
        isServerInvoiceItemsVisible && invoice.oneInvoiceItemId &&
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}>
          <p
            style={{
              color: theme.colors.LINK_COLOR_GREY,
              fontSize: '0.8rem',
              border: `1px solid ${theme.colors.LINK_COLOR_GREY}`,
              borderRadius: 4,
              padding: '4px 8px',
              cursor: 'pointer'
            }}
            onClick={toggleIsServerInvoiceItemsVisible}
          >
            Load Less
          </p>
        </div>
      }

      {
        isServerInvoiceItemsVisible &&
        <div className={GlobalStyles.subItemsListContainer} >
          <DraggableLists
            items={invoiceItems}
            listItemPropName="invoiceItem"
            renderNotFound={'No Invoice Item found'}
            loading={invoiceItemsLoader}
            onDrop={(dragItems, reOrderItems, dropEvent) => {
              dispatch(setInvoiceItems(reOrderItems));
              dragItems.map(item => dispatch(updateInvoiceItem(item)));
            }}
          >
            <InvoiceItemDetailsListItem invoice={invoice} />
          </DraggableLists>
        </div>
      }

      {
        (addLoader || editLoader || deleteLoader)
        &&
        <FullScreenLoaderCNO />
      }

      {
        isAddInvoiceItemDialog &&
        <AddInvoiceItemDialog
          invoice={invoice}
          invoiceItemType={invoiceItemType}
        />
      }

    </div>
  )
}

export default InvoiceItemDetailsCard
