import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme
} from '@material-ui/core';
import {
  mdiAccountPlus
} from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleAddCustomerDialog } from '../../actions/customerActions';
import RightsManager from '../../utils/RightsManager';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';

function CustomerListHeaderMorePopover({ closeMoreActions }) {

  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const isUserAdmin = RightsManager.isAdmin();

  const dispatch = useDispatch();

  const openAddCustomerDialog = () => {
    dispatch(toggleAddCustomerDialog(true));
    closeMoreActions();
  };

  return (
    <div className={styles.container_MorePopover}>
      <List className={styles.list}>
        {
          isUserAdmin &&
          <ListItem className={GlobalStyles.listItem} onClick={openAddCustomerDialog}>
            <ListItemText primary='Add Customer' />
            <ListItemIcon>
              <IconButton edge='end'>
                <Icon path={mdiAccountPlus} size='1.5rem' color={theme.colors.HEADER_ADD_TODO_ICON_COLOR} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        }

      </List>
    </div>
  )
}

export default CustomerListHeaderMorePopover

const useStyles = makeStyles(theme => ({
  container_EmployeeListHeaderMorePopover: {
    padding: 5,
    overflow: 'hidden',
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR
  },
  list: {
    background: theme.colors.MORE_ACTIONS_OPTIONS_WRAPPER_BACKGROUND_COLOR,
    // background: theme.palette.background.dark,
    padding: 0
  },
  listItem: {
    background: theme.colors.MORE_ACTIONS_SINGLE_OPTION_WRAPPER_BACKGROUND_COLOR,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 2,
    // width: 200,
    color: theme.colors.MORE_ACTIONS_OPTION_TEXT_COLOR,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.01)'
    }
  },
  listItemText: {
    textDecoration: 'none',
  }
}))