import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery, TextField, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import HeadingBar from '../../components/HeadingBar';
import FormBox from '../../components/FormBox';
import { InputCNO } from '../../library';
import FormActionButtons from '../../components/FormActionButtons';
import Webcam from 'react-webcam';

const TestFormDialog = ({ open, onClose, onSave, testId }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [errors, setErrors] = useState({ name: '', email: '', mobile: '' });
  const [screenshot, setScreenshot] = useState(null);
  const [isWebcamVisible, setIsWebcamVisible] = useState(false);
  const webcamRef = useRef(null);

  const history = useHistory();
  const tillTablet = useMediaQuery("(max-width: 767px)");

  const styles = useStyles({ tillTablet });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateMobile = (mobile) => {
    const re = /^\d{10}$/;
    return re.test(String(mobile));
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      setMobile(value);
    }
  };

  const handleKeyPress = (e) => {
    if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    const newErrors = { name: '', email: '', mobile: '' };

    if (!name.trim()) newErrors.name = 'Name is required';
    if (!validateEmail(email)) newErrors.email = 'Invalid email format';
    if (!validateMobile(mobile)) newErrors.mobile = 'Mobile number must be 10 digits';

    if (newErrors.name || newErrors.email || newErrors.mobile) {
      setErrors(newErrors);
      return;
    }

    const formData = { testId, name, email, mobile, screenshot };

    // Save formData in local storage
    const userDetailsInfo = JSON.parse(localStorage.getItem('userDetailsInfo')) || {};
    userDetailsInfo[testId] = formData;
    localStorage.setItem('userDetailsInfo', JSON.stringify(userDetailsInfo));

    onSave(testId, formData);
    setName('');
    setEmail('');
    setMobile('');
    setScreenshot(null);
    setErrors({ name: '', email: '', mobile: '' });
    setIsWebcamVisible(false);
    onClose();
    history.push(`/test/${testId}/countdown`);
  };

  const handleClose = () => {
    setName('');
    setEmail('');
    setMobile('');
    setScreenshot(null);
    setErrors({ name: '', email: '', mobile: '' });
    setIsWebcamVisible(false);
    onClose();
  };

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setScreenshot(imageSrc);
    setIsWebcamVisible(false);
  };

  const retakePhoto = () => {
    setScreenshot(null);
    setIsWebcamVisible(true);
  };

  useEffect(() => {
    if (!open) {
      setName('');
      setEmail('');
      setMobile('');
      setErrors({ name: '', email: '', mobile: '' });
      setIsWebcamVisible(false);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: styles.paper }} className={styles.container}>
      <form onSubmit={handleSave} className={styles.form}>
        <HeadingBar title="Enter Your Details" onClose={handleClose} />
        <FormBox title={"Details"}>
          <InputCNO
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
            name='name'
            placeholder='Enter Your Name'
            isRequired
          />
          <InputCNO
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
            name='email'
            placeholder='Enter Your Email'
            isRequired
          />
          <InputCNO
            label="Mobile"
            type="text"
            fullWidth
            value={mobile}
            onChange={handleMobileChange}
            onKeyPress={handleKeyPress}
            error={!!errors.mobile}
            helperText={errors.mobile}
            inputProps={{ maxLength: 10 }}
            name='number'
            placeholder='Enter Your Number'
            isRequired
          />
        </FormBox>

        <FormBox title={"Upload Photo"}>
          <div className={styles.webcamContainer}>
            {!isWebcamVisible && !screenshot && (
              <Button onClick={() => setIsWebcamVisible(true)} className={styles.btn}>Take Photo</Button>
            )}
            {isWebcamVisible && (
              <>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  className={styles.webcam}
                />
                <Button onClick={capture} className={styles.btn}>Capture Photo</Button>
              </>
            )}
            {screenshot && (
              <div className={styles.screenshotContainer}>
                <img src={screenshot} alt="screenshot" className={styles.screenshot} />
                <Button onClick={retakePhoto} className={styles.btn}>Retake Photo</Button>
              </div>
            )}
          </div>
        </FormBox>

        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
          rightClick={handleSave}
        />
      </form>
    </Dialog>
  );
};

export default TestFormDialog;

const useStyles = makeStyles((theme) => ({
  container: {},
  paper: {
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    borderRadius: 10,
    width: "40vw",
    maxWidth: "90vw",
    margin: ({ tillTablet }) => (tillTablet ? 8 : 32),
  },
  form: {
    padding: "20px",
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    overflowX: "hidden",
    "&>*": {
      marginBottom: theme.spacing(3),
    },
  },
  webcamContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  webcam: {
    width: '100%',
    height: 'auto',
    marginBottom : "1rem",
  },
  screenshotContainer: {
    marginTop: theme.spacing(2),
  },
  screenshot: {
    width: '100%',
    height: 'auto',
    marginBottom : "1rem",
  },
  btn: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
  },
}));
