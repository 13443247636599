/* ------------------------------------ */
// Navbar data section
/* ------------------------------------ */
import logo from '../../../common/assets/image/LandingPage/logo.png';
import client1 from '../../../common/assets/image/LandingPage/company1.png';
import client2 from '../../../common/assets/image/LandingPage/company2.png';
import client3 from '../../../common/assets/image/LandingPage/company3.png';
import client4 from '../../../common/assets/image/LandingPage/company4.png';
import featureIcon1 from '../../../common/assets/image/LandingPage/icon1.svg';
import featureIcon2 from '../../../common/assets/image/LandingPage/icon2.svg';
import featureIcon3 from '../../../common/assets/image/LandingPage/icon3.svg';
import featureIcon4 from '../../../common/assets/image/LandingPage/icon4.svg';
import appSlide1 from '../../../common/assets/image/LandingPage/appSlider1.png';
import appSlide2 from '../../../common/assets/image/LandingPage/appSlider2.png';
import appSlide3 from '../../../common/assets/image/LandingPage/appSlider3.png';
import appIcon from '../../../common/assets/image/LandingPage/icon1.svg';
import slide1 from '../../../common/assets/image/LandingPage/page1.png';
import slide2 from '../../../common/assets/image/LandingPage/page2.png';
import slide3 from '../../../common/assets/image/LandingPage/page3.png';
import codingImage from '../../../common/assets/image/LandingPage/code.png';
import member1 from '../../../common/assets/image/LandingPage/1.png';
import member2 from '../../../common/assets/image/LandingPage/2.png';
import member3 from '../../../common/assets/image/LandingPage/3.png';
import member4 from '../../../common/assets/image/LandingPage/4.png';
import member5 from '../../../common/assets/image/LandingPage/5.png';
import member6 from '../../../common/assets/image/LandingPage/6.png';
import member7 from '../../../common/assets/image/LandingPage/7.png';
import chat from '../../../common/assets/image/LandingPage/chat.svg';
import group from '../../../common/assets/image/LandingPage/group.svg';
import github from '../../../common/assets/image/LandingPage/github.svg';
import footerLogo from '../../../common/assets/image/LandingPage/logoWhite.png';
import availableThumb from '../../../common/assets/image/LandingPage/availableThumb.png';

export const navbar = {
  logo: logo,
  navMenu: [
    {
      id: 1,
      label: 'Home',
      path: '#home',
      offset: '84',
    },
    {
      id: 2,
      label: 'Key Features',
      path: '#features',
      offset: '81',
    },
    {
      id: 3,
      label: 'Testimonial',
      path: '#testimonial',
      offset: '81',
    },
    {
      id: 4,
      label: 'Pricing',
      path: '#pricing',
      offset: '81',
    },
    {
      id: 5,
      label: 'Team Members',
      path: '#team',
      offset: '81',
    },
  ],
};

/* ------------------------------------ */
// client data section
/* ------------------------------------ */


export const client = [
  {
    id: 1,
    image: client1,
    title: 'The new york times',
  },
  {
    id: 2,
    image: client2,
    title: 'amazon',
  },
  {
    id: 3,
    image: client3,
    title: 'evernote',
  },
  {
    id: 4,
    image: client4,
    title: 'the verge',
  },
];

/* ------------------------------------ */
// Features data section
/* ------------------------------------ */

export const features = {
  slogan: 'KEY FEATURES',
  title: 'Why you choose our app',
  items: [
    {
      id: 1,
      color: '#F55767',
      icon: featureIcon1,
      title: 'App Development',
      description:
        'We are specialized at custom Saas Application Development and special features.',
    },
    {
      id: 2,
      color: '#ff4742',
      icon: featureIcon2,
      title: '10 Times Award',
      description:
        'We are globally recognised for our services and won a lot of prices around the world .',
    },
    {
      id: 3,
      color: '#fb5781',
      icon: featureIcon3,
      title: 'Cloud Storage',
      description:
        'LiteSpeed Web Server known for its high performance and low resource consumption.',
    },
    {
      id: 4,
      color: '#f18e47',
      icon: featureIcon4,
      title: 'Customization',
      description:
        'Client Satisfaction is our first priority and We are best at it. Keep In Touch for the best output. ',
    },
  ],
};

/* ------------------------------------ */
// App slider data section
/* ------------------------------------ */
export const appSlider = {
  carousel: [
    {
      id: 1,
      image: appSlide1,
      title: 'App Slide 1',
    },
    {
      id: 2,
      image: appSlide2,
      title: 'App Slide 1',
    },
    {
      id: 3,
      image: appSlide3,
      title: 'App Slide 1',
    },
  ],
  title: 'Smart Jackpots that you may love this anytime & anywhere',
  description:
    "The rise of mobile devices transforms the way we consume information entirely and the world's most elevant channels such as Facebook.",
  features: [
    {
      id: 1,
      icon: appIcon,
      title: 'Easy Invoicing',
      description: 'Surprice your clients with professional looking invoices.',
    },
    {
      id: 2,
      icon: appIcon,
      title: 'UX Planning',
      description:
        'UI/UX Design by following and maintaining the latest trends .',
    },
    {
      id: 3,
      icon: appIcon,
      title: 'Customer Support',
      description: 'A Dedicated support team will be always ready for you.',
    },
  ],
};

/* ------------------------------------ */
// Design and built data section
/* ------------------------------------ */
export const designAndBuilt = {
  image: codingImage,
  slogan: 'CODE INTEGRATION',
  title: 'Introducing coding features of our apps with Customization',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore features that Lorem ipsum dolor sit amet consectetur.',
};

/* ------------------------------------ */
// Product  Slide  section
/* ------------------------------------ */

export const productData = {
  slogan: 'PRODUCT SHOWCASE',
  title: 'Meet Client Satisfaction by using our product',
  carousel: [
    {
      id: 1,
      thumb_url: slide1,
      link: '#1',
      title: 'slide 1',
    },
    {
      id: 2,
      thumb_url: slide2,
      link: '#1',
      title: 'slide 2',
    },
    {
      id: 3,
      thumb_url: slide3,
      link: '#1',
      title: 'slide 3',
    },

    {
      id: 4,
      thumb_url: slide1,
      link: '#1',
      title: 'slide 4',
    },

    {
      id: 5,
      thumb_url: slide3,
      link: '#1',
      title: 'slide 5',
    },
    {
      id: 6,
      thumb_url: slide2,
      link: '#1',
      title: 'slide 6',
    },
  ],
};

/* ------------------------------------ */
// Team Portfolio data section
/* ------------------------------------ */
export const teamportfolio = {
  title: 'Meet our awesome team members, work behind the sense',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore features that Lorem ipsum dolor sit amet consectetur.',

  teammember: [
    {
      id: 1,
      img: member1,
      text: 'Berlin Corleone',
    },
    {
      id: 2,
      img: member2,
      text: 'Jona White',
    },
    {
      id: 3,
      img: member3,
      text: 'Michael Price',
    },
    {
      id: 4,
      img: member4,
      text: 'Gullyboy Rana',
    },
    {
      id: 5,
      img: member5,
      text: 'Miss Clair',
    },
    {
      id: 6,
      img: member6,
      text: 'Bucky Ali',
    },
    {
      id: 7,
      img: member7,
      text: 'Arthus Doe',
    },
  ],
};

/* ------------------------------------ */
// Testimonial data section
/* ------------------------------------ */
export const testimonial = {
  slogan: 'TESTIMONIAL',
  title: 'Meet Client Satisfaction by using product',
  reviews: [
    {
      id: 1,
      title: 'Modern look & trending design',
      description:
        'Get working experience to work with this amazing team & in future want to work together for bright future projects and also make deposit to freelancer.',
      avatar:
        'https://pbs.twimg.com/profile_images/974736784906248192/gPZwCbdS.jpg',
      name: 'Jon Doe',
      designation: 'CEO of RedQ Inc.',
      review: 4,
    },
    {
      id: 2,
      title: 'User friendly & Customizable',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore features Lorem ipsum dolor sit amet consectetur adipisicing.',
      avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
      name: 'Jeny Doe',
      designation: 'Co Founder of RedQ Inc.',
      review: 5,
    },
    {
      id: 3,
      title: 'User friendly & Customizable',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore features Lorem ipsum dolor sit amet consectetur adipisicing.',
      avatar: 'https://randomuser.me/api/portraits/men/44.jpg',
      name: 'Jon Doe',
      designation: 'Co Founder of RedQ Inc.',
      review: 5,
    },
  ],
};

/* ------------------------------------ */
// Footer data section
/* ------------------------------------ */
export const footer = {
  widgets: [
    {
      id: 1,
      icon: chat,
      title: 'Join the Community',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore.',
    },
    {
      id: 2,
      icon: group,
      title: 'Join in Chat Community',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore.',
    },
    {
      id: 3,
      icon: github,
      title: 'Github Access',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore.',
    },
  ],
  logo: footerLogo,
  menu: [
    {
      id: 1,
      text: 'Home',
      link: '#',
    },
    {
      id: 2,
      text: 'Adversite',
      link: '#',
    },
    {
      id: 3,
      text: 'Supports',
      link: '#',
    },
    {
      id: 4,
      text: 'Marketing',
      link: '#',
    },
    {
      id: 5,
      text: 'Contact',
      link: '#',
    },
  ],
};


/* ------------------------------------ */
// Available Store data section
/* ------------------------------------ */

export const availableStore = {
  title: 'We are available in both play store & app store ✌️',
  description:
    'Download now to have the seamless experience for your business.',
  thumb: availableThumb,
};

/* ------------------------------------ */
// Faq data section
/* ------------------------------------ */
export const faq = {
  slogan: 'FREQUENT QUESTION',
  title: 'Do you have any question',
  faqs: [
    {
      id: 1,
      question: 'Can we track a car with this app?',
      answer:
        'Yes, the app can track automobiles (car, truck, motorbike, etc.), walking/cycling persons and the person working at a specific location for hours.',
    },
    {
      id: 2,
      question: 'Which industry owner can use this app?',
      answer:
        'Any company which has field staff working at different locations can use it. Some industries are:</br></br><ul><li>&emsp;&#8226;Construction</li><li>&emsp;&#8226;Agriculture</li><li>&emsp;&#8226;Security</li><li>&emsp;&#8226;Cleaning</li><li>&emsp;&#8226;Event management</li><li>&emsp;&#8226;Pharmacies</li><li>&emsp;&#8226;Sales and marketing service providers</li><li>&emsp;&#8226;Street sellers</li><li>&emsp;&#8226;Home services like domestic help, Plumbers, electricians, etc.</ul>',
    },
    {
      id: 3,
      question: 'Can a company with thousands of employees use it?',
      answer:
        'Yes, the application is designed to handle millions of users together that are working on the field locations. Special discounts are given if the number of employees is more than 100.',
    },
    {
      id: 4,
      question: 'Can we calculate the working hours of employees through the app?',
      answer:
        'Yes, the application has the facility to calculate the payment based on the hours spent by the employees at the work location.',
    },
    {
      id: 5,
      question: 'Can we pay employees through the app?',
      answer:
        'Yes, the application has the functionality to pay directly to the employee in their bank account.',
    },
    {
      id: 6,
      question: 'Does the application allow to view the availability of employees?',
      answer:
        'Yes, through the calendar, the employer can see the available slots of the employees. Also, employees can check their schedules in the calendar.',
    },
    {
      id: 7,
      question: 'Can the customer see their work progress?',
      answer:
        'Yes, employers can decide if they want to allow the customers to check the progress of their work done.',
    },
    {
      id: 8,
      question: 'Can we make invoices for my clients?',
      answer:
        'Yes, there is a full business life from appointment to quotation to job management to invoicing.',
    },
    {
      id: 9,
      question: 'Can we track the workforce via mobile and desktop?',
      answer:
        'Yes, the workforce can be tracked by both mobile and desktop.',
    },
    {
      id: 10,
      question: 'Does the app help to monitor the ongoing work?',
      answer:
        'Yes, the application supports the video call feature which can help to monitor the live progress through the app.',
    },
    {
      id: 11,
      question: 'Does the app allow the employer to see the live location tracking?',
      answer:
        'Yes, the employer can track the live location of the employees on the field.',
    },
  ],
};

/* ------------------------------------ */
// Pricing data section
/* ------------------------------------ */
export const pricing = {
  title: 'Meet our exciting Pricing Plan',
  slogan: 'We designed our pricing plan with affordable price for users',
  pricingFeature: [
    {
      id: 1,
      name: 'Manage Company',
    },
    {
      id: 2,
      name: 'Manage Users',
    },
    {
      id: 3,
      name: 'Job Creation',
    },
    {
      id: 4,
      name: 'Tracking',
    },
    {
      id: 5,
      name: 'Payment',
    },
    {
      id: 6,
      name: 'Quotation',
    },
    {
      id: 7,
      name: 'Invoice',
    },
    {
      id: 8,
      name: 'Appointment',
    },
    {
      id: 9,
      name: 'Customer Login',
    },
    {
      id: 10,
      name: 'Share',
    },

    {
      id: 11,
      name: 'Chat',
    },
    {
      id: 12,
      name: 'Customer Chat',
    },
    {
      id: 13,
      name: 'Basic Report/Analytics',
    },
    {
      id: 14,
      name: 'Dashboard',
    },
    {
      id: 15,
      name: 'Calling (Audio/Video)',
    },
    {
      id: 16,
      name: 'Detailed Report/Analytics',
    },
  ],
  pricingItems: [
    {
      id: 1,
      package_name: 'Silver',
      price: '69.99',
      strikedPrice: '79.99',
      trial_day: 30,
      noOfUsers: 10,
      perUserPrice: '6.99',
      isRecommended: false,
      features: [
        {
          id: 1,
          name: 'Manage Company',
          isAvailable: true
        },
        {
          id: 2,
          name: 'Manage Users',
          isAvailable: true
        },
        {
          id: 3,
          name: 'Job Creation',
          isAvailable: true
        },
        {
          id: 4,
          name: 'Tracking',
          isAvailable: true
        },
        {
          id: 5,
          name: 'Payment',
          isAvailable: true
        },
        {
          id: 6,
          name: 'Quotation',
          isAvailable: false
        },
        {
          id: 7,
          name: 'Invoice',
          isAvailable: false
        },
        {
          id: 8,
          name: 'Appointment',
          isAvailable: false
        },
        {
          id: 9,
          name: 'Customer Login',
          isAvailable: false
        },
        {
          id: 10,
          name: 'Share',
          isAvailable: false
        },
        {
          id: 11,
          name: 'Chat',
          isAvailable: false
        },
        {
          id: 12,
          name: 'Customer Chat',
          isAvailable: false
        },
        {
          id: 13,
          name: 'Basic Report/Analytics',
          isAvailable: false
        },
        {
          id: 14,
          name: 'Dashboard',
          isAvailable: false
        },
        {
          id: 15,
          name: 'Calling (Audio/Video)',
          isAvailable: false
        },
        {
          id: 16,
          name: 'Detailed Report/Analytics',
          isAvailable: false
        },
      ],
    },
    {
      id: 2,
      package_name: 'Gold',
      price: '79.99',
      strikedPrice: '89.99',
      trial_day: 30,
      noOfUsers: 10,
      perUserPrice: '7.99',
      isRecommended: true,
      features: [
        {
          id: 1,
          name: 'Manage Company',
          isAvailable: true
        },
        {
          id: 2,
          name: 'Manage Users',
          isAvailable: true
        },
        {
          id: 3,
          name: 'Job Creation',
          isAvailable: true
        },
        {
          id: 4,
          name: 'Tracking',
          isAvailable: true
        },
        {
          id: 5,
          name: 'Payment',
          isAvailable: true
        },
        {
          id: 6,
          name: 'Quotation',
          isAvailable: true
        },
        {
          id: 7,
          name: 'Invoice',
          isAvailable: true
        },
        {
          id: 8,
          name: 'Appointment',
          isAvailable: true
        },
        {
          id: 9,
          name: 'Customer Login',
          isAvailable: true
        },
        {
          id: 10,
          name: 'Share',
          isAvailable: true
        },
        {
          id: 11,
          name: 'Chat',
          isAvailable: false
        },
        {
          id: 12,
          name: 'Customer Chat',
          isAvailable: false
        },
        {
          id: 13,
          name: 'Basic Report/Analytics',
          isAvailable: false
        },
        {
          id: 14,
          name: 'Dashboard',
          isAvailable: false
        },
        {
          id: 15,
          name: 'Calling (Audio/Video)',
          isAvailable: false
        },
        {
          id: 16,
          name: 'Detailed Report/Analytics',
          isAvailable: false
        },
      ],
    },
    {
      id: 3,
      package_name: 'Diamond',
      price: '89.99',
      strikedPrice: '99.99',
      trial_day: 30,
      noOfUsers: 10,
      perUserPrice: '8.99',
      isRecommended: false,
      features: [
        {
          id: 1,
          name: 'Manage Company',
          isAvailable: true
        },
        {
          id: 2,
          name: 'Manage Users',
          isAvailable: true
        },
        {
          id: 3,
          name: 'Job Creation',
          isAvailable: true
        },
        {
          id: 4,
          name: 'Tracking',
          isAvailable: true
        },
        {
          id: 5,
          name: 'Payment',
          isAvailable: true
        },
        {
          id: 6,
          name: 'Quotation',
          isAvailable: true
        },
        {
          id: 7,
          name: 'Invoice',
          isAvailable: true
        },
        {
          id: 8,
          name: 'Appointment',
          isAvailable: true
        },
        {
          id: 9,
          name: 'Customer Login',
          isAvailable: true
        },
        {
          id: 10,
          name: 'Share',
          isAvailable: true
        },
        {
          id: 11,
          name: 'Chat',
          isAvailable: true
        },
        {
          id: 12,
          name: 'Customer Chat',
          isAvailable: true
        },
        {
          id: 13,
          name: 'Basic Report/Analytics',
          isAvailable: true
        },
        {
          id: 14,
          name: 'Dashboard',
          isAvailable: true
        },
        {
          id: 15,
          name: 'Calling (Audio/Video)',
          isAvailable: false
        },
        {
          id: 16,
          name: 'Detailed Report/Analytics',
          isAvailable: false
        },
      ],
    },
    {
      id: 4,
      package_name: 'Platinum',
      price: '99.99',
      strikedPrice: '109.99',
      trial_day: 30,
      noOfUsers: 10,
      perUserPrice: '9.99',
      isRecommended: false,
      features: [
        {
          id: 1,
          name: 'Manage Company',
          isAvailable: true
        },
        {
          id: 2,
          name: 'Manage Users',
          isAvailable: true
        },
        {
          id: 3,
          name: 'Job Creation',
          isAvailable: true
        },
        {
          id: 4,
          name: 'Tracking',
          isAvailable: true
        },
        {
          id: 5,
          name: 'Payment',
          isAvailable: true
        },
        {
          id: 6,
          name: 'Quotation',
          isAvailable: true
        },
        {
          id: 7,
          name: 'Invoice',
          isAvailable: true
        },
        {
          id: 8,
          name: 'Appointment',
          isAvailable: true
        },
        {
          id: 9,
          name: 'Customer Login',
          isAvailable: true
        },
        {
          id: 10,
          name: 'Share',
          isAvailable: true
        },
        {
          id: 11,
          name: 'Chat',
          isAvailable: true
        },
        {
          id: 12,
          name: 'Customer Chat',
          isAvailable: true
        },
        {
          id: 13,
          name: 'Basic Report/Analytics',
          isAvailable: true
        },
        {
          id: 14,
          name: 'Dashboard',
          isAvailable: true
        },
        {
          id: 15,
          name: 'Calling (Audio/Video)',
          isAvailable: true
        },
        {
          id: 16,
          name: 'Detailed Report/Analytics',
          isAvailable: true
        },
      ],
    },
  ],
};

