import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { handlePostSearch } from "../../actions/postActions";
import { InfiniteScrollCNO } from "../../library";
import { Box } from "@material-ui/core";
import { mdiScriptTextOutline } from "@mdi/js";
import { MainPostType } from "../PostsManagement/enums";
import { PostListCard } from "../PostsManagement";
import JobCard from "../../components/JobCard/JobCard";
import { EventListItemFW } from "../EventManagement";
import { transformListData } from "../../utils/Helpers";
// import { useMediaQuery } from "@material-ui/core";


const PostDetails = () => {
  const [postId, setPostId] = useState("");
  const [post, setpost] = useState([]);
  // const tillTablet = useMediaQuery("(max-width: 767px)");

  const location = useLocation();
  const dispatch = useDispatch();

  const { posts, loadMore, hasMorePosts, postsLoader, isAddPostDialog } =
    useSelector((state) => state.posts);
  console.log("posss", posts);
  useEffect(() => {
    const path = location.pathname;
    const parts = path.split("/");
    const id = parts[parts.length - 1];
    setPostId(id);
  }, [location]);

  useEffect(() => {
    console.log("PostListingPage: useEffect: ", posts);

    const selectedPost = posts.filter((item) => item.id === postId);
    setpost(() => selectedPost);
    dispatch(handlePostSearch());
  }, [postId]);

  const renderListItem = (item) => {
    console.log("renderListItem: ", { item });

    item = transformListData(item);

    switch (item.postType) {
      case MainPostType.ids.Post:
        return <PostListCard key={item.id} post={item} details={"hii"} />;

      case MainPostType.ids.Job:
        return <JobCard key={item.id} item={item} />;

      case MainPostType.ids.Event:
        return <EventListItemFW key={item.id} event={item} />;

      default:
        return null;
    }
  };
  // const margin = tillTablet ? "auto" : "-350px";
  console.log("postId", postId);
  console.log("actualPost", post);
  console.log("real", posts);

  return (
    // <div style={{ marginLeft: margin }}>
      <Box padding={2} margin="0 auto">
        <InfiniteScrollCNO
          items={post}
          next={loadMore}
          hasMore={hasMorePosts}
          loading={postsLoader}
          renderListItem={renderListItem}
          notFoundTextMessage={"No Post Present"}
          notFoundIcon={mdiScriptTextOutline}
          showListCTAButtons={true}
          listCTAButtonsProps={{ addTitle: "Add Post" }}
        />
      </Box>
    // </div>
  );
};

export default PostDetails;
