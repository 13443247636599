import {
  Dialog,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Tooltip,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { Fragment, useEffect, useRef } from "react";
import ChipCNO from "../../library/ChipCNO";
import {
  mdiAccountTie,
  mdiCalendar,
  mdiCar,
  mdiCircleOutline,
  mdiDoctor,
  mdiEmail,
  mdiFacebook,
  mdiFormatText,
  mdiGenderFemale,
  mdiGenderMale,
  mdiHumanFemale,
  mdiHumanMale,
  mdiLinkedin,
  mdiMapMarkerPath,
  mdiSkype,
  mdiTwitter,
  mdiWalk,
  mdiPlus,
} from "@mdi/js";
import InputCNO from "../../library/InputCNO";
import Icon from "@mdi/react";
import "react-phone-number-input/style.css";
import HeadingBar from "../HeadingBar/HeadingBar";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import { SnapList, SnapItem, useDragToScroll } from "react-snaplist-carousel";
import { useDispatch, useSelector } from "react-redux";
import {
  Currency,
  CurrencyIcon,
  Gender,
  Salutation,
  TrackingMode,
} from "../../utils/mappings";
import { useState } from "react";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { useStyles } from "./AddServiceProviderStyles";
import {
  addServiceProvider,
  searchAlreadyServiceProviders,
  setAlreadyExistServiceProviders,
  toggleAddServiceProviderDialog,
} from "../../actions/serviceProviderActions";
import { DatePicker } from "@material-ui/pickers";
import GooglePlaceInputCNO from "../../library/GooglePlaceInputCNO/GooglePlaceInputCNO";
import * as icons from "@mdi/js";
import { getMaterialIconString } from "../../utils/icons";
import FormBox from "../FormBox";
import {
  getGenderBasedOnSalutation,
  getUserCountry,
} from "../../utils/Helpers";
import { useDebouncedFormik, useFormikErrors } from "../../hooks";
import AlreadyExistCustomerList from "../AlreadyExistCustomerList/AlreadyExistCustomerList";
import PhoneInputCNO from "../../library/PhoneInputCNO/PhoneInputCNO";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import AddServiceProviderErrorDialog from "./AddServiceProviderErrorDialog";
import { Button } from "@material-ui/core";
import JobChipsFilter from "../JobChipsFilter";
import CollageView from "../../library/collageViewOnSelectCNO/CollageView";
// import { PostMediaPicker } from "../../pages/PostsManagement/components";
import { PostMediaPicker } from "../../pages/PostsManagement";
import { toast } from "react-hot-toast";
import { getMediaData } from "../../utils/Helpers";
import { v4 } from "uuid";
import { AppConstants } from "../../utils/AppConstants";
function AddServiceProvider({ onAdd }) {
  let currencyType = localStorage.getItem("currencyType");
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const country = getUserCountry();

  const tillTablet = useMediaQuery("(max-width: 767px)");

  const theme = useTheme();

  const dispatch = useDispatch();
  const [mediaLinks, setMediaLinks] = useState([]);
  const {
    isAddServiceProviderDialog,
    alreadyExistServiceProviders,
    allServiceProvidersLoader: { addLoader },
  } = useSelector((state) => state.serviceProviders);

  const [isAlreadyExistServiceProvider, setIsAlreadyExistServiceProvider] =
    useState(false);
  const [numLocations, setNumLocations] = useState(1);

  const [isUserSelectedFromList, setIsUserSelectedFromList] = useState(false);
  const [isMoreDetailsExpanded, setIsMoreDetailsExpanded] = useState(false);
  const [addError, setAddError] = useState({ open: false, error: null });

  useEffect(() => {
    return () => {
      setIsAlreadyExistServiceProvider(false);
      formik.resetForm();
      dispatch(setAlreadyExistServiceProviders([]));
    };
  }, []);

  useEffect(() => {
    setIsAlreadyExistServiceProvider(
      alreadyExistServiceProviders.length ? true : false
    );
  }, [alreadyExistServiceProviders]);

  const handleClose = () => {
    localStorage.removeItem("addServiceProvider");

    dispatch(toggleAddServiceProviderDialog(false));
  };

  const validationSchema = yup.object({
    salutation: yup.string().required("Salutation is required."),
    firstName: yup.string().required("First Name is required."),
    lastName: yup.string().required("Last Name is required."),
    gender: yup.string(),
    birthday: yup.object().nullable(),
    abstract: yup.string(),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required."),
    mobile: yup.string().required("Mobile is required."),
    location: yup
      .array()
      .of(
        yup.object().shape({
          mapAddress: yup.string().required("Location is required"),
          lat: yup.number().required(),
          lng: yup.number().required(),
        })
      )
      .required("At least one location is required"),
    skype: yup.string(),
    facebook: yup.string(),
    twitter: yup.string(),
    linkedIn: yup.string(),
    trackingMode: yup.number(),
    hourlyRate: yup.number(),
    categories: yup
      .array()
      .of(yup.string())
      .required("At least one category is required"),
  });

  const formik = useFormik({
    initialValues: {
      salutation: "",
      firstName: "",
      lastName: "",
      gender: Gender.Others,
      birthday: new Date(),
      abstract: "",
      email: "",
      mobile: "",
      location: [{}],
      skype: "",
      facebook: "",
      twitter: "",
      linkedIn: "",
      trackingMode: TrackingMode.Fitness,
      hourlyRate: 0,
      dailyRate: 0,
      experience: 0,
      availability: "",
      mediaLinks: [],
      ratingsReviews: [],
      bio: "",
      categories: [],
    },
    validationSchema,
    onSubmit: (values) => handleAddServiceProvider(values),
  });

  const onAddSuccess = () => {
    if (onAdd) onAdd();

    handleClose();

    formik.resetForm();
  };

  const onAddError = (error) => {
    console.log("Add ServiceProvider: onAddError: ", error);

    if (error) {
      setAddError({ open: true, error });
    }
  };

  console.log("Add ServiceProvider: addError state: ", addError);

  const handleAddServiceProvider = async (values) => {
    if (isUserSelectedFromList) {
      onAddSuccess();
    } else {
      await dispatch(addServiceProvider(values, onAddSuccess, onAddError));
    }
  };

  console.log("formik addEmp: errors", formik.errors);
  console.log("formik addEmp: values", formik.values);

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  const styles = useStyles({ tillTablet });
  const GlobalStyles = useGlobalStyles();

  useFormikErrors(formik);
  useDebouncedFormik(formik, "addServiceProvider");

  const checkIfServiceProviderAlreadyExist = () => {
    const { firstName, lastName } = formik.values;
    const searchText = `${firstName} ${lastName}`.trim();

    const dataToSend = {
      searchText: searchText,
    };

    if (!searchText) {
      dispatch(setAlreadyExistServiceProviders([]));
      return;
    }

    dispatch(searchAlreadyServiceProviders(dataToSend));
  };

  const onMediaSelect = async (mediasToUpload) => {
    const mediasPromise = mediasToUpload.map(async (media) => {
      const { mediaData, fileType } = await getMediaData(media);
      return {
        id: v4(),
        link: mediaData,
        fileType: fileType,
      };
    });

    const toastId = toast.loading("Uploading...", {
      style: {
        minWidth: "200px",
        color: "black",
      },
    });
    const resolvedMedias = await Promise.all(mediasPromise);
    toast.dismiss(toastId);

    mediaLinks?.length + resolvedMedias?.length >
    AppConstants.MAX_NUMBER_OF_FILES
      ? toast.error(
          `Maximum number of allowed files are ${AppConstants.MAX_NUMBER_OF_FILES}.`
        )
      : (setMediaLinks((prevMediaLinks) => [
          ...prevMediaLinks,
          ...resolvedMedias,
        ]),
        formik.setFieldValue("mediaLinks", [
          ...formik.values.mediaLinks,
          ...resolvedMedias,
        ]));
  };

  const onMediaUnselect = (mediaId) => {
    setMediaLinks((prevMedia) => {
      const updatedMediaLinks = prevMedia.filter(
        (media) => media.id !== mediaId
      );
      formik.setFieldValue("mediaLinks", updatedMediaLinks);
      return updatedMediaLinks;
    });
  };

  return (
    <Dialog
      open={isAddServiceProviderDialog}
      classes={{ paper: styles.paper }}
      className={styles.container_AddServiceProvider}
    >
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        {addLoader && <FullScreenLoaderCNO />}

        <HeadingBar title="Add Service Provider" onClose={handleClose} />

        <FormBox title="Service Provider details">
          <div className={styles.selectChips}>
            <Typography component="label">
              Salutation
              <span style={{ color: theme.colors.ERROR_COLOR }}>{" *"}</span>
            </Typography>
            <SnapList className={styles.chipsWrapper}>
              <SnapItem>
                <ChipCNO
                  icon={mdiAccountTie}
                  name="Sir"
                  active={formik.values.salutation === Salutation.Sir}
                  onClick={() => {
                    formik.setFieldValue("salutation", Salutation.Sir);
                    formik.setFieldValue(
                      "gender",
                      getGenderBasedOnSalutation(Salutation.Sir)
                    );
                  }}
                />
              </SnapItem>
              <SnapItem>
                <ChipCNO
                  icon={mdiHumanMale}
                  name="Mr"
                  active={formik.values.salutation === Salutation.Mr}
                  onClick={() => {
                    formik.setFieldValue("salutation", Salutation.Mr);
                    formik.setFieldValue(
                      "gender",
                      getGenderBasedOnSalutation(Salutation.Mr)
                    );
                  }}
                />
              </SnapItem>
              <SnapItem>
                <ChipCNO
                  icon={mdiHumanFemale}
                  name="Ms"
                  active={formik.values.salutation === Salutation.Ms}
                  onClick={() => {
                    formik.setFieldValue("salutation", Salutation.Ms);
                    formik.setFieldValue(
                      "gender",
                      getGenderBasedOnSalutation(Salutation.Ms)
                    );
                  }}
                />
              </SnapItem>
              <SnapItem>
                <ChipCNO
                  icon={mdiHumanFemale}
                  name="Mrs"
                  active={formik.values.salutation === Salutation.Mrs}
                  onClick={() => {
                    formik.setFieldValue("salutation", Salutation.Mrs);
                    formik.setFieldValue(
                      "gender",
                      getGenderBasedOnSalutation(Salutation.Mrs)
                    );
                  }}
                />
              </SnapItem>
              <SnapItem>
                <ChipCNO
                  icon={mdiDoctor}
                  name="Dr"
                  active={formik.values.salutation === Salutation.Dr}
                  onClick={() => {
                    formik.setFieldValue("salutation", Salutation.Dr);
                    formik.setFieldValue(
                      "gender",
                      getGenderBasedOnSalutation(Salutation.None)
                    );
                  }}
                />
              </SnapItem>
            </SnapList>
          </div>
          <InputCNO
            name="firstName"
            label="First Name"
            formik={formik}
            placeholder="First Name"
            icon={mdiFormatText}
            fullWidth
            isRequired
            onBlur={() => checkIfServiceProviderAlreadyExist()}
            isBorderHighlight={isAlreadyExistServiceProvider}
          />
          <InputCNO
            name="lastName"
            label="Last Name"
            formik={formik}
            placeholder="Last Name"
            icon={mdiFormatText}
            fullWidth
            isRequired
            onBlur={() => checkIfServiceProviderAlreadyExist()}
            isBorderHighlight={isAlreadyExistServiceProvider}
          />

          <InputCNO
            name="bio"
            label="About You"
            formik={formik}
            placeholder="Enter your bio"
            icon={mdiFormatText}
            fullWidth
            onBlur={() => checkIfServiceProviderAlreadyExist()}
            isBorderHighlight={isAlreadyExistServiceProvider}
          />
        </FormBox>

        <FormBox title="Contact details">
          <InputCNO
            name="email"
            label="Email"
            type="email"
            formik={formik}
            placeholder="Email"
            icon={mdiEmail}
            fullWidth
            isRequired
          />

          <PhoneInputCNO
            label={"Mobile"}
            value={formik.values.mobile}
            onChange={(value) => formik.setFieldValue("mobile", value)}
            showHelperText={formik.errors.mobile}
            helperText={formik.errors.mobile}
            required={true}
            onValidatePhone={(error) => {
              if (!!error) formik.setFieldError("mobile", error);
            }}
          />

          <div style={{ marginTop: 10 }}>
            {Array(numLocations)
              .fill(null)
              .map((_, index) => (
                <GooglePlaceInputCNO
                  key={index}
                  address={formik.values.location[index]?.mapAddress || ""}
                  onSelectAddress={(address) => {
                    const locationObj = {
                      mapAddress: address.address,
                      lat: address.lat,
                      lng: address.lng,
                    };

                    const updatedLocations = [...formik.values.location];
                    updatedLocations[index] = locationObj;
                    formik.setFieldValue("location", updatedLocations);
                  }}
                  label={`Service Location ${index + 1}`}
                  isRequired
                />
              ))}
          </div>
          <Button
            style={{
              backgroundColor:
                theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
              color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
            }}
            variant="contained"
            onClick={() => setNumLocations(numLocations + 1)}
            startIcon={<Icon path={mdiPlus} size={1.1} />}
          >
            {`Add More Locations`}
          </Button>
        </FormBox>

        <FormBox title="Work details">
          <Typography component="label">
            Categories
            <span style={{ color: theme.colors.ERROR_COLOR }}>{" *"}</span>
          </Typography>
          <div
            className={GlobalStyles.verticalScrollChipsWrapper}
            style={{ marginTop: 12, marginBottom: 12 }}
          >
            <JobChipsFilter
              mode="service"
              onChipToggle={(categories) => {
                formik.setFieldValue("categories", categories);
              }}
            />
          </div>

          <InputCNO
            name="hourlyRate"
            label="Hourly Rate"
            formik={formik}
            placeholder="Hourly Rate"
            icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            fullWidth
          />

          <InputCNO
            name="dailyRate"
            label="Daily Rate"
            formik={formik}
            placeholder="Daily Rate"
            icon={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
            fullWidth
          />

          <InputCNO
            name="experience"
            label="Experience"
            formik={formik}
            placeholder="Enter experience in years "
            icon={icons.mdiAlphaECircleOutline}
            fullWidth
          />

          <InputCNO
            name="availability"
            label="Availability"
            formik={formik}
            placeholder="Enter Your Availability ( Mon-Fri, 9am-5pm )"
            icon={icons.mdiClockTimeEightOutline}
            fullWidth
          />
          <Typography component="label" className={styles.fadeMediaLabel}>Upload Media</Typography>

          {mediaLinks?.length > 0 && (
            <CollageView
              mediaLinks={mediaLinks}
              onMediaUnselect={onMediaUnselect}
            />
          )}
          <Box className={styles.pickerContainer}>
            <Box>
              <PostMediaPicker onMediaSelect={onMediaSelect} />
            </Box>

            <span className={styles.fadeMediaLabel}>{"(audio/video)"}</span>
          </Box>
        </FormBox>

        <div
          className={GlobalStyles.link}
          onClick={() => setIsMoreDetailsExpanded(!isMoreDetailsExpanded)}
        >
          {isMoreDetailsExpanded ? "Less Details" : "More Details"}
        </div>

        {isMoreDetailsExpanded && (
          <div className={GlobalStyles.moreDetailsContainer}>
            <FormBox title="Personal details">
              <div className={styles.selectChips}>
                <Typography component="label">Gender</Typography>
                <div className={styles.chipsWrapper}>
                  <ChipCNO
                    icon={mdiGenderFemale}
                    name="Female"
                    active={formik.values.gender === Gender.Female}
                    onClick={() =>
                      formik.setFieldValue("gender", Gender.Female)
                    }
                  />
                  <ChipCNO
                    icon={mdiGenderMale}
                    name="Male"
                    active={formik.values.gender === Gender.Male}
                    onClick={() => formik.setFieldValue("gender", Gender.Male)}
                  />
                  <ChipCNO
                    icon={mdiCircleOutline}
                    name="Others"
                    active={formik.values.gender === Gender.Others}
                    onClick={() =>
                      formik.setFieldValue("gender", Gender.Others)
                    }
                  />
                </div>
              </div>

              <Typography component="label" className={styles.label}>
                Birthday
              </Typography>
              <DatePicker
                className={styles.picker}
                margin="dense"
                value={formik.values.birthday}
                format="MMM dd, yyyy"
                inputVariant="outlined"
                placeholder="Enter birthday"
                onChange={(date) => formik.setFieldValue("birthday", date)}
                error={
                  formik.touched.birthday && Boolean(formik.errors.birthday)
                }
                helperText={formik.errors.birthday}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <Icon
                        color={theme.colors.INPUT_ICON_COLOR}
                        path={mdiCalendar}
                        size="1.5rem"
                      />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />

              <InputCNO
                name="abstract"
                label="Abstract"
                formik={formik}
                placeholder="Abstract"
                icon={mdiFormatText}
                fullWidth
                multiline
              />
            </FormBox>

            <FormBox title="Social details">
              <InputCNO
                name="skype"
                label="Skype"
                formik={formik}
                placeholder="Skype"
                icon={mdiSkype}
                fullWidth
              />

              <InputCNO
                name="facebook"
                label="Facebook"
                formik={formik}
                placeholder="Facebook"
                icon={mdiFacebook}
                fullWidth
              />

              <InputCNO
                name="twitter"
                label="Twitter"
                formik={formik}
                placeholder="Twitter"
                icon={mdiTwitter}
                fullWidth
              />

              <InputCNO
                name="linkedIn"
                label="LinkedIn"
                formik={formik}
                placeholder="LinkedIn"
                icon={mdiLinkedin}
                fullWidth
              />
            </FormBox>
          </div>
        )}

        {isAlreadyExistServiceProvider && (
          <AlreadyExistCustomerList
            customers={alreadyExistServiceProviders}
            onSelectItem={(selectedUser) => {
              if (Boolean(selectedUser)) {
                formik.setValues({ ...selectedUser });
              } else {
                formik.resetForm();
              }
              setIsUserSelectedFromList(Boolean(selectedUser));
            }}
          />
        )}

        <AddServiceProviderErrorDialog
          open={addError.open}
          error={addError.error}
          onClose={() => {
            setAddError({ open: false, error: null });
          }}
        />

        <FormActionButtons
          rightText={isUserSelectedFromList ? "OK" : "Add Service Provider"}
          leftClick={handleClose}
          formik={formik}
        />
      </form>
    </Dialog>
  );
}

export default AddServiceProvider;
