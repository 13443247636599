import { Typography, useTheme } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import Icon from "@mdi/react";
import React, { useEffect } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMoreTaskPaymentHistory,
  fetchTaskPaymentHistory,
} from "../../actions/taskSearchActions";
import { getMaterialIconString } from "../../utils/icons";
import { Currency, CurrencyIcon } from "../../utils/mappings";
import { useStyles } from "./PaymentHistoryTableStyles";
import * as icons from "@mdi/js";
import { formatTimeWithTZ } from "../../utils/Helpers";

function PaymentHistoryTable({ job }) {
  let currencyType = localStorage.getItem("currencyType");
  currencyType = currencyType ? JSON.parse(currencyType) : Currency.GBP;

  const theme = useTheme();

  const styles = useStyles();

  const dispatch = useDispatch();
  const {
    taskPaymentHistory,
    teamMember,
    hasMorePaymentHistory,
    taskPaymentHistoryLoader,
  } = useSelector((state) => state.taskSearch);

  // useEffect(() => {

  //   const payload = {
  //     pageIndex: 0,
  //     memberId: teamMember.id,
  //     taskId: job.id,
  //     zeroStart: true
  //   };

  //   dispatch(fetchTaskPaymentHistory(payload));

  // }, [teamMember, job.id]);

  const rows = taskPaymentHistory;

  const columns = [
    {
      field: "creationDate",
      headerName: "Date",
      width: 150,
      // flex: 2,
      renderHeader: () => {
        return (
          <div
            style={{
              color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR,
              fontSize: "0.8rem",
            }}
          >
            Dates
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <Moment format="DD MMM, YYYY">
            {formatTimeWithTZ(params.value)}
          </Moment>
        );
      },
    },
    {
      field: "bonus",
      headerName: "Bonus",
      flex: 1,
      // width: '100%',
      renderHeader: () => {
        return (
          <div
            style={{
              color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR,
              fontSize: "0.8rem",
              textAlign: "right",
              width: "100%",
              paddingRight: "1em",
            }}
          >
            Bonus
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <Typography
            style={{
              fontWeight: "normal",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              textAlign: "right",
              width: "100%",
              paddingRight: theme.spacing(2),
            }}
          >
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
              size="1rem"
            />
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      // width: '100%',
      renderHeader: () => {
        return (
          <div
            style={{
              color: theme.colors.WORK_HOUR_JOB_TITLE_LABEL_TEXT_COLOR,
              fontSize: "0.8rem",
              textAlign: "right",
              width: "100%",
              paddingRight: "1em",
            }}
          >
            Amount
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <Typography
            style={{
              fontWeight: "normal",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              textAlign: "right",
              width: "100%",
              paddingRight: theme.spacing(2),
            }}
          >
            <Icon
              path={icons[getMaterialIconString(CurrencyIcon[currencyType])]}
              size="1rem"
            />
            {params.value}
          </Typography>
        );
      },
    },
  ];

  const handleLoadMore = () => {
    if (hasMorePaymentHistory) {
      const payload = {
        pageIndex: 0,
        memberId: teamMember.id,
        taskId: job.id,
        zeroStart: false,
      };
      dispatch(fetchMoreTaskPaymentHistory(payload));
    }
  };

  return (
    <div className={styles.container_PaymentHistoryModal}>
      <div className={styles.grid}>
        {rows.length ? (
          <DataGrid
            rows={rows}
            columns={columns}
            rowHeight={35}
            hideFooterSelectedRowCount
            pageSize={5}
            autoHeight
            headerHeight={40}
            disableColumnMenu
            disableSelectionOnClick
            // onPageChange={handleLoadMore}
          />
        ) : (
          <Typography
            style={{
              fontSize: "0.7rem",
              textAlign: "center",
              color: "#dedede",
            }}
          >
            {taskPaymentHistoryLoader ? "Loading..." : "No payment done yet"}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default PaymentHistoryTable;
