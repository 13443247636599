
import { gif, location, emoji, media, tag ,lifeEvent} from '../../assets/AddMediaInPost'

export const options=[
    {
      id: 1,
      function_name: "Photo/Video",
      icon:media,
    },
    {
      id: 2,
      function_name: "Tag People",
      icon:tag,
    },
    {
      id: 3,
      function_name: "Feeling/activity",
      icon:emoji,
    },
    {
      id: 4,
      function_name: "Check in",
      icon:location,
    },
    {
      id: 5,
      function_name: "Gif",
      icon:gif,
    },
    {
      id: 6,
      function_name: "Life Event",
      icon:lifeEvent,
    }
    
  ];
  