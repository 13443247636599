import React from 'react'
import { ChipCNO } from '../../../library';
import { getMaterialIconString } from '../../../utils/icons';
import { useGlobalStyles } from '../../../utils/Styles/GlobalStyles'
import { getAttendeeResponseStatus } from '../helpers';
import * as icons from '@mdi/js';

const EventResponseActions = ({ event }) => {

  const GlobalStyles = useGlobalStyles();

  const attendeesResponseStatus = getAttendeeResponseStatus(event.attendees);

  console.log({ attendeesResponseStatus })

  return (
    <div className={GlobalStyles.chipsWrapper}>
      {
        attendeesResponseStatus.map(action => (
          <ChipCNO
            active={action.isTrue}
            icon={icons[getMaterialIconString(action.icon)]}
            name={action.title}
          />
        ))
      }
    </div>
  )
}

export default EventResponseActions