import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {
  mdiAccountDetails,
  mdiCheck,
  mdiTrashCan,
  mdiSendCircleOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState, useEffect } from "react";
import LinkCNO from "../../library/LinkCNO";
import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import ConfirmationDialogCNO from "../../library/ConfirmationDialogCNO";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteService,
  updateService,
  updateServiceStatus,
} from "../../actions/serviceActions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ServiceStatus } from "../../utils/mappings";
import RightsManager from "../../utils/RightsManager";
import EditServiceTimelineDialog from "../Service/EditServiceTimelineDialog";
import { useHistory } from "react-router-dom";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import "@culturehq/add-to-calendar/dist/styles.css";

import { getCustomerById } from "../../actions/customerActions";

import { mdiCheckboxMarkedCircleAutoOutline } from "@mdi/js";
import LocalStorageConstants from "../../utils/LocalStorageConstants";
import { provider } from "firebase-functions/lib/providers/analytics";
import {toast} from "react-toastify";

function ServiceListCardPopover({ service, closeMoreActions }) {
  if (!service) return <div></div>;

  const isUserAdmin = RightsManager.isAdmin();
  const isUserCustomer = RightsManager.isCustomer();
  const authUser = LocalStorageConstants.getItem("authUser");
  
  const theme = useTheme();
  const styles = useStyles();
  const GlobalStyles = useGlobalStyles();

  const isDraft = service.status === ServiceStatus.Draft;
  const isReady = service.status === ServiceStatus.Ready;
  const isSent = service.status === ServiceStatus.Sent;
  const isSentAccepted = service.status === ServiceStatus.SentAccepted;
  const isSentRejected = service.status === ServiceStatus.SentRejected;

  const isServiceDetails = useLocation().pathname.includes("/service/");

  const dispatch = useDispatch();
  const { deleteServiceLoader, editServiceLoader } = useSelector(
    (state) => state.services
  );

  const customerId = service.customerId ? service.customerId : null;
  console.log("customerId2: ", customerId);

  useEffect(() => {
    const payload = {
      ids: [customerId],
      orderBy: "newest",
    };
    customerId &&  dispatch(getCustomerById([customerId], false));
  }, [customerId, dispatch]);

  const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false);
  const [isConfirmApplyModal, setIsConfirmApplyModal] = useState(false);

  const [isAccept, setIsAccept] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isReadyToShare, setIsReadyToShare] = useState(false);
  const [targetStatus, setTargetStatus] = useState(ServiceStatus.Draft);
  const [isMarkedComplete, setMarkedComplete] = useState(false);

  const history = useHistory();

  let event = {
    name: service.title,
    details: service.description,
    location: service.mapAddress,
    startsAt: new Date(service.startTime),
    endsAt: new Date(service.endTime),
  };

  return (
    <div className={styles.container_MorePopover}>
      {(deleteServiceLoader || editServiceLoader) && <FullScreenLoaderCNO />}

      <List className={GlobalStyles.list}>
        {!isServiceDetails && (
          <LinkCNO to={`service/${service.id}`}>
            <ListItem className={GlobalStyles.listItem}>
              <ListItemText primary="Details" />
              <ListItemIcon>
                <IconButton edge="end">
                  <Icon
                    path={mdiAccountDetails}
                    size="1.5rem"
                    color={
                      theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR
                    }
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </LinkCNO>
        )}

        {
          // (isUserAdmin || isUserCustomer) &&
          isUserAdmin && (
            <ListItem
              className={GlobalStyles.listItem}
              onClick={() => {
                setMarkedComplete(true);
              }}
            >
              <ListItemText primary="Mark service Complete" />
              <ListItemIcon>
                <IconButton edge="end">
                  <Icon
                    path={mdiCheckboxMarkedCircleAutoOutline}
                    size="1.5rem"
                    color={
                      theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR
                    }
                  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          )
        }

        {isUserAdmin && isDraft && (
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setTargetStatus(ServiceStatus.Ready);
              setIsReadyToShare(true);
            }}
          >
            <ListItemText primary="Mark as Ready" />
            <ListItemIcon>
              <IconButton edge="end">
                <Icon
                  path={mdiCheck}
                  size="1.5rem"
                  color={
                    theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR
                  }
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        )}
        {isUserAdmin && (
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setIsConfirmApplyModal(true);
            }}
          >
            <ListItemText primary="Apply" />
            <ListItemIcon>
              <IconButton edge="end">
                <Icon
                  path={mdiSendCircleOutline}
                  size="1.5rem"
                  color={
                    theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR
                  }
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        )}

        {isUserAdmin && (
          <ListItem
            className={GlobalStyles.listItem}
            onClick={() => {
              setIsConfirmDeleteModal(true);
            }}
          >
            <ListItemText primary="Delete" />
            <ListItemIcon>
              <IconButton edge="end">
                <Icon
                  path={mdiTrashCan}
                  size="1.5rem"
                  color={
                    theme.colors.MORE_ACTIONS_JOB_DETAILS_OPTION_ICON_COLOR
                  }
                />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        )}
      </List>

      {isConfirmDeleteModal && (
        <ConfirmationDialogCNO
          open={isConfirmDeleteModal}
          onClose={() => setIsConfirmDeleteModal(false)}
          dialogTitle="Comfirm Delete Service"
          messages={["Are you sure you want to delete?"]}
          yesClick={async () => {
            const onDeleteService = () => {
              if (isServiceDetails) {
                history.push("/services");
              }
            };

            try {
              await dispatch(deleteService(service));
              onDeleteService();
            } catch (error) {
              console.log("Error: Service delete error");
            }
          }}
          rightText="Delete"
          closeMoreActions={closeMoreActions}
        />
      )}
      {isConfirmApplyModal && (
        <ConfirmationDialogCNO
          open={isConfirmApplyModal}
          onClose={() => setIsConfirmApplyModal(false)}
          dialogTitle="Apply For This Service"
          messages={["Are you interested in this service ?"]}
          yesClick={async () => {
            const onApplyService = () => {
              if (isServiceDetails) {
                history.push("/services");
              }
            };

            try {
              const payload = {
                id: authUser.id,
                title: authUser.firstName + " " + authUser.lastName,
                profilePic: authUser.profilePic,
                rating: authUser?.rating ? authUser.rating : 0,
                approveStatus: false,
              };

              const dataToSend = {
                ...service,
                serviceProviders: [payload, ...service.serviceProviders],
              };
              const onAdd=()=>{
                toast.success("Service applied successfully");
              }
              await dispatch(updateService(dataToSend,onAdd));
              onApplyService();
            } catch (error) {
              console.log("Error: Service Apply error");
            }
          }}
          rightText="Submit"
          closeMoreActions={closeMoreActions}
        />
      )}
      {(isAccept || isReadyToShare || isReject) && (
        <ConfirmationDialogCNO
          open={isAccept || isReadyToShare || isReject}
          onClose={() => {
            setIsAccept(false);
            setIsReadyToShare(false);
            setIsSend(false);
            setIsReject(false);
          }}
          dialogTitle="Update Service Status"
          messages={[
            isAccept ? "Are you sure you want to accept the Service?" : null,
            isReadyToShare ? "Are you sure draft is ready to share?" : null,
            isReject ? "Are you sure you want to reject the Service?" : null,
          ]}
          yesClick={() => {
            // const meta = getServiceAcceptHtmlString(service);

            const ServicePayload = {
              ...service,
              status: targetStatus,
              // meta: meta || "",
            };

            dispatch(updateServiceStatus(ServicePayload));
          }}
          rightText={"Yes"}
          closeMoreActions={closeMoreActions}
        />
      )}
      {
        // (isUserAdmin || isUserCustomer) &&
        isUserAdmin && isMarkedComplete && (
          <EditServiceTimelineDialog
            open={isMarkedComplete}
            onClose={() => setMarkedComplete(false)}
            service={service}
            closeMoreActions={closeMoreActions}
            isMarkedComplete={true}
          />
        )
      }
    </div>
  );
}

export default ServiceListCardPopover;

const useStyles = makeStyles((theme) => ({
  container_MorePopover: {
    padding: 5,
    overflow: "auto",
    background: theme.colors.MORE_ACTIONS_OPTIONS_CONTAINER_BACKGROUND_COLOR,
  },
}));
