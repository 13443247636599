import React from 'react';

function MapLegends() {

  const styles = {
    container: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bottom: 5,
      right: '15%',
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 5,
      padding: 2,
      backgroundColor: '#4E4E4E',
    },
    legendItem: {
      display: 'flex',
      alignItems: 'center',
      borderRight: '1px solid #fff',
      height: 25,
      padding: 10,
    },
    label: {
      color: '#fff',
      whiteSpace: 'pre',
      fontSize: '0.7rem'
    },
    lastLocationSymbol: {
      marginRight: 4,
      color: 'turquoise',
      fontWeight: 'bold',
      fontSize: '0.9rem',
      padding: '0 2px'
    },
    markerImg: {
      height: 20,
      objectFit: 'contain'
    }
  };

  return (
    <div style={styles.container}>

      <div style={styles.wrapper}>
        <div style={styles.legendItem}>
          <img style={styles.markerImg} src="http://maps.google.com/mapfiles/kml/paddle/red-circle.png" alt="red-marker" />
          <span style={styles.label}>Check In</span>
        </div>

        <div style={styles.legendItem}>
          <img style={styles.markerImg} src="http://maps.google.com/mapfiles/kml/paddle/grn-circle.png" alt="green-marker" />
          <span style={styles.label}>Check Out</span>
        </div>

        <div style={styles.legendItem}>
          <img style={styles.markerImg} src="http://maps.google.com/mapfiles/kml/paddle/purple-circle.png" alt="purple-marker" />
          <span style={styles.label}>App Restart</span>
        </div>

        <div style={styles.legendItem}>
          <img style={styles.markerImg} src="http://maps.google.com/mapfiles/kml/paddle/ylw-circle.png" alt="yellow-marker" />
          <span style={styles.label}>Done</span>
        </div>

        <div style={styles.legendItem}>
          <span style={{ width: 10, height: 10, backgroundColor: '#cfcecc', transform: 'rotate(45deg)', marginRight: 4 }} />
          <span style={styles.label}>Location Soft</span>
        </div>

        <div style={styles.legendItem}>
          <span style={{ width: 10, height: 10, backgroundColor: '#ff9100', transform: 'rotate(45deg)', marginRight: 4 }} />
          <span style={styles.label}>Location</span>
        </div>

        <div style={styles.legendItem}>
          <span style={{ ...styles.lastLocationSymbol, color: 'green' }}> X</span>
          <span style={styles.label}>Last Location Checkout</span>
        </div>

        <div style={{ ...styles.legendItem, borderRight: 0 }}>
          <span style={styles.lastLocationSymbol}>X</span>
          <span style={styles.label}>Last Location</span>
        </div>
      </div>

    </div >
  )
}

export default MapLegends