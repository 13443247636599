import { mdiRestore } from '@mdi/js';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleLeaveSearch, setAppointmentTimesFilter } from '../../actions/appointmentActions';
import FormActionButtons from '../../components/FormActionButtons';
import JobChipsFilter from '../../components/JobChipsFilter';
import { useGlobalStyles } from '../../utils/Styles/GlobalStyles';
import * as yup from 'yup';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import HeadingBar from '../../components/HeadingBar';
import { useGlobalStore } from '../../providers/GlobalProvider';
import { TOGGLE_SEARCH } from '../../utils/actions';
import InputLabelCNO from '../../library/InputLabelCNO';
import { TimesFilter } from '../../utils';
import DateTimePickerCNO from '../../library/DateTimePickerCNO';
import SelectCNO from '../../library/SelectCNO';
import { LeaveType } from '../../utils/mappings';

function LeaveFilterModal() {

  const tillTablet = useMediaQuery('(max-width: 768px)');

  const styles = useStyles({ tillTablet });
  const GlobalStyles = useGlobalStyles();

  const dispatch = useDispatch();
  const { leaveSearchParams } = useSelector(state => state.appointments);

  const { dispatchGlobal } = useGlobalStore();
  const closeLeaveFilterDialog = () => {
    dispatchGlobal({
      type: TOGGLE_SEARCH,
      payload: false
    });
  };

  const formik = useFormik({
    initialValues: {
      leaveType: null,
      leave: null,
      startDate: null,
      endDate: null
    },
    // validationSchema,
    onSubmit: (values) => {
      dispatch(handleLeaveSearch(values));
      closeLeaveFilterDialog();
    }
  });

  useEffect(() => {

    console.log("==========useEffectLeaveSearchParams", leaveSearchParams);

    formik.setValues(leaveSearchParams);
  }, [leaveSearchParams]);


  const handleResetForm = () => {
    formik.resetForm();
    dispatch(handleLeaveSearch(formik.initialValues));

    dispatch(setAppointmentTimesFilter(TimesFilter.None));

  };

  const triggerLeaveSearch = (values) => {
    if (!tillTablet) {
      dispatch(handleLeaveSearch(values));
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.container_LeaveFilterModal}>

      <HeadingBar
        title={formik.dirty ? "Reset" : "Search"}
        icon={formik.dirty ? mdiRestore : null}
        onHeaderClick={handleResetForm}
        onClose={formik.dirty ? undefined : closeLeaveFilterDialog}
      />

      <div style={{ marginTop: 12 }}></div>

      <SelectCNO
        name="leaveType"
        label="Select a Leave Type"
        options={LeaveType.entities}
        fullWidth
        containerStyles={{ width: "98%" }}
        value={formik.values.leaveType}
        formik={formik}
        isRequired={false}
        onChange={(value) => {
          triggerLeaveSearch({ ...formik.values, leaveType: value });
        }}
      />


      <div style={{ marginTop: 12 }} />

      <DateTimePickerCNO
        dateName="startDate"
        datePlaceholder="Start Date"
        datePickerLabel='Start by Date'
        onDateChange={date => {
          formik.setFieldValue('startDate', date);
        }}

        formik={formik}
        iconColor="red"
        isRequired={false}
      />

      <DateTimePickerCNO
        dateName="endDate"
        datePlaceholder="End Date"
        datePickerLabel='End Date'
        onDateChange={date => {
          formik.setFieldValue('endDate', date);
        }}

        formik={formik}
        minDate={formik.values.startDate}
        iconColor="green"
        isRequired={false}
      />

      {
        tillTablet &&
        <FormActionButtons
          rightText="Search"
          rightClick={formik.handleSubmit}
          leftClick={closeLeaveFilterDialog}
          formik={formik}
        />
      }

    </form>
  )
}

export default LeaveFilterModal

const useStyles = makeStyles((theme) => ({
  container_LeaveFilterModal: {
    padding: 8,
    '&>*:first-child': {
      height: ({ tillTablet }) => tillTablet ? 'auto' : 68
    },
  },

  chip: {
    background: '#444'
  }
}));