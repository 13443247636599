import { Box, Typography } from "@material-ui/core";
import React from "react";
import { PopoverPicker } from "./PopoverColorPicker";

const ColorPickerRow = ({ item, colors, setColors }) => {
  const handlePickerColorChange = (color) => {
    const newThemeConstants = item.themeConstants.reduce((result, item) => {
      result = {
        ...result,
        [item]: color,
      };

      return result;
    }, {});

    const newColors = {
      ...colors,
      ...newThemeConstants,
    };

    setColors(newColors);
  };

  const color = colors[item.themeConstants[0]];

  return (
    <Box
      marginTop={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mr={3}
    >
      <Typography style={{ marginRight: 8 }}>{item.name}</Typography>
      <PopoverPicker color={color} onChange={handlePickerColorChange} />
    </Box>
  );
};

export default ColorPickerRow;
