import React from "react";

import { useGlobalStyles } from "../../utils/Styles/GlobalStyles";
import MoreActionCNO from "../../library/MoreActionsCNO";
import { useSelector } from "react-redux";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import DetailsInfoSection from "../../components/DetailsInfoSection/DetailsInfoSection";
import { AppointmentStatus, AppointmentType } from "../../utils/mappings";
import {
  AppointmentStatusButtonBar,
  AppointmentCustomerDetailsCard,
  AppointmentMainDetailsCard,
  AppointmentListCardPopover,
  AppointmentTimeDetailsCard,
  AppointmentMediaDetailsCard,
  AppointmentAssignedUsersDetailsCard,
  AppointmentLocationDetailsCard,
} from "../../components/Appointment";
import { MediaType } from "../../utils";
import RightsManager from "../../utils/RightsManager";
import LeaveStatusButtonBar from "../../components/Appointment/LeaveStatusButtonBar";
import LeaveListCardPopover from "../../components/Appointment/LeaveListCardPopover";

function AppointmentDetails({ appointment }) {
  const isUserAdmin = RightsManager.isAdmin();
  const isUserCustomer = RightsManager.isCustomer();
  const isLeave = appointment.appointmentType
    ? appointment.appointmentType === AppointmentType.LEAVE
    : false;
  const GlobalStyles = useGlobalStyles();
  console.log("isLeave",{isLeave,appointment});
  const { editAppointmentLoader } = useSelector((state) => state.appointments);
  console.log("Details appointment->", appointment);

  return (
    <div className={GlobalStyles.detailsContainer}>
      {editAppointmentLoader && <FullScreenLoaderCNO />}

      <div className={GlobalStyles.statusBar}>
        {isLeave ? (
          <LeaveStatusButtonBar appointment={appointment} />
        ) : (
          <AppointmentStatusButtonBar appointment={appointment} />
        )}
        {isLeave ? (
          <MoreActionCNO>
            <LeaveListCardPopover appointment={appointment} />
          </MoreActionCNO>
        ) : (
          (isUserAdmin || isUserCustomer) && (
            <MoreActionCNO>
              <AppointmentListCardPopover appointment={appointment} />
            </MoreActionCNO>
          )
        )}
      </div>

      <AppointmentMainDetailsCard appointment={appointment} />

      <AppointmentMediaDetailsCard
        appointment={appointment}
        header="Medias"
        mediaType={MediaType.Input}
        userType="employee"
      />

        
      <AppointmentCustomerDetailsCard appointment={appointment} isLeave={isLeave}/>

      <AppointmentAssignedUsersDetailsCard appointment={appointment} isLeave={isLeave} />

      {!isLeave && <AppointmentLocationDetailsCard appointment={appointment} />}

      <AppointmentTimeDetailsCard appointment={appointment} isLeave={isLeave} />
    </div>
  );
}

export default AppointmentDetails;
