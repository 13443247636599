import { combineReducers } from 'redux';
import authUserReducer from './authUserReducer';
import { customerReducer } from './customerReducer';
import jobsReducer from './jobsReducer';
import servicesReducer from './serviceReducer';
import suggestionsReducer from './suggestionsReducer';
import taskSearchReducer from './taskSearchReducer';
import trackSearchReducer from './trackSearchReducer';
import { notificationReducer } from './notificationReducer';
import chatReducer from './chatReducer';
import connectionHubReducer from './connectionHubReducer';
import { employeesReducer } from './employeeReducer';
import { companiesReducer } from './customerCompanyReducer';
import { quotationsReducer } from './quotationReducder';
import { subTaskReducer } from './subTaskReducer';
import { invoicesReducer } from './invoiceReducer';
import { invoiceItemReducer } from './invoiceItemReducer';
import { quotationItemReducer } from './quotationItemReducer';
import { subscriptionReducer } from './subscriptionReducer';
import { LOG_OUT } from '../actions/authUserActions';
import { appointmentReducer } from './appointmentReducer';
import { calendarReducer } from './calendarReducer';
import { appReducer } from './appReducer';
import { dashboardReducer } from './dashboardReducer';
import { eventReducer } from './eventsReducer';
import { formBuilderReducer } from './formBuilderReducer';
import { postReducer } from './postReducer';
import { eventDiscussionsReducer } from './eventDiscussionReducer';
import { postListReducer } from './postListReducer';
import { visitorReducer } from './visitorReducer';
import { serviceProviderReducer } from './serviceProviderReducer';

const allReducers = combineReducers({
  authUser: authUserReducer,
  jobs: jobsReducer,
  services:servicesReducer,
  serviceProviders:serviceProviderReducer,
  suggestions: suggestionsReducer,
  taskSearch: taskSearchReducer,
  trackSearch: trackSearchReducer,
  customers: customerReducer,
  notifications: notificationReducer,
  chats: chatReducer,
  connection: connectionHubReducer,
  employees: employeesReducer,
  companies: companiesReducer,
  quotations: quotationsReducer,
  subTasks: subTaskReducer,
  invoices: invoicesReducer,
  invoiceItems: invoiceItemReducer,
  quotationItems: quotationItemReducer,
  subscription: subscriptionReducer,
  appointments: appointmentReducer,
  calendar: calendarReducer,
  app: appReducer,
  dashboard: dashboardReducer,
  events: eventReducer,
  eventDiscussions: eventDiscussionsReducer,
  formBuilder: formBuilderReducer,
  posts: postReducer,
  postListTypes:postListReducer,
  visitor:visitorReducer,
});

const rootReducer = (state, action) => {

  if (action.type === LOG_OUT) {
    return allReducers(undefined, action);
  }

  return allReducers(state, action);

};

export default rootReducer;