export const field=[
    {
        name:'goto',
        label:'Go To',
        attributes:{
            options:[
                {
                    label:'2024',
                    value:'2024',
                },
                {
                    label:'2023',
                    value:'2023',
                },
                
                
            ]
        }
    },
    {
        name:'postedBy',
        label:'Posted By',
        attributes:{
            options:[
                {
                    label:'Anyone',
                    value:'Anyone',
                },
                {
                    label:'You',
                    value:'You',
                },
                {
                    label:'Others',
                    value:'Others',
                }
            ]
        }
    },
    {
        name:'privacy',
        label:'Privacy',
        attributes:{
            options:[
                {
                    label:'Public',
                    value:'Public',
                },
                {
                    label:'Friends',
                    value:'Friends',
                },
                {
                    label:'Private',
                    value:'Private',
                },
                {
                    label:'All Posts',
                    value:'All Posts',
                }
            ]
        }
    },
    {
        name:'taggedPosts',
        label:'Tagged Posts',
        attributes:{
            options:[
                {
                    label:'All Posts',
                    value:'All Posts',
                },
                {
                    label:"Only Show Posts I'm tagged In",
                    value:"Only Show Posts I'm tagged In",
                },
                
            ]
        }
    },
    {
        name:'showHiddenPosts',
        label:'Show Hidden Posts',
        attributes:{
            options:[
                {
                    label:'True',
                    value:"True"
                },
                {
                    label:"False",
                    value:"False"
                },
                
            ]
        }
    },
]