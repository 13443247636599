import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import HeadingBar from "../HeadingBar";
import { SnapList, useDragToScroll, SnapItem } from "react-snaplist-carousel";
import InputCNO from "../../library/InputCNO";
import { useFormik } from "formik";
import {
  mdiContentSave,
  mdiFileCancel,
  mdiPencilBoxOutline,
  mdiAccountCircleOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { useDispatch, useSelector } from "react-redux";
import {
  addLeave,
  toggleAddLeaveDialog,
} from "../../actions/appointmentActions";

import FlexibleSpaceCNO from "../../library/FlexibleSpaceCNO/FlexibleSpaceCNO";
import FormBox from "../FormBox/FormBox";
import * as yup from "yup";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import DateTimePickerCNO from "../../library/DateTimePickerCNO";
import { useDebouncedFormik, useFormikErrors } from "../../hooks";
import { validateStartDateEndDate } from "../../utils/Helpers";
import moment, { duration } from "moment";
import { setAddModalsCalendarDates } from "../../actions/calendarActions";
import { mdiEmail } from "@mdi/js";
import { LeaveStatus, LeaveType } from "../../utils/mappings";
import SelectCNO from "../../library/SelectCNO/SelectCNO";
import { ChipCNO, InputLabelCNO } from "../../library";
import UserSuggestionOption from "../UserSuggestionOption";
import { fetchMemberSuggest, resetSuggestions } from "../../actions/suggestionsActions";
import { Autocomplete } from "@material-ui/lab";

function AddLeave() {
  const tillTablet = useMediaQuery("(max-width: 767px)");

  const theme = useTheme();
  const styles = useStyles({ tillTablet });

  const dispatch = useDispatch();

  const suggestions = useSelector(state => state.suggestions);
  const { addAppointmentLoader, isAddLeaveDialog } = useSelector(
    (state) => state.appointments
  );
  const { addModalsCalendarDates } = useSelector((state) => state.calendar);
  const { startDate, startTime, endDate, endTime } = addModalsCalendarDates;

  console.log("======addModalsCalendarDates======", addModalsCalendarDates);

  const snapList = useRef(null);
  useDragToScroll({ ref: snapList });

  const [remainingLeaves, setRemainingLeaves] = useState(15); // REMAINING LEAVES SHOULD BE FETCHED FROM CURRENT USER DATA
  const [leaveDuration, setLeaveDuration] = useState(0);
  const [leaveBalance, setLeaveBalance] = useState(
    remainingLeaves - leaveDuration
  );
  const [warning, setWarning] = useState(false);

  const WARNING_MESSAGE =
    "You are exceeding your leaves. You don't have sufficient leaves!";

  // let localCategories = localStorage.getItem("categories");
  // localCategories = localCategories ? JSON.parse(localCategories) : [];

  const handleClose = () => {
    localStorage.removeItem("addLeave");
    dispatch(toggleAddLeaveDialog(false));
    formik.handleReset();
  };

  const validationSchema = yup.object().shape({
    leaveType: yup.number().required("Leave type is required!"),
    description: yup.string().required("Reason for leave is required!"),
    startDate: yup.string().required("Leave start date is required!"),
    endDate: yup
      .string()
      .required("Leave end date is required!")
      .test(
        "endDateTimeValidation",
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({
            startDate,
            startTime,
            endDate,
            endTime,
          });

          return isValid;
        }
      ),
    startTime: yup.string().required("Leave start time is required!"),
    endTime: yup
      .string()
      .required("Leave end time is required!")
      .test(
        "endDateTimeValidation",
        "End Date can't be less than Start Date.",
        function () {
          const startDate = this.parent.startDate;
          const startTime = this.parent.startTime;
          const endDate = this.parent.endDate;
          const endTime = this.parent.endTime;

          const isValid = validateStartDateEndDate({
            startDate,
            startTime,
            endDate,
            endTime,
          });

          return isValid;
        }
      ),
    // categories: yup.array().required("Category is required!"),
    reportingManager: yup.string(),
    // duration: yup.number().test("Leave Duration Validation","Leave duration can't exceed remaining leaves",function(){
    //   return remainingLeaves >= duration;
    // }),
  });

  const now = new Date();
  const formik = useFormik({
    initialValues: {
      leaveType: "",
      description: "",
      startDate: startDate || now,
      startTime: startTime || now,
      endDate: endDate || now,
      endTime: endTime || moment(now).add(30, "minute").toDate(),
      duration: "",
      leaveBalance: "",
      status: LeaveStatus.PENDING,
      mediaLinks: [],
      // categories: [],
      // reportingManager: "",
      appointmentAssignments: [],
      approvalMessage: "",
      rejectionMessage: "",
    },
    validationSchema,
    onSubmit: (values) => handleAddLeave(values),
  });

  useFormikErrors(formik);
  useDebouncedFormik(formik, "addLeave");

  useEffect(() => {
    return () => {
      dispatch(
        setAddModalsCalendarDates({
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        })
      );
    };
  }, []);

  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : [];
  const assignedUser = [...formik.values.appointmentAssignments, authUser];

  const unSelectedSuggestions = () => suggestions.filter(item => !assignedUser.some(assign => assign.id === item.id));

  useEffect(() => {
    const startDate = new Date(formik.values.startDate);
    const endDate = new Date(formik.values.endDate);

    const differenceInMilliseconds = endDate - startDate;

    let durationInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    durationInDays = durationInDays < 1 ? 1 : Math.ceil(durationInDays);

    remainingLeaves - durationInDays < 0 ? setWarning(true) : setWarning(false);

    setLeaveDuration(durationInDays);
    setLeaveBalance(remainingLeaves - durationInDays);
  }, [formik.values.startDate, formik.values.endDate]);

  const onAddSuccess = () => {
    formik.resetForm();
    handleClose();
  };

  const handleAddLeave = async (values) => {
    console.log("Add Leave values: ", values);

    let assignedUsers = authUser ? [...values.appointmentAssignments, authUser] : values.appointmentAssignments;

    console.log("Add Appointment assignedUsers: ", assignedUsers);
    console.log("Add Appointment authUser: ", authUser);

    const payload = {
      ...values,
      duration: leaveDuration,
      leaveBalance: leaveBalance,
      appointmentAssignments: assignedUsers.map(item => (
        {
          staffId: item.id,
          staffText: item.title || `${item.firstName} ${item.lastName}` || item.staffText,
          startDate: values.startDate,
          startTime: values.startTime,
          endDate: values.endDate,
          endTime: values.endTime
        }
      )),
    };

    console.log("Add Leave payload: ", payload);

    await dispatch(addLeave(payload, onAddSuccess));
  };

  console.log("====formikValues========", formik.values);

  return (
    <Dialog
      open={isAddLeaveDialog}
      classes={{ paper: styles.paper }}
      className={styles.container}
    >
      {addAppointmentLoader && <FullScreenLoaderCNO />}

      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <HeadingBar title="Add Leave" onClose={handleClose} />

        <FormBox className={styles.sectionContainer}>
          <SelectCNO
            name="leaveType"
            label="Select a Leave Type"
            options={LeaveType.entities}
            fullWidth
            containerStyles={{ width: "98%" }}
            value={formik.values.leaveType}
            formik={formik}
            isRequired
          />

          {/* <InputCNO
            name="reportingManager"
            label="Reporting Manager"
            formik={formik}
            placeholder="Select Reporting Manager"
            icon={mdiAccountCircleOutline}
            fullWidth
          /> */}
          <div className={styles.assignToWrapper}>
            <InputLabelCNO label={"Reporting Manager"} />
            
            <Autocomplete
              className={styles.textField}
              classes={{
                paper: styles.assignPaper,
                noOptions: styles.noOptions,
                popper: styles.popper,
              }}
              multiple
              forcePopupIcon={false}
              options={unSelectedSuggestions()}
              //closeIcon={() => null}
              getOptionLabel={(option) => option.title}
              renderOption={(option) => <UserSuggestionOption user={option} />}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    key={index}
                    variant="outlined"
                    label={option.title}
                    {...getTagProps({ index })}
                    className={styles.chip}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Reporting Managers"
                />
              )}
              onChange={(e, newValue) => {
                formik.setFieldValue("appointmentAssignments", newValue);
              }}
              onInputChange={(e) => {
                if (e && e.target.value) {
                  dispatch(fetchMemberSuggest(e.target.value));
                }
              }}
              onBlur={() => {
                dispatch(resetSuggestions());
              }}
            />
          </div>

          <div className={styles.sectionContainer}>
            <DateTimePickerCNO
              datePickerLabel="Start Date"
              dateName={"startDate"}
              datePlaceholder={"Start Date"}
              disablePast={true}
              onDateChange={(date) => formik.setFieldValue("startDate", date)}
              timePlaceholder={"Start Time"}
              timeName={"startTime"}
              onTimeChange={(date) => {
                formik.setValues({
                  ...formik.values,
                  startTime: date,
                  endTime: moment(date).add(30, "minute").toDate(),
                });
              }}
              formik={formik}
              iconColor={"red"}
            />

            <DateTimePickerCNO
              datePickerLabel="End Date"
              dateName={"endDate"}
              datePlaceholder={"End Date"}
              minDate={formik.values.startDate}
              onDateChange={(date) => formik.setFieldValue("endDate", date)}
              timePlaceholder={"End Time"}
              timeName={"endTime"}
              onTimeChange={(date) => formik.setFieldValue("endTime", date)}
              formik={formik}
              iconColor={"green"}
            />
          </div>

          <div>
            <p className={styles.tex}>
              {`Leave Balance : `}
              <span className={warning ? styles.warning : styles.success}>
                {leaveBalance}
              </span>
            </p>
          </div>

          {warning && (
            <Typography className={styles.warning}>
              {WARNING_MESSAGE}
            </Typography>
          )}

          {/* <div>
            <InputLabelCNO label={"Category"} isRequired={true} />
            <SnapList ref={snapList} className={styles.jobChipSlider}>
              {localCategories.map((category) => (
                <SnapItem>
                  <ChipCNO
                    key={category.id}
                    icon={category.iconLink}
                    name={category.title}
                    isCategoryChip={true}
                    active={formik.values.categories[0] === category.id}
                    onClick={() =>
                      formik.setFieldValue("categories", [category.id])
                    }
                  />
                </SnapItem>
              ))}
            </SnapList>
          </div> */}

          <div>
            <InputCNO
              name="description"
              label="Reason for leave"
              formik={formik}
              placeholder="Why are you taking leave ?"
              fullWidth
              multiline
              isRequired
            />
          </div>

          {/* TODO : MEDIA SUPPORT */}
        </FormBox>

        <div className={styles.ctaWrapper}>
          <Button
            variant="contained"
            className={styles.left}
            startIcon={
              <Icon
                path={mdiFileCancel}
                size={"1rem"}
                color={theme.colors.FORM_CANCEL_BUTTON_ICON_COLOR}
              />
            }
            onClick={handleClose}
          >
            Cancel
          </Button>

          <FlexibleSpaceCNO />

          <Button
            type="submit"
            variant="contained"
            className={styles.right}
            startIcon={
              <Icon
                path={mdiContentSave}
                size={"1rem"}
                color={theme.colors.FORM_CONFIRM_BUTTON_ICON_COLOR}
              />
            }
            disabled={formik && (!formik.dirty || !formik.isValid)}
          >
            <pre>Save</pre>
          </Button>
        </div>
      </form>
    </Dialog>
  );
}

export default AddLeave;

const useStyles = makeStyles((theme) => ({
  container: {},
  paper: {
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    borderRadius: 10,
    width: "70vw",
    maxWidth: "90vw",
    margin: ({ tillTablet }) => (tillTablet ? 8 : 32),
  },
  form: {
    padding: "20px",
    background: theme.colors.MODAL_BACKGROUND_COLOR,
    overflowX: "hidden",
    "&>*": {
      marginBottom: theme.spacing(3),
    },
  },

  sectionContainer: {
    "&>*": {
      marginTop: theme.spacing(2),
    },
  },
  jobChipSlider: {
    display: "flex",
    padding: "5px 0",
    "&>*:not(:first-child)": {
      marginLeft: 15,
    },
  },
  ctaWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(6),
    "& > *": {
      marginLeft: 4,
    },
  },
  left: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
  },
  right: {
    background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
  },
  warning: {
    color: "red",
    fontWeight: 400,
  },
  text: {
    padding: "1px",
  },
  success: {
    color: "#6fcd23",
    fontWeight: 400,
  },
  assignToWrapper: {
    display: 'flex',
    marginTop: 8,
    flexDirection: 'column',
    '&>*:first-child': {
      flex: 1,
      marginBottom: 8
    }
  },
  textField: {
    borderRadius: 5,
    '& input': {
      background: 'white',
      color: '#444',
      borderRadius: 5,
      padding: 8,
    },
    '& .MuiOutlinedInput-multiline': {
      color: '#444'
    },
    '& .MuiOutlinedInput-adornedStart': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      "-webkit-box-shadow": "0 0 0 100px #fff inset",
      "-webkit-text-fill-color": "#444",
      caretColor: "#444",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 14
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0
    },
    '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      padding: 0
    }
  },
  assignPaper: {
    background: 'white !important',
    '& ::-webkit-scrollbar': {
      display: 'none'
    }
  },
  noOptions: {
    color: '#BE0A02'
  },
  popper: {
    zIndex: 1301
  },
  chip: {
    background: theme.palette.background.light
  },
}));
