import { useFormik } from 'formik';
import React, { Fragment, useEffect } from 'react';
import Expandable from '../../library/Expandable';
import * as yup from 'yup';
import InputCNO from '../../library/InputCNO';
import LocalStorageConstants from '../../utils/LocalStorageConstants';
import FormActionButtons from '../FormActionButtons';
import { updateCompanyDetails } from '../../actions/customerCompanyActions';
import { useDispatch } from 'react-redux';
import { mdiClockCheckOutline } from '@mdi/js';
import FieldHelperTextCNO from '../../library/FieldHelperTextCNO';
import { useTheme } from '@material-ui/core';

function AppointmentEmailTemplateExpandable({ profile, isActive }) {

  const theme = useTheme();

  const authUserCompany = LocalStorageConstants.getItem("authUserCompany");

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    appointmentEmailTemplate: yup
      .string()
      .matches(/<<Name>>/, "<<Name>> is required as it will be replaced by the Customer Name.")
      .matches(/<<Title>>/, "<<Title>> is required as it will be replaced by the Appointment Title.")
      .matches(/<<StartDate>>/, "<<StartDate>> is required as it will be replaced by the Start Date.")
      .matches(/<<StartTime>>/, "<<StartTime>> is required as it will be replaced by the Start Time.")
      .matches(/<<EndDate>>/, "<<EndDate>> is required as it will be replaced by the End Date.")
      .matches(/<<EndTime>>/, "<<EndTime>> is required as it will be replaced by the End Time.")
      .required("Appointment Email Template is required."),
  });

  const handleSubmit = (values) => {

    const dataToSend = {
      ...profile,
      ...authUserCompany,
      appointmentEmailTemplate: values.appointmentEmailTemplate
    };

    dispatch(updateCompanyDetails(dataToSend));

  };

  const formik = useFormik({
    initialValues: {
      appointmentEmailTemplate: authUserCompany.appointmentEmailTemplate,
    },
    validationSchema,
    onSubmit: values => handleSubmit(values)
  });

  return (
    <Expandable
      title='Appointment Email Template'
      icon={mdiClockCheckOutline}
      disabled={!isActive}
      containerStyle={{ background: theme.colors.PROFILE_CARD_BACKGROUND_COLOR }}
    >
      <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>

        <InputCNO
          formik={formik}
          name="appointmentEmailTemplate"
          placeholder="Enter your Appointment message"
          rows={10}
          isRequired={true}
          multiline={true}
          disabled={!isActive}
          containerStyles={{ width: "100%" }}
        />

        <FieldHelperTextCNO
          helperText={<Fragment>
            <div>In email following will be replaced as given below, please do not remove it from template:</div>
            <div>&lt;&lt;Name&gt;&gt; will be replaced by Customer Name</div>
            <div>&lt;&lt;Title&gt;&gt; will be replaced by Quotation Title</div>
            <div>&lt;&lt;StartDate&gt;&gt; will be replaced by Start Date</div>
            <div>&lt;&lt;StartTime&gt;&gt; will be replaced by Start Time</div>
            <div>&lt;&lt;EndDate&gt;&gt; will be replaced by End Date</div>
            <div>&lt;&lt;EndTime&gt;&gt; will be replaced by End Time</div>
          </Fragment>}
          showHelperText={true}
          errorColor={false}
        />

        <FormActionButtons
          rightText="Save"
          isLeftButton={false}
          formik={formik}
        />

      </form>
    </Expandable>
  )
}

export default AppointmentEmailTemplateExpandable