import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs, { TabPane } from 'rc-tabs';
// import TabContent from 'rc-tabs/lib/TabContent';
// import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import Heading from '../../../common/components/Heading';
import Input from '../../../common/components/Input';
import CheckBox from '../../../common/components/Checkbox/index';
import ButtonLP from '../../../common/components/Button';
import Image from '../../../common/components/Image';
import LoginModalWrapper from './loginModal.style';
import 'rc-tabs/assets/index.css';
import LogoImage from '../../../common/assets/image/LandingPage/logo.png';
import LoginImage from '../../../common/assets/image/LandingPage/login-bg.jpg';
import GoogleLogo from '../../../common/assets/image/LandingPage/google-icon.jpg';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../actions/authUserActions';
import InputCNO from '../../../library/InputCNO';
import { mdiEmail, mdiEye, mdiEyeOff, mdiLock } from '@mdi/js';
import { Button, makeStyles, useTheme } from '@material-ui/core';
import RegisterCompany from '../../RegisterCompany';
import RegisterCompanyLP from '../RegisterCompanyLP';
import FormBox from '../../FormBox';
import FullScreenLoaderCNO from '../../../library/FullScreenLoaderCNO';

const LoginModal = ({
  row,
  col,
  btnStyle,
  logoStyle,
  titleStyle,
  contentWrapper,
  outlineBtnStyle,
  descriptionStyle,
  googleButtonStyle,
}) => {

  const LoginButtonGroup = (props) => (
    <Fragment>
      <ButtonLP
        className="default"
        title="LOGIN"
        type={'submit'}
        {...props}
        {...btnStyle}
      />
      <ButtonLP
        title="Forget Password"
        variant="textButton"
        {...outlineBtnStyle}
      />
    </Fragment>
  );

  const SignupButtonGroup = () => (
    <Fragment>
      <ButtonLP className="default" title="REGISTER" {...btnStyle} />
    </Fragment>
  );

  const dispatch = useDispatch();

  const { logInLoader } = useSelector(state => state.authUser);
  const { loginModalDefaultTab } = useSelector(state => state.app);
  const [defaultTabPane, setDefaultTabPane] = useState(loginModalDefaultTab);

  const [isPasswordSecure, setIsPasswordSecure] = useState(true);

  useEffect(() => {
    setDefaultTabPane(loginModalDefaultTab);
  }, [loginModalDefaultTab]);

  const handleLogin = (values) => {
    dispatch(login(values.email, values.password));
  };

  const validationSchema = yup.object({
    email: yup.string().email("Please enter a valid email.").required("Email is required."),
    password: yup.string().min(4, "Atleast 4 characters are required").required("Password is required.")
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit: values => handleLogin(values)
  });

  const theme = useTheme();
  const styles = useStyles();

  return (
    <LoginModalWrapper>

      {
        logInLoader &&
        <FullScreenLoaderCNO loading={logInLoader} />
      }

      <Box >
        {/* <Box className="col imageCol" {...col}>
          <img className="patternImage" src={LoginImage} alt="Login Banner" height="100%" />
        </Box> */}
        <Box >
          <Box {...contentWrapper} style={{ padding: 0 }}>
            {/* <Image src={LogoImage} {...logoStyle} alt="Logo" /> */}
            <Tabs
              defaultActiveKey={defaultTabPane}
              animated={{ tabPane: true }}
              moreIcon={false}
              tabBarStyle={{ marginRight: 20 }}
            >
              <TabPane tab="LOGIN" key="loginForm">

                <form onSubmit={formik.handleSubmit} style={{ marginTop: 20 }}>
                  <FormBox bgColor={theme.colors.MODAL_BACKGROUND_COLOR}>

                    <InputCNO
                      name='email'
                      formik={formik}
                      placeholder='Email'
                      icon={mdiEmail}
                      fullWidth
                    />
                    <InputCNO
                      name='password'
                      type={isPasswordSecure ? "password" : 'text'}
                      formik={formik}
                      placeholder='Password'
                      icon={mdiLock}
                      rightIcon={isPasswordSecure ? mdiEye : mdiEyeOff}
                      onRightIconClick={() => setIsPasswordSecure(!isPasswordSecure)}
                      isCopyAllowed={false}
                      isPasteAllowed={false}
                      fullWidth
                    />

                  </FormBox>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginTop: 30
                    }}
                  >
                    <Button
                      color='primary'
                      type='submit'
                      variant='contained'
                      className={styles.loginBtn}
                      disabled={!formik.dirty || !formik.isValid}
                    >
                      Login
                    </Button>

                  </div>

                </form>

              </TabPane>
              <TabPane tab="REGISTER" key="registerForm" style={{ padding: 0 }}>

                <RegisterCompanyLP onRegister={email => {
                  formik.setFieldValue('email', email);
                }} />

              </TabPane>
            </Tabs>
          </Box>
        </Box>
      </Box>
    </LoginModalWrapper>
  );
};

// LoginModal style props
LoginModal.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  logoStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  hintTextStyle: PropTypes.object,
  contentWrapper: PropTypes.object,
  descriptionStyle: PropTypes.object,
  googleButtonStyle: PropTypes.object,
};

// LoginModal default style
LoginModal.defaultProps = {
  // Team member row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Team member col default style
  col: {
    width: [1, 1 / 2],
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    height: 'auto',
    ml: '15px',
  },
  // Title default style
  titleStyle: {
    fontSize: ['22px', '36px', '50px'],
    fontWeight: '400',
    color: '#20201D',
    letterSpacing: '-0.025em',
    mt: '35px',
    mb: '10px',
  },
  // Description default style
  descriptionStyle: {
    color: 'rgba(52, 61, 72, 0.8)',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '-0.025em',
    mb: '23px',
    ml: '1px',
  },
  // Content wrapper style
  contentWrapper: {
    pt: ['0', '0'],
    pl: ['17px', '32px', '38px', '40px', '56px'],
    pr: '32px',
    pb: ['32px', '56px'],
  },
  // Default button style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  // Outline button outline style
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#10ac84',
  },
  // Google button style
  googleButtonStyle: {
    bg: '#ffffff',
    color: '#343D48',
  },
};

export default LoginModal;

const useStyles = makeStyles(theme => ({

  loginBtn: {
    border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
    boxShadow: `2px 2px 6px -1px ${theme.palette.common.white}`,
    marginRight: 10,
    '&:focus': {
      boxShadow: `2px 2px 6px -1px ${theme.palette.common.white}`,
    }
  },
  registerBtn: {
    border: `2px solid ${theme.colors.FORM_CANCEL_BUTTON_BORDER_COLOR}`,
    background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
    marginRight: 10,
    color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
  },

}))
