import {
  Chip,
  Dialog,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import { mdiHumanMale } from "@mdi/js";
import Icon from "@mdi/react";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { Fragment, useState } from "react";
import HeadingBar from "../HeadingBar/HeadingBar";
import UserSuggestionOption from "../UserSuggestionOption/UserSuggestionOption";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import RightsManager from "../../utils/RightsManager";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMemberSuggest,
  resetSuggestions,
} from "../../actions/suggestionsActions";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { Autocomplete } from "@material-ui/lab";
import { assignService, updateService } from "../../actions/serviceActions";
import { updateServiceProvider } from "../../actions/serviceProviderActions";
import * as icons from "@mdi/js";
import LinkCNO from "../../library/LinkCNO";
import { useTheme } from "@material-ui/core";
function EditAssignedToDetails({ isEditable, setIsEditable, service }) {
  const dispatch = useDispatch();
  const suggestions = useSelector((state) => state.suggestions);

  const tillTablet = useMediaQuery("(max-width: 767px)");

  const { editServiceLoader } = useSelector((state) => state.services);

  const [open, setOpen] = useState(false);

  const handleClose = () => setIsEditable(false);

  const handleUpdate = async (values) => {
    const serviceAssignments = values.serviceAssignments.map((item) => ({
      startDate: service.startDate,
      endDate: service.endDate,
      startTime: service.startTime,
      endTime: service.endTime,
      staffId: item.id || item.staffId,
      staffText: item.title || item.staffText,
      paymentToEmployeeStatus: 1,
      paidAmount: 0,
      rate: 0,
      rateType: 0,
      ServiceId: service.id,
    }));

    const payload = {
      ...service,
      serviceAssignments: serviceAssignments,
    };

    dispatch(assignService(payload));

    handleClose();
  };

  const validationSchema = yup.object({
    serviceAssignments: yup.array().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      serviceAssignments: service.serviceAssignments,
    },
    validationSchema,
    onSubmit: (values) => handleUpdate(values),
  });

  const unSelectedSuggestions = () =>
    suggestions.filter((item) => {
      return (
        formik.values.serviceAssignments
          .map((item) => item.staffId || item.id)
          .indexOf(item.id) === -1
      );
    });

  console.log(
    "Formik users: ",
    formik.values.serviceAssignments,
    unSelectedSuggestions()
  );

  const useStyles = makeStyles((theme) => ({
    container: {
      width: tillTablet ? 500 : 600,
      margin: "0 auto",
    },
    // paper: {
    //   width: 400,
    //   margin: '0 auto'
    // },
    formTitle: {
      marginLeft: 5,
    },
    form: {
      "&>*": {
        marginTop: theme.spacing(2),
      },
    },
    textField: {
      borderRadius: 5,
      "& input": {
        background: "white",
        color: theme.colors.INPUT_TEXT_COLOR,
        borderRadius: 5,
        padding: 8,
      },
      "& .MuiOutlinedInput-multiline": {
        color: theme.colors.INPUT_TEXT_COLOR,
      },
      "& .MuiOutlinedInput-adornedStart": {
        background: "white",
      },
      "& .MuiOutlinedInput-input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 100px #fff inset",
        "-webkit-text-fill-color": theme.colors.INPUT_TEXT_COLOR,
        caretColor: theme.colors.INPUT_TEXT_COLOR,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
        {
          paddingLeft: 14,
        },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: 0,
      },
      "& .MuiOutlinedInput-adornedEnd": {
        padding: 0,
      },
      "& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
        padding: 0,
      },
    },
    noOptions: {
      color: theme.colors.ERROR_COLOR,
    },
    popper: {
      zIndex: 1301,
    },
    suggestionsPaper: {
      background: "white !important",
    },
    assignmentsWrapper: {
      display: "flex",
      // alignItems: 'flex-end',
      flexDirection: "column",
      "&>*:first-child": {
        flex: 1,
        marginBottom: 8,
      },
    },
    chip: {
      background: theme.colors.CHIP_BUTTON_BACKGROUND_COLOR,
    },
    paper: {
      padding: theme.spacing(2),
      background: theme.colors.DIALOG_BACKGROUND_COLOR,
      color: theme.colors.DIALOG_TITLE_COLOR,
      borderRadius: 10,
      minWidth: tillTablet ? 500 : 600,
      width: "70vw",
      maxWidth: "90vh",
    },
  }));
  const styles = useStyles();

  return (
    <Dialog
      open={isEditable}
      className={styles.container}
      classes={{
        paper: styles.paper,
      }}
    >
      <HeadingBar title="Select Team Member" onClose={handleClose} />

      {editServiceLoader && <FullScreenLoaderCNO />}

      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <Autocomplete
          className={styles.textField}
          classes={{
            paper: styles.suggestionsPaper,
            noOptions: styles.noOptions,
            popper: styles.popper,
          }}
          value={formik.values.serviceAssignments}
          open={open}
          multiple
          dense
          closeIcon={() => null}
          forcePopupIcon={false}
          options={unSelectedSuggestions()}
          getOptionLabel={(option) => option.title}
          renderOption={(option) => <UserSuggestionOption user={option} />}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.title || option.staffText}
                {...getTagProps({ index })}
                className={styles.chip}
              />
            ));
          }}
          renderInput={(params) => (
            <TextField
              autoFocus
              {...params}
              variant="outlined"
              placeholder="Select Team Member"
            />
          )}
          onChange={(e, newValue) => {
            formik.setFieldValue("serviceAssignments", newValue);
          }}
          onInputChange={(e) => {
            if (e && e.target.value) {
              dispatch(fetchMemberSuggest(e.target.value));
              setOpen(true);
            } else {
              setOpen(false);
            }
          }}
          onBlur={() => {
            setOpen(false);
            dispatch(resetSuggestions());
          }}
        />
        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
          disabled={formik.values.serviceAssignments.length === 0}
        />
      </form>
    </Dialog>
  );
}

function serviceAssignedUsersDetailsCard({ service }) {
  const isUserAdmin = RightsManager.isAdmin();
  const theme = useTheme();

  const [isEditable, setIsEditable] = useState(false);

  console.log("serviceAssignments", service?.serviceAssignments);

  const styles = useStyles();
  return (
    <>
      {isUserAdmin && isEditable && (
        <EditAssignedToDetails
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          service={service}
        />
      )}
      <div className={styles.container} onClick={() => setIsEditable(true)}>
        <Typography className={styles.cardTitle}>Assigned to</Typography>
        <div className={styles.cardContent}>
          <div className={styles.providers}>
            {service?.serviceAssignments?.length > 0 ? (
              service.serviceAssignments.map((provider) => (
                <div className={styles.providerDetails}>
                  <div className={styles.profileDetails}>
                    <div>
                      <img src={provider.profilePic} />
                    </div>
                    <div>
                      <p>{provider.title}</p>
                      <p>
                        {`Rating : ${provider.rating}`}
                        <Icon path={icons.mdiStar} size={0.5} />
                      </p>
                    </div>
                  </div>

                  <div className={styles.actionButtons}>
                    <LinkCNO to={`/profile/${provider.id}`}>
                      <Button
                        style={{
                          backgroundColor:
                            theme.colors.VIEW_BUTTON_BACKGROUND_COLOR,
                          color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
                        }}
                        variant="contained"
                        startIcon={
                          <Icon path={icons.mdiEyeOutline} size={1.1} />
                        }
                      >
                        {`View`}
                      </Button>
                    </LinkCNO>
                  </div>
                </div>
              ))
            ) : (
              <Typography
                style={{
                  color: theme.colors.JOB_DETAIL_CUSTOMER_TEXT_COLOR,
                  fontWeight: "bold",
                }}
              >
                {"Currently there is no provider assigned. "}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default serviceAssignedUsersDetailsCard;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    background: theme.colors.JOB_DETAIL_CARD_BACKGROUND_COLOR,
    borderRadius: 10,
  },
  cardTitle: {
    color: theme.colors.JOB_DETAIL_CARD_TITLE_COLOR,
  },
  cardContent: {
    padding: theme.spacing(1),
    "&>*": {
      marginTop: theme.spacing(1),
    },
    maxHeight: "700px",
    overflow: "auto",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.colors.JOB_DETAIL_ASSIGN_USER_COLOR,
  },
  icon: {
    color: theme.colors.JOB_DETAIL_ASSIGN_USER_ICON_COLOR,
  },
  providers: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
  },
  providerDetails: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "8px",
    backgroundColor: theme.palette.background.paper,
  },
  profileDetails: {
    display: "flex",
    "& img": {
      width: "60px",
      height: "60px",
      borderRadius: "50%",
      marginRight: "16px",
    },
    "& p": {
      margin: "0",
      fontSize: "16px",
      color: theme.colors.TEXT_COLOR,
    },
  },
  actionButtons: {
    display: "flex",
    gap: "8px",
  },
}));
