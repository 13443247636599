import { Dialog, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from "yup";
import React, { Fragment, useState } from 'react';
import RightsManager from '../../utils/RightsManager';
import { useStyles } from './JobTargetDetailsCardStyles';
import HeadingBar from '../HeadingBar/HeadingBar';
import InputCNO from '../../library/InputCNO';
import { mdiCheck, mdiCurrencyGbp, mdiHome, mdiHours24, mdiLockClock } from '@mdi/js';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import FullScreenLoaderCNO from '../../library/FullScreenLoaderCNO';
import { apiTaskEdit } from '../../api';
import { toast } from 'react-toastify';
import ChipCNO from '../../library/ChipCNO';
import { JobTargetType, JobTargetTypeIcon, JobTargetTypeReverse, JobTargetTypeUnit, RateType, RateTypeReverse } from '../../utils/mappings';
import * as icons from '@mdi/js';
import { getMaterialIconString } from '../../utils/icons';
import Icon from '@mdi/react';
import { useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { editJob } from '../../actions/jobActions';
import FormErrorsCNO from '../../library/FormErrorsCNO';
import FieldHelperTextCNO from '../../library/FieldHelperTextCNO';

function EditJobTargetDetails({ isEditable, setIsEditable, job, setJob }) {

  const styles = useStyles();

  const dispatch = useDispatch();
  const { editTodoLoader } = useSelector(state => state.jobs);

  const handleClose = () => setIsEditable(false);

  const handleUpdate = async (values) => {

    const jobTargetPayload = {
      ...job,
      target: values.target ? values.target : 0,
      targetType: values.targetType
    };

    setJob({
      ...job,
      target: values.target ? values.target : 0,
      targetType: values.targetType
    });

    dispatch(editJob(jobTargetPayload));

    handleClose();
  };

  const validationSchema = yup.object({
    target: yup.string().required("Target value is required."),
    targetType: yup.number().required("Target type is required."),
  });

  const formik = useFormik({
    initialValues: {
      target: job.target,
      targetType: job.targetType
    },
    validationSchema,
    onSubmit: values => handleUpdate(values)
  });

  return (
    <Dialog
      open={isEditable}
      className={styles.container}
      classes={{
        paper: styles.paper
      }}
    >
      {
        editTodoLoader &&
        <FullScreenLoaderCNO />
      }

      <HeadingBar title="Edit Job Target" onClose={handleClose} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.rateTypeWrapper}>
          <Typography>Job Target Types</Typography>
          <div className={styles.rateTypeChips}>
            {
              Object.keys(JobTargetType).filter((t) => t !== 'None')
                .map((target) => (
                  <ChipCNO
                    icon={icons[getMaterialIconString(JobTargetTypeIcon[target])]}
                    name={target}
                    size="1.5rem"
                    active={formik.values.targetType === JobTargetType[target]}
                    onClick={() => formik.setFieldValue('targetType', JobTargetType[target])}
                  />
                ))
            }
          </div>
          <FieldHelperTextCNO
            showHelperText={Boolean(formik.errors.targetType)}
            helperText={formik.errors.targetType}
          />
        </div>
        <InputCNO
          name='target'
          label='Job Target'
          formik={formik}
          placeholder='Enter Job Target'
          icon={icons.mdiFormatText}
          fullWidth
        />
        <FormActionButtons
          rightText="Save"
          leftClick={handleClose}
          disabled={!formik.dirty || !formik.isValid}
        />
      </form>
    </Dialog>
  )
}


function JobTargetDetailsCard({ job, setJob }) {

  const theme = useTheme();

  const styles = useStyles();

  const isUserAdmin = RightsManager.isAdmin();
  const [isEditable, setIsEditable] = useState(false);

  return (
    <>
      {
        isUserAdmin && isEditable &&
        <EditJobTargetDetails
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          job={job}
          setJob={setJob}
        />
      }
      <div className={styles.container_JobTargetDetailsCard} onClick={() => setIsEditable(true)}>
        <Typography className={styles.cardTitle}>
          Target
        </Typography>
        <div className={styles.cardContent}>
          <div className={styles.row}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                path={icons.mdiBullseyeArrow}
                size="1.2rem"
                color={theme.colors.JOB_DETAIL_RATE_COLOR}
                style={{ marginRight: theme.spacing(1) }}
              />
              <Typography>
                {`${job.target} ${JobTargetTypeUnit[job.targetType]}`}
              </Typography>
            </div>

            {
              job.targetType ?
                <ChipCNO
                  icon={icons[getMaterialIconString(JobTargetTypeIcon[JobTargetTypeReverse[job.targetType]])]}
                  name={JobTargetTypeReverse[job.targetType]}
                  active={true}
                /> : null
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default JobTargetDetailsCard
