import { useFormik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { Dialog, makeStyles, Select, MenuItem } from "@material-ui/core";
import HeadingBar from "../HeadingBar/HeadingBar";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import FullScreenLoaderCNO from "../../library/FullScreenLoaderCNO";
import { apiTaskEdit } from "../../api";
import { toast } from "react-toastify";

function EditJobTypeDialog({
  job,
  setJob,
  isJobTypeEditable,
  setIsJobTypeEditable,
}) {
  const styles = useStyles();

  const [loading, setLoading] = useState(false);

  const categories = JSON.parse(localStorage.getItem("categories"));

  const handleClose = () => {
    setIsJobTypeEditable(false);
  };

  const handleUpdate = async (values) => {
    setLoading(true);

    const localCategories = JSON.parse(localStorage.getItem("categories"));
    const selected = localCategories.filter(
      (item) => item.title === values.jobCategory
    )[0];
    // job.categories = [values.jobCategory.id];
    job.categories = [selected.id];

    setJob({
      ...job,
      categories: [selected.id],
    });

    await apiTaskEdit
      .post(job)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          toast.success("Job type edited successfully!!");
        } else {
          toast.error(
            "There is some issues in editing job detail, please try again!"
          );
        }
      })
      .catch((error) => {
        console.log("Edit Job title Error: ", error);
        toast.error(
          "There is some issues in editing job detail, please try again!"
        );
      });
    handleClose();
  };

  const validationSchema = yup.object({
    jobCategory: yup.string().required("Required!!!"),
  });

  const formik = useFormik({
    initialValues: {
      jobCategory: categories.filter(
        (category) => category.id === job.categories[0]
      )[0].title,
    },
    validationSchema,
    onSubmit: (values) => handleUpdate(values),
  });

  console.log("loading: ", loading);

  if (loading) {
    return <FullScreenLoaderCNO />;
  }

  return (
    <Dialog
      open={isJobTypeEditable}
      className={styles.container}
      classes={{
        paper: styles.paper,
      }}
    >
      <HeadingBar title="Edit Job Type" onClose={handleClose} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <Select
          fullWidth
          variant="outlined"
          margin="dense"
          onChange={(e) => formik.setFieldValue("jobCategory", e.target.value)}
          value={formik.values.jobCategory}
          className={styles.dropdown}
        >
          {categories.map((category, index) => {
            return <MenuItem value={category.title}>{category.title}</MenuItem>;
          })}
        </Select>
        <FormActionButtons rightText="Save" leftClick={handleClose} />
      </form>
    </Dialog>
  );
}

export default EditJobTypeDialog;

const useStyles = makeStyles((theme) => ({
  container: {
    "&>*": {
      marginTop: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    // color: theme.palette.text.secondary,
    background: theme.colors.DIALOG_BACKGROUND_COLOR,
    borderRadius: 10,
    width: "70vw",
    maxWidth: "90vh",
  },
  formTitle: {
    marginLeft: 5,
    color: theme.colors.DIALOG_TITLE_COLOR,
  },
  form: {
    "&>*": {
      marginTop: theme.spacing(2),
    },
  },
  dropdown: {
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      background: theme.colors.INPUT_BACKGROUND_COLOR,
      color: theme.colors.INPUT_TEXT_COLOR,
      borderRadius: 5,
      padding: 8,
    },
    "& .MuiOutlinedInput-multiline": {
      color: theme.colors.INPUT_TEXT_COLOR,
    },
    "& .MuiSelect-icon": {
      color: theme.colors.INPUT_TEXT_COLOR,
    },
  },
  ".MuiMenu-paper": {
    background: "white",
    color: "orangered",
  },
  ctaWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(6),
    "&>*": {
      color: theme.colors.FORM_CONFIRM_BUTTON_TEXT_COLOR,
      boxShadow: `2px 2px 5px -1px ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
      border: `2px solid ${theme.colors.FORM_CONFIRM_BUTTON_BORDER_COLOR}`,
      "&:first-child": {
        marginRight: theme.spacing(4),
        background: theme.colors.FORM_CANCEL_BUTTON_BACKGROUND_COLOR,
        color: theme.colors.FORM_CANCEL_BUTTON_TEXT_COLOR,
      },
      "&:last-child": {
        background: theme.colors.FORM_CONFIRM_BUTTON_BACKGROUND_COLOR,
      },
    },
  },
}));
